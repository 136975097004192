import { TranslationModel } from '../../../../models';
import {
  PasswordValidationError,
  PasswordValidationErrorsTitles,
  PasswordValidationErrorTypes,
  PasswordValidationObject,
  PasswordValidationTypes,
} from './passwordControl.model';
import * as Yup from 'yup';
import { passwordValidation } from './passwordControl.constants';

export const getPasswordValidationErrorsTitles = (
  translations?: TranslationModel
): PasswordValidationErrorsTitles => ({
  [PasswordValidationTypes.minLength]:
    translations?.password_validation_min_length,
  [PasswordValidationTypes.upperCase]:
    translations?.password_validation_upper_case,
  [PasswordValidationTypes.lowerCase]:
    translations?.password_validation_lower_case,
  [PasswordValidationTypes.number]: translations?.password_validation_number,
  [PasswordValidationTypes.specialCharacter]:
    translations?.password_validation_special_character,
});

export const getPasswordValidationObject = (
  translations?: TranslationModel
): PasswordValidationObject => ({
  password: Yup.string()
    .required(translations?.input_required_error_label)
    .test((value) => {
      let errors: PasswordValidationError[] = [];

      if (value) {
        passwordValidation.map((validation) => {
          const isExclude = validation.regexExclude?.test(value);
          const isInclude = validation.regex.test(value);

          if (isExclude || !isInclude) {
            errors.push({
              validationType: validation.type,
              type: isExclude
                ? PasswordValidationErrorTypes.exclude
                : PasswordValidationErrorTypes.include,
            });
          }
        });
      }

      if (errors.length > 0) {
        // because need to return array of errors
        // @ts-ignore
        throw new Yup.ValidationError({
          errors: errors,
          inner: true,
          path: 'password',
          message: errors,
          value: value,
          name: 'ValidationError',
        });
      }

      return true;
    }),
});
