import React, { ReactElement } from 'react';
import { HistogramProps } from './histogram.model';
import { HistogramStyled } from './histogram.styled';
import { HistogramMarkersContainer } from './histogramMarkers';
import { HistogramBarsContainer } from './histogramBars';
import { getMaxValue } from './histogram.helper';

export const HistogramContainer = ({
  values,
}: HistogramProps): ReactElement => {
  const maxValue = getMaxValue(values);

  return (
    <HistogramStyled>
      <HistogramMarkersContainer maxValue={maxValue} />
      <HistogramBarsContainer maxValue={maxValue} values={values} />
    </HistogramStyled>
  );
};
