import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBookMinimalisticComponent = ({
  width = 16,
  height = 16,
  fillColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8 2.22363C9.6401 2.22363 8.7 3.16373 8.7 4.32363V11.3236C8.7 11.71 8.3864 12.0236 8 12.0236C7.6136 12.0236 7.3 11.71 7.3 11.3236V4.32363C7.3 3.16373 6.3599 2.22363 5.2 2.22363H1.7C1.3136 2.22363 1 2.53723 1 2.92363V12.0236C1 12.7971 1.6265 13.4236 2.4 13.4236H6.7939C7.0361 13.8401 7.4827 14.1236 8 14.1236C8.5173 14.1236 8.9632 13.8401 9.2061 13.4236H13.6C14.3735 13.4236 15 12.7971 15 12.0236V2.92363C15 2.53723 14.6864 2.22363 14.3 2.22363H10.8Z"
      fill={fillColor}
    />
  </svg>
);
