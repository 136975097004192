import { CommonEventTypes, CourseModel } from '@lerna-core';
import {
  EVENT_TYPE,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { UserOrderFormSubmitStatusTypes } from '../userOrderForm.model';

export const pushFormArchiveSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = [
    OrderFormEventsValues.subscription,
    OrderFormEventsValues.noTabs,
    course.subscriptionAccess?.withHomeworks
      ? OrderFormEventsValues.withHomework
      : OrderFormEventsValues.noHomework,
  ];
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
