import {
  CourseUrlParams,
  getCoursePageHref,
  Nullable,
  QueryParams,
} from '@lerna-core';
import { GetCourseHrefProps } from '../models/course.model';

export const getCourseHref = ({
  course,
  router,
  basePath,
  initiator,
}: GetCourseHrefProps): Nullable<string> => {
  const urlParams: CourseUrlParams = {};
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;
  const promoCodeQuery = router.query[QueryParams.promoCode] as string;

  if (discountProgramQuery) {
    urlParams[QueryParams.discountProgram] = discountProgramQuery;
  }
  if (promoCodeQuery) {
    urlParams[QueryParams.promoCode] = promoCodeQuery;
  }
  if (initiator) {
    urlParams[QueryParams.iframeInitiator] = initiator;
  }

  return getCoursePageHref({
    course,
    router,
    urlParams,
    basePath,
  });
};
