import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';
import { PopupStyleProps } from '../../popups';
import { modalContentPadding, modalPaddings } from './registerPopup.meta';

export const canUseRegisterPopup = (
  user: Nullable<UserAggregateModel>
): boolean =>
  !!(
    user?.career?.isPersonalDataFillOptional &&
    (!user?.career.isVerified || !user?.personal.isFulfilled)
  );

export const canUseRegisterWarning = (
  user: Nullable<UserAggregateModel>
): boolean =>
  canUseRegisterPopup(user) &&
  !(
    user?.career?.isBonusPointsHiddenOnListing ||
    user?.career?.isPricesHiddenOnListingCards ||
    user?.bonuses.points === 0
  );

export const canUseRegisterWelcome = (
  user: Nullable<UserAggregateModel>,
  canShowWelcomePopup: boolean
): boolean =>
  canUseRegisterPopup(user) &&
  !(
    user?.career?.isBonusPointsHiddenOnListing ||
    user?.career?.isPricesHiddenOnListingCards ||
    user?.bonuses.points === 0
  ) &&
  canShowWelcomePopup;

export const getRegisterPopupStyleSettings = (
  isMobile: boolean
): PopupStyleProps => ({
  ...modalPaddings,
  paddingContent: {
    ...modalPaddings.paddingContent,
    ...(isMobile && modalContentPadding),
  },
});
