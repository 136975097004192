import styled from 'styled-components';
import { fontSettings } from '../../../../styles';

export const SuggestItemContainerStyled = styled.div`
  cursor: pointer;
  position: relative;
  padding: 16px 32px 16px 0;
  margin-left: ${({ theme }): number => (theme.IS_MOBILE ? 0 : 36)}px;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  &:not(:first-child) {
    border-top: 1px solid ${(props): string => props.theme.COLOR.BLACK_08};
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(-90deg);
  }
`;

export const SuggestItemTermStyled = styled.span`
  font-weight: 500;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
`;
