import { createSelector } from 'reselect';

import { RootState } from '../../models';
import { FooterState } from './footer.model';

export const getFooterImages = createSelector(
  (state: RootState<FooterState>) => state.footer,
  (reducer: FooterState) => reducer.images
);

export const getFooterStatus = createSelector(
  (state: RootState<FooterState>) => state.footer,
  (reducer: FooterState) => reducer.status
);
