import {
  ApiProgramSectionModel,
  CourseProgramSectionModel,
} from './courseProgram.model';

export const courseProgramSectionsAdapter = (
  sections?: ApiProgramSectionModel[]
): CourseProgramSectionModel[] => {
  if (!sections || sections?.length === 0) {
    return [];
  }

  return sections.map((section) => ({
    title: section.title,
    description: section.description || null,
    subSections: (section.sub_sections.data || []).map((subsection) => ({
      title: subsection.title || null,
      description: subsection.description || null,
    })),
  }));
};
