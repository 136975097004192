import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSportComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.98 3.315c-.072.002-.144.01-.214.025-9.002.125-16.297 7.419-16.426 16.42a1.25 1.25 0 0 0 0 .474c.125 9.002 7.419 16.297 16.42 16.426.156.03.317.03.474 0 9.002-.125 16.297-7.419 16.426-16.42.03-.156.03-.317 0-.474-.125-9.002-7.419-16.297-16.42-16.426a1.25 1.25 0 0 0-.26-.025Zm-1.23 2.582V18.75h-3.802a16.594 16.594 0 0 0-4.015-9.64 14.096 14.096 0 0 1 7.817-3.213Zm2.5 0a14.086 14.086 0 0 1 8.053 3.428 16.611 16.611 0 0 0-3.82 9.425H21.25V5.897ZM9.163 10.874a14.072 14.072 0 0 1 3.274 7.876h-6.54a14.092 14.092 0 0 1 3.266-7.876Zm21.892.269a14.088 14.088 0 0 1 3.048 7.607h-6.108a14.059 14.059 0 0 1 3.06-7.607ZM5.897 21.25h6.541a14.064 14.064 0 0 1-3.28 7.871 14.093 14.093 0 0 1-3.261-7.871Zm9.05 0h3.803v12.853a14.095 14.095 0 0 1-7.82-3.216 16.597 16.597 0 0 0 4.018-9.637Zm6.303 0h4.233a16.597 16.597 0 0 0 3.828 9.419 14.087 14.087 0 0 1-8.061 3.434V21.25Zm6.743 0h6.11a14.089 14.089 0 0 1-3.055 7.616 14.071 14.071 0 0 1-3.055-7.616Z"
        fill={fillColor}
      />
    </svg>
  );
};
