export enum OrderTypesEnum {
  consultation = 'consultation',
  online_payment = 'online_payment',
}

export type CourseOrderTypesApiModel = {
  data: Array<{
    slug: OrderTypesEnum;
  }>;
};

export type CourseOrderTypesModel = OrderTypesEnum[];
