import React, { memo, ReactElement } from 'react';

import { useTheme } from '../../../../hooks';
import { SelectStyledArrow } from '../../../form/formInputs/select/select.styled';
import { IconArrowComponent } from '../../../icons';
import { SelectButtonStyled } from './selectButton.styled';
import { SelectButtonProps } from './selectButton.model';

const SelectButton = ({
  children,
  borderRadius = 16,
  active,
  ...props
}: SelectButtonProps): ReactElement => {
  const theme = useTheme();

  return (
    <SelectButtonStyled {...props} borderRadius={borderRadius} active={active}>
      <span>{children}</span>

      <SelectStyledArrow isOpened={active}>
        <IconArrowComponent
          strokeColor={
            active ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR
          }
        />
      </SelectStyledArrow>
    </SelectButtonStyled>
  );
};

export const SelectButtonContainer = memo(SelectButton);
