import { ImageModel } from '../../../models';

export enum FooterImageSizes {
  big = 'big',
}

export type FooterImageModel = {
  image: ImageModel;
  slug: string;
  title: string;
  type: string;
};

export type FooterImagesContainerProps = {
  images?: FooterImageModel[];
};

export type FooterImagesContainerStyledProps = {
  maxColumns: number;
};
