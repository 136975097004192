import styled, { keyframes } from 'styled-components';
import {
  HeaderLoaderLinePropsType,
  HeaderLoaderPropsType,
} from './headerLoader.model';
import { headerLoaderLineGradient } from '../../../styles';

const loading = keyframes` 
  0% {
    left: -400px;
  }

  100% {
    left: calc(100% + 400px);
  }
`;

const loaderLineWithCompany = keyframes`
  0% {
    background: -webkit-linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
    background: -moz-linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
    background: linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
  }

  20% {
    background: -webkit-linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
    background: -moz-linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
    background: linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
  }

  40% {
    background: -webkit-linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
    background:-moz-linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
    background: linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
  }

  60% {
    background: -webkit-linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
    background: -moz-linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
    background: linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
  }

  80% {
    background: -webkit-linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
    background: -moz-linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
    background: linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
  }

  100% {
    background: -webkit-linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
    background: -moz-linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
    background: linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
  }
`;

const loaderLineWithoutCompany = keyframes`
  0% {
    background: -webkit-linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
    background: -moz-linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
    background: linear-gradient(76deg, #FF95C2 8%, #E48ACB 28%, #C092F5 47%, #9B9FFF 69%, #8DA7FF 92%);
    -webkit-box-shadow: -100px 0 90px 48px #FF95C2 ,100px 0 90px 48px #8DA7FF;
    -moz-box-shadow: -100px 0 90px 48px #FF95C2 ,100px 0 90px 48px #8DA7FF;
    box-shadow: -100px 0 90px 48px #FF95C2 ,100px 0 90px 48px #8DA7FF;
  }

  20% {
    background: -webkit-linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
    background: -moz-linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
    background: linear-gradient(76deg, #ff95c2 12%, #e48acb 32%, #c092f5 51%, #c092f5 67%, #8da7ff 96%);
    -webkit-box-shadow: -100px 0 90px 48px #ff95c2 ,100px 0 90px 48px #8da7ff;
    -moz-box-shadow: -100px 0 90px 48px #ff95c2 ,100px 0 90px 48px #8da7ff;
    box-shadow: -100px 0 90px 48px #ff95c2 ,100px 0 90px 48px #8da7ff;
  }

  40% {
    background: -webkit-linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
    background:-moz-linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
    background: linear-gradient(76deg, #c092f5 19%, #e48acb 39%, #e48acb 60%, #c092f5 74%, #8da7ff 100%);
    -webkit-box-shadow: -100px 0 90px 48px #c092f5 ,100px 0 90px 48px #8da7ff;
    -moz-box-shadow: -100px 0 90px 48px #c092f5 ,100px 0 90px 48px #8da7ff;
    box-shadow: -100px 0 90px 48px #c092f5 ,100px 0 90px 48px #8da7ff;
  }

  60% {
    background: -webkit-linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
    background: -moz-linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
    background: linear-gradient(76deg, #e48acb 18%, #8da7ff 33%, #c092f5 52%, #c092f5 59%, #ff95c2 92%);
    -webkit-box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
    -moz-box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
    box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
  }

  80% {
    background: -webkit-linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
    background: -moz-linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
    background: linear-gradient(76deg, #e48acb 18%, #8da7ff 37%, #e48acb 66%, #c092f5 80%, #ff95c2 90%);
    -webkit-box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
    -moz-box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
    box-shadow: -100px 0 90px 48px #e48acb ,100px 0 90px 48px #ff95c2;
  }

  100% {
    background: -webkit-linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
    background: -moz-linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
    background: linear-gradient(76deg, #7283d0 20%, #8da7ff 42%, #c092f5 52%, #c092f5 65%, #ff95c2 80%);
    -webkit-box-shadow: -100px 0 90px 48px #7283d0 ,100px 0 90px 48px #ff95c2;
    -moz-box-shadow: -100px 0 90px 48px #7283d0 ,100px 0 90px 48px #ff95c2;
    box-shadow: -100px 0 90px 48px #7283d0 ,100px 0 90px 48px #ff95c2;
  }
`;

export const HeaderLoader = styled.div<HeaderLoaderPropsType>`
  display: ${({ isRouting }) => (isRouting ? 'block' : 'none')};
  position: ${({ isOnTop }) => (isOnTop ? 'fixed' : 'absolute')};
  width: 100%;
  height: ${({ withCompany }) => (withCompany ? '2px' : 'calc(100% + 2px)')};
  opacity: ${({ withCompany }) => (withCompany ? 1 : 0.2)};
  background-color: ${({ withCompany }) => (withCompany ? '#eceafb' : 'none')};
  top: ${({ isOnTop }) => (isOnTop ? 0 : 'initial')};
  right: 0;
  bottom: -2px;
  left: 0;
  overflow: hidden;
  z-index: 1;
`;

export const HeaderLoaderLine = styled.div<HeaderLoaderLinePropsType>`
  position: absolute;
  width: 200px;
  height: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${headerLoaderLineGradient()}
  animation: ${loading} 1.75s ease-in-out infinite,
    ${({ withCompany }) =>
    withCompany
      ? loaderLineWithCompany
      : loaderLineWithoutCompany} 3s ease-in-out infinite;
`;
