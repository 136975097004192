import React, { ReactElement } from 'react';

import { CareerModel, PersonalModel } from '../user.model';
import { UserAvatarContainer, UserAvatarSize } from '../userAvatar';
import {
  UserSmallInfoNameStyled,
  UserSmallInfoProfessionStyled,
  UserSmallInfoStyled,
  UserSmallInfoTextBlockStyled,
} from './userSmallInfo.styled';

type UserSmallInfoContainerProps = {
  personal: PersonalModel;
  career?: CareerModel;
};

export const UserSmallInfoContainer = ({
  personal,
  career,
}: UserSmallInfoContainerProps): ReactElement => {
  return (
    <UserSmallInfoStyled>
      <UserAvatarContainer personal={personal} size={UserAvatarSize.medium} />
      <UserSmallInfoTextBlockStyled>
        <UserSmallInfoNameStyled>
          {personal.firstName} {personal.lastName}
        </UserSmallInfoNameStyled>
        {!!career && (
          <UserSmallInfoProfessionStyled>
            {career.position?.title}
          </UserSmallInfoProfessionStyled>
        )}
      </UserSmallInfoTextBlockStyled>
    </UserSmallInfoStyled>
  );
};
