import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconArtoxLogoComponent = ({
  width = 115,
  height = 54,
  fillColor = '#FF0000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 317 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M224.64 7.36C233.92 7.36 240.64 15.04 240.64 24.64V27.2C240.64 36.8 233.92 44.48 224.64 44.48H216.96C207.68 44.48 200.96 36.8 200.96 27.2V24.32C200.96 14.72 207.68 7.04 216.96 7.04C218.88 7.36 222.08 7.36 224.64 7.36ZM224.64 0H217.28C203.84 0 192.96 10.88 192.96 24.32V26.88C192.96 40.32 203.84 51.2 217.28 51.2H224.64C238.08 51.2 248.96 40.32 248.96 26.88V24.32C248.96 10.88 238.08 0 224.64 0ZM182.08 0.96H136.32V8H155.2V50.56H162.88V8H182.08V0.96ZM122.88 50.56L112.96 30.72L113.6 30.4C118.72 27.84 121.6 22.72 121.6 17.28V16C121.6 7.36 114.56 0.64 105.6 0.64H75.84V50.24H83.52V32H105.28L113.6 49.92H122.88V50.56ZM106.24 25.28H83.84V8H106.24C110.4 8 113.6 11.84 113.6 16.32V16.96C113.6 21.44 110.08 25.28 106.24 25.28ZM8.32 50.56L14.08 36.8H43.52L49.28 50.56H57.6L37.12 0.96H20.8L0 50.56H8.32ZM25.92 7.68H31.68L40.64 29.76H16.96L25.92 7.68ZM299.2 25.28V24.96L316.48 0.96H306.88L292.48 21.44H285.44L271.04 0.96H261.44L278.4 24.96V25.28L261.12 50.56H270.4L285.12 28.48H292.16L307.52 50.56H316.8L299.2 25.28Z"
        fill={fillColor}
      />
    </svg>
  );
};
