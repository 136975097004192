import { CurrenciesActions } from './currencies.model';
import * as constants from './currencies.constants';
import { CurrencyModel } from '../../models';
import { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { currenciesClientProvider } from './currencies.clientProvider';
import { logger } from '../../services';

export const requestCurrencies = async (
  dispatch: Dispatch<CurrenciesActions>,
  host?: string,
  privateHost?: string
): Promise<void> => {
  await currenciesClientProvider(host, privateHost)
    .then((response) => {
      dispatch(requestCurrenciesSuccess(response));
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not get currencies. ${error}`);
      dispatch(requestCurrenciesError(error));
    });
};

export const requestCurrenciesSuccess = (
  payload: CurrencyModel[]
): CurrenciesActions => ({
  type: constants.REQUEST_CURRENCIES_SUCCESS,
  payload,
});

export const requestCurrenciesError = (
  payload: AxiosError
): CurrenciesActions => ({
  type: constants.REQUEST_CURRENCIES_ERROR,
  payload,
});
