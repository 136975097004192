import { TranslationModel } from '../models';
import {
  ApiFilter,
  ApiFilterOption,
  ApiFilterRange,
  ApiFilters,
  FilterOptionModel,
  FilterRangeOptionModel,
  FiltersModel,
  FilterTypeEnum,
  getFilterRangeSelectedTitle,
  getFilterSelectedTitle,
} from '../catalog/filters';
import { mapImage } from '../features';

const sortFilters = (a: ApiFilter, b: ApiFilter): number => {
  if (a.sort_order >= b.sort_order) {
    return 1;
  }

  return -1;
};

const filterOptionsAdapter = (option: ApiFilterOption): FilterOptionModel => ({
  slug: option.slug,
  value: option.value,
  checked: option.checked,
  mindboxId: option.mindbox_id,
  image: option?.image ? mapImage(option.image) : null,
});

export const filtersAdapter = (
  apiFilters: ApiFilter[],
  translations: TranslationModel
): FiltersModel[] => {
  const filtersMap = apiFilters
    .sort(sortFilters)
    .map((apiFilter) => filterAdapter(apiFilter, translations));

  return filtersMap as FiltersModel[];
};

export const filterAdapter = (
  apiFilter: ApiFilters,
  translations: TranslationModel
): FiltersModel => {
  switch (apiFilter.type) {
    case FilterTypeEnum.range: {
      const filter = apiFilter as ApiFilterRange;
      const title = filter.title;
      const options = filter.options.data as FilterRangeOptionModel;
      const units = filter.units;

      return {
        isActive: filter.is_active,
        isVisible: filter.is_visible,
        sortOrder: filter.sort_order,
        title,
        showTitle: getFilterRangeSelectedTitle(
          title,
          options,
          units,
          translations
        ),
        slug: filter.slug,
        type: FilterTypeEnum.range,
        options,
        units,
        min: Math.floor(filter.min),
        max: Math.ceil(filter.max),
      };
    }
    default: {
      const title = apiFilter.title;
      const options = apiFilter.options.data.map(filterOptionsAdapter);

      return {
        isActive: apiFilter.is_active,
        isVisible: apiFilter.is_visible,
        sortOrder: apiFilter.sort_order,
        backgroundColor: apiFilter.background_color,
        activeButtonColor: apiFilter.active_button_color,
        iconColor: apiFilter.icon_color,
        tooltip: apiFilter.tooltip,
        title,
        description: apiFilter.description || '',
        showTitle: getFilterSelectedTitle(title, options),
        slug: apiFilter.slug,
        type: apiFilter.type,
        options,
      };
    }
  }
};
