import { useCallback, useEffect, useMemo, useState } from 'react';

import { PaginationProps } from './pagination.container';

const getRange = (start: number, end: number): (string | number)[] => {
  const length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export const DOTS = '...';

export interface UsePaginationOutput {
  paginationRange: (string | number)[];
  currentPage: number;
  totalPageCount: number;
  handlePageChange: (pageNumber: number) => () => void;
  handlePrevPageChange: () => void;
  handleNextPageChange: () => void;
  prevPageIsAvailable: boolean;
  nextPageIsAvailable: boolean;
}

export function usePaginationHelpers({
  page = 1,
  totalItems,
  limit = 1,
  siblingCount = 0,
  onPageChanged,
}: PaginationProps): UsePaginationOutput {
  const [currentPage, setCurrentPage] = useState(page as number);

  useEffect(() => {
    setCurrentPage(page as number);
  }, [page]);

  const totalPageCount = useMemo(
    () => Math.ceil(totalItems / limit),
    [totalItems, limit]
  );

  const paginationRange = useMemo(() => {
    // Общее кол-во видимых элементов пагинации (siblingCount + первая/текущая/последняя страницы + 2 многоточия)
    const visibleItems = siblingCount + 5;

    // Показываем весь диапазон страниц, кол-во страниц меньше видимых элементов пагинации
    if (totalPageCount <= visibleItems) {
      return getRange(1, totalPageCount);
    }

    // Правая и левая граница диапазона
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 2 + 2 * siblingCount;
      const leftRange = getRange(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = getRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );

      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);

      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [siblingCount, totalPageCount, totalItems, currentPage]);

  const handlePageChange = useCallback(
    (pageNumber: number) => (): void => {
      setCurrentPage(pageNumber);
      onPageChanged(pageNumber);
    },
    [onPageChanged]
  );

  const handlePrevPageChange = useCallback(() => {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    onPageChanged(newPage);
  }, [currentPage, onPageChanged]);

  const handleNextPageChange = useCallback(() => {
    const newPage = parseInt(currentPage as unknown as string) + 1;
    setCurrentPage(newPage);
    setCurrentPage(parseInt(currentPage as unknown as string) + 1);
    onPageChanged(newPage);
  }, [currentPage, onPageChanged]);

  const prevPageIsAvailable = useMemo(() => currentPage > 1, [currentPage]);
  const nextPageIsAvailable = useMemo(
    () => currentPage < totalPageCount,
    [currentPage, totalPageCount]
  );

  return useMemo(
    () => ({
      paginationRange,
      currentPage,
      totalPageCount,
      handlePageChange,
      handlePrevPageChange,
      handleNextPageChange,
      prevPageIsAvailable,
      nextPageIsAvailable,
    }),
    [
      paginationRange,
      currentPage,
      totalPageCount,
      handlePageChange,
      handlePrevPageChange,
      handleNextPageChange,
      prevPageIsAvailable,
      nextPageIsAvailable,
    ]
  );
}
