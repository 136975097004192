import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { ISuggestCacheList, SearchContainerProps } from './search.model';
import { useDebounce, useTheme } from '../../hooks';
import { Nullable, SuggestModel } from '../../models';
import { makeSearchRequest } from './search.client';
import {
  SearchContainerStyled,
  SearchFormStyled,
  SearchMagnifierStyled,
  SearchClearStyled,
} from './search.styled';
import {
  GenericInputContainer,
  InputSizes,
} from '../../features/inputs/genericInput';
import { SearchSuggestContainer } from './searchSuggest';
import { handleSetSearchTerm, handleToggleSearchForm } from './search.actions';

export const SearchContainer = ({
  initialTerm,
  placeholder,
  magnifierIcon,
  suggestItemArrowIcon,
  handleClickSuggest,
  handleClose,
  translations,
  handleClearUrl,
  discountProgram,
}: SearchContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const theme = useTheme();
  const searchRef = useRef<Nullable<HTMLDivElement>>(null);

  const [term, setTerm] = useState<string>(initialTerm);
  const debouncedValue = useDebounce<string>(term, 300);
  const [suggestCache, setSuggestCache] = useState<ISuggestCacheList>({});
  const [suggestItems, setSuggestItems] = useState<Array<SuggestModel>>([]);

  useEffect(() => {
    setFocus();
  });

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 1) {
      if (!suggestCache[term]) {
        makeSearchRequest(
          String(debouncedValue),
          5,
          router.locale,
          discountProgram
        )
          .then((res): void => {
            setSuggestCache({
              ...suggestCache,
              [debouncedValue]: res.data.data,
            });
            setSuggestItems(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSuggestItems(suggestCache[term]);
      }
    } else {
      setSuggestItems([]);
    }
  }, [debouncedValue]);

  const setFocus = (): void => {
    const input = searchRef?.current?.querySelector('input');

    if (input) {
      input.focus();
    }
  };

  const handleChangeTerm = (val: string): void => {
    setTerm(val);
  };

  const handleClickSuggestItem = (title: string): void => {
    handleClose(false);
    handleChangeTerm(title);
    handleClickSuggest(title, term);
  };

  const handleClearMobile = (): void => {
    dispatch(handleToggleSearchForm(false, null));
    handleClearUrl();
    dispatch(handleSetSearchTerm(''));
  };

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    handleClickSuggestItem(term);
  };

  return (
    <SearchContainerStyled
      ref={searchRef}
      hasSuggests={Boolean(suggestItems.length)}
    >
      <SearchFormStyled onSubmit={handleSubmit}>
        <GenericInputContainer
          type={'text'}
          name={'showcasesearch'}
          onChange={handleChangeTerm}
          onClear={setFocus}
          initialValue={term}
          placeholder={placeholder}
          clearControl={true}
          autoComplete={'off'}
          size={theme.IS_MOBILE ? InputSizes.small : InputSizes.normal}
          maxlength={255}
        />
        {theme.IS_MOBILE ? (
          <SearchClearStyled onClick={handleClearMobile}>
            {translations?.mobile_suggest_clear_label}
          </SearchClearStyled>
        ) : (
          <SearchMagnifierStyled>{magnifierIcon}</SearchMagnifierStyled>
        )}
      </SearchFormStyled>
      <SearchSuggestContainer
        term={term}
        suggestItems={suggestItems}
        arrowIcon={suggestItemArrowIcon}
        handleClickSuggest={handleClickSuggestItem}
      />
    </SearchContainerStyled>
  );
};
