import React, { ReactElement } from 'react';
import { Nullable } from '../../../../models';

import { ContentStyles, PopupStyleProps } from '../popup.model';
import { PopupContentContainerStyled } from './popupContent.styled';

type PopupContentContainerProps = {
  children: ReactElement | ReactElement[];
  styleProps: Nullable<PopupStyleProps>;
  isMobile: boolean;
  hasFooter: boolean;
  contentStyles?: ContentStyles;
  withoutContentOverflowY: boolean;
  fullHeightMobile: boolean;
};

export const PopupContentContainer = ({
  children,
  styleProps,
  isMobile,
  hasFooter,
  contentStyles,
  withoutContentOverflowY,
  fullHeightMobile,
}: PopupContentContainerProps): ReactElement => {
  return (
    <PopupContentContainerStyled
      styleProps={styleProps}
      isMobile={isMobile}
      hasFooter={hasFooter}
      contentStyles={contentStyles}
      fullHeightMobile={fullHeightMobile}
      withoutContentOverflowY={withoutContentOverflowY}
    >
      {children}
    </PopupContentContainerStyled>
  );
};
