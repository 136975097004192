import { stringService } from '../../services';
import { FilterRangePrefixEnum, FiltersSlugEnum } from '../filters';
import { ShowcaseRequestProps } from './showcase.model';
import { QueryParams } from '../../models';

export const mapShowcaseDefaultRequestProps = (
  props: ShowcaseRequestProps
): ShowcaseRequestProps => {
  const requestProps: ShowcaseRequestProps = {};
  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      switch (key) {
        case QueryParams.numericId:
        case 'page':
        case 'limit':
        case 'debug':
        case 'title':
        case 'promo_code':
        case 'lang': {
          requestProps[key] = props[key];
          break;
        }

        case `${FiltersSlugEnum.schools}`: {
          const filterValues = props[key] as string;
          requestProps[`${FiltersSlugEnum.schools}`] = filterValues.split(',');
          break;
        }

        case `${FiltersSlugEnum.price}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`: {
          requestProps[
            `${FiltersSlugEnum.price}[${FilterRangePrefixEnum.from}]`
          ] = props[key];
          break;
        }

        case `${FiltersSlugEnum.price}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`: {
          requestProps[
            `${FiltersSlugEnum.price}[${FilterRangePrefixEnum.to}]`
          ] = props[key];
          break;
        }

        case `${FiltersSlugEnum.duration}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`: {
          requestProps[
            `${FiltersSlugEnum.duration}[${FilterRangePrefixEnum.from}]`
          ] = props[key];
          break;
        }

        case `${FiltersSlugEnum.duration}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`: {
          requestProps[
            `${FiltersSlugEnum.duration}[${FilterRangePrefixEnum.to}]`
          ] = props[key];
          break;
        }

        case 'fastlink': {
          requestProps['fast_link'] = props[key];
          break;
        }

        // При добавлении нового фильтра,если FilterTypeEnum === toggle, добавить сюда слаг
        case FiltersSlugEnum.sphere:
        case FiltersSlugEnum.level:
        case FiltersSlugEnum.format:
        case FiltersSlugEnum.profession:
        case FiltersSlugEnum.subscription:
        case FiltersSlugEnum.wb:
        case FiltersSlugEnum.legalEntitiesPrice:
        case FiltersSlugEnum.theme:
        case FiltersSlugEnum.onlyMiniCourses:
        case FiltersSlugEnum.type:
        case FiltersSlugEnum.competency:
        case FiltersSlugEnum.expertise:
        case FiltersSlugEnum.collections: {
          const filterValues = props[key] as string;
          requestProps[`options[${key}]`] = filterValues.split(',');
          break;
        }

        default: {
          break;
        }
      }
    }
  }

  return requestProps;
};
