import styled from 'styled-components';

import { fontSettings } from '../../../styles';

export const FormControlSuccess = styled.div`
  margin: 8px 20px 0;
  color: ${({ theme }): string => theme.COLOR.INFO_GREEN_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)};
`;
