import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { SeoTextWrapperStyledProps } from './coursesSeoText.model';

export const SeoTextContainerStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  margin-bottom: 56px;
  color: ${({ theme }): string => theme.COLOR.TEXT_MEDIUM_COLOR};

  ${breakpoint(
    'smallDown',
    css`
      padding: 20px;
    `
  )}
`;

export const SeoTextWrapperStyled = styled.div<SeoTextWrapperStyledProps>`
  max-height: ${({ isCollapsed, height }): string =>
    isCollapsed ? `${height}px` : 'unset'};
  overflow: hidden;
  position: relative;
`;

export const SeoTextInnerStyled = styled.div``;

export const SeoTextShadowStyled = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 56px;
  background: linear-gradient(
    0deg,
    ${({ theme }): string => theme.COLOR.WHITE},
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2)
  );
`;
