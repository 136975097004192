import React, { ReactElement, useState } from 'react';
import { ItemLabelProps } from '../itemLabel.model';
import {
  LabelMobileContainerStyled,
  LabelMobileShowMoreStyled,
} from './labelMobile.styled';

const LIMIT = 75;
const DOT_SEP = '...';

export const LabelMobileContainer = ({
  label,
}: ItemLabelProps): ReactElement => {
  const [isFull, setIsFull] = useState<boolean>(label.length <= LIMIT);
  const visibleLabel = isFull ? label : `${label.slice(0, LIMIT)}${DOT_SEP}`;

  const handleShowFull = (): void => setIsFull(true);

  return (
    <>
      <LabelMobileContainerStyled>{visibleLabel}</LabelMobileContainerStyled>
      {!isFull && (
        <LabelMobileShowMoreStyled onClick={handleShowFull}>
          {'Читать полностью'}
        </LabelMobileShowMoreStyled>
      )}
    </>
  );
};
