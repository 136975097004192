import { GTMSimpleEventModel } from '../../../../models';
import {
  getSimpleEvent,
  publicConfig,
  pushDataLayer,
} from '../../../../services';
import { CommonEventTypes } from '../../../../analytics';
import { projectTypes } from '../../../../constants';
import { MenuEventsEnum, MenuPositionsEnum } from '../../menu.enum';

const getPosition = (): string => {
  const appType = publicConfig?.APP_TYPE;

  switch (true) {
    case appType === projectTypes.hr:
    case appType === projectTypes.personal:
      return `${appType}_${MenuPositionsEnum.header}`;
    default:
      return `${MenuPositionsEnum.header}`;
  }
};

export const topMenuLinkAnalytics = (eventName: string): GTMSimpleEventModel =>
  getSimpleEvent(eventName, CommonEventTypes.overallEvents, getPosition());

export const goToCatalogAnalytics = (): void => {
  pushDataLayer(topMenuLinkAnalytics(MenuEventsEnum.goToCatalog));
};
