import React, { ReactElement } from 'react';

import { AccordionContentStyled } from './accordionContent.styled';
import { NonRequiredString } from '../../../models';

type AccordionContentContainerProps = {
  content: NonRequiredString;
  isOpened: boolean;
};

export const AccordionContentContainer = ({
  content,
  isOpened,
}: AccordionContentContainerProps): ReactElement | null => {
  if (content) {
    return (
      <AccordionContentStyled
        dangerouslySetInnerHTML={{ __html: content }}
        isOpened={isOpened}
      />
    );
  }

  return null;
};
