import styled from 'styled-components';

import { fontSettings } from '../../../../styles';

export const PopupSubtitleStyled = styled.div`
  font-weight: 400;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  margin-bottom: 32px;
  text-align: center;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  white-space: pre-wrap;
`;
