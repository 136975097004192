import {
  PasswordValidation,
  PasswordValidationTypes,
} from './passwordControl.model';

export const passwordValidation: PasswordValidation[] = [
  {
    regex: /^(?=.{8,})/,
    type: PasswordValidationTypes.minLength,
  },
  {
    regex: /[A-Z]/,
    regexExclude: /[А-Я]/,
    type: PasswordValidationTypes.upperCase,
  },
  {
    regex: /[a-z]/,
    regexExclude: /[а-я]/,
    type: PasswordValidationTypes.lowerCase,
  },
  {
    regex: /[0-9]/,
    type: PasswordValidationTypes.number,
  },
  {
    regex: /[~!@#№$%^?&*(){}\[\]<>"'`\-_+=\\|/:;,.]/,
    type: PasswordValidationTypes.specialCharacter,
  },
];
