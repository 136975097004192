import Link from 'next/link';
import styled from 'styled-components';
import { fontSettings } from '../../../../../styles';

export const DesktopLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const DesktopLinkIconStyled = styled.div`
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;
