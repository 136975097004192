import { publicConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';
import { ConsentApiRequestModel, ConsentRequestModel } from './consent.model';
import { mapAdvMailingsRequestParams } from './consent.helper';

export class ConsentClient extends RequestClient<
  ConsentApiRequestModel,
  any,
  any
> {
  protected path = 'orders-api/v1/customers/consent';
  protected requestParameters: ConsentApiRequestModel;

  constructor(params: ConsentRequestModel) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = mapAdvMailingsRequestParams(params);
  }
}
