import {
  ITEMS_TO_SCROLL_DESKTOP,
  ITEMS_TO_SCROLL_MOBILE,
  SPACE_BETWEEN_ITEMS_DESKTOP,
  SPACE_BETWEEN_ITEMS_MOBILE,
} from './tabsRow.constants';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  browserService,
  DeviceInfoContext,
  FilterModel,
  pushDataLayer,
} from '@lerna-core';
import { UseTabsRowHookModel } from './tabsRow.model';
import { FilterEvent, getFilterEventData } from '@features/common/filters';

export const useTabsRow = (filter: FilterModel): UseTabsRowHookModel => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const itemClickRef = useRef<boolean>(false);
  const [sliderKey, setSliderKey] = useState<number | undefined>(undefined);

  const spaceBetweenItems = isMobile
    ? SPACE_BETWEEN_ITEMS_MOBILE
    : SPACE_BETWEEN_ITEMS_DESKTOP;
  const itemsToScroll = isMobile
    ? ITEMS_TO_SCROLL_MOBILE
    : ITEMS_TO_SCROLL_DESKTOP;

  const activeOption = filter.options.find((option) => option.checked);
  const activeOptionIndex = activeOption
    ? filter.options.findIndex((option) => option.checked)
    : undefined;
  const sliderActiveIndex =
    isMobile && activeOptionIndex ? activeOptionIndex + 1 : activeOptionIndex;

  const handleItemClick = useCallback((): void => {
    itemClickRef.current = true;
  }, []);
  const handleDefaultItemClick = useCallback((): void => {
    pushDataLayer(
      getFilterEventData(FilterEvent.constructorFiltersMain, filter, true)
    );

    itemClickRef.current = false;
  }, []);

  useEffect(() => {
    if (!itemClickRef.current) {
      setSliderKey(sliderActiveIndex);
    }
  });

  useEffect(() => {
    itemClickRef.current = false;
  }, [sliderKey]);

  return {
    isMobile,
    sliderActiveIndex,
    spaceBetweenItems,
    itemsToScroll,
    handleItemClick,
    handleDefaultItemClick,
    sliderKey,
  };
};
