import { RequestHeader } from '../../../services';
import { OnboardingArea } from '../onboarding.model';
import { OnboardingCompletedClient } from './onboardingCompleted.client';
import { OnboardingCompletedResponseModel } from './onboardingCompleted.model';

export async function onboardingCompletedClientProvider(
  area: OnboardingArea,
  requestHeaders?: RequestHeader
): Promise<OnboardingCompletedResponseModel> {
  const response = new OnboardingCompletedClient(area, requestHeaders).get();

  return response as unknown as Promise<OnboardingCompletedResponseModel>;
}
