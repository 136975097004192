import { CheckEmailResponse, CheckEmailStatusCode } from './checkEmail.model';
import { checkEmailAdapter } from './checkEmail.adapter';
import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';

export class CheckEmailClient extends SecureRequestClient<
  null,
  CheckEmailResponse,
  CheckEmailStatusCode
> {
  protected path = '';
  protected requestParameters: null;
  protected adapter = checkEmailAdapter;

  constructor(email: string) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
    );
    this.path = `personal-api/v1/identities/emails/${email}`;
    this.requestParameters = null;
  }
}
