import { ToastType, useToast, useTranslations } from '../features';
import { useToastHook } from './useToast.hook';

type CopyFunction = (text: string) => Promise<boolean | void>;

export function useCopyToClipboard(): CopyFunction {
  const successToast = useToast();
  const errorToast = useToast();

  const translations = useTranslations();

  useToastHook(
    translations.copy_link_success,
    ToastType.success,
    successToast.visible
  );

  useToastHook(
    translations.copy_link_error,
    ToastType.error,
    errorToast.visible
  );

  const fallbackCopyTextToClipboard = (text: string): void => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');

      successful ? successToast.show() : errorToast.show();
    } catch (error) {
      errorToast.show();
    }

    document.body.removeChild(textArea);
  };

  const copyFunction: CopyFunction = async (text) => {
    if (!navigator?.clipboard) {
      return fallbackCopyTextToClipboard(text);
    }

    try {
      await navigator.clipboard.writeText(text);
      successToast.show();

      return true;
    } catch (error) {
      errorToast.show();

      return false;
    }
  };

  return copyFunction;
}
