import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const TransferTitleStyled = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
  font-weight: 500;
`;

export const TransferTextStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  margin-right: 5px;
`;

export const TransferCountStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;
