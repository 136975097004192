import { RequestHeader } from '../../services';
import { RedirectClient } from './redirect.client';
import { RedirectModel } from './redirect.model';

export async function redirectClientProvider(
  url: string,
  requestHeaders?: RequestHeader
): Promise<RedirectModel> {
  const response = await new RedirectClient(url, requestHeaders).get();

  return response as unknown as Promise<RedirectModel>;
}
