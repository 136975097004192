import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const ContentInfoStyled = styled.div`
  border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_4};
  padding: 16px 40px;
  text-align: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      padding: 16px 8px;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
