import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { UserAvatarSize } from '../userAvatar';
import { getAvatarImageScale } from '../userAvatar/avatarImage';

export const UserAvatarContainerStyled = styled.div`
  position: relative;
`;

type UserAvatarActionOverlayProps = {
  size: UserAvatarSize;
};

export const UserAvatarActionOverlay = styled.div<UserAvatarActionOverlayProps>`
  position: absolute;
  display: none;
  bottom: 0;
  background: ${(props): string =>
    rgba(props.theme.COLOR.TEXT_DARK_COLOR, 0.4)};
  ${(props): ReturnType<typeof css> => getAvatarImageScale(props.size)}
  color: ${(props): string => props.theme.COLOR.WHITE};
  height: ${({ theme }): string => (theme.IS_MOBILE ? '20px' : '32px')};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  pointer-events: none;

  ${UserAvatarContainerStyled}:hover & {
    display: block;
    pointer-events: all;
  }
`;

export const UserAvatarActionStyled = styled.div`
  padding-top: ${({ theme }): string => (theme.IS_MOBILE ? '2px' : '8px')};
  cursor: pointer;
`;
