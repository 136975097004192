import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBurgerComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12H22"
      stroke={strokeColor}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 18H22"
      stroke={strokeColor}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 6H22"
      stroke={strokeColor}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
