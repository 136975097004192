import cookie from 'cookie';
import { cookieService } from '../../services';
import { AppContext } from 'next/app';
import { IS_TELEGRAM } from '../../features';

export const telegramMiddleware = (context: AppContext): AppContext => {
  const hasTelegramQuery = context.ctx.query.isTelegram as string;

  if (hasTelegramQuery) {
    context.ctx.res?.setHeader(
      'Set-Cookie',
      cookie.serialize(
        cookieService.getCookieKey(IS_TELEGRAM),
        'true',
        cookieService.cookiesDefaultProps
      )
    );
  }

  return context;
};
