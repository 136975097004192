import { CourseLinkApiModel, CourseLinkModel } from './courseUrl.model';

export const courseUrlAdapter = (
  courseLinkApi: CourseLinkApiModel
): CourseLinkModel => ({
  type: courseLinkApi.type,
  params: {
    courseSlug: courseLinkApi.params.course_slug,
    path: courseLinkApi.params.path_url,
    vendorSlug: courseLinkApi.params.vendor_slug,
  },
});
