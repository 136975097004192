export enum pageUrls {
  home = '/',
  courses = '/courses',
  iframeCourses = '/iframe/[initiator]/courses',
  iframeCourse = '/iframe/[initiator]/course',
  help = '/help',
  user = '/user',
  userCourses = '/courses',
  customers = '/customers',
  digitalBridge = '/digitalbridge',
  orders = '/orders',
  register = '/register',
  activateByVerifiedPhone = '/activate-by-verified-phone',
  discountProgram = '/promo/{{discount}}',
}

export enum pageUrlsReplacers {
  discountProgram = '{{discount}}',
}
