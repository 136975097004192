export const TRIGGER_OPEN_POPUP = 'TRIGGER_OPEN_POPUP';
export const TRIGGER_CLOSE_POPUP = 'TRIGGER_CLOSE_POPUP';

export enum PopupsEnum {
  showcaseSearchPopup = 'showcaseSearchPopup',
  filterPopup = 'filterPopup',
  contactUsFormPopup = 'contactUsFormPopup',
  guestOrderFormPopup = 'guestOrderFormPopup',
  userOrderFormPopup = 'userOrderFormPopup',
  switchLanguagePopup = 'switchLanguagePopup',
  userMenuPopup = 'userMenuPopup',
  clearSessionPopup = 'clearSessionPopup',
  referralProgramPopup = 'referralProgramPopup',
  educationStreamsPopup = 'educationStreamsPopup',
}
