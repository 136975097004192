import React, { ReactElement } from 'react';
import {
  FooterSberInfoStyled,
  FooterSberInfoTextStyled,
} from './footerSberInfo.styled';
import { FooterSberInfoContainerProps } from './footerSberInfo.model';
import { useSelector } from 'react-redux';
import { getUser } from '../../user';
import Image from 'next/image';

export const FooterSberInfoContainer = ({
  translations,
}: FooterSberInfoContainerProps): ReactElement => {
  const user = useSelector(getUser);
  const hasJivoChat = user?.career?.companyId;

  return (
    <FooterSberInfoStyled hasJivoChat={Boolean(hasJivoChat)}>
      <a
        href="https://www.pokupay.ru/credit_terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src="/img/buy_with_sber.png"
          width={138}
          height={48}
          alt="logo buy with sber"
        />
      </a>
      <FooterSberInfoTextStyled>
        {translations?.footer_buy_with_sber_info}
      </FooterSberInfoTextStyled>
    </FooterSberInfoStyled>
  );
};
