export enum NotificationManagementEvent {
  clickLinkSettingsCookie = 'click_link_settings_cookie',
  bannerCookieAutoOpen = 'banner_cookie_auto_open',
  clickLinkDetailsCookie = 'click_link_details_cookie',
  clickAcceptAllSettings = 'click_accept_all_settings',
  clickAcceptTechnicalSettings = 'click_accept_technical_settings',
  clickSaveAllSettings = 'click_save_all_settings',
  clickSaveTechnicalSettings = 'click_save_technical_settings',
  clickSaveTechnicalPromoSettings = 'click_save_technical_promo_settings',
  clickSaveTechnicalAnalyticSettings = 'click_save_technical_analytic_settings',
}
