import styled from 'styled-components';
import { fontSettings } from '../../styles';

export const BackLinkWithWarningStyled = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 24px;
  max-width: max-content;

  svg {
    margin-right: 12px;
  }
`;
