import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconSuccessSentComponent = ({
  width = 24,
  height = 24,
  fillColor = '#2E8200',
  strokeColor = '#fff',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.2 4.80005C3.432 4.80005 0.335438 7.70405 0.0234375 11.4L24 24.3469L47.9766 11.4C47.6646 7.70405 44.568 4.80005 40.8 4.80005H7.2ZM0 15.4688V36C0 39.972 3.228 43.2001 7.2 43.2001H40.8C44.772 43.2001 48 39.972 48 36V15.4688L24.8531 27.9844C24.5891 28.1284 24.3 28.2001 24 28.2001C23.7 28.2001 23.4109 28.1284 23.1469 27.9844L0 15.4688Z"
        fill={strokeColor}
      />
    </svg>
  );
};
