import { ApiCourseModel, CourseModel, MetaPaginationModel } from '@lerna-core';

export type ApiSimilarCoursesModel = {
  data: ApiCourseModel[];
};

export type SimilarCoursesModel = {
  data: CourseModel[];
  pagination: MetaPaginationModel;
};

export enum AdditionalCoursesStateEnum {
  init = 'init',
  pending = 'pending',
  success = 'success',
  error = 'error',
}
export type AdditionalCoursesStateModel = {
  [key: number]: AdditionalCoursesStateEnum;
};
