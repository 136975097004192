import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const ItemTitleContainerStyled = styled.div`
  ${(props): string => fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}
  font-weight: 700;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};

  ${breakpoint(
    'mediumDown',
    css`
      font-weight: 500;
    `
  )}
`;
