import { TranslationModel } from '@lerna-core';

export const getTextMaxLengthValidationMessage = (
  length: number,
  translations: TranslationModel
): string =>
  `${
    translations?.max_length_is
  } ${length} ${translations?.symbols_many?.toLowerCase()}`;

export const getTextMinLengthValidationMessage = (
  length: number,
  translations: TranslationModel
): string =>
  `${
    translations?.min_length_is
  } ${length} ${translations?.symbols_many?.toLowerCase()}`;
