import { ParsedUrlQuery } from 'querystring';
import {
  AppService,
  cookieService,
  logger,
  publicConfig,
  urlService,
} from '../../services';
import { refreshTokenClientProvider } from './authorizationRefreshToken/refreshToken.clientProvider';
import { AuthorizationPaths } from './authorizationsPaths.enum';

const ATTEMPT_INITIAL_VALUE = 1;

export class AuthorizationService {
  protected ACCESS_TOKEN_TIMESTAMP_KEY = 'token_expires_at';
  protected ACCESS_TOKEN = 'access_token';
  protected accessTokenPattern = new RegExp(
    `${this.ACCESS_TOKEN}=[^;]+?;`,
    'gi'
  );
  private readonly REFRESH_TIME = 60 * 1000;
  private shouldRefreshToken = false;
  private isOnLine = true;
  private isTabActive = true;
  private isRefreshing = false;
  private attempt = ATTEMPT_INITIAL_VALUE;
  private refreshTimerId: NodeJS.Timeout | null = null;
  public static instance: AuthorizationService;

  public static getInstance = (): AuthorizationService => {
    if (!AuthorizationService.instance) {
      AuthorizationService.instance = new AuthorizationService();
    }

    return AuthorizationService.instance;
  };

  private get canRequestRefreshToken(): boolean {
    return (
      this.isTabActive &&
      this.isOnLine &&
      this.shouldRefreshToken &&
      !this.isRefreshing
    );
  }

  private initEvents(): void {
    window.addEventListener('offline', () => {
      this.isOnLine = navigator.onLine;
    });
    window.addEventListener('online', () => {
      this.isOnLine = navigator.onLine;
      if (this.canRequestRefreshToken) {
        this.refreshToken();
      }
    });
    window.addEventListener('visibilitychange', () => {
      this.isTabActive = !document.hidden;
      if (this.canRequestRefreshToken) {
        this.refreshToken();
      }
    });
  }

  public init(): void {
    if (!AppService.isClientSide) {
      throw new Error('This service only for Client Side');
    }

    this.initEvents();

    if (this.isAuthed()) {
      if (this.isTokenUpToDate()) {
        this.setRefreshProcedure();
      } else {
        this.refreshToken();
      }
    }
  }

  protected getRedirectUrl(): string {
    return window.location.href;
  }

  public getLogoutLink(): string {
    const redirectUrl = encodeURIComponent(this.getRedirectUrl());

    return `${publicConfig.AUTH_URL}${AuthorizationPaths.logout}/?return_to=${redirectUrl}`;
  }

  public async login(): Promise<void> {
    if (AppService.isClientSide) {
      if (!this.isAuthed()) {
        window.location.href = this.getLoginLink();
      }
    } else {
      throw new Error('Login can be started only from Client Side');
    }
  }

  public getLoginLink(isGoogle?: boolean, companyUuid?: string): string {
    const loginLink = `${publicConfig?.AUTH_URL}${AuthorizationPaths.login}`;
    const redirectUrl = this.getRedirectUrl();

    const loginQueryParams: ParsedUrlQuery = {
      redirectUrl,
      kc_idp_hint: isGoogle ? 'google' : '',
      company_id: companyUuid,
    };

    return urlService.setQueryParamsToLink(loginLink, loginQueryParams);
  }

  public getRegisterLoginLink(redirect: string): string {
    const registerLoginLink = `${publicConfig?.AUTH_URL}${AuthorizationPaths.registerLogin}/`;

    return urlService.setQueryParamsToLink(registerLoginLink, {
      redirect,
    });
  }

  public isAuthed(cookie?: string): boolean {
    return !!this.accessTokenTimestamp(cookie);
  }

  private setRefreshProcedure(): void {
    const nextRefreshTime =
      this.accessTokenTimestamp() - Date.now() - this.REFRESH_TIME;

    if (this.refreshTimerId !== null) {
      clearTimeout(this.refreshTimerId);
    }

    this.refreshTimerId = setTimeout(() => {
      if (this.isTabActive && this.isOnLine) {
        this.refreshToken();

        return;
      }

      this.shouldRefreshToken = true;
    }, nextRefreshTime);
  }

  protected refreshToken(): void {
    this.isRefreshing = true;
    refreshTokenClientProvider()
      .then(async () => {
        this.attempt = ATTEMPT_INITIAL_VALUE;
        this.setRefreshProcedure();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          logger.error('[REFRESH-TOKEN] [ERROR]', error.response.data);
          window.location.href = this.getLogoutLink();

          return;
        }
        if (error.response && error.response.status === 503) {
          logger.error('[REFRESH-TOKEN] [ERROR]', error.response.data);
          window.location.href = this.getLoginLink();

          return;
        }
        logger.error(
          `[REFRESH-TOKEN] [ERROR] ${error.message}`,
          error.response?.data
        );

        this.retryRequestRefreshToken();
      })
      .finally(() => {
        this.isRefreshing = false;
        this.shouldRefreshToken = false;
      });
  }

  protected retryRequestRefreshToken() {
    setTimeout(() => {
      logger.error(`[ERROR] Retrying attempt #${this.attempt}`);
      if (this.isTabActive && this.isOnLine) {
        this.refreshToken();
        this.attempt++;

        return;
      }

      this.shouldRefreshToken = true;
    }, 100 * this.attempt);
  }

  protected accessTokenTimestamp(cookie?: string): number {
    return parseInt(
      cookieService.getCookie(this.ACCESS_TOKEN_TIMESTAMP_KEY, cookie, false) ||
        '0'
    );
  }

  public isTokenUpToDate(cookie?: string): boolean {
    return Date.now() + this.REFRESH_TIME < this.accessTokenTimestamp(cookie);
  }
}

export const authorizationService = AuthorizationService.getInstance();
