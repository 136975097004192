import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCheckComponent = ({
  width = 10,
  height = 7.5,
  strokeWidth = '2',
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3L4 6L9 1"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
