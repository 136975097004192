import React, { ReactElement } from 'react';
import {
  CourseOptionsSlug,
  IconEducationComponent,
  IconHourglassComponent,
  IconMonitorWithManComponent,
  Nullable,
} from '@lerna-core';
import {
  PropsItemContainerStyled,
  PropsItemIconStyled,
  PropsItemTitleStyled,
  PropsItemValueStyled,
} from './propsItem.styled';
import { PropsItemContainerProps } from './propsItem.model';

const ICON_SIZE = 24;

const getIcon = (slug: string): Nullable<ReactElement> => {
  const iconSizeProps = {
    width: ICON_SIZE,
    height: ICON_SIZE,
  };

  switch (slug) {
    case CourseOptionsSlug.difficulty: {
      return <IconEducationComponent {...iconSizeProps} />;
    }
    case CourseOptionsSlug.duration: {
      return <IconHourglassComponent {...iconSizeProps} />;
    }
    case CourseOptionsSlug.type: {
      return <IconMonitorWithManComponent {...iconSizeProps} />;
    }
    default: {
      return null;
    }
  }
};

export const PropsItemContainer = ({
  prop,
  count,
}: PropsItemContainerProps): ReactElement => {
  return (
    <PropsItemContainerStyled count={count}>
      <PropsItemIconStyled>{getIcon(prop.slug)}</PropsItemIconStyled>
      <PropsItemTitleStyled>{prop.title}</PropsItemTitleStyled>
      <PropsItemValueStyled>{prop.value}</PropsItemValueStyled>
    </PropsItemContainerStyled>
  );
};
