import { CookiePermissionsModel } from '../../features';
import { getConsentModeData } from './gtm.helper';
import { Nullable } from '../../models';

export const gtmScriptContent = (id: string): string => {
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-${id}');`;
};

export const gtmConsentScriptContent = (
  cookiePermissions: Nullable<CookiePermissionsModel>
): string => `(function() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('consent', 'default', ${JSON.stringify(
      getConsentModeData(cookiePermissions)
    )});
})()`;
