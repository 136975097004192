import { StaticsClient } from './statics.client';
import { StaticsPageModel, StaticsRequestParamsModel } from './statics.model';
import { RequestHeader } from '../../services/request';

export async function StaticsClientProvider(
  params: StaticsRequestParamsModel,
  headers: RequestHeader
): Promise<StaticsPageModel> {
  const response = await new StaticsClient(params, headers).get();

  return response as unknown as Promise<StaticsPageModel>;
}
