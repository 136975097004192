import { GetServerSidePropsContext, NextPageContext } from 'next';
import { utmMiddleware } from '@lerna-core';

export const basicPageMiddleware = (
  context: NextPageContext | GetServerSidePropsContext
): NextPageContext | GetServerSidePropsContext => {
  utmMiddleware(context);

  return context;
};
