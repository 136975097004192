import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconArrowLongComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5L5 12M12 5L19 12M12 5L12 19"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
