import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  getCustomersTableColumns,
  getCustomersTableRows,
  getLeftRowsData,
  getRightRowsData,
  TabCustomersTransferComponents,
} from './customersTransfer.meta';
import { CustomersTransferProps } from './customersTransfer.model';
import {
  CustomersTransferPendingStyled,
  CustomersTransferStyled,
} from './customersTransfer.styled';
import {
  SearchAllocatedContainer,
  SearchUnallocatedContainer,
} from './transferSearch';
import { LocationType, TransferContainer } from '../../transfer';
import { getCustomersTransferSelector } from './customersTransfer.selectors';
import { CustomersTransferContextProvider } from './customersTransfer.context';
import { SpinnerContainer } from '../../spinner';
import { useTheme } from '../../../hooks';
import {
  useCustomersTransfer,
  useCustomersTransferSearch,
} from './customersTransfer.hooks';

/*
  1. Родитель должен иметь 'position: relative', что бы корректно отображать лоадер.
  2. Если провайдеру необходимы параметры подефолту использовать setUnallocatedFilters/setAllocatedFilters или обертку
    Example:
        const customersProvider: CustomersTransferProvider = async (
          params,
        ) => {
          return await customersTableClientProvider(
            {
              ...params,
              companiesFilterIds: [query.companyUUID as string],
            },
          );
        };
 */

/**
 * @param translations
 * @param customersProvider
 * @param customersAllProvider
 * @param accessors - принимает массив аксессоров, name - дефолтный аксессор, прокидывать его не надо
 * @param dispatch - передавать dispatch из useAppDispatch
 * @param isRadioButton
 * @param totalCustomers - значение по-умолчанию allocated.totalCustomers + unallocated.totalCustomers
 * @param withDisabledRows - allocated строки будут задизейблены, если они лежат в allocated массиве,
 *   added строки не будут
 * @param withDisableLeftRows - unallocated строки будут задизейблены
 * @param getCustomRows - функция которая будет использоваться для получения строк, вместо дефолтной
 * @param subscriptionId - передавать для трансферов редактирования обьектов (для трансферов создания не надо)
 * @param benefitId - передавать для трансферов редактирования обьектов (для трансферов создания не надо)
 * @param libraryId - передавать для трансферов редактирования обьектов (для трансферов создания не надо)
 * @param tariffId - передавать для трансферов редактирования обьектов (для трансферов создания не надо)
 * @param allocatedTopBlock - передавать для добавления блока над самой таблицей allocated
 * @param unallocatedTopBlock - передавать для добавления блока над самой таблицей unallocated
 * @param isUnlimited - передавать для правильного отображения текста в transferTitle при безлимите
 * @param tariffs - передавать если используется колонка тарифов
 */

export const CustomersTransferContainer = ({
  translations,
  customersProvider,
  customersAllProvider,
  accessors,
  dispatch,
  isRadioButton = false,
  withDisabledRows = false,
  withDisableLeftRows = false,
  isUnlimited = false,
  getCustomRows,
  totalCustomers,
  subscriptionId = '',
  benefitId = '',
  libraryId = '',
  tariffId = '',
  allocatedTopBlock = null,
  unallocatedTopBlock = null,
  tariffs,
}: CustomersTransferProps): ReactElement => {
  const theme = useTheme();
  const id = subscriptionId || benefitId || libraryId || tariffId;
  const {
    unallocated,
    allocated,
    added: addedCustomers,
  } = useSelector(getCustomersTransferSelector);
  const { handleCheckedLeftCallback, handleCheckedRightCallback } =
    useCustomersTransfer(
      dispatch,
      customersProvider,
      id,
      subscriptionId,
      benefitId,
      libraryId,
      tariffId
    );
  const {
    handleUnallocatedSearchCancel,
    handleAllocatedSearchCancel,
    isUnallocatedSearchOpen,
    isAllocatedSearchOpen,
    changeUnallocatedSearch,
    changeAllocatedSearch,
  } = useCustomersTransferSearch(
    dispatch,
    customersProvider,
    id,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId
  );

  const isPending = unallocated.isPending || allocated.isPending;
  const disabledTransferControl = unallocated.isLoading || allocated.isLoading;

  const rightRowsData = getRightRowsData(
    allocated.data,
    addedCustomers,
    withDisabledRows
  );

  const leftRowsData = getLeftRowsData(unallocated.data, withDisableLeftRows);

  const contextProviderProps = {
    customersProvider,
    customersAllProvider,
    accessors,
    isRadioButton,
    dispatch,
    withDisabledRows,
    subscriptionId,
    benefitId,
    libraryId,
    totalCustomers,
    isUnlimited,
    tariffId,
    tariffs,
  };

  const getRows =
    getCustomRows && typeof getCustomRows === 'function'
      ? getCustomRows
      : getCustomersTableRows;

  return (
    <CustomersTransferStyled isPending={isPending}>
      {isPending && (
        <CustomersTransferPendingStyled>
          <SpinnerContainer strokeWidth="6" strokeColor={theme.COLOR.WHITE} />
        </CustomersTransferPendingStyled>
      )}
      <CustomersTransferContextProvider {...contextProviderProps}>
        <TransferContainer
          disabledLeft={disabledTransferControl}
          disabledRight={disabledTransferControl}
          translations={translations}
          leftRows={getRows(leftRowsData)}
          leftColumns={getCustomersTableColumns(
            translations,
            LocationType.unallocated,
            changeUnallocatedSearch,
            accessors
          )}
          rightRows={getRows(rightRowsData)}
          rightColumns={getCustomersTableColumns(
            translations,
            LocationType.allocated,
            changeAllocatedSearch,
            accessors
          )}
          Components={TabCustomersTransferComponents}
          handleCheckedLeftCallback={handleCheckedLeftCallback}
          handleCheckedRightCallback={handleCheckedRightCallback}
          leftHeaderComponent={
            isUnallocatedSearchOpen && (
              <SearchUnallocatedContainer
                translations={translations}
                handleSearchCancel={handleUnallocatedSearchCancel}
              />
            )
          }
          rightHeaderComponent={
            isAllocatedSearchOpen && (
              <SearchAllocatedContainer
                translations={translations}
                handleSearchCancel={handleAllocatedSearchCancel}
              />
            )
          }
          allocatedTopBlock={allocatedTopBlock}
          unallocatedTopBlock={unallocatedTopBlock}
        />
      </CustomersTransferContextProvider>
    </CustomersTransferStyled>
  );
};
