import React, { ReactElement } from 'react';
import {
  PricesInstallmentStyled,
  TitleStyled,
  ValueStyled,
  ValueWithDiscountStyled,
} from './pricesInstallment.styled';
import { PricesInstallmentContainerProps } from './pricesInstallment.model';
import { Nullable } from '@lerna-core';

export const PricesInstallmentContainer = ({
  installment,
  translations,
  hasDiscounts,
}: PricesInstallmentContainerProps): Nullable<ReactElement> => {
  if (!installment) return null;

  return (
    <PricesInstallmentStyled>
      <TitleStyled>
        {translations.installment_price_label} {installment.duration}
      </TitleStyled>
      {hasDiscounts && <ValueStyled>{installment.price}</ValueStyled>}
      <ValueWithDiscountStyled>
        {installment.priceWithTotalDiscount}
      </ValueWithDiscountStyled>
    </PricesInstallmentStyled>
  );
};
