import { breakpoint, ButtonStyled, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const ErrorPageContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  min-height: 100vh;
`;

export const ErrorPageHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  height: 86px;
  width: 100%;

  ${breakpoint(
    'smallDown',
    css`
      height: 64px;
      justify-content: center;
    `
  )};
`;

export const ErrorPageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-top: 148px;

  svg {
    cursor: pointer;
  }

  ${ButtonStyled} {
    margin-top: 24px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      padding-top: 88px;
    `
  )}
`;

export const TitleStyled = styled.h1`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_48, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 700;
  line-height: 56px;

  margin-bottom: 28px;

  ${breakpoint(
    'smallDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)};
      line-height: 40px;
    `
  )}
`;

export const TextStyled = styled.span`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 16px;

  font-weight: 500;
`;
