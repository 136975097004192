import styled from 'styled-components';
import { CheckboxContainerStyled, fontSettings } from '@lerna-core';

export const FilterOptionStyled = styled.div`
  margin-bottom: 12px;
  box-sizing: border-box;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  ${CheckboxContainerStyled} {
    gap: 8px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    line-height: 24px;

    svg {
      min-width: 24px;
    }
  }
`;
