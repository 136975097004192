import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PricesTotalDiscountStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.RED};
  background-color: ${({ theme }): string => theme.COLOR.LIGHT_ROSE};
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 100px;
  position: absolute;
  right: 0;
  top: 0;
  right: 16px;
  top: -16px;
  display: flex;
  align-items: center;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
      padding: 4px 8px;
      right: 18px;
      top: -12px;
    `
  )}
`;

export const PricesTotalDiscountIconStyled = styled.div`
  display: flex;
  margin-right: 4px;
`;
