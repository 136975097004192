import {
  FiltersModel,
  FiltersOption,
  Nullable,
  TranslationModel,
} from '@lerna-core';

export enum FiltersResetTypes {
  desktop = 'desktop',
  mobile = 'mobile',
}

export type ResetItemFilterOption = {
  filter: FiltersModel;
  currentOption: FiltersOption;
  itemValue: string;
  itemSlug: string;
};

export type FiltersResetContainerProps = {
  translations: TranslationModel;
  type?: keyof typeof FiltersResetTypes;
  customFilterColor?: Nullable<string>;
};

export type FiltersResetStyledProps = {
  isMobileType: boolean;
};

export type UseFiltersResetHookModel = {
  filtersWithSelectedOptions: FiltersModel[];
  resetItemsToRender: ResetItemFilterOption[];
  handleClearAllFilters: () => void;
  toggleCollapsed: () => void;
  showResetAllItem: boolean;
  showShowMoreItem: boolean;
  isMobileType: boolean;
};
