import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';
import { menuAdapter } from './menu.adapter';
import { MenuApiSlugs } from './menu.enum';
import { MenuApiModel, MenuModel } from './menu.model';

export class MenuClient extends RequestClient<null, MenuApiModel, MenuModel> {
  protected path = 'user-api/v1/navigations';
  protected requestParameters: null;
  protected adapter = menuAdapter;

  constructor(type: MenuApiSlugs, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.path = `${this.path}/${type}`;
    this.requestParameters = null;
  }
}
