import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonColor,
  ButtonContainer,
  getCurrentPopupFromOpen,
  handleClosePopup,
  IconArrowLongComponent,
  Nullable,
  PopupContainer,
  PopupsEnum,
  useTheme,
} from '@lerna-core';
import { ReferralProgramPopupContainerProps } from './referralProgramPopup.model';
import {
  ReferralProgramPopupDescriptionStyled,
  ReferralProgramPopupImgStyled,
  ReferralProgramPopupImgWrapperStyled,
  ReferralProgramPopupStyled,
  ReferralProgramPopupTextWrapperStyled,
  ReferralProgramPopupTitleStyled,
} from './referralProgramPopup.styled';
import { getReferralPopupStyleSettings } from './referralProgramPopup.helper';
import { PopupVendorsContainer } from './popupVendors';

export const ReferralProgramPopupContainer = ({
  isMobile = false,
  translations,
}: ReferralProgramPopupContainerProps): Nullable<ReactElement> => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.referralProgramPopup)
  );

  const handleClose = (): void => {
    dispatch(handleClosePopup(PopupsEnum.referralProgramPopup));
  };

  if (!currentPopup) return null;

  return (
    <PopupContainer
      isOpened={!!currentPopup}
      handleClose={handleClose}
      handleDestroy={handleClose}
      isMobile={isMobile}
      width={'920px'}
      styleSettings={getReferralPopupStyleSettings(isMobile)}
      closeOutside={true}
      fullHeightMobile={false}
    >
      <ReferralProgramPopupStyled>
        <ReferralProgramPopupTextWrapperStyled>
          <ReferralProgramPopupTitleStyled>
            {translations?.referral_program_title}{' '}
            <span>{translations?.referral_program_percentage}</span>
          </ReferralProgramPopupTitleStyled>
          <ReferralProgramPopupDescriptionStyled>
            {translations?.referral_program_description}
          </ReferralProgramPopupDescriptionStyled>
          <ButtonContainer
            color={ButtonColor.purple}
            title={translations?.select_course}
            onClick={handleClose}
          >
            <IconArrowLongComponent
              width={24}
              height={24}
              strokeColor={theme.COLOR.WHITE}
            />
          </ButtonContainer>
        </ReferralProgramPopupTextWrapperStyled>
        <ReferralProgramPopupImgWrapperStyled>
          <ReferralProgramPopupImgStyled
            src="/img/referral_program/girl_referral_program.png"
            srcSet="/img/referral_program/girl_referral_program_2x.png 2x, /img/referral_program/girl_referral_program_3x.png 3x"
            width={302}
            height={302}
            alt="toy girl holding a toy heart"
          />
        </ReferralProgramPopupImgWrapperStyled>
      </ReferralProgramPopupStyled>
      <PopupVendorsContainer isMobile={isMobile} />
    </PopupContainer>
  );
};
