import { AxiosResponse } from 'axios';

export enum CheckEmailStatusCodes {
  EMAIL_EXIST = 200,
  EMAIL_NOT_FOUND = 404,
  INVALID_EMAIL = 422,
}

export type CheckEmailResponse = AxiosResponse;
export type CheckEmailStatusCode =
  | CheckEmailStatusCodes.EMAIL_EXIST
  | CheckEmailStatusCodes.EMAIL_NOT_FOUND
  | CheckEmailStatusCodes.INVALID_EMAIL;
