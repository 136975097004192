import { breakpoint, fontSettings, lineClamping } from '@lerna-core';
import styled, { css } from 'styled-components';
import { PropsItemContainerStyledProps } from './propsItem.model';

export const PropsItemContainerStyled = styled.div<PropsItemContainerStyledProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: ${({ count }): number => (count > 2 ? 64 : 80)}px;
  }

  ${({ count }): ReturnType<typeof css> | string => {
    return breakpoint(
      'mediumDown',
      css`
        &:not(:last-child) {
          margin-right: ${count > 2 ? 16 : 40}px;
        }
      `
    );
  }}

  ${breakpoint(
    'smallDown',
    css`
      padding-left: 36px;
      position: relative;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    `
  )}
`;

export const PropsItemTitleStyled = styled.div`
  margin-bottom: 4px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  ${lineClamping(1)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  justify-self: flex-end;

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
    `
  )}
`;

export const PropsItemValueStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};
  ${lineClamping(1)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  justify-self: flex-end;
  font-weight: 600;

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
      max-height: ${({ theme }): number => theme.FONT.LINE_HEIGHT_20}px;
      font-weight: 500;
    `
  )}
`;

export const PropsItemIconStyled = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 12px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 8px;
    `
  )}

  ${breakpoint(
    'smallDown',
    css`
      position: absolute;
      left: 0;
      top: 0;
      boargin-bottom: 0;
    `
  )}
`;
