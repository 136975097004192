import React, { ReactElement } from 'react';
import {
  AdvantagesTextStyled,
  SubtitleStyled,
  TitleStyled,
} from './advantagesText.styled';
import { AdvantagesTitleContainerProps } from './advantagesText.model';

export const AdvantagesTextContainer = ({
  title,
  titleColor,
  subtitle,
  withBanners,
}: AdvantagesTitleContainerProps): ReactElement => {
  const advantagesTitle = !!title && (
    <TitleStyled
      withSubtitle={!!subtitle}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
  const advantagesSubtitle = !!subtitle && (
    <SubtitleStyled dangerouslySetInnerHTML={{ __html: subtitle }} />
  );

  return (
    <AdvantagesTextStyled withBanners={withBanners} titleColor={titleColor}>
      {advantagesTitle}
      {advantagesSubtitle}
    </AdvantagesTextStyled>
  );
};
