export enum TooltipArrowPlacements {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export type TooltipArrowPlacement = {
  vertical: TooltipArrowVerticalPlacement;
  horizontal: TooltipArrowHorizontalPlacement;
};

export type TooltipArrowVerticalPlacement =
  | TooltipArrowPlacements.top
  | TooltipArrowPlacements.bottom;
export type TooltipArrowHorizontalPlacement =
  | TooltipArrowPlacements.left
  | TooltipArrowPlacements.right;

export type TooltipArrowIconProps = {
  verticalPlacement: TooltipArrowVerticalPlacement;
  horizontalPlacement: TooltipArrowHorizontalPlacement;
};
