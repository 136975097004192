import React, {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import RussianLocalization from 'react-phone-input-2/lang/ru.json';
import 'react-phone-input-2/lib/material.css';
import { Nullable } from '../../../models';
import { publicConfig } from '../../../services';
import { InputColors } from '../../../styles';
import { normalizePhone } from '../../form';
import {
  FIRST_TWO_CHARS,
  INPUT_PHONE__DEFAULT_BORDER_RADIUS,
  INPUT_PHONE_DEFAULT_HEIGHT,
  PHONE_MASKS,
  PREFERRED_PHONE_COUNTRIES,
} from './phoneInput.constants';
import {
  mapCountriesFromRegion,
  setPhoneLocalStorageValue,
} from './phoneInput.helpers';
import { PhoneInputContainerProps } from './phoneInput.model';
import { PhoneInputStyled } from './phoneInput.styled';

export const PhoneInputContainer = ({
  value = '',
  name = '',
  placeholder = '',
  onChange,
  onBlur,
  autoFocus = false,
  required = false,
  disabled = false,
  bordered,
  onlyCountries = [],
  country = mapCountriesFromRegion(publicConfig?.REGION),
  preferredCountries = PREFERRED_PHONE_COUNTRIES,
  color = InputColors.white,
  height = INPUT_PHONE_DEFAULT_HEIGHT,
  borderRadius = INPUT_PHONE__DEFAULT_BORDER_RADIUS,
  hasError,
}: PhoneInputContainerProps): ReactElement => {
  const phoneInputStyledProps = useMemo(
    () => ({
      disabled,
      color,
      bordered,
      height,
      borderRadius,
      hasError,
    }),
    [disabled, color, bordered, height, borderRadius, hasError]
  );
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const [inputValue, setInputValue] = useState<string>(value);
  const [countryCode, setCountryCode] = useState<string>('');

  const handleChangeValue = (
    value: string,
    country: CountryData,
    e: ChangeEvent,
    formattedValue: string
  ): void => {
    setInputValue(normalizePhone(value));
    setCountryCode(country.countryCode);
    onChange(normalizePhone(value), e);
    handleCursorPosition(formattedValue);
  };

  const handleBlur = (e: FocusEvent, country: CountryData): void => {
    onBlur && onBlur(normalizePhone(inputValue), e);
    setCountryCode(country.countryCode);
  };

  const handleCursorPosition = (formattedValue: string): void => {
    formattedValue.length === FIRST_TWO_CHARS
      ? inputRef?.current?.setSelectionRange(
          formattedValue.length,
          formattedValue.length
        )
      : null;
  };

  useEffect(() => {
    setPhoneLocalStorageValue(countryCode);
  }, [countryCode]);

  return (
    <PhoneInputStyled {...phoneInputStyledProps}>
      <PhoneInput
        value={inputValue}
        onChange={handleChangeValue}
        onBlur={handleBlur}
        placeholder={placeholder}
        inputProps={{
          name,
          required,
          autoFocus,
          ref: inputRef,
        }}
        onlyCountries={onlyCountries}
        preferredCountries={preferredCountries}
        specialLabel=""
        masks={PHONE_MASKS}
        country={country}
        disableCountryGuess
        countryCodeEditable={false}
        localization={RussianLocalization}
      />
    </PhoneInputStyled>
  );
};
