import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const CourseTutorsContainerStyled = styled.div``;

export const CourseTutorsWrapStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -12px;

  ${breakpoint(
    'mediumDown',
    css`
      margin: 0 -4px;
    `
  )}
`;
