import styled from 'styled-components';
import { fontSettings } from '../../styles/mixins';
import { PaperStyledProps } from './paper.model';

export const PaperComponentStyled = styled.div<PaperStyledProps>`
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  border-radius: 16px;
  padding: 20px;
  background: ${({ theme }): string => theme.COLOR.WHITE};

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const PaperTitleComponentStyled = styled.div<PaperStyledProps>`
  display: flex;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 600;

  button {
    margin-left: 12px;
    margin-top: -2px;
  }
`;

export const PaperSubTitleComponentStyled = styled.div<PaperStyledProps>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  margin-top: 8px;
`;
