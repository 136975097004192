import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTheme } from '../../hooks';
import { InputColor } from '../../styles';
import { IconArrowComponent } from '../icons';
import { SelectOption, SelectOptionsProps } from './select.model';
import { SelectOptionsContainer } from './select.options.container';
import {
  SelectStyled,
  SelectStyledArrow,
  SelectStyledButton,
  SelectStyledText,
} from './select.styled';
import { SelectDropdownContainer } from './selectDropdown/selectDropdown.container';
import { Nullable } from '../../models';
import { FloatingPlaceholderStyled } from '../form';

type SelectProps = {
  options: SelectOption[];
  bordered?: boolean;
  disabled?: boolean;
  placeholder?: string | ReactElement;
  onChange: (key: string) => void;
  onBlur: (key: string) => void;
  color?: InputColor;
  defaultOption: SelectOption;
  className?: string;
  width?: number;
  optionComponent?: (props: SelectOptionsProps) => ReactElement;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
};

const DEFAULT_OPTION_KEY = 'default';

export const defaultOptionState = {
  key: DEFAULT_OPTION_KEY,
  value: '',
};

export const SelectContainer = ({
  options,
  placeholder,
  bordered,
  onBlur,
  onChange,
  disabled,
  color,
  defaultOption,
  className,
  width,
  optionComponent,
  floatingPlaceholder,
  hasError,
}: SelectProps): ReactElement => {
  const theme = useTheme();
  const [isOpened, setIsOpened] = useState(false);
  const [activeOption, setActiveOption] = useState(
    defaultOption || defaultOptionState
  );
  const selectRef = useRef<Nullable<HTMLDivElement>>(null);
  const selectValue = activeOption.value ? activeOption.value : placeholder;

  const isDefaultValue = useMemo(
    () => activeOption.key === DEFAULT_OPTION_KEY,
    [activeOption.key]
  );

  const OptionComponent = optionComponent
    ? optionComponent
    : SelectOptionsContainer;

  const handleToggleDropdown = (close?: boolean | undefined): void => {
    setIsOpened(close ? false : !isOpened);
  };

  const handleSelect = (option: SelectOption): void => {
    if (option.key !== DEFAULT_OPTION_KEY) {
      onChange(option.key);
      onBlur(option.key);
      setActiveOption(option);
      setIsOpened(false);
    }
  };

  useEffect(() => {
    if (defaultOption && defaultOption.key !== activeOption.key) {
      setActiveOption(defaultOption);
    }
  }, [options]);

  const isActiveFloatingPlaceholder = !isDefaultValue;
  const activeText = !isActiveFloatingPlaceholder ? '' : selectValue;

  return (
    <SelectStyled
      className={className}
      color={color}
      bordered={bordered}
      disabled={disabled}
      ref={selectRef}
      hasError={hasError}
    >
      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          children={placeholder}
          active={isActiveFloatingPlaceholder}
        />
      ) : null}

      <SelectStyledButton
        onClick={(): void => {
          if (disabled) {
            return;
          }
          handleToggleDropdown();
        }}
      />
      <SelectStyledText isDefaultValue={!activeOption} children={activeText} />
      <SelectStyledArrow isOpened={isOpened}>
        <IconArrowComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
      </SelectStyledArrow>
      {isOpened ? (
        <SelectDropdownContainer
          ref={selectRef}
          width={width}
          handleOutsideClick={handleToggleDropdown.bind(this, false)}
        >
          <OptionComponent
            options={options}
            activeOption={activeOption}
            handleClick={handleSelect}
          />
        </SelectDropdownContainer>
      ) : null}
    </SelectStyled>
  );
};
