import React, { ReactElement } from 'react';

import { useTheme } from '../../../hooks';
import { IconChevronComponent, IconNextComponent } from '../../icons';
import {
  SliderControlContainerProps,
  SliderControlTypes,
} from './sliderControl.model';
import {
  SliderControlNextStyled,
  SliderControlPrevStyled,
} from './sliderControl.styled';
import { SliderControlIcon } from '../slider.styled';

export const SliderControlContainer = ({
  disabled,
  type,
  handleClick,
  isMobile,
  controlIcon,
  controlsInside,
  controlsStyle,
}: SliderControlContainerProps): ReactElement => {
  const theme = useTheme();

  const controlProps = {
    onClick: handleClick,
    disabled: disabled,
    type: type,
    isMobile: isMobile,
    isChevron: controlIcon === SliderControlIcon.chevron,
    controlsInside: controlsInside,
    controlsStyle: controlsStyle,
  };

  const Icon = (): ReactElement => {
    switch (controlIcon) {
      case SliderControlIcon.chevron:
        return <IconChevronComponent fillColor={theme.COLOR.TEXT_DARK_COLOR} />;
      default:
      case SliderControlIcon.arrow:
        return <IconNextComponent fillColor={theme.COLOR.TEXT_DARK_COLOR} />;
    }
  };

  switch (type) {
    case SliderControlTypes.prev:
      return (
        <SliderControlPrevStyled {...controlProps}>
          <Icon />
        </SliderControlPrevStyled>
      );

    case SliderControlTypes.next:
      return (
        <SliderControlNextStyled {...controlProps}>
          <Icon />
        </SliderControlNextStyled>
      );
  }
};
