import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconHomeComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M21 18.75v-6.6199c0-1.1487 0-1.723-.1481-2.25197a3.99759 3.99759 0 00-.636-1.30039c-.3266-.4416-.7799-.79422-1.6867-1.49946l-2.6-2.02222v-.00001c-1.4053-1.093-2.1079-1.63951-2.8838-1.84958a4.0001 4.0001 0 00-2.0908 0c-.7759.21007-1.47852.75658-2.88382 1.84959l-2.6 2.02222c-.90674.70524-1.36011 1.05786-1.68673 1.49946a4.0002 4.0002 0 00-.636 1.30039C3 10.4071 3 10.9814 3 12.1301V18.75c0 .6978 0 1.0467.08612 1.3306.1939.6392.69411 1.1394 1.33331 1.3333.2839.0861.63279.0861 1.33057.0861h.625c.11606 0 .1741 0 .22314-.0024 1.0274-.0505 1.84898-.8721 1.89945-1.8995.00241-.049.00241-.107.00241-.2231V16.5c0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5v2.875c0 .1161 0 .1741.0024.2231.0505 1.0274.8721 1.849 1.8995 1.8995.049.0024.107.0024.2231.0024h.625c.6978 0 1.0467 0 1.3306-.0861.6392-.1939 1.1394-.6941 1.3333-1.3333C21 19.7967 21 19.4478 21 18.75z"
    />
  </svg>
);
