import React, { ReactElement, useMemo } from 'react';
import {
  IconFireComponent,
  TagLabelColor,
  TagLabelContainer,
  TagLabelSize,
  useTheme,
} from '@lerna-core';
import { DiscountTotalContainerProps } from './discountTotal.model';
import {
  DiscountTotalContainerStyled,
  DiscountTotalIconStyled,
  DiscountTotalWrapperStyled,
} from './discountTotal.styled';
import { hasProgramDiscount } from '@features/common/course';
import { useShowLegalEntitiesPriceHook } from '@hooks';
import { negativeValue } from './discountTotal.helper';

export const DiscountTotalContainer = ({
  price,
  isB2B,
  megaDiscount,
}: DiscountTotalContainerProps): ReactElement => {
  const theme = useTheme();
  const programDiscount = hasProgramDiscount(price);
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const labelSize = theme.IS_MOBILE ? TagLabelSize.small : TagLabelSize.big;

  const value = useMemo(() => {
    if (showLegalEntitiesPrice) {
      return price?.priceCustomerB2B?.vendorWithPartnershipDiscount?.value;
    }

    if (isB2B) {
      return price?.priceCustomer?.totalDiscount?.value;
    }

    if (price.priceWithPromoCodeDiscount?.discount?.value) {
      return price.price.discount?.value;
    }

    return programDiscount
      ? price?.priceWithProgramDiscount?.textBlocks?.labelText
      : price?.price?.discount?.value;
  }, [price, showLegalEntitiesPrice]);

  const label = useMemo(() => {
    if (megaDiscount && !showLegalEntitiesPrice) {
      return (
        <DiscountTotalWrapperStyled>
          <DiscountTotalIconStyled>
            <IconFireComponent />
          </DiscountTotalIconStyled>
          {negativeValue(value)}
        </DiscountTotalWrapperStyled>
      );
    }

    return negativeValue(value);
  }, [price, showLegalEntitiesPrice]);

  return (
    <DiscountTotalContainerStyled>
      <TagLabelContainer
        size={labelSize}
        label={label}
        color={TagLabelColor.rose}
        borderRadius={100}
      />
    </DiscountTotalContainerStyled>
  );
};
