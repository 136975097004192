import {
  ButtonColor,
  ButtonContainer,
  IconLoopGradientComponent,
  PopupContainer,
} from '@lerna-core';
import React, { ReactElement } from 'react';
import { ItemPopupContainerProps } from './itemPopup.model';
import { ButtonStyled, ItemPopupStyled, TitleStyled } from './itemPopup.styled';
import { PopupListContainer } from './popupList';

export const ItemPopupContainer = ({
  isOpened,
  handleClose,
  popup,
  translations,
}: ItemPopupContainerProps): ReactElement => {
  return (
    <PopupContainer
      handleClose={handleClose}
      handleDestroy={handleClose}
      isOpened={isOpened}
      width={'600px'}
      hasHeader={false}
      title={popup.title}
    >
      <ItemPopupStyled>
        <IconLoopGradientComponent width={48} height={48} />
        <TitleStyled>{popup.title}</TitleStyled>
        <PopupListContainer list={popup.items} />
        <ButtonStyled onClick={handleClose}>
          <ButtonContainer
            title={translations.understand}
            borderRadius={100}
            color={ButtonColor.purple}
          />
        </ButtonStyled>
      </ItemPopupStyled>
    </PopupContainer>
  );
};
