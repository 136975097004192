import Link from 'next/link';
import React, { ReactElement } from 'react';

import { useTheme } from '../../../../../hooks';
import {
  SideMenuLinkContainerStyled,
  SideMenuLinkTitleStyled,
} from './sideMenuLink.styled';
import { MenuIconContainer } from '../../../menuIcon';
import { SideMenuLinkContainerProps } from './sideMenuLink.model';
import { getSimpleEvent, pushDataLayer } from '../../../../../services';
import {
  getProjectEventPosition,
  getProjectEventType,
  UsagePositions,
} from '../../../../../analytics';
import { getSideMenuLinkAnalyticsEvent } from './sideMenuLink.analytics';

export const SideMenuLinkContainer = ({
  link,
  title,
  icon,
  isActive,
  nested = false,
  useAnalytics,
}: SideMenuLinkContainerProps): ReactElement => {
  const handleSendAnalytics = () => {
    if (useAnalytics) {
      pushDataLayer(
        getSimpleEvent(
          getSideMenuLinkAnalyticsEvent(link),
          getProjectEventType(),
          getProjectEventPosition(UsagePositions.navigation)
        )
      );
    }
  };

  return (
    <Link onClick={handleSendAnalytics} shallow={true} href={link}>
      <SideMenuLinkContainerStyled isActive={isActive} nested={nested && !icon}>
        <MenuIconContainer icon={icon} theme={useTheme()} dark={isActive} />
        <SideMenuLinkTitleStyled children={title} isActive={isActive} />
      </SideMenuLinkContainerStyled>
    </Link>
  );
};
