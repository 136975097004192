import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconARComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 18V8a3 3 0 0 0-3-3h-1M5 18V8a3 3 0 0 1 3-3h1m0 0 .5 1.5h5L15 5M9 5h6"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12 19c-6.075 0-11-1.79-11-4 0-1.195 1.44-2.267 3.724-3M12 19l-3-3m3 3-3 2.5m7-2.773c4.097-.582 7-2.031 7-3.727 0-1.195-1.44-2.267-3.724-3"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
