import {
  ApiSubscriptionAvailabilityModel,
  SubscriptionAvailabilityModel,
} from './subscriptionAvailability.model';

export const subscriptionAvailabilityAdapter = (
  response: ApiSubscriptionAvailabilityModel
): SubscriptionAvailabilityModel => {
  const { data } = response;

  return {
    subscriptionAccess: {
      id: data.data.subscription_access.id,
      expiredAt: data.data.subscription_access.expired_at,
      coursesPerPeriod: data.data.subscription_access.courses_per_period,
      subscription: {
        id: data.data.subscription_access.subscription.id,
        title: data.data.subscription_access.subscription.title,
        createdAt: data.data.subscription_access.subscription.created_at,
      },
      withHomeworks: data.data.subscription_access.with_homeworks,
    },
    isClaimAvailable: data.data.is_claim_available,
    isCourseClaimed: data.data.is_course_claimed,
    coursesClaimed: data.data.courses_claimed,
    coursesAvailable: data.data.courses_available,
    claimPeriodEnd: data.data.claim_period_end,
    nextClaimPeriodStart: data.data.next_claim_period_start,
  };
};
