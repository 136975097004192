import styled from 'styled-components';

export const TableEndlessLoaderStyled = styled.tr`
  width: 100%;
  position: relative;
  margin: 40px 0 30px;
  background: inherit;
  display: flex;
  justify-content: center;
`;

export const TableEndlessLoaderWrapperStyled = styled.td``;
