import { publicConfig } from '../../../../../../services';
import { RequestClient } from '../../../../../../services/request/request.service';
import { UserPhotoResponseModel } from '../formUserPhoto';
import { getUserPhotoAdapter } from './getUserPhoto.adapter';

export type GetUserPhotoParams = {
  entity: string;
  filename: string;
};

export type GetUserPhotoApiModel = {
  data: {
    response: UserPhotoResponseModel;
  };
};

export class GetUserPhotoClient extends RequestClient<
  GetUserPhotoParams,
  GetUserPhotoApiModel,
  UserPhotoResponseModel
> {
  protected path = 'ms-uploader-api/v1/image/store';
  protected requestParameters: GetUserPhotoParams;
  protected adapter = getUserPhotoAdapter;

  constructor(params: GetUserPhotoParams) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = params;
  }
}
