import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { SearchAllocatedContainerProps } from './searchAllocated.model';
import {
  requestTransferCustomersAllocated,
  setTransferCustomersAllocatedFilters,
} from '../../customersTransfer.actions';
import { TableSearchContainer } from '../../../../table';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import {
  getCustomersTransferAllocatedFiltersSelector,
  getCustomersTransferSelector,
} from '../../customersTransfer.selectors';
import { useSearch } from '../../../../../hooks';

export const SearchAllocatedContainer = ({
  translations,
  handleSearchCancel,
}: SearchAllocatedContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
  } = useCustomersTransferContext();
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCustomersTransferSelector);
  const { search: initialSearch, searchTerm } = useSelector(
    getCustomersTransferAllocatedFiltersSelector
  );

  const onSearch = (search: string): void => {
    dispatch(setTransferCustomersAllocatedFilters({ search }));
    dispatch(
      requestTransferCustomersAllocated(customersProvider, {
        ...filters,
        id,
        added,
        removed,
        searchTerm,
        search,
        relatedWithSubscriptionId: subscriptionId,
        relatedWithBenefitId: benefitId,
        relatedWithLibraryId: libraryId,
        relatedWithTariffId: tariffId,
      })
    );
  };

  const { search, setSearch } = useSearch({ onSearch, initialSearch });

  return (
    <TableSearchContainer
      cancelText={translations.cancel}
      onCancelClick={handleSearchCancel}
      placeholder={translations.search}
      search={search || ''}
      onChange={setSearch}
    />
  );
};
