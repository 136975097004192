import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPersonComponent = ({
  width = 24,
  height = 24,
  fillColor = 'transparent',
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={fillColor}
      height={height}
      width={width}
    >
      <circle
        cx="12"
        cy="8"
        r="5"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M2.7793 21c1.9627-3.0102 5.35951-5 9.2211-5 3.8615 0 7.2583 1.9898 9.221 5"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
