import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { ContentLeftContainerProps } from './contentLeft.model';

export const ContentLeftContainer = ({
  translations,
}: ContentLeftContainerProps): ReactElement => (
  <ContentLeftStyled>
    <TitleStyled>{translations?.form_without_prices_title}</TitleStyled>
  </ContentLeftStyled>
);
