import styled from 'styled-components';

import { fontSettings, input, InputColor, transition } from '../../styles';
import { rgba } from 'polished';

type SelectProps = {
  disabled?: boolean;
  bordered?: boolean;
  color?: InputColor;
  hasError?: boolean;
};

type SelectStyledText = {
  isDefaultValue: boolean;
};

type SelectArrowProps = {
  isOpened: boolean;
};

type SelectOption = {
  isActive: boolean;
};

const borderRadius = '16px';

export const SelectStyled = styled.div<SelectProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-radius: ${borderRadius};
  order: 0;
  flex-grow: 0;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  user-select: none;
  cursor: pointer;
  padding: 15px 19px;
  ${(props): string => input(props)}

  :hover {
    border-color: ${({ theme, hasError }): string =>
      hasError ? '' : theme.COLOR.TEXT_LIGHT_COLOR};
  }
`;

export const SelectStyledText = styled.div<SelectStyledText>`
  color: ${({ theme, isDefaultValue }): string =>
    isDefaultValue
      ? theme.COLOR.TEXT_LIGHT_COLOR
      : theme.COLOR.TEXT_DARK_COLOR};
  min-height: 24px;
`;

export const SelectStyledButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${borderRadius};
  z-index: ${(props): string => props.theme.ZINDEX.TOP_BLOCK};
`;

const SelectIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const SelectStyledArrow = styled(SelectIcon)<SelectArrowProps>`
  ${transition('transform')}
  ${({ isOpened }): string => (isOpened ? 'transform: rotate(180deg);' : '')}
`;

export const SelectStyledOption = styled.div<SelectOption>`
  cursor: pointer;
  padding: 10px 20px;
  flex: 0 0 100%;
  width: 100%;
  background-color: ${({ isActive, theme }): string =>
    isActive ? rgba(theme.COLOR.BLACK, 0.04) : ''};
  transition: background-color 0.1s;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
  }
`;
