import { PopupStyleProps } from '@lerna-core';

export const modalPaddingsWithFooter: PopupStyleProps = {
  paddingSticky: {
    top: '24px',
    right: '24px',
    bottom: '24px',
    left: '24px',
  },
  paddingContent: {
    top: '48px',
    right: '24px',
    bottom: '0',
    left: '24px',
  },
};
