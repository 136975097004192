import {
  getDeviceInfo,
  mediaPreset,
  publicConfig,
  redirectToLocale,
  telegramMiddleware,
  themeDefault,
  trailingSlash,
} from '@lerna-core';
import App, { AppContext } from 'next/app';
import { AppPropsModel } from '../models';
import { basicPageMiddleware } from '@middlewares';

export const getInitialProps = async (
  appContext: AppContext
): Promise<AppPropsModel> => {
  const { pageProps, ...appProps } = await App.getInitialProps(appContext);
  telegramMiddleware(appContext);
  basicPageMiddleware(appContext.ctx);
  const deviceInfo = getDeviceInfo(appContext);
  const theme = themeDefault(deviceInfo.isMobile);

  if (publicConfig?.TRAILING_SLASH === 'true') {
    trailingSlash(appContext);
  }

  redirectToLocale(appContext);

  const mediaPresets = mediaPreset.get();
  const result = {
    ...appProps,
    deviceInfo,
    theme,
    mediaPresets,
  } as AppPropsModel;
  if (Object.keys(pageProps).length > 0) {
    result.pageProps = pageProps;
  }

  return result;
};
