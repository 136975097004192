import { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';
import { FormB2BContainer } from './formB2B';
import {
  RegisterCareerDataRequestParamsModel,
  RegisterPersonalDataRequestParamsModel,
} from './registerForm.model';
import { UpdateUserParams } from '../../updateUser';
import { UpdateCareerParams } from '../../updateCareer';
import {
  CreateUserError,
  CreateUserStatusCodes,
  mapRegisterValidationErrors,
} from '../../createUser';
import { FormikHelpers } from 'formik';
import { FormPersonalContainer } from './formPersonal';
import { FormCareerContainer } from './formCareer/formCareer.container';

export const getRegisterForm = (
  user: Nullable<UserAggregateModel>
): Nullable<ReactElement> => {
  const isB2B = !!user?.career?.companyId;
  const isVerified = !!user?.career?.isVerified;
  const isFulfilled = !!user?.personal.isFulfilled;
  switch (true) {
    case isB2B && !isFulfilled && !isVerified:
      return <FormB2BContainer />;
    case !isFulfilled:
      return <FormPersonalContainer />;
    case !isVerified:
      return <FormCareerContainer />;
    default:
      return null;
  }
};

export const mapPersonalDataRequestParams = ({
  firstName,
  lastName,
  phone,
}: RegisterPersonalDataRequestParamsModel): UpdateUserParams => ({
  first_name: firstName,
  last_name: lastName,
  phone,
});

export const mapCareerDataRequestParams = ({
  departmentUuid,
  positionUuid,
}: RegisterCareerDataRequestParamsModel): UpdateCareerParams => ({
  department_uuid: departmentUuid,
  position_uuid: positionUuid,
});

export function handleRegisterErrors<T>(
  error: CreateUserError,
  actions: FormikHelpers<T>
) {
  if (error) {
    const { status, errors } = error;
    if (status === CreateUserStatusCodes.VALIDATION_ERROR && errors) {
      actions.setStatus(mapRegisterValidationErrors(errors));
    }
  }
}
