import React, { ReactElement } from 'react';
import { LeftAdvantagesStyled } from './leftAdvantages.styled';
import { AdvantagesItemContainer } from './advantagesItem';
import { getAdvantages } from './leftAdvantages.meta';
import { dateService, Nullable } from '@lerna-core';
import { useFormSubscriptionContext } from '../../../formSubscription.context';

export const LeftAdvantagesContainer = (): Nullable<ReactElement> => {
  const { translations, subscriptionAvailability, showAdvantages } =
    useFormSubscriptionContext();

  const advantages =
    showAdvantages &&
    subscriptionAvailability &&
    getAdvantages(
      translations,
      dateService.formatDate(
        subscriptionAvailability.subscriptionAccess.expiredAt
      ),
      subscriptionAvailability?.subscriptionAccess?.withHomeworks
    );

  if (!advantages) return null;

  return (
    <LeftAdvantagesStyled>
      {advantages.map((advantage) => (
        <AdvantagesItemContainer key={advantage.id} {...advantage} />
      ))}
    </LeftAdvantagesStyled>
  );
};
