import styled from 'styled-components';

import { fontSettings } from '../../../styles/mixins/font.mixins';

export const InputErrorStyled = styled.div`
  padding: 4px 20px 0;
  font-weight: 500;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.INFO_RED_COLOR};
  text-align: left;
`;
