import styled from 'styled-components';
import { ListItemLabelStyled } from '../listItem.styled';

export const ListItemPropsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${ListItemLabelStyled} {
    span + span:before {
      content: ' • ';
      position: relative;
      top: 1px;
    }
  }
`;
