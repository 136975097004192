import styled from 'styled-components';

export const TransferRightStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  gap: 20px;
  z-index: 1;
`;
