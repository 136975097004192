import React, { ReactElement } from 'react';
import { ContactsLinkStyled, TitleStyled } from './contactsLink.styled';
import { FooterLinkContainer } from '../../../footerLink';
import { Nullable } from '../../../../../models';
import { ContactsItemContainerProps } from './contactsLink.model';

export const ContactsLinkContainer = ({
  link,
  title,
  secondLink,
  analyticsEvent,
}: ContactsItemContainerProps): Nullable<ReactElement> => {
  if (!link.label) return null;

  return (
    <ContactsLinkStyled>
      {title && <TitleStyled>{title}</TitleStyled>}
      <FooterLinkContainer link={link} analyticsEvent={analyticsEvent} />
      {secondLink?.label && (
        <FooterLinkContainer
          link={secondLink}
          analyticsEvent={analyticsEvent}
        />
      )}
    </ContactsLinkStyled>
  );
};
