import React, { ReactElement } from 'react';

import { SelectOption } from '../../../select';
import { SelectFormControlContainer } from '../selectFormControl';
import { GenericFormControlContainerProps } from './genericFormControl.model';
import { PhoneFormControlContainer } from '../phoneFormControl';
import { EmailFormControlContainer } from '../emailFormControl';
import { TextareaFormControlContainer } from '../textAreaFormControl';
import { TextFormControlContainer } from '../textFormControl';
import { InputColors } from '../../../../styles';

export const GenericFormControlContainer = ({
  type = 'text',
  isRequired = false,
  label,
  onBlur,
  errors = [],
  placeholder,
  onChange,
  initialValue,
  name,
  clearControl,
  disabled,
  bordered = true,
  options,
  color = InputColors.white,
  defaultOption,
  height,
  borderRadius,
  floatingPlaceholder = true,
}: GenericFormControlContainerProps): ReactElement => {
  switch (type) {
    case 'phone':
      return (
        <PhoneFormControlContainer
          key={'phone'}
          errors={errors}
          isRequired={isRequired}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          initialValue={initialValue as string}
          label={label}
          color={color}
          name={name}
          bordered={bordered}
          disabled={disabled}
          height={height}
          borderRadius={borderRadius}
          floatingPlaceholder={floatingPlaceholder}
        />
      );

    case 'email':
      return (
        <EmailFormControlContainer
          errors={errors}
          key={'email'}
          isRequired={isRequired}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={onChange}
          initialValue={initialValue as string}
          label={label}
          color={color}
          name={name}
          bordered={bordered}
          disabled={disabled}
          clearControl={clearControl}
          floatingPlaceholder={floatingPlaceholder}
        />
      );

    case 'textarea':
      return (
        <TextareaFormControlContainer
          errors={errors}
          key={'textarea'}
          isRequired={isRequired}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={onChange}
          initialValue={initialValue as string}
          label={label}
          color={color}
          name={name}
          bordered={bordered}
          disabled={disabled}
          floatingPlaceholder={floatingPlaceholder}
        />
      );
    case 'select':
      return (
        <SelectFormControlContainer
          errors={errors}
          key={`select-${name}`}
          placeholder={placeholder}
          options={options}
          label={label}
          defaultOption={defaultOption as SelectOption}
          onBlur={onBlur}
          bordered={bordered}
          disabled={disabled}
          color={color}
          onChange={onChange}
          floatingPlaceholder={floatingPlaceholder}
        />
      );
    case 'text':
    default:
      return (
        <TextFormControlContainer
          errors={errors}
          key={'text'}
          isRequired={isRequired}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={onChange}
          initialValue={initialValue as string}
          label={label}
          name={name}
          color={color}
          bordered={bordered}
          clearControl={clearControl}
          disabled={disabled}
          floatingPlaceholder={floatingPlaceholder}
        />
      );
  }
};
