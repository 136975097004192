import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import { SearchContainerStyledProps } from './coursesSearch.model';

export const SearchContainerStyled = styled.div<SearchContainerStyledProps>`
  width: 100%;
  height: ${({ theme }): number => (theme.IS_MOBILE ? 48 : 54)}px;
  position: relative;
  box-sizing: border-box;
  background-color: ${(props): string => props.theme.COLOR.WHITE};
  border-radius: 100px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  padding: 16px ${(props): string => (props.hasSuggest ? '46px' : '16px')} 16px
    20px;
  color: ${({ color }): string => color};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: ${(props): string =>
    props.hasSuggest ? 'flex-start' : 'center'};
  cursor: pointer;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > svg {
    margin-right: 12px;
    flex: 0 0 24px;
  }
`;

export const SearchTextStyled = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
