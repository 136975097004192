import React, { ReactElement } from 'react';
import { SwitcherOptionContainerProps } from './switcherOption.model';
import {
  OptionValueStyled,
  SwitcherOptionStyled,
} from './switcherOption.styled';

export const SwitcherOptionContainer = ({
  option,
  onChange,
  activeKey,
  name,
  borderRadius,
}: SwitcherOptionContainerProps): ReactElement => {
  const isChecked = option.key === activeKey;
  const id = `${name}_${option.key}`;

  return (
    <SwitcherOptionStyled borderRadius={borderRadius} isChecked={isChecked}>
      <input
        name={name}
        hidden
        id={id}
        type="radio"
        value={option.key}
        checked={isChecked}
        onChange={onChange}
      />

      <OptionValueStyled>{option.label}</OptionValueStyled>
    </SwitcherOptionStyled>
  );
};
