import React, { ReactElement, useCallback } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import { ButtonColor, ButtonContainer, CommonEventTypes } from '@lerna-core';
import { ContentRightContainerProps } from './contentRight.model';
import { RightHeadContainer } from './rightHead';
import { RightDescriptionContainer } from './rightDescription';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';

export const ContentRightContainer = ({
  formGranuleData,
}: ContentRightContainerProps): ReactElement => {
  const {
    handleSubmit,
    isSubmitting,
    translations,
    course,
    isMobile,
    eventPosition,
  } = formGranuleData;

  const handleDescriptionCollapseClick = useCallback(() => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollMoreDetails,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course
    );
  }, []);

  return (
    <ContentRightStyled>
      <RightHeadContainer
        course={course}
        isMobile={isMobile}
        translations={translations}
      />
      {course.description && (
        <RightDescriptionContainer
          description={course.description}
          translations={translations}
          isMobile={isMobile}
          handleCollapseClick={handleDescriptionCollapseClick}
        />
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.sign_up_course_button_title}
          disabled={isSubmitting}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
