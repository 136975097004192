import { PopupsActions } from './popups.actions';
import * as constants from './popups.constants';

export type OpenPopupModel = {
  slug: string;
  target?: string;
};
export type PopupsReducer = {
  openedPopups: OpenPopupModel[];
};

const defaultState: PopupsReducer = {
  openedPopups: [],
};

export const popupsReducer = (
  state: PopupsReducer = defaultState,
  action: PopupsActions
): PopupsReducer => {
  switch (action.type) {
    case constants.TRIGGER_OPEN_POPUP: {
      return {
        ...state,
        openedPopups: [
          ...state.openedPopups,
          {
            slug: action.popupSlug,
            target: action.target,
          },
        ],
      };
    }
    case constants.TRIGGER_CLOSE_POPUP: {
      return {
        ...state,
        openedPopups: state.openedPopups.filter(
          (popup) => popup.slug !== action.popupSlug
        ),
      };
    }
    default: {
      return state;
    }
  }
};
