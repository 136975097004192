import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import {
  ButtonColor,
  ButtonContainer,
  FilterModel,
  filterRouterService,
  IconNextComponent,
  urlService,
} from '@lerna-core';
import {
  PageNotFoundFilterOptionStyled,
  PageNotFoundFilterStyled,
} from '../pageNotFound.styled';
import {
  PageNotFoundLinkContainerProps,
  PageNotFoundLinksContainerProps,
} from '../pageNotFound.model';
import { SPHERE_FILTER_SLUG } from './pageNotFoundLinks.constants';

export const PageNotFoundLinksContainer = ({
  coursesData,
  coursesPath,
}: PageNotFoundLinksContainerProps): ReactElement => {
  const router = useRouter();
  const { all_directions_title } = coursesData?.translations;

  const navigate = (optionSlug: string): void => {
    router.query = {};

    const newQuery = filterRouterService.getNewRouteCheckbox(
      router,
      false,
      SPHERE_FILTER_SLUG,
      optionSlug
    );

    router.push(
      urlService.createUrlFromPathAndQuery(coursesPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  const filters = coursesData?.filters || [];
  const currentFilter = filters.find(
    (filter) => filter.slug === SPHERE_FILTER_SLUG
  ) as FilterModel;
  const currentFilterOptions = currentFilter?.options || [];

  return (
    <PageNotFoundFilterStyled>
      <PageNotFoundLinkContainer
        key="all"
        title={all_directions_title}
        event={(): void => {
          router.push(
            urlService.createUrlFromPathAndQuery(coursesPath, router)
          );
        }}
      />
      {currentFilterOptions.map((option) => (
        <PageNotFoundLinkContainer
          key={option.slug}
          title={option.value}
          event={(): void => {
            navigate(option.slug);
          }}
        />
      ))}
    </PageNotFoundFilterStyled>
  );
};

export const PageNotFoundLinkContainer = ({
  event,
  title,
}: PageNotFoundLinkContainerProps): ReactElement => {
  return (
    <PageNotFoundFilterOptionStyled onClick={event}>
      <ButtonContainer
        color={ButtonColor.white}
        boxShadow={'0 5px 25px -5px rgba(12, 11, 13, 0.04)'}
        title={title}
      >
        <IconNextComponent />
      </ButtonContainer>
    </PageNotFoundFilterOptionStyled>
  );
};
