import {
  dateService,
  personalPath,
  projectSubDomains,
  stringService,
  TranslationModel,
  urlService,
} from '@lerna-core';
import {
  SubscriptionBadgeModel,
  SubscriptionBadgeStatuses,
} from '../subscriptionBadge';

export const getSubscriptionButtonsMeta = (
  status: SubscriptionBadgeStatuses,
  translations: TranslationModel,
  coursesClaimed: number,
  subscriptionTitle: string,
  nextClaimPeriodStart: string
): SubscriptionBadgeModel => {
  switch (status) {
    case SubscriptionBadgeStatuses.success:
      return {
        status: SubscriptionBadgeStatuses.success,
        label: translations.subscription_course_not_available,
        link: urlService.getFullUrlToSubDomain(
          projectSubDomains.personal,
          personalPath.courses
        ),
        linkLabel: translations.my_education,
      };
    case SubscriptionBadgeStatuses.warn:
      return {
        status: SubscriptionBadgeStatuses.warn,
        label: `${
          translations.subscription_not_available_1
        } ${coursesClaimed} ${stringService
          .pluralTranslations(coursesClaimed, [
            translations.course_label_1,
            translations.course_label_2,
            translations.course_label_many,
          ])
          .toLowerCase()} ${translations.by_subscription.toLowerCase()} <b>«${stringService.capitalizeFirstLetter(
          subscriptionTitle
        )}»</b> ${
          translations.subscription_not_available_2
        } <b>${dateService.formatDate(nextClaimPeriodStart)}</b>`,
      };
  }
};
