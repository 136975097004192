import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CommonEventTypes,
  consentClientProvider,
  ConsentKinds,
  getCoursePageHref,
  getCurrentPopupFromOpen,
  logger,
  Nullable,
  PopupsEnum,
  RegisterPositions,
  requestClosePopup,
  usePromoCodeContext,
  useRegisterPopupContext,
} from '@lerna-core';
import {
  B2CFreeOrderResponse,
  B2COrderResponse,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  withPromoCodeError,
} from '@features/common/forms';
import { useSelector } from 'react-redux';
import {
  getGuestOrderFormInitialValues,
  getGuestOrderFormValidationSchema,
  sendGuestOrderRequest,
} from './guestOrderForm.helper';
import { useFormik } from 'formik';
import { useDiscountProgram } from '@features/common/discountProgram';
import { useRouter } from 'next/router';
import {
  GuestOrderFormContextModel,
  GuestOrderFormContextProviderProps,
  GuestOrderFormSubmitStatusTypes,
} from './guestOrderForm.model';
import { PageNameContext } from '@contexts';
import { pushFormSubmitStatusEvent } from './guestOrderForm.analytics';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const GuestOrderFormContext =
  React.createContext<Nullable<GuestOrderFormContextModel>>(null);

export const GuestOrderFormContextProvider = ({
  props,
  children,
}: GuestOrderFormContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { removeNumericId } = useCourseActiveContext();
  const pageName = useContext(PageNameContext);
  const { promoCodeQuery, discountProgramQuery } = useDiscountProgram();
  const { promoCode, setPromoCode } = usePromoCodeContext();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.guestOrderFormPopup)
  );

  const isFreeCourse =
    props.course.isFree ||
    props.course.isFreeByBonuses ||
    props.course.isFreeByDiscount;

  const [isApplyingPromoCode, setIsApplyingPromoCode] =
    useState<boolean>(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);
  const { handleAction } = useRegisterPopupContext();

  const validationSchema = useMemo(
    () =>
      getGuestOrderFormValidationSchema(
        props.translations,
        promoCode,
        discountProgramQuery
      ),
    [promoCode]
  );
  const initialValues = useMemo(() => getGuestOrderFormInitialValues(), []);

  const showFormStatus = isSuccessDisplayed || isErrorDisplayed || isExistOrder;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      handleAction(
        {
          onSuccess: () => {
            actions.setSubmitting(true);
            actions.setStatus({});

            const promoCodeValue = values.withPromoCode
              ? values.promoCode.trim()
              : undefined;
            const companyValue = values.withCompany
              ? values.company.trim()
              : undefined;

            const courseUrl = getCoursePageHref({
              course: props.course,
              router,
              urlParams: {},
            });

            sendGuestOrderRequest(
              router,
              values.name.trim(),
              values.email,
              values.phone,
              props.course,
              courseUrl,
              pageName,
              router.locale,
              promoCodeValue,
              companyValue,
              discountProgramQuery
            )
              .then((response) => onSuccess(true, response))
              .catch((error): void => {
                const orderConflictError = isConflictError(error);
                const promoCodeError = withPromoCodeError(error);

                if (promoCodeError) {
                  setInputError(
                    discountProgramQuery ? 'promoCodeDraft' : 'promoCode',
                    `${props.translations?.promocode_does_not_match}`
                  );
                  orderFormPushDataLayer(
                    OrderFormEvents.formEnrollPromoCodeError,
                    CommonEventTypes.coursesEvents,
                    props.eventPosition,
                    props.course
                  );

                  return;
                }
                if (orderConflictError) {
                  onExistError();

                  return;
                }

                onError();
                logger.error(
                  `[ERROR]: error sending request. ${error.response?.data}`
                );
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
            consentClientProvider({
              ...values,
              kind: ConsentKinds.advMailings,
              agreement: !!values.advConsent,
            });
          },
          onError: () => {
            actions.setSubmitting(false);
          },
        },
        RegisterPositions.submitForm,
        {
          firstName: values.name,
          phone: values.phone,
        }
      );
    },
  });

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    !promoCodeQuery && setFieldValue('withPromoCode', false);
    !promoCodeQuery && setFieldValue('promoCode', '');
    !promoCodeQuery && setFieldValue('promoCodeDraft', '');
    !promoCodeQuery && setPromoCode(null);
    setFieldValue('withCompany', false);
    setFieldValue('company', '');
    setFieldValue('privacy', false);
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<B2COrderResponse | B2CFreeOrderResponse>
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        GuestOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        (response as B2COrderResponse)?.id ||
          (response as B2CFreeOrderResponse)?.order.dealId,
        OrderFormRequestTypes.b2c
      );
      if (values.withPromoCode) {
        orderFormPushDataLayer(
          OrderFormEvents.formEnrollPromoCode,
          CommonEventTypes.coursesEvents,
          props.eventPosition,
          props.course
        );
      }
      if (values.withCompany) {
        orderFormPushDataLayer(
          OrderFormEvents.formEnrollLegalName,
          CommonEventTypes.coursesEvents,
          props.eventPosition,
          props.course
        );
      }
    }

    setIsSuccessDisplayed(true);
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      GuestOrderFormSubmitStatusTypes.error,
      props.course,
      props.eventPosition
    );
    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      GuestOrderFormSubmitStatusTypes.repeat,
      props.course,
      props.eventPosition
    );
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };

  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.guestOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };

  const handleFormSubmit = (): void => {
    handleSubmit();
  };

  useEffect(() => {
    if (promoCodeQuery && (!isFreeCourse || discountProgramQuery)) {
      setFieldValue('withPromoCode', true);
      setFieldValue('promoCode', promoCodeQuery);
      setFieldValue('promoCodeDraft', promoCodeQuery);
    }
    if (isFreeCourse && !discountProgramQuery) {
      setFieldValue('withPromoCode', false);
      setFieldValue('promoCode', '');
      setFieldValue('promoCodeDraft', '');
    }
  }, [promoCodeQuery, isFreeCourse, currentPopup]);

  useEffect(() => {
    if (promoCode) {
      setTouched({ promoCodeDraft: true });
    }
  }, [promoCode, currentPopup]);

  const context = {
    ...props,
    setErrors,
    setTouched,
    currentPopup,
    handlePopupClose,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    discountProgramQuery,
    promoCodeQuery,
    isFreeCourse,
    isApplyingPromoCode,
    setIsApplyingPromoCode,
  };

  return (
    <GuestOrderFormContext.Provider value={context}>
      {children}
    </GuestOrderFormContext.Provider>
  );
};

export const useGuestOrderFormContext = (): GuestOrderFormContextModel => {
  const value = useContext(GuestOrderFormContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as GuestOrderFormContextModel;
  }

  return value;
};
