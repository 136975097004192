import React, { memo, ReactElement, useCallback, useMemo } from 'react';

import { useTheme } from '../../hooks';
import { IconChevronComponent } from '../icons';
import { PaginationButtonDirections } from './pagination.model';
import {
  PageStyled,
  PaginationButtonStyled,
  PaginationStyled,
} from './pagination.styled';
import { DOTS, usePaginationHelpers } from './usePaginationHelpers.hook';

export interface PaginationProps {
  totalItems: number;
  limit?: number;
  page?: number | string;
  siblingCount?: number;
  onPageChanged: (page: number) => void;
  hidePrevButton?: boolean;
  hideNextButton?: boolean;
  className?: string;
}

function PaginationContainer({
  totalItems,
  page = 1,
  limit = 10,
  siblingCount = 2,
  onPageChanged,
  hidePrevButton = false,
  hideNextButton = false,
  className,
}: PaginationProps): ReactElement {
  const theme = useTheme();
  const {
    paginationRange,
    currentPage,
    handlePageChange,
    handlePrevPageChange,
    handleNextPageChange,
    prevPageIsAvailable,
    nextPageIsAvailable,
  } = usePaginationHelpers({
    totalItems,
    page,
    limit,
    siblingCount,
    onPageChanged,
  });

  const items = useMemo(() => {
    return paginationRange.map((pageNumber, index) => {
      const key = `${pageNumber}_${index}`;

      if (pageNumber === DOTS) {
        return <PageStyled key={key}>{DOTS}</PageStyled>;
      }

      return (
        <PageStyled
          key={key}
          active={parseInt(currentPage as unknown as string) === pageNumber}
          onClick={handlePageChange(+pageNumber)}
        >
          {pageNumber}
        </PageStyled>
      );
    });
  }, [currentPage, paginationRange, handlePageChange]);

  const getIconColor = useCallback(
    (isDisabled: boolean) => {
      return isDisabled
        ? theme.COLOR.ICON_GREY_COLOR
        : theme.COLOR.TEXT_DARK_COLOR;
    },
    [theme]
  );

  return (
    <PaginationStyled className={className}>
      {!hidePrevButton && (
        <PaginationButtonStyled
          disabled={!prevPageIsAvailable}
          direction={PaginationButtonDirections.left}
          onClick={handlePrevPageChange}
        >
          <IconChevronComponent
            strokeColor={getIconColor(!prevPageIsAvailable)}
          />
        </PaginationButtonStyled>
      )}
      {items}
      {!hideNextButton && (
        <PaginationButtonStyled
          disabled={!nextPageIsAvailable}
          direction={PaginationButtonDirections.right}
          onClick={handleNextPageChange}
        >
          <IconChevronComponent
            strokeColor={getIconColor(!nextPageIsAvailable)}
          />
        </PaginationButtonStyled>
      )}
    </PaginationStyled>
  );
}

export const Pagination = memo(PaginationContainer);
