import React, { ReactElement } from 'react';
import { DoughnutModel } from './doughnut.model';
import {
  DoughnutStyled,
  DoughnutTextStyled,
  DoughnutSubtextStyled,
  DoughnutTextWrapperStyled,
  DoughnutWrapperStyled,
} from './doughnut.styled';
import {
  convertToPercents,
  createDoughnutSegment,
  getOffset,
} from './doughnut.helper';
import { defaultColors } from './doughnut.meta';

export const DoughnutContainer = ({
  text,
  subtext,
  data,
  diameter,
  withDivider = true,
  colors = defaultColors,
}: DoughnutModel): ReactElement => {
  return (
    <DoughnutWrapperStyled height={diameter} width={diameter}>
      <DoughnutStyled
        cx="50%"
        cy="50%"
        height={diameter}
        width={diameter}
        viewBox="0 0 34 34"
      >
        {convertToPercents(data).map((width, index, list) =>
          createDoughnutSegment(
            width,
            getOffset(list, index),
            colors[index],
            index,
            withDivider
          )
        )}
      </DoughnutStyled>
      {text && (
        <DoughnutTextWrapperStyled>
          <DoughnutTextStyled>{text}</DoughnutTextStyled>
          {subtext && <DoughnutSubtextStyled>{subtext}</DoughnutSubtextStyled>}
        </DoughnutTextWrapperStyled>
      )}
    </DoughnutWrapperStyled>
  );
};
