import { Dispatch } from 'react';

import { logger } from '../../../services';
import {
  userPositionsByCompanyProvider,
  userPositionsProvider,
} from './userPositions.clientProvider';
import {
  UserPositionsActions,
  UserPositionWithOptions,
} from './userPosition.model';
import * as constants from './userPosition.constants';
import { SelectOption } from '../../select';

export const requestUserPositions = (
  dispatch: Dispatch<UserPositionsActions>,
  companyId?: string
) => {
  const provider = companyId
    ? userPositionsByCompanyProvider(companyId)
    : userPositionsProvider();

  dispatch({
    type: constants.REQUEST_USER_POSITIONS_START,
  });

  provider
    .then((response) => {
      dispatch({
        type: constants.REQUEST_USER_POSITIONS_SUCCESS,
        payload: response as SelectOption[],
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.REQUEST_USER_POSITIONS_ERROR,
        payload: err,
      });
      logger.error('[ERROR]: Can not get user positions', err);
    });
};

export const requestUserPositionsSuccess = (
  payload: UserPositionWithOptions[]
): UserPositionsActions => ({
  type: constants.REQUEST_USER_POSITIONS_SUCCESS,
  payload,
});
