import { RequestClient } from '../../../services/request/request.service';
import { publicConfig } from '../../../services';

export class RegisterWelcomeClient extends RequestClient<null, null, null> {
  protected requestParameters: null;
  protected path = 'personal-api/v1/me/mark-welcome-popup-as-viewed';

  constructor() {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = null;
  }
}
