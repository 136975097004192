import React, { ReactElement, useMemo } from 'react';
import { PricesItemContainer } from '../pricesItem';
import { PricesStyled, WrapperStyled } from './prices.styled';
import { PricesConsultationContainerProps } from './prices.model';
import { PricesInfoContainer } from '../pricesInfo';
import { textGradient, useTheme } from '@lerna-core';
import { getB2CPrices } from '@features/common/course';

export const PricesContainer = ({
  course,
  translations,
  withInstallment = true,
}: PricesConsultationContainerProps): ReactElement => {
  const theme = useTheme();
  const {
    startPrice,
    hasVendorDiscount,
    vendorDiscountValue,
    vendorDiscountAmount,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
    hasProgramDiscount,
    programDiscountAmount,
    hasPromoCodeDiscount,
    promoCodeDiscountAmount,
  } = useMemo(() => getB2CPrices(course), [course.price]);

  const vendorDiscountTitle = `${translations?.discount_label} - ${vendorDiscountValue}`;
  const programDiscountTitle =
    course.price?.priceWithProgramDiscount?.textBlocks?.pricesBlockText;
  const gradientText = textGradient(
    theme.COLOR.DEFAULT_GRADIENT_START,
    theme.COLOR.DEFAULT_GRADIENT_END
  );

  const showStartPrice =
    (withInstallment && hasInstallment) || hasOneOfDiscounts;
  const showTotalPrice =
    withInstallment &&
    hasInstallment &&
    hasOneOfDiscounts &&
    !course.isFreeByDiscount;
  const showBonusPoints = hasBonusPointsDiscount && !course.isFreeByDiscount;
  const showProgramDiscount = hasProgramDiscount && !!programDiscountTitle;
  const showVendorDiscount = hasVendorDiscount && !hasProgramDiscount;

  return (
    <PricesStyled>
      {!course.isFree && (
        <WrapperStyled>
          {showStartPrice && (
            <PricesItemContainer
              title={translations?.item_price_label}
              value={startPrice}
              itemStyles={
                hasOneOfDiscounts
                  ? {
                      value: `text-decoration: line-through; color: ${theme.COLOR.TEXT_LIGHT_COLOR}`,
                    }
                  : undefined
              }
            />
          )}
          {showProgramDiscount && (
            <PricesItemContainer
              title={programDiscountTitle}
              value={programDiscountAmount}
              isNegative
            />
          )}
          {showVendorDiscount && (
            <PricesItemContainer
              title={vendorDiscountTitle}
              value={vendorDiscountAmount}
              isNegative
            />
          )}
          {hasPromoCodeDiscount && (
            <PricesItemContainer
              title={
                translations?.promo_code_discount_label || 'Скидка по промокоду'
              }
              value={promoCodeDiscountAmount}
              isNegative
            />
          )}
          {showBonusPoints && (
            <PricesItemContainer
              title={translations?.discount_bonuses_label}
              value={bonusPointsDiscount}
              isNegative
              itemStyles={{
                title: gradientText,
                value: gradientText,
              }}
            />
          )}
          {showTotalPrice && (
            <PricesItemContainer
              title={translations?.total_price_label}
              value={totalPrice}
              itemStyles={{
                item: 'font-weight: 700;',
              }}
            />
          )}
        </WrapperStyled>
      )}
      <PricesInfoContainer
        course={course}
        translations={translations}
        withInstallment={withInstallment}
      />
    </PricesStyled>
  );
};
