import React, { ReactElement, useRef } from 'react';
import { SidePopupContainerProps } from './sidePopup.model';
import { PopupPortalContainer } from '../popupPortal';
import {
  PopupContentStyled,
  PopupFooterStyled,
  PopupOverlayStyled,
  PopupScrollWrapperStyled,
  SidePopupStyled,
} from './sidePopup.styled';
import { PopupCloseContainer } from './popupClose';
import { useSidePopup } from './sidePopup.hooks';
import { Nullable } from '@lerna-core';

//todo: delete if unused
export const SidePopupContainer = ({
  isOpen,
  handleClose,
  children,
  withAnimation = true,
  footer,
  message,
  contentStyles,
}: SidePopupContainerProps): Nullable<ReactElement> => {
  const footerRef = useRef<Nullable<HTMLDivElement>>(null);

  const { mounted, footerHeight } = useSidePopup({
    isOpen,
    footerRef,
    withAnimation: withAnimation,
  });

  const popupFooter = footer && (
    <PopupFooterStyled ref={footerRef}>{footer}</PopupFooterStyled>
  );
  const popupContent = !message && (
    <>
      <PopupScrollWrapperStyled
        hasFooter={!!footer}
        footerHeight={footerHeight}
      >
        {children}
      </PopupScrollWrapperStyled>
      {popupFooter}
    </>
  );
  const popupMessage = message && message;

  if (!mounted) {
    return null;
  }

  return (
    <PopupPortalContainer>
      <SidePopupStyled>
        <PopupOverlayStyled onClick={handleClose} />
        <PopupContentStyled
          withAnimation={withAnimation}
          contentStyles={contentStyles}
          isOpen={isOpen}
        >
          <PopupCloseContainer handleClose={handleClose} />
          {popupContent}
          {popupMessage}
        </PopupContentStyled>
      </SidePopupStyled>
    </PopupPortalContainer>
  );
};
