import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { mainPath } from '../../constants';
import { useEffect, useState } from 'react';
import { QueryParams, RegionsEnum } from '../../models';
import { publicConfig, urlService } from '../../services';
import { CompanyUuidEnum } from '../company';
import { getUser } from '../user';
import { getFastTrackContext } from './fasttrackWidget.helper';
import { UseFasttrackWidgetModel } from './fasttrackWidget.model';

export const useFasttrackWidget = (show?: boolean): UseFasttrackWidgetModel => {
  const user = useSelector(getUser);
  const router = useRouter();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const userCompanyId = user?.career?.companyId;
  const queryCompanyId = router.query[QueryParams.company];
  const iframeInitiator = router.query[QueryParams.initiator];
  const isActivateByVerifiedPhonePage =
    router.pathname === mainPath.activateByVerifiedPhone;
  const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;
  const companyId = userCompanyId || queryCompanyId;
  const isMincifraCompany = companyId === CompanyUuidEnum.mincifra;
  const handleLoad = () => {
    setIsLoaded(true);
    window.fasttrack = {
      context: getFastTrackContext(user, router),
    };
  };

  const showFasttrackWidget =
    show ||
    (isRuRegion && !isMincifraCompany) ||
    isActivateByVerifiedPhonePage ||
    (Boolean(userCompanyId) && !isMincifraCompany) ||
    (Boolean(userCompanyId) && !iframeInitiator);

  useEffect(() => {
    if (isLoaded) {
      window.fasttrack.context.url = urlService.getFullUrlFromReq(router);
    }
  }, [isLoaded, router.asPath]);

  return {
    showFasttrackWidget,
    handleLoad,
  };
};
