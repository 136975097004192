export enum CourseOptionLogicTypes {
  types = 'types',
  difficulty = 'difficulty',
  spheres = 'spheres',
  professions = 'professions',
}

export enum CourseOptionsSlug {
  startDate = 'startDate',
  duration = 'duration',
  difficulty = 'difficulty',
  company = 'company',
  type = 'type',
}
