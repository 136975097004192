import { TranslationModel } from '../../../models';
import * as Yup from 'yup';
import {
  ContactUsFormInitialValues,
  ContactUsFormValidationSchema,
} from './contactUsForm.model';
import { PersonalModel } from '../../user';
import {
  getFormAgreementInitialValues,
  getFormAgreementValidationSchema,
  phoneRegex,
} from '../../form';
import { isPhoneValid } from '../../inputs';

const EMPTY = '';
const MAX_LENGTH = 255;

export const getContactUsFormValidationSchema = (
  translations: TranslationModel
): ContactUsFormValidationSchema => {
  const validationObject = {
    firstName: Yup.string()
      .required(translations.input_required_error_label)
      .trim(translations.input_required_error_label),
    email: Yup.string()
      .email(translations?.input_email_format_error_label)
      .required(translations?.input_required_error_label),
    phone: Yup.string()
      .matches(phoneRegex, translations?.input_phone_format_error_label)
      .required(translations?.input_required_error_label)
      .test(
        'phone',
        translations?.input_phone_format_error_label,
        isPhoneValid
      ),
    message: Yup.string()
      .trim(translations.input_required_error_label)
      .max(MAX_LENGTH, `${translations.max_symbols_length} - ${MAX_LENGTH}`)
      .nullable(),
  };

  const formAgreementValidationSchema =
    getFormAgreementValidationSchema(translations);

  Object.assign(validationObject, formAgreementValidationSchema);

  return Yup.object().shape(validationObject).required();
};

export const getContactUsFormInitialValues = (
  personal: PersonalModel | undefined
): ContactUsFormInitialValues => {
  return {
    firstName: personal?.firstName || EMPTY,
    email: personal?.email || EMPTY,
    phone: personal?.phone || EMPTY,
    message: EMPTY,
    ...getFormAgreementInitialValues(),
  };
};
