import {
  PromoCodeRequestApiParams,
  PromoCodeRequestParams,
} from './promoCode.model';

export const mapPromoCodeRequestParams = ({
  promoCode,
  discountProgramSlug,
}: PromoCodeRequestParams): PromoCodeRequestApiParams => {
  const requestParams: PromoCodeRequestApiParams = {
    promo_code: promoCode,
  };

  if (discountProgramSlug) {
    requestParams.discount_program_slug = discountProgramSlug;
  }

  return requestParams;
};
