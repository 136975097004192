import { coursesAdapter, CoursesPageModel } from '@features/courses';
import {
  mapShowcaseDefaultRequestProps,
  publicConfig,
  RequestClient,
  RequestHeader,
  serverConfig,
  ShowcaseApiModel,
  ShowcaseErrorModel,
  ShowcaseRequestProps,
} from '@lerna-core';
import {
  CourseApiRequestParams,
  CourseDataErrorModel,
  CourseDataModel,
  coursePageAdapter,
  CoursePageApiModel,
  CourseRequestParams,
} from '@features/course';
import { DiscountProgramAggregatePathEnum } from './discountProgram.constants';

export class DiscountProgramClient extends RequestClient<
  ShowcaseRequestProps,
  ShowcaseApiModel,
  CoursesPageModel | ShowcaseErrorModel
> {
  protected path = '';
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesAdapter;

  constructor(
    params: ShowcaseRequestProps,
    discountProgram: string,
    headers?: RequestHeader
  ) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );

    this.requestParameters = mapShowcaseDefaultRequestProps(params);
    this.path = `${DiscountProgramAggregatePathEnum.aggregate}${discountProgram}`;
  }
}

export class DiscountProgramCourseClient extends RequestClient<
  CourseApiRequestParams,
  CoursePageApiModel,
  CourseDataModel | CourseDataErrorModel
> {
  protected requestParameters: CourseApiRequestParams;
  protected adapter = coursePageAdapter;
  protected path = '';

  constructor(params: CourseRequestParams, headers?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );

    this.requestParameters = {};

    if (params.promo_code) {
      this.requestParameters.promo_code = params.promo_code;
    }
    this.path = `aggregate/v1/catalog/courses/${params.slug}/discount-programs/${params.discount}`;
  }
}
