import React, { ReactElement } from 'react';
import { Nullable } from '../../../../../models';
import { DesktopDynamicLinksContainerProps } from './desktopDynamicLinks.model';
import { DesktopDynamicLinksStyled } from './desktopDynamicLinks.styled';
import { LinksParentContainer } from './linksParent';

export const DesktopDynamicLinksContainer = ({
  menu,
  handleClose,
  projectPageName,
}: DesktopDynamicLinksContainerProps): Nullable<ReactElement> => {
  if (!menu) return null;

  return (
    <DesktopDynamicLinksStyled>
      {menu.map((parent, index) => (
        <LinksParentContainer
          parent={parent}
          openedDefault={index === 0}
          withHead={menu.length > 1}
          key={parent.title}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </DesktopDynamicLinksStyled>
  );
};
