import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import {
  FilterPopupTitleStyled,
  FilterTitleContainerStyled,
} from '@features/common/filters';

export const FilterPopupStyled = styled.div`
  ${FilterTitleContainerStyled} {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
    font-weight: 700;
  }

  ${FilterPopupTitleStyled} {
    font-weight: 700;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
  }
`;
