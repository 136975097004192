import { css } from 'styled-components';
import { ITheme } from '../../themes';
import { rgba } from 'polished';

export const getFormAgreementStyles = (
  theme: ITheme,
  hasErrors: boolean
): ReturnType<typeof css> => {
  if (hasErrors) {
    return css`
      border-color: ${theme.COLOR.ERROR['900']};
      background-color: ${rgba(theme.COLOR.ERROR['900'], 0.02)};
    `;
  }

  return css`
    border-color: transparent;
    background-color: transparent;
  `;
};
