import React from 'react';
import { PopupContainer } from '../../popups';
import { RegisterFormContainer } from '../registerForm';
import { RegisterPopupModel } from './registerPopup.model';
import { useRegisterPopupContext } from './registerPopup.context';
import { useTheme } from '../../../hooks';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import { RegisterEvents } from '../register.analytics';
import { CommonEventTypes } from '../../../analytics';
import { getRegisterPopupStyleSettings } from './registerPopup.helper';

export const RegisterPopupContainer = ({ canClose }: RegisterPopupModel) => {
  const theme = useTheme();
  const { isRegisterOpened, handleCloseRegister, handleWarningVisibility } =
    useRegisterPopupContext();
  const handleClosePopup = canClose
    ? () => {
        pushDataLayer(
          getSimpleEvent(
            RegisterEvents.registrationClickClose,
            CommonEventTypes.registration
          )
        );
        handleCloseRegister();
        handleWarningVisibility(true);
      }
    : undefined;

  return (
    <PopupContainer
      styleSettings={getRegisterPopupStyleSettings(theme.IS_MOBILE)}
      hasClose={canClose}
      canClose={canClose}
      closeOutside={canClose}
      isOpened={isRegisterOpened}
      handleClose={handleClosePopup}
      fullHeightMobile={false}
      zIndex={theme.ZINDEX.TOP_PAGE_HINTS}
      hasHeader={theme.IS_MOBILE}
    >
      <RegisterFormContainer />
    </PopupContainer>
  );
};
