import { publicConfig } from '../../services';
import { CookiesNotificationEnum } from './cookiesNotification.model';

export const useCookieNotificationType = (): CookiesNotificationEnum => {
  const useCookieManagement = publicConfig.ENABLE_COOKIE_MANAGEMENT == 'true';

  return useCookieManagement
    ? CookiesNotificationEnum.management
    : CookiesNotificationEnum.agreement;
};
