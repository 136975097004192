import styled from 'styled-components';

import { fontSettings } from '../../../styles';
import { FilterPopupOptionLabelProps } from './filterPopupOption.model';

export const FilterPopupOptionStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;

  &:first-child {
    padding-top: 0;
  }
`;

export const FilterPopupOptionLabelStyled = styled.div<FilterPopupOptionLabelProps>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${({ theme, active }): string =>
    active ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR};
`;
