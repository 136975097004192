import { logger } from '../logger/logger.service';
import { AppService } from '../appService/app.service';

class Storage {
  private static instance: Storage;

  public setValue<T>(key: string, value: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logger.error(`[ERROR]: can not set value of ${key} from local storage`);
    }
  }

  public getValue<T>(key: string): T | null {
    if (!AppService.isClientSide) {
      return null;
    }

    try {
      const value = localStorage.getItem(key);
      if (value) {
        try {
          return JSON.parse(value) as T;
        } catch (error) {
          logger.error(
            `[ERROR]: can not get value of ${key} from local storage`
          );

          return null;
        }
      }
    } catch (error) {
      logger.error('[ERROR]: can not read localStorage');
    }

    return null;
  }

  public deleteValue(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      logger.error(
        `[ERROR]: can not delete value of ${key} from local storage`
      );
    }
  }

  public static getInstance(): Storage {
    if (!Storage.instance) {
      Storage.instance = new Storage();
    }

    return Storage.instance;
  }
}

export const storage = Storage.getInstance();
