import { ReactElement } from 'react';
import { PricesContainer } from './prices';
import { CourseModel, TranslationModel } from '@lerna-core';
import { UserOrderFormPaymentContentTypes } from '../../../../../userOrderForm.model';

export const getPricesContent = (
  paymentContent: UserOrderFormPaymentContentTypes,
  course: CourseModel,
  translations: TranslationModel
): ReactElement => {
  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.onlinePay:
      return (
        <PricesContainer
          course={course}
          translations={translations}
          withInstallment={false}
        />
      );
    case UserOrderFormPaymentContentTypes.consultation:
    default:
      return <PricesContainer course={course} translations={translations} />;
  }
};
