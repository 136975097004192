import styled from 'styled-components';
import {
  TooltipDesktopContentStyledProps,
  TooltipDesktopPositions,
} from './tooltipDesktop.model';
import { fontSettings, transition } from '@lerna-core';

export const TooltipDesktopStyled = styled.div`
  position: relative;
  margin-left: 4px;
`;

export const TooltipDesktopTriggerStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: max-content;
  opacity: 0.6;
  ${transition('all')}

  &:hover {
    opacity: 1;
  }
`;

const getTooltipContentPosition = (
  position: TooltipDesktopPositions
): string => {
  switch (position) {
    case TooltipDesktopPositions.bottom: {
      return `
      top: calc(100% + 10px);
      
      &::before {
        top: unset;
        bottom: 100%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
      `;
    }
    case TooltipDesktopPositions.top:
    default: {
      return 'bottom: calc(100% + 10px);';
    }
  }
};

export const TooltipDesktopContentStyled = styled.div<TooltipDesktopContentStyledProps>`
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 8px;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_HINTS};
  background-color: #333333;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  width: 350px;
  font-weight: 400;
  box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.16);
  ${({ isOpen }): string =>
    isOpen
      ? 'opacity: 1; pointer-events: all;'
      : 'opacity: 0; pointer-events: none;'}
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  &::before {
    content: '';
    background-color: inherit;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  }

  ${({ position }): string => getTooltipContentPosition(position)}

  h4 {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  p {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    margin: 0;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;
