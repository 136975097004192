import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import { FormB2CContainerProps } from './formB2C.model';
import { FormContentContainer } from './formContent';
import { FormB2CContextProvider, useFormB2CContext } from './formB2C.context';

const FormB2B2CContent = (): ReactElement => {
  const { currentPopup, handlePopupClose } = useFormB2CContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer />
    </CenteredPopupContainer>
  );
};

export const FormB2CContainer = (
  props: FormB2CContainerProps
): Nullable<ReactElement> => (
  <FormB2CContextProvider props={props}>
    <FormB2B2CContent />
  </FormB2CContextProvider>
);
