import { context, propagation } from '@opentelemetry/api';
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { publicConfig, RequestParametersModel, tracer } from '../../services';
import { commonErrorAdapter } from './adapters/commonError.adapter';
import {
  requestLoggerMiddleware,
  responseLoggerMiddleware,
} from './middlewares/logger.middleware';
import { RequestErrorMessages } from './request.enum';

export interface IRequest<Params, RequestResponse> {
  get: (path: string, params: Params) => AxiosPromise<RequestResponse>;
}

export type RequestHeader = {
  [propName: string]: string | boolean;
};

export type RequestMiddleware = (
  config: InternalAxiosRequestConfig
) => InternalAxiosRequestConfig;
export type ResponseMiddleware = (response: AxiosResponse) => AxiosResponse;

export type RequestSuccessAdapter<ServerResponse, Response> = (
  data: ServerResponse
) => Response;

export type RequestErrorAdapter<ServerResponse, Response> = (
  error: ServerResponse
) => Response;

export const setAcceptLanguage = (locale?: string): RequestHeader => ({
  'Accept-Language': locale || publicConfig?.DEFAULT_LOCALE,
});

export abstract class RequestClient<
  Params,
  ServerRequestResponse,
  RequestResponse
> implements IRequest<Params, RequestResponse>
{
  protected apiUrl: string;
  protected requestConfig: AxiosRequestConfig;
  protected adapter?: RequestSuccessAdapter<
    ServerRequestResponse,
    RequestResponse
  >;
  protected errorAdapter?: RequestErrorAdapter<
    ServerRequestResponse,
    RequestResponse
  >;
  protected requestMiddlewares: Array<RequestMiddleware> = [
    requestLoggerMiddleware,
  ];
  protected responseMiddlewares: Array<ResponseMiddleware> = [
    responseLoggerMiddleware,
  ];
  protected headers: RequestHeader = {
    'Content-type': 'application/json',
    'Accept-Language': 'ru',
  };
  protected abstract path: string;
  protected abstract requestParameters: Params;
  protected readonly appName: string = publicConfig?.APP_NAME || '';
  protected readonly axios: AxiosInstance;
  private readonly cancelTokenSource = axios.CancelToken.source();
  protected readonly excludeTraceRoutes = ['/api/healthcheck'];

  protected constructor(
    apiUrl: string,
    internalApiUrl?: string,
    additionalHeaders?: RequestHeader,
    requestConfigs?: AxiosRequestConfig
  ) {
    if (internalApiUrl && !!process) {
      this.apiUrl = internalApiUrl;
    } else {
      this.apiUrl = apiUrl;
    }
    this.requestConfig = this.getConfig(additionalHeaders, requestConfigs);
    this.axios = axios.create(this.requestConfig);
  }

  public get(): Promise<AxiosResponse<RequestResponse>> {
    this.setMiddlewares();
    this.setAdapter();

    if (this.excludeTraceRoutes.includes(this.path)) {
      return this.axios.put(this.setRequestUrl(), this.requestParameters, {
        params: this.setRequestParameters(this.requestParameters),
      });
    }

    return tracer.startActiveSpan(this.path, async (span) => {
      try {
        const traceHeaders = {};
        propagation.inject(context.active(), traceHeaders);
        if (this.requestConfig.headers?.['x-request-id']) {
          span.setAttribute(
            'x-request-id',
            this.requestConfig.headers?.['x-request-id']
          );
        }
        if (this.requestConfig.headers?.['x-forwarded-for']) {
          span.setAttribute(
            'x-forwarded-for',
            this.requestConfig.headers?.['x-forwarded-for']
          );
        }

        return await this.axios.get(this.setRequestUrl(), {
          params: this.setRequestParameters(this.requestParameters),
          headers: {
            ...this.requestConfig.headers,
            ...traceHeaders,
          },
        });
      } finally {
        span.end();
      }
    });
  }

  public post(): AxiosPromise<RequestResponse> {
    this.setMiddlewares();
    this.setAdapter();

    return this.axios.post(this.setRequestUrl(), this.requestParameters, {
      params: this.setRequestParameters(),
    });
  }

  public patch(): AxiosPromise<RequestResponse> {
    this.setMiddlewares();
    this.setAdapter();

    return this.axios.patch(this.setRequestUrl(), this.requestParameters, {
      params: this.setRequestParameters(),
    });
  }

  public put(): AxiosPromise<RequestResponse> {
    this.setMiddlewares();
    this.setAdapter();

    return this.axios.put(this.setRequestUrl(), this.requestParameters, {
      params: this.setRequestParameters(),
    });
  }

  public options(): AxiosPromise<RequestResponse> {
    this.setMiddlewares();
    this.setAdapter();

    return this.axios.options(this.setRequestUrl(), {
      data: this.setRequestParameters(this.requestParameters),
    });
  }

  public delete(): AxiosPromise<RequestResponse> {
    this.setMiddlewares();
    this.setAdapter();

    return this.axios.delete(this.setRequestUrl(), {
      data: this.setRequestParameters(this.requestParameters),
    });
  }

  public cancel(message?: RequestErrorMessages): void {
    this.cancelTokenSource.cancel(message);
  }

  protected getConfig(
    additionalHeaders?: RequestHeader,
    requestConfigs?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const headers = Object.assign({}, this.headers, additionalHeaders);

    return {
      headers,
      withCredentials: true,
      baseURL: this.apiUrl,
      cancelToken: this.cancelTokenSource.token,
      ...requestConfigs,
    };
  }

  protected setMiddlewares(): void {
    this.requestMiddlewares.forEach((middleware) => {
      this.axios.interceptors.request.use(middleware);
    });
    this.responseMiddlewares.forEach((middleware) => {
      this.axios.interceptors.response.use(middleware, this.errorMiddleware);
    });
  }

  private setAdapter(): void {
    if (!!this.adapter) {
      const errorAdapter = this.errorAdapter || commonErrorAdapter;
      this.axios.interceptors.response.use(
        this.adapter as unknown as ResponseMiddleware,
        errorAdapter
      );
    }
  }

  private setRequestParameters(
    params?: Params
  ): RequestParametersModel<Params> {
    if (!!this.appName) {
      return {
        ...params,
        site: this.appName,
      } as RequestParametersModel<Params>;
    }

    return params as RequestParametersModel<Params>;
  }

  private setRequestUrl(): string {
    return `${this.apiUrl}${this.path}`;
  }

  protected errorMiddleware(error: Error): Promise<Error> {
    return Promise.reject(error);
  }
}
