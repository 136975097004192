import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '../../user';
import {
  getIsUserPositionsLoaded,
  getIsUserPositionsLoading,
} from './userPosition.selectors';
import { requestUserPositions } from './userPositions.actions';
import { UserPositionsLoaderProps } from './userPosition.model';

export const UserPositionsLoaderContainer = ({
  children,
}: UserPositionsLoaderProps): ReactElement => {
  const isLoading = useSelector(getIsUserPositionsLoading);
  const isLoaded = useSelector(getIsUserPositionsLoaded);
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      requestUserPositions(dispatch, user?.career?.companyId);
    }
  }, []);

  return <>{children}</>;
};
