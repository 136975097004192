import React, { ReactElement, useEffect } from 'react';

import { authorizationService } from './authorization.service';
import { AuthorizationContainerProps } from './authorization.model';
import { Nullable } from '../../models';
import { useUser } from '../user';

type ContentProps = {
  children: ReactElement;
};

const Content = ({ children }: ContentProps): ReactElement => {
  return <>{children}</>;
};

export const AuthorizationContainer = ({
  children,
  shouldLogin = true,
}: AuthorizationContainerProps): Nullable<ReactElement> => {
  const { user } = useUser();
  useEffect(() => {
    authorizationService.init();
    if (shouldLogin && !user) {
      authorizationService.login();
    }
  }, []);
  return !!user || !shouldLogin ? <Content children={children} /> : null;
};
