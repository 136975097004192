import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconUploadComponent = ({
  width = 48,
  height = 48,
  strokeWidth = '3.5',
  fillColor = 'none',
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill={fillColor}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M32 34h3a9 9 0 1 0 0-18h-.414C33.28 11.383 29.036 8 24 8c-5.035 0-9.28 3.383-10.586 8H13a9 9 0 1 0 0 18h3M24 39V19m0 0-7 7m7-7 7 7"
      />
    </svg>
  );
};
