import React, { ReactElement } from 'react';

import { SelectOptionsProps } from './select.model';
import { SelectStyledOption } from './select.styled';

export const SelectOptionsContainer = ({
  options,
  handleClick,
  activeOption,
}: SelectOptionsProps): ReactElement => (
  <>
    {options.map((option) => (
      <SelectStyledOption
        key={option.key}
        onClick={(): void => {
          handleClick(option);
        }}
        isActive={activeOption.key === option.key}
      >
        {option.value}
      </SelectStyledOption>
    ))}
  </>
);
