import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  getTextMaxLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import {
  FormSubscriptionContentTypes,
  FormSubscriptionInitialValues,
  FormSubscriptionValidationSchema,
} from './formSubscription.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';

export const getFormB2BWithoutCompensationInitialValues =
  (): FormSubscriptionInitialValues => ({
    withPromoCode: false,
    promoCode: '',
  });

export function getFormB2BWithoutCompensationValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean
): FormSubscriptionValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected
      ? {
          withPromoCode: Yup.boolean().optional(),
          promoCode: Yup.string().when('withPromoCode', {
            is: true,
            then: (promoCode) =>
              promoCode
                .required(translations?.input_required_error_label)
                .max(255, getTextMaxLengthValidationMessage(255, translations))
                .trim(),
          }),
        }
      : {}),
  };

  return Yup.object().shape(validationObject);
}

export const pushFormSubscriptionSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  formContent: FormSubscriptionContentTypes,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = [OrderFormEventsValues.subscription];
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  switch (formContent) {
    case FormSubscriptionContentTypes.subscription:
      eventValueOptions.push(OrderFormEventsValues.noTabs);
      eventValueOptions.push(
        course.subscriptionAccess?.withHomeworks
          ? OrderFormEventsValues.withHomework
          : OrderFormEventsValues.noHomework
      );
      break;
    case FormSubscriptionContentTypes.fullAccess:
      eventValueOptions.push(
        showPaymentTabs
          ? OrderFormEventsValues.withTabs
          : OrderFormEventsValues.noTabs
      );
      switch (paymentContent) {
        case UserOrderFormPaymentContentTypes.consultation:
          eventValueOptions.push(OrderFormEventsValues.consultation);
          break;
        case UserOrderFormPaymentContentTypes.onlinePay:
          eventValueOptions.push(OrderFormEventsValues.paymentOnline);
          break;
      }
      break;
  }

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};

export const pushFormSubscriptionPaymentEvent = (
  course: CourseModel,
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  const eventValueOptions = [
    OrderFormEventsValues.subscription,
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
    OrderFormEventsValues.paymentOnline,
  ];

  orderFormEvent(
    `${OrderFormEvents.formEnrollGoToOnlinePay}`,
    eventValueOptions,
    CommonEventTypes.coursesEvents,
    eventPosition,
    course
  );
};
