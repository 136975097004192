import React, { forwardRef, ReactElement, useEffect, useRef } from 'react';
import { useIntersectionObserver } from '../../../hooks';
import { TableEmptyContainer } from '../tableEmpty';

import { TableBodyProps } from './tableBody.model';
import { getTableRows } from './tableBody.service';
import {
  TableBodyDataStyled,
  TableBodyRowStyled,
  TableBodyStyled,
} from './tableBody.styled';

export const TableBodyContainer = forwardRef<
  HTMLTableSectionElement,
  TableBodyProps
>(
  (
    {
      rows,
      prepareRow,
      handlePagination,
      handleRowClick,
      hasNextPage,
      hasEndlessPagination,
      isLoading,
      isPaginating,
      emptyContent,
      height,
      paginationLoader,
      loader,
    },
    ref
  ): ReactElement => {
    const refFooter = useRef<HTMLTableRowElement | null>(null);

    const entryFooter = useIntersectionObserver(refFooter, {});

    useEffect(() => {
      const paginate =
        typeof handlePagination === 'function' &&
        hasEndlessPagination &&
        entryFooter?.isIntersecting &&
        !isPaginating &&
        !isLoading &&
        hasNextPage;

      if (paginate) {
        handlePagination();
      }
    }, [entryFooter?.isIntersecting]);

    const tableRows =
      !!rows.length &&
      !isLoading &&
      getTableRows(
        rows,
        prepareRow,
        TableBodyRowStyled,
        TableBodyDataStyled,
        handleRowClick
      );

    const tableEmptyContent = !rows.length && !isLoading && (
      <TableEmptyContainer emptyContent={emptyContent} />
    );

    return (
      <TableBodyStyled height={height} scroll={isLoading} ref={ref}>
        {tableRows}
        {tableEmptyContent}
        {isLoading && loader}
        {isPaginating && paginationLoader}
        {hasEndlessPagination && (
          <tr style={{ marginTop: '-600px' }} ref={refFooter} />
        )}
      </TableBodyStyled>
    );
  }
);

TableBodyContainer.displayName = 'TableBodyContainer';
