import styled, { css } from 'styled-components';
import { customizeScrollBar } from '../../styles';
import { TableStyledProps, TableWrapperStyledProps } from './table.model';

export const BORDER = '1px solid rgba(0, 0, 0, 0.08)';

export const TableWrapperStyled = styled.div<TableWrapperStyledProps>`
  display: grid;
  ${({ hasHorizontalScroll }) =>
    hasHorizontalScroll &&
    css`
      position: relative;
      overflow: hidden;
    `}
`;

export const TableStyled = styled.table<TableStyledProps>`
  border-collapse: collapse;
  display: grid;

  ${({ hasHorizontalScroll }) =>
    hasHorizontalScroll &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
    `}

  ${({ hasHorizontalScroll }) =>
    hasHorizontalScroll && customizeScrollBar(0, 0, 4, false)};

  &::-webkit-scrollbar-track {
    margin-left: 20px;
    margin-right: 20px;
  }

  &::-webkit-scrollbar {
    height: 4px;
  }

  ${({ showLeftBlur }) =>
    showLeftBlur &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 92px;
        border-radius: 16px;
        z-index: 2;
        background: linear-gradient(
          90deg,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `};

  ${({ showRightBlur }) =>
    showRightBlur &&
    css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 92px;
        border-radius: 16px;
        z-index: 2;
        background: linear-gradient(
          270deg,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `};
`;
