import styled from 'styled-components';

import { fontSettings } from '../../../../../styles';

export const ProfilePhotoSectionStyled = styled.div`
  display: flex;
  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
   align-items: center;
   margin-bottom: 24px;`
      : ''}
  gap: ${({ theme }): string => (theme.IS_MOBILE ? '16px' : '20px')};
`;

export const ProfilePhotoSideStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProfilePhotoHeadingStyled = styled.h2`
  ${({ theme }): string =>
    fontSettings(
      theme.IS_MOBILE ? theme.FONT.SIZE_20 : theme.FONT.SIZE_22,
      theme.FONT
    )};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 600;
  margin: 0;
`;
