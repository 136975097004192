import * as Yup from 'yup';
import { TranslationModel } from '../../../../models';
import {
  FormCareerInitialValues,
  FormCareerValidationSchema,
} from './formCareer.model';
import { Department } from '../../../departments';
import { UserPositionWithOptions } from '../../../user';

export const getFormCareerInitialValues = (
  departments: Department[],
  positions: UserPositionWithOptions[],
  company?: string
): FormCareerInitialValues => {
  return {
    company: company || '',
    positionUuid: positions.find(({ isDefault }) => isDefault)?.key || '',
    departmentUuid: departments.find(({ isDefault }) => isDefault)?.key || '',
  };
};

export const getFormCareerValidationSchema = (
  translations: TranslationModel
): FormCareerValidationSchema => {
  return Yup.object().shape({
    company: Yup.string().optional(),
    departmentUuid: Yup.string().required(
      translations.input_required_error_label
    ),
    positionUuid: Yup.string().required(
      translations.input_required_error_label
    ),
  });
};
