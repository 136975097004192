import { NextRouter } from 'next/router';
import {
  CareerModel,
  CompanyMBMColors,
  CompanyUuidEnum,
  ITheme,
  objectService,
  publicConfig,
  QueryParams,
  ShowcaseAggregatePathEnum,
} from '@lerna-core';
import { DiscountProgramAggregatePathEnum } from '@features/common/discountProgram';
import { CoursesPageWithErrorModel } from './courses.model';

export const getNextCoursesPageAggregateLink = (
  currentLink: string,
  career?: CareerModel,
  router?: NextRouter
): string => {
  const url = new URL(currentLink);
  const queryObject = objectService.getObjectFromQueryString(url.search);
  delete queryObject[QueryParams.site];
  const queryString = objectService.getQueryStringFromObject(queryObject);
  const discountProgramQuery = router?.query[QueryParams.discountProgram];

  if (discountProgramQuery) {
    if (career) {
      return `${publicConfig.API_GATEWAY_URL}${DiscountProgramAggregatePathEnum.aggregateAuth}${discountProgramQuery}?${queryString}`;
    }

    return `${publicConfig.API_GATEWAY_URL}${DiscountProgramAggregatePathEnum.aggregate}${discountProgramQuery}?${queryString}`;
  }

  if (career?.companyId) {
    return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregateAuthWithToken}/${career.companyId}?${queryString}`;
  } else if (career) {
    return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregateAuth}?${queryString}`;
  }

  return `${publicConfig.API_GATEWAY_URL}${ShowcaseAggregatePathEnum.aggregate}?${queryString}`;
};

export const getCoursesCustomTheme = (
  props: CoursesPageWithErrorModel,
  theme: ITheme
): ITheme => {
  const discountColors = props.discountProgram?.colors;
  const companyId = props.company?.uuid;

  switch (companyId) {
    case CompanyUuidEnum.mbm:
      return {
        ...theme,
        COLOR: {
          ...theme.COLOR,
          PURPLE: CompanyMBMColors.secondaryColor,
          BUTTON_PURPLE_TEXT: '#FFFFFF',
          PURPLE_SUBSCRIPTION: CompanyMBMColors.secondaryColor,
        },
      };
    default:
      return {
        ...theme,
        COLOR: {
          ...theme.COLOR,
          PURPLE: discountColors?.buttonAccentBackground || theme.COLOR.PURPLE,
          BUTTON_PURPLE_TEXT:
            discountColors?.buttonAccentColor || theme.COLOR.BUTTON_PURPLE_TEXT,
        },
      };
  }
};
