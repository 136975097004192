import styled from 'styled-components';
import { CourseSubTitleContainerStyled } from '../courseSubTitle/courseSubTitle.styled';
import { CourseFormButtonContainerStyled } from '../courseFormButton/courseFormButton.styled';

export const CourseHeadContainerStyled = styled.div`
  overflow: hidden;
`;

export const CourseHeadTitleWrapStyled = styled.div`
  margin-right: 20px;

  ${CourseSubTitleContainerStyled} {
    margin-top: 4px;
  }
`;

export const CourseHeadRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CourseHeadFormButtonStyled = styled.div`
  margin-top: 24px;

  ${CourseFormButtonContainerStyled} {
    padding: 0;
    border-radius: unset;
    box-shadow: unset;
  }
`;
