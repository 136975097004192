import { AppContext } from 'next/app';
import { urlService } from '../../services/urlService';
import { cookieService } from '../../services/cookie';
import { USER_LOCALE_COOKIE_NAME } from '../../features/switchLanguage';

export const redirectToLocale = (context: AppContext): void => {
  if (urlService.checkNeedRedirectToLocale(context)) {
    context?.ctx?.res?.writeHead(301, {
      Location: urlService.getFullUrlFromReq(
        context.router,
        cookieService.getCookie(
          USER_LOCALE_COOKIE_NAME,
          context?.ctx?.req?.headers.cookie || ''
        )
      ),
    });
    context?.ctx?.res?.end();
  }
};
