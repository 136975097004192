import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  AppService,
  authorizationService,
  CommonEventTypes,
  getSimpleEvent,
  pushDataLayer,
  serverAuthorizationService,
} from '@lerna-core';
import { getHeaderEventPositionByPageName } from '@features/common/header';
import { PageNameContext } from '@contexts';
import { LoginLinkEvents } from './loginLink.analytics';
import { LoginLinkContainerProps } from './loginLink.model';
import { LoginLinkContainerStyled } from './loginLink.styled';

export const LoginLinkContainer = ({
  children,
}: LoginLinkContainerProps): ReactElement => {
  const pageName = useContext(PageNameContext);
  const router = useRouter();

  const loginLink = AppService.isClientSide
    ? authorizationService.getLoginLink()
    : serverAuthorizationService.getLoginLink(false, undefined, router);

  const eventPosition = useMemo(
    () => getHeaderEventPositionByPageName(pageName),
    []
  );

  const handleClick = useCallback((): void => {
    pushDataLayer(
      getSimpleEvent(
        LoginLinkEvents.clickIconAuthorization,
        CommonEventTypes.overallEvents,
        eventPosition
      )
    );
  }, []);

  return (
    <LoginLinkContainerStyled onClick={handleClick} href={loginLink}>
      {children}
    </LoginLinkContainerStyled>
  );
};
