import { LogoResponseModel } from '../companyEditLogo.model';
import { GetLogoParams, LogoGetClient } from './logoGet.client';

export async function logoGetClientProvider(
  params: GetLogoParams,
  host?: string
): Promise<LogoResponseModel> {
  const response = await new LogoGetClient(host, params).get();

  return response as unknown as Promise<LogoResponseModel>;
}
