import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const PricesB2B2CStyled = styled.div``;

export const WrapperStyled = styled.div`
  margin-bottom: 24px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;
