import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const ProfTestContainerStyled = styled.div`
  margin-bottom: 56px;
  padding: 20px 40px;
  color: ${(props): string => props.theme.COLOR.WHITE};
  position: relative;
  background: linear-gradient(90deg, #2f1e5e 0%, #54191e 100%);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  overflow: hidden;

  button {
    margin-left: auto;

    svg {
      transform: rotate(90deg);
    }
  }

  ${breakpoint(
    'largeDown',
    css`
      justify-content: center;
      flex-direction: column;
      padding: 20px 20px 40px 20px;

      button {
        margin-left: 0;
      }
    `
  )};
`;

export const ProfTestLinkStyled = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ProfTestTitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_22, props.theme.FONT)};
  font-weight: 600;

  ${breakpoint(
    'largeDown',
    css`
      text-align: center;
    `
  )};

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_18, props.theme.FONT)};
    `
  )};
`;
