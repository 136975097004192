import React, { ReactElement } from 'react';
import {
  IconHighlightStyled,
  IconInfoStyled,
  ItemContentStyled,
  SubtitleStyled,
  TitleStyled,
  WrapperStyled,
} from './itemContent.styled';
import { IconHighlightComponent, IconInfoCircledComponent } from '@lerna-core';
import { ItemContentContainerProps } from './itemContent.model';

export const ItemContentContainer = ({
  title,
  subtitle,
  showPopupTrigger,
  handleOpenPopup,
}: ItemContentContainerProps): ReactElement => (
  <ItemContentStyled>
    <IconHighlightStyled>
      <IconHighlightComponent />
    </IconHighlightStyled>
    <WrapperStyled>
      <TitleStyled>
        {title}
        {showPopupTrigger && (
          <IconInfoStyled onClick={handleOpenPopup}>
            <IconInfoCircledComponent />
          </IconInfoStyled>
        )}
      </TitleStyled>
      <SubtitleStyled>{subtitle}</SubtitleStyled>
    </WrapperStyled>
  </ItemContentStyled>
);
