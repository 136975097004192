import { breakpoint } from '@lerna-core';
import styled, { css } from 'styled-components';
import { SearchStyledProps } from './search.model';

export const SearchStyled = styled.div<SearchStyledProps>`
  width: 660px;
  margin: 0 auto;
  text-align: center;

  ${({ isMobile }): ReturnType<typeof css> | string => {
    return breakpoint(
      'mediumDown',
      css`
        width: 100%;
        display: flex;
        margin: 0 0 20px;
      `,
      isMobile
    );
  }}
`;
