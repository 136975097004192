import styled from 'styled-components';
import { PageScrollArrowStyledProps } from './pageScrollArrow.model';

export const PageScrollArrowStyled = styled.div<PageScrollArrowStyledProps>`
  position: fixed;
  bottom: 84px;
  right: 20px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_PAGE_LAYER - 1};
  background-color: ${({ theme }): string => theme.COLOR.ICON_GREY_COLOR};

  ${({ withWidget }): string => (!withWidget ? 'bottom: 20px;' : '')}
`;
