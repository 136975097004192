import styled from 'styled-components';
import { fontSettings, transition } from '@lerna-core';
import {
  ArrowStyledProps,
  FilterOptionsShowAllContainerStyledProps,
} from './filterOptionsShowAll.model';

export const FilterOptionsShowAllContainerStyled = styled.div<FilterOptionsShowAllContainerStyledProps>`
  display: flex;
  align-items: center;
  color: ${({ theme, filterColor }): string =>
    filterColor ? `${filterColor}` : theme.COLOR.PRIMARY[500]};
  font-weight: 500;
  margin-top: 12px;
  cursor: pointer;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  ${transition('opacity')}

  &:hover {
    opacity: 0.8;
  }
`;

export const ArrowStyled = styled.div<ArrowStyledProps>`
  display: flex;
  margin-left: 8px;

  ${({ showAllFilterOptions }): string =>
    showAllFilterOptions ? 'transform: rotate(-180deg);' : ''}
`;
