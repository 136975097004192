import { VendorEnum } from './vendors.model';

/**
 * Get a custom color of vendor
 * */
export const getVendorColor = (vendor: string): string => {
  switch (vendor) {
    case VendorEnum.skillbox:
      return '#3A35FF';

    case VendorEnum.geekbrains:
      return '#6435D1';

    case VendorEnum.skillfactory:
      return '#35B34A';

    default: {
      return '#ffffff';
    }
  }
};
