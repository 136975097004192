import { useEffect, useMemo, useState } from 'react';
import { DepartmentsHookModel } from './departments.model';
import { departmentsByCompanyClientProvider } from './departments.clientProvider';
import { SelectOption } from '../select';

export function useCompanyDepartments(
  companyId?: string
): DepartmentsHookModel {
  const [departments, setDepartments] = useState<SelectOption[]>([]);
  const [hasDefaultDepartment, setHasDefaultDepartment] =
    useState<boolean>(false);

  useEffect(() => {
    if (companyId) {
      departmentsByCompanyClientProvider(companyId).then((departments) => {
        const hasDefaultDepartment = departments.some(
          (department) => department.isDefault
        );
        setDepartments(departments);
        setHasDefaultDepartment(hasDefaultDepartment);
      });
    }
  }, [companyId]);

  return useMemo(
    () => ({ departments, hasDefaultDepartment }),
    [departments, hasDefaultDepartment]
  );
}
