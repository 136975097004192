import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const BreadcrumbsItem = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  a {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};

    &:hover {
      color: ${({ theme }): string => theme.COLOR.TEXT['500']};
    }
  }
`;
