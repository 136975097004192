import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

type CourseSubTitleContainerStyledProps = {
  isMobile: boolean;
};
export const CourseSubTitleContainerStyled = styled.div<CourseSubTitleContainerStyledProps>`
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  ${(props): string => fontSettings(props.theme.FONT.SIZE_22, props.theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}
    `
  )}
`;
