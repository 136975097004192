import { ContentStyles } from '../popup.model';
import { css } from 'styled-components';

export const getContentStyles = (
  contentStyles?: ContentStyles
): ReturnType<typeof css> | string => {
  return contentStyles
    ? css`
        ${contentStyles.paddingTop || contentStyles.paddingTop === 0
          ? `padding-top: ${contentStyles.paddingTop}px;`
          : ''}
        ${contentStyles.paddingRight || contentStyles.paddingRight === 0
          ? `padding-right: ${contentStyles.paddingRight}px;`
          : ''}
        ${contentStyles.paddingBottom || contentStyles.paddingBottom === 0
          ? `padding-bottom: ${contentStyles.paddingBottom}px;`
          : ''}
        ${contentStyles.paddingLeft || contentStyles.paddingLeft === 0
          ? `padding-left: ${contentStyles.paddingLeft}px;`
          : ''}
      `
    : '';
};
