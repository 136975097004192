import { ReactElement } from 'react';
import * as S from './registerWarning.styled';
import { useTranslations } from '../../translations';
import { IconNextComponent } from '../../icons';
import { useRegisterPopupContext } from '../registerPopup';
import { Nullable } from '../../../models';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import { RegisterEvents, RegisterPositions } from '../register.analytics';
import { CommonEventTypes } from '../../../analytics';

export const RegisterWarningContainer = (): Nullable<ReactElement> => {
  const { isWarningVisible, handleOpenRegister } = useRegisterPopupContext();
  const translations = useTranslations();

  if (!isWarningVisible) {
    return null;
  }

  const handleWarningClick = () => {
    pushDataLayer(
      getSimpleEvent(RegisterEvents.warningClick, CommonEventTypes.registration)
    );
    handleOpenRegister(RegisterPositions.registerWarning);
  };

  return (
    <S.RegisterWarning onClick={handleWarningClick}>
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" fill="#fff" />
        <path
          d="M12 7v5"
          stroke="#333"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="12" cy="16" r="1" fill="#333" />
      </svg>
      <S.RegisterWarningText>
        {translations.register_warning_text}
      </S.RegisterWarningText>
      <S.RegisterWarningIcon>
        <IconNextComponent height={16} width={16} />
      </S.RegisterWarningIcon>
    </S.RegisterWarning>
  );
};
