import React, { ReactElement } from 'react';

import { AvatarDefaultContainer } from './avatarDefault';
import { AvatarImageContainer } from './avatarImage';
import { UserAvatarProps } from './userAvatar.model';
import { UserAvatarStyled } from './userAvatar.styled';

const Content = ({
  personal,
  size,
  isRound,
  mediaDomain,
}: UserAvatarProps): ReactElement => {
  if (personal.avatar) {
    return (
      <AvatarImageContainer
        size={size}
        isRound={isRound}
        mediaDomain={mediaDomain}
        imageResource={personal.avatar}
      />
    );
  }

  return (
    <AvatarDefaultContainer
      size={size}
      firstName={personal.firstName}
      lastName={personal.lastName}
      isRound={isRound}
    />
  );
};

export const UserAvatarContainer = ({
  personal,
  size,
  isRound,
  pointer = false,
  handleClick,
  mediaDomain,
  className,
}: UserAvatarProps): ReactElement => {
  return (
    <UserAvatarStyled
      pointer={typeof handleClick === 'function' || pointer}
      onClick={handleClick}
      className={className}
    >
      <Content
        mediaDomain={mediaDomain}
        size={size}
        personal={personal}
        isRound={isRound}
      />
    </UserAvatarStyled>
  );
};
