import { css } from 'styled-components';

export const transition = (
  property = '',
  transitionDuration = 0.3
): ReturnType<typeof css> =>
  css`
    transition-duration: ${transitionDuration}s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    ${property != '' && `transition-property: ${property};`}
  `;
