import {
  ApiCoursesSubscriptionAccessModel,
  CoursesSubscriptionAccessModel,
} from '../models';
import { coursesSubscriptionAdapter } from './coursesSubscription.adapter';

export const coursesSubscriptionAccessAdapter = (
  subscriptionAccess: ApiCoursesSubscriptionAccessModel
): CoursesSubscriptionAccessModel => ({
  id: subscriptionAccess.id,
  expiredAt: subscriptionAccess.expired_at,
  coursesPerPeriod: subscriptionAccess.courses_per_period,
  subscription: coursesSubscriptionAdapter(subscriptionAccess.subscription),
  withHomeworks: subscriptionAccess.with_homeworks,
});
