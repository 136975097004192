import styled from 'styled-components';

export const LoaderEmptyCircleContainerStyled = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

export const LoaderEmptyCircleStyled = styled.div`
  width: 100px;
  height: 100px;
  display: inline-block;
  animation: spinner-rotate 2s linear infinite;

  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stroke-rotate {
    0%,
    25%,
    25.0001%,
    50%,
    50.0001%,
    75%,
    75.0001%,
    100% {
      stroke-dashoffset: 268px;
    }
    12.5%,
    12.5001%,
    37.5%,
    37.5001%,
    62.5%,
    62.5001%,
    87.5%,
    87.5001% {
      stroke-dashoffset: 56px;
    }
    0%,
    12.5% {
      transform: rotate(0);
    }
    12.5001%,
    25% {
      transform: rotateX(180deg) rotate(72.5deg);
    }
    25.0001%,
    37.5% {
      transform: rotate(270deg);
    }
    37.5001%,
    50% {
      transform: rotateX(180deg) rotate(161.5deg);
    }
    50.0001%,
    62.5% {
      transform: rotate(180deg);
    }
    62.5001%,
    75% {
      transform: rotateX(180deg) rotate(251.5deg);
    }
    75.0001%,
    87.5% {
      transform: rotate(90deg);
    }
    87.5001%,
    100% {
      transform: rotateX(180deg) rotate(341.5deg);
    }
  }
`;

export const LoaderEmptyCircleIconStyled = styled.svg`
  width: 100%;
  height: 100%;
`;

export const LoaderEmptyCircleCircleStyled = styled.circle`
  fill: none;
  animation-name: stroke-rotate;
  stroke-dasharray: 282px;
  stroke-width: 10%;
  transform-origin: center;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-iteration-count: infinite;
  stroke: currentColor;
`;
