import React, { ReactElement, useMemo } from 'react';

import {
  getSimpleEvent,
  publicConfig,
  pushDataLayer,
  staticSrc,
  urlService,
} from '../../services';
import { ButtonColor } from '../button';
import { HelpButtonContainer } from '../buttons';
import { HelpContainerProps } from './help.model';
import {
  HelpButtonWrapperStyled,
  HelpLineStyled,
  HelpLinkStyled,
  HelpWrapperStyled,
} from './help.styled';
import { HelpFaqContainer } from './helpFaq';
import {
  CommonEventTypes,
  getProjectEventPosition,
  UsagePositions,
} from '../../analytics';
import { HelpEvents } from './help.analytics';

export const HelpContainer = ({
  helpFormInputColor,
  helpFormInputBordered,
  personal,
  translations,
}: HelpContainerProps): ReactElement => {
  const eventPosition = useMemo(
    () => getProjectEventPosition(UsagePositions.help),
    []
  );
  const handleHelpLinkClick = (): void => {
    pushDataLayer(
      getSimpleEvent(
        HelpEvents.clickLinkOferta,
        CommonEventTypes.overallEvents,
        eventPosition
      )
    );
  };

  return (
    <>
      <HelpWrapperStyled>
        <HelpLinkStyled
          href={`//${publicConfig.BASE_DOMAIN}${staticSrc.ofertaPdf}`}
          {...urlService.setTargetBlankOptions()}
          onClick={handleHelpLinkClick}
        >
          {translations?.terms_service_label}
        </HelpLinkStyled>
      </HelpWrapperStyled>
      <HelpWrapperStyled>{translations?.help_support_label}</HelpWrapperStyled>
      <HelpLinkStyled href={`tel:${translations?.company_phone_label}`}>
        {translations?.company_phone_label}
      </HelpLinkStyled>
      <HelpLineStyled />

      <HelpFaqContainer />

      <HelpWrapperStyled>{translations?.help_faq_label}</HelpWrapperStyled>

      <HelpButtonWrapperStyled>
        <HelpButtonContainer
          title={translations?.contact_us_button_title}
          color={ButtonColor.white}
          formInputColor={helpFormInputColor}
          formInputBordered={helpFormInputBordered}
          personal={personal}
          translations={translations}
          usagePosition={UsagePositions.help}
        />
      </HelpButtonWrapperStyled>
    </>
  );
};
