import { ParsedUrlQuery } from 'querystring';
import { personalPath, projectSubDomains } from '../../constants';
import { UserAggregateModel } from '../../features';
import { urlService } from '../../services';
import { Nullable } from '../../models';

export const needRedirectUser = (user: Nullable<UserAggregateModel>) =>
  !!user?.personal &&
  !user?.personal.isFulfilled &&
  !user.career?.isPersonalDataFillOptional;

export const getUserRedirectLink = (
  query: ParsedUrlQuery,
  redirectUrl?: string
): string => {
  const path = redirectUrl
    ? `${personalPath.register}?redirectUrl=${encodeURIComponent(redirectUrl)}`
    : personalPath.register;
  const registerPageUrl = `${urlService.getFullUrlToSubDomain(
    projectSubDomains.personal,
    path
  )}`;

  return urlService.setQueryParamsToLink(registerPageUrl, query);
};
