import React, { ReactElement } from 'react';
import {
  PricesTotalStyled,
  ValueStyled,
  ValueWithDiscountStyled,
} from './pricesTotal.styled';
import { PricesTotalContainerProps } from './pricesTotal.model';
import { Nullable } from '@lerna-core';

export const PricesTotalContainer = ({
  value,
  isFreeCourse,
  valueWithDiscount,
  hasDiscounts,
}: PricesTotalContainerProps): Nullable<ReactElement> => {
  const showValue = hasDiscounts && !isFreeCourse;

  if (!value) return null;

  return (
    <PricesTotalStyled>
      <ValueWithDiscountStyled>{valueWithDiscount}</ValueWithDiscountStyled>
      {showValue && <ValueStyled>{value}</ValueStyled>}
    </PricesTotalStyled>
  );
};
