import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBookHouseComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9796 1.00023C11.8933 1.00285 11.8081 1.02036 11.7277 1.05199L1.47769 5.05199C1.38231 5.08503 1.29459 5.13698 1.21977 5.20473C1.14495 5.27247 1.08457 5.35462 1.04225 5.44626C0.999935 5.53789 0.976546 5.63713 0.973489 5.73802C0.970431 5.8389 0.987767 5.93937 1.02446 6.0334C1.06115 6.12743 1.11644 6.21309 1.18702 6.28524C1.2576 6.3574 1.34202 6.41456 1.43522 6.45332C1.52842 6.49207 1.62848 6.51162 1.72941 6.51079C1.83034 6.50995 1.93006 6.48876 2.02261 6.44847L12.0001 2.55492L21.9777 6.44847C22.0702 6.48876 22.17 6.50996 22.2709 6.51079C22.3718 6.51162 22.4719 6.49207 22.5651 6.45332C22.6583 6.41456 22.7427 6.3574 22.8133 6.28524C22.8839 6.21309 22.9391 6.12743 22.9758 6.0334C23.0125 5.93937 23.0299 5.8389 23.0268 5.73802C23.0238 5.63713 23.0004 5.53789 22.958 5.44626C22.9157 5.35462 22.8554 5.27247 22.7805 5.20473C22.7057 5.13698 22.618 5.08503 22.5226 5.05199L12.2726 1.05199C12.1794 1.01535 12.0798 0.997749 11.9796 1.00023ZM4.75015 7.50023C3.79267 7.50023 3.00015 8.29275 3.00015 9.25023V19.2502C3.00015 20.2077 3.79267 21.0002 4.75015 21.0002H10.2824C10.6262 21.5961 11.2618 22.0002 12.0001 22.0002C12.7385 22.0002 13.3741 21.5961 13.7179 21.0002H19.2501C20.2076 21.0002 21.0001 20.2077 21.0001 19.2502V9.25023C21.0001 8.29275 20.2076 7.50023 19.2501 7.50023H14.0001C13.129 7.50023 12.3823 7.93909 11.8771 8.57836C11.2812 7.92657 10.445 7.50023 9.50015 7.50023H4.75015ZM4.75015 9.00023H9.50015C10.4661 9.00023 11.2501 9.78432 11.2501 10.7502V19.5002H4.75015C4.60263 19.5002 4.50015 19.3978 4.50015 19.2502V9.25023C4.50015 9.10271 4.60263 9.00023 4.75015 9.00023ZM14.0001 9.00023H19.2501C19.3977 9.00023 19.5001 9.10271 19.5001 9.25023V19.2502C19.5001 19.3978 19.3977 19.5002 19.2501 19.5002H12.7501V10.7502V10.2502C12.7501 9.55112 13.301 9.00023 14.0001 9.00023Z"
      fill={fillColor}
    />
  </svg>
);
