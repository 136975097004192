import React, { ReactElement, useState } from 'react';
import { CompanyContext, defaultCompanyContext } from './company.context';
import { Company, CompanyContainerPropsModel } from './company.model';

export const CompanyContainer = ({
  company,
  children,
}: CompanyContainerPropsModel): ReactElement => {
  const [companyState] = useState<Company | undefined>(
    company || defaultCompanyContext
  );

  return (
    <CompanyContext.Provider value={companyState}>
      {children}
    </CompanyContext.Provider>
  );
};
