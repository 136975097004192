import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../../styles';
import { FooterHover } from '../../../footer.mixin';

export const FooterTopContactsPhoneStyled = styled.a`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.WHITE};
  font-weight: 600;
  margin-bottom: 16px;
  text-decoration: none;

  ${FooterHover}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
