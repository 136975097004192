import { InvalidPhoneMessageContainer } from '@features/common/invalidPhoneMessage';
import { ReferralProgramPopupContainer } from '@features/common/popups';
import {
  browserService,
  ContentWrapperStyled,
  DeviceInfoContext,
  handleOpenPopup,
  Nullable,
  PopupsEnum,
  QueryParams,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CoursesFilterPopupContainer } from './coursesFilterPopup';
import { CoursesListContainer } from './coursesList';
import { useCoursesPageContext } from './coursesPage';
import { CoursesProfTestContainer } from './coursesProfTest';
import { CoursesSeoTextContainer } from './coursesSeoText';
import { CoursesWrapperStyled } from './courses.styled';
import { FiltersContainer } from './coursesFilters';
import { CourseActiveContextProvider } from './coursesActive';

export const CoursesContainer = (): Nullable<ReactElement> => {
  const router = useRouter();
  const dispatch = useDispatch();
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);
  const { user, company, translations } = useCoursesPageContext();

  const isFiltersHiddenOnListing = user?.career?.isFiltersHiddenOnListing;

  const showProfTest = useMemo(() => !company, [company]);
  const showReferralProgramPopup =
    !!router.query[QueryParams.discountProgram] &&
    router.query[QueryParams.referral] === 'true';

  useEffect(() => {
    showReferralProgramPopup &&
      dispatch(handleOpenPopup(PopupsEnum.referralProgramPopup));
  }, []);

  return (
    <CourseActiveContextProvider>
      <ContentWrapperStyled isMobile={isMobile}>
        <CoursesWrapperStyled>
          <FiltersContainer />
          <CoursesListContainer />
        </CoursesWrapperStyled>
        {showProfTest && (
          <CoursesProfTestContainer translations={translations} />
        )}
        <CoursesSeoTextContainer />
        {!isFiltersHiddenOnListing && <CoursesFilterPopupContainer />}
        <ReferralProgramPopupContainer
          isMobile={isMobile}
          translations={translations}
        />
        <InvalidPhoneMessageContainer translations={translations} />
      </ContentWrapperStyled>
    </CourseActiveContextProvider>
  );
};
