import { FormPopupSubTitle } from '@features/common/popups';
import { modalPaddingsWithFooter } from '@features/common/popups/formPopup/formPopup.meta';
import {
  browserService,
  DeviceInfoContext,
  Nullable,
  PopupContainer,
  PopupTitleContainer,
} from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import { FormPopupContainerProps } from './formPopup.model';

export const FormPopupContainer = ({
  children,
  handleClose,
  isOpened,
  footer,
  title,
  subtitle,
}: FormPopupContainerProps): Nullable<ReactElement> => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  if (!isOpened) return null;

  return (
    <PopupContainer
      handleClose={handleClose}
      isOpened={isOpened}
      title={title}
      footer={footer}
      isMobile={isMobile}
      contentStyles={{ paddingBottom: 32 }}
      width={'600px'}
      styleSettings={modalPaddingsWithFooter}
    >
      <>
        <PopupTitleContainer text={title} />
        <FormPopupSubTitle text={subtitle} />
        <>{children}</>
      </>
    </PopupContainer>
  );
};
