import styled from 'styled-components';
import { CourseLogoLabelStyled } from '@features/course/courseLogoLabel';
import { CourseLogoPropsModel } from '@features/course/courseLogo';

export const ListItemLogoStyled = styled.div<CourseLogoPropsModel>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${CourseLogoLabelStyled} {
    margin-top: -12px;
  }
`;

export const ListItemLogoHitTextStyled = styled.div`
  margin-left: 2px;
`;
