import { CourseModel } from '@lerna-core';
import { B2CPricesModel } from './coursePrices.model';

export const getB2CPrices = ({ price }: CourseModel): B2CPricesModel => {
  const startPrice = price.startPrice?.amount;

  const promoCodeDiscountAmount =
    price?.priceWithPromoCodeDiscount?.discount?.amount;
  const vendorDiscountAmount = price?.priceWithVendorDiscount?.discount?.amount;
  const vendorDiscountValue = price?.priceWithVendorDiscount?.discount?.value;
  const hasVendorDiscount = Boolean(
    vendorDiscountValue && parseInt(vendorDiscountValue)
  );

  const bonusPointsDiscount = price.priceWithBonuses?.discount?.amount;
  const hasBonusPointsDiscount = Boolean(
    bonusPointsDiscount && parseInt(bonusPointsDiscount)
  );

  const programDiscountAmount =
    price?.priceWithProgramDiscount?.discount?.amount;
  const programDiscountPercent =
    price?.priceWithProgramDiscount?.discount?.value;
  const hasProgramDiscount = Boolean(
    programDiscountAmount && parseInt(programDiscountAmount)
  );
  const hasPromoCodeDiscount = Boolean(
    promoCodeDiscountAmount && parseInt(promoCodeDiscountAmount)
  );

  const totalPrice = price.price?.amount;

  const totalDiscount = price.price?.discount?.value;
  const hasTotalDiscount = Boolean(totalDiscount && parseInt(totalDiscount));

  const cashBackPercent = price?.cashBack?.percent;
  const cashBackPoints = price?.cashBack?.points;

  const hasInstallment = !!price?.installment;

  const hasDiscounts =
    hasBonusPointsDiscount && hasVendorDiscount && hasProgramDiscount;
  const hasOneOfDiscounts =
    hasBonusPointsDiscount ||
    hasVendorDiscount ||
    hasProgramDiscount ||
    hasPromoCodeDiscount;

  return {
    startPrice,
    hasVendorDiscount,
    vendorDiscountAmount,
    vendorDiscountValue,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasTotalDiscount,
    totalDiscount,
    hasDiscounts,
    hasOneOfDiscounts,
    hasInstallment,
    hasProgramDiscount,
    programDiscountAmount,
    promoCodeDiscountAmount,
    programDiscountPercent,
    cashBackPercent,
    cashBackPoints,
    hasPromoCodeDiscount,
  };
};
