import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultColWidth } from '../customersTransfer.service';
import {
  requestTransferCustomersAllUnallocated,
  requestTransferCustomersUnallocated,
} from '../customersTransfer.actions';
import { getUnallocatedEmptyContent } from './transferLeft.service';
import { TransferLeftStyled } from './transferLeft.styled';
import { useCustomersTransferContext } from '../customersTransfer.context';
import {
  getCustomersTransferSelector,
  getCustomersTransferUnallocatedFiltersSelector,
} from '../customersTransfer.selectors';
import {
  LocationType,
  TransferLeftProps,
  TransferTitleContainer,
} from '../../../transfer';
import { TransferTableContainer } from '../transferTable';

export const TransferLeftContainer = ({
  getSelectedRows,
  leftRows,
  handleCheck,
  leftColumns,
  headerComponent,
  translations,
  unallocatedTopBlock,
}: TransferLeftProps): ReactElement => {
  const customers = useSelector(getCustomersTransferSelector);
  const {
    added,
    removed,
    unallocated: {
      filters,
      meta,
      isLoading,
      isPaginating,
      isSelectedAll,
      isPending: isPendingUnallocatedCustomers,
    },
    allocated: { isPending: isPendingAllocatedCustomers },
  } = customers;

  const isPending =
    isPendingUnallocatedCustomers || isPendingAllocatedCustomers;
  const {
    id,
    isRadioButton,
    customersProvider,
    customersAllProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
    accessors,
  } = useCustomersTransferContext();
  const customersUnallocatedFilters = useSelector(
    getCustomersTransferUnallocatedFiltersSelector
  );

  const handlePagination = (): void => {
    dispatch(
      requestTransferCustomersUnallocated(
        customersProvider,
        {
          ...filters,
          id,
          added,
          removed,
          page: meta.currentPage + 1,
          notRelatedWithSubscriptionId: subscriptionId,
          notRelatedWithBenefitId: benefitId,
          notRelatedWithLibraryId: libraryId,
          notRelatedWithTariffId: tariffId,
        },
        true
      )
    );
  };

  const handleCheckAll = (): void => {
    if (meta.totalPages > meta.currentPage) {
      dispatch(
        requestTransferCustomersAllUnallocated(customersAllProvider, {
          ...filters,
          id,
          added,
          removed,
          limit: 500,
          pages: { current: 0, last: Math.ceil(meta.total / 500) },
          notRelatedWithSubscriptionId: subscriptionId,
          notRelatedWithBenefitId: benefitId,
          notRelatedWithLibraryId: libraryId,
          notRelatedWithTariffId: tariffId,
        })
      );
    }
  };

  const emptyContent = useMemo(
    () =>
      getUnallocatedEmptyContent(
        translations,
        customersUnallocatedFilters,
        customers
      ),
    [customersUnallocatedFilters, customers]
  );

  return (
    <TransferLeftStyled>
      <TransferTitleContainer
        translations={translations}
        title={translations.select_customers}
      />
      {unallocatedTopBlock}
      <TransferTableContainer
        defaultColWidth={getDefaultColWidth(accessors.length + 1, 2)}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        tableData={{ rows: leftRows, columns: leftColumns }}
        getSelectedRows={getSelectedRows}
        handlePagination={handlePagination}
        meta={meta}
        isLoading={isLoading && !isPending}
        isPaginating={isPaginating}
        headerComponent={headerComponent}
        emptyContent={emptyContent}
        isSelectedAll={isSelectedAll}
        isRadioButton={isRadioButton}
        locationType={LocationType.unallocated}
      />
    </TransferLeftStyled>
  );
};
