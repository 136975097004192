import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const RightPricesStyled = styled.div`
  margin-bottom: 24px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;
