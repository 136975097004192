import { publicConfig, SecureRequestClient } from '@lerna-core';
import {
  ApiSubscriptionClaimModel,
  SubscriptionClaimModel,
} from './subscriptionClaim.model';

export class SubscriptionClaimClient extends SecureRequestClient<
  null,
  ApiSubscriptionClaimModel,
  SubscriptionClaimModel
> {
  protected path = '';
  protected requestParameters: null;

  constructor(courseUuid: string) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = null;
    this.path = `user-api/v1/subscriptions/courses/${courseUuid}/claim`;
  }
}
