import styled, { css } from 'styled-components';
import { breakpoint, FormControl } from '@lerna-core';

export const PromoCodeControlledInput = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;

  ${FormControl} {
    flex: 1 1 auto;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  button {
    flex: 0 0 150px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      flex-wrap: wrap;

      button {
        flex: 0 0 100%;
      }

      ${FormControl} {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    `
  )}
`;

export const PromoCodeControlledInputControl = styled.div`
  flex: 1 1 auto;
`;
