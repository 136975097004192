import { getSimpleEvent, pushDataLayer } from '../../../services';
import { CommonEventTypes } from '../../../analytics';

export enum MenuSwitcherEvents {
  goToCatalog = 'go_to_main_courses_page',
  goToHR = 'go_to_hr_account_customers',
  goToPersonal = 'go_to_personal_account_courses',
}

export enum MenuSwitcherPositions {
  catalogHeader = 'courses_page_block_header',
  catalogCoursePageHeader = 'course_page_block_header',
  personalHeader = 'personal_account_page_block_header',
  hrHeader = 'hr_account_page_block_header',
}

export const handleMenuSwitcherDataLayer = (
  event?: string,
  eventPosition?: string
): void => {
  if (event && eventPosition) {
    pushDataLayer(
      getSimpleEvent(event, CommonEventTypes.overallEvents, eventPosition)
    );
  }
};
