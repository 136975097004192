import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconWindowComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#FFF',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6668 7.66671V24.3334C27.6668 25.806 26.4728 27 25.0002 27H7.00016C5.5275 27 4.3335 25.806 4.3335 24.3334V7.66671C4.3335 6.19404 5.5275 5.00004 7.00016 5.00004H25.0002C26.4728 5.00004 27.6668 6.19404 27.6668 7.66671Z"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.0002 4.00004H7.00016C4.97883 4.00004 3.3335 5.64538 3.3335 7.66671V9.00004C3.3335 9.55204 3.7815 10 4.3335 10H27.6668C28.2188 10 28.6668 9.55204 28.6668 9.00004V7.66671C28.6668 5.64538 27.0215 4.00004 25.0002 4.00004ZM7.00016 8.00004C6.44816 8.00004 6.00016 7.55204 6.00016 7.00004C6.00016 6.44804 6.44816 6.00004 7.00016 6.00004C7.55216 6.00004 8.00016 6.44804 8.00016 7.00004C8.00016 7.55204 7.55216 8.00004 7.00016 8.00004ZM10.3335 8.00004C9.7815 8.00004 9.3335 7.55204 9.3335 7.00004C9.3335 6.44804 9.7815 6.00004 10.3335 6.00004C10.8855 6.00004 11.3335 6.44804 11.3335 7.00004C11.3335 7.55204 10.8855 8.00004 10.3335 8.00004Z"
      fill={strokeColor}
    />
  </svg>
);
