import { ChipModel } from './chip/chip.model';

export type ChipsInputProps = {
  placeholder: string;
  duplicateErrorMessage: string;
  chips: ChipModel[];
  onChange: (values: ChipModel[]) => void;
  errorMessage?: string;
  label?: string;
  limit?: number;
  isValidRule?: RegExp;
  isAllowSpace?: boolean;
  maxLength?: number;
};

export type KeyCodes = Record<string, number>;

export const KEYCODES: KeyCodes = {
  SPACE: 32,
  TAB: 9,
  BACKSPACE: 8,
  ENTER: 13,
};
