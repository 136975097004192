import { TranslationModel } from '@lerna-core';

export const getStatusMessage = (
  isSuccessDisplayed: boolean,
  isErrorDisplayed: boolean,
  hasSubscription: boolean,
  translations: TranslationModel
): Record<string, string> => {
  switch (true) {
    case isSuccessDisplayed: {
      const successTitle = hasSubscription
        ? translations?.sign_up_subscription_success_title_description
        : translations?.sign_up_default_success_title_description;
      const successSubtitle = hasSubscription
        ? translations?.sign_up_subscription_success_subtitle_description
        : translations?.sign_up_default_success_subtitle_description;
      return {
        title: successTitle,
        subtitle: successSubtitle,
      };
    }
    case isErrorDisplayed:
    default:
      return {
        title: translations?.popup_error_message_title,
        subtitle: translations?.popup_error_message_description,
      };
  }
};
