import { AxiosPromise } from 'axios';
import {
  publicConfig,
  RequestClient,
  RequestHeader,
  setAcceptLanguage,
} from '@lerna-core';

export type ContactUsRequestProps = {
  user_name: string;
  email: string;
  phone: string;
  comment: string;
  is_data_agreement_accepted: boolean;
  lang?: string;
};

export class ContactUsFormClient extends RequestClient<
  ContactUsRequestProps,
  ContactUsRequestProps,
  ContactUsRequestProps
> {
  protected path = 'course-api/v1/callback-request';
  protected requestParameters: ContactUsRequestProps;

  constructor(params: ContactUsRequestProps, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);
    this.requestParameters = params;
  }
}

export const makeContactUsRequest = (
  nameValue: string,
  emailValue: string,
  phoneValue: string,
  messageValue: string,
  lang?: string
): AxiosPromise<ContactUsRequestProps> => {
  const requestParams = {
    user_name: nameValue.trim(),
    email: emailValue,
    phone: phoneValue,
    comment: messageValue.trim(),
    is_data_agreement_accepted: true,
    lang,
  };

  const headers = setAcceptLanguage(lang);

  return new ContactUsFormClient(requestParams, headers).post();
};
