import {
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  getBonusPointsLabel,
  IconInfoWithoutBgComponent,
  mapPriceValue,
  Nullable,
  useTheme,
} from '@lerna-core';
import React, { ReactElement, useMemo } from 'react';
import { BannerBonusesContainerProps } from './wildberriesBonuses.model';
import * as S from './wildberriesBonuses.styled';

export const WildberriesBonusesContainer = ({
  bonuses,
  translations,
  currencies,
  handleClickActionButton,
  handleClickMoreButton,
}: BannerBonusesContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();

  const bonusLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return getBonusPointsLabel(bonuses.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ]);
  }, []);
  const bonusPriceLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return `${getBonusPointsLabel(bonuses.bonusPriceConversion.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])} = ${mapPriceValue(bonuses.bonusPriceConversion.money, currencies)}`;
  }, []);

  if (!bonuses) {
    return null;
  }

  return (
    <S.WildberriesBonuses>
      <S.WildberriesBonusesHead>
        <S.WildberriesBonusesPoints>
          <S.WildberriesBonusesPointsValue>
            {bonusLabel}
          </S.WildberriesBonusesPointsValue>
        </S.WildberriesBonusesPoints>
        <S.WildberriesBonusesLegend>
          {bonusPriceLabel}
        </S.WildberriesBonusesLegend>
      </S.WildberriesBonusesHead>
      <S.WildberriesBonusesButtons>
        <ButtonContainer
          size={ButtonSize.small}
          color={ButtonColor.white}
          title={translations.banner_bonuses_button_points}
          onClick={handleClickActionButton}
        />
        <ButtonContainer
          color={ButtonColor.transparent}
          size={ButtonSize.small}
          borderColor={theme.COLOR.WHITE_32}
          onClick={handleClickMoreButton}
          children={
            <IconInfoWithoutBgComponent fillColor={theme.COLOR.WHITE} />
          }
        />
      </S.WildberriesBonusesButtons>
    </S.WildberriesBonuses>
  );
};
