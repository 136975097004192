// import { TranslationsClient } from './translations.client';
import { getTranslations } from '../../translations';
import { Translations } from './translations.model';

export async function translationsClientProvider(): Promise<Translations> {
  // const response = await new TranslationsClient().get();

  const response = getTranslations();

  return response as unknown as Promise<Translations>;
}
