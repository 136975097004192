import {
  CourseModel,
  Nullable,
  OpenPopupModel,
  TranslationModel,
  UserAggregateModel,
} from '@lerna-core';
import * as Yup from 'yup';
import { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { SubscriptionAvailabilityModel } from '@features/common/subscriptionAvailability';
import { FormPaymentInfoModel } from '../../form.model';
import { UserOrderFormPaymentContentTypes } from '../userOrderForm.model';

export enum FormSubscriptionContentTypes {
  fullAccess = 'fullAccess',
  subscription = 'subscription',
}

export type FormSubscriptionContainerProps = {
  course: CourseModel;
  user: UserAggregateModel;
  translations: TranslationModel;
  eventPosition: string;
  courseIndex?: Nullable<number>;
};

export type FormSubscriptionInitialValues = {
  withPromoCode: boolean;
  promoCode: string;
};

export type FormSubscriptionValidationSchema = Yup.ObjectSchema<
  Partial<Record<keyof FormSubscriptionInitialValues, Yup.AnySchema>>
>;

export type FormSubscriptionContextModel = FormSubscriptionContainerProps & {
  currentPopup: Nullable<OpenPopupModel>;
  handlePopupClose: () => void;
  formContent: FormSubscriptionContentTypes;
  handleChangeFormContent: (content: FormSubscriptionContentTypes) => void;
  paymentContent: UserOrderFormPaymentContentTypes;
  handleChangePaymentContent: (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ) => void;
  subscriptionAvailability: Nullable<SubscriptionAvailabilityModel>;
  fullAccessSubmitButtonTitle: string;
  handleFormSubmit: () => void;
  handleChange: (e: ChangeEvent<Element>) => void;
  values: FormSubscriptionInitialValues;
  errors: FormikErrors<FormSubscriptionInitialValues>;
  touched: FormikTouched<FormSubscriptionInitialValues>;
  isSubmitting: boolean;
  isSuccessDisplayed: boolean;
  isErrorDisplayed: boolean;
  isExistOrder: boolean;
  showFormStatus: boolean;
  showPaymentTabs: boolean;
  showSwitcherButton: boolean;
  showAdvantages: boolean;
  isOnlinePaySelected: boolean;
  isSubscriptionFormContentSelected: boolean;
  isFreeCourse: boolean;
  isLernaPrime: boolean;
  isMobile: boolean;
  isLoading: boolean;
  isSubscriptionButtonDisabled: boolean;
  formOrderInfo: FormPaymentInfoModel;
  handleBlur?: (e: FocusEvent<Element, Element>) => void;
  setFieldValue?: (field: string, value: string) => void;
};

export type FormSubscriptionContextProviderProps = {
  props: FormSubscriptionContainerProps;
  children: ReactNode;
};
