import React, { ReactElement, SyntheticEvent } from 'react';
import { TooltipMobileContentContainerProps } from './tooltipMobileContent.model';
import {
  TooltipMobileButtonStyled,
  TooltipMobileContentHTMLStyled,
  TooltipMobileContentStyled,
  TooltipMobileContentWrapperStyled,
  TooltipMobileOverlayStyled,
} from './tooltipMobileContent.styled';
import { ButtonColor, ButtonContainer, ButtonSize } from '@lerna-core';

export const TooltipMobileContentContainer = ({
  content,
  handleClose,
  translations,
}: TooltipMobileContentContainerProps): ReactElement => {
  const handleClickContent = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <TooltipMobileContentStyled>
      <TooltipMobileOverlayStyled onClick={handleClose} />
      <TooltipMobileContentWrapperStyled onClick={handleClickContent}>
        <TooltipMobileContentHTMLStyled
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <TooltipMobileButtonStyled onClick={handleClose}>
          <ButtonContainer
            color={ButtonColor.default}
            size={ButtonSize.small}
            fullWidth
            title={translations?.understand}
          />
        </TooltipMobileButtonStyled>
      </TooltipMobileContentWrapperStyled>
    </TooltipMobileContentStyled>
  );
};
