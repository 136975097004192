import React, { ReactElement, useContext, useState } from 'react';
import {
  PromoCodeContextProviderContextModel,
  PromoCodeContextProviderProps,
  PromoCodeWithErrorModel,
} from './promoCode.model';
import { Nullable } from '../../models';
import { logger } from '../../services';

export const PromoCodeContext =
  React.createContext<Nullable<PromoCodeContextProviderContextModel>>(null);

export const PromoCodeContextProvider = ({
  props,
  children,
}: PromoCodeContextProviderProps): ReactElement => {
  const [promoCode, _setPromoCode] =
    useState<Nullable<PromoCodeWithErrorModel>>(props);

  const setPromoCode = (promoCode: Nullable<PromoCodeWithErrorModel>) => {
    _setPromoCode(promoCode);
  };

  const context = {
    promoCode,
    setPromoCode,
  };

  return (
    <PromoCodeContext.Provider value={context}>
      {children}
    </PromoCodeContext.Provider>
  );
};

export const usePromoCodeContext = (): PromoCodeContextProviderContextModel => {
  const value = useContext(PromoCodeContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as PromoCodeContextProviderContextModel;
  }

  return value;
};
