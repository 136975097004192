import styled, { css } from 'styled-components';

import { fontSettings } from '../../../../styles';
import { DropdownItemProps } from './selectItem.container';

export const SelectItemStyled = styled.div<Pick<DropdownItemProps, 'active'>>`
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: 100%;

  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  ${({ theme, active }): ReturnType<typeof css> => css`
    color: ${active
      ? theme.COLOR.TEXT_DARK_COLOR
      : theme.COLOR.TEXT_LIGHT_COLOR};
  `};

  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;
