import { AxiosPromise } from 'axios';
import {
  RequestClient,
  RequestHeader,
  setAcceptLanguage,
} from '../../services/request';
import { SearchRequestProps, SearchRequestResponse } from './search.model';
import { publicConfig } from '../../services';

export class SearchFormClient extends RequestClient<
  SearchRequestProps,
  SearchRequestResponse,
  SearchRequestResponse
> {
  protected path = 'course-api/v1/catalog/courses/suggest';
  protected requestParameters: SearchRequestProps;

  constructor(params: SearchRequestProps, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);
    this.requestParameters = params;
  }
}

export const makeSearchRequest = (
  query: string,
  limit: number,
  lang?: string,
  discountProgram?: string
): AxiosPromise<SearchRequestResponse> => {
  const requestParams = {
    query,
    limit,
    lang,
    discount_program: discountProgram,
  };

  const headers = setAcceptLanguage(lang);

  return new SearchFormClient(requestParams, headers).get();
};
