import React, { ElementType, ReactElement } from 'react';
import { Row } from 'react-table';

export const getTableRows = (
  rows: Row[],
  prepareRow: (row: Row) => void,
  TableBodyRow: ElementType,
  TableBodyData: ElementType,
  handleRowClick?: (row: Row) => void
): ReactElement[] => {
  return rows.map((row) => {
    prepareRow(row);
    const { key, ...rowProps } = row.getRowProps();
    const canClick = typeof handleRowClick === 'function' && !row.isDisabled;
    const onClick = (): void => {
      if (typeof handleRowClick === 'function' && !row.isDisabled) {
        handleRowClick(row);
      }
    };

    return (
      <TableBodyRow
        hasHover={canClick}
        key={key}
        {...rowProps}
        onClick={onClick}
      >
        {row.cells.map((cell) => {
          const { key, ...cellProps } = cell.getCellProps();

          return (
            <TableBodyData key={key} disabled={row.isDisabled} {...cellProps}>
              {cell.render('Cell')}
            </TableBodyData>
          );
        })}
      </TableBodyRow>
    );
  });
};
