import {
  AdjustmentType,
  CompensationRequestApiModel,
  CompensationRequestModel,
  CompensationRequestsApiModel,
  CompensationRequestsApiStatuses,
  CompensationRequestsModel,
  CompensationRequestsResponse,
  CompensationRequestsStatuses,
  CompensationRequestType,
} from './compensationRequests.model';
import { mapMetaPaginationAdapter } from '../../adapters';
import { dateService } from '../../services';
import { vendorAdapter } from '../vendors';
import { mapCustomer } from '../customer';
import { courseAdapter } from '../course';
import { CurrencyModel } from '../../models';

const mapCompensationRequestStatuses = (
  status: CompensationRequestsApiStatuses
): CompensationRequestsStatuses => {
  switch (status) {
    case CompensationRequestsApiStatuses.pending:
      return CompensationRequestsStatuses.pending;
    case CompensationRequestsApiStatuses.accepted:
      return CompensationRequestsStatuses.accepted;
    case CompensationRequestsApiStatuses.rejected:
      return CompensationRequestsStatuses.rejected;
    case CompensationRequestsApiStatuses.accepted_with_library_claim:
      return CompensationRequestsStatuses.acceptedWithLibraryClaim;
  }
};

const getCompensationRequestType = (
  type?: AdjustmentType
): CompensationRequestType => {
  switch (type) {
    case AdjustmentType.compensationPercent:
    case AdjustmentType.customCompensationPercent:
      return CompensationRequestType.percent;

    case AdjustmentType.compensationAbsolute:
    case AdjustmentType.customCompensationAbsolute:
    default:
      return CompensationRequestType.absolute;
  }
};

export const mapCompensationRequest = (
  compensationRequestApi: CompensationRequestApiModel,
  currencies: CurrencyModel[]
): CompensationRequestModel => {
  const vendor = vendorAdapter(compensationRequestApi.vendor);

  return {
    uuid: compensationRequestApi.uuid,
    status: mapCompensationRequestStatuses(compensationRequestApi.status),
    processedAt: compensationRequestApi.processed_at
      ? dateService.formatDate(compensationRequestApi.processed_at)
      : null,
    createdAt: dateService.formatDate(compensationRequestApi.created_at),
    isAvailableInLibrary: compensationRequestApi.is_available_in_library,
    isCustomCompensation: compensationRequestApi.is_custom_compensation,
    compensationType: getCompensationRequestType(
      compensationRequestApi.adjustment?.type
    ),
    compensationPercent: compensationRequestApi.adjustment
      ?.price_influence_percent
      ? `${compensationRequestApi.adjustment?.price_influence_percent}%`
      : null,
    compensationAmount: compensationRequestApi?.adjustment?.value
      ? `${compensationRequestApi.adjustment.value.amount} ${compensationRequestApi.adjustment.value.currency}`
      : null,
    compensationPrice: compensationRequestApi.adjustment
      ? `${compensationRequestApi.adjustment.value.amount} ${compensationRequestApi.adjustment.value.currency}`
      : null,
    initialPrice: `${compensationRequestApi.initial_price.amount} ${compensationRequestApi.initial_price.currency}`,
    customerPrice: {
      amount: compensationRequestApi.customer_price.amount,
      currency: compensationRequestApi.customer_price.currency,
    },
    b2bPrice: {
      amount: compensationRequestApi.b2b_price?.amount,
      currency: compensationRequestApi.b2b_price?.currency,
    },
    companyPrice: `${compensationRequestApi.company_price.amount} ${compensationRequestApi.company_price.currency}`,
    startDate: compensationRequestApi.course.start_date || '',
    customer: mapCustomer(compensationRequestApi.customer),
    course: courseAdapter(compensationRequestApi.course, currencies, {}, [
      vendor,
    ]),
    vendor,
    comment: compensationRequestApi.comment
      ? compensationRequestApi.comment.trim()
      : null,
  };
};

export const compensationRequestsApiAdapter = (
  compensationRequests: CompensationRequestsApiModel,
  currencies: CurrencyModel[]
): CompensationRequestsModel => {
  return {
    data: compensationRequests.data.map((compensationRequest) => {
      return mapCompensationRequest(compensationRequest, currencies);
    }),
    meta: mapMetaPaginationAdapter(compensationRequests.meta.pagination),
  };
};

export const mapCompensationRequestsResponse = (
  currencies: CurrencyModel[],
  { data }: CompensationRequestsResponse
): CompensationRequestsModel => ({
  data: data.data.map((compensationRequest) => {
    return mapCompensationRequest(
      compensationRequest,
      currencies as CurrencyModel[]
    );
  }),
  meta: mapMetaPaginationAdapter(data.meta.pagination),
});
