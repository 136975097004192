import styled from 'styled-components';
import { FormAgreementTreePropsModel } from './formAgreementTree.model';
import { getFormAgreementStyles } from './formAgreementTree.mixin';
import { rgba } from 'polished';

export const FormAgreementTree = styled.div<FormAgreementTreePropsModel>`
  border: 1px solid;
  border-radius: 12px;
  padding: 10px;
  margin: 12px -10px -10px;

  &:not(:last-child) {
    margin: 12px -10px 12px;
  }

  ${({ theme, hasErrors }) => getFormAgreementStyles(theme, hasErrors)};
`;
export const FormAgreementTreeCheckAll = styled.div`
  margin-bottom: 8px;
`;
export const FormAgreementTreeRows = styled.div`
  position: relative;
`;
export const FormAgreementTreeRow = styled.div`
  position: relative;
  margin-left: 28px;

  &:after {
    content: '';
    display: inline-block;
    height: calc(100% + 12px);
    width: 1px;
    background-color: ${({ theme }) => rgba(theme.COLOR.BLACK, 0.12)};
    position: absolute;
    top: 0;
    left: -21px;
  }

  &:first-child:after {
    top: -4px;
    height: calc(100% + 16px);
  }

  &:last-child:after {
    height: 10px;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 1px;
    width: 11px;
    background-color: ${({ theme }) => rgba(theme.COLOR.BLACK, 0.12)};
    position: absolute;
    top: 9px;
    left: -20px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
