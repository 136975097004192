import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '@lerna-core';

export const ItemInstallmentContainerStyled = styled.div``;

export const TitleStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  margin-bottom: 4px;
  ${lineClamping(1)}
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  
  
  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 2px;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)}
    `
  )}
`;

export const ValueStyled = styled.div`
  font-weight: 600;
  white-space: nowrap;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_18, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    `
  )}
`;
