import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconFireComponent = ({
  width = 16,
  height = 20,
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.12953 4.58215C6.60887 3.31779 7.29813 0.914948 7.18287 0C11.0553 1.42125 9.82933 6.19175 10.6152 6.19175C11.1299 6.19175 11.5649 5.48876 11.5261 3.43071C16.4793 7.70093 12.9028 11.401 14.2496 11.401C14.6911 11.4014 15 10.5953 15 10.5953C15 15.2811 11.7959 17.9949 8.17173 18C4.50093 18 1 15.8772 1 10.6846C1 10.6846 1.784 12.0568 2.4434 11.7296C3.32727 11.2918 0.947734 8.15632 5.12953 4.58215Z"
      fill="#FF1446"
    />
    <path
      d="M5.12813 4.6173C6.60886 3.34276 7.30093 0.973702 7.19499 0.0328369C11.0445 1.46612 9.81066 6.20469 10.6101 6.21117C11.129 6.21441 11.5714 5.51235 11.5355 3.46355C16.4518 7.71248 12.886 11.4139 14.2337 11.4218C14.6766 11.425 14.9916 10.6225 14.9916 10.6225C14.9855 15.2764 11.7931 17.9893 8.17219 17.9944C4.52006 17.9944 1.02379 15.8818 1.01306 10.7156C1.01306 10.7156 1.80173 12.0771 2.46066 11.7467C3.34266 11.3047 0.978995 8.17065 5.12813 4.6173Z"
      fill="#FF1446"
    />
    <path
      d="M5.12676 4.65202C6.61216 3.37054 7.30423 1.03203 7.20716 0.0652466C11.0334 1.5101 9.7925 6.21859 10.6045 6.23016C11.1276 6.23756 11.578 5.53504 11.5448 3.49642C16.4243 7.72406 12.8688 11.4264 14.2174 11.4431C14.6612 11.4491 14.9828 10.6508 14.9828 10.6508C14.9706 15.2727 11.7898 17.9847 8.17223 17.9898C4.53876 17.9898 1.0467 15.8873 1.0257 10.7475C1.0257 10.7475 1.81903 12.098 2.47703 11.7643C3.3581 11.3172 1.01916 8.19519 5.12676 4.65202Z"
      fill="#FF1446"
    />
    <path
      d="M5.12536 4.68672C6.61402 3.39644 7.30702 1.09033 7.21976 0.097168C11.0231 1.55405 9.77429 6.23107 10.5994 6.24866C11.1262 6.25976 11.585 5.5577 11.5542 3.5288C16.3963 7.73516 12.852 11.4385 14.2016 11.4634C14.6468 11.4727 14.9739 10.6781 14.9739 10.6781C14.9552 15.2681 11.787 17.9792 8.17222 17.9842C4.55742 17.9842 1.06956 15.8919 1.03876 10.7785C1.03876 10.7785 1.83676 12.1183 2.49429 11.7809C3.37349 11.3302 1.05509 8.21415 5.12536 4.68672Z"
      fill="#FF1446"
    />
    <path
      d="M5.12393 4.72187C6.61586 3.4228 7.31026 1.14908 7.23186 0.130005C11.0119 1.59892 9.75607 6.24493 10.5942 6.26807C11.1253 6.28288 11.5915 5.58082 11.5635 3.5621C16.3687 7.74717 12.8347 11.4519 14.1852 11.4847C14.6313 11.4972 14.965 10.7063 14.965 10.7063C14.9403 15.2644 11.7837 17.975 8.1722 17.9796C4.57606 17.9796 1.0924 15.8975 1.05133 10.81C1.05133 10.81 1.854 12.1391 2.5106 11.798C3.3884 11.3431 1.09053 8.23311 5.12393 4.72187Z"
      fill="#FF1446"
    />
    <path
      d="M5.12256 4.75657C6.61776 3.44871 7.3131 1.20738 7.24403 0.161926C11.0012 1.64287 9.73743 6.25742 10.5886 6.28611C11.1239 6.30416 11.598 5.60302 11.5724 3.59402C16.3408 7.7578 12.8174 11.4606 14.1689 11.5046C14.6164 11.5194 14.9562 10.7331 14.9562 10.7331C14.9254 15.2597 11.7805 17.9689 8.17223 17.9736C4.59476 17.9736 1.11576 15.9021 1.06396 10.8405C1.06396 10.8405 1.8713 12.1585 2.52743 11.8147C3.40383 11.356 1.12556 8.25207 5.12256 4.75657Z"
      fill="#FF1446"
    />
    <path
      d="M5.12116 4.79129C6.61963 3.4751 7.31636 1.26571 7.25663 0.194336C10.9904 1.68685 9.71969 6.27039 10.584 6.3051C11.1234 6.32685 11.6055 5.62571 11.5826 3.62643C16.3137 7.76939 12.8016 11.4722 14.1535 11.525C14.602 11.5426 14.9478 10.7604 14.9478 10.7604C14.9109 15.2551 11.7777 17.9634 8.17269 17.968C4.61389 17.968 1.13909 15.9067 1.07703 10.8715C1.07703 10.8715 1.88903 12.1789 2.54423 11.8313C3.41923 11.369 1.16103 8.27106 5.12116 4.79129Z"
      fill="#FF1446"
    />
    <path
      d="M5.11975 4.82599C6.62102 3.501 7.31915 1.32401 7.26875 0.226257C10.9797 1.73081 9.70102 6.28287 10.5784 6.3236C11.122 6.34905 11.612 5.64838 11.5915 3.65882C16.2857 7.78048 12.7843 11.4838 14.1372 11.5453C14.587 11.5657 14.9389 10.7877 14.9389 10.7877C14.896 15.2505 11.7749 17.9578 8.17315 17.9625C4.63302 17.9625 1.16242 15.9114 1.09009 10.902C1.09009 10.902 1.90675 12.1988 2.56149 11.8475C3.43462 11.3815 1.19602 8.28956 5.11975 4.82599Z"
      fill="#FF1446"
    />
    <path
      d="M5.11837 4.86114C6.6229 3.5269 7.32244 1.38276 7.28137 0.259094C10.969 1.77568 9.68284 6.29627 10.5737 6.34301C11.1216 6.37171 11.619 5.6715 11.6013 3.69212C16.2586 7.79249 12.7675 11.4963 14.1218 11.5666C14.5726 11.5902 14.931 10.8159 14.931 10.8159C14.8815 15.2468 11.7726 17.9532 8.1741 17.9578C4.65264 17.9578 1.18624 15.9169 1.10364 10.934C1.10364 10.934 1.92497 12.2196 2.57877 11.8651C3.44957 11.3944 1.23104 8.30758 5.11837 4.86114Z"
      fill="#FF1446"
    />
    <path
      d="M5.11696 4.8959C6.62476 3.55287 7.3257 1.44113 7.2935 0.291077C10.9582 1.81969 9.66463 6.30835 10.5681 6.36158C11.1197 6.39397 11.6256 5.69376 11.6102 3.72456C16.2302 7.80365 12.7502 11.5083 14.105 11.587C14.5572 11.6134 14.9212 10.8428 14.9212 10.8428C14.8656 15.2417 11.7684 17.9472 8.17316 17.9518C4.67036 17.9518 1.20863 15.9211 1.1153 10.9645C1.1153 10.9645 1.94176 12.2396 2.59463 11.8818C3.46496 11.4074 1.26603 8.32615 5.11696 4.8959Z"
      fill="#FF1446"
    />
    <path
      d="M5.11556 4.93062C6.62616 3.5788 7.32849 1.49945 7.30562 0.323486C10.947 1.86414 9.64596 6.32133 10.5625 6.3801C11.1183 6.41574 11.6321 5.71645 11.6195 3.75697C16.2026 7.81477 12.7334 11.5199 14.0891 11.6074C14.5422 11.6365 14.9128 10.8702 14.9128 10.8702C14.8512 15.2376 11.766 17.9417 8.17362 17.9463C4.68949 17.9463 1.23196 15.9262 1.12836 10.9951C1.12836 10.9951 1.95949 12.259 2.61142 11.898C3.48036 11.4204 1.30056 8.34421 5.11556 4.93062Z"
      fill="#FF1446"
    />
    <path
      d="M5.11415 4.96575C6.62802 3.60513 7.33175 1.55818 7.31822 0.355835C10.9368 1.90805 9.62775 6.33424 10.5578 6.39903C11.1178 6.43837 11.6396 5.73908 11.6293 3.78932C16.1756 7.82583 12.7171 11.5314 14.0732 11.6282C14.5278 11.6606 14.9044 10.8979 14.9044 10.8979C14.8367 15.2333 11.7632 17.9365 8.17408 17.9412C4.70862 17.9412 1.25529 15.9312 1.14142 11.0265C1.14142 11.0265 1.97722 12.2798 2.62868 11.9151C3.49575 11.4333 1.33555 8.36221 5.11415 4.96575Z"
      fill="#FF1446"
    />
    <path
      d="M5.11321 5.00047C6.63035 3.63106 7.33501 1.61651 7.33081 0.388245C10.926 1.9525 9.61001 6.34675 10.5527 6.41802C11.1169 6.4606 11.6461 5.76224 11.6386 3.82219C16.1475 7.83788 12.6998 11.544 14.0573 11.649C14.5133 11.6842 14.8955 10.9257 14.8955 10.9257C14.8217 15.2292 11.7599 17.9315 8.17408 17.9361C4.72728 17.9361 1.27815 15.9363 1.15448 11.058C1.15448 11.058 1.99495 12.3006 2.64548 11.9322C3.51115 11.4458 1.37008 8.37981 5.11321 5.00047Z"
      fill="#FF1446"
    />
    <path
      d="M5.11181 5.0352C6.63174 3.65653 7.33827 1.67483 7.34294 0.420654C10.9153 1.99694 9.59134 6.35972 10.5475 6.43701C11.1159 6.48282 11.6531 5.78493 11.6479 3.85507C16.12 7.84946 12.683 11.556 14.0415 11.6698C14.4983 11.7083 14.8871 10.9534 14.8871 10.9534C14.8068 15.225 11.7571 17.9269 8.17454 17.931C4.74594 17.931 1.30194 15.9415 1.16754 11.0895C1.16754 11.0895 2.01267 12.321 2.66274 11.9498C3.52607 11.4588 1.40461 8.39741 5.11181 5.0352Z"
      fill="#FF1446"
    />
    <path
      d="M5.11038 5.07038C6.63358 3.68292 7.34105 1.73362 7.35552 0.453064C10.9045 2.04138 9.57312 6.37223 10.5424 6.456C11.1145 6.50551 11.66 5.80808 11.6577 3.88794C16.0929 7.86104 12.6666 11.5685 14.0256 11.6907C14.4838 11.7319 14.8782 10.9812 14.8782 10.9812C14.7918 15.2209 11.7543 17.9218 8.17452 17.9259C4.76458 17.9259 1.32478 15.947 1.18011 11.1205C1.18011 11.1205 2.02991 12.3414 2.67905 11.9665C3.54145 11.4718 1.43865 8.41501 5.11038 5.07038Z"
      fill="#FF1446"
    />
    <path
      d="M5.10902 5.10511C6.63548 3.70839 7.34435 1.79195 7.36768 0.485474C10.8938 2.08582 9.55495 6.3852 10.5368 6.47498C11.1131 6.52774 11.6666 5.83077 11.6666 3.92081C16.0645 7.87263 12.6494 11.5806 14.0093 11.7115C14.4685 11.7555 14.8693 11.009 14.8693 11.009C14.7769 15.2167 11.7511 17.9167 8.17455 17.9209C4.78328 17.9209 1.34768 15.9521 1.19275 11.152C1.19275 11.152 2.04722 12.3622 2.69588 11.9836C3.55688 11.4848 1.47275 8.43215 5.10902 5.10511Z"
      fill="#FF1446"
    />
    <path
      d="M5.10761 5.1398C6.63688 3.73429 7.34714 1.85025 7.37981 0.517395C10.8826 2.12931 9.53628 6.39722 10.5317 6.49302C11.1122 6.54902 11.6731 5.85297 11.6759 3.95227C16.0369 7.8828 12.6321 11.5916 13.9929 11.7309C14.4535 11.7782 14.8605 11.0354 14.8605 11.0354C14.762 15.2116 11.7478 17.9102 8.17501 17.9144C4.80241 17.9144 1.37101 15.9558 1.20581 11.1821C1.20581 11.1821 2.06494 12.3812 2.71268 11.9994C3.57228 11.4977 1.50681 8.44926 5.10761 5.1398Z"
      fill="#FF1446"
    />
    <path
      d="M5.10618 5.17495C6.63872 3.76019 7.35038 1.909 7.39238 0.550232C10.8723 2.17418 9.51805 6.41016 10.5265 6.51244C11.1112 6.57167 11.6801 5.87609 11.6853 3.98557C16.0094 7.89481 12.6153 11.6046 13.977 11.7522C14.4386 11.8022 14.8516 11.0636 14.8516 11.0636C14.7471 15.2079 11.745 17.9056 8.17498 17.9097C4.82105 17.9097 1.39432 15.9613 1.21838 11.2135C1.21838 11.2135 2.08218 12.402 2.72945 12.0169C3.58718 11.5102 1.54085 8.46636 5.10618 5.17495Z"
      fill="#FF1446"
    />
    <path
      d="M5.10478 5.20965C6.64011 3.78563 7.35318 1.9673 7.40451 0.582153C10.8611 2.21814 9.49984 6.42218 10.5214 6.53094C11.1098 6.59341 11.6871 5.89876 11.6946 4.01795C15.9814 7.90637 12.5985 11.6161 13.9612 11.7726C14.4241 11.8258 14.8432 11.0909 14.8432 11.0909C14.7321 15.2033 11.7422 17.9 8.17544 17.9042C4.84018 17.9042 1.41765 15.966 1.23145 11.2445C1.23145 11.2445 2.09991 12.4223 2.74624 12.0336C3.60258 11.5231 1.57491 8.48348 5.10478 5.20965Z"
      fill="#FF1446"
    />
    <path
      d="M5.10343 5.24438C6.64157 3.81156 7.3565 2.02563 7.41717 0.614563C10.8509 2.26258 9.4817 6.43515 10.5163 6.54993C11.109 6.61564 11.6937 5.92145 11.7044 4.05083C15.9544 7.91795 12.5822 11.6287 13.9454 11.7934C14.4097 11.8494 14.8348 11.1187 14.8348 11.1187C14.7177 15.1991 11.7394 17.8949 8.17597 17.8991C4.85937 17.8991 1.44103 15.9715 1.24457 11.276C1.24457 11.276 2.1177 12.4427 2.76357 12.0507C3.61803 11.5361 1.60857 8.50015 5.10343 5.24438Z"
      fill="#FF1446"
    />
    <path
      d="M5.10205 5.27908C6.64345 3.837 7.35978 2.08393 7.42931 0.646484C10.8397 2.30653 9.46305 6.44717 10.5107 6.56843C11.1076 6.63738 11.7002 5.94365 11.7133 4.08321C15.9264 7.92905 12.565 11.6407 13.929 11.8142C14.3943 11.8735 14.8255 11.1464 14.8255 11.1464C14.7023 15.1949 11.7357 17.8898 8.17551 17.894C4.87758 17.894 1.46391 15.9766 1.25671 11.307C1.25671 11.307 2.13405 12.4617 2.77945 12.0678C3.63205 11.5472 1.64218 8.5168 5.10205 5.27908Z"
      fill="#FF1446"
    />
    <path
      d="M5.10062 5.31423C6.64482 3.8629 7.36255 2.14268 7.44142 0.679321C10.8285 2.35094 9.44435 6.46011 10.5056 6.58738C11.1066 6.65958 11.7072 5.96677 11.7226 4.11558C15.8984 7.94013 12.5477 11.6522 13.9127 11.8346C14.3794 11.8966 14.8166 11.1737 14.8166 11.1737C14.6874 15.1908 11.7329 17.8842 8.17549 17.8884C4.89622 17.8884 1.48675 15.9812 1.26929 11.338C1.26929 11.338 2.15129 12.482 2.79622 12.0845C3.64742 11.5601 1.67575 8.53297 5.10062 5.31423Z"
      fill="#FF1446"
    />
    <path
      d="M5.09918 5.34899C6.64618 3.8884 7.36578 2.20105 7.45398 0.711304C10.8182 2.39496 9.42658 6.47219 10.5004 6.60594C11.1056 6.68138 11.7142 5.98903 11.7319 4.14803C15.8708 7.95129 12.5308 11.6643 13.8968 11.855C14.3644 11.9202 14.8082 11.201 14.8082 11.201C14.6728 15.1862 11.73 17.8792 8.17591 17.8829C4.91531 17.8829 1.51004 15.9859 1.28278 11.369C1.28278 11.369 2.16944 12.502 2.81344 12.1012C3.66278 11.5731 1.70931 8.54969 5.09918 5.34899Z"
      fill="#FF1446"
    />
    <path
      d="M5.09824 5.38371C6.6485 3.91387 7.36904 2.25937 7.46657 0.743713C10.8074 2.4394 9.40837 6.48424 10.4957 6.62493C11.1047 6.7036 11.7212 6.01219 11.7422 4.1809C15.8442 7.96287 12.515 11.6768 13.8814 11.8758C14.3504 11.9438 14.7998 11.2288 14.7998 11.2288C14.6579 15.182 11.7272 17.8741 8.17637 17.8778C4.93444 17.8778 1.53337 15.991 1.29584 11.4005C1.29584 11.4005 2.18717 12.5228 2.8307 12.1183C3.6777 11.5856 1.74244 8.5659 5.09824 5.38371Z"
      fill="#FF1446"
    />
    <path
      d="M5.09693 5.41886C6.65 3.93976 7.3724 2.31812 7.4788 0.77655C10.7968 2.48427 9.3898 6.49718 10.4902 6.64434C11.1034 6.72626 11.7278 6.03531 11.7511 4.2142C15.8158 7.97535 12.4978 11.6898 13.8651 11.8971C14.3351 11.9684 14.791 11.257 14.791 11.257C14.6431 15.1783 11.7245 17.8695 8.17647 17.8732C4.9532 17.8732 1.5568 15.997 1.30853 11.432C1.30853 11.432 2.20453 12.5431 2.84713 12.1359C3.6932 11.5986 1.77567 8.58161 5.09693 5.41886Z"
      fill="#FF1446"
    />
    <path
      d="M5.09549 5.45356C6.65135 3.96474 7.37515 2.37643 7.49135 0.808472C10.786 2.52822 9.37202 6.5092 10.4855 6.66285C11.1029 6.748 11.7352 6.05797 11.7609 4.24659C15.7887 7.98644 12.4814 11.7018 13.8497 11.9174C14.321 11.9919 14.7826 11.2843 14.7826 11.2843C14.6286 15.1737 11.7217 17.8639 8.17735 17.8676C4.97275 17.8676 1.58055 16.0016 1.32202 11.463C1.32202 11.463 2.22269 12.5635 2.86482 12.1525C3.70856 11.6115 1.80875 8.5978 5.09549 5.45356Z"
      fill="#FF1446"
    />
    <path
      d="M5.0941 5.48828C6.65277 3.99021 7.37843 2.43475 7.5035 0.840881C10.7753 2.5722 9.35337 6.52171 10.4799 6.68183C11.1015 6.77023 11.7418 6.08066 11.7698 4.27946C15.7607 7.99803 12.4637 11.7143 13.8329 11.9383C14.3056 12.0156 14.7732 11.3121 14.7732 11.3121C14.6132 15.17 11.718 17.8588 8.1769 17.8625C4.99097 17.8625 1.60297 16.0067 1.33417 11.4945C1.33417 11.4945 2.2395 12.5843 2.8807 12.1697C3.7235 11.624 1.8419 8.61355 5.0941 5.48828Z"
      fill="#FF1446"
    />
    <path
      d="M5.09263 5.52347C6.6541 4.01614 7.38117 2.49354 7.51557 0.873291C10.764 2.61664 9.33463 6.53421 10.4742 6.70036C11.1 6.79153 11.7478 6.10335 11.779 4.3114C15.7326 8.00868 12.4468 11.7259 13.817 11.9582C14.2906 12.0387 14.7648 11.3385 14.7648 11.3385C14.5986 15.1649 11.7151 17.8528 8.1773 17.8565C5.01003 17.8565 1.62623 16.0109 1.34717 11.5245C1.34717 11.5245 2.25717 12.6033 2.8979 12.1854C3.73883 11.637 1.87497 8.62929 5.09263 5.52347Z"
      fill="#FF1446"
    />
    <path
      d="M5.09133 5.55813C6.6556 4.04108 7.38453 2.55181 7.52826 0.90564C10.7539 2.66103 9.317 6.5462 10.4697 6.71929C11.0992 6.8137 11.7553 6.12598 11.7889 4.34422C15.7057 8.02021 12.4306 11.7383 13.8012 11.979C14.2763 12.0623 14.756 11.3662 14.756 11.3662C14.5833 15.1602 11.7124 17.8472 8.1774 17.8505C5.02833 17.8505 1.64966 16.015 1.35986 11.555C1.35986 11.555 2.27453 12.6232 2.91433 12.202C3.75433 11.6499 1.90773 8.64498 5.09133 5.55813Z"
      fill="#FF1446"
    />
    <path
      d="M5.0899 5.59289C6.65697 4.06659 7.3873 2.60971 7.54037 0.937622C10.7426 2.70504 9.2983 6.55828 10.464 6.73785C11.0978 6.8355 11.7618 6.14871 11.7978 4.37666C15.6776 8.03136 12.4133 11.7504 13.7848 11.9998C14.2608 12.0864 14.7471 11.394 14.7471 11.394C14.5684 15.1561 11.7091 17.8422 8.17737 17.8454C5.04697 17.8454 1.6725 16.0206 1.37244 11.5866C1.37244 11.5866 2.29177 12.6436 2.9311 12.2192C3.76924 11.6629 1.94037 8.6603 5.0899 5.59289Z"
      fill="#FF1446"
    />
    <path
      d="M5.08852 5.62804C6.65839 4.09202 7.39059 2.66846 7.55299 0.970459C10.7324 2.74991 9.28059 6.57076 10.4594 6.75726C11.0973 6.85815 11.7693 6.17183 11.8076 4.40996C15.6501 8.04337 12.397 11.7633 13.7695 12.0211C14.2469 12.1109 14.7392 11.4222 14.7392 11.4222C14.5544 15.1524 11.7068 17.8375 8.17832 17.8408C5.06659 17.8408 1.69632 16.0261 1.38599 11.6185C1.38599 11.6185 2.30999 12.6649 2.94839 12.2368C3.78465 11.6754 1.97305 8.67602 5.08852 5.62804Z"
      fill="#FF1446"
    />
    <path
      d="M5.08709 5.66274C6.65976 4.11746 7.39383 2.72676 7.56509 1.00238C10.7212 2.7934 9.26189 6.58278 10.4538 6.77576C11.0959 6.87943 11.7754 6.19449 11.8169 4.44235C15.6226 8.05493 12.3802 11.7754 13.7531 12.0415C14.2314 12.134 14.7298 11.4495 14.7298 11.4495C14.539 15.1478 11.7035 17.832 8.17783 17.8352C5.08476 17.8352 1.71869 16.0308 1.39856 11.649C1.39856 11.649 2.32723 12.6847 2.96516 12.253C3.80003 11.6883 2.00523 8.69082 5.08709 5.66274Z"
      fill="#FF1446"
    />
    <path
      d="M5.08573 5.69746C6.66119 4.14247 7.39666 2.78509 7.57726 1.03479C10.7105 2.83784 9.24326 6.59483 10.4482 6.79429C11.0945 6.9012 11.7819 6.21672 11.8258 4.47429C15.5941 8.06559 12.3625 11.7869 13.7368 12.0614C14.2165 12.1572 14.721 11.4764 14.721 11.4764C14.5241 15.1431 11.7003 17.8264 8.17786 17.8292C5.10346 17.8292 1.74206 16.0349 1.41119 11.6796C1.41119 11.6796 2.34453 12.7042 2.98153 12.2696C3.81546 11.7013 2.03793 8.7061 5.08573 5.69746Z"
      fill="#FF1446"
    />
    <path
      d="M5.08432 5.73216C6.66259 4.16745 7.39992 2.84339 7.58986 1.06671C10.6997 2.8818 9.22552 6.60685 10.4435 6.81279C11.0941 6.92294 11.7894 6.23892 11.8356 4.50668C15.5671 8.07669 12.3461 11.7994 13.7209 12.0817C14.2021 12.1808 14.7126 11.5037 14.7126 11.5037C14.5091 15.1385 11.6975 17.8209 8.17832 17.8236C5.12259 17.8236 1.76539 16.04 1.42426 11.7106C1.42426 11.7106 2.36226 12.7246 2.99879 12.2863C3.83039 11.7143 2.07012 8.72136 5.08432 5.73216Z"
      fill="#FF1446"
    />
    <path
      d="M5.08287 5.76737C6.66347 4.19294 7.40267 2.9022 7.60194 1.09961C10.6889 2.92673 9.20681 6.61938 10.4379 6.83227C11.0921 6.94519 11.7959 6.26257 11.8444 4.54004C15.539 8.08876 12.3288 11.8124 13.7045 12.1031C14.1866 12.2049 14.7032 11.532 14.7032 11.532C14.4937 15.1348 11.6941 17.8163 8.17781 17.8191C5.14074 17.8191 1.78774 16.0456 1.43634 11.7421C1.43634 11.7421 2.37901 12.745 3.01461 12.3034C3.84574 11.7273 2.10227 8.73621 5.08287 5.76737Z"
      fill="#FF1446"
    />
    <path
      d="M5.08196 5.8021C6.66536 4.21794 7.40642 2.96053 7.61456 1.13202C10.6782 2.97071 9.18909 6.63189 10.4332 6.85126C11.0917 6.96742 11.8029 6.28526 11.8547 4.57291C15.512 8.10034 12.313 11.8249 13.6896 12.1239C14.1731 12.2289 14.6958 11.5597 14.6958 11.5597C14.4797 15.1307 11.6914 17.8107 8.17876 17.814C5.16036 17.814 1.81156 16.0507 1.44989 11.7736C1.44989 11.7736 2.39722 12.7658 3.03236 12.3206C3.86116 11.7398 2.13449 8.75103 5.08196 5.8021Z"
      fill="#FF1446"
    />
    <path
      d="M5.08055 5.83679C6.66629 4.24292 7.40922 3.01883 7.62715 1.16394C10.668 3.01466 9.17088 6.64345 10.4281 6.86976C11.0908 6.98916 11.8099 6.30792 11.864 4.60529C15.4844 8.11144 12.2957 11.8369 13.6733 12.1442C14.1577 12.2525 14.6864 11.587 14.6864 11.587C14.4648 15.126 11.6886 17.8056 8.17922 17.8084C5.17949 17.8084 1.83535 16.0553 1.46295 11.8046C1.46295 11.8046 2.41495 12.7861 3.04915 12.3377C3.87609 11.7527 2.16622 8.76583 5.08055 5.83679Z"
      fill="#FF1446"
    />
    <path
      d="M5.07921 5.87195C6.66774 4.26836 7.41254 3.07758 7.63934 1.19678C10.6568 3.05953 9.15227 6.65592 10.423 6.88871C11.0899 7.01089 11.8169 6.33058 11.8734 4.63767C15.4569 8.12299 12.2789 11.8494 13.6575 12.1646C14.1433 12.2757 14.6781 11.6143 14.6781 11.6143C14.4503 15.1219 11.6858 17.8001 8.17974 17.8028C5.19867 17.8028 1.85874 16.0599 1.47607 11.8351C1.47607 11.8351 2.43274 12.8056 3.06647 12.3539C3.89154 11.7657 2.19847 8.78016 5.07921 5.87195Z"
      fill="#FF1446"
    />
    <path
      d="M5.07778 5.90664C6.66865 4.29334 7.41531 3.13588 7.65191 1.2287C10.6465 3.10349 9.13451 6.66794 10.4178 6.90721C11.0889 7.03263 11.8234 6.35324 11.8827 4.67005C15.4289 8.13408 12.2621 11.8614 13.6411 12.1854C14.1278 12.2997 14.6692 11.6421 14.6692 11.6421C14.4349 15.1177 11.683 17.795 8.17971 17.7977C5.21731 17.7977 1.88158 16.0655 1.48865 11.8665C1.48865 11.8665 2.44998 12.8264 3.08278 12.371C3.90691 11.7786 2.23018 8.79449 5.07778 5.90664Z"
      fill="#FF1446"
    />
    <path
      d="M5.07635 5.94131C6.66955 4.31782 7.41855 3.19415 7.66402 1.26105C10.6353 3.14787 9.11582 6.67993 10.4122 6.92614C11.0875 7.05433 11.83 6.37587 11.892 4.70286C15.4014 8.14561 12.2453 11.8739 13.6252 12.2062C14.1134 12.3237 14.6603 11.6698 14.6603 11.6698C14.42 15.1135 11.6797 17.7898 8.17969 17.7926C5.23595 17.7926 1.90442 16.0705 1.50122 11.898C1.50122 11.898 2.46722 12.8472 3.09955 12.3881C3.92229 11.7915 2.26189 8.80926 5.07635 5.94131Z"
      fill="#FF1446"
    />
    <path
      d="M5.07493 5.97649C6.67046 4.34329 7.42133 3.25294 7.67613 1.29346C10.6245 3.19185 9.0976 6.69198 10.4071 6.94513C11.0865 7.07656 11.8369 6.39903 11.9013 4.73574C15.3738 8.15719 12.228 11.8869 13.6089 12.227C14.0979 12.3473 14.6514 11.6976 14.6514 11.6976C14.405 15.1093 11.6764 17.7847 8.17966 17.7875C5.25459 17.7875 1.92773 16.0756 1.51379 11.9294C1.51379 11.9294 2.48446 12.8675 3.11586 12.4057C3.93719 11.804 2.29359 8.82315 5.07493 5.97649Z"
      fill="#FF1446"
    />
    <path
      d="M5.07354 6.01122C6.67141 4.36783 7.42461 3.31126 7.68874 1.32587C10.6138 3.23629 9.07941 6.70402 10.4019 6.96411C11.0851 7.09832 11.8439 6.42172 11.9107 4.76861C15.3458 8.16877 12.2112 11.8994 13.593 12.2479C14.0835 12.3714 14.643 11.7254 14.643 11.7254C14.3905 15.1052 11.6741 17.7801 8.18014 17.7824C5.27374 17.7824 1.95108 16.0807 1.52734 11.9605C1.52734 11.9605 2.50268 12.8879 3.13361 12.4223C3.95261 11.817 2.32488 8.83751 5.07354 6.01122Z"
      fill="#FF1446"
    />
    <path
      d="M5.07218 6.04592C6.67238 4.39281 7.42791 3.36956 7.70091 1.35779C10.6031 3.28024 9.06124 6.71558 10.3968 6.98261C11.0842 7.12007 11.8505 6.44438 11.92 4.80053C15.3183 8.17941 12.1944 11.9114 13.5772 12.2677C14.069 12.3946 14.6342 11.7522 14.6342 11.7522C14.3756 15.1005 11.6708 17.7741 8.18018 17.7764C5.29244 17.7764 1.97398 16.0849 1.53998 11.991C1.53998 11.991 2.51998 12.9073 3.14998 12.4385C3.96804 11.8299 2.35618 8.85185 5.07218 6.04592Z"
      fill="#FF1446"
    />
    <path
      d="M5.07077 6.08113C6.67331 4.41784 7.43071 3.42838 7.71304 1.39069C10.5919 3.32517 9.04257 6.72812 10.3912 7.00163C11.0828 7.14186 11.857 6.4671 11.9289 4.83343C15.2903 8.19148 12.1772 11.9239 13.5604 12.2886C14.0532 12.4187 14.6248 11.78 14.6248 11.78C14.3607 15.0959 11.6676 17.7686 8.18064 17.7714C5.31111 17.7714 1.99777 16.0905 1.55304 12.0225C1.55304 12.0225 2.53771 12.9282 3.16724 12.4557C3.98297 11.8429 2.38791 8.86577 5.07077 6.08113Z"
      fill="#FF1446"
    />
    <path
      d="M5.06937 6.11582C6.67423 4.44235 7.43397 3.48668 7.72564 1.42261C10.5812 3.36913 9.02484 6.73967 10.3861 7.02013C11.0819 7.16359 11.864 6.4893 11.9387 4.86581C15.2628 8.20257 12.1604 11.9364 13.545 12.309C14.0392 12.4418 14.6169 11.8073 14.6169 11.8073C14.3458 15.0918 11.6648 17.7635 8.1811 17.7658C5.33023 17.7658 2.0211 16.0951 1.5661 12.053C1.5661 12.053 2.55543 12.9481 3.18403 12.4723C3.99837 11.8559 2.41917 8.87964 5.06937 6.11582Z"
      fill="#FF1446"
    />
    <path
      d="M5.06798 6.15055C6.67518 4.4669 7.43678 3.54501 7.73778 1.45502C10.5704 3.41311 9.00618 6.75172 10.381 7.03912C11.081 7.18536 11.871 6.51245 11.948 4.89868C15.2352 8.21416 12.1436 11.9489 13.5286 12.3298C14.0238 12.4659 14.6076 11.8346 14.6076 11.8346C14.3304 15.0871 11.6615 17.7579 8.18065 17.7603C5.34845 17.7603 2.04351 16.0997 1.57825 12.0841C1.57825 12.0841 2.57225 12.968 3.20038 12.489C4.01378 11.8684 2.44998 8.89354 5.06798 6.15055Z"
      fill="#FF1446"
    />
    <path
      d="M5.06704 6.18525C6.67611 4.49141 7.44051 3.60331 7.75038 1.48694C10.5597 3.45706 8.98844 6.76328 10.3758 7.05762C11.08 7.2071 11.8776 6.53466 11.9574 4.93107C15.2077 8.22525 12.1268 11.9614 13.5128 12.3502C14.0093 12.4895 14.5992 11.8619 14.5992 11.8619C14.3159 15.0825 11.6587 17.7524 8.18111 17.7547C5.36758 17.7547 2.06684 16.1044 1.59131 12.1151C1.59131 12.1151 2.58998 12.9884 3.21718 12.5057C4.02918 11.8813 2.48124 8.90695 5.06704 6.18525Z"
      fill="#FF1446"
    />
    <path
      d="M5.06564 6.2204C6.67704 4.51638 7.4433 3.66206 7.76297 1.51978C10.5494 3.50193 8.97024 6.77575 10.3712 7.07703C11.0796 7.22929 11.885 6.55824 11.9672 4.96391C15.1802 8.2368 12.11 11.9739 13.4974 12.3714C13.9948 12.514 14.5908 11.8901 14.5908 11.8901C14.3014 15.0788 11.6559 17.7477 8.18157 17.75C5.3867 17.75 2.09064 16.1104 1.60437 12.1465C1.60437 12.1465 2.6077 13.0092 3.23444 12.5228C4.04457 11.8943 2.51204 8.92082 5.06564 6.2204Z"
      fill="#FF1446"
    />
    <path
      d="M5.06421 6.25512C6.67748 4.54092 7.44654 3.72039 7.77508 1.55219C10.5382 3.54637 8.95201 6.7878 10.3655 7.09556C11.0781 7.2506 11.8915 6.58047 11.9765 4.99632C15.1526 8.24792 12.0931 11.9864 13.481 12.3918C13.9799 12.5376 14.5819 11.9174 14.5819 11.9174C14.286 15.0746 11.6531 17.7422 8.18154 17.7445C5.40534 17.7445 2.11348 16.115 1.61694 12.1775C1.61694 12.1775 2.62541 13.0291 3.25074 12.5399C4.05948 11.9073 2.54281 8.93425 5.06421 6.25512Z"
      fill="#FF1446"
    />
    <path
      d="M5.0628 6.28982C6.67794 4.56498 7.44934 3.77869 7.78767 1.58411C10.5279 3.59033 8.9338 6.79936 10.3604 7.11406C11.0772 7.27234 11.8985 6.60313 11.9858 5.0287C15.1251 8.25948 12.0763 11.9989 13.4651 12.4122C13.9649 12.5607 14.5735 11.9447 14.5735 11.9447C14.2715 15.07 11.6503 17.7366 8.182 17.7389C5.42447 17.7389 2.1368 16.1196 1.63 12.2085C1.63 12.2085 2.64314 13.0494 3.268 12.5565C4.07487 11.9202 2.5736 8.94766 5.0628 6.28982Z"
      fill="#FF1446"
    />
    <path
      d="M5.06144 6.32497C6.67844 4.58995 7.45264 3.83744 7.79984 1.61694C10.5168 3.63474 8.91564 6.81183 10.3553 7.13348C11.0758 7.29453 11.9051 6.62625 11.9952 5.062C15.0971 8.27149 12.0591 12.0118 13.4488 12.4334C13.95 12.5852 14.5642 11.9729 14.5642 11.9729C14.2562 15.0663 11.6466 17.7324 8.18157 17.7343C5.44271 17.7343 2.15924 16.1251 1.64264 12.2404C1.64264 12.2404 2.66044 13.0707 3.28437 12.5741C4.09031 11.9331 2.60444 8.96106 5.06144 6.32497Z"
      fill="#FF8200"
    />
    <path
      d="M5.06001 6.35973C6.67888 4.61406 7.45541 3.8958 7.81195 1.64893C10.506 3.67875 8.89695 6.82345 10.3497 7.15204C11.0744 7.31587 11.9116 6.64898 12.004 5.09444C15.0691 8.28265 12.0418 12.0244 13.4325 12.4538C13.9346 12.6089 14.5553 12.0003 14.5553 12.0003C14.2412 15.0617 11.6433 17.7269 8.18155 17.7288C5.46135 17.7288 2.18255 16.1298 1.65521 12.271C1.65521 12.271 2.67768 13.0906 3.30115 12.5904C4.10568 11.9461 2.63521 8.97453 5.06001 6.35973Z"
      fill="#FF8300"
    />
    <path
      d="M5.05865 6.39445C6.67938 4.63814 7.45872 3.95413 7.82458 1.68134C10.4953 3.72319 8.87925 6.83503 10.345 7.17102C11.074 7.33809 11.9191 6.67167 12.0143 5.12685C15.0425 8.29377 12.026 12.0369 13.4176 12.4742C13.9211 12.6325 14.5478 12.0276 14.5478 12.0276C14.2277 15.0571 11.6419 17.7214 8.18298 17.7232C5.48145 17.7232 2.20685 16.1349 1.66925 12.302C1.66925 12.302 2.69638 13.1106 3.31892 12.6075C4.12065 11.9587 2.66558 8.9875 5.05865 6.39445Z"
      fill="#FF8400"
    />
    <path
      d="M5.0572 6.42964C6.6798 4.66268 7.46194 4.01292 7.83667 1.71375C10.4845 3.76764 8.861 6.84708 10.3394 7.18955C11.0725 7.3594 11.9251 6.69436 12.0231 5.15926C15.014 8.30489 12.0082 12.0494 13.4007 12.4946C13.9052 12.6561 14.538 12.0549 14.538 12.0549C14.2113 15.0529 11.6377 17.7159 8.18247 17.7177C5.4996 17.7177 2.2292 16.1396 1.68134 12.3331C1.68134 12.3331 2.71314 13.1309 3.3352 12.6242C4.136 11.9716 2.69587 9.00094 5.0572 6.42964Z"
      fill="#FF8500"
    />
    <path
      d="M5.0558 6.46436C6.6798 4.68676 7.46473 4.07124 7.8488 1.74615C10.4733 3.81208 8.84233 6.85913 10.3343 7.20854C11.0716 7.38116 11.9321 6.71705 12.0325 5.19214C14.9865 8.31647 11.9914 12.0619 13.3849 12.5154C13.8907 12.6802 14.5296 12.0827 14.5296 12.0827C14.1969 15.0488 11.6349 17.7108 8.18293 17.7126C5.51873 17.7126 2.25253 16.1447 1.6944 12.3641C1.6944 12.3641 2.73086 13.1513 3.352 12.6408C4.1514 11.9846 2.7262 9.01391 5.0558 6.46436Z"
      fill="#FF8600"
    />
    <path
      d="M5.05443 6.49906C6.6803 4.71082 7.46803 4.12954 7.86143 1.77808C10.4631 3.85557 8.82463 6.87022 10.3292 7.22704C11.0707 7.4029 11.9392 6.73972 12.0418 5.22452C14.959 8.32757 11.9746 12.0744 13.3686 12.5362C13.8754 12.7042 14.5208 12.1105 14.5208 12.1105C14.182 15.0446 11.6321 17.7057 8.18296 17.7075C5.53743 17.7075 2.2759 16.1498 1.70703 12.3955C1.70703 12.3955 2.74816 13.1717 3.36883 12.658C4.16683 11.9975 2.75656 9.02685 5.05443 6.49906Z"
      fill="#FF8700"
    />
    <path
      d="M5.053 6.53421C6.68027 4.73532 7.4708 4.1883 7.87354 1.81091C10.4519 3.90044 8.80594 6.8827 10.3235 7.24646C11.0693 7.4251 11.9457 6.76283 12.0511 5.25782C14.9309 8.34004 11.9573 12.0878 13.3527 12.5575C13.8609 12.7283 14.5119 12.1387 14.5119 12.1387C14.167 15.0409 11.6288 17.701 8.18294 17.7029C5.55607 17.7029 2.29874 16.1553 1.7196 12.4275C1.7196 12.4275 2.7654 13.1929 3.38514 12.676C4.1822 12.0105 2.7864 9.03979 5.053 6.53421Z"
      fill="#FF8800"
    />
    <path
      d="M5.0516 6.56891C6.68027 4.75891 7.47407 4.2466 7.88613 1.84283C10.4416 3.94439 8.7882 6.89426 10.3189 7.26496C11.0688 7.44637 11.9527 6.78504 12.0609 5.28974C14.9039 8.35068 11.941 12.0998 13.3368 12.5774C13.8459 12.7514 14.5035 12.1655 14.5035 12.1655C14.1525 15.0358 11.626 17.695 8.1834 17.6968C5.57473 17.6968 2.32207 16.1599 1.73267 12.458C1.73267 12.458 2.78313 13.2128 3.4024 12.6922C4.19713 12.0234 2.81673 9.05274 5.0516 6.56891Z"
      fill="#FF8900"
    />
    <path
      d="M5.0507 6.60363C6.68077 4.78299 7.47737 4.30492 7.89877 1.87524C10.4309 3.98883 8.7705 6.90584 10.3138 7.28394C11.0679 7.4686 11.9597 6.80819 12.0703 5.32261C14.8764 8.36226 11.9238 12.1128 13.321 12.5982C13.8315 12.7755 14.4946 12.1933 14.4946 12.1933C14.1372 15.0321 11.6228 17.6899 8.18343 17.6918C5.59343 17.6918 2.34497 16.165 1.7453 12.489C1.7453 12.489 2.80043 13.2327 3.41877 12.7088C4.21257 12.0364 2.84663 9.06525 5.0507 6.60363Z"
      fill="#FF8A00"
    />
    <path
      d="M5.04932 6.6383C6.68078 4.80655 7.48065 4.36319 7.91092 1.90759C10.4202 4.03275 8.75185 6.91783 10.3087 7.30241C11.067 7.48984 11.9667 6.83036 12.0797 5.35496C14.8484 8.37332 11.907 12.1252 13.3051 12.6186C13.8166 12.799 14.4862 12.2205 14.4862 12.2205C14.1227 15.0274 11.6204 17.6848 8.18392 17.6862C5.61258 17.6862 2.36878 16.1696 1.75885 12.52C1.75885 12.52 2.81912 13.253 3.43652 12.7255C4.22798 12.0493 2.87652 9.07816 5.04932 6.6383Z"
      fill="#FF8B00"
    />
    <path
      d="M5.04791 6.67348C6.68031 4.83063 7.48345 4.42198 7.92351 1.94C10.4094 4.0772 8.73411 6.92987 10.3035 7.3214C11.066 7.51161 11.9737 6.85351 12.0894 5.38784C14.8213 8.3849 11.8902 12.1382 13.2892 12.6394C13.8021 12.8231 14.4778 12.2483 14.4778 12.2483C14.1082 15.0233 11.6176 17.6797 8.18438 17.6811C5.63171 17.6811 2.39211 16.1747 1.77191 12.5515C1.77191 12.5515 2.83684 13.2739 3.45331 12.7431C4.24338 12.0623 2.90638 9.09067 5.04791 6.67348Z"
      fill="#FF8C00"
    />
    <path
      d="M5.04646 6.70821C6.6798 4.85424 7.48666 4.48031 7.9356 1.97241C10.3987 4.12164 8.71586 6.94146 10.2979 7.34039C11.0646 7.53383 11.9797 6.8762 12.0983 5.42071C14.7933 8.39649 11.8729 12.1507 13.2729 12.6602C13.7867 12.8472 14.4685 12.2761 14.4685 12.2761C14.0928 15.0191 11.6139 17.6746 8.18386 17.676C5.64986 17.676 2.41446 16.1798 1.784 12.5825C1.784 12.5825 2.8536 13.2943 3.4696 12.7597C4.25873 12.0748 2.9362 9.10318 5.04646 6.70821Z"
      fill="#FF8D00"
    />
    <path
      d="M5.04506 6.74291C6.67979 4.87784 7.48992 4.53861 7.94772 2.00433C10.3875 4.16559 8.69719 6.95301 10.2927 7.35889C11.0637 7.55511 11.9867 6.89887 12.1076 5.45309C14.7653 8.40805 11.8556 12.1632 13.2565 12.6806C13.7717 12.8708 14.4596 12.3034 14.4596 12.3034C14.0779 15.0145 11.6111 17.6691 8.18432 17.6705C5.66899 17.6705 2.43826 16.1849 1.79706 12.6135C1.79706 12.6135 2.87132 13.3141 3.48639 12.7764C4.27366 12.0877 2.96559 9.11566 5.04506 6.74291Z"
      fill="#FF8E00"
    />
    <path
      d="M5.04365 6.77806C6.67885 4.90188 7.49272 4.59736 7.96032 2.03717C10.3772 4.21046 8.67945 6.96503 10.2876 7.3783C11.0627 7.57731 11.9937 6.92199 12.1169 5.48593C14.7377 8.41959 11.8388 12.1761 13.2407 12.7014C13.7568 12.8948 14.4512 12.3307 14.4512 12.3307C14.0629 15.0098 11.6083 17.6635 8.18479 17.6649C5.68812 17.6649 2.46159 16.1895 1.81012 12.6445C1.81012 12.6445 2.88999 13.3359 3.50365 12.7935C4.29045 12.1025 2.99499 9.12814 5.04365 6.77806Z"
      fill="#FF8F00"
    />
    <path
      d="M5.04229 6.81281C6.67842 4.92507 7.49602 4.65572 7.97249 2.06915C10.366 4.25401 8.66129 6.97664 10.2825 7.3964C11.0618 7.59818 12.0008 6.94425 12.1263 5.51791C14.7102 8.43029 11.8216 12.1882 13.2248 12.7218C13.7424 12.9185 14.4424 12.358 14.4424 12.358C14.048 15.0057 11.605 17.658 8.18482 17.6594C5.70682 17.6594 2.48449 16.1942 1.82275 12.6751C1.82275 12.6751 2.90729 13.3554 3.52002 12.8097C4.30589 12.1151 3.02442 9.14068 5.04229 6.81281Z"
      fill="#FF9000"
    />
    <path
      d="M5.04086 6.84754C6.67793 4.94822 7.49879 4.71405 7.98459 2.10156C10.3553 4.29846 8.64306 6.98823 10.2769 7.41539C11.0604 7.62041 12.0068 6.96694 12.1356 5.55078C14.6822 8.44187 11.8047 12.2012 13.2085 12.7426C13.7269 12.9426 14.4335 12.3858 14.4335 12.3858C14.0331 15.0015 11.6022 17.6529 8.18479 17.6543C5.72546 17.6543 2.50733 16.1993 1.83533 12.7065C1.83533 12.7065 2.92453 13.3762 3.53679 12.8269C4.32126 12.128 3.05379 9.15319 5.04086 6.84754Z"
      fill="#FF9100"
    />
    <path
      d="M5.03948 6.88273C6.67701 4.9723 7.50208 4.77284 7.99721 2.13397C10.3445 4.3429 8.62488 7.00028 10.2722 7.43438C11.0599 7.64217 12.0143 6.99009 12.1454 5.58366C14.6551 8.45346 11.7879 12.2141 13.1931 12.7635C13.7125 12.9666 14.4255 12.4136 14.4255 12.4136C14.0191 14.9974 11.5999 17.6478 8.18574 17.6492C5.74508 17.6492 2.53161 16.2048 1.84888 12.738C1.84888 12.738 2.94321 13.3966 3.55408 12.844C4.33621 12.141 3.08321 9.16524 5.03948 6.88273Z"
      fill="#FF9200"
    />
    <path
      d="M5.03813 6.91739C6.67613 4.99539 7.50493 4.8311 8.0094 2.16632C10.3334 4.38728 8.60673 7.0118 10.2667 7.45331C11.0586 7.66388 12.0209 7.01272 12.1543 5.61647C14.6272 8.46498 11.7707 12.227 13.1763 12.7843C13.6971 12.9907 14.4158 12.4413 14.4158 12.4413C14.0033 14.9932 11.5957 17.6427 8.18486 17.6441C5.76286 17.6441 2.5536 16.2099 1.8606 12.7694C1.8606 12.7694 2.9596 13.4174 3.57 12.8615C4.35166 12.1539 3.11266 9.17722 5.03813 6.91739Z"
      fill="#FF9300"
    />
    <path
      d="M5.03672 6.95209C6.67519 5.01806 7.50819 4.88894 8.02199 2.19824C10.3231 4.43123 8.58899 7.02336 10.2615 7.47181C11.0577 7.68516 12.0279 7.03493 12.1637 5.64839C14.5992 8.47607 11.7535 12.2391 13.1605 12.8041C13.6822 13.0138 14.4074 12.4682 14.4074 12.4682C13.9883 14.9881 11.5929 17.6367 8.18532 17.6381C5.78199 17.6381 2.57692 16.214 1.87366 12.7995C1.87366 12.7995 2.97732 13.4368 3.58679 12.8773C4.36706 12.1669 3.14159 9.18971 5.03672 6.95209Z"
      fill="#FF9400"
    />
    <path
      d="M5.03577 6.9873C6.67423 5.0417 7.51143 4.94775 8.03457 2.23114C10.3124 4.4757 8.57077 7.03543 10.2568 7.49082C11.0572 7.70695 12.0348 7.05811 12.1739 5.68129C14.5726 8.48768 11.7371 12.2525 13.1455 12.825C13.6686 13.0379 14.3994 12.496 14.3994 12.496C13.9743 14.9844 11.591 17.6321 8.18623 17.633C5.80157 17.633 2.6007 16.2192 1.88763 12.831C1.88763 12.831 2.99597 13.4572 3.60497 12.8944C4.38243 12.1794 3.1705 9.20178 5.03577 6.9873Z"
      fill="#FF9500"
    />
    <path
      d="M5.03432 7.02196C6.67279 5.06433 7.51465 5.00602 8.04665 2.26349C10.3016 4.52008 8.55252 7.04695 10.2512 7.50975C11.0557 7.72911 12.0413 7.08074 12.1827 5.7141C14.5441 8.49921 11.7198 12.2654 13.1287 12.8458C13.6527 13.0619 14.3901 12.5237 14.3901 12.5237C13.9589 14.9802 11.5873 17.6269 8.18572 17.6279C5.81972 17.6279 2.62352 16.2242 1.89972 12.8624C1.89972 12.8624 3.01272 13.478 3.62079 12.9115C4.39732 12.1923 3.19939 9.21376 5.03432 7.02196Z"
      fill="#FF9600"
    />
    <path
      d="M5.03296 7.05666C6.67142 5.087 7.51749 5.06432 8.05929 2.29541C10.2909 4.56404 8.53482 7.05851 10.2466 7.52825C11.0553 7.75039 12.0488 7.1034 12.1926 5.74648C14.517 8.5103 11.703 12.2779 13.1133 12.8661C13.6388 13.0855 14.3822 12.551 14.3822 12.551C13.9449 14.9756 11.585 17.6214 8.18716 17.6223C5.83982 17.6223 2.64782 16.2293 1.91376 12.893C1.91376 12.893 3.03142 13.4979 3.63902 12.9282C4.41276 12.2053 3.22836 9.22578 5.03296 7.05666Z"
      fill="#FF9700"
    />
    <path
      d="M5.03161 7.09144C6.67008 5.10975 7.52081 5.12271 8.07148 2.32788C10.2802 4.60854 8.51668 7.07015 10.241 7.5473C11.0539 7.77222 12.0549 7.12615 12.2015 5.77942C14.4891 8.52195 11.6858 12.291 13.0965 12.887C13.6229 13.1096 14.3724 12.5788 14.3724 12.5788C13.9291 14.9715 11.5813 17.6164 8.18628 17.6173C5.85714 17.6173 2.66981 16.2344 1.92548 12.9245C1.92548 12.9245 3.04781 13.5183 3.65448 12.9454C4.42821 12.2183 3.25734 9.23789 5.03161 7.09144Z"
      fill="#FF9800"
    />
    <path
      d="M5.03014 7.12663C6.66814 5.1329 7.52401 5.1815 8.08354 2.36029C10.2689 4.65298 8.49841 7.08174 10.2353 7.56629C11.0525 7.79398 12.0614 7.14931 12.2107 5.81183C14.461 8.53307 11.6685 12.3035 13.0806 12.9079C13.6079 13.1337 14.3639 12.6066 14.3639 12.6066C13.9145 14.9673 11.5784 17.6113 8.18668 17.6117C5.87621 17.6117 2.69308 16.2391 1.93848 12.9555C1.93848 12.9555 3.06548 13.5387 3.67168 12.962C4.44354 12.2313 3.28574 9.24948 5.03014 7.12663Z"
      fill="#FF9900"
    />
    <path
      d="M5.02874 7.16129C6.66627 5.15553 7.5268 5.23976 8.09614 2.39264C10.2587 4.6969 8.48021 7.09372 10.2307 7.58521C11.052 7.81569 12.0689 7.17194 12.2205 5.84464C14.4339 8.54505 11.6517 12.3164 13.0647 12.9287C13.5935 13.1577 14.3555 12.6343 14.3555 12.6343C13.8996 14.9636 11.5756 17.6061 8.18714 17.6071C5.89534 17.6071 2.71687 16.2446 1.95154 12.987C1.95154 12.987 3.0832 13.5595 3.68847 12.9791C4.45894 12.2437 3.3142 9.26146 5.02874 7.16129Z"
      fill="#FF9A00"
    />
    <path
      d="M5.02735 7.19599C6.66442 5.17774 7.53008 5.29806 8.10828 2.42456C10.2475 4.74086 8.46202 7.10482 10.2251 7.60325C11.0506 7.8365 12.0754 7.19414 12.2294 5.87656C14.406 8.55569 11.6344 12.3284 13.0484 12.9485C13.5781 13.1809 14.3462 12.6612 14.3462 12.6612C13.8842 14.9585 11.5719 17.6001 8.18668 17.601C5.91355 17.601 2.73928 16.2487 1.96368 13.0175C1.96368 13.0175 3.10002 13.5789 3.70435 12.9958C4.47388 12.2567 3.34268 9.27348 5.02735 7.19599Z"
      fill="#FF9B00"
    />
    <path
      d="M5.02601 7.23114C6.66214 5.20039 7.53294 5.35682 8.12047 2.4574C10.2368 4.78573 8.44387 7.11683 10.22 7.62267C11.0497 7.85916 12.082 7.21726 12.2388 5.90986C14.378 8.5677 11.6172 12.3418 13.0326 12.9698C13.5637 13.2054 14.3379 12.6894 14.3379 12.6894C13.8698 14.9547 11.5696 17.5955 8.18721 17.5964C5.93274 17.5964 2.76267 16.2547 1.97681 13.0494C1.97681 13.0494 3.11781 13.6001 3.72167 13.0133C4.48934 12.2696 3.37121 9.28503 5.02601 7.23114Z"
      fill="#FF9C00"
    />
    <path
      d="M5.0246 7.26584C6.6598 5.22259 7.5362 5.41512 8.13307 2.48932C10.2261 4.82968 8.42614 7.12793 10.2149 7.64117C11.0488 7.88043 12.089 7.23992 12.2486 5.94224C14.3509 8.57879 11.6004 12.3547 13.0167 12.9902C13.5487 13.229 14.3295 12.7167 14.3295 12.7167C13.8553 14.9501 11.5668 17.5899 8.18767 17.5908C5.95187 17.5908 2.786 16.2594 1.98987 13.0804C1.98987 13.0804 3.13553 13.6205 3.73847 13.03C4.50473 12.2826 3.39967 9.29705 5.0246 7.26584Z"
      fill="#FF9D00"
    />
    <path
      d="M5.0232 7.30056C6.657 5.24436 7.539 5.47344 8.1452 2.52173C10.2153 4.87412 8.40793 7.13997 10.2097 7.66016C11.0479 7.9022 12.096 7.26261 12.2579 5.97465C14.3234 8.58991 11.5831 12.3673 13.0009 13.0105C13.5338 13.2526 14.3206 12.744 14.3206 12.744C13.8399 14.9455 11.5635 17.5848 8.18766 17.5853C5.97053 17.5853 2.80933 16.264 2.00293 13.111C2.00293 13.111 3.15326 13.6399 3.75573 13.0462C4.52013 12.2955 3.42766 9.30864 5.0232 7.30056Z"
      fill="#FF9E00"
    />
    <path
      d="M5.02177 7.33575C6.65417 5.26659 7.54224 5.53223 8.15777 2.55414C10.2046 4.9181 8.39017 7.15156 10.2046 7.67914C11.0469 7.92396 12.103 7.28577 12.2672 6.00752C14.2954 8.6015 11.5663 12.3802 12.9845 13.0314C13.5188 13.2767 14.3117 12.7718 14.3117 12.7718C13.825 14.9413 11.5607 17.5797 8.18764 17.5802C5.98917 17.5802 2.83217 16.2691 2.0155 13.1425C2.0155 13.1425 3.1705 13.6608 3.77204 13.0638C4.53504 12.308 3.4561 9.32022 5.02177 7.33575Z"
      fill="#FF9F00"
    />
    <path
      d="M5.02034 7.37047C6.65134 5.28835 7.54501 5.59056 8.16988 2.58655C10.1938 4.96254 8.37241 7.1599 10.1989 7.69767C11.0459 7.94388 12.109 7.308 12.2761 6.03993C14.2673 8.61262 11.5471 12.3955 12.9681 13.0518C13.5029 13.3012 14.3023 12.7991 14.3023 12.7991C13.8095 14.9372 11.5569 17.5742 8.18761 17.5747C6.00781 17.5747 2.85501 16.2742 2.02808 13.1735C2.02808 13.1735 3.18774 13.6807 3.78881 13.0805C4.55041 12.321 3.48408 9.3318 5.02034 7.37047Z"
      fill="#FFA000"
    />
    <path
      d="M5.01939 7.4052C6.64852 5.31012 7.54872 5.64888 8.18245 2.61896C10.1831 5.00699 8.35465 7.17241 10.1943 7.71666C11.0455 7.96611 12.1165 7.33069 12.2863 6.07281C14.2407 8.62467 11.5313 12.408 12.9532 13.0726C13.4894 13.3253 14.2949 12.8264 14.2949 12.8264C13.796 14.9326 11.5551 17.5687 8.18899 17.5691C6.02785 17.5691 2.87972 16.2789 2.04205 13.204C2.04205 13.204 3.20685 13.7006 3.80652 13.0967C4.56579 12.334 3.51205 9.34385 5.01939 7.4052Z"
      fill="#FFA100"
    />
    <path
      d="M5.01802 7.44032C6.64529 5.33182 7.55155 5.70761 8.19509 2.65131C10.1728 5.05137 8.33649 7.1844 10.1892 7.73559C11.0446 7.98781 12.1235 7.35378 12.2957 6.10562C14.2128 8.63619 11.5145 12.4205 12.9369 13.0938C13.474 13.3493 14.286 12.8546 14.286 12.8546C13.7811 14.9288 11.5518 17.564 8.18902 17.564C6.04655 17.564 2.90262 16.2839 2.05469 13.2355C2.05469 13.2355 3.22415 13.7214 3.82335 13.1137C4.58122 12.3469 3.53962 9.35537 5.01802 7.44032Z"
      fill="#FFA200"
    />
    <path
      d="M5.01666 7.47505C6.64159 5.35359 7.55486 5.76594 8.20726 2.68372C10.1617 5.09581 8.31832 7.19644 10.1841 7.75457C11.0437 8.01004 12.1305 7.37647 12.3051 6.13803C14.1853 8.64731 11.4973 12.433 12.9211 13.1142C13.4596 13.3729 14.2772 12.8819 14.2772 12.8819C13.7657 14.9242 11.5491 17.5585 8.18906 17.5585C6.06526 17.5585 2.92552 16.2885 2.06732 13.2665C2.06732 13.2665 3.24146 13.7413 3.83972 13.1309C4.59619 12.3599 3.56766 9.36696 5.01666 7.47505Z"
      fill="#FFA300"
    />
    <path
      d="M5.01523 7.50974C6.63783 5.3744 7.55809 5.82424 8.21936 2.71564C10.1509 5.1393 8.30009 7.208 10.1784 7.77308C11.0422 8.03178 12.1366 7.39914 12.3139 6.17041C14.1572 8.6584 11.48 12.445 12.9042 13.1346C13.4442 13.3965 14.2683 12.9092 14.2683 12.9092C13.7508 14.9196 11.5458 17.5529 8.18903 17.5529C6.08389 17.5529 2.94836 16.2932 2.0799 13.2975C2.0799 13.2975 3.2587 13.7617 3.8565 13.1475C4.61156 12.3728 3.59516 9.37852 5.01523 7.50974Z"
      fill="#FFA400"
    />
    <path
      d="M5.01382 7.54447C6.63362 5.39571 7.56089 5.88257 8.23196 2.74805C10.1402 5.18374 8.28189 7.22051 10.1733 7.7916C11.0408 8.05308 12.1436 7.42136 12.3237 6.20282C14.1297 8.66953 11.4636 12.4571 12.8888 13.1549C13.4297 13.4201 14.2599 12.9365 14.2599 12.9365C13.7363 14.9154 11.543 17.5473 8.18949 17.5473C6.10302 17.5473 2.97216 16.2983 2.09296 13.328C2.09296 13.328 3.27642 13.7816 3.87329 13.1637C4.62696 12.3853 3.62269 9.3901 5.01382 7.54447Z"
      fill="#FFA500"
    />
    <path
      d="M5.01246 7.57965C6.62946 5.41701 7.56419 5.94135 8.24412 2.78046C10.1295 5.22819 8.26372 7.23256 10.1682 7.81059C11.0399 8.07531 12.1506 7.44452 12.3331 6.23569C14.1022 8.68111 11.4464 12.47 12.873 13.1758C13.4148 13.4437 14.2511 12.9643 14.2511 12.9643C13.7214 14.9113 11.5402 17.5423 8.18952 17.5423C6.12172 17.5423 2.99506 16.3034 2.10559 13.3595C2.10559 13.3595 3.29372 13.8019 3.89012 13.1809C4.64239 12.3983 3.65026 9.40168 5.01246 7.57965Z"
      fill="#FFA600"
    />
    <path
      d="M5.03572 7.67359C6.63172 5.54381 7.56832 6.02419 8.23192 2.93549C10.1056 5.35961 8.27485 7.3376 10.1452 7.90731C11.0076 8.1711 12.0992 7.56669 12.3022 6.36943C14.0349 8.79171 11.4216 12.495 12.8309 13.2114C13.3648 13.4812 14.2071 13.0133 14.2071 13.0133C13.6751 14.9478 11.5028 17.5386 8.18712 17.5386C6.13892 17.5386 3.03232 16.3131 2.15265 13.4072C2.15778 13.4104 3.33892 13.8385 3.92785 13.2211C4.67172 12.439 3.68098 9.47804 5.03572 7.67359Z"
      fill="#FFA701"
    />
    <path
      d="M5.05861 7.76751C6.63407 5.67058 7.57207 6.107 8.21887 3.09003C10.0809 5.49009 8.28514 7.4417 10.1215 8.00353C10.9741 8.2664 12.0474 7.68883 12.2709 6.50315C13.9668 8.90182 11.395 12.5237 12.7889 13.2466C13.3144 13.5191 14.1628 13.0614 14.1628 13.0614C13.6285 14.9834 11.465 17.5339 8.18434 17.5339C6.15574 17.5339 3.06967 16.3218 2.19934 13.4539C2.20961 13.4608 3.38374 13.8741 3.96474 13.26C4.70114 12.4792 3.71181 9.55483 5.05861 7.76751Z"
      fill="#FFA802"
    />
    <path
      d="M5.08201 7.86149C6.63694 5.79741 7.57634 6.18986 8.20634 3.24463C10.0567 5.62109 8.2964 7.54632 10.0982 8.09936C10.9415 8.36131 11.9961 7.81011 12.2397 6.63646C13.8987 9.012 11.3689 12.5501 12.7465 13.2818C13.2636 13.5562 14.1181 13.1101 14.1181 13.1101C13.5819 15.0196 11.4273 17.5298 8.18114 17.5298C6.17214 17.5298 3.10661 16.3311 2.24561 13.5011C2.26101 13.5113 3.42767 13.9102 4.00167 13.2993C4.73107 12.5195 3.74267 9.63122 5.08201 7.86149Z"
      fill="#FFA903"
    />
    <path
      d="M5.10524 7.95543C6.63964 5.92421 7.58044 6.2727 8.19411 3.39966C10.0328 5.75251 8.30891 7.6472 10.0752 8.19608C10.9096 8.45525 11.9447 7.93229 12.2093 6.7702C13.8319 9.12214 11.3436 12.5769 12.7054 13.3169C13.214 13.5937 14.0746 13.1586 14.0746 13.1586C13.536 15.0556 11.3903 17.5256 8.17871 17.5256C6.18931 17.5256 3.14384 16.3399 2.29218 13.5483C2.31271 13.5622 3.47238 13.9459 4.03891 13.3387C4.76038 12.5602 3.77338 9.70757 5.10524 7.95543Z"
      fill="#FFA904"
    />
    <path
      d="M5.1286 8.0494C6.642 6.05105 7.58467 6.35557 8.18153 3.55426C10.009 5.88351 8.32013 7.7509 10.0524 8.29191C10.8775 8.54968 11.8939 8.05403 12.1785 6.90352C13.7643 9.23185 11.318 12.6029 12.6634 13.3521C13.1641 13.6307 14.0303 13.2068 14.0303 13.2068C13.4894 15.0913 11.3525 17.521 8.17593 17.521C6.20613 17.521 3.1812 16.3487 2.33887 13.5951C2.36453 13.6122 3.5172 13.9815 4.07627 13.3776C4.79027 12.6005 3.8042 9.78442 5.1286 8.0494Z"
      fill="#FFAA05"
    />
    <path
      d="M5.15149 8.14332C6.64903 6.18106 7.58843 6.43837 8.16896 3.7088C9.98476 6.01399 8.33089 7.85407 10.0291 8.38814C10.8448 8.64452 11.8421 8.17571 12.1478 7.03677C13.6971 9.34196 11.2924 12.6292 12.6214 13.3868C13.1138 13.6673 13.986 13.2549 13.986 13.2549C13.4428 15.1269 11.3152 17.5163 8.17316 17.5163C6.22296 17.5163 3.21809 16.3575 2.38556 13.6418C2.41589 13.6626 3.56343 14.0185 4.11316 13.4169C4.82156 12.6426 3.83923 9.86353 5.15149 8.14332Z"
      fill="#FFAB06"
    />
    <path
      d="M5.17481 8.23732C6.65228 6.30838 7.59308 6.52127 8.15634 3.86389C9.96048 6.14548 8.34208 7.95825 10.0057 8.48491C10.8117 8.73991 11.7907 8.29841 12.1165 7.17103C13.6289 9.45262 11.2662 12.6561 12.5794 13.4229C13.0633 13.7057 13.9421 13.304 13.9421 13.304C13.3965 15.1635 11.2779 17.5127 8.17081 17.5127C6.24021 17.5127 3.25588 16.3673 2.43268 13.6895C2.46814 13.7136 3.60868 14.0551 4.15094 13.4567C4.85141 12.6834 3.87048 9.94041 5.17481 8.23732Z"
      fill="#FFAC07"
    />
    <path
      d="M5.19815 8.33123C6.65555 6.43562 7.59728 6.60408 8.14375 4.01843C9.93622 6.27642 8.35328 8.06188 9.98241 8.58068C10.779 8.83429 11.7394 8.41963 12.0857 7.30429C13.5617 9.56273 11.241 12.682 12.5374 13.4576C13.0129 13.7422 13.8977 13.3521 13.8977 13.3521C13.3503 15.1991 11.2401 17.508 8.16802 17.508C6.25702 17.508 3.29275 16.376 2.47888 13.7367C2.51948 13.7645 3.65302 14.0912 4.18782 13.496C4.88128 12.7241 3.90175 10.0177 5.19815 8.33123Z"
      fill="#FFAD08"
    />
    <path
      d="M5.22151 8.42521C6.65884 6.56245 7.60151 6.68694 8.13164 4.17303C9.91291 6.40695 8.36497 8.16558 9.95957 8.67697C10.7468 8.92965 11.6881 8.54137 12.0549 7.4376C13.4941 9.67244 11.2154 12.7079 12.4959 13.4928C12.9635 13.7793 13.8539 13.4003 13.8539 13.4003C13.3042 15.2348 11.2032 17.5034 8.16524 17.5034C6.27384 17.5034 3.33011 16.3848 2.52557 13.7835C2.57131 13.8145 3.69831 14.1269 4.22471 13.5349C4.91071 12.7648 3.93304 10.0945 5.22151 8.42521Z"
      fill="#FFAE09"
    />
    <path
      d="M5.24438 8.51909C6.66164 6.68965 7.60524 6.76972 8.11858 4.328C9.88818 6.53832 8.37571 8.26964 9.93578 8.77362C10.7137 9.02492 11.6363 8.66394 12.0236 7.57174C13.4264 9.78298 11.1892 12.7347 12.4534 13.5284C12.9126 13.8167 13.8091 13.4493 13.8091 13.4493C13.257 15.2713 11.165 17.4996 8.16198 17.4996C6.29018 17.4996 3.36651 16.3945 2.57178 13.8311C2.62264 13.8658 3.74264 14.1633 4.26158 13.5751C4.94058 12.805 3.96478 10.1713 5.24438 8.51909Z"
      fill="#FFAE0A"
    />
    <path
      d="M5.2677 8.61312C6.66443 6.81701 7.60943 6.85218 8.10643 4.48267C9.86436 6.66938 8.38736 8.37339 9.9129 8.86951C10.6815 9.11942 11.585 8.78528 11.9928 7.70465C13.3588 9.89276 11.1636 12.7598 12.4119 13.5632C12.8632 13.8534 13.7652 13.4975 13.7652 13.4975C13.2108 15.3065 11.1276 17.4951 8.15963 17.4951C6.30743 17.4951 3.4043 16.4034 2.6189 13.8779C2.6749 13.9158 3.7879 14.1991 4.29936 13.6141C4.97043 12.8459 3.99603 10.2486 5.2677 8.61312Z"
      fill="#FFAF0B"
    />
    <path
      d="M5.29108 8.70704C6.66774 6.94425 7.61368 6.93499 8.09388 4.63721C9.84014 6.80032 8.39861 8.47749 9.89008 8.96574C10.6493 9.21426 11.5341 8.90743 11.9625 7.83837C13.2921 10.0029 11.1384 12.7861 12.3704 13.5983C12.8133 13.8904 13.7219 13.5456 13.7219 13.5456C13.1656 15.3421 11.0913 17.4904 8.15781 17.4904C6.32568 17.4904 3.44261 16.4121 2.66608 13.9246C2.72721 13.9658 3.83321 14.2347 4.33721 13.6529C5.00034 12.8866 4.02734 10.3254 5.29108 8.70704Z"
      fill="#FFB00C"
    />
    <path
      d="M5.31393 8.80098C6.67053 7.07151 7.61786 7.01782 8.08079 4.79224C9.81586 6.93128 8.40886 8.58161 9.86626 9.06246C10.6157 9.30959 11.4819 9.0296 11.9308 7.97257C13.2235 10.1135 11.1123 12.8125 12.3279 13.6344C12.7624 13.9283 13.6771 13.5951 13.6771 13.5951C13.1185 15.3792 11.053 17.4872 8.15406 17.4872C6.34153 17.4872 3.47853 16.4223 2.71179 13.9727C2.77806 14.0176 3.87706 14.2717 4.37313 13.6932C5.03019 12.9273 4.05859 10.4023 5.31393 8.80098Z"
      fill="#FFB10D"
    />
    <path
      d="M5.33724 8.89495C6.67331 7.19834 7.62204 7.10069 8.06864 4.94684C9.79204 7.06228 8.42051 8.68531 9.84338 9.15829C10.5835 9.40449 11.431 9.15134 11.9004 8.10542C13.1567 10.2232 11.087 12.8375 12.2864 13.6687C12.7129 13.9644 13.6332 13.6423 13.6332 13.6423C13.0722 15.4139 11.0156 17.4812 8.15171 17.4812C6.35878 17.4812 3.51631 16.4293 2.75891 14.0181C2.83031 14.0662 3.92231 14.306 4.41091 13.7307C5.06004 12.9676 4.08984 10.4791 5.33724 8.89495Z"
      fill="#FFB20E"
    />
    <path
      d="M5.3606 8.98887C6.6766 7.32558 7.62627 7.1835 8.05607 5.10138C9.7678 7.19322 8.43174 8.7894 9.82007 9.25451C10.5509 9.49933 11.3797 9.27303 11.8697 8.23914C13.0891 10.3333 11.0619 12.8634 12.2449 13.7043C12.663 14.0014 13.5893 13.6913 13.5893 13.6913C13.0261 15.4504 10.9788 17.4775 8.1494 17.4775C6.37607 17.4775 3.55367 16.439 2.8056 14.0657C2.88214 14.1176 3.96714 14.3425 4.44827 13.7709C5.08947 13.0083 4.12114 10.5559 5.3606 8.98887Z"
      fill="#FFB30F"
    />
    <path
      d="M5.38384 9.08281C6.67931 7.45238 7.63037 7.26633 8.04384 5.25641C9.74391 7.32465 8.44331 8.89399 9.79711 9.35123C10.5186 9.59466 11.3282 9.3952 11.8388 8.37288C13.0218 10.4439 11.0361 12.8893 12.2028 13.7395C12.613 14.0384 13.5449 13.7395 13.5449 13.7395C12.9798 15.4861 10.9409 17.4728 8.14651 17.4728C6.39277 17.4728 3.59091 16.4477 2.85217 14.1125C2.93337 14.1676 4.01184 14.3781 4.48504 13.8098C5.11924 13.049 4.15184 10.6327 5.38384 9.08281Z"
      fill="#FFB310"
    />
    <path
      d="M5.40675 9.17678C6.68215 7.57967 7.63415 7.3492 8.03082 5.41101C9.71922 7.45518 8.45362 8.99768 9.77335 9.44706C10.4855 9.68956 11.2765 9.51694 11.8076 8.50619C12.9537 10.5536 11.0105 12.9143 12.1608 13.7747C12.5626 14.075 13.5011 13.7881 13.5011 13.7881C12.9336 15.5222 10.9036 17.4687 8.14422 17.4687C6.41009 17.4687 3.62829 16.457 2.89935 14.1597C2.98569 14.2185 4.05762 14.4142 4.52289 13.8492C5.14915 13.0893 4.18315 10.7096 5.40675 9.17678Z"
      fill="#FFB411"
    />
    <path
      d="M5.43007 9.27119C6.68493 7.70694 7.63833 7.4325 8.0182 5.56604C9.6954 7.58661 8.4648 9.10227 9.75 9.54377C10.4528 9.78489 11.2247 9.63911 11.7763 8.6404C12.886 10.6642 10.9843 12.9402 12.1183 13.8103C12.5122 14.1125 13.4563 13.8371 13.4563 13.8371C12.8865 15.5587 10.8658 17.465 8.14047 17.465C6.42594 17.465 3.66467 16.4667 2.94507 14.2074C3.03654 14.2694 4.10147 14.4508 4.55927 13.889C5.17853 13.13 4.2144 10.7864 5.43007 9.27119Z"
      fill="#FFB512"
    />
    <path
      d="M5.45341 9.36513C6.68774 7.8342 7.64254 7.51533 8.00608 5.72107C9.67161 7.71804 8.47648 9.20685 9.72761 9.64003C10.4206 9.88022 11.1743 9.76128 11.7464 8.77367C12.8193 10.7743 10.9601 12.9652 12.0777 13.8455C12.4637 14.1491 13.4133 13.8853 13.4133 13.8853C12.8412 15.5944 10.8294 17.4604 8.13908 17.4604C6.44414 17.4604 3.70341 16.4755 2.99268 14.2541C3.08928 14.3198 4.14721 14.486 4.59754 13.9278C5.20841 13.1702 4.24568 10.8632 5.45341 9.36513Z"
      fill="#FFB613"
    />
    <path
      d="M5.47675 9.45904C6.69055 7.96097 7.64721 7.59814 7.99348 5.87561C9.64735 7.84851 8.48721 9.31095 9.70428 9.73626C10.3879 9.97506 11.1229 9.88296 11.7151 8.90739C12.7516 10.8845 10.9344 12.9906 12.0353 13.8806C12.4128 14.186 13.3685 13.9338 13.3685 13.9338C12.7941 15.6304 10.7911 17.4562 8.13581 17.4562C6.46048 17.4562 3.73981 16.4848 3.03888 14.3013C3.14061 14.3702 4.19108 14.522 4.63395 13.9676C5.23828 13.2109 4.27648 10.94 5.47675 9.45904Z"
      fill="#FFB714"
    />
    <path
      d="M5.49966 9.55302C6.69293 8.0878 7.65099 7.68101 7.98093 6.03021C9.62313 7.97951 8.49846 9.41557 9.68099 9.83255C10.3553 10.0704 11.0712 10.0047 11.6844 9.0407C12.684 10.9946 10.9093 13.0157 11.9938 13.9158C12.3634 14.2226 13.3247 13.982 13.3247 13.982C12.7484 15.6661 10.7543 17.4516 8.13353 17.4516C6.47779 17.4516 3.77766 16.4936 3.08606 14.3485C3.19293 14.4212 4.23639 14.5582 4.67179 14.007C5.26773 13.2512 4.30779 11.0168 5.49966 9.55302Z"
      fill="#FFB815"
    />
    <path
      d="M5.52294 9.64696C6.69567 8.2146 7.65514 7.76384 7.96827 6.18524C9.59927 8.11094 8.50914 9.52015 9.6576 9.9288C10.3226 10.1653 11.0198 10.1269 11.6531 9.17444C12.6163 11.1048 10.8835 13.0406 11.9513 13.951C12.3129 14.2592 13.2803 14.0306 13.2803 14.0306C12.7017 15.7022 10.7165 17.4474 8.1302 17.4474C6.49407 17.4474 3.814 16.5028 3.1322 14.3957C3.2442 14.4716 4.28067 14.5943 4.7086 14.0463C5.29754 13.2919 4.33854 11.0932 5.52294 9.64696Z"
      fill="#FFB815"
    />
    <path
      d="M5.54629 9.74087C6.69849 8.34138 7.65936 7.84665 7.95569 6.33978C9.57503 8.24188 8.52036 9.62471 9.63429 10.025C10.2895 10.2606 10.968 10.2486 11.6223 9.30816C12.5486 11.2149 10.8588 13.0661 11.9098 13.9866C12.263 14.2962 13.2365 14.0791 13.2365 14.0791C12.6555 15.7383 10.6792 17.4432 8.12789 17.4432C6.51136 17.4432 3.85183 16.512 3.17889 14.4429C3.29603 14.522 4.32549 14.6303 4.74549 14.0856C5.32696 13.3322 4.36936 11.17 5.54629 9.74087Z"
      fill="#FFB916"
    />
    <path
      d="M5.56916 9.83487C6.70036 8.46824 7.6631 7.92954 7.9431 6.49487C9.55076 8.3729 8.5311 9.72936 9.61096 10.1218C10.2568 10.3564 10.9167 10.3713 11.591 9.44196C12.481 11.3255 10.8332 13.0911 11.8673 14.0218C12.2126 14.3328 13.1917 14.1278 13.1917 14.1278C12.6084 15.7744 10.6414 17.4391 8.12463 17.4391C6.52816 17.4391 3.88823 16.5209 3.2251 14.4901C3.34736 14.573 4.36983 14.666 4.78236 14.1255C5.35683 13.373 4.40063 11.2469 5.56916 9.83487Z"
      fill="#FFBA17"
    />
    <path
      d="M5.59248 9.92879C6.70315 8.59501 7.66728 8.01235 7.93048 6.64941C9.52648 8.50384 8.54182 9.83391 9.58762 10.2176C10.2241 10.4513 10.8653 10.4925 11.5602 9.57521C12.4133 11.4352 10.8084 13.1151 11.8257 14.0569C12.1631 14.3693 13.1478 14.1763 13.1478 14.1763C12.5626 15.8105 10.604 17.4349 8.12228 17.4349C6.54542 17.4349 3.92602 16.5301 3.27222 14.5373C3.39962 14.6234 4.41508 14.7021 4.82015 14.1648C5.38622 13.4132 4.43142 11.3232 5.59248 9.92879Z"
      fill="#FFBB18"
    />
    <path
      d="M5.6158 10.0228C6.70547 8.72184 7.67147 8.09522 7.91834 6.80402C9.50314 8.63484 8.55347 9.93854 9.5652 10.3139C10.1924 10.5466 10.8145 10.6147 11.5299 9.70853C12.3465 11.5449 10.7841 13.1397 11.7842 14.0917C12.1137 14.4054 13.1039 14.224 13.1039 14.224C12.5164 15.8457 10.5667 17.4298 8.11994 17.4298C6.56267 17.4298 3.9638 16.5385 3.31934 14.5836C3.4514 14.6734 4.46034 14.7373 4.85794 14.2032C5.4156 13.4535 4.4622 11.4 5.6158 10.0228Z"
      fill="#FFBC19"
    />
    <path
      d="M5.63916 10.1166C6.70829 8.84858 7.67616 8.17799 7.90576 6.95898C9.47889 8.7662 8.56423 10.0435 9.54189 10.4105C10.1593 10.6424 10.7632 10.7368 11.4991 9.84267C12.2789 11.6559 10.7594 13.1651 11.7427 14.1277C12.0642 14.4429 13.0606 14.2735 13.0606 14.2735C12.4707 15.8826 10.5303 17.4265 8.11763 17.4265C6.57996 17.4265 4.00116 16.5486 3.36603 14.6317C3.50323 14.7247 4.50469 14.7738 4.89483 14.2434C5.44549 13.4937 4.49303 11.4763 5.63916 10.1166Z"
      fill="#FFBC1A"
    />
    <path
      d="M5.66207 10.2106C6.7102 8.97495 7.67994 8.26086 7.89274 7.11359C9.4542 8.89674 8.57454 10.1486 9.51814 10.5063C10.1262 10.7373 10.7109 10.8581 11.4674 9.97598C12.2108 11.7656 10.7338 13.1892 11.6998 14.1624C12.0139 14.4785 13.0153 14.3212 13.0153 14.3212C12.4231 15.9178 10.4916 17.421 8.11394 17.421C6.59587 17.421 4.0376 16.5565 3.4118 14.6775C3.55414 14.7743 4.5486 14.8085 4.93127 14.2814C5.47494 13.5344 4.5234 11.5527 5.66207 10.2106Z"
      fill="#FFBD1B"
    />
    <path
      d="M5.68535 10.3046C6.71248 9.10179 7.68408 8.34373 7.88055 7.26819C9.43035 9.02774 8.58568 10.2532 9.49522 10.6026C10.0935 10.8326 10.66 10.9803 11.437 10.1093C12.1436 11.8758 10.71 13.2133 11.6587 14.1976C11.9648 14.5151 12.9719 14.3698 12.9719 14.3698C12.3778 15.9539 10.4547 17.4168 8.11202 17.4168C6.61355 17.4168 4.07535 16.5658 3.45935 14.7248C3.60682 14.8247 4.59428 14.8442 4.96948 14.3212C5.50428 13.5747 4.55415 11.6291 5.68535 10.3046Z"
      fill="#FFBE1C"
    />
    <path
      d="M5.70869 10.3985C6.71482 9.22812 7.68829 8.4261 7.86795 7.42322C9.40609 9.15917 8.59642 10.3587 9.47189 10.6994C10.0608 10.9284 10.6087 11.1024 11.4058 10.2435C12.0759 11.9864 10.6852 13.2383 11.6162 14.2333C11.9144 14.5521 12.9271 14.4184 12.9271 14.4184C12.3307 15.99 10.4169 17.4127 8.10875 17.4127C6.62989 17.4127 4.11222 16.575 3.50555 14.772C3.65815 14.8756 4.63815 14.8803 5.00589 14.3605C5.53369 13.615 4.58495 11.7055 5.70869 10.3985Z"
      fill="#FFBF1D"
    />
    <path
      d="M5.73198 10.4925C6.71712 9.35496 7.69245 8.50897 7.85579 7.57782C9.38272 9.29017 8.60758 10.4638 9.44899 10.7952C10.0281 11.0233 10.5573 11.2242 11.3754 10.3763C12.0087 12.0956 10.6614 13.2614 11.5751 14.268C11.8654 14.5878 12.8837 14.4665 12.8837 14.4665C12.2849 16.0257 10.38 17.4081 8.10639 17.4081C6.64712 17.4081 4.14952 16.5838 3.55219 14.8187C3.70992 14.9256 4.68292 14.9159 5.04318 14.3994C5.56305 13.6553 4.61525 11.7819 5.73198 10.4925Z"
      fill="#FFC01E"
    />
    <path
      d="M5.75486 10.5864C6.71899 9.48127 7.69619 8.59177 7.84272 7.73236C9.35799 9.42064 8.61786 10.5688 9.42519 10.8914C9.99499 11.1191 10.5055 11.3459 11.3437 10.5101C11.9405 12.2062 10.6367 13.2859 11.5322 14.3031C11.815 14.6243 12.8389 14.5151 12.8389 14.5151C12.2378 16.0618 10.3417 17.4039 8.10312 17.4039C6.66346 17.4039 4.18639 16.5931 3.59839 14.8659C3.76126 14.9765 4.72679 14.9515 5.08006 14.4387C5.59246 13.6955 4.64559 11.8582 5.75486 10.5864Z"
      fill="#FFC11F"
    />
    <path
      d="M5.77822 10.6804C6.72088 9.6076 7.70088 8.67461 7.83062 7.88739C9.33422 9.55207 8.62908 10.6743 9.40282 10.9877C9.96282 11.2149 10.4547 11.4676 11.3138 10.6438C11.8738 12.3163 10.6134 13.31 11.4916 14.3388C11.7665 14.6609 12.796 14.5637 12.796 14.5637C12.1926 16.0979 10.3058 17.3997 8.10175 17.3997C6.68168 17.3997 4.22468 16.6023 3.64648 14.9131C3.81448 15.0269 4.77302 14.9876 5.11882 14.4781C5.62188 13.7357 4.67595 11.9345 5.77822 10.6804Z"
      fill="#FFC120"
    />
    <path
      d="M5.80155 10.7743C6.72322 9.73398 7.70509 8.75748 7.81802 8.04199C9.30995 9.68307 8.63982 10.7794 9.37949 11.084C9.93015 11.3107 10.4034 11.5898 11.2826 10.7771C11.8062 12.4265 10.5891 13.3336 11.4492 14.3735C11.7166 14.697 12.7512 14.6118 12.7512 14.6118C12.1459 16.1335 10.2676 17.3951 8.09849 17.3951C6.69802 17.3951 4.26155 16.6107 3.69269 14.9599C3.86582 15.077 4.81689 15.0228 5.15522 14.5174C5.65129 13.776 4.70629 12.0105 5.80155 10.7743Z"
      fill="#FFC221"
    />
    <path
      d="M5.82442 10.8683C6.72462 9.86035 7.70883 8.84034 7.80496 8.19659C9.28569 9.8136 8.64963 10.885 9.35569 11.1802C9.89656 11.4065 10.3516 11.7115 11.2513 10.9109C11.738 12.5367 10.5653 13.3577 11.4072 14.4092C11.6671 14.7336 12.7068 14.6605 12.7068 14.6605C12.0992 16.1696 10.2302 17.391 8.09569 17.391C6.71482 17.391 4.29889 16.6199 3.73889 15.0076C3.91716 15.1284 4.86122 15.0589 5.19209 14.5573C5.68069 13.8163 4.73662 12.0864 5.82442 10.8683Z"
      fill="#FFC322"
    />
    <path
      d="M5.84776 10.9623C6.72696 9.98668 7.71303 8.92318 7.79283 8.35162C9.26189 9.94503 8.66083 10.991 9.33283 11.2765C9.8639 11.5023 10.3002 11.8337 11.2205 11.0442C11.6708 12.6464 10.542 13.3813 11.3652 14.4439C11.6176 14.7697 12.6625 14.7086 12.6625 14.7086C12.0526 16.2053 10.1924 17.3863 8.09243 17.3863C6.73116 17.3863 4.3353 16.6287 3.7851 15.0543C3.96803 15.1783 4.9051 15.0946 5.22896 14.5961C5.7101 13.8566 4.7665 12.1623 5.84776 10.9623Z"
      fill="#FFC423"
    />
    <path
      d="M5.8711 11.0562C6.72884 10.1125 7.71724 9.00598 7.78024 8.50616C9.23764 10.076 8.6711 11.0964 9.3095 11.3727C9.83077 11.5986 10.2489 11.9554 11.1897 11.1779C11.6032 12.7569 10.5186 13.4053 11.3236 14.4795C11.5686 14.8062 12.6191 14.7572 12.6191 14.7572C12.0068 16.2413 10.1556 17.3821 8.09057 17.3821C6.74937 17.3821 4.37357 16.638 3.8327 15.1015C4.02077 15.2292 4.95037 15.1302 5.26677 14.6354C5.73904 13.8968 4.79684 12.2382 5.8711 11.0562Z"
      fill="#FFC524"
    />
    <path
      d="M5.89446 11.1501C6.73073 10.2389 7.72146 9.08885 7.76813 8.66077C9.21386 10.207 8.68233 11.202 9.28666 11.469C9.79813 11.6944 10.1976 12.0771 11.1589 11.3117C11.536 12.8671 10.4953 13.429 11.2817 14.5147C11.5192 14.8423 12.5748 14.8058 12.5748 14.8058C11.9607 16.2775 10.1183 17.378 8.08779 17.378C6.76619 17.378 4.41046 16.6472 3.87939 15.1487C4.07259 15.2797 4.99519 15.1663 5.30413 14.6753C5.76846 13.9371 4.82673 12.3141 5.89446 11.1501Z"
      fill="#FFC625"
    />
    <path
      d="M5.91733 11.2441C6.73213 10.3648 7.7252 9.17169 7.75506 8.8158C9.1896 10.3379 8.69213 11.3084 9.26286 11.5653C9.76406 11.7911 10.1458 12.1993 11.1277 11.4449C11.4679 12.9768 10.472 13.4526 11.2397 14.5498C11.4697 14.8789 12.5305 14.8544 12.5305 14.8544C11.914 16.3136 10.0805 17.3738 8.085 17.3738C6.783 17.3738 4.4478 16.6565 3.9256 15.1959C4.12393 15.3306 5.03906 15.2019 5.341 14.7146C5.7974 13.9774 4.8566 12.3895 5.91733 11.2441Z"
      fill="#FFC626"
    />
    <path
      d="M5.94067 11.338C6.73354 10.4906 7.72987 9.25449 7.74247 8.97034C9.16534 10.4689 8.7024 11.4144 9.24 11.6615C9.7314 11.8873 10.0945 12.321 11.0969 11.5787C11.4007 13.0874 10.4496 13.4761 11.1977 14.585C11.4203 14.915 12.4861 14.9025 12.4861 14.9025C11.8673 16.3492 10.0427 17.3692 8.08173 17.3692C6.79933 17.3692 4.4842 16.6653 3.9718 15.2426C4.17527 15.3805 5.08247 15.2371 5.37787 14.7534C5.8254 14.0167 4.886 12.4649 5.94067 11.338Z"
      fill="#FFC727"
    />
    <path
      d="M5.96395 11.432C6.73441 10.6156 7.73402 9.33736 7.73028 9.12494C9.14148 10.5999 8.71261 11.5204 9.21708 11.7578C9.69821 11.9841 10.0435 12.4432 11.0665 11.712C11.3334 13.1971 10.4271 13.4993 11.1565 14.6202C11.3721 14.9511 12.4427 14.9511 12.4427 14.9511C11.8215 16.3853 10.0062 17.365 8.07981 17.365C6.81701 17.365 4.52241 16.6745 4.01935 15.2898C4.22795 15.4315 5.12768 15.2727 5.41561 14.7928C5.85521 14.0574 4.91395 12.539 5.96395 11.432Z"
      fill="#FFC828"
    />
    <path
      d="M5.98731 11.5259C6.73677 10.7424 7.73824 9.4202 7.71771 9.27997C9.11724 10.7313 8.72291 11.6268 9.19377 11.854C9.66464 12.0813 9.99224 12.5649 11.0352 11.8457C11.2658 13.3077 10.4043 13.5233 11.1141 14.6553C11.3222 14.9876 12.3984 14.9992 12.3984 14.9992C11.7754 16.4209 9.96844 17.3604 8.07704 17.3604C6.83384 17.3604 4.55977 16.6833 4.06604 15.3366C4.27977 15.4814 5.17251 15.3084 5.45297 14.8317C5.88464 14.0977 4.94477 12.6153 5.98731 11.5259Z"
      fill="#FFC929"
    />
    <path
      d="M5.99478 11.5463C6.74051 10.7656 7.73638 9.45121 7.71771 9.31006C9.11164 10.7568 8.71591 11.6509 9.18818 11.8781C9.65718 12.1044 9.98478 12.5825 11.0226 11.8675C11.2522 13.3225 10.394 13.5377 11.101 14.6651C11.3096 14.9969 12.3806 15.0094 12.3806 15.0094C11.759 16.4237 9.96144 17.3585 8.07798 17.3585C6.83991 17.3585 4.57331 16.6838 4.08191 15.3426C4.29471 15.487 5.18418 15.3144 5.46324 14.8396C5.89304 14.1079 4.95784 12.6315 5.99478 11.5463Z"
      fill="#FFC92A"
    />
    <path
      d="M6.00267 11.5662C6.7442 10.7887 7.7354 9.4822 7.71767 9.33966C9.106 10.7813 8.70887 11.6745 9.18207 11.9017C9.6492 12.1266 9.9768 12.5996 11.0095 11.8883C11.2382 13.3359 10.3828 13.5511 11.0875 14.6739C11.2961 15.0057 12.3624 15.0186 12.3624 15.0186C11.7427 16.426 9.9544 17.3558 8.07794 17.3558C6.84454 17.3558 4.58634 16.6833 4.0968 15.3477C4.30867 15.4916 5.19487 15.3195 5.473 14.8465C5.90187 14.118 4.97133 12.6477 6.00267 11.5662Z"
      fill="#FFCA2C"
    />
    <path
      d="M6.01065 11.5861C6.74845 10.8113 7.73451 9.51273 7.71818 9.36926C9.10091 10.8062 8.70191 11.6976 9.17698 11.9248C9.64225 12.1488 9.97031 12.6162 10.9974 11.9091C11.2252 13.3498 10.3726 13.5645 11.0749 14.6826C11.284 15.0145 12.3456 15.0279 12.3456 15.0279C11.7278 16.4283 9.94885 17.353 8.07985 17.353C6.85158 17.353 4.60131 16.6833 4.11365 15.3528C4.32458 15.4958 5.20751 15.3241 5.48425 14.8534C5.91031 14.1277 4.98445 12.6634 6.01065 11.5861Z"
      fill="#FFCA2D"
    />
    <path
      d="M6.01807 11.6064C6.75214 10.8349 7.73261 9.54421 7.71767 9.39935C9.09481 10.8317 8.69441 11.7217 9.17087 11.9489C9.63427 12.172 9.96234 12.6338 10.9843 11.9304C11.2116 13.3641 10.3613 13.5784 11.0613 14.6919C11.2704 15.0237 12.3274 15.0376 12.3274 15.0376C11.7109 16.4311 9.94134 17.3507 8.07981 17.3507C6.85621 17.3507 4.61434 16.6833 4.12854 15.3583C4.33901 15.5009 5.21821 15.3296 5.49401 14.8608C5.91867 14.1379 4.99747 12.6797 6.01807 11.6064Z"
      fill="#FFCA2E"
    />
    <path
      d="M6.02605 11.6264C6.75592 10.8577 7.73171 9.5748 7.71818 9.42902C9.08971 10.8567 8.68791 11.7448 9.16531 11.9725C9.62731 12.1942 9.95538 12.651 10.9718 11.9517C11.1981 13.3785 10.3511 13.5923 11.0478 14.7012C11.2574 15.033 12.3092 15.0474 12.3092 15.0474C11.6946 16.4339 9.93438 17.3488 8.08032 17.3488C6.86185 17.3488 4.62792 16.6843 4.14398 15.3644C4.35352 15.5065 5.22898 15.3357 5.50385 14.8683C5.92758 14.1482 5.01058 12.6954 6.02605 11.6264Z"
      fill="#FFCB2F"
    />
    <path
      d="M6.03398 11.6463C6.76012 10.8808 7.73032 9.60579 7.71818 9.45862C9.08412 10.8817 8.68092 11.7684 9.15972 11.9957C9.61985 12.2164 9.94792 12.6676 10.9587 11.9721C11.1841 13.3919 10.3399 13.6053 11.0343 14.7095C11.2443 15.0413 12.2915 15.0561 12.2915 15.0561C11.6788 16.4353 9.92738 17.3456 8.08125 17.3456C6.86745 17.3456 4.64145 16.6833 4.15985 15.369C4.36845 15.5106 5.24065 15.3398 5.51458 14.8747C5.93598 14.1583 5.02365 12.7116 6.03398 11.6463Z"
      fill="#FFCB31"
    />
    <path
      d="M6.04143 11.6666C6.76337 10.9044 7.7289 9.63727 7.71817 9.48871C9.07803 10.9072 8.67437 11.7911 9.1541 12.0197C9.61283 12.2386 9.94043 12.6852 10.9461 11.9938C11.1706 13.4067 10.3292 13.6196 11.0212 14.7192C11.2312 15.0511 12.2738 15.0663 12.2738 15.0663C11.6624 16.4385 9.92083 17.3438 8.0817 17.3438C6.87303 17.3438 4.65497 16.6838 4.17523 15.375C4.3829 15.5157 5.25183 15.3459 5.52437 14.8826C5.94437 14.1685 5.03717 12.7278 6.04143 11.6666Z"
      fill="#FFCB32"
    />
    <path
      d="M6.04935 11.6865C6.76755 10.9271 7.72748 9.66779 7.71815 9.51831C9.07241 10.9322 8.66735 11.8142 9.14801 12.0433C9.60488 12.2608 9.93295 12.7023 10.933 12.0146C11.157 13.4206 10.3179 13.633 11.0077 14.728C11.2181 15.0598 12.2555 15.0756 12.2555 15.0756C11.6461 16.4408 9.91335 17.341 8.08215 17.341C6.87815 17.341 4.66848 16.6838 4.19061 15.3796C4.39735 15.5199 5.26301 15.3505 5.53461 14.8891C5.95321 14.1782 5.05021 12.7435 6.04935 11.6865Z"
      fill="#FFCC33"
    />
    <path
      d="M6.05726 11.7064C6.77126 10.9502 7.72653 9.69878 7.7186 9.54791C9.06726 10.9571 8.6608 11.8374 9.14286 12.0669C9.59786 12.283 9.92593 12.7195 10.9209 12.0359C11.1439 13.4349 10.3077 13.6469 10.9951 14.7372C11.2055 15.0691 12.2387 15.0853 12.2387 15.0853C11.6311 16.4436 9.90773 17.3391 8.084 17.3391C6.88513 17.3391 4.6834 16.6842 4.2074 15.3856C4.41366 15.5254 5.2756 15.3565 5.5458 14.8964C5.9616 14.1884 5.06326 12.7597 6.05726 11.7064Z"
      fill="#FFCC35"
    />
    <path
      d="M6.06475 11.7263C6.77502 10.9729 7.72515 9.72937 7.71815 9.57758C9.06122 10.9822 8.65335 11.861 9.13682 12.0901C9.59042 12.3053 9.91849 12.7366 10.9078 12.0568C11.13 13.4484 10.297 13.6608 10.9816 14.7461C11.1925 15.0779 12.2206 15.0946 12.2206 15.0946C11.6148 16.4459 9.90075 17.3364 8.08402 17.3364C6.88982 17.3364 4.69648 16.6843 4.22235 15.3908C4.42768 15.5301 5.28635 15.3611 5.55515 14.9034C5.97002 14.1986 5.07635 12.7755 6.06475 11.7263Z"
      fill="#FFCC36"
    />
    <path
      d="M6.07273 11.7467C6.77926 10.9965 7.72379 9.76082 7.71866 9.60809C9.05613 11.0081 8.64686 11.8851 9.13126 12.1146C9.58299 12.3284 9.91106 12.7546 10.8953 12.0785C11.1169 13.4632 10.2863 13.6751 10.9681 14.7558C11.179 15.0876 12.2024 15.1047 12.2024 15.1047C11.5981 16.4487 9.89333 17.3345 8.08453 17.3345C6.89546 17.3345 4.71006 16.6847 4.23779 15.3967C4.44219 15.5351 5.29713 15.3671 5.56546 14.9113C5.97846 14.2088 5.08946 12.7917 6.07273 11.7467Z"
      fill="#FFCD37"
    />
    <path
      d="M6.08066 11.7666C6.78299 11.0191 7.72286 9.79134 7.71866 9.6377C9.05053 11.033 8.63986 11.9082 9.12566 12.1382C9.57553 12.3511 9.9036 12.7718 10.8827 12.0998C11.1034 13.4775 10.2732 13.6904 10.955 14.765C11.1655 15.0973 12.1851 15.1144 12.1851 15.1144C11.5827 16.4514 9.88726 17.3321 8.08546 17.3321C6.90106 17.3321 4.7236 16.6852 4.25366 15.4023C4.45713 15.5402 5.3088 15.3727 5.57573 14.9187C5.98733 14.2189 5.10299 12.8079 6.08066 11.7666Z"
      fill="#FFCD39"
    />
    <path
      d="M6.08811 11.7864C6.78671 11.0423 7.72098 9.82233 7.71864 9.6673C9.04491 11.058 8.63284 11.9313 9.12004 12.1613C9.56804 12.3728 9.89658 12.7884 10.8696 12.1201C11.0894 13.4909 10.262 13.7033 10.9414 14.7733C11.1524 15.1056 12.1669 15.1232 12.1669 15.1232C11.5663 16.4533 9.87978 17.3294 8.08591 17.3294C6.90664 17.3294 4.73711 16.6847 4.26904 15.4074C4.47158 15.5448 5.31998 15.3773 5.58598 14.9256C5.99571 14.2286 5.11604 12.8236 6.08811 11.7864Z"
      fill="#FFCD3A"
    />
    <path
      d="M6.09603 11.8068C6.79043 11.0654 7.72003 9.85335 7.71909 9.69739C9.03976 11.0835 8.62629 11.9554 9.11489 12.1854C9.56149 12.396 9.88956 12.806 10.8574 12.1419C11.0763 13.5057 10.2517 13.7177 10.9288 14.7831C11.1398 15.1153 12.1501 15.1334 12.1501 15.1334C11.5509 16.4565 9.87416 17.3275 8.08776 17.3275C6.91363 17.3275 4.75203 16.6851 4.28583 15.4134C4.48789 15.5504 5.33256 15.3833 5.59716 14.933C6.00409 14.2388 5.12909 12.8398 6.09603 11.8068Z"
      fill="#FFCE3B"
    />
    <path
      d="M6.10349 11.8268C6.79416 11.0886 7.71816 9.8844 7.71863 9.72705C9.03323 11.1085 8.61883 11.9786 9.10836 12.209C9.55309 12.4182 9.88163 12.8232 10.8439 12.1627C11.0623 13.5197 10.24 13.7316 10.9148 14.7919C11.1262 15.1242 12.1314 15.1427 12.1314 15.1427C11.5341 16.4589 9.86623 17.3248 8.08729 17.3248C6.91783 17.3248 4.76463 16.6852 4.30029 15.4185C4.50143 15.5546 5.34283 15.3884 5.60649 14.94C6.01296 14.249 5.14216 12.8556 6.10349 11.8268Z"
      fill="#FFCE3C"
    />
    <path
      d="M6.11139 11.8466C6.79786 11.1113 7.71719 9.91493 7.71906 9.75665C9.02806 11.1335 8.61226 12.0017 9.10319 12.2326C9.54606 12.4409 9.87459 12.8403 10.8317 12.184C11.0492 13.534 10.2297 13.7455 10.9022 14.8011C11.1136 15.1334 12.1141 15.1524 12.1141 15.1524C11.5187 16.4612 9.86012 17.3224 8.08866 17.3224C6.92432 17.3224 4.77906 16.6852 4.31659 15.4241C4.51679 15.5597 5.35492 15.3935 5.61719 14.9474C6.02132 14.2592 5.15519 12.8717 6.11139 11.8466Z"
      fill="#FFCE3E"
    />
    <path
      d="M6.11936 11.867C6.8021 11.1349 7.7163 9.94641 7.7191 9.78674C9.0225 11.1589 8.6053 12.0253 9.09763 12.2562C9.5391 12.4631 9.86716 12.8574 10.8192 12.2049C11.0357 13.5479 10.2186 13.7589 10.8887 14.8099C11.1006 15.1422 12.0964 15.1617 12.0964 15.1617C11.5028 16.4635 9.85363 17.3197 8.08916 17.3197C6.9295 17.3197 4.79216 16.6852 4.33203 15.4292C4.5313 15.5643 5.3657 15.3986 5.6275 14.9543C6.02976 14.2694 5.16876 12.8875 6.11936 11.867Z"
      fill="#FFCF3F"
    />
    <path
      d="M6.12681 11.8869C6.80535 11.1575 7.71441 9.97694 7.71908 9.81635C9.01688 11.1839 8.59781 12.0489 9.09155 12.2798C9.53115 12.4858 9.85968 12.8745 10.8061 12.2261C11.0221 13.5618 10.2073 13.7733 10.8751 14.8192C11.087 15.1515 12.0782 15.1714 12.0782 15.1714C11.486 16.4663 9.84615 17.3178 8.08961 17.3178C6.93508 17.3178 4.80568 16.6856 4.34741 15.4352C4.54575 15.5698 5.37688 15.4046 5.63728 14.9622C6.03861 14.2796 5.18181 12.9037 6.12681 11.8869Z"
      fill="#FFCF40"
    />
    <path
      d="M6.13473 11.9068C6.80953 11.1807 7.71346 10.0079 7.71906 9.84595C9.01126 11.2089 8.59079 12.072 9.08593 12.3034C9.52366 12.508 9.85219 12.8916 10.793 12.2474C11.0081 13.5761 10.1961 13.7871 10.8616 14.8284C11.0739 15.1607 12.06 15.1811 12.06 15.1811C11.4697 16.469 9.83913 17.3155 8.09006 17.3155C6.94019 17.3155 4.81919 16.6856 4.36279 15.4407C4.56019 15.5749 5.38806 15.4101 5.64753 14.9691C6.04699 14.2893 5.19486 12.9198 6.13473 11.9068Z"
      fill="#FFCF42"
    />
    <path
      d="M6.14268 11.9267C6.81375 11.2034 7.71208 10.0385 7.71955 9.87561C9.00615 11.2339 8.58428 12.0952 9.08082 12.3266C9.51668 12.5302 9.84522 12.9083 10.7809 12.2678C10.9951 13.5896 10.1859 13.8001 10.849 14.8368C11.0614 15.1691 12.0432 15.1899 12.0432 15.1899C11.4548 16.4705 9.83308 17.3123 8.09148 17.3123C6.94675 17.3123 4.83368 16.6852 4.37915 15.4449C4.57608 15.5782 5.40022 15.4139 5.65828 14.9752C6.05542 14.2995 5.20795 12.9356 6.14268 11.9267Z"
      fill="#FFD043"
    />
    <path
      d="M6.15017 11.947C6.81704 11.2269 7.7107 10.0699 7.7191 9.90564C9.0001 11.2593 8.57684 12.1187 9.0743 12.3506C9.5083 12.5528 9.8373 12.9258 10.7674 12.2895C10.9806 13.6043 10.1742 13.8149 10.835 14.8465C11.0478 15.1787 12.0246 15.2 12.0246 15.2C11.4375 16.4736 9.82564 17.3104 8.0915 17.3104C6.95144 17.3104 4.84677 16.6856 4.3941 15.4509C4.5901 15.5837 5.41097 15.4199 5.66764 14.983C6.06384 14.3096 5.22104 12.9518 6.15017 11.947Z"
      fill="#FFD044"
    />
    <path
      d="M6.15808 11.967C6.82122 11.2501 7.70929 10.1005 7.71955 9.9353C8.99495 11.2844 8.57029 12.1424 9.06915 12.3742C9.50175 12.5756 9.83028 12.943 10.7552 12.3104C10.968 13.6182 10.164 13.8283 10.822 14.8553C11.0348 15.1876 12.0068 15.2093 12.0068 15.2093C11.4216 16.476 9.81862 17.3081 8.09195 17.3081C6.95702 17.3081 4.86028 16.6861 4.40948 15.4565C4.60455 15.5888 5.42169 15.4255 5.67789 14.9904C6.07269 14.3198 5.23455 12.9675 6.15808 11.967Z"
      fill="#FFD045"
    />
    <path
      d="M6.16604 11.9869C6.82498 11.2728 7.70838 10.1315 7.71958 9.96539C8.98938 11.3098 8.56331 12.166 9.06358 12.3983C9.49431 12.5982 9.82331 12.9606 10.7426 12.3321C10.9545 13.6331 10.1532 13.8427 10.8089 14.865C11.0222 15.1973 11.9896 15.2191 11.9896 15.2191C11.4062 16.4788 9.81258 17.3058 8.09338 17.3058C6.96311 17.3058 4.87431 16.6861 4.42584 15.462C4.61998 15.5939 5.43384 15.4306 5.68864 14.9974C6.08111 14.33 5.24764 12.9838 6.16604 11.9869Z"
      fill="#FFD147"
    />
    <path
      d="M6.17349 12.0072C6.82869 11.2963 7.70696 10.1625 7.71956 9.99542C8.98329 11.3352 8.55629 12.1895 9.05749 12.4219C9.48636 12.6209 9.81536 12.9777 10.7296 12.3529C10.9405 13.6469 10.142 13.8561 10.7954 14.8738C11.0086 15.206 11.9714 15.2283 11.9714 15.2283C11.3894 16.4811 9.80556 17.303 8.09382 17.303C6.96869 17.303 4.88782 16.6856 4.44122 15.4671C4.63442 15.598 5.44502 15.4356 5.69889 15.0043C6.08949 14.3397 5.26069 12.9999 6.17349 12.0072Z"
      fill="#FFD148"
    />
    <path
      d="M6.18144 12.0271C6.83244 11.3191 7.70558 10.1935 7.71958 10.0251C8.97771 11.3603 8.54931 12.2132 9.05191 12.4455C9.47891 12.6431 9.80791 12.9948 10.7165 12.3742C10.9265 13.6613 10.1308 13.8705 10.7818 14.8831C10.9956 15.2153 11.9532 15.238 11.9532 15.238C11.3731 16.4834 9.79811 17.3007 8.09384 17.3007C6.97338 17.3007 4.90091 16.6861 4.45618 15.4727C4.64891 15.6032 5.45578 15.4412 5.70824 15.0117C6.09838 14.3499 5.27378 13.0157 6.18144 12.0271Z"
      fill="#FFD149"
    />
    <path
      d="M6.1893 12.047C6.83657 11.3422 7.70457 10.2241 7.71997 10.0547C8.9725 11.3852 8.5427 12.2363 9.0467 12.4691C9.4723 12.6658 9.8013 13.012 10.7043 12.3955C10.9138 13.6756 10.1205 13.8843 10.7692 14.8923C10.9829 15.2246 11.9363 15.2477 11.9363 15.2477C11.3581 16.4862 9.79243 17.2988 8.09563 17.2988C6.9803 17.2988 4.91577 16.6866 4.4729 15.4787C4.6647 15.6087 5.4683 15.4472 5.71983 15.0196C6.1067 14.3601 5.28677 13.0319 6.1893 12.047Z"
      fill="#FFD14B"
    />
    <path
      d="M6.19685 12.067C6.84038 11.3649 7.70278 10.2551 7.71958 10.0844C8.96652 11.4103 8.53532 12.2595 9.04071 12.4923C9.46445 12.6876 9.79345 13.0287 10.6913 12.4159C10.8999 13.6886 10.1094 13.8973 10.7557 14.9007C10.9694 15.233 11.9182 15.2566 11.9182 15.2566C11.3418 16.4881 9.78505 17.2956 8.09572 17.2956C6.98552 17.2956 4.92892 16.6861 4.48792 15.4833C4.67878 15.6129 5.47912 15.4514 5.72925 15.0261C6.11518 14.3703 5.30038 13.0476 6.19685 12.067Z"
      fill="#FFD24C"
    />
    <path
      d="M6.20478 12.0873C6.84412 11.3885 7.70185 10.2861 7.72005 10.1144C8.96138 11.4357 8.52878 12.283 9.03512 12.5163C9.45698 12.7107 9.78645 13.0462 10.6787 12.4376C10.8864 13.7034 10.0987 13.9116 10.7422 14.9103C10.9564 15.2426 11.9005 15.2667 11.9005 15.2667C11.3255 16.4913 9.77852 17.2937 8.09665 17.2937C6.99112 17.2937 4.94292 16.6866 4.50378 15.4893C4.69372 15.618 5.49032 15.4574 5.73998 15.0334C6.12405 14.3804 5.31345 13.0638 6.20478 12.0873Z"
      fill="#FFD24D"
    />
    <path
      d="M6.21266 12.1072C6.84826 11.4111 7.70039 10.3171 7.71999 10.144C8.95573 11.4606 8.52173 12.3066 9.02946 12.5399C9.44946 12.7329 9.77893 13.0633 10.6661 12.4584C10.8733 13.7172 10.0879 13.9255 10.7291 14.9191C10.9433 15.2514 11.8827 15.2759 11.8827 15.2759C11.3096 16.4935 9.77193 17.2909 8.09753 17.2909C6.99713 17.2909 4.95639 16.6861 4.51959 15.4944C4.70859 15.6226 5.50193 15.4624 5.75019 15.0404C6.13239 14.3901 5.32646 13.0795 6.21266 12.1072Z"
      fill="#FFD24E"
    />
    <path
      d="M6.22011 12.1271C6.85151 11.4343 7.69897 10.3477 7.71997 10.1736C8.95011 11.4852 8.51471 12.3298 9.02384 12.5635C9.44244 12.7556 9.77144 13.0805 10.6534 12.4798C10.8597 13.7316 10.0771 13.9394 10.716 14.9284C10.9306 15.2607 11.8649 15.2857 11.8649 15.2857C11.2937 16.4959 9.76491 17.2887 8.09797 17.2887C7.00224 17.2887 4.96991 16.6866 4.53497 15.5C4.72351 15.6277 5.51311 15.4676 5.75997 15.0478C6.14077 14.4004 5.33951 13.0957 6.22011 12.1271Z"
      fill="#FFD350"
    />
    <path
      d="M6.22804 12.1474C6.85571 11.4574 7.69804 10.3791 7.71998 10.2037C8.94451 11.5106 8.50771 12.3534 9.01778 12.5871C9.43451 12.7777 9.76398 13.0975 10.6404 12.5005C10.8457 13.7455 10.0654 13.9528 10.7024 14.9372C10.9171 15.2694 11.8472 15.2949 11.8472 15.2949C11.2774 16.4982 9.75838 17.2863 8.09891 17.2863C7.00831 17.2863 4.98391 16.6865 4.55084 15.5055C4.73844 15.6327 5.52478 15.4731 5.77071 15.0552C6.14964 14.4105 5.35258 13.1119 6.22804 12.1474Z"
      fill="#FFD351"
    />
    <path
      d="M6.23602 12.1674C6.85948 11.4806 7.69668 10.4097 7.72048 10.2333C8.93895 11.5356 8.50122 12.3765 9.01268 12.6107C9.42755 12.8005 9.75702 13.1147 10.6278 12.5219C10.8322 13.7598 10.0548 13.9667 10.6894 14.9465C10.9046 15.2787 11.8295 15.3047 11.8295 15.3047C11.2616 16.501 9.75142 17.284 8.09942 17.284C7.01348 17.284 4.99748 16.687 4.56628 15.5106C4.75295 15.6369 5.53602 15.4782 5.78055 15.0622C6.15808 14.4207 5.36615 13.1277 6.23602 12.1674Z"
      fill="#FFD352"
    />
    <path
      d="M6.24349 12.1872C6.86322 11.5032 7.69529 10.4407 7.72049 10.2629C8.93335 11.5606 8.49422 12.4001 9.00709 12.6343C9.42009 12.8227 9.75002 13.1318 10.6152 12.5427C10.8192 13.7737 10.044 13.9806 10.6764 14.9552C10.8915 15.2871 11.8118 15.3139 11.8118 15.3139C11.2457 16.5033 9.74489 17.2812 8.10035 17.2812C7.01955 17.2812 5.01149 16.6866 4.58215 15.5157C4.76789 15.6416 5.54769 15.4833 5.79129 15.0686C6.16649 14.4309 5.37922 13.1438 6.24349 12.1872Z"
      fill="#FFD454"
    />
    <path
      d="M6.2514 12.2076C6.8674 11.5268 7.69387 10.4717 7.72047 10.293C8.92773 11.5861 8.4872 12.4237 9.001 12.6579C9.41213 12.8454 9.74207 13.1489 10.6021 12.564C10.8051 13.7881 10.0328 13.9945 10.6623 14.9645C10.8779 15.2963 11.7935 15.3236 11.7935 15.3236C11.2293 16.5061 9.7374 17.2789 8.1008 17.2789C7.02467 17.2789 5.02453 16.687 4.59753 15.5212C4.78233 15.6467 5.5584 15.4884 5.80107 15.076C6.17487 14.4406 5.39227 13.1596 6.2514 12.2076Z"
      fill="#FFD455"
    />
    <path
      d="M6.25934 12.2275C6.87114 11.55 7.69294 10.5027 7.72094 10.3226C8.9226 11.6111 8.4802 12.4469 8.99587 12.6815C9.4056 12.868 9.73507 13.1661 10.59 12.5852C10.7921 13.8019 10.0225 14.0083 10.6497 14.9737C10.8653 15.3056 11.7763 15.3333 11.7763 15.3333C11.2135 16.5084 9.73134 17.2771 8.10174 17.2771C7.03074 17.2771 5.03854 16.6875 4.6134 15.5272C4.79774 15.6522 5.57007 15.4944 5.8118 15.0839C6.18374 14.4508 5.40534 13.1758 6.25934 12.2275Z"
      fill="#FFD456"
    />
    <path
      d="M6.26678 12.2474C6.87485 11.5727 7.69105 10.5337 7.72045 10.3527C8.91652 11.6365 8.47272 12.4709 8.98978 12.7056C9.39765 12.8907 9.72758 13.1841 10.5769 12.6065C10.7781 13.8163 10.0113 14.0222 10.6362 14.983C10.8523 15.3148 11.7581 15.3431 11.7581 15.3431C11.1971 16.5112 9.72432 17.2748 8.10218 17.2748C7.03585 17.2748 5.05205 16.6875 4.62878 15.5328C4.81218 15.6568 5.58125 15.4999 5.82158 15.0913C6.19212 14.461 5.41838 13.192 6.26678 12.2474Z"
      fill="#FFD558"
    />
    <path
      d="M6.27478 12.2673C6.87865 11.5958 7.69018 10.5642 7.72098 10.3823C8.91145 11.6615 8.46625 12.4941 8.98425 12.7292C9.39025 12.9134 9.72018 13.2012 10.5644 12.6278C10.765 13.8306 10.0006 14.0366 10.6232 14.9922C10.8392 15.3241 11.7408 15.3528 11.7408 15.3528C11.1818 16.5139 9.71785 17.2724 8.10318 17.2724C7.04198 17.2724 5.06611 16.6879 4.64471 15.5383C4.82718 15.6619 5.59298 15.505 5.83238 15.0982C6.20058 14.4711 5.43198 13.2077 6.27478 12.2673Z"
      fill="#FFD559"
    />
    <path
      d="M6.28269 12.2877C6.88283 11.6189 7.68923 10.5957 7.72096 10.4124C8.90583 11.687 8.45923 12.5177 8.97863 12.7528C9.38276 12.9356 9.71316 13.2183 10.5513 12.6486C10.751 13.8445 9.98943 14.05 10.6096 15.001C10.8262 15.3329 11.7226 15.362 11.7226 15.362C11.165 16.5162 9.71083 17.2697 8.10363 17.2697C7.0471 17.2697 5.07963 16.6875 4.6601 15.5434C4.84163 15.6665 5.60416 15.5101 5.84216 15.1052C6.20943 14.4813 5.44503 13.2239 6.28269 12.2877Z"
      fill="#FFD55A"
    />
    <path
      d="M6.29014 12.3076C6.88608 11.6421 7.68734 10.6263 7.72094 10.4421C8.90021 11.712 8.45221 12.5413 8.97301 12.7764C9.37528 12.9583 9.70568 13.2355 10.5387 12.67C10.7375 13.8589 9.97868 14.0639 10.5961 15.0103C10.8126 15.3422 11.7044 15.3718 11.7044 15.3718C11.1486 16.5191 9.70334 17.2679 8.10408 17.2679C7.05268 17.2679 5.09314 16.6884 4.67548 15.5495C4.85608 15.6721 5.61534 15.5162 5.85241 15.1131C6.21781 14.4911 5.45808 13.2397 6.29014 12.3076Z"
      fill="#FFD65B"
    />
    <path
      d="M6.29808 12.3275C6.89028 11.6648 7.68641 10.6573 7.72095 10.4717C8.89461 11.737 8.44521 12.5644 8.96741 12.8C9.36828 12.981 9.69821 13.2526 10.5261 12.6908C10.7244 13.8728 9.96841 14.0773 10.583 15.0191C10.8 15.3509 11.6871 15.381 11.6871 15.381C11.1332 16.5209 9.69728 17.2651 8.10501 17.2651C7.05875 17.2651 5.10668 16.688 4.69135 15.5546C4.87101 15.6767 5.62655 15.5213 5.86268 15.12C6.22621 14.5012 5.47115 13.2559 6.29808 12.3275Z"
      fill="#FFD65D"
    />
    <path
      d="M6.30601 12.3478C6.89401 11.6883 7.68501 10.6882 7.72141 10.5017C8.88901 11.7624 8.43868 12.588 8.96181 12.8236C9.36082 13.0031 9.69121 13.2697 10.5135 12.712C10.7109 13.8871 9.95768 14.0912 10.5699 15.0283C10.7869 15.3601 11.6694 15.3907 11.6694 15.3907C11.1173 16.5236 9.69028 17.2627 8.10595 17.2627C7.06435 17.2627 5.12068 16.6879 4.70721 15.5601C4.88641 15.6813 5.63821 15.5263 5.87295 15.1274C6.23508 14.5114 5.48421 13.272 6.30601 12.3478Z"
      fill="#FFD65E"
    />
    <path
      d="M6.31352 12.3677C6.89779 11.711 7.68366 10.7193 7.72099 10.5314C8.88299 11.7874 8.43126 12.6116 8.95579 12.8472C9.35292 13.0258 9.68332 13.2869 10.5005 12.7329C10.6969 13.901 9.94652 14.1051 10.5565 15.0372C10.7739 15.369 11.6513 15.4 11.6513 15.4C11.1006 16.526 9.68332 17.26 8.10646 17.26C7.06999 17.26 5.13426 16.688 4.72266 15.5652C4.90092 15.686 5.64946 15.5314 5.88326 15.1339C6.24352 14.5216 5.49732 13.2878 6.31352 12.3677Z"
      fill="#FFD75F"
    />
    <path
      d="M6.32148 12.3876C6.90201 11.7342 7.68228 10.7498 7.72148 10.561C8.87788 11.8124 8.42475 12.6348 8.95068 12.8708C9.34595 13.048 9.67635 13.304 10.4883 12.7542C10.6839 13.9153 9.93628 14.119 10.5439 15.0464C10.7613 15.3782 11.6345 15.4097 11.6345 15.4097C11.0857 16.5287 9.67775 17.2576 8.10788 17.2576C7.07608 17.2576 5.14875 16.6879 4.73901 15.5707C4.91635 15.6911 5.66161 15.537 5.89401 15.1413C6.25195 14.5318 5.51088 13.304 6.32148 12.3876Z"
      fill="#FFD761"
    />
    <path
      d="M6.32937 12.408C6.90571 11.7573 7.68131 10.7813 7.72144 10.5911C8.87224 11.8378 8.41771 12.6584 8.94457 12.8949C9.33797 13.0712 9.66884 13.3216 10.4748 12.7759C10.6698 13.9297 9.92457 14.1333 10.5298 15.0561C10.7478 15.3879 11.6158 15.4199 11.6158 15.4199C11.0688 16.532 9.66977 17.2563 8.10784 17.2563C7.08117 17.2563 5.16177 16.6893 4.75391 15.5772C4.93031 15.6971 5.67231 15.543 5.90377 15.1496C6.26031 14.5415 5.52391 13.3197 6.32937 12.408Z"
      fill="#FFD762"
    />
    <path
      d="M6.33682 12.4279C6.90942 11.7805 7.67989 10.8119 7.72142 10.6207C8.86662 11.8629 8.41069 12.682 8.93895 12.918C9.33049 13.0934 9.66135 13.3383 10.4622 12.7963C10.6563 13.9431 9.91382 14.1463 10.5163 15.0645C10.7342 15.3963 11.598 15.4287 11.598 15.4287C11.0525 16.5334 9.66322 17.2531 8.10829 17.2531C7.08629 17.2531 5.17482 16.6884 4.76929 15.5814C4.94475 15.7004 5.68302 15.5472 5.91355 15.1557C6.26915 14.5517 5.53695 13.336 6.33682 12.4279Z"
      fill="#FFD863"
    />
    <path
      d="M6.34476 12.4478C6.91316 11.8032 7.67849 10.8429 7.72142 10.6503C8.86102 11.8878 8.40369 12.7051 8.93336 12.9416C9.32349 13.1156 9.65436 13.3554 10.4496 12.8176C10.6428 13.9575 9.90309 14.1602 10.5032 15.0733C10.7216 15.4051 11.5803 15.438 11.5803 15.438C11.0366 16.5357 9.65622 17.2503 8.10922 17.2503C7.09236 17.2503 5.18882 16.688 4.78516 15.5865C4.96016 15.705 5.69469 15.5523 5.92429 15.1626C6.27756 14.5619 5.55002 13.3517 6.34476 12.4478Z"
      fill="#FFD864"
    />
    <path
      d="M6.35269 12.4677C6.91736 11.8263 7.67756 10.8734 7.72189 10.6799C8.85589 11.9128 8.39669 12.7283 8.92776 12.9652C9.31603 13.1383 9.64689 13.3725 10.437 12.8384C10.6297 13.9713 9.89236 14.174 10.4902 15.082C10.7086 15.4139 11.5626 15.4472 11.5626 15.4472C11.0208 16.538 9.64969 17.2475 8.11016 17.2475C7.09796 17.2475 5.20283 16.688 4.80103 15.5916C4.97509 15.7096 5.70636 15.5573 5.93456 15.1691C6.28596 14.572 5.56309 13.3679 6.35269 12.4677Z"
      fill="#FFD866"
    />
    <path
      d="M6.36014 12.4881C6.92061 11.8494 7.67567 10.9049 7.72141 10.71C8.84981 11.9383 8.38921 12.7523 8.92167 12.9888C9.30807 13.1605 9.63894 13.3896 10.4239 12.8597C10.6157 13.9857 9.88114 14.1879 10.4766 15.0913C10.6955 15.4231 11.5448 15.4569 11.5448 15.4569C11.0044 16.5408 9.64267 17.2456 8.11061 17.2456C7.10354 17.2456 5.21634 16.6884 4.81641 15.5976C4.98954 15.7152 5.71754 15.5629 5.94481 15.1769C6.29481 14.5822 5.57661 13.3841 6.36014 12.4881Z"
      fill="#FFD967"
    />
    <path
      d="M6.3681 12.5079C6.92483 11.8725 7.67476 10.9354 7.7219 10.7401C8.84423 11.9637 8.3827 12.7759 8.91656 13.0128C9.3011 13.1836 9.63243 13.4071 10.4118 12.8809C10.6026 14 9.8709 14.2018 10.464 15.1005C10.6829 15.4323 11.5276 15.4661 11.5276 15.4661C10.989 16.5426 9.63663 17.2428 8.11203 17.2428C7.10963 17.2428 5.23083 16.6884 4.83276 15.6027C5.00496 15.7193 5.7297 15.5679 5.95556 15.1838C6.30323 14.5919 5.5897 13.3997 6.3681 12.5079Z"
      fill="#FFD968"
    />
    <path
      d="M6.37597 12.5279C6.92897 11.8957 7.6733 10.9664 7.72183 10.7697C8.83857 11.9887 8.37563 12.7991 8.91044 13.0365C9.2931 13.2063 9.62443 13.4243 10.3982 12.9023C10.5881 14.0144 9.85917 14.2157 10.45 15.1098C10.6693 15.4416 11.5088 15.4759 11.5088 15.4759C10.9722 16.5454 9.6291 17.2405 8.1115 17.2405C7.11423 17.2405 5.24337 16.6884 4.84717 15.6082C5.01843 15.7244 5.7399 15.5735 5.96437 15.1913C6.31157 14.6021 5.6027 13.416 6.37597 12.5279Z"
      fill="#FFD96A"
    />
    <path
      d="M6.38344 12.5482C6.93224 11.9188 7.6719 10.9974 7.72184 10.7998C8.83297 12.0142 8.36864 12.8232 8.90484 13.0606C9.28564 13.229 9.61744 13.4419 10.3856 12.9236C10.575 14.0287 9.84844 14.2296 10.4364 15.1191C10.6558 15.4509 11.4911 15.4856 11.4911 15.4856C10.9563 16.5482 9.6221 17.2382 8.11244 17.2382C7.11984 17.2382 5.2569 16.6884 4.86304 15.6138C5.03384 15.7295 5.7511 15.5786 5.9751 15.1987C6.32044 14.6123 5.61577 13.4317 6.38344 12.5482Z"
      fill="#FFDA6B"
    />
    <path
      d="M6.39139 12.5681C6.93646 11.942 7.671 11.0284 7.72233 10.8294C8.82786 12.0392 8.36213 12.8463 8.89973 13.0837C9.27913 13.2512 9.61046 13.4586 10.3735 12.9444C10.562 14.0421 9.8382 14.2435 10.4239 15.1279C10.6437 15.4597 11.4739 15.4949 11.4739 15.4949C10.9405 16.5505 9.61606 17.2359 8.11386 17.2359C7.12639 17.2359 5.27139 16.6889 4.87939 15.6193C5.04926 15.7346 5.76326 15.5842 5.98586 15.2056C6.32886 14.6225 5.62886 13.4479 6.39139 12.5681Z"
      fill="#FFDA6C"
    />
    <path
      d="M6.39937 12.588C6.94024 11.9646 7.66964 11.0589 7.72237 10.859C8.8223 12.0641 8.35517 12.8694 8.89417 13.1073C9.2717 13.2739 9.60304 13.4757 10.3609 12.9657C10.5485 14.0565 9.8275 14.2573 10.4108 15.1371C10.6306 15.4689 11.4562 15.5046 11.4562 15.5046C10.9246 16.5533 9.6091 17.2336 8.11437 17.2336C7.13204 17.2336 5.28497 16.6889 4.89484 15.6249C5.06377 15.7392 5.7745 15.5897 5.99617 15.213C6.3373 14.6327 5.64244 13.4641 6.39937 12.588Z"
      fill="#FFDA6D"
    />
    <path
      d="M6.40684 12.6084C6.94397 11.9882 7.66824 11.0904 7.72237 10.8891C8.81671 12.0896 8.34817 12.8935 8.88811 13.1313C9.26377 13.2966 9.59557 13.4933 10.3478 12.987C10.5345 14.0708 9.81677 14.2712 10.3973 15.1464C10.6176 15.4782 11.4384 15.5143 11.4384 15.5143C10.9088 16.5556 9.60257 17.2313 8.11531 17.2313C7.13764 17.2313 5.29897 16.6893 4.91071 15.6304C5.07871 15.7443 5.78617 15.5953 6.00644 15.2204C6.34571 14.6424 5.65551 13.4798 6.40684 12.6084Z"
      fill="#FFDB6F"
    />
    <path
      d="M6.41475 12.6283C6.94769 12.0109 7.66682 11.1209 7.72235 10.9187C8.81109 12.1146 8.34115 12.9166 8.88249 13.1545C9.25629 13.3188 9.58809 13.5099 10.3352 13.0078C10.5214 14.0847 9.80602 14.2846 10.3842 15.1551C10.6045 15.487 11.4207 15.5235 11.4207 15.5235C10.8924 16.5579 9.59555 17.2285 8.11575 17.2285C7.14322 17.2285 5.31249 16.6888 4.92609 15.6355C5.09315 15.7489 5.79735 15.5999 6.01669 15.2273C6.35455 14.6525 5.66855 13.496 6.41475 12.6283Z"
      fill="#FFDB70"
    />
    <path
      d="M6.42269 12.6482C6.95189 12.0341 7.66589 11.152 7.72282 10.9484C8.80596 12.1396 8.33462 12.9398 8.87735 13.1781C9.24929 13.3415 9.58156 13.5271 10.3231 13.0286C10.5084 14.0986 9.79575 14.2981 10.3712 15.164C10.5919 15.4958 11.403 15.5328 11.403 15.5328C10.8766 16.5602 9.58902 17.2257 8.11669 17.2257C7.14882 17.2257 5.32602 16.6884 4.94196 15.6407C5.10809 15.7536 5.80902 15.605 6.02696 15.2339C6.36295 14.6628 5.68162 13.5118 6.42269 12.6482Z"
      fill="#FFDB71"
    />
    <path
      d="M6.43018 12.6681C6.95564 12.0567 7.66404 11.1825 7.72238 10.978C8.79944 12.1646 8.32671 12.9634 8.87084 13.2017C9.24091 13.3637 9.57318 13.5442 10.3091 13.0499C10.4934 14.1129 9.78364 14.312 10.3567 15.1732C10.5774 15.505 11.3843 15.5425 11.3843 15.5425C10.8598 16.563 9.58158 17.2239 8.11671 17.2239C7.15398 17.2239 5.33911 16.6893 4.95691 15.6462C5.12258 15.7582 5.81931 15.6106 6.03678 15.2412C6.37138 14.6729 5.69471 13.528 6.43018 12.6681Z"
      fill="#FFDC73"
    />
    <path
      d="M6.43811 12.6885C6.95938 12.0804 7.66311 11.214 7.72284 11.0081C8.79431 12.19 8.32018 12.987 8.86571 13.2253C9.23391 13.3864 9.56618 13.5613 10.297 13.0707C10.4808 14.1268 9.77338 14.3254 10.3441 15.182C10.5653 15.5138 11.367 15.5518 11.367 15.5518C10.8444 16.5653 9.57505 17.2211 8.11765 17.2211C7.15958 17.2211 5.35311 16.6889 4.97278 15.6513C5.13751 15.7628 5.83098 15.6152 6.04704 15.2482C6.38024 14.6831 5.70824 13.5437 6.43811 12.6885Z"
      fill="#FFDC74"
    />
    <path
      d="M6.44607 12.7084C6.9636 12.103 7.6622 11.2445 7.72287 11.0377C8.78874 12.2145 8.3132 13.0106 8.86014 13.2489C9.22694 13.409 9.5592 13.5784 10.2844 13.092C10.4673 14.1412 9.76267 14.3397 10.3311 15.1913C10.5523 15.5231 11.3498 15.5615 11.3498 15.5615C10.8285 16.5676 9.569 17.2188 8.11907 17.2188C7.16614 17.2188 5.3676 16.6893 4.98914 15.6568C5.15294 15.7679 5.84314 15.6207 6.05827 15.2556C6.38867 14.6933 5.72134 13.5599 6.44607 12.7084Z"
      fill="#FFDC75"
    />
    <path
      d="M6.4535 12.7283C6.96683 12.1262 7.6603 11.2756 7.72283 11.0673C8.7831 12.2396 8.30616 13.0337 8.85403 13.2725C9.21896 13.4313 9.55123 13.5956 10.2713 13.1129C10.4533 14.1551 9.75143 14.3532 10.3175 15.2001C10.5392 15.5319 11.3316 15.5708 11.3316 15.5708C10.8122 16.57 9.56149 17.216 8.11903 17.216C7.17076 17.216 5.38063 16.6889 5.00403 15.662C5.16689 15.7726 5.85383 15.6259 6.06756 15.2626C6.39703 14.703 5.73436 13.5761 6.4535 12.7283Z"
      fill="#FFDD77"
    />
    <path
      d="M6.46143 12.7486C6.97103 12.1493 7.65936 11.3066 7.72283 11.0978C8.7775 12.2655 8.29916 13.0578 8.84843 13.297C9.2115 13.4549 9.54423 13.6136 10.2587 13.1351C10.4398 14.1699 9.74116 14.368 10.3044 15.2102C10.5261 15.5421 11.3138 15.5814 11.3138 15.5814C10.7963 16.5736 9.55496 17.2151 8.11996 17.2151C7.17683 17.2151 5.39463 16.6907 5.0199 15.6689C5.18183 15.779 5.8655 15.6328 6.07783 15.2709C6.4059 14.7132 5.74743 13.5918 6.46143 12.7486Z"
      fill="#FFDD78"
    />
    <path
      d="M6.46933 12.7685C6.97473 12.1724 7.65793 11.3376 7.72326 11.1274C8.77233 12.2904 8.29259 13.0814 8.84326 13.3202C9.20446 13.4771 9.53719 13.6302 10.2465 13.1559C10.4271 14.1837 9.73086 14.3814 10.2913 15.219C10.5135 15.5508 11.2965 15.5906 11.2965 15.5906C10.7804 16.5759 9.54839 17.2123 8.12133 17.2123C7.18286 17.2123 5.40859 16.6903 5.03619 15.674C5.19766 15.7832 5.87759 15.6374 6.08899 15.2778C6.41426 14.7234 5.76046 13.608 6.46933 12.7685Z"
      fill="#FFDD79"
    />
    <path
      d="M6.47683 12.7884C6.9785 12.1951 7.65656 11.3681 7.72283 11.157C8.7663 12.3154 8.28516 13.1045 8.83676 13.3438C9.1961 13.4997 9.52883 13.6478 10.2326 13.1767C10.4122 14.1976 9.71876 14.3948 10.2769 15.2278C10.499 15.5596 11.2774 15.5999 11.2774 15.5999C10.7632 16.5782 9.5405 17.2095 8.12043 17.2095C7.1871 17.2095 5.42077 16.6898 5.05023 15.679C5.21077 15.7878 5.88697 15.6425 6.09743 15.2847C6.4227 14.7335 5.77403 13.6237 6.47683 12.7884Z"
      fill="#FFDE7A"
    />
    <path
      d="M6.48479 12.8088C6.98272 12.2188 7.65519 11.3996 7.72332 11.1872C8.76119 12.3409 8.27866 13.1286 8.83166 13.3679C9.18912 13.5225 9.52232 13.6655 10.2205 13.1985C10.3992 14.2125 9.70852 14.4092 10.2643 15.2376C10.4869 15.5694 11.2602 15.6101 11.2602 15.6101C10.7478 16.5811 9.53446 17.2077 8.12186 17.2077C7.19319 17.2077 5.43526 16.6908 5.06659 15.6851C5.22619 15.7934 5.89912 15.6486 6.10866 15.2927C6.43112 14.7438 5.78712 13.64 6.48479 12.8088Z"
      fill="#FFDE7C"
    />
    <path
      d="M6.49266 12.8287C6.9864 12.2419 7.6542 11.4306 7.72326 11.2168C8.75553 12.3659 8.27113 13.1517 8.826 13.391C9.1816 13.5447 9.5148 13.6821 10.2078 13.2189C10.3861 14.2259 9.69773 14.4221 10.2512 15.2459C10.4738 15.5777 11.2429 15.6189 11.2429 15.6189C10.7319 16.5829 9.52786 17.2049 8.12273 17.2049C7.1992 17.2049 5.4492 16.6903 5.0824 15.6902C5.24106 15.798 5.91073 15.6532 6.11886 15.2996C6.43993 14.7535 5.80013 13.6562 6.49266 12.8287Z"
      fill="#FFDE7D"
    />
    <path
      d="M6.50013 12.8486C6.99013 12.2646 7.6528 11.4611 7.72327 11.2464C8.74947 12.3909 8.26413 13.1749 8.8204 13.4146C9.1746 13.5673 9.50733 13.6992 10.1952 13.2401C10.3725 14.2402 9.68747 14.4365 10.2381 15.2551C10.4612 15.587 11.2251 15.6286 11.2251 15.6286C10.716 16.5857 9.52133 17.2026 8.12367 17.2026C7.20527 17.2026 5.4632 16.6907 5.09827 15.6957C5.256 15.8026 5.9224 15.6587 6.1296 15.3065C6.44833 14.7637 5.8132 13.6719 6.50013 12.8486Z"
      fill="#FFDF7E"
    />
    <path
      d="M6.50805 12.8684C6.99385 12.2876 7.65138 11.4921 7.72325 11.2759C8.74385 12.4158 8.25711 13.1984 8.81431 13.4381C9.16665 13.5895 9.49985 13.7163 10.1821 13.2609C10.3585 14.254 9.67625 14.4498 10.2246 15.2639C10.4481 15.5957 11.2069 15.6378 11.2069 15.6378C10.6996 16.5879 9.51385 17.1997 8.12411 17.1997C7.21038 17.1997 5.47625 16.6902 5.11365 15.7007C5.27091 15.8072 5.93358 15.6637 6.13938 15.3134C6.45671 14.7738 5.82625 13.688 6.50805 12.8684Z"
      fill="#FFDF80"
    />
    <path
      d="M6.51604 12.8889C6.99811 12.3108 7.65051 11.5231 7.72378 11.3061C8.73878 12.4413 8.25064 13.2221 8.80924 13.4623C9.15971 13.6127 9.49291 13.7339 10.17 13.2827C10.3455 14.2689 9.66604 14.4642 10.2116 15.2736C10.4351 15.6055 11.1897 15.648 11.1897 15.648C10.6843 16.5912 9.50784 17.198 8.12511 17.198C7.21651 17.198 5.49031 16.6907 5.12958 15.7068C5.28591 15.8128 5.94484 15.6698 6.15018 15.3213C6.46564 14.784 5.83984 13.7038 6.51604 12.8889Z"
      fill="#FFDF81"
    />
    <path
      d="M6.52347 12.9087C7.00134 12.3339 7.6486 11.5541 7.72327 11.3357C8.73267 12.4663 8.24314 13.2457 8.80314 13.4854C9.15174 13.6349 9.4854 13.7506 10.1565 13.3035C10.3315 14.2823 9.65434 14.4776 10.1975 15.2824C10.4215 15.6142 11.171 15.6573 11.171 15.6573C10.667 16.5931 9.49987 17.1952 8.12507 17.1952C7.22114 17.1952 5.50334 16.6907 5.14447 15.7114C5.29987 15.8169 5.95554 15.6739 6.15947 15.3278C6.474 14.7942 5.85287 13.72 6.52347 12.9087Z"
      fill="#FFE082"
    />
    <path
      d="M6.53138 12.9287C7.00552 12.3567 7.64765 11.5847 7.72372 11.3654C8.72752 12.4913 8.23658 13.2688 8.79752 13.509C9.14425 13.6576 9.47792 13.7677 10.1439 13.3244C10.3179 14.2962 9.64358 14.4911 10.1845 15.2913C10.4085 15.6226 11.1533 15.6666 11.1533 15.6666C10.6511 16.5954 9.49332 17.1929 8.12552 17.1929C7.22672 17.1929 5.51685 16.6908 5.15985 15.717C5.31432 15.8216 5.96672 15.6795 6.16925 15.3348C6.48238 14.8039 5.86592 13.7363 6.53138 12.9287Z"
      fill="#FFE083"
    />
    <path
      d="M6.53936 12.9491C7.0093 12.3803 7.64676 11.6162 7.72423 11.3954C8.72243 12.5168 8.2301 13.2929 8.79243 13.5331C9.1373 13.6803 9.47096 13.7853 10.1318 13.3461C10.3049 14.3111 9.63383 14.5054 10.1719 15.301C10.3964 15.6323 11.1365 15.6768 11.1365 15.6768C10.6362 16.5987 9.48776 17.191 8.12743 17.191C7.2333 17.191 5.53183 16.6917 5.1767 15.7231C5.33023 15.8272 5.97936 15.6856 6.18096 15.3426C6.4913 14.8141 5.87903 13.752 6.53936 12.9491Z"
      fill="#FFE085"
    />
    <path
      d="M6.54681 12.9689C7.01301 12.4029 7.64488 11.6467 7.72374 11.425C8.71634 12.5418 8.22214 13.316 8.78634 13.5562C9.12934 13.7025 9.46348 13.802 10.1187 13.3665C10.2909 14.3245 9.62261 14.5184 10.1583 15.3093C10.3828 15.6411 11.1183 15.686 11.1183 15.686C10.6194 16.601 9.48028 17.1883 8.12788 17.1883C7.23888 17.1883 5.54534 16.6912 5.19208 15.7281C5.34468 15.8318 5.99054 15.6902 6.19074 15.3496C6.49968 14.8243 5.89208 13.7682 6.54681 12.9689Z"
      fill="#FFE186"
    />
    <path
      d="M6.55479 12.9888C7.01725 12.4261 7.64399 11.6777 7.72425 11.4547C8.71125 12.5668 8.21565 13.3396 8.78079 13.5798C9.12192 13.7251 9.45605 13.8191 10.1061 13.3878C10.2779 14.3388 9.61192 14.5322 10.1449 15.3185C10.3698 15.6499 11.1006 15.6953 11.1006 15.6953C10.6036 16.6028 9.47379 17.1855 8.12839 17.1855C7.24405 17.1855 5.55845 16.6907 5.20752 15.7332C5.35965 15.8364 6.00179 15.6953 6.20105 15.3565C6.50812 14.8345 5.90565 13.7839 6.55479 12.9888Z"
      fill="#FFE187"
    />
    <path
      d="M6.56272 13.0092C7.02099 12.4492 7.64259 11.7087 7.72426 11.4852C8.70519 12.5926 8.20866 13.3637 8.77519 13.6043C9.11492 13.7487 9.44906 13.8371 10.0935 13.4095C10.2643 14.3536 9.60119 14.5466 10.1318 15.3282C10.3567 15.6596 11.0829 15.7049 11.0829 15.7049C10.5877 16.6055 9.46679 17.1836 8.12932 17.1836C7.25012 17.1836 5.57246 16.6916 5.22339 15.7392C5.37459 15.8415 6.01299 15.7012 6.21132 15.3643C6.51652 14.8446 5.91872 13.8001 6.56272 13.0092Z"
      fill="#FFE189"
    />
    <path
      d="M6.57019 13.0291C7.02473 12.4724 7.64119 11.7398 7.72426 11.5148C8.69959 12.6177 8.20166 13.3873 8.76959 13.628C9.10746 13.771 9.44159 13.8543 10.0809 13.4308C10.2508 14.368 9.59093 14.5605 10.1187 15.3375C10.3441 15.6689 11.0651 15.7147 11.0651 15.7147C10.5719 16.6084 9.46026 17.1813 8.13026 17.1813C7.25572 17.1813 5.58646 16.6917 5.23926 15.7448C5.38952 15.8466 6.02466 15.7068 6.22206 15.3713C6.52539 14.8544 5.93179 13.8159 6.57019 13.0291Z"
      fill="#FFE18A"
    />
    <path
      d="M6.57808 13.049C7.02842 12.495 7.63975 11.7703 7.72422 11.5444C8.69395 12.6426 8.19462 13.4104 8.76348 13.6511C9.09948 13.7932 9.43362 13.8709 10.0674 13.4512C10.2368 14.3814 9.57922 14.5734 10.1047 15.3458C10.3301 15.6772 11.0469 15.7235 11.0469 15.7235C10.555 16.6102 9.45275 17.1781 8.13022 17.1781C7.26082 17.1781 5.59948 16.6912 5.25415 15.7494C5.40348 15.8508 6.03535 15.711 6.23135 15.3778C6.53375 14.8645 5.94482 13.832 6.57808 13.049Z"
      fill="#FFE28B"
    />
    <path
      d="M6.58604 13.0689C7.03264 12.5182 7.63884 11.8013 7.72471 11.5741C8.68884 12.6677 8.18811 13.4341 8.75838 13.6747C9.09251 13.8159 9.42711 13.8881 10.0552 13.4725C10.2237 14.3958 9.56897 14.5878 10.0921 15.3551C10.318 15.6865 11.0296 15.7332 11.0296 15.7332C10.5396 16.613 9.44671 17.1758 8.13164 17.1758C7.26691 17.1758 5.61397 16.6912 5.27051 15.755C5.41891 15.8559 6.04751 15.7166 6.24257 15.3852C6.54217 14.8748 5.95791 13.8483 6.58604 13.0689Z"
      fill="#FFE28C"
    />
    <path
      d="M6.59353 13.0892C7.03593 12.5413 7.63746 11.8323 7.72426 11.6041C8.68279 12.6931 8.18019 13.4576 8.75233 13.6987C9.08459 13.839 9.41919 13.9056 10.0422 13.4942C10.2097 14.4101 9.55779 14.6021 10.0786 15.3648C10.3049 15.6962 11.0115 15.7434 11.0115 15.7434C10.5233 16.6157 9.43973 17.1743 8.13166 17.1743C7.27206 17.1743 5.62659 16.6921 5.28546 15.7614C5.43339 15.8614 6.05826 15.723 6.25193 15.3935C6.55106 14.8849 5.97099 13.864 6.59353 13.0892Z"
      fill="#FFE28E"
    />
    <path
      d="M6.60142 13.1091C7.04009 12.5644 7.63602 11.8633 7.72469 11.6337C8.67762 12.7181 8.17362 13.4807 8.74716 13.7219C9.07756 13.8607 9.41262 13.9223 10.03 13.5145C10.1966 14.4235 9.54796 14.6151 10.0655 15.3731C10.2918 15.7045 10.9942 15.7522 10.9942 15.7522C10.5074 16.6176 9.43316 17.1711 8.13302 17.1711C7.27856 17.1711 5.64102 16.6916 5.30176 15.766C5.44876 15.8655 6.07036 15.7272 6.26309 15.4C6.55942 14.8951 5.98449 13.8801 6.60142 13.1091Z"
      fill="#FFE38F"
    />
    <path
      d="M6.60934 13.129C7.04427 12.5875 7.63507 11.8938 7.72467 11.6633C8.67201 12.743 8.16661 13.5043 8.74107 13.7455C9.06961 13.8834 9.40467 13.9394 10.0169 13.5358C10.1831 14.4378 9.53674 14.6289 10.0519 15.3824C10.2787 15.7137 10.9759 15.7619 10.9759 15.7619C10.4911 16.6203 9.42614 17.1688 8.13347 17.1688C7.28367 17.1688 5.65454 16.6916 5.31714 15.7716C5.46321 15.8706 6.08107 15.7327 6.27287 15.4069C6.56781 14.9048 5.99754 13.8959 6.60934 13.129Z"
      fill="#FFE390"
    />
    <path
      d="M6.61679 13.1494C7.04752 12.6107 7.63319 11.9253 7.72465 11.6934C8.66639 12.7685 8.15959 13.5284 8.73545 13.7695C9.06212 13.9065 9.39719 13.957 10.0043 13.5571C10.1695 14.4522 9.52599 14.6428 10.0389 15.3916C10.2657 15.723 10.9582 15.7716 10.9582 15.7716C10.4752 16.6231 9.41912 17.1665 8.13392 17.1665C7.28925 17.1665 5.66805 16.6921 5.33252 15.7767C5.47765 15.8752 6.09225 15.7378 6.28265 15.4138C6.57665 14.9149 6.01059 13.9121 6.61679 13.1494Z"
      fill="#FFE392"
    />
    <path
      d="M6.62472 13.1693C7.05172 12.6339 7.63225 11.9559 7.72465 11.7231C8.66079 12.7935 8.15259 13.5516 8.72985 13.7932C9.05465 13.9292 9.39019 13.9741 9.99126 13.5784C10.1555 14.4665 9.51479 14.6567 10.0253 15.4009C10.2526 15.7323 10.9405 15.7813 10.9405 15.7813C10.4593 16.6259 9.41259 17.1642 8.13485 17.1642C7.29485 17.1642 5.68206 16.6921 5.34839 15.7823C5.49259 15.8804 6.10392 15.7434 6.29339 15.4213C6.58506 14.9252 6.02365 13.9283 6.62472 13.1693Z"
      fill="#FFE493"
    />
    <path
      d="M6.63264 13.1892C7.05544 12.6565 7.63084 11.9869 7.7251 11.7527C8.65517 12.8185 8.14604 13.5752 8.72424 13.8163C9.04764 13.951 9.3827 13.9908 9.97864 13.5988C10.1424 14.4799 9.5045 14.6697 10.0122 15.4092C10.2395 15.7406 10.9227 15.7901 10.9227 15.7901C10.443 16.6273 9.40557 17.1614 8.1353 17.1614C7.30044 17.1614 5.69557 16.6921 5.36377 15.7873C5.5075 15.8845 6.1151 15.748 6.30317 15.4282C6.59344 14.9353 6.0367 13.944 6.63264 13.1892Z"
      fill="#FFE494"
    />
    <path
      d="M6.64014 13.2096C7.05921 12.6801 7.62948 12.0179 7.72468 11.7828C8.64914 12.844 8.13814 13.5988 8.71821 13.8404C9.03974 13.9741 9.37528 14.0084 9.96561 13.6205C10.1285 14.4948 9.49334 14.684 9.99874 15.419C10.2265 15.7503 10.9045 15.8003 10.9045 15.8003C10.4267 16.6306 9.39861 17.1595 8.13581 17.1595C7.30561 17.1595 5.70868 16.6926 5.37921 15.7934C5.52201 15.8901 6.12634 15.754 6.31348 15.4356C6.60188 14.9455 6.05028 13.9602 6.64014 13.2096Z"
      fill="#FFE495"
    />
    <path
      d="M6.6481 13.2295C7.06297 12.7028 7.62857 12.0489 7.72517 11.8124C8.64403 12.8689 8.13164 13.6224 8.7131 13.864C9.03277 13.9968 9.3683 14.0255 9.9535 13.6413C10.1154 14.5086 9.4831 14.6975 9.9857 15.4277C10.2134 15.7591 10.8873 15.8095 10.8873 15.8095C10.4113 16.6329 9.39257 17.1568 8.13724 17.1568C7.31217 17.1568 5.72317 16.6921 5.39557 15.7984C5.53744 15.8947 6.1385 15.7591 6.32424 15.4426C6.61077 14.9552 6.06337 13.976 6.6481 13.2295Z"
      fill="#FFE597"
    />
    <path
      d="M6.656 13.2493C7.06713 12.7259 7.62713 12.0794 7.72513 11.842C8.63839 12.8939 8.12459 13.6455 8.70699 13.8871C9.02479 14.019 9.36033 14.0421 9.94039 13.6622C10.1014 14.522 9.47233 14.7109 9.97213 15.4365C10.2003 15.7679 10.8691 15.8188 10.8691 15.8188C10.3945 16.6352 9.38506 17.154 8.13719 17.154C7.31679 17.154 5.73619 16.6921 5.41046 15.8035C5.55139 15.8993 6.14873 15.7637 6.33399 15.4495C6.61913 14.9654 6.0764 13.9921 6.656 13.2493Z"
      fill="#FFE598"
    />
    <path
      d="M6.66346 13.2693C7.07086 12.7486 7.62573 12.1104 7.72513 11.8721C8.6328 12.9189 8.1176 13.6696 8.7014 13.9112C9.01733 14.0421 9.35333 14.0597 9.9278 13.6839C10.0883 14.5369 9.4616 14.7252 9.95906 15.4462C10.1877 15.7771 10.8513 15.829 10.8513 15.829C10.3786 16.6384 9.37853 17.1526 8.13813 17.1526C7.32286 17.1526 5.7502 16.693 5.42633 15.81C5.56633 15.9049 6.1604 15.7702 6.34426 15.4578C6.62753 14.9756 6.08946 14.0079 6.66346 13.2693Z"
      fill="#FFE599"
    />
    <path
      d="M6.6714 13.2896C7.0746 12.7722 7.62433 12.1414 7.7256 11.9022C8.62767 12.9444 8.11107 13.6932 8.69627 13.9352C9.01033 14.0648 9.34633 14.0773 9.9152 13.7052C10.0748 14.5512 9.45087 14.7391 9.946 15.4555C10.1747 15.7864 10.8341 15.8387 10.8341 15.8387C10.3632 16.6407 9.372 17.1503 8.13907 17.1503C7.32847 17.1503 5.7642 16.6935 5.4422 15.8156C5.58127 15.91 6.17207 15.7758 6.355 15.4652C6.6364 14.9858 6.10253 14.0241 6.6714 13.2896Z"
      fill="#FFE69B"
    />
    <path
      d="M6.67937 13.3096C7.07884 12.7949 7.62344 12.1725 7.72564 11.9318C8.62211 12.9694 8.10364 13.7168 8.69024 13.9589C9.00244 14.0875 9.33844 14.0949 9.90217 13.7265C10.0608 14.5656 9.44018 14.753 9.93251 15.4648C10.1616 15.7957 10.8159 15.8485 10.8159 15.8485C10.3464 16.6436 9.36504 17.148 8.13957 17.148C7.33411 17.148 5.77777 16.6935 5.45764 15.8212C5.59624 15.9151 6.18331 15.7814 6.36484 15.4722C6.64484 14.996 6.11611 14.0403 6.67937 13.3096Z"
      fill="#FFE69C"
    />
    <path
      d="M6.68687 13.3295C7.08213 12.8181 7.6216 12.203 7.72567 11.9614C8.61653 12.9944 8.09667 13.74 8.68467 13.982C8.995 14.1097 9.33147 14.1116 9.8896 13.7469C10.0473 14.579 9.42947 14.766 9.91947 15.4731C10.1486 15.804 10.7987 15.8572 10.7987 15.8572C10.3311 16.6454 9.35853 17.1447 8.141 17.1447C7.3402 17.1447 5.7918 16.6926 5.474 15.8258C5.61167 15.9193 6.19547 15.7855 6.3756 15.4787C6.65327 15.0057 6.1292 14.056 6.68687 13.3295Z"
      fill="#FFE69D"
    />
    <path
      d="M6.69476 13.3498C7.08629 12.8412 7.62063 12.2345 7.72563 11.9915C8.61043 13.0198 8.08962 13.764 8.67902 14.0061C8.98749 14.1329 9.32396 14.1292 9.87696 13.7687C10.0342 14.5938 9.41869 14.7803 9.90589 15.4829C10.1355 15.8138 10.7804 15.8674 10.7804 15.8674C10.3147 16.6486 9.35149 17.1434 8.14096 17.1434C7.34529 17.1434 5.80482 16.694 5.48889 15.8323C5.62562 15.9248 6.20569 15.792 6.38536 15.487C6.66209 15.0159 6.14222 14.0722 6.69476 13.3498Z"
      fill="#FFE79F"
    />
    <path
      d="M6.70223 13.3696C7.08956 12.8643 7.61923 12.265 7.72563 12.0211C8.60483 13.0448 8.08263 13.7876 8.67343 14.0296C8.98003 14.1555 9.31696 14.1462 9.86436 13.7894C10.0207 14.6076 9.40843 14.7937 9.89283 15.4916C10.1229 15.8225 10.7627 15.8766 10.7627 15.8766C10.2988 16.6509 9.34496 17.1405 8.14189 17.1405C7.35136 17.1405 5.81883 16.6934 5.50476 15.8373C5.64056 15.9294 6.21736 15.797 6.39563 15.4939C6.67049 15.026 6.15529 14.0879 6.70223 13.3696Z"
      fill="#FFE7A0"
    />
    <path
      d="M6.71014 13.3896C7.09374 12.8875 7.61781 12.296 7.72561 12.0507C8.59921 13.0698 8.07561 13.8107 8.66734 14.0528C8.97207 14.1773 9.30901 14.1629 9.85127 13.8103C10.0067 14.6216 9.39721 14.8071 9.87927 15.5004C10.1093 15.8313 10.7449 15.8859 10.7449 15.8859C10.2825 16.6528 9.33794 17.1378 8.14234 17.1378C7.35647 17.1378 5.83234 16.6935 5.52014 15.8424C5.65501 15.934 6.22854 15.8017 6.40587 15.5009C6.67887 15.0362 6.16834 14.1041 6.71014 13.3896Z"
      fill="#FFE7A1"
    />
    <path
      d="M6.71814 13.41C7.09754 12.9106 7.61694 12.3275 7.72614 12.0808C8.59414 13.0953 8.06867 13.8348 8.66227 14.0769C8.96561 14.2004 9.30207 14.1805 9.83874 13.8316C9.99367 14.6354 9.38654 14.821 9.86627 15.5097C10.0968 15.8406 10.7273 15.8956 10.7273 15.8956C10.2667 16.6556 9.33147 17.1355 8.14334 17.1355C7.36261 17.1355 5.84641 16.6935 5.53607 15.8475C5.67047 15.9387 6.24027 15.8068 6.41621 15.5074C6.68781 15.0464 6.18194 14.1204 6.71814 13.41Z"
      fill="#FFE8A2"
    />
    <path
      d="M6.72559 13.4299C7.10125 12.9338 7.61505 12.3581 7.72565 12.1105C8.58805 13.1203 8.06119 13.858 8.65619 14.1005C8.95765 14.2231 9.29459 14.1977 9.82565 13.8529C9.97965 14.6498 9.37579 14.835 9.85272 15.519C10.0833 15.8499 10.7091 15.9054 10.7091 15.9054C10.2503 16.6584 9.32399 17.1332 8.14379 17.1332C7.36772 17.1332 5.85945 16.6935 5.55145 15.8531C5.68492 15.9434 6.25145 15.8124 6.42645 15.5148C6.69619 15.0562 6.19499 14.1361 6.72559 13.4299Z"
      fill="#FFE8A4"
    />
    <path
      d="M6.7335 13.4498C7.10543 12.9564 7.6141 12.3891 7.7261 12.1401C8.5829 13.1453 8.05463 13.8816 8.65057 14.1241C8.95017 14.2458 9.2871 14.2148 9.81303 13.8742C9.9661 14.6642 9.36503 14.8488 9.83917 15.5282C10.0702 15.8591 10.6913 15.9151 10.6913 15.9151C10.234 16.6611 9.31743 17.1313 8.14423 17.1313C7.3733 17.1313 5.87297 16.6945 5.56683 15.8591C5.69937 15.9489 6.26263 15.8184 6.43623 15.5227C6.70457 15.0663 6.20803 14.1523 6.7335 13.4498Z"
      fill="#FFE8A5"
    />
    <path
      d="M6.74144 13.4697C7.10917 12.9796 7.6127 12.4196 7.7261 12.1697C8.5773 13.1702 8.04764 13.9052 8.64497 14.1472C8.9427 14.268 9.2801 14.2314 9.80044 13.8945C9.95257 14.6776 9.3543 14.8618 9.8261 15.5365C10.0576 15.8674 10.6736 15.9239 10.6736 15.9239C10.2181 16.663 9.31044 17.1281 8.14517 17.1281C7.3789 17.1281 5.88697 16.6935 5.5827 15.8637C5.7143 15.953 6.27384 15.8225 6.44697 15.5291C6.71297 15.0765 6.2211 14.168 6.74144 13.4697Z"
      fill="#FFE9A6"
    />
    <path
      d="M6.74888 13.49C7.11288 13.0027 7.61128 12.4511 7.72608 12.1998C8.57168 13.1957 8.04015 13.9288 8.63935 14.1713C8.93522 14.2911 9.27262 14.249 9.78782 13.9163C9.93948 14.6924 9.34402 14.8761 9.81302 15.5462C10.0445 15.8771 10.6558 15.9341 10.6558 15.9341C10.2022 16.6658 9.30388 17.1262 8.14562 17.1262C7.38448 17.1262 5.90048 16.6944 5.59808 15.8697C5.72875 15.9586 6.28502 15.8286 6.45675 15.5365C6.72182 15.0867 6.23415 14.1842 6.74888 13.49Z"
      fill="#FFE9A8"
    />
    <path
      d="M6.75688 13.5099C7.11668 13.0258 7.61041 12.4816 7.72615 12.2294C8.56615 13.2207 8.03321 13.9524 8.63335 14.1949C8.92735 14.3138 9.26475 14.2661 9.77481 13.9371C9.92555 14.7063 9.33288 14.8895 9.79955 15.555C10.0315 15.8855 10.6381 15.9433 10.6381 15.9433C10.1864 16.668 9.29741 17.1234 8.14661 17.1234C7.39015 17.1234 5.91455 16.694 5.61401 15.8748C5.74421 15.9627 6.29675 15.8336 6.46755 15.5435C6.73028 15.0969 6.24775 14.2004 6.75688 13.5099Z"
      fill="#FFE9A9"
    />
    <path
      d="M6.76478 13.5298C7.12084 13.0485 7.60898 12.5126 7.72658 12.2595C8.56051 13.2461 8.02664 13.9764 8.62818 14.2185C8.92031 14.336 9.25818 14.2833 9.76264 13.9584C9.91244 14.7206 9.32304 14.9034 9.78691 15.5638C10.0188 15.8943 10.6208 15.9521 10.6208 15.9521C10.1705 16.6699 9.29084 17.1207 8.14798 17.1207C7.39664 17.1207 5.92898 16.6935 5.63031 15.8799C5.75958 15.9674 6.30884 15.8383 6.47824 15.5504C6.73864 15.1066 6.26078 14.2162 6.76478 13.5298Z"
      fill="#FFEAAA"
    />
    <path
      d="M6.77224 13.5502C7.12411 13.0721 7.60758 12.5436 7.72611 12.2896C8.55444 13.2716 8.01918 14 8.62211 14.2426C8.91238 14.3592 9.25024 14.3009 9.74911 13.9797C9.89797 14.735 9.31138 14.9173 9.77291 15.5731C10.0053 15.9035 10.6022 15.9618 10.6022 15.9618C10.1537 16.6727 9.28338 17.1184 8.14751 17.1184C7.40084 17.1184 5.94111 16.694 5.64478 15.8855C5.77311 15.9725 6.31911 15.8438 6.48711 15.5578C6.74751 15.1168 6.27384 14.2324 6.77224 13.5502Z"
      fill="#FFEAAB"
    />
    <path
      d="M6.78012 13.5702C7.12825 13.0949 7.60612 12.5747 7.72652 12.3192C8.54925 13.2966 8.01212 14.0237 8.61645 14.2662C8.90485 14.3819 9.24318 14.318 9.73645 14.001C9.88485 14.7494 9.30058 14.9312 9.75932 15.5824C9.99218 15.9128 10.5844 15.9716 10.5844 15.9716C10.1378 16.6751 9.27632 17.1161 8.14838 17.1161C7.40685 17.1161 5.95505 16.694 5.66058 15.8911C5.78798 15.9776 6.33072 15.8494 6.49778 15.5653C6.75585 15.127 6.28685 14.2481 6.78012 13.5702Z"
      fill="#FFEAAD"
    />
    <path
      d="M6.78809 13.59C7.13249 13.1179 7.60522 12.6051 7.72656 12.3488C8.54369 13.3216 8.00516 14.0472 8.61089 14.2897C8.89743 14.4045 9.23576 14.3351 9.72389 14.0222C9.87136 14.7632 9.29036 14.945 9.74629 15.5916C9.97916 15.922 10.5667 15.9812 10.5667 15.9812C10.1215 16.6778 9.26983 17.1137 8.14889 17.1137C7.41249 17.1137 5.96863 16.6944 5.67603 15.8966C5.80249 15.9822 6.34149 15.8549 6.50763 15.5721C6.76429 15.1371 6.29996 14.2643 6.78809 13.59Z"
      fill="#FFEBAE"
    />
    <path
      d="M6.79552 13.6099C7.13572 13.1406 7.60332 12.6361 7.72652 12.3784C8.53805 13.3465 7.99812 14.0703 8.60526 14.3128C8.88992 14.4267 9.22825 14.3517 9.71126 14.0426C9.85779 14.7766 9.27959 14.958 9.73319 15.5999C9.96652 15.9303 10.5494 15.99 10.5494 15.99C10.1061 16.6796 9.26326 17.1109 8.15026 17.1109C7.41852 17.1109 5.98306 16.6939 5.69232 15.9016C5.81832 15.9868 6.35359 15.8595 6.51879 15.5791C6.77312 15.1473 6.31346 14.28 6.79552 13.6099Z"
      fill="#FFEBAF"
    />
    <path
      d="M6.80352 13.6302C7.13999 13.1642 7.60245 12.6672 7.72658 12.4084C8.53252 13.372 7.99118 14.0944 8.59972 14.3369C8.88252 14.4498 9.22132 14.3693 9.69872 14.0643C9.84478 14.7914 9.26939 14.9723 9.72019 15.6096C9.95399 15.94 10.5317 16.0002 10.5317 16.0002C10.0903 16.6828 9.25678 17.1091 8.15125 17.1091C7.42465 17.1091 5.99712 16.6944 5.70825 15.9077C5.83332 15.9923 6.36532 15.8655 6.52912 15.5869C6.78158 15.1575 6.32659 14.2962 6.80352 13.6302Z"
      fill="#FFEBB1"
    />
    <path
      d="M6.81143 13.6502C7.1437 13.1869 7.6015 12.6982 7.72703 12.4381C8.52737 13.397 7.98417 14.1181 8.5941 14.3606C8.87503 14.4721 9.21383 14.3865 9.6861 14.0852C9.83123 14.8053 9.25863 14.9858 9.7071 15.6184C9.9409 15.9489 10.514 16.0095 10.514 16.0095C10.0744 16.6852 9.25023 17.1063 8.1517 17.1063C7.42977 17.1063 6.01017 16.6944 5.72363 15.9123C5.84777 15.9966 6.3765 15.8702 6.53937 15.5935C6.78997 15.1672 6.33963 14.3124 6.81143 13.6502Z"
      fill="#FFECB2"
    />
    <path
      d="M6.8189 13.6701C7.14744 13.21 7.59964 12.7287 7.72704 12.4677C8.52177 13.422 7.97717 14.1416 8.5885 14.3837C8.86804 14.4943 9.20637 14.4031 9.67304 14.106C9.81723 14.8192 9.2479 14.9992 9.69357 15.6272C9.92784 15.9577 10.4962 16.0187 10.4962 16.0187C10.058 16.687 9.24324 17.1035 8.15264 17.1035C7.43584 17.1035 6.02417 16.694 5.7395 15.9174C5.8627 16.0012 6.38817 15.8748 6.54964 15.6004C6.79837 15.1774 6.3527 14.3282 6.8189 13.6701Z"
      fill="#FFECB3"
    />
    <path
      d="M6.82682 13.6904C7.15115 13.2336 7.59868 12.7602 7.72702 12.4978C8.51568 13.4475 7.97015 14.1653 8.58288 14.4078C8.86055 14.5174 9.19935 14.4207 9.66042 14.1273C9.80368 14.8335 9.23715 15.0131 9.68002 15.6365C9.91475 15.9665 10.478 16.0285 10.478 16.0285C10.0417 16.6898 9.23622 17.1012 8.15308 17.1012C7.44095 17.1012 6.03768 16.6944 5.75488 15.923C5.87715 16.0058 6.39935 15.8804 6.55988 15.6073C6.80722 15.1876 6.36575 14.3444 6.82682 13.6904Z"
      fill="#FFECB4"
    />
    <path
      d="M6.83479 13.7104C7.15539 13.2564 7.59732 12.7908 7.72752 12.5275C8.51059 13.4725 7.96366 14.1889 8.57732 14.4314C8.85312 14.5402 9.19192 14.4379 9.64786 14.1486C9.79066 14.8479 9.22646 15.027 9.66699 15.6458C9.90172 15.9758 10.4603 16.0382 10.4603 16.0382C10.0259 16.6926 9.22926 17.0994 8.15359 17.0994C7.44659 17.0994 6.05126 16.6949 5.77032 15.929C5.89166 16.0114 6.41012 15.8864 6.56972 15.6152C6.81566 15.1978 6.37932 14.3601 6.83479 13.7104Z"
      fill="#FFEDB6"
    />
    <path
      d="M6.84226 13.7302C7.15913 13.2794 7.59593 12.8217 7.72706 12.557C8.50453 13.4974 7.95573 14.2124 8.57126 14.4545C8.84519 14.5623 9.18446 14.4545 9.63479 14.1689C9.77666 14.8613 9.21573 15.0399 9.65346 15.654C9.88866 15.984 10.4426 16.047 10.4426 16.047C10.0095 16.6944 9.22273 17.0961 8.15453 17.0961C7.45219 17.0961 6.06526 16.6944 5.78619 15.9336C5.90706 16.0155 6.42179 15.891 6.57999 15.6216C6.82406 15.2079 6.39239 14.3763 6.84226 13.7302Z"
      fill="#FFEDB7"
    />
    <path
      d="M6.8502 13.7506C7.16286 13.3026 7.59453 12.8527 7.72753 12.5871C8.4994 13.5229 7.9492 14.2365 8.56613 14.4785C8.8382 14.5855 9.17746 14.4721 9.62266 14.1907C9.7636 14.8756 9.20546 15.0538 9.64086 15.6638C9.87653 15.9937 10.4253 16.0571 10.4253 16.0571C9.99413 16.6977 9.2162 17.0943 8.15546 17.0943C7.45826 17.0943 6.07926 16.6949 5.80206 15.9396C5.922 16.021 6.43346 15.8966 6.59073 15.6295C6.83293 15.2176 6.40546 14.3925 6.8502 13.7506Z"
      fill="#FFEDB8"
    />
    <path
      d="M6.85811 13.7705C7.16704 13.3257 7.59358 12.8838 7.72751 12.6172C8.49378 13.5483 7.94218 14.2601 8.56004 14.5026C8.83024 14.6086 9.16951 14.4897 9.60958 14.2124C9.75004 14.8904 9.19471 15.0682 9.62731 15.6735C9.86298 16.0035 10.4071 16.0673 10.4071 16.0673C9.97778 16.7004 9.20918 17.0924 8.15591 17.0924C7.46338 17.0924 6.09231 16.6953 5.81744 15.9456C5.93644 16.0261 6.44464 15.9026 6.60051 15.6374C6.84131 15.2278 6.41851 14.4082 6.85811 13.7705Z"
      fill="#FFEEBA"
    />
    <path
      d="M6.86556 13.7904C7.17029 13.3484 7.59216 12.9143 7.72749 12.6468C8.48816 13.5733 7.93516 14.2837 8.55442 14.5262C8.82276 14.6313 9.16249 14.5068 9.59649 14.2333C9.73602 14.9043 9.18349 15.0816 9.61329 15.6823C9.84942 16.0122 10.3889 16.0766 10.3889 16.0766C9.96096 16.7027 9.20216 17.0901 8.15636 17.0901C7.46896 17.0901 6.10582 16.6958 5.83282 15.9512C5.95089 16.0312 6.45582 15.9081 6.61076 15.6443C6.84969 15.238 6.43156 14.4244 6.86556 13.7904Z"
      fill="#FFEEBB"
    />
    <path
      d="M6.87353 13.8103C7.17453 13.3716 7.5908 12.9453 7.72753 12.6765C8.4826 13.5983 7.92773 14.3073 8.54887 14.5494C8.81533 14.6535 9.15507 14.5235 9.58393 14.2541C9.72253 14.9182 9.17327 15.095 9.60027 15.6911C9.83687 16.0211 10.3712 16.0859 10.3712 16.0859C9.94513 16.7051 9.1952 17.0874 8.15687 17.0874C7.47413 17.0874 6.1194 16.6954 5.84827 15.9563C5.9654 16.0359 6.4666 15.9128 6.6206 15.6513C6.8586 15.2482 6.44513 14.4402 6.87353 13.8103Z"
      fill="#FFEEBC"
    />
    <path
      d="M6.88147 13.8306C7.17827 13.3947 7.58987 12.9763 7.728 12.7065C8.47747 13.6233 7.9212 14.3314 8.54327 14.5734C8.80787 14.6761 9.14807 14.541 9.57134 14.2754C9.709 14.9325 9.16253 15.1089 9.5872 15.7003C9.8238 16.0298 10.3535 16.0955 10.3535 16.0955C9.92927 16.7078 9.18867 17.085 8.1578 17.085C7.4802 17.085 6.1334 16.6958 5.86414 15.9618C5.9808 16.0409 6.47827 15.9183 6.63134 15.6587C6.867 15.2583 6.4582 14.4563 6.88147 13.8306Z"
      fill="#FFEFBE"
    />
    <path
      d="M6.88892 13.8506C7.18198 13.4178 7.58798 13.0073 7.72752 12.7361C8.47138 13.6483 7.91372 14.355 8.53718 14.597C8.79992 14.6989 9.14012 14.5586 9.55825 14.2967C9.69545 14.9469 9.15178 15.1228 9.57365 15.7096C9.81072 16.0391 10.3357 16.1053 10.3357 16.1053C9.91338 16.7106 9.18165 17.0827 8.15825 17.0827C7.48578 17.0827 6.14692 16.6958 5.87952 15.9674C5.99525 16.0456 6.48945 15.9239 6.64112 15.6661C6.87538 15.2681 6.47125 14.4721 6.88892 13.8506Z"
      fill="#FFEFBF"
    />
    <path
      d="M6.89681 13.8704C7.18568 13.4405 7.58701 13.0379 7.72795 12.7657C8.46575 13.6733 7.90668 14.3786 8.53201 14.6202C8.79288 14.7211 9.13308 14.5753 9.54608 14.317C9.68235 14.9603 9.14148 15.1357 9.56101 15.7179C9.79855 16.0474 10.3184 16.1141 10.3184 16.1141C9.89748 16.712 9.17555 17.0799 8.15961 17.0799C7.49181 17.0799 6.16088 16.6954 5.89581 15.9725C6.01061 16.0502 6.50155 15.929 6.65228 15.6726C6.88375 15.2783 6.48428 14.4883 6.89681 13.8704Z"
      fill="#FFEFC0"
    />
    <path
      d="M6.90475 13.8908C7.18988 13.4641 7.58562 13.0694 7.72795 12.7958C8.46015 13.6988 7.89968 14.4027 8.52642 14.6442C8.78542 14.7442 9.12608 14.5929 9.53348 14.3388C9.66882 14.9751 9.13122 15.1501 9.54795 15.7277C9.78548 16.0572 10.3007 16.1243 10.3007 16.1243C9.88162 16.7153 9.16855 17.0781 8.16055 17.0781C7.49788 17.0781 6.17488 16.6963 5.91168 15.978C6.02555 16.0553 6.51322 15.9341 6.66255 15.68C6.89262 15.2885 6.49735 14.5045 6.90475 13.8908Z"
      fill="#FFF0C1"
    />
    <path
      d="M6.91218 13.9107C7.19358 13.4868 7.58417 13.0999 7.72791 12.8254C8.45451 13.7237 7.89264 14.4258 8.52031 14.6678C8.77744 14.7669 9.11811 14.61 9.52037 14.3596C9.65477 14.989 9.11998 15.1635 9.53391 15.7364C9.77191 16.0659 10.2824 16.1335 10.2824 16.1335C9.86524 16.7176 9.16151 17.0753 8.16051 17.0753C7.50251 17.0753 6.18791 16.6958 5.92657 15.9831C6.03951 16.0599 6.52391 15.9391 6.67231 15.6869C6.90098 15.2986 6.51038 14.5202 6.91218 13.9107Z"
      fill="#FFF0C3"
    />
    <path
      d="M6.92015 13.9306C7.19735 13.51 7.58328 13.1309 7.72795 12.8551C8.44895 13.7488 7.88522 14.4494 8.51475 14.6915C8.77002 14.7896 9.11115 14.6271 9.50735 14.3809C9.64128 15.0029 9.10928 15.1774 9.52042 15.7457C9.75888 16.0752 10.2643 16.1433 10.2643 16.1433C9.84848 16.7204 9.15455 17.073 8.16102 17.073C7.50815 17.073 6.20148 16.6963 5.94202 15.9887C6.05448 16.0646 6.53468 15.9447 6.68215 15.6944C6.90942 15.3088 6.52395 14.5364 6.92015 13.9306Z"
      fill="#FFF0C4"
    />
    <path
      d="M6.92811 13.951C7.20157 13.533 7.58191 13.1623 7.72844 12.8851C8.44384 13.7742 7.87871 14.4734 8.50964 14.715C8.76304 14.8122 9.10417 14.6442 9.49524 14.4022C9.62824 15.0172 9.09904 15.1912 9.50784 15.7549C9.74631 16.0844 10.2475 16.1529 10.2475 16.1529C9.83357 16.7231 9.14851 17.0707 8.16244 17.0707C7.51424 17.0707 6.21597 16.6963 5.95837 15.9942C6.06991 16.0696 6.54684 15.9498 6.69291 15.7017C6.91831 15.3185 6.53704 14.5521 6.92811 13.951Z"
      fill="#FFF0C5"
    />
    <path
      d="M6.93553 13.9708C7.2048 13.5562 7.58047 13.1929 7.72793 12.9147C8.43773 13.7991 7.8712 14.497 8.50307 14.7386C8.7546 14.8349 9.09573 14.6613 9.48167 14.423C9.61373 15.0311 9.0878 15.2042 9.4938 15.7637C9.73273 16.0928 10.2288 16.1622 10.2288 16.1622C9.81673 16.7249 9.141 17.0683 8.1624 17.0683C7.51933 17.0683 6.229 16.6962 5.97327 15.9997C6.08387 16.0747 6.55753 15.9553 6.70267 15.7086C6.92667 15.3287 6.55007 14.5683 6.93553 13.9708Z"
      fill="#FFF1C7"
    />
    <path
      d="M6.94349 13.9908C7.20902 13.5794 7.57909 13.2239 7.72842 12.9444C8.43262 13.8242 7.86422 14.5207 8.49796 14.7623C8.74762 14.8576 9.08922 14.6785 9.46956 14.4443C9.60116 15.0455 9.07756 15.2181 9.48122 15.773C9.72062 16.1021 10.2116 16.1719 10.2116 16.1719C9.80136 16.7278 9.13449 17.0661 8.16382 17.0661C7.52542 17.0661 6.24302 16.6967 5.98962 16.0053C6.09929 16.0798 6.56969 15.9609 6.71342 15.7161C6.93509 15.3389 6.56316 14.5845 6.94349 13.9908Z"
      fill="#FFF1C8"
    />
    <path
      d="M6.95143 14.0107C7.21276 13.602 7.57816 13.2545 7.72843 12.974C8.42703 13.8491 7.85723 14.5443 8.49236 14.7854C8.74063 14.8798 9.08176 14.6951 9.45696 14.4647C9.58763 15.0589 9.06729 15.2311 9.46816 15.7813C9.70803 16.1104 10.1943 16.1807 10.1943 16.1807C9.78549 16.7296 9.12843 17.0628 8.16476 17.0628C7.53149 17.0628 6.25703 16.6958 6.00549 16.0099C6.11423 16.0835 6.58136 15.9655 6.72416 15.7225C6.94396 15.3491 6.57623 14.6003 6.95143 14.0107Z"
      fill="#FFF1C9"
    />
    <path
      d="M6.95891 14.0311C7.21651 13.6257 7.57631 13.286 7.72845 13.0046C8.42098 13.8751 7.85025 14.5688 8.48631 14.8099C8.73271 14.9034 9.07431 14.7132 9.44391 14.4869C9.57365 15.0742 9.05611 15.2459 9.45418 15.7915C9.69405 16.1206 10.1756 16.1914 10.1756 16.1914C9.76871 16.7333 9.12098 17.0615 8.16478 17.0615C7.53618 17.0615 6.27011 16.6972 6.02045 16.0165C6.12825 16.0896 6.59211 15.9716 6.73351 15.7309C6.95238 15.3593 6.58978 14.6165 6.95891 14.0311Z"
      fill="#FFF2CA"
    />
    <path
      d="M6.96683 14.0509C7.22069 13.6483 7.57536 13.3165 7.72889 13.0342C8.41583 13.9001 7.84323 14.5924 8.48116 14.8335C8.72569 14.9261 9.06729 14.7303 9.43129 14.5077C9.56009 15.0881 9.04583 15.2593 9.44109 15.8003C9.68143 16.1294 10.1579 16.2002 10.1579 16.2002C9.75283 16.7352 9.11396 17.0587 8.16523 17.0587C7.54176 17.0587 6.28363 16.6967 6.03583 16.0215C6.14316 16.0942 6.60283 15.9766 6.74376 15.7378C6.96076 15.369 6.60283 14.6322 6.96683 14.0509Z"
      fill="#FFF2CC"
    />
    <path
      d="M6.97478 14.0709C7.22445 13.6715 7.57445 13.3475 7.72892 13.0638C8.41025 13.9251 7.83625 14.6161 8.47558 14.8572C8.71825 14.9483 9.05985 14.7475 9.41872 14.5291C9.54705 15.1025 9.03512 15.2732 9.42805 15.8096C9.66885 16.1382 10.1407 16.2099 10.1407 16.2099C9.73698 16.7375 9.10792 17.0564 8.16665 17.0564C7.54785 17.0564 6.29812 16.6968 6.05219 16.0271C6.15859 16.0993 6.61498 15.9822 6.75452 15.7448C6.96918 15.3792 6.61592 14.6485 6.97478 14.0709Z"
      fill="#FFF2CD"
    />
    <path
      d="M6.98223 14.0912C7.22817 13.6946 7.57257 13.3785 7.7289 13.0939C8.40463 13.9505 7.82923 14.6401 8.4695 14.8807C8.7103 14.971 9.05237 14.7646 9.40563 14.5498C9.53303 15.1158 9.02437 15.2866 9.4145 15.8184C9.65577 16.1469 10.1224 16.2191 10.1224 16.2191C9.72063 16.7398 9.10043 17.0536 8.1671 17.0536C7.55343 17.0536 6.31117 16.6967 6.06757 16.0322C6.17303 16.1039 6.62617 15.9868 6.76477 15.7517C6.97803 15.3893 6.62897 14.6646 6.98223 14.0912Z"
      fill="#FFF3CE"
    />
    <path
      d="M6.99015 14.1111C7.23188 13.7177 7.57161 13.4095 7.72888 13.1235C8.39901 13.9755 7.82175 14.6637 8.46388 14.9043C8.70281 14.9936 9.04488 14.7817 9.39301 14.5711C9.51948 15.1302 9.01408 15.3 9.40141 15.8276C9.64268 16.1562 10.1047 16.2288 10.1047 16.2288C9.70475 16.7425 9.09388 17.0512 8.16755 17.0512C7.55901 17.0512 6.32468 16.6967 6.08295 16.0377C6.18748 16.1085 6.63735 15.9923 6.77455 15.7591C6.98641 15.3995 6.64201 14.6803 6.99015 14.1111Z"
      fill="#FFF3D0"
    />
    <path
      d="M6.99804 14.131C7.23604 13.7404 7.57018 13.44 7.72931 13.1531C8.39384 14.0005 7.81518 14.6872 8.45871 14.9279C8.69578 15.0163 9.03784 14.7988 9.38084 14.5924C9.50684 15.1445 9.00378 15.3139 9.38831 15.8368C9.63004 16.1654 10.0874 16.2385 10.0874 16.2385C9.68884 16.7453 9.08731 17.0494 8.16891 17.0494C7.56504 17.0494 6.33911 16.6976 6.09924 16.0432C6.20284 16.1136 6.64944 15.9979 6.78524 15.7665C6.99478 15.4097 6.65551 14.6965 6.99804 14.131Z"
      fill="#FFF3D1"
    />
    <path
      d="M7.00559 14.1513C7.23939 13.764 7.56886 13.4715 7.72892 13.1832C8.38786 14.0259 7.80732 14.7113 8.45272 14.9515C8.68792 15.039 9.03046 14.8159 9.36786 14.6132C9.49292 15.1584 8.99312 15.3273 9.37486 15.8456C9.61706 16.1742 10.0693 16.2478 10.0693 16.2478C9.67259 16.7472 9.08039 17.0466 8.16899 17.0466C7.57026 17.0466 6.35226 16.6972 6.11426 16.0483C6.21739 16.1182 6.66026 16.003 6.79512 15.773C7.00372 15.4194 6.66866 14.7122 7.00559 14.1513Z"
      fill="#FFF4D2"
    />
    <path
      d="M7.0135 14.1713C7.24357 13.7867 7.56744 13.5021 7.72937 13.2128C8.38271 14.051 7.80077 14.735 8.44711 14.9752C8.68044 15.0617 9.02297 14.8331 9.35477 14.6345C9.4789 15.1728 8.9819 15.3412 9.3613 15.8549C9.60397 16.1831 10.051 16.2576 10.051 16.2576C9.65624 16.75 9.07337 17.0443 8.16944 17.0443C7.57537 17.0443 6.36577 16.6972 6.12964 16.0539C6.23184 16.1234 6.67097 16.0081 6.8049 15.7804C7.0121 15.4296 6.6817 14.7285 7.0135 14.1713Z"
      fill="#FFF4D3"
    />
    <path
      d="M7.0214 14.1912C7.24773 13.8098 7.56647 13.5331 7.72933 13.2424C8.37707 14.0759 7.79373 14.759 8.44147 14.9987C8.67293 15.0844 9.01593 14.8502 9.34213 14.6553C9.46533 15.1866 8.9716 15.3546 9.3482 15.8637C9.59087 16.1918 10.0337 16.2668 10.0337 16.2668C9.6408 16.7523 9.0668 17.0415 8.1708 17.0415C7.58187 17.0415 6.3802 16.6972 6.14594 16.059C6.2472 16.1275 6.68307 16.0132 6.81607 15.7873C7.02047 15.4398 6.69473 14.7442 7.0214 14.1912Z"
      fill="#FFF4D5"
    />
    <path
      d="M7.02885 14.211C7.25098 13.8325 7.56505 13.5636 7.72932 13.272C8.37098 14.1009 7.78625 14.7826 8.43585 15.0223C8.66545 15.107 9.00845 14.8673 9.32952 14.6766C9.45225 15.201 8.96085 15.3685 9.33512 15.8729C9.57825 16.2011 10.016 16.2765 10.016 16.2765C9.62445 16.755 9.06025 17.0392 8.17125 17.0392C7.58698 17.0392 6.39325 16.6972 6.16132 16.0645C6.26165 16.1326 6.69425 16.0187 6.82585 15.7947C7.02932 15.45 6.70778 14.7604 7.02885 14.211Z"
      fill="#FFF5D6"
    />
    <path
      d="M7.03679 14.2314C7.25519 13.8561 7.56365 13.5951 7.72932 13.3021C8.36539 14.1264 7.77925 14.8067 8.42979 15.0459C8.65752 15.1297 9.00052 14.8844 9.31645 14.6975C9.43825 15.2149 8.95012 15.3819 9.32159 15.8818C9.56519 16.2099 9.99825 16.2858 9.99825 16.2858C9.60858 16.7574 9.05325 17.0369 8.17218 17.0369C7.59305 17.0369 6.40725 16.6977 6.17719 16.0701C6.27659 16.1377 6.70592 16.0238 6.83659 15.8021C7.03772 15.4601 6.72132 14.7766 7.03679 14.2314Z"
      fill="#FFF5D7"
    />
    <path
      d="M7.04472 14.2513C7.25892 13.8788 7.56272 13.6256 7.72979 13.3317C8.36025 14.1513 7.77225 14.8303 8.42465 15.0695C8.65052 15.1524 8.99399 14.9015 9.30432 14.7187C9.42519 15.2292 8.94032 15.3953 9.30852 15.891C9.55259 16.2186 9.98052 16.2955 9.98052 16.2955C9.59272 16.7597 9.04672 17.0346 8.17312 17.0346C7.59865 17.0346 6.42125 16.6976 6.19305 16.0756C6.29199 16.1428 6.71759 16.0294 6.84685 15.8091C7.04612 15.4698 6.73439 14.7923 7.04472 14.2513Z"
      fill="#FFF5D9"
    />
    <path
      d="M7.05221 14.2712C7.26267 13.9019 7.56087 13.6566 7.72934 13.3613C8.35421 14.1763 7.76481 14.8538 8.41861 15.0931C8.64261 15.175 8.98607 14.9186 9.29127 14.7395C9.41121 15.2426 8.92914 15.4087 9.29501 15.8998C9.53954 16.2274 9.96234 16.3047 9.96234 16.3047C9.57594 16.762 9.03927 17.0318 8.17314 17.0318C7.60381 17.0318 6.43434 16.6976 6.20801 16.0807C6.30601 16.1469 6.72787 16.0344 6.85667 15.816C7.05454 15.48 6.74747 14.8085 7.05221 14.2712Z"
      fill="#FFF6DA"
    />
    <path
      d="M7.06014 14.2916C7.26641 13.9255 7.55994 13.6876 7.72981 13.3919C8.34908 14.2023 7.75828 14.8789 8.41301 15.1172C8.63514 15.1982 8.97908 14.9363 9.27821 14.7613C9.39768 15.2574 8.91841 15.4231 9.28148 15.9095C9.52601 16.2372 9.94461 16.3149 9.94461 16.3149C9.56008 16.7652 9.03274 17.0299 8.17408 17.0299C7.60941 17.0299 6.44834 16.6981 6.22388 16.0868C6.32094 16.1525 6.73954 16.0405 6.86694 15.8239C7.06341 15.4902 6.76054 14.8243 7.06014 14.2916Z"
      fill="#FFF6DB"
    />
    <path
      d="M7.06806 14.3115C7.27059 13.9482 7.55852 13.7186 7.72979 13.4215C8.34346 14.2272 7.75079 14.9025 8.40739 15.1408C8.62766 15.2209 8.97159 14.9534 9.26559 14.7826C9.38412 15.2718 8.90859 15.4361 9.26839 15.9188C9.51433 16.246 9.92686 16.3246 9.92686 16.3246C9.54419 16.768 9.02619 17.0281 8.17453 17.0281C7.61499 17.0281 6.46186 16.6986 6.23926 16.0928C6.33539 16.158 6.75072 16.046 6.87719 15.8318C7.07179 15.5004 6.77359 14.8404 7.06806 14.3115Z"
      fill="#FFF6DD"
    />
    <path
      d="M7.07553 14.3314C7.27386 13.9714 7.55713 13.7492 7.72979 13.4512C8.33786 14.2523 7.74379 14.9261 8.40179 15.1644C8.62019 15.2436 8.96413 14.9705 9.25299 14.8035C9.37059 15.2857 8.89833 15.4495 9.25533 15.9276C9.50126 16.2548 9.90913 16.3339 9.90913 16.3339C9.52833 16.7703 9.01919 17.0253 8.17546 17.0253C7.62059 17.0253 6.47539 16.6986 6.25513 16.0979C6.35033 16.1627 6.76239 16.0512 6.88746 15.8387C7.08019 15.5106 6.78713 14.8567 7.07553 14.3314Z"
      fill="#FFF7DE"
    />
    <path
      d="M7.08352 14.3518C7.27812 13.9945 7.55626 13.7807 7.72986 13.4813C8.33232 14.2777 7.73592 14.9516 8.39579 15.1885C8.61232 15.2672 8.95672 14.9881 9.23999 14.8252C9.35712 15.3005 8.88719 15.4639 9.24186 15.9373C9.48826 16.2645 9.89146 16.3441 9.89146 16.3441C9.51206 16.7731 9.01272 17.0235 8.17646 17.0235C7.62672 17.0235 6.48946 16.6991 6.27106 16.1039C6.36532 16.1678 6.77412 16.0572 6.89779 15.8462C7.08912 15.5203 6.80026 14.8724 7.08352 14.3518Z"
      fill="#FFF7DF"
    />
    <path
      d="M7.09144 14.3716C7.2823 14.0176 7.55484 13.8112 7.73031 13.5108C8.32671 14.3026 7.72937 14.9746 8.39064 15.2116C8.6053 15.2889 8.94971 15.0052 9.22784 14.8455C9.34404 15.3139 8.8769 15.4772 9.22877 15.9456C9.47517 16.2723 9.8737 16.3528 9.8737 16.3528C9.49617 16.7749 9.0057 17.0202 8.1769 17.0202C7.6323 17.0202 6.50297 16.6985 6.28644 16.1085C6.38024 16.1719 6.7853 16.0613 6.90804 15.8526C7.0975 15.5304 6.8133 14.8885 7.09144 14.3716Z"
      fill="#FFF7E0"
    />
    <path
      d="M7.09889 14.3915C7.28555 14.0403 7.55342 13.8422 7.72982 13.5405C8.32062 14.3277 7.72189 14.9983 8.38455 15.2352C8.59735 15.3116 8.94222 15.0223 9.21475 14.8668C9.33002 15.3282 8.86569 15.4912 9.21522 15.9549C9.46209 16.2816 9.85549 16.3626 9.85549 16.3626C9.47982 16.7777 8.99869 17.0184 8.17735 17.0184C7.63742 17.0184 6.51649 16.6991 6.30182 16.1141C6.39469 16.177 6.79602 16.0669 6.91782 15.86C7.10589 15.5407 6.82635 14.9043 7.09889 14.3915Z"
      fill="#FFF8E2"
    />
    <path
      d="M7.10684 14.4114C7.28978 14.0634 7.55204 13.8727 7.73031 13.5701C8.31551 14.3522 7.71538 15.0219 8.37944 15.2588C8.59038 15.3342 8.93524 15.0394 9.20264 14.8876C9.31698 15.3421 8.85544 15.5046 9.20264 15.9636C9.44951 16.2904 9.83871 16.3718 9.83871 16.3718C9.46444 16.78 8.99264 17.0156 8.17878 17.0156C7.64398 17.0156 6.53098 16.6986 6.31818 16.1192C6.41011 16.1816 6.80818 16.0719 6.92904 15.8669C7.11478 15.5508 6.83944 14.9205 7.10684 14.4114Z"
      fill="#FFF8E3"
    />
  </svg>
);
