import { ReactElement } from 'react';
import * as S from './breadcrumbsItem.styled';
import { BreadcrumbsItemModel } from './breadcrumbsItem.model';
import Link from 'next/link';

const DividerComponent = (): ReactElement => (
  <svg
    style={{ margin: '0 6px 0 6px' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={12}
    width={12}
    viewBox="0 0 12 12"
  >
    <path
      d="m4.1 10.4 3.75-3.75L4.1 2.9"
      stroke="#201926"
      stroke-width="1.2"
      stroke-linecap="round"
      opacity=".32"
    />
  </svg>
);

export const BreadcrumbsItemContainer = ({
  title,
  link,
  hasDivider,
}: BreadcrumbsItemModel): ReactElement => {
  const Item = link ? <Link href={link}>{title}</Link> : <span>{title}</span>;

  return (
    <S.BreadcrumbsItem>
      {hasDivider && <DividerComponent />}
      {Item}
    </S.BreadcrumbsItem>
  );
};
