import {
  EducationStreamsActions,
  EducationStreamsModel,
  EducationStreamsState,
} from './educationStreams.model';
import {
  REQUEST_EDUCATION_STREAMS_ERROR,
  REQUEST_EDUCATION_STREAMS_START,
  REQUEST_EDUCATION_STREAMS_SUCCESS,
} from './educationStreams.constants';

const initialState: EducationStreamsState = {
  status: {
    isLoading: false,
    isLoaded: false,
  },
  educationStreams: null,
};

export const educationStreamsReducer = (
  state: EducationStreamsState = initialState,
  { type, payload }: EducationStreamsActions
): EducationStreamsState => {
  switch (type) {
    case REQUEST_EDUCATION_STREAMS_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };
    case REQUEST_EDUCATION_STREAMS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          isLoaded: true,
          isLoading: false,
        },
        educationStreams: payload as EducationStreamsModel,
      };
    case REQUEST_EDUCATION_STREAMS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoaded: true,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
