import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPercentComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8"
        y1="16.5858"
        x2="16.5858"
        y2="8"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <circle
        cx="9.5"
        cy="8.5"
        r="1.5"
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="15.5"
        cy="15.5"
        r="1.5"
        fill="white"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle cx="12" cy="12" r="10" stroke={strokeColor} strokeWidth="2" />
    </svg>
  );
};
