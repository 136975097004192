import styled from 'styled-components';
import {
  FormAgreementStyled,
  FormAgreementTree,
  TextAreaControlComponent,
} from '@lerna-core';

export const TextAreaStyled = styled(TextAreaControlComponent)`
  textarea {
    height: 120px;
  }
`;

export const ContactUsFormContentStyled = styled.div`
  ${FormAgreementTree} {
    margin: 22px -10px -10px;

    &:not(:last-child) {
      margin: 22px -10px 12px;
    }
  }

  ${FormAgreementStyled} {
    margin: 32px 0 0;
  }
`;
