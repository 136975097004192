import styled from 'styled-components';
import { fontSettings, transition } from '@lerna-core';

export const LinksItemStyled = styled.span`
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 100px;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  margin-right: 18px;
  ${transition('color,background-color')};

  &:hover {
    color: ${({ theme }): string => theme.COLOR.WHITE};
    background-color: ${({ theme }): string => theme.COLOR.PURPLE};
  }
`;
