import React, { useContext } from 'react';
import { CourseModel, logger, Nullable } from '@lerna-core';
import { CoursePageModel } from './course.model';

export const CoursePageContext =
  React.createContext<Nullable<CoursePageModel>>(null);

export const useCoursePageContext = (): CoursePageModel => {
  const value = useContext(CoursePageContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as CoursePageModel;
  }

  return value;
};

export const CourseContext = React.createContext<Nullable<CourseModel>>(null);

export const useCourseContext = (): CourseModel => {
  const value = useContext(CourseContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as CourseModel;
  }

  return value;
};
