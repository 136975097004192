import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { FiltersResetStyledProps } from './filtersReset.model';

export const FiltersResetStyled = styled.div<FiltersResetStyledProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;

  ${({ isMobileType, theme }): string =>
    !isMobileType
      ? 'margin-bottom: 20px;'
      : `padding: 16px 0; border-bottom: 1px solid ${theme.COLOR.BLACK_08};`}

  ${({ isMobileType }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        ${!isMobileType ? 'display: none;' : ''}
      `
    )}
`;
