import styled from 'styled-components';
import { fontSettings } from '../../styles';

export const PopupWrapperStyled = styled.div`
  min-height: 230px;
`;

export const PopupContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const PopupHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  text-align: center;
  font-weight: 700;
`;

export const TextStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  text-align: center;
  white-space: pre-wrap;
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
`;
