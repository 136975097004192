import {
  UserPosition,
  UserPositionApiModel,
  UserPositionsApiModel,
  UserPositionWithOptions,
} from './userPosition.model';
import { Nullable } from '../../../models';

export const mapUserPosition = (
  userPosition: UserPositionApiModel
): Nullable<UserPosition> => userPosition.data || null;

export const userPositionsAdapter = (
  response: UserPositionsApiModel
): UserPositionWithOptions[] => {
  return response.data.data.map(userPositionAdapter);
};

export const userPositionAdapter = ({
  uuid,
  title,
  is_default,
}: UserPosition): UserPositionWithOptions => ({
  key: uuid,
  value: title,
  isDefault: is_default,
});
