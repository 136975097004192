import { ApiCourseTutorModel, CourseTutorModel } from './courseTutors.model';

export const courseTutorsAdapter = (
  tutors: ApiCourseTutorModel[] = []
): CourseTutorModel[] => {
  return tutors.map((tutor) => ({
    name: tutor.name,
    workInfo: tutor.work_info,
    photo: tutor.photo?.data
      ? {
          backgroundColor: tutor.photo?.data.background_color,
          size: tutor.photo?.data.size,
          imageResource: tutor.photo?.data.image_resource,
        }
      : null,
  }));
};
