import React, { ReactElement } from 'react';

import {
  PageWrapperContainerStyled,
  PageWrapperContentStyled,
} from '../pageWrapper.styled';
import { DefaultPageWrapperModel } from './defaultPageWrapper.model';

export const DefaultPageWrapperContainer = ({
  header,
  content,
  footer,
}: DefaultPageWrapperModel): ReactElement => (
  <PageWrapperContainerStyled>
    {header}
    <PageWrapperContentStyled>{content}</PageWrapperContentStyled>
    {footer}
  </PageWrapperContainerStyled>
);
