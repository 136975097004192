import React, { ReactElement } from 'react';
import { FilterPopupFooterStyled } from './popupFooter.styled';
import { ButtonColor, ButtonContainer } from '../../button';
import { PopupFooterModel } from './popupFooter.model';

export const PopupFooterContainer = ({
  resetButtonTitle,
  handleReset,
  applyButtonTitle,
  handleApply,
}: PopupFooterModel): ReactElement => {
  return (
    <FilterPopupFooterStyled>
      <ButtonContainer
        title={resetButtonTitle}
        color={ButtonColor.transparent}
        onClick={handleReset}
      />
      <ButtonContainer
        title={applyButtonTitle}
        color={ButtonColor.purple}
        onClick={handleApply}
      />
    </FilterPopupFooterStyled>
  );
};
