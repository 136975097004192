import {
  SubscriptionsActions,
  SubscriptionsModel,
  SubscriptionsRequestParams,
} from './subscriptions.model';
import { Constants } from './subscriptions.constants';
import { AxiosError } from 'axios';

export const requestSubscriptionsStart = (
  payload?: SubscriptionsRequestParams
): SubscriptionsActions => ({
  type: Constants.REQUEST_SUBSCRIPTIONS_START,
  payload,
});

export const requestSubscriptionsSuccess = (
  payload: SubscriptionsModel
): SubscriptionsActions => ({
  type: Constants.REQUEST_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const requestSubscriptionsError = (
  payload: AxiosError
): SubscriptionsActions => ({
  type: Constants.REQUEST_SUBSCRIPTIONS_ERROR,
  payload,
});
