import { useEffect, useRef, useState } from 'react';
import { logger } from '../services';

export type WebSocketMessageModel<Message> = {
  event: string;
  payload?: Message;
};

type WebSocketType<Message> = {
  url: string;
  createConnect: boolean;
  handleClose?: (event: CloseEvent) => void;
  handleOpen?: () => void;
  handleReconnect?: () => Promise<boolean>;
  handleMessage: (message: WebSocketMessageModel<Message>) => void;
};

const pingEvent = 'ping';
const pongEvent = 'pong';

export const useWebSocket = <Message>({
  url,
  createConnect = true,
  handleClose,
  handleOpen,
  handleMessage,
  handleReconnect,
}: WebSocketType<Message>): {
  isConnected: boolean;
  sendEvent: (message: WebSocketMessageModel<Message>) => void;
  close: (code?: number, reason?: string) => void;
} => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const socket = useRef<WebSocket>();
  const timer = useRef<NodeJS.Timeout>();
  const sendEvent = (message: WebSocketMessageModel<Message>): void => {
    socket.current?.send(JSON.stringify(message));
  };
  const keepSocketAlive = () => {
    if (socket.current?.readyState === socket.current?.OPEN) {
      sendEvent({ event: pingEvent });
    }
  };
  const close = (code?: number, reason?: string): void => {
    socket.current?.close(code, reason);
  };
  const connect = () => {
    socket.current = new WebSocket(`wss://${url}`);
    keepSocketAlive();

    socket.current.addEventListener('open', (event) => {
      logger.info(`[WebSocket] Connection opened`, {
        timeStamp: event.timeStamp,
      });
      setIsConnected(true);
      timer.current = setInterval(keepSocketAlive, 20000);
      handleOpen && handleOpen();
    });

    socket.current.addEventListener('close', (event) => {
      if (event.wasClean) {
        logger.info(`[WebSocket] Connection closed cleanly`, {
          eventCode: event.code,
          reason: event.reason,
        });
      } else {
        logger.error(
          '[WebSocket ERROR]: Connection died. Reconnect will be attempted in 1 second'
        );
        setTimeout(async () => {
          if (handleReconnect) {
            await handleReconnect().then((shouldReconnect = true) => {
              shouldReconnect && connect();
            });
          } else {
            connect();
          }
        }, 1000);
      }
      setIsConnected(false);
      clearTimeout(timer.current);
      handleClose && handleClose(event);
    });

    socket.current.addEventListener(
      'message',
      (event: MessageEvent<string>) => {
        const data = JSON.parse(event.data) as WebSocketMessageModel<Message>;
        if (data.event === pongEvent) {
          logger.info('[WebSocket] Connection still opened');
        } else {
          handleMessage(data);
        }
      }
    );
  };

  useEffect(() => {
    if (createConnect) {
      connect();
    }
  }, []);

  return { isConnected, sendEvent, close };
};
