import React, { ReactElement } from 'react';
import { HtmlWrapContainerProps } from './htmlWrap.model';
import { HtmlWrapContainerStyled } from './htmlWrap.styled';

export const HtmlWrapContainer = ({
  html,
}: HtmlWrapContainerProps): ReactElement => {
  return (
    <HtmlWrapContainerStyled
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
