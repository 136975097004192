import { UserPhotoResponseModel } from '../formUserPhoto';
import { GetUserPhotoClient, GetUserPhotoParams } from './';

export async function getUserPhotoClientProvider(
  params: GetUserPhotoParams
): Promise<UserPhotoResponseModel> {
  const response = await new GetUserPhotoClient(params).get();

  return response as unknown as Promise<UserPhotoResponseModel>;
}
