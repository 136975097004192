import { PopupStyleProps } from './popup.model';

export const BODY_CLASS_NAME = 'isPopupOpened';

export const STICKY_HEADER_DELTA = 16;
export const STICKY_HEADER_HEIGHT = 53;
export const FOOTER_MOBILE_HEIGHT = 62;

export const popupStyleSettings: PopupStyleProps = {
  paddingSticky: {
    top: '20px',
    right: '20px',
    bottom: '20px',
    left: '20px',
  },
  paddingContent: {
    top: '48px',
    right: '24px',
    bottom: '48px',
    left: '24px',
  },
};
