export enum CustomerBannerEvent {
  clickButtonSpendPoints = 'click_button_spend_points',
  clickButtonMoreDetails = 'click_button_more_details',
  clickButtonGoToLearning = 'click_button_go_to_learning',
  clickButtonMyCourses = 'click_button_my_courses',
}

export enum CustomerBannerEventPosition {
  coursesPageBannerBonusAccount = 'courses_page_banner_bonus_account',
  coursesPageBannerAccessToCourses = 'courses_page_banner_access_to_courses',
}
