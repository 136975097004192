import { AddLogo, LogoAddClient } from './logoAdd.client';

export async function logoAddClientProvider(
  blob: Blob,
  host?: string
): Promise<AddLogo> {
  const response = await new LogoAddClient(host, blob).post();

  return response as unknown as Promise<AddLogo>;
}
