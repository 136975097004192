import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { advantagesBreakpoint } from '../coursesAdvantages.styled';

export const AdvantagesListStyled = styled.div`
  display: flex;
  margin: -8px;

  ${advantagesBreakpoint} {
    justify-content: space-between;
    max-width: 930px;
    width: 100%;
    margin: -8px auto;
  }
  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      justify-content: flex-start;
      max-width: 100%;
      margin: 0 auto;
    `
  )}
`;
