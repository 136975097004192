export enum RegionsEnum {
  by = 'by',
  kz = 'kz',
  ua = 'ua',
  uz = 'uz',
  kg = 'kg',
  md = 'md',
  am = 'am',
  ru = 'ru',
  az = 'az',
  me = 'me',
  tj = 'tj',
  ge = 'ge',
  pl = 'pl',
  team = 'team',
}
