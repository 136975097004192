import styled, { css } from 'styled-components';
import {
  breakpoint,
  fontSettings,
  FormControl,
  InputStyled,
} from '@lerna-core';
import { rgba } from 'polished';

export const ContentRightStyled = styled.div`
  padding: 40px;
  background-color: #fafafa;
  flex: 0 1 50%;
  box-sizing: border-box;

  ${InputStyled} {
    background: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
    border-radius: 8px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  }

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
      background-color: unset;

      ${FormControl} {
        &:not(:last-child) {
          margin: 0 0 20px;
        }
      }
    `
  )}
`;

export const ButtonStyled = styled.div`
  button {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_18, theme.FONT)}
    padding: 15px 40px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      button {
        padding: 15px;
        width: 100%;
      }
    `
  )}
`;

export const CustomCompensationCheckboxStyled = styled.div`
  display: flex;
  align-items: flex-start;

  ${FormControl} {
    max-width: 355px;
  }

  ${breakpoint(
    'smallDown',
    css`
      ${FormControl} {
        max-width: 245px;
      }
    `
  )}
`;
