import { createSelector } from 'reselect';
import { RootState, TranslationModel } from '../../models';
import { FilterTypeEnum } from './filters.enum';
import {
  FilterModel,
  FilterOptionModel,
  FilterRangeModel,
  FilterRangeOptionModel,
  FiltersModel,
  FiltersOptions,
} from './filters.model';

import { FiltersState } from './filters.reducer';

/**
 * Getting options list from list of filters by filter slug
 * @param filters - list of filters
 * @param filterSlug - searching filter slug
 * @return {FilterOptionModel[]} - founded options
 */
const getFilterOptions = (
  filters: FilterModel[],
  filterSlug: string
): FilterOptionModel[] => {
  const searchingFilter = filters.find((filter) => filter.slug === filterSlug);
  if (searchingFilter) {
    return searchingFilter.options;
  }

  return [];
};

/**
 * Getting checked options list from list of filters by filter slug
 * @param filters - list of filters
 * @param filterSlug - searching filter slug
 * @return {FilterOptionModel[]} - founded options
 */
export const getFilterCheckedOptions = (
  filters: FilterModel[],
  filterSlug: string
): FilterOptionModel[] => {
  const searchingFilter = filters.find((filter) => filter.slug === filterSlug);
  if (searchingFilter) {
    return searchingFilter.options.filter((option) => option.checked);
  }

  return [];
};

/**
 * Getting checked value of filter option by option slug
 * @param options - list of options
 * @param optionSlug - searching option slug value
 * @return {boolean} - checked value of searching option
 */
const getFilterOptionValue = (
  options: FilterOptionModel[],
  optionSlug: string
): boolean => {
  const searchingOption = options.find((option) => option.slug === optionSlug);
  if (searchingOption) {
    return searchingOption.checked;
  }

  return false;
};

/**
 * Getting amount of checked options
 * @param options - filter options
 * @return {number} - amount of checked options
 */
export const getFilterSelectedOptionsCount = (
  options: FilterOptionModel[]
): number => {
  return options.reduce<number>((acc, option) => {
    if (option.checked) {
      acc++;
    }

    return acc;
  }, 0);
};

/**
 * Check the selected range filter
 * @param {object} options
 * @return {boolean} if at least one value is selected returns true
 */
export const checkedFilterRange = (
  options: FilterRangeOptionModel
): boolean => {
  return options.from !== '' || options.to !== '';
};

/**
 * Check the selected filter
 * @param filter
 * @return {boolean}
 */
export const checkedFilter = (filter: FiltersModel): boolean => {
  switch (filter.type) {
    case FilterTypeEnum.toggle:
    case FilterTypeEnum.tab:
    case FilterTypeEnum.checkbox: {
      return !!getFilterSelectedOptionsCount(filter.options);
    }
    case FilterTypeEnum.range: {
      return checkedFilterRange(filter.options as FilterRangeOptionModel);
    }
    default:
      return false;
  }
};

/**
 * Getting displaying value for checkbox filter depend on amount of checked options
 * @param {string} title - filter title,
 * @param {array} options - filter options,
 * @return {string} - if one checked option - return option display value
 * @return {string} - if more then 1 checked option - return count of checked values and filter title
 * @return {string} - if no one checked - return filter title
 */
export const getFilterSelectedTitle = (
  title: string,
  options: FilterOptionModel[]
): string => {
  const count = getFilterSelectedOptionsCount(options);
  if (count > 1) {
    return `${title}: ${count}`;
  } else if (count === 1) {
    const option = options.find((option) => option.checked);

    return option?.value || title;
  }

  return title;
};

/**
 * Getting displaying value for range filter depend on amount of checked options
 * @param {string} title - filter title
 * @param {object} options - filter options
 * @param {string} units - units
 * @param translations?
 * @return {string} if one value is entered, return from\to and the value
 * @return {string} if both values are entered, return from value and to value
 * @return {string} if nothing is entered, return the filter title
 */
export const getFilterRangeSelectedTitle = (
  title: string,
  options: FilterRangeOptionModel,
  units: string,
  translations?: TranslationModel
): string => {
  if (checkedFilterRange(options)) {
    let newTitle = title;
    if (options.from && options.to) {
      newTitle = `${translations?.prefix_from} ${options.from} ${(
        translations?.prefix_to || ''
      ).toLowerCase()} ${options.to} ${units}`;
    } else if (options.from) {
      newTitle = `${translations?.prefix_from} ${options.from} ${units}`;
    } else if (options.to) {
      newTitle = `${translations?.prefix_to} ${options.to} ${units}`;
    }

    return newTitle;
  }

  return title;
};

/**
 * Getting displaying value for all type filters
 * @param {string} type - filter type,
 * @param {string} title - filter title,
 * @param {object | array} options - filter options,
 * @param {string} units - units of range filter,
 * @param {object} translations - TranslationModel,
 * @return {string}
 */
export const getFilterShowTitle = (
  type: string,
  title: string,
  options: FiltersOptions,
  units: string,
  translations: TranslationModel
): string => {
  switch (type) {
    case FilterTypeEnum.range: {
      return getFilterRangeSelectedTitle(
        title,
        options as FilterRangeOptionModel,
        units,
        translations
      );
    }
    case FilterTypeEnum.checkbox: {
      return getFilterSelectedTitle(title, options as FilterOptionModel[]);
    }
    default: {
      return '';
    }
  }
};

/**
 * Getting checked value for "Select all" option
 * @param options - list of filter options
 * @return {boolean} - is checked value
 */
const getFilterAllOptionValue = (options: FilterOptionModel[]): boolean => {
  return getFilterSelectedOptionsCount(options) === options.length;
};

/**
 Getting filters count with selected options or range value
 * @param filters - all filters
 * @return {number}
 */
export const getFiltersSelectedCount = (filters: FiltersModel[]): number => {
  let count = 0;
  filters
    .filter((filter) => filter.isVisible)
    .forEach((filter): void => {
      switch (filter.type) {
        case FilterTypeEnum.toggle:
        case FilterTypeEnum.tab:
        case FilterTypeEnum.checkbox:
          if (getFilterSelectedOptionsCount(filter.options) > 0) {
            count++;
          }
          break;
        case FilterTypeEnum.range:
          if (checkedFilterRange(filter.options as FilterRangeOptionModel)) {
            count++;
          }
          break;
        default:
          break;
      }
    });

  return count;
};

type FilterOptionValueSelector = (state: RootState<FiltersState>) => boolean;
type FilterOptionsCheckedSelector = (
  state: RootState<FiltersState>
) => FilterOptionModel[];
type FilterRangeCheckedSelector = (state: RootState<FiltersState>) => boolean;
type FilterSelectedSelector = (state: RootState<FiltersState>) => boolean;
type FiltersWithCheckedOptionsSelector = (
  state: RootState<FiltersState>
) => FiltersModel[];

/**
 * Getting from Redux checked value for searching option in filter
 * @param filterSlug - searching filter slug
 * @param optionSlug - searching option slug
 * @return {boolean} - searched filter option checked value
 */
export const getFilterOptionValueSelector = (
  filterSlug: string,
  optionSlug: string
): FilterOptionValueSelector =>
  createSelector(
    (state: RootState<FiltersState>) => state.filters,
    (reducer: FiltersState) =>
      getFilterOptionValue(
        getFilterOptions(reducer.filters as FilterModel[], filterSlug),
        optionSlug
      )
  );

/**
 * Getting from Redux checked title for searching option in filter
 * @param filterSlug - searching filter slug
 * @return {string} - searched filter option checked title
 */
export const getFilterCheckedOptionsSelector = (
  filterSlug: string
): FilterOptionsCheckedSelector =>
  createSelector(
    (state: RootState<FiltersState>) => state.filters,
    (reducer: FiltersState) =>
      getFilterCheckedOptions(reducer.filters as FilterModel[], filterSlug)
  );

/**
 * Whether a filter of type range has a value
 * @param filterSlug - searching filter slug
 * @return {boolean}
 */
export const checkedFilterRangeSelector = (
  filterSlug: string
): FilterRangeCheckedSelector =>
  createSelector(
    (state: RootState<FiltersState>) => state.filters,
    (reducer: FiltersState) => {
      const filter = reducer.filters.find(
        (filter) => filter.slug === filterSlug
      ) as FilterRangeModel;
      if (filter) {
        return checkedFilterRange(filter.options);
      }

      return false;
    }
  );

export const getAllFiltersWithCheckedOptions =
  (): FiltersWithCheckedOptionsSelector =>
    createSelector(
      (state: RootState<FiltersState>) => state.filters,
      (reducer: FiltersState) => {
        const filtersWithCheckedOptions: FiltersModel[] = [];

        reducer.filters.forEach((filter) => {
          if (checkedFilter(filter)) {
            filtersWithCheckedOptions.push(filter);
          }
        });

        return filtersWithCheckedOptions;
      }
    );

/**
 * Whether the filter has a value
 * @param filterSlug - searching filter slug
 * @param filterType - searching filter type
 * @return {boolean}
 */
export const checkedFilterSelectedSelector = (
  filterSlug: string,
  filterType: string
): FilterSelectedSelector =>
  createSelector(
    (state: RootState<FiltersState>) => state.filters,
    (reducer: FiltersState) => {
      const filter = reducer.filters.find(
        (filter) => filter.slug === filterSlug
      );
      if (filter) {
        switch (filterType) {
          case FilterTypeEnum.checkbox:
            return (
              getFilterCheckedOptions(
                reducer.filters as FilterModel[],
                filterSlug
              ).length > 0
            );

          case FilterTypeEnum.range:
            return checkedFilterRange(filter.options as FilterRangeOptionModel);

          default:
            return false;
        }
      }

      return false;
    }
  );

/**
 * Getting checked value for "Select all" option from Redux
 * @param filterSlug - searching filter value
 * @return {boolean} - returning "Select all" checked value for searched filter
 */
export const getFilterAllOptionValueSelector = (
  filterSlug: string
): FilterOptionValueSelector =>
  createSelector(
    (state: RootState<FiltersState>) => state.filters,
    (reducer: FiltersState) =>
      getFilterAllOptionValue(
        getFilterOptions(reducer.filters as FilterModel[], filterSlug)
      )
  );

/**
 * Getting filters count with selected options or range value
 * @return {number}
 */
export const getSelectedFiltersCountSelector = createSelector(
  (state: RootState<FiltersState>) => state.filters,
  (reducer: FiltersState) => reducer.selectedCount
);

/**
 * Getting filters count with selected options or range value
 * @return {boolean}
 */
export const getIsOpenedFiltersSelector = createSelector(
  (state: RootState<FiltersState>) => state.filters,
  (reducer: FiltersState) => reducer.isOpened
);

/**
 * Getting filters
 * @return {object}
 */
export const getFilters = createSelector(
  (state: RootState<FiltersState>) => state.filters,
  (reducer: FiltersState) => reducer.filters
);
