import { stringService, TranslationModel } from '@lerna-core';

export const getSubscriptionAvailabilityInfo = (
  translations: TranslationModel,
  isClaimAvailable: boolean,
  isCourseClaimed: boolean,
  coursesAvailable: number,
  coursesClaimed: number,
  subscriptionTitle: string,
  claimPeriodEnd: string,
  nextClaimPeriodStart: string
): string => {
  if (isCourseClaimed) {
    return translations.subscription_course_not_available;
  } else if (isClaimAvailable) {
    return stringService.capitalizeFirstLetter(
      `${translations.you_can_add} ${coursesAvailable} ${stringService
        .pluralTranslations(coursesAvailable, [
          translations.course_label_1,
          translations.course_label_2,
          translations.course_label_many,
        ])
        .toLowerCase()} ${
        translations?.from_subscription
      } <b>«${stringService.capitalizeFirstLetter(subscriptionTitle)}»</b> ${
        translations.prefix_to
      } ${claimPeriodEnd}`
    );
  }

  return `${
    translations.subscription_not_available_1
  } ${coursesClaimed} ${stringService
    .pluralTranslations(coursesClaimed, [
      translations.course_label_1,
      translations.course_label_2,
      translations.course_label_many,
    ])
    .toLowerCase()} ${translations.by_subscription.toLowerCase()} <b>«${stringService.capitalizeFirstLetter(
    subscriptionTitle
  )}»</b> ${
    translations.subscription_not_available_2
  } <b>${nextClaimPeriodStart}</b>`;
};
