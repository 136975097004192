import { PresetSourcesModel } from '../../../services/mediaPreset';
import { ImageScales } from './imageDefault.model';

const getScaleCoefficient = (scale: string): ImageScales =>
  scale.replace('_', '') as ImageScales;

export const getSrcSetString = (imageSrc: PresetSourcesModel): string => {
  const srcSet = [];

  for (const sourceKey in imageSrc) {
    const scaleCoefficient = getScaleCoefficient(sourceKey);
    const src = imageSrc[sourceKey as keyof typeof imageSrc];

    switch (scaleCoefficient) {
      case '1x':
        break;
      case '2x':
        srcSet.push(`${src} 1x`);
        srcSet.push(`${src} 2x`);
        break;
      default:
        srcSet.push(`${src} ${scaleCoefficient}`);
        break;
    }
  }

  return srcSet.join();
};
