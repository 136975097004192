import { RequestHeader } from '../../../services';
import { OnboardingArea } from '../onboarding.model';
import { OnboardingAllClient } from './onboardingAll.client';
import { OnboardingAllResponseModel } from './onboardingAll.model';

export async function onboardingAllClientProvider(
  area: OnboardingArea,
  requestHeaders?: RequestHeader
): Promise<OnboardingAllResponseModel> {
  const response = new OnboardingAllClient(area, requestHeaders).get();

  return response as unknown as Promise<OnboardingAllResponseModel>;
}
