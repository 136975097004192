import {
  CustomerActions,
  CustomerModel,
  CustomerState,
} from './customer.model';
import { CustomerConstants } from './customer.constants';

const initialState: CustomerState = {
  customer: null,
  status: {
    isLoading: false,
    isLoaded: false,
  },
};

export function customerReducer(
  state: CustomerState = initialState,
  action: CustomerActions
): CustomerState {
  switch (action.type) {
    case CustomerConstants.RELOAD_CUSTOMER_START:
    case CustomerConstants.REQUEST_CUSTOMER_START:
      return { ...state, status: { ...state.status, isLoading: true } };

    case CustomerConstants.REQUEST_EDIT_CUSTOMER_SUCCESS:
    case CustomerConstants.REQUEST_CUSTOMER_SUCCESS:
      const customer = action.payload as CustomerModel;

      return {
        ...state,
        customer,
        status: { ...state.status, isLoading: false, isLoaded: true },
      };

    case CustomerConstants.REQUEST_CUSTOMER_ERROR:
      return { ...state, status: { ...state.status, isLoading: false } };

    case CustomerConstants.CLEAR_CUSTOMER:
      return initialState;

    default:
      return state;
  }
}
