export enum MenuApiSlugs {
  hr = 'hr',
  admin = 'admin',
  personalB2B = 'personal-b2b',
  personalB2C = 'personal-b2c',
}

export enum MenuPositionsEnum {
  header = 'account_page_block_header',
  side = 'account_page_block_sidebar',
}

export enum MenuEventsEnum {
  goToCatalog = 'go_to_main_courses_page_button_catalog',
}
