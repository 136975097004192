import {
  Nullable,
  PersonalModel,
  personalPath,
  projectSubDomains,
  RequestHeader,
  urlService,
  UserAggregateModel,
} from '@lerna-core';
import { NextRouter } from 'next/router';
import { pageUrls } from '../constants';
import { AppContext } from 'next/app';

export const NEED_REFRESH_PAGE_COOKIE_NAME = 'needRefresh';

export const setSecureHeadersProps = (): RequestHeader => {
  return {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };
};

/**
 * Redirect by user access rights
 * @param router
 * @param personal
 * @param redirectUrl
 * */
export const redirectToUserAccessRights = (
  router: NextRouter,
  personal?: PersonalModel,
  redirectUrl?: string
): void => {
  if (!!personal) {
    if (!personal.isFulfilled) {
      window.location.href = urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        personalPath.register
      );
    } else if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(pageUrls.courses, router)
      );
    }
  }
};

export const shouldRedirectToPersonal = (
  appContext: AppContext,
  user: Nullable<UserAggregateModel>
): void => {
  if (user?.career?.isListingHidden) {
    const location = urlService.getFullUrlToSubDomain(
      projectSubDomains.personal,
      pageUrls.courses
    );

    appContext?.ctx?.res?.writeHead(301, {
      Location: location,
    });

    appContext?.ctx?.res?.end();
  }
};
