export enum ConsentActions {
  agreed = 'agreed',
  refused = 'refused',
}

export enum ConsentKinds {
  advMailings = 'adv_mailings',
}

export type ConsentApiRequestModel = {
  action: ConsentActions;
  kind: ConsentKinds;
  email?: string;
  name?: string;
  phone?: string;
};

export type ConsentRequestModel = {
  agreement: boolean;
  kind: ConsentKinds;
  email?: string;
  name?: string;
  phone?: string;
};
