import {
  CheckboxColorModel,
  CheckboxCurrentColorModel,
} from './checkbox.model';

export enum CheckboxColorState {
  hover = 'hover',
  default = 'default',
  checked = 'checked',
}

export const getColor = (
  state: CheckboxColorState,
  colors: CheckboxColorModel
): CheckboxCurrentColorModel => {
  switch (state) {
    case CheckboxColorState.default: {
      return {
        label: colors.default,
        icon: colors.defaultIcon,
      };
    }

    case CheckboxColorState.hover: {
      return {
        label: colors.hover,
        icon: colors.hoverIcon,
      };
    }

    case CheckboxColorState.checked: {
      return {
        label: colors.default,
        icon: colors.checked,
      };
    }
  }
};
