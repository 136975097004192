import React, { ChangeEvent, ReactElement } from 'react';

import { SelectionHeaderProps } from './selectionHeader.model';
import { FormCheckbox } from '../../../form';
import { SelectionHeaderStyled } from './selectionHeader.styled';

export const SelectionHeaderContainer = ({
  getToggleAllRowsSelectedProps,
  toggleAllRowsSelected,
  handleCheckAll,
  isPaginating,
  isLoading,
}: SelectionHeaderProps): ReactElement => {
  const props = getToggleAllRowsSelectedProps();
  const onChange = (e: ChangeEvent): void => {
    if (isPaginating || isLoading) {
      return;
    }
    if (handleCheckAll) {
      handleCheckAll(e);
    }

    const check = !props.indeterminate && !props.checked;
    toggleAllRowsSelected(check);
  };

  return (
    <SelectionHeaderStyled>
      <FormCheckbox {...props} onChange={onChange} />
    </SelectionHeaderStyled>
  );
};
