import styled from 'styled-components';
import { fontSettings } from '../../../../styles';

export const CompanyEditLogoPopupTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  text-align: center;
`;

export const CompanyEditLogoPopupSubtitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  margin: 8px auto 32px;
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: center;
`;
