import {
  UserMenuApiLink,
  UserMenuApiModel,
  UserMenuApiResponseModel,
  UserMenuLink,
  UserMenuModel,
} from './userMenu.model';

export const mapUserMenuChildren = (data: UserMenuApiLink): UserMenuLink => ({
  title: data.title,
  link: data.link,
  icon: data.icon,
});

export const mapUserMenu = (data: UserMenuApiModel): UserMenuModel => ({
  title: data.title,
  type: data.type,
  link: data.link ? data.link : null,
  icon: data.icon ? data.icon : null,
  children: data.children.map(mapUserMenuChildren),
});

export const userMenuAdapter = ({
  data: { data },
}: UserMenuApiResponseModel): UserMenuModel[] => data.map(mapUserMenu);
