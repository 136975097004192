import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const SeoTextH1 = styled.h1`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_48, theme.FONT)};
  line-height: 56px;
  font-weight: 700;
  white-space: pre-wrap;
  margin: 32px 0;
  max-width: 750px;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)};
      text-align: center;
    `
  )}
`;
