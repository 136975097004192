import { fontSettings } from '@lerna-core';
import styled from 'styled-components';

export const WildberriesBonuses = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 20px;
  max-width: 335px;
  width: 100%;
  min-width: 0;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  background: linear-gradient(
    270deg,
    #933bc4 0%,
    #bb1c9b 19.24%,
    #f064a5 67%,
    #fe7653 100%
  );
  flex-direction: column;
  min-height: 100%;
`;

export const WildberriesBonusesHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WildberriesBonusesPoints = styled.div`
  margin-right: 16px;
`;

export const WildberriesBonusesPointsValue = styled.div`
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
`;

export const WildberriesBonusesLegend = styled.div`
  opacity: 0.48;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
`;

export const WildberriesBonusesButtons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  > * {
    &:first-child {
      flex: 1 1 auto;
    }

    &:last-child {
      max-width: 46px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    svg:last-child:first-child {
      flex: 0 0 24px;
    }
  }
`;
