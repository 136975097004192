import styled from 'styled-components';
import { fontSettings } from '../../../../styles';
import { TextStyledProps } from './hint.model';

export const HintStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  background-color: #f4f6fa;

  svg {
    flex: 0 0 16px;
  }
`;

export const TextStyled = styled.div<TextStyledProps>`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme, color }): string => color || theme.COLOR.TEXT_DARK_COLOR};
`;
