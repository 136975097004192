import { createGlobalStyle } from 'styled-components';
import { themeDefault } from '../themes';

const theme = themeDefault(false);

export default createGlobalStyle`
    body > fstrk-widget {
        z-index: ${theme?.ZINDEX?.TOP_PAGE_LAYER - 1} !important;
        bottom: 20px;
        right: 20px;
        left: 0;

    }
`;
