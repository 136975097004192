import { PopupStyleProps } from '../../../popups';
import { ManagementPopupContentModel } from './managementPopup.model';
import { staticSrc } from '../../../../services';
import { CookieTypes } from '../../cookiesNotification.model';

export const modalPaddings: PopupStyleProps = {
  paddingSticky: {
    top: '48px',
    right: '24px',
    bottom: '48px',
    left: '24px',
  },
  paddingContent: {
    top: '40px',
    right: '40px',
    bottom: '0',
    left: '40px',
  },
};

export const getPopupContent = (): ManagementPopupContentModel => {
  const cookiePolicyHTMLHref = `<a href=${staticSrc.cookiePolicyPdf} target="_blank">Политикой использования файлов куки (cookie)</a>`;
  const cookieFilesHTMLHref = `<a href=${staticSrc.cookieFilesPdf} target="_blank">списком файлов cookie</a>`;

  return {
    title: 'Настройте параметры использования файлов cookie',
    description1:
      'Вы можете настроить использование каждой категории файлов cookie, за исключением категории «технические (обязательные) cookie», без которых невозможно функционирование сайта.',
    description2:
      'Сайт запоминает ваш выбор настроек на 1 год. По окончании этого периода сайт снова запросит ваше согласие. Вы вправе изменить свой выбор настроек cookie (в т.ч. отозвать согласие) в любое время в интерфейсе сайта путем перехода по ссылке в нижней части страницы сайта «Настройки Cookie».',
    description3: `Перед тем как совершить выбор настроек параметров использования файлов cookie вы можете ознакомиться с ${cookiePolicyHTMLHref}, а также со ${cookieFilesHTMLHref}, содержащим их описание и сроки хранения.`,
    inputs: {
      [CookieTypes.technical]: {
        title: 'Технические/функциональные (обязательные) cookie-файлы',
        description:
          'Данный тип cookie-файлов требуется для обеспечения функционирования сайта и не подлежит отключению. Эти сookie-файлы не сохраняют какую-либо информацию о пользователе, которая может быть использована в маркетинговых целях или для учета посещаемых сайтов в сети Интернет.',
      },
      [CookieTypes.analytics]: {
        title: 'Аналитические cookie-файлы',
        description:
          'Данные cookie-файлы необходимы в статистических целях, позволяют подсчитывать количество и длительность посещений Сайта, анализировать как посетители используют Сайт, что помогает улучшать его производительность и сделать более удобным для использования. Запретить хранение данного типа cookie-файлов можно непосредственно на интернет-сайте либо в настройках браузера.',
        warning:
          'Отключение аналитических cookie-файлов не позволяет определять предпочтения пользователей сайта, в том числе наиболее и наименее популярные страницы и принимать меры по совершенствованию работы сайта исходя из предпочтений пользователей.',
      },
      [CookieTypes.advertising]: {
        title: 'Рекламные cookie-файлы',
        description:
          'Рекламные cookie-файлы используются для целей маркетинга и улучшения качества рекламы (предоставление более актуального и подходящего контента и персонализированного рекламного материала). Запретить хранение данного типа cookie-файлов можно непосредственно на Сайте либо в настройках браузера.',
        warning:
          'Отключение рекламных cookie-файлы не позволит принимать меры по совершенствованию работы Сайта, исходя из предпочтений пользователя, а также осуществлять подбор рекламы, иных рекламных материалов по наиболее актуальному, подходящему назначению для каждого конкретного пользователя.',
      },
    },
    submitButton: 'Сохранить настройки',
  };
};
