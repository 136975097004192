import {
  ConsentActions,
  ConsentApiRequestModel,
  ConsentRequestModel,
} from './consent.model';

export const mapAdvMailingsRequestParams = ({
  agreement,
  email,
  kind,
  name,
  phone,
}: ConsentRequestModel): ConsentApiRequestModel => {
  const requestParams: ConsentApiRequestModel = {
    action: agreement ? ConsentActions.agreed : ConsentActions.agreed,
    kind,
  };
  if (email) {
    requestParams.email = email;
  }
  if (name) {
    requestParams.name = name;
  }
  if (phone) {
    requestParams.phone = phone;
  }
  return requestParams;
};
