import React, { ReactElement } from 'react';

import {
  UserInfoPropStyled,
  UserInfoPropTitleStyled,
  UserInfoPropValueStyled,
} from '../userInfo.styled';
import { Nullable } from '../../../../models';
import Link from 'next/link';
import { InfoPropContainerProps } from './infoProp.model';
import { urlService } from '../../../../services';

export const InfoPropContainer = ({
  value,
  title,
  companyUuid,
  allowSeeCompanyLink,
}: InfoPropContainerProps): Nullable<ReactElement> => {
  const isCms = urlService.getSubdomain() === 'cms';

  if (!value) return null;

  const userInfoPropValue =
    companyUuid && isCms && allowSeeCompanyLink ? (
      <Link href={`/companies/${companyUuid}`} passHref>
        <UserInfoPropValueStyled target={'_blank'} link>
          {value}
        </UserInfoPropValueStyled>
      </Link>
    ) : (
      <UserInfoPropValueStyled>{value}</UserInfoPropValueStyled>
    );

  return (
    <UserInfoPropStyled>
      <UserInfoPropTitleStyled>{title}</UserInfoPropTitleStyled>
      {userInfoPropValue}
    </UserInfoPropStyled>
  );
};
