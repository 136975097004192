import React, { ReactElement } from 'react';
import { IconMarkComponent, useTheme } from '@lerna-core';
import { SkillsItemContainerProps } from './skillsItem.model';
import {
  SkillsItemBulletStyled,
  SkillsItemContainerStyled,
  SkillsItemDescriptionStyled,
  SkillsItemTitleStyled,
} from './skillsItem.styled';

export const SkillsItemContainer = ({
  skill,
}: SkillsItemContainerProps): ReactElement => {
  const theme = useTheme();

  return (
    <SkillsItemContainerStyled>
      <SkillsItemBulletStyled>
        <IconMarkComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.MEDIUM_PURPLE}
        />
      </SkillsItemBulletStyled>
      {skill.title && (
        <SkillsItemTitleStyled>{skill.title}</SkillsItemTitleStyled>
      )}
      <SkillsItemDescriptionStyled
        dangerouslySetInnerHTML={{
          __html: skill.description,
        }}
      />
    </SkillsItemContainerStyled>
  );
};
