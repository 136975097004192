import {
  clearAcademikaCourseItemsVendor,
  CourseItemModel,
  filterCustomerCourses,
  filterCustomerPopCourses,
} from '@lerna-core';

export const getBannerCourse = (
  courses: CourseItemModel[],
  companyId?: string
): CourseItemModel => {
  const customerPopCourses = filterCustomerPopCourses(courses, companyId);
  const customerCourses = filterCustomerCourses(courses, companyId);
  const allCourses = clearAcademikaCourseItemsVendor(
    customerPopCourses.concat(customerCourses)
  );

  return allCourses[0];
};
