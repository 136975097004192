import { TranslationModel } from '@lerna-core';
import { FormAdvantagesItem } from './advantagesItem';

export const getAdvantages = (
  translations: TranslationModel,
  date?: string,
  withHomeWorks = false
): FormAdvantagesItem[] => [
  {
    id: 1,
    isOk: true,
    text: translations?.order_form_advantages_text_1,
  },
  {
    id: 2,
    isOk: withHomeWorks,
    text: translations?.order_form_advantages_text_2,
  },
  {
    id: 3,
    isOk: false,
    text: translations?.order_form_advantages_text_3,
  },
  {
    id: 4,
    isOk: true,
    text: `${translations?.order_form_advantages_text_4} ${date}`,
  },
];
