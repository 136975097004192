import { publicConfig, useUser } from '@lerna-core';
import { useMemo } from 'react';

export const useB2BLandingLink = (): boolean => {
  const { user } = useUser();

  return useMemo(
    () => !user?.personal && publicConfig?.HAS_B2B_LANDING === 'true',
    []
  );
};
