import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { faqsAdapter } from './faq.adapter';
import { Faq, FaqsApiModel } from './faq.model';

export class FaqClient extends SecureRequestClient<null, FaqsApiModel, Faq[]> {
  protected path = `user-api/v1/faqs/${publicConfig.REGION.toUpperCase()}`;
  protected requestParameters: null;
  protected adapter = faqsAdapter;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
  }
}
