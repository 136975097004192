import * as constants from './departments.constants';
import {
  Department,
  DepartmentsActions,
  DepartmentsState,
} from './departments.model';

const initialState: DepartmentsState = {
  departments: [],
  isLoading: false,
  isLoaded: false,
};

export const departmentsReducer = (
  state: DepartmentsState = initialState,
  action: DepartmentsActions
): DepartmentsState => {
  switch (action.type) {
    case constants.REQUEST_DEPARTMENTS_START:
      return { ...state, isLoading: true };

    case constants.REQUEST_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.payload as Department[],
        isLoading: false,
        isLoaded: true,
      };

    case constants.REQUEST_DEPARTMENTS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
