import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

const ICON_COMMON_STYLES = css`
  flex: 0 0 auto;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoursesCompensationHintStyled = styled.div`
  margin-bottom: 20px;
  padding: 16px 20px 16px 16px;
  border-radius: 16px;
  background-color: ${({ theme }): string =>
    theme.COLOR.INFO_GREEN_LIGHT_COLOR};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${breakpoint(
    'mediumDown',
    css`
      align-items: flex-start;
      padding: 12px 16px 12px 12px;
    `
  )}
`;

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      padding-right: 0;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;

export const HighlightIconStyled = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 16px;

  ${ICON_COMMON_STYLES}

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `
  )}
`;

export const CloseIconStyled = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;

  ${ICON_COMMON_STYLES}

  ${breakpoint(
    'mediumDown',
    css`
      width: 24px;
      height: 24px;
      margin: -20px -24px 0 0;
    `
  )}
`;
