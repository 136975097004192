import React, { ReactElement, useMemo } from 'react';
import {
  FooterBottomInfoStyled,
  FooterBottomInfoTextStyled,
  FooterBottomLogoStyled,
  FooterBottomStyled,
  FooterBottomWrapperStyled,
  FooterCompanyStyled,
} from './footerBottom.styled';
import { FooterBottomContainerProps } from './footerBottom.model';
import { useTheme } from '../../../hooks';
import { publicConfig } from '../../../services';
import { IconLernaLogoComponent } from '../../icons';
import { FooterImagesContainer } from '../footerImages';
import { RegionsEnum } from '../../../models';
import {
  FooterSberInfoContainer,
  isNotContainsExcludedCompanies,
} from '../footerSberInfo';
import { FooterCyberParkContainer } from '../footerCyberPark';
import { useUser } from '../../user';

export const FooterBottomContainer = ({
  translations,
  footerImages,
}: FooterBottomContainerProps): ReactElement => {
  const theme = useTheme();
  const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;
  const isUzRegion = publicConfig?.REGION === RegionsEnum.uz;
  const { user } = useUser();

  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const companyText =
    translations?.company && `© ${translations.company}, ${currentYear}`;

  const footerLogoContainer = useMemo(() => {
    const logoComponent = (
      <IconLernaLogoComponent
        fillColor={theme.COLOR.WHITE}
        width={83}
        height={39}
      />
    );

    const lernaCompanyText =
      (isRuRegion && isNotContainsExcludedCompanies(user?.career?.companyId)) ||
      isUzRegion ? (
        <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
      ) : null;

    switch (publicConfig?.REGION) {
      case RegionsEnum.ua:
        return null;
      default:
        return (
          <FooterBottomLogoStyled>
            {logoComponent}
            {lernaCompanyText}
          </FooterBottomLogoStyled>
        );
    }
  }, []);

  const footerImagesContainer = useMemo(() => {
    switch (true) {
      case isRuRegion &&
        isNotContainsExcludedCompanies(user?.career?.companyId):
        return <FooterSberInfoContainer translations={translations} />;
      case isUzRegion:
        return (
          <>
            <FooterImagesContainer images={footerImages} />
            <FooterCyberParkContainer translations={translations} />
          </>
        );
      default:
        return (
          <>
            <FooterImagesContainer images={footerImages} />
            <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
          </>
        );
    }
  }, []);

  return (
    <FooterBottomStyled>
      <FooterBottomWrapperStyled>
        <FooterBottomInfoStyled>
          {footerLogoContainer}
          <FooterBottomInfoTextStyled>
            {translations?.exclusive_partner}
          </FooterBottomInfoTextStyled>
        </FooterBottomInfoStyled>
        <FooterCompanyStyled isMobile>{companyText}</FooterCompanyStyled>
      </FooterBottomWrapperStyled>

      {footerImagesContainer}
    </FooterBottomStyled>
  );
};
