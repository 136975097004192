export enum ContactUsFormEvent {
  buttonAskQuestion = 'click_button_ask question',
  formAskQuestionClose = 'form_ask question_close',
  formAskQuestionOpen = 'form_ask question_open',
  formAskQuestionSuccess = 'form_ask question_submit_success',
  formAskQuestionError = 'form_ask question_submit_error',
}

export enum ContactUsFormEventType {
  overallEvents = 'overall_events',
}
