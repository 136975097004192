import { css } from 'styled-components';
import { rgba } from 'polished';
import { transition } from '../../styles';

export const FooterHover = css`
  ${transition('color')}

  &:hover {
    color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.7)};
  }
`;
