import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLaptopComponent = ({
  width = 32,
  height = 32,
  strokeWidth = '2',
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.28 8.65v4.12M15.84 10.2v2.57M10.72 7.1v5.67M8.16 10.2v2.57"
      stroke="#713AE0"
      strokeWidth={strokeWidth}
      strokeMiterlimit="20"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 20h22M19.93 15.87H4.07c-.57 0-1.02-.46-1.02-1.03v-9.8C3.05 4.45 3.5 4 4.07 4h15.86c.57 0 1.02.46 1.02 1.03v9.8c0 .58-.45 1.04-1.02 1.04Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
