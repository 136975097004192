import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const PopupCloseStyled = styled.div`
  position: absolute;
  right: calc(100% + 8px);
  top: 8px;

  ${breakpoint(
    'mediumDown',
    css`
      position: static;
      right: unset;
      top: unset;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    `
  )}
`;

export const WrapperStyled = styled.div`
  padding: 8px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.COLOR.BLACK_4};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: max-content;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
      border-radius: unset;
      background-color: unset;

      svg {
        width: 24px;
        height: 24px;
      }
    `
  )}
`;
