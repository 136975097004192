import { Dispatch } from 'react';
import { logger, RequestHeader } from '../../services';
import {
  FooterActions,
  requestFooterError,
  requestFooterSuccess,
} from '../footer';
import {
  MenuActions,
  MenuApiSlugs,
  requestMenuError,
  requestMenuSuccess,
} from '../menu';
import {
  requestUserMenuError,
  requestUserMenuSuccess,
  UserMenuActions,
} from '../user';
import { navigationsClientProvider } from './navigations.clientProvider';

export const requestNavigations = async (
  dispatch: Dispatch<MenuActions | UserMenuActions | FooterActions>,
  type: MenuApiSlugs,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await navigationsClientProvider(type, requestHeaders)
    .then(({ menu, userMenu, footer }) => {
      dispatch(requestMenuSuccess(menu));
      dispatch(requestFooterSuccess(footer));
      dispatch(requestUserMenuSuccess(userMenu));
    })
    .catch((error) => {
      dispatch(requestMenuError(error));
      dispatch(requestFooterError(error));
      dispatch(requestUserMenuError());
      logger.error(`[ERROR]: can not get navigations. ${error}`);
    });
};
