import React, { ReactElement } from 'react';

type EmojiProps = {
  symbol: string;
  label?: string;
};

export const Emoji = ({ symbol, label = '' }: EmojiProps): ReactElement => {
  return (
    <span role="img" aria-label={label} aria-hidden={label ? false : true}>
      {symbol}
    </span>
  );
};
