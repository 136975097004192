import React, { ReactElement } from 'react';
import { SeoTextContainerProps } from './seoText.model';
import { SeoTextContainerStyled } from './seoText.styled';
import { HtmlWrapContainer } from '@features/common/htmlWrap';

export const SeoTextContainer = ({
  html,
}: SeoTextContainerProps): ReactElement => {
  return (
    <SeoTextContainerStyled>
      <HtmlWrapContainer html={html} />
    </SeoTextContainerStyled>
  );
};
