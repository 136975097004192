import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const FilterTitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
`;
