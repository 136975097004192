import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../styles';
import { FormControl } from '../../../form/formControl/formControl.styled';
import { rgba } from 'polished';
import {
  FormCheckboxContainer,
  IconContainerStyled,
} from '../../../form/formInputs/checkbox/checkbox.styled';
import { ManagementPopupInputModel } from './managementPopup.model';

export const ManagementPopupTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
  font-weight: 700;
  margin-bottom: 32px;
`;

export const ManagementPopupDescription = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 32px;

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_14, theme.FONT)}
    `
  )}
`;

export const ManagementPopupInputDescription = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.BLACK};
  opacity: 0.5;
  margin-left: 28px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ManagementPopupInput = styled.div<ManagementPopupInputModel>`
  margin-bottom: 24px;

  ${FormControl} {
    ${FormCheckboxContainer} {
      font-weight: 500;

      ${({ disabled }): ReturnType<typeof css> => {
        if (disabled) {
          return css`
            color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
          `;
        }

        return css``;
      }}
      ${IconContainerStyled} {
        ${({ disabled, checked }): ReturnType<typeof css> => {
          if (disabled && checked) {
            return css`
              opacity: 0.4;
            `;
          }

          return css``;
        }}
      }

      ${breakpoint(
        'mediumDown',
        css`
          ${({ theme }): string => fontSettings(theme.FONT.SIZE_14, theme.FONT)}
        `
      )}
    }

    &:not(:last-child) {
      margin: 0 0 8px;
    }
  }
`;

export const ManagementPopupInputWarning = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.8)};
  background: ${({ theme }): string => theme.COLOR.ERROR['100']};
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;

  svg {
    flex: 0 0 auto;
  }
`;

export const ManagementPopupFooter = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: -24px;
  padding: 32px 0 40px;
  background: ${({ theme }): string => theme.COLOR.WHITE};
`;
