import styled from 'styled-components';

import { fontSettings, transition } from '../../../../../styles';

export type OpenedProps = { opened: boolean };

export const SideMenuSectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  padding: 15px 16px;
  margin-bottom: 8px;
  user-select: none;
`;

export const SideMenuSectionBlockStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const IconArrowBlockStyled = styled.div<OpenedProps>`
  ${transition('transform')}
  transform: rotate(${({ opened }): string => (opened ? '180deg' : '0deg')});
`;

export const SideMenuSectionTitleStyled = styled.span`
  margin-left: 12px;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  max-width: 196px;
  word-break: break-word;
`;

export const SideMenuSectionItemsStyled = styled.div<OpenedProps>`
  overflow: hidden;
  max-height: ${({ opened }): string => (opened ? '999px' : '0px')};
  transition-property: max-height;
  transition: ${({ opened }): string =>
    opened ? '0.45s ease-in' : '0.20s ease-out'};
`;
