import { RequestHeader, UserAggregateModel } from '@lerna-core';
import { CourseClient } from './course.client';
import { CoursePageModel, CourseRequestParams } from './course.model';

export async function courseClientProvider(
  params: CourseRequestParams,
  headers: RequestHeader,
  user?: UserAggregateModel
): Promise<CoursePageModel> {
  const response = await new CourseClient(params, headers, user).get();

  return { ...response, params: params } as unknown as Promise<CoursePageModel>;
}
