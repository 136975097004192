import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';
import { ContentStatusContainer } from './contentStatus';
import { useGranulePayFormContext } from '../granulePayForm.context';

export const FormContentContainer = (): ReactElement => {
  const { showFormStatus } = useGranulePayFormContext();

  return (
    <FormContentStyled>
      <ContentLeftContainer />
      <ContentRightContainer />
      {showFormStatus && <ContentStatusContainer />}
    </FormContentStyled>
  );
};
