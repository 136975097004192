import React, { ReactElement } from 'react';
import { EMPTY } from '../../../../../constants';
import { CellExternalIdContainerProps } from './cellExternalId.model';
import { CellExternalIdStyled, TooltipStyled } from './cellExternalId.styled';

export const CellExternalIdContainer = ({
  course,
}: CellExternalIdContainerProps): ReactElement => {
  const externalId = course.externalId || EMPTY;

  return (
    <TooltipStyled title={externalId} cursorType={'pointer'}>
      <CellExternalIdStyled>{externalId}</CellExternalIdStyled>
    </TooltipStyled>
  );
};
