import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCopyComponent = ({
  width = 24,
  height = 24,
  fillColor = '#78747A',
  strokeColor = '#78747A',
  strokeWidth = '2',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={height}
    width={width}
  >
    <rect
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      width="13"
      height="13"
      x="8"
      y="8"
      rx="2"
    />
    <path
      fill={fillColor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C3.7909 2 2 3.7909 2 6v8c0 2.2091 1.7909 4 4 4h2v-2H6c-1.1046 0-2-.8954-2-2V6c0-1.1046.8954-2 2-2h8c1.1046 0 2 .8954 2 2v2h2V6c0-2.2091-1.7909-4-4-4H6z"
    />
  </svg>
);
