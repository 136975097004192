import {
  TooltipArrowHorizontalPlacement,
  TooltipArrowIconProps,
  TooltipArrowPlacements,
  TooltipArrowVerticalPlacement,
} from './tooltipArrow.model';
import styled, { css } from 'styled-components';

const ARROW_WIDTH = 18;
const ARROW_HEIGHT = 8;

const setArrowVerticalPosition = (
  verticalPlacement: TooltipArrowVerticalPlacement
): ReturnType<typeof css> => {
  if (verticalPlacement === TooltipArrowPlacements.bottom) {
    return css`
      bottom: -${ARROW_HEIGHT}px;
      transform: rotate(180deg);
    `;
  }

  return css`
    top: -${ARROW_HEIGHT}px;
  `;
};

const setArrowHorizontalPosition = (
  horizontalPlacement: TooltipArrowHorizontalPlacement
): ReturnType<typeof css> => {
  if (horizontalPlacement === TooltipArrowPlacements.right) {
    return css`
      right: 14px;
    `;
  }

  return css`
    left: 14px;
  `;
};

export const TooltipIconStyled = styled.div<TooltipArrowIconProps>`
  width: ${ARROW_WIDTH}px;
  height: ${ARROW_HEIGHT}px;
  display: flex;
  position: absolute;

  ${({ verticalPlacement }): ReturnType<typeof css> =>
    setArrowVerticalPosition(verticalPlacement)}

  ${({ horizontalPlacement }): ReturnType<typeof css> =>
    setArrowHorizontalPosition(horizontalPlacement)}
`;
