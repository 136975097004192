import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { rgba } from 'polished';

export const CoursesCountStyled = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      font-weight: 500;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    `
  )}
`;

export const CoursesNumberStyled = styled.span`
  color: ${({ theme }): string => rgba(theme.COLOR.TEXT_DARK_COLOR, 0.4)};
`;
