import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PricesTotalStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    `
  )}
`;

export const TitleStyled = styled.div`
  margin-bottom: 4px;
`;

export const ValueStyled = styled.div`
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)};

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)};
    `
  )}
`;
