export const staticSrc = {
  ofertaPdf: '/oferta.pdf',
  ofertaOrgPdf: '/oferta_org.pdf',
  privacyPdf: '/privacy_policy.pdf',
  cookiePolicyPdf: '/cookie_policy.pdf',
  cookieFilesPdf: '/cookie_files.pdf',
  termsPaymentPdf: '/terms_of_payment.pdf',
  termsUsePdf: '/terms_of_use.pdf',
  bonusesPdf: '/bonuses_rules.pdf',
  b2bBonusesPdf: '/bonuses_rules_b2b.pdf',
  b2bBonusesSpecialPdf: '/bonuses_rules_b2b_special.pdf',
  promotionRulesPdf: '/rules.pdf',
  userPolicyPdf: '/user_policy.pdf',
  softwarePolicyPdf: '/software_specifications.pdf',
  costSoftwarePolicyPdf: '/software_cost.pdf',
  platformUsageRules: '/pravila.pdf',
  companyInformationPdf: '/company_information.pdf',
  favicon: '/favicon.ico',
  faviconM: '/faviconM.svg',
  faviconSvg: '/favicon.svg',
  reports: '/reports/',
};
