import styled from 'styled-components';

import { fontRalewayBold, fontSettings } from '../../../styles';

export const HelpFaqStyled = styled.div`
  margin-bottom: 32px;
`;

export const HelpFaqTitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_28, props.theme.FONT)};
  ${fontRalewayBold()};
  margin-bottom: 8px;
`;
