import React, { ReactElement } from 'react';
import { PhoneControlComponent, TextControlComponent } from '../../../form';
import { useTranslations } from '../../../translations';
import { useFormPersonal } from './formPersonal.hook';
import { ButtonColor, ButtonContainer } from '../../../button';

export const FormPersonalContainer = (): ReactElement => {
  const translations = useTranslations();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    status,
    setFieldValue,
  } = useFormPersonal();

  return (
    <>
      <TextControlComponent
        type="text"
        label={translations.input_name_label}
        name="firstName"
        disabled={isSubmitting}
        touched={touched.firstName}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={translations.placeholder_name}
        errorMessage={errors.firstName}
        statusMessage={status?.firstName}
        showResetButton
        setFieldValue={setFieldValue}
      />
      <TextControlComponent
        type="text"
        label={translations.input_surname_label}
        name="lastName"
        disabled={isSubmitting}
        touched={touched.lastName}
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={translations.placeholder_last_name}
        errorMessage={errors.lastName}
        statusMessage={status?.lastName}
        showResetButton
        setFieldValue={setFieldValue}
      />
      <PhoneControlComponent
        name="phone"
        disabled={isSubmitting}
        value={values.phone}
        onChange={handleChange}
        setFieldValue={setFieldValue}
        onBlur={handleBlur}
        errorMessage={errors.phone}
        statusMessage={status?.phone}
        touched={touched.phone}
        placeholder={translations.enter_phone}
        label={translations.input_phone_label}
      />
      <ButtonContainer
        fullWidth
        title={translations.register_form_submit_button}
        borderRadius={100}
        color={ButtonColor.purple}
        disabled={isSubmitting}
        onClick={handleSubmit}
      />
    </>
  );
};
