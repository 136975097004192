import {
  breakpoint,
  fontSettings,
  lineClamping,
  TagLabelContainerStyled,
} from '@lerna-core';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { ItemInstallmentContainerStyled } from './itemInstallment';
import { ItemPriceContainerStyled } from './itemPrice';
import { ListItemBoxStyledProps, ListItemWidthsModel } from './listItem.model';

const itemWidths: ListItemWidthsModel = {
  _4: 'calc(25% - 20px)',
  _3: 'calc(100% / 3 - 20px)',
  _2: 'calc(50% - 20px)',
  _1: '100%',
};

export const ListItemStyled = styled.div`
  position: relative;
  flex: 1 1 ${itemWidths._3};
  max-width: ${itemWidths._3};
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};

  ${ItemPriceContainerStyled} {
    width: 50%;
  }

  ${ItemInstallmentContainerStyled} {
    flex: 1 1 auto;
    margin-left: 12px;
    box-sizing: border-box;
  }

  ${breakpoint(
    'xxlargeDown',
    css`
      flex: 1 1 ${itemWidths._2};
      max-width: ${itemWidths._2};
    `
  )}

  ${breakpoint(
    'mobile',
    css`
      flex: ${itemWidths._1};
      max-width: ${itemWidths._1};
      padding-right: 0;
    `
  )}
`;

export const ListItemInnerStyled = styled.div`
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const ItemInnerRowStyled = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
`;

export const ListItemLabelStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const ListItemLabelValueStyled = styled.span`
  ${lineClamping(1)}
`;

export const ListItemDurationsStyled = styled.div``;

export const ListItemDurationsBulletStyled = styled.div`
  margin: 0 4px;
`;

export const ListItemBoxStyled = styled.div<ListItemBoxStyledProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: ${({ withPrice }): string =>
    withPrice ? '-4px -4px 12px -4px' : '-4px -4px 0 -4px'};

  ${TagLabelContainerStyled} {
    margin: 4px;
  }
`;
