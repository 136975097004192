import React, { ReactElement, useMemo } from 'react';

import { InfoLabelContainerProps } from './infoLabel.model';
import {
  getBonusPointsLabel,
  getUserRole,
  UserRole,
  UserRoleLabelMap,
} from '../../../../../user';
import { hasBonusPoints } from '../../../../../bonusPoints';
import { InfoLabelStyled } from './infoLabel.styled';
import {
  TagLabelColor,
  TagLabelContainer,
  TagLabelSize,
} from '../../../../../tagLabel';

export const InfoLabelContainer = ({
  career,
  bonuses,
  translations,
}: InfoLabelContainerProps): ReactElement | null => {
  const bonusLabel = useMemo(
    () =>
      getBonusPointsLabel(bonuses?.points, [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]),
    []
  );
  const withPoints = useMemo(() => hasBonusPoints(career), [career]);
  const userRole = getUserRole(career);
  const isNotCustomer = userRole !== UserRole.customer;
  const roleLabel = isNotCustomer
    ? UserRoleLabelMap[userRole as UserRole]
    : null;

  if (!withPoints && roleLabel === null) {
    return null;
  }

  return (
    <InfoLabelStyled>
      {withPoints ? (
        <TagLabelContainer
          label={bonusLabel}
          size={TagLabelSize.small}
          color={TagLabelColor.gradient}
          borderRadius={100}
        />
      ) : (
        roleLabel
      )}
    </InfoLabelStyled>
  );
};
