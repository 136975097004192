import {
  Department,
  DepartmentApi,
  DepartmentApiModel,
  DepartmentsApiModel,
} from './departments.model';

export const mapDepartment = (
  department: DepartmentApiModel
): DepartmentApi | null => {
  return department?.data || null;
};

export const departmentAdapter = ({
  uuid,
  title,
  is_default,
}: DepartmentApi): Department => ({
  key: uuid,
  value: title,
  isDefault: is_default,
});

export const departmentsAdapter = (
  response: DepartmentsApiModel
): Department[] => {
  return response.data.data.map(departmentAdapter);
};
