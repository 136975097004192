import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const FullAccessContentStyled = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  ${breakpoint(
    'largeDown',
    css`
      flex-direction: column;
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      padding: 28px 20px;
    `
  )}
`;
