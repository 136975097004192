import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import { FiltersClearContainerStyled } from '../filtersClear/filtersClear.styled';
import { FiltersClearAllContainerStyled } from '../../../common/filters/filtersClearAll/filtersClearAll.styled';

export const FiltersColumnContainerStyled = styled.div`
  padding-bottom: 32px;
  position: relative;

  ${FiltersClearContainerStyled} {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 100%;
    overflow: hidden;
  }

  ${FiltersClearAllContainerStyled} {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  }
`;
