import { useEffect, useRef } from 'react';

export function useDidMount(): boolean {
  const mountRef = useRef(false);

  useEffect(() => {
    mountRef.current = true;
  }, []);

  return mountRef.current;
}
