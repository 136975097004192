import styled, { css, keyframes } from 'styled-components';
import { breakpoint } from '../../styles';
import { PointerStyledProps } from './onboarding.model';

const pulsation = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width: 0;
  }

  50% {
    border-width: 10px;
  }

  100% {
    width: 64px;
    height: 64px;
    border-width: 0;
  }
`;

export const OnboardingStyledWrapper = styled.div`
  overflow: hidden;
  position: fixed;
  z-index: 10000;
  inset: 0;

  // 20px для закрытия белых полос от пропавших скроллов
  min-height: calc(100vh + 20px);
  width: calc(100vw + 20px);
`;

export const OnboardingStyled = styled.div`
  border-radius: 16px;
  position: absolute;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.2s ease 0s;
  z-index: 10000;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 1000vh;
`;

export const PointerStyled = styled.div<PointerStyledProps>`
  &:after {
    content: ' ';
    z-index: 100;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
  }

  &:before {
    box-sizing: border-box;
    content: ' ';
    z-index: 100;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    border-radius: 100px;
    border: 1px solid;
    border-color: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
    opacity: 0.32;
    animation: ${pulsation} infinite cubic-bezier(0.47, 0, 0.75, 0.72) 1.3s;
  }

  ${({ showPointer }) =>
    breakpoint(
      'mediumUp',
      css`
        display: ${showPointer ? 'block' : 'none'};
      `
    )};
  ${({ showMobilePointer }) =>
    breakpoint(
      'mediumDown',
      css`
        display: ${showMobilePointer ? 'block' : 'none'};
      `
    )};
`;
