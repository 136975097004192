import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconAddUserComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      cx="12"
      cy="6.5"
      r="4"
    />
    <path
      strokeLinecap="round"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      d="M6 14v4m0 4v-4m0 0h4m-4 0H2"
    />
    <path
      strokeLinecap="round"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M18.5 18S17 14 12 14c-.9175 0-1.7509.1347-2.5.3546"
    />
  </svg>
);
