import {
  PageNotFoundLinkContainerStyled,
  PageNotFoundLinkStyled,
  PageNotFoundStyled,
  PageNotFoundTextStyled,
  PageNotFoundTitleStyled,
} from './pageNotFound.styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useTranslations } from '../translations';
import { publicConfig, urlService } from '../../services';
import { CookieNotificationContextProvider } from '../cookiesNotification';

export const PageNotFoundContainer = (): ReactElement => {
  const {
    page_not_found_title,
    error_cod_title,
    return_to_main_segment_return,
    return_to_main_segment_to_main,
    return_to_main_segment_or_open,
    return_to_main_segment_courses_list,
  } = useTranslations();

  const router = useRouter();

  const handleReturnToCourses = (): void => {
    router.push(
      urlService.normalizeUrl(publicConfig?.COURSES_APP_LINK),
      undefined,
      { scroll: false }
    );
  };

  return (
    <CookieNotificationContextProvider>
      <PageNotFoundStyled>
        <PageNotFoundTitleStyled>
          {page_not_found_title}
        </PageNotFoundTitleStyled>
        <PageNotFoundTextStyled>{error_cod_title} 404</PageNotFoundTextStyled>
        <PageNotFoundLinkContainerStyled>
          <PageNotFoundTextStyled>
            {return_to_main_segment_return}{' '}
          </PageNotFoundTextStyled>
          <PageNotFoundLinkStyled>
            <Link
              href={urlService.normalizeUrl(publicConfig?.COURSES_APP_LINK)}
            >
              {return_to_main_segment_to_main}
            </Link>
          </PageNotFoundLinkStyled>
          <PageNotFoundTextStyled>
            {' '}
            {return_to_main_segment_or_open}{' '}
          </PageNotFoundTextStyled>
          <PageNotFoundLinkStyled onClick={handleReturnToCourses}>
            {return_to_main_segment_courses_list}
          </PageNotFoundLinkStyled>
        </PageNotFoundLinkContainerStyled>
      </PageNotFoundStyled>
    </CookieNotificationContextProvider>
  );
};
