import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const FilterDescriptionContainerStyled = styled.div`
  margin: -12px 0 12px;
  font-weight: 400;
  color: ${({ theme }): string => theme.COLOR.TEXT[100]};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)}
    `
  )}
`;
