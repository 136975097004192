import styled from 'styled-components';
import { FormControlStyledProps } from './formControl.model';

export const FormControl = styled.div<FormControlStyledProps>`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin: ${({ margin }): string => (margin ? margin : '0 0 32px')};
  }
`;
