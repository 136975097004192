import { Nullable, QueryParams, UserAggregateModel } from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';
import { HeaderLinkTypes } from '@features/common/header';

export const getCoursesHeaderLinkType = (
  user: Nullable<UserAggregateModel>,
  query: ParsedUrlQuery
): HeaderLinkTypes => {
  switch (true) {
    case !!query[QueryParams.discountProgram]:
      return HeaderLinkTypes.none;
    case !!user:
      return HeaderLinkTypes.switcher;
    default:
      return HeaderLinkTypes.b2bLanding;
  }
};
