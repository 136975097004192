import React, { ReactElement } from 'react';
import { HistogramMarkersProps } from './histogramMarkers.model';
import {
  HistogramMarkersStyled,
  MarkerLabelStyled,
} from './histogramMarkers.styled';

export const HistogramMarkersContainer = ({
  maxValue,
}: HistogramMarkersProps): ReactElement => {
  return (
    <HistogramMarkersStyled>
      <MarkerLabelStyled>{maxValue}</MarkerLabelStyled>

      <MarkerLabelStyled>{maxValue / 2}</MarkerLabelStyled>

      <MarkerLabelStyled>0</MarkerLabelStyled>
    </HistogramMarkersStyled>
  );
};
