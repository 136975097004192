import React, { ReactElement } from 'react';

import { TableHeaderProps } from './tableHeader.model';
import {
  TableHeaderHeadStyled,
  TableHeaderRowStyled,
  TableHeaderStyled,
} from './tableHeader.styled';
import { HeaderGroup } from 'react-table';
import { selectionColumn } from '../tableSelection';

const renderCommonHeader = (headers: HeaderGroup[]): ReactElement[] => {
  return headers.map((column, index) => {
    const { key, ...headerProps } = column.getHeaderProps();

    const showResizer =
      index !== headers.length - 1 &&
      column.canResize &&
      column?.getResizerProps;

    return (
      <TableHeaderHeadStyled key={key} {...headerProps}>
        {column.render('Header')}

        {showResizer && (
          <div
            {...column.getResizerProps()}
            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
          />
        )}
      </TableHeaderHeadStyled>
    );
  });
};

const renderCustomHeader = (
  headers: HeaderGroup[],
  component: ReactElement | boolean
): ReactElement => {
  const filteredCols = headers.filter((col) => col?.id === selectionColumn.id);

  return (
    <>
      {renderCommonHeader(filteredCols)}
      {component}
    </>
  );
};

export const TableHeaderContainer = ({
  headerGroups,
  isSticky,
  headerComponent,
}: TableHeaderProps): ReactElement => {
  return (
    <TableHeaderStyled isSticky={isSticky}>
      {headerGroups.map((headerGroup) => {
        const { key, ...headerGroupProps } = {
          ...headerGroup.getHeaderGroupProps(),
        };

        return (
          <TableHeaderRowStyled key={key} {...headerGroupProps}>
            {headerComponent
              ? renderCustomHeader(headerGroup.headers, headerComponent)
              : renderCommonHeader(headerGroup.headers)}
          </TableHeaderRowStyled>
        );
      })}
    </TableHeaderStyled>
  );
};
