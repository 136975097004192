import {
  NavigationsResponseApiModel,
  NavigationsResponseModel,
} from './navigations.model';
import { footerAdapter } from '../footer';
import { mapUserMenu } from '../user';
import { mapMenuAdapter } from '../menu';

export const navigationsAdapter = ({
  data,
}: NavigationsResponseApiModel): NavigationsResponseModel => ({
  userMenu: data.user_menu.data.map(mapUserMenu),
  footer: footerAdapter(data.footer),
  menu: mapMenuAdapter(data.sidebar_navigation.data),
});
