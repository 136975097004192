import styled from 'styled-components';
import { FilterOptionsSearchStyledProps } from './filterOptionsSearch.model';

export const FilterOptionsSearchStyled = styled.div<FilterOptionsSearchStyledProps>`
  box-sizing: border-box;
  position: relative;

  input {
    color: ${({ color }): string | undefined => color};
    padding-left: 40px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const FilterOptionsSearchIconStyled = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const FilterOptionsSearchMagnifierStyled = styled(
  FilterOptionsSearchIconStyled
)`
  left: 12px;
  width: 20px;
  height: 20px;
`;
