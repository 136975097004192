import { BaseInputProps } from '../input.model';

export interface PasswordInputProps extends Omit<BaseInputProps, 'type'> {
  floatingPlaceholder?: boolean;
  hasError?: boolean;
}

export enum Type {
  text = 'text',
  password = 'password',
}
