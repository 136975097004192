import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';
import { redirectResponseAdapter } from './redirect.adapter';
import {
  RedirectApiRequestParamsModel,
  RedirectApiResponseModel,
  RedirectModel,
} from './redirect.model';

export class RedirectClient extends RequestClient<
  RedirectApiRequestParamsModel,
  RedirectApiResponseModel,
  RedirectModel
> {
  protected path = 'redirects-api/v1/redirects';
  protected requestParameters: RedirectApiRequestParamsModel;
  protected adapter = redirectResponseAdapter;

  constructor(url: string, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = {
      from_url: url,
    };
  }
}
