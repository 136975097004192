import { css } from 'styled-components';

export const gradientBackground0Styles = css`
  background: #f4f6fa;
  z-index: -3;
`;

export const gradientBackground1Styles = css`
  height: 900px;
  max-height: 100vh;
  background: radial-gradient(
      16.81% 38.78% at 18.92% 3.69%,
      #4d04ff 0%,
      rgba(76, 5, 255, 0) 100%
    ),
    radial-gradient(
      19.93% 45.99% at 32.36% 87.9%,
      rgba(4, 255, 41, 0.1) 0%,
      rgba(5, 255, 193, 0) 100%,
      rgba(5, 255, 43, 0) 100%
    ),
    radial-gradient(
      22.95% 52.96% at 99.03% 62.82%,
      #ff04e6 0%,
      rgba(255, 5, 230, 0) 100%
    ),
    linear-gradient(
      247.98deg,
      rgba(35, 255, 233, 0.248) 20.32%,
      rgba(91, 191, 255, 0.208) 33.65%,
      rgba(242, 166, 255, 0.176) 56.4%,
      rgba(255, 77, 68, 0.184) 82.18%
    ),
    linear-gradient(
      99.95deg,
      rgba(255, 223, 237, 0.8) 0%,
      rgba(255, 228, 246, 0.8) 23.43%,
      rgba(238, 226, 255, 0.8) 52.6%,
      rgba(230, 226, 255, 0.8) 80.21%,
      rgba(210, 233, 255, 0.8) 100%
    );
  background-blend-mode: soft-light, difference, soft-light, multiply, normal;
  opacity: 0.2;
  z-index: -2;
`;

export const gradientBackground2Styles = css`
  top: 400px;
  height: 500px;
  max-height: calc(100vh - 400px);
  background: linear-gradient(180deg, rgba(244, 246, 250, 0) 0%, #f4f6fa 100%);
  z-index: -1;
`;
