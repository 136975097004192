import { PopupStyleProps } from '../../../popups';

export const modalPaddings: PopupStyleProps = {
  paddingSticky: {
    top: '48px',
    right: '24px',
    bottom: '48px',
    left: '24px',
  },
  paddingContent: {
    top: '48px',
    right: '24px',
    bottom: '48px',
    left: '24px',
  },
};
