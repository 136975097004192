import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconReferralUsersComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      d="M21.075 9.778h-4.82c.091.355.145.726.145 1.11 0 1.33-.58 2.52-1.497 3.334h.122c1.38 0 2.545.94 2.907 2.219.04 0 .078.004.118.004 2.776 0 4.95-1.465 4.95-3.334v-1.389a1.937 1.937 0 00-1.925-1.944zM6.068 16.44c.362-1.278 1.527-2.218 2.907-2.218h.122a4.448 4.448 0 01-1.353-4.444H2.925A1.937 1.937 0 001 11.722v1.39c0 1.868 2.174 3.333 4.95 3.333.04 0 .079-.004.118-.004zM5.95 7.833c1.367 0 2.475-1.119 2.475-2.5 0-1.38-1.108-2.5-2.475-2.5s-2.475 1.12-2.475 2.5c0 1.381 1.108 2.5 2.475 2.5z"
    ></path>
    <path
      fill={fillColor}
      d="M5.95 8.667c-1.82 0-3.3-1.495-3.3-3.334C2.65 3.495 4.13 2 5.95 2c1.82 0 3.3 1.495 3.3 3.333 0 1.839-1.48 3.334-3.3 3.334zm0-5c-.91 0-1.65.747-1.65 1.666C4.3 6.253 5.04 7 5.95 7c.91 0 1.65-.748 1.65-1.667a1.66 1.66 0 00-1.65-1.666zM18.05 7.833c1.367 0 2.475-1.119 2.475-2.5 0-1.38-1.108-2.5-2.475-2.5s-2.475 1.12-2.475 2.5c0 1.381 1.108 2.5 2.475 2.5z"
    ></path>
    <path
      fill={fillColor}
      d="M18.05 8.667c-1.82 0-3.3-1.495-3.3-3.334C14.75 3.495 16.23 2 18.05 2c1.82 0 3.3 1.495 3.3 3.333 0 1.839-1.48 3.334-3.3 3.334zm0-5c-.91 0-1.65.747-1.65 1.666 0 .92.74 1.667 1.65 1.667.91 0 1.65-.748 1.65-1.667a1.66 1.66 0 00-1.65-1.666zM12 14.222c-1.82 0-3.3-1.495-3.3-3.333s1.48-3.333 3.3-3.333c1.82 0 3.3 1.495 3.3 3.333s-1.48 3.333-3.3 3.333zm0-5c-.91 0-1.65.748-1.65 1.667s.74 1.667 1.65 1.667c.91 0 1.65-.748 1.65-1.667A1.66 1.66 0 0012 9.222zM12 22c-2.776 0-4.95-1.464-4.95-3.333v-1.39c0-1.071.864-1.944 1.925-1.944h6.05c1.062 0 1.925.873 1.925 1.945v1.389C16.95 20.536 14.776 22 12 22zm-3.025-5a.277.277 0 00-.275.278v1.389c0 .786 1.411 1.666 3.3 1.666 1.889 0 3.3-.88 3.3-1.666v-1.39a.277.277 0 00-.275-.277h-6.05z"
    ></path>
  </svg>
);
