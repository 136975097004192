import styled, { css } from 'styled-components';
import { breakpoint } from '../../../styles';

export const FormPopupSubmitButtonContainerStyled = styled.div`
  width: 100%;

  > button {
    width: 100%;
  }

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
  width: 100%;`
      : ''};
`;

export const FormPopupColumn = styled.div`
  margin-bottom: 32px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;
