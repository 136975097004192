import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import { CustomCompensationFormContainerProps } from './customCompensationForm.model';
import { FormContentContainer } from './formContent';
import {
  CustomCompensationFormContextProvider,
  useCustomCompensationFormContext,
} from './customCompensationForm.context';
import { FormCommentPopupContainer } from './formCommentPopup';

const FormContent = (): ReactElement => {
  const { currentPopup, handlePopupClose } = useCustomCompensationFormContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer />
      <FormCommentPopupContainer />
    </CenteredPopupContainer>
  );
};

export const CustomCompensationFormContainer = (
  props: CustomCompensationFormContainerProps
): Nullable<ReactElement> => (
  <CustomCompensationFormContextProvider props={props}>
    <FormContent />
  </CustomCompensationFormContextProvider>
);
