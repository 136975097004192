import { Translations } from '../../translations';
import * as Yup from 'yup';
import {
  FormAgreementTreeValidationSchemaModel,
  FormAgreementTreeValuesModel,
} from './formAgreementTree.model';

export const getFormAgreementTreeInitialValues =
  (): FormAgreementTreeValuesModel => ({
    advConsent: false,
    privacy: false,
  });

export const getFormAgreementTreeValidationSchema = (
  translations: Translations
): FormAgreementTreeValidationSchemaModel => ({
  advConsent: Yup.boolean().oneOf(
    [false, true],
    translations?.input_required_error_label
  ),
  privacy: Yup.boolean()
    .required()
    .oneOf([true], translations?.input_required_error_label),
});
