import { useRouter } from 'next/router';
import { FiltersSlugEnum, useUser } from '@lerna-core';

export const useShowLegalEntitiesPriceHook = (): boolean => {
  const { query } = useRouter();
  const showLegalEntitiesPrice = Boolean(
    query[FiltersSlugEnum.legalEntitiesPrice]
  );

  const { user } = useUser();
  const hasCompany = Boolean(user?.career?.companyId);

  return showLegalEntitiesPrice && hasCompany;
};
