import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '@lerna-core';

export const BannerBonusesHeadStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
`;

export const BannerBonusesPointsStyled = styled.div`
  margin-right: 16px;
`;

export const BannerBonusesPointsValueStyled = styled.div`
  font-weight: 700;
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
`;

export const BannerBonusesLegendStyled = styled.div`
  opacity: 0.64;
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        ${fontSettings(theme.FONT.SIZE_13, theme.FONT)}
      `
    )}
`;

export const BannerBonusesButtonsStyled = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  > * {
    min-height: 54px;
    flex: 0 1 50%;
    ${lineClamping(1)}

    &:not(:last-child) {
      margin-right: 12px;
    }
    svg:last-child:first-child {
      margin-right: 4px;
    }
  }

  ${breakpoint(
    'xlargeDown',
    css`
      & > * {
        &:not(:last-child) {
          margin-right: 8px;
        }
        &:first-child {
          flex: 1 1 auto;
          max-width: 100%;
        }

        &:last-child {
          flex: 0 0 54px;
          font-size: 0;
          padding: 0;
        }

        svg:last-child:first-child {
          margin-right: 0;
        }
      }
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 12px;
    `
  )}
`;
