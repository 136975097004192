import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconInfoWithoutBgComponent = ({
  width = 24,
  height = 24,
  fillColor = '#ffffff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.067 19.187c-.664.265-1.193.465-1.589.604-.395.14-.855.209-1.379.209-.804 0-1.43-.199-1.875-.595-.446-.396-.668-.898-.668-1.508 0-.237.017-.48.05-.727s.087-.525.16-.838l.831-2.966c.074-.284.137-.554.187-.806.05-.254.074-.487.074-.699 0-.377-.077-.642-.231-.79-.156-.15-.45-.222-.888-.222a2.31 2.31 0 0 0-.661.099c-.224.07-.419.132-.578.194l.22-.913a20.603 20.603 0 0 1 1.562-.575c.497-.161.966-.24 1.409-.24.798 0 1.414.196 1.848.584.432.39.649.896.649 1.518 0 .13-.015.357-.045.681a4.6 4.6 0 0 1-.166.895l-.827 2.957a8.411 8.411 0 0 0-.182.812c-.054.303-.08.535-.08.69 0 .393.086.662.261.804.173.142.475.214.904.214.202 0 .428-.037.683-.107.254-.07.437-.134.553-.188l-.222.913Zm-.146-12.003c-.386.362-.85.543-1.393.543-.542 0-1.01-.18-1.398-.543a1.74 1.74 0 0 1-.582-1.316c0-.513.196-.954.582-1.32A1.969 1.969 0 0 1 13.528 4c.543 0 1.008.182 1.393.548.386.366.579.807.579 1.32 0 .515-.193.954-.579 1.316Z"
      fill={fillColor}
    />
  </svg>
);
