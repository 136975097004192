import React, { ReactElement } from 'react';
import { ContentRightStyled } from './contentRight.styled';
import { RightHeadContainer } from './rightHead';
import { useFormSubscriptionContext } from '../../formSubscription.context';
import { SubscriptionButtonsContainer } from '../../../../components';

export const ContentRightContainer = (): ReactElement => {
  const {
    translations,
    course,
    isMobile,
    subscriptionAvailability,
    handleFormSubmit,
    isSubscriptionButtonDisabled,
  } = useFormSubscriptionContext();

  return (
    <ContentRightStyled>
      <RightHeadContainer
        course={course}
        isMobile={isMobile}
        translations={translations}
      />
      <SubscriptionButtonsContainer
        subscriptionAvailability={subscriptionAvailability}
        translations={translations}
        handleSignUp={handleFormSubmit}
        isSignUpDisabled={isSubscriptionButtonDisabled}
      />
    </ContentRightStyled>
  );
};
