import styled from 'styled-components';

import { LanguageItemStyled } from '../../switchLanguage';
import { LanguageItemsStyled } from '../../switchLanguage';

export const SwitchLanguagePopupStyled = styled.div`
  ${LanguageItemsStyled} {
    margin: 0 -20px;
  }

  ${LanguageItemStyled} {
    padding: 16px 0;
    margin: 0 20px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
`;
