import React, { ReactElement } from 'react';
import {
  CommonEventPositions,
  CommonEventTypes,
  IconInfoCircledComponent,
  pushDataLayer,
  TagLabelColor,
  TagLabelContainer,
  TagLabelIconPosition,
  useTheme,
} from '@lerna-core';
import { TooltipContainer } from '@features/common/tooltip';
import { SubscriptionTagContainerProps } from './subscriptionTag.model';
import { SubscriptionTagContainerStyled } from './subscriptionTag.styled';
import { getCourseEventData } from '@features/course';
import { ListItemTagsEvents } from '../listItemTags.analytics';

export const SubscriptionTagContainer = ({
  translations,
  size,
  course,
  courseIndex,
  borderRadius = 100,
}: SubscriptionTagContainerProps): ReactElement => {
  const theme = useTheme();

  const handleTooltipOpen = (): void => {
    pushDataLayer(
      getCourseEventData(
        ListItemTagsEvents.clickIconInfoFreeSubscription,
        CommonEventTypes.coursesEvents,
        CommonEventPositions.coursesPageList,
        course,
        courseIndex
      )
    );
  };

  return (
    <TooltipContainer
      content={translations?.course_free_access_by_subscription}
      onOpen={handleTooltipOpen}
    >
      <SubscriptionTagContainerStyled>
        <TagLabelContainer
          noWrap={false}
          label={translations?.courses_subscription_free}
          color={TagLabelColor.subscription}
          size={size}
          iconPosition={TagLabelIconPosition.right}
          borderRadius={borderRadius}
          icon={
            <IconInfoCircledComponent
              fillColor={theme.COLOR.SUBSCRIPTION}
              strokeColor={theme.COLOR.SUBSCRIPTION}
            />
          }
        />
      </SubscriptionTagContainerStyled>
    </TooltipContainer>
  );
};
