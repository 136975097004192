import styled from 'styled-components';
import { TagLabelContainerStyled } from '@lerna-core';
import { GranuleTagStyledProps } from './granuleTag.model';

export const GranuleTagContainerStyled = styled.div<GranuleTagStyledProps>`
  ${TagLabelContainerStyled} {
    display: inline-block;
    vertical-align: top;
    font-weight: ${({ thin }): string => (thin ? '400' : '700')};
    ${({ thin }): string => (thin ? 'padding: 2px 4px;' : '')}
  }
`;
