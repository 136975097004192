import { AxiosError } from 'axios';
import { Dispatch } from 'react';

import { logger, RequestHeader } from '../../services';
import { menuClientProvider } from './menu.clientProvider';
import * as constants from './menu.constants';
import { MenuApiSlugs } from './menu.enum';
import { MenuModel } from './menu.model';

export type MenuActions = {
  type: string;
  payload?: MenuModel | AxiosError;
};

export const requestMenu = async (
  dispatch: Dispatch<MenuActions>,
  type: MenuApiSlugs,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await menuClientProvider(type, requestHeaders)
    .then((response) => {
      dispatch(requestMenuSuccess(response));
    })
    .catch((error) => {
      dispatch(requestMenuError(error));
      logger.error(`[ERROR]: can not get menu. ${error}`);
    });
};

export const requestMenuSuccess = (payload: MenuModel): MenuActions => ({
  type: constants.REQUEST_MENU_SUCCESS,
  payload,
});

export const requestMenuError = (payload: AxiosError): MenuActions => ({
  type: constants.REQUEST_MENU_ERROR,
  payload,
});
