import React, { ReactElement, useMemo } from 'react';

import { B2bLandingLinkProps } from './b2bLandingLink.model';
import { B2bLandingLinkContainerStyled } from './b2bLandingLink.styled';
import {
  CommonEventTypes,
  getSimpleEvent,
  pushDataLayer,
  urlService,
} from '@lerna-core';
import { B2bLandingLinkEvents } from './b2bLandingLink.analytics';
import { getB2BLandingLink } from './b2bLandingLink.helpers';
import { HeaderEventPositions } from '@features/common/header';

export const B2bLandingLinkContainer = ({
  children,
}: B2bLandingLinkProps): ReactElement => {
  const landingUrl = useMemo(() => getB2BLandingLink(), []);

  const handleClick = (): void => {
    pushDataLayer(
      getSimpleEvent(
        B2bLandingLinkEvents.clickButtonBusinessInformation,
        CommonEventTypes.overallEvents,
        HeaderEventPositions.coursesPageBlockHeader
      )
    );
  };

  return (
    <B2bLandingLinkContainerStyled
      href={landingUrl}
      {...urlService.setTargetBlankOptions()}
      onClick={handleClick}
    >
      {children}
    </B2bLandingLinkContainerStyled>
  );
};
