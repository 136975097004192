import React, { ReactElement } from 'react';
import {
  ButtonStyled,
  ContentRightStyled,
  TitleStyled,
} from './contentRight.styled';
import { useGuestOrderFormContext } from '../../guestOrderForm.context';
import {
  ButtonColor,
  ButtonContainer,
  CheckboxControlComponent,
  FormAgreementContainer,
  PhoneControlComponent,
  TextControlComponent,
} from '@lerna-core';
import { FormPromoCode } from '@features/common/forms';

export const ContentRightContainer = (): ReactElement => {
  const {
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setTouched,
    setErrors,
    errors,
    touched,
    translations,
    discountProgramQuery,
    promoCodeQuery,
    handleFormSubmit,
    isFreeCourse,
    setIsApplyingPromoCode,
  } = useGuestOrderFormContext();

  const showCompanyInputs = !discountProgramQuery && !isFreeCourse;

  return (
    <ContentRightStyled>
      <TitleStyled>{translations?.fill_contacts_data}</TitleStyled>
      <TextControlComponent
        type="text"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        name="name"
        placeholder={translations?.input_name_label}
        disabled={isSubmitting}
        touched={touched.name}
        errorMessage={errors.name}
        showResetButton
        setFieldValue={setFieldValue}
        floatingPlaceholder={false}
        margin="0 0 24px"
      />
      <PhoneControlComponent
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        name="phone"
        disabled={isSubmitting}
        touched={touched.phone}
        errorMessage={errors.phone}
        setFieldValue={setFieldValue}
        margin="0 0 24px"
      />
      <TextControlComponent
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        name="email"
        placeholder={translations?.input_email_label}
        disabled={isSubmitting}
        touched={touched.email}
        errorMessage={errors.email}
        showResetButton
        setFieldValue={setFieldValue}
        floatingPlaceholder={false}
        margin="0 0 24px"
      />
      {!isFreeCourse && (
        <FormPromoCode
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          disabled={isSubmitting || !!promoCodeQuery}
          onChange={handleChange}
          translations={translations}
          setFieldValue={setFieldValue}
          setIsApplyingPromoCode={setIsApplyingPromoCode}
        />
      )}
      {showCompanyInputs && (
        <>
          <CheckboxControlComponent
            name="withCompany"
            checked={values.withCompany}
            onChange={handleChange}
            children={translations?.i_am_legal_entity}
            disabled={isSubmitting}
            margin="0 0 20px"
          />
          {values.withCompany && (
            <TextControlComponent
              type="text"
              name="company"
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translations?.company_name}
              floatingPlaceholder={false}
              disabled={isSubmitting}
              touched={touched.company}
              errorMessage={errors.company}
              showResetButton
              setFieldValue={setFieldValue}
              margin="0 0 24px"
            />
          )}
        </>
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleFormSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.send_request}
          disabled={isSubmitting}
        />
      </ButtonStyled>
      <FormAgreementContainer
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
        setTouched={setTouched}
        handleChange={handleChange}
        withOferta
        isSubmitting={isSubmitting}
      />
    </ContentRightStyled>
  );
};
