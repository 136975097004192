import { publicConfig } from '../../../../../../services';
import { RequestClient } from '../../../../../../services/request/request.service';
import { addUserPhotoAdapter } from './addUserPhoto.adapter';

export type AddUserPhotoApiModel = {
  data: {
    response: AddUserPhoto;
    success: string;
  };
};

export type AddUserPhoto = {
  filename: string;
  url: string;
};

export class AddUserPhotoClient extends RequestClient<
  FormData,
  AddUserPhotoApiModel,
  AddUserPhoto
> {
  protected path = 'ms-uploader-api/v1/image/add';
  protected requestParameters: FormData;
  protected adapter = addUserPhotoAdapter;

  constructor(files: FileList) {
    super(publicConfig.API_GATEWAY_URL, '', {
      'Content-Type': `multipart/form-data`,
    });
    this.requestParameters = new FormData();
    this.requestParameters.append('image', files[0]);
  }
}
