import styled, { css } from 'styled-components';
import { breakpoint, ButtonContainer } from '@lerna-core';
import { ListItemButtonsContainerStyledProps } from './listItemButtons.model';

export const ListItemButtonsContainerStyled = styled.div<ListItemButtonsContainerStyledProps>`
  margin: 14px -6px -6px -6px;
  display: flex;
  justify-content: space-between;
  flex-grow 1;

  ${breakpoint(
    'largeDown',
    css`
      ${({ theme }): string =>
        !theme.IS_MOBILE
          ? `
        flex-wrap: wrap;
      `
          : ''}
    `
  )}
`;

const BUTTON_COMMON_STYLES = css`
  flex: 1 1 calc(50% - 16px);
  position: relative;
  margin: 6px;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_MODULE};
`;

export const ListItemAboutButtonStyled = styled(ButtonContainer)`
  ${BUTTON_COMMON_STYLES}

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
    padding: 9px 8px;
    max-width: unset; 
    flex: unset;
    order: 2;
    
    svg {
      flex: 0 0 24px;
    }
  `
      : ''}
`;

export const ListItemSignUpButtonStyled = styled(ButtonContainer)`
  ${BUTTON_COMMON_STYLES}

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
    order: 1;
  `
      : ''}
`;
