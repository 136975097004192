import React, { ReactElement } from 'react';
import { AdvantagesImageStyled } from './advantagesImage.styled';
import { AdvantagesImageContainerProps } from './advantagesImage.model';
import { ImageContainer } from '@lerna-core';

export const AdvantagesImageContainer = ({
  image,
  withBanners,
}: AdvantagesImageContainerProps): ReactElement => (
  <AdvantagesImageStyled withBanners={withBanners}>
    <ImageContainer
      imageResource={image.imageResource}
      preset="advantagesImage"
    />
  </AdvantagesImageStyled>
);
