import {
  B2BOrderApiResponse,
  B2BOrderResponse,
  B2CFreeOrderApiResponse,
  B2CFreeOrderResponse,
  B2COrderApiResponse,
  B2COrderResponse,
} from './form.model';

export const mapB2COrder = ({
  data,
}: B2COrderApiResponse): B2COrderResponse => ({
  createdDate: data.data.created_date,
  email: data.data.email,
  phone: data.data.phone,
  userName: data.data.user_name,
  id: data.data.id,
});

export const mapB2BOrder = ({
  data: { data },
}: B2BOrderApiResponse): B2BOrderResponse => ({
  order: {
    uuid: data.order.uuid,
    createdAt: data.order.created_at,
    updatedAt: data.order.updated_at,
    status: data.order.status,
  },
  payment: data?.payment
    ? {
        url: data.payment.url,
      }
    : null,
});

export const mapB2CFreeOrder = ({
  data,
}: B2CFreeOrderApiResponse): B2CFreeOrderResponse => ({
  order: {
    id: data.order.id,
    dealId: data.order.deal_id,
  },
});
