import { Constants } from './subscriptions.constants';
import {
  SubscriptionsActions,
  SubscriptionsModel,
  SubscriptionsRequestParams,
  SubscriptionsState,
} from './subscriptions.model';

export const SUBSCRIPTIONS_PER_PAGE_LIMIT = 10;

export const initialSubscriptionsMeta = {
  total: 0,
  count: 0,
  perPage: 50,
  currentPage: 1,
  totalPages: 1,
  links: {},
};

const initialState: SubscriptionsState = {
  subscriptions: {
    data: [],
    meta: initialSubscriptionsMeta,
  },
  status: {
    isLoading: false,
    isLoaded: false,
  },
  requestParams: {
    search: '',
    limit: SUBSCRIPTIONS_PER_PAGE_LIMIT,
  },
};

export const subscriptionsReducer = (
  state: SubscriptionsState = initialState,
  action: SubscriptionsActions
): SubscriptionsState => {
  switch (action.type) {
    case Constants.REQUEST_SUBSCRIPTIONS_START:
      const requestParams = action.payload as SubscriptionsRequestParams;

      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
          isLoaded: false,
        },
        requestParams: {
          ...state.requestParams,
          ...requestParams,
        },
      };

    case Constants.RELOAD_SUBSCRIPTIONS_START:
      return initialState;

    case Constants.REQUEST_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
        subscriptions: action.payload as SubscriptionsModel,
      };

    case Constants.REQUEST_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
