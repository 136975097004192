import { AlternateLinkMetaModel, RegionsEnum } from '@lerna-core';

export const COURSES_PAGE_AGGREGATE_REQUIRED_KEYS = [
  'courses',
  'currencies',
  'filters',
  'footer',
  'statics',
  'vendors',
  'companies',
];

export const COURSES_PAGE_ALTERNATE_LINKS: AlternateLinkMetaModel[] = [
  {
    path: '',
    hreflang: 'ru-kg',
    topLevelDomain: RegionsEnum.kg,
  },
  {
    path: '',
    hreflang: 'ru-by',
    topLevelDomain: RegionsEnum.by,
  },
  {
    path: '',
    hreflang: 'ru-uz',
    topLevelDomain: RegionsEnum.uz,
  },
  {
    path: '',
    hreflang: 'ru-kz',
    topLevelDomain: RegionsEnum.kz,
  },
  {
    path: '',
    hreflang: 'ru-ru',
    topLevelDomain: RegionsEnum.ru,
  },
  {
    path: '',
    hreflang: 'ru-am',
    topLevelDomain: RegionsEnum.am,
  },
  {
    path: '',
    hreflang: 'ru-md',
    topLevelDomain: RegionsEnum.md,
  },
];
