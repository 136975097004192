import React, { ChangeEvent, FocusEvent, ReactElement } from 'react';
import { FormControl, FormControlError } from '../../../formControl';
import { PhoneControlProps } from './phoneControl.model';
import { PhoneInputContainer } from '../../../../inputs';

export const PhoneControlComponent = ({
  value,
  name,
  placeholder,
  disabled,
  touched,
  errorMessage,
  statusMessage,
  onChange,
  onBlur,
  onlyCountries,
  preferredCountries,
  color,
  bordered = true,
  height,
  borderRadius,
  setFieldValue,
  country,
  margin,
}: PhoneControlProps): ReactElement => {
  const handleValueChange = (value: string, e: ChangeEvent): void => {
    onChange(e);
    setFieldValue(name, value);
  };

  const handleBlur = (_value: string, e: FocusEvent): void => {
    onBlur && onBlur(e);
  };

  const hasError = Boolean(errorMessage || statusMessage) && touched;

  return (
    <FormControl margin={margin}>
      <PhoneInputContainer
        value={value}
        name={name}
        onChange={handleValueChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        onlyCountries={onlyCountries}
        preferredCountries={preferredCountries}
        color={color}
        disabled={disabled}
        bordered={bordered}
        height={height}
        borderRadius={borderRadius}
        country={country}
        hasError={hasError}
      />
      {errorMessage && touched && (
        <FormControlError>{errorMessage}</FormControlError>
      )}
      {statusMessage && touched && (
        <FormControlError>{statusMessage}</FormControlError>
      )}
    </FormControl>
  );
};
