import {
  FilterRangeOptionModel,
  FiltersModel,
  FilterTypeEnum,
} from '@lerna-core';
import { ResetItemFilterOption } from './filtersReset.model';
import { FILTERS_RESET_ITEMS_LIMIT } from './filtersReset.constants';

const mapFilterOptions = (filter: FiltersModel): ResetItemFilterOption[] => {
  const resetItems: ResetItemFilterOption[] = [];

  switch (filter.type) {
    case FilterTypeEnum.checkbox:
      filter.options
        .filter((option) => option.checked)
        .forEach((option) => {
          resetItems.push({
            filter,
            currentOption: option,
            itemValue: option.value,
            itemSlug: option.slug,
          });
        });
      break;
    case FilterTypeEnum.range:
      resetItems.push({
        filter,
        currentOption: filter.options as FilterRangeOptionModel,
        itemValue: filter.title,
        itemSlug: filter.slug,
      });
      break;
    case FilterTypeEnum.toggle:
      filter.options.forEach((option) => {
        resetItems.push({
          filter,
          currentOption: option,
          itemValue: filter.title,
          itemSlug: filter.slug,
        });
      });
      break;
    case FilterTypeEnum.tab:
      filter.options
        .filter((option) => option.checked)
        .forEach((option) => {
          resetItems.push({
            filter,
            currentOption: option,
            itemValue: option.value,
            itemSlug: filter.slug,
          });
        });
      break;
    default:
      break;
  }

  return resetItems;
};

export const getResetItems = (
  filters: FiltersModel[]
): ResetItemFilterOption[] => {
  const result: ResetItemFilterOption[] = [];

  filters.forEach((filter) => {
    const resetItems = mapFilterOptions(filter);

    result.push(...resetItems);
  });

  return result;
};

export const getResetItemsToRender = (
  items: ResetItemFilterOption[],
  isCollapsed: boolean
): ResetItemFilterOption[] => {
  return isCollapsed ? items.slice(0, FILTERS_RESET_ITEMS_LIMIT) : items;
};
