import React, { ReactElement } from 'react';
import { EditButtonStyled } from './editButton.styled';
import { IconEditComponent } from '../../../icons';
import { ButtonColor, ButtonContainer, ButtonSize } from '../../../button';
import { EditButtonModel } from './editButton.model';

export const EditButtonContainer = ({
  href,
  handleClick,
}: EditButtonModel): ReactElement => (
  <EditButtonStyled onClick={handleClick}>
    <ButtonContainer
      color={ButtonColor.white}
      size={ButtonSize.normal}
      href={href}
    >
      <IconEditComponent />
    </ButtonContainer>
  </EditButtonStyled>
);
