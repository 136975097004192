import {
  UserMenuActions,
  UserMenuModel,
  UserMenuState,
} from './userMenu.model';
import { UserMenuConstants } from './userMenu.constants';

const initialState: UserMenuState = {
  menu: null,
  status: {
    isLoading: false,
    isLoaded: false,
  },
};

export function userMenuReducer(
  state: UserMenuState = initialState,
  action: UserMenuActions
): UserMenuState {
  switch (action.type) {
    case UserMenuConstants.REQUEST_USER_MENU_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case UserMenuConstants.REQUEST_USER_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload as UserMenuModel[],
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case UserMenuConstants.REQUEST_USER_MENU_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
}
