import { useCallback } from 'react';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  CoursePricesModel,
  pushDataLayer,
  TranslationModel,
} from '@lerna-core';
import { UseCompanyCompensationTagModel } from './companyCompensationTag.model';
import { useShowLegalEntitiesPriceHook, useUserTariff } from '@hooks';
import { getCourseEventData } from '@features/course';
import { ListItemTagsEvents } from '../listItemTags.analytics';

export const useCompanyCompensationTag = (
  price: CoursePricesModel,
  translations: TranslationModel,
  course: CourseModel,
  courseIndex?: number
): UseCompanyCompensationTagModel => {
  const { isLernaPrime } = useUserTariff();
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const companyCompensationWithBonuses =
    price?.priceCustomer?.compensationWithBonuses?.amount;
  const hasCompanyCompensationWithBonuses =
    !!companyCompensationWithBonuses &&
    !!parseInt(companyCompensationWithBonuses);
  const companyCompensation =
    price?.priceCustomer?.compensation?.discount?.amount;
  const hasCompanyCompensation =
    !!companyCompensation && !!parseInt(companyCompensation);

  const compensationAmount = hasCompanyCompensationWithBonuses
    ? companyCompensationWithBonuses
    : companyCompensation;

  const showTag =
    isLernaPrime &&
    !showLegalEntitiesPrice &&
    (hasCompanyCompensation || hasCompanyCompensationWithBonuses);
  const tagLabel = `${translations?.prefix_to?.toLowerCase()} -${compensationAmount} ${
    translations?.from_company_label
  }`;
  const tooltipContent = hasCompanyCompensationWithBonuses
    ? translations?.company_compensation_with_bonus_points_tag_label
    : translations?.company_compensation_tag_label;

  const handleTooltipOpen = useCallback((): void => {
    pushDataLayer(
      getCourseEventData(
        ListItemTagsEvents.clickIconInfoCompanyAdditionalCompensation,
        CommonEventTypes.coursesEvents,
        CommonEventPositions.coursesPageList,
        course,
        courseIndex
      )
    );
  }, []);

  return {
    showTag,
    tagLabel,
    tooltipContent,
    handleTooltipOpen,
  };
};
