import { FooterClient } from './footer.client';
import { FooterModel } from './footer.model';
import { RequestHeader } from '../../services';

export async function footerClientProvider(
  requestHeaders?: RequestHeader
): Promise<FooterModel> {
  const response = await new FooterClient(requestHeaders).get();

  return response as unknown as Promise<FooterModel>;
}
