import React, { ReactElement } from 'react';

type GoogleTagManagerNoscriptProps = {
  gtmId: string;
};

export const GtmNoscript = ({
  gtmId,
}: GoogleTagManagerNoscriptProps): ReactElement => {
  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}'
height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
      }}
    ></noscript>
  );
};
