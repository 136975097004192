import styled from 'styled-components';
import { TableSearchInputStyled, TableStyled } from '../../../table';

export const CoursesTableBoxStyled = styled.div`
  ${TableStyled} {
    position: relative;
    z-index: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    height: 655px;
  }

  ${TableSearchInputStyled} {
    width: 100%;
    max-width: none;
  }
`;

export const CoursesTableEmptyCheckAll = styled.div`
  width: 16px;
`;
