import React, { ReactElement, useMemo } from 'react';
import { DesktopLinkIconStyled, DesktopLinkStyled } from './desktopLink.styled';
import { DesktopLinkContainerProps } from './desktopLink.model';
import { MenuIconContainer } from '../../../../menu';
import { useTheme } from '../../../../../hooks';
import { getUserMenuLinkPath } from '../../userMenu.helpers';
import { handleUserMenuDataLayer } from '../../userMenu.analytics';
import { UserMenuTypes } from '../../userMenu.constants';
import { publicConfig } from '../../../../../services';

export const DesktopLinkContainer = ({
  title,
  link,
  icon,
  linkType,
  parentType,
  handleClose,
  projectPageName,
}: DesktopLinkContainerProps): ReactElement => {
  const theme = useTheme();

  const linkPath = useMemo(() => getUserMenuLinkPath(link, parentType), []);

  const handleClick = (): void => {
    handleClose && handleClose();
    handleUserMenuDataLayer(
      UserMenuTypes.desktop,
      link,
      linkType,
      parentType,
      publicConfig?.APP_TYPE,
      projectPageName
    );
  };

  return (
    <DesktopLinkStyled onClick={handleClick} href={linkPath}>
      <DesktopLinkIconStyled>
        <MenuIconContainer icon={icon} theme={theme} />
      </DesktopLinkIconStyled>
      {title}
    </DesktopLinkStyled>
  );
};
