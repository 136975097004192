import { CompensationRequestsModel } from './compensationRequests.model';
import { MetaPaginationModel } from '../../models';

export const initialCompensationRequestMeta: MetaPaginationModel = {
  total: 0,
  count: 0,
  perPage: 100,
  currentPage: 1,
  totalPages: 1,
  links: {},
};

export const initialCompensationRequestState: CompensationRequestsModel = {
  data: [],
  meta: initialCompensationRequestMeta,
};
