import { createSelector } from 'reselect';
import { RootState } from '../../models';
import { CompanyState } from './company.model';

export const getCompany = createSelector(
  (state: RootState<CompanyState>) => state.company,
  (reducer: CompanyState) => reducer.company
);

export const getCompanyStatus = createSelector(
  (state: RootState<CompanyState>) => state.company,
  (reducer: CompanyState) => reducer.status
);
