import React, { ReactElement } from 'react';

import { FormPopupFooterContainerStyled } from './formPopupFooter.styled';
import { FormPopupFooterContainerProps } from './formPopupFooter.model';

export const FormPopupFooterContainer = ({
  button,
  privacyCheckbox,
}: FormPopupFooterContainerProps): ReactElement => {
  return (
    <FormPopupFooterContainerStyled withPrivacyCheckbox={!!privacyCheckbox}>
      {button}
      {privacyCheckbox}
    </FormPopupFooterContainerStyled>
  );
};
