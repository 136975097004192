import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMagnifierComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
  fillColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
    >
      <circle
        cx="10.5"
        cy="10.5"
        r="7.5"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M20.293 21.707a1 1 0 001.414-1.414l-1.414 1.414zm-5.5-5.5l5.5 5.5 1.414-1.414-5.5-5.5-1.414 1.414z"
        fill={fillColor}
      />
    </svg>
  );
};
