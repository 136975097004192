import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconStaffManagementComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.583 2.5C7.067 2.5 5 4.567 5 7.083c0 2.517 2.067 4.584 4.583 4.584 2.517 0 4.584-2.067 4.584-4.584 0-2.516-2.067-4.583-4.584-4.583Zm0 2.5c1.165 0 2.084.918 2.084 2.083a2.065 2.065 0 0 1-2.084 2.084A2.065 2.065 0 0 1 7.5 7.083C7.5 5.918 8.418 5 9.583 5Zm9.584 1.667c-.147 0-.288.003-.424.006a1.25 1.25 0 1 0 .064 2.499 10.819 10.819 0 0 1 9.626 5.212 1.25 1.25 0 1 0 2.137-1.3 13.339 13.339 0 0 0-11.403-6.417Zm-13.75 6.666A2.936 2.936 0 0 0 2.5 16.25v1.25c0 2.473 2.489 5 7.083 5 4.595 0 7.084-2.527 7.084-5v-1.25a2.936 2.936 0 0 0-2.917-2.917H5.417Zm0 2.5h8.333c.246 0 .417.171.417.417v1.25c0 .749-.845 2.5-4.584 2.5S5 18.249 5 17.5v-1.25c0-.246.17-.417.417-.417Zm25 1.667c-2.517 0-4.584 2.067-4.584 4.583 0 2.517 2.067 4.584 4.584 4.584 2.516 0 4.583-2.067 4.583-4.584 0-2.516-2.067-4.583-4.583-4.583Zm0 2.5c1.165 0 2.083.918 2.083 2.083a2.065 2.065 0 0 1-2.083 2.084 2.065 2.065 0 0 1-2.084-2.084c0-1.165.919-2.083 2.084-2.083ZM8.039 25.002a1.251 1.251 0 0 0-1.109 1.914 13.339 13.339 0 0 0 11.826 6.41 1.248 1.248 0 0 0 1.255-1.281 1.25 1.25 0 0 0-1.318-1.217 10.819 10.819 0 0 1-9.626-5.211 1.25 1.25 0 0 0-1.028-.615Zm18.211 3.331a2.936 2.936 0 0 0-2.917 2.917v1.25c0 2.473 2.49 5 7.084 5 4.594 0 7.083-2.527 7.083-5v-1.25a2.936 2.936 0 0 0-2.917-2.917H26.25Zm0 2.5h8.333c.246 0 .417.171.417.417v1.25c0 .749-.844 2.5-4.583 2.5-3.74 0-4.584-1.751-4.584-2.5v-1.25c0-.246.171-.417.417-.417Z"
        fill={fillColor}
      />
    </svg>
  );
};
