import React, { ReactElement } from 'react';
import { FilterOptionModel } from './popupBody.model';
import { FilterPopupOptionContainer } from '../filterPopupOption';
import { FilterOptionsTypes } from '../filterPopup.model';

export const getOption = ({
  optionsType,
  labelKey,
  idKey,
  handleSelect,
  isActive,
  option,
}: FilterOptionModel): ReactElement => {
  switch (optionsType) {
    case FilterOptionsTypes.checkbox:
    case FilterOptionsTypes.radio:
      return (
        <FilterPopupOptionContainer
          key={`${option[idKey]}_${option[labelKey]}`}
          id={option[idKey] as string}
          label={option[labelKey] as string}
          active={isActive}
          onSelect={handleSelect}
        />
      );
  }
};
