import React, { ReactElement } from 'react';
import {
  DotStyled,
  RightPaymentTabsStyled,
  TabStyled,
  TabWrappersStyled,
} from './rightPaymentTabs.styled';
import { useFormB2BWithoutCompensationContext } from '../../../formB2BWithoutCompensation.context';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { CommonEventTypes } from '@lerna-core';
import { TabsPaymentIconsContainer } from './tabsPaymentIcons';

export const RightPaymentTabsContainer = (): ReactElement => {
  const {
    paymentContent,
    handleChangePaymentContent,
    translations,
    isSubmitting,
    eventPosition,
    courseIndex,
    course,
  } = useFormB2BWithoutCompensationContext();

  const isConsultationTabActive = paymentContent === 'consultation';
  const isOnlinePayTabActive = paymentContent === 'onlinePay';

  const handleClickConsultation = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollSelectConsultation,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      courseIndex
    );

    handleChangePaymentContent('consultation');
  };
  const handleClickOnlinePay = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollSelectOnlinePay,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      courseIndex
    );

    handleChangePaymentContent('onlinePay');
  };

  return (
    <RightPaymentTabsStyled>
      <TabStyled
        isActive={isConsultationTabActive}
        disabled={isSubmitting}
        onClick={handleClickConsultation}
      >
        <DotStyled isActive={isConsultationTabActive} />
        <TabWrappersStyled>
          {translations?.leave_consultation_request}
        </TabWrappersStyled>
      </TabStyled>
      <TabStyled
        isActive={isOnlinePayTabActive}
        disabled={isSubmitting}
        onClick={handleClickOnlinePay}
      >
        <DotStyled isActive={isOnlinePayTabActive} />
        <TabWrappersStyled>
          {translations?.pay_now}
          <TabsPaymentIconsContainer />
        </TabWrappersStyled>
      </TabStyled>
    </RightPaymentTabsStyled>
  );
};
