import { createContext, useContext } from 'react';
import { StaticsPageModel } from './statics.model';
import { logger } from '../../services';

export const StaticsContext = createContext<StaticsPageModel | null>(null);

export const useStaticsContext = (): StaticsPageModel => {
  const value = useContext(StaticsContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as StaticsPageModel;
  }

  return value;
};
