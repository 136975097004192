import {
  BannerApiModel,
  BannerImageApiModel,
  BannerImageModel,
  BannerModel,
  BannerResponseApiModel,
} from './headerBanner.model';
import { mapImage } from '../../features';

//todo: временно добавлено в либу
const mapBannerImage = (image: BannerImageApiModel): BannerImageModel => ({
  id: image.id,
  sizeType: image.size_type,
  image: mapImage(image.image),
});

export const bannerAdapter = (data: BannerApiModel): BannerModel => ({
  id: data.id,
  destinationUrl: data.destination_url,
  site: data.site,
  placementType: data.placement_type,
  isOpenNewWindow: data.is_open_new_window,
  images: data.images.map(mapBannerImage),
});

export const bannerResponseAdapter = ({
  data: { data },
}: BannerResponseApiModel): BannerModel[] => data.map(bannerAdapter);
