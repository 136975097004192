import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftCourseInfoContainer } from './leftCourseInfo';
import { useGuestOrderFormContext } from '../../guestOrderForm.context';
import { LeftPricesContainer } from './leftPrices';
import { LeftAdvantagesContainer } from './leftAdvantages';

export const ContentLeftContainer = (): ReactElement => {
  const { course, translations } = useGuestOrderFormContext();
  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftPricesContainer />
      <LeftAdvantagesContainer course={course} translations={translations} />
      <LeftCourseInfoContainer course={course} translations={translations} />
    </ContentLeftStyled>
  );
};
