import { CourseSkillModel, CourseSkillApiModel } from './courseSkills.model';

export const courseSkillsAdapter = (
  skills?: CourseSkillApiModel[]
): CourseSkillModel[] => {
  if (!skills || skills?.length === 0) {
    return [];
  }

  return skills.map(
    (skill): CourseSkillModel => ({
      title: skill.title,
      description: skill.description,
    })
  );
};
