import React from 'react';
import { IconHitComponent, Nullable, TranslationModel } from '@lerna-core';
import { ListItemLogoHitTextStyled } from './listItemLogo.styled';
import { CourseLogoLabelModel } from '@features/course/courseLogoLabel';

export const getHitLogoLabel = (
  translations: TranslationModel
): CourseLogoLabelModel => ({
  title: (
    <>
      <IconHitComponent />
      <ListItemLogoHitTextStyled>{translations.hit}</ListItemLogoHitTextStyled>
    </>
  ),
  color: '#FFF',
  background: 'linear-gradient(73deg, #EE2C72 8%, #4C07DF 92%)',
});

export const getLogoLabel = (
  logoLabel: Nullable<CourseLogoLabelModel>,
  isHit: boolean,
  translations: TranslationModel
): Nullable<CourseLogoLabelModel> => {
  if (!!logoLabel) {
    return logoLabel;
  }

  if (isHit) {
    return getHitLogoLabel(translations);
  }

  return null;
};
