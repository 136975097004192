import { CurrenciesStorageState } from '../../models';
import { storage } from '../localStorage/localStorage.service';
import { Currency } from '../../features';
import { dateService } from '../dateService';

class CurrenciesService {
  private static instance: CurrenciesService;
  private static readonly storageKey = 'currencies';
  private static readonly initialStore: CurrenciesStorageState = {
    expired: null,
    data: [],
  };

  public static getInstance(): CurrenciesService {
    if (!CurrenciesService.instance) {
      CurrenciesService.instance = new CurrenciesService();
    }

    return CurrenciesService.instance;
  }

  public get currenciesStore(): CurrenciesStorageState {
    return (
      storage.getValue(CurrenciesService.storageKey) ||
      CurrenciesService.initialStore
    );
  }

  public get currencies(): Currency[] {
    if (this.mustUpdate()) {
      CurrenciesService.deleteCurrencies();
    }

    return this.currenciesStore.data;
  }

  public set currencies(currencies: Currency[]) {
    storage.setValue(CurrenciesService.storageKey, {
      expired: CurrenciesService.expireTime,
      data: currencies,
    });
  }

  private static deleteCurrencies(): void {
    storage.deleteValue(CurrenciesService.storageKey);
  }

  private mustUpdate(): boolean {
    const { expired } = this.currenciesStore;
    if (expired) {
      return expired < dateService.timestamp;
    }

    return true;
  }

  private static get expireTime(): number {
    return dateService.timestamp + dateService.dayInMilliseconds;
  }
}

export const currenciesService = CurrenciesService.getInstance();
