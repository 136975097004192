import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../../styles';

export const ContactsEmailStyled = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ContactsItemTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.WHITE};
  text-decoration: none;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;

export const ContactsItemValueStyled = styled.div``;
