import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  EVENT_VALUE,
  getTextMaxLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
  orderFormPushDataLayer,
} from '@features/common/forms';
import {
  FormB2BWithoutCompensationInitialValues,
  FormB2BWithoutCompensationValidationSchema,
} from './formB2BWithoutCompensation.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';

export const getFormB2BWithoutCompensationInitialValues =
  (): FormB2BWithoutCompensationInitialValues => ({
    withPromoCode: false,
    promoCode: '',
  });

export function getFormB2BWithoutCompensationValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean
): FormB2BWithoutCompensationValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected
      ? {
          withPromoCode: Yup.boolean().optional(),
          promoCode: Yup.string().when('withPromoCode', {
            is: true,
            then: (promoCode) =>
              promoCode
                .required(translations?.input_required_error_label)
                .max(255, getTextMaxLengthValidationMessage(255, translations))
                .trim(),
          }),
        }
      : {}),
  };

  return Yup.object().shape(validationObject);
}

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;
  const eventValueOptions = [
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
  ];

  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.consultation:
      switch (true) {
        case course.isFree:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.priceNull);
          break;
        case course.isFreeByDiscount:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.discount100);
          break;
        case course.isFreeByBonuses:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.discountPoints);
          break;
        default:
          eventValueOptions.unshift(OrderFormEventsValues.paidCourse);
          eventValueOptions.push(OrderFormEventsValues.consultation);
          break;
      }
      break;
    case UserOrderFormPaymentContentTypes.onlinePay:
      switch (true) {
        case course.isFree:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.priceNull);
          break;
        default:
          eventValueOptions.unshift(OrderFormEventsValues.paidCourse);
          eventValueOptions.push(OrderFormEventsValues.paymentOnline);
          break;
      }
      break;
  }

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};

export const pushFormB2BWithoutCompensationFormPaymentEvent = (
  course: CourseModel,
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  const eventValueOptions = [
    OrderFormEventsValues.paidCourse,
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
    OrderFormEventsValues.paymentOnline,
  ];

  const event = `${OrderFormEvents.formEnrollGoToOnlinePay.replace(
    EVENT_VALUE,
    eventValueOptions.join(',')
  )}`;

  orderFormPushDataLayer(
    event,
    CommonEventTypes.coursesEvents,
    eventPosition,
    course
  );
};
