import { rgba } from 'polished';
import styled from 'styled-components';
import { TabsStyledProps } from './tabs.model';

export const TabsStyled = styled.div<TabsStyledProps>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
  padding-bottom: ${({ lineGap }): string => `${lineGap}px`};
`;
