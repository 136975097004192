import { MetaPaginationModel, TranslationModel } from '@lerna-core';
import { CoursesPaginationData } from './coursesPagination.model';

export const getCoursesPaginationData = (
  pagination: MetaPaginationModel,
  pageNumber: number,
  translations: TranslationModel
): CoursesPaginationData => {
  const coursesShown = pageNumber * pagination.perPage;
  const remainingCourses = pagination.total - coursesShown;

  const coursesPaginationData = {
    remainingCourses: remainingCourses,
    buttonTitle: `${translations?.show_more_label} ${remainingCourses}`,
  };

  if (remainingCourses >= pagination.perPage) {
    coursesPaginationData.buttonTitle = `${translations?.show_more_label} ${pagination.perPage} ${translations?.of} ${remainingCourses}`;
  }

  return coursesPaginationData;
};
