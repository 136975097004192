import { createSelector } from 'reselect';

import { RootState } from '../../../models';
import {
  UserPositionsState,
  UserPositionWithOptions,
} from './userPosition.model';

export const getUserPositions = createSelector(
  (state: RootState<UserPositionsState>) => state.positions,
  (reducer: UserPositionsState) =>
    reducer.positions as UserPositionWithOptions[]
);

export const getIsUserPositionsLoading = createSelector(
  (state: RootState<UserPositionsState>) => state.positions,
  (reducer: UserPositionsState) => reducer.isLoading
);

export const getIsUserPositionsLoaded = createSelector(
  (state: RootState<UserPositionsState>) => state.positions,
  (reducer: UserPositionsState) => reducer.isLoaded
);
