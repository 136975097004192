import {
  UserPositionsByCompanyClient,
  UserPositionsClient,
} from './userPositions.client';
import { UserPositionWithOptions } from './userPosition.model';

export async function userPositionsProvider(
  host?: string
): Promise<UserPositionWithOptions[]> {
  const response = await new UserPositionsClient(host).get();

  return response as unknown as Promise<UserPositionWithOptions[]>;
}

export async function userPositionsByCompanyProvider(
  companyId: string,
  host?: string
): Promise<UserPositionWithOptions[]> {
  const response = await new UserPositionsByCompanyClient(
    companyId,
    host
  ).get();

  return response as unknown as Promise<UserPositionWithOptions[]>;
}
