import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPaperComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 3H5.75A2.753 2.753 0 0 0 3 5.75v12.5A2.753 2.753 0 0 0 5.75 21h12.5A2.753 2.753 0 0 0 21 18.25V5.75A2.753 2.753 0 0 0 18.25 3Zm-11 4h6.5a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5Zm3.5 10h-3.5a.75.75 0 1 1 0-1.5h3.5a.75.75 0 1 1 0 1.5Zm6-4.25h-9.5a.75.75 0 1 1 0-1.5h9.5a.75.75 0 1 1 0 1.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
