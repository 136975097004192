import React, { ReactElement } from 'react';
import {
  PaperFooterContainerStyled,
  PaperFooterWrapStyled,
} from './paperFooter.styled';
import { PaperFooterContainerProps } from './paperFooter.model';

export const PaperFooterContainer = ({
  handleClick,
  label,
  children,
}: PaperFooterContainerProps): ReactElement => {
  return (
    <PaperFooterContainerStyled>
      <PaperFooterWrapStyled onClick={handleClick}>
        {children || label}
      </PaperFooterWrapStyled>
    </PaperFooterContainerStyled>
  );
};
