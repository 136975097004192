import { InputSizes } from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../coursesPage';
import { FilterContainerProps } from './filterColumn.model';
import { FilterContainerStyled } from './filterColumn.styled';
import { FilterTypeItem } from '@features/common/filters';

export const FilterColumnContainer = ({
  filter,
  coursesPath,
  withFilterDescription,
}: FilterContainerProps): ReactElement => {
  const coursesContext = useCoursesPageContext();

  return (
    <FilterContainerStyled>
      <FilterTypeItem
        rangeInputSize={InputSizes.small}
        filter={filter}
        isCollapsed={false}
        withTitle={true}
        withDescription={withFilterDescription}
        withSearch={true}
        translations={coursesContext.translations}
        defaultPathname={coursesPath}
      />
    </FilterContainerStyled>
  );
};
