import { Column } from 'react-table';
import { ReactElement } from 'react';
import { MetaPaginationModel } from '../../../../models';
import { TableEmptyContent } from '../../../table';
import { LocationType, Row } from '../../../transfer';

type TableData = {
  columns?: Column<Record<string, unknown>>[];
  rows: Record<string, unknown>[];
};

export type CustomerTableRow = Omit<CustomerTableData, 'name' | 'email'> & {
  name: ReactElement | string;
  email: ReactElement | string;
};

export type CustomerTableNameStyledProps = {
  disabled?: boolean;
};

export type CustomerTableData = {
  email: string;
  uuid: string;
  name: string;
  position: string;
  department: string;
  companyTitle: string;
  tariff: string;
  isDisabled?: boolean;
  claims?: string;
  maxClaimsCount?: string;
};

export type CustomersTableProps = {
  selectionColMinWidth?: number;
  selectionColWidth?: number;
  handlePagination: () => void;
  defaultColMinWidth?: number;
  defaultColWidth?: number;
  handleCheckAll?: () => void;
  handleCheck?: (rows: Row[]) => void;
  isRadioButton?: boolean;
  tableData: TableData;
  meta: MetaPaginationModel;
  isLoading: boolean;
  isPaginating: boolean;
  isSelectedAll: boolean;
  getSelectedRows?: (rows: Record<string, unknown>[]) => void;
  headerComponent?: ReactElement | boolean;
  emptyContent: TableEmptyContent;
  locationType: LocationType;
};

export enum SearchTerms {
  email = 'email',
  name = 'name',
}

export enum CustomersTableAccessors {
  name = 'name',
  position = 'position',
  department = 'department',
  tariff = 'tariff',
  search = 'search',
  email = 'email',
  companyTitle = 'companyTitle',
  claims = 'claims',
}
