import { breakpoint, MenuSwitcherStyled } from '@lerna-core';
import styled, { css } from 'styled-components';

export const CoursesHeadContainerStyled = styled.div`
  ${MenuSwitcherStyled} {
    margin-top: 16px;

    ${breakpoint(
      'xlarge',
      css`
        display: none;
      `
    )}
  }
`;
