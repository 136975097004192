import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { publicConfig, urlService } from '../../services';
import { IconLernaLogoComponent, IconReloadComponent } from '../icons';
import {
  ErrorPageContainerStyled,
  ErrorPageLinkContainerStyled,
  ErrorPageReloadStyled,
  ErrorPageStyled,
  PageNotFoundLinkStyled,
} from './errorPage.styled';
import {
  PageNotFoundTextStyled,
  PageNotFoundTitleStyled,
} from '../pageNotFound';
import { useTheme } from '../../hooks';
import { ContentWrapperStyled } from '../pageWrapper';
import { mainPath } from '../../constants';

export const ErrorPageContainer = (): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const handleReloadClick = (): void => {
    router.reload();
  };

  const linkToCourses = publicConfig?.COURSES_APP_LINK
    ? urlService.normalizeUrl(publicConfig?.COURSES_APP_LINK)
    : urlService.getFullUrlFromToPage(mainPath.courses);

  return (
    <ContentWrapperStyled>
      <ErrorPageContainerStyled>
        <a href={linkToCourses}>
          <IconLernaLogoComponent />
        </a>
        <ErrorPageStyled>
          <PageNotFoundTitleStyled>Something’s broken</PageNotFoundTitleStyled>
          <PageNotFoundTextStyled>Error code: 500</PageNotFoundTextStyled>
          <ErrorPageLinkContainerStyled>
            <PageNotFoundTextStyled>Try to</PageNotFoundTextStyled>
            <PageNotFoundLinkStyled onClick={handleReloadClick}>
              {' '}
              reload the page{' '}
            </PageNotFoundLinkStyled>
            <PageNotFoundTextStyled>or come back later</PageNotFoundTextStyled>
          </ErrorPageLinkContainerStyled>
          <ErrorPageReloadStyled onClick={handleReloadClick}>
            <IconReloadComponent strokeColor={theme.COLOR.PURPLE} />
          </ErrorPageReloadStyled>
        </ErrorPageStyled>
      </ErrorPageContainerStyled>
    </ContentWrapperStyled>
  );
};
