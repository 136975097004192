import styled from 'styled-components';

type BackgroundLayer0Props = {
  backgroundLayer0Styles: string;
};

type BackgroundLayer1Props = {
  backgroundLayer1Styles: string;
};

type BackgroundLayer2Props = {
  backgroundLayer2Styles: string;
};

export const BackgroundLayer0Styled = styled.div<BackgroundLayer0Props>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  ${({ backgroundLayer0Styles }): string => backgroundLayer0Styles}
`;

export const BackgroundLayer1Styled = styled.div<BackgroundLayer1Props>`
  width: 100%;
  height: 624px;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props): string => props.theme.ZINDEX.BOTTOM};
  ${({ backgroundLayer1Styles }): string => backgroundLayer1Styles}
`;

export const BackgroundLayer2Styled = styled.div<BackgroundLayer2Props>`
  width: 100%;
  height: 624px;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props): string => props.theme.ZINDEX.BOTTOM};
  ${({ backgroundLayer2Styles }): string => backgroundLayer2Styles}
`;
