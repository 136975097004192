import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { DEFAULT_WIDTH } from '../centeredPopup.constants';
import { PopupCloseStyledProps } from './popupClose.model';

export const PopupCloseStyled = styled.div<PopupCloseStyledProps>`
  box-sizing: border-box;
  width: 100%;
  max-width: ${({ contentWidth }): string =>
    contentWidth ? `${contentWidth}px` : `${DEFAULT_WIDTH}px`};

  ${breakpoint(
    'mediumDown',
    css`
      display: flex;
      justify-content: flex-end;
      padding: 16px 0;
    `
  )}
`;

export const WrapperStyled = styled.div`
  padding: 8px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.COLOR.BLACK_4};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: max-content;
  margin-left: auto;
  margin-right: -46px;
  margin-bottom: -32px;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
      border-radius: unset;
      background-color: unset;
      margin-right: unset;
      margin-bottom: unset;

      svg {
        width: 24px;
        height: 24px;
      }
    `
  )}
`;
