import { orderFormPushDataLayer } from '@features/common/forms';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
} from '@lerna-core';
import { ListItemButtonsEvent } from './listItem';

export const handleCoursesListDataLayer = (
  course: CourseModel,
  activeCourseIndex: number
): void => {
  orderFormPushDataLayer(
    ListItemButtonsEvent.buttonEnroll,
    CommonEventTypes.coursesEvents,
    CommonEventPositions.coursesPageList,
    course,
    activeCourseIndex
  );
};
