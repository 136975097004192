import { fontSettings, GenericInputClearStyled, transition } from '@lerna-core';
import styled from 'styled-components';
import { FilterStyledProps } from './filter.model';
import { FilterRangeItemStyled } from '@features/common/filters/filterRange/';

export const FilterStyled = styled.div<FilterStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.TEXT_DARK_COLOR};

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  ${transition('color')}
    &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  }

  ${FilterRangeItemStyled} {
    ${GenericInputClearStyled} {
      right: 12px;
    }
  }

  ${({ isCustomFilter, background }): string =>
    isCustomFilter
      ? `
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
    background: ${background ? `${background}` : 'transparent'};
  `
      : ''}
`;
