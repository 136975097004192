export enum CookiesNotificationEnum {
  agreement = 'agreement',
  management = 'management',
}

export enum CookieTypes {
  technical = 'technical',
  analytics = 'analytics',
  advertising = 'advertising',
}
