import {
  ImageApiModel,
  ImageModel,
  ImageResourceModel,
  Nullable,
} from '../../models';

export enum BannerSizeTypes {
  desktop = 'desk',
  tablet = 'tablet',
  mobile = 'mobile',
  smallMobile = 'small-mobile',
}

export enum BannerPlacementTypes {
  header = 'header',
  orderPopup = 'order-popup',
}

export type BannerImageApiModel = {
  id: string;
  image: ImageApiModel;
  size_type: BannerSizeTypes;
};
export type BannerImageModel = {
  id: string;
  image: ImageModel;
  sizeType: BannerSizeTypes;
};

export type BannerApiModel = {
  id: string;
  destination_url: string;
  is_open_new_window: boolean;
  site: string;
  placement_type: BannerPlacementTypes;
  title: string;
  images: BannerImageApiModel[];
};
export type BannerModel = {
  id: string;
  destinationUrl: string;
  isOpenNewWindow: boolean;
  site: string;
  placementType: BannerPlacementTypes;
  images: BannerImageModel[];
};

export type BannerResponseApiModel = {
  data: {
    data: BannerApiModel[];
  };
};

export type BannerImageResources = Record<string, Nullable<ImageResourceModel>>;
