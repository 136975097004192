import { FiltersModel, FilterTypeEnum } from '@lerna-core';
import { VISIBLE_FILTERS_LIMIT } from './filters.constants';

export const getVisibleFiltersWithoutTabs = (
  filters: FiltersModel[]
): FiltersModel[] =>
  filters.filter(
    (filter) => filter.type !== FilterTypeEnum.tab && filter.isVisible
  );

export const getVisibleFilters = (
  filters: FiltersModel[],
  showAllFilters: boolean
): FiltersModel[] =>
  filters.length > VISIBLE_FILTERS_LIMIT && !showAllFilters
    ? filters.slice(0, VISIBLE_FILTERS_LIMIT)
    : filters;
