import React, { ReactElement } from 'react';
import { Nullable, publicConfig } from '@lerna-core';
import Head from 'next/head';
import Script from 'next/script';

export const FbPixelContainer = (): Nullable<ReactElement> => {
  if (publicConfig?.USE_FB_PIXEL !== 'true') {
    return null;
  }

  return (
    <>
      <Head>
        <meta
          name="facebook-domain-verification"
          content={publicConfig.FB_PIXEL_VERIFICATION_CONTENT}
          key="facebook-domain-verification"
        />
        <noscript>
          <img
            height="1"
            width="1"
            alt="fb"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${publicConfig.FB_PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>
      <Script
        strategy="beforeInteractive"
        id={publicConfig.FB_PIXEL_ID}
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${publicConfig.FB_PIXEL_ID});
      fbq('track', 'PageView');`,
        }}
      />
    </>
  );
};
