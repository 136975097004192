import styled from 'styled-components';

export const SkillsItemContainerStyled = styled.div`
  padding-left: 34px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const SkillsItemTitleStyled = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const SkillsItemDescriptionStyled = styled.div``;

export const SkillsItemBulletStyled = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }): string => theme.COLOR.PURPLE_INFO_BG};
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 1px;

  svg {
    position: absolute;
    top: -1px;
    right: -2px;
  }
`;
