const PHONE_PREFIX = '+';

export const normalizePhone = (phone: string): string => {
  const trimmedPhone = phone.trim();

  if (!trimmedPhone.length) {
    return '';
  }

  return trimmedPhone[0] === PHONE_PREFIX
    ? trimmedPhone
    : `${PHONE_PREFIX}${trimmedPhone}`;
};
