import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import {
  OnboardingRequestParamsModel,
  OnboardingSlugsEnum,
} from './onboarding.model';

export class OnboardingClient extends SecureRequestClient<
  OnboardingRequestParamsModel,
  null,
  null
> {
  protected path = 'user-api/v1/suggests/complete';
  protected requestParameters: OnboardingRequestParamsModel;

  constructor(slugs: OnboardingSlugsEnum[], requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = {
      slugs,
    };
  }
}
