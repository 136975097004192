import { AppService } from '../appService/app.service';
import { publicConfig } from '../config/config.model';

export enum LogLevel {
  info = 'info',
  warning = 'warning',
  error = 'error',
  fatal = 'fatal',
}

export enum LogStyle {
  info = '\x1b[0m',
  warning = '\x1b[33m',
  error = '\x1b[31m',
  fatal = '\x1b[31m',
}

export type LoggerMessageFormat<T> = {
  service: string;
  project: string;
  level: LogLevel;
  message: string;
  req_id: string | undefined;
  payload: string | LoggerMessageType<T> | undefined;
  date_server: Date;
  date_unix: number;
};

type LoggerMessageType<T> = T & {
  req_id?: string;
};

class Logger {
  private static instance: Logger;

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  public info<T>(message: string, payload?: LoggerMessageType<T>): void {
    Logger.log<T>(message, LogLevel.info, payload);
  }

  public warning<T>(message: string, payload?: LoggerMessageType<T>): void {
    Logger.log<T>(message, LogLevel.warning, payload);
  }

  public error<T>(message: string, payload?: LoggerMessageType<T>): void {
    Logger.log<T>(message, LogLevel.error, payload);
  }

  public fatal<T>(message: string, payload?: LoggerMessageType<T>): void {
    Logger.log<T>(message, LogLevel.fatal, payload);
  }

  private static getFormattedMessage<T>(
    message: string,
    logLevel: LogLevel,
    payload?: LoggerMessageType<T>
  ): LoggerMessageFormat<T> {
    const req_id = payload?.req_id;

    return {
      service: 'lerna/coapp',
      project: publicConfig?.BASE_DOMAIN,
      level: logLevel,
      message,
      req_id,
      date_server: new Date(),
      date_unix: Math.round(new Date().getTime() / 1000),
      payload,
    };
  }

  private static log<T>(
    message: string,
    logLevel: LogLevel,
    payload?: LoggerMessageType<T>
  ): void {
    if (AppService.isServerSide) {
      const resultMessage = Logger.getFormattedMessage<T>(
        message,
        logLevel,
        payload
      );
      process.stdout.write(JSON.stringify(resultMessage) + '\n');
    } else {
      console.log(LogStyle[logLevel], message, payload);
    }
  }
}

export const logger = Logger.getInstance();
