import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconAIComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.659 2.506c-3.947-.136-7.35 2.364-8.776 5.848a9.09 9.09 0 0 0-5.705 4.061c-2.091 3.35-1.626 7.546.679 10.523a9.094 9.094 0 0 0 .662 6.97c1.856 3.487 5.724 5.185 9.455 4.677a9.093 9.093 0 0 0 6.367 2.91c3.946.136 7.35-2.365 8.776-5.85a9.085 9.085 0 0 0 5.703-4.06c2.092-3.35 1.628-7.546-.677-10.523a9.094 9.094 0 0 0-.662-6.97c-1.856-3.487-5.724-5.185-9.455-4.677a9.087 9.087 0 0 0-6.367-2.909Zm-.632 2.5a6.642 6.642 0 0 1 4.191 1.249c-.229.109-.457.22-.68.348l-6.27 3.62c-.41.237-.665.675-.667 1.149l-.042 9.83-2.892-1.686v-7.618c0-3.604 2.76-6.732 6.36-6.892Zm8.578 2.785c2.13.07 4.182 1.105 5.394 3.003a6.641 6.641 0 0 1 1.012 4.255 9.441 9.441 0 0 0-.641-.417l-6.27-3.618a1.335 1.335 0 0 0-1.328-.003l-8.533 4.878.014-3.347 6.599-3.81a7.046 7.046 0 0 1 3.753-.94Zm-17.4 3.417c-.02.252-.038.505-.038.761v7.24c0 .474.252.913.662 1.152l8.491 4.953-2.905 1.66-6.598-3.808c-3.121-1.803-4.45-5.756-2.788-8.954a6.643 6.643 0 0 1 3.177-3.004Zm15.38 1.818 6.598 3.808c3.121 1.803 4.45 5.756 2.788 8.954a6.642 6.642 0 0 1-3.177 3.004c.02-.252.04-.505.04-.762v-7.239c0-.474-.253-.913-.663-1.152l-8.491-4.953 2.905-1.66Zm-4.569 2.61 3.771 2.198-.017 4.365-3.786 2.164-3.772-2.197.018-4.365 3.786-2.165Zm5.425 3.161 2.892 1.686v7.62c0 3.602-2.76 6.73-6.36 6.89a6.642 6.642 0 0 1-4.191-1.248c.229-.109.457-.22.68-.348l6.27-3.62c.41-.238.665-.675.667-1.15l.042-9.83Zm-1.68 5.314-.014 3.347-6.599 3.81c-3.12 1.801-7.208.975-9.147-2.062A6.64 6.64 0 0 1 6.99 24.95c.209.144.418.288.641.417l6.27 3.618c.41.237.916.238 1.328.003l8.533-4.878Z"
      fill={fillColor}
    />
  </svg>
);
