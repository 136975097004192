import {
  ApiPriceCustomerModel,
  ApiPriceModel,
  ApiPriceWithDiscountModel,
  CurrencyModel,
  PriceCustomerModel,
  PriceWithDiscountModel,
  PriceWithProgramDiscountApiModel,
  PriceWithProgramDiscountModel,
  PriceWithPromoCodeDiscountApiModel,
  PriceWithPromoCodeDiscountModel,
  TranslationModel,
} from '../models';
import { currencyPriceMask } from './currency.adapter';
import { discountAdapter } from './discount.adapter';
import { installmentAdapter } from './installment.adapter';
import { mapCashBack } from './cashBack.adapter';
import { compensationAdapter } from './compensation.adapter';
import { partnershipDiscountPriceAdapter } from './partnershipDiscounts.adapter';

export const setPriceValue = (
  price: ApiPriceModel,
  currencies: CurrencyModel[]
): string => {
  const currentCurrency = currencies.find(
    (currency) => currency.code === price.currency
  );
  if (currentCurrency) {
    return currentCurrency.mask.replace(currencyPriceMask, `${price.amount}`);
  }

  return '';
};

export const priceWithDiscountAdapter = (
  price: ApiPriceWithDiscountModel,
  currencies: CurrencyModel[]
): PriceWithDiscountModel => ({
  amount: setPriceValue(price, currencies),
  currency: price.currency,
  discount: !!price?.discount?.value
    ? discountAdapter(price.discount, currencies)
    : null,
  totalDiscount: !!price?.total_discount?.value
    ? discountAdapter(price.total_discount, currencies)
    : null,
});

export const priceCustomerAdapter = (
  price: ApiPriceCustomerModel,
  currencies: CurrencyModel[],
  translations: TranslationModel
): PriceCustomerModel => {
  const priceCustomer: PriceCustomerModel = {
    ...priceWithDiscountAdapter(price, currencies),
    installment: price.installment
      ? installmentAdapter(price.installment, currencies, translations)
      : null,
    discountBonuses: !!price.bonuses?.discount?.value
      ? discountAdapter(price.bonuses?.discount, currencies)
      : null,
    compensation: !!price?.compensation?.data?.discount?.value
      ? compensationAdapter(price.compensation, currencies)
      : null,
    compensationWithBonuses: !!price?.compensation_with_bonuses?.value
      ? discountAdapter(price.compensation_with_bonuses, currencies)
      : null,
    partnershipDiscount: !!price?.partnership_discount?.data?.discount?.value
      ? partnershipDiscountPriceAdapter(price.partnership_discount, currencies)
      : null,
    vendorWithPartnershipDiscount: !!price?.vendor_with_partnership_discount
      ?.value
      ? discountAdapter(price.vendor_with_partnership_discount, currencies)
      : null,
  };

  if (!!parseInt(price?.bonuses?.bonus_points)) {
    priceCustomer.cashBack = mapCashBack({
      bonus_points: price.bonuses.bonus_points,
      percent: price.bonuses?.percent,
    });
  }

  return priceCustomer;
};

export const priceWithProgramDiscountAdapter = (
  price: PriceWithProgramDiscountApiModel,
  currencies: CurrencyModel[]
): PriceWithProgramDiscountModel => ({
  ...priceWithDiscountAdapter(price, currencies),
  textBlocks: {
    labelText: price.text_blocks.label_text,
    pricesBlockText: price.text_blocks.prices_block_text,
  },
});

export const priceWithPromoCodeDiscountAdapter = (
  price: PriceWithPromoCodeDiscountApiModel,
  currencies: CurrencyModel[]
): PriceWithPromoCodeDiscountModel => ({
  amount: setPriceValue(price, currencies),
  currency: price.currency,
  discount: !!price?.discount?.value
    ? discountAdapter(price.discount, currencies)
    : null,
});
