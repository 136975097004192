import styled, { css } from 'styled-components';
import { getDefaultPhoneInputStyles } from './phoneInput.mixins';
import { PhoneInputStyledProps } from './phoneInput.model';

export const PhoneInputStyled = styled.div<PhoneInputStyledProps>`
  width: 100%;
  box-sizing: border-box;

  ${({
    theme,
    disabled,
    color,
    bordered,
    height,
    borderRadius,
    hasError,
  }): ReturnType<typeof css> =>
    getDefaultPhoneInputStyles({
      theme,
      disabled,
      color,
      bordered,
      height,
      borderRadius,
      hasError,
    })}
`;
