import { TranslationModel } from '../../../../models';
import { FormInitialValues, FormValidationSchema } from './formUserEdit.model';
import * as Yup from 'yup';
import { phoneRegex } from '../../../form';
import { isPhoneValid } from '../../../inputs';
import { PersonalModel } from '../../user.model';

const EMPTY = '';

export const getFormValidationSchema = (
  translations: TranslationModel
): FormValidationSchema => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(translations.input_required_error_label)
      .trim(translations.input_required_error_label),
    lastName: Yup.string()
      .required(translations?.input_required_error_label)
      .trim(translations.input_required_error_label),
    patronymic: Yup.string().nullable(),
    phone: Yup.string()
      .matches(phoneRegex, translations?.input_phone_format_error_label)
      .required(translations?.input_required_error_label)
      .test(
        'phone',
        translations?.input_phone_format_error_label,
        isPhoneValid
      ),
  });
};

export const getFormInitialValues = (
  personal: PersonalModel | undefined
): FormInitialValues => ({
  firstName: personal?.firstName || EMPTY,
  lastName: personal?.lastName || EMPTY,
  patronymic: personal?.patronymic || EMPTY,
  phone: personal?.phone || EMPTY,
});
