import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOptionSearchContainerTypes } from './filterOption.model';
import { FilterOptionStyled } from './filterOption.styled';
import {
  CheckboxContainer,
  FilterTypes,
  getFilterOptionValueSelector,
  getFilters,
  handleTriggerFilterOption,
  mainPath,
  pushDataLayer,
  useTheme,
} from '@lerna-core';
import { FilterEvent, getMainFilterEventData } from '../filters.analytics';
import { selectFilterOption } from '../filters.helper';

export const FilterOption = ({
  option,
  filter,
  defaultPathname,
  optionCheckedColor,
  analyticsType,
}: FilterOptionSearchContainerTypes): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const dispatcher = useDispatch();

  const filters = useSelector(getFilters);
  const isSelected = useSelector(
    getFilterOptionValueSelector(filter.slug, option.slug)
  );

  const checkboxColors = {
    default: theme.COLOR.TEXT_DARK_COLOR,
    defaultIcon: theme.COLOR.CHECKBOX_ICON_DEFAULT,
    hover: theme.COLOR.TEXT_DARK_COLOR,
    hoverIcon: theme.COLOR.CHECKBOX_ICON_HOVER,
    checked: optionCheckedColor || theme.COLOR.PRIMARY[900],
    defaultFill: theme.COLOR.WHITE,
  };

  const handleClick = (): void => {
    const defaultPath = router.query?.fastlink
      ? mainPath.courses
      : defaultPathname;
    selectFilterOption(
      router,
      isSelected,
      filter,
      option,
      filters,
      defaultPath,
      () =>
        dispatcher(
          handleTriggerFilterOption(filter.slug, option.slug, !isSelected)
        )
    );

    if (analyticsType === FilterTypes.main) {
      pushDataLayer(
        getMainFilterEventData(
          FilterEvent.constructorFiltersMain,
          filter,
          option
        )
      );
    }
  };

  return (
    <FilterOptionStyled key={option.slug}>
      <CheckboxContainer
        label={option.value}
        onClick={handleClick}
        defaultChecked={isSelected}
        checkboxColors={checkboxColors}
      />
    </FilterOptionStyled>
  );
};
