import { CustomersTransferState } from './customersTransfer.model';
import { RootState } from '../../../models';
import { createSelector } from 'reselect';

export const getCustomersTransferSelector = createSelector(
  (state: RootState<CustomersTransferState>) => state.customersTransfer,
  (reducer: CustomersTransferState) => reducer as CustomersTransferState
);

export const getCustomersTransferUnallocatedFiltersSelector = createSelector(
  (state: RootState<CustomersTransferState>) => state.customersTransfer,
  (reducer: CustomersTransferState) => reducer.unallocated.filters
);

export const getCustomersTransferAllocatedFiltersSelector = createSelector(
  (state: RootState<CustomersTransferState>) => state.customersTransfer,
  (reducer: CustomersTransferState) => reducer.allocated.filters
);
