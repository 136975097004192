import { Nullable } from '../../models';

export type TariffApiModel = {
  id: string;
  title: string;
  slug: TariffSlugEnum;
  additional_customer_bonus_points: Nullable<number>;
  additional_manager_bonus_points: number;
  spending_bonus_points_ratio: Nullable<number>;
  is_adding_joining_customers: boolean;
  without_courses: boolean;
  is_default?: boolean;
  created_at: string;
  expired_at: Nullable<string>;
  customers_count: number;
  customers_count_limit: Nullable<number>;
  customers_removal_limit: Nullable<number>;
  available_removal_count: Nullable<number>;
  courses_count: number;
};

export type TariffModel = {
  uuid: string;
  title: string;
  slug: TariffSlugEnum;
  additionalCustomerBonusPoints: Nullable<number>;
  additionalManagerBonusPoints: number;
  spendingBonusPointsRatio: Nullable<number>;
  isAddingJoiningCustomers: boolean;
  withoutMiniCourses: boolean;
  isDefault?: boolean;
  createdAt: string;
  expiredAt: string;
  customersCount: number;
  customersCountLimit: Nullable<number>;
  customersRemovalLimit: Nullable<number>;
  availableRemovalCount: Nullable<number>;
  coursesCount: number;
};

export type TariffTypeModel = {
  uuid: string;
  title: string;
  slug: TariffSlugEnum;
  additionalCustomerBonusPoints: number;
  additionalManagerBonusPoints: number;
};

export type TariffTypeApiModel = {
  id: string;
  title: string;
  slug: TariffSlugEnum;
  additional_customer_bonus_points: number;
  additional_manager_bonus_points: number;
};

export enum TariffSlugEnum {
  basic = 'basic',
  prime = 'prime',
}
