import { css } from 'styled-components';
import { transition } from '@lerna-core';

export const ListItemTagLabelHover = css`
  & > * {
    ${transition('all')}
  }

  &:hover {
    & > * {
      opacity: 0.8;
    }
  }
`;
