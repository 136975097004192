import React, { ReactElement } from 'react';
import { Nullable } from '@lerna-core';
import { ListItemVendorStyled } from './listItemVendor.styled';
import { ListItemVendorModel } from './listItemVendor.model';
import { CourseVendorLabelContainer } from '@features/course/courseVendorLabel';
import { CourseVendorLogoContainer } from '@features/course';

export const ListItemVendorContainer = ({
  vendor,
  vendorLabel,
}: ListItemVendorModel): Nullable<ReactElement> => {
  const vendorLogo = vendor?.logo || vendor?.coursePlug;

  if (!vendorLogo) {
    return null;
  }

  return (
    <ListItemVendorStyled>
      <CourseVendorLogoContainer logo={vendorLogo} />
      {vendorLabel && <CourseVendorLabelContainer {...vendorLabel} />}
    </ListItemVendorStyled>
  );
};
