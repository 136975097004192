import { Nullable, CatalogPageTypes } from '@lerna-core';

export enum HeaderEventPositions {
  coursesPageBlockHeader = 'courses_page_block_header',
  coursePageBlockHeader = 'course_page_block_header',
}

export const getHeaderEventPositionByPageName = (
  pageName: Nullable<CatalogPageTypes>
): HeaderEventPositions | undefined => {
  switch (pageName) {
    case CatalogPageTypes.courses:
      return HeaderEventPositions.coursesPageBlockHeader;

    case CatalogPageTypes.coursePage:
      return HeaderEventPositions.coursePageBlockHeader;

    default:
      return;
  }
};
