import { publicConfig, RequestHeader, serverConfig } from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';
import { OnboardingArea } from '../onboarding.model';
import { onboardingCompletedAdapter } from './onboardingCompleted.adapter';
import {
  OnboardingCompletedResponseApiModel,
  OnboardingCompletedResponseModel,
} from './onboardingCompleted.model';

const AREA = '{{AREA}}';

export class OnboardingCompletedClient extends SecureRequestClient<
  null,
  OnboardingCompletedResponseApiModel,
  OnboardingCompletedResponseModel
> {
  protected path = `user-api/v1/suggests/completed/${AREA}`;
  protected requestParameters: null;
  protected adapter = onboardingCompletedAdapter;

  constructor(area: OnboardingArea, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
    this.path = this.path.replace(AREA, area);
  }
}
