import { Nullable } from '../../models';
import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';
import { userAdapter } from './user.adapter';
import { UserAggregateApiModel, UserAggregateModel } from './user.model';

export class UserClient extends RequestClient<
  null,
  UserAggregateApiModel,
  Nullable<UserAggregateModel>
> {
  protected path = 'aggregate/v1/me';
  protected requestParameters: null;
  protected adapter = userAdapter;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
  }
}
