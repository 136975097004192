import { AccordionModel, NonRequiredString } from '@lerna-core';
import {
  CourseProgramSectionModel,
  CourseSubSectionModel,
} from './courseProgram.model';

const setSubtitleTitle = (title: NonRequiredString): string => {
  return !!title ? `<h5>${title}</h5>` : '';
};

const mapSubtitles = (subtitles: CourseSubSectionModel[]): string[] =>
  subtitles.map(
    (subtitle) =>
      `${setSubtitleTitle(subtitle.title)}${subtitle.description || ''}`
  );

const mapDescription = (section: CourseProgramSectionModel): string => {
  return `${
    section.description ? `<div>${section.description}</div>` : ''
  }${mapSubtitles(section.subSections).join('')}`;
};

export const mapAccordionItems = (
  program: CourseProgramSectionModel[]
): AccordionModel[] => {
  return program.map((programItem) => ({
    title: programItem.title,
    content: mapDescription(programItem),
  }));
};
