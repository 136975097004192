import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { FiltersClearAllContainerProps } from './filtersClearAll.model';
import { FiltersClearAllContainerStyled } from './filtersClearAll.styled';
import {
  filterRouterService,
  handleClearFilters,
  handleSetSearchTerm,
  urlService,
} from '@lerna-core';

export const FiltersClearAllContainer = ({
  children,
  defaultPathname,
  filters,
  handleClear,
}: FiltersClearAllContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handleClick = (): void => {
    if (handleClear) {
      handleClear();
    }
    dispatch(handleSetSearchTerm(''));
    dispatch(handleClearFilters());
    const newQuery = filterRouterService.clearFilters(filters, router);
    delete newQuery.title;
    router.push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <FiltersClearAllContainerStyled onClick={handleClick}>
      {children}
    </FiltersClearAllContainerStyled>
  );
};
