import React, { ReactElement } from 'react';
import { PopupCloseStyled, WrapperStyled } from './popupClose.styled';
import { IconCloseComponent } from '@lerna-core';
import { PopupCloseContainerProps } from './popupClose.model';

export const PopupCloseContainer = ({
  handleClose,
  contentWidth,
}: PopupCloseContainerProps): ReactElement => {
  return (
    <PopupCloseStyled contentWidth={contentWidth}>
      <WrapperStyled onClick={handleClose}>
        <IconCloseComponent width={16} height={16} />
      </WrapperStyled>
    </PopupCloseStyled>
  );
};
