import { pushDataLayer } from '../../services';
import { CommonEventPositions, CommonEventTypes } from '../../analytics';

export enum FooterAnalyticsEvents {
  clickLinkOferta = 'click_link_oferta',
  clickLinkPrivacyPolicy = 'click_link_privacy_policy',
  clickLinkPlatformUsage = 'click_link_pravila',
  clickLinkUserPolicy = 'click_link_user_policy',
  clickLinkSoftwareSpecifications = 'click_link_software_specifications',
  clickLinkSoftwareCost = 'click_link_software_cost',
  clickLinkBonusesRules = 'click_link_bonuses_rules',
  clickInfoPhone = 'click_info_phone',
  clickInfoEmail = 'click_info_email',
  clickButtonWriteSupport = 'click_button_write_support',
  clickButtonWriteSupportTelegram = 'click_button_write_support_telegram',
  clickButtonWriteSupportChat = 'click_button_write_support_chat',
  clickButtonWriteSupportMail = 'click_button_write_support_mail',
}

export const pushFooterDataLayer = (event: string): void => {
  if (!event) return;

  pushDataLayer({
    event,
    event_type: CommonEventTypes.overallEvents,
    event_position: CommonEventPositions.footer,
  });
};
