import { mapImageResource } from '../image';
import {
  VendorModel,
  ApiVendorModel,
  VendorApiResponseModel,
} from './vendors.model';

export const vendorAdapter = (apiVendor: ApiVendorModel): VendorModel => ({
  slug: apiVendor.slug,
  title: apiVendor.title,
  uuid: apiVendor.uuid,
  logo: apiVendor.logo
    ? {
        imageResource: mapImageResource(apiVendor.logo.data.image_resource),
        size: apiVendor?.logo?.data?.size || null,
        backgroundColor: apiVendor?.logo?.data?.background_color || null,
      }
    : null,
  coursePlug: apiVendor.course_plug
    ? {
        imageResource: mapImageResource(
          apiVendor.course_plug.data.image_resource
        ),
        size: apiVendor?.course_plug?.data?.size || null,
        backgroundColor: apiVendor?.course_plug?.data?.background_color || null,
      }
    : null,
});

export const vendorsAdapter = (apiVendors: ApiVendorModel[]): VendorModel[] =>
  apiVendors.map(vendorAdapter);

export const vendorsApiAdapter = ({
  data: { data },
}: VendorApiResponseModel): VendorModel[] => vendorsAdapter(data);
