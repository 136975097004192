import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpoint, fontSettings } from '../../../styles';
import { MenuSwitcherStyledProps } from './menuSwitcher.model';
import {
  getAdaptiveSwitcherStyle,
  getIsHiddenBreakpoint,
  getItemsSeparator,
} from './menuSwitcher.mixin';

export const MenuSwitcherStyled = styled.div<MenuSwitcherStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  height: 50px;
  margin-left: auto;
  margin-right: 19px;
  border-radius: 100px;
  position: relative;
  flex: 0 0 auto;
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  ${({ isAdaptive }): ReturnType<typeof css> =>
    getAdaptiveSwitcherStyle(isAdaptive)}
  ${({ hiddenAfter }): ReturnType<typeof css> =>
    getIsHiddenBreakpoint(hiddenAfter)}
    ${({ activeLinkIndex, linksLength }): ReturnType<typeof css> =>
    getItemsSeparator(activeLinkIndex, linksLength)}

  ${breakpoint(
    'mediumDown',
    css`
      margin: 0 -12px 24px -12px;
      width: auto;
    `
  )}
`;
