import styled, { css } from 'styled-components';
import { FooterFastLinksTextStyledProps } from './footerFastLinks.model';
import { breakpoint, fontSettings } from '../../../styles';
import { FooterHover } from '../footer.mixin';
import Link from 'next/link';

export const FooterFastLinksStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 0 0 400px;
  height: max-content;

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      flex: 0 1 auto;
    `
  )}
`;

export const FooterFastLinksItemStyled = styled(Link)`
  flex: 0 0 50%;
  padding: 0 10px;
  margin-bottom: 16px;
  height: max-content;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: inherit;
  text-decoration: none;

  ${FooterHover}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    `
  )}
`;

export const FooterFastLinkTextStyled = styled.span<FooterFastLinksTextStyledProps>`
  ${({ isActive }): string => (isActive ? 'font-weight: 700;' : '')}
`;
