import { AxiosPromise } from 'axios';

import {
  publicConfig,
  RequestHeader,
  setAcceptLanguage,
} from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';

export type ContactUsRequestProps = {
  user_name: string;
  email: string;
  phone: string;
  comment: string;
  is_data_agreement_accepted: boolean;
  lang?: string;
};

export class ContactUsFormClient extends SecureRequestClient<
  ContactUsRequestProps,
  ContactUsRequestProps,
  ContactUsRequestProps
> {
  protected path = publicConfig.INQUIRIES_PATH;
  protected requestParameters: ContactUsRequestProps;

  constructor(params: ContactUsRequestProps, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);
    this.requestParameters = params;
  }
}

export const makeContactUsRequest = (
  nameValue: string,
  emailValue: string,
  phoneValue: string,
  messageValue: string,
  lang?: string
): AxiosPromise<ContactUsRequestProps> => {
  const requestParams = {
    user_name: nameValue,
    email: emailValue,
    phone: phoneValue,
    comment: messageValue,
    is_data_agreement_accepted: true,
    lang,
  };

  const headers = setAcceptLanguage(lang);

  return new ContactUsFormClient(requestParams, headers).post();
};

type ApiFormError = {
  response: {
    status: number;
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
};

export const mapValidationErrors = (
  error: ApiFormError,
  setMessageErrors: (value: string[]) => void,
  setEmailErrors: (value: string[]) => void,
  setNameErrors: (value: string[]) => void,
  setPhoneErrors: (value: string[]) => void
): boolean => {
  if (error.response.status === 422) {
    const { errors } = error.response.data;
    if (errors['[comment]']) {
      setMessageErrors(errors['[comment]']);
    }
    if (errors['[user_name]']) {
      setNameErrors(errors['[user_name]']);
    }
    if (errors['[email]']) {
      setEmailErrors(errors['[email]']);
    }
    if (errors['[phone]']) {
      setPhoneErrors(errors['[phone]']);
    }

    return true;
  }

  return false;
};
