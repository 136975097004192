import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { createUserErrorAdapter } from '../createUser';
import {
  UpdateCareer,
  UpdateCareerApiModel,
  UpdateCareerParams,
} from './updateCareer.model';
import { updateCareerAdapter } from './updateCareer.adapter';

export class UpdateCareerClient extends SecureRequestClient<
  UpdateCareerParams,
  UpdateCareerApiModel,
  UpdateCareer
> {
  protected path = 'user-api/v1/me/career';
  protected requestParameters: UpdateCareerParams;
  protected adapter = updateCareerAdapter;
  // TODO: Расширить класс RequestClient для типизации error response
  // @ts-ignore
  protected errorAdapter = createUserErrorAdapter;

  constructor(career: UpdateCareerParams) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
    );
    this.requestParameters = career;
  }
}
