import { breakpoint, fontRalewayBold, fontSettings, ITheme } from '@lerna-core';
import styled, { css } from 'styled-components';

const defaultTitleStyled = (theme: ITheme): ReturnType<typeof css> => css`
  ${fontRalewayBold()}
  color: ${theme.COLOR.TEXT_DARK_COLOR};
  margin: 0;
`;

export const TitleH1Styled = styled.h1`
  ${(props): ReturnType<typeof css> => defaultTitleStyled(props.theme)}
  ${(props): string => fontSettings(props.theme.FONT.SIZE_40, props.theme.FONT)}
  
  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_24, props.theme.FONT)}
      font-weight: 600;
    `
  )}
`;

export const TitleH3Styled = styled.h3`
  ${(props): ReturnType<typeof css> => defaultTitleStyled(props.theme)}
  ${(props): string => fontSettings(props.theme.FONT.SIZE_28, props.theme.FONT)}
  margin-bottom: 32px;

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_24, props.theme.FONT)}
      font-weight: 600;
      margin-bottom: 24px;
    `
  )}
`;
