import { IncomingMessage } from 'http';
import { RequestHeader } from './request.service';

export const getUserRequestHeaders = (req?: IncomingMessage): RequestHeader => {
  const headers: RequestHeader = {};
  const ip = (req?.headers?.['x-forwarded-for'] ||
    req?.socket.remoteAddress) as string;
  const requestId = req?.headers?.['x-request-id'] as string;

  if (ip) {
    headers['x-forwarded-for'] = ip;
  }

  if (requestId) {
    headers['x-request-id'] = requestId;
  }

  return headers;
};
