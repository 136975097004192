import styled from 'styled-components';
import { LegendItemColorModel } from './legendItem.model';
import { fontSettings } from '../../../../styles';

export const LegendItemStyled = styled.div`
  display: flex;
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const LegendItemColorStyled = styled.div<LegendItemColorModel>`
  border-radius: 50%;
  height: 12px;
  flex: 0 0 12px;
  margin-right: 8px;
  margin-top: 3px;
  background-color: ${({ color }): string => color};
`;

export const LegendItemTitleStyled = styled.div`
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
`;
