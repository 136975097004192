import { createSelector } from 'reselect';
import { SearchReducerState } from './search.reducer';
import { RootState } from '../../models';

/**
 * Getting from Redux search term value
 * @return {string} - search term value
 */
export const getSearchTermValueSelector = createSelector(
  (state: RootState<SearchReducerState>) => state.search,
  (reducer: SearchReducerState) => reducer.suggest
);

/**
 * Getting from Redux search popup isOpened state
 * @return {boolean}
 */
export const getSearchIsOpenedSelector = createSelector(
  (state: RootState<SearchReducerState>) => state.search,
  (reducer: SearchReducerState) => reducer.isOpened
);

/**
 * Getting from Redux search popup position style
 * @return {boolean}
 */
export const getSearchPopupStyleSelector = createSelector(
  (state: RootState<SearchReducerState>) => state.search,
  (reducer: SearchReducerState) => reducer.style
);
