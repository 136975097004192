import styled, { css } from 'styled-components';
import { getReferralPopupStyleSettings } from '../referralProgramPopup.helper';
import { breakpoint } from '@lerna-core';

export const PopupVendorsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-right: ${({ theme }): string =>
    getReferralPopupStyleSettings(theme.IS_MOBILE).paddingContent.left};
  margin-top: auto;

  svg {
    display: block;
    max-width: 100%;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: auto;
      padding-top: 60px;
      padding-right: 0;
      justify-content: flex-start;

      & > * {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    `
  )}
`;
