import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  FilterRangePrefixEnum,
  filterRouterService,
  FilterTypes,
  getFilters,
  handleTriggerFilterRange,
  Nullable,
  pushDataLayer,
  urlService,
} from '@lerna-core';
import { FilterEvent, getMainFilterEventData } from '../filters.analytics';
import { FilterTitleContainer } from '../filterTitle';
import {
  FilterRangeContainerStyled,
  FilterRangeItemsStyled,
} from './filterRange.styled';
import { FilterRangeContainerProps } from './filterRange.model';
import { FilterRangeItemContainer } from './filterRangeItem';

export const FilterRangeContainer = ({
  filter,
  withTitle,
  translations,
  defaultPathname,
  size,
  analyticsType,
}: FilterRangeContainerProps): Nullable<ReactElement> => {
  const dispatcher = useDispatch();
  const [fromValue, setFromValue] = useState<string>(filter.options.from);
  const [toValue, setToValue] = useState<string>(filter.options.to);
  const router = useRouter();
  const prefixLabelFrom = translations?.prefix_from?.toLowerCase() || '';
  const prefixLabelTo = translations?.prefix_to?.toLowerCase() || '';
  const filters = useSelector(getFilters);

  useEffect(() => {
    setFromValue(filter.options.from);
    setToValue(filter.options.to);
  }, [filter]);

  const handleChangeFrom = (val: string): void => {
    setFromValue(val);
  };

  const handleChangeTo = (val: string): void => {
    setToValue(val);
  };

  const handleQueryChange = (fromQueryValue: string, toQueryValue: string) => {
    const hasFastLink = !!router.query.fastlink;
    setFromValue(String(fromQueryValue));
    setToValue(String(toQueryValue));

    let newQuery = filterRouterService.getNewRouteRange(
      router,
      filter.slug,
      fromQueryValue,
      toQueryValue
    );

    if (hasFastLink) {
      newQuery = {
        ...newQuery,
        ...filterRouterService.getNewRouteFromFilters(
          router,
          filters.filter((currentFilter) => currentFilter.slug !== filter.slug)
        ),
      };
    }

    if (analyticsType === FilterTypes.main) {
      pushDataLayer(
        getMainFilterEventData(FilterEvent.constructorFiltersMain, filter, {
          from: fromValue,
          to: toValue,
        })
      );
    }

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
      undefined,
      { scroll: false }
    );
    dispatcher(
      handleTriggerFilterRange(
        filter.slug,
        fromQueryValue,
        toQueryValue,
        translations
      )
    );
  };

  const handleBlur = (prefix: string): void => {
    const numberFromValue =
      fromValue != '' ? Number(fromValue) : Number.MIN_VALUE;
    const numberToValue = toValue != '' ? Number(toValue) : Number.MAX_VALUE;

    let fromQueryValue = fromValue;
    let toQueryValue = toValue;

    switch (prefix) {
      case FilterRangePrefixEnum.from: {
        if (fromValue === '') {
          fromQueryValue = '';
        } else {
          fromQueryValue = String(
            Math.min(
              Number(toValue) || filter.max,
              Math.max(filter.min, numberFromValue)
            )
          );
        }
        break;
      }
      case FilterRangePrefixEnum.to: {
        if (toValue === '') {
          toQueryValue = '';
        } else {
          toQueryValue = String(
            Math.max(
              Number(fromValue) || filter.min,
              Math.min(filter.max, numberToValue)
            )
          );
        }
        break;
      }
      default:
        break;
    }

    handleQueryChange(fromQueryValue, toQueryValue);
  };

  const handleClear = (prefix: string): void => {
    let fromQueryValue = fromValue;
    let toQueryValue = toValue;

    switch (prefix) {
      case FilterRangePrefixEnum.from: {
        fromQueryValue = '';
        break;
      }
      case FilterRangePrefixEnum.to: {
        toQueryValue = '';
        break;
      }
      default:
        break;
    }

    handleQueryChange(fromQueryValue, toQueryValue);
  };

  if (!filter.isVisible) return null;

  return (
    <FilterRangeContainerStyled>
      {withTitle && <FilterTitleContainer title={filter.title} />}
      <FilterRangeItemsStyled>
        <FilterRangeItemContainer
          size={size}
          filterSlug={filter.slug}
          prefix={FilterRangePrefixEnum.from}
          prefixLabel={prefixLabelFrom}
          value={fromValue}
          units={filter.units || ''}
          placeholder={String(filter.min)}
          handleChange={handleChangeFrom}
          handleBlur={(): void => handleBlur(FilterRangePrefixEnum.from)}
          handleClear={(): void => handleClear(FilterRangePrefixEnum.from)}
        />
        <FilterRangeItemContainer
          size={size}
          filterSlug={filter.slug}
          prefix={FilterRangePrefixEnum.to}
          units={filter.units || ''}
          prefixLabel={prefixLabelTo}
          value={toValue}
          placeholder={String(filter.max)}
          handleChange={handleChangeTo}
          handleBlur={(): void => handleBlur(FilterRangePrefixEnum.to)}
          handleClear={(): void => handleClear(FilterRangePrefixEnum.to)}
        />
      </FilterRangeItemsStyled>
    </FilterRangeContainerStyled>
  );
};
