import Link from 'next/link';
import styled from 'styled-components';
import { fontSettings } from '../../../../../styles';
import { rgba } from 'polished';

export const MobileLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid
      ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
  }
`;

export const MobileLinkIconStyled = styled.div`
  margin-right: 12px;
  width: 24px;
  height: 24px;
`;
