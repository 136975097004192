import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const TableSearchStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px 14px 16px;
  flex: 1;
  box-sizing: border-box;
`;

export const TableSearchInputStyled = styled.div`
  max-width: 400px;
  width: 100%;

  input {
    border-radius: 8px;
    padding: 7px 32px 7px 12px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  }

  input + div {
    right: 12px;
  }
`;

export const TableSearchCancelStyled = styled.div`
  margin-left: 16px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
`;
