import React, { ReactElement } from 'react';
import {
  PricesTotalStyled,
  TitleStyled,
  ValueStyled,
} from './pricesTotal.styled';
import { PricesTotalContainerProps } from './pricesTotal.model';
import { Nullable } from '@lerna-core';

export const PricesTotalContainer = ({
  title,
  value,
}: PricesTotalContainerProps): Nullable<ReactElement> => {
  if (!value) return null;

  return (
    <PricesTotalStyled>
      <TitleStyled>{title}</TitleStyled>
      <ValueStyled>{value}</ValueStyled>
    </PricesTotalStyled>
  );
};
