import React, { ReactElement, useMemo } from 'react';
import { VendorsLogoContainerProps } from './vendorsLogo.model';
import { VendorEnum } from '../vendors.model';
import {
  IconContentedComponent,
  IconGeekbrainsComponent,
  IconSkillboxComponent,
  IconSkillfactoryComponent,
} from '../../icons';
import { getVendorLogoSize } from './vendorsLogo.service';
import { Nullable } from '../../../models';

export const VendorsLogoContainer = ({
  vendor,
  fillColor,
  width,
  height,
}: VendorsLogoContainerProps): Nullable<ReactElement> => {
  const iconSize = useMemo(
    () =>
      getVendorLogoSize(vendor, {
        width,
        height,
      }),
    []
  );

  if (!iconSize) {
    return null;
  }

  switch (vendor) {
    case VendorEnum.geekbrains:
      return <IconGeekbrainsComponent fillColor={fillColor} {...iconSize} />;

    case VendorEnum.skillbox:
      return <IconSkillboxComponent fillColor={fillColor} {...iconSize} />;

    case VendorEnum.skillfactory:
      return <IconSkillfactoryComponent fillColor={fillColor} {...iconSize} />;

    case VendorEnum.contented:
      return <IconContentedComponent fillColor={fillColor} {...iconSize} />;

    default:
      return null;
  }
};
