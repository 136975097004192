export class AppService {
  /**
   * Method returns is script runs on server
   * @return {boolean} - is script run on server
   */
  public static get isServerSide(): boolean {
    return AppService.detectProcess() && !AppService.detectWindow();
  }

  /**
   * Method returns is script runs on browser
   * @return {boolean} - is script run on client
   */
  public static get isClientSide(): boolean {
    return AppService.detectWindow();
  }

  private static detectProcess(): boolean {
    return !!process;
  }

  private static detectWindow(): boolean {
    return typeof window != 'undefined';
  }
}
