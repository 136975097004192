import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconFinishComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  fillColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 21.75V3.25"
        stroke="#201926"
        strokeWidth={strokeWidth}
        strokeMiterlimit="5"
        strokeLinecap="round"
      />
      <path
        d="M20.25 2.5H3.75a.75.75 0 0 0-.75.75v12.5c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75V3.25a.75.75 0 0 0-.75-.75Zm-.75 5H16v4h3.5V15H16v-3.5h-4v-4H8v4h4V15H8v-3.5H4.5v-4H8V4h4v3.5h4V4h3.5v3.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
