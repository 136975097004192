import { AxiosError } from 'axios';
import { Dispatch } from 'react';

import { logger, RequestHeader } from '../../services';
import { customerClientProvider } from './customer.clientProvider';
import { CustomerConstants } from './customer.constants';
import { CustomerActions, CustomerModel } from './customer.model';

export const requestCustomer = async (
  dispatch: Dispatch<CustomerActions>,
  uuid: string,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await customerClientProvider(uuid, requestHeaders)
    .then((response) => {
      dispatch(requestCustomerSuccess(response));
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not get customer. ${error}`);
      dispatch(requestCustomerError(error));
    });
};

export const clearCustomer =
  () =>
  (dispatch: Dispatch<CustomerActions>): void => {
    dispatch({
      type: CustomerConstants.CLEAR_CUSTOMER,
    });
  };

export const requestCustomerSuccess = (
  payload: CustomerModel
): CustomerActions => ({
  type: CustomerConstants.REQUEST_CUSTOMER_SUCCESS,
  payload,
});

export const requestCustomerError = (payload: AxiosError): CustomerActions => ({
  type: CustomerConstants.REQUEST_CUSTOMER_ERROR,
  payload,
});

export const editCustomerSuccess = (
  payload: CustomerModel
): CustomerActions => ({
  type: CustomerConstants.REQUEST_EDIT_CUSTOMER_SUCCESS,
  payload,
});
