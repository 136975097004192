import React, { ReactElement } from 'react';
import {
  TooltipBoxStyled,
  TooltipCloseStyled,
  TooltipContentStyled,
  TooltipOverlayStyled,
} from './tooltipContent.styled';
import { IconCloseComponent } from '@lerna-core';
import { TooltipContentContainerProps } from './tooltipContent.model';

export const TooltipContentContainer = ({
  content,
  handleClose,
  position,
  margin,
}: TooltipContentContainerProps): ReactElement => {
  return (
    <TooltipContentStyled position={position} margin={margin}>
      <TooltipOverlayStyled onClick={handleClose} />
      <TooltipBoxStyled>
        <TooltipCloseStyled onClick={handleClose}>
          <IconCloseComponent width={16} height={16} />
        </TooltipCloseStyled>
        {content}
      </TooltipBoxStyled>
    </TooltipContentStyled>
  );
};
