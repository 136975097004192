import React, { ReactElement, RefObject, useEffect, useRef } from 'react';

import { InputContainer } from '../input.container';
import { BaseInputProps, OnResetProps } from '../input.model';
import { TextareaStyled } from './textarea.styled';
import { FloatingPlaceholderStyled } from '../../../floatingPlaceholder';

export interface TextareaProps
  extends Omit<BaseInputProps, 'onChange' | 'onBlur'>,
    OnResetProps {
  rows?: number;
  maxLength?: number;
  showResetButton?: boolean;
  autoHeight?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
}

type TextAreaCustomRef = RefObject<HTMLTextAreaElement | HTMLInputElement>;

export const Textarea = ({
  name,
  value,
  rows = 3,
  onReset,
  showResetButton,
  autoHeight = false,
  placeholder,
  floatingPlaceholder,
  hasError,
  ...props
}: TextareaProps): ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (textareaRef?.current && autoHeight) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <InputContainer
      inputRef={textareaRef as TextAreaCustomRef}
      name={name}
      showIcon={showResetButton && !!value}
      onReset={onReset}
    >
      <TextareaStyled
        ref={textareaRef as RefObject<HTMLTextAreaElement>}
        name={name}
        rows={rows}
        value={value}
        autoHeight={autoHeight}
        placeholder={placeholder}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
        {...props}
      />

      <>
        {floatingPlaceholder ? (
          <FloatingPlaceholderStyled
            hasError={hasError}
            htmlFor={name}
            children={placeholder}
          />
        ) : null}
      </>
    </InputContainer>
  );
};
