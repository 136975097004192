import styled from 'styled-components';
import { fontSettings } from '../../../../styles';
import { EditButtonWrapperProps } from './infoPersonal.model';

export const EditButtonWrapper = styled.div<EditButtonWrapperProps>`
  margin: ${({ margin }): string => (margin ? margin : '16px 0 0')};
  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
      button {
          width: 100%;
          white-space: normal;
          text-align: left;
      }
  `
      : ''}
`;

export const LastVisitStyled = styled.div`
  font-weight: 500;
  margin-top: 8px;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const TariffStyled = styled.div`
  font-weight: 500;
  margin-top: 8px;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  a {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  b {
    font-weight: 500;
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  }
`;

export const InvitedDateWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;

  label {
    ${({ theme }): string =>
      fontSettings(
        theme.IS_MOBILE ? theme.FONT.SIZE_13 : theme.FONT.SIZE_15,
        theme.FONT
      )};
    color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  }
`;

export const InvitedDateStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }): string =>
    fontSettings(
      theme.IS_MOBILE ? theme.FONT.SIZE_15 : theme.FONT.SIZE_17,
      theme.FONT
    )};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;

  span {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: ${({ theme }): string => theme.COLOR.ERROR['900']};
  }
`;
