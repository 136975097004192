import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { useFasttrackWidget } from '../fasttrackWidget.hook';
import {
  FasttrackControlButtonStyled,
  FasttrackControlButtonWrapperStyled,
} from './fasttrackControlButton.styled';
import { FasttrackControlButtonContainerProps } from './fasttrackControlButton.model';
import { useFasttrackControlButton } from './fasttrackControlButton.hook';
import { IconArrowComponent } from '../../icons';
import { useTheme } from '../../../hooks';

export const FasttrackControlButtonContainer = ({
  translations,
}: FasttrackControlButtonContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const { showFasttrackWidget } = useFasttrackWidget();
  const { handleOpenChat } = useFasttrackControlButton();

  if (!showFasttrackWidget) return null;

  return (
    <FasttrackControlButtonWrapperStyled>
      <FasttrackControlButtonStyled onClick={handleOpenChat}>
        {translations?.write_to_support}
        <IconArrowComponent strokeColor={theme.COLOR.WHITE} />
      </FasttrackControlButtonStyled>
    </FasttrackControlButtonWrapperStyled>
  );
};
