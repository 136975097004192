import { MessagePopupStatus } from './messagePopup.component';
import React, { ReactElement } from 'react';
import {
  IconErrorComponent,
  IconSuccessComponent,
  IconWarningComponent,
} from './popupIcons';

export const getMessagePopupStatusIcon = (
  status: MessagePopupStatus,
  iconSize?: number
): ReactElement => {
  switch (status) {
    case MessagePopupStatus.success:
      return <IconSuccessComponent width={iconSize} height={iconSize} />;
    case MessagePopupStatus.warning:
      return <IconWarningComponent width={iconSize} height={iconSize} />;
    default:
      return <IconErrorComponent width={iconSize} height={iconSize} />;
  }
};
