import { publicConfig, serverConfig } from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';

export class CustomerActivityClient extends SecureRequestClient<
  null,
  null,
  null
> {
  protected path = `personal-api/v1/me/update-last-activity`;
  protected requestParameters = null;

  constructor(cookie?: string) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      cookie ? { cookie } : {}
    );
  }
}
