import { RequestErrorAdapter } from '../request.service';
import { RequestErrorMessages } from '../request.enum';
import { logger } from '../../logger/logger.service';
import { AxiosError } from 'axios';

export const commonErrorAdapter: RequestErrorAdapter<AxiosError, void> = (
  error
) => {
  const err = error.toJSON() as AxiosError;
  if (error.message !== RequestErrorMessages.abort) {
    logger.error(`Status: ${err.status}. Message: ${err.message}`, err);
  }

  return Promise.reject(error);
};
