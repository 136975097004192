import React, { forwardRef, ReactChild, ReactElement, RefObject } from 'react';

import { useClickOutsideHook } from '../../../hooks';
import { SelectDropdownStyled } from './selectDropdown.styled';
import { Nullable } from '../../../models';

export type SelectOptionsContainerTypes = {
  width?: number;
  children: ReactChild;
  handleOutsideClick: () => void;
};

export const SelectDropdownContainer = forwardRef<
  HTMLDivElement,
  SelectOptionsContainerTypes
>(
  (
    { width, children, handleOutsideClick },
    forwardedRef
  ): Nullable<ReactElement> => {
    useClickOutsideHook(
      forwardedRef as RefObject<HTMLDivElement>,
      handleOutsideClick
    );

    return (
      <SelectDropdownStyled width={width}>{children}</SelectDropdownStyled>
    );
  }
);

SelectDropdownContainer.displayName = 'SelectDropdownContainer';
