import { ReactElement } from 'react';
import { TabsProps } from './tabs.model';
import { TabsStyled } from './tabs.styled';
import { TabItemContainer } from './tabsItem';

export const Tabs = ({
  tabs,
  className,
  lineGap = 14,
}: TabsProps): ReactElement => (
  <TabsStyled lineGap={lineGap} className={className}>
    {tabs.map((tab, index) => (
      <TabItemContainer {...tab} activeLineGap={lineGap} key={index} />
    ))}
  </TabsStyled>
);
