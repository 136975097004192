import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { SimilarItemContainer } from '../similarItem';
import { SimilarItemsContainerProps } from './similarItems.model';
import { SimilarItemsContainerStyled } from './similarItems.styled';
import { PaperFooterContainer } from '../../coursePaper';
import { Nullable } from '@lerna-core';

const COURSES_LIMIT = 2;

export const SimilarItemsContainer = ({
  similarCourses,
  translations,
  showMore,
  additionalCourses,
  isPricesHiddenOnListingCards,
}: SimilarItemsContainerProps): Nullable<ReactElement> => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    COURSES_LIMIT < similarCourses.pagination.total
  );
  const showFooterControl = useMemo(
    () => currentPage < similarCourses.pagination.totalPages || isCollapsed,
    [currentPage, isCollapsed]
  );
  const items = useMemo(() => {
    if (isCollapsed) {
      return similarCourses.data.slice(0, COURSES_LIMIT);
    }

    return [...similarCourses.data, ...additionalCourses];
  }, [isCollapsed, additionalCourses]);

  const handleClickShowMore = (): void => {
    if (isCollapsed) {
      setIsCollapsed(false);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => showMore(currentPage), [currentPage]);

  return (
    <>
      <SimilarItemsContainerStyled>
        {items.map((course) => (
          <SimilarItemContainer
            key={course.uuid}
            course={course}
            translations={translations}
            isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
          />
        ))}
      </SimilarItemsContainerStyled>
      {showFooterControl && (
        <PaperFooterContainer
          handleClick={handleClickShowMore}
          label={translations.show_more_label}
        />
      )}
    </>
  );
};
