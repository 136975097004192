import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const FormContentWrapperStyled = styled.div``;

export const FormContentStyled = styled.div`
  display: flex;
  position: relative;

  ${breakpoint(
    'largeDown',
    css`
      flex-direction: column;
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      padding: 28px 20px;
    `
  )}
`;
