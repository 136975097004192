import React, { ReactElement, useMemo } from 'react';
import { CourseOptionLogicTypes, getCourseOption } from '@lerna-core';
import { ListItemPropsContainer } from '../listItemProps';
import { ListItemTitleContainer } from '../listItemTitle';
import { ItemInnerRowStyled } from '../listItem.styled';
import { ListItemVendorContainer } from '../listItemVendor';
import { ListItemLogoContainer } from '../listItemLogo';
import { ListItemHeaderContainerProps } from './liItemHeader.model';
import { ItemHeaderSphereContainer } from './itemHeaderSphere';
import {
  ListItemHeaderContainerStyled,
  ListItemHeaderTitleStyled,
} from './listItemHeader.styled';

export const ListItemHeaderContainer = ({
  course,
  translations,
  withSphereFilter,
  discountProgramColors,
}: ListItemHeaderContainerProps): ReactElement => {
  const sphere = useMemo(
    () => getCourseOption(course.options, CourseOptionLogicTypes.spheres),
    [course]
  );

  return (
    <ListItemHeaderContainerStyled hasSphere={!!sphere}>
      {!!sphere && (
        <ItemHeaderSphereContainer
          sphere={sphere}
          withSphereFilter={withSphereFilter}
          sphereColor={discountProgramColors?.secondaryColor}
        />
      )}
      <ItemInnerRowStyled>
        <ListItemHeaderTitleStyled isMobile={false}>
          <ListItemVendorContainer
            vendor={course.vendor}
            vendorLabel={course.vendorLabel}
          />
          <ListItemTitleContainer text={course.title} />
          <ListItemPropsContainer course={course} translations={translations} />
        </ListItemHeaderTitleStyled>
        {course.logo && (
          <ListItemLogoContainer
            logo={course.logo}
            logoLabel={course.logoLabel}
            isHit={course.isHit}
            translations={translations}
          />
        )}
      </ItemInnerRowStyled>
    </ListItemHeaderContainerStyled>
  );
};
