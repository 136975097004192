import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PricesTotalStyled = styled.div`
  display: flex;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)}
  margin-bottom: 16px;

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
    `
  )}
`;

export const ValueWithDiscountStyled = styled.div`
  font-weight: 600;

  ${breakpoint(
    'mediumDown',
    css`
      order: 2;
    `
  )}
`;

export const ValueStyled = styled.div`
  text-decoration: line-through;
  margin-left: 16px;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  ${breakpoint(
    'mediumDown',
    css`
      order: 1;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
      margin-left: 0;
      margin-bottom: 8px;
    `
  )}
`;
