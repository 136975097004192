import { ReactElement } from 'react';

export enum TagLabelColor {
  white = 'white',
  grey = 'grey',
  blue = 'blue',
  green = 'green',
  gradient = 'gradient',
  gradientBorder = 'gradientBorder',
  subscription = 'subscription',
  rose = 'rose',
  orange = 'orange',
}

export enum TagLabelSize {
  big = 'big',
  normal = 'normal',
  small = 'small',
  xsmall = 'xsmall',
}

export enum TagLabelIconPosition {
  left = 'left',
  right = 'right',
}

export type TagLabelContainerProps = {
  label: string | ReactElement;
  noWrap?: boolean;
  borderRadius?: number;
  color?: TagLabelColor;
  size?: TagLabelSize;
  icon?: ReactElement;
  iconPosition?: TagLabelIconPosition;
};
