import { useEffect, useRef, useState } from 'react';
import { Nullable } from '../../../models';
import { ElementPropertiesModel } from '../onboarding.model';
import { UsePopupPropertiesReturnType } from './onboardingPopup.model';

const DEFAULT_POPUP_PROPERTIES: ElementPropertiesModel = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};

export const usePopupProperties = (): UsePopupPropertiesReturnType => {
  const popupRef = useRef<Nullable<HTMLDivElement>>(null);
  const [popupProperties, setPopupProperties] =
    useState<ElementPropertiesModel>(DEFAULT_POPUP_PROPERTIES);

  useEffect(() => {
    if (!popupRef.current) {
      return;
    }

    const rect = popupRef.current.getBoundingClientRect();

    setPopupProperties({
      width: rect.width,
      height: rect.height,
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  }, [popupRef]);

  return { popupRef, popupProperties };
};
