import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../../styles';

export const ContactsLinkStyled = styled.div`
  display: flex;
  flex-direction: column;

  a {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.WHITE};
  text-decoration: none;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
