import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconDocumentWithArrowCheckComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6471 7.29412V4.35294C19.6471 3.05345 18.5936 2 17.2941 2H4.35294C3.05345 2 2 3.05345 2 4.35294V19.6471C2 20.9466 3.05345 22 4.35294 22H11.4118"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.70581 6.70605H14.9411"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.70557 10.8237L11.4114 10.8237"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6666 16.6665L16.6666 19.6665L22.6666 13.6665"
      stroke={strokeColor}
      strokeWidth="2.30769"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
