import React, { ReactElement } from 'react';
import { ToggleButtonStyled } from './toggleButton.styled';
import { ToggleButtonPropsModel } from './toggleButton.model';

export const ToggleButtonContainer = ({
  item,
  isActive,
  borderRadius,
  handleClick,
}: ToggleButtonPropsModel): ReactElement => {
  const handleItemClick = (): void => {
    if (!isActive) {
      handleClick(item);
    }
  };

  return (
    <ToggleButtonStyled
      onClick={handleItemClick}
      borderRadius={borderRadius}
      isActive={isActive}
    >
      {item.component}
    </ToggleButtonStyled>
  );
};
