import React, { ReactElement } from 'react';

import { AdminContentModel } from './adminContent.model';
import { AdminContentStyled } from './adminContent.styled';
import { SectionContainer } from './section';
import { SidebarContainer } from './sidebar';

export const AdminContentContainer = ({
  sidebar,
  section,
  heading,
  count,
  isMobile,
  appSwitcherLinks,
}: AdminContentModel): ReactElement => (
  <AdminContentStyled>
    <SidebarContainer sidebar={sidebar} />
    <SectionContainer
      appSwitcherLinks={appSwitcherLinks}
      isMobile={isMobile}
      heading={heading}
      count={count}
      section={section}
    />
  </AdminContentStyled>
);
