import { TranslationModel } from '@lerna-core';

export const getStatusMessage = (
  isSuccessDisplayed: boolean,
  isErrorDisplayed: boolean,
  isExistOrder: boolean,
  translations: TranslationModel,
  isFreeCourse: boolean,
  isSkillboxCourse: boolean
): Record<string, string> => {
  const freeCourseStatusSubtitle = isSkillboxCourse
    ? translations?.guest_order_form_free_course_skillbox_subtitle
    : translations?.guest_order_form_free_course_subtitle;

  switch (true) {
    case isSuccessDisplayed:
      return {
        title: translations?.sign_up_default_success_title_description,
        subtitle: isFreeCourse
          ? freeCourseStatusSubtitle
          : translations?.sign_up_default_success_subtitle_description,
      };
    case isExistOrder:
      return {
        title: translations?.order_exist_message_title,
        subtitle: isFreeCourse
          ? freeCourseStatusSubtitle
          : translations?.order_exist_message_subtitle,
      };
    case isErrorDisplayed:
    default:
      return {
        title: translations?.popup_error_message_title,
        subtitle: translations?.popup_error_message_description,
      };
  }
};
