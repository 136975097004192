import React, { ReactElement } from 'react';
import {
  TableEmptyStyled,
  TableEmptyText,
  TableEmptyTitle,
} from './tableEmpty.styled';
import { TableEmptyContainerProps } from './tableEmpty.model';

export const TableEmptyContainer = ({
  emptyContent,
}: TableEmptyContainerProps): ReactElement => {
  return (
    <TableEmptyStyled>
      {emptyContent.title && (
        <TableEmptyTitle>{emptyContent.title}</TableEmptyTitle>
      )}
      {emptyContent.text && (
        <TableEmptyText>{emptyContent.text}</TableEmptyText>
      )}
    </TableEmptyStyled>
  );
};
