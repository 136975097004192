import styled, { css } from 'styled-components';

import { fontSettings } from '../../../styles';

export const Label = styled.label`
  display: inline-block;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${(props): ReturnType<typeof css> =>
    props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin: 0 20px 4px;
  font-weight: 500;
`;
