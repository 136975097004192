import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconWalletGradientComponent = ({
  width = 40,
  height = 40,
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="url(#paint0_linear_1748_59995)"
      fillRule="evenodd"
      d="M29.153 9.409v-2.5l-14.1 3.71h16.861A4.084 4.084 0 0136 14.704v17.211A4.084 4.084 0 0131.914 36H8.084a4.084 4.084 0 01-4.085-4.085v-18.21a4.084 4.084 0 013.046-3.95l21.518-5.663a2.76 2.76 0 013.464 2.67V9.41h-2.874zm-22.28 5.295v17.211c0 .67.542 1.21 1.21 1.21h23.831c.67 0 1.21-.54 1.21-1.21v-17.21c0-.67-.54-1.211-1.21-1.211H8.084c-.67 0-1.21.541-1.21 1.21z"
      clipRule="evenodd"
    ></path>
    <path
      fill="url(#paint1_linear_1748_59995)"
      d="M28.604 21.324a1.986 1.986 0 100 3.971 1.986 1.986 0 000-3.971z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_1748_59995"
        x1="3.999"
        x2="35.999"
        y1="25.2"
        y2="15.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1748_59995"
        x1="3.999"
        x2="35.999"
        y1="25.2"
        y2="15.6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
    </defs>
  </svg>
);
