import React, { ReactElement } from 'react';
import { Nullable } from '../../../../../models';
import { LinksParentContainer } from './linksParent';
import { MobileDynamicLinksContainerProps } from './mobileDynamicLinks.model';

export const MobileDynamicLinksContainer = ({
  menu,
  handleClose,
  projectPageName,
}: MobileDynamicLinksContainerProps): Nullable<ReactElement> => {
  if (!menu) return null;

  return (
    <>
      {menu.map((parent) => (
        <LinksParentContainer
          key={parent.title}
          parent={parent}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </>
  );
};
