import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTheme } from '../../../hooks';
import { pushDataLayer } from '../../../services';
import { ButtonColor, ButtonContainer } from '../../button';
import { IconArrowLongComponent, IconCloseComponent } from '../../icons';
import { usePopupProperties } from './onboardingPopup.hooks';
import { OnboardingPopupContainerProps } from './onboardingPopup.model';
import {
  ContentMessageStyled,
  FooterPaginationStyled,
  OnboardingPopupStyled,
  PaginationItemStyled,
  PopupCloseStyled,
  PopupContentStyled,
  PopupFooterStyled,
} from './onboardingPopup.styled';

export const OnboardingPopupContainer = ({
  step,
  withCounter,
  stepsCount,
  activeStepIndex,
  targetProperties,
  gap,
  setActiveStepIndex,
  handleClose,
  translations,
}: OnboardingPopupContainerProps): ReactElement => {
  const theme = useTheme();
  const { popupProperties, popupRef } = usePopupProperties();

  const isLastStep = activeStepIndex === stepsCount - 1;
  const buttonTitle = isLastStep
    ? translations?.clear || translations?.understand
    : translations?.forward;

  const paginationItems = withCounter
    ? Array(stepsCount)
        .fill(0)
        .map((_, index) => (
          <PaginationItemStyled
            isActive={index <= activeStepIndex}
            key={index}
          />
        ))
    : null;

  const handleClick = () => {
    if (isLastStep) {
      if (step.events?.close) {
        pushDataLayer(step.events.close);
      }
      handleClose();
    } else {
      if (step.events?.next) {
        pushDataLayer(step.events.next);
      }
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  const onCloseClick = () => {
    if (step.events?.close) {
      pushDataLayer(step.events.close);
    }
    handleClose();
  };

  const messages = useMemo(() => {
    if (step.content instanceof Array) {
      return step.content.map((message, index) => (
        <ContentMessageStyled
          dangerouslySetInnerHTML={{ __html: message }}
          key={index}
        />
      ));
    }

    return (
      <ContentMessageStyled
        dangerouslySetInnerHTML={{ __html: step.content }}
      />
    );
  }, [step]);

  useEffect(() => {
    if (!step.events?.open) return;

    pushDataLayer(step.events.open);
  }, [step]);

  return (
    <OnboardingPopupStyled
      placement={step.placement}
      ref={popupRef}
      gap={gap}
      targetProperties={targetProperties}
      popupProperties={popupProperties}
    >
      <PopupCloseStyled onClick={onCloseClick}>
        <IconCloseComponent
          width={20}
          height={20}
          strokeColor={theme.COLOR.TEXT['100']}
        />
      </PopupCloseStyled>

      <PopupContentStyled>{messages}</PopupContentStyled>

      <PopupFooterStyled>
        <ButtonContainer
          borderRadius={100}
          color={ButtonColor.brightPurple}
          title={buttonTitle}
          onClick={handleClick}
        >
          {!isLastStep ? (
            <IconArrowLongComponent strokeColor={theme.COLOR.PRIMARY['500']} />
          ) : undefined}
        </ButtonContainer>

        {withCounter && (
          <FooterPaginationStyled stepsCount={stepsCount}>
            {paginationItems}
          </FooterPaginationStyled>
        )}
      </PopupFooterStyled>
    </OnboardingPopupStyled>
  );
};
