import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const PageNotFoundStyled = styled.div`
  margin: 100px 0 96px 0;

  ${breakpoint(
    'smallDown',
    css`
      margin: 60px 0 80px;
    `
  )}
`;

export const PageNotFoundTitleStyled = styled.h1`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_48, props.theme.FONT)};
  line-height: 56px;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 700;

  margin-bottom: 28px;

  ${breakpoint(
    'smallDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_34, props.theme.FONT)};
      line-height: 40px;
    `
  )}
`;

export const PageNotFoundTextStyled = styled.span`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  font-weight: 500;
`;

export const PageNotFoundLinkContainerStyled = styled.div`
  margin: 16px 0 120px;

  ${breakpoint(
    'smallDown',
    css`
      margin-bottom: 80px;
    `
  )}
`;

export const PageNotFoundLinkStyled = styled.span`
  color: ${({ theme }): string => theme.COLOR.PURPLE};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};

  font-weight: 500;
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${({ theme }): string => theme.COLOR.PURPLE};

    &:visited {
      color: ${({ theme }): string => theme.COLOR.PURPLE};
    }
  }
`;

export const PageNotFoundFilterTitleStyled = styled.span`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_22, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};

  font-weight: 600;
`;

export const PageNotFoundFilterStyled = styled.div`
  max-width: 968px;
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

export const PageNotFoundFilterOptionStyled = styled.div`
  margin: 12px 12px 0 0;

  ${breakpoint(
    'smallDown',
    css`
      flex: 0 0 100%;
      margin: 12px 0 0 0;

      > button {
        width: 100%;
        justify-content: space-between;
      }
    `
  )}
`;
