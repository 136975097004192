import React, { ReactElement } from 'react';
import { InputColor } from '../../../../styles';
import { SelectContainer, SelectOption } from '../../../select';
import { GenericFormControlStyled } from '../genericFormControl';
import { InputErrorStyled, InputLabelStyled } from '../../../inputs';

type SelectFormControlContainerProps = {
  errors: string[];
  label?: string | ReactElement;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  options: SelectOption[] | undefined;
  placeholder: string | undefined;
  bordered?: boolean;
  disabled?: boolean;
  color?: InputColor;
  defaultOption: SelectOption;
  floatingPlaceholder: boolean;
};

const getErrorsComponents = (errors: string[]): ReactElement[] => {
  return errors.map((error) => (
    <InputErrorStyled key={error}>{error}</InputErrorStyled>
  ));
};

export const SelectFormControlContainer = ({
  errors,
  label,
  options,
  placeholder,
  bordered,
  disabled,
  onChange,
  onBlur,
  color,
  defaultOption,
  floatingPlaceholder,
}: SelectFormControlContainerProps): ReactElement | null => {
  if (!options) {
    return null;
  }

  return (
    <GenericFormControlStyled>
      {label && !floatingPlaceholder && (
        <InputLabelStyled>{label}</InputLabelStyled>
      )}
      <SelectContainer
        options={options}
        onChange={onChange}
        bordered={bordered}
        disabled={disabled}
        placeholder={floatingPlaceholder ? label : placeholder}
        onBlur={onBlur}
        color={color}
        defaultOption={defaultOption}
        floatingPlaceholder={floatingPlaceholder}
        hasError={Boolean(errors.length)}
      />
      {getErrorsComponents(errors)}
    </GenericFormControlStyled>
  );
};
