import React, { ReactElement, useMemo } from 'react';
import { MobileLinkIconStyled, MobileLinkStyled } from './mobileLink.styled';
import { MobileLinkContainerProps } from './mobileLink.model';
import { MenuIconContainer } from '../../../../menu';
import { useTheme } from '../../../../../hooks';
import { getUserMenuLinkPath } from '../../userMenu.helpers';
import { handleUserMenuDataLayer } from '../../userMenu.analytics';
import { UserMenuTypes } from '../../userMenu.constants';
import { publicConfig } from '../../../../../services';

export const MobileLinkContainer = ({
  title,
  link,
  icon,
  linkType,
  parentType,
  handleClose,
  projectPageName,
}: MobileLinkContainerProps): ReactElement => {
  const theme = useTheme();

  const linkPath = useMemo(() => getUserMenuLinkPath(link, parentType), []);

  const handleClick = (): void => {
    handleClose && handleClose();
    handleUserMenuDataLayer(
      UserMenuTypes.mobile,
      link,
      linkType,
      parentType,
      publicConfig?.APP_TYPE,
      projectPageName
    );
  };

  return (
    <MobileLinkStyled href={linkPath} onClick={handleClick}>
      <MobileLinkIconStyled>
        <MenuIconContainer icon={icon} theme={theme} />
      </MobileLinkIconStyled>
      {title}
    </MobileLinkStyled>
  );
};
