import {
  cookieService,
  dateService,
  setConsentModeData,
} from '../../../services';
import { COOKIES_MANAGEMENT_COOKIE_NAME } from './notificationManagement.constant';
import { CookiePermissionsModel } from './notificationManagement.model';
import { Nullable } from '../../../models';

export const setCookiePermissions = (
  permissions: CookiePermissionsModel
): void => {
  cookieService.setCookie({
    name: COOKIES_MANAGEMENT_COOKIE_NAME,
    val: JSON.stringify(permissions),
    days: dateService.yearInDays,
  });
  setConsentModeData(permissions);
};

export const getCookiePermissions = (
  cookie?: string
): Nullable<CookiePermissionsModel> => {
  const cookiePermissions = cookieService.getCookie(
    COOKIES_MANAGEMENT_COOKIE_NAME,
    cookie
  );

  if (cookiePermissions) {
    return JSON.parse(cookiePermissions) as CookiePermissionsModel;
  }

  return null;
};

export const isNotificationVisible = (): boolean => {
  const permissions = getCookiePermissions();
  return !permissions;
};
