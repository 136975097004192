import React, { ReactElement, useState } from 'react';

import { useControl } from '../../../hooks';
import { InputColors } from '../../../styles';
import { ButtonColor, ButtonContainer } from '../../button';
import { DepartmentsSelectContainer } from '../../departments';
import { selectValidations } from '../../form';
import { GenericFormControlContainer } from '../../form';
import { PaperComponentStyled } from '../../paper/paper.styled';
import { MessagePopupComponent, PopupContainer } from '../../popups';
import { FormPopupColumn } from '../../popups/formPopup/formPopup.styled';
import { SelectOption } from '../../select';
import { useTranslations } from '../../translations';
import { PositionSelectContainer, useUser } from '../../user';
import { FormUserEditStyled } from '../../user/userEdit/editForm/formUserEdit.styled';
import { customerEditCompanyMapValidationErrors } from './customerEditCompany.helper';
import { EditCustomerCompanyContainerProps } from './customerEditCompany.model';
import { Nullable } from '../../../models';

const MAX_ATTEMPTS = 3;
const EMPTY = '';

export const CustomerEditCompanyContainer = ({
  personal,
  career,
  requestClientProvider,
  requestHost,
  reloadCustomer,
  onSuccessRequest,
}: EditCustomerCompanyContainerProps): ReactElement => {
  const translations = useTranslations();

  const [isEnabledBtn, setIsEnabledBtn] = useState(true);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(0);

  const [
    positionValue,
    positionErrors,
    isPositionValid,
    handlePositionChanged,
    handlePositionBlur,
    handleSetPositionErrors,
  ] = useControl(career?.position?.uuid, selectValidations);
  const [
    departmentValue,
    departmentErrors,
    isDepartmentValid,
    handleDepartmentChanged,
    handleDepartmentBlur,
    handleSetDepartmentErrors,
  ] = useControl(career?.department?.uuid, selectValidations);

  const { user } = useUser();

  const defaultDepartment: SelectOption = {
    key: career?.department?.uuid || EMPTY,
    value: career?.department?.title || EMPTY,
  };

  const defaultPosition: SelectOption = {
    key: career?.position?.uuid || EMPTY,
    value: career?.position?.title || EMPTY,
  };

  const closeError = (): void => {
    setIsErrorDisplayed(false);
    setAttemptNumber(0);
  };

  const closeSuccess = (): void => {
    setIsSuccessDisplayed(false);
    setAttemptNumber(0);

    if (user?.personal?.uuid === personal.uuid) {
      reloadCustomer();
    }
  };

  const errorButtonTitle: Nullable<string> =
    attemptNumber < MAX_ATTEMPTS
      ? translations?.button_resend_title || null
      : null;

  const isFormValid: boolean = isDepartmentValid && isPositionValid;

  const sendRequest = async (): Promise<void> => {
    setIsErrorDisplayed(false);
    setIsEnabledBtn(false);

    await requestClientProvider(personal.uuid, positionValue, departmentValue)
      .then((response): void => {
        if (response) {
          onSuccessRequest(response);
        }
        setIsSuccessDisplayed(true);
      })
      .catch((error) => {
        const isMapped = customerEditCompanyMapValidationErrors(
          error,
          handleSetPositionErrors,
          handleSetDepartmentErrors
        );
        if (!isMapped) {
          setIsErrorDisplayed(true);
          setAttemptNumber(attemptNumber + 1);
        }
      })
      .finally(() => {
        setIsEnabledBtn(true);
      });
  };

  const isReadyToSend: boolean =
    isFormValid && !!positionValue && !!departmentValue && isEnabledBtn;

  const doNothing = (): void => {
    //just for elements without changing functionality
  };

  return (
    <FormUserEditStyled>
      <PaperComponentStyled>
        <FormPopupColumn>
          <GenericFormControlContainer
            errors={[]}
            onChange={doNothing}
            onBlur={doNothing}
            initialValue={career?.companyTitle || ''}
            label={translations?.company_input_label}
            placeholder={translations?.company_input_label}
            name="company"
            isRequired={false}
            disabled={true}
            bordered
            color={InputColors.white}
            clearControl={false}
          />
        </FormPopupColumn>
        <FormPopupColumn>
          <DepartmentsSelectContainer
            name="department_uuid"
            requestHost={requestHost}
            companyId={career?.companyId}
            errors={departmentErrors}
            onChange={handleDepartmentChanged}
            onBlur={handleDepartmentBlur}
            initialValue={defaultDepartment?.value || EMPTY}
            defaultOption={
              defaultDepartment?.value ? defaultDepartment : undefined
            }
            bordered
            color={InputColors.white}
            label={translations?.customer_profile_department_label}
            placeholder={translations?.placeholder_department}
          />
        </FormPopupColumn>
        <FormPopupColumn>
          <PositionSelectContainer
            name="position_uuid"
            requestHost={requestHost}
            companyId={career?.companyId}
            errors={positionErrors}
            onChange={handlePositionChanged}
            onBlur={handlePositionBlur}
            initialValue={defaultPosition?.value || EMPTY}
            defaultOption={defaultPosition?.value ? defaultPosition : undefined}
            label={translations?.customer_profile_position_title}
            placeholder={translations?.placeholder_position}
            bordered
            color={InputColors.white}
          />
        </FormPopupColumn>

        <ButtonContainer
          color={ButtonColor.purple}
          disabled={!isReadyToSend}
          onClick={sendRequest}
          borderRadius={100}
        >
          <>{translations?.button_save_changes_label}</>
        </ButtonContainer>
      </PaperComponentStyled>
      <PopupContainer
        handleClose={closeError}
        isOpened={isErrorDisplayed}
        hasHeader={false}
      >
        <MessagePopupComponent
          title={translations?.popup_error_edit_title}
          buttonTitle={errorButtonTitle}
          buttonCallback={sendRequest}
        />
      </PopupContainer>
      <PopupContainer
        handleClose={closeSuccess}
        isOpened={isSuccessDisplayed}
        hasHeader={false}
      >
        <MessagePopupComponent
          title={translations?.popup_success_edit_title}
          buttonTitle={translations?.button_ok_title}
          buttonCallback={closeSuccess}
        />
      </PopupContainer>
    </FormUserEditStyled>
  );
};
