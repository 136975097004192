import React, { ReactElement } from 'react';
import { CommonEventTypes, Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import { useCustomCompensationFormContext } from '../customCompensationForm.context';
import { PopupContentContainer } from './popupContent';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';

export const FormCommentPopupContainer = (): Nullable<ReactElement> => {
  const theme = useTheme();
  const {
    isCommentPopupOpened,
    toggleCommentPopupOpened,
    eventPosition,
    course,
  } = useCustomCompensationFormContext();

  const handleCloseCommentPopup = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollCourseCloseMotivationLetter,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course
    );

    toggleCommentPopupOpened();
  };

  if (!isCommentPopupOpened) return null;

  return (
    <CenteredPopupContainer
      isOpen={isCommentPopupOpened}
      handleClose={handleCloseCommentPopup}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
      withScrollbarHide={false}
    >
      <PopupContentContainer />
    </CenteredPopupContainer>
  );
};
