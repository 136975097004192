import {
  StaticsApiModel,
  StaticsErrorModel,
  StaticsModel,
} from './statics.model';
import { checkError } from '../../services/aggregateRequests';
import { AggregateResponseApiErrorModel } from '../../models';
import { footerAdapter } from '../footer';
import { fastLinksAdapter } from '../../catalog/fastLinks';

export const staticsAdapter = (
  response: StaticsApiModel
): StaticsModel | StaticsErrorModel => {
  const { data } = response;
  const statusCode = checkError(
    data as unknown as AggregateResponseApiErrorModel,
    ['translations', 'footer']
  );
  const footer = footerAdapter(data.footer);
  const translations = data.statics.data.translations;

  if (statusCode) {
    return {
      statusCode,
      translations,
      footer,
    };
  }
  const fastLinks = fastLinksAdapter(data.fastlinks?.data || []);

  return {
    footer,
    translations,
    fastLinks,
  };
};
