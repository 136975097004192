import { CurrenciesActions, CurrenciesState } from './currencies.model';
import * as constants from './currencies.constants';
import { CurrencyModel } from '../../models';

const initialState: CurrenciesState = {
  currencies: [],
  status: {
    isLoaded: false,
    isLoading: false,
  },
};

export const currenciesReducer = (
  state: CurrenciesState = initialState,
  action: CurrenciesActions
): CurrenciesState => {
  switch (action.type) {
    case constants.REQUEST_CURRENCIES_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
        },
      };

    case constants.REQUEST_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload as CurrencyModel[],
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case constants.REQUEST_CURRENCIES_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};
