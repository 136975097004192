import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { currenciesApiAdapter } from './currencies.adapter';
import { Currency, CurrenciesApi } from './currencies.model';

export class CurrenciesClient extends SecureRequestClient<
  null,
  CurrenciesApi,
  Currency[]
> {
  protected path = 'course-api/v1/currencies';
  protected requestParameters: null;
  protected adapter = currenciesApiAdapter;

  constructor(
    host = publicConfig.API_GATEWAY_URL,
    privateHost = serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
  ) {
    super(host, privateHost, {}, { withCredentials: false });
    this.requestParameters = null;
  }
}
