import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMoreComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33187 11.6667C2.95011 11.6667 0.998535 13.619 0.998535 16C0.998535 18.3813 2.95058 20.3333 5.33187 20.3333C7.71316 20.3333 9.6652 18.3813 9.6652 16C9.6652 13.6187 7.71316 11.6667 5.33187 11.6667ZM15.9985 11.6667C13.6168 11.6667 11.6652 13.619 11.6652 16C11.6652 18.3813 13.6172 20.3333 15.9985 20.3333C18.3798 20.3333 20.3319 18.3813 20.3319 16C20.3319 13.6187 18.3798 11.6667 15.9985 11.6667ZM26.6652 11.6667C24.2834 11.6667 22.3319 13.619 22.3319 16C22.3319 18.3813 24.2839 20.3333 26.6652 20.3333C29.047 20.3333 30.9985 18.3811 30.9985 16C30.9985 13.6187 29.0465 11.6667 26.6652 11.6667ZM5.33187 13.6667C6.63191 13.6667 7.6652 14.7 7.6652 16C7.6652 17.3 6.63191 18.3333 5.33187 18.3333C4.03183 18.3333 2.99854 17.3 2.99854 16C2.99854 14.6997 4.03096 13.6667 5.33187 13.6667ZM15.9985 13.6667C17.2986 13.6667 18.3319 14.7 18.3319 16C18.3319 17.3 17.2986 18.3333 15.9985 18.3333C14.6985 18.3333 13.6652 17.3 13.6652 16C13.6652 14.6997 14.6976 13.6667 15.9985 13.6667ZM26.6652 13.6667C27.9652 13.6667 28.9985 14.7 28.9985 16C28.9985 17.3003 27.9661 18.3333 26.6652 18.3333C25.3652 18.3333 24.3319 17.3 24.3319 16C24.3319 14.6997 25.3643 13.6667 26.6652 13.6667Z"
      fill={strokeColor}
    />
  </svg>
);
