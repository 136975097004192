import { PopupStylePaddingProps, PopupStyleProps } from '../../popups';

export const modalPaddings: PopupStyleProps = {
  paddingSticky: {
    top: '32px',
    right: '40px',
    bottom: '32px',
    left: '40px',
  },
  paddingContent: {
    top: '32px',
    right: '40px',
    bottom: '32px',
    left: '40px',
  },
};

export const modalContentPadding: PopupStylePaddingProps = {
  top: '53px',
  left: '20px',
  right: '20px',
  bottom: '20px',
};
