import React, { ReactElement } from 'react';
import { useTheme } from '../../../../hooks';

import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
  ButtonSize,
} from '../../../button';
import { IconEditComponent } from '../../../icons';
import { useTranslations } from '../../../translations';
import { EditFormButtonStyled } from './editFormButton.styled';
import { EditUserButtonComponentProps } from './editFormButton.model';

export const EditFormButtonContainer = ({
  handleClick,
  title = '',
}: EditUserButtonComponentProps): ReactElement => {
  const translations = useTranslations();
  const theme = useTheme();

  return (
    <ButtonContainer
      color={ButtonColor.brightPurple}
      bordered
      onClick={handleClick}
      childrenPosition={ButtonChildrenPosition.left}
      title={title || translations.customer_profile_edit_profile_title}
    >
      <IconEditComponent strokeColor={theme.COLOR.PRIMARY['500']} />
    </ButtonContainer>
  );
};

export const EditUserSmallButtonComponent = ({
  handleClick,
}: EditUserButtonComponentProps): ReactElement => {
  return (
    <ButtonContainer
      size={ButtonSize.small}
      color={ButtonColor.white}
      bordered
      borderRadius={8}
      onClick={handleClick}
      childrenPosition={ButtonChildrenPosition.left}
    >
      <EditFormButtonStyled>
        <IconEditComponent height={16} width={16} />
      </EditFormButtonStyled>
    </ButtonContainer>
  );
};
