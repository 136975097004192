import React, {
  KeyboardEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTheme } from '../../../hooks';
import { InputColor } from '../../../styles';
import { FloatingPlaceholderStyled, IconCloseComponent } from '../../index';
import {
  GenericInputClearStyled,
  GenericInputStyled,
  GenericInputWrapStyled,
  InputSizes,
} from './genericInput.styled';
import { Nullable } from '../../../models';

type GenericInputContainerProps = {
  type?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  formatValue?: (value: string) => string;
  deformatValue?: (value: string) => string;
  initialValue?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  size?: string;
  name: string;
  autoComplete?: string;
  clearControl?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  color?: InputColor;
  maxlength?: number;
  autoFocus?: boolean;
  onClear?: () => void;
  className?: string;
  selectInitialValue?: boolean;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
};

export const GenericInputContainer = ({
  placeholder = '',
  type = 'text',
  onChange,
  initialValue = '',
  onBlur,
  onKeyDown,
  deformatValue,
  formatValue,
  onFocus,
  size = InputSizes.normal,
  clearControl = true,
  name,
  autoComplete = 'on',
  maxlength,
  color,
  bordered = false,
  disabled,
  autoFocus,
  onClear,
  className,
  selectInitialValue,
  floatingPlaceholder,
  hasError,
}: GenericInputContainerProps): ReactElement => {
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);
  const theme = useTheme();
  const handleDeformatValue = (value: string): string => {
    if (deformatValue) {
      return deformatValue(value);
    }

    return value;
  };

  const handleFormatValue = (value: string): string => {
    if (formatValue) {
      return formatValue(value);
    }

    return value;
  };

  const [value, setValue] = useState(handleDeformatValue(String(initialValue)));

  useEffect(() => {
    setValue(handleDeformatValue(String(initialValue)));
    initialValue &&
      selectInitialValue &&
      inputRef?.current?.setSelectionRange(0, initialValue.length);
  }, [initialValue]);

  const handleClear = (): void => {
    setValue('');
    onChange('');

    if (!!onClear) {
      onClear();
    }
  };

  const handleFocus = (): void => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = (): void => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const inputValue = handleDeformatValue(String(event.target.value));
    setValue(inputValue);
    onChange(inputValue);
  };

  const checkVisibleClearControl = (): boolean =>
    clearControl && Boolean(value) && !disabled;

  return (
    <GenericInputWrapStyled
      className={className}
      size={size}
      clearControl={clearControl}
    >
      <GenericInputStyled
        type={type}
        value={handleFormatValue(value)}
        name={name}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChangeValue}
        onFocus={handleFocus}
        color={color}
        autoComplete={autoComplete}
        maxLength={maxlength}
        disabled={disabled}
        bordered={bordered}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        ref={inputRef}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
      />

      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          htmlFor={name}
          children={placeholder}
        />
      ) : null}

      <GenericInputClearStyled
        visibleClearControl={checkVisibleClearControl()}
        onClick={handleClear}
      >
        <IconCloseComponent
          width={8}
          height={8}
          strokeWidth={'4'}
          strokeColor={theme.COLOR.WHITE}
        />
      </GenericInputClearStyled>
    </GenericInputWrapStyled>
  );
};
