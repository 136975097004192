import React, { ReactElement } from 'react';
import { SwitcherItemModel } from './switcherItem.model';
import {
  SwitcherItemLabelStyled,
  SwitcherItemStyled,
  SwitcherItemValueStyled,
} from './switcherItem.styled';
import { handleMenuSwitcherDataLayer } from '../menuSwitcher.analytics';
import { useRouter } from 'next/router';
import { useRegisterPopupContext } from '../../../register';
import { getSwitcherItemRegistrationPosition } from './switcherItem.helper';

export const SwitcherItemContainer = ({
  isActive,
  href,
  title,
  count,
  isAdaptive,
  analyticsEvent,
  analyticsEventPosition,
}: SwitcherItemModel): ReactElement => {
  const { handleAction } = useRegisterPopupContext();
  const router = useRouter();
  const hasCount = typeof count === 'number';

  //todo: add events for hr and personal
  const handleClick = (): void => {
    if (isActive) return;

    if (typeof handleAction === 'function') {
      handleAction(
        {
          onSuccess: () => {
            handleMenuSwitcherDataLayer(analyticsEvent, analyticsEventPosition);
            router.push(href);
          },
        },
        getSwitcherItemRegistrationPosition(analyticsEvent)
      );
    } else {
      router.push(href);
    }
  };

  return (
    <SwitcherItemStyled
      isActive={isActive}
      isAdaptive={isAdaptive}
      onClick={handleClick}
    >
      <SwitcherItemLabelStyled>{title}</SwitcherItemLabelStyled>
      {hasCount && <SwitcherItemValueStyled>{count}</SwitcherItemValueStyled>}
    </SwitcherItemStyled>
  );
};
