import { B2bLandingLinkUtmEnum } from './b2bLandingLink.enum';
import {
  projectSubDomains,
  publicConfig,
  urlService,
  utmService,
} from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';

export const getB2BLandingLinkQueries = (): ParsedUrlQuery => {
  const domain = publicConfig?.BASE_DOMAIN.replace('.', '-');

  return {
    source: domain,
    medium: B2bLandingLinkUtmEnum.utmMedium,
    campaign: B2bLandingLinkUtmEnum.utmCampaign
      .replace('{{domain}}', domain)
      .replace('{{region}}', publicConfig?.REGION),
  };
};

export const getB2BLandingLink = (): string =>
  `${urlService.getFullUrlToSubDomain(
    projectSubDomains.corp,
    null
  )}?${utmService.getUtmQueryStringFromObject(getB2BLandingLinkQueries())}`;
