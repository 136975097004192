import { RegionsEnum } from '../../../models';
import { storage } from '../../../services';
import {
  PHONE_LOCAL_STORAGE_KEY,
  PREFERRED_PHONE_COUNTRIES,
} from './phoneInput.constants';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';

//phone length with prefix (+)
export const getPhoneLength = (countryCode: string): number => {
  switch (countryCode) {
    case RegionsEnum.ru:
    case RegionsEnum.kz:
    case RegionsEnum.am:
    case RegionsEnum.md:
    case RegionsEnum.pl:
      return 12;
    case RegionsEnum.by:
    case RegionsEnum.kg:
    case RegionsEnum.uz:
    case RegionsEnum.ge:
      return 13;
    default:
      return 0;
  }
};

export const setPhoneLocalStorageValue = (value: string): void =>
  storage.setValue(PHONE_LOCAL_STORAGE_KEY, value);

export const getPhoneLocalStorageValue = (): string =>
  storage.getValue(PHONE_LOCAL_STORAGE_KEY) || '';

export const formatPhone = (value: string): string =>
  value.replace(/[\s\-\(\)]/g, '');

export const testPreferredPhonesLength = (value?: string): boolean => {
  if (
    !!getPhoneLocalStorageValue() &&
    PREFERRED_PHONE_COUNTRIES.includes(getPhoneLocalStorageValue())
  ) {
    return (
      !!value?.length &&
      formatPhone(value).length === getPhoneLength(getPhoneLocalStorageValue())
    );
  }

  return true;
};

export const isPhoneValid = (value?: string): boolean => {
  if (!value) return false;

  return isValidPhoneNumber(value);
};

export const mapCountriesFromRegion = (
  country?: string
): string | undefined => {
  switch (country) {
    case RegionsEnum.me:
      return 'ru';
    case RegionsEnum.team:
      return 'pl';
    default:
      return country;
  }
};
