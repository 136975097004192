import {
  LibrariesCoursesApiModel,
  LibrariesCoursesApiResponseModel,
  LibrariesCoursesModel,
} from './librariesCourses.model';
import { libraryCourseAdapter, LibraryCourseModel } from '../../library';

export const librariesCoursesDataAdapter = (
  data: LibrariesCoursesApiModel['data']
): LibrariesCoursesModel['data'] =>
  data.map((item) => libraryCourseAdapter(item));

export const librariesCoursesAdapter = (
  librariesApi: LibrariesCoursesApiModel
): LibrariesCoursesModel => ({
  data: librariesCoursesDataAdapter(librariesApi.data),
});

export const librariesCoursesApiResponseAdapter = ({
  data,
}: LibrariesCoursesApiResponseModel): LibraryCourseModel[] =>
  librariesCoursesDataAdapter(data.data);
