import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconSettingsComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M9.9083 3.7215c.4482-2.2973 3.7351-2.2973 4.1833 0 .2727 1.398 1.8137 2.1401 3.0767 1.4817 2.0756-1.082 4.1249 1.4878 2.6082 3.2706-.9229 1.0849-.5423 2.7523.7599 3.3293 2.14.9481 1.4086 4.1526-.9309 4.0784-1.4236-.0452-2.49 1.292-2.1291 2.6698.593 2.2643-2.3684 3.6905-3.769 1.8151-.8523-1.1412-2.5626-1.1412-3.4149 0-1.4006 1.8754-4.362.4492-3.769-1.8151.3608-1.3778-.7055-2.715-2.1291-2.6698-2.3395.0742-3.071-3.1303-.9309-4.0784 1.3022-.577 1.6828-2.2444.7599-3.3293-1.5167-1.7828.5326-4.3526 2.6082-3.2706 1.263.6584 2.804-.0837 3.0767-1.4817z"
    />
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M14.9999 12.0001c0 1.6569-1.3431 3-3 3-1.6568 0-3-1.3431-3-3 0-1.6568 1.3432-3 3-3 1.6569 0 3 1.3432 3 3z"
    />
  </svg>
);
