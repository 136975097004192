export const TRIGGER_FILTER_OPTION = 'TRIGGER_FILTER_OPTION';
export const TRIGGER_FILTER_RANGE = 'TRIGGER_FILTER_RANGE';
export const TRIGGER_ALL_FILTER_OPTION = 'TRIGGER_ALL_FILTER_OPTION';
export const SET_FILTERS_OPTIONS_STATE = 'SET_FILTERS_OPTIONS_STATE';
export const TRIGGER_CLEAR_FILTERS = 'TRIGGER_CLEAR_FILTERS';
export const TRIGGER_CLEAR_FILTER = 'TRIGGER_CLEAR_FILTER';
export const TRIGGER_OPEN_FILTERS = 'TRIGGER_OPEN_FILTERS';

export const SUBSCRIPTION_FILTER_TOOLTIP_COOKIE_NAME =
  'showSubscriptionFilterTooltip';

export enum FilterTypes {
  main = 'main',
  fixed = 'fixed',
}
