import React, { ReactElement } from 'react';
import { ImageContainer } from '../../image';
import { IconLernaLogoComponent } from '../../icons';
import { LogoCompanyStyled } from './logoCompany.styled';
import { LogoCompanyModel } from './logoCompany.model';

export const LogoCompanyContainer = ({
  height,
  width,
  logo,
  preset = 'logoCompany',
}: LogoCompanyModel): ReactElement => {
  if (logo) {
    return (
      <LogoCompanyStyled width={width} height={height}>
        <ImageContainer
          imageResource={logo}
          preset={preset}
          width={width}
          height={height}
        />
      </LogoCompanyStyled>
    );
  }

  return <IconLernaLogoComponent height={height} width={width} />;
};
