import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const FiltersTabsStyled = styled.div`
  margin-bottom: 32px;
  position: relative;
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_MODULE};

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;
