import { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { MenuIconProps } from './menuIcon.model';
import { mapIcons } from './menuIcon.meta';

export const MenuIconContainer = ({
  dark,
  theme,
  icon,
}: MenuIconProps): Nullable<ReactElement> => {
  const color = dark
    ? theme.COLOR.PRIMARY['500']
    : theme.COLOR.TEXT_LIGHT_COLOR;

  return mapIcons(icon, { color });
};
