import styled, { css } from 'styled-components';
import { breakpoint, ButtonStyled, fontSettings } from '@lerna-core';

export const ReferralProgramPopupStyled = styled.div`
  display: grid;
  grid-template-columns: 410px minmax(100px, 302px);
  justify-content: space-between;
  overflow: hidden;

  ${breakpoint(
    'mediumDown',
    css`
      overflow: unset;
      display: block;
    `
  )}
`;

export const ReferralProgramPopupTextWrapperStyled = styled.div`
  margin-top: 8px;

  ${ButtonStyled} {
    margin-top: 24px;

    svg {
      transform: rotate(90deg);
    }
  }
`;

export const ReferralProgramPopupTitleStyled = styled.h2`
  margin: 0;
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  line-height: 52px;

  span {
    white-space: nowrap;
    color: ${({ theme }): string => theme.COLOR.ROSE};
  }
`;

export const ReferralProgramPopupDescriptionStyled = styled.p`
  line-height: 22px;
  margin: 16px 0 0;
`;

export const ReferralProgramPopupImgWrapperStyled = styled.div`
  ${breakpoint(
    'mediumDown',
    css`
      width: 237px;
      height: 186px;
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: -1;
      overflow: hidden;
    `
  )}
`;

export const ReferralProgramPopupImgStyled = styled.img`
  ${breakpoint(
    'mediumDown',
    css`
      width: 400px;
      height: auto;
    `
  )}
`;
