import { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import {
  getCookiePermissions,
  setCookiePermissions,
} from '../notificationManagement.helper';
import { getFormInitialValues } from './managementPopup.helpers';
import {
  ManagementPopupFormHookModel,
  ManagementPopupFormInitialValues,
} from './managementPopup.model';
import { useCookieNotificationManagementContext } from '../notificationManagement.context';
import { CookieTypes } from '../../cookiesNotification.model';
import { getAnalyticsEventByPermissions } from './managementPopup.helper';
import { getSimpleEvent, pushDataLayer } from '../../../../services';
import { CommonEventPositions, CommonEventTypes } from '../../../../analytics';

export const useManagementForm = (
  handleClose: () => void,
  isOpened: boolean
): ManagementPopupFormHookModel => {
  const { setInvisible } = useCookieNotificationManagementContext();
  const initialValues: ManagementPopupFormInitialValues = useMemo(() => {
    return getFormInitialValues(getCookiePermissions());
  }, []);

  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: (values) => {
      setCookiePermissions(values);
      const eventName = getAnalyticsEventByPermissions(values);
      if (eventName) {
        pushDataLayer(
          getSimpleEvent(
            eventName,
            CommonEventTypes.overallEvents,
            CommonEventPositions.footer
          )
        );
      }
    },
  });

  const handleAccept = useCallback((): void => {
    handleSubmit();
    handleClose();
    setInvisible();
  }, []);

  useEffect(() => {
    if (isOpened) {
      const cookiePermissions = getCookiePermissions();
      if (cookiePermissions) {
        Object.values(CookieTypes).forEach((type) => {
          setFieldValue(
            CookieTypes[type],
            cookiePermissions[CookieTypes[type]]
          );
        });
      }
    }
  }, [isOpened]);

  return {
    handleAccept,
    handleChange,
    values,
  };
};
