import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconUserFillComponent = ({
  width = 100,
  height = 100,
  fillColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 65C34.979 65 21.7513 70.6179 14 79.1445C18.5003 84.4412 24.0386 88.684 30.246 91.5904C36.4535 94.4968 43.1877 96 50 96C56.8123 96 63.5465 94.4968 69.754 91.5904C75.9614 88.684 81.4997 84.4412 86 79.1445C78.2487 70.6486 64.9988 65 50 65Z"
      fill={fillColor}
    />
    <circle cx="50" cy="40" r="19" fill={fillColor} />
  </svg>
);
