import { cookieService } from '../../../services';
import {
  HIDE_COOKIES_NOTIFICATION_COOKIE_NAME,
  NotificationAgreementStatus,
} from './notificationAgreement.constant';
import { TranslationModel } from '../../../models';

export const isNotificationVisible = (
  translations: TranslationModel
): boolean => {
  const cookieNotificationValue = cookieService.getCookie(
    HIDE_COOKIES_NOTIFICATION_COOKIE_NAME
  );

  return (
    cookieNotificationValue !== NotificationAgreementStatus.hide &&
    !!translations.cookie_notification_text
  );
};
