import styled from 'styled-components';

type SliderItemContainerStyledProps = {
  width: string;
  spaceBetweenItems: number;
};
export const SliderItemContainerStyled = styled.div<SliderItemContainerStyledProps>`
  flex: ${({ width, spaceBetweenItems }): string =>
    `0 0 calc(${width} - ${spaceBetweenItems / 2}px)`};
  box-sizing: border-box;

  &:not(:last-child) {
    margin-right: ${({ spaceBetweenItems }): number => spaceBetweenItems}px;
  }
`;
