import { MetaPaginationApiModel, MetaPaginationModel } from '../models';

export const mapMetaPaginationAdapter = (
  apiPagination: MetaPaginationApiModel
): MetaPaginationModel => {
  return {
    total: apiPagination.total,
    count: apiPagination.count,
    perPage: apiPagination.per_page,
    currentPage: apiPagination.current_page,
    totalPages: apiPagination.total_pages,
    links: apiPagination.links,
  };
};

export const initialPaginationMeta = {
  total: 0,
  count: 0,
  perPage: 50,
  currentPage: 1,
  totalPages: 1,
  links: {},
};
