import md5 from 'crypto-js/md5';

import { ImageResourceModel } from '../../models';
import { publicConfig } from '../config/config.model';
import { PRESETS, RETINA_MEDIA_QUALITY } from './media.config';
import {
  FullPresetModel,
  FullPresetsModel,
  ImagePresetModel,
  PresetModel,
  PresetOptsModel,
  PresetsModel,
  PresetSourcesModel,
  PresetWithSignatureModel,
} from './mediaPreset.model';
import { ScaleMethod } from './mediaPreset.enum';

/** class takes presets from configs and generates expanded presets */
class MediaPreset {
  private static instance: MediaPreset;
  private static presets: FullPresetsModel = {};
  private static privateMediaSecretKey: string;
  private static readonly scaleSteps = 3;

  constructor() {
    //TODO create task to refactor media signatures generations
    MediaPreset.privateMediaSecretKey = publicConfig?.MEDIA_SECRET_KEY;
  }
  /**
   * Convert presets from configs to FullPresets.
   * @param {object} presets - presets from configs
   */
  private static setPresets(presets: PresetsModel): void {
    for (const key in presets) {
      const currentPreset: PresetModel = presets[key];
      MediaPreset.presets[key] = {};

      for (let i = 1; i <= MediaPreset.scaleSteps; i++) {
        const preset = {
          ...currentPreset,
          opts: MediaPreset.setScalePresetOptions(currentPreset, i),
        };

        MediaPreset.presets[key][
          MediaPreset.setKeyFromScaleNumber(i) as keyof FullPresetModel
        ] = {
          ...preset,
          signature: MediaPreset.getSignature(preset),
        };
      }
    }
  }

  public static getInstance(): ImagePresetModel {
    if (!MediaPreset.instance) {
      MediaPreset.instance = new MediaPreset();
    }

    return MediaPreset.instance;
  }

  private static setKeyFromScaleNumber(scaleNumber: number): string {
    return `_${scaleNumber}x`;
  }

  /**
   * Convert options to string.
   * @param {object} PresetOpts - preset options
   * @return {string}
   */
  private static optsStringify(opts: PresetOptsModel): string {
    const optsArr: string[] = [];
    Object.keys(opts).forEach((key) => {
      optsArr.push(`${key}=${opts[key as keyof PresetOptsModel]}`);
    });

    return optsArr.join(',');
  }

  /**
   * Generate signature for preset
   * @param {object} Preset - preset
   * @return {string} - signature
   */
  private static getSignature(preset: PresetModel): string {
    return md5(
      `${MediaPreset.privateMediaSecretKey}${
        preset.method
      }${MediaPreset.optsStringify(preset.opts)}`
    ).toString();
  }

  /**
   * Set scale preset options
   * @return {object}
   * @param preset
   * @param scale
   */
  private static setScalePresetOptions(
    preset: PresetModel,
    scale: number
  ): PresetOptsModel {
    const quality = scale > 1 ? RETINA_MEDIA_QUALITY : preset.opts.q;

    return {
      ...preset.opts,
      w: preset.opts.w * scale,
      h: preset.opts.h * scale,
      q: quality,
    };
  }

  /**
   * Checking the ability to output a scaleX picture
   * @param {object} imageResource - imageResource
   * @param {object} scalePreset - preset scaleX
   * @return {boolean}
   */
  private static checkScale(
    imageResource: ImageResourceModel,
    scalePreset: PresetWithSignatureModel
  ): boolean {
    if (imageResource) {
      if (scalePreset.method === ScaleMethod.resize) {
        return (
          imageResource.width >= scalePreset.opts.w ||
          imageResource.height >= scalePreset.opts.h
        );
      }

      return (
        imageResource.width >= scalePreset.opts.w &&
        imageResource.height >= scalePreset.opts.h
      );
    }

    return false;
  }

  /**
   * Get image src
   * @param {object} preset - presetWithSignature
   * @param {object} imageResource
   * @param {string} mediaDomain
   * @param {string} proxyOrigin
   * @return {string} - image src
   */
  private static getImageSrc(
    preset: PresetWithSignatureModel,
    imageResource: ImageResourceModel,
    mediaDomain = publicConfig?.MEDIA_DOMAIN,
    proxyOrigin?: string
  ): string {
    const origin = proxyOrigin
      ? proxyOrigin
      : `${publicConfig?.BASE_PROTOCOL}//${imageResource?.server}.${mediaDomain}`;

    return `${origin}/images/${preset.signature}/${
      preset.method
    }/${MediaPreset.optsStringify(preset.opts)}/${imageResource?.entity}${
      imageResource?.path
    }/${imageResource?.filename}`;
  }

  /**
   * Get image src for fullPreset
   * @param {object} mediaPreset - FullPreset
   * @param {object} imageResource
   * @param {string} mediaDomain
   * @param {string} proxyOrigin
   * @return {object|null} - { _1x: src, _2x: src, _3x: src }
   */
  public getImageSet(
    mediaPreset: FullPresetModel,
    imageResource: ImageResourceModel,
    mediaDomain?: string,
    proxyOrigin?: string
  ): PresetSourcesModel | null {
    if (mediaPreset) {
      const presetSources: PresetSourcesModel = {};

      for (let i = 1; i <= MediaPreset.scaleSteps; i++) {
        const scaleKey = MediaPreset.setKeyFromScaleNumber(i);
        const preset = mediaPreset[scaleKey as keyof FullPresetModel];

        if (preset) {
          const hasScale = !!preset
            ? MediaPreset.checkScale(imageResource, preset)
            : false;

          if (hasScale) {
            presetSources[scaleKey as keyof PresetSourcesModel] =
              MediaPreset.getImageSrc(
                preset,
                imageResource,
                mediaDomain,
                proxyOrigin
              );
          }
        }
      }

      return presetSources;
    }

    return null;
  }

  /**
   * Get fullPresets
   * @return {object} FullPresets
   */
  public get(): FullPresetsModel {
    MediaPreset.setPresets(PRESETS);

    return MediaPreset.presets;
  }
}

export const mediaPreset = MediaPreset.getInstance();
