import { publicConfig } from '../config/config.model';
import { Cookies, Nullable, setCookieProps } from '../../models';
import { IncomingMessage } from 'http';
import cookie from 'cookie';
import { dateService } from '../dateService';
import { AppService } from '..';

class CookieService {
  private static instance: CookieService;

  private getDefaultCookies(): string {
    if (AppService.isClientSide) {
      return document.cookie || '';
    }

    return '';
  }

  public static readonly cookieDomain = publicConfig?.BASE_DOMAIN.replace(
    '.',
    ''
  );
  public readonly cookiesDefaultProps = {
    domain: `.${publicConfig?.BASE_DOMAIN}`,
    path: '/',
  };

  public static getInstance(): CookieService {
    if (!CookieService.instance) {
      CookieService.instance = new CookieService();
    }

    return CookieService.instance;
  }

  public getCookieKey(name: string, withPrefix = true): string {
    return withPrefix ? `${CookieService.cookieDomain}__${name}` : name;
  }

  public prepareCookieToSet({
    name,
    val,
    days,
    path = this.cookiesDefaultProps.path,
    domain = this.cookiesDefaultProps.domain,
    withPrefix = true,
  }: setCookieProps): string {
    const date = new Date();
    const secure = name.indexOf('__Secure') !== -1;
    if (days !== undefined) {
      date.setTime(date.getTime() + days * dateService.dayInMilliseconds);
    }

    return `${this.getCookieKey(name, withPrefix)}=${val}; ${
      days !== undefined ? `expires=${date.toUTCString()};` : ''
    }path=${path}; domain=${domain}; ${secure ? 'secure' : ''}`;
  }

  public setCookie(props: setCookieProps): void {
    document.cookie = this.prepareCookieToSet(props);
  }

  public removeCookie(name: string): void {
    this.setCookie({
      name,
      val: '',
      days: -1,
    });
  }

  public getCookie(
    name: string,
    cookies = this.getDefaultCookies(),
    withPrefix = true
  ): Nullable<string> {
    if (!cookies) {
      return null;
    }
    const value = `; ${cookies}`;
    const parts = value.split(`; ${this.getCookieKey(name, withPrefix)}=`);

    if (parts.length === 2) {
      return parts?.pop()?.split(';').shift() || null;
    } else {
      return null;
    }
  }

  public parseReqCookies(req?: IncomingMessage): Cookies {
    if (!req || !req.headers) {
      return {};
    }

    return cookie.parse(req.headers.cookie || '');
  }
}

export const cookieService = CookieService.getInstance();
