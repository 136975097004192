import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import { MenuSwitcherModel } from './menuSwitcher.model';
import { MenuSwitcherStyled } from './menuSwitcher.styled';
import { SwitcherItemContainer } from './switcherItem';

export const MenuSwitcherContainer = ({
  links,
  isAdaptive = false,
  hiddenAfter,
}: MenuSwitcherModel): Nullable<ReactElement> => {
  const activeLinkIndex = links.findIndex((link) => link.isActive);

  return (
    <MenuSwitcherStyled
      hiddenAfter={hiddenAfter}
      isAdaptive={isAdaptive}
      activeLinkIndex={activeLinkIndex}
      linksLength={links.length}
    >
      {links.map((link) => (
        <SwitcherItemContainer
          isAdaptive={isAdaptive}
          key={`${link.href}${link.title}`}
          {...link}
        />
      ))}
    </MenuSwitcherStyled>
  );
};
