import React, { memo, ReactElement } from 'react';
import { Nullable } from '../../../../../models';
import { ContactsPhoneContainerProps } from './contactsPhone.model';
import { FooterTopContactsPhoneStyled } from './contactsPhone.styled';
import { stringService } from '../../../../../services';
import { pushFooterDataLayer } from '../../../footer.analytics';

const PHONE_SYMBOLS_TO_REMOVE_REGEXP = /[\s\-]/g;

const ContactsPhone = ({
  value,
  analyticsEvent,
}: ContactsPhoneContainerProps): Nullable<ReactElement> => {
  const handleClick = (): void => {
    analyticsEvent && pushFooterDataLayer(analyticsEvent);
  };

  if (!value) return null;

  return (
    <FooterTopContactsPhoneStyled
      href={`tel:${stringService.removeSymbolsByRegExp(
        value,
        PHONE_SYMBOLS_TO_REMOVE_REGEXP
      )}`}
      onClick={handleClick}
    >
      {value}
    </FooterTopContactsPhoneStyled>
  );
};

export const ContactsPhoneContainer = memo(ContactsPhone);
