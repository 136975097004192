import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { PriceTitleContainerStyledProps } from './priceTitle.model';

export const PriceTitleContainerStyled = styled.div<PriceTitleContainerStyledProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 6px;
  ${({ lineThrough }): string =>
    lineThrough ? 'text-decoration: line-through;' : ''}
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  
  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 4px;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)}
    `
  )}
`;
