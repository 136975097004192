import { publicConfig, stringService, urlService } from '../../services';
import { ParsedUrlQuery } from 'querystring';
import { QueryParams, TranslationModel } from '../../models';
import { TestQueryParams, TestType } from './tests.model';
import { testPath, testSubDomains } from './tests.constants';

const BULLET = '\u2022';

export const getQuestionsPluralTranslations = (
  questions: number,
  translations: TranslationModel
): string =>
  stringService.pluralTranslations(questions, [
    translations.questions_label_1,
    translations.questions_label_2,
    translations.questions_label_many,
  ]);

export const getTestInfoText = (
  questionsSize: number,
  averageSpentTime: string,
  translations: TranslationModel
): string =>
  `${questionsSize} ${getQuestionsPluralTranslations(
    questionsSize,
    translations
  )} ${BULLET} ${averageSpentTime}`;

export const getTestAssignmentsText = (
  translations: TranslationModel,
  completedAssignments: number,
  totalAssignments: number
): string => {
  return `${completedAssignments} ${translations.from} ${totalAssignments}`;
};

export const getTestLink = (
  type: TestType,
  slug: string = '',
  companyUuid: string = ''
): string => {
  const subdomain = getTestSubdomain(type);
  const testQueryParams: ParsedUrlQuery = {
    [QueryParams.company]: companyUuid,
  };

  switch (type) {
    case TestType.skilltest:
      return getSkilltestTestLink(subdomain, testQueryParams, slug || '');
    default:
      return getCommonTestLink(subdomain, testQueryParams);
  }
};

const getCommonTestLink = (
  subdomain: string,
  query: ParsedUrlQuery
): string => {
  const url = urlService.getFullUrlToSubDomain(subdomain, testPath.v2);

  return urlService.setQueryParamsToLink(url, query);
};

const getSkilltestTestLink = (
  subdomain: string,
  query: ParsedUrlQuery,
  slug: string
): string => {
  const url = urlService.getFullUrlToSubDomain(
    subdomain,
    `${testPath.skilltest}/${slug}`
  );

  return urlService.setQueryParamsToLink(url, query);
};

const getProdTestSubdomain = (type: TestType): testSubDomains => {
  switch (type) {
    case TestType.anxiety:
      return testSubDomains.anxietytest;
    case TestType.skilltest:
      return testSubDomains.hardskills;
    case TestType.softtest:
      return testSubDomains.softskills;
  }
};

export const getTestSubdomain = (type: TestType): testSubDomains => {
  switch (publicConfig?.LOCATION) {
    case 'dev':
      return testSubDomains.test;
    default:
      return getProdTestSubdomain(type);
  }
};

const getResultQuery = (
  testUuid: string,
  userUuid?: string
): ParsedUrlQuery => {
  const query: ParsedUrlQuery = { [TestQueryParams.testId]: testUuid };
  if (userUuid) {
    Object.assign(query, { [TestQueryParams.userId]: userUuid });
  }

  return query;
};

export const getTestResultLink = (
  type: TestType,
  testUuid: string,
  userUuid?: string
): string => {
  const subdomain = getTestSubdomain(type);
  const resultPathByType = getTestResultPathByType(type);
  const resultQuery = getResultQuery(testUuid, userUuid);
  const resultPathWithParams = urlService.updatePathQuery(
    testPath.result,
    resultQuery
  );

  const resultPath = `${resultPathByType}${resultPathWithParams}`;

  return urlService.getFullUrlToSubDomain(subdomain, resultPath);
};

const getTestResultPathByType = (type: TestType): string => {
  switch (type) {
    case TestType.skilltest:
      return testPath.skilltest;
    default:
      return testPath.v2;
  }
};
