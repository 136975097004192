import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { ItemPhotoContainerStyledProps } from './itemPhoto.model';

export const ItemPhotoContainerStyled = styled.div<ItemPhotoContainerStyledProps>`
  flex: 0 0 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  overflow: hidden;
  position: relative;

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumUp',
      css`
        background-color: ${theme.COLOR.MEDIUM_LIGHT_PURPLE};
      `
    )}

  ${({ theme, isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        background-color: ${theme.COLOR.WHITE};
        flex: 0 0 50px;
        height: 50px;
        margin-right: 8px;
      `,
      isMobile
    )}

  img,
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;
