import Link from 'next/link';
import { ReactElement, useMemo } from 'react';
import React from 'react';

import { useTheme } from '../../../hooks';
import { IconArrowComponent } from '../../icons';
import { UserSmallInfoContainer } from '../../user';
import { CustomerModel } from '../customer.model';
import { CustomerPreviewStyled } from './customerPreview.styled';
import { getCustomerCareer, getCustomerPersonal } from '../customer.service';

type CustomerPreviewContainerProps = {
  customer: CustomerModel;
};

export const CustomerPreviewContainer = ({
  customer,
}: CustomerPreviewContainerProps): ReactElement => {
  const { COLOR } = useTheme();
  const career = useMemo(() => getCustomerCareer(customer), [customer]);
  const personal = useMemo(() => getCustomerPersonal(customer), [customer]);

  return (
    <Link href={`customers/${customer.uuid}`}>
      <CustomerPreviewStyled>
        <UserSmallInfoContainer personal={personal} career={career} />
        <IconArrowComponent
          height={24}
          width={24}
          strokeColor={COLOR.TEXT_LIGHT_COLOR}
        />
      </CustomerPreviewStyled>
    </Link>
  );
};
