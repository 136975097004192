export enum TestType {
  skilltest = 'skilltest',
  softtest = 'softtest',
  anxiety = 'anxiety',
}

export enum TestQueryParams {
  userId = 'userId',
  respondentId = 'respondentId',
  testId = 'testId',
  professionId = 'professionId',
  // TODO remove in 1 month ~ 16.11.23
  testIdOld = 'test_id',
}
