import styled from 'styled-components';
import { rgba } from 'polished';
import { HeaderLogoProps } from './companyLogo.model';

export const CompanyLogoStyled = styled.div`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  box-sizing: border-box;
  min-width: 280px;
  min-height: 96px;

  img {
    max-width: 100%;
  }
`;

export const LogoPlug = styled.div<HeaderLogoProps>`
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
  position: relative;
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3333 6.66667V25.3333H6.66667V6.66667H25.3333ZM25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM18.8533 15.8133L14.8533 20.9733L12 17.52L8 22.6667H24L18.8533 15.8133Z' fill='black' fill-opacity='0.12'/%3E%3C/svg%3E%0A")
      center / contain no-repeat;
    width: 32px;
    height: 32px;
  }
`;
