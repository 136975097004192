import { PersonalModel } from '../user.model';

export enum UserAvatarSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  big = 'big',
  xbig = 'xbig',
}

export type UserAvatarProps = {
  size: UserAvatarSize;
  personal: PersonalModel;
  isRound?: boolean;
  pointer?: boolean;
  mediaDomain?: string;
  handleClick?: () => void;
  className?: string;
};
