import styled from 'styled-components';
import { DesktopStaticLinksStyledProps } from './desktopStaticLinks.model';
import { rgba } from 'polished';

export const DesktopStaticLinksStyled = styled.div<DesktopStaticLinksStyledProps>`
  ${({ bordered, theme }): string =>
    bordered
      ? `
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${rgba(theme.COLOR.BLACK, 0.08)};
  `
      : ''}
`;
