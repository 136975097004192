import {
  publicConfig,
  RequestClient,
  RequestHeader,
  serverConfig,
  UserAggregateModel,
} from '@lerna-core';
import {
  CourseApiRequestParams,
  CourseDataErrorModel,
  CourseDataModel,
  CoursePageApiModel,
  CourseRequestParams,
} from './course.model';
import { coursePageAdapter } from './course.adapter';

export class CourseClient extends RequestClient<
  CourseApiRequestParams,
  CoursePageApiModel,
  CourseDataModel | CourseDataErrorModel
> {
  protected requestParameters: CourseApiRequestParams;
  protected adapter = coursePageAdapter;
  protected path = '';
  constructor(
    params: CourseRequestParams,
    headers?: RequestHeader,
    user?: UserAggregateModel
  ) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );
    this.path = `aggregate/v2/catalog/courses/${params.slug}`;
    const token = user?.career?.companyId;

    if (user) {
      if (token) {
        this.path = `aggregate/v1/catalog/courses/${params.slug}/token/${token}`;
      } else {
        this.path = `aggregate/v2/auth/catalog/courses/${params.slug}`;
      }
    }

    this.requestParameters = {};
  }
}
