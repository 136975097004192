import styled, { css } from 'styled-components';

import { breakpoint } from '../../../styles';
import {
  AdminPageWrapperContentProps,
  PageWrapperContainerStyledProps,
} from './adminPageWrapper.model';

export const AdminPageWrapperContainerStyled = styled.div<PageWrapperContainerStyledProps>`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  ${({ minWidth }): string => `min-width: ${minWidth}px`};

  & > * {
    width: 100%;
  }
`;

export const AdminPageWrapperContentStyled = styled.div<AdminPageWrapperContentProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: ${({ theme }): string =>
    theme.IS_MOBILE ? '16px 0' : '40px 0 80px'};
  position: relative;
  z-index: ${(props): number => props.theme.ZINDEX.TOP_BLOCK + 1};
  box-sizing: border-box;

  & > * {
    width: 100%;
  }

  ${breakpoint(
    'desktop',
    css`
      padding: 0 40px;
    `
  )}

  & > div {
    ${({ isMobile }): ReturnType<typeof css> | string =>
      breakpoint(
        'tablet',
        css`
          padding: 0 20px;
        `,
        isMobile
      )}
  }
`;
