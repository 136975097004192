import { urlService, objectService } from '../../services';
import { QueryParams } from '../../models';
import { NextPageContext } from 'next';

export const paginationRedirect = (context: NextPageContext): void => {
  const currentUrl = urlService.getFullUrlFromToPage(
    context.req?.url || `${window.location.pathname}${window.location.search}`
  );
  const url = new URL(currentUrl);
  const searchObject = objectService.getObjectFromQueryString(url.search);

  if (
    searchObject.hasOwnProperty(QueryParams.page) &&
    searchObject[QueryParams.page] === '1'
  ) {
    delete searchObject.page;

    const newSearch = objectService.getQueryStringFromObject(searchObject);

    context?.res?.writeHead(301, {
      Location: urlService.normalizeUrl(
        `${url.origin}${url.pathname}${!!newSearch ? `?${newSearch}` : ''}`
      ),
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    context?.res?.end();
  }
};
