import { useDiscountProgram } from '@features/common/discountProgram';
import { SeoTextContainer } from '@features/common/seoText';
import { browserService, DeviceInfoContext, Nullable } from '@lerna-core';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCoursesPageContext } from '../coursesPage';
import { SEO_TEXT_HEIGHT } from './coursesSeoText.constants';
import { checkCollapseSeoText } from './coursesSeoText.helper';
import {
  SeoTextContainerStyled,
  SeoTextInnerStyled,
  SeoTextShadowStyled,
  SeoTextWrapperStyled,
} from './coursesSeoText.styled';
import { SeoTextShowMoreContainer } from './seoTextShowMore';

export const CoursesSeoTextContainer = (): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);
  const { isDiscountProgramPage } = useDiscountProgram();

  const ref = useRef<Nullable<HTMLDivElement>>(null);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [showControls, setShowControls] = useState<boolean>(false);

  const seoText = coursesContext.seo.text;
  const translations = coursesContext.translations;
  const seoTextHeight = isMobile
    ? SEO_TEXT_HEIGHT.MOBILE
    : SEO_TEXT_HEIGHT.DESKTOP;
  const hideSeoText =
    !seoText || coursesContext.user?.career?.companyId || isDiscountProgramPage;

  useEffect(() => {
    if (ref?.current) {
      const needToCollapse = checkCollapseSeoText(ref.current);
      setIsCollapsed(needToCollapse);
      setShowControls(needToCollapse);
    }
  }, []);

  const handleShowMore = (): void => {
    setIsCollapsed(false);
    setShowControls(false);
  };

  if (hideSeoText) return null;

  return (
    <SeoTextContainerStyled>
      <SeoTextWrapperStyled
        ref={ref}
        height={seoTextHeight}
        isCollapsed={isCollapsed}
      >
        <SeoTextInnerStyled>
          <SeoTextContainer html={seoText} />
          {showControls && <SeoTextShadowStyled />}
        </SeoTextInnerStyled>
      </SeoTextWrapperStyled>
      {showControls && (
        <SeoTextShowMoreContainer
          label={translations.catalog_seo_text_show_more}
          handleClick={handleShowMore}
        />
      )}
    </SeoTextContainerStyled>
  );
};
