import { Dispatch, useEffect } from 'react';
import { setToast, ToastActions, ToastType } from '../features';
import { useDispatch } from 'react-redux';

export function useToastHook(
  message: string,
  type: ToastType,
  visible: boolean
): void {
  const dispatch: Dispatch<ToastActions> = useDispatch();
  useEffect(() => {
    if (visible) {
      dispatch(setToast(message, type, true));
    }
  }, [visible]);
}
