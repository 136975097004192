export enum IconNames {
  user = 'user',
  catalog = 'catalog',
  logout = 'logout',
  login = 'login',
  tests = 'tests',
  home = 'home',
  case = 'case',
  analytics = 'analytics',
  badge = 'badge',
  coin = 'coin',
  grid = 'grid',
  heart = 'heart',
  list = 'list',
  cv = 'cv',
  cog = 'cog',
  settings = 'settings',
  users = 'users',
  help = 'help',
  percent = 'percent',
  percentWithPen = 'percent_with_pen',
  portfolio = 'portfolio',
  paper_with_pen = 'paper_with_pen',
  box = 'box',
  referralUsers = 'referral_users',
  proftests = 'proftests',
  marketing = 'marketing',
  laptop = 'laptop',
}
