import { CSSProperties, RefObject } from 'react';

import { PopupStyleModel } from './index';
import { ITheme } from '../../themes';
import { Nullable } from '../../../models';

/**
 * Check if the header needs to be sticked
 * @params {number} scrollTop - scroll height
 * @params {number} stickyPosition - position when you have to sticky an item
 * @params {number} stickyDelta - a value that indicates how early you need to stick
 * @return {boolean}
 *
 * */
export const isStickyHeader = (
  scrollTop: number,
  stickyPosition: number,
  stickyDelta: number
): boolean => scrollTop >= stickyPosition + stickyDelta;

/**
 * Check if the header needs to be unsticked
 * @params {number} scrollTop - scroll height
 * @params {number} stickyPosition - position when you have to sticky an item
 * @params {number} stickyDelta - a value that indicates how early you need to unstick
 * @return {boolean}
 *
 * */
export const isUnStickyHeader = (
  scrollTop: number,
  stickyPosition: number,
  stickyDelta: number
): boolean => scrollTop < stickyPosition - stickyDelta;

/**
 * Check if the footer needs to be sticked
 * @params {HTMLDivElement} targetDiv - element that scrolls
 * @params {number} stickyDelta - a value that indicates how early you need to stick
 * @params {number} footerHeight - footer height
 * @return {boolean}
 *
 * */
export const isStickyFooter = (
  targetDiv: HTMLDivElement,
  stickyDelta: number,
  footerHeight: number
): boolean =>
  targetDiv.offsetHeight + targetDiv.scrollTop <=
  targetDiv.scrollHeight - footerHeight + stickyDelta;

/**
 * Check if the footer needs to be unsticked
 * @params {HTMLDivElement} targetDiv - element that scrolls
 * @params {number} stickyDelta - a value that indicates how early you need to unstick
 * @params {number} footerHeight - footer height
 * @return {boolean}
 *
 * */
export const isUnStickyFooter = (
  targetDiv: HTMLDivElement,
  stickyDelta: number,
  footerHeight: number
): boolean =>
  targetDiv.offsetHeight + targetDiv.scrollTop >
  targetDiv.scrollHeight - footerHeight + stickyDelta;

/**
 * Check if the footer needs to be sticked
 * @params {RefObject} contentRef - element that scrolls
 * @params {RefObject} footerRef - footer react ref
 * @params {number} stickyDelta - a value that indicates how early you need to stick
 * @return {boolean}
 *
 * */
export const setStickyFooter = (
  contentRef: RefObject<HTMLDivElement>,
  footerRef: RefObject<HTMLDivElement>,
  stickyDelta: number
): boolean => {
  const contentDiv: HTMLDivElement | null = contentRef.current;
  const footerDiv: HTMLDivElement | null = footerRef.current;
  if (contentDiv && footerDiv) {
    return (
      contentDiv.scrollHeight - contentDiv.offsetHeight - stickyDelta >
      getFooterHeight(footerRef)
    );
  }

  return false;
};

/**
 * Get footer height
 * @params {RefObject} footerRef - footer react ref
 * @return {number}
 *
 * */
export const getFooterHeight = (footerRef: RefObject<HTMLDivElement>): number =>
  footerRef?.current?.offsetHeight || 0;

export const getPosition = (
  style?: Nullable<PopupStyleModel>
): CSSProperties => {
  if (style) {
    return {
      left: `${style.left}px`,
      top: `${style.top}px`,
      width: `${style.width}px`,
      position: 'absolute',
    };
  }

  return {};
};

export const getStylesByMode = (theme: ITheme, isDarkMode: boolean): string => {
  if (isDarkMode) {
    return `
      background: ${theme.COLOR.TEXT_DARK_COLOR};
      color: ${theme.COLOR.WHITE};
    `;
  } else {
    return `
      background: ${theme.COLOR.WHITE};
      color: ${theme.COLOR.TEXT_DARK_COLOR};
    `;
  }
};
