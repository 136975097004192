import { ReactElement } from 'react';
import {
  FilterTypes,
  FilterModel,
  FilterOptionsSearchModel,
  FiltersSlugEnum,
  Nullable,
  TranslationModel,
} from '@lerna-core';

export const filtersWithSearch = [
  FiltersSlugEnum.profession,
  FiltersSlugEnum.theme,
  FiltersSlugEnum.schools,
  FiltersSlugEnum.expertise,
];
export const optionsCollapseLimit = 4;
export const optionsSearchLimit = 8;

export type FilterOptionsContainerTypes = {
  filter: FilterModel;
  isCollapsed: boolean;
  translations: TranslationModel;
  defaultPathname: string;
  withTitle?: boolean;
  withSearch?: boolean;
  withDescription?: boolean;
  withTooltip?: boolean;
  searchColors?: FilterOptionsSearchModel;
  icon?: Nullable<ReactElement>;
  filterColor?: Nullable<string>;
  analyticsType?: keyof typeof FilterTypes;
};
