import React, { ReactElement } from 'react';
import { PopupListItemStyled, PopupListStyled } from './popupList.styled';
import { PopupListContainerProps } from './popupList.model';

export const PopupListContainer = ({
  list,
}: PopupListContainerProps): ReactElement => (
  <PopupListStyled>
    {list.map((item) => (
      <PopupListItemStyled key={item}>{item}</PopupListItemStyled>
    ))}
  </PopupListStyled>
);
