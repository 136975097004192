import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';
import { useFormB2CContext } from '../formB2C.context';
import { ContentStatusContainer } from './contentStatus';
import { LoaderContainer } from '@lerna-core';

export const FormContentContainer = (): ReactElement => {
  const { showFormStatus, isApplyingPromoCode } = useFormB2CContext();

  return (
    <FormContentStyled>
      <ContentLeftContainer />
      <ContentRightContainer />
      {showFormStatus && <ContentStatusContainer />}
      {isApplyingPromoCode && <LoaderContainer withBlur position="absolute" />}
    </FormContentStyled>
  );
};
