import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLightOnComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="M4.52 1.188a1 1 0 0 0-.696 1.717l.943.943a1 1 0 1 0 1.414-1.414l-.943-.943a1 1 0 0 0-.717-.304Zm22.93 0a1 1 0 0 0-.688.303l-.943.943a1 1 0 1 0 1.414 1.414l.943-.943a1 1 0 0 0-.727-1.716ZM16 2.679c-5.14 0-9.333 4.18-9.333 9.322a9.31 9.31 0 0 0 2.926 6.779c.211.2.366.48.438.803l1.645 7.402a3.009 3.009 0 0 0 2.928 2.35h2.792c1.4 0 2.624-.983 2.928-2.35l1.646-7.402v-.001c.072-.322.226-.601.438-.801v-.001A9.312 9.312 0 0 0 25.334 12c0-5.142-4.193-9.321-9.333-9.323Zm0 2A7.31 7.31 0 0 1 23.333 12a7.293 7.293 0 0 1-2.3 5.326 3.554 3.554 0 0 0-1.016 1.822l-.781 3.519h-6.472l-.78-3.52a3.559 3.559 0 0 0-1.018-1.821A7.29 7.29 0 0 1 8.666 12 7.31 7.31 0 0 1 16 4.677ZM1 10a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H1Zm28 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-2.484 7.849a1 1 0 0 0-.697 1.717l.943.943a1 1 0 1 0 1.414-1.414l-.943-.943a1.003 1.003 0 0 0-.717-.303Zm-21.061.001a1 1 0 0 0-.688.302l-.943.943a1 1 0 1 0 1.414 1.414l.943-.943a1 1 0 0 0-.726-1.716Zm7.755 6.817h5.58l-.418 1.884a.992.992 0 0 1-.976.782h-2.792a.99.99 0 0 1-.976-.782l-.418-1.884Z"
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
