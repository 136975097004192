import { breakpoint } from '@lerna-core';
import styled, { css } from 'styled-components';
import {
  TutorsItemContainerStyledProps,
  TutorsItemInfoStyledProps,
} from './tutorsItem.model';

export const TutorsItemContainerStyled = styled.div<TutorsItemContainerStyledProps>`
  flex: 0 0 calc(50% - 12px);
  display: flex;
  box-sizing: border-box;
  padding: 0 12px;

  &:not(:nth-last-child(-n + 2)) {
    margin-bottom: 24px;
  }

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        flex: 0 0 calc(50% - 8px);
        padding: 20px 16px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 16px;
        min-height: 130px;
        margin: 0 4px;

        &:not(:nth-last-child(-n + 2)) {
          margin-bottom: 8px;
        }
      `,
      isMobile
    )}

  ${breakpoint(
    'smallDown',
    css`
      flex: 0 0 100%;

      &:nth-last-child(-n + 2) {
        margin-bottom: 8px;
      }

      &:not(:nth-last-child) {
        margin-bottom: 8px;
      }
    `
  )}
`;

export const TutorsItemInfoStyled = styled.div<TutorsItemInfoStyledProps>`
  flex: 1 1 auto;

  ${({ isMobile }): string =>
    isMobile
      ? ''
      : `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;
