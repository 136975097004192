import React, { ReactElement } from 'react';
import { Nullable } from '@lerna-core';
import { CourseSkillsContainerProps } from './courseSkills.model';
import { CourseSkillsContainerStyled } from './courseSkills.styled';
import {
  CourseTitleContainer,
  CourseTitleType,
} from '@features/course/courseTitle';
import { SkillsItemContainer } from '@features/course/courseSkills/skillsItem';
import { CoursePageArticleStyled } from '@features/course/course.styled';

export const CourseSkillsContainer = ({
  skills,
  translations,
}: CourseSkillsContainerProps): Nullable<ReactElement> => {
  if (skills.length === 0) {
    return null;
  }

  return (
    <CourseSkillsContainerStyled>
      <CourseTitleContainer
        title={translations.course_skills_title}
        type={CourseTitleType.h3}
      />
      <CoursePageArticleStyled>
        {skills.map((skill, idx) => (
          <SkillsItemContainer skill={skill} key={idx} />
        ))}
      </CoursePageArticleStyled>
    </CourseSkillsContainerStyled>
  );
};
