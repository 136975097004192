import { AppContext } from 'next/app';
import { sensitiveCookies } from './notificationManagement.meta';
import { cookieService, publicConfig } from '../../../services';
import { getCookiePermissions } from './notificationManagement.helper';
import { RegionsEnum } from '../../../models';

const setClearCookie = (acc: string[], name: string, cookie?: string) => {
  const hasCookie = !!cookieService.getCookie(name, cookie, false);
  const options = {
    name,
    val: '',
    days: -1,
    withPrefix: false,
  };

  if (hasCookie) {
    acc.push(
      cookieService.prepareCookieToSet({
        ...options,
        domain: `.${publicConfig.BASE_DOMAIN.split('.').slice(-2).join('.')}`,
      })
    );
    acc.push(cookieService.prepareCookieToSet(options));
  }

  return acc;
};

export const clearSensitiveCookies = (appContext: AppContext) => {
  if (publicConfig?.REGION !== RegionsEnum.by) {
    return;
  }
  const clearedCookies: string[] = [];
  const cookiePermissions = getCookiePermissions(
    appContext.ctx.req?.headers.cookie
  );

  if (!cookiePermissions?.advertising) {
    sensitiveCookies.adv.reduce(
      (acc: string[], name) =>
        setClearCookie(acc, name, appContext.ctx.req?.headers.cookie),
      clearedCookies
    );
  }

  if (!cookiePermissions?.analytics) {
    sensitiveCookies.analytics.reduce(
      (acc: string[], name) =>
        setClearCookie(acc, name, appContext.ctx.req?.headers.cookie),
      clearedCookies
    );
  }

  if (clearedCookies.length) {
    appContext.ctx.res?.setHeader('set-cookie', clearedCookies);
  }
};
