import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FooterCompanyStyledProps } from './footerBottom.model';
import { breakpoint, fontSettings } from '../../../styles';

export const FooterBottomStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  border-top: 1px solid ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.2)};

  ${breakpoint(
    'largeDown',
    css`
      padding: 32px 0;
      align-items: flex-start;
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      align-items: center;

      & > * {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    `
  )}
`;

export const FooterBottomWrapperStyled = styled.div`
  flex: 0 0 auto;

  ${breakpoint(
    'xlargeDown',
    css`
      flex: 0 1 auto;
    `
  )}
`;

export const FooterBottomInfoStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterBottomInfoTextStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.WHITE};
  white-space: pre-wrap;
  padding-left: 16px;
  margin-left: 16px;
  position: relative;

  &:not(:empty):before {
    content: '';
    width: 1px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.5)};
  }
`;

export const FooterBottomLogoStyled = styled.div`
  position: relative;

  ${breakpoint(
    'mediumDown',
    css`
      align-items: center;
      text-align: center;
    `
  )}
`;

export const FooterCompanyStyled = styled.div<FooterCompanyStyledProps>`
  flex: 0 0 20%;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.8)};
  display: ${({ isMobile }): string => (isMobile ? 'none' : 'block')};
  text-align: right;

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        display: ${isMobile ? 'block' : 'none'};
        ${isMobile ? 'margin-top: 42px;' : ''};
        flex: 0 1 auto;
        text-align: left;
      `
    )}

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        display: ${isMobile ? 'none' : 'block'};
        ${isMobile ? 'margin-top: 0;' : ''}
      `
    )}
`;
