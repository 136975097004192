import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLernaLogoComponent = ({
  width = 115,
  height = 34,
  fillColor = '#29004D',
}: IconProps): ReactElement => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115 34"
      width={width}
      height={height}
    >
      <g clipPath="url(#clip0)" fill={fillColor}>
        <path d="M35.326 19.412h9.13c0-6.518-3.932-10.067-9.13-10.067v10.067zm9.628 5.933a12.63 12.63 0 01-5.506 1.36c-4.113 0-8.136-1.913-8.136-8.612 0-2.9 1.252-6.155 3.164-8.739-5.198.272-10.736 5.05-10.736 12.147 0 7.524 5.343 11.331 10.813 11.331 2.304.03 4.556-.681 6.428-2.028a10.813 10.813 0 003.973-5.46zM56.54 18.41h8.394V9.345c-4.973 0-8.394 3.372-8.394 9.065zm-8.625 14.05h7.73V9.8h-7.73V32.46zM76.677 16.556c3.784-.942 6.238.132 6.238 4.043v11.862h7.73V16.643c0-4.94-2.848-7.434-6.238-7.434-3.21 0-6.554 2.453-7.73 7.348zm-8.453 15.905h7.716V9.799h-7.73l.015 22.662zM106.271 32.46h7.73V19.38c0-6.753-4.475-10.152-9.221-10.152-4.069 0-7.933 2.488-9.968 7.111l.055.045c5.523-1.962 11.404-1.124 11.404 6.137v9.94zM8.86 3H0v29.46h8.86V3zM15.189 26.673c-1.985 2.266-4.11 4.646-5.085 5.742h10.825V20.223a942.06 942.06 0 00-5.74 6.45z" />
        <path d="M93.8 27.298A7.128 7.128 0 0096.472 33l9.56-11.68a10.424 10.424 0 00-4.778-1.192c-3.295 0-7.454 2.085-7.454 7.17z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="none" transform="translate(0 3)" d="M0 0h114v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
