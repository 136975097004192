import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '../user';
import { requestDepartments } from './departments.actions';
import {
  getIsDepartmentsLoaded,
  getIsDepartmentsLoading,
} from './departments.selectors';

type DepartmentsLoaderProps = {
  children?: ReactElement;
};

export const DepartmentsLoaderContainer = ({
  children,
}: DepartmentsLoaderProps): ReactElement => {
  const isLoading = useSelector(getIsDepartmentsLoading);
  const isLoaded = useSelector(getIsDepartmentsLoaded);
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      requestDepartments(dispatch, user?.career?.companyId);
    }
  }, [isLoading, isLoaded]);

  return <>{children}</>;
};
