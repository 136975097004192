import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconTabWithClockComponent = ({
  width = 48,
  height = 48,
  strokeWidth = '2',
  strokeColor = '#000',
  fillColor = '#000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12H43"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M6 8C5.73478 8 5.48043 8.10536 5.29289 8.29289C5.10536 8.48043 5 8.73478 5 9C5 9.26522 5.10536 9.51957 5.29289 9.70711C5.48043 9.89464 5.73478 10 6 10C6.26522 10 6.51957 9.89464 6.70711 9.70711C6.89464 9.51957 7 9.26522 7 9C7 8.73478 6.89464 8.48043 6.70711 8.29289C6.51957 8.10536 6.26522 8 6 8ZM9 8C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9C10 8.73478 9.89464 8.48043 9.70711 8.29289C9.51957 8.10536 9.26522 8 9 8ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9C11 9.26522 11.1054 9.51957 11.2929 9.70711C11.4804 9.89464 11.7348 10 12 10C12.2652 10 12.5196 9.89464 12.7071 9.70711C12.8946 9.51957 13 9.26522 13 9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
        fill={fillColor}
      />
      <path
        d="M20.913 39H5C4.448 39 4 38.551 4 38V8C4 7.449 4.448 7 5 7H41C41.552 7 42 7.449 42 8V22.564C42.704 22.899 43.368 23.289 44 23.716V8C44 6.346 42.654 5 41 5H5C3.346 5 2 6.346 2 8V38C2 39.654 3.346 41 5 41H21.931C21.528 40.356 21.191 39.687 20.913 39Z"
        fill={fillColor}
      />
      <path
        d="M36 46C30.5 46 26 41.5 26 36C26 30.5 30.5 26 36 26C41.5 26 46 30.5 46 36C46 41.5 41.5 46 36 46ZM36 28C31.6 28 28 31.6 28 36C28 40.4 31.6 44 36 44C40.4 44 44 40.4 44 36C44 31.6 40.4 28 36 28ZM37 36.5V30H35V35.5L31.4 38.2L32.6 39.8L37 36.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
