import { pageUrls } from '@constants';
import {
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  Nullable,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../../coursesPage';
import { EmptyClearButtonStyled } from './emptyClearButton.styled';
import { FiltersClearAllContainer } from '@features/common/filters';

export const EmptyClearButtonContainer = (): Nullable<ReactElement> => {
  const { translations, filters } = useCoursesPageContext();
  const router = useRouter();
  const defaultPathname = router.query?.fastlink
    ? pageUrls.courses
    : router.pathname;

  return (
    <EmptyClearButtonStyled>
      <FiltersClearAllContainer
        filters={filters}
        defaultPathname={defaultPathname}
      >
        <ButtonContainer
          color={ButtonColor.default}
          size={ButtonSize.small}
          title={translations?.clear_filters_button_label}
          borderRadius={100}
        />
      </FiltersClearAllContainer>
    </EmptyClearButtonStyled>
  );
};
