import React, { ReactElement } from 'react';
import { ListItemContainerProps } from './listItem.model';
import { ListItemInnerStyled, ListItemStyled } from './listItem.styled';
import { ListItemFooter } from './listItemFooter';
import { ListItemHeaderContainer } from './listItemHeader';
import { ListItemWholeLinkContainer } from './listItemWholeLink';

export const ListItemContainer = ({
  course,
  handleOpenSingUpCoursePopup,
  withButtons = true,
  translations,
  handleGoToCourse,
  courseHref,
  hrefTarget,
  useCourseHref = false,
  isListingWithBenefitsOnly,
  isPricesHiddenOnListingCards,
  withSphereFilter,
  discountProgramColors,
  courseIndex,
}: ListItemContainerProps): ReactElement => {
  //todo вернуть КТТС course.status === CourseStatus.active вместо isActive
  const showCourseLink = !!course.isActive && courseHref;

  return (
    <ListItemStyled data-instance="course">
      <ListItemInnerStyled>
        {showCourseLink && (
          <ListItemWholeLinkContainer
            handleGoToCourse={handleGoToCourse}
            courseHref={courseHref}
            useHref={useCourseHref}
            hrefTarget={hrefTarget}
          />
        )}
        <ListItemHeaderContainer
          course={course}
          translations={translations}
          withSphereFilter={withSphereFilter}
          discountProgramColors={discountProgramColors}
        />
        <ListItemFooter
          course={course}
          courseIndex={courseIndex}
          translations={translations}
          handleOpenSingUpCoursePopup={handleOpenSingUpCoursePopup}
          withButtons={withButtons}
          isListingWithBenefitsOnly={isListingWithBenefitsOnly}
          isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
          handleGoToCourse={handleGoToCourse}
          courseHref={courseHref}
          useHref={useCourseHref}
          hrefTarget={hrefTarget}
        />
      </ListItemInnerStyled>
    </ListItemStyled>
  );
};
