import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../styles';
import { PaperComponentStyled } from '../../../paper';
import { SelectStyled } from '../../../select';

export const FormUserEditStyled = styled.div`
  max-width: ${({ theme }): string => (theme.IS_MOBILE ? 'auto' : '600px')};
  width: 100%;

  ${PaperComponentStyled} {
    padding-top: 32px;
  }

  button {
    width: 100%;
  }

  input {
    border-radius: 8px;
  }

  ${SelectStyled} {
    border-radius: 8px;
  }
`;

export const FormUserEditTitleStyled = styled.p`
  margin: 32px 0 16px;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme)}

  ${breakpoint(
    'mediumDown',
    css`
      margin: 20px 0;
    `
  )}
`;
