import React, { ReactElement } from 'react';
import { getSubscriptionAvailabilityInfo } from './leftSubscriptionInfo.helpers';
import { dateService, Nullable } from '@lerna-core';
import { useFormArchiveContext } from '../../../formArchive.context';
import { LeftSubscriptionInfoStyled } from './leftSubscriptionInfo.styled';

export const LeftSubscriptionInfoContainer = (): Nullable<ReactElement> => {
  const { translations, subscriptionAvailability } = useFormArchiveContext();

  const subscriptionAvailabilityInfo =
    subscriptionAvailability &&
    getSubscriptionAvailabilityInfo(
      translations,
      subscriptionAvailability.isClaimAvailable,
      subscriptionAvailability.isCourseClaimed,
      subscriptionAvailability.coursesAvailable,
      subscriptionAvailability.coursesClaimed,
      subscriptionAvailability.subscriptionAccess.subscription.title,
      dateService.formatDate(subscriptionAvailability.claimPeriodEnd),
      dateService.formatDate(subscriptionAvailability.nextClaimPeriodStart)
    );

  if (!subscriptionAvailabilityInfo) return null;

  return (
    <LeftSubscriptionInfoStyled
      dangerouslySetInnerHTML={{ __html: subscriptionAvailabilityInfo }}
    />
  );
};
