import { RefObject, useEffect } from 'react';

export const useClickOutsideHook = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void
): void => {
  const handleClick = (e: Event): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return (): void => {
      document.removeEventListener('click', handleClick, true);
    };
  });
};
