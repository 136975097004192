import { useEffect, useState } from 'react';
import { UseScrollArrowModel } from './pageScrollArrow.model';

export const useScrollArrow = (): UseScrollArrowModel => {
  const [showArrow, setShowArrow] = useState<boolean>(false);

  const handleArrowVisibility = (): void => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;

    if (windowHeight * 2 < scrollY) {
      setShowArrow(true);
    }
    if (windowHeight * 2 > scrollY) {
      setShowArrow(false);
    }
  };

  useEffect(() => {
    handleArrowVisibility();
    window.addEventListener('scroll', handleArrowVisibility);

    return (): void => {
      window.removeEventListener('scroll', handleArrowVisibility);
    };
  }, []);

  return { showArrow };
};
