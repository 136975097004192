import { CourseLogoPropsModel, CourseLogoSizeEnum } from './courseLogo.model';

/**
 * Get course logo props for course head element
 * @return {object}
 * @param - size
 * */

export const getCourseLogoProps = (
  size: CourseLogoSizeEnum
): CourseLogoPropsModel => {
  switch (size) {
    case CourseLogoSizeEnum.small: {
      return {
        width: 48,
        height: 48,
        preset: 'logoCourseSmall',
      };
    }
    case CourseLogoSizeEnum.medium: {
      return {
        width: 64,
        height: 64,
        preset: 'logoCourseMedium',
      };
    }

    case CourseLogoSizeEnum.xbig: {
      return {
        width: 120,
        height: 120,
        preset: 'logoCourseXBig',
      };
    }
    case CourseLogoSizeEnum.big:
    default: {
      return {
        width: 80,
        height: 80,
        preset: 'logoCourse',
      };
    }
  }
};
