import { MetaPaginationModel } from '../../models';
import { DropdownPosition } from '../dropdown';

export type FilterPopupOption = Record<string, unknown>;
export type FilterPopupOptions = FilterPopupOption[];

export enum FilterSearchType {
  local = 'local',
  api = 'api',
}

export enum FilterOptionsTypes {
  checkbox = 'checkbox',
  radio = 'radio',
}

export type FilterPopupProps = {
  open: boolean;
  idKey: string;
  labelKey: string;
  selectAllOptionLabel: string;
  searchPlaceholder?: string;
  resetButtonTitle?: string;
  applyButtonTitle?: string;
  options: FilterPopupOptions;
  selected: string[];
  searchable?: boolean;
  actions?: boolean;
  onApply?: (selected: string[]) => void;
  onSelect?: (selected: string[]) => void;
  onClose: () => void;
  onReset?: (selected: string[]) => void;
  handlePagination?: (page: number, search?: string) => void;
  handleSearch?: (search: string) => void;
  handleApiSearch?: (search: string) => void;
  isPaginating?: boolean;
  hideSelectAllOption?: boolean;
  meta?: MetaPaginationModel;
  searchType?: FilterSearchType;
  optionsType: FilterOptionsTypes;
  position?: DropdownPosition;
};
