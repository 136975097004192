import { InputColor } from '../../../../styles';
import React, { KeyboardEventHandler, ReactElement } from 'react';
import { Nullable } from '../../../../models';

export interface BaseInputProps {
  id: string;
  name: string;
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  autoComplete?: string;
  color?: InputColor;
  bordered?: boolean;
  setFieldValue?: (field: string, value: string) => void;
  min?: number | string;
  max?: number | string;
  hintButton?: Nullable<ReactElement>;
}

export interface InputStyledProps {
  color?: InputColor;
  bordered?: boolean;
  disabled?: boolean;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
}

export interface OnResetProps {
  onReset?: (fieldName: string, value: string) => void;
}

export interface InputProps extends BaseInputProps, OnResetProps {
  type?: InputType;
  showResetButton?: boolean;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
}

export type InputType = 'text' | 'number' | 'email' | 'date';
