import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, transition } from '@lerna-core';
import { LeftDescriptionStyledProps } from './leftDescription.model';
import { DESCRIPTION_HEIGHT } from './leftDescription.constants';
import { rgba } from 'polished';

export const TextStyled = styled.div``;

export const LeftDescriptionStyled = styled.div<LeftDescriptionStyledProps>`
  margin-top: 20px;

  ${TextStyled} {
    position: relative;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
    ${({ isCollapsed, theme }): string =>
      isCollapsed
        ? `
    max-height: ${
      theme.IS_MOBILE ? DESCRIPTION_HEIGHT.MOBILE : DESCRIPTION_HEIGHT.DESKTOP
    }px;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 56px;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    }
  `
        : ''}
    

    h2 {
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
      margin: 0 0 10px 0;
      font-weight: 600;

      ${breakpoint(
        'mediumDown',
        css`
          ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
        `
      )}
    }

    p {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }
    }

    b {
      font-weight: 600;
    }

    ul {
      margin: 0 0 10px 0;
      padding: 0 0 0 25px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
      `
    )}
  }
`;

export const CollapseButtonStyled = styled.span`
  display: inline-block;
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_14, theme.FONT)}
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 10px;
  ${transition('background-color')}

  &:hover {
    background-color: ${({ theme }): string => theme.COLOR.BLACK_08};
  }
`;
