import { ITheme } from './default.model';

export const themeDefault = (isMobile: boolean): ITheme => {
  return {
    IS_MOBILE: isMobile,
    FONT: {
      SIZE_9: 9,
      SIZE_10: 10,
      SIZE_11: 11,
      SIZE_12: 12,
      SIZE_13: 13,
      SIZE_14: 14,
      SIZE_15: 15,
      SIZE_16: 16,
      SIZE_17: 17,
      SIZE_18: 18,
      SIZE_20: 20,
      SIZE_22: 22,
      SIZE_24: 24,
      SIZE_26: 26,
      SIZE_28: 28,
      SIZE_30: 30,
      SIZE_32: 32,
      SIZE_34: 34,
      SIZE_36: 36,
      SIZE_38: 38,
      SIZE_40: 40,
      SIZE_48: 48,
      SIZE_54: 54,
      SIZE_56: 56,
      LINE_HEIGHT_12: 12,
      LINE_HEIGHT_16: 16,
      LINE_HEIGHT_20: 20,
      LINE_HEIGHT_22: 22,
      LINE_HEIGHT_24: 24,
      LINE_HEIGHT_28: 28,
      LINE_HEIGHT_30: 30,
      LINE_HEIGHT_32: 32,
      LINE_HEIGHT_34: 34,
      LINE_HEIGHT_38: 38,
      LINE_HEIGHT_41: 41,
      LINE_HEIGHT_44: 44,
      LINE_HEIGHT_48: 48,
      LINE_HEIGHT_60: 60,
      LINE_HEIGHT_68: 68,
    },
    COLOR: {
      RED: '#F60065',
      ROSE: '#DF4986',
      LIGHT_ROSE: '#FEE5F0',
      WHITE: '#FFFFFF',
      WHITE_32: 'rgba(255, 255, 255, .32)',
      WHITE_24: 'rgba(255, 255, 255, .24)',
      BLACK: '#000000',
      BLACK_4: '#F5F5F5',
      BLACK_08: 'rgba(0, 0, 0, .08)',
      BLACK_12: 'rgba(0, 0, 0, .12)',
      BLUE: '#3F2BFF',
      PURPLE: '#29004D',
      BUTTON_PURPLE_TEXT: '#FFFFFF',
      LIGHT_PURPLE: '#FBF7FF',
      MEDIUM_LIGHT_PURPLE: '#E3E1FA',
      MEDIUM_PURPLE: '#7F609A',
      PURPLE_INFO_BG: '#F5EAFF',
      PURPLE_SUBSCRIPTION: '#713AE0',
      PURPLE_SUBSCRIPTION_BG: '#F1EBFC',
      SUBSCRIPTION: '#3975EA',
      SUBSCRIPTION_BG: '#EAF5FF',
      TEXT_DARK_COLOR: '#201926',
      TEXT_MEDIUM_COLOR: '#49454D',
      TEXT_LIGHT_COLOR: '#78747A',
      INFO_GREEN_LIGHT_COLOR: '#E6FFD9',
      INFO_GREEN_COLOR: '#2E8200',
      INFO_RED_COLOR: '#C7141A',
      INFO_BLUE_LIGHT_COLOR: '#EAF5FF',
      INFO_BLUE_COLOR: '#005DAD',
      ICON_GREY_COLOR: '#E0E0E0',
      DEFAULT_GRADIENT_START: '#EE2C72',
      DEFAULT_GRADIENT_END: '#4C07DF',
      CHECKBOX_DEFAULT: '#78747A',
      CHECKBOX_ICON_DEFAULT: '#78747A',
      CHECKBOX_HOVER: '#49454D',
      CHECKBOX_ICON_HOVER: '#49454D',
      CHECKBOX_CHECKED: '#201926',
      ACCENT: '#DBFF73',
      ERROR: {
        100: '#FFE5E6',
        500: '#F60065',
        900: '#C7141A',
      },
      PRIMARY: {
        100: '#ECEAFB',
        300: '#FBF7FF',
        500: '#713AE0',
        900: '#29004D',
      },
      TEXT: {
        100: '#78747A',
        500: '#49454D',
        900: '#201926',
      },
    },
    ZINDEX: {
      BOTTOM: -1,
      TOP_BLOCK: 1,
      TOP_MODULE: 10,
      TOP_PAGE_LAYER: 100,
      TOP_PAGE_CONTENT: 200,
      TOP_PAGE_HINTS: 300,
    },
  };
};
