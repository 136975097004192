import styled from 'styled-components';
import { fontSettings } from '../../styles';

export const DividerContainerStyled = styled.div<{ containTitle?: string }>`
  margin: 22px 0;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  ${(props): string => fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)}
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  ${({ containTitle }): string => (containTitle ? 'gap: 18px;' : '')}

  &::after,
  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: ${(props): string => props.theme.COLOR.BLACK_12};
  }
`;
