import { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSVariables, Nullable } from '../../models';
import { browserService } from '../../services';
import {
  BODY_CLASS_NAME,
  cleanDOM,
  getVisibleElement,
  scrollToElement,
} from './onboarding.helper';
import {
  ElementPropertiesModel,
  OnboardingProps,
  OnboardingStepModel,
} from './onboarding.model';
import {
  OnboardingStyled,
  OnboardingStyledWrapper,
  PointerStyled,
} from './onboarding.styled';
import { OnboardingPopupContainer } from './onboardingPopup';

const DEFAULT_TARGET_PROPERTIES: ElementPropertiesModel = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};

export const Onboarding = ({
  steps,
  parent,
  withCounter = true,
  handleClose,
  gap = 20,
  showPointer = false,
  showMobilePointer = true,
  translations,
}: OnboardingProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(-1);
  const [targetProperties, setTargetProperties] =
    useState<ElementPropertiesModel>(DEFAULT_TARGET_PROPERTIES);

  const activeStep = useMemo<Nullable<OnboardingStepModel>>(() => {
    if (steps.length && activeStepIndex >= 0) {
      return steps[activeStepIndex];
    }

    return null;
  }, [activeStepIndex]);

  useEffect(() => {
    const getTargetProperties = () => {
      if (!activeStep) return;

      const elements = document.querySelectorAll(
        `[data-onboarding-id="${activeStep.target}"]`
      );

      const target = getVisibleElement(elements);

      if (!target) return;

      scrollToElement(target);

      const rect = target.getBoundingClientRect();

      const height =
        rect.height > window.innerHeight ? window.innerHeight : rect.height;

      const targetProperties = {
        width: rect.width,
        height: height,
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };

      setTargetProperties(targetProperties);
    };

    window.addEventListener('resize', getTargetProperties);
    getTargetProperties();

    return (): void => {
      window.removeEventListener('resize', getTargetProperties);
    };
  }, [activeStep?.target]);

  useEffect(() => {
    setActiveStepIndex(0);
  }, [steps]);

  useEffect(() => {
    if (activeStep) {
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarPlugWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.body.classList.add(BODY_CLASS_NAME);
    }

    return (): void => {
      cleanDOM();
    };
  }, [activeStep]);

  if (!activeStep) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <OnboardingPopupContainer
          withCounter={withCounter}
          activeStepIndex={activeStepIndex}
          handleClose={handleClose}
          stepsCount={steps.length}
          step={activeStep}
          translations={translations}
          setActiveStepIndex={setActiveStepIndex}
          targetProperties={targetProperties}
          gap={gap}
        />,
        parent || document.querySelector('#__next') || document.body
      )}

      {ReactDOM.createPortal(
        <>
          <OnboardingStyledWrapper />
          <OnboardingStyled style={targetProperties}>
            <PointerStyled
              showPointer={showPointer}
              showMobilePointer={showMobilePointer}
            />
          </OnboardingStyled>
        </>,
        parent || document.querySelector('#__next') || document.body
      )}
    </>
  );
};
