import { createSelector } from 'reselect';

import { RootState } from '../../models';
import { MenuState } from './menu.model';

export const getTopMenuElements = createSelector(
  (state: RootState<MenuState>) => state.menu,
  (reducer: MenuState) => reducer.top || []
);

export const getSideMenuElements = createSelector(
  (state: RootState<MenuState>) => state.menu,
  (reducer: MenuState) => reducer.side || []
);

export const getMenuStatus = createSelector(
  (state: RootState<MenuState>) => state.menu,
  (reducer: MenuState) => reducer.status
);
