import { LogoDeleteClient } from './logoDelete.client';
import { ImageResourceModel } from '../../../../models';

export async function logoDeleteClientProvider(
  params: ImageResourceModel,
  host?: string
): Promise<boolean> {
  const response = await new LogoDeleteClient(host, params).post();

  return response as unknown as Promise<boolean>;
}
