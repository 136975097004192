import React, { ReactElement } from 'react';

import { useTranslations } from '../../../translations';
import { InfoPropContainer } from '../infoProp';
import {
  UserInfoParamsContainerStyled,
  UserInfoParamsTitleStyled,
} from '../userInfo.styled';
import { EducationStreamModel } from '../../../educationStreams';

type InfoCompanyContainerProps = {
  educationStreams: EducationStreamModel[];
};

export const InfoEducationStreamsContainer = ({
  educationStreams,
}: InfoCompanyContainerProps): ReactElement => {
  const translations = useTranslations();
  const educationStreamsToTextTitle = educationStreams
    .map((stream) => stream.title)
    .join(', ');

  return (
    <>
      <UserInfoParamsTitleStyled margin="20px 0 16px">
        {translations.interests}
      </UserInfoParamsTitleStyled>
      <UserInfoParamsContainerStyled>
        <InfoPropContainer title={null} value={educationStreamsToTextTitle} />
      </UserInfoParamsContainerStyled>
    </>
  );
};
