import styled from 'styled-components';

export const LogoContainerStyled = styled.div`
  flex: 0 0 auto;

  svg,
  img {
    cursor: pointer;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const LogoWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const LogoCollaborateWithLerna = styled.div`
  display: flex;
  align-items: center;
  margin-top: -4px;

  svg:first-child {
    margin: 2px 5px 0;

    ${({ theme }) =>
      theme.IS_MOBILE
        ? ` height: 12px; width: 12px `
        : ` height: 16px; width: 16px `}
  }

  svg:nth-child(2) {
    height: 24px;
    width: 82px;

    ${({ theme }) =>
      theme.IS_MOBILE
        ? `height: 18px; width: 61px`
        : `height: 24px; width: 82px`}
  }
`;
