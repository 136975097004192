import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { VendorAllocatedFilterContainerProps } from './vendorsAllocatedFilter.model';
import {
  requestTransferCoursesAllocated,
  setTransferCoursesAllocatedFilters,
} from '../../coursesTransfer.actions';
import { getCurrencies } from '../../../../currencies';
import { getCoursesTransferSelector } from '../../coursesTransfer.selectors';
import {
  getVendors,
  getVendorsMeta,
  getVendorsStatus,
} from '../../../../vendors';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import { TableFilterContainer } from '../../../../table';
import { FilterSearchType } from '../../../../filterPopup';

export const VendorsAllocatedFilterContainer = ({
  translations,
  handlePagination,
  handleSearch,
}: VendorAllocatedFilterContainerProps): ReactElement => {
  const currencies = useSelector(getCurrencies);
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const vendorList = useSelector(getVendors);
  const { isPaginating } = useSelector(getVendorsStatus);
  const vendorMeta = useSelector(getVendorsMeta);

  const { id, dispatch, coursesProvider, courseExclusionCompanyId } =
    useCoursesTransferContext();

  const handleApplyFilter = (vendors: string[]): void => {
    dispatch(setTransferCoursesAllocatedFilters({ vendors }));
  };

  const handleRequest = (vendors: string[]): void => {
    dispatch(
      requestTransferCoursesAllocated(
        coursesProvider,
        {
          ...filters,
          removed,
          added,
          id,
          relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          vendors,
        },
        currencies,
        vendorList
      )
    );
  };

  return (
    <TableFilterContainer
      idKey="slug"
      labelKey="title"
      filterOptions={vendorList}
      filters={filters.vendors}
      meta={vendorMeta}
      isPaginating={isPaginating}
      searchType={FilterSearchType.api}
      handleSearch={handleSearch}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      handlePagination={handlePagination}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.vendor}
    />
  );
};
