import React, { ReactElement } from 'react';
import { SuggestItemContainerProps } from './suggestItem.model';
import {
  SuggestItemContainerStyled,
  SuggestItemTermStyled,
} from './suggestItem.styled';
import { stringService } from '../../../../services/stringService';

export const SuggestItemContainer = ({
  item,
  term,
  handleClick,
  arrowIcon,
}: SuggestItemContainerProps): ReactElement => {
  const handleClickSuggest = (): void => {
    handleClick(item.title);
  };

  const getSuggestWithTerm = (): ReactElement => {
    const clearTitle = stringService.replaceNBSP(item.title);
    const index = clearTitle.toLowerCase().indexOf(term.toLowerCase());

    if (index >= 0 && term.length) {
      return (
        <>
          {clearTitle.slice(0, index)}
          <SuggestItemTermStyled>
            {clearTitle.slice(index, index + term.length)}
          </SuggestItemTermStyled>
          {clearTitle.slice(index + term.length)}
        </>
      );
    }

    return <>{clearTitle}</>;
  };

  return (
    <SuggestItemContainerStyled onClick={handleClickSuggest}>
      {getSuggestWithTerm()}
      {arrowIcon}
    </SuggestItemContainerStyled>
  );
};
