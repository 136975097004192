import { FormPopupSubmitButtonContainerStyled } from '@features/common/popups';
import {
  MessagePopupStatusStyled,
  MessagePopupStyled,
  MessagePopupSubtitleStyled,
} from './messagePopup.styled';
import { ButtonColor, ButtonContainer, PopupTitleContainer } from '@lerna-core';
import React, { ReactElement, useMemo } from 'react';
import { getMessagePopupStatusIcon } from './messagePopup.helpers';

export enum MessagePopupStatus {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

const ICON_SIZE = 80;

type MessagePopupComponentProps = {
  title?: string;
  subtitle?: string | null;
  buttonTitle?: string | null;
  buttonCallback?: () => void;
  status: MessagePopupStatus;
  iconSize?: number;
  withoutIcon?: boolean;
};

export const MessagePopupComponent = ({
  title,
  subtitle,
  buttonTitle,
  buttonCallback,
  status,
  iconSize = ICON_SIZE,
  withoutIcon = false,
}: MessagePopupComponentProps): ReactElement => {
  const hasButton = buttonCallback && buttonTitle;
  const statusIcon = useMemo(
    () => getMessagePopupStatusIcon(status, iconSize),
    []
  );

  return (
    <MessagePopupStyled>
      {!withoutIcon && (
        <MessagePopupStatusStyled>{statusIcon}</MessagePopupStatusStyled>
      )}
      <PopupTitleContainer text={title} />
      {subtitle && (
        <MessagePopupSubtitleStyled>{subtitle}</MessagePopupSubtitleStyled>
      )}
      {hasButton && (
        <FormPopupSubmitButtonContainerStyled>
          <ButtonContainer
            onClick={buttonCallback}
            color={ButtonColor.purple}
            borderRadius={8}
            title={buttonTitle || ''}
          />
        </FormPopupSubmitButtonContainerStyled>
      )}
    </MessagePopupStyled>
  );
};
