import * as constants from './company.constants';
import {
  Company,
  CompanyActions,
  CompanyState,
  CustomersCountModel,
} from './company.model';

const initialState: CompanyState = {
  company: null,
  status: {
    isLoaded: false,
    isLoading: false,
  },
};

export const companyReducer = (
  state: CompanyState = initialState,
  action: CompanyActions
): CompanyState => {
  switch (action.type) {
    case constants.REQUEST_COMPANY_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case constants.REQUEST_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload as Company,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case constants.REQUEST_CUSTOMERS_COUNT_SUCCESS:
      return {
        ...state,
        company: {
          ...(state.company as Company),
          ...(action.payload as CustomersCountModel),
        },
      };

    case constants.REQUEST_COMPANY_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};
