import React, { ReactElement, useEffect, useState } from 'react';

import { GenericFormControlContainerProps } from '../../../form';
import { GenericFormControlContainer } from '../../../form';
import { SelectOption } from '../../../select';
import {
  userPositionsByCompanyProvider,
  userPositionsProvider,
} from '../userPositions.clientProvider';

type SelectUserPositionProps = GenericFormControlContainerProps & {
  companyId?: string;
  requestHost?: string;
};

export const PositionSelectContainer = ({
  errors,
  onChange,
  onBlur,
  defaultOption,
  placeholder,
  label,
  name,
  color,
  bordered,
  companyId,
  requestHost,
}: SelectUserPositionProps): ReactElement => {
  const [positions, setPositions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (companyId) {
      userPositionsByCompanyProvider(companyId, requestHost).then(setPositions);
    } else {
      userPositionsProvider(requestHost).then(setPositions);
    }
  }, []);

  return (
    <GenericFormControlContainer
      type="select"
      errors={errors}
      onChange={(value): void => {
        onChange(value);
      }}
      onBlur={(value): void => {
        onBlur(value);
      }}
      initialValue={defaultOption?.value || ''}
      placeholder={placeholder}
      label={label}
      name={name}
      isRequired
      color={color}
      bordered={bordered}
      clearControl={false}
      options={positions}
      defaultOption={defaultOption}
    />
  );
};
