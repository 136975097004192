import React, { ReactElement } from 'react';
import {
  BonusPointsContainerStyled,
  BonusPointsCountStyled,
} from './bonusPoints.styled';
import { BonusPointsContainerProps } from './bonusPoints.model';
import { stringService } from '../../services';

export const BonusPointsContainer = ({
  points,
  label,
}: BonusPointsContainerProps): ReactElement => {
  return (
    <BonusPointsContainerStyled>
      <BonusPointsCountStyled>
        {typeof points === 'number' && stringService.numberWithSpaces(points)}
      </BonusPointsCountStyled>{' '}
      {label}
    </BonusPointsContainerStyled>
  );
};
