import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const LeftAdvantagesStyled = styled.div`
  margin: 32px 0;

  ${breakpoint(
    'mediumDown',
    css`
      margin: 20px 0;
    `
  )}
`;
