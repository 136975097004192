import {
  DeleteUserPhotoClient,
  DeleteUserPhotoParams,
} from './deleteUserPhoto.client';

export async function deleteUserPhotoClientProvider(
  params: DeleteUserPhotoParams
): Promise<boolean> {
  const response = await new DeleteUserPhotoClient(params).post();

  return response as unknown as Promise<boolean>;
}
