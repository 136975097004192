import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { getFilterIcon } from './filterIcon.helper';
import { FilterIconStyled } from './filterIcon.styled';
import { FilterIconContainerProps } from './filterIcon.model';

export const FilterIconContainer = ({
  filterSlug,
  background,
}: FilterIconContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const FilterIcon = getFilterIcon(filterSlug);

  if (!FilterIcon) return null;

  return (
    <FilterIconStyled background={background}>
      <FilterIcon fillColor={theme.COLOR.WHITE} />
    </FilterIconStyled>
  );
};
