import styled from 'styled-components';

export const SelectDropdownStyled = styled.div<{ width?: number }>`
  left: 0;
  top: calc(100% + 8px);
  box-sizing: border-box;
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  max-height: 350px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props): string => props.theme.COLOR.WHITE};
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
  border-radius: 16px;
  padding: 10px 0;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_MODULE};
  cursor: default;
  overflow-x: hidden;
  overflow-y: auto;
`;
