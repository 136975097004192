import { HeaderBannerContainer } from '@features/common/headerBanner';
import { SearchContainer } from '@features/common/search';
import { SeoTextH1Container } from '@features/courses/coursesSeoText';
import { WildberriesAdvantagesContainer } from '@features/wildberries';
import {
  browserService,
  CompanyUuidEnum,
  ContentWrapperStyled,
  DeviceInfoContext,
  MenuSwitcherContainer,
  Nullable,
  useDidMount,
  useIntersectionObserver,
  useOffsetTop,
  useUser,
} from '@lerna-core';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CoursesAdvantagesContainer } from '../coursesAdvantages';
import { CoursesCompensationHintContainer } from '../coursesCompensationHint';
import { CoursesCountContainer } from '../coursesCount';
import { FiltersTabsContainer } from '../coursesFilters';
import { CoursesHeaderContainer } from '../coursesHeader';
import { useCoursesPageContext } from '../coursesPage';
import { SearchPopupWrapContainer } from '../coursesSearch';
import { getAppSwitcherBreakpoint } from './coursesHead.helper';
import { coursesHeadTopLinks } from './coursesHead.meta';
import { CoursesHeadContainerStyled } from './coursesHead.styled';
import { FiltersResetContainer } from '@features/common/filters';

export const CoursesHeadContainer = (): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const deviceInfoContext = useContext(DeviceInfoContext);
  const { user } = useUser();
  const offsetTop = useOffsetTop();
  const didMount = useDidMount();
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const coursesListRef = useRef<Nullable<HTMLDivElement>>(null);
  const refFilters = useRef<Nullable<HTMLDivElement>>(null);
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const showAppSwitcher = !!user;
  const translations = coursesContext.translations;
  const withAdvantages = !!coursesContext.advantages;
  const isWildberries = user?.career?.companyId === CompanyUuidEnum.wildberries;
  const isTochkaBank = user?.career?.companyId === CompanyUuidEnum.tochkaBank;
  const customerCoursesCount = coursesContext.customerCourses.length;
  const isSearchHidden = coursesContext.user?.career?.isSearchHiddenOnListing;
  const isFiltersHidden = coursesContext.user?.career?.isFiltersHiddenOnListing;
  const headerOffset = isMobile ? 74 : 82;

  const menuSwitcherLinks = useMemo(
    () => coursesHeadTopLinks(translations, customerCoursesCount),
    []
  );
  const appSwitcherHiddenAfter = useMemo(
    () => getAppSwitcherBreakpoint(menuSwitcherLinks),
    []
  );

  const entryFilters = useIntersectionObserver(refFilters, {
    rootMargin: `-${offsetTop}px`,
  });

  const isIntersectingFilters = entryFilters?.isIntersecting || false;
  const showFilters =
    didMount && !isFiltersHidden && (!isIntersectingFilters || isMobile);

  const handleSticky = (stickyStatus: boolean): void => {
    setIsSticky(stickyStatus);
  };

  const handleScrollToFilters = useCallback(
    (scrollBehaviour?: ScrollBehavior) => {
      if (!!refFilters?.current) {
        browserService.scrollToElement(
          refFilters.current,
          0,
          refFilters.current?.offsetTop - headerOffset,
          scrollBehaviour
        );
      }
    },
    [refFilters]
  );

  const handleScrollToCourses = useCallback(
    (scrollBehaviour?: ScrollBehavior) => {
      if (!!coursesListRef?.current) {
        browserService.scrollToElement(
          coursesListRef.current,
          0,
          coursesListRef.current?.offsetTop - headerOffset,
          scrollBehaviour
        );
      }
    },
    [refFilters]
  );

  const wildberriesAdvantages = isWildberries && (
    <WildberriesAdvantagesContainer
      handleScrollToCourses={handleScrollToCourses}
    />
  );

  const advantages = useMemo(
    () =>
      withAdvantages &&
      !isTochkaBank &&
      !isWildberries && (
        <CoursesAdvantagesContainer
          handleScrollToCourses={handleScrollToFilters}
        />
      ),
    [handleScrollToFilters]
  );

  const seoH1Text = useMemo(() => {
    if (advantages || wildberriesAdvantages) {
      return null;
    }

    return <SeoTextH1Container />;
  }, [advantages, wildberriesAdvantages]);

  return (
    <>
      <HeaderBannerContainer banners={coursesContext.banners} />
      <CoursesHeaderContainer
        showSearch={!isSearchHidden}
        alwaysShowSearch={!isSearchHidden}
        showFilters={showFilters}
        translations={translations}
        company={coursesContext.company}
        handleSticky={handleSticky}
      />
      <CoursesHeadContainerStyled>
        <ContentWrapperStyled isMobile={isMobile}>
          {showAppSwitcher && (
            <MenuSwitcherContainer
              isAdaptive
              links={menuSwitcherLinks}
              hiddenAfter={appSwitcherHiddenAfter}
            />
          )}
          {wildberriesAdvantages}
          {advantages}
          {seoH1Text}
          <FiltersTabsContainer ref={refFilters} />
          <SearchContainer showSearch={isMobile && !isSearchHidden} />
          <CoursesCompensationHintContainer />
          {<div ref={coursesListRef} />}
          <CoursesCountContainer />
          {!isMobile && <FiltersResetContainer translations={translations} />}
        </ContentWrapperStyled>

        {!isSearchHidden && (
          <SearchPopupWrapContainer
            isSticky={isSticky}
            handleScrollToCourses={handleScrollToCourses.bind(
              undefined,
              'auto'
            )}
          />
        )}
      </CoursesHeadContainerStyled>
    </>
  );
};
