import { publicConfig, RequestClient, RequestHeader } from '@lerna-core';
import {
  B2BOrderApiResponse,
  B2BOrderRequestParams,
  B2BOrderResponse,
  B2CFreeOrderApiResponse,
  B2CFreeOrderResponse,
  B2COrderApiResponse,
  B2COrderRequestParams,
  B2COrderResponse,
  FreeOrderRequestParams,
} from './form.model';
import { mapB2BOrder, mapB2CFreeOrder, mapB2COrder } from './form.adapter';

export class B2BOrderClient extends RequestClient<
  B2BOrderRequestParams,
  B2BOrderApiResponse,
  B2BOrderResponse
> {
  protected path = 'user-api/v1/orders';
  protected requestParameters: B2BOrderRequestParams;
  protected adapter = mapB2BOrder;

  constructor(params: B2BOrderRequestParams, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);
    this.requestParameters = params;
  }
}

export class B2COrderClient extends RequestClient<
  B2COrderRequestParams,
  B2COrderApiResponse,
  B2COrderResponse
> {
  protected path = 'course-api/v1/order';
  protected requestParameters: B2COrderRequestParams;
  protected adapter = mapB2COrder;

  constructor(params: B2COrderRequestParams, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);

    this.requestParameters = params;
  }
}

export class DiscountProgramOrderClient extends RequestClient<
  B2COrderRequestParams,
  B2COrderApiResponse,
  B2COrderResponse
> {
  protected path = 'course-api/v1/discount-orders';
  protected requestParameters: B2COrderRequestParams;
  protected adapter = mapB2COrder;

  constructor(params: B2COrderRequestParams, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);

    this.requestParameters = params;
  }
}

export class FreeOrderClient extends RequestClient<
  FreeOrderRequestParams,
  B2CFreeOrderApiResponse,
  B2CFreeOrderResponse
> {
  protected path = 'orders-api/v1/customers/order-course';
  protected requestParameters: FreeOrderRequestParams;
  protected adapter = mapB2CFreeOrder;

  constructor(params: FreeOrderRequestParams, headers: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);

    this.requestParameters = params;
  }
}
