import { PromoCodeClient } from './promoCode.client';
import { PromoCodeModel, PromoCodeRequestParams } from './promoCode.model';

export async function promoCodeClientProvider(
  params: PromoCodeRequestParams
): Promise<PromoCodeModel> {
  const response = await new PromoCodeClient(params).get();

  return response as unknown as Promise<PromoCodeModel>;
}
