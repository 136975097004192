import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { TabChildrenProps, TabItemContainerProps } from './tabItem.model';
import {
  TabCounterStyled,
  TabStyled,
  TabTitleStyled,
  TabWrapperStyled,
} from './tabItem.styled';

const TabChildren = ({
  isActive,
  hasCounter,
  count,
  title,
}: TabChildrenProps): ReactElement => (
  <>
    <TabTitleStyled isActive={isActive || false}>{title}</TabTitleStyled>
    {hasCounter && (
      <TabCounterStyled isActive={isActive || false}>{count}</TabCounterStyled>
    )}
  </>
);

export const TabItemContainer = ({
  title,
  count,
  href,
  handleClick,
  active,
  activeLineGap,
  pushAnalytics,
}: TabItemContainerProps): ReactElement => {
  const { asPath } = useRouter();
  const isActive = asPath === href || active || false;
  const hasCounter = typeof count === 'number';

  const onTabItemClick = (): void => {
    if (handleClick && typeof handleClick === 'function') handleClick();
    if (pushAnalytics && typeof pushAnalytics === 'function') pushAnalytics();
  };

  return !!href ? (
    <TabStyled
      onClick={pushAnalytics}
      isActive={isActive}
      activeLineGap={activeLineGap}
    >
      <Link shallow={true} href={href}>
        <TabWrapperStyled>
          <TabChildren
            isActive={isActive}
            hasCounter={hasCounter}
            count={count}
            title={title}
          />
        </TabWrapperStyled>
      </Link>
    </TabStyled>
  ) : (
    <TabStyled
      isActive={isActive}
      onClick={onTabItemClick}
      activeLineGap={activeLineGap}
    >
      <TabWrapperStyled>
        <TabChildren
          isActive={isActive}
          hasCounter={hasCounter}
          count={count}
          title={title}
        />
      </TabWrapperStyled>
    </TabStyled>
  );
};
