import { ReactElement } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

export enum DropdownPosition {
  left = 'left',
  right = 'right',
}

export type DropdownContainerProps = {
  children: ReactElement | ReactElement[];
  handleOutsideClick: () => void;
  position?: DropdownPosition;
  top?: number;
  extraStyles?: FlattenSimpleInterpolation;
};

export type DropdownContainerStyledProps = {
  position: DropdownPosition;
  top?: number;
  extraStyles?: FlattenSimpleInterpolation;
};
