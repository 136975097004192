import styled, { css } from 'styled-components';
import { breakpoint, customizeScrollBar } from '@lerna-core';
import { rgba } from 'polished';
import { ContentStyledProps } from './centeredPopup.model';
import { DEFAULT_WIDTH } from './centeredPopup.constants';

export const CenteredPopupStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_CONTENT};
  padding: 20px 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0 20px 20px;
      background-color: #ebebeb;
      justify-content: flex-start;
    `
  )}
`;

export const OverlayStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.48)};

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `
  )}
`;

export const ContentStyled = styled.div<ContentStyledProps>`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  max-width: ${({ styles }): string =>
    styles?.width ? `${styles.width}px` : `${DEFAULT_WIDTH}px`};
  ${({ styles }): string =>
    styles?.borderRadius ? `border-radius: ${styles.borderRadius}px;` : ''}
  ${({ styles }): string =>
    styles?.backgroundColor
      ? `background-color: ${styles.backgroundColor};`
      : ''}
  ${({ styles }): ReturnType<typeof css> =>
    customizeScrollBar(
      styles?.scrollbar?.marginTop,
      styles?.scrollbar?.marginBottom
    )}
`;
