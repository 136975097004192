import { ReactElement } from 'react';
import { FooterContainerStyled, FooterContentStyled } from './footer.styled';
import { FooterTopContainer } from './footerTop';
import { FooterBottomContainer } from './footerBottom';
import { FooterContainerProps } from './footer.model';

export const FooterContainer = ({
  translations = {},
  footerImages = [],
  socialLinks = [],
  fastLinks,
  homePageLink = '/',
  staticLinks,
}: FooterContainerProps): ReactElement => (
  <FooterContainerStyled>
    <FooterContentStyled>
      <FooterTopContainer
        translations={translations}
        fastLinks={fastLinks}
        staticLinks={staticLinks}
        socialLinks={socialLinks}
      />
      <FooterBottomContainer
        translations={translations}
        footerImages={footerImages}
        homePageLink={homePageLink}
      />
    </FooterContentStyled>
  </FooterContainerStyled>
);
