import { RequestHeader } from '../../services';
import { CustomerClient } from './customer.client';
import { CustomerModel } from './customer.model';

export async function customerClientProvider(
  customerUUID: string,
  requestHeaders?: RequestHeader
): Promise<CustomerModel> {
  const response = await new CustomerClient(customerUUID, requestHeaders).get();

  return response as unknown as Promise<CustomerModel>;
}
