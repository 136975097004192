import { useEffect } from 'react';

import { useUser } from '../features';
import { getPageEventData, pushDataLayer } from '../services';
import { objectService } from '../services';
import { EventDataModel } from '../models';

export const useViewPageDataLayer = (
  pageType: string,
  eventData?: EventDataModel
): void => {
  const { user } = useUser();
  const userUuid = user?.personal?.uuid;

  useEffect(() => {
    if (eventData || user) {
      const eventPushData = {
        ...eventData,
        user_id: userUuid,
        page_type: pageType,
      };

      pushDataLayer(objectService.clearObject(eventPushData));
    } else {
      pushDataLayer(getPageEventData(pageType, userUuid));
    }
  }, []);
};
