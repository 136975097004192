import React, { ReactElement } from 'react';
import { SimilarItemEmptyContainerStyled } from '@features/course/courseSimilar/similarItemEmpty/similarItemEmpty.styled';
import { LoaderContainer } from '@lerna-core';

export const SimilarItemEmptyContainer = (): ReactElement => {
  return (
    <SimilarItemEmptyContainerStyled>
      <LoaderContainer position={'static'} />
    </SimilarItemEmptyContainerStyled>
  );
};
