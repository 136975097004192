import { rgba } from 'polished';
import {
  customizeScrollBar,
  fontSettings,
  getInputColor,
  transition,
} from '../../../styles';
import { css } from 'styled-components';
import { PhoneInputStyledProps } from './phoneInput.model';

export const getDefaultPhoneInputStyles = ({
  theme,
  disabled,
  color,
  bordered,
  height,
  borderRadius,
  hasError,
}: PhoneInputStyledProps): ReturnType<typeof css> => css`
  .react-tel-input {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: ${borderRadius}px;
    box-sizing: border-box;
    position: relative;
    font-family: inherit;
    color: ${theme.COLOR.TEXT_DARK_COLOR};

    ${fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    ${getInputColor({ bordered, disabled, color, theme, hasError })}
    ${disabled ? 'pointer-events: none;' : ''}
  }

  .react-tel-input .form-control {
    order: 2;
    width: 100%;
    height: ${height}px;
    transition: unset;
    font-weight: 500;
    border: none;
    border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
    padding: 0 20px 0 0;
    background: transparent;
    color: inherit;
    font-size: inherit;

    &:hover {
      border: none;
    }
    &:focus {
      border: none;
      box-shadow: unset;
    }

    &::placeholder {
      color: ${theme.COLOR.TEXT_LIGHT_COLOR};
    }
  }

  .react-tel-input .flag-dropdown {
    position: static;
    order: 1;
  }

  .react-tel-input .selected-flag {
    padding: 0 0 0 20px;
    width: 70px;
    height: ${height}px;
    border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
    margin-right: 10px;

    .arrow {
      border: none;
      width: 16px;
      height: 16px;
      transform: translateY(-40%);
      margin: 1px 0 0;
      left: calc(100% + 10px);
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%2378747A' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        center / cover no-repeat;
      ${transition('transform')}

      &.up {
        transform: translateY(-40%) rotate(180deg);
      }
    }
  }

  .react-tel-input .country-list {
    top: calc(100% + 8px);
    width: 100%;
    border-radius: ${borderRadius}px;
    box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
    margin: 0;
    padding: 10px 0;
    border: 1px solid ${rgba(theme.COLOR.BLACK, 0.12)};
    max-height: 230px;
    ${customizeScrollBar(10, 10, 4)}

    .divider {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid ${rgba(theme.COLOR.BLACK, 0.12)};
    }
  }

  .react-tel-input .country-list .country {
    position: relative;
    padding: 10px 45px 10px 55px;
    ${transition('background-color')}
    ${fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    color: ${theme.COLOR.TEXT_DARK_COLOR};
    font-weight: 500;

    &:hover {
      background-color: ${rgba(theme.COLOR.BLACK, 0.04)};
    }

    .flag {
      display: inline-block;
      position: absolute;
      margin: 1px 0 0;
      top: 8px;
      left: 20px;
    }

    .country-name {
      margin: 0;
    }

    .dial-code {
      margin-left: 8px;
      color: inherit;

      &::before {
        content: '(';
      }
      &::after {
        content: ')';
      }
    }

    &.highlight {
      background-color: inherit;

      &::before {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8L6 12L14 4' stroke='%23201926' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          center / cover no-repeat;
        width: 16px;
        height: 16px;
      }
    }
  }
`;
