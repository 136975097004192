import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { filterRouterService, pushDataLayer, urlService } from '@lerna-core';
import { pageUrls } from '@constants';
import {
  ItemHeaderSphereBottomRectangleStyled,
  ItemHeaderSphereLinkStyled,
  ItemHeaderSphereStyled,
  ItemHeaderSphereTopRectangleStyled,
  ItemHeaderSphereValueStyled,
} from './itemHeaderSphere.styled';
import { ItemHeaderSphereModel } from './itemHeaderSphere.model';
import { FilterEvent, getTabFilterEventData } from '@features/common/filters';

export const ItemHeaderSphereContainer = ({
  sphere,
  withSphereFilter = true,
  sphereColor,
}: ItemHeaderSphereModel): ReactElement => {
  const router = useRouter();

  const hasFilterQuery = !!router.query[sphere.filterSlug];
  const isDisabled = !withSphereFilter || hasFilterQuery;

  const handleClick = (): void => {
    const defaultPath = router.query?.fastlink
      ? pageUrls.courses
      : router.pathname;
    const newQuery = filterRouterService.getNewRouteTab(
      router,
      sphere.filterSlug,
      sphere.slug
    );

    pushDataLayer(
      getTabFilterEventData(
        FilterEvent.constructorFiltersCardCourse,
        sphere.filterSlug,
        sphere.slug
      )
    );

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery)
    );
  };

  return (
    <ItemHeaderSphereStyled isDisabled={isDisabled}>
      <ItemHeaderSphereLinkStyled
        onClick={handleClick}
        sphereColor={sphereColor}
      >
        <ItemHeaderSphereTopRectangleStyled sphereColor={sphereColor} />
        <ItemHeaderSphereValueStyled>
          {sphere.value}
        </ItemHeaderSphereValueStyled>
        <ItemHeaderSphereBottomRectangleStyled />
      </ItemHeaderSphereLinkStyled>
    </ItemHeaderSphereStyled>
  );
};
