import styled, { css } from 'styled-components';
import {
  breakpoint,
  fontSettings,
  lineClamping,
  transition,
} from '@lerna-core';
import { RowItemStyledProps } from './rowItem.model';
import { rgba } from 'polished';

export const ROW_ITEM_BORDER_RADIUS = 16;

export const RowItemStyled = styled.div<RowItemStyledProps>`
  border-radius: ${ROW_ITEM_BORDER_RADIUS}px;
  box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.04);
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: 16px;
  width: 185px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border: 2px solid transparent;

  ${transition('border')}
  ${({ isActive, theme, itemColor }): string =>
    isActive
      ? `
    border-color: ${itemColor ? itemColor : theme.COLOR.PURPLE_SUBSCRIPTION};
    pointer-events: none;
  `
      : ''}
      
  &:hover {
    border-color: ${({ isActive, theme, itemColor }): string =>
      isActive
        ? itemColor
          ? itemColor
          : theme.COLOR.PURPLE_SUBSCRIPTION
        : rgba(theme.COLOR.BLACK, 0.08)};
  }

  ${breakpoint(
    'largeDown',
    css`
      width: 210px;
      height: 112px;
    `
  )}
`;

export const TitleStyled = styled.div`
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  ${lineClamping(2)}
`;

export const ImageStyled = styled.div`
  width: 40px;
  height: 40px;

  svg,
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  ${breakpoint(
    'largeDown',
    css`
      width: 32px;
      height: 32px;
    `
  )}
`;
