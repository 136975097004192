import React, { memo, ReactElement } from 'react';
import { SocialItemContainerProps } from './socialItem.model';
import { SocialItemContainerStyled } from './socialItem.styled';
import { ImageContainer } from '../../../image';
import { urlService } from '../../../../services/urlService';

function socialItemContainer({
  social,
}: SocialItemContainerProps): ReactElement {
  return (
    <SocialItemContainerStyled
      href={social.url}
      {...urlService.setTargetBlankOptions()}
    >
      <ImageContainer
        imageResource={social.image.imageResource}
        preset={'footerSocialImage'}
        alt={social.title}
        title={social.title}
      />
    </SocialItemContainerStyled>
  );
}

export const SocialItemContainer = memo(socialItemContainer);
