import styled from 'styled-components';
import { defaultGradient, fontSettings } from '../../styles/mixins';
import {
  BonusPointsContainerStyled,
  BonusPointsCountStyled,
} from '../bonusPoints';
import Link from 'next/link';

const defaultPadding = '16px';
const defaultPaddingWithLink = '48px';
const mobilePaddingHorizontal = '12px';
const mobilePaddingVertical = '16px';

type PointsBoxContainerStyledProps = {
  hasLink: boolean;
};
export const PointsBoxContainerStyled = styled.div<PointsBoxContainerStyledProps>`
  color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: ${({ theme, hasLink }): string =>
    theme.IS_MOBILE
      ? `${mobilePaddingHorizontal} ${
          hasLink ? defaultPaddingWithLink : mobilePaddingVertical
        } ${mobilePaddingHorizontal} ${mobilePaddingVertical}`
      : `${defaultPadding} ${defaultPaddingWithLink} ${defaultPadding} ${defaultPadding}`};
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  ${({ theme }): string => defaultGradient(theme.COLOR)}
  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
    display: flex;
    align-items: center;`
      : ''}
    ${BonusPointsContainerStyled} {
    display: inline-block;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    font-weight: 400;
    white-space: nowrap;
    ${({ theme }): string =>
      theme.IS_MOBILE
        ? `
      display: flex;
      align-items: baseline;
      text-transform: lowercase;
      `
        : ''}
  }

  ${BonusPointsCountStyled} {
    ${({ theme }): string =>
      fontSettings(
        theme.IS_MOBILE ? theme.FONT.SIZE_20 : theme.FONT.SIZE_28,
        theme.FONT
      )}
    font-weight: 700;
    margin: ${({ theme }): string =>
      theme.IS_MOBILE ? '0 5px 0 0' : '0 0 14px 0'};
    display: block;
  }
`;

export const PointsLinkStyled = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  display: flex;
  padding: ${defaultPadding};
  align-items: flex-end;
  justify-content: flex-end;

  svg {
    transform: rotate(-90deg);
    position: relative;
    top: ${({ theme }): string => (theme.IS_MOBILE ? '2px' : '-1px')};
  }
`;
