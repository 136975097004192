import { mapPromoCodeRequestParams } from './promoCode.helper';
import {
  PromoCodeApiResponseModel,
  PromoCodeModel,
  PromoCodeRequestApiParams,
  PromoCodeRequestParams,
} from './promoCode.model';
import { promoCodeResponseAdapter } from './promoCode.adapter';
import { RequestClient } from '../../services/request';
import { publicConfig } from '../../services/config/config.model';

export class PromoCodeClient extends RequestClient<
  PromoCodeRequestApiParams,
  PromoCodeApiResponseModel,
  PromoCodeModel
> {
  protected requestParameters: PromoCodeRequestApiParams;
  protected adapter = promoCodeResponseAdapter;
  protected path = 'course-api/v1/promo-code';

  constructor(params: PromoCodeRequestParams) {
    super(publicConfig.API_GATEWAY_URL);

    this.requestParameters = mapPromoCodeRequestParams(params);
  }
}
