import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const ContentStatusStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  text-align: center;
`;

export const IconStyled = styled.div`
  margin-bottom: 16px;
`;

export const TitleStyled = styled.div`
  font-weight: 600;
  margin-bottom: 16px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
`;

export const SubtitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: #5c5c5c;
`;

export const ButtonStyled = styled.div`
  margin-top: 16px;

  button {
    padding: 14px 24px;
  }
`;
