import styled from 'styled-components';
import { CourseLogoContainerStyledProps } from './courseLogo.model';

export const CourseLogoContainerStyled = styled.div<CourseLogoContainerStyledProps>`
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
  display: flex;
  flex: 0 0 ${({ width }): number => width}px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;
