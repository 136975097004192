import styled, { css } from 'styled-components';

import { fontSettings, getInputColor } from '../../../../styles';
import { InputStyledProps } from './input.model';
import { FloatingPlaceholderStyled } from '../../floatingPlaceholder';

export const setBaseInputStyles = (
  floatingPlaceholder?: boolean
): ReturnType<typeof css> => css`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  padding: 15px 45px 15px 19px;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 100%;
  margin: 0;
  font-weight: 500;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out;
  cursor: text;
  text-overflow: ellipsis;

  ::placeholder {
    color: ${({ theme }): string =>
      floatingPlaceholder ? 'transparent' : theme.COLOR.TEXT_LIGHT_COLOR};
  }

  ::-webkit-datetime-edit {
    flex: 0 0 auto;
  }

  ${floatingPlaceholder &&
  `
    &:-webkit-autofill ~ ${FloatingPlaceholderStyled},
    &:focus ~ ${FloatingPlaceholderStyled},
    &:not(:placeholder-shown) ~ ${FloatingPlaceholderStyled} {
      top: 0;
      font-size: 13px;
    }
  `}
`;

export const InputContainerStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

type IconButtonStyledProps = {
  transparent?: boolean;
};

export const IconButtonStyled = styled.button<IconButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${({ transparent, theme }): string =>
    transparent ? 'transparent' : theme.COLOR.TEXT_LIGHT_COLOR};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  width: 16px;
  max-width: 16px;
  height: 16px;
  padding: 0;
  opacity: 0.64;
  transition: opacity 0.1s ease-in-out;

  :hover {
    opacity: 1;
  }
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${({ floatingPlaceholder }): ReturnType<typeof css> =>
    setBaseInputStyles(floatingPlaceholder)}
  ${({ bordered, disabled, color, theme, hasError }): string =>
    getInputColor({ bordered, disabled, color, theme, hasError })}
`;
