import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  FormControlSuccess,
  TextControlComponent,
} from '@lerna-core';
import { PromoCodeControlledInputModel } from './promoCodeControlledInput.model';
import * as S from './promoCodeControlledInput.styled';
import { usePromoCodeControlledInput } from './promoCodeControlledInput.hook';

export const PromoCodeControlledInputContainer = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  setFieldValue,
  translations,
  setIsApplyingPromoCode,
}: PromoCodeControlledInputModel): ReactElement => {
  const { handleApply, handleCancel, promoCodeIsValid } =
    usePromoCodeControlledInput(setFieldValue, setIsApplyingPromoCode);

  return (
    <S.PromoCodeControlledInput>
      <S.PromoCodeControlledInputControl>
        <TextControlComponent
          type="text"
          name="promoCodeDraft"
          value={values.promoCodeDraft}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={translations?.promo_code}
          floatingPlaceholder={false}
          disabled={promoCodeIsValid}
          touched={touched.promoCodeDraft}
          errorMessage={errors.promoCodeDraft}
          showResetButton
          setFieldValue={setFieldValue}
          margin="0 0 20px 0"
        />
        {promoCodeIsValid === true && (
          <FormControlSuccess>
            {translations.promo_code_activated || 'Промокод применен'}
          </FormControlSuccess>
        )}
      </S.PromoCodeControlledInputControl>
      {!promoCodeIsValid && (
        <ButtonContainer
          onClick={handleApply.bind(null, values.promoCodeDraft)}
          title={translations.apply_filter_label}
          color={ButtonColor.transparent}
        />
      )}
      {promoCodeIsValid && (
        <ButtonContainer
          onClick={handleCancel}
          title={translations.delete || 'Удалить'}
          color={ButtonColor.transparent}
        />
      )}
    </S.PromoCodeControlledInput>
  );
};
