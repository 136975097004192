import { AppContext } from 'next/app';
import { urlService } from '../../services/urlService';

export const trailingSlash = (context: AppContext): void => {
  const initialAsPathString = decodeURIComponent(context.router.asPath);

  if (initialAsPathString) {
    const asPathStringSplitBySearch = initialAsPathString.split('?');
    const search = asPathStringSplitBySearch[1] || '';
    const path = asPathStringSplitBySearch[0];
    const newUrlString = urlService.normalizeUrl(initialAsPathString);
    const needToRedirect =
      newUrlString !==
      `${path}${
        !!search ? `?${urlService.normalizeSearch(search, false)}` : ''
      }`;

    if (needToRedirect) {
      context?.ctx?.res?.writeHead(301, {
        Location: newUrlString,
      });
      context?.ctx?.res?.end();
    }
  }
};
