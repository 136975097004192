import { CoursesTransferState, CoursesFilters } from '../coursesTransfer.model';
import { TranslationModel } from '../../../../models';
import { TableEmptyContent } from '../../../table';

export const getUnallocatedEmptyContent = (
  translations: TranslationModel,
  coursesUnallocatedFilters: CoursesFilters,
  courses: CoursesTransferState
): TableEmptyContent => {
  switch (true) {
    case !!(
      coursesUnallocatedFilters.sphere &&
      coursesUnallocatedFilters.sphere.length
    ) && !courses.unallocated.data.length:
      return {
        title: translations.filters_is_empty,
        text: null,
      };
    case courses.unallocated.data.length === 0 &&
      courses.allocated.data.length !== 0:
      return {
        title: translations.list_is_empty,
        text: translations.you_added_all_courses,
      };
    default:
      return {
        title: translations.list_is_empty,
        text: null,
      };
  }
};
