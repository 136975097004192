import { logger } from '../logger/logger.service';
import { AppService } from '../appService/app.service';
import { Nullable } from '../../models';

class Storage {
  private static instance: Storage;

  public setValue<T>(key: string, value: T): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logger.error(`[ERROR]: can not set value of ${key} to session storage`);
    }
  }

  public getValue<T>(key: string): Nullable<T> {
    if (!AppService.isClientSide) {
      return null;
    }

    try {
      const value = sessionStorage.getItem(key);
      if (value) {
        try {
          return JSON.parse(value) as T;
        } catch (error) {
          logger.error(
            `[ERROR]: can not get value of ${key} from session storage`
          );

          return null;
        }
      }
    } catch (error) {
      logger.error('[ERROR]: can not read session');
    }

    return null;
  }

  public deleteValue(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      logger.error(
        `[ERROR]: can not delete value of ${key} from session storage`
      );
    }
  }

  public static getInstance(): Storage {
    if (!Storage.instance) {
      Storage.instance = new Storage();
    }

    return Storage.instance;
  }
}

export const sessionStorageService = Storage.getInstance();
