import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { MobileRangeTypeContainerProps } from './mobileRangeType.model';
import {
  FilterItemTypeTitleStyled,
  FilterItemTypeValueStyled,
  MobileFilterTypeContainerStyled,
} from '../filtersPopupMobile.styled';
import { checkedFilterRangeSelector } from '@lerna-core';

export const MobileRangeTypeContainer = ({
  filter,
}: MobileRangeTypeContainerProps): ReactElement => {
  const hasSelectedRangeValue = useSelector(
    checkedFilterRangeSelector(filter.slug)
  );

  return (
    <MobileFilterTypeContainerStyled>
      <FilterItemTypeTitleStyled hasSelected={hasSelectedRangeValue}>
        {filter.title}
      </FilterItemTypeTitleStyled>
      {hasSelectedRangeValue && (
        <FilterItemTypeValueStyled>
          {filter.showTitle}
        </FilterItemTypeValueStyled>
      )}
    </MobileFilterTypeContainerStyled>
  );
};
