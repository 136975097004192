import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import { CompensationFormContainerProps } from './compensationForm.model';
import { FormContentContainer } from './formContent';
import {
  CompensationFormContextProvider,
  useCompensationFormContext,
} from './compensationForm.context';
import { FormCommentPopupContainer } from './formCommentPopup';

const FormContent = (): ReactElement => {
  const { currentPopup, handlePopupClose } = useCompensationFormContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer />
      <FormCommentPopupContainer />
    </CenteredPopupContainer>
  );
};

export const CompensationFormContainer = (
  props: CompensationFormContainerProps
): Nullable<ReactElement> => (
  <CompensationFormContextProvider props={props}>
    <FormContent />
  </CompensationFormContextProvider>
);
