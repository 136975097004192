import { AccordionModel, CourseFaqsModel } from '@lerna-core';

/**
 * Map CourseQuestionModel for AccordionModel
 * @return (Object)
 * @params - questions
 * */
export const mapCourseFaqsForAccordion = (
  faqs: CourseFaqsModel[]
): AccordionModel[] =>
  faqs.map((faq) => ({
    title: faq.question,
    content: faq.answer,
  }));
