import { RegionsEnum, TranslationModel } from '../models';
import { publicConfig } from '../services';
import BY from './BY.json';
import KZ from './KZ.json';
import UZ from './UZ.json';
import KG from './KG.json';
import ME from './ME.json';
import RU from './RU.json';
import MD from './MD.json';
import AM from './AM.json';
import TJ from './TJ.json';
import AZ from './AZ.json';
import baseTranslations from './common.json';

export const getTranslationsByRegion = (): TranslationModel => {
  switch (publicConfig?.REGION) {
    case RegionsEnum.kz:
      return KZ;
    case RegionsEnum.uz:
      return UZ;
    case RegionsEnum.kg:
      return KG;
    case RegionsEnum.md:
      return MD;
    case RegionsEnum.am:
      return AM;
    case RegionsEnum.me:
      return ME;
    case RegionsEnum.tj:
      return TJ;
    case RegionsEnum.ru:
      return RU;
    case RegionsEnum.az:
      return AZ;
    case RegionsEnum.by:
    default:
      return BY;
  }
};

export const getTranslations = (): TranslationModel => ({
  ...baseTranslations,
  ...getTranslationsByRegion(),
});
