export enum CompanyUuidEnum {
  mincifra = 'dc66b1de-ac3f-41eb-9014-208999c3d6a7',
  wildberries = '8b7e83d0-a6ef-4bad-94b6-57b23b68b8a5',
  lerna = '259008e8-7900-4bc2-8581-b75068d61c17',
  cdek = 'eb1ce669-52d4-4c54-b005-807d05352f68',
  ifcm = '997c47ec-8692-48f2-bddb-7e38c61d6e09',
  severstal = '0e1c147e-12fa-42d5-a5bd-0d0880203211',
  uzumMarket = '07763816-b6cd-401e-a12a-ad3bf8842f14',
  tochkaBank = '6f705193-ee8b-4530-99df-3927cad8119d',
  alfaBankSpecCourses = 'd6043e2a-9208-48bd-b2b0-0837291b8fd9',
  mbm = '8483c5ce-947f-4a3f-83c4-4a4683b5e99a',
}
