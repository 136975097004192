import { createSelector } from 'reselect';
import { UserMenuState } from './userMenu.model';
import { RootState } from '../../../models';

export const getUserMenuSelector = createSelector(
  (state: RootState<UserMenuState>) => state.userMenu,
  (reducer: UserMenuState) => reducer.menu
);

export const getUserMenuStatusSelector = createSelector(
  (state: RootState<UserMenuState>) => state.userMenu,
  (reducer: UserMenuState) => reducer.status
);
