export enum IconDirection {
  left = 'left',
  right = 'right',
}

export type TransferControlStyledProps = {
  iconDirection: IconDirection;
  disabled?: boolean;
};

export type TransferControlProps = TransferControlStyledProps & {
  handleClick: () => void;
};
