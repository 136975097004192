import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const FiltersPopupContainerStyled = styled.div`
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
`;

export const FilterPopupTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
  padding-bottom: 16px;
  text-align: center;
  font-weight: 700;

  ${breakpoint(
    'mediumDown',
    css`
      text-align: left;
      border-bottom: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
      margin: 0 -20px;
      padding: 8px 20px 16px;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
    `
  )}
`;
