import styled from 'styled-components';
import { fontSettings } from '../../../styles';

type CheckboxContainerStyledProps = {
  color: string;
};

export const CheckboxContainerStyled = styled.label<CheckboxContainerStyledProps>`
  min-height: 24px;
  display: inline-flex;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  gap: 12px;
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  line-height: 24px;
  cursor: pointer;
  color: ${(props): string => props.color};
  touch-action: manipulation;
  outline: none;

  & > input {
    display: none;
  }

  & > svg {
    margin-top: 1px;
  }
`;

export const CheckedStyled = styled.div`
  height: 24px;

  path {
    stroke: ${(props) => props.theme.COLOR.WHITE};
  }
`;
