import { useRouter } from 'next/router';
import { UseDiscountProgramModel } from './discountProgram.model';
import { QueryParams } from '@lerna-core';

export const useDiscountProgram = (): UseDiscountProgramModel => {
  const router = useRouter();

  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;
  const promoCodeQuery = router.query[QueryParams.promoCode] as string;

  return {
    discountProgramQuery,
    promoCodeQuery,
    isDiscountProgramPage: Boolean(discountProgramQuery),
  };
};
