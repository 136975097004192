import { UserHookModel } from './user.model';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserStatus } from './user.selectors';
import { useCallback } from 'react';
import { reloadUser as requestReloadUser } from './user.actions';

export const useUser = (): UserHookModel => {
  const user = useSelector(getUser);
  const status = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const reloadUser = useCallback((): void => {
    requestReloadUser(dispatch);
  }, []);

  return {
    user,
    status,
    reloadUser,
  };
};
