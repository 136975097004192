import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCalendarComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 8h-17M6 21h12a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.5 5V2.75M16.5 5V2.75"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 17.5h-1.5m1.5-3h-1.5m1.5-3h-1.5m6 6h-1.5m1.5-3h-1.5m1.5-3h-1.5m6 6h-1.5m1.5-3h-1.5m1.5-3h-1.5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
