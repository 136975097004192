import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconVisaComponent = ({
  width = 32,
  height = 20,
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path
        d="m12.248 5.172-4.055 9.67H5.557L3.566 7.135c-.115-.473-.23-.645-.587-.846C2.362 5.96 1.374 5.645.5 5.46l.057-.287h4.255c.545 0 1.032.358 1.146.988l1.06 5.588 2.608-6.576 2.622-.001Zm10.358 6.519c.014-2.55-3.524-2.694-3.51-3.826.014-.344.344-.716 1.06-.816.358-.043 1.347-.086 2.464.43l.444-2.05C22.463 5.216 21.69 5 20.73 5c-2.478 0-4.212 1.318-4.226 3.195-.014 1.39 1.246 2.163 2.192 2.636.974.473 1.304.774 1.304 1.203-.015.645-.774.932-1.49.946-1.261.014-1.992-.344-2.565-.616l-.458 2.12c.587.272 1.662.502 2.779.516 2.622 0 4.327-1.304 4.341-3.31l-.001.001Zm6.533 3.151h2.307l-2.02-9.67H27.29c-.487 0-.888.272-1.06.702l-3.754 8.954H25.1l.516-1.447h3.209l.314 1.461Zm-2.794-3.424 1.318-3.624.76 3.624h-2.078Zm-10.53-6.246-2.063 9.67H11.26l2.063-9.67h2.493-.001Z"
        fill="#2660AC"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h31v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
