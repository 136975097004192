import { AxiosError } from 'axios';

export enum ToastType {
  warning = 'warning',
  success = 'success',
  error = 'error',
}

export interface ToastContainerProps {
  message: string;
  type: ToastType | null;
  visible: boolean;
  autoHideDuration?: number;
  onHide?: () => void;
  hideCallback?: () => void;
  top?: number;
  right?: number;
  width?: number;
}

export type ToastModel = {
  message: string;
  type: ToastType | null;
  visible: boolean;
};

export type ToastState = ToastModel;

export type ToastActions = {
  type: string;
  payload?: ToastModel | AxiosError;
};

export type ToastStyledProps = Required<
  Pick<ToastContainerProps, 'visible' | 'top' | 'right' | 'width'>
>;
