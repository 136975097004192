import React, { ReactElement } from 'react';
import {
  CourseLogoLabelInnerStyled,
  CourseLogoLabelStyled,
} from './courseLogoLabel.styled';
import { CourseLogoLabelModel } from './courseLogoLabel.model';

export const CourseLogoLabelContainer = ({
  title,
  color,
  background,
}: CourseLogoLabelModel): ReactElement => (
  <CourseLogoLabelStyled color={color}>
    <CourseLogoLabelInnerStyled background={background} color={color}>
      {title}
    </CourseLogoLabelInnerStyled>
  </CourseLogoLabelStyled>
);
