import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCoinComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
  fillColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <circle
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      cx="12"
      cy="12"
      r="9"
    />
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 14.5V20c0 1.1046.89543 2 2 2h16c1.1046 0 2-.8954 2-2v-5.5"
    />
    <path
      fill={fillColor}
      d="M10.459 9.9411c0-.594.457-.9662 1.0772-1.038v2.0957c-.0652-.0131-.124-.0327-.1828-.0523-.5941-.1958-.8944-.5353-.8944-1.0054zm3.2643 4.0739c0 .6398-.4896 1.0642-1.1816 1.1229v-2.2524c.0457.0131.0914.0261.1305.0327.7312.2024 1.0511.5614 1.0511 1.0968zm-1.1686 3.3622l-.0065-.8291c1.7888-.1698 2.9248-1.1556 2.9248-2.7094 0-1.5799-1.0642-2.2458-2.3764-2.5266l-.5549-.1175V8.9161c.692.111 1.0772.6333 1.0968 1.1556h1.6713c-.0261-1.358-1.0903-2.3895-2.742-2.5657v-.8488h-1.0577v.8422c-1.5799.1437-2.8203 1.012-2.8203 2.5788 0 1.482 1.0707 2.2393 2.285 2.507l.5614.1241v2.4286c-.8356-.111-1.273-.6137-1.3057-1.2143H8.52c.013 1.2078.8226 2.4809 2.9836 2.6375l-.0065.8161h1.0576z"
    />
  </svg>
);
