import React, { ReactElement } from 'react';
import { LinksParentContainerProps } from './linksParent.model';
import {
  LinksParentArrowStyled,
  LinksParentHeadStyled,
  LinksParentStyled,
  LinksParentTitleStyled,
  LinksParentWrapperStyled,
} from './linksParent.styled';
import { DesktopLinkContainer } from '../../desktopLink';
import { useTheme, useToggle } from '../../../../../../hooks';
import { IconArrowComponent } from '../../../../../icons';

export const LinksParentContainer = ({
  parent,
  openedDefault,
  withHead,
  handleClose,
  projectPageName,
}: LinksParentContainerProps): ReactElement => {
  const [isOpened, toggleIsOpened] = useToggle(!withHead || openedDefault);
  const theme = useTheme();

  const parentHead = withHead && (
    <LinksParentHeadStyled isOpened={isOpened} onClick={toggleIsOpened}>
      <LinksParentTitleStyled>{parent.title}</LinksParentTitleStyled>
      <LinksParentArrowStyled isOpened={isOpened}>
        <IconArrowComponent
          width={24}
          height={24}
          strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
        />
      </LinksParentArrowStyled>
    </LinksParentHeadStyled>
  );
  const parentLinks = isOpened && (
    <LinksParentWrapperStyled>
      {parent.children.map((children) => (
        <DesktopLinkContainer
          key={children.title}
          {...children}
          parentType={parent.type}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </LinksParentWrapperStyled>
  );

  return (
    <LinksParentStyled>
      {parentHead}
      {parentLinks}
    </LinksParentStyled>
  );
};
