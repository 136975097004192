import React, { ReactElement } from 'react';
import { FilterTitleContainerProps } from './filterTitle.model';
import { FilterTitleContainerStyled } from './filterTitle.styled';
import {
  TooltipDesktopContainer,
  TooltipDesktopPositions,
} from '../filterTooltip/tooltipDesktop';

export const FilterTitleContainer = ({
  title,
  withTooltip,
  tooltip,
  icon,
}: FilterTitleContainerProps): ReactElement => {
  const hasTooltip = withTooltip && !!tooltip;

  return (
    <FilterTitleContainerStyled>
      {icon && icon}
      {title}
      {hasTooltip && (
        <TooltipDesktopContainer
          content={tooltip}
          defaultPosition={TooltipDesktopPositions.bottom}
        />
      )}
    </FilterTitleContainerStyled>
  );
};
