import { publicConfig, serverConfig } from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';
import { userPositionsAdapter } from './userPosition.adapter';
import {
  UserPositionsApiModel,
  UserPositionWithOptions,
} from './userPosition.model';

export class UserPositionsClient extends SecureRequestClient<
  null,
  UserPositionsApiModel,
  UserPositionWithOptions[]
> {
  protected path = 'user-api/v1/positions';
  protected requestParameters: null;
  protected adapter = userPositionsAdapter;

  constructor(
    host = publicConfig.API_GATEWAY_URL,
    privateHost = serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
  ) {
    super(host, privateHost);
    this.requestParameters = null;
  }
}

export class UserPositionsByCompanyClient extends UserPositionsClient {
  constructor(companyId: string, host?: string) {
    super(host);
    this.requestParameters = null;
    this.path = `user-api/v1/companies/${companyId}/positions`;
  }
}
