import { fontSettings } from '@lerna-core';
import styled from 'styled-components';

export const FormPopupSubTitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  margin-bottom: 32px;
  text-align: center;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  white-space: pre-wrap;
`;
