import React, { ReactElement, useEffect, useState } from 'react';
import {
  cookieService,
  dateService,
  staticSrc,
  urlService,
} from '../../../services';
import { ButtonColor, ButtonContainer, ButtonSize } from '../../button';
import {
  HIDE_COOKIES_NOTIFICATION_COOKIE_NAME,
  NotificationAgreementStatus,
} from './notificationAgreement.constant';
import * as S from './notificationAgreement.styled';
import { useTranslations } from '../../translations';
import { Nullable } from '../../../models';
import { isNotificationVisible } from './notificationAgreement.helper';

export const NotificationAgreement = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClose = (): void => {
    cookieService.setCookie({
      name: HIDE_COOKIES_NOTIFICATION_COOKIE_NAME,
      val: NotificationAgreementStatus.hide,
      days: dateService.yearInDays,
    });
    setIsVisible(false);
  };

  useEffect(() => {
    if (isNotificationVisible(translations)) {
      setIsVisible(true);
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <S.NotificationAgreement>
      <S.NotificationAgreementText>
        {translations.cookie_notification_text}{' '}
        <S.NotificationAgreementLink>
          <a
            {...urlService.setTargetBlankOptions()}
            href={staticSrc.cookiePolicyPdf}
          >
            {translations.cookie_notification_link}
          </a>
        </S.NotificationAgreementLink>
      </S.NotificationAgreementText>
      <S.NotificationAgreementButton>
        <ButtonContainer
          title={translations.cookie_notification_button || ''}
          size={ButtonSize.small}
          color={ButtonColor.transparent}
          onClick={handleClose}
        />
      </S.NotificationAgreementButton>
    </S.NotificationAgreement>
  );
};
