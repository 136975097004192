import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const defaultCoursePageSpacing = '24px';
export const mobileCoursePageSpacing = '16px';

export const CoursePaperStyled = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 32px 40px;

  &:not(:last-child) {
    margin-bottom: ${defaultCoursePageSpacing};
  }

  ${breakpoint(
    'xlargeDown',
    css`
      padding: 24px;

      &:not(:last-child) {
        margin-bottom: ${mobileCoursePageSpacing};
      }
    `
  )}
`;
