import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '@lerna-core';

export const LeftCourseInfoStyled = styled.div`
  margin-top: auto;
`;

export const CourseTitleStyled = styled.div`
  font-weight: 600;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)};
  ${lineClamping(3)}
`;

export const WrapperStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};
  margin-top: 16px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 12px;
    `
  )}
`;

export const CourseDurationStyled = styled.div``;

export const CourseStartDate = styled.div`
  margin-top: 4px;
`;
