import React, { ReactElement } from 'react';
import { ToggleDotStyled, ToggleStyled } from './toggle.styled';
import { ToggleContainerProps } from './toggle.model';

export const ToggleContainer = ({
  isActive,
  onClick,
  disabled = false,
  activeColor,
}: ToggleContainerProps): ReactElement => {
  return (
    <ToggleStyled
      isActive={isActive}
      disabled={disabled}
      onClick={onClick}
      activeColor={activeColor}
    >
      <ToggleDotStyled isActive={isActive} />
    </ToggleStyled>
  );
};
