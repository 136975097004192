import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FiltersPopupMobileContainerProps } from './filterPopupMobile.model';
import {
  FilterMobileFirstStepOptions,
  FilterMobileFirstStepStyled,
  FilterPopupMobileContainerStyled,
} from './filtersPopupMobile.styled';
import { FilterPopupTitleStyled } from '../filterPopup.styled';
import { FilterPopupFooterContainer } from '../filterPopupFooter';
import {
  ButtonSize,
  checkedFilterSelectedSelector,
  CloseTypes,
  FilterOptionsSearchModel,
  FiltersModel,
  InputSizes,
  Nullable,
  PopupContainer,
  useTheme,
} from '@lerna-core';
import {
  FiltersResetContainer,
  FilterTypeItem,
} from '@features/common/filters';
import { MobileFilterContainer } from './mobileFilter';

export const FiltersPopupMobileContainer = ({
  filters,
  coursesCount,
  translations,
  defaultPathname,
  parent,
  customFilterColor,
}: FiltersPopupMobileContainerProps): ReactElement => {
  const theme = useTheme();
  const isMobile = true;

  const [activeFilter, setActiveFilter] =
    useState<Nullable<FiltersModel>>(null);

  const hasSelected = useSelector(
    checkedFilterSelectedSelector(
      activeFilter?.slug || '',
      activeFilter?.type || ''
    )
  );

  const searchColors: FilterOptionsSearchModel = useMemo(
    () => ({
      default: theme.COLOR.TEXT_LIGHT_COLOR,
      active: theme.COLOR.TEXT_DARK_COLOR,
    }),
    []
  );

  useEffect(() => {
    if (!!activeFilter) {
      setActiveFilter(
        filters.find((filter) => filter.slug === activeFilter.slug) || null
      );
    }
  }, [filters]);

  const handleClose = (): void => {
    setActiveFilter(null);
  };
  const handleClickFilter = (filter: FiltersModel): void => {
    setActiveFilter(filter);
  };

  const Footer = hasSelected ? (
    <FilterPopupFooterContainer
      buttonSize={ButtonSize.small}
      coursesCount={coursesCount}
      activeFilter={activeFilter}
      handleClose={handleClose}
      translations={translations}
      isMobile={isMobile}
      filters={filters}
      defaultPathname={defaultPathname}
    />
  ) : null;

  return (
    <>
      <FilterMobileFirstStepStyled>
        <FiltersResetContainer
          type="mobile"
          customFilterColor={customFilterColor}
          translations={translations}
        />
        <FilterMobileFirstStepOptions>
          {filters.map(
            (filter: FiltersModel): Nullable<ReactElement> =>
              filter.isVisible ? (
                <MobileFilterContainer
                  key={filter.slug}
                  filter={filter}
                  handleClick={handleClickFilter}
                  customFilterColor={customFilterColor}
                />
              ) : null
          )}
        </FilterMobileFirstStepOptions>
      </FilterMobileFirstStepStyled>
      {!!activeFilter && (
        <PopupContainer
          title={activeFilter.title}
          hasHeader={true}
          hasClose={true}
          isMobile={isMobile}
          handleClose={handleClose}
          closeType={CloseTypes.back}
          isOpened={!!activeFilter}
          footer={Footer}
          parent={parent}
        >
          <FilterPopupTitleStyled>{activeFilter.title}</FilterPopupTitleStyled>
          <FilterPopupMobileContainerStyled>
            <FilterTypeItem
              rangeInputSize={InputSizes.small}
              filter={activeFilter}
              withTitle={false}
              withSearch={true}
              isCollapsed={false}
              translations={translations}
              defaultPathname={defaultPathname}
              searchColors={searchColors}
              analyticsType="fixed"
              filterColor={customFilterColor}
            />
          </FilterPopupMobileContainerStyled>
        </PopupContainer>
      )}
    </>
  );
};
