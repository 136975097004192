import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PricesInstallmentStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    `
  )}
`;

export const TitleStyled = styled.div``;

export const ValueStyled = styled.div`
  text-decoration: line-through;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const ValueWithDiscountStyled = styled.div`
  margin-top: 4px;
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)};

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)};
    `
  )}
`;
