import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { companyAdapter } from './company.adapter';
import { Company, CompanyApiModel } from './company.model';

export class CompanyClient extends SecureRequestClient<
  null,
  CompanyApiModel,
  Company
> {
  // todo will be replaced after keycloack enabled
  protected path = '';
  protected requestParameters: null;
  protected adapter = companyAdapter;

  constructor(companyId: string, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
    this.path = `user-api/v1/companies/${companyId}`;
  }
}
