import styled from 'styled-components';
import { rgba } from 'polished';
import { ToggleButtonGroupStyledModel } from './toggleButtonGroup.model';

export const ToggleButtonGroupStyled = styled.div<ToggleButtonGroupStyledModel>`
  display: flex;
  border-radius: ${({ borderRadius }): string => `${borderRadius || 16}px`};
  background: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
  padding: 4px;
`;
