import React, { ReactElement } from 'react';
import { Nullable } from '@lerna-core';
import { CourseInstallmentContainerProps } from './itemInstallment.model';
import {
  ItemInstallmentContainerStyled,
  TitleStyled,
  ValueStyled,
} from './itemInstallment.styled';

export const ItemInstallmentContainer = ({
  price,
  translations,
}: CourseInstallmentContainerProps): Nullable<ReactElement> => {
  const showInstallment =
    (!!price?.priceCustomer && !!price?.priceCustomer?.installment) ||
    (!price?.priceCustomer && !!price?.installment);
  const installment = price?.priceCustomer?.installment
    ? price.priceCustomer.installment
    : price.installment;

  if (!showInstallment) {
    return null;
  }

  return (
    <ItemInstallmentContainerStyled>
      <TitleStyled>
        {translations.installment_price_label} {installment?.duration}
      </TitleStyled>
      <ValueStyled>
        {installment?.priceWithTotalDiscount || installment?.price}
      </ValueStyled>
    </ItemInstallmentContainerStyled>
  );
};
