import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import {
  FormSubscriptionContainerProps,
  FormSubscriptionContentTypes,
} from './formSubscription.model';
import {
  FormSubscriptionContextProvider,
  useFormSubscriptionContext,
} from './formSubscription.context';
import { ContentSwitcherContainer } from './contentSwitcher';
import { FullAccessContentContainer } from './fullAccessContent';
import { SubscriptionContentContainer } from './subscriptionContent';
import { FormStatusContainer } from './formStatus';

const FormContent = (): ReactElement => {
  const {
    currentPopup,
    handlePopupClose,
    formContent,
    showFormStatus,
    showSwitcherButton,
  } = useFormSubscriptionContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <>
        {formContent === FormSubscriptionContentTypes.subscription ? (
          <SubscriptionContentContainer />
        ) : (
          <FullAccessContentContainer />
        )}
        {showFormStatus && <FormStatusContainer />}
        {showSwitcherButton && <ContentSwitcherContainer />}
      </>
    </CenteredPopupContainer>
  );
};

export const FormSubscriptionContainer = (
  props: FormSubscriptionContainerProps
): Nullable<ReactElement> => (
  <FormSubscriptionContextProvider props={props}>
    <FormContent />
  </FormSubscriptionContextProvider>
);
