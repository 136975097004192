import styled, { css } from 'styled-components';

import { breakpoint, hideScrollbar } from '../../../styles';
import { PopupStyleProps } from './popup.model';
import { ITheme } from '../../themes';
import { getStylesByMode } from './popup.service';

type WrapperPopupStyledProps = {
  isMobile: boolean;
  zIndex?: number;
};

export const WrapperPopupStyled = styled.div<WrapperPopupStyledProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 40px;
  z-index: ${({ theme, zIndex }): string =>
    zIndex || theme.ZINDEX.TOP_PAGE_CONTENT};
  position: fixed;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  ${hideScrollbar};
  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 0;
      `,
      isMobile
    )}
`;

type PopupStyledProps = {
  customStyle: boolean;
  isMobile: boolean;
  styleProps: PopupStyleProps;
  width?: string;
  darkMode: boolean;
  fullHeightMobile?: boolean;
};

const defaultStyles = (
  theme: ITheme,
  width = '520px',
  isDarkMode: boolean,
  isMobile: boolean
): string => `
       width: ${width};
       ${getStylesByMode(theme, isDarkMode)}
       box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
       border-radius: 24px;
      max-height: 100%;
      ${isMobile && 'overflow-y: auto;'}
      ${hideScrollbar()};
`;

export const PopupStyled = styled.div<PopupStyledProps>`
  ${(props): string =>
    props.customStyle
      ? ''
      : defaultStyles(
          props.theme,
          props.width,
          props.darkMode,
          props.isMobile
        )};
  position: relative;
  box-sizing: border-box;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_LAYER};
  display: flex;
  flex-direction: column;

  ${({ isMobile, fullHeightMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 0;
        width: 100%;
        ${fullHeightMobile
          ? `
            min-height: 100%;
            border-radius: 0;
         `
          : `
            min-height: 375px;
            max-height: 80%;
            height: fit-content;
            margin-top: auto;
            border-radius: 24px 24px 0 0;
        
        `}
      `,
      isMobile
    )}
  ${breakpoint(
    'smallDown',
    css`
      padding: 0;
    `
  )}
`;
