import styled from 'styled-components';
import { AdvantagesImageStyledProps } from './advantagesImage.model';
import { advantagesBreakpoint } from '../coursesAdvantages.styled';

export const AdvantagesImageStyled = styled.div<AdvantagesImageStyledProps>`
  align-self: flex-end;
  flex: 0 0 362px;
  height: 362px;

  img {
    width: 100%;
    height: 100%;
  }

  ${advantagesBreakpoint} {
    align-self: center;
    flex: unset;
    width: 235px;
    height: 235px;
    margin-top: -32px;
    margin-bottom: 16px;
  }
`;
