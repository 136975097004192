import { urlService, objectService } from '../../services';
import { MetaPaginationModel } from '../../models';
import { NextPageContext } from 'next';

export const paginationRedirectToLastPage = (
  context: NextPageContext,
  pagination?: MetaPaginationModel
): void => {
  if (pagination && pagination.currentPage > pagination.totalPages) {
    const currentUrl = urlService.getFullUrlFromToPage(
      context.req?.url || `${window.location.pathname}${window.location.search}`
    );
    const url = new URL(currentUrl);
    const searchObject = objectService.getObjectFromQueryString(url.search);

    searchObject.page = String(pagination.totalPages);

    const newSearch = objectService.getQueryStringFromObject(searchObject);

    context?.res?.writeHead(301, {
      Location: urlService.normalizeUrl(
        `${url.origin}${url.pathname}${!!newSearch ? `?${newSearch}` : ''}`
      ),
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    context?.res?.end();
  }
};
