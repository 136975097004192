import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconInfoComponent = ({
  width = 16,
  height = 16,
  strokeColor = '#005DAD',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="6.5"
      fill={strokeColor}
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M8.21038 3.607C8.45238 3.607 8.64671 3.695 8.79338 3.871C8.94738 4.047 9.01704 4.26333 9.00238 4.52C8.99504 4.76933 8.91071 4.982 8.74938 5.158C8.58804 5.32667 8.38638 5.411 8.14438 5.411C7.89504 5.411 7.69338 5.32667 7.53938 5.158C7.39271 4.982 7.32304 4.76933 7.33038 4.52C7.34504 4.26333 7.44038 4.047 7.61638 3.871C7.79238 3.695 7.99038 3.607 8.21038 3.607ZM7.82538 6.379C8.20671 6.379 8.47071 6.49633 8.61738 6.731C8.77138 6.95833 8.84838 7.26633 8.84838 7.655C8.84838 7.831 8.83004 8.06933 8.79338 8.37C8.75671 8.67067 8.71638 8.986 8.67238 9.316C8.62838 9.646 8.58804 9.94667 8.55138 10.218C8.51471 10.4893 8.49638 10.6873 8.49638 10.812C8.49638 10.9293 8.51471 11.0063 8.55138 11.043C8.58804 11.0797 8.62471 11.098 8.66138 11.098C8.74938 11.098 8.83738 11.0357 8.92538 10.911C9.01338 10.7863 9.10504 10.559 9.20038 10.229L9.79438 10.405C9.76504 10.559 9.70638 10.735 9.61838 10.933C9.53771 11.131 9.42771 11.3217 9.28838 11.505C9.14904 11.681 8.98038 11.8277 8.78238 11.945C8.59171 12.0623 8.37171 12.121 8.12238 12.121C7.71904 12.121 7.44038 12.011 7.28638 11.791C7.13971 11.571 7.06638 11.2667 7.06638 10.878C7.06638 10.6653 7.08471 10.4123 7.12138 10.119C7.15804 9.82567 7.19838 9.525 7.24238 9.217C7.29371 8.909 7.33771 8.623 7.37438 8.359C7.41104 8.08767 7.42938 7.864 7.42938 7.688C7.42938 7.56333 7.41104 7.48633 7.37438 7.457C7.33771 7.42033 7.29738 7.402 7.25338 7.402C7.17271 7.402 7.08838 7.46433 7.00038 7.589C6.91238 7.71367 6.82071 7.941 6.72538 8.271L6.13138 8.095C6.16071 7.941 6.21938 7.765 6.30738 7.567C6.39538 7.369 6.50904 7.182 6.64838 7.006C6.79504 6.82267 6.96371 6.67233 7.15438 6.555C7.35238 6.43767 7.57604 6.379 7.82538 6.379Z"
      fill="white"
    />
  </svg>
);
