import styled from 'styled-components';

import { fontSettings } from '../../../../styles/mixins';

export const FormPopupSubTitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  margin-bottom: 32px;
  text-align: center;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  white-space: pre-wrap;
  a {
    text-decoration: none;
    color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
    cursor: pointer;
  }
`;
