import styled from 'styled-components';

import { PaperComponentStyled } from '../../paper/paper.styled';

export const CustomerPreviewStyled = styled(PaperComponentStyled)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px;

  & > svg {
    transform: rotate(-90deg);
  }
`;
