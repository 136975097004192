import Link from 'next/link';
import React, { ReactElement } from 'react';

import { IconBackComponent } from '../../icons';
import { BackLinkStyled } from './backLink.styled';
import { BackLinkContainerProps } from './backLink.model';
import { logger } from '../../../services';
import { Nullable } from '../../../models';

export const BackLinkContainer = ({
  href,
  title,
  onClick,
}: BackLinkContainerProps): Nullable<ReactElement> => {
  if (onClick) {
    return (
      <BackLinkStyled onClick={onClick}>
        <IconBackComponent />
        {title}
      </BackLinkStyled>
    );
  }

  if (href) {
    return (
      <Link href={href}>
        <BackLinkStyled>
          <IconBackComponent />
          {title}
        </BackLinkStyled>
      </Link>
    );
  }

  logger.error('ERROR: You should specify onClick or href prop');

  return null;
};
