import styled from 'styled-components';
import { LogoCompanyProps } from './logoCompany.model';

export const LogoCompanyStyled = styled.div<LogoCompanyProps>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
`;
