import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { MenuElement } from '../../../menu.model';
import { TopMenuLinkContainerStyled } from './topMenuLink.styled';

export const TopMenuLinkContainer = ({
  link,
  title,
  handleClickCallback,
}: MenuElement): ReactElement => {
  const router = useRouter();
  const onLinkClick = (): void => {
    if (typeof handleClickCallback === 'function') {
      handleClickCallback(link);
    }
    router.push(link);
  };

  return <TopMenuLinkContainerStyled onClick={onLinkClick} children={title} />;
};
