import styled from 'styled-components';

export const PaginationContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 56px;

  button svg {
    transform: rotate(90deg);
  }
`;

export const DividerStyled = styled.div`
  padding-bottom: 16px;
`;
