import { RequestHeader } from '@lerna-core';
import { CourseSimilarClient } from './courseSimilar.client';
import { ApiSimilarCoursesModel } from './courseSimilar.model';

export async function courseSimilarClientProvider(
  courseSlug: string,
  page: number,
  headers: RequestHeader
): Promise<ApiSimilarCoursesModel> {
  const response = await new CourseSimilarClient(
    courseSlug,
    page,
    headers
  ).get();

  return response as unknown as Promise<ApiSimilarCoursesModel>;
}
