import { createSelector } from 'reselect';

import { RootState } from '../../models';
import { PopupsEnum } from './popups.constants';
import { PopupsReducer } from './popups.reducer';
import {
  GetOpenedPopupSelector,
  GetOpenedPopupsSelector,
} from './popups.model';

/**
 * Get popup by slug
 * @return {boolean}
 */
export const getCurrentPopupFromOpen = (
  popupSlug: PopupsEnum
): GetOpenedPopupSelector =>
  createSelector(
    (state: RootState<PopupsReducer>) => state.popups,
    (reducer: PopupsReducer) =>
      reducer.openedPopups.find((popup) => popup.slug === popupSlug) || null
  );

export const getCurrentPopups = (): GetOpenedPopupsSelector =>
  createSelector(
    (state: RootState<PopupsReducer>) => state.popups,
    (reducer: PopupsReducer) => reducer.openedPopups || null
  );
