import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const LeftAdvantagesStyled = styled.div``;

export const AdvantagesItem = styled.div`
  display: flex;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  svg {
    flex: 0 0 24px;
    margin-right: 8px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

      svg {
        flex: 0 0 20px;
        height: 20px;
      }
    `
  )}
`;
