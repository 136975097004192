import React, { ReactElement } from 'react';
import Link from 'next/link';
import { LinksItemStyled } from './linksItem.styled';
import { LinksItemContainerProps } from './linksItem.model';

export const LinksItemContainer = ({
  text,
  href,
}: LinksItemContainerProps): ReactElement => {
  return (
    <Link href={href}>
      <LinksItemStyled>{text}</LinksItemStyled>
    </Link>
  );
};
