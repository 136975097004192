import { NextRouter } from 'next/router';
import {
  FilterModel,
  FilterOptionModel,
  filterRouterService,
  FiltersModel,
  urlService,
  FiltersSlugEnum,
} from '@lerna-core';

export const isCustomCheckboxFilter = (slug: FiltersSlugEnum): boolean => {
  const customFilterSlugs = [
    FiltersSlugEnum.subscription,
    FiltersSlugEnum.wb,
    FiltersSlugEnum.onlyMiniCourses,
  ];

  return customFilterSlugs.includes(slug);
};

export const selectFilterOption = (
  router: NextRouter,
  isSelected: boolean,
  filter: FilterModel,
  option: FilterOptionModel,
  filters: FiltersModel[],
  defaultPathname: string,
  callback?: () => void
): void => {
  const hasFastLink = !!router.query.fastlink;
  let newQuery = filterRouterService.getNewRouteCheckbox(
    router,
    isSelected,
    filter.slug,
    option.slug
  );

  if (hasFastLink) {
    newQuery = {
      ...newQuery,
      ...filterRouterService.getNewRouteFromFilters(router, filters),
    };
  }
  router
    .push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
      undefined,
      { scroll: false }
    )
    .then(() => {
      if (!!callback) {
        callback();
      }
    });
};
