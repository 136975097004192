import React, { memo, ReactElement } from 'react';
import {
  ContactsTextStyled,
  ContactsItemTitleStyled,
  ContactsItemValueStyled,
} from './contactsText.styled';
import { Nullable } from '../../../../../models';
import { ContactsItemContainerProps } from './contactsText.model';

function ContactsText({
  value,
  title,
  secondValue,
}: ContactsItemContainerProps): Nullable<ReactElement> {
  if (!value) return null;

  return (
    <ContactsTextStyled>
      {title && <ContactsItemTitleStyled>{title}</ContactsItemTitleStyled>}
      <ContactsItemValueStyled>{value}</ContactsItemValueStyled>
      {secondValue && (
        <ContactsItemValueStyled>{secondValue}</ContactsItemValueStyled>
      )}
    </ContactsTextStyled>
  );
}

export const ContactsTextContainer = memo(ContactsText);
