import styled, { css } from 'styled-components';
import {
  TooltipContentPosition,
  TooltipContentStyledProps,
} from './tooltipContent.model';
import { breakpoint, fontSettings } from '@lerna-core';
import { rgba } from 'polished';

const getTooltipContentPosition = (
  position?: TooltipContentPosition
): string => {
  switch (position) {
    case 'center': {
      return `
        right: calc(50% + 3px);
        transform: translateX(50%);
      `;
    }
    case 'left': {
      return `
        left: 100%;
      `;
    }
    case 'right':
    default: {
      return `
        left: 0;
      `;
    }
  }
};

export const TooltipContentStyled = styled.div<TooltipContentStyledProps>`
  position: absolute;
  top: calc(100% + 2px);
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_HINTS};
  ${({ position }): string => getTooltipContentPosition(position)}
  margin: ${({ margin }): string => (margin ? margin : '0')}}

  ${breakpoint(
    'mediumDown',
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: unset;
      transform: unset;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0;
    `
  )}
`;

export const TooltipOverlayStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.48)};
  display: none;

  ${breakpoint(
    'mediumDown',
    css`
      display: block;
    `
  )}
`;

export const TooltipBoxStyled = styled.div`
  background: ${({ theme }): string => theme.COLOR.WHITE};
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
  border-radius: 16px;
  padding: 20px 28px 20px 20px;
  min-width: 355px;
  box-sizing: border-box;
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};

  ${breakpoint(
    'mediumDown',
    css`
      border-radius: 24px 24px 0px 0px;
      padding-top: 45px;
      min-width: unset;
    `
  )}
`;

export const TooltipTextStyled = styled.div`
  b {
    font-weight: 600;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const TooltipCloseStyled = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 8px;
      border-radius: 50%;
      background-color: ${({ theme }): string => theme.COLOR.BLACK_4};
    `
  )}
`;
