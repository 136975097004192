import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EducationStreamsPopupStyled,
  EducationStreamsPopupSubTitleStyled,
  EducationStreamsPopupTitleStyled,
  EducationStreamsPopupWrapperStyled,
} from './educationStreamsPopup.styled';
import {
  getCurrentPopupFromOpen,
  handleClosePopup,
  PopupContainer,
  PopupsEnum,
} from '../../popups';
import { ButtonColor, ButtonContainer } from '../../button';
import { EducationStreamsPopupContainerProps } from './educationStreamsPopup.model';
import { useTranslations } from '../../translations';
import { getEducationStreamsPopupStyleSettings } from './educationStreamsPopup.helper';
import { EducationStreamComponent } from '../educationStream';

export const EducationStreamsPopupContainer = ({
  educationStreams,
  selectedEducationStreams,
  handleSelectEducationStreams,
}: EducationStreamsPopupContainerProps): ReactElement | null => {
  const dispatch = useDispatch();
  const {
    button_save_label,
    education_stream_title,
    education_stream_subtitle,
  } = useTranslations();

  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.educationStreamsPopup)
  );

  const handleClose = (): void => {
    dispatch(handleClosePopup(PopupsEnum.educationStreamsPopup));
  };

  if (!currentPopup) return null;

  const checkIsSelected = (id: string): boolean => {
    return selectedEducationStreams.some((stream) => stream.id === id);
  };

  return (
    <PopupContainer
      isOpened={!!currentPopup}
      handleClose={handleClose}
      styleSettings={getEducationStreamsPopupStyleSettings()}
      width={'600px'}
    >
      <EducationStreamsPopupStyled>
        <EducationStreamsPopupTitleStyled>
          {education_stream_title}
        </EducationStreamsPopupTitleStyled>
        <EducationStreamsPopupSubTitleStyled>
          {education_stream_subtitle}
        </EducationStreamsPopupSubTitleStyled>
        <EducationStreamsPopupWrapperStyled>
          {educationStreams?.map((educationStream) => (
            <EducationStreamComponent
              key={educationStream.id}
              educationStream={educationStream}
              handleSelect={handleSelectEducationStreams}
              isSelected={checkIsSelected(educationStream.id)}
            />
          ))}
        </EducationStreamsPopupWrapperStyled>
        <ButtonContainer
          color={ButtonColor.purple}
          onClick={handleClose}
          borderRadius={100}
          title={button_save_label}
        />
      </EducationStreamsPopupStyled>
    </PopupContainer>
  );
};
