import { usePageRouter } from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../coursesPage';
import { EmptyClearButtonContainer } from './emptyClearButton';
import {
  ListEmptyStyled,
  SubtitleStyled,
  TitleStyled,
} from './listEmpty.styled';

export const ListEmptyContainer = (): ReactElement => {
  const isRouting = usePageRouter();
  const { translations } = useCoursesPageContext();

  return (
    <ListEmptyStyled isRouting={isRouting}>
      <TitleStyled>{translations?.list_no_results_label}</TitleStyled>
      <SubtitleStyled>
        {translations?.list_clear_filters_and_search_label}
      </SubtitleStyled>
      <EmptyClearButtonContainer />
    </ListEmptyStyled>
  );
};
