import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { PriceItemStyledProps } from './pricesItem.model';

export const PricesItemStyled = styled.div<PriceItemStyledProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};
  ${({ styles }): string => (styles ? styles : '')}

  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    `
  )}
`;

export const TitleStyled = styled.div<PriceItemStyledProps>`
  ${({ styles }): string => (styles ? styles : '')}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
    `
  )}
`;

export const ValueStyled = styled.div<PriceItemStyledProps>`
  flex: 0 0 auto;
  margin-left: 10px;
  ${({ styles }): string => (styles ? styles : '')}
`;
