import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';
import { VendorEnum, VendorsLogoSize } from '../vendors';

export const IconSkillboxComponent = ({
  width = VendorsLogoSize[VendorEnum.skillbox].width,
  height = VendorsLogoSize[VendorEnum.skillbox].height,
  fillColor = '#000',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.735 15.555H62l-2.96-3.545-2.945 3.545H54.37l3.805-4.58-3.608-4.32h1.735l2.738 3.28 2.724-3.28h1.724l-3.584 4.314 3.83 4.586Zm-26.192.223c-2.725 0-3.543-.668-3.543-.668V2.207h1.329v4.69c.491-.244 1.2-.463 2.214-.463 2.69 0 4.871 1.968 4.871 4.671 0 2.704-2.18 4.672-4.871 4.672Zm0-8.01c-.923 0-1.679.356-2.215.721v5.509s.713.445 2.214.445c1.957 0 3.543-1.372 3.543-3.337 0-1.966-1.586-3.337-3.543-3.337ZM29.57 2.208H30.9v13.348H29.57V2.207Zm-3.1 0v13.348h-1.33V2.207h1.33Zm-5.12 2.002a1.11 1.11 0 0 1-1.108-1.112 1.11 1.11 0 0 1 1.107-1.113 1.11 1.11 0 0 1 1.107 1.113 1.11 1.11 0 0 1-1.107 1.112Zm-4.403 11.346-4.65-4.672 4.207-4.227h1.879l-4.207 4.227 4.65 4.672h-1.88Zm-4.65-4.673v4.673H10.97V2.207h1.329v8.675Zm-3.432 1.042c0 2.23-1.769 3.849-4.577 3.849-1.818 0-3.232-.902-4.288-1.935l.94-1.043c.878.841 1.913 1.66 3.33 1.66 1.573 0 3.27-.887 3.27-2.48 0-1.322-1.047-2.184-3.244-2.865C1.79 8.34.519 7.297.519 5.335.519 3.394 2.155 1.8 4.485 1.8c1.74 0 2.83.746 3.89 1.699l-.923 1.03C6.522 3.7 5.637 3.14 4.55 3.14c-1.348 0-2.676.873-2.676 2.13 0 1.14.651 1.866 2.95 2.573 2.107.652 4.041 1.662 4.041 4.081Zm13.175 3.63h-1.33V6.657h1.33v8.899ZM48.832 16c-2.69 0-4.871-2.191-4.871-4.895 0-2.703 2.18-4.894 4.871-4.894 2.691 0 4.872 2.19 4.872 4.894S51.524 16 48.832 16Zm-3.543-4.895a3.551 3.551 0 0 1 3.543-3.56 3.551 3.551 0 0 1 3.543 3.56 3.551 3.551 0 0 1-3.543 3.56 3.551 3.551 0 0 1-3.543-3.56Z"
      fill={fillColor}
    />
  </svg>
);
