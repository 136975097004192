import React, { ReactElement } from 'react';
import { publicConfig, RegionsEnum } from '@lerna-core';
import { IconStyled, TabsPaymentIconsStyled } from './tabsPaymentIcons.styled';
import {
  IconMastercardComponent,
  IconMirComponent,
  IconVisaComponent,
} from '@features/common/forms/components/icons';

export const TabsPaymentIconsContainer = (): ReactElement => {
  const isKzRegion = publicConfig?.REGION === RegionsEnum.kz;

  return (
    <TabsPaymentIconsStyled>
      <IconStyled>
        <IconVisaComponent />
      </IconStyled>
      {!isKzRegion && (
        <IconStyled>
          <IconMirComponent />
        </IconStyled>
      )}
      <IconStyled>
        <IconMastercardComponent />
      </IconStyled>
    </TabsPaymentIconsStyled>
  );
};
