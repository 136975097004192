import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const ItemPopupStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  ${breakpoint(
    'mediumDown',
    css`
      padding-bottom: calc(70px + env(safe-area-inset-bottom));
    `
  )}
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin: 32px 0 12px;
`;

export const ButtonStyled = styled.div`
  width: 100%;

  button {
    width: 100%;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  }

  ${breakpoint(
    'mediumDown',
    css`
      position: fixed;
      left: 20px;
      right: 20px;
      bottom: calc(16px + env(safe-area-inset-bottom));
      width: auto;
    `
  )}
`;
