import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TranslationModel } from '../../../models';
import { LanguageItemsContainer } from '../../switchLanguage';
import { PopupContainer } from '../popup';
import { PopupTitleContainer } from '../popup/popupTitle';
import { handleClosePopup } from '../popups.actions';
import { PopupsEnum } from '../popups.constants';
import { getCurrentPopupFromOpen } from '../popups.selectors';
import { SwitchLanguagePopupStyled } from './switchLanguagePopup.styled';

type SwitchLanguagePopupContainerProps = {
  supportedLocales: string[];
  translations?: TranslationModel;
};

export const SwitchLanguagePopupContainer = ({
  supportedLocales,
  translations,
}: SwitchLanguagePopupContainerProps): ReactElement | null => {
  const dispatch = useDispatch();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.switchLanguagePopup)
  );

  const handleClose = (): void => {
    dispatch(handleClosePopup(PopupsEnum.switchLanguagePopup));
  };

  if (!currentPopup) return null;

  return (
    <PopupContainer
      isOpened={!!currentPopup}
      handleClose={handleClose}
      title={translations?.select_language_label}
      isMobile
    >
      <PopupTitleContainer text={translations?.select_language_label} />
      <SwitchLanguagePopupStyled>
        <LanguageItemsContainer
          supportedLocales={supportedLocales}
          handleClickItem={handleClose}
        />
      </SwitchLanguagePopupStyled>
    </PopupContainer>
  );
};
