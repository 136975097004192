import { RootState } from '../../../models';
import { createSelector } from 'reselect';
import { CoursesTransferState } from './coursesTransfer.model';

export const getCoursesTransferSelector = createSelector(
  (state: RootState<CoursesTransferState>) => state.coursesTransfer,
  (reducer: CoursesTransferState) => reducer as CoursesTransferState
);

export const getCoursesTransferUnallocatedFiltersSelector = createSelector(
  (state: RootState<CoursesTransferState>) => state.coursesTransfer,
  (reducer: CoursesTransferState) => reducer.unallocated.filters
);

export const getCoursesTransferAllocatedFiltersSelector = createSelector(
  (state: RootState<CoursesTransferState>) => state.coursesTransfer,
  (reducer: CoursesTransferState) => reducer.allocated.filters
);
