import React, { ReactElement, SyntheticEvent, useRef } from 'react';
import { TooltipMobileContainerProps } from './tooltipMobile.model';
import {
  TooltipMobileStyled,
  TooltipMobileTriggerStyled,
} from './tooltipMobile.styled';
import { TooltipMobileContentContainer } from './tooltipMobileContent';
import { IconInfoCircledComponent, useTheme, useToggle } from '@lerna-core';

export const TooltipMobileContainer = ({
  content,
  translations,
}: TooltipMobileContainerProps): ReactElement => {
  const theme = useTheme();
  const tooltipRef = useRef(null);
  const [isOpen, toggleOpen] = useToggle(false);

  const handleTooltipToggle = (e: SyntheticEvent): void => {
    e.stopPropagation();

    toggleOpen();
  };

  return (
    <TooltipMobileStyled ref={tooltipRef}>
      <TooltipMobileTriggerStyled onClick={handleTooltipToggle}>
        <IconInfoCircledComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.BLACK}
          fillColor={theme.COLOR.BLACK}
        />
      </TooltipMobileTriggerStyled>
      {isOpen && (
        <TooltipMobileContentContainer
          content={content}
          handleClose={handleTooltipToggle}
          translations={translations}
        />
      )}
    </TooltipMobileStyled>
  );
};
