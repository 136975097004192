import React, { ReactElement } from 'react';

import { HeaderStyled, HeaderStyledWrapper } from './header.styled';
import { HeaderContainerProps } from './header.model';

export const HeaderContainer = ({
  children,
  isSticky,
  maxWidth,
  withCompany = false,
}: HeaderContainerProps): ReactElement => (
  <HeaderStyledWrapper isSticky={isSticky} withCompany={withCompany}>
    <HeaderStyled maxWidth={maxWidth} children={children} />
  </HeaderStyledWrapper>
);
