import React, { Fragment, ReactElement } from 'react';
import { DoughnutSegmentStyled } from './doughnut.styled';

const DIVIDER_WIDTH = 0.5;

export const createDoughnutSegmentDivider = (
  width: number,
  color = '#fff',
  index: number
): ReactElement => {
  return (
    <DoughnutSegmentStyled
      key={`divider_${index}`}
      cx="50%"
      cy="50%"
      stroke={color}
      strokeWidth={2}
      fill="none"
      r={15.9}
      strokeDasharray={`${width} 100`}
    />
  );
};

export const createDoughnutSegment = (
  segment: number,
  offset: number,
  color = '#000',
  index: number,
  withDivider: boolean,
  dividerColor?: string,
  dividerWidth = DIVIDER_WIDTH
): ReactElement => {
  const Segment = (
    segment: string | number,
    offset: string | number
  ): ReactElement => (
    <DoughnutSegmentStyled
      key={`segment_${index}`}
      cx="50%"
      cy="50%"
      strokeDasharray={`${segment} 100`}
      strokeDashoffset={`-${offset}`}
      stroke={color}
      strokeWidth={2}
      fill="none"
      r={15.9}
    />
  );
  if (withDivider) {
    return (
      <Fragment key={`segment_${index}`}>
        {Segment(segment - dividerWidth, offset + dividerWidth)}
        {createDoughnutSegmentDivider(dividerWidth, dividerColor, index)}
      </Fragment>
    );
  }

  return Segment(segment, offset);
};

export const convertToPercents = (arr: number[]): number[] => {
  const total = arr.reduce((acc, item) => {
    acc += item;

    return acc;
  }, 0);

  const percents = arr.filter((percent) => percent !== 0);

  return percents.reduce((acc, item, index, list) => {
    if (list.length === index + 1) {
      const rest = acc.reduce((acc, item) => {
        acc = acc - item;

        return acc;
      }, 100);
      acc.push(rest);
    } else {
      acc.push(Math.round((item * 100) / total));
    }

    return acc;
  }, [] as number[]);
};

export const getOffset = (list: number[], index: number): number =>
  list.slice(0, index).reduce((acc, item) => {
    acc += item;

    return acc;
  }, 0);
