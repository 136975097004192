import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import {
  AdvantagesTitleStyledProps,
  TitleStyledProps,
} from './advantagesText.model';
import { advantagesBreakpoint } from '../coursesAdvantages.styled';

export const AdvantagesTextStyled = styled.div<AdvantagesTitleStyledProps>`
  background: ${({ titleColor }): string => titleColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: max-content;
  margin-bottom: ${({ withBanners }): number => (withBanners ? 24 : 40)}px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)}
  font-weight: 600;

  ${advantagesBreakpoint} {
    text-align: center;
    margin-bottom: 24px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
    font-weight: 700;
  }
  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 16px;
    `
  )}
`;

export const TitleStyled = styled.div<TitleStyledProps>`
  max-width: 750px;

  ${advantagesBreakpoint} {
    max-width: 520px;
  }
`;

export const SubtitleStyled = styled.div``;
