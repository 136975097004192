import {
  CompanyMBMColors,
  CompanyUuidEnum,
  DiscountProgramModel,
  FilterOptionModel,
  FiltersModel,
  FiltersSlugEnum,
  mindboxService,
  Nullable,
} from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';
import { CoursesAdditionalColorsModel } from '@features/courses';
import { rgba } from 'polished';

export const sendMindboxSphereEvent = (
  query: ParsedUrlQuery,
  filters: FiltersModel[]
): void => {
  const querySphere = query[FiltersSlugEnum.sphere] || query.fastlink;
  if (querySphere) {
    const sphereFilter = filters.find(
      (filter) => filter.slug === FiltersSlugEnum.sphere
    );
    const activeSphereOption = (
      sphereFilter?.options as FilterOptionModel[]
    ).find((option) => option.slug === querySphere);

    if (activeSphereOption) {
      mindboxService.sendEvent(
        mindboxService.EventName.gotToSphere,
        mindboxService.getGoToSpherePayload(activeSphereOption.mindboxId)
      );
    }
  }
};

export const getAdditionalColors = (
  discountProgram: Nullable<DiscountProgramModel>,
  companyId?: string
): CoursesAdditionalColorsModel => {
  if (companyId === CompanyUuidEnum.mbm) {
    return {
      filtersAccentColor: CompanyMBMColors.accentColor,
      buttonAdditionalColors: {
        backgroundHoverColor: rgba(CompanyMBMColors.secondaryColor, 0.2),
        backgroundColor: rgba(CompanyMBMColors.secondaryColor, 0.1),
        color: CompanyMBMColors.secondaryColor,
      },
    };
  }

  if (!!discountProgram?.colors.filtersAccentColor) {
    return {
      filtersAccentColor: discountProgram.colors.filtersAccentColor,
      buttonAdditionalColors: discountProgram?.colors
        ? {
            backgroundHoverColor: rgba(
              discountProgram.colors.secondaryColor,
              0.4
            ),
            backgroundColor: rgba(discountProgram.colors.secondaryColor, 0.1),
            color: discountProgram.colors.secondaryColor,
          }
        : undefined,
    };
  }

  return {};
};
