import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconMarkComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '1.5',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m2 8 4 4 8-8"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
