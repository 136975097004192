import React, { ReactElement } from 'react';
import { DepartmentsUnallocatedFilterContainerProps } from './departmentsUnallocatedFilter.model';
import { useSelector } from 'react-redux';
import {
  requestTransferCustomersUnallocated,
  setTransferCustomersUnallocatedFilters,
} from '../../customersTransfer.actions';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import { getCustomersTransferSelector } from '../../customersTransfer.selectors';
import { getDepartments } from '../../../../departments';
import { TableFilterContainer } from '../../../../table';

export const DepartmentsUnallocatedFilterContainer = ({
  translations,
}: DepartmentsUnallocatedFilterContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
  } = useCustomersTransferContext();

  const filterOptions = useSelector(getDepartments);
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCustomersTransferSelector);

  const handleApplyFilter = (departmentUuids: string[]): void => {
    dispatch(setTransferCustomersUnallocatedFilters({ departmentUuids }));
  };
  const handleRequest = (departmentUuids: string[]): void => {
    dispatch(
      requestTransferCustomersUnallocated(customersProvider, {
        ...filters,
        departmentUuids,
        removed,
        added,
        id,
        notRelatedWithSubscriptionId: subscriptionId,
        notRelatedWithBenefitId: benefitId,
        notRelatedWithLibraryId: libraryId,
        notRelatedWithTariffId: tariffId,
      })
    );
  };

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.departmentUuids}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.customer_profile_department_label}
    />
  );
};
