import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconUserComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#78747A',
  strokeWidth = '2',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="8"
      r="5"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M2.7793 21C4.742 17.9898 8.13881 16 12.0004 16C15.8619 16 19.2587 17.9898 21.2214 21"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);
