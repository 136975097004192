import { ITheme } from '../../themes';
import { rgba } from 'polished';

export const getFloatingPlaceholderColor = (
  theme: ITheme,
  hasError = false,
  disabled = false,
  active = false
): string => {
  switch (true) {
    case hasError:
      return theme.COLOR.INFO_RED_COLOR;
    case disabled && !active:
      return rgba(theme.COLOR.TEXT_LIGHT_COLOR, 0.5);
    default:
      return theme.COLOR.TEXT_LIGHT_COLOR;
  }
};
