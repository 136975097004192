import { publicConfig } from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';
import { mapCustomerEditCompanyRequestParams } from './customerEditCompany.helper';
import { CustomerEditCompanyRequestParams } from './customerEditCompany.model';
import { CustomerApiResponseModel, CustomerModel } from '../customer.model';
import { customerAdapter } from '../customer.adapter';

const CUSTOMER_ID = '{{CUSTOMER_ID}}';

export class CustomerEditCompanyClient extends SecureRequestClient<
  CustomerEditCompanyRequestParams,
  CustomerApiResponseModel,
  CustomerModel
> {
  protected path = `user-api/v1/customers/${CUSTOMER_ID}/career`;
  protected requestParameters: CustomerEditCompanyRequestParams;
  protected adapter = customerAdapter;

  constructor(id: string, positionUuid: string, departmentUuid: string) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = mapCustomerEditCompanyRequestParams(
      positionUuid,
      departmentUuid
    );
    this.path = this.path.replace(CUSTOMER_ID, id);
  }
}
