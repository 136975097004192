import React, { ReactElement } from 'react';

import { SliderItemContainerProps } from './sliderItem.model';
import { SliderItemContainerStyled } from './sliderItem.styled';

export const SliderItemContainer = ({
  width = 'auto',
  spaceBetweenItems,
  slide,
}: SliderItemContainerProps): ReactElement => {
  return (
    <SliderItemContainerStyled
      spaceBetweenItems={spaceBetweenItems}
      width={width}
    >
      {slide}
    </SliderItemContainerStyled>
  );
};
