import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLoopGradientComponent = ({
  width = 100,
  height = 100,
  strokeWidth = '8',
  fillColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_27320_209911)">
      <path
        d="M85.3624 37.5C83.5574 32.3818 80.608 27.5772 76.5144 23.4835C61.8697 8.83884 38.126 8.83884 23.4814 23.4835L9.46485 37.5M14.6333 62.5C16.4383 67.6182 19.3877 72.4228 23.4814 76.5165C38.126 91.1612 61.8697 91.1612 76.5143 76.5165L90.5308 62.5M9.46485 37.5L9.46484 20.5M9.46485 37.5L26.5 37.5M90.5308 62.5L90.5308 80M90.5308 62.5H73"
        stroke="url(#paint0_linear_27320_209911)"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_27320_209911"
        x1="35.0273"
        y1="88.0625"
        x2="77.5679"
        y2="20.6266"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72" />
        <stop offset="1" stopColor="#4C07DF" />
      </linearGradient>
      <clipPath id="clip0_27320_209911">
        <rect width={'100'} height={'100'} fill={fillColor} />
      </clipPath>
    </defs>
  </svg>
);
