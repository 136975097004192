import { ITheme } from '../../../themes';
import { rgba } from 'polished';

export const getCheckboxBorderColor = (
  theme: ITheme,
  checked?: boolean,
  indeterminate?: boolean,
  disabled?: boolean,
  checkedColor?: string
): string => {
  if (disabled) {
    return rgba(theme.COLOR.BLACK, 0.12);
  }
  if (indeterminate || checked) {
    return checkedColor || theme.COLOR.TEXT_DARK_COLOR;
  }

  return theme.COLOR.TEXT_LIGHT_COLOR;
};
