import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconUserCheckGradientComponent = ({
  width = 40,
  height = 40,
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      fill="url(#paint0_linear_1748_60002)"
      d="M16.346 3c-4.592 0-8.341 3.746-8.341 8.333 0 4.588 3.75 8.334 8.341 8.334 4.592 0 8.342-3.746 8.342-8.334 0-4.587-3.75-8.333-8.342-8.333zm0 2.5c3.24 0 5.84 2.597 5.84 5.833 0 3.237-2.6 5.834-5.84 5.834a5.817 5.817 0 01-5.839-5.834c0-3.236 2.6-5.833 5.84-5.833zm11.678 14.167c-5.067 0-9.175 4.104-9.175 9.166 0 5.063 4.108 9.167 9.175 9.167 5.068 0 9.176-4.104 9.176-9.167 0-5.062-4.108-9.166-9.176-9.166zM6.754 23A3.757 3.757 0 003 26.75v1c0 4.816 5.864 8.583 13.346 8.583a20.64 20.64 0 003.578-.307 10.861 10.861 0 01-1.575-2.292c-.643.067-1.31.1-2.003.1-6.698 0-10.844-3.16-10.844-6.085v-.999c0-.692.56-1.25 1.252-1.25h10.953A10.42 10.42 0 0118.89 23H6.754zm26.275 1.667a.832.832 0 01.59 1.423l-6.673 6.666a.832.832 0 01-1.18 0l-3.336-3.334a.834.834 0 011.18-1.178l2.746 2.744 6.084-6.077a.832.832 0 01.59-.244z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_1748_60002"
        x1="3"
        x2="37.328"
        y1="26.188"
        y2="16.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
    </defs>
  </svg>
);
