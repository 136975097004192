import styled from 'styled-components';

import { fontSettings } from '../../styles';

export const HelpWrapperStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  margin-bottom: 0;
  margin-top: 0;

  + div {
    margin-top: 24px;
  }
`;

export const HelpLineStyled = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.08);
  margin: 40px auto;
`;

export const HelpLinkStyled = styled.a`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.INFO_BLUE_COLOR};
  text-decoration: none;

  &:visited {
    color: ${(props): string => props.theme.COLOR.INFO_BLUE_COLOR};
  }
`;

export const HelpButtonWrapperStyled = styled.div`
  padding-top: 8px;
`;
