import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBookshelfComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M4.75 2.5c-.682 0-1.25.568-1.25 1.25v11.275c-1.117.128-2 1.077-2 2.225v.5A2.261 2.261 0 0 0 3.75 20H5.5v1.75a.75.75 0 1 0 1.5 0V20h10v1.75a.751.751 0 1 0 1.5 0V20h1.75a2.261 2.261 0 0 0 2.25-2.25v-.5c0-1.013-.69-1.87-1.618-2.148.129-.284.156-.615.047-.927l-2.313-6.606a1.26 1.26 0 0 0-1.593-.766l-1.523.533V4.75c0-.682-.568-1.25-1.25-1.25h-2.5c-.682 0-1.25.568-1.25 1.25v.75h-2V3.75c0-.682-.568-1.25-1.25-1.25h-2.5ZM5 4h2v11H5V4Zm7 1h2v10h-2V5ZM8.5 7h2v8h-2V7Zm8.783 1.3 2.148 6.135-1.415.496-2.148-6.135 1.415-.495ZM15.5 12.287 16.45 15h-.95v-2.714ZM3.75 16.5h16.5c.423 0 .75.327.75.75v.5c0 .423-.327.75-.75.75h-2.377a.75.75 0 0 0-.244 0H6.373a.751.751 0 0 0-.244 0H3.75a.739.739 0 0 1-.75-.75v-.5c0-.423.327-.75.75-.75Z"
    />
  </svg>
);
