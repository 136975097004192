import React, { ReactElement } from 'react';
import { Column } from 'react-table';
import { EMPTY } from '../../../constants';
import { TranslationModel } from '../../../models';
import { TableSearchButtonContainer } from '../../table';
import { LocationType, TransferControlsContainer } from '../../transfer';
import {
  DepartmentsAllocatedFilterContainer,
  DepartmentsUnallocatedFilterContainer,
} from './transferDepartments';
import { TransferLeftContainer } from './transferLeft';

import {
  PositionsAllocatedFilterContainer,
  PositionsUnallocatedFilterContainer,
} from './transferPositions';
import { TransferRightContainer } from './transferRight';
import {
  CustomersTableAccessors,
  CustomerTableData,
  CustomerTableEmailStyled,
  CustomerTableRow,
  SearchTerms,
} from './transferTable';
import {
  TariffsAllocatedFilterContainer,
  TariffsUnallocatedFilterContainer,
} from './transferTariffs';

export const TabCustomersTransferComponents = {
  TransferLeftContainer,
  TransferRightContainer,
  TransferControlsContainer,
};

export const getCustomersTableColumns = (
  translations: TranslationModel,
  side: LocationType,
  changeSearch: (term: SearchTerms) => void,
  accessors: CustomersTableAccessors[]
): Column<Record<string, unknown>>[] => {
  const columns = [];

  columns.push({
    Header(): ReactElement {
      const handleClick = (): void => {
        changeSearch(SearchTerms.name);
      };
      switch (side) {
        case LocationType.unallocated:
          return (
            <TableSearchButtonContainer
              title={translations.input_name_label}
              onClick={handleClick}
            />
          );
        case LocationType.allocated:
          return (
            <TableSearchButtonContainer
              title={translations.input_name_label}
              onClick={handleClick}
            />
          );
      }
    },
    accessor: CustomersTableAccessors.name,
  });

  if (accessors.includes(CustomersTableAccessors.email)) {
    columns.push({
      Header(): ReactElement {
        const handleClick = (): void => {
          changeSearch(SearchTerms.email);
        };

        switch (side) {
          case LocationType.unallocated:
            return (
              <TableSearchButtonContainer
                title={translations.input_email_label}
                onClick={handleClick}
              />
            );
          case LocationType.allocated:
            return (
              <TableSearchButtonContainer
                title={translations.input_email_label}
                onClick={handleClick}
              />
            );
        }
      },
      accessor: CustomersTableAccessors.email,
    });
  }

  if (accessors.includes(CustomersTableAccessors.position)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <PositionsUnallocatedFilterContainer
                translations={translations}
              />
            );
          case LocationType.allocated:
            return (
              <PositionsAllocatedFilterContainer translations={translations} />
            );
        }
      },
      accessor: CustomersTableAccessors.position,
    });
  }

  if (accessors.includes(CustomersTableAccessors.department)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <DepartmentsUnallocatedFilterContainer
                translations={translations}
              />
            );
          case LocationType.allocated:
            return (
              <DepartmentsAllocatedFilterContainer
                translations={translations}
              />
            );
        }
      },
      accessor: CustomersTableAccessors.department,
    });
  }

  if (accessors.includes(CustomersTableAccessors.tariff)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <TariffsUnallocatedFilterContainer translations={translations} />
            );
          case LocationType.allocated:
            return (
              <TariffsAllocatedFilterContainer translations={translations} />
            );
        }
      },
      accessor: CustomersTableAccessors.tariff,
      width: 110,
    });
  }

  if (accessors.includes(CustomersTableAccessors.claims)) {
    columns.push({
      Header(): ReactElement {
        return <div>{translations.statistics_opened_courses}</div>;
      },
      accessor: CustomersTableAccessors.claims,
      width: 150,
    });
  }

  return columns;
};

export const getRightRowsData = (
  customers: CustomerTableData[],
  addedCustomers: CustomerTableData[],
  withDisabledRows: boolean
): CustomerTableData[] => {
  if (withDisabledRows) {
    return customers.map((customer) => {
      const isAddedCustomer = addedCustomers.some(
        (addedCustomer) => addedCustomer.uuid === customer.uuid
      );

      return {
        ...customer,
        isDisabled: !isAddedCustomer,
      };
    });
  }

  return customers;
};

export const getLeftRowsData = (
  customers: CustomerTableData[],
  withDisableLeftRows: boolean
): CustomerTableData[] => {
  if (withDisableLeftRows) {
    return customers.map((customer) => ({
      ...customer,
      isDisabled: true,
    }));
  }

  return customers;
};

export const getCustomersTableRows = (
  customers: CustomerTableData[]
): CustomerTableRow[] =>
  customers.map((customer: CustomerTableData): CustomerTableRow => {
    return {
      ...customer,
      name: customer.name || EMPTY,
      position: customer.position.trim() || EMPTY,
      department: customer.department.trim() || EMPTY,
      isDisabled: Boolean(customer.isDisabled),
      tariff: customer.tariff || EMPTY,
      email: (
        <CustomerTableEmailStyled>{customer.email}</CustomerTableEmailStyled>
      ),
    };
  });
