import { ReactElement } from 'react';
import { useTheme } from '../../../../hooks';
import { Nullable } from '../../../../models';
import { IconInfoCircledComponent } from '../../../icons';
import { TransferHintModel } from './hint.model';
import { HintStyled, TextStyled } from './hint.styled';

export const HintContainer = ({
  message,
  isError,
}: TransferHintModel): Nullable<ReactElement> => {
  const theme = useTheme();
  const color = isError
    ? theme.COLOR.INFO_RED_COLOR
    : theme.COLOR.TEXT_MEDIUM_COLOR;

  return (
    <HintStyled>
      <IconInfoCircledComponent strokeColor={color} fillColor={color} />

      <TextStyled dangerouslySetInnerHTML={{ __html: message }} color={color} />
    </HintStyled>
  );
};
