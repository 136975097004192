import styled from 'styled-components';

type LoaderContainerStyled = {
  position?: string;
  withBlur?: boolean;
};

type IconLoaderComponentProps = {
  width?: number;
  height?: number;
};

export const LoaderStyledContainer = styled.div<LoaderContainerStyled>`
  position: ${({ position }): string => (position ? position : 'fixed')};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme, withBlur }): string =>
    withBlur ? theme.COLOR.WHITE_32 : 'initial'};
`;

export const LoaderLogoWrapper = styled.div`
  padding: 16px 0 0 40px;
`;

export const IconLoaderComponentStyled = styled.div<
  IconLoaderComponentProps & LoaderContainerStyled
>`
  position: ${({ position }): string => (position ? position : 'absolute')};
  width: ${({ width = 80 }): string => `${width}px`};
  height: ${({ height = 80 }): string => `${height}px`};
  top: 50%;
  left: 50%;

  & > div {
    width: ${({ width = 80 }): string => `${width}px`};
    height: ${({ height = 80 }): string => `${height}px`};
    transform: translate(-50%, -50%);

    svg {
      -webkit-animation: spin 1.5s linear infinite;
      -moz-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export const IconLoaderLetterComponentStyled = styled.div<IconLoaderComponentProps>`
  position: absolute;
  width: ${({ width = 18 }): string => `${width}px`};
  height: ${({ height = 26 }): string => `${height}px`};
  top: 50%;
  left: 50%;
  svg {
    transform: translate(-50%, -50%);
  }
`;
