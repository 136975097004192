import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  browserService,
  courseAdapter,
  CourseModel,
  DeviceInfoContext,
  logger,
  Nullable,
  setAcceptLanguage,
} from '@lerna-core';
import { CourseGalleryContainer } from '../courseGallery';
import {
  CourseSimilarDesktopContainerStyled,
  CourseSimilarMobileContainerStyled,
} from './courseSimilar.styled';
import { CourseTitleContainer, CourseTitleType } from '../courseTitle';
import { SimilarItemsContainer } from './similarItems';
import { CoursePaperStyled } from '../coursePaper';
import { courseSimilarClientProvider } from './courseSimilar.clientProvider';
import { getAdditionalCoursesState } from './courseSimilar.helper';
import { setSecureHeadersProps } from '@services';
import {
  AdditionalCoursesStateEnum,
  AdditionalCoursesStateModel,
} from './courseSimilar.model';
import { useCoursePageContext } from '../../course';

export const CourseSimilarContainer = (): Nullable<ReactElement> => {
  const { user, course, currencies, translations, vendors, similarCourses } =
    useCoursePageContext();

  const router = useRouter();
  const [additionalCourses, setAdditionalCourses] = useState<CourseModel[]>([]);
  const [additionalCoursesState, setAdditionalCoursesState] =
    useState<AdditionalCoursesStateModel>(
      getAdditionalCoursesState(
        similarCourses?.pagination.totalPages || 1,
        similarCourses?.pagination.currentPage || 1
      )
    );
  const [currentSendingPage, setCurrentSendingPage] = useState<number>(0);

  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const isPricesHiddenOnListingCards =
    user?.career?.isPricesHiddenOnListingCards;

  const handleShowMore = (pageNumber: number): void => {
    if (!!pageNumber) {
      setCurrentSendingPage(pageNumber);
    }
  };

  useEffect(() => {
    if (!!currentSendingPage) {
      const currentPageNeedRequest =
        additionalCoursesState[currentSendingPage] !==
          AdditionalCoursesStateEnum.pending &&
        additionalCoursesState[currentSendingPage] !==
          AdditionalCoursesStateEnum.success;

      if (currentPageNeedRequest) {
        setCurrentSendingPage(currentSendingPage);
        setAdditionalCoursesState({
          ...additionalCoursesState,
          [currentSendingPage]: AdditionalCoursesStateEnum.pending,
        });
      }
    }
  }, [currentSendingPage]);

  useEffect(() => {
    if (
      additionalCoursesState[currentSendingPage] ===
      AdditionalCoursesStateEnum.pending
    ) {
      courseSimilarClientProvider(course.slug, currentSendingPage, {
        ...setAcceptLanguage(router.locale),
        ...setSecureHeadersProps(),
      })
        .then((data) => {
          if (data.data?.length > 0) {
            setAdditionalCourses([
              ...additionalCourses,
              ...data.data.map((course) =>
                courseAdapter(course, currencies, translations, vendors)
              ),
            ]);
            setAdditionalCoursesState({
              ...additionalCoursesState,
              [currentSendingPage]: AdditionalCoursesStateEnum.success,
            });
            setCurrentSendingPage(0);
          }
        })
        .catch((err) => {
          logger.error('[ERROR]: can not get similar courses', err);
          setAdditionalCoursesState({
            ...additionalCoursesState,
            [currentSendingPage]: AdditionalCoursesStateEnum.error,
          });
        });
    }
  }, [additionalCoursesState]);

  const CourseSimilarTitle = (
    <CourseTitleContainer
      title={translations.course_similar_title}
      type={CourseTitleType.h3}
    />
  );

  if (
    !similarCourses ||
    (!!similarCourses && similarCourses.data.length === 0)
  ) {
    return null;
  }

  if (isMobile) {
    return (
      <CourseSimilarMobileContainerStyled>
        <CoursePaperStyled>
          {CourseSimilarTitle}
          <SimilarItemsContainer
            similarCourses={similarCourses}
            translations={translations}
            additionalCourses={additionalCourses}
            showMore={handleShowMore}
            isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
          />
        </CoursePaperStyled>
      </CourseSimilarMobileContainerStyled>
    );
  }

  return (
    <CourseSimilarDesktopContainerStyled>
      {CourseSimilarTitle}
      <CourseGalleryContainer
        similarCourses={similarCourses}
        additionalCourses={additionalCourses}
        translations={translations}
        showMore={handleShowMore}
        additionalCoursesState={additionalCoursesState}
        isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
      />
    </CourseSimilarDesktopContainerStyled>
  );
};
