import { rgba } from 'polished';
import { DottedBorderProps } from './companyEditLogo.model';

export const getDottedBorder = ({
  theme,
  dottedBorder,
}: DottedBorderProps): string => {
  if (dottedBorder) {
    return '';
  }

  return `border: 2px solid ${rgba(theme.COLOR.BLACK, 0.12)};`;
};
