import React, { ReactElement } from 'react';
import { DepartmentsAllocatedFilterContainerProps } from './departmentsAllocatedFilter.model';
import { useSelector } from 'react-redux';
import {
  requestTransferCustomersAllocated,
  setTransferCustomersAllocatedFilters,
} from '../../customersTransfer.actions';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import { getCustomersTransferSelector } from '../../customersTransfer.selectors';
import { getDepartments } from '../../../../departments';
import { TableFilterContainer } from '../../../../table';

export const DepartmentsAllocatedFilterContainer = ({
  translations,
}: DepartmentsAllocatedFilterContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
  } = useCustomersTransferContext();

  const filterOptions = useSelector(getDepartments);
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCustomersTransferSelector);

  const handleApplyFilter = (departmentUuids: string[]): void => {
    dispatch(setTransferCustomersAllocatedFilters({ departmentUuids }));
  };
  const handleRequest = (departmentUuids: string[]): void => {
    dispatch(
      requestTransferCustomersAllocated(customersProvider, {
        ...filters,
        departmentUuids,
        removed,
        added,
        id,
        relatedWithSubscriptionId: subscriptionId,
        relatedWithBenefitId: benefitId,
        relatedWithLibraryId: libraryId,
        relatedWithTariffId: tariffId,
      })
    );
  };

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.departmentUuids}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.customer_profile_department_label}
    />
  );
};
