import { updateCareerProvider } from './updateCareer.clientProvider';
import { UpdateCareer, UpdateCareerParams } from './updateCareer.model';

class UpdateCareerService {
  private static instance: UpdateCareerService;

  public static getInstance(): UpdateCareerService {
    if (!UpdateCareerService.instance) {
      UpdateCareerService.instance = new UpdateCareerService();
    }

    return UpdateCareerService.instance;
  }

  public updateCareer(params: UpdateCareerParams): Promise<UpdateCareer> {
    return updateCareerProvider(params);
  }
}

export const updateCareerService = UpdateCareerService.getInstance();
