//file upload accept types
//https://www.thoughtco.com/mime-types-by-content-type-3469108

export enum FileUploadAcceptEnum {
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  img = 'image/*',
  jpeg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+xml',
  xml = 'application/xml',
  txt = 'text/plain',
  pdf = 'application/pdf',
  html = 'text/html',
  mov = 'video/quicktime',
  mp4 = 'video/mp4',
  ico = 'image/x-icon',
  all = '*',
}
