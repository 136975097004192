import { breakpoint, fontSettings, PopupTitleStyled } from '@lerna-core';
import styled, { css } from 'styled-components';

export const MessagePopupStyled = styled.div`
  ${breakpoint(
    'smallDown',
    css`
      flex: 1 1 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: calc(70px + env(safe-area-inset-bottom));

      ${PopupTitleStyled} {
        text-align: center;
        border-bottom: none;
      }
    `
  )}
`;

export const MessagePopupStatusStyled = styled.div`
  margin: 0 auto 24px;
  text-align: center;
`;

export const MessagePopupSubtitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  text-align: center;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  white-space: pre-wrap;
`;
