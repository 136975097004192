import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconTabWithLineComponent = ({
  width = 48,
  height = 48,
  strokeWidth = '2',
  strokeColor = '#000',
  fillColor = '#000',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        strokeMiterlimit="10"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        d="M3 12h40"
      />
      <path
        fill={fillColor}
        d="M6 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      />
      <path
        fill={fillColor}
        d="M20.913 39H5c-.552 0-1-.449-1-1V8c0-.551.448-1 1-1h36c.552 0 1 .449 1 1v14.564c.704.335 1.368.725 2 1.152V8c0-1.654-1.346-3-3-3H5C3.346 5 2 6.346 2 8v30c0 1.654 1.346 3 3 3h16.931a12.848 12.848 0 0 1-1.018-2Z"
      />
      <path
        fill={fillColor}
        d="M40.2 33.2 37.4 36l2.8 2.8c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3L36 37.4l-2.8 2.8c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2.8-2.8-2.8-2.8c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.8 2.8 2.8-2.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4ZM46 36c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10Zm-2 0c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8Z"
      />
    </svg>
  );
};
