import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { LeftCourseInfoContainer } from './leftCourseInfo';
import { useCustomCompensationFormContext } from '../../customCompensationForm.context';

export const ContentLeftContainer = (): ReactElement => {
  const { course, translations } = useCustomCompensationFormContext();

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftAdvantagesContainer />
      <LeftCourseInfoContainer course={course} translations={translations} />
    </ContentLeftStyled>
  );
};
