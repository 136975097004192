export enum projectDomains {
  lernaBy = 'lerna.by',
  lernaGroup = 'lerna.group',
  lernaMe = 'lerna.me',
  lernaRu = 'lerna.ru',
  myskillsTeam = 'myskills.team',
}

export enum projectSubDomains {
  hr = 'hr',
  personal = 'personal',
  admin = 'b2b-admin',
  corp = 'corp',
  cms = 'cms',
  test = 'test',
}

export enum projectTypes {
  hr = 'hr',
  personal = 'personal',
  courses = 'courses',
}

export enum personalPath {
  courses = '/courses',
  help = '/help',
  user = '/user',
  customers = '/customers',
  orders = '/orders',
  compensations = '/compensations',
  compensationRequests = '/compensation-requests',
  userEdit = '/user/edit',
  bonuses = '/bonuses',
  register = '/register',
  gotolms = '/gotolms',
  companies = '/companies',
  referralProgram = '/referral-program',
  iframeCourses = '/iframe/[initiator]/courses',
}

export enum mainPath {
  home = '/',
  courses = '/courses',
  activateByVerifiedPhone = '/activate-by-verified-phone',
  iframeCourses = '/iframe/[initiator]/courses',
  course = '/course',
  discountProgram = '/promo/{{discount}}',
  promoFriends = '/promo/friends',
  promoFriendsPopup = '/promo/friends/?referral=true',
}

export enum corpPath {
  platform = '/platform',
}
