import styled from 'styled-components';
import { TableLoaderProps } from './tableLoader.model';
import { TableLoaderPositionsEnum } from './tableLoader.enum';

const getLoaderPositionStyles = (
  position: TableLoaderPositionsEnum
): string => {
  switch (position) {
    case TableLoaderPositionsEnum.absolute:
      return `
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      `;
    case TableLoaderPositionsEnum.sticky:
      return `
        position: sticky;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      `;
  }
};

export const TableLoaderStyled = styled.tr<TableLoaderProps>`
  ${({ position }): string =>
    position ? getLoaderPositionStyles(position) : ''}

  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(112, 96, 126, 0.04);
`;

export const TableLoaderWrapperStyled = styled.td``;
