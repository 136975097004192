import styled, { css } from 'styled-components';

import { breakpoint, positionSticky } from '../../styles';
import { HelpButtonContainerStyled } from '../buttons';
import { HeaderContainerStyledProps, HeaderStyledProps } from './header.model';

const stickyHeaderBg = (withCompany: boolean): ReturnType<typeof css> => {
  if (withCompany) {
    return css`
      background: rgb(255, 255, 255);
    `;
  }

  return css`
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    background: rgba(255, 255, 255, 0.8);
  `;
};

const getHeaderStyle = ({
  isSticky,
  withCompany,
}: HeaderContainerStyledProps): ReturnType<typeof css> => {
  if (withCompany) {
    return css`
      ${stickyHeaderBg(withCompany)};
      top: -4px;

      &:after {
        content: '';
        ${stickyHeaderBg(withCompany)};
        width: var(--scrollbar-plug-width);
        right: calc(0px - var(--scrollbar-plug-width));
        position: absolute;
        height: 100%;
        top: 0;
      }
    `;
  }

  if (!withCompany && isSticky) {
    return css`
      ${stickyHeaderBg(withCompany)};
      top: -4px;

      &:after {
        content: '';
        ${stickyHeaderBg(withCompany)};
        width: var(--scrollbar-plug-width);
        right: calc(0px - var(--scrollbar-plug-width));
        position: absolute;
        height: 100%;
        top: 0;
      }
    `;
  }

  return css``;
};

export const HeaderStyledWrapper = styled.div<HeaderContainerStyledProps>`
  padding: 20px 20px 16px;
  margin: 0 -40px;
  ${breakpoint(
    'mediumDown',
    css`
      margin: 0 -20px;
      padding: 20px 20px 16px;
    `
  )}
  height: ${({ theme }): string => (theme.IS_MOBILE ? '64px' : '86px')};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  ${positionSticky()};
  ${({ isSticky, withCompany }): ReturnType<typeof css> =>
    getHeaderStyle({ isSticky, withCompany })};
  z-index: ${(props): number => props.theme.ZINDEX.TOP_BLOCK + 2};

  ${breakpoint(
    'desktop',
    css`
      padding: 20px 40px 16px;
    `
  )}
`;

export const HeaderStyled = styled.div<HeaderStyledProps>`
  width: 100%;
  max-width: ${({ maxWidth }): number => maxWidth}px;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_CONTENT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: max-content;
`;

type HeaderButtonsContainerStyledProps = {
  isSticky: boolean;
};

const getHeaderMailButtonStyle = (
  isSticky: boolean
): ReturnType<typeof css> => {
  if (isSticky) {
    return css``;
  }

  return css`
    max-width: 100%;
  `;
};

export const HeaderButtonsContainerStyled = styled.div<HeaderButtonsContainerStyledProps>`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;

  ${HelpButtonContainerStyled} {
    justify-content: flex-end;
    display: flex;

    > * {
      white-space: nowrap;
      overflow: hidden;
      padding: 14px 13px;
      width: 54px;
      color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
      background: transparent;
      ${({ isSticky }): ReturnType<typeof css> =>
        getHeaderMailButtonStyle(isSticky)};
    }

    & svg {
      ${breakpoint(
        'mediumDown',
        css`
          margin-right: 0 !important;
          margin-left: 0 !important;
        `
      )}
    }
  }
`;
