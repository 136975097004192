import styled, { css } from 'styled-components';
import { HistogramBarStyledProps } from './histogramBars.model';

export const HistogramBarsStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 0 16px;
  align-items: flex-end;
`;

export const HistogramBarStyled = styled.div<HistogramBarStyledProps>`
  z-index: 2;
  flex: 1;
  height: ${({ height, maxValue }): ReturnType<typeof css> =>
    css`calc( ${height} * 100 / ${maxValue} * 1%)`};
  border-radius: 4px;
  background: ${({ color }): string => color};
`;

export const MarkerLinesStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

export const MarkerLineStyled = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
`;
