import { ApiDiscountModel, CurrencyModel, DiscountModel } from '../models';
import { setPriceValue } from './price.adapter';

export const discountAdapter = (
  discount: ApiDiscountModel,
  currencies: CurrencyModel[]
): DiscountModel => ({
  value: `${discount.value}%`,
  amount: setPriceValue(discount, currencies),
});
