import React, { ChangeEvent, ReactElement } from 'react';

import { SelectionCellProps } from './selectionCell.model';
import { SelectionCellStyled } from './selectionCell.styled';
import { FormCheckbox } from '../../../form';

export const SelectionCellContainer = ({
  row,
  handleCheck,
  toggleAllRowsSelected,
  isRadioButton,
}: SelectionCellProps): ReactElement => {
  const { checked, ...props } = row.getToggleRowSelectedProps();
  const onChange = (e: ChangeEvent): void => {
    if (isRadioButton) {
      toggleAllRowsSelected(false);
    }
    if (handleCheck) {
      handleCheck([row.original]);
    }
    if (props && props.onChange) {
      props.onChange(e);
    }
  };

  const onClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  return (
    <SelectionCellStyled onClick={onClick}>
      <FormCheckbox
        {...props}
        checked={checked && !row.isDisabled}
        disabled={row.isDisabled}
        onChange={onChange}
      />
    </SelectionCellStyled>
  );
};
