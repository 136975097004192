import styled from 'styled-components';

import { fontSettings } from '../../../../styles';
import { SelectButtonStyledProps } from './selectButton.model';

export const SelectButtonStyled = styled.button<SelectButtonStyledProps>`
  border: none;
  cursor: pointer;
  padding: 16px 40px 16px 20px;
  display: flex;
  align-items: center;

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  background-color: ${({ active }): string =>
    active ? 'rgba(0, 0, 0, 0.04)' : 'transparent'};
  border-radius: ${({ borderRadius }): string => `${borderRadius}px`};

  > *:not(:last-child) {
    margin-right: 8px;
  }

  &:focus-visible {
    outline: none;
  }
`;
