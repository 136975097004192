import { RequestHeader } from '../../services';
import { MenuClient } from './menu.client';
import { MenuApiSlugs } from './menu.enum';
import { MenuModel } from './menu.model';

export async function menuClientProvider(
  type: MenuApiSlugs,
  requestHeaders?: RequestHeader
): Promise<MenuModel> {
  const response = await new MenuClient(type, requestHeaders).get();

  return response as unknown as Promise<MenuModel>;
}
