import React, { ReactElement } from 'react';
import {
  EducationStreamComponent,
  EducationStreamsPopupContainer,
  getEducationStreams,
  MAX_EDUCATION_STREAMS,
} from '../../../../educationStreams';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
  ButtonSize,
} from '../../../../button';
import { IconPlusComponent } from '../../../../icons';
import { handleOpenPopup, PopupsEnum } from '../../../../popups';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from '../../../../translations';
import { FormUserEducationStreamsStyled } from './formUserEducationStreams.styled';
import { FormUserEducationStreamsComponentProps } from './formUserEducationStreams.model';

export const FormUserEducationStreamsComponent = ({
  selectedEducationStreams,
  handleSelectEducationStreams,
}: FormUserEducationStreamsComponentProps): ReactElement | null => {
  const dispatch = useDispatch();
  const { add } = useTranslations();

  const handleOpenEducationStreamsPopup = (): void => {
    dispatch(handleOpenPopup(PopupsEnum.educationStreamsPopup));
  };

  const educationStreams = useSelector(getEducationStreams);

  if (!educationStreams) {
    return null;
  }

  return (
    <>
      <FormUserEducationStreamsStyled>
        {selectedEducationStreams.map((educationStream) => (
          <EducationStreamComponent
            key={educationStream.id}
            educationStream={educationStream}
            handleSelect={handleSelectEducationStreams}
            isSelected={false}
            showClose
          />
        ))}
        {selectedEducationStreams.length < MAX_EDUCATION_STREAMS && (
          <ButtonContainer
            title={add}
            childrenPosition={ButtonChildrenPosition.left}
            size={ButtonSize.small}
            color={ButtonColor.transparent}
            borderRadius={100}
            onClick={handleOpenEducationStreamsPopup}
          >
            <IconPlusComponent width={16} height={16} />
          </ButtonContainer>
        )}
      </FormUserEducationStreamsStyled>

      <EducationStreamsPopupContainer
        educationStreams={educationStreams}
        selectedEducationStreams={selectedEducationStreams}
        handleSelectEducationStreams={handleSelectEducationStreams}
      />
    </>
  );
};
