import styled, { css, keyframes } from 'styled-components';
import { breakpoint, positionSticky, MenuSwitcherStyled } from '@lerna-core';
import { getHeaderStyle } from '../header.styled';
import {
  SearchStyled,
  SearchButtonContainerStyled,
} from '@features/common/search';
import { SearchContainerStyled } from '@features/courses/coursesSearch';
import { HeaderColorTypes } from '../header.model';
import { HeaderDesktopStyledProps } from './headerDesktop.model';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const controlBgColor = 'rgba(0, 0, 0, 0.04)';

export const HeaderDesktopContainerStyled = styled.div<HeaderDesktopStyledProps>`
  padding: 20px 0 16px;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 20px 0 16px;
    `
  )}

  ${({ hasSticky }): string =>
    hasSticky ? positionSticky() : 'position: static'};
  height: 86px;
  box-sizing: border-box;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_CONTENT};
  ${({ isSticky, colorType }): ReturnType<typeof css> =>
    getHeaderStyle(isSticky, colorType)};
  ${({ isSticky }): string => (isSticky ? 'top: -4px;' : '')}
  ${({ showSearch }): ReturnType<typeof css> =>
    showSearch
      ? css`
      
   ${HeaderDesktopStickyCenterStyled} {
     animation: ${opacity} 0.12s ease-in-out;
   }
   
   ${SearchStyled} {
    width: 400px;
   }
  }
  `
      : css``};

  ${SearchButtonContainerStyled} {
    margin-left: 8px;

    > button {
      border-radius: 50%;
      background-color: ${({ isSticky, colorType }): string =>
        isSticky || colorType === HeaderColorTypes.white ? controlBgColor : ''};
    }
  }
`;

export const HeaderDesktopContainerWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -2px;

  ${MenuSwitcherStyled} {
    margin-left: 0;
    ${breakpoint(
      'xlargeDown',
      css`
        display: none;
      `
    )}
  }
`;

export const HeaderDesktopLeftStyled = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  z-index: 200;
`;

export const HeaderDesktopButtonsContainerStyled = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  z-index: 200;

  & > button {
    white-space: nowrap;
    overflow: hidden;
    justify-content: flex-start;
    padding: 14px 13px;
    width: 54px;
    color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};

    ${breakpoint(
      'mediumDown',
      css`
        display: none;
      `
    )}
  }
`;

export const HeaderDesktopStickyCenterStyled = styled.div<HeaderDesktopStyledProps>`
  display: flex;
  margin: 0 auto;
  z-index: 200;
  
  ${({ showSearch }): ReturnType<typeof css> | string =>
    !showSearch
      ? css`
          margin: 0 0 0 auto;
          ${SearchButtonContainerStyled} {
            margin-right: 32px;
          }
        `
      : ''}

  ${SearchContainerStyled} {
    background-color: ${({ isSticky, colorType }): string =>
      isSticky || colorType === HeaderColorTypes.white ? controlBgColor : ''};
  }
  
  ${SearchStyled} {
   ${breakpoint(
     'mediumDown',
     css`
       margin: 0;
     `
   )}
}}
`;
