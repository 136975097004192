import {
  FooterApiModel,
  FooterApiResponseModel,
  FooterModel,
} from './footer.model';

export const footerApiAdapter = ({
  data,
}: FooterApiResponseModel): FooterModel => footerAdapter(data);

export const footerAdapter = ({ data }: FooterApiModel): FooterModel => {
  return {
    images: data.images.data.map((footerImage) => ({
      image: {
        backgroundColor: footerImage.image.data.background_color,
        size: footerImage.image.data.size,
        imageResource: footerImage.image.data.image_resource,
      },
      slug: footerImage.slug,
      title: footerImage.title,
      type: footerImage.type,
    })),
    socialLinks: data.socialLinks.data.map((social) => ({
      image: {
        backgroundColor: social.image.data.background_color,
        size: social.image.data.size,
        imageResource: social.image.data.image_resource,
      },
      slug: social.slug,
      title: social.title,
      type: social.type,
      url: social.url,
    })),
  };
};
