import { FilterOptionsTypes } from '../filterPopup.model';
import { hasSelectedOption } from './popupBody.helper';
import {
  FilterSelectOptionPropsModel,
  FilterSelectPropsModel,
} from './popupBody.model';

const handleCheckboxSelect = ({
  selected,
  setSelected,
  optionId,
}: FilterSelectOptionPropsModel): void => {
  const isSelected = hasSelectedOption(selected, optionId);

  if (isSelected) {
    setSelected(selected.filter((opt) => opt !== optionId));
  } else {
    setSelected([...selected, optionId]);
  }
};

const handleRadioSelect = ({
  selected,
  setSelected,
  optionId,
}: FilterSelectOptionPropsModel): void => {
  const isSelected = hasSelectedOption(selected, optionId);

  isSelected ? setSelected([]) : setSelected([optionId]);
};

export const handleSelectOption = ({
  optionsType,
  ...props
}: FilterSelectPropsModel): void => {
  switch (optionsType) {
    case FilterOptionsTypes.checkbox:
      return handleCheckboxSelect(props);
    case FilterOptionsTypes.radio:
      return handleRadioSelect(props);
  }
};
