import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useRef,
} from 'react';

import { useTheme } from '../../../../hooks';
import { IconCloseComponent } from '../../../icons';
import { OnResetProps } from './input.model';
import { IconButtonStyled, InputContainerStyled } from './input.styled';
import { Nullable } from '../../../../models';

export interface InputContainerProps extends OnResetProps {
  children: ReactElement | ReactElement[];
  showIcon?: boolean;
  name: string;
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  setFieldValue?: (field: string, value: string) => void;
  hintButton?: Nullable<ReactElement>;
}

export const InputContainer = ({
  children,
  showIcon = false,
  onReset,
  name,
  inputRef,
  setFieldValue,
  hintButton,
}: InputContainerProps): ReactElement => {
  const defaultRef = useRef<HTMLInputElement>();
  const ref = inputRef || defaultRef;
  const theme = useTheme();

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (onReset) {
        onReset(name, '');
      }
      if (ref?.current) {
        ref.current.focus();
        setFieldValue && setFieldValue(name, '');
      }
    },
    [name, ref]
  );

  return (
    <InputContainerStyled>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ref })
      )}

      {showIcon && (
        <IconButtonStyled onClick={handleReset} type="button" tabIndex={-1}>
          <IconCloseComponent
            width={8}
            height={8}
            strokeWidth={'4'}
            strokeColor={theme.COLOR.WHITE}
          />
        </IconButtonStyled>
      )}
      {hintButton}
    </InputContainerStyled>
  );
};
