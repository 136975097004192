import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Nullable } from '../../../../models';

export const useIsDropdownOpenBottom = (
  filterDropdownRef: MutableRefObject<Nullable<HTMLDivElement>>,
  directionOpenTop: boolean,
  dropdownHeight: number
): boolean => {
  const [isOpenTop, setIsOpenTop] = useState<boolean>(directionOpenTop);

  const checkIsCanOpenBottom = useCallback(() => {
    if (!filterDropdownRef.current) return;

    const hasSpaceBottom =
      window.innerHeight -
        filterDropdownRef.current.getBoundingClientRect().bottom >
      dropdownHeight + 8;
    // 8 это оступ между селектом и дропдауном

    if (hasSpaceBottom) {
      setIsOpenTop(false);
    } else {
      setIsOpenTop(true);
    }
  }, [filterDropdownRef, dropdownHeight]);

  useEffect(() => {
    checkIsCanOpenBottom();

    window.addEventListener('resize', checkIsCanOpenBottom);
    window.addEventListener('scroll', checkIsCanOpenBottom);

    return (): void => {
      window.removeEventListener('resize', checkIsCanOpenBottom);
      window.removeEventListener('scroll', checkIsCanOpenBottom);
    };
  }, [filterDropdownRef]);

  return isOpenTop;
};
