import React, { ReactElement, useMemo } from 'react';
import Head from 'next/head';
import { HeadSeoMetaTagsContainerProps } from './headSeoMetaTags.model';
import { objectService } from '../../services/objectService';
import { publicConfig, stringService } from '../../services';
import { MetaTagsEnum, Nullable } from '../../models';

export const HeadSeoMetaTagsContainer = ({
  seo,
}: HeadSeoMetaTagsContainerProps): Nullable<ReactElement> => {
  const hasSeoData = useMemo(
    () => !!seo && !objectService.isEmptyObject(seo || {}),
    []
  );
  const pageTitle = useMemo(() => {
    if (hasSeoData) {
      return seo?.title;
    }

    return stringService.capitalizeFirstLetter(publicConfig.APP_NAME);
  }, []);

  return (
    <Head>
      {publicConfig?.NOINDEX === 'true' && (
        <meta name="robots" content="noindex" />
      )}
      <title key={MetaTagsEnum.title}>{pageTitle}</title>
      {!!seo?.description && (
        <meta
          key={MetaTagsEnum.description}
          name="description"
          content={seo.description}
        />
      )}
      {!!seo?.keywords && (
        <meta
          key={MetaTagsEnum.description}
          name="keywords"
          content={seo.keywords}
        />
      )}
    </Head>
  );
};
