import { createSelector } from 'reselect';
import { SubscriptionsState } from './subscriptions.model';
import { RootState } from '../../models';

export const getSubscriptionsSelector = createSelector(
  (state: RootState<SubscriptionsState>) => state.subscriptions,
  (reducer: SubscriptionsState) => reducer.subscriptions
);

export const getSubscriptionsStatusSelector = createSelector(
  (state: RootState<SubscriptionsState>) => state.subscriptions,
  (reducer: SubscriptionsState) => reducer.status
);

export const getSubscriptionsRequestParamsSelector = createSelector(
  (state: RootState<SubscriptionsState>) => state.subscriptions,
  (reducer: SubscriptionsState) => reducer.requestParams
);
