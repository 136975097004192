import styled from 'styled-components';
import { rgba } from 'polished';
import { fontSettings } from '../../../../styles';

export const CellCourseStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CellCourseTitleLinkStyled = styled.a`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  text-decoration: none;

  &:active,
  &:visited {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const CellCourseTitleStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  text-decoration: none;
`;

export const CellCourseStatusStyled = styled.div`
  background: ${(props): string =>
    rgba(props.theme.COLOR.TEXT_DARK_COLOR, 0.04)};
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 600;
  max-width: max-content;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)};
`;
