import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconErrorComponent = ({
  width = 80,
  height = 80,
  fillColor = '#FF809B',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40.5" cy="40" r="40" fill="white" />
      <g filter="url(#filter0_ddii_2201_107864)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.5131 56.0116C57.8641 54.6606 57.8641 52.4703 56.5131 51.1194L45.5053 40.1116L56.5116 29.1053C57.8625 27.7544 57.8625 25.5641 56.5116 24.2131C55.1606 22.8622 52.9703 22.8622 51.6194 24.2131L40.6131 35.2194L29.6062 24.2124C28.2552 22.8615 26.0649 22.8615 24.714 24.2124C23.3631 25.5634 23.3631 27.7537 24.714 29.1046L35.721 40.1116L24.7124 51.1201C23.3615 52.471 23.3615 54.6614 24.7124 56.0123C26.0634 57.3632 28.2537 57.3632 29.6046 56.0123L40.6131 45.0037L51.621 56.0116C52.9719 57.3625 55.1622 57.3625 56.5131 56.0116Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_ddii_2201_107864"
          x="23.2451"
          y="20.9288"
          width="34.7363"
          height="38.3689"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.908149" />
          <feGaussianBlur stdDeviation="0.227037" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2201_107864"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.908149" />
          <feGaussianBlur stdDeviation="0.227037" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2201_107864"
            result="effect2_dropShadow_2201_107864"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2201_107864"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.27037" />
          <feGaussianBlur stdDeviation="1.13519" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.870833 0 0 0 0 0.326563 0 0 0 0 0.442274 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_2201_107864"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.27037" />
          <feGaussianBlur stdDeviation="1.13519" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.6625 0 0 0 0 0.734252 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_2201_107864"
            result="effect4_innerShadow_2201_107864"
          />
        </filter>
      </defs>
    </svg>
  );
};
