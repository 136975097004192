import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { rgba } from 'polished';

export const CourseFormButtonContainerStyled = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  box-shadow: 0 0 12px ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
  border-radius: 16px;
  padding: 20px;

  button {
    width: 100%;
  }

  ${breakpoint(
    'xlargeDown',
    css`
      padding: 0;
    `
  )}
`;
