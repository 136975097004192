import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  EVENT_VALUE,
  getTextMaxLengthValidationMessage,
  getTextMinLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
  orderFormPushDataLayer,
} from '@features/common/forms';
import {
  CompensationFormInitialValues,
  CompensationFormValidationSchema,
} from './compensationForm.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';

export const getCompensationFormInitialValues =
  (): CompensationFormInitialValues => ({
    withPromoCode: false,
    promoCode: '',
    comment: '',
  });

export function getCompensationFormValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean,
  isCommentPopupOpened: boolean,
  isCommentNecessary = true
): CompensationFormValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected && {
      withPromoCode: Yup.boolean().optional(),
      promoCode: Yup.string().when('withPromoCode', {
        is: true,
        then: (promoCode) =>
          promoCode
            .required(translations?.input_required_error_label)
            .max(255, getTextMaxLengthValidationMessage(255, translations))
            .trim(),
      }),
    }),
    ...(isCommentPopupOpened && {
      comment: Yup.string().when({
        is: () => isCommentNecessary,
        then: (comment) =>
          comment
            .required(translations?.input_required_error_label)
            .min(5, getTextMinLengthValidationMessage(5, translations))
            .max(1000, getTextMaxLengthValidationMessage(1000, translations))
            .trim(),
        otherwise: Yup.string()
          .notRequired()
          .max(1000, getTextMaxLengthValidationMessage(1000, translations)),
      }),
    }),
  };

  return Yup.object().shape(validationObject);
}

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  withCompensation: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = [OrderFormEventsValues.addOnCompensation];
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  switch (true) {
    case withCompensation:
      eventValueOptions.push(OrderFormEventsValues.noTabs);
      eventValueOptions.push(OrderFormEventsValues.withCompensation);
      break;
    default:
      eventValueOptions.push(
        showPaymentTabs
          ? OrderFormEventsValues.withTabs
          : OrderFormEventsValues.noTabs
      );
      switch (paymentContent) {
        case UserOrderFormPaymentContentTypes.consultation:
          eventValueOptions.push(OrderFormEventsValues.consultation);
          break;
        case UserOrderFormPaymentContentTypes.onlinePay:
          eventValueOptions.push(OrderFormEventsValues.paymentOnline);
          break;
      }
      break;
  }

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};

export const pushCompensationFormPaymentEvent = (
  course: CourseModel,
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  const eventValueOptions = [
    OrderFormEventsValues.addOnCompensation,
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
    OrderFormEventsValues.paymentOnline,
  ];

  const event = `${OrderFormEvents.formEnrollGoToOnlinePay.replace(
    EVENT_VALUE,
    eventValueOptions.join(',')
  )}`;

  orderFormPushDataLayer(
    event,
    CommonEventTypes.coursesEvents,
    eventPosition,
    course
  );
};

export const pushCompensationFormNextButtonEvent = (
  course: CourseModel,
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  orderFormEvent(
    `${OrderFormEvents.formEnrollCourseClickButtonNext}`,
    [
      OrderFormEventsValues.addOnCompensation,
      showPaymentTabs
        ? OrderFormEventsValues.withTabs
        : OrderFormEventsValues.noTabs,
      OrderFormEventsValues.withCompensation,
    ],
    CommonEventTypes.coursesEvents,
    eventPosition,
    course
  );
};
