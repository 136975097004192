import { css } from 'styled-components';
import { rgba } from 'polished';

export const customizeScrollBar = (
  topMargin = 0,
  bottomMargin = 0,
  width = 4,
  addOverflow = true
): ReturnType<typeof css> => css`
  ${addOverflow ? 'overflow-y: auto;' : ''}

  &::-webkit-scrollbar {
    background-color: transparent;
    width: ${width}px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* scrollbar itself */

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.16)};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track-piece:start {
    margin-top: ${topMargin}px;
  }

  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: ${bottomMargin}px;
  }
`;
