import styled, { css } from 'styled-components';

import { fontSettings } from '../../styles';
import {
  PaginationButtonDirection,
  PaginationButtonDirections,
} from './pagination.model';

interface PageStyledProps {
  active?: boolean;
  onClick?: () => void;
}

interface PaginationButtonStyledProps {
  direction: PaginationButtonDirection;
  disabled: boolean;
}

export const PaginationStyled = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const PageStyled = styled.div<PageStyledProps>`
  width: 40px;
  height: 40px;
  cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 50%;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};

  ${({ active, theme }): string =>
    active
      ? `
      background-color: ${theme.COLOR.PURPLE};
      color: ${theme.COLOR.WHITE};
    `
      : ''};

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const getDirectionStyles = (
  direction: PaginationButtonDirection
): ReturnType<typeof css> => {
  if (direction === PaginationButtonDirections.left) {
    return css`
      margin-right: 16px;
    `;
  } else {
    return css`
      margin-left: 16px;

      & > svg {
        transform: rotate(180deg);
      }
    `;
  }
};

export const PaginationButtonStyled = styled.button<PaginationButtonStyledProps>`
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;

  ${({ direction }): ReturnType<typeof css> => getDirectionStyles(direction)}
`;
