import { publicConfig } from '../../../services';
import { RegionsEnum, TranslationModel } from '../../../models';
import {
  FormAgreementTreeValidationSchemaModel,
  FormAgreementTreeValuesModel,
  getFormAgreementTreeInitialValues,
  getFormAgreementTreeValidationSchema,
} from '../formAgreementTree';
import * as Yup from 'yup';
import { FormAgreementValidationSchemaModel } from './formAgreement.model';

export const isRuRegion = () => publicConfig.REGION === RegionsEnum.ru;

export const getFormAgreementInitialValues = ():
  | FormAgreementTreeValuesModel
  | { advConsent: boolean } => {
  if (isRuRegion()) {
    return getFormAgreementTreeInitialValues();
  }

  return { advConsent: false };
};

export const getFormAgreementValidationSchema = (
  translations: TranslationModel
):
  | FormAgreementTreeValidationSchemaModel
  | FormAgreementValidationSchemaModel => {
  if (isRuRegion()) {
    return getFormAgreementTreeValidationSchema(translations);
  }

  return {
    advConsent: Yup.boolean().oneOf(
      [true, false],
      translations?.input_required_error_label
    ),
    oferta: Yup.boolean().notRequired(),
  };
};
