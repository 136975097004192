import { CustomerCoursesResponseModel } from './customerCourses.model';
import {
  CourseItemApiModel,
  CourseItemModel,
  mapCourseItem,
} from '../../courses';

const coursesCountAdapter = (courses: CourseItemApiModel[]): number =>
  courses.length;

export const customerCoursesCountAdapter = (
  response: CustomerCoursesResponseModel
): number => {
  return (
    coursesCountAdapter(response.corporate) +
    coursesCountAdapter(response.personal)
  );
};

export const customerCoursesAdapter = (
  data: CustomerCoursesResponseModel
): CourseItemModel[] =>
  data.corporate.map(mapCourseItem).concat(data.personal.map(mapCourseItem));
