import styled from 'styled-components';

import { fontSettings } from '../../../../styles';

export const PopupTitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_28, props.theme.FONT)};
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
`;
