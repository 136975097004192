import React, { ReactElement } from 'react';
import { ResetShowMoreItemStyled } from './resetShowMoreItem.styled';
import { IconDotsComponent } from '@lerna-core';
import { ResetShowMoreItemContainerProps } from './resetShowMoreItem.model';

export const ResetShowMoreItemContainer = ({
  handleClick,
}: ResetShowMoreItemContainerProps): ReactElement => (
  <ResetShowMoreItemStyled onClick={handleClick}>
    <IconDotsComponent />
  </ResetShowMoreItemStyled>
);
