import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../styles';
import { getPlacement } from './onboardingPopup.mixins.styled';
import {
  FooterPaginationStyledProps,
  OnboardingPopupStyledProps,
  PaginationItemStyledProps,
} from './onboardingPopup.model';

export const OnboardingPopupStyled = styled.div<OnboardingPopupStyledProps>`
  z-index: 10001;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 356px;
  height: max-content;
  padding: 24px;
  gap: 32px;
  border-radius: 16px;
  background: ${({ theme }): string => theme.COLOR.WHITE};

  transition: all 0.3s;

  &:after {
    content: ' ';
    position: absolute;
    width: 16px;
    height: 16px;
    background: ${({ theme }): string => theme.COLOR.WHITE};
  }

  ${({ popupProperties, placement, targetProperties, gap }) =>
    breakpoint(
      'mediumUp',
      css`
        ${getPlacement(popupProperties, targetProperties, gap, placement)}
      `
    )};

  ${breakpoint(
    'mediumDown',
    css`
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      border-radius: 24px 24px 0 0;
      padding: 48px 20px 20px 20px;

      &:after {
        display: none;
      }
    `
  )}
`;

export const PopupCloseStyled = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;

  ${breakpoint(
    'mediumDown',
    css`
      top: 20px;
      right: 20px;
    `
  )}
`;

export const PopupContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentMessageStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT['900']};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  font-weight: 400;
  text-align: left;
  white-space: pre-wrap;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    `
  )}
  b {
    font-weight: 500;
    background: linear-gradient(73.03deg, #ee2c72 7.89%, #4c07df 48.26%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const PopupFooterStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  svg {
    transform: rotate(90deg);
  }
`;

export const FooterPaginationStyled = styled.div<FooterPaginationStyledProps>`
  display: grid;
  grid-template-columns: repeat(${({ stepsCount }) => stepsCount}, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  gap: 8px;
`;

export const PaginationItemStyled = styled.div<PaginationItemStyledProps>`
  height: 4px;
  border-radius: 100px;
  background: ${({ theme, isActive }): string =>
    isActive ? theme.COLOR.PRIMARY['500'] : theme.COLOR.BLACK_08};
`;
