import { TooltipProps } from './tooltip.model';
import React, { MutableRefObject, ReactElement, useRef } from 'react';
import { TooltipContainerStyled, TooltipStyled } from './tooltip.styled';
import { useTooltipPosition, useTooltipToggling } from './tooltip.hook';
import { TooltipArrowComponent } from './tooltipArrow';

export const TooltipComponent = ({
  children,
  title,
  cursorType = 'default',
  className,
}: TooltipProps): ReactElement => {
  const tooltipRef = useRef<HTMLDivElement>();
  const { position, arrowPlacements, handleMove } =
    useTooltipPosition(tooltipRef);
  const { isOpen, handleOpen, handleClose } = useTooltipToggling();

  return (
    <TooltipContainerStyled
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onMouseMove={handleMove}
      cursorType={cursorType}
      className={className}
    >
      {children}
      {isOpen && (
        <TooltipStyled
          ref={tooltipRef as MutableRefObject<HTMLDivElement>}
          isOpen={isOpen}
          top={position.top}
          left={position.left}
        >
          <TooltipArrowComponent
            verticalPlacement={arrowPlacements.vertical}
            horizontalPlacement={arrowPlacements.horizontal}
          />
          {title}
        </TooltipStyled>
      )}
    </TooltipContainerStyled>
  );
};
