import React, { ReactElement } from 'react';
import { GenericInputContainer } from '../../inputs';
import { TableSearchProps } from './tableSearch.model';
import {
  TableSearchCancelStyled,
  TableSearchInputStyled,
  TableSearchStyled,
} from './tableSearch.styled';

export const TableSearchContainer = ({
  search,
  placeholder,
  onChange,
  name = 'table_search',
  cancelText,
  onCancelClick,
  className,
}: TableSearchProps): ReactElement => {
  return (
    <TableSearchStyled className={className}>
      <TableSearchInputStyled>
        <GenericInputContainer
          initialValue={search}
          placeholder={placeholder}
          onChange={onChange}
          clearControl
          name={name}
          autoComplete="off"
          autoFocus
        />
      </TableSearchInputStyled>
      <TableSearchCancelStyled onClick={onCancelClick}>
        {cancelText}
      </TableSearchCancelStyled>
    </TableSearchStyled>
  );
};
