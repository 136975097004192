import {
  ApiCashBackModel,
  CurrencyModel,
  TranslationModel,
} from '../../../models';
import { ApiCoursePricesModel, CoursePricesModel } from './coursePrices.model';
import {
  installmentAdapter,
  mapCashBack,
  priceCustomerAdapter,
  priceWithDiscountAdapter,
  priceWithProgramDiscountAdapter,
  priceWithPromoCodeDiscountAdapter,
  setPriceValue,
} from '../../../adapters';

export const coursePricesAdapter = (
  priceData: ApiCoursePricesModel,
  currencies: CurrencyModel[],
  translations: TranslationModel,
  bonuses?: ApiCashBackModel
): CoursePricesModel => {
  const price: CoursePricesModel = {
    price: priceWithDiscountAdapter(priceData.price, currencies),
    startPrice: {
      amount: setPriceValue(priceData.start_price, currencies),
      currency: priceData.start_price.currency,
    },
    startPriceCustomer: priceData.start_price_customer
      ? {
          amount: setPriceValue(priceData.start_price_customer, currencies),
          currency: priceData.start_price_customer.currency,
        }
      : null,
    startPriceCustomerB2B: priceData.start_price_customer_b2b
      ? {
          amount: setPriceValue(priceData.start_price_customer_b2b, currencies),
          currency: priceData.start_price_customer_b2b.currency,
        }
      : null,
    startPriceCustomerB2B2C: priceData.start_price_customer_b2b2c
      ? {
          amount: setPriceValue(
            priceData.start_price_customer_b2b2c,
            currencies
          ),
          currency: priceData.start_price_customer_b2b2c.currency,
        }
      : null,
    priceWithVendorDiscount: !!priceData?.price_with_vendor_discount
      ? priceWithDiscountAdapter(
          priceData.price_with_vendor_discount,
          currencies
        )
      : null,
    priceWithBonuses: !!priceData?.price_with_bonuses
      ? priceWithDiscountAdapter(priceData.price_with_bonuses, currencies)
      : null,
    priceWithProgramDiscount: !!priceData?.price_with_program_discount
      ? priceWithProgramDiscountAdapter(
          priceData.price_with_program_discount,
          currencies
        )
      : null,
    priceWithPromoCodeDiscount: !!priceData?.price_with_promo_code_discount
      ? priceWithPromoCodeDiscountAdapter(
          priceData.price_with_promo_code_discount,
          currencies
        )
      : null,
    installment: !!priceData?.installment?.duration
      ? installmentAdapter(priceData.installment, currencies, translations)
      : null,
    priceCustomer: !!priceData?.price_customer
      ? priceCustomerAdapter(priceData.price_customer, currencies, translations)
      : null,
    priceCustomerB2B: !!priceData?.price_customer_b2b
      ? priceCustomerAdapter(
          priceData.price_customer_b2b,
          currencies,
          translations
        )
      : null,
    priceCustomerB2B2C: !!priceData?.price_customer_b2b2c
      ? priceCustomerAdapter(
          priceData.price_customer_b2b2c,
          currencies,
          translations
        )
      : null,
  };

  if (bonuses) {
    price.cashBack = mapCashBack(bonuses);
  }

  return price;
};
