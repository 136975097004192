import { RegisterPositions } from '../../../register';
import { MenuSwitcherEvents } from '../menuSwitcher.analytics';

export const getSwitcherItemRegistrationPosition = (
  itemEvent: MenuSwitcherEvents
): RegisterPositions => {
  switch (itemEvent) {
    case MenuSwitcherEvents.goToHR:
      return RegisterPositions.hrPage;
    case MenuSwitcherEvents.goToPersonal:
      return RegisterPositions.personalPage;
    case MenuSwitcherEvents.goToCatalog:
      return RegisterPositions.catalogPage;
  }
};
