import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconStairsComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.75 3h-2a.75.75 0 0 0-.75.75V6h-2.25a.75.75 0 0 0-.75.75V9H9.75a.75.75 0 0 0-.75.75V12H6.75a.75.75 0 0 0-.75.75V15H3.75a.75.75 0 0 0-.75.75v2A3.254 3.254 0 0 0 6.25 21h11.5A3.254 3.254 0 0 0 21 17.75V6.25A3.254 3.254 0 0 0 17.75 3Zm-.25 12.25a.75.75 0 1 1-1.5 0v-1.19l-3.22 3.22a.746.746 0 0 1-1.06 0 .75.75 0 0 1 0-1.06L14.94 13h-1.19a.75.75 0 1 1 0-1.5h3a.75.75 0 0 1 .75.75v3Z"
        fill={fillColor}
      />
    </svg>
  );
};
