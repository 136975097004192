import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const FasttrackControlButtonWrapperStyled = styled.div`
  position: relative;
  max-width: max-content;
  margin-bottom: 24px;
`;
export const FasttrackControlButtonStyled = styled.div`
  text-align: center;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: 12px 20px;
  background-color: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    transform: rotate(-90deg);
  }
`;
