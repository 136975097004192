import { ShowcaseConstants } from './showcase.constants';
import { ShowcaseActions, ShowcaseReducerState } from './showcase.model';

const defaultState: ShowcaseReducerState = {
  status: {
    isLoaded: false,
    isLoading: false,
  },
};

export const showcaseReducer = (
  state: ShowcaseReducerState = defaultState,
  action: ShowcaseActions
): ShowcaseReducerState => {
  switch (action.type) {
    case ShowcaseConstants.SET_SHOWCASE_STATUS: {
      return {
        ...state,
        status: { ...state.status, ...action.status },
      };
    }
    default: {
      return state;
    }
  }
};
