import * as constants from './userPosition.constants';
import {
  UserPositionsActions,
  UserPositionsState,
  UserPositionWithOptions,
} from './userPosition.model';

const initialState: UserPositionsState = {
  positions: [],
  isLoading: false,
  isLoaded: false,
};

export const userPositionReducer = (
  state: UserPositionsState = initialState,
  action: UserPositionsActions
): UserPositionsState => {
  switch (action.type) {
    case constants.REQUEST_USER_POSITIONS_START:
      return { ...state, isLoading: true };

    case constants.REQUEST_USER_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: action.payload as UserPositionWithOptions[],
        isLoading: false,
        isLoaded: true,
      };

    case constants.REQUEST_USER_POSITIONS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
