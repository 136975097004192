import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconGoogleComponent = ({
  width = 24,
  height = 24,
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.01" width="24" height="24" fill="white" />
    <path
      d="M5.319 14.5035L4.4835 17.6222L1.43025 17.6868C0.5175 15.9943 0 14.0578 0 12C0 10.0101 0.48375 8.13365 1.34175 6.48132H1.3425L4.0605 6.9797L5.2515 9.6818C5.0025 10.4084 4.86675 11.1884 4.86675 12C4.86675 12.8809 5.0265 13.7249 5.319 14.5035Z"
      fill="#FBBB00"
    />
    <path
      d="M23.7899 9.7583C23.9279 10.4842 23.9999 11.2339 23.9999 12.0001C23.9999 12.8592 23.9099 13.6972 23.7374 14.5056C23.1532 17.2564 21.6269 19.6583 19.5127 21.3581L19.5119 21.3574L16.0882 21.1827L15.6037 18.1576C17.0062 17.3348 18.1027 16.0471 18.6802 14.5056H12.2639V9.7583H18.7739H23.7899Z"
      fill="#518EF8"
    />
    <path
      d="M19.5119 21.3573L19.5127 21.358C17.4562 23.0109 14.8439 24 11.9999 24C7.43018 24 3.45668 21.4457 1.43018 17.6868L5.31893 14.5035C6.33218 17.2081 8.94143 19.1334 11.9999 19.1334C13.3147 19.1334 14.5462 18.778 15.6029 18.1575L19.5119 21.3573Z"
      fill="#28B446"
    />
    <path
      d="M19.6598 2.76262L15.7718 5.94525C14.6783 5.26155 13.3853 4.86653 12 4.86653C8.87255 4.86653 6.21455 6.8802 5.25155 9.68175L1.34255 6.48128H1.3418C3.33905 2.63077 7.36205 0 12 0C14.9115 0 17.5815 1.03715 19.6598 2.76262Z"
      fill="#F14336"
    />
  </svg>
);
