import React, { ReactElement } from 'react';
import { useTheme } from '../../../hooks';
import { ButtonColor, ButtonContainer } from '../../button';
import { IconErrorComponent, IconSuccessSentComponent } from '../../icons';
import {
  FormPopupSubmitButtonContainerStyled,
  FormPopupSubTitle,
} from '../formPopup';
import { PopupTitleContainer } from '../popup';
import {
  MessagePopupComponentProps,
  MessagePopupStatus,
} from './messagePopup.model';
import { MessagePopupStyled } from './messagePopup.styled';

export const MessagePopupComponent = ({
  title,
  subtitle,
  buttonTitle,
  buttonCallback,
  status,
}: MessagePopupComponentProps): ReactElement => {
  const hasButton = buttonCallback && buttonTitle;
  const theme = useTheme();

  const icon =
    status &&
    (status === MessagePopupStatus.success ? (
      <IconSuccessSentComponent
        width={48}
        height={48}
        fillColor={theme.COLOR.PRIMARY['500']}
        strokeColor={theme.COLOR.PRIMARY['500']}
      />
    ) : (
      <IconErrorComponent
        width={48}
        height={48}
        fillColor={theme.COLOR.BLACK}
      />
    ));

  return (
    <MessagePopupStyled>
      {icon}

      <PopupTitleContainer text={title} />

      {subtitle && typeof subtitle === 'string' ? (
        <FormPopupSubTitle text={subtitle} />
      ) : (
        <FormPopupSubTitle text={undefined}>
          {subtitle as ReactElement}
        </FormPopupSubTitle>
      )}
      {hasButton && (
        <FormPopupSubmitButtonContainerStyled>
          <ButtonContainer
            onClick={buttonCallback}
            color={ButtonColor.purple}
            borderRadius={100}
            fullWidth
            title={buttonTitle || ''}
          />
        </FormPopupSubmitButtonContainerStyled>
      )}
    </MessagePopupStyled>
  );
};
