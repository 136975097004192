import styled from 'styled-components';

import { customizeScrollBar } from '../../../../../styles';
import { SelectOptionContainerStyled } from '../select.styled';

type SelectDropdownStyledProps = {
  bordered?: boolean;
  directionOpenTop?: boolean;
  search?: boolean;
  dropdownHeight?: number;
};

export const SelectDropdownStyled = styled.div<SelectDropdownStyledProps>`
  left: -1px;
  right: -1px;
  max-height: ${({ dropdownHeight }): string =>
    dropdownHeight ? `${dropdownHeight}px` : '270px'};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 0;
  border-radius: 16px;
  background-color: ${(props): string => props.theme.COLOR.WHITE};
  z-index: ${(props): string => props.theme.ZINDEX.TOP_MODULE};
  cursor: default;
  box-sizing: border-box;

  ${({ directionOpenTop }): string =>
    directionOpenTop
      ? `bottom: calc(100% + 8px);
         box-shadow: 0 -5px 25px -5px rgba(12, 11, 13, 0.16);`
      : `top: calc(100% + 8px);
         border-top: none;
         box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);`}

  ${({ search }): string =>
    search
      ? `${SelectOptionContainerStyled} {
         &:first-child {
           border-top: none;
         }
       }`
      : ''}
`;

export const SelectDropdownInnerStyled = styled.div`
  ${customizeScrollBar(0, 0)}
`;
