import React, { ReactElement, useCallback } from 'react';
import { BackLinkWithWarningProps } from './backLinkWithWarning.model';
import { WarningPopupContainer } from '../warningPopup';
import { BackLinkWithWarningStyled } from './backLinkWithWarning.styled';
import { useToggle } from '../../hooks';
import { IconBackComponent } from '../icons';

export const BackLinkWithWarningContainer = ({
  handleBack,
  withWarning = false,
  backText,
  popupTitle,
  popupText,
  popupWarning,
  leftButtonTitle,
  rightButtonTitle,
  isChanging,
}: BackLinkWithWarningProps): ReactElement => {
  const [isOpened, toggleIsOpened] = useToggle();

  const handleClick = useCallback(() => {
    if (withWarning) {
      toggleIsOpened();
    } else {
      handleBack();
    }
  }, [withWarning, handleBack]);

  return (
    <>
      <BackLinkWithWarningStyled onClick={handleClick}>
        <IconBackComponent />
        {backText}
      </BackLinkWithWarningStyled>

      <WarningPopupContainer
        isOpened={isOpened}
        handleClose={toggleIsOpened}
        handleRightButton={handleBack}
        popupTitle={popupTitle}
        popupText={popupText}
        popupWarning={popupWarning}
        leftButtonTitle={leftButtonTitle}
        rightButtonTitle={rightButtonTitle}
        isChanging={isChanging}
      />
    </>
  );
};
