import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import { ListItemStyled } from '../../../courses/coursesList/listItem/listItem.styled';
import { SimilarItemContainerStyled } from '../../courseSimilar';
import { GalleryItemContainerStyledProps } from './galleryItem.model';

export const GalleryItemContainerStyled = styled.div<GalleryItemContainerStyledProps>`
  width: 100%;
  height: 100%;
  position: relative;

  ${({ isMobile, spaceBetweenItems }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        width: calc(100vw - ${spaceBetweenItems}px - 40px - 20px);
        max-width: 570px;
      `,
      isMobile
    )}
  ${SimilarItemContainerStyled} {
    flex: 1 1 100%;
    max-width: 100%;
  }

  ${ListItemStyled} {
    width: 100%;
    box-shadow: 0px 5px 25px -5px rgb(12 11 13 / 4%);
    margin: 0;
    flex: 1 1 100%;
    max-width: 100%;
    height: 100%;
  }
`;
