import styled from 'styled-components';
import { rgba } from 'polished';
import { SearchStyledProps } from './selectSearch.model';

export const SelectSearchStyled = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid
    ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.12)};
`;

export const SearchStyled = styled.div<SearchStyledProps>`
  position: relative;
  box-sizing: border-box;

  input {
    color: ${({ color }): string | undefined => color};
    padding-left: 52px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const SearchIconStyled = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

export const SearchMagnifierStyled = styled(SearchIconStyled)`
  width: 24px;
  height: 24px;
`;
