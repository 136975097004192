import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { FloatingPlaceholderStyled } from '../../form';
import { rgba } from 'polished';

export const ChipsInputStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

type WrapperStyledProps = {
  error?: boolean;
};

export const WrapperStyled = styled.div<WrapperStyledProps>`
  height: 190px;
  border-radius: 16px;
  border: 1px solid ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.12)};
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: border-color 0.15s ease-in-out;

  ${({ theme, error }): string => `
    ${error ? `border-color: ${theme.COLOR.INFO_RED_COLOR}` : ''};
  
    :focus-within,
    :focus,
    :hover {
      border-color: ${
        error ? theme.COLOR.INFO_RED_COLOR : theme.COLOR.TEXT_LIGHT_COLOR
      };
    }
  `}

  &:focus-within ${FloatingPlaceholderStyled} {
    top: -1px;
    font-size: 13px;
  }
`;

export const InnerStyled = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ChipsWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -6px -6px 3px;
`;

export const TextAreaStyled = styled.textarea`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  flex: 1 1 auto;
  resize: none;
  overflow: hidden;
  font-weight: 500;
  min-height: 40px;
`;
