import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import {
  CourseLogoInnerLabelPropsModel,
  CourseLogoLabelPropsModel,
} from './courseLogoLabel.model';

export const CourseLogoLabelStyled = styled.div<CourseLogoLabelPropsModel>`
  padding: 2px;
  background: ${({ color }) => color};
  font-weight: 700;
  border-radius: 4px;
  white-space: nowrap;
`;

export const CourseLogoLabelInnerStyled = styled.div<CourseLogoInnerLabelPropsModel>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  display: flex;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_10, theme.FONT)};
  text-transform: uppercase;
  padding: 4px;
  border-radius: 4px;
`;
