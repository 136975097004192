import React, { ReactElement } from 'react';
import {
  CourseItemProgressItemPercentStyled,
  CourseItemProgressItemStyled,
  CourseItemProgressItemTextStyled,
} from './courseItemProgressItem.styled';
import { CourseItemProgressItemProps } from './courseItemProgressItem.model';

export const CourseItemProgressItemComponent = ({
  progressItem,
  isMobile,
}: CourseItemProgressItemProps): ReactElement | null => {
  if (!progressItem?.count) {
    return null;
  }

  return (
    <CourseItemProgressItemStyled isMobile={isMobile}>
      <CourseItemProgressItemTextStyled>
        {progressItem?.title}:
      </CourseItemProgressItemTextStyled>
      <CourseItemProgressItemPercentStyled>
        {progressItem?.countDone}/{progressItem?.count}
      </CourseItemProgressItemPercentStyled>
    </CourseItemProgressItemStyled>
  );
};
