import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconDesktopComponent = ({
  width = 16,
  height = 16,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      fill={fillColor}
      d="M1.99996 1.33398C1.26329 1.33398 0.666626 1.93065 0.666626 2.66732V10.6673C0.666626 11.404 1.26329 12.0007 1.99996 12.0007H6.66663V13.334H5.99996C5.63196 13.334 5.33329 13.6327 5.33329 14.0007C5.33329 14.3687 5.63196 14.6673 5.99996 14.6673H9.99996C10.368 14.6673 10.6666 14.3687 10.6666 14.0007C10.6666 13.6327 10.368 13.334 9.99996 13.334H9.33329V12.0007H14C14.7366 12.0007 15.3333 11.404 15.3333 10.6673V2.66732C15.3333 1.93065 14.7366 1.33398 14 1.33398H1.99996ZM1.99996 2.66732H14V10.6673H1.99996V2.66732Z"
    />
  </svg>
);
