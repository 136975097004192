import { RequestHeader } from '../../services';
import { CompanyClient } from './company.client';
import { Company } from './company.model';

export async function companyClientProvider(
  companyId: string,
  requestHeaders?: RequestHeader
): Promise<Company> {
  const response = await new CompanyClient(companyId, requestHeaders).get();

  return response as unknown as Promise<Company>;
}
