import styled, { css } from 'styled-components';

import { breakpoint, hideScrollbar } from '../../styles';
import { SliderListWrapStyledProps } from './slider.model';
import { SliderItemContainerStyled } from './sliderItem';

export enum SliderControlIcon {
  arrow = 'arrow',
  chevron = 'chevron',
}

export enum SliderControlStyle {
  circle = 'circle',
  square = 'square',
}

type SliderContainerStyledProps = {
  spaceBetweenItems: number;
  isMobile: boolean;
  withoutControls: boolean;
  controlsInside: boolean;
  disabledPrevBtn: boolean;
  disabledNextBtn: boolean;
};

export const SliderContainerStyled = styled.div<SliderContainerStyledProps>`
  overflow: hidden;
  padding: ${({ withoutControls, controlsInside }): string =>
    withoutControls || controlsInside ? '0' : '0 78px'};
  position: relative;
  box-sizing: border-box;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
    `
  )}

  ${({ controlsInside, disabledPrevBtn, disabledNextBtn, theme }): string =>
    controlsInside
      ? `
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: ${theme.IS_MOBILE ? 0 : 28}px;
          pointer-events: none;
          transition: opacity 0.2s;
          z-index: 1;
        }
        
        &:before {
          opacity: ${disabledPrevBtn ? '0' : '1'};
          left: -2px;
          background: linear-gradient(90deg, #FFF 21.74%, rgba(255, 255, 255, 0.00) 100%)
        }
        
        &:after {
          opacity: ${disabledNextBtn ? '0' : '1'};
          right: -2px;
          background: linear-gradient(270deg, #FFF 21.74%, rgba(255, 255, 255, 0.00) 100%)
        }
          `
      : ''};
`;

export const SliderListStyled = styled.div`
  overflow: hidden;
  display: flex;

  ${({ theme }): ReturnType<typeof css> =>
    theme.IS_MOBILE
      ? css`
          margin: 0 -20px;

          ${SliderItemContainerStyled} {
            &:first-child {
              margin-left: 20px;
            }

            &:last-child {
              margin-right: 20px;
            }
          }
        `
      : css``}
`;

export const SliderListWrapStyled = styled.div<SliderListWrapStyledProps>`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  ${hideScrollbar()}

  ${({ centeredList }): string => (centeredList ? 'margin: 0 auto;' : '')}
`;
