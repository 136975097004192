import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { ContentLeftContainerProps } from './contentLeft.model';

export const ContentLeftContainer = ({
  course,
  translations,
}: ContentLeftContainerProps): ReactElement => (
  <ContentLeftStyled>
    <TitleStyled>
      {translations?.course_free_access_by_subscription}
    </TitleStyled>
    <LeftAdvantagesContainer course={course} translations={translations} />
  </ContentLeftStyled>
);
