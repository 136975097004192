import { CheckEmailClient } from './checkEmail.client';
import { CheckEmailStatusCode } from './checkEmail.model';

export async function checkEmailProvider(
  email: string
): Promise<CheckEmailStatusCode> {
  const response = await new CheckEmailClient(email).get();

  return response as unknown as Promise<CheckEmailStatusCode>;
}
