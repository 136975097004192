import styled from 'styled-components';
import { fontSettings, lineClamping } from '../../../styles';

export const CourseItemContainerStyled = styled.div`
  display: flex;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  border-radius: 16px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CourseItemContentStyled = styled.div`
  margin-left: 20px;
  flex: 1 1 100%;
`;

export const CourseItemTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  ${lineClamping(2)}
  max-height: 48px;
`;

export const CourseItemSubTitleStyled = styled.div`
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const CourseItemLogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 72px;
  border-radius: 8px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

type CourseItemButtonStyledProps = {
  isMobile?: boolean;
};

export const CourseItemButtonStyled = styled.div<CourseItemButtonStyledProps>`
  ${({ isMobile }): string =>
    isMobile ? 'margin-top: 16px;' : 'margin-left: 16px;'};
`;

export const CourseItemContentWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CourseItemDescriptionStyled = styled.div`
  display: flex;

  ${CourseItemSubTitleStyled} {
    &:nth-of-type(2) {
      position: relative;

      &:before {
        content: '·';
        padding: 0 5px;
        font-weight: 700;
      }
    }
  }
`;
