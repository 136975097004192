import { TranslationModel } from '@lerna-core';

export const getStatusMessage = (
  isSuccessDisplayed: boolean,
  isErrorDisplayed: boolean,
  isExistOrder: boolean,
  translations: TranslationModel,
  isFreeCourse: boolean,
  isSkillboxCourse: boolean
): Record<string, string> => {
  switch (true) {
    case isSuccessDisplayed: {
      const successTitle =
        isFreeCourse && isSkillboxCourse
          ? translations?.course_will_be_added
          : translations?.sign_up_default_success_title_description;
      const freeCourseSubtitle =
        isFreeCourse && isSkillboxCourse
          ? ''
          : translations?.course_will_be_added_in_one_day;

      return {
        title: successTitle,
        subtitle: isFreeCourse
          ? freeCourseSubtitle
          : translations?.sign_up_default_success_subtitle_description,
      };
    }
    case isExistOrder: {
      const freeCourseSubtitle =
        isFreeCourse && isSkillboxCourse
          ? translations?.course_will_be_added
          : translations?.course_will_be_added_in_one_day;

      return {
        title: translations?.order_exist_message_title,
        subtitle: isFreeCourse
          ? freeCourseSubtitle
          : translations?.order_exist_message_subtitle,
      };
    }
    case isErrorDisplayed:
    default:
      return {
        title: translations?.popup_error_message_title,
        subtitle: translations?.popup_error_message_description,
      };
  }
};
