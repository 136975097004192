import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { VendorApiResponseModel, VendorModel } from './vendors.model';
import { vendorsApiAdapter } from './vendors.adapter';

export class VendorsClient extends SecureRequestClient<
  null,
  VendorApiResponseModel,
  VendorModel[]
> {
  protected path = 'course-api/v1/vendors';
  protected requestParameters: null;
  protected adapter = vendorsApiAdapter;

  constructor(
    host = publicConfig.API_GATEWAY_URL,
    privateHost = serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
  ) {
    super(host, privateHost, {}, { withCredentials: false });
    this.requestParameters = null;
  }
}
