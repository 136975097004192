import styled from 'styled-components';
import { SwitcherStyledProps } from './switcherControl.model';

export const SwitcherControlStyled = styled.div<SwitcherStyledProps>`
  display: flex;
  align-items: center;
  gap: 2px;

  height: 56px;
  padding: 4px;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  box-sizing: border-box;

  background: ${({ theme }): string => theme.COLOR.BLACK_08};
`;
