import { ApiVendorModel, VendorModel } from '../features/vendors';
import { mapImageResource } from '../features/image';

export const mapVendor = (apiVendor: ApiVendorModel): VendorModel => {
  return {
    slug: apiVendor.slug,
    uuid: apiVendor.uuid,
    title: apiVendor.title,
    logo: apiVendor.logo
      ? {
          imageResource: mapImageResource(apiVendor.logo.data.image_resource),
          size: apiVendor?.logo?.data?.size || null,
          backgroundColor: apiVendor?.logo?.data?.background_color || null,
        }
      : null,
  };
};

export const mapVendors = (apiVendors: ApiVendorModel[]): VendorModel[] => {
  return apiVendors.map((apiVendor) => {
    return mapVendor(apiVendor);
  });
};
