import React, {
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTheme } from '../../hooks';
import {
  IconAttentionComponent,
  IconCloseComponent,
  IconErrorComponent,
  IconSuccessComponent,
} from '../icons';
import {
  ToastIconButton,
  ToastMessageStyled,
  ToastStyled,
} from './toast.styled';
import { ToastContainerProps, ToastType } from './toast.model';
import { useDispatch } from 'react-redux';
import { clearToast } from './toast.actions';

export const ToastContainer = memo(function RenderToast({
  message,
  type,
  visible,
  onHide,
  hideCallback,
  top = 40,
  right = 40,
  width = 400,
}: ToastContainerProps): ReactElement {
  const theme = useTheme();
  const [isVisible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  const icon = useMemo(() => {
    switch (type) {
      case ToastType.success:
        return <IconSuccessComponent />;
      case ToastType.warning:
        return <IconAttentionComponent fillColor="#FFCF24" />;
      case ToastType.error:
        return <IconErrorComponent />;
      default:
        return null;
    }
  }, [type]);

  const handleClose = (): void => {
    setVisible(false);
  };

  const timeout = useRef<NodeJS.Timeout>();

  const handleTransitionEnd = (): void => {
    if (!isVisible) {
      hideCallback && hideCallback();
      dispatch(clearToast());
    }
  };

  useEffect(() => {
    if (visible) {
      setVisible(visible);
      timeout.current = setTimeout((): void => {
        setVisible(false);
      }, 4000);
    } else {
      clearTimeout(timeout.current);
    }
  }, [visible]);

  return (
    <ToastStyled
      visible={isVisible}
      top={top}
      right={right}
      width={width}
      onTransitionEnd={handleTransitionEnd}
    >
      <div>
        {icon}
        <ToastMessageStyled>{message}</ToastMessageStyled>
      </div>

      <div>
        <ToastIconButton onClick={onHide || handleClose}>
          <IconCloseComponent
            width={16}
            height={16}
            strokeColor={theme.COLOR.WHITE}
          />
        </ToastIconButton>
      </div>
    </ToastStyled>
  );
});
