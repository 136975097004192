import styled from 'styled-components';

export const TopMenuLinkContainerStyled = styled.div`
  text-decoration: none;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  font-size: ${(props): string => props.theme.FONT.SIZE_17}px;
  line-height: ${(props): string => props.theme.FONT.LINE_HEIGHT_22}px;
  cursor: pointer;
  font-weight: 500;
`;
