import React, { ReactElement, useContext } from 'react';

import {
  ProfileButtonContainerStyled,
  ProfileButtonLoginLinkTextStyled,
} from './headerProfileButton.styled';
import {
  ButtonColor,
  ButtonContainer,
  IconPersonComponent,
  UserAvatarSize,
  UserBarContainer,
  useTheme,
  useUser,
  useUserMenu,
} from '@lerna-core';
import { LoginLinkContainer } from '@features/common/loginLink';
import { PageNameContext } from '@contexts';
import { HeaderProfileButtonContainerProps } from './headerProfileButton.model';

export const HeaderProfileButtonContainer = ({
  translations,
  withLogin,
  useLoginText,
}: HeaderProfileButtonContainerProps): ReactElement => {
  const pageName = useContext(PageNameContext);
  const theme = useTheme();
  const { user } = useUser();
  const { menu } = useUserMenu();

  const projectPageName = pageName ? pageName : undefined;

  const userBar = Boolean(user) && (
    <UserBarContainer
      avatarSize={UserAvatarSize.small}
      user={user}
      isRound={true}
      translations={translations}
      menu={menu}
      projectPageName={projectPageName}
    />
  );
  const login = !user && withLogin && (
    <LoginLinkContainer>
      <ButtonContainer
        borderRadius={16}
        children={
          useLoginText ? (
            <ProfileButtonLoginLinkTextStyled>
              {translations?.user_login}
            </ProfileButtonLoginLinkTextStyled>
          ) : (
            <IconPersonComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
          )
        }
        color={ButtonColor.transparentFull}
      />
    </LoginLinkContainer>
  );

  return (
    <ProfileButtonContainerStyled hasMargin={Boolean(user)}>
      {login}
      {userBar}
    </ProfileButtonContainerStyled>
  );
};
