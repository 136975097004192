import React, { ReactElement } from 'react';
import { FilterDescriptionContainerProps } from './filterDescription.model';
import { FilterDescriptionContainerStyled } from './filterDescription.styled';
import { Nullable } from '@lerna-core';

export const FilterDescriptionContainer = ({
  text,
}: FilterDescriptionContainerProps): Nullable<ReactElement> => {
  if (!text) return null;

  return (
    <FilterDescriptionContainerStyled>{text}</FilterDescriptionContainerStyled>
  );
};
