import {
  CourseModel,
  Nullable,
  OpenPopupModel,
  TranslationModel,
  UserAggregateModel,
} from '@lerna-core';
import * as Yup from 'yup';
import { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { FormPaymentInfoModel } from '../../../form.model';
import { UserOrderFormPaymentContentTypes } from '../../userOrderForm.model';

export enum CompensationFormContent {
  courseWithCompensation = 'courseWithCompensation',
  courseWithoutCompensation = 'courseWithoutCompensation',
}

export type CompensationFormContainerProps = {
  course: CourseModel;
  user: UserAggregateModel;
  translations: TranslationModel;
  eventPosition: string;
  courseIndex?: Nullable<number>;
};

export type CompensationFormInitialValues = {
  withPromoCode: boolean;
  promoCode: string;
  comment: string;
};

export type CompensationFormValidationSchema = Yup.ObjectSchema<
  Partial<Record<keyof CompensationFormInitialValues, Yup.AnySchema>>
>;

export type CompensationFormContextModel = CompensationFormContainerProps & {
  currentPopup: Nullable<OpenPopupModel>;
  handlePopupClose: () => void;
  formContent: CompensationFormContent;
  handleChangeFormContent: (content: CompensationFormContent) => void;
  paymentContent: UserOrderFormPaymentContentTypes;
  handleChangePaymentContent: (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ) => void;
  isCourseWithCompensationContent: boolean;
  isOnlinePaySelected: boolean;
  submitButtonTitle: string;
  handleFormButtonClick: () => void;
  handleFormSubmit: () => void;
  handleChange: (e: ChangeEvent<Element>) => void;
  values: CompensationFormInitialValues;
  errors: FormikErrors<CompensationFormInitialValues>;
  touched: FormikTouched<CompensationFormInitialValues>;
  isSubmitting: boolean;
  isSuccessDisplayed: boolean;
  isErrorDisplayed: boolean;
  isExistOrder: boolean;
  showFormStatus: boolean;
  showPaymentTabs: boolean;
  isFreeCourse: boolean;
  isLernaPrime: boolean;
  isCommentPopupOpened: boolean;
  toggleCommentPopupOpened: () => void;
  formOrderInfo: FormPaymentInfoModel;
  handleBlur?: (e: FocusEvent<Element, Element>) => void;
  setFieldValue?: (field: string, value: string) => void;
};

export type CompensationFormContextProviderProps = {
  props: CompensationFormContainerProps;
  children: ReactNode;
};
