import React, { ReactElement } from 'react';
import {
  LoaderEmptyCircleCircleStyled,
  LoaderEmptyCircleContainerStyled,
  LoaderEmptyCircleIconStyled,
  LoaderEmptyCircleStyled,
} from './loaderEmptyCircle.styled';

export const LoaderEmptyCircleContainer = (): ReactElement => {
  return (
    <LoaderEmptyCircleContainerStyled>
      <LoaderEmptyCircleStyled>
        <LoaderEmptyCircleIconStyled viewBox="0 0 100 100">
          <LoaderEmptyCircleCircleStyled cx="50%" cy="50%" r="45" />
        </LoaderEmptyCircleIconStyled>
      </LoaderEmptyCircleStyled>
    </LoaderEmptyCircleContainerStyled>
  );
};
