import { ReactNode } from 'react';
import { CourseStatus, isCourseHasNeededStatus } from '../../../course';
import { CellCourseStatusStyled } from './cellCourse.styled';
import { CourseStatusPlugProps } from './cellCourse.model';

export const courseStatusPlug = ({
  status,
  translations,
  showInactivePlug,
  showArchivedPlug,
}: CourseStatusPlugProps): ReactNode => {
  let statusName = '';

  if (
    isCourseHasNeededStatus(status, CourseStatus.inactive) &&
    showInactivePlug
  )
    statusName = translations.inactive;

  if (
    isCourseHasNeededStatus(status, CourseStatus.archived) &&
    showArchivedPlug
  )
    statusName = translations.archived;

  return (
    !!statusName && (
      <CellCourseStatusStyled>{statusName}</CellCourseStatusStyled>
    )
  );
};
