import { DeviceInfo } from './device.model';
import { AppContext } from 'next/app';
import { checkIsTelegram } from './device.helper';
import { NextPageContext } from 'next';

export function getDeviceInfoFromContext(ctx: NextPageContext): DeviceInfo {
  const headers = ctx.req?.headers;
  const userAgent = headers?.['user-agent']
    ? headers?.['user-agent']
    : navigator.userAgent;
  const accept = headers?.['accept'] ?? '';
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOperaMini = Boolean(userAgent.match(/Opera Mini/i));
  const isIEMobile = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isTelegram = checkIsTelegram(ctx);
  const isMobile = Boolean(
    isAndroid || isIos || isOperaMini || isIEMobile || isTelegram
  );
  const isTablet = Boolean(
    userAgent
      .toLowerCase()
      .match(
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i
      )
  );
  const isDesktop = Boolean(!isMobile && !isSSR);
  const webPSupport = Boolean(accept.match(/image\/webp/i));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    webPSupport,
  };
}

export function getDeviceInfo(context: AppContext): DeviceInfo {
  return getDeviceInfoFromContext(context.ctx);
}
