import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  CompanyEditLogoWrapperStyled,
  CompanyEditLogoWarning,
  CompanyLogoInfoStyled,
  CompanyLogoLabelStyled,
  CompanyLogoStyled,
  LogoPlug,
  CompanyEditLogoActionOverlay,
  CompanyEditLogoActionStyled,
} from './companyEditLogo.styled';
import { CompanyEditLogoModel } from './companyEditLogo.model';
import 'cropperjs/dist/cropper.css';
import { LogoPopupContainer } from './logoPopup';
import { logger, PRESETS } from '../../../services';
import { FileUploadAcceptEnum, FileUploadContainer } from '../../inputs';
import {
  IconDeleteComponent,
  IconEditComponent,
  IconPlusComponent,
} from '../../icons';
import { ImageContainer } from '../../image';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
} from '../../button';
import { useTranslations } from '../../translations';
import { useTheme } from '../../../hooks';

const { h: logoHeight, w: logoWidth } = PRESETS.logoCompanyBig.opts;
const inputAccept = [
  FileUploadAcceptEnum.jpeg,
  FileUploadAcceptEnum.svg,
  FileUploadAcceptEnum.png,
];

export const CompanyEditLogoContainer = ({
  nextLogo,
  logo,
  handleChangeLogo,
  handleChangeNextLogo,
  uploaderApiHost,
  mediaDomain,
}: CompanyEditLogoModel): ReactElement | null => {
  const translations = useTranslations();
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState<string>('');
  const [isPopupOpened, setPopupOpened] = useState<boolean>(false);
  const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target?.files && e.target?.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader?.result) {
          setImageSrc(reader.result.toString());
          setPopupOpened(true);
        } else {
          logger.error('[ERROR]: reader result is not specified', reader);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  const handleDelete = useCallback(() => {
    handleChangeLogo(null);
    handleChangeNextLogo(null);
  }, []);

  const logoComponent = useMemo(() => {
    const companyLogo = Object.assign({}, logo, nextLogo);

    if (Object.keys(companyLogo).length) {
      return (
        <>
          <ImageContainer
            width={logoWidth}
            height={logoHeight}
            imageResource={companyLogo}
            preset="logoCompanyBig"
            mediaDomain={mediaDomain}
          />
          <CompanyEditLogoActionOverlay height={logoHeight}>
            <CompanyEditLogoActionStyled onClick={handleDelete}>
              <IconDeleteComponent
                width={16}
                height={16}
                strokeColor={theme.COLOR.WHITE}
              />
            </CompanyEditLogoActionStyled>
          </CompanyEditLogoActionOverlay>
        </>
      );
    } else {
      return (
        <FileUploadContainer accept={inputAccept} onUpload={handleUpload}>
          <LogoPlug>
            <IconPlusComponent />
          </LogoPlug>
        </FileUploadContainer>
      );
    }
  }, [nextLogo, logo]);

  const handleClosePopup = useCallback(() => {
    setPopupOpened(false);
  }, []);

  return (
    <>
      <CompanyEditLogoWrapperStyled>
        <CompanyLogoStyled
          width={logoWidth}
          height={logoHeight}
          dottedBorder={!(nextLogo || logo)}
        >
          {logoComponent}
        </CompanyLogoStyled>

        <CompanyLogoInfoStyled>
          <CompanyLogoLabelStyled>{translations.logo}</CompanyLogoLabelStyled>

          <FileUploadContainer accept={inputAccept} onUpload={handleUpload}>
            <ButtonContainer
              title={translations.edit_logo}
              color={ButtonColor.brightPurple}
              childrenPosition={ButtonChildrenPosition.left}
            >
              <IconEditComponent strokeColor={theme.COLOR.PRIMARY['500']} />
            </ButtonContainer>
          </FileUploadContainer>
        </CompanyLogoInfoStyled>
      </CompanyEditLogoWrapperStyled>

      <CompanyEditLogoWarning>
        {translations.logo_sizes_warning}
      </CompanyEditLogoWarning>

      <LogoPopupContainer
        isOpened={isPopupOpened}
        imageSrc={imageSrc}
        handleClosePopup={handleClosePopup}
        handleChangeNextLogo={handleChangeNextLogo}
        uploaderApiHost={uploaderApiHost}
      />
    </>
  );
};
