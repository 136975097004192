import React, { ReactElement } from 'react';
import { AdvantagesListStyled } from './advantagesList.styled';
import { ListItemContainer } from './listItem';
import { AdvantagesListContainerProps } from './advantagesList.model';

export const AdvantagesListContainer = ({
  list,
  translations,
}: AdvantagesListContainerProps): ReactElement => (
  <AdvantagesListStyled>
    {list.map((item) => (
      <ListItemContainer
        key={item.title}
        translations={translations}
        {...item}
      />
    ))}
  </AdvantagesListStyled>
);
