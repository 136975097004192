import { SliderControlIcon, SliderControlStyle } from '../slider.styled';

export enum SliderControlTypes {
  prev = 'prev',
  next = 'next',
}

export type SliderControlContainerProps = {
  type: SliderControlTypes;
  handleClick: () => void;
  disabled: boolean;
  isMobile: boolean;
  controlIcon: SliderControlIcon;
  controlsStyle: SliderControlStyle;
  controlsInside: boolean;
};
