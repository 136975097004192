import React, { ReactElement, useMemo } from 'react';
import { PricesItemContainer } from '../pricesItem';
import { PricesB2B2CStyled, WrapperStyled } from './pricesB2B2C.styled';
import { PricesB2B2CContainerProps } from './pricesB2B2C.model';
import { textGradient, useTheme } from '@lerna-core';
import { B2B2CPriceInfoContainer } from './B2B2CPriceInfo';
import { getCustomerB2B2CPrices } from '@features/common/course';

export const PricesB2B2CContainer = ({
  course,
  translations,
  withInstallment = true,
  isLernaPrime,
}: PricesB2B2CContainerProps): ReactElement => {
  const theme = useTheme();
  const {
    startPrice,
    hasCorporateDiscount,
    corporateDiscountValue,
    corporateDiscountAmount,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = useMemo(() => getCustomerB2B2CPrices(course), []);

  const showStartPrice =
    (withInstallment && hasInstallment) || hasOneOfDiscounts;
  const showTotalPrice =
    withInstallment &&
    hasInstallment &&
    hasOneOfDiscounts &&
    !course.isFreeByDiscount;
  const showBonusPoints = hasBonusPointsDiscount && !course.isFreeByDiscount;

  const corporateDiscountTitle = `${translations?.customer_discount_label} - ${corporateDiscountValue}`;
  const gradientText = textGradient(
    theme.COLOR.DEFAULT_GRADIENT_START,
    theme.COLOR.DEFAULT_GRADIENT_END
  );
  const bonusPointsTitle = isLernaPrime
    ? translations?.company_discount_bonuses_label
    : translations?.discount_bonuses_label;

  return (
    <PricesB2B2CStyled>
      {!course.isFree && (
        <WrapperStyled>
          {showStartPrice && (
            <PricesItemContainer
              title={translations?.item_price_label}
              value={startPrice}
              itemStyles={
                hasOneOfDiscounts
                  ? {
                      value: `text-decoration: line-through; color: ${theme.COLOR.TEXT_LIGHT_COLOR}`,
                    }
                  : undefined
              }
            />
          )}
          {hasCorporateDiscount && (
            <PricesItemContainer
              title={corporateDiscountTitle}
              value={corporateDiscountAmount}
              isNegative
            />
          )}
          {showBonusPoints && (
            <PricesItemContainer
              title={bonusPointsTitle}
              value={bonusPointsDiscount}
              isNegative
              itemStyles={{
                title: gradientText,
                value: gradientText,
              }}
            />
          )}
          {showTotalPrice && (
            <PricesItemContainer
              title={translations?.total_price_label}
              value={totalPrice}
              itemStyles={{
                item: 'font-weight: 700;',
              }}
            />
          )}
        </WrapperStyled>
      )}
      <B2B2CPriceInfoContainer
        course={course}
        translations={translations}
        withInstallment={withInstallment}
      />
    </PricesB2B2CStyled>
  );
};
