import { rgba } from 'polished';
import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { EducationStreamStyledProps } from './educationStream.model';

export const EducationStreamStyled = styled.div<EducationStreamStyledProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background: ${({ theme, isSelected }): string =>
    isSelected ? theme.COLOR.PRIMARY['500'] : theme.COLOR.BLACK_4};
  color: ${({ theme, isSelected }): string =>
    isSelected ? theme.COLOR.WHITE : theme.COLOR.TEXT_DARK_COLOR};
  border-radius: 100px;
  font-weight: 500;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  cursor: pointer;

  &:hover {
    background: ${({ theme, isSelected }): string =>
      isSelected
        ? rgba(theme.COLOR.PRIMARY['500'], 0.8)
        : theme.COLOR.BLACK_08};
  }
`;
