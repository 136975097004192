import styled from 'styled-components';
import {
  CounterStyledProps,
  FiltersCoursesCounterStyledProps,
} from './filtersCoursesCounter.model';
import { fontSettings, transition } from '@lerna-core';

export const FiltersCoursesCounterStyled = styled.div<FiltersCoursesCounterStyledProps>`
  position: sticky;
  bottom: 0;
  left: 0;
  margin: 0 4px;
  background-color: #ebebeb;
  padding: 28px 12px 12px;
  margin-top: -16px;
  z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  border-radius: 0 0 16px 16px;
  display: flex;
  align-items: center;
  ${transition('background-color')}
  ${({ isSticky, theme }): string =>
    isSticky
      ? `z-index: ${theme.ZINDEX.TOP_BLOCK}; padding: 12px; border-radius: 16px 16px 0 0;`
      : ''}
  
   
   ${({ hasSelectedFilters, isRouting }): string =>
    hasSelectedFilters && !isRouting
      ? `&:hover {
    background-color: #e0e0e0;
  }`
      : ''}
`;

export const CounterStyled = styled.div<CounterStyledProps>`
  text-align: center;
  font-weight: 500;
  flex-grow: 1;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${({ isRouting, theme }): string =>
    isRouting ? `color: ${theme.COLOR.TEXT[100]};` : ''}
`;

export const WrapperStyled = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid ${({ theme }): string => theme.COLOR.BLACK_12};
`;

export const IconStyled = styled.div`
  cursor: pointer;
  display: flex;
`;
