import styled, { css } from 'styled-components';
import {
  DropdownContainerStyledProps,
  DropdownPosition,
} from './dropdown.model';

const getDropdownPosition = (
  position: DropdownPosition
): ReturnType<typeof css> => css`
  ${position === DropdownPosition.right ? 'right: 0px' : `left: 0`}
`;

export const DropdownContainerStyled = styled.div<DropdownContainerStyledProps>`
  ${({ position }): ReturnType<typeof css> => getDropdownPosition(position)};
  ${({ top }): ReturnType<typeof css> =>
    css`
      top: calc(100% + ${top}px);
    `};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props): string => props.theme.COLOR.WHITE};
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
  border-radius: 16px;
  cursor: default;
  overflow: hidden;
  padding: 20px;
  min-width: 140px;
  box-sizing: border-box;
  ${({ extraStyles }): ReturnType<typeof css> | string =>
    extraStyles ? extraStyles : ''}
`;
