import React, { ReactElement } from 'react';
import { LoaderContainer } from '../loader';
import { publicConfig } from '../../services';
import { LoaderEmptyCircleContainer } from './loaderEmptyCircle';
import { ApplicationNames } from '../../constants';

export const LoadersContainer = (): ReactElement => {
  switch (publicConfig?.APP_THEME) {
    case ApplicationNames.lerna:
      return <LoaderContainer />;
    default:
      return <LoaderEmptyCircleContainer />;
  }
};
