import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconHandWithCoinsComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <g stroke={strokeColor} strokeMiterlimit={10} strokeWidth={strokeWidth}>
        <path d="M13.29 4.3a2.5 2.5 0 1 1 2.46 2.95" />
        <path d="M12.75 10.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.25 16.25h3.5a1.5 1.5 0 1 0 0-3h-2.196c-2.898 0-2.97-.5-4.64-.5-3.226 0-5.164 4.253-5.164 4.253"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.25 21.75s.5-2 2.5-2c2.027 0 4.046.5 6 .5 1.413 0 2.628-1.381 8.087-5.967l-.026.028a1.5 1.5 0 0 0 0-2.121c-.586-.586-1.367-.561-2.121 0-.755.56-5.026 3.75-5.026 3.75"
        />
      </g>
    </svg>
  );
};
