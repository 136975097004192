import React, { ReactElement } from 'react';

import { IconLoaderComponent, IconLoaderLComponent } from '../icons';
import { LoaderProps } from './loader.model';
import {
  IconLoaderComponentStyled,
  IconLoaderLetterComponentStyled,
  LoaderStyledContainer,
} from './loader.styled';

export const LoaderContainer = ({
  position,
  width = 80,
  height = 80,
  withLogo = true,
  withBlur = false,
}: LoaderProps): ReactElement => {
  return (
    <LoaderStyledContainer withBlur={withBlur} position={position}>
      <IconLoaderComponentStyled width={width} height={height}>
        <div>
          <IconLoaderComponent width={width} height={height} />
        </div>
      </IconLoaderComponentStyled>
      {withLogo ? (
        <IconLoaderLetterComponentStyled>
          <IconLoaderLComponent />
        </IconLoaderLetterComponentStyled>
      ) : null}
    </LoaderStyledContainer>
  );
};
