import { IColor } from '../../features/themes';

export const textGradient = (colorStart: string, colorStop: string): string => `
  background: ${colorStart};
  background: -webkit-linear-gradient(
    90deg,
    ${colorStart} 0%,
    ${colorStop} 100%
  );
  background: -moz-linear-gradient(90deg, ${colorStart} 0%, ${colorStop} 100%);
  background: linear-gradient(90deg, ${colorStart} 0%, ${colorStop} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const textMultiGradient = (
  gradient: string,
  fallbackColor: string
): string => `
  background: ${fallbackColor};
  background: -webkit-linear-gradient(${gradient});
  background: -moz-linear-gradient(${gradient});
  background: linear-gradient(${gradient});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const defaultGradient = (iColor: IColor): string => `
  background: linear-gradient(
    235deg,
    ${iColor.DEFAULT_GRADIENT_END} 0%,
    ${iColor.DEFAULT_GRADIENT_START} 100%
  );`;

export const headerLoaderLineGradient = (): string => `
    background: -webkit-linear-gradient(
        75.96deg,
        #ff95c2 7.67%,
        #e48acb 27.59%,
        #c092f5 47.51%,
        #9b9fff 69.2%,
        #8da7ff 92.67%
    );
    
    background: -moz-linear-gradient(
        75.96deg,
        #ff95c2 7.67%,
        #e48acb 27.59%,
        #c092f5 47.51%,
        #9b9fff 69.2%,
        #8da7ff 92.67%
    );
    
    background: linear-gradient(
        75.96deg,
        #ff95c2 7.67%,
        #e48acb 27.59%,
        #c092f5 47.51%,
        #9b9fff 69.2%,
        #8da7ff 92.67%
    );
`;
