import { getCourseHref } from '@helpers';
import {
  CommonEventPositions,
  CommonEvents,
  CommonEventTypes,
  containUrlFilter,
  CourseModel,
  getSimpleEvent,
  handleOpenPopup,
  IframeInitiator,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  QueryParams,
  usePageRouter,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCoursesPageContext } from '../coursesPage';
import { CoursesPaginationContainer } from '../coursesPagination';
import { handleCoursesListDataLayer } from './coursesList.helpers';
import { CoursesListContainerProps } from './coursesList.model';
import {
  CoursesListContainerStyled,
  CoursesListStyled,
} from './coursesList.styled';
import { ListEmptyContainer } from './listEmpty';
import {
  GoToCourseEvent,
  ListItemButtonsEvent,
  ListItemContainer,
} from './listItem';
import { useCourseActiveContext } from '../coursesActive';
import { getCourseEventData } from '../../course';

export const CoursesListContainer = ({
  useCourseHref = true,
  courseBasePath,
  hrefTarget,
}: CoursesListContainerProps): Nullable<ReactElement> => {
  const { courses, translations, discountProgram, handleSetCourses } =
    useCoursesPageContext();
  const dispatch = useDispatch();
  const { setNumericId } = useCourseActiveContext();
  const { user } = useUser();
  const router = useRouter();
  const isRouting = usePageRouter();

  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const discountProgramColors = iframeInitiator
    ? undefined
    : discountProgram?.colors;
  const isListingWithBenefitsOnly = user?.career?.isListingWithBenefitsOnly;
  const isPricesHiddenOnListingCards =
    user?.career?.isPricesHiddenOnListingCards;
  const initiator = router.query[QueryParams.initiator] as IframeInitiator;
  const isEmptyList =
    courses.length === 0 && (!!router.query.title || containUrlFilter(router));

  const handleSuccessShowMore = (nextCourses: CourseModel[]): void => {
    handleSetCourses(courses.concat(nextCourses));
    setIsLoading(false);
  };

  const handleClickShowMore = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CommonEvents.clickButtonShowMoreCourses,
        CommonEventTypes.overallEvents,
        CommonEventPositions.coursesPageList
      )
    );
    setIsLoading(true);
  };

  const handleOpenSingUpCoursePopup = (
    course: CourseModel,
    courseIndex: number
  ): void => {
    handleCoursesListDataLayer(course, courseIndex);
    dispatch(
      handleOpenPopup(
        !!user ? PopupsEnum.userOrderFormPopup : PopupsEnum.guestOrderFormPopup,
        CommonEventPositions.coursesPageList
      )
    );
    setNumericId(course.numericId);
  };

  const handleGoToCourse = (
    course: CourseModel,
    courseHref: Nullable<string>,
    courseIndex: number,
    eventName: GoToCourseEvent | ListItemButtonsEvent
  ): void => {
    if (courseHref) {
      pushDataLayer(
        getCourseEventData(
          eventName,
          CommonEventTypes.coursesEvents,
          CommonEventPositions.coursesPageList,
          course,
          courseIndex
        )
      );
    } else {
      handleOpenSingUpCoursePopup(course, courseIndex);
    }
  };

  if (isEmptyList) {
    return <ListEmptyContainer />;
  }

  return (
    <CoursesListContainerStyled>
      <CoursesListStyled isRouting={isRouting}>
        {courses.map((course, index) => {
          const courseHref = getCourseHref({
            course,
            router,
            basePath: courseBasePath,
            initiator,
          });

          return (
            <ListItemContainer
              key={course.uuid}
              useCourseHref={useCourseHref}
              courseHref={courseHref}
              handleGoToCourse={handleGoToCourse.bind(
                null,
                course,
                courseHref,
                index
              )}
              course={course}
              courseIndex={index}
              hrefTarget={hrefTarget}
              translations={translations}
              isListingWithBenefitsOnly={isListingWithBenefitsOnly}
              isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
              handleOpenSingUpCoursePopup={handleOpenSingUpCoursePopup.bind(
                null,
                course,
                index
              )}
              discountProgramColors={discountProgramColors}
            />
          );
        })}
      </CoursesListStyled>
      <CoursesPaginationContainer
        isLoading={isLoading}
        handleSuccess={handleSuccessShowMore}
        handleClick={handleClickShowMore}
      />
    </CoursesListContainerStyled>
  );
};
