import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconWalletWithCoinsComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3334 1.33334C11.5837 1.33334 9.33337 3.58368 9.33337 6.33334C9.33337 6.91748 9.44019 7.47754 9.62633 8.00001H7.00003C6.4487 8.00001 6.00003 7.55134 6.00003 7.00001C6.00003 6.44868 6.4487 6.00001 7.00003 6.00001H8.01696C8.05363 5.29668 8.20125 4.62401 8.44925 4.00001H7.00003C5.42045 4.00001 4.13583 5.23145 4.02217 6.78256C4.00687 6.85402 3.99944 6.92694 4.00003 7.00001V9.00001V25C4.00003 26.645 5.35503 28 7.00003 28H25C26.645 28 28 26.645 28 25V11C28 9.4489 26.7914 8.16993 25.2748 8.02735C25.3093 7.80029 25.3334 7.56951 25.3334 7.33334C25.3334 4.76774 23.2323 2.66668 20.6667 2.66668C19.8064 2.66668 19.0015 2.9069 18.3073 3.31772C17.3924 2.11471 15.953 1.33334 14.3334 1.33334ZM14.3334 3.33334C15.3859 3.33334 16.297 3.87452 16.8321 4.68491C16.3085 5.4389 16 6.35163 16 7.33334C16 7.55993 16.0216 7.78175 16.0534 8.00001H11.8529C11.5285 7.52244 11.3334 6.95664 11.3334 6.33334C11.3334 4.66434 12.6644 3.33334 14.3334 3.33334ZM20.6667 4.66668C22.1518 4.66668 23.3334 5.84828 23.3334 7.33334C23.3334 7.5648 23.3021 7.78702 23.2461 8.00001H18.0873C18.0313 7.78702 18 7.5648 18 7.33334C18 5.84828 19.1816 4.66668 20.6667 4.66668ZM6.00003 10H25C25.5644 10 26 10.4357 26 11V25C26 25.5643 25.5644 26 25 26H7.00003C6.4357 26 6.00003 25.5643 6.00003 25V10ZM22.3334 16C21.8913 16 21.4674 16.1756 21.1549 16.4882C20.8423 16.8007 20.6667 17.2246 20.6667 17.6667C20.6667 18.1087 20.8423 18.5326 21.1549 18.8452C21.4674 19.1577 21.8913 19.3333 22.3334 19.3333C22.7754 19.3333 23.1993 19.1577 23.5119 18.8452C23.8244 18.5326 24 18.1087 24 17.6667C24 17.2246 23.8244 16.8007 23.5119 16.4882C23.1993 16.1756 22.7754 16 22.3334 16Z"
      fill={strokeColor}
    />
  </svg>
);
