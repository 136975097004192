import { ReactElement } from 'react';
import { useCookieNotificationManagementContext } from '../notificationManagement.context';
import { ManagementButtonStyled } from './managementButton.styled';
import { ManagementButtonModel } from './managementButton.model';
import { getSimpleEvent, pushDataLayer } from '../../../../services';
import { NotificationManagementEvent } from '../notificationManagement.analytics';
import { CommonEventPositions, CommonEventTypes } from '../../../../analytics';

export const ManagementButtonContainer = ({
  title,
}: ManagementButtonModel): ReactElement => {
  const { handleOpenPopup } = useCookieNotificationManagementContext();
  const handleClick = () => {
    pushDataLayer(
      getSimpleEvent(
        NotificationManagementEvent.clickLinkSettingsCookie,
        CommonEventTypes.overallEvents,
        CommonEventPositions.footer
      )
    );
    handleOpenPopup();
  };

  return (
    <ManagementButtonStyled as="div" onClick={handleClick}>
      {title}
    </ManagementButtonStyled>
  );
};
