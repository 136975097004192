import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { GoToCourseEvent } from '../listItem.analytics';
import { ListItemWholeLinkContainerProps } from './listItemWholeLink.model';
import { ListItemWholeLinkContainerStyled } from './listItemWholeLink.styled';

export const ListItemWholeLinkContainer = ({
  handleGoToCourse,
  courseHref,
  useHref = true,
  hrefTarget,
}: ListItemWholeLinkContainerProps): ReactElement => {
  const router = useRouter();
  const onClick = (): void => {
    handleGoToCourse(GoToCourseEvent.goToCourseCard);
    if (!useHref && courseHref) {
      if (hrefTarget === '_blank') {
        window.open(courseHref, '_blank');
      } else {
        router.push(courseHref);
      }
    }
  };

  return (
    <ListItemWholeLinkContainerStyled
      onClick={onClick}
      href={useHref ? courseHref : undefined}
    />
  );
};
