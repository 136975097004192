import { IFont } from '../../../index';

const setFontSettings = (fontSize: number, lineHeight: number): string => `
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  `;

export const fontSettings = (fontSize: number, fontTheme: IFont): string => {
  switch (fontSize) {
    case fontTheme.SIZE_9: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_12);
    }
    case fontTheme.SIZE_10: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_12);
    }
    case fontTheme.SIZE_11: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_16);
    }
    case fontTheme.SIZE_12: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_16);
    }
    case fontTheme.SIZE_13: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_16);
    }
    case fontTheme.SIZE_14: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_20);
    }
    case fontTheme.SIZE_15: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_20);
    }
    case fontTheme.SIZE_16: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_20);
    }
    case fontTheme.SIZE_17: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_22);
    }
    case fontTheme.SIZE_18: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_22);
    }
    case fontTheme.SIZE_20: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_24);
    }
    case fontTheme.SIZE_22: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_28);
    }
    case fontTheme.SIZE_24: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_30);
    }
    case fontTheme.SIZE_26: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_32);
    }
    case fontTheme.SIZE_28: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_34);
    }
    case fontTheme.SIZE_30: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_38);
    }
    case fontTheme.SIZE_32: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_41);
    }
    case fontTheme.SIZE_34: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_41);
    }
    case fontTheme.SIZE_36: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_44);
    }
    case fontTheme.SIZE_38: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_48);
    }
    case fontTheme.SIZE_40: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_48);
    }
    case fontTheme.SIZE_48: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_68);
    }
    case fontTheme.SIZE_54: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_68);
    }
    case fontTheme.SIZE_56: {
      return setFontSettings(fontSize, fontTheme.LINE_HEIGHT_68);
    }
    default:
      return '';
  }
};

export const fontRalewayBold = (): string => `
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
`;

export const fontRaleway = (): string => `
  font-family: 'Raleway', sans-serif;
`;
