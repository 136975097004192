import styled, { css } from 'styled-components';
import {
  AdvantagesContentStyledProps,
  AdvantagesStyledProps,
} from './coursesAdvantages.model';
import { breakpoint } from '@lerna-core';

export const advantagesBreakpoint =
  '@media only screen and (min-width: 0) and (max-width: 1439px)';

export const CoursesAdvantagesStyled = styled.div<AdvantagesStyledProps>`
  background: ${({ background }) => background};
  background-size: 100% 100%;
  box-sizing: border-box;
  border-radius: 16px;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 40px;
  margin: 32px 0;

  ${advantagesBreakpoint} {
    flex-direction: column;
    align-items: center;
    overflow: unset;
    padding: 0 20px 40px;
  }
  ${breakpoint(
    'mediumDown',
    css`
      padding: 0 20px 24px;
      margin: 32px 0 20px;
    `
  )}
`;

export const AdvantagesContentStyled = styled.div<AdvantagesContentStyledProps>`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex-grow: 1;
  ${({ withCustomerBanners }): string =>
    withCustomerBanners ? 'padding: 40px 0 20px;' : 'padding: 56px 0 20px;'}

  ${breakpoint(
    'xxlargeDown',
    css`
      justify-content: center;
      padding: 0px;
    `
  )}
  ${advantagesBreakpoint} {
    order: 2;
    width: 100%;
    margin-right: 0;
    align-items: center;
    padding: 0;
  }
`;
