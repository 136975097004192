import { PopupStyleProps } from '@lerna-core';

export const getReferralPopupStyleSettings = (
  isMobile = false
): PopupStyleProps => {
  return {
    paddingSticky: {
      top: '20px',
      right: '20px',
      bottom: '20px',
      left: '20px',
    },
    paddingContent: {
      top: isMobile ? '40px' : '56px',
      right: '0',
      bottom: isMobile ? '17px' : '24px',
      left: isMobile ? '0' : '64px',
    },
  };
};
