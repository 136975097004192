import React, { ReactElement } from 'react';
import { ListItemTitleStyled } from './listItemTitle.styled';
import { ListItemTitleContainerProps } from './listItemTitle.model';

export const ListItemTitleContainer = ({
  text,
}: ListItemTitleContainerProps): ReactElement => {
  return (
    <ListItemTitleStyled data-element={'title'}>
      <span>{text}</span>
    </ListItemTitleStyled>
  );
};
