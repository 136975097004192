import { FilterModel, Nullable, TranslationModel } from '@lerna-core';

export const dropdownSearchStickyDelta = 12;

export type FilterTabsContainerTypes = {
  filter: FilterModel;
  defaultPathname: string;
  filterColor?: Nullable<string>;
  translations: TranslationModel;
  withTitle?: boolean;
};
