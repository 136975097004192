import styled from 'styled-components';
import { fontSettings } from '../../styles';
import {
  TooltipContainerStyledProps,
  TooltipStyledProps,
} from './tooltip.model';

export const TooltipContainerStyled = styled.div<TooltipContainerStyledProps>`
  cursor: ${({ cursorType }): string => cursorType};
  display: flex;
`;

export const TooltipStyled = styled.div.attrs<TooltipStyledProps>(
  ({ top, left }) => ({
    style: {
      top,
      left,
    },
  })
)<TooltipStyledProps>`
  position: fixed;
  display: ${({ isOpen }): string => (isOpen ? 'block' : 'none')};
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 8px;
  padding: 16px;
  font-weight: 400;
  ${(props): string => fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)}
  box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.16);
  z-index: 100;
  max-width: 250px;
  box-sizing: border-box;
`;
