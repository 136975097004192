import React, { ChangeEvent, ReactElement, useState } from 'react';
import { SwitcherControlContainerProps } from './switcherControl.model';
import { SwitcherControlStyled } from './switcherControl.styled';
import { SwitcherOptionContainer } from './switcherOption';
import { FormControl } from '../../../formControl';

export const SwitcherControlContainer = ({
  options,
  value,
  onChange,
  name,
  borderRadius = 8,
}: SwitcherControlContainerProps): ReactElement => {
  const [activeKey, setActiveKey] = useState<string>(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setActiveKey(e.target.value);

    onChange(e);
  };

  return (
    <FormControl>
      <SwitcherControlStyled borderRadius={borderRadius}>
        {options.map((option, index) => (
          <SwitcherOptionContainer
            name={name}
            option={option}
            activeKey={activeKey}
            onChange={handleChange}
            borderRadius={borderRadius}
            key={index}
          />
        ))}
      </SwitcherControlStyled>
    </FormControl>
  );
};
