import { UpdateUserParams } from './updateUser.model';
import { UpdateUserClient } from './updateUser.client';

export async function updateUserProvider(
  updateUserParams: UpdateUserParams
): Promise<null> {
  const response = await new UpdateUserClient(updateUserParams).patch();

  return response as unknown as Promise<null>;
}
