import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { ToggleButtonStyledModel } from './toggleButton.model';
import { rgba } from 'polished';

export const ToggleButtonStyled = styled.div<ToggleButtonStyledModel>`
  padding: 15px 12px;
  margin-left: 1px;
  margin-right: 1px;
  background: ${({ theme, isActive }): string =>
    isActive ? theme.COLOR.WHITE : 'none'};
  box-shadow: ${({ isActive }): string =>
    isActive ? '0px 5px 25px -5px rgba(12, 11, 13, 0.04)' : 'none'};
  font-weight: 500;
  border-radius: ${({ borderRadius }): string => `${borderRadius || 12}px`};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: ${({ theme, isActive }): string =>
      isActive ? theme.COLOR.WHITE : rgba(theme.COLOR.WHITE, 0.6)};
    box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.04);
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
