import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../styles';
import { WithTheme } from '../themes';

export const AccessDeniedStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 108px 0 208px;

  ${breakpoint(
    'smallDown',
    css`
      padding: 48px 0 96px;
    `
  )};

  button {
    margin-top: 24px;

    ${breakpoint(
      'smallDown',
      css`
        margin-top: 0;
      `
    )};
  }
`;

export const AccessDeniedTitleStyled = styled.h1<WithTheme>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_56, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 700;
  line-height: 56px;
  margin: 0 0 28px 0;

  ${breakpoint(
    'smallDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_34, props.theme.FONT)};
      line-height: 40px;
    `
  )};
`;

export const AccessDeniedTextStyled = styled.span<WithTheme>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 16px;

  font-weight: 500;
`;
