import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PricesPromo = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  border-radius: 0 0 12px 12px;
  padding: 24px 20px 12px;
  background: linear-gradient(90deg, #f60065 0%, #ff4793 100%);
  color: ${({ theme }): string => theme.COLOR.WHITE};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  margin-top: -12px;

  b {
    font-weight: 700;
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    `
  )}
`;

export const PricesPromoIcon = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 50%;
  background: ${({ theme }): string => theme.COLOR.WHITE};
  flex: 0 0 auto;
  margin-right: 12px;
`;

export const PricesPromoText = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
