import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPaperWithPenComponent = ({
  width = 26,
  height = 24,
  fillColor = '#201926',
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg width={width} height={height} fill="none">
      <path
        d="M19.647 7.294V4.353c0-1.3-1.053-2.353-2.353-2.353H4.353A2.353 2.353 0 0 0 2 4.353v15.294C2 20.947 3.053 22 4.353 22h7.059M6.706 6.706h8.235M6.706 10.823h4.706"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.458 10.584c-6.733-.735-11.117 8.364-12.205 11.93-.083.27.218.395.386.169a36.017 36.017 0 0 1 2.451-2.965c.31-.334.754-.49 1.208-.514 2.544-.135 3.597-1.711 3.597-1.711-.382.127-1.84-.127-1.84-.127-1.013-.127.249-.443.436-.483 3.162-.681 3.369-2.052 3.369-2.052-.76.254-2.472-.19-2.472-.19 3.105-.253 3.676-2.092 3.676-2.092-.95.317-2.124.174-2.124.174 2.52-.523 3.518-2.14 3.518-2.14Z"
        fill={fillColor}
      />
    </svg>
  );
};
