import styled, { css } from 'styled-components';
import { breakpoint } from '../../../../styles';
import { FooterTopContactsStyledProps } from './topContacts.model';

export const FooterTopContactsStyled = styled.div<FooterTopContactsStyledProps>`
  display: flex;
  flex-direction: column;
  margin-right: 92px;
  white-space: nowrap;

  @media (max-width: 1439px) {
    ${({ hasFastLinks }): string => (hasFastLinks ? 'margin-right: 40px;' : '')}
  }
  ${breakpoint(
    'xlargeDown',
    css`
      margin-right: 0;
      margin-bottom: 40px;
    `
  )}
`;
