import styled from 'styled-components';

import { fontSettings } from '../../../styles/mixins';

type LanguageItemStyledProps = {
  isActive: boolean;
};

export const LanguageItemStyled = styled.div<LanguageItemStyledProps>`
  cursor: pointer;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string =>
    props.isActive
      ? props.theme.COLOR.TEXT_DARK_COLOR
      : props.theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 500;
  display: flex;
  flex: 0 0 100%;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 22px;
  }

  svg {
    margin: -1px 12px -1px 0;
  }
`;
