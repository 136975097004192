import React, { ReactElement } from 'react';

import { useTheme } from '../../../hooks';
import { IconDeleteComponent } from '../../icons';
import { UserAvatarContainer } from '../userAvatar';
import {
  UserAvatarActionOverlay,
  UserAvatarActionStyled,
  UserAvatarContainerStyled,
} from './index';
import { UserAvatarWithActionProps } from './userAvatarWithAction.model';

export const UserAvatarWithActionContainer = ({
  personal,
  imageSource,
  onActionClick,
  iconComponent,
  size,
}: UserAvatarWithActionProps): ReactElement => {
  const theme = useTheme();

  return (
    <UserAvatarContainerStyled>
      <UserAvatarContainer personal={personal} size={size} />
      {imageSource && (
        <UserAvatarActionOverlay size={size}>
          <UserAvatarActionStyled onClick={onActionClick}>
            {iconComponent ? (
              iconComponent
            ) : (
              <IconDeleteComponent
                width={16}
                height={16}
                strokeColor={theme.COLOR.WHITE}
              />
            )}
          </UserAvatarActionStyled>
        </UserAvatarActionOverlay>
      )}
    </UserAvatarContainerStyled>
  );
};
