import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLoaderComponent = ({
  width = 80,
  height = 80,
  strokeColor = '#29004D',
  strokeWidth = '4',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="40"
      cy="40"
      r="38"
      stroke="black"
      strokeOpacity="0.04"
      strokeWidth={strokeWidth}
    />
    <path
      d="M40 78C29.9218 78 20.2563 73.9964 13.1299 66.8701C6.00356 59.7437 2 50.0782 2 40"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);
