export enum MindboxEventName {
  gotToCourse = 'Website.ViewProduct',
  gotToSphere = 'Website.ViewCategory',
}

export type MindboxSites = 'lerna' | 'skillbox';

export type MindboxOptionsModel = {
  email?: string;
  site?: MindboxSites;
};
