import { SubscriptionClaimModel } from './subscriptionClaim.model';
import { SubscriptionClaimClient } from './subscriptionClaim.client';

export async function subscriptionClaimProvider(
  courseUuid: string
): Promise<SubscriptionClaimModel> {
  const response = await new SubscriptionClaimClient(courseUuid).post();

  return response as unknown as Promise<SubscriptionClaimModel>;
}
