import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMobileComponent = ({
  width = 16,
  height = 16,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      fill={fillColor}
      d="M11 0.666016H5.00004C4.08004 0.666016 3.33337 1.41268 3.33337 2.33268V13.666C3.33337 14.586 4.08004 15.3327 5.00004 15.3327H11C11.92 15.3327 12.6667 14.586 12.6667 13.666V2.33268C12.6667 1.41268 11.92 0.666016 11 0.666016ZM8.00004 14.0827C7.58537 14.0827 7.25004 13.7473 7.25004 13.3327C7.25004 12.918 7.58537 12.5827 8.00004 12.5827C8.41471 12.5827 8.75004 12.918 8.75004 13.3327C8.75004 13.7473 8.41471 14.0827 8.00004 14.0827ZM10.6667 11.9993H5.33337C4.96537 11.9993 4.66671 11.7007 4.66671 11.3327V3.33268C4.66671 2.96468 4.96537 2.66602 5.33337 2.66602H10.6667C11.0347 2.66602 11.3334 2.96468 11.3334 3.33268V11.3327C11.3334 11.7007 11.0347 11.9993 10.6667 11.9993Z"
    />
  </svg>
);
