import {
  EventDataModel,
  GTMEventCoursePageViewModel,
  GTMEventPageViewModel,
  GTMProductModel,
  GTMSimpleEventModel,
} from '../../models';
import { objectService } from '../objectService';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const getSimpleEvent = (
  eventName: string,
  eventType?: string,
  eventPosition?: string
): GTMSimpleEventModel => {
  return {
    event: eventName,
    ...objectService.clearObject({
      event_type: eventType,
      event_position: eventPosition,
    }),
  };
};

export const getPageEventData = (
  pageType: string,
  userId?: string
): GTMEventPageViewModel => {
  const eventProps: GTMEventPageViewModel = {
    page_type: pageType,
  };

  if (userId) {
    eventProps.user_id = userId;
  }

  return eventProps;
};

export const getCoursePageEventData = (
  pageType: string,
  product: GTMProductModel,
  userId?: string
): GTMEventCoursePageViewModel => {
  const eventProps: GTMEventCoursePageViewModel = {
    page_type: pageType,
    event_description: {
      product: product,
    },
  };

  if (userId) {
    eventProps.user_id = userId;
  }

  return eventProps;
};

export const pushDataLayer = (eventData: EventDataModel): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    if (eventData !== null) {
      window.dataLayer.push(eventData);
    }
  }
};
