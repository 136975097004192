import React, { ReactElement } from 'react';

import {
  ButtonChildrenPosition,
  ButtonLinkStyled,
  ButtonStyled,
  ButtonTitleStyled,
  IButton,
} from './button.styled';

const ButtonChildren = ({
  title,
  children,
  childrenPosition = ButtonChildrenPosition.right,
}: IButton): ReactElement => (
  <>
    {children && childrenPosition === ButtonChildrenPosition.left && children}
    {title && <ButtonTitleStyled>{title}</ButtonTitleStyled>}
    {children && childrenPosition === ButtonChildrenPosition.right && children}
  </>
);

const Button = (props: IButton): ReactElement =>
  // TODO: кнопка будет заменена на новый компонент Button_v2
  // eslint-disable-next-line react/no-children-prop
  React.createElement(ButtonStyled, {
    ...props,
    children: ButtonChildren(props),
  });

export const ButtonContainer = (props: IButton): ReactElement => {
  if (props.href && props.isNativeLink) {
    const targetProps =
      props.target === '_blank'
        ? {
            target: props.target,
            rel: 'noopener noreferrer',
          }
        : {
            target: props.target,
          };

    return (
      <ButtonLinkStyled {...props} href={props.href} {...targetProps}>
        {ButtonChildren(props)}
      </ButtonLinkStyled>
    );
  }

  if (props.href) {
    return (
      <ButtonLinkStyled {...props} href={props.href}>
        {ButtonChildren(props)}
      </ButtonLinkStyled>
    );
  }

  return Button(props);
};

ButtonContainer.defaultProps = {
  disabled: false,
};
