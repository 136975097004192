import { breakpoint, fontSettings, textMultiGradient } from '@lerna-core';
import styled, { css } from 'styled-components';

export const WildberriesAdvantages = styled.div`
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoint(
    'mediumDown',
    css`
      gap: 24px;
      flex-direction: column;
      align-items: center;
    `
  )}
`;

export const WildberriesAdvantagesTitle = styled.div`
  position: relative;
  z-index: 2;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_48, theme.FONT)};
  line-height: 56px;
  ${textMultiGradient(
    `270deg, #933bc4 0%, #bb1c9b 19.24%, #f064a5 67%, #fe7653 100%`,
    '#bb1c9b'
  )}
  font-weight: 700;
  white-space: pre-wrap;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)};
      text-align: center;
    `
  )}
`;
