import React, { ReactElement } from 'react';
import * as S from './registerForm.styled';
import { useTranslations } from '../../translations';
import { getRegisterForm } from './registerForm.helper';
import { useUser } from '../../user';

export const RegisterFormContainer = (): ReactElement => {
  const translations = useTranslations();
  const { user } = useUser();

  return (
    <S.RegisterForm>
      <S.RegisterFormTitle>
        {translations.register_form_title}
      </S.RegisterFormTitle>
      {getRegisterForm(user)}
    </S.RegisterForm>
  );
};
