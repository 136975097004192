import styled, { css } from 'styled-components';
import { FilterToggleContainerStyledProps } from './filterToggle.model';
import { breakpoint, fontSettings } from '@lerna-core';

export const FilterToggleContainerStyled = styled.div<FilterToggleContainerStyledProps>`
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;
  ${({ isCustomFilter, background, theme }): string =>
    isCustomFilter
      ? `
  background: ${background || 'transparent'};
  padding: 12px;
  `
      : `
   margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${theme.COLOR.BLACK_08};
  `}

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  
   ${breakpoint(
    'mediumDown',
    css`
      margin: 16px 0;
    `
  )}
`;

export const ToggleStyled = styled.div`
  margin-left: auto;
`;

export const FilterToggleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterToggleDescription = styled.div`
  margin-top: 12px;
  color: ${({ theme }): string => theme.COLOR.TEXT[100]};
  font-weight: 400;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)}
    `
  )}
`;
