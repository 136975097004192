import styled from 'styled-components';

import { fontSettings } from '../../../styles/mixins';
import { PointsBoxContainerStyled } from '../../pointsBox';
import { PaperTitleComponentStyled } from '../../paper';
import { AvatarDefaultStyled, UserAvatarContainer } from '../userAvatar';
import {
  UserAvatarStyledProps,
  UserInfoParamsTitleStyledProps,
} from './userInfo.model';

type props = {
  link?: boolean;
};

export const UserInfoNameStyled = styled.div`
  ${({ theme }): string =>
    fontSettings(
      theme.IS_MOBILE ? theme.FONT.SIZE_20 : theme.FONT.SIZE_22,
      theme.FONT
    )};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 700;
`;

export const UserInfoContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }): string => (theme.IS_MOBILE ? 'flex-direction: column' : '')};
`;

export const UserInfoParamsTitleStyled = styled(
  PaperTitleComponentStyled
)<UserInfoParamsTitleStyledProps>`
  ${({ margin }): string => (margin ? `margin:${margin};` : '')};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
  align-items: center;

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `${fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500`
      : ''}
`;

export const UserInfoHeadStyled = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  word-break: break-word;

  ${({ theme }): string => (theme.IS_MOBILE ? 'align-items: center;' : '')}
  ${PointsBoxContainerStyled} {
    flex: 0 0 auto;
  }
`;

export const UserInfoHeadAvatarStyled = styled(
  UserAvatarContainer
)<UserAvatarStyledProps>`
  ${AvatarDefaultStyled} {
    background: ${({ theme, isTemporaryUser }): string =>
      isTemporaryUser ? theme.COLOR.ERROR['900'] : theme.COLOR.MEDIUM_PURPLE};
  }
`;

export const UserInfoTitleEditContainerStyled = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  flex: 1 1 auto;
`;

export const UserInfoParamsContainerStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  ${({ theme }): string => (theme.IS_MOBILE ? 'flex-direction: column' : '')};
`;

export const UserInfoPropStyled = styled.div`
  flex: 1 1 auto;
`;

export const UserInfoPropTitleStyled = styled.div`
  ${({ theme }): string =>
    fontSettings(
      theme.IS_MOBILE ? theme.FONT.SIZE_13 : theme.FONT.SIZE_15,
      theme.FONT
    )};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 4px;
`;

export const UserInfoPropValueStyled = styled.a<props>`
  ${({ theme }): string =>
    fontSettings(
      theme.IS_MOBILE ? theme.FONT.SIZE_15 : theme.FONT.SIZE_17,
      theme.FONT
    )};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  word-break: break-word;
  cursor: ${({ link }): string => (link ? 'pointer' : 'initial')};
  text-decoration: ${({ link }): string => (link ? 'underline' : 'none')};
`;
