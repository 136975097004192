import React, { ReactElement } from 'react';
import {
  IconInfoCircledComponent,
  Nullable,
  TagLabelColor,
  TagLabelContainer,
  TagLabelIconPosition,
  useTheme,
} from '@lerna-core';
import { TooltipContainer } from '@features/common/tooltip';
import { CompanyBonusPointsTagContainerProps } from './companyBonusPointsCompensationTag.model';
import { CompanyBonusPointsCompensationTagStyled } from './companyBonusPointsCompensationTag.styled';
import { useCompanyBonusPointsCompensationTag } from './companyBonusPointsCompensationTag.hook';

export const CompanyBonusPointsCompensationTagContainer = ({
  price,
  translations,
  size,
  course,
  courseIndex,
  borderRadius = 100,
}: CompanyBonusPointsTagContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const { showTag, tagLabel, tooltipContent, handleTooltipOpen } =
    useCompanyBonusPointsCompensationTag(
      price,
      translations,
      course,
      courseIndex
    );

  if (!showTag) return null;

  return (
    <TooltipContainer content={tooltipContent} onOpen={handleTooltipOpen}>
      <CompanyBonusPointsCompensationTagStyled>
        <TagLabelContainer
          noWrap={false}
          label={tagLabel}
          color={TagLabelColor.subscription}
          size={size}
          iconPosition={TagLabelIconPosition.right}
          borderRadius={borderRadius}
          icon={
            <IconInfoCircledComponent
              fillColor={theme.COLOR.SUBSCRIPTION}
              strokeColor={theme.COLOR.SUBSCRIPTION}
            />
          }
        />
      </CompanyBonusPointsCompensationTagStyled>
    </TooltipContainer>
  );
};
