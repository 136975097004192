import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { publicConfig, urlService } from '../../services';
import { ButtonColor, ButtonContainer } from '../button';
import { useTranslations } from '../translations';
import { AccessDeniedContainerProps } from './accessDenied.model';
import {
  AccessDeniedStyled,
  AccessDeniedTextStyled,
  AccessDeniedTitleStyled,
} from './accessDenied.styled';
import { CookieNotificationContextProvider } from '../cookiesNotification';

export const AccessDeniedContainer = ({
  showLogoutLink = false,
  handleLogout = () => {},
}: AccessDeniedContainerProps): ReactElement => {
  const translations = useTranslations();

  const router = useRouter();

  const handleReturnToCourses = (): void => {
    router.push(
      urlService.normalizeUrl(publicConfig?.COURSES_APP_LINK),
      undefined,
      { scroll: false }
    );
  };

  const buttonTitle = showLogoutLink
    ? translations.change_user
    : translations.courses_catalog;

  return (
    <CookieNotificationContextProvider>
      <AccessDeniedStyled>
        <AccessDeniedTitleStyled>
          {translations.error_page_403_title}
        </AccessDeniedTitleStyled>
        <AccessDeniedTextStyled>
          {translations.error_cod_title} 403
        </AccessDeniedTextStyled>
        <AccessDeniedTextStyled>
          {translations.error_page_403_description}
        </AccessDeniedTextStyled>
        <ButtonContainer
          color={ButtonColor.white}
          title={buttonTitle}
          onClick={showLogoutLink ? handleLogout : handleReturnToCourses}
        />
      </AccessDeniedStyled>
    </CookieNotificationContextProvider>
  );
};
