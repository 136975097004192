import { ApiDiscountModel, DiscountModel } from './discount.model';
import { ApiInstallmentModel, InstallmentModel } from './installment.model';
import { ApiCashBackModel, CashBackModel } from './cashBack.model';
import { Nullable } from './nullable.model';

export type ApiPriceModel = {
  amount: string;
  raw_amount?: Nullable<string>;
  currency: string;
};

export type ApiPriceWithDiscountModel = ApiPriceModel & {
  discount: Nullable<ApiDiscountModel>;
  total_discount: Nullable<ApiDiscountModel>;
};

export type PriceWithPromoCodeDiscountApiModel = ApiPriceModel & {
  discount: Nullable<ApiDiscountModel>;
};

export type ApiPriceCustomerModel = ApiPriceWithDiscountModel & {
  installment: ApiInstallmentModel;
  bonuses: ApiCashBackModel & {
    spending: number;
    discount: ApiDiscountModel;
  };
  compensation: Nullable<CompensationApiModel>;
  compensation_with_bonuses: Nullable<ApiDiscountModel>;
  partnership_discount: Nullable<PartnershipDiscountApiModel>;
  vendor_with_partnership_discount: Nullable<ApiDiscountModel>;
};

export type PriceValueModel = {
  amount: string;
  currency: string;
};

export type PriceWithDiscountModel = PriceValueModel & {
  discount: Nullable<DiscountModel>;
  totalDiscount: Nullable<DiscountModel>;
};

export type PriceCustomerModel = PriceWithDiscountModel & {
  installment: Nullable<InstallmentModel>;
  discountBonuses: Nullable<DiscountModel>;
  compensation: Nullable<CompensationModel>;
  compensationWithBonuses: Nullable<DiscountModel>;
  partnershipDiscount: Nullable<PartnershipDiscountModel>;
  vendorWithPartnershipDiscount: Nullable<DiscountModel>;
  cashBack?: CashBackModel;
};

export type PriceWithProgramDiscountApiModel = ApiPriceWithDiscountModel & {
  text_blocks: {
    label_text: string;
    prices_block_text: string;
  };
};

export type PriceWithProgramDiscountModel = PriceWithDiscountModel & {
  textBlocks: {
    labelText: string;
    pricesBlockText: string;
  };
};

export type PriceWithPromoCodeDiscountModel = PriceValueModel & {
  discount: Nullable<DiscountModel>;
};

export enum CompensationTypes {
  absolute = 'absolute',
  percent = 'percent',
}

export type CompensationApiModel = {
  data: {
    discount: ApiDiscountModel;
    id: string;
    type: CompensationTypes;
  };
};

export type CompensationModel = {
  discount: DiscountModel;
  id: string;
  type: CompensationTypes;
};

export type PartnershipDiscountApiModel = {
  data: {
    discount: ApiDiscountModel;
  };
};

export type PartnershipDiscountModel = {
  discount: DiscountModel;
};
