import { CareerModel, UserAggregateModel, UserRole } from './user.model';
import { stringService } from '../../services';
import { Nullable, TranslationModel } from '../../models';

/**
 * Get user role
 * @return {string}
 * @params - user
 * */
export const getUserRole = (career?: CareerModel): UserRole => {
  switch (true) {
    case !career:
      return UserRole.customer;
    case career?.roles?.includes(UserRole.manager):
      return UserRole.manager;
    case career?.roles?.includes(UserRole.customer):
      return UserRole.customer;
    case career?.roles?.includes(UserRole.admin):
      return UserRole.admin;
    default: {
      return UserRole.customer;
    }
  }
};

/**
 * Checking the user role
 * @return {string}
 * @params - user
 * */
export const checkUserRole = (
  role: UserRole,
  career?: CareerModel
): boolean => {
  if (!career) {
    return role === UserRole.customer;
  }

  return !!career?.roles?.includes(role);
};

export const getUserRoleTitle = (
  roles: UserRole[],
  translations: TranslationModel,
  company?: Nullable<string>,
  invitedAt?: Nullable<string>
): Nullable<string> => {
  switch (true) {
    case roles.includes(UserRole.manager):
      return translations.hr;
    case roles.includes(UserRole.customer) && !company:
      return translations.student;
    case roles.includes(UserRole.customer):
      return translations.customers_count_label_1;
    case !!invitedAt && !!company:
      return translations.customers_count_label_1;
    case !!invitedAt && !company:
      return translations.student;
    default:
      return null;
  }
};

/**
 * Get bonus points label
 * @return {string}
 * @params - points
 * @params - pluralTranslationsArray
 *
 * */

export const getBonusPointsLabel = (
  points = 0,
  pluralTranslationsArray: [string, string, string]
): string => {
  return `${stringService.numberWithSpaces(
    points
  )} ${stringService.pluralTranslations(points, pluralTranslationsArray)}`;
};

export const checkUserRegistered = (
  user: Nullable<UserAggregateModel>
): boolean => {
  const isVerified = user?.career?.isVerified;
  const isFulfilled = user?.personal?.isFulfilled;

  return Boolean(isVerified && isFulfilled);
};

export const checkUserHasFullPersonalInfo = (
  user: Nullable<UserAggregateModel>
): boolean => {
  const isRegistered = checkUserRegistered(user);
  const hasEducationStreams = user?.personal?.educationStreams?.length;

  return Boolean(isRegistered && hasEducationStreams);
};
