import React, { ReactElement } from 'react';
import { AdvantagesItemStyled } from './advantagesItem.styled';
import { FormAdvantagesItem } from './advantagesItem.model';
import {
  IconsCheckComponent,
  IconsCrossComponent,
} from '@features/common/forms/components/icons';

export const AdvantagesItemContainer = ({
  isOk,
  text,
}: FormAdvantagesItem): ReactElement => {
  const icon = isOk ? (
    <IconsCheckComponent width={24} height={24} />
  ) : (
    <IconsCrossComponent width={24} height={24} />
  );

  return (
    <AdvantagesItemStyled>
      {icon}
      {text}
    </AdvantagesItemStyled>
  );
};
