import { publicConfig, RequestHeader, serverConfig } from '../../../services';
import { RequestClient } from '../../../services/request/request.service';
import { userMenuAdapter } from './userMenu.adapter';
import { UserMenuApiResponseModel, UserMenuModel } from './userMenu.model';

export class UserMenuClient extends RequestClient<
  null,
  UserMenuApiResponseModel,
  UserMenuModel[]
> {
  protected path = 'user-api/v1/navigations';
  protected requestParameters: null;
  protected adapter = userMenuAdapter;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
  }
}
