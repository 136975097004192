import React, { ReactElement, useEffect, useMemo } from 'react';

import { useDidMount } from '../../../hooks';
import { ButtonColor, ButtonContainer } from '../../button';
import {
  FormAgreementContainer,
  PhoneControlComponent,
  TextControlComponent,
} from '../../form';
import {
  MessagePopupComponent,
  MessagePopupStatus,
  PopupContainer,
} from '../../popups';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import {
  CommonEvents,
  CommonEventTypes,
  getProjectEventPosition,
} from '../../../analytics';
import { ContactUsFormContainerProps } from './contactUsForm.model';
import { useContactUs, useContactUsForm } from './contactUsForm.hooks';
import {
  ContactUsFormHeaderStyled,
  ContactUsFormStyled,
  ContactUsFormWrapperStyled,
  HeaderSubtitleStyled,
  HeaderTitleStyled,
  TextAreaStyled,
} from './contactUsForm.styled';
import { modalPaddings, modalPaddingsWithFooter } from './contactsUsForm.meta';

const MAX_ATTEMPTS = 3;

export const ContactUsFormContainer = ({
  open,
  handleClose,
  translations,
  usagePosition,
}: ContactUsFormContainerProps): ReactElement => {
  const didMount = useDidMount();

  const eventPosition = useMemo(
    () => getProjectEventPosition(usagePosition),
    []
  );

  const {
    onSubmit,
    attemptNumber,
    setAttemptNumber,
    clearStatus,
    isErrorDisplayed,
    isSuccessDisplayed,
  } = useContactUs(handleClose, eventPosition);

  const {
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    setErrors,
    setTouched,
  } = useContactUsForm(onSubmit);

  const formNeedHelpTitle = translations?.form_need_help_title || '';
  const formNeedHelpSubTitle = translations?.form_need_help_description || '';

  const errorButtonTitle =
    attemptNumber < MAX_ATTEMPTS
      ? translations?.button_resend_title || null
      : null;

  const resend = (): void => {
    onSubmit(values);
  };

  useEffect(() => {
    if (open) {
      resetForm();
      setAttemptNumber(0);
    }

    if (eventPosition && open) {
      pushDataLayer(
        getSimpleEvent(
          CommonEvents.formAskQuestionOpen,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
    if (eventPosition && didMount && !open) {
      pushDataLayer(
        getSimpleEvent(
          CommonEvents.formAskQuestionClose,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
  }, [open]);

  const footer = (
    <ButtonContainer
      title={translations?.form_send_button_title}
      borderRadius={100}
      fullWidth
      onClick={handleSubmit}
      color={ButtonColor.purple}
      disabled={isSubmitting || !isValid}
    />
  );

  return (
    <>
      <PopupContainer
        handleClose={handleClose}
        isOpened={open}
        footer={footer}
        contentStyles={{ paddingBottom: 32 }}
        styleSettings={modalPaddingsWithFooter}
        width={'600px'}
      >
        <ContactUsFormWrapperStyled>
          <ContactUsFormHeaderStyled>
            <HeaderTitleStyled>{formNeedHelpTitle}</HeaderTitleStyled>
            <HeaderSubtitleStyled>{formNeedHelpSubTitle}</HeaderSubtitleStyled>
          </ContactUsFormHeaderStyled>

          <ContactUsFormStyled>
            <TextControlComponent
              value={values.firstName}
              errorMessage={errors.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.firstName}
              label={translations?.input_name_label}
              placeholder={translations?.placeholder_name}
              setFieldValue={setFieldValue}
              name="firstName"
              type="text"
            />

            <TextControlComponent
              value={values.email}
              errorMessage={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              label={translations?.input_email_label}
              setFieldValue={setFieldValue}
              placeholder={translations?.placeholder_email}
              name="email"
              type="email"
            />

            <PhoneControlComponent
              errorMessage={errors.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              label={translations?.input_phone_label}
              name="phone"
              touched={touched.phone}
              setFieldValue={setFieldValue}
            />

            <TextAreaStyled
              errorMessage={errors.message}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              label={translations?.input_message_label}
              touched={touched.message}
              name="message"
            />
            <FormAgreementContainer
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setErrors={setErrors}
              setTouched={setTouched}
              handleChange={handleChange}
              isSubmitting={isSubmitting}
            />
          </ContactUsFormStyled>
        </ContactUsFormWrapperStyled>
      </PopupContainer>

      <PopupContainer
        handleClose={clearStatus}
        isOpened={isErrorDisplayed || isSuccessDisplayed}
        hasHeader={false}
        styleSettings={modalPaddings}
        width={'600px'}
      >
        {isErrorDisplayed && (
          <MessagePopupComponent
            status={MessagePopupStatus.error}
            title={translations?.popup_error_message_title}
            subtitle={`${translations?.popup_error_message_description}.`}
            buttonTitle={errorButtonTitle}
            buttonCallback={resend}
          />
        )}

        {isSuccessDisplayed && (
          <MessagePopupComponent
            status={MessagePopupStatus.success}
            title={translations?.popup_success_message_title}
            subtitle={`${translations?.popup_success_message_description}.`}
            buttonTitle={translations?.button_ok_title}
            buttonCallback={clearStatus}
          />
        )}
      </PopupContainer>
    </>
  );
};
