import React, { ReactElement, useEffect } from 'react';
import { ButtonColor, ButtonContainer, ButtonSize } from '../../button';
import * as S from './notificationManagement.styled';
import { useTranslations } from '../../translations';
import { Nullable } from '../../../models';
import {
  isNotificationVisible,
  setCookiePermissions,
} from './notificationManagement.helper';
import { CookiePermissionsModel } from './notificationManagement.model';
import { CookieTypes } from '../cookiesNotification.model';
import { ManagementPopupContainer } from './managementPopup';
import { notificationContent } from './notificationManagement.meta';
import { useCookieNotificationManagementContext } from './notificationManagement.context';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import { CommonEventPositions, CommonEventTypes } from '../../../analytics';
import { NotificationManagementEvent } from './notificationManagement.analytics';

export const NotificationManagement = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const {
    handleOpenPopup,
    handleClosePopup,
    isPopupOpened,
    isVisible,
    setInvisible,
    setVisible,
  } = useCookieNotificationManagementContext();
  const setPermissions = (permissions: CookiePermissionsModel): void => {
    setCookiePermissions(permissions);
    setInvisible();
  };

  const handleAccept = () => {
    pushDataLayer(
      getSimpleEvent(
        NotificationManagementEvent.clickAcceptAllSettings,
        CommonEventTypes.overallEvents,
        CommonEventPositions.footer
      )
    );
    setPermissions({
      [CookieTypes.technical]: true,
      [CookieTypes.advertising]: true,
      [CookieTypes.analytics]: true,
    });
  };

  const handleClose = () => {
    setPermissions({
      [CookieTypes.technical]: true,
      [CookieTypes.advertising]: false,
      [CookieTypes.analytics]: false,
    });
  };

  const handleCancel = () => {
    pushDataLayer(
      getSimpleEvent(
        NotificationManagementEvent.clickAcceptTechnicalSettings,
        CommonEventTypes.overallEvents,
        CommonEventPositions.footer
      )
    );
    handleClose();
  };

  const handleMoreClick = () => {
    pushDataLayer(
      getSimpleEvent(
        NotificationManagementEvent.clickLinkDetailsCookie,
        CommonEventTypes.overallEvents,
        CommonEventPositions.footer
      )
    );
    handleOpenPopup();
  };

  useEffect(() => {
    if (isNotificationVisible()) {
      pushDataLayer(
        getSimpleEvent(
          NotificationManagementEvent.bannerCookieAutoOpen,
          CommonEventTypes.overallEvents,
          CommonEventPositions.footer
        )
      );
      setVisible();
    }
  }, []);

  return (
    <>
      {isVisible && (
        <S.NotificationManagement>
          <S.NotificationManagementText>
            {notificationContent.text}{' '}
            <S.NotificationManagementLink onClick={handleMoreClick}>
              {translations.cookie_notification_link || 'Подробнее'}
            </S.NotificationManagementLink>
          </S.NotificationManagementText>
          <S.NotificationManagementButton>
            <ButtonContainer
              title={notificationContent.cancel}
              size={ButtonSize.small}
              color={ButtonColor.white}
              onClick={handleCancel}
            />
          </S.NotificationManagementButton>
          <S.NotificationManagementButton>
            <ButtonContainer
              title={notificationContent.accept}
              size={ButtonSize.small}
              color={ButtonColor.white}
              onClick={handleAccept}
            />
          </S.NotificationManagementButton>
        </S.NotificationManagement>
      )}
      <ManagementPopupContainer
        isOpened={isPopupOpened}
        handleClose={handleClosePopup}
      />
    </>
  );
};
