import { RequestHeader } from '../../../services';
import { RequestClient } from '../../../services/request/request.service';
import { AxiosRequestConfig } from 'axios';
import {
  CoursesFilterOption,
  CoursesFilterOptionsApiResponse,
} from './coursesFilters.model';
import { courseFilterOptionsApiResponseAdapter } from './coursesFilters.adapter';

const FILTER_SLUG = '{{SLUG}}';

export class CoursesFiltersClient extends RequestClient<
  null,
  CoursesFilterOptionsApiResponse,
  CoursesFilterOption[]
> {
  protected path = `course-api/v1/catalog/filters/${FILTER_SLUG}/options`;
  protected requestParameters: null;
  protected adapter = courseFilterOptionsApiResponseAdapter;

  constructor(
    host: string,
    privateHost: string = '',
    slug: string,
    additionalHeaders?: RequestHeader,
    requestConfigs?: AxiosRequestConfig
  ) {
    super(host, privateHost, additionalHeaders, requestConfigs);
    this.requestParameters = null;
    this.path = this.path.replace(FILTER_SLUG, slug);
  }
}
