import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const AdvantagesItemStyled = styled.div`
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    margin-right: 8px;
  }
`;
