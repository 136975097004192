import React, { ReactElement } from 'react';
import { GranuleTagContainerStyled } from './granuleTag.styled';
import { GranuleTagContainerProps } from './granuleTag.model';
import { TagLabelColor, TagLabelContainer } from '@lerna-core';

export const GranuleTagContainer = ({
  label,
  size,
  thin = false,
  borderRadius,
}: GranuleTagContainerProps): ReactElement => {
  return (
    <GranuleTagContainerStyled thin={thin}>
      <TagLabelContainer
        noWrap={false}
        label={label}
        color={TagLabelColor.grey}
        size={size}
        borderRadius={borderRadius}
      />
    </GranuleTagContainerStyled>
  );
};
