import { ControlValidationRule } from '../../hooks';
import { testPreferredPhonesLength } from '../inputs';

export const phoneRegex =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)[0-9 \-\(\)\.]{7,32}\d$/;
export const emailRegex =
  /^\s*?(?!.*?\.\.)+(?!\.)[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\s*?$/;

export const isRequiredValidation = (value: string): boolean =>
  value.length === 0;

export const numberRegex = /^\d*\.?\d*$/;

export const isEmptyValidation = (value: string): boolean =>
  value.length !== 0 && value.trim().length === 0;

export const maxLengthValidation = (max: number, value: string): boolean =>
  value.length > max;

const requiredRule: ControlValidationRule = {
  rule: isRequiredValidation,
  messageTranslationKey: 'input_required_error_label',
};

export const notEmptyRule: ControlValidationRule = {
  rule: isEmptyValidation,
  messageTranslationKey: 'input_empty_error_label',
};

export const phoneValidations: ControlValidationRule[] = [
  requiredRule,
  {
    rule: (value): boolean => !phoneRegex.test(value),
    messageTranslationKey: 'input_phone_format_error_label',
  },
  {
    rule: (value: string): boolean => !testPreferredPhonesLength(value),
    messageTranslationKey: 'input_phone_format_error_label',
  },
];

export const emailValidations: ControlValidationRule[] = [
  requiredRule,
  {
    rule: (value): boolean => !emailRegex.test(value),
    messageTranslationKey: 'input_email_format_error_label',
  },
];

export const maxLengthRule = (max: number): ControlValidationRule => ({
  rule: maxLengthValidation.bind(null, max),
  // TODO: переделать с ключей на строки
  messageTranslationKey: `max_symbols_length_${max}`,
});

type NumberWithLimitsProps = {
  message: string;
  value: number;
};

export const numberWithLimitsValidations = (
  min: NumberWithLimitsProps,
  max: NumberWithLimitsProps
): ControlValidationRule[] => [
  requiredRule,
  {
    rule: (value): boolean => !numberRegex.test(value),
    messageTranslationKey: 'input_value_should_be_digits',
  },
  {
    rule: (value): boolean => !(+value >= min.value),
    messageTranslationKey: '',
    message: min.message,
  },
  {
    rule: (value): boolean => !(+value <= max.value),
    messageTranslationKey: '',
    message: max.message,
  },
];

export const integerValidation: ControlValidationRule = {
  rule: (value): boolean => !Number.isInteger(+value),
  messageTranslationKey: 'value_must_be_integer',
};

export const textValidations: ControlValidationRule[] = [
  requiredRule,
  notEmptyRule,
];

export const selectValidations: ControlValidationRule[] = [requiredRule];
