import { Nullable, TariffModel, TariffSlugEnum, useUser } from '@lerna-core';

type UseUserTariffHookModel = {
  userTariff: Nullable<TariffModel>;
  isLernaPrime: boolean;
};

export const useUserTariff = (): UseUserTariffHookModel => {
  const { user } = useUser();

  const userTariff = user?.career?.tariff || null;
  const isLernaPrime = userTariff?.slug === TariffSlugEnum.prime;

  return {
    userTariff,
    isLernaPrime,
  };
};
