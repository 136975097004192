import styled from 'styled-components';
import { StepperItemStyledProps } from './stepperItem.model';
import { ITheme } from '../../themes';
import { fontSettings } from '../../../styles';

const getStepperItemStyles = (
  theme: ITheme,
  isActive?: boolean,
  beforeActive?: boolean,
  isClickable?: boolean
): string => {
  switch (true) {
    case beforeActive:
      return `
        &::before {
          background: ${theme.COLOR.MEDIUM_PURPLE};
        }
      ${isClickable ? 'cursor: pointer' : ''}
      `;
    case isActive:
    default:
      return `
        &:not(:last-child) {
          &::before {
            background: rgba(0, 0, 0, 0.12);
          }
        }
        ${isClickable ? 'cursor: pointer' : ''}
      `;
  }
};

export const StepperItemStyled = styled.div<StepperItemStyledProps>`
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-right: 80px;

    &::before {
      content: '';
      position: absolute;
      left: calc(100% + 13px);
      top: 50%;
      transform: translateY(-50%);
      width: 55px;
      height: 2px;
      cursor: default;
      pointer-events: none;
    }
  }

  ${({ isActive, beforeActive, theme, isClickable }): string =>
    getStepperItemStyles(theme, isActive, beforeActive, isClickable)}
`;

const getProgressbarCountStyles = (
  theme: ITheme,
  isActive?: boolean,
  beforeActive?: boolean
): string => {
  switch (true) {
    case isActive:
      return `
      background: ${theme.COLOR.MEDIUM_PURPLE};
      color: ${theme.COLOR.WHITE};
      border: 1px solid transparent;
        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          right: -5px;
          bottom: -5px;
          border-radius: 50%;
          border: 1px solid ${theme.COLOR.MEDIUM_PURPLE};
          background: transparent;
        }
      `;
    case beforeActive:
      return `
        background: ${theme.COLOR.MEDIUM_PURPLE};
        color: ${theme.COLOR.WHITE};
        border: 1px solid transparent;
      `;
    default:
      return `
        background: transparent;
        color: ${theme.COLOR.TEXT_LIGHT_COLOR};
        border: 1px solid rgba(0, 0, 0, 0.12);
      `;
  }
};

export const StepperItemCountStyled = styled.div<StepperItemStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: 500;
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};

  ${({ isActive, beforeActive, theme }): string =>
    getProgressbarCountStyles(theme, isActive, beforeActive)}
`;

export const StepperItemTitleStyled = styled.div`
  margin-left: 12px;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
`;
