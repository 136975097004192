import { useMemo } from 'react';
import {
  BannerModel,
  BannerPlacementTypes,
  Nullable,
  useUser,
} from '@lerna-core';
import { getBannerImageResources } from './headerBanner.helpers';
import { HeaderBannerHookModel } from './headerBanner.model';

export const useHeaderBanner = (
  banners: Nullable<BannerModel[]>
): HeaderBannerHookModel => {
  const { user } = useUser();

  const banner = useMemo(
    () =>
      banners?.find(
        (banner) => banner.placementType === BannerPlacementTypes.header
      ),
    []
  );
  const bannerImageResources = useMemo(
    () => getBannerImageResources(banner?.images),
    []
  );

  const showBanners =
    !!banner && !!bannerImageResources && !user?.career?.isWithoutPromoBanner;

  return {
    bannerImageResources,
    showBanners,
    banner,
  };
};
