import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPlusComponent = ({
  width = 16,
  height = 16,
  strokeColor = '#000000',
  strokeWidth = '1.5',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2V8M8 8H14M8 8H2M8 8V14"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
