import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const TableEmptyStyled = styled.tr`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const TableEmptyTitle = styled.td``;

export const TableEmptyText = styled.td``;
