import React, { ReactElement } from 'react';

import { HorizontalLineStyled } from '../../horizontalLine';
import { LoaderContainer } from '../../loader';
import { PaperComponentStyled } from '../../paper';
import { InfoCompanyContainer } from './infoCompany';
import { InfoPersonalContainer } from './infoPersonal';
import { UserInfoContainerProps } from './userInfo.model';
import { hasBonusPoints } from '../../bonusPoints';
import { useUser } from '../user.hook';
import { InfoEducationStreamsContainer } from './infoEducationStreams';
import { personalPath, projectSubDomains } from '../../../constants';
import { urlService } from '../../../services';

export const UserInfoContainer = ({
  allowEditCareer = false,
  allowEdit = false,
  onEdit,
  onEditCareer,
  bonusPageLink,
  isMobile,
}: UserInfoContainerProps): ReactElement => {
  const { user } = useUser();

  if (user?.career) {
    const isb2b = Boolean(user.career.companyId);
    const points = hasBonusPoints(user?.career)
      ? user.bonuses.points
      : undefined;
    const educationStreams = user?.personal?.educationStreams;

    return (
      <PaperComponentStyled>
        <InfoPersonalContainer
          points={points}
          personal={user.personal}
          career={user.career}
          allowEdit={allowEdit}
          handleClick={onEdit}
          bonusPageLink={bonusPageLink}
          isMobile={isMobile}
          pointsHref={urlService.getFullUrlToSubDomain(
            projectSubDomains.personal,
            personalPath.bonuses
          )}
        />
        {isb2b && (
          <>
            <HorizontalLineStyled />
            <InfoCompanyContainer
              allowSeeCompanyLink
              career={user.career}
              allowEdit={allowEditCareer}
              handleClick={onEditCareer}
            />
          </>
        )}
        {educationStreams && (
          <>
            <HorizontalLineStyled />
            <InfoEducationStreamsContainer
              educationStreams={educationStreams}
            />
          </>
        )}
      </PaperComponentStyled>
    );
  }

  return <LoaderContainer position="relative" />;
};
