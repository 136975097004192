import React, { ReactElement } from 'react';

import { ImageResourceModel } from '../../../../models';
import { ImageContainer } from '../../../image';
import { UserAvatarSize } from '../userAvatar.model';
import { getAvatarImageResource } from './avatarImage.service';
import { AvatarImageStyled } from './avatarImage.styled';

type AvatarImageProps = {
  size: UserAvatarSize;
  isRound?: boolean;
  mediaDomain?: string;
  imageResource: ImageResourceModel | null;
};

export const AvatarImageContainer = ({
  size,
  isRound,
  imageResource,
  mediaDomain,
}: AvatarImageProps): ReactElement => (
  <AvatarImageStyled size={size} isRound={isRound}>
    {imageResource && (
      <ImageContainer
        mediaDomain={mediaDomain}
        imageResource={imageResource}
        preset={getAvatarImageResource(size)}
      />
    )}
  </AvatarImageStyled>
);
