import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMinusComponent = ({
  width = 16,
  height = 16,
  strokeWidth = '1.5',
  strokeColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8H8H2"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
