import styled, { css } from 'styled-components';

import { getInputColor, InputColor } from '../../../styles';
import { setBaseInputStyles } from '../../form';

type TextareaInputProps = {
  disabled?: boolean;
  bordered?: boolean;
  color?: InputColor;
  isMobile?: boolean;
  floatingPlaceholder: boolean;
  hasError: boolean;
};

export const TextareaInputStyled = styled.textarea<TextareaInputProps>`
  ${({ floatingPlaceholder }): ReturnType<typeof css> =>
    setBaseInputStyles(floatingPlaceholder)}
  ${({ bordered, disabled, color, theme, hasError }): string =>
    getInputColor({
      bordered,
      disabled,
      color,
      theme,
      hasError,
    })}

  min-height: 118px;
  resize: none;
`;
