import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const TabsPaymentIconsStyled = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 4px;
    `
  )}
`;

export const IconStyled = styled.div`
  flex: 0 0 auto;
  display: flex;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;
