import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { rgba } from 'polished';

export const ContentInfoStyled = styled.div`
  border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_4};
  padding: 16px 40px;
  text-align: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      padding: 16px 8px;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;

export const TextStyled = styled.div``;

export const SwitcherStyled = styled.div`
  max-width: max-content;
  margin: 0 auto;
  cursor: pointer;

  span {
    border-bottom: 1px solid
      ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.2)};
  }
`;
