import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCheckboxCheckedComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
  fillColor = '#fff',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="4"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M8 12l3 3 5-5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
