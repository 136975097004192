import { ImageApiModel, ImageModel } from './image.model';

export type SeoApiModel = Partial<{
  title: string;
  description: string;
  text: string;
  image: ImageApiModel;
  h1: string;
  no_index: boolean;
  keywords: string;
}>;

export type SeoModel = Partial<{
  title: string;
  description: string;
  text: string;
  image: ImageModel;
  h1: string;
  no_index: boolean;
  keywords: string;
}>;

export enum MetaTagsEnum {
  title = 'metaTitle',
  description = 'metaDescription',
  keywords = 'metaKeywords',
}
