import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconAnalyticsComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '1.8',
  fillColor = '#78747A',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 11l5.5-5.5L14 11l9-9m0 0h-5m5 0v5M3 18v3m5.5 0v-6m5.5 6v-3m5.5 3v-6"
    />
    <circle cx="14" cy="11" r="2.5" fill={fillColor} />
    <circle cx="8.5" cy="5.5" r="2.5" fill={fillColor} />
    <circle cx="3" cy="11" r="2.5" fill={fillColor} />
  </svg>
);
