import styled, { css } from 'styled-components';

import { breakpoint } from '../../styles';

export const PageWrapperContainerStyled = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0 auto;
`;

export const PageWrapperContentStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

type ContentWrapperStyledProps = {
  isMobile?: boolean;
};

export const ContentWrapperStyled = styled.div<ContentWrapperStyledProps>`
  padding: 0 40px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 0 20px;
        max-width: 100%;
      `,
      isMobile
    )}
`;

export const ContentWrapperBigPStyled = styled.div<ContentWrapperStyledProps>`
  padding: 0 140px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  ${breakpoint(
    'xlargeDown',
    css`
      padding: 0 40px;
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
      max-width: 100%;
    `
  )}
`;
