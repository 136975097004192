import React, { ReactElement, useMemo } from 'react';
import { PricesInfoContainerProps } from './pricesInfo.model';
import { PricesInfoStyled } from './pricesInfo.styled';
import { PricesInstallmentContainer } from '../pricesInstallment';
import { PricesTotalDiscountContainer } from '../pricesTotalDiscount';
import { PricesTotalContainer } from '../pricesTotal';
import { getB2CPrices } from '@features/common/course';

export const PricesInfoContainer = ({
  course,
  translations,
  withInstallment,
}: PricesInfoContainerProps): ReactElement => {
  //todo: Вынести цены в контекст
  const {
    hasTotalDiscount,
    totalDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = useMemo(() => getB2CPrices(course), []);

  const showInstallment =
    withInstallment &&
    hasInstallment &&
    !course.isFree &&
    !course.isFreeByDiscount;
  const showTotalPrice =
    !withInstallment ||
    !hasInstallment ||
    course.isFree ||
    course.isFreeByDiscount;
  const showTotalDiscount = hasTotalDiscount && !course.isFree;
  const totalPriceTitle =
    hasOneOfDiscounts && !course.isFree
      ? translations?.total_price_label
      : translations?.item_price_label;

  return (
    <PricesInfoStyled>
      {showInstallment && (
        <PricesInstallmentContainer
          installment={course.price?.installment}
          hasDiscounts={hasOneOfDiscounts}
          translations={translations}
        />
      )}
      {showTotalPrice && (
        <PricesTotalContainer title={totalPriceTitle} value={totalPrice} />
      )}
      {showTotalDiscount && (
        <PricesTotalDiscountContainer
          discount={totalDiscount}
          isMegaDiscount={course.megaDiscount}
        />
      )}
    </PricesInfoStyled>
  );
};
