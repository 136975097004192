export enum RegisterEvents {
  registrationAutoOpen = 'banner_tell_about_auto_open',
  registrationOpen = 'banner_tell_about_open',
  registrationSuccess = 'banner_tell_about_click_registration_success',
  registrationError = 'banner_tell_about_click_registration_error',
  registrationClickClose = 'banner_tell_about_click_close',
  welcomeAutoOpen = 'banner_welcome_auto_open',
  welcomeClickRegistration = 'banner_welcome_click_registration',
  welcomeClickClose = 'banner_welcome_click_close',
  warningAutoOpen = 'message_registration_auto_open',
  warningClick = 'message_click_registration',
}

export enum RegisterPositions {
  welcomeBanner = 'welcome_banner',
  submitForm = 'submit_form',
  personalPage = 'personal_page',
  hrPage = 'hr_page',
  catalogPage = 'catalog_page',
  registerWarning = 'message_registration',
  bannerOnCatalog = 'banner_on_catalog',
  userBar = 'user_profile',
}
