import React, { ReactElement } from 'react';
import { TableSortContainerProps } from './tableSort.model';
import { useTheme } from '../../../hooks';
import { TableSortButtonStyled, TableSortIconStyled } from './tableSort.styled';
import { IconArrowLongComponent } from '../../icons';

export const TableSortContainer = ({
  buttonTitle,
  onClickHandler,
  isActive,
  className,
}: TableSortContainerProps): ReactElement => {
  const theme = useTheme();

  const iconColor = isActive
    ? theme.COLOR.MEDIUM_PURPLE
    : theme.COLOR.TEXT_LIGHT_COLOR;

  return (
    <TableSortButtonStyled onClick={onClickHandler} className={className}>
      {buttonTitle}
      <TableSortIconStyled selected={isActive}>
        <IconArrowLongComponent
          width={16}
          height={16}
          strokeColor={iconColor}
        />
      </TableSortIconStyled>
    </TableSortButtonStyled>
  );
};
