import styled from 'styled-components';

import { positionSticky } from '../../../../../styles';

export const SidebarStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: auto;
  min-height: 250px;
  flex: 0 0 300px;
  margin-right: 40px;
  top: 94px;
  ${positionSticky()}
`;
