import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpoint } from '@lerna-core';

export const BannerContainerStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  border-radius: 16px;
  padding: 20px;
  background-color: ${({ theme }) => rgba(theme.COLOR.WHITE, 0.24)};
  max-width: 552px;
  width: 100%;
  min-width: 0;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${breakpoint(
    'xxlargeDown',
    css`
      max-width: 440px;
    `
  )}
  ${breakpoint(
    'xlargeDown',
    css`
      padding: 16px;
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    `
  )}
`;
