import { ReactElement } from 'react';
import { useCookieNotificationType } from './cookiesNotification.hook';
import { CookiesNotificationEnum } from './cookiesNotification.model';
import { NotificationAgreement } from './notificationAgreement';
import { NotificationManagement } from './notificationManagement';

export const CookiesNotification = (): ReactElement => {
  const cookieNotificationType = useCookieNotificationType();

  switch (cookieNotificationType) {
    case CookiesNotificationEnum.agreement:
      return <NotificationAgreement />;
    case CookiesNotificationEnum.management:
      return <NotificationManagement />;
  }
};
