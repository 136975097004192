import React, { ReactElement } from 'react';

import { useTheme } from '../../../hooks';
import { IconArrowComponent } from '../../icons';
import { AccordionSpoilerContainerProps } from './accordionSpoiler.model';
import { AccordionSpoilerStyled } from './accordionSpoiler.styled';

export const AccordionSpoilerContainer = ({
  title,
  isOpened,
  handleClickItem,
  hasContent,
}: AccordionSpoilerContainerProps): ReactElement => {
  const theme = useTheme();

  const handleClick = (): void => {
    if (hasContent) {
      handleClickItem();
    }
  };

  const getIconColor = (): string =>
    isOpened ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR;

  return (
    <AccordionSpoilerStyled
      isOpened={isOpened}
      onClick={handleClick}
      hasContent={hasContent}
    >
      {title}
      {hasContent && (
        <IconArrowComponent
          width={24}
          height={24}
          strokeColor={getIconColor()}
        />
      )}
    </AccordionSpoilerStyled>
  );
};
