import styled from 'styled-components';

import { InputStyled } from '../input.styled';
import { Type } from './passwordInput.model';

export const PasswordInputStyled = styled(InputStyled)`
  letter-spacing: ${(props): string =>
    props.type === Type.password ? '3px' : 'inherit'};

  ${({ floatingPlaceholder, theme }): string =>
    floatingPlaceholder
      ? ''
      : `
          ::placeholder {
            letter-spacing: 3px;
            color: ${theme.COLOR.TEXT_LIGHT_COLOR};
          }
        `};
`;
