import { ReactElement, useEffect, useState } from 'react';
import { PopupPortalContainerProps } from './popupPortal.model';
import ReactDOM from 'react-dom';

export const PopupPortalContainer = ({
  children,
}: PopupPortalContainerProps): ReactElement => {
  const [container] = useState<HTMLDivElement>(() =>
    document.createElement('div')
  );

  useEffect(() => {
    const parent = document.querySelector('#__next') || document.body;
    parent.appendChild(container);

    return (): void => {
      parent.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};
