import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const SeoTextShowMoreContainerStyled = styled.div`
  margin-top: 16px;
  color: ${({ theme }): string => theme.COLOR.PURPLE};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  font-weight: 500;
  text-decoration: none;

  span {
    cursor: pointer;
  }
`;
