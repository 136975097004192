import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FullAccessContentStyled } from './fullAccessContent.styled';

export const FullAccessContentContainer = (): ReactElement => (
  <FullAccessContentStyled>
    <ContentLeftContainer />
    <ContentRightContainer />
  </FullAccessContentStyled>
);
