import React, { ReactElement } from 'react';
import { TooltipComponent } from '@lerna-core';
import { ItemLabelProps } from '../itemLabel.model';
import { LabelDesktopContainerStyled } from './labelDesktop.styled';

export const LabelDesktopContainer = ({
  label,
}: ItemLabelProps): ReactElement => {
  return (
    <TooltipComponent title={label}>
      <LabelDesktopContainerStyled>{label}</LabelDesktopContainerStyled>
    </TooltipComponent>
  );
};
