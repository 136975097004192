import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type UseToggle = [
  boolean,
  () => void,
  Dispatch<SetStateAction<boolean>>
];

export const useToggle = (defaultValue?: boolean): UseToggle => {
  const [value, setValue] = useState<boolean>(Boolean(defaultValue));

  const toggle = useCallback(() => setValue((prevValue) => !prevValue), []);

  return [value, toggle, setValue];
};
