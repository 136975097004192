import { CourseItemModel } from './courseItem.model';
import { CompanyUuidEnum } from '../../company';
import { clearVendor, CourseTypes } from '../../course';

const coursesToPop = [
  { id: 'a75de26d-5f47-4689-9002-82ea1fbfd921', order: 0 },
  { id: 'd0f36c27-3b7b-48ae-9b80-bbb3bdaa5144', order: 1 },
  { id: '7b894755-3091-40ac-b6a1-ef4830b7f119', order: 2 },
];

const filterAlfaBankSpecCustomerCourses = (
  courses: CourseItemModel[]
): CourseItemModel[] =>
  courses.filter(
    (course) =>
      !coursesToPop.find((courseToPop) => courseToPop.id === course.courseId)
  );

const filterAlfaBankSpecCustomerPopCourses = (
  courses: CourseItemModel[]
): CourseItemModel[] => {
  const popCourses = courses.reduce((acc: CourseItemModel[], course) => {
    const currentCourseToPop = coursesToPop.find(
      (courseToPop) => courseToPop.id === course.courseId
    );
    if (currentCourseToPop) {
      acc.push({
        ...course,
        order: currentCourseToPop.order,
      });
    }

    return acc;
  }, []);

  return popCourses.sort((a, b) => (a.order as number) - (b.order as number));
};

export const filterCustomerCourses = (
  courses: CourseItemModel[],
  companyId?: string
): CourseItemModel[] => {
  switch (companyId) {
    case CompanyUuidEnum.alfaBankSpecCourses:
      return filterAlfaBankSpecCustomerCourses(courses);
    default:
      return courses;
  }
};

export const filterCustomerPopCourses = (
  courses: CourseItemModel[],
  companyId?: string
): CourseItemModel[] => {
  switch (companyId) {
    case CompanyUuidEnum.alfaBankSpecCourses:
      return filterAlfaBankSpecCustomerPopCourses(courses);
    default:
      return [];
  }
};

export const clearAcademikaCourseItemsVendor = (
  courses: CourseItemModel[]
): CourseItemModel[] =>
  courses.map((course) =>
    course.type === CourseTypes.academika ? clearVendor(course) : course
  );
