import React, { createContext, ReactElement, useContext, useMemo } from 'react';
import { logger } from '../../../services';
import {
  CustomersTransferContextProviderProps,
  CustomersTransferContextReturnType,
} from './customersTransfer.model';

const CustomersTransferContext =
  createContext<CustomersTransferContextReturnType>(
    {} as CustomersTransferContextReturnType
  );

export const CustomersTransferContextProvider = ({
  children,
  customersProvider,
  customersAllProvider,
  accessors,
  dispatch,
  withDisabledRows,
  subscriptionId,
  benefitId,
  libraryId,
  tariffId,
  isRadioButton,
  totalCustomers,
  isUnlimited,
  tariffs,
}: CustomersTransferContextProviderProps): ReactElement => {
  const context = useMemo(
    () =>
      ({
        customersProvider,
        customersAllProvider,
        accessors,
        withDisabledRows,
        dispatch,
        subscriptionId,
        libraryId,
        benefitId,
        tariffId,
        isRadioButton,
        totalCustomers,
        tariffs,
        id: subscriptionId || libraryId || benefitId || tariffId,
        isUnlimited,
      } as CustomersTransferContextReturnType),
    [
      customersProvider,
      withDisabledRows,
      customersAllProvider,
      accessors,
      libraryId,
      benefitId,
      tariffId,
      subscriptionId,
      isRadioButton,
      totalCustomers,
      tariffs,
    ]
  );

  return (
    <CustomersTransferContext.Provider value={context}>
      {children}
    </CustomersTransferContext.Provider>
  );
};

export const useCustomersTransferContext =
  (): CustomersTransferContextReturnType => {
    const value = useContext(CustomersTransferContext);

    if (!value) {
      logger.error('[ERROR]: you cannot use context without a provider');

      // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
      // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
      return {} as CustomersTransferContextReturnType;
    }

    return value;
  };
