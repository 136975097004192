import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { Translations } from './translations.model';

export class TranslationsClient extends SecureRequestClient<
  null,
  Translations,
  Translations
> {
  protected path = 'aggregate/v1/translations';
  protected requestParameters: null;

  constructor() {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
    );
    this.requestParameters = null;
  }
}
