import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';

export const PaperFooterContainerStyled = styled.div`
  text-align: center;
  margin-top: 32px;
  margin-bottom: -12px;
  padding: 20px 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 24px;
      margin-bottom: -4px;
    `
  )}
`;

export const PaperFooterWrapStyled = styled.span`
  font-weight: 500;
  color: ${(props): string => props.theme.COLOR.PURPLE};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  ${(props): string => fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}

  a {
    color: ${(props): string => props.theme.COLOR.PURPLE};
    text-decoration: none;
  }
`;
