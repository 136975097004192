import React, { ReactElement } from 'react';
import { CommonEventPositions, Nullable } from '@lerna-core';
import {
  GuestOrderFormContainer,
  UserOrderFormContainer,
} from '@features/common/forms';
import { useCoursePageContext } from '@features/course/course.context';

export const CourseSignUpContainer = (): Nullable<ReactElement> => {
  const { user, course, translations } = useCoursePageContext();

  if (user) {
    return (
      <UserOrderFormContainer
        translations={translations}
        course={course}
        user={user}
        eventPosition={CommonEventPositions.coursePagePrice}
      />
    );
  }

  return (
    <GuestOrderFormContainer
      course={course}
      translations={translations}
      eventPosition={CommonEventPositions.coursePagePrice}
    />
  );
};
