import React, { ReactElement } from 'react';
import {
  PricesInstallmentStyled,
  DurationStyled,
  ValueStyled,
  ValueWithDiscountStyled,
  WrapperStyled,
} from './pricesInstallment.styled';
import { PricesInstallmentContainerProps } from './pricesInstallment.model';
import { Nullable } from '@lerna-core';

export const PricesInstallmentContainer = ({
  installment,
  translations,
  hasDiscounts,
}: PricesInstallmentContainerProps): Nullable<ReactElement> => {
  if (!installment) return null;

  return (
    <PricesInstallmentStyled>
      <WrapperStyled>
        <ValueWithDiscountStyled>
          {installment.priceWithTotalDiscount}
        </ValueWithDiscountStyled>
        {hasDiscounts && <ValueStyled>{installment.price}</ValueStyled>}
      </WrapperStyled>
      <DurationStyled>
        {translations.installment_price_label} {installment.duration}
      </DurationStyled>
    </PricesInstallmentStyled>
  );
};
