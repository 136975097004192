import { Dispatch } from 'react';

import * as constants from './coursesTransfer.constants';

import {
  CourseItemsAfterChange,
  CoursesFilters,
  CoursesTransferActions,
  CoursesTransferAllProvider,
  CoursesTransferAllRequestParams,
  CoursesTransferProvider,
  CoursesTransferRequestParams,
  CoursesTransferState,
} from './coursesTransfer.model';
import { initialTransferCourses } from './coursesTransfer.reducer';
import { CurrencyModel } from '../../../models';
import { VendorModel } from '../../vendors';
import { LocationType } from '../../transfer';
import { logger } from '../../../services';
import { errorAction } from '../../error';
import { ToastActions } from '../../toast';
import { coursesTableAllAdapter } from './transferTable';

export const clearTransferCourses =
  () =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.CLEAR_TRANSFER_COURSES,
    });
  };

export const requestTransferAllocatedCoursesStart = (
  requestParams: CoursesTransferRequestParams
) => ({
  type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_START,
  payload: requestParams,
});

export const requestTransferUnallocatedCoursesStart = (
  requestParams: CoursesTransferRequestParams
) => ({
  type: constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_START,
  payload: requestParams,
});

export const requestTransferAllocatedCoursesError = () => ({
  type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_ERROR,
});

export const requestTransferUnallocatedCoursesError = () => ({
  type: constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_ERROR,
});

export const initTransferCourses =
  (courses: CoursesTransferState) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.INIT_TRANSFER_COURSES_SUCCESS,
      payload: courses,
    });
  };

export const setTransferCoursesTotalCourses =
  (totalCourses: number) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.SET_TRANSFER_COURSES_TOTAL_COURSES,
      payload: totalCourses,
    });
  };

export const setTransferCoursesUnallocatedFilters =
  (filters: CoursesFilters) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.SET_TRANSFER_UNALLOCATED_COURSES_FILTERS,
      payload: filters,
    });
  };

export const setTransferCoursesAllocatedFilters =
  (filters: CoursesFilters) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.SET_TRANSFER_ALLOCATED_COURSES_FILTERS,
      payload: filters,
    });
  };

export const toggleTransferCourses =
  (changedCourses: CourseItemsAfterChange) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.TOGGLE_TRANSFER_COURSES,
      payload: changedCourses,
    });
  };

export const requestTransferCoursesUnallocated =
  (
    provider: CoursesTransferProvider,
    requestParams: CoursesTransferRequestParams,
    currencies: CurrencyModel[],
    vendors: VendorModel[],
    concat?: boolean
  ) =>
  async (
    dispatch: Dispatch<CoursesTransferActions | ToastActions>
  ): Promise<void> => {
    dispatch(requestTransferUnallocatedCoursesStart(requestParams));

    provider(
      {
        ...requestParams,
        locationType: LocationType.unallocated,
      },
      currencies,
      vendors
    )
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_SUCCESS,
          payload: { ...response, concat },
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get unallocated courses', err);
        dispatch(requestTransferUnallocatedCoursesError());
        dispatch(errorAction());
      });
  };

export const fakeRequestTransferCoursesAllocated =
  async (dispatch: Dispatch<CoursesTransferActions | ToastActions>) =>
  (): Promise<void> =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          dispatch({
            type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS,
            payload: initialTransferCourses,
          })
        );
      }, 300);
    });

export const requestTransferCoursesAllocated =
  (
    provider: CoursesTransferProvider,
    requestParams: CoursesTransferRequestParams,
    currencies: CurrencyModel[],
    vendors: VendorModel[],
    concat?: boolean
  ) =>
  async (
    dispatch: Dispatch<CoursesTransferActions | ToastActions>
  ): Promise<void> => {
    dispatch(requestTransferAllocatedCoursesStart(requestParams));

    if (
      !requestParams.id &&
      requestParams.added &&
      requestParams.added.length === 0
    ) {
      setTimeout(() => {
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS,
          payload: initialTransferCourses,
        });
      }, 300);

      return;
    }

    provider(
      {
        ...requestParams,
        locationType: LocationType.allocated,
      },
      currencies,
      vendors
    )
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS,
          payload: { ...response, concat },
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get allocated courses', err);
        dispatch(requestTransferAllocatedCoursesError());
        dispatch(errorAction());
      });
  };

export const requestTransferCoursesAllUnallocated =
  (
    provider: CoursesTransferAllProvider,
    requestParams: CoursesTransferAllRequestParams,
    currencies: CurrencyModel[],
    vendors: VendorModel[],
    concat?: boolean
  ) =>
  async (
    dispatch: Dispatch<CoursesTransferActions | ToastActions>
  ): Promise<void> => {
    dispatch({
      type: constants.REQUEST_ALL_TRANSFER_UNALLOCATED_COURSES_START,
    });

    provider(
      {
        ...requestParams,
        locationType: LocationType.unallocated,
      },
      currencies,
      vendors
    )
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_SUCCESS,
          payload: { ...coursesTableAllAdapter(response), concat },
        });
        dispatch({
          type: constants.SELECT_ALL_TRANSFER_UNALLOCATED_COURSES,
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get all unallocated courses', err);
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_ERROR,
        });
      });
  };

export const requestTransferCoursesAllAllocated =
  (
    provider: CoursesTransferAllProvider,
    requestParams: CoursesTransferAllRequestParams,
    currencies: CurrencyModel[],
    vendors: VendorModel[],
    concat?: boolean
  ) =>
  async (
    dispatch: Dispatch<CoursesTransferActions | ToastActions>
  ): Promise<void> => {
    dispatch({
      type: constants.REQUEST_ALL_TRANSFER_ALLOCATED_COURSES_START,
    });

    provider(
      {
        ...requestParams,
        locationType: LocationType.allocated,
      },
      currencies,
      vendors
    )
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS,
          payload: { ...coursesTableAllAdapter(response), concat },
        });
        dispatch({
          type: constants.SELECT_ALL_TRANSFER_ALLOCATED_COURSES,
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get all allocated courses', err);
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_COURSES_ERROR,
        });
      });
  };

export const toggleTransferCoursesPending =
  (state: boolean) =>
  (dispatch: Dispatch<CoursesTransferActions>): void => {
    dispatch({
      type: constants.TOGGLE_TRANSFER_COURSES_PENDING,
      payload: state,
    });
  };
