import React, { ReactElement } from 'react';
import { FormCheckbox } from '../../formInputs';
import { FormControl } from '../../formControl';
import { CheckboxControlProps } from './checkboxControl.model';

// TODO: Вынести компонент в папку v2
export const CheckboxControlComponent = ({
  name,
  checked,
  onChange,
  children,
  disabled,
  margin,
  hasError,
  checkboxValue,
  checkedColor,
}: CheckboxControlProps): ReactElement => (
  <FormControl margin={margin}>
    <FormCheckbox
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      hasError={hasError}
      checkboxValue={checkboxValue}
      checkedColor={checkedColor}
    >
      {children}
    </FormCheckbox>
  </FormControl>
);
