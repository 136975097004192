import React, { ReactElement, useState } from 'react';

import { InputColor } from '../../../../styles';
import {
  InputErrorStyled,
  InputLabelStyled,
  TextareaInputContainer,
} from '../../../index';
import { GenericFormControlStyled } from '../../index';

type GenericFormControlContainerProps = {
  errors: string[];
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  initialValue: string;
  label?: string;
  isRequired?: boolean;
  isValidCallBack?: (value: boolean) => void;
  name: string;
  bordered?: boolean;
  disabled?: boolean;
  color?: InputColor;
  floatingPlaceholder: boolean;
};

const getErrorsComponents = (errors: string[]): ReactElement[] => {
  return errors.map((error) => (
    <InputErrorStyled key={error}>{error}</InputErrorStyled>
  ));
};

export const TextareaFormControlContainer = ({
  label,
  errors = [],
  placeholder,
  onBlur,
  onChange,
  initialValue,
  name,
  bordered,
  disabled,
  color,
  floatingPlaceholder,
}: GenericFormControlContainerProps): ReactElement => {
  const [innerValue, setInnerValue] = useState(initialValue);

  const onInputChange = (value: string): void => {
    setInnerValue(value);
    onChange(value);
  };

  const onInputBlur = (): void => {
    onBlur(innerValue);
  };

  return (
    <GenericFormControlStyled>
      {label && !floatingPlaceholder && (
        <InputLabelStyled>{label}</InputLabelStyled>
      )}
      <TextareaInputContainer
        placeholder={floatingPlaceholder ? label : placeholder}
        onBlur={onInputBlur}
        onChange={onInputChange}
        name={name}
        color={color}
        initialValue={initialValue}
        bordered={bordered}
        disabled={disabled}
        floatingPlaceholder={floatingPlaceholder}
        hasError={Boolean(errors.length)}
      />
      {getErrorsComponents(errors)}
    </GenericFormControlStyled>
  );
};
