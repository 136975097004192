import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { logger } from '../../services';
import { Onboarding } from './onboarding.component';
import {
  OnboardingContextProviderProps,
  OnboardingContextReturnType,
  OnboardingStepModel,
} from './onboarding.model';

const OnboardingContext = createContext<OnboardingContextReturnType>(
  {} as OnboardingContextReturnType
);

export const OnboardingContextProvider = ({
  children,
  ...componentProps
}: OnboardingContextProviderProps): ReactElement => {
  const [steps, setSteps] = useState<OnboardingStepModel[]>([]);
  const callbackRef = useRef<() => void>();

  const showOnboarding = useCallback(
    (steps: OnboardingStepModel[], callback?: () => void) => {
      setSteps(steps);

      if (callback && typeof callback === 'function') {
        callbackRef.current = callback;
      }
    },
    []
  );

  const context = useMemo(
    () =>
      ({
        showOnboarding,
      } as OnboardingContextReturnType),
    []
  );

  const handleClose = useCallback(() => {
    setSteps([]);
    if (callbackRef.current && typeof callbackRef.current === 'function') {
      callbackRef.current();
    }

    callbackRef.current = () => {};
  }, []);

  return (
    <OnboardingContext.Provider value={context}>
      {Boolean(steps.length) && (
        <Onboarding
          {...componentProps}
          steps={steps}
          handleClose={handleClose}
        />
      )}
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContextReturnType => {
  const value = useContext(OnboardingContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as OnboardingContextReturnType;
  }

  return value;
};
