import {
  CustomersTransferState,
  CustomersFilters,
} from '../customersTransfer.model';
import { TranslationModel } from '../../../../models';
import { TableEmptyContent } from '../../../table';

export const getAllocatedEmptyContent = (
  translations: TranslationModel,
  customersAllocatedFilters: CustomersFilters,
  customers: CustomersTransferState
): TableEmptyContent => {
  switch (true) {
    case !!(
      customersAllocatedFilters.departmentUuids?.length ||
      customersAllocatedFilters.positionUuids?.length
    ) && !customers.allocated.data.length:
      return {
        title: translations.filters_is_empty,
        text: null,
      };
    default:
      return {
        title: translations.list_is_empty,
        text: translations.add_customers_width_arrows,
      };
  }
};
