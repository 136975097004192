import { createUserErrorAdapter } from '../createUser';
import {
  CreateCustomer,
  createCustomerAdapter,
  CreateCustomerApiModel,
  CreateCustomerParams,
} from './index';
import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';

export class CreateCustomerClient extends SecureRequestClient<
  CreateCustomerParams,
  CreateCustomerApiModel,
  CreateCustomer
> {
  protected path = 'user-api/v1/customers';
  protected requestParameters: CreateCustomerParams;
  protected adapter = createCustomerAdapter;
  // TODO: Расширить класс RequestClient для типизации error response
  // @ts-ignore
  protected errorAdapter = createUserErrorAdapter;

  constructor(customer: CreateCustomerParams) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
    );
    this.requestParameters = customer;
  }
}
