import * as Yup from 'yup';
import { TranslationModel } from '../../../../models';
import { isPhoneValid } from '../../../inputs';
import { phoneRegex } from '../../../form';
import { RegisterPopupFormValuesModel } from '../../registerPopup';
import {
  FormPersonalInitialValues,
  FormPersonalValidationSchema,
} from './formPersonal.model';

export const getFormPersonalInitialValues = ({
  firstName,
  lastName,
  phone,
}: RegisterPopupFormValuesModel): FormPersonalInitialValues => {
  return {
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
  };
};

export const getFormPersonalValidationSchema = (
  translations: TranslationModel
): FormPersonalValidationSchema => {
  return Yup.object().shape({
    firstName: Yup.string().required(translations.input_required_error_label),
    lastName: Yup.string().required(translations.input_required_error_label),
    phone: Yup.string()
      .required(translations.input_required_error_label)
      .matches(phoneRegex, translations.input_phone_format_error_label)
      .test('phone', translations.input_phone_format_error_label, isPhoneValid),
  });
};
