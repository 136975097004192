import styled from 'styled-components';
import { fontSettings, transition } from '../../../../../../styles';
import { LinksParentStyledProps } from './linksParent.model';
import { rgba } from 'polished';

export const LinksParentStyled = styled.div`
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
`;

export const LinksParentHeadStyled = styled.div<LinksParentStyledProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ isOpened }): string => (isOpened ? '24px;' : '0;')}
  overflow: hidden;
`;

export const LinksParentArrowStyled = styled.div<LinksParentStyledProps>`
  ${transition('transform')};
  ${({ isOpened }): string => (isOpened ? 'transform: rotate(180deg);' : '')}
`;

export const LinksParentTitleStyled = styled.div`
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
`;

export const LinksParentWrapperStyled = styled.div``;
