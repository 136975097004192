import React, { ReactElement } from 'react';
import { CourseLabelModel } from '@lerna-core';
import { CourseVendorLabelStyled } from './courseVendorLabel.styled';

export const CourseVendorLabelContainer = ({
  title,
  color,
  background,
}: CourseLabelModel): ReactElement => (
  <CourseVendorLabelStyled background={background} color={color}>
    {title}
  </CourseVendorLabelStyled>
);
