import { fontSettings } from '@lerna-core';
import styled from 'styled-components';

export const LabelMobileContainerStyled = styled.div`
  ${(props): string => fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)}
  font-weight: 400;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-top: 4px;
`;

export const LabelMobileShowMoreStyled = styled.div`
  ${(props): string => fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)}
  font-weight: 400;
  color: ${(props): string => props.theme.COLOR.PURPLE};
  text-decoration: underline;
`;
