import React, { ReactElement } from 'react';
import { IconPercentWithoutCircleComponent } from '@lerna-core';
import * as S from './pricesPromo.styled';
import { PricesPromoContainerProps } from './pricesPromo.model';

export const PricesPromoContainer = ({
  promoAmount,
  translations,
}: PricesPromoContainerProps): ReactElement => (
  <S.PricesPromo>
    <S.PricesPromoIcon>
      <IconPercentWithoutCircleComponent
        strokeColor="#F60065"
        height={24}
        width={24}
      />
    </S.PricesPromoIcon>
    <S.PricesPromoText>
      {translations.promo_code_discount || 'Скидка по промокоду'}:
      <b>&nbsp;{promoAmount}</b>
    </S.PricesPromoText>
  </S.PricesPromo>
);
