import React, { ReactElement } from 'react';

import { SidebarModel } from './sidebar.model';
import { SidebarStyled } from './sidebar.styled';

export const SidebarContainer = ({
  sidebar,
}: SidebarModel): ReactElement | null => {
  if (sidebar) {
    return <SidebarStyled>{sidebar}</SidebarStyled>;
  }

  return null;
};
