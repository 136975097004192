import { useCallback } from 'react';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  CoursePricesModel,
  pushDataLayer,
  TranslationModel,
} from '@lerna-core';
import { useUserTariff } from '@hooks';
import { UseCompensationTagModel } from './compensationTag.model';
import {
  hasCustomerCompensation,
  hasCustomerFullCompensation,
} from '@features/common/course';
import { getCourseEventData } from '@features/course';
import { ListItemTagsEvents } from '../listItemTags.analytics';

export const useCompensationTag = (
  price: CoursePricesModel,
  translations: TranslationModel,
  course: CourseModel,
  courseIndex?: number
): UseCompensationTagModel => {
  const { isLernaPrime } = useUserTariff();

  const hasCompensation =
    hasCustomerFullCompensation(price) || hasCustomerCompensation(price);

  const showTag = !isLernaPrime && hasCompensation;

  const tagLabel = translations?.compensation_tag_label;
  const tooltipContent = translations?.compensation_tag_tooltip_text;

  const handleTooltipOpen = useCallback((): void => {
    pushDataLayer(
      getCourseEventData(
        ListItemTagsEvents.clickIconInfoAdditionalCompensation,
        CommonEventTypes.coursesEvents,
        CommonEventPositions.coursesPageList,
        course,
        courseIndex
      )
    );
  }, []);

  return {
    showTag,
    tagLabel,
    tooltipContent,
    handleTooltipOpen,
  };
};
