import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconWarningComponent = ({
  width = 80,
  height = 80,
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.3029 66.2552L43.3524 9C43.3524 9 42.3848 7 40 7C37.6152 7 36.6476 9 36.6476 9L0.697143 66.2552C0.697143 66.2552 0 67.1905 0 68.4533C0 70.5581 1.70476 72.2629 3.80952 72.2629H76.1905C78.2952 72.2629 80 70.5581 80 68.4533C80 67.1905 79.3029 66.2552 79.3029 66.2552Z"
        fill="url(#paint0_linear_4779_123363)"
      />
      <path
        d="M40.0233 61.6667C38.8033 61.6667 37.7967 61.2967 37.0033 60.5567C36.21 59.8167 35.8133 58.9217 35.8133 57.8683C35.8133 56.77 36.2133 55.87 37.015 55.1683C37.815 54.4667 38.8183 54.1167 40.0233 54.1167C41.2433 54.1167 42.2433 54.4717 43.02 55.18C43.7983 55.89 44.1867 56.785 44.1867 57.8683C44.1867 58.9667 43.8017 59.8733 43.0317 60.59C42.2617 61.3067 41.2583 61.6667 40.0233 61.6667ZM43.7267 29.535L42.925 49.8267C42.9 50.4467 42.39 50.9367 41.77 50.9367H38.1383C37.5167 50.9367 37.0067 50.445 36.9833 49.8233L36.225 29.5333C36.2 28.8783 36.725 28.3333 37.38 28.3333H42.5733C43.2283 28.3333 43.7533 28.8783 43.7267 29.535Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4779_123363"
          x1="-593.811"
          y1="-666.545"
          x2="64.5828"
          y2="88.581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA1C" />
          <stop offset="1" stopColor="#FEB705" />
        </linearGradient>
      </defs>
    </svg>
  );
};
