import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBookComponent = ({
  width = 16,
  height = 16,
  fillColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 2.5C5.05109 2.50002 4.86034 2.57904 4.71969 2.71969C4.57904 2.86034 4.50002 3.05109 4.5 3.25V4H2.75C2.55109 4.00002 2.36034 4.07904 2.21969 4.21969C2.07904 4.36034 2.00002 4.55109 2 4.75V20.25C2.00002 20.4489 2.07904 20.6397 2.21969 20.7803C2.36034 20.921 2.55109 21 2.75 21H21.25C21.4489 21 21.6397 20.921 21.7803 20.7803C21.921 20.6397 22 20.4489 22 20.25V4.75C22 4.55109 21.921 4.36034 21.7803 4.21969C21.6397 4.07904 21.4489 4.00002 21.25 4H19.5V3.25C19.5 3.05109 19.421 2.86034 19.2803 2.71969C19.1397 2.57904 18.9489 2.50002 18.75 2.5H15C13.7438 2.5 12.6815 3.16647 12 4.12207C11.3185 3.16647 10.2562 2.5 9 2.5H5.25ZM6 4H9C10.2517 4 11.25 4.99825 11.25 6.25V17.7646C10.621 17.29 9.84456 17 9 17H6V4.87305C6.01323 4.79253 6.01323 4.7104 6 4.62988V4ZM15 4H18V4.62695C17.9868 4.70747 17.9868 4.7896 18 4.87012V17H15C14.1554 17 13.379 17.29 12.75 17.7646V6.25C12.75 4.99825 13.7483 4 15 4ZM3.5 5.5H4.5V17.75C4.50002 17.9489 4.57904 18.1397 4.71969 18.2803C4.86034 18.421 5.05109 18.5 5.25 18.5H9C9.78526 18.5 10.4684 18.8983 10.8701 19.5H3.5V5.5ZM19.5 5.5H20.5V19.5H13.1299C13.5316 18.8983 14.2147 18.5 15 18.5H18.75C18.9489 18.5 19.1397 18.421 19.2803 18.2803C19.421 18.1397 19.5 17.9489 19.5 17.75V5.5Z"
      fill={fillColor}
    />
  </svg>
);
