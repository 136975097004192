import React, { ReactElement } from 'react';
import {
  MenuSwitcherContainer,
  MenuSwitcherLinkModel,
  Nullable,
  QueryParams,
  TranslationModel,
  urlService,
} from '@lerna-core';
import { HeaderLinkTypes } from '../../header.model';
import { B2bLandingLinkContainer } from '@features/common/b2bLandingLink';
import { LinksItemContainer } from './linksItem';
import { pageUrls } from '@constants';
import { NextRouter } from 'next/router';
import { getDiscountProgramPageUrl } from '@features/common/discountProgram';

export const getDesktopLinks = (
  linkType: HeaderLinkTypes,
  translations: TranslationModel,
  readyToShowB2bLandingLink: boolean,
  switcherLinks: MenuSwitcherLinkModel[],
  router: NextRouter
): Nullable<ReactElement> => {
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  switch (true) {
    case linkType === HeaderLinkTypes.b2bLanding && readyToShowB2bLandingLink:
      return (
        <B2bLandingLinkContainer children={translations?.b2b_link_label} />
      );
    case linkType === HeaderLinkTypes.catalog: {
      const catalogLink = !!discountProgramQuery
        ? getDiscountProgramPageUrl(router, discountProgramQuery)
        : urlService.getFullUrlFromToPage(pageUrls.courses);

      return (
        <LinksItemContainer text={translations?.catalog} href={catalogLink} />
      );
    }
    case linkType === HeaderLinkTypes.switcher && !discountProgramQuery:
      return <MenuSwitcherContainer links={switcherLinks} />;
    case linkType === HeaderLinkTypes.none:
    default:
      return null;
  }
};
