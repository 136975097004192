import React, { ReactElement, useMemo } from 'react';

import { getDefaultColWidth } from '../customersTransfer.service';
import { useSelector } from 'react-redux';

import {
  requestTransferCustomersAllAllocated,
  requestTransferCustomersAllocated,
} from '../customersTransfer.actions';
import { getAllocatedEmptyContent } from './transferRight.service';
import { TransferRightStyled } from './transferRight.styled';
import { TransferTableContainer } from '../transferTable';
import {
  getCustomersTransferAllocatedFiltersSelector,
  getCustomersTransferSelector,
} from '../customersTransfer.selectors';
import {
  LocationType,
  TransferRightProps,
  TransferTitleContainer,
} from '../../../transfer';
import { useCustomersTransferContext } from '../customersTransfer.context';

export const TransferRightContainer = ({
  getSelectedRows,
  rightRows,
  handleCheck,
  rightColumns,
  headerComponent,
  translations,
  allocatedTopBlock,
}: TransferRightProps): ReactElement => {
  const customers = useSelector(getCustomersTransferSelector);
  const {
    added,
    removed,
    allocated: {
      filters,
      meta,
      isLoading,
      isPaginating,
      isSelectedAll,
      isPending: isPendingAllocatedCustomers,
    },
    unallocated: { isPending: isPendingUnallocatedCustomers },
  } = customers;
  const {
    id,
    isRadioButton,
    customersProvider,
    customersAllProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
    totalCustomers,
    isUnlimited,
    accessors,
  } = useCustomersTransferContext();

  const customersAllocatedFilters = useSelector(
    getCustomersTransferAllocatedFiltersSelector
  );

  const isPending =
    isPendingUnallocatedCustomers || isPendingAllocatedCustomers;

  const handlePagination = (): void => {
    dispatch(
      requestTransferCustomersAllocated(
        customersProvider,
        {
          ...filters,
          id,
          added,
          removed,
          page: meta.currentPage + 1,
          relatedWithSubscriptionId: subscriptionId,
          relatedWithBenefitId: benefitId,
          relatedWithLibraryId: libraryId,
          relatedWithTariffId: tariffId,
        },
        true
      )
    );
  };

  const handleCheckAll = (): void => {
    if (meta.totalPages > meta.currentPage) {
      dispatch(
        requestTransferCustomersAllAllocated(customersAllProvider, {
          ...filters,
          id,
          added,
          removed,
          limit: 500,
          pages: { current: 0, last: Math.ceil(meta.total / 500) },
          relatedWithSubscriptionId: subscriptionId,
          relatedWithBenefitId: benefitId,
          relatedWithLibraryId: libraryId,
          relatedWithTariffId: tariffId,
        })
      );
    }
  };

  const emptyContent = useMemo(
    () =>
      getAllocatedEmptyContent(
        translations,
        customersAllocatedFilters,
        customers
      ),
    [customersAllocatedFilters, customers]
  );

  const customersCount = useMemo(() => {
    const totalCustomersCount =
      typeof totalCustomers === 'number'
        ? totalCustomers
        : customers.totalCustomers;

    return isRadioButton ? 1 : totalCustomersCount;
  }, [isRadioButton, totalCustomers, customers.totalCustomers]);

  const showUnlimitedCount = !!(isUnlimited && !customersCount);

  return (
    <TransferRightStyled>
      <TransferTitleContainer
        translations={translations}
        title={translations.selected_customers}
        selected={customers.allocated.meta.total}
        amount={customersCount}
        showUnlimitedCount={showUnlimitedCount}
      />
      {allocatedTopBlock}
      <TransferTableContainer
        defaultColWidth={getDefaultColWidth(accessors.length + 1, 2)}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        tableData={{ rows: rightRows, columns: rightColumns }}
        getSelectedRows={getSelectedRows}
        handlePagination={handlePagination}
        meta={meta}
        isLoading={isLoading && !isPending}
        isPaginating={isPaginating}
        headerComponent={headerComponent}
        isRadioButton={isRadioButton}
        emptyContent={emptyContent}
        isSelectedAll={isSelectedAll}
        locationType={LocationType.allocated}
      />
    </TransferRightStyled>
  );
};
