import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { PositionsUnallocatedFilterContainerProps } from './positionsUnallocatedFilter.model';
import {
  requestTransferCustomersUnallocated,
  setTransferCustomersUnallocatedFilters,
} from '../../customersTransfer.actions';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import { getCustomersTransferSelector } from '../../customersTransfer.selectors';
import { getUserPositions } from '../../../../user';
import { TableFilterContainer } from '../../../../table';

export const PositionsUnallocatedFilterContainer = ({
  translations,
}: PositionsUnallocatedFilterContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    libraryId,
    benefitId,
    tariffId,
  } = useCustomersTransferContext();
  const filterOptions = useSelector(getUserPositions);
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCustomersTransferSelector);

  const handleApplyFilter = (positionUuids: string[]): void => {
    dispatch(setTransferCustomersUnallocatedFilters({ positionUuids }));
  };
  const handleRequest = (positionUuids: string[]): void => {
    dispatch(
      requestTransferCustomersUnallocated(customersProvider, {
        ...filters,
        positionUuids,
        removed,
        added,
        id,
        notRelatedWithSubscriptionId: subscriptionId,
        notRelatedWithBenefitId: benefitId,
        notRelatedWithLibraryId: libraryId,
        notRelatedWithTariffId: tariffId,
      })
    );
  };

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.positionUuids}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.customer_profile_position_title}
    />
  );
};
