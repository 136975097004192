import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconErrorComponent = ({
  width = 48,
  height = 48,
  fillColor = '#000',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill={fillColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5429 15.5429C15.9334 15.1524 16.5666 15.1524 16.9571 15.5429L32.4571 31.0429C32.8476 31.4334 32.8476 32.0666 32.4571 32.4571C32.0666 32.8476 31.4334 32.8476 31.0429 32.4571L15.5429 16.9571C15.1524 16.5666 15.1524 15.9334 15.5429 15.5429Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4571 15.5429C32.8476 15.9334 32.8476 16.5666 32.4571 16.9571L16.9571 32.4571C16.5666 32.8476 15.9334 32.8476 15.5429 32.4571C15.1524 32.0666 15.1524 31.4334 15.5429 31.0429L31.0429 15.5429C31.4334 15.1524 32.0666 15.1524 32.4571 15.5429Z"
      fill="white"
    />
  </svg>
);
