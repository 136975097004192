export enum FilterRangePrefixEnum {
  from = 'from',
  to = 'to',
}

export enum FilterTypeEnum {
  checkbox = 'checkbox',
  range = 'range',
  tab = 'tab',
  toggle = 'toggle',
}

export enum FiltersSlugEnum {
  sphere = 'sphere',
  level = 'level',
  format = 'format',
  profession = 'profession',
  price = 'price',
  legalEntitiesPrice = 'legal_entities_price',
  schools = 'schools',
  duration = 'duration',
  subscription = 'only_available_in_subscriptions',
  wb = 'only_recommended_by_wb',
  theme = 'theme',
  onlyMiniCourses = 'only_mini_courses',
  type = 'type',
  competency = 'competency',
  expertise = 'expertise',
  collections = 'collections',
}
