import styled, { css } from 'styled-components';
import { CoursesListStyledProps } from './coursesList.model';

export const CoursesListContainerStyled = styled.div`
  width: 100%;
`;

export const CoursesListStyled = styled.div<CoursesListStyledProps>`
  flex-wrap: wrap;
  display: flex;
  margin: 0 -10px;
  padding-bottom: 20px;

  & > * {
    ${({ isRouting }): ReturnType<typeof css> | string =>
      isRouting
        ? css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
          `
        : ''}
    transition: opacity 500ms;
  }
`;
