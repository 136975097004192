import React, { ReactElement, useState } from 'react';

import { AccordionModel } from './accordion.model';
import { AccordionItemContainer } from './accordionItem/accordionItem.container';

export type AccordionContainerProps = {
  items: AccordionModel[];
  autoClose?: boolean;
};

export const AccordionContainer = ({
  items,
  autoClose,
}: AccordionContainerProps): ReactElement | null => {
  const [openedIndex, setOpenedIndex] = useState<number[]>([]);

  if (items.length === 0) {
    return null;
  }

  const checkIsOpened = (index: number): boolean => openedIndex.includes(index);

  const handleClickItem = (index: number): void => {
    if (checkIsOpened(index)) {
      setOpenedIndex(
        openedIndex.filter((currentIndex) => index !== currentIndex)
      );
    } else {
      if (autoClose) {
        setOpenedIndex([index]);
      } else {
        setOpenedIndex([...openedIndex, index]);
      }
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <AccordionItemContainer
          key={index}
          item={item}
          isOpened={checkIsOpened(index)}
          handleClickItem={(): void => handleClickItem(index)}
        />
      ))}
    </>
  );
};
