import React, { ReactElement } from 'react';
import { AlternateLinksPropsModel } from './alternateLinks.model';
import Head from 'next/head';

export const AlternateLinksContainer = ({
  links,
}: AlternateLinksPropsModel): ReactElement => (
  <Head>
    {links.map(({ href, hreflang }, index) => (
      <link
        rel="alternate"
        key={`${index}.${hreflang}`}
        href={href}
        hrefLang={hreflang}
      />
    ))}
  </Head>
);
