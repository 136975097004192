import { ToastActions, ToastType } from './toast.model';
import { ToastConstants } from './toast.constants';

export const setToast = (
  message: string,
  type: ToastType,
  visible: boolean
) => ({
  type: ToastConstants.SET_TOAST,
  payload: { message, type, visible },
});

export const clearToast = (): ToastActions => ({
  type: ToastConstants.CLEAR_TOAST,
});
