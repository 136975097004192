import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconHeartComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M11.9996 21.0543C-8 10.0002 6-1.9998 11.9996 5.5883 18-2 32 10.0001 11.9996 21.0542z"
    />
  </svg>
);
