import React, { ReactElement } from 'react';
import {
  PhoneControlComponent,
  SelectControlComponent,
  TextControlComponent,
} from '../../../form';
import { useTranslations } from '../../../translations';
import { useFormB2B } from './formB2B.hook';
import { ButtonColor, ButtonContainer } from '../../../button';
import { RegisterFormFooterStyled } from '../registerForm.styled';

export const FormB2BContainer = (): ReactElement => {
  const translations = useTranslations();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleSelect,
    values,
    isSubmitting,
    errors,
    touched,
    status,
    setFieldValue,
    defaultDepartment,
    departments,
    defaultPosition,
    positions,
  } = useFormB2B();

  return (
    <>
      <TextControlComponent
        type="text"
        label={translations.input_name_label}
        name="firstName"
        disabled={isSubmitting}
        touched={touched.firstName}
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={translations.placeholder_name}
        errorMessage={errors.firstName}
        statusMessage={status?.firstName}
        showResetButton
        setFieldValue={setFieldValue}
      />
      <TextControlComponent
        type="text"
        label={translations.input_surname_label}
        name="lastName"
        disabled={isSubmitting}
        touched={touched.lastName}
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={translations.placeholder_last_name}
        errorMessage={errors.lastName}
        statusMessage={status?.lastName}
        showResetButton
        setFieldValue={setFieldValue}
      />
      <PhoneControlComponent
        name="phone"
        disabled={isSubmitting}
        value={values.phone}
        onChange={handleChange}
        setFieldValue={setFieldValue}
        onBlur={handleBlur}
        errorMessage={errors.phone}
        statusMessage={status?.phone}
        touched={touched.phone}
        placeholder={translations.enter_phone}
        label={translations.input_phone_label}
      />
      {!!departments.length && (
        <SelectControlComponent
          label={translations.customer_profile_department_label}
          options={departments}
          name="departmentUuid"
          disabled={isSubmitting}
          defaultOption={defaultDepartment}
          placeholder={translations.placeholder_department}
          floatingPlaceholder
          onChange={handleSelect}
          statusMessage={status?.departmentUuid}
          errorMessage={errors.departmentUuid}
          touched={!!touched.departmentUuid}
          directionOpenTop
        />
      )}
      {!!positions.length && (
        <SelectControlComponent
          label={translations.customer_profile_position_title}
          options={positions}
          name="positionUuid"
          disabled={isSubmitting}
          defaultOption={defaultPosition}
          placeholder={translations.placeholder_position}
          floatingPlaceholder
          onChange={handleSelect}
          statusMessage={status?.positionUuid}
          errorMessage={errors.positionUuid}
          touched={!!touched.positionUuid}
          directionOpenTop
        />
      )}
      <RegisterFormFooterStyled>
        <ButtonContainer
          fullWidth
          title={translations.register_form_submit_button}
          borderRadius={100}
          color={ButtonColor.purple}
          disabled={isSubmitting}
          onClick={handleSubmit}
        />
      </RegisterFormFooterStyled>
    </>
  );
};
