import styled from 'styled-components';
import { TooltipTriggerStyledProps } from './tooltip.model';
import { transition } from '@lerna-core';

export const TooltipStyled = styled.div`
  position: relative;
`;

export const TooltipTriggerStyled = styled.div<TooltipTriggerStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: max-content;
  ${transition('all')}

  ${({ margin }): string => (margin ? `margin: ${margin};` : '')};

  &:hover {
    ${({ triggerHoverOpacity }): string =>
      triggerHoverOpacity ? `opacity: ${triggerHoverOpacity};` : ''}
  }
`;
