import { MutableRefObject, useEffect, useState } from 'react';
import {
  TooltipDesktopHookModel,
  TooltipDesktopPositions,
} from './tooltipDesktop.model';
import { Nullable, useToggle } from '@lerna-core';

const OFFSET_MARGIN = 20;

export const useTooltipDesktop = (
  tooltipRef: MutableRefObject<Nullable<HTMLDivElement>>
): TooltipDesktopHookModel => {
  const [isOpen, toggleOpen] = useToggle(false);
  const [position, setPosition] = useState<TooltipDesktopPositions>(
    TooltipDesktopPositions.top
  );

  const setTooltipPosition = (): void => {
    if (tooltipRef?.current) {
      const { top, height } = tooltipRef?.current?.getBoundingClientRect();

      if (position === TooltipDesktopPositions.top && top < 0) {
        setPosition(TooltipDesktopPositions.bottom);
      }
      if (
        position === TooltipDesktopPositions.bottom &&
        top >= height + OFFSET_MARGIN
      ) {
        setPosition(TooltipDesktopPositions.top);
      }
    }
  };

  useEffect(() => {
    setTooltipPosition();
  }, [isOpen]);

  return {
    isOpen,
    position,
    handleTooltipToggle: toggleOpen,
  };
};
