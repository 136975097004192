import React, { ReactElement, useCallback } from 'react';
import { DescriptionComponentProps } from './description.model';
import { getPasswordValidationErrorsTitles } from '../passwordControl.helper';
import { passwordValidation } from '../passwordControl.constants';
import { DescriptionItemStyled, DescriptionStyled } from './description.styled';
import {
  PasswordValidationErrorTypes,
  PasswordValidationTypes,
} from '../passwordControl.model';
import { Nullable } from '../../../../../models';

export const DescriptionComponent = ({
  value,
  hasError,
  touched,
  errorMessage,
  translations,
}: DescriptionComponentProps): Nullable<ReactElement> => {
  const getErrorMessageByType = useCallback(
    (type: PasswordValidationTypes) =>
      Array.isArray(errorMessage) &&
      errorMessage?.find((error) => error.validationType === type),
    [errorMessage]
  );

  const isSuccess = (type: PasswordValidationTypes) => {
    return !getErrorMessageByType(type) && Boolean(value);
  };

  const isError = (type: PasswordValidationTypes) => {
    const errorMessageByType = getErrorMessageByType(type);
    const hasExcludeError =
      errorMessageByType &&
      errorMessageByType.type === PasswordValidationErrorTypes.exclude;

    return hasExcludeError || (touched && hasError);
  };

  if (!translations) {
    return null;
  }

  const errorTitles = getPasswordValidationErrorsTitles(translations);

  return (
    <DescriptionStyled>
      {passwordValidation.map((validation) => (
        <DescriptionItemStyled
          key={validation.type}
          success={isSuccess(validation.type)}
          error={isError(validation.type)}
        >
          {errorTitles[validation.type]}
        </DescriptionItemStyled>
      ))}
    </DescriptionStyled>
  );
};
