import { useTheme } from '../../../../hooks';
import { modalPaddings } from './logoPopup.meta';
import {
  CompanyEditLogoPopupSubtitle,
  CompanyEditLogoPopupTitle,
} from './logoPopup.styled';
import Cropper, { ReactCropperElement } from 'react-cropper';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { LogoPopupModel } from './logoPopup.model';
import { logoAddClientProvider } from '../logoAdd';
import { logoGetClientProvider } from '../logoGet';
import { useTranslations } from '../../../translations';
import { MessagePopupComponent, PopupContainer } from '../../../popups';
import { COMPANY_LOGO } from '../companyEditLogo.constants';
import { logger, PRESETS } from '../../../../services';
import { ButtonColor, ButtonContainer } from '../../../button';
import { IconCropComponent } from '../../../icons';

const { w: minWidth, h: minHeight } = PRESETS.logoCompany.opts;
const aspectRatio = minWidth / minHeight;

export const LogoPopupContainer = ({
  isOpened,
  imageSrc,
  handleChangeNextLogo,
  handleClosePopup,
  uploaderApiHost,
}: LogoPopupModel): ReactElement => {
  const translations = useTranslations();
  const theme = useTheme();

  const cropperRef = useRef<HTMLImageElement>(null);
  const [photoError, setPhotoError] = useState(false);
  const [isCropping, setCropping] = useState<boolean>(false);

  const uploadLogo = (blob: Blob): void => {
    logoAddClientProvider(blob, uploaderApiHost)
      .then((response) => {
        fetchUploadedPhoto(response.filename);
      })
      .catch(() => {
        setPhotoError(true);
      });
  };

  const fetchUploadedPhoto = (filename: string): void => {
    logoGetClientProvider(
      {
        entity: COMPANY_LOGO,
        filename,
      },
      uploaderApiHost
    ).then((response) => {
      handleChangeNextLogo({
        ...response.image,
        source_server: response.source.server,
        entity: COMPANY_LOGO,
      });
      handleClosePopup();
    });
  };

  const handleSaveCropped = (): void => {
    const imageElement = cropperRef?.current as ReactCropperElement;
    const [, type] = imageElement.src.split(';')[0].split('/');
    const cropper = imageElement?.cropper;
    const canvasOptions: Cropper.GetCroppedCanvasOptions = {
      minWidth: minWidth * 4,
      minHeight: minHeight * 4,
    };

    cropper.getCroppedCanvas(canvasOptions).toBlob(
      (blob): void => {
        if (blob) {
          uploadLogo(blob);
        } else {
          logger.error('[ERROR]: no blob were specified', { blob });
        }
      },
      type === 'jpeg' ? 'image/jpeg' : undefined
    );
  };
  const closePhotoError = useCallback((): void => {
    setPhotoError(false);
  }, []);

  const onCropStart = useCallback(() => {
    setCropping(true);
  }, []);

  const onCropEnd = useCallback(() => {
    setCropping(false);
  }, []);

  return (
    <>
      <PopupContainer
        handleClose={handleClosePopup}
        width={'600px'}
        styleSettings={modalPaddings}
        isOpened={isOpened}
        isTouching={isCropping}
      >
        <CompanyEditLogoPopupTitle>
          {translations.logo_cropping}
        </CompanyEditLogoPopupTitle>
        <CompanyEditLogoPopupSubtitle>
          <IconCropComponent
            width={22}
            height={22}
            strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
          />
          {translations.choose_logo_area}
        </CompanyEditLogoPopupSubtitle>

        <Cropper
          src={imageSrc}
          style={{ height: 380, width: '100%', marginBottom: '32px' }}
          initialAspectRatio={aspectRatio}
          aspectRatio={aspectRatio}
          guides={true}
          background={true}
          disabled={true}
          viewMode={0}
          cropstart={onCropStart}
          cropend={onCropEnd}
          ref={cropperRef}
        />

        <ButtonContainer
          title={translations.save_logo}
          borderRadius={100}
          color={ButtonColor.purple}
          fullWidth
          onClick={handleSaveCropped}
        />
      </PopupContainer>

      <PopupContainer
        handleClose={closePhotoError}
        isOpened={photoError}
        hasHeader={false}
        width={'600px'}
        styleSettings={modalPaddings}
      >
        <MessagePopupComponent
          title={translations?.popup_error_photo_upload_title}
          status="error"
          statusWidth={128}
          statusHeight={128}
        />
      </PopupContainer>
    </>
  );
};
