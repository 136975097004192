import {
  TagLabelColor,
  TagLabelContainerProps,
  TagLabelIconPosition,
} from './tagLabel.model';
import { TagLabelContainerStyled, TagLabelTextStyled } from './tagLabel.styled';
import React, { ReactElement } from 'react';

const DEFAULT_BORDER_RADIUS = 4;

export const TagLabelContainer = ({
  label,
  borderRadius = DEFAULT_BORDER_RADIUS,
  color = TagLabelColor.white,
  noWrap = true,
  size,
  icon,
  iconPosition = TagLabelIconPosition.left,
}: TagLabelContainerProps): ReactElement => {
  return (
    <TagLabelContainerStyled
      color={color}
      size={size}
      borderRadius={borderRadius}
      iconPosition={iconPosition}
      hasIcon={!!icon}
      noWrap={noWrap}
    >
      <TagLabelTextStyled>{label}</TagLabelTextStyled>
      {!!icon && icon}
    </TagLabelContainerStyled>
  );
};
