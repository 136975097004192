import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface UseToastOutput {
  show: () => void;
  hide: () => void;
  visible: boolean;
}

export const useToast = (autoHideDuration = 4000): UseToastOutput => {
  const [visible, setVisible] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (visible) {
      timer.current = setTimeout(hide, autoHideDuration);
    } else {
      clearTimeout(timer.current);
    }
  }, [visible]);

  const show = useCallback((): void => {
    setVisible(true);
  }, [setVisible]);

  const hide = useCallback((): void => {
    setVisible(false);
  }, [setVisible]);

  return useMemo(
    () => ({
      show,
      hide,
      visible,
    }),
    [show, hide, visible]
  );
};
