import { UseFasttrackControlButtonModel } from './fasttrackControlButton.model';
import { useCallback, useContext, useRef } from 'react';
import { DeviceInfoContext } from '../../device';
import { browserService } from '../../../services';
import { useToggle } from '../../../hooks';
import { Nullable } from '../../../models';
import { FooterAnalyticsEvents, pushFooterDataLayer } from '../../footer';

export const useFasttrackControlButton = (): UseFasttrackControlButtonModel => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const [isDropdownOpen, toggleDropdown] = useToggle(false);
  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null);

  const handleOpenChat = useCallback((): void => {
    // @ts-ignore
    window?.fasttrack?.openCover();
    pushFooterDataLayer(FooterAnalyticsEvents.clickButtonWriteSupport);
  }, []);

  //пока не удаляем все с дропдауном (перенес аналитику в handleOpenChat)
  const handleOpenDropdown = useCallback((): void => {
    toggleDropdown();
  }, []);

  return {
    isMobile,
    handleOpenChat,
    isDropdownOpen,
    toggleDropdown,
    dropdownRef,
    handleOpenDropdown,
  };
};
