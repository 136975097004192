import styled, { css } from 'styled-components';
import {
  breakpoint,
  SliderContainerStyled,
  SliderControlNextStyled,
  SliderControlPrevStyled,
} from '@lerna-core';
import { ListItemStyled } from '@features/courses/coursesList/listItem';
import { TitleH3Styled } from '@features/course/courseTitle';
import { mobileCoursePageSpacing } from '@features/course/coursePaper';

export const CourseSimilarDesktopContainerStyled = styled.div`
  margin-top: 56px;

  ${TitleH3Styled} {
    margin-bottom: 24px;
  }

  ${breakpoint(
    'xxlargeDown',
    css`
      ${SliderContainerStyled} {
        padding: 0 0 78px;
      }

      ${SliderControlNextStyled},
      ${SliderControlPrevStyled} {
        top: unset;
        bottom: 0;
        transform: translateY(0);
      }
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: ${mobileCoursePageSpacing};

      ${TitleH3Styled} {
        margin-left: 20px;
      }
    `
  )}
`;

export const CourseSimilarMobileContainerStyled = styled.div`
  margin-top: 16px;

  ${ListItemStyled} {
    margin: 0 0 8px 0;
    border: 1px solid rgba(120, 116, 122, 0.2);
    box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.04);

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
