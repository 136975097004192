import React, { ReactElement } from 'react';
import {
  PricesItemStyled,
  TitleStyled,
  ValueStyled,
} from './pricesItem.styled';
import { PricesItemContainerProps } from './pricesItem.model';
import { IconInfoCircledComponent, Nullable, useTheme } from '@lerna-core';
import { TooltipContainer } from '@features/common/tooltip';

export const PricesItemContainer = ({
  title,
  value,
  isNegative,
  itemStyles,
  tooltipText,
  handleTooltipClick,
}: PricesItemContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();

  if (!value) return null;

  return (
    <PricesItemStyled styles={itemStyles?.item}>
      <TitleStyled styles={itemStyles?.title}>
        {title}
        {!!tooltipText && (
          <TooltipContainer
            content={tooltipText}
            contentPosition="center"
            onOpen={handleTooltipClick}
            triggerHoverOpacity={0.64}
            contentMargin="4px -75px 0 0"
            triggerMargin="0 0 0 4px"
          >
            <IconInfoCircledComponent
              width={16}
              height={16}
              strokeColor={theme.COLOR.TEXT_DARK_COLOR}
              fillColor={theme.COLOR.TEXT_DARK_COLOR}
            />
          </TooltipContainer>
        )}
      </TitleStyled>
      <ValueStyled styles={itemStyles?.value}>
        {isNegative && '-'} {value}
      </ValueStyled>
    </PricesItemStyled>
  );
};
