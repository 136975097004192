import { BonusesApiModel, BonusesModel } from '../features';
import { stringService } from '../services';

export const mapBonuses = (bonusesData: BonusesApiModel): BonusesModel => ({
  points: Number(stringService.replaceSpaces(bonusesData.points)),
  bonusPriceConversion: {
    money: bonusesData.bonus_price_conversion.data.money,
    points: Number(
      stringService.replaceSpaces(
        bonusesData.bonus_price_conversion.data.points
      )
    ),
  },
});
