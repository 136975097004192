import React, { ReactElement, useContext } from 'react';
import { browserService, DeviceInfoContext } from '@lerna-core';
import {
  CourseLogoContainer,
  CourseLogoSizeEnum,
  getCourseLogoProps,
} from '@features/course/courseLogo';
import { CourseLogoLabelContainer } from '@features/course/courseLogoLabel';
import { ListItemLogoStyled } from './listItemLogo.styled';
import { ListItemLogoModel } from './listItemLogo.model';
import { getLogoLabel } from './listItemLogo.helper';

export const ListItemLogoContainer = ({
  logo,
  isHit,
  logoLabel,
  translations,
}: ListItemLogoModel): ReactElement => {
  const itemLogoLabel = getLogoLabel(logoLabel, isHit, translations);
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);
  const containerProps = getCourseLogoProps(
    isMobile ? CourseLogoSizeEnum.medium : CourseLogoSizeEnum.big
  );

  return (
    <ListItemLogoStyled {...containerProps}>
      <CourseLogoContainer logo={logo} />
      {itemLogoLabel && <CourseLogoLabelContainer {...itemLogoLabel} />}
    </ListItemLogoStyled>
  );
};
