import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLanguageComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <circle cx="12" cy="12" r="10" stroke={strokeColor} strokeWidth="2" />
      <path d="M3 12h18" stroke={strokeColor} strokeWidth="1.5" />
      <path
        d="M10 21.5c-2-5.095-2-15 0-19M14 21.5c2-5.095 2-15 0-19"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
