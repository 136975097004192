import { MenuActions } from './menu.actions';
import * as constants from './menu.constants';
import { MenuModel, MenuState } from './menu.model';

const initialState: MenuState = {
  top: [],
  side: [],
  status: {
    isLoading: false,
    isLoaded: false,
  },
};

export function menuReducer(
  state: MenuState = initialState,
  action: MenuActions
): MenuState {
  switch (action.type) {
    case constants.REQUEST_MENU_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case constants.REQUEST_MENU_SUCCESS:
      const response = action.payload as MenuModel;

      return {
        ...state,
        top: response.top,
        side: response.side,
        status: {
          ...state.status,
          isLoaded: true,
          isLoading: false,
        },
      };

    case constants.REQUEST_MENU_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
