import { CoursePricesModel } from '@lerna-core';

/**
 * Checking the availability of B2B/B2C bonuses points
 * @return {boolean}
 * @params isB2B,price
 * */
export const hasBonusesPoints = (
  isB2B: boolean,
  price: CoursePricesModel
): boolean => {
  if (isB2B) {
    return true;
  }

  return (
    !!price.priceWithBonuses?.discount &&
    parseInt(price?.priceWithBonuses?.discount?.value) > 0
  );
};

/**
 * Checking the availability of B2C discount
 * @return {boolean}
 * @params price
 * */
export const hasB2CDiscount = (price: CoursePricesModel): boolean =>
  !!price.price?.discount?.value && parseInt(price.price?.discount?.value) > 0;

/**
 * Checking the availability of B2B discount
 * @return {boolean}
 * @params price
 * */
export const hasCustomerDiscount = (price: CoursePricesModel): boolean =>
  !!price.priceCustomer?.totalDiscount?.value &&
  parseInt(price.priceCustomer?.totalDiscount?.value) > 0;

/**
 * Checking the availability of B2B legal entity discount
 * @return {boolean}
 * @params price
 * */
export const hasLegalEntitiesDiscount = (price: CoursePricesModel): boolean =>
  !!price.priceCustomerB2B?.vendorWithPartnershipDiscount?.value &&
  parseInt(price.priceCustomerB2B?.vendorWithPartnershipDiscount?.value) > 0;

/**
 * Checking the availability of program discount
 * @return {boolean}
 * @params price
 * */
export const hasProgramDiscount = (price: CoursePricesModel): boolean =>
  !!price.priceWithProgramDiscount?.discount?.value &&
  parseInt(price.priceWithProgramDiscount?.discount?.value) > 0;

/**
 * Checking the availability of B2B compensation discount
 * @return {boolean}
 * @params price
 * */
export const hasB2BCompensationDiscount = (
  price: CoursePricesModel
): boolean => {
  const discountValue =
    !!price.priceCustomer?.compensation?.discount?.value &&
    parseInt(price.priceCustomer?.compensation?.discount?.value);

  return !!discountValue && discountValue > 0 && discountValue < 100;
};

/**
 * Checking the availability of B2B Full compensation discount
 * @return {boolean}
 * @params price
 * */
export const hasB2BFullCompensationDiscount = (
  price: CoursePricesModel
): boolean => {
  const discountValue =
    !!price.priceCustomer?.compensation?.discount?.value &&
    parseInt(price.priceCustomer?.compensation?.discount?.value);

  return discountValue === 100;
};

/**
 * Checking if there is a compensation for B2B price
 * @return {boolean}
 * @params price
 * */
export const hasCustomerCompensation = (price: CoursePricesModel): boolean =>
  !!price?.priceCustomer?.compensation
    ? hasB2BCompensationDiscount(price)
    : false;

/**
 * Checking if there is a full compensation for B2B price
 * @return {boolean}
 * @params price
 * */
export const hasCustomerFullCompensation = (
  price: CoursePricesModel
): boolean =>
  !!price?.priceCustomer?.compensation
    ? hasB2BFullCompensationDiscount(price)
    : false;

/**
 * Checking if there is a cashback percent value
 * @return {boolean}
 * @params price
 * */
export const hasCashback = (price: CoursePricesModel): boolean =>
  !!price?.cashBack?.percent;
