export enum GTMConsentStatus {
  denied = 'denied',
  granted = 'granted',
}
export enum GTMConsentMode {
  adStorage = 'ad_storage',
  adUserData = 'ad_user_data',
  adPersonalization = 'ad_personalization',
  analyticsStorage = 'analytics_storage',
  functionalityStorage = 'functionality_storage',
}

export type GTMConsentModeModel = {
  [key in GTMConsentMode]: GTMConsentStatus;
};
