import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';
import {
  ListItemHeaderStyledProps,
  ListItemHeaderTitleStyledProps,
} from './liItemHeader.model';

export const ListItemHeaderContainerStyled = styled.div<ListItemHeaderStyledProps>`
  margin-bottom: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${({ hasSphere }): number => (hasSphere ? 0 : 40)}px;
`;

export const ListItemHeaderTitleStyled = styled.div<ListItemHeaderTitleStyledProps>`
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        flex: 0 0 calc(100% - 80px);
        max-width: calc(100% - 80px);
      `,
      isMobile
    )}
`;
