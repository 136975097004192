import styled, { css } from 'styled-components';

import { ITheme } from '../../../../themes';

const getActiveLinkStyle = (isActive: boolean): ReturnType<typeof css> => {
  if (isActive) {
    return css`
      background: #fff;
      border-radius: 16px;
    `;
  }

  return css``;
};

const getActiveLinkTitleStyle = (isActive: boolean, theme: ITheme): string =>
  isActive ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR;

type SideMenuLinkStyledProps = {
  isActive: boolean;
  nested?: boolean;
};

export const SideMenuLinkContainerStyled = styled.div<SideMenuLinkStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: ${(props): string => props.theme.FONT.SIZE_17}px;
  line-height: ${(props): string => props.theme.FONT.LINE_HEIGHT_22}px;
  padding: 15px 16px;
  ${({ nested }): string =>
    nested
      ? `
      padding-left: 40px;
    `
      : ''};
  margin-bottom: 8px;
  ${({ isActive }): ReturnType<typeof css> => getActiveLinkStyle(isActive)};
`;

export const SideMenuLinkTitleStyled = styled.span<SideMenuLinkStyledProps>`
  margin-left: 12px;
  color: ${({ isActive, theme }): string =>
    getActiveLinkTitleStyle(isActive, theme)};
`;
