import React, { ReactElement } from 'react';
import { HeaderLoader, HeaderLoaderLine } from './headerLoader.styled';
import { usePageRouter } from '../../../hooks';
import { HeaderLoaderContainerPropsType } from './headerLoader.model';

export const HeaderLoaderContainer = ({
  withCompany = true,
  isOnTop = false,
}: HeaderLoaderContainerPropsType): ReactElement => {
  const isRouting = usePageRouter();

  return (
    <HeaderLoader
      isRouting={isRouting}
      withCompany={withCompany}
      isOnTop={isOnTop}
    >
      <HeaderLoaderLine withCompany={withCompany} />
    </HeaderLoader>
  );
};
