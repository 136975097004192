import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconHourglassComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.576V4.228C19 3.439 15.092 2 12 2 8.907 2 5.004 3.438 5.004 4.227v2.348c0 2.141 4.345 3.908 4.345 5.424 0 1.514-4.345 3.281-4.345 5.422v2.35C5.004 20.559 8.907 22 12 22c3.092 0 7-1.44 7-2.229v-2.35c0-2.141-4.393-3.907-4.393-5.421 0-1.515 4.393-3.283 4.393-5.424ZM6.941 4.328c.696-.439 2-1.082 5.114-1.082 2.113 0 5.006 1.082 5.006 1.082.142.086.698.383.317.609-.838.497-3.478 1.02-5.378 1.02-2.9 0-4.484-.576-5.324-1.074-.381-.225.265-.555.265-.555Zm6.043 7.673c0 1.193 1.513 1.96 2.568 2.985.771.748 1.826 1.773 1.826 2.435v1.328c-.97-.483-4.872-1.249-4.872-3.504 0-.783-1.013-.783-1.013 0 0 2.176-3.902 3.021-4.872 3.504v-1.328c0-.662 1.056-1.688 1.826-2.435 1.055-1.025 2.519-1.793 2.519-2.986 0-1.193-1.464-1.961-2.519-2.986-.779-.758-1.744-1.5-1.927-2.621 1.026.553 2.652 1.078 5.425 1.078 1.772 0 4.406-.525 5.433-1.078 0 1.073-1.124 1.937-1.826 2.621-1.054 1.025-2.568 1.794-2.568 2.987Z"
      fill={fillColor}
    />
  </svg>
);
