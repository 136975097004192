export type TooltipDesktopContainerProps = {
  content: string;
  defaultPosition?: TooltipDesktopPositions;
};

export enum TooltipDesktopPositions {
  top = 'top',
  bottom = 'bottom',
}

export type TooltipDesktopHookModel = {
  isOpen: boolean;
  position: TooltipDesktopPositions;
  handleTooltipToggle: () => void;
};

export type TooltipDesktopContentStyledProps = Pick<
  TooltipDesktopHookModel,
  'isOpen' | 'position'
>;
