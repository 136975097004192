import { useEffect, useState } from 'react';

import { CSSVariables } from '../models';
import { browserService } from '../services/browserService/browser.service';

export function useOffsetTop(): number {
  const [offsetTop, setOffsetTop] = useState<number>(0);
  useEffect(() => {
    setOffsetTop(browserService.getCSSVariableValue(CSSVariables.offsetTop));
  }, [browserService.getCSSVariableValue(CSSVariables.offsetTop)]);

  return offsetTop;
}
