import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const LeftSubscriptionInfoStyled = styled.div`
  margin-top: auto;
  color: ${({ theme }): string => theme.COLOR.TEXT[100]};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}

  span {
    font-weight: 700;
  }
`;
