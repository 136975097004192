import { ReactElement, useMemo } from 'react';
import { PopupContainer } from '../../../popups';
import { ManagementPopupModel } from './managementPopup.model';
import { getPopupContent, modalPaddings } from './managementPopup.meta';
import { CheckboxControlComponent } from '../../../form';
import * as S from './managementPopup.styled';
import { CookieTypes } from '../../cookiesNotification.model';
import { useManagementForm } from './managementPopup.hook';
import { ButtonContainer } from '../../../button';
import { IconAttentionComponent } from '../../../icons';
import { useTheme } from '../../../../hooks';

export const ManagementPopupContainer = ({
  isOpened,
  handleClose,
}: ManagementPopupModel): ReactElement => {
  const theme = useTheme();
  const content = useMemo(getPopupContent, []);
  const { values, handleChange, handleAccept } = useManagementForm(
    handleClose,
    isOpened
  );

  return (
    <PopupContainer
      styleSettings={modalPaddings}
      closeOutside
      width="820px"
      isOpened={isOpened}
      handleClose={handleClose}
    >
      <S.ManagementPopupTitle>{content.title}</S.ManagementPopupTitle>
      <S.ManagementPopupDescription
        dangerouslySetInnerHTML={{ __html: content.description1 }}
      />
      <S.ManagementPopupDescription
        dangerouslySetInnerHTML={{ __html: content.description2 }}
      />
      <S.ManagementPopupDescription
        dangerouslySetInnerHTML={{ __html: content.description3 }}
      />
      {Object.values(CookieTypes).map((type, index) => {
        const disabled = CookieTypes[type] === CookieTypes.technical;
        const checked = values[CookieTypes[type]];

        return (
          <S.ManagementPopupInput
            disabled={disabled}
            checked={checked}
            key={`checkbox_${index}`}
          >
            <CheckboxControlComponent
              disabled={disabled}
              name={CookieTypes[type]}
              checked={checked}
              onChange={handleChange}
              children={content.inputs[CookieTypes[type]].title}
            />
            <S.ManagementPopupInputDescription
              dangerouslySetInnerHTML={{
                __html: content.inputs[CookieTypes[type]].description,
              }}
            />
            {!!content.inputs[CookieTypes[type]].warning && (
              <S.ManagementPopupInputWarning>
                <IconAttentionComponent
                  fillColor={theme.COLOR.ERROR['500']}
                  height={24}
                  width={24}
                />
                {content.inputs[CookieTypes[type]].warning}
              </S.ManagementPopupInputWarning>
            )}
          </S.ManagementPopupInput>
        );
      })}
      <S.ManagementPopupFooter>
        <ButtonContainer
          type="submit"
          additionalColors={{ color: '#FFF', backgroundColor: '#000' }}
          onClick={handleAccept}
          title={content.submitButton}
          fullWidth
        />
      </S.ManagementPopupFooter>
    </PopupContainer>
  );
};
