import { CatalogPageTypes, projectTypes } from '../../../constants';
import { UserMenuStaticLinksTypes, UserMenuTypes } from './userMenu.constants';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import { CommonEventTypes } from '../../../analytics';
import { UserMenuAnalytics } from './userMenu.model';

export const UserMenuEventPageReplacer = '{{page}}';

export enum UserMenuEvents {
  goToHR = 'go_to_hr_account_{{page}}',
  goToPersonal = 'go_to_personal_account_{{page}}',
  goToCatalog = 'go_to_main_courses_page',
  goToProfile = 'go_to_personal_account_profile',
  login = 'click_icon_authorization',
  logout = 'userBar_log_out',
}

export enum UserMenuEventPositions {
  catalogMenu = 'courses_page_block_menu',
  catalogMobileMenu = 'courses_page_block_modal_header',
  catalogCoursePageMenu = 'course_page_block_menu',
  catalogCoursePageMobileMenu = 'course_page_block_modal_header',
  personalMenu = 'personal_account_page_block_menu',
  personalMobileMenu = 'personal_account_page_block_modal_header',
  hrMenu = 'hr_account_page_block_menu',
  hrMobileMenu = 'hr_account_page_block_modal_header',
}

const getUserMenuAnalyticsEvent = (
  link: string,
  linkType?: UserMenuStaticLinksTypes,
  parentType?: string
): string => {
  const userMenuEventPage = link[0] === '/' ? link.slice(1) : link;

  switch (true) {
    case parentType === projectTypes.hr:
      return UserMenuEvents.goToHR
        .replace(UserMenuEventPageReplacer, userMenuEventPage)
        .replace(/-/g, '_');
    case parentType === projectTypes.personal:
      return UserMenuEvents.goToPersonal
        .replace(UserMenuEventPageReplacer, userMenuEventPage)
        .replace(/-/g, '_');
    case linkType === UserMenuStaticLinksTypes.catalog:
      return UserMenuEvents.goToCatalog;
    case linkType === UserMenuStaticLinksTypes.profile:
      return UserMenuEvents.goToProfile;
    case linkType === UserMenuStaticLinksTypes.login:
      return UserMenuEvents.login;
    case linkType === UserMenuStaticLinksTypes.logout:
      return UserMenuEvents.logout;
    default:
      return '';
  }
};

const getCatalogEventPosition = (
  menuType: UserMenuTypes,
  projectPageName?: string
): string => {
  switch (projectPageName) {
    case CatalogPageTypes.courses:
      return menuType === UserMenuTypes.desktop
        ? UserMenuEventPositions.catalogMenu
        : UserMenuEventPositions.catalogMobileMenu;
    case CatalogPageTypes.coursePage:
      return menuType === UserMenuTypes.desktop
        ? UserMenuEventPositions.catalogCoursePageMenu
        : UserMenuEventPositions.catalogCoursePageMobileMenu;
    default:
      return '';
  }
};

const getUserMenuAnalyticsEventPosition = (
  menuType: UserMenuTypes,
  projectType?: string,
  projectPageName?: string
): string => {
  switch (projectType) {
    case projectTypes.courses:
      return getCatalogEventPosition(menuType, projectPageName);
    case projectTypes.hr:
      return menuType === UserMenuTypes.desktop
        ? UserMenuEventPositions.hrMenu
        : UserMenuEventPositions.hrMobileMenu;
    case projectTypes.personal:
      return menuType === UserMenuTypes.desktop
        ? UserMenuEventPositions.personalMenu
        : UserMenuEventPositions.personalMobileMenu;
    default:
      return '';
  }
};

const getUserMenuAnalytics = (
  menuType: UserMenuTypes,
  link: string,
  linkType?: UserMenuStaticLinksTypes,
  parentType?: string,
  projectType?: string,
  projectPageName?: string
): UserMenuAnalytics => ({
  event: getUserMenuAnalyticsEvent(link, linkType, parentType),
  eventPosition: getUserMenuAnalyticsEventPosition(
    menuType,
    projectType,
    projectPageName
  ),
});

export const handleUserMenuDataLayer = (
  menuType = UserMenuTypes.desktop,
  link = '',
  linkType?: UserMenuStaticLinksTypes,
  parentType?: string,
  projectType?: string,
  projectPageName?: string
): void => {
  const { event, eventPosition } = getUserMenuAnalytics(
    menuType,
    link,
    linkType,
    parentType,
    projectType,
    projectPageName
  );

  if (event && eventPosition) {
    pushDataLayer(
      getSimpleEvent(event, CommonEventTypes.overallEvents, eventPosition)
    );
  }
};
