import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import {
  FormArchiveContextProvider,
  useFormArchiveContext,
} from './formArchive.context';
import { FormContentContainer } from './formContent';
import { FormArchiveContainerProps } from './formArchive.model';
import { FormStatusContainer } from './formStatus';

const ArchiveFormContent = (): ReactElement => {
  const { currentPopup, handlePopupClose, showFormStatus } =
    useFormArchiveContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <>
        <FormContentContainer />
        {showFormStatus && <FormStatusContainer />}
      </>
    </CenteredPopupContainer>
  );
};

export const FormArchiveContainer = (
  props: FormArchiveContainerProps
): Nullable<ReactElement> => (
  <FormArchiveContextProvider props={props}>
    <ArchiveFormContent />
  </FormArchiveContextProvider>
);
