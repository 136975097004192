import {
  PromoCodeApiModel,
  PromoCodeApiResponseModel,
  PromoCodeModel,
  PromoCodeWithErrorModel,
} from './promoCode.model';
import { AggregateApiErrorModel, Nullable } from '../../models';

export const promoCodeAdapter = (promo: PromoCodeApiModel): PromoCodeModel => ({
  ...promo,
});

export const promoCodeWithErrorAdapter = (
  promo?: PromoCodeApiModel,
  error?: AggregateApiErrorModel
): Nullable<PromoCodeWithErrorModel> => {
  if (promo) {
    return promoCodeAdapter(promo);
  }

  if (error) {
    return { status: error?.http_status_code };
  }

  return null;
};

export const promoCodeResponseAdapter = ({
  data,
}: PromoCodeApiResponseModel): PromoCodeModel => promoCodeAdapter(data.data);
