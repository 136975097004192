import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCaseComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  fillColor = '#78747A',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor}
      d="M9.99997.5c-1.933 0-3.5 1.567-3.5 3.5 0 .55228-.44771 1.00001-1 1.00001H3.76553c-1.51297 0-2.78917 1.12661-2.97683 2.6279L.22284 12.1548c-.18518 1.4814.74713 2.8736 2.18748 3.2664l8.27408 2.2565a5.00026 5.00026 0 002.6312 0l8.274-2.2565c1.4404-.3928 2.3727-1.785 2.1875-3.2664l-.5659-4.52689c-.1876-1.50129-1.4638-2.6279-2.9768-2.6279H18.5c-.5523 0-1-.44773-1-1.00001 0-1.933-1.567-3.5-3.5-3.5H9.99997zM14.5 5.00001c.5523 0 1-.44773 1-1.00001 0-.82843-.6716-1.5-1.5-1.5H9.99997c-.82843 0-1.5.67157-1.5 1.5 0 .55228.44772 1.00001 1 1.00001H14.5zM2.77325 7.87598c.06255-.50043.48796-.87597.99228-.87597H20.2344c.5043 0 .9297.37554.9923.87597l.5658 4.52692c.0618.4938-.249.9578-.7291 1.0887l-8.2741 2.2566c-.5168.141-1.0619.141-1.5787 0l-8.27405-2.2566c-.48011-.1309-.79088-.5949-.72916-1.0887l.56586-4.52692z"
    />
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 14.5V20c0 1.1046.89543 2 2 2h16c1.1046 0 2-.8954 2-2v-5.5"
    />
  </svg>
);
