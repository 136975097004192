import React, { ReactElement } from 'react';
import { RightPaymentTabsContainer } from '../rightPaymentTabs';
import { RightHeadStyled, TitleStyled } from './rightHead.styled';
import { useCustomCompensationFormContext } from '../../../customCompensationForm.context';

export const RightHeadContainer = (): ReactElement => {
  const { translations, showPaymentTabs } = useCustomCompensationFormContext();

  return (
    <RightHeadStyled>
      {showPaymentTabs && <RightPaymentTabsContainer />}
      {!showPaymentTabs && (
        <TitleStyled>{translations?.course_price}</TitleStyled>
      )}
    </RightHeadStyled>
  );
};
