import { VendorEnum } from './vendors.model';

export const REQUEST_VENDORS_START = 'REQUEST_VENDORS_START';
export const REQUEST_VENDORS_SUCCESS = 'REQUEST_VENDORS_SUCCESS';
export const REQUEST_VENDORS_META_SUCCESS = 'REQUEST_VENDORS_META_SUCCESS';
export const REQUEST_VENDORS_PAGINATE_SUCCESS =
  'REQUEST_VENDORS_PAGINATE_SUCCESS';
export const REQUEST_VENDORS_ERROR = 'REQUEST_VENDORS_ERROR';
export const START_REQUEST_VENDORS_PAGINATE_SUCCESS =
  'START_REQUEST_VENDORS_PAGINATE_SUCCESS';

export const VendorsLogoSize = {
  [VendorEnum.skillbox]: {
    width: 64,
    height: 20,
  },
  [VendorEnum.skillboxEng]: {
    width: 64,
    height: 20,
  },
  [VendorEnum.geekbrains]: {
    width: 87,
    height: 20,
  },
  [VendorEnum.skillfactory]: {
    width: 92,
    height: 16,
  },
  [VendorEnum.contented]: {
    width: 100,
    height: 16,
  },
  [VendorEnum.uup]: {
    width: 100,
    height: 30,
  },
};
