import styled from 'styled-components';
import { rgba } from 'polished';
import { fontSettings } from '../../../../styles';

type CourseItemProgressBarStyledProps = {
  progress: number;
};

type CourseItemProgressBarMobileProps = {
  isMobile: boolean;
};

export const CourseItemProgressStyled = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const CourseItemProgressBarWrapperStyled = styled.div`
  display: flex;
  flex: 1 1 100%;
  gap: 4px;
  align-items: center;
`;

export const CourseItemProgressBarStyled = styled.div<CourseItemProgressBarStyledProps>`
  height: 8px;
  background: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: ${({ progress }): string => (progress ? `${progress}%` : '0')};
    height: 100%;
    background: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const CourseItemProgressBarTextStyled = styled.div<CourseItemProgressBarMobileProps>`
  text-align: right;

  ${({ theme, isMobile }): string =>
    isMobile
      ? `
        ${fontSettings(theme.FONT.SIZE_11, theme.FONT)};
        font-weight: 700;
        width: 36px;
    `
      : `
        font-weight: 600;
        width: 48px;
   `};
`;

export const CourseItemProgressItemWrapperStyled = styled.div<CourseItemProgressBarMobileProps>`
  display: flex;
  flex-wrap: wrap;

  ${({ isMobile }): string =>
    isMobile
      ? `
        order: 1;
        gap: 12px 16px;
        margin-top: 12px;
    `
      : `
        gap: 20px;
        margin-bottom: 4px;
   `};
`;
