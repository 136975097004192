import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { FooterContainer as Footer, urlService } from '@lerna-core';
import { FooterContainerProps } from './footer.model';
import { pageUrls } from '@constants';

export const FooterContainer = ({
  footerImages,
  translations,
}: FooterContainerProps): ReactElement => {
  const router = useRouter();

  const homePageLink = urlService.getFullUrlToPage(pageUrls.courses, router);

  return (
    <Footer
      footerImages={footerImages}
      translations={translations}
      homePageLink={homePageLink}
    />
  );
};
