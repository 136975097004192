import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPercentWithoutCircleComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#F60065',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM7.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M16 6 8 18"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
