import { SeoApiModel, SeoModel } from '../models';
import { mapImage } from '../features';

export const seoAdapter = (data: SeoApiModel): SeoModel => ({
  title: data?.title,
  description: data?.description,
  text: data?.text,
  image: data.image?.data && mapImage(data.image),
  h1: data?.h1,
  no_index: data?.no_index,
});
