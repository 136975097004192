import { CourseFaqsApiModel, CourseFaqsModel } from './courseFaqs.model';

export const courseFaqsAdapter = (
  faqs?: CourseFaqsApiModel[]
): CourseFaqsModel[] => {
  if (!faqs || !faqs?.length) {
    return [];
  }

  return faqs.map((faq) => ({
    question: faq.question,
    answer: faq.answer,
  }));
};
