import React, { ReactElement } from 'react';

import { TranslationModel } from '../../models';
import { publicConfig, stringService } from '../../services';
import { SwitchLanguagePopupContainer } from '../popups';
import { LanguageDesktopContainer } from './languageDesktop';

type SwitchLanguageContainerProps = {
  isMobile: boolean;
  translations?: TranslationModel;
};

export const SwitchLanguageContainer = ({
  isMobile,
  translations,
}: SwitchLanguageContainerProps): ReactElement | null => {
  const supportedLocales: string[] = stringService.splitStringToArray(
    publicConfig?.SUPPORTED_LOCALES
  );

  if (supportedLocales.length < 2) {
    return null;
  }

  if (isMobile) {
    return (
      <SwitchLanguagePopupContainer
        supportedLocales={supportedLocales}
        translations={translations}
      />
    );
  }

  return <LanguageDesktopContainer supportedLocales={supportedLocales} />;
};
