import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, transition } from '@lerna-core';
import { rgba } from 'polished';
import { TabStyledProps } from './rightPaymentTabs.model';

export const RightPaymentTabsStyled = styled.div`
  display: flex;

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
    `
  )}
`;

export const TabStyled = styled.div<TabStyledProps>`
  padding: 12px 12px 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 12px;
  border: 1px solid ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.12)};
  cursor: pointer;
  flex: 0 1 50%;
  transition: border-color 0.3s ease;
  position: relative;
  box-sizing: border-box;

  ${({ disabled }): string =>
    disabled ? 'pointer-events: none; opacity: 0.4;' : ''}

  ${({ isActive, theme }): string =>
    isActive ? `border-color: ${theme.COLOR.PURPLE_SUBSCRIPTION};` : ''}

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      padding: 11px 12px;
      flex: 0 1 100%;
      min-height: 64px;
      display: flex;
      align-items: center;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 12px;
      }
    `
  )}
`;

export const DotStyled = styled.div<TabStyledProps>`
  display: none;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid ${({ theme }): string => theme.COLOR.CHECKBOX_DEFAULT};
  transition: all 0.3s ease;
  margin-right: 12px;
  position: relative;

  ${({ isActive, theme }): string =>
    isActive ? `border-color: ${theme.COLOR.PURPLE_SUBSCRIPTION};` : ''}

  &::before {
    content: '';
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: ${({ theme }): string => theme.COLOR.PURPLE_SUBSCRIPTION};
    border-radius: 50%;
    ${transition('all')}
  }

  ${({ isActive }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
          display: block;
          ${isActive ? `&::before {opacity: 1;}` : ''}
      }
      `
    )}
`;

export const TabWrappersStyled = styled.div``;
