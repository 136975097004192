import { ImageResourceModel } from '../../../../models';
import { publicConfig } from '../../../../services';
import { SecureRequestClient } from '../../../../services/request/secureRequest.service';
import {
  EducationStreamModel,
  mapEducationStreams,
} from '../../../educationStreams';

type EditUserProps = {
  first_name: string;
  last_name: string;
  patronymic: string;
  phone: string;
  avatar?: ImageResourceModel | null;
  uuid: string;
  education_stream_ids: string[];
};

type EditUserFormProps = {
  lastName: string;
  firstName: string;
  patronymic: string;
  phone: string;
  avatar: ImageResourceModel | null;
  uuid: string;
  educationStreams: EducationStreamModel[];
};

const mapUserPatchProps = (user: EditUserFormProps): EditUserProps => {
  return {
    last_name: user.lastName,
    first_name: user.firstName,
    patronymic: user.patronymic,
    phone: user.phone,
    avatar: user.avatar
      ? {
          entity: user.avatar.entity,
          server: user.avatar.server,
          path: user.avatar.path,
          filename: user.avatar.filename,
          width: user.avatar.width,
          height: user.avatar.height,
          source_server: user.avatar.source_server,
        }
      : null,
    uuid: user.uuid,
    education_stream_ids: mapEducationStreams(user.educationStreams),
  };
};

export class FormUserEditClient extends SecureRequestClient<
  EditUserProps,
  null,
  null
> {
  protected path = 'personal-api/v1/me';
  protected requestParameters: EditUserProps;

  constructor(user: EditUserFormProps) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = mapUserPatchProps(user);
  }
}

type ApiFormError = {
  response: {
    status: number;
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
};

export const mapValidationErrors = (
  error: ApiFormError,
  setNameErrors: (value: string[]) => void,
  setLastNameErrors: (value: string[]) => void,
  setPatronymicErrors: (value: string[]) => void,
  setPhoneErrors: (value: string[]) => void
): boolean => {
  if (!error.response || error.response?.status === 422) {
    if (error.response?.data) {
      const { errors } = error.response?.data;

      if (errors['[last_name]']) {
        setLastNameErrors(errors['[last_name]']);
      }
      if (errors['[first_name]']) {
        setNameErrors(errors['[first_name]']);
      }
      if (errors['[patronymic]']) {
        setPatronymicErrors(errors['[patronymic]']);
      }
      if (errors['[phone]']) {
        setPhoneErrors(errors['[phone]']);
      }
    }

    return false;
  }

  return false;
};
