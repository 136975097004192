import React, { ReactElement } from 'react';
import { StepperProps } from './stepper.model';
import { StepperStyled } from './stepper.styled';
import { StepperItemContainer } from './stepperItem';

export const StepperContainer = ({ steps }: StepperProps): ReactElement => (
  <StepperStyled>
    {steps.map((step, index) => (
      <StepperItemContainer index={index + 1} key={index} {...step} />
    ))}
  </StepperStyled>
);
