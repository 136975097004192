import { useClickOutsideHook, useTheme } from '../../hooks';
import { DropdownContainerStyled } from './dropdown.styled';
import React, {
  CSSProperties,
  forwardRef,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Nullable } from '../../models';
import { DropdownContainerProps, DropdownPosition } from './dropdown.model';

export const DropdownContainer = forwardRef<
  HTMLElement,
  DropdownContainerProps
>(
  (
    { top = 24, position, children, handleOutsideClick, extraStyles },
    ref
  ): Nullable<ReactElement> => {
    const theme = useTheme();

    const [currentPosition, setCurrentPosition] = useState<DropdownPosition>(
      position || DropdownPosition.left
    );
    const [styles, setStyles] = useState<CSSProperties>({
      opacity: 0,
      zIndex: theme.ZINDEX.BOTTOM,
    });
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (componentRef.current) {
        const clientWidth = document.documentElement.clientWidth;
        const { left, width } = componentRef.current.getBoundingClientRect();

        if (
          left + width > clientWidth &&
          currentPosition === DropdownPosition.left
        ) {
          setCurrentPosition(DropdownPosition.right);
        } else if (left < 0 && currentPosition === DropdownPosition.right) {
          setCurrentPosition(DropdownPosition.left);
        }

        setStyles({
          opacity: 1,
          zIndex: theme.ZINDEX.TOP_PAGE_LAYER,
        });
      }
    }, []);

    useClickOutsideHook(ref as RefObject<HTMLDivElement>, handleOutsideClick);

    return (
      <DropdownContainerStyled
        style={styles}
        ref={componentRef}
        position={currentPosition}
        top={top}
        extraStyles={extraStyles}
      >
        {children}
      </DropdownContainerStyled>
    );
  }
);

DropdownContainer.displayName = 'DropdownContainer';
