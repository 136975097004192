import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import {
  FormContentStyled,
  FormContentWrapperStyled,
} from './formContent.styled';
import { useCustomCompensationFormContext } from '../customCompensationForm.context';
import { ContentStatusContainer } from './contentStatus';
import { ContentInfoContainer } from './contentInfo';

export const FormContentContainer = (): ReactElement => {
  const { showFormStatus, formOrderInfo } = useCustomCompensationFormContext();

  return (
    <FormContentWrapperStyled>
      <FormContentStyled>
        <ContentLeftContainer />
        <ContentRightContainer />
        {showFormStatus && <ContentStatusContainer />}
      </FormContentStyled>
      {!showFormStatus && !formOrderInfo.hasOnlyOnlinePayment && (
        <ContentInfoContainer />
      )}
    </FormContentWrapperStyled>
  );
};
