import { AxiosResponse } from 'axios/index';
import { IncomingMessage, OutgoingMessage } from 'http';
import { AppContext } from 'next/app';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { projectTypes } from '../../constants';
import {
  AppService,
  cookieService,
  getUserRequestHeaders,
  logger,
  publicConfig,
  RequestHeader,
  urlService,
} from '../../services';
import { AuthorizationService } from './authorization.service';
import { RefreshTokenClient } from './authorizationRefreshToken/refreshToken.client';
import { AuthorizationPaths } from './authorizationsPaths.enum';

class ServerAuthorizationService extends AuthorizationService {
  constructor() {
    super();
  }

  public initServer(appContext: AppContext): Promise<void> {
    return new Promise((resolve, reject) => {
      if (AppService.isClientSide) {
        resolve();
      }

      const cookie = appContext.ctx.req?.headers.cookie;

      if (this.isAuthed(cookie)) {
        if (this.isTokenUpToDate(cookie)) {
          resolve();
        } else {
          const requestHeaders = cookie ? ({ cookie } as RequestHeader) : {};
          const userRequestHeaders = getUserRequestHeaders(appContext.ctx.req);

          this.refreshToken({ ...requestHeaders, ...userRequestHeaders })
            .then(async (response) => {
              if (!!response.headers['set-cookie']) {
                appContext.ctx.res?.setHeader(
                  'set-cookie',
                  response.headers['set-cookie']
                );

                resolve();
              } else {
                logger.error(
                  `[AUTH SERVER ERROR] refresh-token headers: ${JSON.stringify(
                    response.headers
                  )}`
                );
                reject();
              }
            })
            .catch((err) => {
              const timeNow = new Date().getTime();

              logger.error(
                `[AUTH SERVER ERROR] cookieContainer: ${JSON.stringify(
                  cookie
                )} # timeNow: ${timeNow} # error: ${JSON.stringify(err)}`
              );

              reject(err);
              if (err.response.status === 400) {
                appContext.ctx?.res?.writeHead(302, {
                  Location: serverAuthorizationService.getLogoutLink(),
                });
                appContext.ctx?.res?.end();
              }
            });
        }
      } else {
        reject();
      }
    });
  }

  protected getRedirectUrl(router?: NextRouter): string {
    const subDomain =
      publicConfig?.APP_TYPE !== projectTypes.courses
        ? publicConfig?.APP_TYPE
        : '';

    return urlService.getFullUrlToPageWithQuery(
      router?.asPath || '',
      subDomain
    );
  }

  public getLoginLink(
    isGoogle?: boolean,
    companyUuid?: string,
    router?: NextRouter
  ): string {
    const loginLink = `${publicConfig?.AUTH_URL}${AuthorizationPaths.login}`;
    const redirectUrl = this.getRedirectUrl(router);

    const loginQueryParams: ParsedUrlQuery = {
      redirectUrl,
      kc_idp_hint: isGoogle ? 'google' : '',
      company_id: companyUuid,
    };

    return urlService.setQueryParamsToLink(loginLink, loginQueryParams);
  }

  protected async refreshToken(
    requestHeaders?: RequestHeader
  ): Promise<AxiosResponse<null>> {
    return await new RefreshTokenClient(requestHeaders).get();
  }

  protected getNextAccessToken(
    setCookieHeaders: string[] = []
  ): string | undefined {
    return setCookieHeaders.join().match(this.accessTokenPattern)?.[0];
  }

  public getAuthHeaders(
    req?: IncomingMessage,
    res?: OutgoingMessage
  ): RequestHeader {
    const nextAccessToken = this.getNextAccessToken(
      res?.getHeader('set-cookie') as string[]
    );

    const accessToken = cookieService.getCookie(
      this.ACCESS_TOKEN,
      nextAccessToken || req?.headers.cookie,
      false
    );

    const userRequestHeaders = getUserRequestHeaders(req);

    return accessToken
      ? { Authorization: `Bearer ${accessToken}`, ...userRequestHeaders }
      : userRequestHeaders;
  }
}

export const serverAuthorizationService = new ServerAuthorizationService();
