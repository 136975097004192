import { ImageApiModel, ImageModel, ImageResourceModel } from '../../models';
import { ApiImageResource } from './image.model';

export const mapImage = ({ data }: ImageApiModel): ImageModel => ({
  imageResource: mapImageResource(data.image_resource),
  size: data.size,
  backgroundColor: data.background_color,
});

export const mapImageResource = (
  apiImageResource: ApiImageResource
): ImageResourceModel => {
  return {
    entity: apiImageResource.entity,
    server: apiImageResource.server,
    source_server: apiImageResource.source_server,
    path: apiImageResource.path,
    filename: apiImageResource.filename,
    width: apiImageResource.width,
    height: apiImageResource.height,
  };
};
