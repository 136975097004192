import { UserAvatarContainer, UserAvatarSize } from '../userAvatar';
import { UserDataNameStyled, UserDataStyled } from './userData.styled';
import React, { ReactElement } from 'react';
import { UserDataProps } from './userData.model';
import Link from 'next/link';

export const UserDataContainer = ({
  user,
  profileLink,
}: UserDataProps): ReactElement => {
  return (
    <UserDataStyled>
      <Link href={profileLink}>
        <UserAvatarContainer
          pointer
          personal={user}
          size={UserAvatarSize.medium}
        />
      </Link>
      <Link href={profileLink}>
        <UserDataNameStyled>
          {user.firstName} {user.lastName} {user.patronymic}
        </UserDataNameStyled>
      </Link>
    </UserDataStyled>
  );
};
