import React, { ReactElement } from 'react';
import { PointsBoxContainerStyled, PointsLinkStyled } from './pointsBox.styled';
import { PointsBoxContainerProps } from './pointsBox.model';
import { IconArrowComponent } from '../icons';
import { useTheme } from '../../hooks';
import { BonusPointsContainer } from '../bonusPoints';

export const PointsBoxContainer = ({
  count,
  label,
  href,
}: PointsBoxContainerProps): ReactElement => {
  const theme = useTheme();

  return (
    <PointsBoxContainerStyled hasLink={!!href}>
      <BonusPointsContainer points={count} label={label} />
      {!!href && (
        <PointsLinkStyled href={href}>
          <IconArrowComponent strokeColor={theme.COLOR.WHITE} />
        </PointsLinkStyled>
      )}
    </PointsBoxContainerStyled>
  );
};
