import styled from 'styled-components';

export const TooltipMobileStyled = styled.div`
  position: relative;
  margin-left: 8px;
`;

export const TooltipMobileTriggerStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: max-content;
`;
