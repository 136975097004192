import React, { ReactElement } from 'react';
import { IconLoaderComponent } from '../icons';
import { SpinnerStyled } from './spinner.styled';
import { SpinnerContainerProps } from './spinner.model';

export const SpinnerContainer = ({
  height = 80,
  width = 80,
  strokeColor,
  strokeWidth,
}: SpinnerContainerProps): ReactElement => {
  return (
    <SpinnerStyled width={width} height={height}>
      <IconLoaderComponent
        width={width}
        height={height}
        strokeWidth={strokeWidth}
        strokeColor={strokeColor}
      />
    </SpinnerStyled>
  );
};
