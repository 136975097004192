import {
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  useLayoutEffect,
} from 'react';
import { useWindowSize } from '../../hooks';
import {
  MousePosition,
  TooltipPosition,
  UseTooltipPositionOutput,
  UseTooltipTogglingOutput,
} from './tooltip.model';
import { TooltipArrowPlacement, TooltipArrowPlacements } from './tooltipArrow';

const TOOLTIP_TOP_OFFSET = 30;
const TOOLTIP_LEFT_OFFSET = 16;
const SPACE_OFFSET = 30;

export function useTooltipPosition(
  tooltipRef: MutableRefObject<HTMLDivElement | undefined>
): UseTooltipPositionOutput {
  const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize();

  const [position, setPosition] = useState<TooltipPosition>({
    top: 0,
    left: 0,
  });
  const [arrowPlacements, setArrowPlacements] = useState<TooltipArrowPlacement>(
    {
      vertical: TooltipArrowPlacements.top,
      horizontal: TooltipArrowPlacements.left,
    }
  );
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
  });

  const hasSpaceTop = useMemo(() => {
    const tooltipHeight = tooltipRef.current
      ? tooltipRef.current?.offsetHeight
      : 0;

    return windowHeight - mousePosition.y > tooltipHeight + SPACE_OFFSET;
  }, [windowHeight, mousePosition.y, tooltipRef.current]);

  const hasSpaceLeft = useMemo(() => {
    const tooltipWidth = tooltipRef.current
      ? tooltipRef.current?.offsetWidth
      : 0;

    return windowWidth - mousePosition.x > tooltipWidth + SPACE_OFFSET;
  }, [windowWidth, mousePosition.x, tooltipRef?.current]);

  const handleMove = useCallback((e: MouseEvent) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  }, []);

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      const { x, y } = mousePosition;
      const top = hasSpaceTop
        ? y + TOOLTIP_TOP_OFFSET
        : y - tooltipRef.current?.offsetHeight - TOOLTIP_TOP_OFFSET / 2;

      const left = hasSpaceLeft
        ? x - TOOLTIP_LEFT_OFFSET
        : x - tooltipRef.current?.offsetWidth + TOOLTIP_LEFT_OFFSET * 2;

      setPosition({
        top,
        left,
      });
    }
  }, [mousePosition, hasSpaceTop, hasSpaceLeft, tooltipRef.current]);

  useLayoutEffect(() => {
    setArrowPlacements({
      horizontal: hasSpaceLeft
        ? TooltipArrowPlacements.left
        : TooltipArrowPlacements.right,
      vertical: hasSpaceTop
        ? TooltipArrowPlacements.top
        : TooltipArrowPlacements.bottom,
    });
  }, [hasSpaceTop, hasSpaceLeft]);

  return useMemo(
    () => ({
      position,
      arrowPlacements,
      handleMove,
    }),
    [handleMove, position, arrowPlacements]
  );
}

export function useTooltipToggling(): UseTooltipTogglingOutput {
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return useMemo(
    () => ({
      isOpen,
      handleOpen,
      handleClose,
    }),
    [isOpen]
  );
}
