import React, { ReactElement } from 'react';
import {
  ContentSwitcherStyled,
  SwitcherStyled,
} from './contentSwitcher.styled';
import { useFormSubscriptionContext } from '../formSubscription.context';
import { FormSubscriptionContentTypes } from '../formSubscription.model';
import {
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '../../../form.analytics';
import { CommonEventTypes } from '@lerna-core';
import { UserOrderFormPaymentContentTypes } from '../../userOrderForm.model';

export const ContentSwitcherContainer = (): ReactElement => {
  const {
    translations,
    formContent,
    paymentContent,
    isSubscriptionFormContentSelected,
    handleChangeFormContent,
    course,
    eventPosition,
    showPaymentTabs,
  } = useFormSubscriptionContext();

  let switcherText = isSubscriptionFormContentSelected
    ? translations?.subscription_form_switcher_text_1
    : translations?.subscription_form_switcher_text_2;

  const handleClickSwitcher = (): void => {
    let event: string;
    const eventValueOptions = [OrderFormEventsValues.subscription];

    switch (formContent) {
      case FormSubscriptionContentTypes.subscription:
        event = `${OrderFormEvents.formEnrollSelectSelfPay}`;
        eventValueOptions.push(OrderFormEventsValues.noTabs);
        eventValueOptions.push(
          course.subscriptionAccess?.withHomeworks
            ? OrderFormEventsValues.withHomework
            : OrderFormEventsValues.noHomework
        );

        handleChangeFormContent(FormSubscriptionContentTypes.fullAccess);
        break;
      case FormSubscriptionContentTypes.fullAccess:
        event = `${OrderFormEvents.formEnrollSelectCompanyPay}`;
        eventValueOptions.push(
          showPaymentTabs
            ? OrderFormEventsValues.withTabs
            : OrderFormEventsValues.noTabs
        );

        switch (paymentContent) {
          case UserOrderFormPaymentContentTypes.consultation:
            eventValueOptions.push(OrderFormEventsValues.consultation);
            break;
          case UserOrderFormPaymentContentTypes.onlinePay:
            eventValueOptions.push(OrderFormEventsValues.paymentOnline);
            break;
        }

        handleChangeFormContent(FormSubscriptionContentTypes.subscription);
        break;
    }

    orderFormEvent(
      event,
      eventValueOptions,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course
    );
  };

  return (
    <ContentSwitcherStyled>
      <SwitcherStyled onClick={handleClickSwitcher}>
        <span>{switcherText}</span>
      </SwitcherStyled>
    </ContentSwitcherStyled>
  );
};
