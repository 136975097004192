import { VendorLogoSizeProps } from './vendorsLogo.model';
import { VendorEnum } from '../vendors.model';
import { VendorsLogoSize } from '../vendors.constants';

type SizePropsModel = {
  width?: number;
  height?: number;
};

/**
 * Get the size of the icon according to the specified values
 * @return {object}
 * @param width
 * @param height
 * */
export const getVendorLogoSize = (
  vendor: VendorEnum,
  sizeProps: SizePropsModel
): VendorLogoSizeProps | null => {
  const defaultSize = VendorsLogoSize[vendor];

  if (!defaultSize) {
    return null;
  }

  switch (true) {
    case !!sizeProps.width && !!sizeProps.height: {
      return {
        width: sizeProps.width,
        height: sizeProps.height,
      };
    }

    case !!sizeProps.width: {
      return {
        width: sizeProps?.width,
        height: Math.round(
          (sizeProps?.width || 0) / (defaultSize.width / defaultSize.height)
        ),
      };
    }

    case !!sizeProps.height: {
      return {
        width: Math.round(
          (sizeProps?.height || 0) / (defaultSize.height / defaultSize.width)
        ),
        height: sizeProps?.height,
      };
    }

    default:
      return null;
  }
};
