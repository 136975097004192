import { ImageApiModel, ImageModel } from '../../models';

export type AdvantagesImageApiModel = {
  data: {
    image: ImageApiModel;
    slug: string;
    title: string;
    type: string;
  };
};

export enum AdvantagesPrivilegeTypes {
  default = 'default',
  cashback = 'cashback',
}

export type AdvantagesPrivileges = {
  title: string;
  subtitle: string;
  type: AdvantagesPrivilegeTypes;
  popup: {
    title: string;
    items: string[];
  } | null;
};

export type AdvantagesApiModel = {
  data: {
    title: string;
    subtitle: string;
    type: AdvantagesPrivilegeTypes;
    background: string;
    color: string;
    image: AdvantagesImageApiModel;
    privileges: AdvantagesPrivileges[];
  };
};

export type AdvantagesModel = {
  title: string;
  subtitle: string;
  image: ImageModel;
  background: string;
  color: string;
  privileges: AdvantagesPrivileges[];
};
