import React, { ReactElement, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { IconNextComponent, useTheme } from '@lerna-core';
import { CourseBackContainerStyled } from './courseBack.styled';
import { CourseBackContainerProps } from './courseBack.model';
import { getCourseBackHref } from './courseBack.helpers';

export const CourseBackContainer = ({
  translations,
  backHrefPath,
}: CourseBackContainerProps): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const href = useMemo(() => getCourseBackHref(router, backHrefPath), []);

  return (
    <CourseBackContainerStyled>
      <Link href={href} passHref>
        <IconNextComponent strokeColor={theme.COLOR.TEXT_LIGHT_COLOR} />
        {translations?.back_label}
      </Link>
    </CourseBackContainerStyled>
  );
};
