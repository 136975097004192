import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { customerAdapter } from './customer.adapter';
import { CustomerApiResponseModel, CustomerModel } from './customer.model';

export class CustomerClient extends SecureRequestClient<
  null,
  CustomerApiResponseModel,
  CustomerModel
> {
  protected path = '';
  protected requestParameters: null;
  protected adapter = customerAdapter;

  constructor(customerUUID: string, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );

    this.path = `user-api/v1/customers/${customerUUID}`;
    this.requestParameters = null;
  }
}
