import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconRefreshComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#29004D',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.488 9A9 9 0 0 0 5.636 5.636L2.271 9m1.24 6a9 9 0 0 0 14.851 3.364L21.729 15M2.272 9V3m0 6H8.27m13.458 6v6m0-6H15.73"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
