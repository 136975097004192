import React, { ReactElement, useEffect, useState } from 'react';

import { useTheme } from '../../../hooks';
import {
  IconCheckboxCheckedComponent,
  IconCheckboxComponent,
} from '../../icons';
import { CheckboxColorState, getColor } from './checkbox.helper';
import { Checkbox } from './checkbox.input';
import { CheckboxCurrentColorModel, CheckboxTypes } from './checkbox.model';
import { CheckboxContainerStyled, CheckedStyled } from './checkbox.styled';

export const CheckboxContainer = ({
  label,
  defaultChecked = false,
  onClick = (): void => {
    return;
  },
  checkboxColors,
}: CheckboxTypes): ReactElement => {
  const [checked, setChecked] = useState(defaultChecked);
  const theme = useTheme();
  const checkboxDefaultColors = {
    default: theme.COLOR.CHECKBOX_DEFAULT,
    defaultIcon: theme.COLOR.CHECKBOX_ICON_DEFAULT,
    hover: theme.COLOR.CHECKBOX_HOVER,
    hoverIcon: theme.COLOR.CHECKBOX_ICON_HOVER,
    checked: theme.COLOR.TEXT_DARK_COLOR,
  };

  const [color, setColor] = useState<CheckboxCurrentColorModel>(
    getColor(
      checked ? CheckboxColorState.checked : CheckboxColorState.default,
      checkboxColors || checkboxDefaultColors
    )
  );
  useEffect(() => {
    setChecked(defaultChecked);
    setColor(
      getColor(
        defaultChecked
          ? CheckboxColorState.checked
          : CheckboxColorState.default,
        checkboxColors || checkboxDefaultColors
      )
    );
  }, [defaultChecked]);
  const onChange = (): void => {
    setChecked(!checked);
    onClick();
  };

  const handleSetColor = (isInside: boolean): void => {
    if (isInside) {
      setColor(
        getColor(
          CheckboxColorState.hover,
          checkboxColors || checkboxDefaultColors
        )
      );
    } else {
      setColor(
        getColor(
          checked ? CheckboxColorState.checked : CheckboxColorState.default,
          checkboxColors || checkboxDefaultColors
        )
      );
    }
  };

  return (
    <CheckboxContainerStyled
      color={color.label}
      onMouseEnter={(): void => handleSetColor(true)}
      onMouseLeave={(): void => handleSetColor(false)}
      onFocus={(): void => handleSetColor(true)}
    >
      {checked ? (
        <CheckedStyled>
          <IconCheckboxCheckedComponent
            fillColor={checkboxColors?.checked || theme.COLOR.TEXT_DARK_COLOR}
            strokeColor={checkboxColors?.checked || theme.COLOR.TEXT_DARK_COLOR}
          />
        </CheckedStyled>
      ) : (
        <IconCheckboxComponent
          strokeColor={color.icon}
          fillColor={checkboxColors?.defaultFill}
        />
      )}
      <Checkbox checked={checked} onChange={onChange} />
      <span>{label}</span>
    </CheckboxContainerStyled>
  );
};
