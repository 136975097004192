import React, { ReactElement } from 'react';
import { CourseDescriptionContainerStyled } from './courseDescription.styled';
import { CourseTitleContainer, CourseTitleType } from '../courseTitle';
import { SeoTextContainer } from '@features/common/seoText';
import { CourseDescriptionContainerProps } from './courseDescription.model';

export const CourseDescriptionContainer = ({
  description,
  translations,
}: CourseDescriptionContainerProps): ReactElement => (
  <CourseDescriptionContainerStyled>
    <CourseTitleContainer
      title={translations.course_description_title}
      type={CourseTitleType.h3}
    />
    <SeoTextContainer html={description} />
  </CourseDescriptionContainerStyled>
);
