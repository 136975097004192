import { TextAreaControlProps } from './textAreaControl.model';
import React, { ReactElement } from 'react';
import { Label } from '../../../label';
import { Textarea } from '../../../formInputs';
import { FormControl, FormControlError } from '../../../formControl';
import { InputColors } from '../../../../../styles';

export const TextAreaControlComponent = ({
  label,
  name,
  disabled = false,
  touched,
  errorMessage,
  statusMessage,
  value,
  onChange,
  onBlur,
  placeholder,
  color = InputColors.white,
  floatingPlaceholder = true,
  bordered = true,
  className,
  setFieldValue,
  showResetButton,
  margin,
  rows,
}: TextAreaControlProps): ReactElement => {
  const hasError = Boolean(errorMessage || statusMessage) && touched;

  return (
    <FormControl className={className} margin={margin}>
      {label && !floatingPlaceholder && <Label htmlFor={name}>{label}</Label>}
      <Textarea
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        placeholder={floatingPlaceholder ? label : placeholder}
        color={color}
        floatingPlaceholder={floatingPlaceholder}
        onReset={setFieldValue}
        showResetButton={showResetButton}
        hasError={hasError}
        bordered={bordered}
        rows={rows}
      />
      {errorMessage && touched ? (
        <FormControlError as="div">{errorMessage}</FormControlError>
      ) : null}

      {statusMessage && touched ? (
        <FormControlError as="div">{statusMessage}</FormControlError>
      ) : null}
    </FormControl>
  );
};
