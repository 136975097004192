import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLoginLComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.0002H14.7274M11.091 8.36377L14.7274 12.0002L11.091 15.6366"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.09961 16.5456C4.75599 19.7811 8.11513 22.0003 11.9997 22.0003C17.5225 22.0003 21.9999 17.5229 21.9999 12.0001C21.9999 6.47733 17.5225 2 11.9997 2C8.11513 2 4.75599 4.21912 3.09961 7.45462"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
