import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const MobileCheckboxTypeStyled = styled.div``;

export const MobileCheckboxTypeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileCheckboxTypeDescriptionStyled = styled.div`
  margin-top: 12px;
  color: ${({ theme }): string => theme.COLOR.TEXT[100]};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)}
`;
