import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconAnotherComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.665 14.583c-2.977 0-5.417 2.44-5.417 5.417s2.44 5.417 5.417 5.417 5.417-2.44 5.417-5.417-2.44-5.417-5.417-5.417Zm13.333 0c-2.977 0-5.416 2.44-5.416 5.417s2.44 5.417 5.416 5.417c2.977 0 5.417-2.44 5.417-5.417s-2.44-5.417-5.417-5.417Zm13.334 0c-2.977 0-5.417 2.44-5.417 5.417s2.44 5.417 5.417 5.417 5.416-2.44 5.416-5.417-2.44-5.417-5.416-5.417Zm-26.667 2.5A2.899 2.899 0 0 1 9.582 20a2.899 2.899 0 0 1-2.917 2.917A2.899 2.899 0 0 1 3.748 20a2.898 2.898 0 0 1 2.917-2.917Zm13.333 0A2.899 2.899 0 0 1 22.915 20a2.899 2.899 0 0 1-2.917 2.917A2.899 2.899 0 0 1 17.082 20a2.898 2.898 0 0 1 2.916-2.917Zm13.334 0A2.899 2.899 0 0 1 36.248 20a2.898 2.898 0 0 1-2.916 2.917A2.899 2.899 0 0 1 30.415 20a2.898 2.898 0 0 1 2.917-2.917Z"
        fill={fillColor}
      />
    </svg>
  );
};
