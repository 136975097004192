import React, { ReactElement } from 'react';

import { ImageResourceModel } from '../../models';
import { ImageDefaultContainer } from './imageDefault';
import { ImageSvgContainer } from './imageSvg';
import { ImageIcoContainer } from './imageIco';

type ImageContainerProps = {
  imageResource: ImageResourceModel;
  mediaDomain?: string;
  proxyOrigin?: string;
  preset?: string;
  width?: number;
  height?: number;
  alt?: string;
  title?: string;
  useMaxQuality?: boolean;
};

export const ImageContainer = ({
  imageResource,
  mediaDomain,
  proxyOrigin,
  preset,
  width,
  height,
  alt,
  title,
  useMaxQuality,
}: ImageContainerProps): ReactElement | null => {
  const isSvg = imageResource?.filename?.slice(-4) === '.svg' || false;
  const isIco = imageResource?.filename?.slice(-4) === '.ico' || false;
  const imageProps = {
    imageResource,
    mediaDomain,
    proxyOrigin,
    width,
    height,
    alt,
    title,
    useMaxQuality,
  };

  //todo: Сделать общий компонент на картинки с другими урлами
  switch (true) {
    case isSvg:
      return <ImageSvgContainer {...imageProps} />;
    case isIco:
      return <ImageIcoContainer {...imageProps} />;
    case !!preset:
      return (
        <ImageDefaultContainer {...imageProps} preset={preset as string} />
      );
    default:
      return null;
  }
};
