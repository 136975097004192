import React, { ReactElement, useCallback } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { useGranulePayFormContext } from '../../granulePayForm.context';
import { LeftHeadContainer } from './leftHead';
import { LeftDescriptionContainer } from './leftDescription';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { CommonEventTypes } from '@lerna-core';

export const ContentLeftContainer = (): ReactElement => {
  const { course, translations, eventPosition, isMobile } =
    useGranulePayFormContext();

  const handleDescriptionCollapseClick = useCallback(() => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollMoreDetails,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course
    );
  }, []);

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftHeadContainer
        course={course}
        translations={translations}
        isMobile={isMobile}
      />
      {course.description && (
        <LeftDescriptionContainer
          description={course.description}
          translations={translations}
          handleCollapseClick={handleDescriptionCollapseClick}
          isMobile={isMobile}
        />
      )}
    </ContentLeftStyled>
  );
};
