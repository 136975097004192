import React, { memo, ReactElement } from 'react';
import { CoursesCountStyled, CoursesNumberStyled } from './coursesCount.styled';
import { useCoursesPageContext } from '../coursesPage';

export const CoursesCount = (): ReactElement => {
  const { pagination, translations } = useCoursesPageContext();

  return (
    <CoursesCountStyled>
      {translations?.courses_found}{' '}
      <CoursesNumberStyled>{pagination.total}</CoursesNumberStyled>
    </CoursesCountStyled>
  );
};

export const CoursesCountContainer = memo(CoursesCount);
