import { useShowLegalEntitiesPriceHook, useUserTariff } from '@hooks';
import { CourseTypes, TagLabelSize } from '@lerna-core';
import React, { ReactElement } from 'react';
import { ItemDiscountContainer } from '../itemDiscount';
import { ItemInstallmentContainer } from '../itemInstallment';
import { ItemPriceContainer } from '../itemPrice';
import { ItemInnerRowStyled, ListItemBoxStyled } from '../listItem.styled';
import { ListItemButtonsContainer } from '../listItemButtons';
import {
  CompanyBonusPointsCompensationTagContainer,
  CompanyCompensationTagContainer,
  CompensationTagContainer,
  SubscriptionTagContainer,
} from '../listItemTags';
import { ListItemFooterContainerProps } from './listItemFooter.model';
import { ListItemFooterStyled } from './listItemFooter.styled';

//todo: refactor prices
export const ListItemFooter = ({
  course,
  courseIndex,
  translations,
  handleGoToCourse,
  handleOpenSingUpCoursePopup,
  withButtons,
  isListingWithBenefitsOnly,
  isPricesHiddenOnListingCards,
  courseHref,
  useHref,
  hrefTarget,
}: ListItemFooterContainerProps): ReactElement => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();
  const { isLernaPrime } = useUserTariff();

  //todo вернуть КТТС course.status === CourseStatus.active
  const courseStatusIsActive = !!course.isActive;

  const isGranule = course.type === CourseTypes.granule;
  const isGranuleWithPrices =
    isGranule && isLernaPrime && !course?.subscriptionAccess;
  const showNotActiveSubscriptionTag =
    !courseStatusIsActive &&
    !!course?.subscriptionAccess &&
    !isListingWithBenefitsOnly;
  const showItemInfo = courseStatusIsActive && !isListingWithBenefitsOnly;
  const showPrice =
    isGranuleWithPrices ||
    (!course?.subscriptionAccess && !isPricesHiddenOnListingCards);
  const showDiscounts = showPrice && !course.isFree;
  const showInstallment =
    !course.isFree && !course.isFreeByDiscount && !showLegalEntitiesPrice;

  const defaultTagSize = TagLabelSize.big;

  return (
    <ListItemFooterStyled isActive={courseStatusIsActive}>
      {showNotActiveSubscriptionTag && (
        <SubscriptionTagContainer
          size={defaultTagSize}
          translations={translations}
          course={course}
          courseIndex={courseIndex}
        />
      )}
      {showItemInfo && (
        <>
          <ListItemBoxStyled withPrice={showPrice}>
            {showDiscounts && (
              <ItemDiscountContainer
                price={course.price}
                translations={translations}
                megaDiscount={course.megaDiscount}
                isFreeCourseByDiscount={course.isFreeByDiscount}
              />
            )}
            {showPrice && (
              <>
                <CompanyCompensationTagContainer
                  price={course.price}
                  translations={translations}
                  size={defaultTagSize}
                  course={course}
                  courseIndex={courseIndex}
                />
                <CompanyBonusPointsCompensationTagContainer
                  price={course.price}
                  translations={translations}
                  size={defaultTagSize}
                  course={course}
                  courseIndex={courseIndex}
                />
                <CompensationTagContainer
                  price={course.price}
                  translations={translations}
                  size={defaultTagSize}
                  course={course}
                  courseIndex={courseIndex}
                />
              </>
            )}
            {!!course.subscriptionAccess && (
              <SubscriptionTagContainer
                size={defaultTagSize}
                translations={translations}
                course={course}
                courseIndex={courseIndex}
              />
            )}
          </ListItemBoxStyled>
          {showPrice && (
            <ItemInnerRowStyled>
              <ItemPriceContainer
                price={course.price}
                translations={translations}
                isFreeCourse={course.isFree}
                isFreeCourseByDiscount={course.isFreeByDiscount}
              />
              {showInstallment && (
                <ItemInstallmentContainer
                  price={course.price}
                  translations={translations}
                />
              )}
            </ItemInnerRowStyled>
          )}
        </>
      )}
      {withButtons && (
        <ItemInnerRowStyled>
          <ListItemButtonsContainer
            handleGoToCourse={handleGoToCourse}
            courseHref={courseHref}
            useHref={useHref}
            hrefTarget={hrefTarget}
            handleOpenSingUpCoursePopup={handleOpenSingUpCoursePopup}
            isActive={courseStatusIsActive}
            isGranuleCourse={isGranule}
          />
        </ItemInnerRowStyled>
      )}
    </ListItemFooterStyled>
  );
};
