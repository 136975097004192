import React, { ReactElement } from 'react';

import { SectionHeadingModel } from './sectionHeading.model';
import {
  SectionHeadingCountStyled,
  SectionHeadingStyled,
} from './sectionHeading.styled';

export const SectionHeadingContainer = ({
  count,
  heading,
  isMobile,
}: SectionHeadingModel): ReactElement => {
  const hasCount = typeof count === 'number';

  return (
    <SectionHeadingStyled isMobile={isMobile}>
      {heading}
      {hasCount && (
        <SectionHeadingCountStyled>{count}</SectionHeadingCountStyled>
      )}
    </SectionHeadingStyled>
  );
};
