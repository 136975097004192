import { publicConfig } from '../../../../../services';

export const SideMenuEventAppTypeReplacer = '{{app_type}}';
export const SideMenuEventPageReplacer = '{{page}}';
export const SideMenuEvent = `go_to_${SideMenuEventAppTypeReplacer}_account_${SideMenuEventPageReplacer}`;

export const getSideMenuLinkAnalyticsEvent = (link: string): string => {
  const appType = publicConfig?.APP_TYPE;
  const eventPage = link[0] === '/' ? link.slice(1) : link;

  return SideMenuEvent.replace(SideMenuEventAppTypeReplacer, appType)
    .replace(SideMenuEventPageReplacer, eventPage)
    .replace(/-/g, '_');
};
