import { CookiePermissionsModel } from '../../features';
import { publicConfig } from '../config/config.model';
import { Nullable } from '../../models';
import {
  GTMConsentMode,
  GTMConsentModeModel,
  GTMConsentStatus,
} from './gtm.model';

export const getConsentModeData = (
  cookiePermissions?: Nullable<CookiePermissionsModel>
): GTMConsentModeModel => {
  const enableCookieConsent = publicConfig.ENABLE_COOKIE_CONSENT === 'true';
  const adPermission =
    !enableCookieConsent || cookiePermissions?.advertising
      ? GTMConsentStatus.granted
      : GTMConsentStatus.denied;
  const analyticsPermission =
    !enableCookieConsent || cookiePermissions?.analytics
      ? GTMConsentStatus.granted
      : GTMConsentStatus.denied;
  const functionalityPermission = GTMConsentStatus.granted;

  return {
    [GTMConsentMode.adStorage]: adPermission,
    [GTMConsentMode.adUserData]: adPermission,
    [GTMConsentMode.adPersonalization]: adPermission,
    [GTMConsentMode.analyticsStorage]: analyticsPermission,
    [GTMConsentMode.functionalityStorage]: functionalityPermission,
  };
};

export const setConsentModeData = (
  cookiePermissions: Nullable<CookiePermissionsModel>
): void => {
  if (publicConfig?.ENABLE_COOKIE_CONSENT !== 'true') return;
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // @ts-ignore
    window.dataLayer.push(arguments);
  }

  // @ts-ignore
  gtag('consent', 'update', getConsentModeData(cookiePermissions));
};
