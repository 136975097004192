import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { SubscriptionContentStyled } from './subscriptionContent.styled';

export const SubscriptionContentContainer = (): ReactElement => (
  <SubscriptionContentStyled>
    <ContentLeftContainer />
    <ContentRightContainer />
  </SubscriptionContentStyled>
);
