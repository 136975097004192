import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const InvalidPhoneMessageStyled = styled.div`
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  background-color: ${({ theme }): string => theme.COLOR.ERROR[100]};
  box-shadow: 0 4px 24px -5px rgba(255, 229, 230, 0.32);
  border-radius: 16px;
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_PAGE_HINTS};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  min-width: max-content;

  ${breakpoint(
    'mediumDown',
    css`
      min-width: unset;
      max-width: 335px;
      width: calc(100% - 20px);
    `
  )}
`;

export const MessageStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageTextStyled = styled.div``;

export const MessageLinkStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.ERROR[500]};
  text-decoration: underline;
  display: none;

  ${breakpoint(
    'mediumDown',
    css`
      display: block;
    `
  )}
`;

export const MessageIconStyled = styled.div`
  display: flex;
  margin-right: 16px;
  flex: 0 0 24px;
`;

export const MessageButtonStyled = styled.div`
  margin-left: 16px;

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `
  )}
`;
