import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { DoughnutWrapperModel } from './doughnut.model';

export const DoughnutStyled = styled.svg`
  display: flex;
  transform: rotate(-90deg);
`;

export const DoughnutSegmentStyled = styled.circle`
  display: flex;
`;

export const DoughnutWrapperStyled = styled.div<DoughnutWrapperModel>`
  position: relative;
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
`;

export const DoughnutTextWrapperStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DoughnutTextStyled = styled.div`
  font-weight: 700;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};
`;

export const DoughnutSubtextStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)};
`;
