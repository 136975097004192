import { publicConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { customersApiAdapter } from './customers.adapter';
import {
  CustomersApiResponseModel,
  CustomersModel,
  CustomersRequestApiParams,
  CustomersRequestParams,
} from './customers.model';
import { mapCustomersRequestParams } from './customers.service';

export class CustomersClient extends SecureRequestClient<
  CustomersRequestApiParams,
  CustomersApiResponseModel,
  CustomersModel
> {
  protected path = '';
  protected requestParameters: CustomersRequestApiParams;
  protected adapter = customersApiAdapter;

  constructor(companyId: string, requestParams: CustomersRequestParams) {
    super(publicConfig.API_GATEWAY_URL);

    this.path = `user-api/v1/companies/${companyId}/customers`;
    this.requestParameters = mapCustomersRequestParams(requestParams);
  }
}
