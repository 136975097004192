import {
  CoursesFilter,
  CoursesFiltersActions,
  CoursesFiltersConstants,
} from './coursesFilters.model';

export const requestCoursesFiltersSuccess = (
  payload: CoursesFilter
): CoursesFiltersActions => ({
  type: CoursesFiltersConstants.REQUEST_COURSES_FILTERS_SUCCESS,
  payload,
});
