import { AxiosError } from 'axios';
import { Dispatch } from 'react';

import { logger, RequestHeader } from '../../services';
import {
  CompanyActions,
  requestCompanyError,
  requestCompanySuccess,
} from '../company';
import { userClientProvider } from './user.clientProvider';
import * as constants from './user.constants';
import { UserActions, UserAggregateModel } from './user.model';

export const requestUser = async (
  dispatch: Dispatch<UserActions | CompanyActions>,
  requestHeaders?: RequestHeader
): Promise<void> =>
  await userClientProvider(requestHeaders)
    .then((response) => {
      dispatch(requestUserSuccess(response));
      if (response.company) {
        dispatch(requestCompanySuccess(response.company));
      }
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not get user ${error}`);
      dispatch(requestUserError(error));
      dispatch(requestCompanyError(error));
    });

export const reloadUser = (dispatch: Dispatch<UserActions>): void => {
  userClientProvider()
    .then((response) => {
      dispatch(requestUserSuccess(response));
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not reload user ${error}`);
      dispatch(requestUserError(error));
    });
};

export const requestUserReload = (): UserActions => ({
  type: constants.REQUEST_USER_RELOAD,
});

export const requestUserSuccess = (
  payload: UserAggregateModel
): UserActions => ({
  type: constants.REQUEST_USER_SUCCESS,
  payload,
});

export const requestUserError = (payload: AxiosError): UserActions => ({
  type: constants.REQUEST_USER_ERROR,
  payload,
});
