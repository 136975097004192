import styled from 'styled-components';
import { fontSettings } from '../../styles';

export const HeadingStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  margin-bottom: 24px;
  font-weight: 700;
  white-space: pre;
`;
