import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const FilterTabsStyled = styled.div`
  margin: -8px 0;
`;

export const FilterTabsItemsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterTabsEmptyTitleStyled = styled.div`
  text-align: center;
  padding: 45px 0 16px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
`;

export const FilterTabsEmptySubTitleStyled = styled.div`
  text-align: center;
  padding-bottom: 65px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
`;

export const FilterTabsBorderStyled = styled.div``;
