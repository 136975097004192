import styled from 'styled-components';
import { fontSettings } from '../../styles';

export const PointsBoxMediumStyled = styled.div`
  max-width: 320px;
  padding: 20px;
  background: linear-gradient(73.3deg, #ee2c72 7.79%, #4c07df 91.63%);
  color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 16px;
  box-sizing: border-box;
`;

export const PointsBoxMediumLabelStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
`;

export const PointsBoxMediumValueStyled = styled.div`
  font-weight: 700;
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
`;

export const PointsBoxMediumLegendStyled = styled.div`
  margin-top: 26px;
  opacity: 0.64;
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
`;
