import styled from 'styled-components';
import {
  CheckboxContainerStyled,
  fontSettings,
  positionSticky,
  STICKY_HEADER_HEIGHT,
} from '@lerna-core';
import {
  FilterOptionsEmptySubTitleStyled,
  FilterOptionsEmptyTitleStyled,
  FilterOptionsItemsStyled,
  FilterOptionsSearchMagnifierStyled,
  FilterOptionsSearchStyled,
  FilterOptionStyled,
  FilterRangeItemStyled,
} from '@features/common/filters';
import { FilterTabsStyled } from '@features/common/filters/filterTabs';
import { rgba } from 'polished';

type FilterItemTypeTitleStyledProps = {
  hasSelected: boolean;
};

export const FilterItemTypeTitleStyled = styled.div<FilterItemTypeTitleStyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ hasSelected }): string => (hasSelected ? '70' : '100')}%;
  display: flex;
  align-items: center;
`;

export const FilterItemTypeValueStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 400;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  max-width: 30%;
  text-align: right;
`;

export const FilterPopupMobileContainerStyled = styled.div`
  ${FilterRangeItemStyled} {
    padding-top: 16px;
  }

  ${FilterOptionsItemsStyled} {
    max-height: unset;
    border: none;
    padding: 0;
    margin: 0;
  }

  ${FilterTabsStyled} {
    margin: 16px 0 0;
  }

  ${FilterOptionsSearchStyled} {
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    top: ${STICKY_HEADER_HEIGHT - 1}px;
    z-index: ${({ theme }): number => theme.ZINDEX.TOP_BLOCK};
    ${positionSticky()};
    margin: -8px -20px 0 -20px;
    padding: 8px 20px 16px;
    border-bottom: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};

    ${FilterOptionsSearchMagnifierStyled} {
      left: 32px;
      top: 20px;
      transform: unset;
    }
  }

  ${CheckboxContainerStyled} {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;

    & > svg {
      top: 15px;
    }
  }

  ${FilterOptionStyled} {
    margin-bottom: 0;

    &:not(:first-child) {
      border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
    }
  }

  ${FilterOptionsEmptyTitleStyled} {
    font-weight: 700;
    margin-top: 16px;
  }

  ${FilterOptionsEmptySubTitleStyled} {
    color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  }

  input {
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid ${({ theme }): string => theme.COLOR.BLACK_12};

    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    &:hover {
      border-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.24)};
    }

    &:focus,
    &:focus-within {
      border-color: ${({ theme }): string => theme.COLOR.PRIMARY[500]};
    }
  }
`;

export const MobileFilterTypeContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterMobileFirstStepStyled = styled.div``;

export const FilterMobileFirstStepOptions = styled.div``;
