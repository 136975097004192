import {
  checkedFilterRange,
  FILTER_QUERY_VALUE_SEPARATOR,
  FilterModel,
  FilterOptionModel,
  FilterRangeModel,
  FilterRangeOptionModel,
  FilterRangePrefixEnum,
  FiltersModel,
  FiltersOption,
  FilterTypeEnum,
  getFilterCheckedOptions,
  getFilterSelectedOptionsCount,
  GTMEventFilterModel,
  pushDataLayer,
  stringService,
} from '@lerna-core';

export enum FilterEvent {
  constructorFiltersMain = 'constructor_filters_main',
  constructorFiltersFixed = 'constructor_filters_fixed',
  constructorFiltersCardCourse = 'constructor_filters_card_course',
}

export enum FilterEventTypes {
  searchFilterEvents = 'search_filter_events',
}

export enum FilterEventParams {
  allParam = 'all_param',
  any = 'any',
}

export enum FilterEventCategories {
  allCategory = 'all_category',
}

const getFixedFilterOptionsParam = (
  filter: FilterModel,
  isClear?: boolean
): string => {
  const checkedOptions = getFilterCheckedOptions([filter], filter.slug);

  if (checkedOptions.length === filter.options.length || isClear) {
    return FilterEventParams.any;
  }

  return checkedOptions.map((option) => option.slug).join(',');
};

const getMainFilterOptionsParams = (
  filter: FilterModel,
  currentOption: FilterOptionModel,
  isClear?: boolean
): string => {
  let currentCheckedOptions = filter.options.filter((option) => option.checked);
  const currentCheckedOptionsSlugs = currentCheckedOptions.map(
    (option) => option.slug
  );

  if (currentCheckedOptionsSlugs.includes(currentOption.slug)) {
    currentCheckedOptions = currentCheckedOptions.filter(
      (option) => option.slug !== currentOption.slug
    );
  } else {
    currentCheckedOptions.push(currentOption);
  }

  if (
    !currentCheckedOptions.length ||
    currentCheckedOptions.length === filter.options.length ||
    isClear
  ) {
    return FilterEventParams.any;
  }

  return currentCheckedOptions.map((option) => option.slug).join(',');
};

const getToggleParam = (filter: FilterModel, isClear?: boolean): string => {
  const [filterOption] = filter.options;

  if (isClear) {
    return FilterEventParams.any;
  }

  return filterOption?.slug || FilterEventParams.any;
};

const getFixedFilterRangeParam = (
  filter: FilterRangeModel,
  isClear?: boolean
): string => {
  if (!checkedFilterRange(filter.options) || isClear) {
    return FilterEventParams.any;
  }

  const from = filter.options.from !== '' ? filter.options.from : filter.min;
  const to = filter.options.to !== '' ? filter.options.to : filter.max;

  return `${from}-${to}`;
};

const getMainFilterRangeParam = (
  filter: FilterRangeModel,
  currentOption: FilterRangeOptionModel
): string => {
  if (!checkedFilterRange(currentOption)) {
    return FilterEventParams.any;
  }

  const from = currentOption.from !== '' ? currentOption.from : filter.min;
  const to = currentOption.to !== '' ? currentOption.to : filter.max;

  return `${from}-${to}`;
};

const getFixedFilterParams = (
  filter: FiltersModel,
  isClear?: boolean
): string => {
  switch (filter.type) {
    case FilterTypeEnum.range:
      return getFixedFilterRangeParam(filter as FilterRangeModel, isClear);

    case FilterTypeEnum.toggle:
      return getToggleParam(filter, isClear);

    case FilterTypeEnum.checkbox:
    default:
      return getFixedFilterOptionsParam(filter, isClear);
  }
};

const getMainFilterParams = (
  filter: FiltersModel,
  currentOption?: FiltersOption,
  isClear?: boolean
): string => {
  switch (filter.type) {
    case FilterTypeEnum.range:
      return getMainFilterRangeParam(
        filter as FilterRangeModel,
        currentOption as FilterRangeOptionModel
      );

    case FilterTypeEnum.toggle:
      return getToggleParam(filter, isClear);

    case FilterTypeEnum.checkbox:
    default:
      return getMainFilterOptionsParams(
        filter,
        currentOption as FilterOptionModel
      );
  }
};

/**
 * Forming a filter event object for the datalayer
 * @params (string) eventName
 * @params (object) filter - FiltersModel. The filter on which the event occurred
 * @params (boolean) isClear - true if you cleaned the filter, otherwise false
 * @return (object) GTMEventFilterModel
 *
 * */
export const getFilterEventData = (
  eventName: string,
  filter: FiltersModel,
  isClear?: boolean
): GTMEventFilterModel => {
  return {
    event: eventName,
    event_type: FilterEventTypes.searchFilterEvents,
    event_category: filter.slug,
    event_param: getFixedFilterParams(filter, isClear),
  };
};

export const getMainFilterEventData = (
  eventName: string,
  filter: FiltersModel,
  currentOption?: FiltersOption,
  isClear?: boolean
): GTMEventFilterModel => {
  return {
    event: eventName,
    event_type: FilterEventTypes.searchFilterEvents,
    event_category: filter.slug,
    event_param: getMainFilterParams(filter, currentOption, isClear),
  };
};

export const getFilterSimpleEventData = (
  eventName: string,
  eventCategory: string,
  eventParam: string
): GTMEventFilterModel => {
  return {
    event: eventName,
    event_type: FilterEventTypes.searchFilterEvents,
    event_category: eventCategory,
    event_param: eventParam,
  };
};

/**
 * Forming a filter event object for the datalayer
 * @params (string) eventName
 * @params (string) eventCategory
 * @params (object) filterOption - FilterOptionModel. The filter option on which the event occurred
 * @return (object) GTMEventFilterModel
 *
 * */
export const getTabFilterEventData = (
  eventName: string,
  eventCategory: string,
  eventParam: string
): GTMEventFilterModel => {
  return {
    event: eventName,
    event_type: FilterEventTypes.searchFilterEvents,
    event_category: eventCategory,
    event_param: eventParam,
  };
};

/**
 * Formation of a filtering event object for a data layer for each filter
 * @params (string) eventName
 * @params (array) filters - FiltersModel[]. All filters
 * @params (string) prevQuery - String with query-parameters before filtering
 *
 * */
export const getFiltersEventData = (
  eventName: string,
  filters: FiltersModel[],
  prevQuery: string
): void => {
  filters.forEach((filter): void => {
    switch (filter.type) {
      case FilterTypeEnum.checkbox:
      case FilterTypeEnum.tab:
      case FilterTypeEnum.toggle:
        if (
          !compareFilterQuery(filter, prevQuery) &&
          getFilterSelectedOptionsCount(filter.options) > 0
        ) {
          pushDataLayer(getFilterEventData(eventName, filter, false));
        }
        break;

      case FilterTypeEnum.range:
        if (
          !compareFilterQuery(filter, prevQuery) &&
          checkedFilterRange(filter.options as FilterRangeOptionModel)
        ) {
          pushDataLayer(getFilterEventData(eventName, filter, false));
        }
        break;

      default:
        break;
    }
  });
};

const sortFilterQueryValues = (values: string[]): string =>
  values.sort().join('');

const getFilterQueryValues = (
  queryValue: string,
  filterSlug: string
): string[] => {
  const filterQuerySeparator = `${filterSlug}=`;
  const queries = decodeURIComponent(queryValue).replace('?', '').split('&');
  const filterValues = (
    queries.find((query) => query.includes(filterQuerySeparator)) || ''
  ).split(filterQuerySeparator);

  if (filterValues && filterValues.length === 2) {
    return filterValues[1].split(FILTER_QUERY_VALUE_SEPARATOR);
  }

  return [];
};

/**
 * Comparison of filter options when opening and closing filters
 * @params (object) filter - FiltersModel. The filter on which the event occurred
 * @params (string) prevQuery - String with query-parameters before filtering
 * @return (boolean) - True if the options haven't changed, otherwise false
 *
 * */
export const compareFilterQuery = (
  filter: FiltersModel,
  prevQuery: string
): boolean => {
  switch (filter.type) {
    case FilterTypeEnum.checkbox: {
      const checkedOptions = filter.options
        .filter((option) => option.checked)
        .map((option) => option.slug);

      return (
        sortFilterQueryValues(getFilterQueryValues(prevQuery, filter.slug)) ===
        sortFilterQueryValues(checkedOptions)
      );
    }

    case FilterTypeEnum.range: {
      const fromPrev = getFilterQueryValues(
        prevQuery,
        `${filter.slug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`
      ).join('');
      const toPrev = getFilterQueryValues(
        prevQuery,
        `${filter.slug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`
      ).join('');
      const fromNext = (filter.options as FilterRangeOptionModel)[
        FilterRangePrefixEnum.from
      ];
      const toNext = (filter.options as FilterRangeOptionModel)[
        FilterRangePrefixEnum.to
      ];

      return fromPrev === fromNext && toPrev === toNext;
    }

    default: {
      return false;
    }
  }
};
