import React, { ReactElement } from 'react';
import {
  CellCourseStyled,
  CellCourseTitleLinkStyled,
  CellCourseTitleStyled,
} from './cellCourse.styled';
import { CellCourseContainerProps } from './cellCourse.model';
import { useRouter } from 'next/router';
import { getCoursePageHref, isCourseHasNeededStatus } from '../../../course';
import { useTranslations } from '../../../translations';
import { courseStatusPlug } from './cellCourse.meta';

export const CellCourseContainer = ({
  course,
  urlParams = {},
  baseDomain,
  analyticsFunction,
  showInactivePlug = true,
  showArchivedPlug = false,
}: CellCourseContainerProps): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();

  const stopPropagation = (
    event: React.MouseEvent<Element, MouseEvent>
  ): void => {
    event.stopPropagation();

    if (analyticsFunction) analyticsFunction(courseHref);
  };

  const courseHref = getCoursePageHref({
    course,
    router,
    urlParams,
    baseDomain,
  });

  const hasLink = isCourseHasNeededStatus(course.status) && courseHref;

  const courseTitle = hasLink ? (
    <CellCourseTitleLinkStyled
      onClick={stopPropagation}
      href={courseHref}
      target="_blank"
    >
      {course.title}
    </CellCourseTitleLinkStyled>
  ) : (
    <CellCourseTitleStyled>{course.title}</CellCourseTitleStyled>
  );

  return (
    <CellCourseStyled>
      {courseTitle}
      {courseStatusPlug({
        status: course.status,
        showInactivePlug,
        showArchivedPlug,
        translations,
      })}
    </CellCourseStyled>
  );
};
