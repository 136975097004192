export enum testPath {
  v2 = '/v2',
  skilltest = '/skilltest',
  result = '/result',
  test = '/test',
}

export enum testSubDomains {
  test = 'test',
  hardskills = 'hardskills',
  softskills = 'softskills',
  anxietytest = 'anxietytest',
}
