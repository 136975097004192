import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconAttentionComponent = ({
  width = 32,
  height = 32,
  fillColor = '#29004D',
  strokeColor = '#FFF',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.0001" cy="15.9993" r="13.3333" fill={fillColor} />
      <path
        d="M16 9.33398L16 16.0007"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <circle cx="16.0001" cy="21.3333" r="1.33333" fill={strokeColor} />
    </svg>
  );
};
