import {
  DepartmentsByCompanyClient,
  DepartmentsClient,
} from './departments.client';
import { Department } from './departments.model';

export async function departmentsClientProvider(
  requestHost?: string
): Promise<Department[]> {
  const response = await new DepartmentsClient(requestHost).get();

  return response as unknown as Promise<Department[]>;
}

export async function departmentsByCompanyClientProvider(
  companyId: string,
  requestHost?: string
): Promise<Department[]> {
  const response = await new DepartmentsByCompanyClient(
    companyId,
    requestHost
  ).get();

  return response as unknown as Promise<Department[]>;
}
