import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import {
  PopupContentStyledProps,
  PopupScrollWrapperStyledProps,
} from './sidePopup.model';
import { breakpoint, customizeScrollBar } from '@lerna-core';
import {
  POPUP_ANIMATION_DURATION,
  FOOTER_MAX_HEIGHT,
} from './sidePopup.constants';

const slideIn = keyframes`
    from {
      transform: translateX(100%);
    }
    to {
     transform: translateX(0);
    }
  `;
const slideOut = keyframes`
    from {
      transform: translateX(0);
    }
    to {
     transform: translateX(110%);
    }
  `;
export const popupSlideInAnimation = css`
  animation-name: ${slideIn};
  animation-duration: ${POPUP_ANIMATION_DURATION / 1000}s;
  animation-timing-function: ease;
`;
export const popupSlideOutAnimation = css`
  animation-name: ${slideOut};
  animation-duration: ${POPUP_ANIMATION_DURATION / 1000}s;
  animation-timing-function: ease;
`;

export const SidePopupStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_CONTENT};
  display: flex;
  justify-content: flex-end;
`;

export const PopupOverlayStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.48)};

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `
  )}
`;

export const PopupContentStyled = styled.div<PopupContentStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  width: ${({ contentStyles }): string =>
      contentStyles?.width ? `${contentStyles.width}px;` : '400px;'}
    ${({ isOpen, withAnimation }): ReturnType<typeof css> | string =>
      isOpen && withAnimation ? popupSlideInAnimation : ''}
    ${({ isOpen, withAnimation }): ReturnType<typeof css> | string =>
      !isOpen && withAnimation ? popupSlideOutAnimation : ''}
    ${breakpoint(
      'mediumDown',
      css`
        width: 100%;
      `
    )};
`;

export const PopupScrollWrapperStyled = styled.div<PopupScrollWrapperStyledProps>`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 20px
    ${({ hasFooter, footerHeight }): string =>
      hasFooter
        ? `${!!footerHeight ? footerHeight + 16 : FOOTER_MAX_HEIGHT + 16}px`
        : '16px'}
    20px;

  ${customizeScrollBar(16, 20)}

  ${({ hasFooter, footerHeight }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 0 20px
          ${hasFooter
            ? `${!!footerHeight ? footerHeight + 85 : FOOTER_MAX_HEIGHT + 85}px`
            : '20px'};
      `
    )}
`;

export const PopupFooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_HINTS};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  max-height: ${FOOTER_MAX_HEIGHT}px;
  overflow-y: auto;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
  ${customizeScrollBar(20, 20)}

  ${breakpoint(
    'mediumDown',
    css`
      z-index: ${({ theme }): number => theme.ZINDEX.TOP_PAGE_HINTS - 1};
    `
  )}
`;
