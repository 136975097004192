import styled from 'styled-components';
import { ResetItemStyled } from '../resetItem';

export const ResetShowMoreItemStyled = styled(ResetItemStyled)`
  background-color: #333333;
  padding: 4px;

  &:hover {
    background-color: ${({ theme }): string => theme.COLOR.BLACK};
  }
`;
