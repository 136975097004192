import React, {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { IconEyeComponent, IconEyeCrossedComponent } from '../../../../icons';
import { IconButtonStyled, InputContainerStyled } from '../input.styled';
import { PasswordInputProps, Type } from './passwordInput.model';
import { PasswordInputStyled } from './passwordInput.styled';
import { FloatingPlaceholderStyled } from '../../../floatingPlaceholder';

const DEFAULT_PLACEHOLDER = '********';
const NO_TAB_INDEX = -1;

export const PasswordInput = ({
  name,
  value,
  placeholder = DEFAULT_PLACEHOLDER,
  floatingPlaceholder,
  hasError,
  ...props
}: PasswordInputProps): ReactElement => {
  const ref = useRef<HTMLInputElement>();
  const [displayState, setDisplayState] = useState<boolean>(false);
  const [typeState, setTypeState] = useState<Type.text | Type.password>(
    Type.password
  );

  const togglePasswordDisplay = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setDisplayState(!displayState);
      ref.current?.focus();
    },
    [displayState]
  );

  const handlePreventDefault = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
    },
    []
  );

  useEffect(() => {
    setTypeState(displayState ? Type.text : Type.password);
  }, [displayState]);

  return (
    <InputContainerStyled>
      <PasswordInputStyled
        ref={ref as RefObject<HTMLInputElement>}
        type={typeState}
        name={name}
        value={value}
        {...props}
        onCopy={handlePreventDefault}
        spellCheck={false}
        placeholder={placeholder}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
      />

      <IconButtonStyled
        type="button"
        onClick={togglePasswordDisplay}
        tabIndex={NO_TAB_INDEX}
        transparent
      >
        {displayState ? <IconEyeComponent /> : <IconEyeCrossedComponent />}
      </IconButtonStyled>

      <>
        {floatingPlaceholder ? (
          <FloatingPlaceholderStyled
            hasError={hasError}
            htmlFor={name}
            children={placeholder}
          />
        ) : null}
      </>
    </InputContainerStyled>
  );
};
