import React, { ReactElement } from 'react';

import { ImageResourceModel } from '../../../models';
import { publicConfig } from '../../../services';

type ImageIcoContainerProps = {
  imageResource: ImageResourceModel;
  mediaDomain?: string;
  proxyOrigin?: string;
  width?: number;
  height?: number;
  alt?: string;
  title?: string;
};

const getIcoSrc = (
  imageResource: ImageResourceModel,
  mediaDomain = publicConfig?.MEDIA_DOMAIN,
  proxyOrigin?: string
): string => {
  const origin = proxyOrigin
    ? proxyOrigin
    : `${publicConfig?.BASE_PROTOCOL}//${imageResource.server}.${mediaDomain}`;

  return `${origin}/ico/${imageResource.entity}${imageResource.path}/${imageResource.filename}`;
};

export const ImageIcoContainer = ({
  imageResource,
  mediaDomain,
  proxyOrigin,
  width,
  height,
  alt,
  title,
}: ImageIcoContainerProps): ReactElement => {
  return (
    <img
      src={getIcoSrc(imageResource, mediaDomain, proxyOrigin)}
      width={width}
      height={height}
      alt={alt}
      title={title}
    />
  );
};
