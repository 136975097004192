import { PageStatusCodes, publicConfig, setAcceptLanguage } from '@lerna-core';
import { setSecureHeadersProps } from './auth.service';
import { ErrorPageProps, ServerSideResponseWithError } from '@models';
import { coursesPageClientProvider } from '@features/courses';

export const getErrorPageData = async (
  props: ServerSideResponseWithError,
  statusCode: PageStatusCodes
): Promise<ErrorPageProps> => {
  try {
    const user = props.store.getState().user.user;
    const locale = props.locale || publicConfig.DEFAULT_LOCALE;

    const coursesData = await coursesPageClientProvider(
      { lang: locale },
      {
        ...setAcceptLanguage(locale),
        ...setSecureHeadersProps(),
      },
      user
    );

    return { coursesData, statusCode };
  } catch (e) {
    return { statusCode: PageStatusCodes.serverError };
  }
};
