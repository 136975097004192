import { updateUserProvider } from './updateUser.clientProvider';
import { UpdateUserParams } from './updateUser.model';

class UpdateUserService {
  private static instance: UpdateUserService;

  public static getInstance(): UpdateUserService {
    if (!UpdateUserService.instance) {
      UpdateUserService.instance = new UpdateUserService();
    }

    return UpdateUserService.instance;
  }

  public updateUser(params: UpdateUserParams): Promise<null> {
    return updateUserProvider(params);
  }
}

export const updateUserService = UpdateUserService.getInstance();
