import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '@lerna-core';
import { advantagesBreakpoint } from '../../coursesAdvantages.styled';

export const BannerCoursesHeadStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const VendorLogoStyled = styled.div`
  display: flex;

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `
  )}
`;

export const BannerCoursesWrapStyled = styled.div``;

export const BannerCoursesTitleStyled = styled.div`
  font-weight: 600;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
  ${lineClamping(1)}

  ${advantagesBreakpoint} {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    ${lineClamping(2)}
    white-space: unset;
  }
`;

export const BannerCoursesButtonsStyled = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  & > * {
    text-align: center;
    min-height: 54px;
    ${lineClamping(1)}

    &:first-child {
      flex: 1 1 auto;
    }
    &:not(:last-child) {
      margin-right: 12px;
    }
    svg:last-child:first-child {
      margin-right: 4px;
    }
  }

  ${breakpoint(
    'xlargeDown',
    css`
      & > * {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 12px;
    `
  )}
`;
