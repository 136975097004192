import styled from 'styled-components';
import { FilterIconStyledProps } from './filterIcon.model';

export const FilterIconStyled = styled.div<FilterIconStyledProps>`
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }): string => background || 'transparent'};
`;
