import { RefObject, useCallback, useEffect, useRef } from 'react';

export const useMutationObserver = (
  target: RefObject<Element>,
  callback: MutationCallback,
  options: MutationObserverInit = {}
): void => {
  const observer = useRef<MutationObserver | null>(null);

  const stop = useCallback(() => {
    if (!observer.current) return;

    observer.current.disconnect();
    observer.current = null;
  }, []);

  useEffect(() => {
    if (target?.current) {
      observer.current = new MutationObserver(callback);
      observer.current?.observe(target.current, options);
    }

    return stop;
  }, [callback, stop, options, target]);
};
