import { filterRouterService, FiltersModel, FilterTypeEnum } from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';
import { NextRouter } from 'next/router';

export const getNewQuery = (
  router: NextRouter,
  filter: FiltersModel,
  optionSlug: string
): ParsedUrlQuery => {
  let newQuery;

  if (filter.type === FilterTypeEnum.checkbox) {
    newQuery = filterRouterService.clearFilterOption(
      filter.slug,
      optionSlug,
      router
    );
  }
  if (filter.type === FilterTypeEnum.range) {
    newQuery = filterRouterService.clearRangeFilter(filter, router);
  }
  if (
    filter.type === FilterTypeEnum.toggle ||
    filter.type === FilterTypeEnum.tab
  ) {
    newQuery = filterRouterService.clearToggleFilter(filter, router);
  }

  return newQuery || router.query;
};
