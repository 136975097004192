import {
  FooterImagesContainerStyled,
  FooterImageStyled,
} from './footerImages.styled';
import React, { ReactElement, useMemo } from 'react';
import { FooterImagesContainerProps } from './footerImages.model';
import { ImageContainer } from '../../image';

const COLUMNS_LIMIT = 6;
const THRESHOLD = {
  FIRST: COLUMNS_LIMIT,
  SECOND: 12,
};

export const FooterImagesContainer = ({
  images,
}: FooterImagesContainerProps): ReactElement | null => {
  const imagesCount = useMemo(() => images?.length || 0, [images]);

  const divider = useMemo(() => {
    if (imagesCount <= THRESHOLD.FIRST) {
      return 1;
    } else if (imagesCount <= THRESHOLD.SECOND) {
      return 2;
    } else {
      return 3;
    }
  }, [imagesCount]);

  const columns = useMemo(() => {
    const result = Math.ceil(imagesCount / divider);

    return result > COLUMNS_LIMIT ? COLUMNS_LIMIT : result;
  }, [divider, imagesCount]);

  if (!images?.length) {
    return null;
  }

  return (
    <FooterImagesContainerStyled maxColumns={columns}>
      {images.map((imageFooter) => (
        <FooterImageStyled key={imageFooter.slug}>
          <ImageContainer
            imageResource={imageFooter.image.imageResource}
            preset={'footerImage'}
            alt={imageFooter.title}
            title={imageFooter.title}
          />
        </FooterImageStyled>
      ))}
    </FooterImagesContainerStyled>
  );
};
