import { ReactElement } from 'react';
import { isRuRegion } from './formAgreement.helper';
import {
  FormAgreementTreeContainer,
  FormAgreementTreeModel,
} from '../formAgreementTree';
import { FormAgreementModel } from './formAgreement.model';
import { FormCheckbox } from '../formInputs';
import {
  FormAgreementRowStyled,
  FormAgreementStyled,
  FormAgreementTextStyled,
} from './formAgreement.styled';
import { useTranslations } from '../../translations';

export const FormAgreementContainer = (
  props: FormAgreementTreeModel | FormAgreementModel
): ReactElement => {
  const translations = useTranslations();

  if (isRuRegion()) {
    return (
      <FormAgreementTreeContainer {...(props as FormAgreementTreeModel)} />
    );
  }

  const {
    values,
    isSubmitting,
    handleChange,
    withOferta,
    fieldsHtml,
    touched,
    errors,
  } = props as FormAgreementModel;
  const hasError = !!(errors.advConsent && touched.advConsent);

  return (
    <FormAgreementStyled>
      <FormAgreementRowStyled>
        <FormCheckbox
          hasError={hasError}
          checked={values.advConsent}
          name="advConsent"
          disabled={isSubmitting}
          onChange={handleChange}
          children={
            <FormAgreementTextStyled
              hasError={hasError}
              dangerouslySetInnerHTML={{
                __html:
                  fieldsHtml?.advConsent ||
                  translations.form_agreement_adv_consent,
              }}
            />
          }
        />
      </FormAgreementRowStyled>
      {withOferta && (
        <FormAgreementRowStyled>
          <FormAgreementTextStyled
            hasError={false}
            dangerouslySetInnerHTML={{
              __html: fieldsHtml?.oferta || translations.form_agreement_oferta,
            }}
          />
        </FormAgreementRowStyled>
      )}
    </FormAgreementStyled>
  );
};
