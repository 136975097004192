import { PRESETS } from '@lerna-core';
import { DiscountLogoPropsModel } from './discountProgramLogo.model';

const { w: desktopLogoWidth, h: desktopLogoHeight } =
  PRESETS.discountProgramLogo.opts;
const { w: mobileLogoWidth, h: mobileLogoHeight } =
  PRESETS.discountProgramLogoMobile.opts;

export const getDiscountLogoProps = (
  isMobile: boolean
): DiscountLogoPropsModel => {
  switch (true) {
    case isMobile:
      return {
        width: mobileLogoWidth,
        height: mobileLogoHeight,
        preset: 'discountProgramLogoMobile',
      };
    default:
      return {
        width: desktopLogoWidth,
        height: desktopLogoHeight,
        preset: 'discountProgramLogo',
      };
  }
};
