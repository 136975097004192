import styled from 'styled-components';
import { SpinnerStyledProps } from './spinner.model';

export const SpinnerStyled = styled.div<SpinnerStyledProps>`
  width: ${({ width }): string => `${width}px`};
  height: ${({ height }): string => `${height}px`};

  svg {
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
