import { TextControlProps } from './textControl.model';
import React, { KeyboardEventHandler, ReactElement } from 'react';
import { Label } from '../../../label';
import { Input } from '../../../formInputs';
import { FormControl, FormControlError } from '../../../formControl';

export const TextControlComponent = ({
  type,
  label,
  name,
  disabled = false,
  touched,
  readOnly = false,
  errorMessage,
  statusMessage,
  value,
  onChange,
  onBlur,
  placeholder,
  showResetButton = true,
  setFieldValue,
  min,
  max,
  floatingPlaceholder = true,
  onKeyPress,
  margin,
  isInteger = false,
  onReset,
  hintButton,
  hasError,
  color,
}: TextControlProps): ReactElement => {
  const withError =
    Boolean(errorMessage || statusMessage || hasError) && touched;

  const disablePoint: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === '.' || e.key === ',') {
      e.preventDefault();
    }
    if (onKeyPress && typeof onKeyPress === 'function') {
      onKeyPress(e);
    }
  };

  return (
    <FormControl margin={margin}>
      {label && !floatingPlaceholder && <Label htmlFor={name}>{label}</Label>}
      <Input
        id={name}
        name={name}
        type={type}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        color={color}
        disabled={disabled}
        placeholder={floatingPlaceholder ? label : placeholder}
        floatingPlaceholder={floatingPlaceholder}
        showResetButton={showResetButton}
        setFieldValue={setFieldValue}
        min={min}
        max={max}
        onKeyPress={isInteger ? disablePoint : onKeyPress}
        hasError={withError}
        onReset={onReset}
        hintButton={hintButton}
      />
      {errorMessage && touched ? (
        <FormControlError as="div">{errorMessage}</FormControlError>
      ) : null}

      {statusMessage && touched ? (
        <FormControlError as="div">{statusMessage}</FormControlError>
      ) : null}
    </FormControl>
  );
};
