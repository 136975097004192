import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DropdownContainer } from '../../dropdown';
import { SelectOptionType } from '../../form';
import {
  IconRadiobuttonCheckedComponent,
  IconRadiobuttonComponent,
} from '../../icons';
import {
  DropdownSelectOptionStyled,
  DropdownSelectStyled,
} from './dropdownSelect.styled';
import { SelectButtonContainer } from './selectButton';
import { SelectItemContainer } from './selectItem';
import { useToggle } from '../../../hooks';
import { DropdownPosition } from '../dropdown.model';
import { Nullable } from '../../../models';

export interface DropdownSelectContainerProps {
  options: SelectOptionType[];
  value: SelectOptionType;
  onSelect: (option: SelectOptionType) => void;
}

export const DropdownSelectContainer = ({
  options,
  value,
  onSelect,
}: DropdownSelectContainerProps): ReactElement => {
  const [selected, setSelected] = useState<SelectOptionType>(value);
  const [open, toggleOpen] = useToggle(false);
  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const handleSelect = useCallback((option: SelectOptionType) => {
    setSelected(option);
    toggleOpen();
    onSelect(option);
  }, []);

  return (
    <DropdownSelectStyled ref={dropdownRef}>
      <SelectButtonContainer onClick={toggleOpen} active={open}>
        {selected.value}
      </SelectButtonContainer>

      {open && (
        <DropdownContainer
          ref={dropdownRef}
          handleOutsideClick={toggleOpen}
          position={DropdownPosition.right}
          top={8}
        >
          {options.map((option) => {
            const isActive = selected.key === option.key;

            return (
              <SelectItemContainer
                key={option.key}
                value={option}
                onSelect={handleSelect}
                active={isActive}
              >
                <DropdownSelectOptionStyled>
                  {isActive ? (
                    <IconRadiobuttonCheckedComponent />
                  ) : (
                    <IconRadiobuttonComponent />
                  )}
                </DropdownSelectOptionStyled>

                {option.value}
              </SelectItemContainer>
            );
          })}
        </DropdownContainer>
      )}
    </DropdownSelectStyled>
  );
};
