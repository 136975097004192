import styled from 'styled-components';
import { fontSettings } from '../../../../styles';

import {
  CourseItemContainerStyled,
  CourseItemLogoStyled,
  CourseItemSubTitleStyled,
} from '../courseItem.styled';

export const CourseItemMobileContainerStyled = styled(
  CourseItemContainerStyled
)`
  display: block;
`;

export const CourseItemMobileSubTitleStyled = styled(CourseItemSubTitleStyled)`
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
`;

export const CourseItemMobileContentStyled = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CourseItemMobileInfoStyled = styled.div`
  margin-right: 16px;
`;

export const CourseItemMobileLogoStyled = styled(CourseItemLogoStyled)`
  flex: 0 0 48px;
  border-radius: 5px;
  overflow: hidden;
`;

export const CourseItemMobileButtonStyled = styled.div`
  margin-top: 20px;
`;
