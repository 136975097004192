import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconHitComponent = ({
  width = 12,
  height = 12,
  fillColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.04922 1C4.92324 1 4.80242 1.05017 4.71334 1.13947C4.62426 1.22878 4.57422 1.3499 4.57422 1.47619C4.57422 3.54476 2.19922 4.80952 2.19922 7.66667C2.19922 10.0476 3.37579 11 5.99922 11C4.94994 11 4.09922 9.67095 4.09922 8.61905C4.09922 7.66667 4.57422 6.71429 4.57422 6.71429C4.57422 6.71429 5.04922 7.66667 5.52422 7.66667C6.47422 7.66667 6.47422 4.80952 6.47422 4.80952C6.47422 4.80952 7.89922 5.7619 7.89922 8.61905C7.89922 9.67095 7.04849 11 5.99922 11C8.62264 11 9.79922 10.0476 9.79922 7.66667C9.79922 4.00412 5.66797 1.30878 5.34609 1.10417C5.26181 1.03665 5.15711 0.999916 5.04922 1Z"
      fill={fillColor}
    />
  </svg>
);
