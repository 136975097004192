import React, { ReactElement, useCallback } from 'react';

import { FormCheckbox } from '../../checkbox';
import { SelectOptionType } from '../select.model';
import {
  SelectOptionContainerStyled,
  SelectStyledOption,
} from '../select.styled';

type SelectOptionProps = {
  active: boolean;
  onClick: (option: SelectOptionType) => void;
  multi?: boolean;
  option: SelectOptionType;
  activeHovered: boolean;
};

export const SelectOption = ({
  onClick,
  active,
  multi,
  option,
  activeHovered = true,
}: SelectOptionProps): ReactElement => {
  const handleClick = useCallback((): void => {
    (!active || multi) && onClick(option);
  }, [option, onClick]);

  return (
    <SelectOptionContainerStyled onClick={handleClick}>
      <SelectStyledOption
        multi={multi}
        isActive={active}
        activeHovered={activeHovered}
      >
        {option.value}
        {multi && <FormCheckbox checked={active} readOnly />}
      </SelectStyledOption>
    </SelectOptionContainerStyled>
  );
};
