import React, { ReactElement } from 'react';

import { HorizontalLineStyled } from '../../horizontalLine';
import { PaperComponentStyled } from '../../paper/paper.styled';
import { InfoCompanyContainer, InfoPersonalContainer } from '../../user';
import { CustomerInfoContainerProps } from '../customer.model';

export const CustomerInfoContainer = ({
  allowEditCareer = false,
  allowEdit = false,
  allowSeeCompanyLink = false,
  showRole = false,
  onEdit,
  onEditCareer,
  isMobile,
  career,
  personal,
  bonusPoints,
  pointsHref,
  tariffHref,
  mediaDomain,
  ImpersonateButton,
  AnonymousEmail,
  invitedAt,
}: CustomerInfoContainerProps): ReactElement => (
  <PaperComponentStyled>
    <InfoPersonalContainer
      personal={personal}
      career={career}
      handleClick={onEdit}
      allowEdit={allowEdit}
      isMobile={isMobile}
      points={bonusPoints}
      pointsHref={pointsHref}
      tariffHref={tariffHref}
      mediaDomain={mediaDomain}
      invitedAt={invitedAt}
      ImpersonateButton={ImpersonateButton}
      AnonymousEmail={AnonymousEmail}
    />
    {!!career.companyId && (
      <>
        <HorizontalLineStyled />
        <InfoCompanyContainer
          career={career}
          allowEdit={allowEditCareer}
          handleClick={onEditCareer}
          showRole={showRole}
          allowSeeCompanyLink={allowSeeCompanyLink}
          invitedAt={invitedAt}
        />
      </>
    )}
  </PaperComponentStyled>
);
