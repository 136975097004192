import { ReactElement } from 'react';
import * as S from './registerWelcome.styled';
import { ButtonColor, ButtonContainer } from '../../button';
import { useTranslations } from '../../translations';
import { PopupContainer } from '../../popups';
import { RegisterWelcomeModel } from './registerWelcome.model';
import { useRegisterPopupContext } from '../registerPopup';
import { useTheme } from '../../../hooks';
import { modalPaddings } from './registerWelcome.meta';
import {
  getSimpleEvent,
  pushDataLayer,
  stringService,
} from '../../../services';
import { getBonusPointsLabel, useUser } from '../../user';
import { mapPriceValue } from '../../../adapters';
import { RegisterEvents, RegisterPositions } from '../register.analytics';
import { CommonEventTypes } from '../../../analytics';

export const RegisterWelcomeContainer = ({
  canClose,
}: RegisterWelcomeModel): ReactElement => {
  const translations = useTranslations();
  const { user } = useUser();
  const userBonuses = user?.bonuses;
  const theme = useTheme();
  const {
    currencies,
    isWelcomeOpened,
    handleCloseWelcome,
    handleOpenRegister,
    handleWarningVisibility,
  } = useRegisterPopupContext();

  const handleClosePopup = canClose
    ? () => {
        pushDataLayer(
          getSimpleEvent(
            RegisterEvents.welcomeClickClose,
            CommonEventTypes.registration
          )
        );
        handleCloseWelcome();
        handleWarningVisibility(true);
      }
    : undefined;

  const handleClick = () => {
    handleOpenRegister(RegisterPositions.registerWarning);
    handleCloseWelcome();

    pushDataLayer(
      getSimpleEvent(
        RegisterEvents.welcomeClickRegistration,
        CommonEventTypes.registration
      )
    );
  };

  const bonuses = stringService
    .pluralTranslations(userBonuses?.points || 0, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])
    .toLowerCase();

  const bonusesCurrency = userBonuses
    ? `${getBonusPointsLabel(userBonuses.bonusPriceConversion.points, [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]).toLowerCase()} = ${mapPriceValue(
        userBonuses.bonusPriceConversion.money,
        currencies
      )}`
    : null;

  return (
    <PopupContainer
      styleSettings={modalPaddings}
      hasClose={canClose}
      canClose={canClose}
      closeOutside={canClose}
      isOpened={isWelcomeOpened}
      handleClose={handleClosePopup}
      zIndex={theme.ZINDEX.TOP_PAGE_HINTS}
    >
      <S.RegisterWelcome>
        {userBonuses?.points && (
          <S.RegisterWelcomeTitle>
            {`${translations.register_welcome_title} ${userBonuses.points} ${bonuses}`}
          </S.RegisterWelcomeTitle>
        )}
        {bonusesCurrency && (
          <S.RegisterWelcomeBonusesCurrency>
            {bonusesCurrency}
          </S.RegisterWelcomeBonusesCurrency>
        )}
        <S.RegisterWelcomeBonusesSubtitle>
          {translations.register_welcome_subtitle}
        </S.RegisterWelcomeBonusesSubtitle>
        <S.RegisterWelcomeVideo muted autoPlay loop src="/video/pig.mp4" />
        <ButtonContainer
          fullWidth
          color={ButtonColor.purple}
          borderRadius={100}
          title={translations.register_form_submit_button}
          onClick={handleClick}
        />
      </S.RegisterWelcome>
    </PopupContainer>
  );
};
