import styled from 'styled-components';
import { DiscountProgramLogoStyledProps } from './discountProgramLogo.model';

export const DiscountProgramLogoStyled = styled.div<DiscountProgramLogoStyledProps>`
  display: flex;
  align-items: center;
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;

  svg,
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
`;
