export class StringService {
  private static instance: StringService;
  private static readonly numberRegExp = /^\d*(\.\d*)?/;
  private static readonly defaultStringDelimiter = ';';

  /**
   * Returning instance of singletone service
   */
  public static getInstance(): StringService {
    if (!StringService.instance) {
      StringService.instance = new StringService();
    }

    return StringService.instance;
  }

  /**
   * If param contains an number in string format, returns that. Otherwise returns empty string
   * @param value{string} Analysing string
   * @return {string} string contains number or empty string
   */
  public formatNumberValue = (value: string): string => {
    const res = StringService.getNumberSubstring(value);

    if (res) {
      return res[0];
    }

    return '';
  };

  /**
   * If param contains an number in string format, returns that integer part. Otherwise returns empty string
   * @param value{string} Analysing string
   * @return {string} string contains integer number or empty string
   */
  public formatIntNumberValue = (value: string): string => {
    const res = StringService.getNumberSubstring(value);

    if (res) {
      return res[0].split('.')[0];
    }

    return '';
  };

  /**
   * Capitalizing first letter
   * @param str{string} Original string
   * @return {string} String with capitalizing first letter
   */
  public capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  /**
   * Uncapitalizing first letter
   * @param str{string} Original string
   * @return {string} String with uncapitalized first letter
   */
  public uncapitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  /**
   * get translation from translations array by count value
   * @param count{number} number of units for that translation
   * @param translations{string[]} translations for that units
   * @return {string} value depending on count value
   */
  public pluralTranslations = (
    count: number,
    translations: string[]
  ): string => {
    const positiveCount = Math.abs(count);
    let translationIndex;

    if (positiveCount % 10 === 1 && positiveCount % 100 !== 11) {
      translationIndex = 0;
    } else if (
      positiveCount % 10 >= 2 &&
      positiveCount % 10 <= 4 &&
      (positiveCount % 100 < 10 || positiveCount % 100 >= 20)
    ) {
      translationIndex = 1;
    } else {
      translationIndex = 2;
    }

    return translations[translationIndex];
  };

  /**
   * Removing special space symbols
   * @param str{string} original string
   * @return {string} string without space symbols
   */
  public replaceSpaces = (str: string): string => str.replace(/\s/g, '');

  /**
   * Removing special symbols by regExp
   * @param str{string} original string
   * @param regExp{RegExp} symbols to remove
   * @return {string} string without regExp symbols
   */
  public removeSymbolsByRegExp = (str: string, regExp: RegExp): string =>
    str.replace(regExp, '');

  /**
   * Replace &nbsp
   * @return string
   * @param str
   */
  public replaceNBSP = (str: string): string => str.replace(/\u00a0/g, ' ');

  /**
   * Replace &nbsp
   * @return string
   * @param str
   */
  public addNBSP = (str: string): string => str.replace(/ /g, '\u00a0');

  /**
   * Splits string into array of substring by delimiter
   * @param str{string} - string to be split
   * @param delimiter{string} - optional delimiter for string split
   * @return {string[]} - array of separated substrings
   */
  public splitStringToArray = (
    str: string,
    delimiter: string = StringService.defaultStringDelimiter
  ): string[] => str.split(delimiter);

  /**
   * Number digit division
   * @return {string}
   * @params - number
   * @params - delimiter
   * */
  public numberWithSpaces = (number: number, delimiter = ' '): string =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

  private static getNumberSubstring(value: string): RegExpMatchArray | null {
    return `${value}`.match(StringService.numberRegExp);
  }
}

export const stringService = StringService.getInstance();
