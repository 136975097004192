import { ConsentRequestModel } from './consent.model';
import { ConsentClient } from './consent.client';

export const consentClientProvider = async (
  params: ConsentRequestModel
): Promise<void> => {
  const response = new ConsentClient(params).post();

  return response as unknown as Promise<void>;
};
