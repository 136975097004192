import React, { ReactElement } from 'react';
import { FooterContainer } from '@features/common/footer';
import { useCoursePageContext } from '../course.context';
import { Nullable } from '@lerna-core';

export const CourseFooterContainer = (): Nullable<ReactElement> => {
  const {
    footer: { images = [] },
    translations,
  } = useCoursePageContext();

  return <FooterContainer footerImages={images} translations={translations} />;
};
