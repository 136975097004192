import styled from 'styled-components';

import { fontSettings } from '../../../../../../styles';
import { SectionHeadingStyledProps } from './sectionHeading.model';

export const SectionHeadingStyled = styled.h1<SectionHeadingStyledProps>`
  margin: 0;
  margin-bottom: 32px;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme)}
`;

export const SectionHeadingCountStyled = styled.span`
  margin-left: 16px;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme)}
`;
