import {
  LibrariesApiModel,
  LibrariesApiResponseModel,
  LibrariesModel,
} from './libraries.model';
import { libraryAdapter, LibraryModel } from '../library';
import { VendorModel } from '../vendors';

export const librariesDataAdapter = (
  data: LibrariesApiModel['data'],
  vendors: VendorModel[]
): LibrariesModel['data'] => data.map((item) => libraryAdapter(item, vendors));

export const librariesAdapter = (
  librariesApi: LibrariesApiModel,
  vendors: VendorModel[]
): LibrariesModel => ({
  data: librariesDataAdapter(librariesApi.data, vendors),
});

export const librariesApiResponseAdapter = (
  vendors: VendorModel[],
  { data }: LibrariesApiResponseModel
): LibraryModel[] => librariesDataAdapter(data.data, vendors);
