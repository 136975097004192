import React, { ReactElement, useState } from 'react';

import { InputColor } from '../../../styles';
import { GenericInputWrapStyled, InputSizes } from '../genericInput';
import { TextareaInputStyled } from './textareaInput.styled';
import { FloatingPlaceholderStyled } from '../../form';

type TextareaInputContainerProps = {
  placeholder?: string;
  onChange: (value: string) => void;
  initialValue?: string;
  onBlur: () => void;
  name: string;
  size?: string;
  bordered?: boolean;
  disabled?: boolean;
  color?: InputColor;
  floatingPlaceholder: boolean;
  hasError: boolean;
};

export const TextareaInputContainer = ({
  placeholder = '',
  onChange,
  initialValue = '',
  onBlur,
  name,
  bordered,
  disabled,
  color,
  size = InputSizes.normal,
  floatingPlaceholder,
  hasError,
}: TextareaInputContainerProps): ReactElement => {
  const [value, setValue] = useState(initialValue);

  const changeValue = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value;

    setValue(inputValue);
    onChange(inputValue);
  };

  return (
    <GenericInputWrapStyled size={size}>
      <TextareaInputStyled
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        name={name}
        color={color}
        onChange={changeValue}
        bordered={bordered}
        disabled={disabled}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
      />

      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          htmlFor={name}
          children={placeholder}
        />
      ) : null}
    </GenericInputWrapStyled>
  );
};
