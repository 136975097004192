import { CustomerEditCompanyClient } from './customerEditCompany.client';
import { CustomerModel } from '../customer.model';

export async function customerEditCompanyClientProvider(
  id: string,
  positionUuid: string,
  departmentUuid: string
): Promise<CustomerModel> {
  const response = await new CustomerEditCompanyClient(
    id,
    positionUuid,
    departmentUuid
  ).patch();

  return response as unknown as Promise<CustomerModel>;
}
