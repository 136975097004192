import React, { ReactElement, useContext, useState } from 'react';
import {
  NotificationManagementContextModel,
  NotificationManagementProviderProps,
} from './notificationManagement.model';
import { Nullable } from '../../../models';
import { logger } from '../../../services';
import { CookiesNotification } from '../cookiesNotification.container';

const NotificationManagementContext =
  React.createContext<Nullable<NotificationManagementContextModel>>(null);

export const CookieNotificationContextProvider = ({
  children,
}: NotificationManagementProviderProps): ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const setInvisible = () => {
    setIsVisible(false);
  };

  const setVisible = () => {
    setIsVisible(true);
  };

  const handleOpenPopup = () => {
    setIsPopupOpened(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpened(false);
  };

  const context = {
    handleOpenPopup,
    handleClosePopup,
    isPopupOpened,
    isVisible,
    setInvisible,
    setVisible,
  };

  return (
    <NotificationManagementContext.Provider value={context}>
      {children}
      <CookiesNotification />
    </NotificationManagementContext.Provider>
  );
};

export const useCookieNotificationManagementContext =
  (): NotificationManagementContextModel => {
    const value = useContext(NotificationManagementContext);

    if (!value) {
      logger.error(
        '[ERROR]: CookieNotificationManagement: you cannot use context without a provider'
      );

      return {} as NotificationManagementContextModel;
    }

    return value;
  };
