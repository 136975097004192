import { Nullable, TranslationModel } from '../../../../models';
import { CompanyUuidEnum } from '../../../company';
import { UserAggregateModel } from '../../../user';

export const getMainContactPhone = (
  user: Nullable<UserAggregateModel>,
  translations: TranslationModel
): string => {
  if (user?.career?.companyId === CompanyUuidEnum.wildberries) {
    return '+7 495 308-32-49';
  }

  return translations.company_phone_label;
};
