import { NextRouter } from 'next/router';
import {
  mainPath,
  personalPath,
  projectSubDomains,
  projectTypes,
} from '../../../constants';
import { Nullable, QueryParams, TranslationModel } from '../../../models';
import { AppService, publicConfig, urlService } from '../../../services';
import {
  authorizationService,
  serverAuthorizationService,
} from '../../authorization';
import { IconNames } from '../../menu';
import { UserAggregateModel } from '../user.model';
import { UserMenuStaticLinksTypes } from './userMenu.constants';
import { UserMenuLink, UserMenuLinkModel } from './userMenu.model';

const DISCOUNT_PROGRAM_PAGE_REPLACER = '{{discount}}';

export const getUserMenuTopStaticLinks = (
  translations: TranslationModel,
  router: NextRouter,
  user?: Nullable<UserAggregateModel>,
  withLogin?: boolean
): UserMenuLinkModel[] => {
  const projectSubdomain =
    publicConfig?.APP_TYPE === projectTypes.hr
      ? projectSubDomains.hr
      : projectSubDomains.personal;

  const userLinks: UserMenuLinkModel[] = [
    {
      title: translations?.user_profile,
      link: urlService.getFullUrlToSubDomain(
        projectSubdomain,
        personalPath.user
      ),
      icon: IconNames.user,
      linkType: UserMenuStaticLinksTypes.profile,
    },
    {
      title: translations?.catalog,
      link: urlService.getFullUrlToPage(mainPath.courses, router),
      icon: IconNames.catalog,
      linkType: UserMenuStaticLinksTypes.catalog,
    },
  ];

  const guestLinks: UserMenuLinkModel[] = [];

  if (withLogin) {
    const loginLink = AppService.isClientSide
      ? authorizationService.getLoginLink()
      : serverAuthorizationService.getLoginLink(false, undefined, router);

    guestLinks.push({
      title: translations?.user_login,
      link: loginLink,
      icon: IconNames.login,
      linkType: UserMenuStaticLinksTypes.login,
    });
  }

  if (user) {
    if (user.career?.isListingHidden) {
      return userLinks.filter(
        (userLink) =>
          userLink.link !==
          urlService.getFullUrlToPage(mainPath.courses, router)
      );
    }

    return userLinks;
  }

  return guestLinks;
};

export const getUserMenuBottomStaticLinks = (
  translations: TranslationModel,
  router: NextRouter,
  user?: Nullable<UserAggregateModel>,
  staticLinks?: Nullable<UserMenuLink[]>
): UserMenuLinkModel[] => {
  const logoutLink = AppService.isClientSide
    ? authorizationService.getLogoutLink()
    : serverAuthorizationService.getLogoutLink();
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  const userLinks: UserMenuLinkModel[] = [
    {
      title: translations?.user_logout,
      link: logoutLink,
      icon: IconNames.logout,
      linkType: UserMenuStaticLinksTypes.logout,
    },
  ];
  const guestLinks: UserMenuLinkModel[] = [
    !!discountProgramQuery
      ? {
          title: translations?.catalog,
          link: urlService.getFullUrlToPage(
            `${mainPath.discountProgram.replace(
              DISCOUNT_PROGRAM_PAGE_REPLACER,
              discountProgramQuery
            )}`,
            router,
            [QueryParams.promoCode]
          ),
          icon: IconNames.catalog,
          linkType: UserMenuStaticLinksTypes.catalog,
        }
      : {
          title: translations?.catalog,
          link: urlService.getFullUrlToPage(mainPath.courses, router),
          icon: IconNames.catalog,
          linkType: UserMenuStaticLinksTypes.catalog,
        },
  ];

  if (staticLinks) {
    guestLinks.push(...staticLinks);
  }

  return user ? userLinks : guestLinks;
};

export const getUserMenuLinkPath = (
  link = '',
  parentType?: projectTypes
): string => {
  if (parentType) {
    switch (parentType) {
      case projectTypes.hr:
        return urlService.getFullUrlToSubDomain(projectSubDomains.hr, link);
      case projectTypes.personal:
        return urlService.getFullUrlToSubDomain(
          projectSubDomains.personal,
          link
        );
      default:
        return link;
    }
  }

  return link;
};
