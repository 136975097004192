import {
  CurrencyModel,
  CompensationApiModel,
  CompensationModel,
} from '../models';
import { discountAdapter } from './discount.adapter';

export const compensationAdapter = (
  compensation: CompensationApiModel,
  currencies: CurrencyModel[]
): CompensationModel => ({
  discount: discountAdapter(compensation?.data?.discount, currencies),
  id: compensation?.data.id,
  type: compensation.data.type,
});
