import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import { FilterToggleContainerStyled } from '../../../filterToggle';
import { MobileFilterStyledProps } from './mobileFilter.model';

export const MobileFilterStyled = styled.div<MobileFilterStyledProps>`
  padding: 16px 32px 16px 0;
  position: relative;
  user-select: none;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}

  ${({ isCustomFilter, background }): string =>
    isCustomFilter
      ? `
  padding: 12px 30px 12px 12px;
  background: ${background ? `${background}` : 'transparent'};
  border-radius: 8px;
  margin: 16px 0;
  `
      : 'padding: 16px 32px 16px 0;'}
  
  &:not(:first-child) {
    border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  }
`;

export const MobileToggleFilterStyled = styled.div<MobileFilterStyledProps>`
  ${({ isCustomFilter, theme }): string => `
  ${FilterToggleContainerStyled} {
    font-weight: 500;
    ${fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    ${
      !isCustomFilter
        ? `padding: 16px 0; margin: 0; border-top: none; border-top: 1px solid ${theme.COLOR.BLACK_08};`
        : `${fontSettings(theme.FONT.SIZE_16, theme.FONT)}`
    }
    }
`}
`;

export const MobileFilterArrowStyled = styled.div<MobileFilterStyledProps>`
  position: absolute;
  right: 0;
  width: 16px;
  height: 16px;

  ${({ isCustomFilter }): string =>
    isCustomFilter
      ? 'top: 18px; transform: rotate(-90deg); right: 12px;'
      : 'top: 50%; transform: translateY(-50%) rotate(-90deg);'}
`;
