import React, { ReactElement } from 'react';

import { TransferControlProps } from './transferControl.model';
import { TransferControlStyled } from './transferControl.styled';
import { IconNextComponent } from '../../icons';
import { ButtonColor, ButtonContainer } from '../../button';
import { useTheme } from '../../../hooks';

export const TransferControlContainer = ({
  iconDirection,
  disabled,
  handleClick,
}: TransferControlProps): ReactElement => {
  const theme = useTheme();

  return (
    <TransferControlStyled iconDirection={iconDirection} disabled={disabled}>
      <ButtonContainer
        onClick={handleClick}
        color={!disabled ? ButtonColor.purple : ButtonColor.default}
        disabled={disabled}
        borderRadius={8}
      >
        <IconNextComponent
          width={24}
          height={24}
          strokeColor={
            !disabled ? theme.COLOR.WHITE : theme.COLOR.TEXT_LIGHT_COLOR
          }
        />
      </ButtonContainer>
    </TransferControlStyled>
  );
};
