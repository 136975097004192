import React, { ReactElement } from 'react';

import { PopupSubtitleStyled } from './popupSubtitle.styled';
import { FormPopupSubtitleProps } from './popupSubtitle.model';

export const PopupSubtitleContainer = ({
  text,
}: FormPopupSubtitleProps): ReactElement => {
  return <PopupSubtitleStyled>{text}</PopupSubtitleStyled>;
};
