import React, { ReactElement, useState } from 'react';
import {
  SortOrder,
  TableDoubleSortContainerProps,
} from './tableDoubleSort.model';
import {
  SortIconsStyled,
  SortTitleStyled,
  TableDoubleSortStyled,
} from './tableDoubleSort.styled';
import { IconArrowComponent } from '../../icons';
import { useTheme } from '../../../hooks';

export const TableDoubleSortContainer = ({
  title,
  onChange,
  initialSort,
}: TableDoubleSortContainerProps): ReactElement => {
  const [activeSort, setActiveSort] = useState<SortOrder>(
    initialSort || SortOrder.asc
  );
  const theme = useTheme();

  const handleChange = (sort: SortOrder): void => {
    if (activeSort === sort) return;

    setActiveSort(sort);
    onChange(sort);
  };

  return (
    <TableDoubleSortStyled>
      <SortTitleStyled>{title}</SortTitleStyled>
      <SortIconsStyled>
        <div
          onClick={() => {
            handleChange(SortOrder.asc);
          }}
        >
          <IconArrowComponent
            strokeColor={
              activeSort === SortOrder.asc
                ? theme.COLOR.MEDIUM_PURPLE
                : theme.COLOR.TEXT_LIGHT_COLOR
            }
          />
        </div>
        <div
          onClick={() => {
            handleChange(SortOrder.desc);
          }}
        >
          <IconArrowComponent
            strokeColor={
              activeSort === SortOrder.desc
                ? theme.COLOR.MEDIUM_PURPLE
                : theme.COLOR.TEXT_LIGHT_COLOR
            }
          />
        </div>
      </SortIconsStyled>
    </TableDoubleSortStyled>
  );
};
