import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CustomersItemsAfterChange,
  CustomersTransferProvider,
  UseCustomersTransferReturnType,
  UseCustomersTransferSearchReturnType,
} from './customersTransfer.model';
import { handleCheckedProps } from '../../transfer';
import {
  getCustomerItemsAfterAdd,
  getCustomerItemsAfterRemove,
} from './customersTransfer.service';
import { CustomerTableData, SearchTerms } from './transferTable';
import {
  requestTransferCustomersAllocated,
  requestTransferCustomersUnallocated,
  setTransferCustomersAllocatedFilters,
  setTransferCustomersUnallocatedFilters,
  toggleTransferCustomers,
  toggleTransferCustomersPending,
} from './customersTransfer.actions';
import {
  getCustomersTransferAllocatedFiltersSelector,
  getCustomersTransferSelector,
  getCustomersTransferUnallocatedFiltersSelector,
} from './customersTransfer.selectors';

export const useCustomersTransfer = (
  dispatch: <T>(action: T) => T,
  customersProvider: CustomersTransferProvider,
  id: string,
  subscriptionId: string,
  benefitId: string,
  libraryId: string,
  tariffId: string
): UseCustomersTransferReturnType => {
  const {
    unallocated,
    allocated,
    added: addedCustomers,
    removed: removedCustomers,
  } = useSelector(getCustomersTransferSelector);

  const reloadCustomers = (
    changedCustomers: CustomersItemsAfterChange
  ): void => {
    const requestParams = {
      ...changedCustomers,
      page: 1,
      id,
    };
    dispatch(toggleTransferCustomersPending(true));
    dispatch(
      requestTransferCustomersUnallocated(customersProvider, {
        ...unallocated.filters,
        ...requestParams,
        notRelatedWithSubscriptionId: subscriptionId,
        notRelatedWithBenefitId: benefitId,
        notRelatedWithLibraryId: libraryId,
        notRelatedWithTariffId: tariffId,
      })
    );
    dispatch(
      requestTransferCustomersAllocated(customersProvider, {
        ...allocated.filters,
        ...requestParams,
        relatedWithSubscriptionId: subscriptionId,
        relatedWithBenefitId: benefitId,
        relatedWithLibraryId: libraryId,
        relatedWithTariffId: tariffId,
      })
    );
  };

  const handleCheckedLeftCallback = (props: handleCheckedProps): void => {
    const changedCustomers = getCustomerItemsAfterRemove(
      addedCustomers,
      removedCustomers,
      props.moved as CustomerTableData[]
    );
    dispatch(toggleTransferCustomers(changedCustomers));
    reloadCustomers(changedCustomers);
  };

  const handleCheckedRightCallback = (props: handleCheckedProps): void => {
    const changedCustomers = getCustomerItemsAfterAdd(
      addedCustomers,
      removedCustomers,
      props.moved as CustomerTableData[]
    );
    dispatch(toggleTransferCustomers(changedCustomers));
    reloadCustomers(changedCustomers);
  };

  return { handleCheckedLeftCallback, handleCheckedRightCallback };
};

export const useCustomersTransferSearch = (
  dispatch: <T>(action: T) => T,
  customersProvider: CustomersTransferProvider,
  id: string,
  subscriptionId: string,
  benefitId: string,
  libraryId: string,
  tariffId: string
): UseCustomersTransferSearchReturnType => {
  const {
    unallocated,
    allocated,
    added: addedCustomers,
    removed: removedCustomers,
  } = useSelector(getCustomersTransferSelector);

  const customersAllocatedFilters = useSelector(
    getCustomersTransferAllocatedFiltersSelector
  );
  const customersUnallocatedFilters = useSelector(
    getCustomersTransferUnallocatedFiltersSelector
  );

  const [isUnallocatedSearchOpen, setIsUnallocatedSearchOpen] =
    useState<boolean>(false);
  const [isAllocatedSearchOpen, setIsAllocatedSearchOpen] =
    useState<boolean>(false);

  const handleUnallocatedSearchCancel = (): void => {
    setIsUnallocatedSearchOpen(false);
    dispatch(setTransferCustomersUnallocatedFilters({ search: '' }));

    if (unallocated.filters.search) {
      dispatch(
        requestTransferCustomersUnallocated(customersProvider, {
          ...unallocated.filters,
          id,
          added: addedCustomers,
          removed: removedCustomers,
          search: '',
          notRelatedWithSubscriptionId: subscriptionId,
          notRelatedWithBenefitId: benefitId,
          notRelatedWithLibraryId: libraryId,
          notRelatedWithTariffId: tariffId,
        })
      );
    }
  };

  const handleAllocatedSearchCancel = (): void => {
    setIsAllocatedSearchOpen(false);
    dispatch(setTransferCustomersAllocatedFilters({ search: '' }));

    if (allocated.filters.search) {
      dispatch(
        requestTransferCustomersAllocated(customersProvider, {
          ...allocated.filters,
          id,
          added: addedCustomers,
          removed: removedCustomers,
          search: '',
          relatedWithSubscriptionId: subscriptionId,
          relatedWithBenefitId: benefitId,
          relatedWithLibraryId: libraryId,
          relatedWithTariffId: tariffId,
        })
      );
    }
  };

  const changeAllocatedSearch = (searchTerm: SearchTerms): void => {
    setIsAllocatedSearchOpen(true);
    dispatch(setTransferCustomersAllocatedFilters({ searchTerm }));
  };

  const changeUnallocatedSearch = (searchTerm: SearchTerms): void => {
    setIsUnallocatedSearchOpen(true);
    dispatch(setTransferCustomersUnallocatedFilters({ searchTerm }));
  };

  useEffect(() => {
    if (customersAllocatedFilters.search) {
      setIsAllocatedSearchOpen(true);
    }
    if (customersUnallocatedFilters.search) {
      setIsUnallocatedSearchOpen(true);
    }
  });

  return {
    handleUnallocatedSearchCancel,
    handleAllocatedSearchCancel,
    changeUnallocatedSearch,
    changeAllocatedSearch,
    isUnallocatedSearchOpen,
    isAllocatedSearchOpen,
  };
};
