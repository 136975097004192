import {
  coursesPageServerRequest,
  CoursesPageWithErrorModel,
} from '@features/courses';
import { handleSetSearchTerm, setFilterOptionsState } from '@lerna-core';
import { Store } from '@store/store-v2';
import { NextPageContext } from 'next';

export const getCoursesPageInitialProps = async (
  store: Store,
  context: NextPageContext
): Promise<CoursesPageWithErrorModel> => {
  const props = await coursesPageServerRequest(store, context);

  store.dispatch(setFilterOptionsState(props.filters, context.query));
  store.dispatch(handleSetSearchTerm(props.suggest));

  return props;
};
