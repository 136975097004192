import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../styles';
import { WithTheme } from '../themes';

const headerHeight = 86;

export const ErrorPageContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: ${headerHeight}px;
  box-sizing: border-box;
  min-height: calc(100vh - ${headerHeight}px);
  text-align: center;

  > a {
    position: absolute;
    top: 34px;
    left: 0;

    svg {
      width: 140px;
      height: 48px;
    }

    ${breakpoint(
      'smallDown',
      css`
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        svg {
          width: 77px;
          height: 24px;
        }
      `
    )}
  }

  ${breakpoint(
    'smallDown',
    css`
      display: block;
      text-align: left;
      padding-top: 120px;
    `
  )}
`;

export const ErrorPageStyled = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const ErrorPageReloadStyled = styled.div`
  ${breakpoint(
    'smallDown',
    css`
      display: none;
    `
  )}
`;

export const ErrorPageLinkContainerStyled = styled.div`
  margin: 16px 0 50px;
`;

export const PageNotFoundLinkStyled = styled.span<WithTheme>`
  color: ${({ theme }): string => theme.COLOR.PURPLE};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};

  font-weight: 500;
  cursor: pointer;

  a {
    text-decoration: none;

    &:visited {
      color: ${({ theme }): string => theme.COLOR.PURPLE};
    }
  }
`;
