export enum UserMenuConstants {
  REQUEST_USER_MENU_START = 'REQUEST_USER_MENU_START',
  REQUEST_USER_MENU_SUCCESS = 'REQUEST_USER_MENU_SUCCESS',
  REQUEST_USER_MENU_ERROR = 'REQUEST_USER_MENU_ERROR',
}

export enum UserMenuTypes {
  desktop = 'desktop',
  mobile = 'mobile',
}

export enum UserMenuStaticLinksTypes {
  catalog = 'catalog',
  profile = 'profile',
  login = 'login',
  logout = 'logout',
}
