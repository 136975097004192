import React, { memo, ReactElement } from 'react';
import { FooterLinkContainerProps } from './footerLink.model';
import { FooterLinkContainerStyled } from './footerLink.styled';
import { objectService } from '../../../services';
import { LinkTargetEnum } from '../../../models';
import { pushFooterDataLayer } from '../footer.analytics';

function footerLinkContainer({
  link,
  rel,
  target = LinkTargetEnum.self,
  analyticsEvent,
}: FooterLinkContainerProps): ReactElement {
  const options =
    objectService.clearObject({
      real: rel || null,
      target,
    }) || {};

  const handleClick = (): void => {
    analyticsEvent && pushFooterDataLayer(analyticsEvent);
  };

  return (
    <FooterLinkContainerStyled
      href={link.link}
      onClick={handleClick}
      {...options}
    >
      {link.label}
    </FooterLinkContainerStyled>
  );
}

export const FooterLinkContainer = memo(footerLinkContainer);
