import styled, { css } from 'styled-components';
import { SectionStyledPropsType } from './section.model';

export const SectionStyled = styled.div<SectionStyledPropsType>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  & > * {
    ${({ isRouting }): ReturnType<typeof css> | string =>
      isRouting
        ? css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
          `
        : ''}
    transition: opacity 500ms;
  }
`;
