import React, { ReactElement } from 'react';
import { HistogramBarsProps } from './histogramBars.model';
import {
  HistogramBarsStyled,
  HistogramBarStyled,
  MarkerLinesStyled,
  MarkerLineStyled,
} from './histogramBars.styled';

export const HistogramBarsContainer = ({
  values,
  maxValue,
}: HistogramBarsProps): ReactElement => (
  <HistogramBarsStyled>
    {values.map(({ value, color }, index) => (
      <HistogramBarStyled
        color={color}
        maxValue={maxValue}
        height={value}
        key={index}
      />
    ))}

    <MarkerLinesStyled>
      <MarkerLineStyled />
      <MarkerLineStyled />
      <MarkerLineStyled />
    </MarkerLinesStyled>
  </HistogramBarsStyled>
);
