import React, { ReactElement, useContext } from 'react';
import { browserService, DeviceInfoContext } from '@lerna-core';
import { CourseSubTitleContainerStyled } from './courseSubTitle.styled';
import { CourseSubTitleContainerProps } from './courseSubtitle.model';

export const CourseSubTitleContainer = ({
  subtitle,
}: CourseSubTitleContainerProps): ReactElement => {
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  return (
    <CourseSubTitleContainerStyled isMobile={isMobile}>
      {subtitle}
    </CourseSubTitleContainerStyled>
  );
};
