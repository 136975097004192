import React, { cloneElement, ReactElement, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  AppService,
  authorizationService,
  ButtonColor,
  ButtonContainer,
  ContentWrapperStyled,
  HeaderLoaderContainer as HeaderLoader,
  IconHelpComponent,
  LogoContainer,
  PRESETS,
  publicConfig,
  RegisterWarningContainer,
  serverAuthorizationService,
  SwitchLanguageContainer,
  useTheme,
  useUser,
} from '@lerna-core';
import { useDiscountProgram } from '@features/common/discountProgram';
import {
  getHeaderColorType,
  getHomePageLink,
  isCollaborateWithLerna,
} from '../header.helpers';
import { HeaderDesktopContainerProps } from './headerDesktop.model';
import {
  HeaderDesktopButtonsContainerStyled,
  HeaderDesktopContainerStyled,
  HeaderDesktopContainerWrapperStyled,
  HeaderDesktopLeftStyled,
  HeaderDesktopStickyCenterStyled,
} from './headerDesktop.styled';
import {
  SearchButtonContainer,
  SearchContainer,
} from '@features/common/search';
import { DesktopLinksContainer } from './desktopLinks';
import { HeaderProfileButtonContainer } from '../headerProfileButton';

const { w: logoWidth, h: logoHeight } = PRESETS.logoCompany.opts;

export const HeaderDesktopContainer = ({
  showSearch,
  isSticky,
  hasSticky,
  handleOpenContactUsPopup,
  translations,
  company,
  showFilters,
  headerLinkType,
  customerCoursesCount,
  useLoginText,
  withLogin,
  withBreadcrumbs,
  handleLogoClick,
  discountProgramLogo,
}: HeaderDesktopContainerProps): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const { user } = useUser();
  const { isDiscountProgramPage } = useDiscountProgram();
  const homePageLink = useMemo(() => getHomePageLink(router), []);
  const withCompany = !!user?.career?.companyId;

  const isAuthenticated = useMemo(() => {
    return AppService.isClientSide
      ? authorizationService.isAuthed()
      : serverAuthorizationService.isAuthed();
  }, [AppService.isClientSide]);
  const headerColorType = useMemo(
    () => getHeaderColorType(user, isDiscountProgramPage, withBreadcrumbs),
    []
  );
  const logo =
    isDiscountProgramPage && !!discountProgramLogo ? (
      cloneElement(discountProgramLogo, { href: homePageLink })
    ) : (
      <LogoContainer
        href={homePageLink}
        logo={company?.logo}
        callback={handleLogoClick}
        withCompany={!isDiscountProgramPage && withCompany}
        width={logoWidth}
        height={logoHeight}
        isCollaborateWithLerna={isCollaborateWithLerna(
          user,
          isDiscountProgramPage
        )}
      />
    );

  return (
    <>
      <HeaderDesktopContainerStyled
        isSticky={isSticky}
        showSearch={showSearch}
        hasSticky={hasSticky}
        withCompany={withCompany}
        colorType={headerColorType}
      >
        <ContentWrapperStyled>
          <HeaderDesktopContainerWrapperStyled>
            <HeaderDesktopLeftStyled>{logo}</HeaderDesktopLeftStyled>
            <HeaderDesktopStickyCenterStyled
              colorType={headerColorType}
              withCompany={withCompany}
              isSticky={isSticky}
              showSearch={showSearch}
            >
              {showSearch && <SearchContainer showSearch={showSearch} />}
              {showFilters && (
                <SearchButtonContainer
                  isFullButton={false}
                  isSticky={isSticky}
                />
              )}
            </HeaderDesktopStickyCenterStyled>
            <HeaderDesktopButtonsContainerStyled>
              <DesktopLinksContainer
                user={user}
                headerLinkType={headerLinkType}
                translations={translations}
                customerCoursesCount={customerCoursesCount}
              />
              <SwitchLanguageContainer
                isMobile={false}
                translations={translations}
              />
              <ButtonContainer
                onClick={handleOpenContactUsPopup}
                borderRadius={16}
                color={ButtonColor.transparentFull}
                data-instance="contactUs"
              >
                <IconHelpComponent
                  fillColor={theme.COLOR.TEXT_DARK_COLOR}
                  strokeColor={theme.COLOR.TEXT_DARK_COLOR}
                />
              </ButtonContainer>
              {(isAuthenticated || publicConfig.HAS_ACCOUNT === 'true') && (
                <HeaderProfileButtonContainer
                  translations={translations}
                  withLogin={withLogin}
                  useLoginText={useLoginText}
                />
              )}
            </HeaderDesktopButtonsContainerStyled>
          </HeaderDesktopContainerWrapperStyled>
          <RegisterWarningContainer />
        </ContentWrapperStyled>
        <HeaderLoader withCompany={withCompany} />
      </HeaderDesktopContainerStyled>
    </>
  );
};
