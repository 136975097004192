import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconEyeCrossedComponent = ({
  width = 16,
  height = 16,
  strokeColor = '#78747A',
  fillColor = '#78747A',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 1.5L14.5 13.5"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25316 5.31382C3.06094 5.1216 2.74946 5.11739 2.5622 5.31446C1.94239 5.96676 1.36785 6.73807 0.852166 7.62209C0.715945 7.85562 0.715945 8.14438 0.852166 8.3779C2.69142 11.5309 5.27937 13.25 8 13.25C8.65887 13.25 9.30996 13.1492 9.94451 12.9516C10.2818 12.8466 10.3638 12.4245 10.114 12.1747L9.67899 11.7397C9.54833 11.609 9.3567 11.563 9.17718 11.6068C8.78289 11.7029 8.38901 11.75 8 11.75C6.13016 11.75 4.14769 10.6626 2.54849 8.26463C2.44166 8.10444 2.44154 7.89574 2.54836 7.73556C2.88532 7.23029 3.23929 6.7832 3.60564 6.39221C3.79798 6.18692 3.80323 5.86389 3.60432 5.66498L3.25316 5.31382ZM5.70534 7.766C5.44044 7.5011 5 7.62537 5 8V8C5 9.65685 6.34315 11 8 11V11C8.37463 11 8.4989 10.5596 8.234 10.2947L5.70534 7.766ZM10.4728 9.69916C10.3146 9.9289 9.99005 9.92939 9.79282 9.73216L6.26784 6.20718C6.07061 6.00995 6.0711 5.68541 6.30084 5.52724C6.78388 5.19468 7.36921 5 8 5C9.65685 5 11 6.34315 11 8C11 8.63079 10.8053 9.21612 10.4728 9.69916ZM11.354 11.2933C11.1278 11.0672 11.1697 10.6898 11.4231 10.4946C12.1383 9.94387 12.8244 9.20497 13.4516 8.26449C13.5584 8.1043 13.5583 7.89555 13.4515 7.73537C11.8523 5.33744 9.86984 4.25 8 4.25C7.17575 4.25 6.3296 4.46131 5.50405 4.90304C5.2996 5.01243 5.04539 4.98474 4.88143 4.82077L4.50394 4.44328C4.28224 4.22158 4.31664 3.85177 4.58823 3.69511C5.66716 3.07277 6.82112 2.75 8 2.75C10.7206 2.75 13.3086 4.46908 15.1478 7.6221C15.2841 7.85562 15.2841 8.14438 15.1478 8.3779C14.3372 9.7676 13.3811 10.8787 12.3323 11.6869C12.1405 11.8347 11.8702 11.8096 11.699 11.6384L11.354 11.2933Z"
        fill={fillColor}
      />
    </svg>
  );
};
