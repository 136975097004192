import { CoursesSearchTerms } from '../../coursesTransfer.model';
import { TranslationModel } from '../../../../../models';

export const getSearchUnallocatedPlaceholder = (
  translations: TranslationModel,
  searchTerm?: CoursesSearchTerms
): string => {
  switch (searchTerm) {
    case CoursesSearchTerms.id:
      return translations.search_by_id;
    case CoursesSearchTerms.title:
    default:
      return translations.search_by_name;
  }
};

export const getSearchUnallocatedMinLength = (
  searchTerm?: CoursesSearchTerms
): number => {
  switch (searchTerm) {
    case CoursesSearchTerms.id:
      return 0;
    case CoursesSearchTerms.title:
    default:
      return 1;
  }
};
