import React, { ReactElement } from 'react';
import { PageScrollArrowStyled } from './pageScrollArrow.styled';
import { useScrollArrow } from './pageScrollArrow.hooks';
import {
  IconArrowLongComponent,
  Nullable,
  useFasttrackWidget,
} from '@lerna-core';

export const PageScrollArrowContainer = (): Nullable<ReactElement> => {
  const { showArrow } = useScrollArrow();
  const { showFasttrackWidget } = useFasttrackWidget();

  const handleClick = (): void => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (!showArrow) return null;

  return (
    <PageScrollArrowStyled
      onClick={handleClick}
      withWidget={showFasttrackWidget}
    >
      <IconArrowLongComponent />
    </PageScrollArrowStyled>
  );
};
