import styled from 'styled-components';

export const TransferLeftStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: inherit;
  z-index: 2;
`;
