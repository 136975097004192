import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconFilterComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      width={width}
      height={height}
    >
      <circle cx="14" cy="7" r="3" stroke={strokeColor} strokeWidth="2" />
      <path
        d="M4 7h7M17 7h3"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="10"
        cy="17"
        r="3"
        transform="rotate(-180 10 17)"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M20 17h-7M7 17H4"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
