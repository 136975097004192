import { ReactNode } from 'react';
import { EventDataModel, Nullable, TranslationModel } from '../../models';

export type OnboardingProps = {
  steps: OnboardingStepModel[];
  handleClose: () => void;
  translations: TranslationModel;
  withCounter?: boolean;
  parent?: Nullable<HTMLDivElement>;
  gap?: number;
  showMobilePointer?: boolean;
  showPointer?: boolean;
};

export enum PlacementEnum {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export type OnboardingStepEventsModel = {
  open?: EventDataModel;
  close?: EventDataModel;
  next?: EventDataModel;
};

export type OnboardingStepModel = {
  target: string;
  content: string | string[];
  events?: OnboardingStepEventsModel;
  placement?: keyof typeof PlacementEnum;
};

export type ElementPropertiesModel = {
  width: number;
  height: number;
  top: number;
  left: number;
};

export type PointerStyledProps = {
  showMobilePointer: boolean;
  showPointer: boolean;
};

export type OnboardingContextProviderProps = Omit<
  OnboardingProps,
  'handleClose' | 'steps'
> & {
  children: ReactNode;
};

export type OnboardingContextReturnType = {
  showOnboarding: (steps: OnboardingStepModel[], callback?: () => void) => {};
};

export type OnboardingRequestParamsModel = {
  slugs: OnboardingSlugsEnum[];
};

export enum OnboardingArea {
  hr = 'hr',
  catalog = 'catalog',
  personal = 'personal',
}

export enum OnboardingCatalogSlugsEnum {
  edstein = 'edstein',
}
export enum OnboardingHrSlugsEnum {}
export enum OnboardingPersonalSlugsEnum {}

export type OnboardingSlugsEnum =
  | OnboardingHrSlugsEnum
  | OnboardingCatalogSlugsEnum
  | OnboardingPersonalSlugsEnum;

export type OnboardingSuggestModel = Record<OnboardingSlugsEnum, boolean>;
