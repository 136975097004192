import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconListComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2.5',
  strokeColor = '#78747A',
  fillColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      d="M22 6.4a.9.9 0 00.9-.9.9.9 0 00-.9-.9v1.8zM9 4.6a.9.9 0 100 1.8V4.6zm13 0H9v1.8h13V4.6zM22 12.9a.9.9 0 00.9-.9.9.9 0 00-.9-.9v1.8zM9 11.1a.9.9 0 00-.9.9.9.9 0 00.9.9v-1.8zm13 0H9v1.8h13v-1.8zM22 19.4a.9.9 0 00.9-.9.9.9 0 00-.9-.9v1.8zM9 17.6a.9.9 0 00-.9.9.9.9 0 00.9.9v-1.8zm13 0H9v1.8h13v-1.8z"
    />
    <circle
      cx="3.5"
      cy="5.5"
      r="1.25"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="3.5"
      cy="12"
      r="1.25"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <circle
      cx="3.5"
      cy="18.5"
      r="1.25"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
);
