import { getHeaderStyle } from '../header.styled';
import { SearchButtonContainerStyled } from '@features/common/search';
import { positionSticky } from '@lerna-core';
import styled, { css } from 'styled-components';
import { HeaderMobileContainerStyledProps } from './headerMobile.model';

export const headerIconContainer = (): ReturnType<typeof css> => css`
  width: 24px;
  height: 24px;
  position: relative;
`;

export const HeaderMobileContainerStyled = styled.div<HeaderMobileContainerStyledProps>`
  box-sizing: border-box;
  height: 64px;
  padding: 20px 20px 16px;
  border-bottom: 1px solid;
  border-bottom-color: transparent;
  position: relative;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_LAYER};
  ${({ isSticky, colorType }): ReturnType<typeof css> =>
    getHeaderStyle(isSticky, colorType)};
  ${({ isSticky }): ReturnType<typeof css> =>
    isSticky
      ? css`
          top: -4px;
          border-bottom-color: rgba(0, 0, 0, 0.08);
          ${positionSticky()};
        `
      : css``};
`;

export const HeaderMobileWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const HeaderMobileLogoStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  z-index: 200;
`;

export const HeaderMobileIconStyled = styled.div`
  ${headerIconContainer()};

  &:not(:last-child) {
    margin-right: 24px;
  }

  ${SearchButtonContainerStyled} {
    margin: 0;

    > button {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
`;

export const HeaderMobileBoxStyled = styled.div`
  display: flex;
  align-items: center;
  z-index: 200;
`;
