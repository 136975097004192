import { ApiVendorModel, VendorModel } from '../features';

export enum PartnershipDiscountType {
  absolute = 'absolute',
  additional = 'additional',
}

export type PartnershipDiscountApi = {
  company_uuid: string;
  vendor: {
    data: ApiVendorModel;
  };
  percentage: number;
  type: PartnershipDiscountType;
};

export type PartnershipDiscount = {
  companyId: string;
  vendor: VendorModel;
  percentage: number;
  type: PartnershipDiscountType;
};

export type PartnershipDiscountsApiResponse = {
  data: PartnershipDiscountApi[];
};

export type PartnershipDiscountsResponse = {
  data: PartnershipDiscountsApiResponse;
};
