import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMyskillsLogoComponent = ({
  width = 137,
  height = 34,
  fillColor = '#116f0f',
}: IconProps): ReactElement => {
  return (
    <svg viewBox="0 0 137 34" width={width} height={height}>
      <path
        fill={fillColor}
        d="M.3 26V8.1h4.1l.4 2.4c.6-.8 1.3-1.5 2.3-2 .9-.5 2-.8 3.3-.8 2.8 0 4.7 1.1 5.9 3.2.6-1 1.5-1.8 2.6-2.3 1.1-.6 2.3-.9 3.6-.9 2.3 0 4.1.7 5.4 2.1 1.2 1.4 1.9 3.4 1.9 6.1V26h-4.6v-9.6c0-1.5-.3-2.7-.9-3.5-.6-.8-1.5-1.2-2.7-1.2-1.2 0-2.3.5-3 1.4-.7.9-1.1 2.2-1.1 3.8V26h-4.6v-9.6c0-1.5-.3-2.7-.9-3.5-.6-.8-1.5-1.2-2.8-1.2-1.2 0-2.2.5-3 1.4-.7.9-1.1 2.2-1.1 3.8V26H.3zM35.7 33.9l4.1-9.1h-1.1L31.8 8.1h5l5 12.6L47 8.1h4.9L40.6 33.9h-4.9zM61.4 26.4c-1.6 0-3-.3-4.2-.8-1.2-.5-2.2-1.2-2.9-2.2-.7-.9-1.2-2-1.3-3.2h4.6c.1.7.5 1.3 1.2 1.8.6.5 1.5.7 2.5.7s1.7-.2 2.2-.6c.5-.4.7-.9.7-1.4 0-.8-.3-1.3-1-1.5-.7-.3-1.6-.6-2.8-.8-.8-.2-1.5-.4-2.3-.6s-1.5-.5-2.2-.9c-.6-.4-1.2-.9-1.6-1.4-.4-.6-.6-1.3-.6-2.2 0-1.6.6-2.9 1.9-4 1.3-1.1 3-1.6 5.3-1.6 2.1 0 3.8.5 5 1.5 1.3 1 2 2.3 2.3 4.1h-4.4c-.3-1.3-1.3-2-3-2-.9 0-1.5.2-2 .5s-.7.8-.7 1.3.3.9 1 1.3c.7.3 1.6.6 2.8.9 1.2.3 2.4.6 3.4 1 1.1.3 1.9.9 2.5 1.5.6.7.9 1.6.9 2.9 0 1.1-.3 2.1-.9 3-.6.9-1.5 1.6-2.6 2.1s-2.2.6-3.8.6zM73 26V.1h4.6v15.3l6.3-7.2h5.5l-7.3 8.1 8.5 9.7h-5.8l-7.2-9v9H73zM95.9 5.4c-.8 0-1.5-.3-2.1-.8-.5-.5-.8-1.1-.8-1.9s.3-1.4.8-1.9C94.4.3 95 0 95.9 0c.8 0 1.5.3 2.1.8.6.5.8 1.1.8 1.9s-.3 1.4-.8 1.9c-.6.5-1.2.8-2.1.8zM93.6 26V8.1h4.6V26h-4.6zM103 26V.1h4.6V26H103zM112.3 26V.1h4.6V26h-4.6zM129 26.4c-1.6 0-3-.3-4.2-.8-1.2-.5-2.2-1.2-2.9-2.2-.7-.9-1.2-2-1.3-3.2h4.6c.1.7.5 1.3 1.2 1.8.6.5 1.5.7 2.5.7s1.7-.2 2.2-.6c.5-.4.7-.9.7-1.4 0-.8-.3-1.3-1-1.5-.7-.3-1.6-.6-2.8-.8-.8-.2-1.5-.4-2.3-.6s-1.5-.5-2.2-.9c-.6-.4-1.2-.9-1.6-1.4-.4-.6-.6-1.3-.6-2.2 0-1.6.6-2.9 1.9-4 1.3-1.1 3-1.6 5.3-1.6 2.1 0 3.8.5 5 1.5 1.3 1 2 2.3 2.3 4.1h-4.4c-.3-1.3-1.3-2-3-2-.9 0-1.5.2-2 .5s-.7.8-.7 1.3.3.9 1 1.3 1.6.6 2.8.9c1.2.3 2.4.6 3.4 1 1.1.3 1.9.9 2.5 1.5.6.7.9 1.6.9 2.9 0 1.1-.3 2.1-.9 3-.6.9-1.5 1.6-2.6 2.1-.9.4-2.2.6-3.8.6z"
      />
    </svg>
  );
};
