import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../../../hooks';
import { Nullable } from '../../../../models';
import { logger, pushDataLayer } from '../../../../services';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
} from '../../../button';
import {
  getSelectedEducationStreamsPageData,
  useSelectedEducationStreams,
} from '../../../educationStreams';
import { PhoneControlComponent, TextControlComponent } from '../../../form';
import { IconEditComponent } from '../../../icons';
import { FileUploadContainer } from '../../../inputs';
import { PaperComponentStyled } from '../../../paper';
import { MessagePopupComponent, PopupContainer } from '../../../popups';
import { FormPopupColumn } from '../../../popups/formPopup/formPopup.styled';
import { useTranslations } from '../../../translations';
import { requestUserReload } from '../../user.actions';
import { UserAvatarSize } from '../../userAvatar';
import { UserAvatarWithActionContainer } from '../../userAvatarWithAction';
import { FormUserEditClient } from './formUserEdit.client';
import { useUserEditForm, useUserPhoto } from './formUserEdit.hooks';
import {
  EditUserFormContainerProps,
  FormInitialValues,
} from './formUserEdit.model';
import {
  FormUserEditStyled,
  FormUserEditTitleStyled,
} from './formUserEdit.styled';
import { FormUserEducationStreamsComponent } from './formUserEducationStreams';
import {
  ProfilePhotoHeadingStyled,
  ProfilePhotoSectionStyled,
  ProfilePhotoSideStyled,
} from './formUserPhoto';

const MAX_ATTEMPTS = 3;

export const FormUserEditContainer = ({
  personal,
  redirectUrl,
  isMobile,
}: EditUserFormContainerProps): ReactElement => {
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [attemptNumber, setAttemptNumber] = useState<number>(0);

  const translations = useTranslations();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedEducationStreams, handleSelectEducationStreams] =
    useSelectedEducationStreams();
  const {
    handleDelete,
    handleUpload,
    deleteUploadedPhoto,
    photoError,
    closePhotoError,
    currentImage,
  } = useUserPhoto();

  const userWithChangedImage = useMemo(
    () => ({
      ...personal,
      avatar: currentImage,
    }),
    [personal, currentImage]
  );

  const fileUploadButtonTitle = personal.avatar
    ? translations.customer_profile_edit_photo
    : translations.customer_profile_add_photo;

  const closeError = (): void => {
    setIsErrorDisplayed(false);
    setAttemptNumber(0);
  };

  const closeSuccess = (): void => {
    setIsSuccessDisplayed(false);
    setAttemptNumber(0);
  };

  const getErrorButtonTitle = (): Nullable<string> =>
    attemptNumber < MAX_ATTEMPTS
      ? translations?.button_resend_title || null
      : null;

  const sendRequest = async (formValues: FormInitialValues): Promise<void> => {
    setIsErrorDisplayed(false);
    await deleteUploadedPhoto();

    await new FormUserEditClient({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      patronymic: formValues.patronymic,
      phone: formValues.phone,
      avatar: currentImage,
      uuid: personal.uuid,
      educationStreams: selectedEducationStreams,
    })
      .patch()
      .then((): void => {
        setIsSuccessDisplayed(true);
        dispatch(requestUserReload());

        pushDataLayer(
          getSelectedEducationStreamsPageData(selectedEducationStreams)
        );
      })
      .catch((error) => {
        logger.error(`[ERROR]: can not edit user ${error}`);
        setIsErrorDisplayed(true);
        setAttemptNumber(attemptNumber + 1);
      });
  };

  const {
    isSubmitting,
    isValid,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = useUserEditForm(sendRequest);

  const isDisabledSubmitButton = isSubmitting || !isValid;

  const resend = (): void => {
    sendRequest(values);
  };

  const fileUploadButton = !isMobile && (
    <FileUploadContainer onUpload={handleUpload}>
      <ButtonContainer
        title={fileUploadButtonTitle}
        color={ButtonColor.brightPurple}
        childrenPosition={ButtonChildrenPosition.left}
        children={
          personal.avatar ? (
            <IconEditComponent strokeColor={theme.COLOR.PRIMARY['500']} />
          ) : undefined
        }
      />
    </FileUploadContainer>
  );

  return (
    <FormUserEditStyled>
      <PaperComponentStyled>
        <FormPopupColumn>
          <ProfilePhotoSectionStyled>
            <UserAvatarWithActionContainer
              personal={userWithChangedImage}
              size={isMobile ? UserAvatarSize.xbig : UserAvatarSize.large}
              imageSource={currentImage}
              onActionClick={handleDelete}
            />

            <ProfilePhotoSideStyled>
              <ProfilePhotoHeadingStyled>
                {translations.customer_profile_photo_title}
              </ProfilePhotoHeadingStyled>

              {!isMobile && fileUploadButton}
            </ProfilePhotoSideStyled>
          </ProfilePhotoSectionStyled>
          {isMobile && fileUploadButton}
        </FormPopupColumn>

        <FormUserEditTitleStyled>
          {translations.customer_profile_general_info_title}
        </FormUserEditTitleStyled>
        <TextControlComponent
          value={values.firstName}
          errorMessage={errors.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.firstName}
          label={translations?.input_name_label}
          placeholder={translations?.placeholder_name}
          setFieldValue={setFieldValue}
          name="firstName"
          type="text"
        />
        <TextControlComponent
          value={values.lastName}
          errorMessage={errors.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.lastName}
          label={translations?.input_last_name_label}
          placeholder={translations?.input_last_name_label}
          setFieldValue={setFieldValue}
          name="lastName"
          type="text"
        />
        <TextControlComponent
          value={values.patronymic}
          errorMessage={errors.patronymic}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.patronymic}
          label={translations?.input_patronymic_label}
          placeholder={translations?.input_patronymic_label}
          setFieldValue={setFieldValue}
          name="patronymic"
          type="text"
        />

        <PhoneControlComponent
          errorMessage={errors.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          label={translations?.input_phone_label}
          name="phone"
          touched={touched.phone}
          setFieldValue={setFieldValue}
        />

        <FormUserEditTitleStyled>
          {translations.interests}
        </FormUserEditTitleStyled>
        <FormPopupColumn>
          <FormUserEducationStreamsComponent
            selectedEducationStreams={selectedEducationStreams}
            handleSelectEducationStreams={handleSelectEducationStreams}
          />
        </FormPopupColumn>
        <ButtonContainer
          color={ButtonColor.purple}
          onClick={handleSubmit}
          borderRadius={100}
          disabled={isDisabledSubmitButton}
          title={translations?.button_save_changes_label}
        />
      </PaperComponentStyled>

      <PopupContainer
        handleClose={closePhotoError}
        isOpened={photoError}
        hasHeader={false}
      >
        <MessagePopupComponent
          title={translations?.popup_error_photo_upload_title}
          status="error"
          statusWidth={128}
          statusHeight={128}
        />
      </PopupContainer>

      <PopupContainer
        handleClose={closeError}
        isOpened={isErrorDisplayed}
        hasHeader={false}
      >
        <MessagePopupComponent
          title={translations?.popup_error_edit_title}
          buttonTitle={getErrorButtonTitle()}
          buttonCallback={resend}
          status="error"
          statusWidth={128}
          statusHeight={128}
          subtitle={translations?.try_again}
        />
      </PopupContainer>

      <PopupContainer
        handleClose={closeSuccess}
        isOpened={isSuccessDisplayed}
        hasHeader={false}
        closeTimeout={3}
        redirectUrl={redirectUrl}
      >
        <MessagePopupComponent
          title={translations?.popup_success_edit_title}
          buttonCallback={closeSuccess}
        />
      </PopupContainer>
    </FormUserEditStyled>
  );
};
