import React, { ReactElement } from 'react';
import { CourseTitleType, TitleH1ContainerProps } from './courseTitle.model';
import { TitleH1Styled, TitleH3Styled } from './courseTitle.styled';

export const CourseTitleContainer = ({
  title,
  type,
}: TitleH1ContainerProps): ReactElement => {
  switch (type) {
    case CourseTitleType.h1: {
      return <TitleH1Styled>{title}</TitleH1Styled>;
    }
    case CourseTitleType.h3: {
      return <TitleH3Styled>{title}</TitleH3Styled>;
    }
  }
};
