import {
  EducationStreamApiModel,
  EducationStreamModel,
  EducationStreamsApiResponse,
  EducationStreamsModel,
} from './educationStreams.model';
import { mapMetaPaginationAdapter } from '../../adapters';

export const educationStreamsAdapter = (
  response: EducationStreamsApiResponse
): EducationStreamsModel => {
  return {
    data: mapEducationStreamsAdapter(response.data.data),
    meta: mapMetaPaginationAdapter(response.data.meta.pagination),
  };
};

export const mapEducationStreamsAdapter = (
  educationStreams: EducationStreamApiModel[]
): EducationStreamModel[] => {
  return educationStreams.map(mapEducationStreamAdapter);
};

const mapEducationStreamAdapter = (
  educationStream: EducationStreamApiModel
): EducationStreamModel => ({
  id: educationStream.id,
  title: educationStream.title,
});
