import { ReactElement } from 'react';
import * as S from './subscriptionBadge.styled';
import { SubscriptionBadgeModel } from './subscriptionBadge.model';

export const SubscriptionBadgeContainer = ({
  status,
  label,
  link,
  linkLabel,
}: SubscriptionBadgeModel): ReactElement => {
  return (
    <S.SubscriptionBadge status={status}>
      <S.SubscriptionBadgeLabel dangerouslySetInnerHTML={{ __html: label }} />
      {link && linkLabel && (
        <S.SubscriptionBadgeLink
          href={link}
          dangerouslySetInnerHTML={{ __html: linkLabel }}
        />
      )}
    </S.SubscriptionBadge>
  );
};
