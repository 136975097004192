import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSuccessComponent = ({
  width = 80,
  height = 80,
  strokeWidth = '6',
  fillColor = '#2E8200',
  strokeColor = '#fff',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill={fillColor} />
      <path
        d="M25 40L35 50L55 30"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
