import {
  CompensationRequestsModel,
  CompensationsRequestsParams,
} from './compensationRequests.model';
import { CompensationRequestsClient } from './compensationRequests.client';
import { CurrencyModel } from '../../models';

export async function compensationsRequestsClientProvider(
  params: CompensationsRequestsParams,
  currencies: CurrencyModel[]
): Promise<CompensationRequestsModel> {
  const response = await new CompensationRequestsClient(
    params,
    currencies
  ).get();

  return response as unknown as Promise<CompensationRequestsModel>;
}
