import { AppContext } from 'next/app';
import {
  AppService,
  cookieService,
  logger,
  RequestHeader,
} from '../../services';
import { serverAuthorizationService } from '../authorization';
import { onboardingClientProvider } from './onboarding.clientProvider';
import {
  OnboardingArea,
  OnboardingSlugsEnum,
  OnboardingSuggestModel,
} from './onboarding.model';
import { onboardingAllClientProvider } from './onboardingAll';
import { onboardingCompletedClientProvider } from './onboardingCompleted';

const COOKIE_KEY = 'onboarding';

class OnboardingService {
  private static instance: OnboardingService;

  private isAuthed = false;
  public suggests = {} as OnboardingSuggestModel;

  public static getInstance(): OnboardingService {
    if (!OnboardingService.instance) {
      OnboardingService.instance = new OnboardingService();
    }
    return OnboardingService.instance;
  }

  private async requestSuggests(area: OnboardingArea, context: AppContext) {
    const all = await onboardingAllClientProvider(area);

    const requestHeaders = serverAuthorizationService.getAuthHeaders(
      context.ctx.req,
      context.ctx.res
    );

    const completed = this.isAuthed
      ? await onboardingCompletedClientProvider(
          OnboardingArea.catalog,
          requestHeaders
        )
      : [];

    // инициализируем подсказки
    all.forEach((slug) => {
      this.suggests[slug] = completed.includes(slug);
    });
  }

  public async init(
    area: OnboardingArea,
    context: AppContext,
    isAuthed: boolean = false
  ) {
    this.isAuthed = isAuthed;

    await this.requestSuggests(area, context);

    // получаем подсказки из кук
    const cookieSuggest = cookieService.getCookie(
      COOKIE_KEY,
      context.ctx.req?.headers.cookie
    );
    const suggestsFromCookie = cookieSuggest ? JSON.parse(cookieSuggest) : {};
    const suggestsSlugs = Object.keys(
      suggestsFromCookie
    ) as OnboardingSlugsEnum[];

    const newSuggestsToComplete = [] as OnboardingSlugsEnum[];

    // выясняем, какие подсказки из кук новые
    suggestsSlugs.forEach((key) => {
      if (
        key in this.suggests &&
        !this.suggests[key] &&
        suggestsFromCookie[key]
      ) {
        newSuggestsToComplete.push(key);
      }
    });

    // сетаем новые подсказки
    if (newSuggestsToComplete.length) {
      const requestHeaders = serverAuthorizationService.getAuthHeaders(
        context.ctx.req,
        context.ctx.res
      );

      this.complete(newSuggestsToComplete, requestHeaders);
    }
  }

  private setCookie() {
    if (!AppService.isClientSide) return;
    cookieService.setCookie({
      name: COOKIE_KEY,
      val: JSON.stringify(this.suggests),
    });
  }

  private setSuggests(slugs: OnboardingSlugsEnum[]) {
    slugs.forEach((slug) => {
      this.suggests[slug] = true;
    });
    this.setCookie();
  }

  public async complete(
    slug: OnboardingSlugsEnum | OnboardingSlugsEnum[],
    requestHeaders?: RequestHeader
  ): Promise<void> {
    const slugs = slug instanceof Array ? slug : [slug];
    // если авторизован, изменяем саджесты только при 200 респонсе
    if (this.isAuthed) {
      await onboardingClientProvider(slugs, requestHeaders)
        .then(() => {
          this.setSuggests(slugs);
        })
        .catch((err) => {
          logger.error('[ERROR]: can not complete suggest', err);
        });
      // если нет просто сетаем в куки
    } else {
      this.setSuggests(slugs);
    }
  }

  public sync(suggests: OnboardingSuggestModel, isAuthed: boolean): void {
    this.isAuthed = isAuthed;
    this.suggests = suggests;
    this.setCookie();
  }

  public shouldShow(slug: OnboardingSlugsEnum): boolean {
    return this.suggests[slug] === false;
  }
}

export const onboardingService = OnboardingService.getInstance();
