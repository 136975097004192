import { publicConfig, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { createUserErrorAdapter } from '../createUser';
import { UpdateUserParams } from './updateUser.model';

export class UpdateUserClient extends SecureRequestClient<
  UpdateUserParams,
  null,
  null
> {
  protected path = 'personal-api/v1/me';
  protected requestParameters: UpdateUserParams;
  // TODO: Расширить класс RequestClient для типизации error response
  // @ts-ignore
  protected errorAdapter = createUserErrorAdapter;

  constructor(user: UpdateUserParams) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL
    );
    this.requestParameters = user;
  }
}
