import React, { ReactElement } from 'react';

import { MenuElement } from '../menu.model';
import { SideMenuSection, SideMenuLinkContainer } from '../sideMenu';

export const renderMenuSection = (
  { title, link, icon, children }: MenuElement,
  path: string
): ReactElement => {
  const isOpen =
    children?.length && children?.some((child) => child.link === path);

  return (
    <SideMenuSection
      key={title}
      link={link}
      icon={icon}
      title={title}
      isOpen={!!isOpen}
    >
      {!!children
        ? children?.map(
            (child: MenuElement): ReactElement => renderMenuElement(child, path)
          )
        : null}
    </SideMenuSection>
  );
};

export const renderMenuLink = (
  { title, link, icon }: MenuElement,
  path: string
): ReactElement => {
  return (
    <SideMenuLinkContainer
      key={title}
      isActive={link === path}
      nested={true}
      link={link}
      icon={icon}
      title={title}
    />
  );
};

export const renderMenuElement = (
  menuElement: MenuElement,
  path: string
): ReactElement => {
  if (menuElement.children && menuElement.children.length) {
    return renderMenuSection(menuElement, path);
  }

  return renderMenuLink(menuElement, path);
};

export interface NestedSideMenuProps {
  links: MenuElement[];
  path: string;
}

export const NestedSideMenuContainer = ({
  links,
  path,
}: NestedSideMenuProps): ReactElement => {
  return (
    <>
      {links.map((menuElement: MenuElement) =>
        renderMenuElement(menuElement, path)
      )}
    </>
  );
};
