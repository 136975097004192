import { QueryParams, urlService } from '@lerna-core';
import { pageUrls, pageUrlsReplacers } from '@constants';
import { NextRouter } from 'next/router';

export const getDiscountProgramPageUrl = (
  router: NextRouter,
  discountProgramQuery: string,
  requiredParameters?: string[]
): string =>
  urlService.getFullUrlToPage(
    `${pageUrls.discountProgram.replace(
      pageUrlsReplacers.discountProgram,
      discountProgramQuery
    )}`,
    router,
    requiredParameters || [QueryParams.promoCode]
  );
