import styled from 'styled-components';
import { rgba } from 'polished';
import {
  CompanyLogoEditProps,
  CompanyEditLogoActionOverlayProps,
} from './companyEditLogo.model';
import { getDottedBorder } from './companyEditLogo.helper';
import { fontSettings } from '../../../styles';

export const CompanyEditLogoWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
`;

export const CompanyLogoStyled = styled.div<CompanyLogoEditProps>`
  ${(props): string => getDottedBorder(props)}
  position: relative;
  border-radius: 8px;
  flex: 0 0 auto;
  width: ${({ width }): number => width}px;
  height: ${({ height }): number => height}px;
`;

export const CompanyLogoInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CompanyLogoLabelStyled = styled.div`
  font-weight: 700;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)};
`;

export const CompanyEditLogoWarning = styled.div`
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
`;

export const LogoPlug = styled.div`
  width: 280px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(0%2C0%2C0%2C%20.12)' stroke-width='2' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 16px;
  cursor: pointer;
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};

  & > svg {
    padding: 8px;
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    border-radius: 50%;
  }
`;

export const CompanyEditLogoActionOverlay = styled.div<CompanyEditLogoActionOverlayProps>`
  width: calc(100% + 4px);
  height: ${({ height }): number => height}px;
  position: absolute;
  display: none;
  bottom: -2px;
  left: -2px;
  background: ${(props): string =>
    rgba(props.theme.COLOR.TEXT_DARK_COLOR, 0.4)};
  color: ${(props): string => props.theme.COLOR.WHITE};
  height: ${({ theme }): string => (theme.IS_MOBILE ? '20px' : '32px')};
  text-align: center;
  pointer-events: none;
  border-radius: 0 0 8px 8px;

  ${CompanyLogoStyled}:hover & {
    display: block;
    pointer-events: all;
  }
`;

export const CompanyEditLogoActionStyled = styled.div`
  padding-top: ${({ theme }): string => (theme.IS_MOBILE ? '2px' : '8px')};
  cursor: pointer;
`;
