import { CSSVariables, ScrollOptionsModel } from '../../models';
import { AppService } from '../appService/app.service';
import { BODY_CLASS_NAME, DeviceInfo } from '../../features';

export class BrowserService {
  private static instance: BrowserService;
  private readonly scrollStyleClass = 'scrollBehavior';
  private readonly scrollStyleBehavior = 'smooth';

  /**
   * Returning instance of singletone service
   */
  public static getInstance(): BrowserService {
    if (!BrowserService.instance) {
      BrowserService.instance = new BrowserService();
    }

    return BrowserService.instance;
  }

  /**
   * Method detects browser scrollbar width
   * @return {number} - scrollbar width in pixels
   */
  public getScrollbarWidth(): number {
    if (AppService.isClientSide) {
      return window.innerWidth - document.documentElement.clientWidth;
    }

    return 0;
  }

  /**
   * Method detecting is current browser is mobile browser
   * @param deviceInfoContext{DeviceInfo | null} - context from serverside, contain information from request header about device and browser
   * @return {boolean} - information is application runs on mobile device
   */
  public mobileDetectFromContext = (
    deviceInfoContext: DeviceInfo | null
  ): boolean => deviceInfoContext?.isMobile || false;

  /**
   * Method detecting is current browser is tablet browser
   * @param deviceInfoContext{DeviceInfo | null} - context from serverside, contain information from request header about device and browser
   * @return {boolean} - information is application runs on mobile device
   */
  public tabletDetectFromContext = (
    deviceInfoContext: DeviceInfo | null
  ): boolean => deviceInfoContext?.isTablet || false;

  /**
   * Method will allow make smooth browser scrolling into coordinates
   * @param ref{HTMLDivElement | null} - React Reference to html element
   * @param left{number} - horizontal offset for scroll
   * @param top - vertical offset for scroll
   * @param scrollBehavior - set type of scrolling
   */
  public scrollToElement(
    ref: HTMLDivElement | null,
    left = 0,
    top = 0,
    scrollBehavior?: ScrollBehavior
  ): void {
    if (!ref) {
      return;
    }

    const scrollOptions: ScrollOptionsModel = {
      top,
      left,
    };

    if (this.scrollStyleClass in document.body.style) {
      scrollOptions.behavior = scrollBehavior || this.scrollStyleBehavior;
    }

    window.scrollTo(scrollOptions);
  }

  /**
   * Method returns css value of css property by property name of Document
   * @param variable{string} - name of css property to be returned
   * @return {number} - value of selected css property
   */
  public getCSSVariableValue(variable: string): number {
    if (AppService.isClientSide) {
      return (
        parseInt(
          getComputedStyle(document.documentElement).getPropertyValue(variable)
        ) || 0
      );
    }

    return 0;
  }

  /**
   * Method removes scroll of Document
   */
  public removePageScroll(): void {
    if (AppService.isClientSide) {
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarWidth,
        `${this.getScrollbarWidth()}px`
      );
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarPlugWidth,
        `${this.getScrollbarWidth()}px`
      );
      document.body.classList.add(BODY_CLASS_NAME);
    }
  }

  /**
   * Method add scroll of Document
   */
  public addPageScroll(): void {
    if (AppService.isClientSide) {
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarPlugWidth,
        '0px'
      );
      document.body.classList.remove(BODY_CLASS_NAME);
    }
  }
}

export const browserService = BrowserService.getInstance();
