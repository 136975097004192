import { CurrenciesClient } from './currencies.client';
import { Currency } from './currencies.model';

export async function currenciesClientProvider(
  host?: string,
  privateHost?: string
): Promise<Currency[]> {
  const response = await new CurrenciesClient(host, privateHost).get();

  return response as unknown as Promise<Currency[]>;
}
