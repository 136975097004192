import * as constants from './coursesTransfer.constants';

import {
  ConcatCoursesTransferModel,
  CourseItemsAfterChange,
  CoursesFilters,
  CoursesTransferActions,
  CoursesTransferRequestParams,
  CoursesTransferState,
} from './coursesTransfer.model';
import { getCoursesLoading } from './coursesTransfer.service';

export const initialTransferCoursesMeta = {
  total: 0,
  count: 0,
  perPage: 50,
  currentPage: 1,
  totalPages: 1,
  links: {},
};

export const initialTransferCourses = {
  data: [],
  meta: initialTransferCoursesMeta,
  isPaginating: false,
  isLoading: false,
  isPending: false,
  isSelectedAll: false,
  initialTotalCourses: 0,
  filters: {
    sphere: [],
    search: '',
  },
};

export const initialTransferCoursesState: CoursesTransferState = {
  unallocated: initialTransferCourses,
  allocated: initialTransferCourses,
  added: [],
  removed: [],
  totalCourses: 0,
};

export const coursesTransferReducer = (
  state: CoursesTransferState = initialTransferCoursesState,
  action: CoursesTransferActions
): CoursesTransferState => {
  let payload = action.payload;
  const { allocated: allocatedCourses, unallocated: unallocatedCourses } =
    state;

  switch (action.type) {
    case constants.INIT_TRANSFER_COURSES_SUCCESS:
      payload = payload as CoursesTransferState;

      return {
        ...state,
        ...payload,
      };

    case constants.SET_TRANSFER_UNALLOCATED_COURSES_FILTERS:
      const unallocatedFilters = action.payload as CoursesFilters;

      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          filters: {
            ...unallocatedCourses.filters,
            ...unallocatedFilters,
          },
        },
      };

    case constants.SET_TRANSFER_ALLOCATED_COURSES_FILTERS:
      const allocatedFilters = action.payload as CoursesFilters;

      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          filters: {
            ...allocatedCourses.filters,
            ...allocatedFilters,
          },
        },
      };

    case constants.TOGGLE_TRANSFER_COURSES:
      return {
        ...state,
        ...(payload as CourseItemsAfterChange),
      };

    case constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_START:
      const unallocatedCoursesParams = payload as CoursesTransferRequestParams;

      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          ...getCoursesLoading(
            unallocatedCourses.meta.currentPage,
            unallocatedCoursesParams.page
          ),
        },
      };

    case constants.REQUEST_ALL_TRANSFER_UNALLOCATED_COURSES_START:
      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          isLoading: true,
        },
      };

    case constants.REQUEST_ALL_TRANSFER_ALLOCATED_COURSES_START:
      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          isLoading: true,
        },
      };

    case constants.SELECT_ALL_TRANSFER_UNALLOCATED_COURSES:
      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          isSelectedAll: true,
        },
      };

    case constants.SELECT_ALL_TRANSFER_ALLOCATED_COURSES:
      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          isSelectedAll: true,
        },
      };

    case constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_SUCCESS:
      const courses = payload as ConcatCoursesTransferModel;

      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          data: courses.concat
            ? [...unallocatedCourses.data, ...courses.data]
            : courses.data,
          meta: courses.meta,
          isPaginating: false,
          isSelectedAll: false,
          isLoading: false,
          isPending: false,
        },
      };

    case constants.REQUEST_TRANSFER_UNALLOCATED_COURSES_ERROR:
      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          isPending: false,
          isPaginating: false,
          isLoading: false,
        },
      };

    case constants.REQUEST_TRANSFER_ALLOCATED_COURSES_START:
      const allocatedCoursesParams = payload as CoursesTransferRequestParams;

      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          ...getCoursesLoading(
            allocatedCourses.meta.currentPage,
            allocatedCoursesParams.page
          ),
        },
      };

    case constants.REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS: {
      const courses = payload as ConcatCoursesTransferModel;

      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          data: courses.concat
            ? [...allocatedCourses.data, ...courses.data]
            : courses.data,
          meta: courses.meta,
          isSelectedAll: false,
          isPaginating: false,
          isLoading: false,
          isPending: false,
        },
      };
    }

    case constants.REQUEST_TRANSFER_ALLOCATED_COURSES_ERROR:
      return {
        ...state,
        allocated: {
          ...allocatedCourses,
          isPending: false,
          isPaginating: false,
          isLoading: false,
        },
      };

    case constants.TOGGLE_TRANSFER_COURSES_PENDING: {
      return {
        ...state,
        unallocated: {
          ...unallocatedCourses,
          isPending: payload as boolean,
        },
        allocated: {
          ...allocatedCourses,
          isPending: payload as boolean,
        },
      };
    }

    case constants.SET_TRANSFER_COURSES_TOTAL_COURSES: {
      return {
        ...state,
        totalCourses: payload as number,
      };
    }

    case constants.CLEAR_TRANSFER_COURSES:
      return initialTransferCoursesState;

    default:
      return state;
  }
};
