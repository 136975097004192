import { EducationStreamModel } from './educationStreams.model';
import { GTMEventFilterModel } from '../../models';

export enum EducationStreamsAnalytics {
  clickButtonCompleteRegistration = 'click_button_complete_registration',
  clickButtonSkipDirectionsSelection = 'click_button_skip_directions_selection',
  addedUserDirections = 'added_user_directions',
  userProperties = 'user_properties',
  sphere = 'sphere',
  eventRegistration = 'event_registration',
}

export const getSelectedEducationStreamsPageData = (
  educationStreams: EducationStreamModel[]
): GTMEventFilterModel => {
  const educationStreamsTitle = educationStreams.map((stream) => stream.title);

  return {
    event: EducationStreamsAnalytics.addedUserDirections,
    event_type: EducationStreamsAnalytics.userProperties,
    event_category: EducationStreamsAnalytics.sphere,
    event_param: educationStreamsTitle.join(','),
  };
};
