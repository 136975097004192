import { cookieService } from '../../services';
import { IS_TELEGRAM } from './device.constants';
import { NextPageContext } from 'next';

export const checkIsTelegram = (ctx: NextPageContext): boolean => {
  const hasTelegramQuery = !!(ctx.query.isTelegram as string);

  return (
    !!cookieService.getCookie(IS_TELEGRAM, ctx.req?.headers.cookie || '') ||
    hasTelegramQuery
  );
};
