export enum UTM {
  source = 'utm_source',
  medium = 'utm_medium',
  campaign = 'utm_campaign',
  content = 'utm_content',
  term = 'utm_term',
}

export enum UTMSource {
  google = 'google',
  yandex = 'yandex',
  direct = 'direct',
}

export enum UTMMedium {
  organic = 'organic',
  referral = 'referral',
}

export const UTMNone = 'none';

export type UTMModel = {
  [key: string]: string | string[] | undefined;
};
