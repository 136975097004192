import styled from 'styled-components';
import { fontSettings } from '../../../styles';
import { ButtonStyled } from '../../button';

export const EducationStreamsPopupStyled = styled.div`
  ${ButtonStyled} {
    width: 100%;
  }
`;

export const EducationStreamsPopupTitleStyled = styled.p`
  font-weight: 700;
  font-size: ${({ theme }): string => theme.FONT.SIZE_28}px;
  line-height: ${({ theme }): string => theme.FONT.LINE_HEIGHT_34}px;
  text-align: center;
  margin: 0;
`;

export const EducationStreamsPopupSubTitleStyled = styled.p`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  text-align: center;
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const EducationStreamsPopupWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
`;
