import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconMastercardComponent = ({
  width = 18,
  height = 20,
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)">
      <path d="M10.868 6.07H6.493v7.862h4.375V6.07Z" fill="#FF5F00" />
      <path
        d="M6.771 10c0-1.597.75-3.014 1.903-3.93a4.997 4.997 0 1 0-.001 7.86A4.993 4.993 0 0 1 6.771 10Z"
        fill="#EB001B"
      />
      <path
        d="M16.771 10a4.996 4.996 0 0 1-8.083 3.93A4.972 4.972 0 0 0 10.59 10c0-1.597-.75-3.014-1.903-3.93A4.97 4.97 0 0 1 11.77 5a5.007 5.007 0 0 1 5.001 5Z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h17v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
