import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconRadiobuttonCheckedComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
  fillColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
    >
      <circle cx="12" cy="12" r="8" stroke={strokeColor} strokeWidth="2" />
      <circle cx="12" cy="12" r="5" fill={fillColor} />
    </svg>
  );
};
