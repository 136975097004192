import {
  CreateUserApiValidationErrors,
  CreateUserValidationErrors,
} from './createUser.model';

const snakeCaseCamelize = (str: string): string =>
  str.replace(/_./g, (x: string) => x[1].toUpperCase());

const removeSquareBrackets = (str: string): string =>
  str.replace(/^[\[]|[\]]$/g, '');

export function mapRegisterValidationErrors(
  errors: CreateUserApiValidationErrors = {}
): CreateUserValidationErrors {
  return Object.keys(errors).reduce((acc, key) => {
    const bracketsRemovedKey = removeSquareBrackets(key);
    acc[snakeCaseCamelize(bracketsRemovedKey)] = errors[key].join(' ');

    return acc;
  }, {} as CreateUserValidationErrors);
}
