import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCoinsGradientComponent = ({
  width = 40,
  height = 40,
  strokeWidth = '3.333',
}: IconProps): ReactElement => (
  <svg height={height} width={width} viewBox="0 0 40 40" fill="none">
    <path
      stroke="url(#paint0_linear_1748_59985)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M20 13.334c8.284 0 15-2.24 15-5 0-2.762-6.716-5-15-5-8.284 0-15 2.238-15 5 0 2.76 6.716 5 15 5z"
    ></path>
    <path
      stroke="url(#paint1_linear_1748_59985)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M35 20c0 2.767-6.667 5-15 5S5 22.767 5 20"
    ></path>
    <path
      stroke="url(#paint2_linear_1748_59985)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M5 8.334v23.333c0 2.767 6.667 5 15 5s15-2.233 15-5V8.333"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_1748_59985"
        x1="5"
        x2="23.066"
        y1="9.959"
        y2="-6.301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1748_59985"
        x1="5"
        x2="12.712"
        y1="23.313"
        y2="9.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_1748_59985"
        x1="5"
        x2="34.703"
        y1="27.104"
        y2="17.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
    </defs>
  </svg>
);
