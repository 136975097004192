import styled, { css } from 'styled-components';
import { FilterTabStyledModel } from './filterTab.model';
import { fontSettings, transition } from '@lerna-core';

export const FilterTabStyled = styled.div<FilterTabStyledModel>`
  padding: 12px 20px;
  box-sizing: border-box;
  font-weight: 500;
  cursor: pointer;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 100px;

  ${transition('background-color,color')}
  ${({ theme, isActive, activeColor }): ReturnType<typeof css> =>
    isActive
      ? css`
          background-color: ${activeColor
            ? activeColor
            : theme.COLOR.PURPLE_SUBSCRIPTION};
          color: ${theme.COLOR.WHITE};
          pointer-events: none;
        `
      : css`
          background-color: ${theme.COLOR.BLACK_4};
          color: ${theme.COLOR.TEXT_DARK_COLOR};

          &:hover {
            background-color: ${theme.COLOR.BLACK_08};
          }
        `}
    &:last-child {
    margin-right: 0;
  }
`;
