import * as constants from './filters.constants';
import { TranslationModel } from '../../models';
import { FiltersModel } from './filters.model';
import { ParsedUrlQuery } from 'querystring';

export type HandleTriggerFilterOption = {
  type: string;
  filterSlug: string;
  optionSlug: string;
  newValue: boolean;
};

export type HandleTriggerFilterRange = {
  type: string;
  filterSlug: string;
  fromValue: string;
  toValue: string;
  translations?: TranslationModel;
};

export type SetFiltersOptionsState = {
  type: string;
  filters: FiltersModel[];
  query: ParsedUrlQuery;
};

export type HandleTriggerAllFilterOption = {
  type: string;
  filterSlug: string;
  newValue: boolean;
};

export type HandleClearFilters = {
  type: string;
};

export type HandleClearFilter = {
  type: string;
  filterSlug: string;
  filterType: string;
  translations?: TranslationModel;
};

export type HandleOpenFilters = {
  type: string;
  isOpened: boolean;
};

export type FilterActions = HandleTriggerFilterOption &
  SetFiltersOptionsState &
  HandleTriggerAllFilterOption &
  HandleTriggerFilterRange &
  HandleClearFilters &
  HandleClearFilter &
  HandleOpenFilters;

/**
 * Redux event for handling "Select all" option checked value change
 * @param filterSlug - changed filter slug
 * @param newValue - new value for "Select all" value
 * @return {HandleTriggerAllFilterOption} - Redux event
 */
export const handleTriggerAllFilterOption = (
  filterSlug: string,
  newValue: boolean
): HandleTriggerAllFilterOption => {
  return {
    type: constants.TRIGGER_ALL_FILTER_OPTION,
    filterSlug,
    newValue,
  };
};

/**
 * Redux event for handling option checked value change
 * @param filterSlug - changed filter slug
 * @param newValue - new value for "Select all" value
 * @param optionSlug - changed option slug
 * @return {HandleTriggerFilterOption} - Redux event
 */
export const handleTriggerFilterOption = (
  filterSlug: string,
  optionSlug: string,
  newValue: boolean
): HandleTriggerFilterOption => {
  return {
    type: constants.TRIGGER_FILTER_OPTION,
    filterSlug,
    optionSlug,
    newValue,
  };
};

/**
 * Redux event for updating all filters state while request data got
 * @param filters - new collection of filters and their options values
 * @param query - ParsedUrlQuery
 * @return {SetFiltersOptionsState} - Redux event
 */
export const setFilterOptionsState = (
  filters: FiltersModel[],
  query: ParsedUrlQuery
): SetFiltersOptionsState => {
  return {
    type: constants.SET_FILTERS_OPTIONS_STATE,
    filters,
    query,
  };
};

/**
 * Redux event for handling range value change
 * @param filterSlug - changed filter slug
 * @param fromValue - new value for from value
 * @param toValue - changed option slug
 * @param translations - translations object
 * @return {HandleTriggerFilterOption} - Redux event
 */
export const handleTriggerFilterRange = (
  filterSlug: string,
  fromValue: string,
  toValue: string,
  translations?: TranslationModel
): HandleTriggerFilterRange => {
  return {
    type: constants.TRIGGER_FILTER_RANGE,
    filterSlug,
    fromValue,
    toValue,
    translations,
  };
};

/**
 * Redux event for handling clear filters
 * @return {HandleTriggerFilterOption} - Redux event
 */
export const handleClearFilters = (): HandleClearFilters => {
  return {
    type: constants.TRIGGER_CLEAR_FILTERS,
  };
};

/**
 * Redux event for handling clear filter
 * @return {HandleTriggerFilterOption} - Redux event
 */
export const handleClearFilter = (
  filterSlug: string,
  filterType: string,
  translations?: TranslationModel
): HandleClearFilter => {
  return {
    type: constants.TRIGGER_CLEAR_FILTER,
    filterSlug,
    filterType,
    translations,
  };
};

/**
 * Redux event for handling open/close filters
 * @return {HandleTriggerFilterOption} - Redux event
 */
export const handleOpenFilters = (isOpened: boolean): HandleOpenFilters => {
  return {
    type: constants.TRIGGER_OPEN_FILTERS,
    isOpened,
  };
};
