import {
  mainPath,
  MenuSwitcherEvents,
  MenuSwitcherLinkModel,
  MenuSwitcherPositions,
  personalPath,
  projectSubDomains,
  TranslationModel,
  urlService,
} from '@lerna-core';

export const coursesHeadTopLinks = (
  translations: TranslationModel,
  coursesCount: number
): MenuSwitcherLinkModel[] => [
  {
    title: translations.catalog,
    href: urlService.getFullUrlFromToPage(mainPath.courses),
    isActive: true,
    analyticsEvent: MenuSwitcherEvents.goToCatalog,
    analyticsEventPosition: MenuSwitcherPositions.catalogHeader,
  },
  {
    title: translations.my_education,
    count: coursesCount,
    href: urlService.getFullUrlToSubDomain(
      projectSubDomains.personal,
      personalPath.courses
    ),
    isActive: false,
    analyticsEvent: MenuSwitcherEvents.goToPersonal,
    analyticsEventPosition: MenuSwitcherPositions.catalogHeader,
  },
];
