import Link from 'next/link';
import React, { ReactElement, useMemo } from 'react';
import { EMPTY, projectSubDomains } from '../../../../constants';
import { publicConfig, stringService } from '../../../../services';
import { PointsBoxContainer } from '../../../pointsBox';
import { TooltipComponent } from '../../../tooltip';

import { useTranslations } from '../../../translations';
import { UserAvatarSize } from '../../userAvatar';
import { EditFormButtonContainer } from '../../userEdit';
import { InfoPropContainer } from '../infoProp';
import {
  UserInfoContainerStyled,
  UserInfoHeadAvatarStyled,
  UserInfoHeadStyled,
  UserInfoNameStyled,
  UserInfoParamsContainerStyled,
  UserInfoParamsTitleStyled,
  UserInfoTitleEditContainerStyled,
} from '../userInfo.styled';
import { InfoPersonalContainerProps } from './infoPersonal.model';
import {
  EditButtonWrapper,
  InvitedDateStyled,
  InvitedDateWrapperStyled,
  LastVisitStyled,
  TariffStyled,
} from './infoPersonal.styled';
import { useUser } from '../../user.hook';

export const InfoPersonalContainer = ({
  personal,
  career,
  points,
  allowEdit,
  handleClick,
  isMobile,
  pointsHref,
  tariffHref,
  mediaDomain,
  ImpersonateButton,
  AnonymousEmail,
  invitedAt,
}: InfoPersonalContainerProps): ReactElement => {
  const translations = useTranslations();
  const isTemporaryUser = Boolean(invitedAt);
  const { user } = useUser();

  const isWithoutPersonalDataUpdate = user?.career?.isWithoutPersonalDataUpdate;
  const fio = `${personal.firstName} ${personal.patronymic || ''} ${
    personal.lastName
  }`.trim();

  const pointsLabel = useMemo(() => {
    if (typeof points === 'number') {
      return stringService.pluralTranslations(points, [
        translations.bonuses_points_label_1,
        translations.bonuses_points_label_2,
        translations.bonuses_points_label_many,
      ]);
    }
  }, []);

  const showEditButton =
    allowEdit &&
    typeof handleClick === 'function' &&
    !isWithoutPersonalDataUpdate;

  const editButton = !isMobile && showEditButton && (
    <EditButtonWrapper>
      <EditFormButtonContainer handleClick={handleClick} />
    </EditButtonWrapper>
  );

  const mobileEditButton = isMobile && showEditButton && (
    <EditButtonWrapper margin="20px 0">
      <EditFormButtonContainer handleClick={handleClick} />
    </EditButtonWrapper>
  );

  const lastVisit = !showEditButton &&
    publicConfig?.APP_TYPE !== projectSubDomains.personal &&
    personal.lastActivityAt && (
      <LastVisitStyled>
        {`${translations.last_visit}: ${personal.lastActivityAt}`}
      </LastVisitStyled>
    );

  const tariff = !showEditButton &&
    publicConfig?.APP_TYPE !== projectSubDomains.personal &&
    career.tariff?.uuid && (
      <TariffStyled>
        {translations.tariff}:{' '}
        {tariffHref ? (
          <Link href={tariffHref}>{career.tariff.title}</Link>
        ) : (
          <b>{career.tariff.title}</b>
        )}
      </TariffStyled>
    );

  const B2CPoints = !isMobile &&
    typeof points === 'number' &&
    pointsLabel &&
    !isTemporaryUser &&
    !career.isListingHidden &&
    !career.isListingWithBenefitsOnly && (
      <PointsBoxContainer
        count={points}
        label={pointsLabel}
        href={pointsHref}
      />
    );

  const mobileB2CPoints = isMobile &&
    typeof points === 'number' &&
    pointsLabel &&
    !isTemporaryUser &&
    !career.isListingHidden &&
    !career.isListingWithBenefitsOnly && (
      <PointsBoxContainer
        count={points}
        label={pointsLabel}
        href={pointsHref}
      />
    );

  return (
    <UserInfoContainerStyled>
      <UserInfoHeadStyled>
        <UserInfoHeadAvatarStyled
          isTemporaryUser={isTemporaryUser}
          mediaDomain={mediaDomain}
          personal={personal}
          size={isMobile ? UserAvatarSize.xbig : UserAvatarSize.large}
        />

        <UserInfoTitleEditContainerStyled>
          {fio && <UserInfoNameStyled>{fio}</UserInfoNameStyled>}
          {editButton}
          {lastVisit}
          {tariff}
        </UserInfoTitleEditContainerStyled>

        {B2CPoints}
      </UserInfoHeadStyled>
      {mobileEditButton}
      {mobileB2CPoints}
      <UserInfoParamsTitleStyled margin={isMobile ? '20px 0' : '32px 0 20px'}>
        {translations.customer_profile_general_info_title}
      </UserInfoParamsTitleStyled>

      <UserInfoParamsContainerStyled>
        <InfoPropContainer
          title={translations.customer_profile_email_title}
          value={personal.email}
        />
        <InfoPropContainer
          title={translations.customer_profile_phone_title}
          value={personal.phone || EMPTY}
        />
        {personal.registeredAt && (
          <InfoPropContainer
            title={translations.registration_date}
            value={personal.registeredAt}
          />
        )}
        {invitedAt && (
          <InvitedDateWrapperStyled>
            <TooltipComponent title={translations.inactive_student}>
              <label>{translations.invited_at_date}</label>

              <InvitedDateStyled>
                {invitedAt} <span />
              </InvitedDateStyled>
            </TooltipComponent>
          </InvitedDateWrapperStyled>
        )}
        {ImpersonateButton}
      </UserInfoParamsContainerStyled>
      {AnonymousEmail}
    </UserInfoContainerStyled>
  );
};
