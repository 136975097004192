import React, { ReactElement } from 'react';
import { StepperItemProps } from './stepperItem.model';
import {
  StepperItemCountStyled,
  StepperItemStyled,
  StepperItemTitleStyled,
} from './stepperItem.styled';

export const StepperItemContainer = ({
  title,
  index,
  isActive,
  beforeActive,
  handleClick,
  isClickable,
}: StepperItemProps): ReactElement => {
  const onClick = (): void => {
    handleClick && isClickable && handleClick();
  };

  return (
    <StepperItemStyled
      isActive={isActive}
      beforeActive={beforeActive}
      isClickable={isClickable}
      onClick={onClick}
    >
      <StepperItemCountStyled isActive={isActive} beforeActive={beforeActive}>
        {index}
      </StepperItemCountStyled>
      <StepperItemTitleStyled>{title}</StepperItemTitleStyled>
    </StepperItemStyled>
  );
};
