import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconIdentityCardComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#FBF7FF',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99998 4C6.87365 4 6.74937 4.00699 6.62628 4.01953C6.38097 4.04453 6.14277 4.09422 5.91404 4.16536C4.76479 4.52283 3.85614 5.43148 3.49868 6.58073C3.42753 6.80946 3.37785 7.04765 3.35284 7.29297C3.3528 7.2934 3.35289 7.29384 3.35284 7.29427C3.34039 7.41694 3.33331 7.54077 3.33331 7.66667V9V24.3333C3.33331 26.3465 4.9868 28 6.99998 28H25C27.0132 28 28.6666 26.3465 28.6666 24.3333V9V7.66667C28.6666 7.5404 28.6598 7.41608 28.6471 7.29297C28.6221 7.04765 28.5724 6.80946 28.5013 6.58073C28.1438 5.43148 27.2352 4.52283 26.0859 4.16536C25.8572 4.09422 25.619 4.04453 25.3737 4.01953C25.3733 4.01949 25.3728 4.01957 25.3724 4.01953C25.2497 4.00693 25.1258 4 25 4H6.99998ZM6.99998 6C7.55198 6 7.99998 6.448 7.99998 7C7.99998 7.552 7.55198 8 6.99998 8C6.44798 8 5.99998 7.552 5.99998 7C5.99998 6.448 6.44798 6 6.99998 6ZM10.3333 6C10.8853 6 11.3333 6.448 11.3333 7C11.3333 7.552 10.8853 8 10.3333 8C9.78131 8 9.33331 7.552 9.33331 7C9.33331 6.448 9.78131 6 10.3333 6ZM14.3333 6H25C25.552 6 26 6.448 26 7C26 7.552 25.552 8 25 8H14.3333C13.7813 8 13.3333 7.552 13.3333 7C13.3333 6.448 13.7813 6 14.3333 6ZM5.33331 10H26.6666V24.3333C26.6666 25.2655 25.9321 26 25 26H6.99998C6.06783 26 5.33331 25.2655 5.33331 24.3333V10ZM16 12.6667C15.2927 12.6667 14.6145 12.9476 14.1144 13.4477C13.6143 13.9478 13.3333 14.6261 13.3333 15.3333C13.3333 16.0406 13.6143 16.7189 14.1144 17.219C14.6145 17.719 15.2927 18 16 18C16.7072 18 17.3855 17.719 17.8856 17.219C18.3857 16.7189 18.6666 16.0406 18.6666 15.3333C18.6666 14.6261 18.3857 13.9478 17.8856 13.4477C17.3855 12.9476 16.7072 12.6667 16 12.6667ZM12.2982 19.3333C11.7648 19.3333 11.3333 19.7648 11.3333 20.2982V21.168C11.3333 22.65 13.2246 24 16 24C18.7753 24 20.6666 22.65 20.6666 21.168V20.2982C20.6666 19.7648 20.2351 19.3333 19.7018 19.3333H12.2982Z"
      fill={strokeColor}
    />
  </svg>
);
