import styled from 'styled-components';
import { LoaderStyledContainer } from '@lerna-core';

export const SimilarItemEmptyContainerStyled = styled.div`
  padding: 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  ${LoaderStyledContainer} {
    height: 100%;
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    border-radius: 16px;
    box-shadow: 0px 5px 25px -5px rgb(12 11 13 / 4%);
  }
`;
