import React, { ReactElement } from 'react';
import { ContentInfoStyled } from './contentInfo.styled';
import { useCustomCompensationFormContext } from '../../customCompensationForm.context';

export const ContentInfoContainer = (): ReactElement => {
  const { translations } = useCustomCompensationFormContext();

  return (
    <ContentInfoStyled>
      {translations?.compensation_b2b_form_info_1}
    </ContentInfoStyled>
  );
};
