import styled from 'styled-components';
import { fontSettings, transition } from '@lerna-core';
import { rgba } from 'polished';
import { ResetItemStyledProps } from './resetItem.model';

export const ResetItemStyled = styled.div<ResetItemStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 12px;
  box-sizing: border-box;
  font-weight: 500;
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)}
  ${transition('background-color')}
    ${({ isMobileType, customFilterColor, theme }): string =>
    isMobileType
      ? `
    color: ${theme.COLOR.WHITE};
    background-color: ${
      customFilterColor ? customFilterColor : theme.COLOR.PRIMARY[500]
    };
  `
      : `
    &:hover {
      background-color: ${rgba(theme.COLOR.BLACK, 0.08)};
    }
  `}
`;

export const IconStyled = styled.div`
  margin-left: 4px;
  display: flex;
`;
