import { css } from 'styled-components';

export const getDisabledStyles = (
  disabled: boolean
): ReturnType<typeof css> => {
  if (disabled) {
    return css`
      pointer-events: none;
      color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
    `;
  }

  return css``;
};
