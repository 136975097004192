import {
  ApiSubscriptionAvailabilityModel,
  SubscriptionAvailabilityModel,
} from './subscriptionAvailability.model';
import { publicConfig, SecureRequestClient } from '@lerna-core';
import { subscriptionAvailabilityAdapter } from './subscriptionAvailability.adapter';

export class SubscriptionAvailabilityClient extends SecureRequestClient<
  null,
  ApiSubscriptionAvailabilityModel,
  SubscriptionAvailabilityModel
> {
  protected path = '';
  protected requestParameters: null;
  protected adapter = subscriptionAvailabilityAdapter;

  constructor(courseUuid: string) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = null;
    this.path = `user-api/v1/catalog/courses/${courseUuid}/subscription-availability`;
  }
}
