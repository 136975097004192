import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconInProgressComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '1.5',
  fillColor = '#005DAD',
  strokeColor = '#fff',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill={fillColor} />
      <path
        d="M14.3535 15.6464L12.8535 14.1464C12.7105 14.0034 12.496 13.9609 12.3085 14.0379C12.1215 14.1154 12 14.2979 12 14.4999V17.4999C12 17.7019 12.1215 17.8844 12.3085 17.9619C12.3705 17.9874 12.4355 17.9999 12.5 17.9999C12.63 17.9999 12.758 17.9489 12.8535 17.8534L14.3535 16.3534C14.549 16.1579 14.549 15.8419 14.3535 15.6464Z"
        fill="white"
      />
      <path
        d="M9.64662 8.3535L11.1466 9.8535C11.2896 9.9965 11.5041 10.039 11.6916 9.962C11.8786 9.8845 12.0001 9.7025 12.0001 9.5V6.5C12.0001 6.298 11.8786 6.1155 11.6916 6.038C11.6296 6.0125 11.5646 6 11.5001 6C11.3701 6 11.2421 6.051 11.1466 6.1465L9.64662 7.6465C9.45112 7.842 9.45112 8.1585 9.64662 8.3535Z"
        fill="white"
      />
      <path
        d="M15.644 13.644C15.8705 13.142 16 12.5865 16 12C16 9.791 14.209 8 12 8"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.356 10.356C8.1295 10.858 8 11.4135 8 12C8 14.209 9.791 16 12 16"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
