import { CreateCustomer, CreateCustomerParams } from './createCustomer.model';
import { createCustomerProvider } from './createCustomer.clientProvider';

export function postCustomer({
  uuid,
  company_uuid,
  position_uuid,
  department_uuid,
}: CreateCustomerParams): Promise<CreateCustomer> {
  return createCustomerProvider({
    uuid: uuid,
    company_uuid: company_uuid,
    department_uuid: department_uuid,
    position_uuid: position_uuid,
  });
}
