import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSuccessComponent = ({
  width = 48,
  height = 48,
  fillColor = '#000',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill={fillColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.4571 16.5429C34.8476 16.9334 34.8476 17.5666 34.4571 17.9571L20.9571 31.4571C20.5666 31.8476 19.9334 31.8476 19.5429 31.4571L13.5429 25.4571C13.1524 25.0666 13.1524 24.4334 13.5429 24.0429C13.9334 23.6524 14.5666 23.6524 14.9571 24.0429L20.25 29.3358L33.0429 16.5429C33.4334 16.1524 34.0666 16.1524 34.4571 16.5429Z"
      fill="white"
    />
  </svg>
);
