export type TableDoubleSortContainerProps = {
  title: string;
  onChange: (sort: SortOrder) => void;
  initialSort?: SortOrder;
};

export enum SortOrder {
  asc = 'ASC',
  desc = 'DESC',
}
