import React, { ReactElement } from 'react';
import { FormB2BWithCompensationContainerProps } from './formB2BWithCompensation.model';
import { CustomCompensationFormContainer } from './customCompensationForm';
import { CompensationFormContainer } from './compensationForm';

export const FormB2BWithCompensationContainer = (
  props: FormB2BWithCompensationContainerProps
): ReactElement => {
  const showCustomCompensationForm =
    props.course.isCustomCompensationAvailable &&
    !(props.hasCustomerFullCompensation || props.hasCustomerCompensation);

  return showCustomCompensationForm ? (
    <CustomCompensationFormContainer {...props} />
  ) : (
    <CompensationFormContainer {...props} />
  );
};
