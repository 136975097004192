import { mapImageResource } from '../image';
import { Company, CompanyApi, CompanyApiModel } from './company.model';
import { mapCustomer } from '../customer';
import { dateService } from '../../services';

export const mapCompany = ({
  uuid,
  name,
  slug,
  logo,
  legal_name,
  number_of_employees,
  unique_taxpayer_reference,
  manager,
  active_employees_count,
  created_at,
  is_joining_bonus_points_disabled,
  is_bonus_points_hidden_on_listing,
  is_prices_hidden_on_listing_cards,
}: CompanyApi): Company => ({
  uuid,
  name,
  slug,
  logo: logo?.data ? mapImageResource(logo.data) : null,
  manager: manager ? mapCustomer(manager) : null,
  legalName: legal_name,
  numberOfEmployees: number_of_employees,
  uniqueTaxpayerReference: unique_taxpayer_reference,
  activeEmployeesCount: active_employees_count,
  createdAt: created_at ? dateService.formatDate(created_at) : null,
  isJoiningBonusPointsDisabled: is_joining_bonus_points_disabled,
  isBonusPointsHiddenOnListing: is_bonus_points_hidden_on_listing,
  isPricesHiddenOnListingCards: is_prices_hidden_on_listing_cards,
});

export const companyAdapter = (response: CompanyApiModel): Company => {
  const company = response.data.data;

  return mapCompany(company);
};
