import { FaqActions } from './faq.actions';
import * as constants from './faq.constants';
import { Faq, FaqState } from './faq.model';

const initialState: FaqState = {
  faq: [],
  isLoading: false,
};

export function faqReducer(
  state: FaqState = initialState,
  action: FaqActions
): FaqState {
  switch (action.type) {
    case constants.REQUEST_FAQ_START:
      return { ...state, isLoading: true };

    case constants.REQUEST_FAQ_SUCCESS:
      const response = action.payload as Faq[];

      return {
        ...state,
        faq: response.sort((a, b) => a.sortOrder - b.sortOrder),
        isLoading: false,
      };

    case constants.REQUEST_FAQ_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
