import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconGiftComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#FFFFFF',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 2C10.2854 2 8.33333 3.95204 8.33333 6.33333C8.33333 7.21003 8.66271 7.98232 9.11589 8.66667H8.33333C5.95204 8.66667 4 10.6187 4 13V25C4 27.3813 5.95204 29.3333 8.33333 29.3333H23.6667C26.048 29.3333 28 27.3813 28 25V13C28 10.6187 26.048 8.66667 23.6667 8.66667H22.8841C23.3373 7.98232 23.6667 7.21003 23.6667 6.33333C23.6667 3.95204 21.7146 2 19.3333 2C17.9677 2 16.7961 2.68165 16 3.67839C15.2039 2.68165 14.0323 2 12.6667 2ZM12.6667 4C13.9667 4 15 5.03329 15 6.33333V8.66667H12.6667C11.3666 8.66667 10.3333 7.63337 10.3333 6.33333C10.3333 5.03329 11.3666 4 12.6667 4ZM19.3333 4C20.6334 4 21.6667 5.03329 21.6667 6.33333C21.6667 7.63337 20.6334 8.66667 19.3333 8.66667H17V6.33333C17 5.03329 18.0333 4 19.3333 4ZM8.33333 10.6667H12.6667H15V18H6V13C6 11.7 7.03329 10.6667 8.33333 10.6667ZM17 10.6667H19.3333H23.6667C24.9667 10.6667 26 11.7 26 13V18H17V10.6667ZM6 20H15V27.3333H8.33333C7.03329 27.3333 6 26.3 6 25V20ZM17 20H26V25C26 26.3 24.9667 27.3333 23.6667 27.3333H17V20Z"
      fill={strokeColor}
    />
  </svg>
);
