import { ChangeEvent, ReactElement } from 'react';
import { useTranslations } from '../../translations';
import { FormCheckbox } from '../formInputs';
import { FormAgreementTreeModel } from './formAgreementTree.model';
import * as S from './formAgreementTree.styled';
import { FormAgreementTextStyled } from '../formAgreement';

export const FormAgreementTreeContainer = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  setErrors,
  setTouched,
  handleChange,
  fieldsHtml,
}: FormAgreementTreeModel): ReactElement => {
  const translations = useTranslations();

  const hasAdvConsentError = !!(errors.advConsent && touched.advConsent);
  const hasPrivacyError = !!(errors.privacy && touched.privacy);
  const hasErrors = hasPrivacyError;
  const handleChangeCheckAllAgreements = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { advConsent, privacy, ...nextErrors } = errors;
    const { advConsent: _, privacy: __, ...nextTouched } = touched;
    setErrors(nextErrors);
    setTouched(nextTouched);
    setFieldValue('advConsent', event.target.checked);
    setFieldValue('privacy', event.target.checked);
  };

  return (
    <S.FormAgreementTree hasErrors={hasErrors}>
      <S.FormAgreementTreeCheckAll>
        <FormCheckbox
          checked={values.advConsent && values.privacy}
          name="checkAllAgreements"
          onChange={handleChangeCheckAllAgreements}
          value={translations.check_all}
          disabled={isSubmitting}
        />
      </S.FormAgreementTreeCheckAll>
      <S.FormAgreementTreeRows>
        <S.FormAgreementTreeRow>
          <FormCheckbox
            hasError={hasAdvConsentError && !hasErrors}
            checked={values.advConsent}
            name="advConsent"
            disabled={isSubmitting}
            onChange={handleChange}
            children={
              <FormAgreementTextStyled
                hasError={hasAdvConsentError && !hasErrors}
                dangerouslySetInnerHTML={{
                  __html:
                    fieldsHtml?.advConsent ||
                    translations.form_agreement_adv_consent,
                }}
              />
            }
          />
        </S.FormAgreementTreeRow>
        <S.FormAgreementTreeRow>
          <FormCheckbox
            hasError={hasPrivacyError && !hasErrors}
            checked={values.privacy}
            name="privacy"
            disabled={isSubmitting}
            onChange={handleChange}
            children={
              <FormAgreementTextStyled
                hasError={hasPrivacyError && !hasErrors}
                dangerouslySetInnerHTML={{
                  __html:
                    fieldsHtml?.privacy || translations.form_agreement_privacy,
                }}
              />
            }
          />
        </S.FormAgreementTreeRow>
      </S.FormAgreementTreeRows>
    </S.FormAgreementTree>
  );
};
