export enum RequestErrorMessages {
  abort = 'AbortError',
}

export enum RequestHeaders {
  acceptLanguage = 'Accept-Language',
  contentType = 'Content-type',
  cacheControl = 'Cache-Control',
  respondentId = 'X-Respondent-ID',
}
