import React, { ReactElement } from 'react';
import { IconFireComponent, Nullable } from '@lerna-core';
import {
  PricesTotalDiscountStyled,
  PricesTotalDiscountIconStyled,
} from './pricesTotalDiscount.styled';
import { PricesTotalDiscountContainerProps } from './pricesTotalDiscount.model';

//todo: Вынести в общий компонент для карточки курса/форм
export const PricesTotalDiscountContainer = ({
  discount,
  isMegaDiscount,
}: PricesTotalDiscountContainerProps): Nullable<ReactElement> => {
  if (!discount) return null;

  return (
    <PricesTotalDiscountStyled>
      {isMegaDiscount && (
        <PricesTotalDiscountIconStyled>
          <IconFireComponent />
        </PricesTotalDiscountIconStyled>
      )}
      -{discount}
    </PricesTotalDiscountStyled>
  );
};
