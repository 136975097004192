import styled from 'styled-components';
import { fontSettings } from '@lerna-core';
import { HtmlWrapContainerStyled } from '@features/common/htmlWrap';

export const SeoTextContainerStyled = styled.div`
  ${HtmlWrapContainerStyled} {
    > *:first-child {
      margin-top: 0;
    }
  }

  h6 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    font-weight: 500;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  a {
    color: ${({ theme }): string => theme.COLOR.PURPLE};
    font-weight: 500;
    text-decoration: none;
  }

  p,
  li {
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-left: 12px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: ${({ theme }): string =>
          theme.COLOR.TEXT_MEDIUM_COLOR};
      }
    }
  }
`;
