import { SensitiveCookieModel } from './notificationManagement.model';

export const notificationContent = {
  text: 'Сайт использует файлы cookie для обеспечения удобства пользователей сайта, его улучшения, предоставления персонализированных рекомендаций.',
  cancel: 'Отклонить',
  accept: 'Принять',
};

export const sensitiveCookies: SensitiveCookieModel = {
  adv: ['__Secure-OSID', '_tt_enable_cookie', 'OTZ', 'bh', '_ttp', '_gcl_au'],
  analytics: ['_fbp', 'OSID'],
};
