import { useRouter } from 'next/router';
import React, { ReactElement, useMemo } from 'react';
import { QueryParams } from '../../../models';
import { AppService, getSimpleEvent, pushDataLayer } from '../../../services';
import {
  authorizationService,
  serverAuthorizationService,
} from '../../authorization';
import { ButtonChildrenPosition, ButtonContainer } from '../../button';
import { IconGoogleComponent } from '../../icons';
import { GoogleButtonEvents } from './googleButton.analytics';
import { GoogleButtonContainerProps } from './googleButton.model';

export const GoogleButtonContainer = ({
  translations,
}: GoogleButtonContainerProps): ReactElement => {
  const router = useRouter();

  const companyUuid = useMemo(
    () => router.query[QueryParams.company] as string,
    [router.query]
  );

  const loginLink = AppService.isClientSide
    ? authorizationService.getLoginLink(true, companyUuid)
    : serverAuthorizationService.getLoginLink(true, companyUuid, router);

  const handleSendAnalytics = (): void => {
    pushDataLayer(
      getSimpleEvent(
        GoogleButtonEvents.clickSignInWithGoogle,
        GoogleButtonEvents.eventRegistration
      )
    );
  };

  return (
    <ButtonContainer
      isNativeLink
      href={loginLink}
      childrenPosition={ButtonChildrenPosition.left}
      title={translations?.with_help_google}
      onClick={handleSendAnalytics}
    >
      <IconGoogleComponent />
    </ButtonContainer>
  );
};
