import { CookieTypes } from '../../cookiesNotification.model';
import { ManagementPopupFormInitialValues } from './managementPopup.model';
import { CookiePermissionsModel } from '../notificationManagement.model';
import { Nullable } from '../../../../models';

export const getFormInitialValues = (
  permissions: Nullable<CookiePermissionsModel>
): ManagementPopupFormInitialValues => ({
  [CookieTypes.technical]: permissions?.technical || true,
  [CookieTypes.advertising]: permissions?.advertising || false,
  [CookieTypes.analytics]: permissions?.analytics || false,
});
