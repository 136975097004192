import Image from 'next/image';
import React, { ReactElement, useMemo } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  CommonEventPositions,
  getSimpleEvent,
  IconArrowLongComponent,
  Nullable,
  pushDataLayer,
  urlService,
} from '@lerna-core';
import {
  ProfTestContainerStyled,
  ProfTestLinkStyled,
  ProfTestTitleStyled,
} from './coursesProfTest.styled';
import { ProfTestEvent, ProfTestEventType } from './coursesProfTest.analytics';
import { CoursesProfTestContainerProps } from './coursesProfTest.model';
import { getProfTestUrlWithUTM } from './coursesProfTest.helper';

export const CoursesProfTestContainer = ({
  translations,
}: CoursesProfTestContainerProps): Nullable<ReactElement> => {
  const profTestUrl = useMemo(
    () => getProfTestUrlWithUTM(translations?.prof_test_url),
    []
  );

  const onClick = (): void => {
    pushDataLayer(
      getSimpleEvent(
        ProfTestEvent.clickButtonTakeTheTest,
        ProfTestEventType.proforientationTest,
        CommonEventPositions.coursesPageList
      )
    );
  };

  if (!translations?.prof_test_url) {
    return null;
  }

  return (
    <ProfTestContainerStyled>
      <Image
        src="/img/proftest_banner.png"
        width={236}
        height={118}
        alt={translations?.prof_test_title}
      />

      <ProfTestTitleStyled>{translations?.prof_test_title}</ProfTestTitleStyled>

      <ButtonContainer
        size={ButtonSize.normal}
        title={translations?.prof_test_button_label}
        color={ButtonColor.white}
        children={<IconArrowLongComponent />}
      />

      <ProfTestLinkStyled
        onClick={onClick}
        href={profTestUrl}
        {...urlService.setTargetBlankOptions()}
      />
    </ProfTestContainerStyled>
  );
};
