import React, { memo, ReactElement } from 'react';
import {
  ContactsEmailStyled,
  ContactsItemTitleStyled,
  ContactsItemValueStyled,
} from './contactsEmail.styled';
import { FooterLinkContainer } from '../../../footerLink';
import { Nullable } from '../../../../../models';
import { ContactsItemContainerProps } from './contactsEmail.model';

function ContactsEmail({
  value,
  title,
  secondValue,
  analyticsEvent,
}: ContactsItemContainerProps): Nullable<ReactElement> {
  if (!value) return null;

  return (
    <ContactsEmailStyled>
      {title && <ContactsItemTitleStyled>{title}</ContactsItemTitleStyled>}
      <ContactsItemValueStyled>
        <FooterLinkContainer
          link={{
            link: `mailto:${value}`,
            label: value,
          }}
          analyticsEvent={analyticsEvent}
        />
      </ContactsItemValueStyled>
      {secondValue && (
        <ContactsItemValueStyled>
          <FooterLinkContainer
            link={{
              link: `mailto:${secondValue}`,
              label: secondValue,
            }}
            analyticsEvent={analyticsEvent}
          />
        </ContactsItemValueStyled>
      )}
    </ContactsEmailStyled>
  );
}

export const ContactsEmailContainer = memo(ContactsEmail);
