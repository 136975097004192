import { CourseModel, MetaPaginationModel } from '@lerna-core';
import { CourseGalleryItemsModel, GalleryProps } from './courseGallery.model';
import {
  AdditionalCoursesStateEnum,
  AdditionalCoursesStateModel,
} from '../courseSimilar';

/**
 * Generating an array for the course gallery
 * @param {number} total
 * @param {array} courses
 * @return {array} - An array of courses and null if not all courses are matched
 * */
export const setGalleryItems = (
  total: number,
  courses: CourseModel[]
): CourseGalleryItemsModel => {
  const arr = [...courses] as CourseGalleryItemsModel;

  if (courses.length < total) {
    for (let i = 0; i < total - courses.length; i++) {
      arr.push(null);
    }
  }

  return arr;
};

export const getGalleryProps = (isMobile: boolean): GalleryProps => ({
  itemWidth: isMobile ? 'auto' : '50%',
  spaceBetweenItems: isMobile ? 16 : 20,
});

/**
 * Get current page by slider
 * @param {object} pagination
 * @param {number} activeSlideIndex
 * @param {object} additionalCoursesState
 * @return {boolean}
 * */
export const getCurrentPageBySlider = (
  pagination: MetaPaginationModel,
  activeSlideIndex: number
): number => {
  //How many slides earlier should you start loading
  const loadIndexDelta = 3;

  return Math.ceil((activeSlideIndex + loadIndexDelta) / pagination.perPage);
};

/**
 * Check if new courses need to be loaded for the current state of the gallery
 * @param {object} pagination
 * @param {number} activeSlideIndex
 * @param {object} additionalCoursesState
 * @return {boolean}
 * */
export const needToLoadAdditionalCourses = (
  pagination: MetaPaginationModel,
  activeSlideIndex: number,
  additionalCoursesState: AdditionalCoursesStateModel
): boolean => {
  const currentPageBySlider = getCurrentPageBySlider(
    pagination,
    activeSlideIndex
  );

  if (currentPageBySlider <= pagination.totalPages) {
    return (
      additionalCoursesState[currentPageBySlider] !==
      AdditionalCoursesStateEnum.success
    );
  }

  return false;
};
