import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconEnvironmentDesignComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.382 4.175c-.748 0-1.496.282-2.06.845L5.02 9.324a2.932 2.932 0 0 0 0 4.119l6.627 6.628-4.222 4.221c-.35.35-.606.784-.741 1.261L4.215 34.2a1.249 1.249 0 0 0 1.543 1.542l8.645-2.469.001-.001a2.916 2.916 0 0 0 1.258-.74l18.415-18.42c2.264-2.265 2.264-5.973 0-8.238a5.803 5.803 0 0 0-4.118-1.698 5.803 5.803 0 0 0-4.118 1.698l-5.774 5.775L18.5 10.08a1.249 1.249 0 0 0-.278-.278l-3.05-3.051a1.247 1.247 0 0 0-.278-.278L13.441 5.02a2.903 2.903 0 0 0-2.06-.845Zm0 2.48c.104 0 .207.044.294.13l.71.71-.366.366a1.248 1.248 0 1 0 1.765 1.765l.366-.365 1.563 1.563-.366.366a1.248 1.248 0 1 0 1.765 1.765l.366-.366.824.824-4.892 4.893-6.626-6.629a.398.398 0 0 1 0-.588l4.303-4.304a.416.416 0 0 1 .294-.13Zm18.577.002c.85 0 1.697.327 2.351.981h.002a3.31 3.31 0 0 1 0 4.708l-1.614 1.614-4.706-4.708 1.614-1.614a3.317 3.317 0 0 1 2.353-.981Zm-5.732 4.36 4.706 4.708-15.037 15.04a.421.421 0 0 1-.18.107L7.23 32.724l1.853-6.486v-.002a.407.407 0 0 1 .106-.179l15.037-15.04Zm5.782 10.575-1.765 1.766 2.446 2.446-.366.365a1.248 1.248 0 1 0 1.765 1.765l.366-.365.853.853a.398.398 0 0 1 0 .588l-4.303 4.305a.398.398 0 0 1-.588 0l-5.064-5.065-1.765 1.765 5.064 5.065a2.93 2.93 0 0 0 4.118 0l4.303-4.304a2.932 2.932 0 0 0 0-4.119l-1.598-1.598a1.25 1.25 0 0 0-.278-.278l-3.188-3.189Z"
        fill={fillColor}
      />
    </svg>
  );
};
