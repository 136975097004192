import React, { ReactElement } from 'react';

import { FormPopupSubTitleStyled } from './formPopupSubTitle.styled';

type FormPopupSubTitleProps = {
  text?: string;
  children?: ReactElement;
};

export const FormPopupSubTitle = ({
  text,
  children,
}: FormPopupSubTitleProps): ReactElement => {
  return (
    <FormPopupSubTitleStyled>
      {children ? children : text}
    </FormPopupSubTitleStyled>
  );
};
