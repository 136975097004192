import React, { ReactElement, useCallback, useState } from 'react';
import { FormAttemptsProps } from './formAttempts.model';
import { MAX_ATTEMPTS_DEFAULT } from './formAttempts.constants';

export const FormAttemptContainer = ({
  FormElement,
  maxAttempts = MAX_ATTEMPTS_DEFAULT,
}: FormAttemptsProps): ReactElement => {
  const [attemptNumber, setAttemptNumber] = useState<number>(0);
  const hasAttempts = attemptNumber < maxAttempts;

  const handleSuccess = useCallback(() => setAttemptNumber(0), []);
  const handleError = useCallback(
    () =>
      setAttemptNumber((prevAttemptNumber: number) => prevAttemptNumber + 1),
    []
  );
  const handleClose = useCallback(() => setAttemptNumber(0), []);

  return (
    <>
      {FormElement({
        attemptsProps: {
          handleSuccess,
          handleError,
          handleClose,
          hasAttempts,
        },
      })}
    </>
  );
};
