import styled, { css } from 'styled-components';

import { breakpoint, fontSettings } from '../../../styles';
import { TabLinkStyledProps } from './tabItem.model';

const getActiveTabStyles = ({
  isActive,
  theme,
  activeLineGap,
}: TabLinkStyledProps): string =>
  isActive
    ? `
    &::before {
      content: '';
      position: absolute;
      top: calc(100% + ${activeLineGap ? `${activeLineGap}` : '17'}px);
      left: 0;
      width: 100%;
      height: 1px;
      background: ${theme.COLOR.PURPLE};
    }
  `
    : '';

const getActiveTabTitleStyles = ({
  isActive,
  theme,
}: TabLinkStyledProps): string =>
  isActive ? `color: ${theme.COLOR.TEXT_DARK_COLOR};` : '';

const getActiveTabCounterStyles = ({
  isActive,
  theme,
}: TabLinkStyledProps): string =>
  isActive
    ? `
    background-color: ${theme.COLOR.PURPLE};
    color: ${theme.COLOR.WHITE}
  `
    : '';

export const TabStyled = styled.div<TabLinkStyledProps>`
  cursor: pointer;
  position: relative;

  &:not(:last-child) {
    margin-right: 32px;
  }

  ${(props): string => getActiveTabStyles(props)}

  ${breakpoint(
    'mediumDown',
    css`
      &:not(:last-child) {
        margin-right: 20px;
      }
    `
  )}
`;

export const TabWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const TabTitleStyled = styled.div<TabLinkStyledProps>`
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  ${(props): string => getActiveTabTitleStyles(props)}

  ${breakpoint(
    'mediumDown',
    css`
      font-weight: 400;
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
    `
  )}
`;

export const TabCounterStyled = styled.div<TabLinkStyledProps>`
  padding: 4px 12px;
  background-color: rgba(0, 0, 0, 0.08);
  margin-left: 12px;
  border-radius: 8px;
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${(props): string => fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)}
  ${(props): string => getActiveTabCounterStyles(props)}
`;
