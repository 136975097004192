import styled from 'styled-components';

export const DropdownSelectStyled = styled.div`
  position: relative;
  width: fit-content;
`;

export const DropdownSelectOptionStyled = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
