import React, { memo, ReactElement, useCallback } from 'react';

import { FormCheckbox } from '../../form';
import {
  FilterPopupOptionLabelStyled,
  FilterPopupOptionProps,
  FilterPopupOptionStyled,
} from '../filterPopupOption';

function RenderFilterPopupOptionContainer({
  id,
  label,
  active,
  onSelect,
}: FilterPopupOptionProps): ReactElement {
  const handleClick = useCallback((): void => {
    onSelect(id);
  }, [id, onSelect]);

  return (
    <FilterPopupOptionStyled onClick={handleClick}>
      <FormCheckbox checked={active} readOnly />
      <FilterPopupOptionLabelStyled active={active}>
        {label}
      </FilterPopupOptionLabelStyled>
    </FilterPopupOptionStyled>
  );
}

export const FilterPopupOptionContainer = memo(
  RenderFilterPopupOptionContainer
);
