import { cookieService } from '../cookie';
import { USER_LOCALE_COOKIE_NAME } from '../../features';
import { publicConfig } from '../config/config.model';

export const getLocale = (cookie = ''): string => {
  return (
    cookieService.getCookie(USER_LOCALE_COOKIE_NAME, cookie) ||
    publicConfig.DEFAULT_LOCALE
  );
};
