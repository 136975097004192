import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { rgba } from 'polished';

export const ContentLeftStyled = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: 40px;
  flex: 0 1 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0 0 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid
        ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.12)};
    `
  )}
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)}
  font-weight: 600;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)}
    `
  )}
`;
