import {
  AlternateDomainReplacesModel,
  AlternateLinkMetaModel,
  AlternateLinkModel,
  AlternateLinksReplacesModel,
} from './alternateLinks.model';
import { NextRouter } from 'next/router';
import { publicConfig, urlService } from '../../services';

const replaceFastLink = (
  alternateLinksReplaces: AlternateLinksReplacesModel,
  fastLink: string,
  domain: string
): string => {
  return alternateLinksReplaces[fastLink]?.[domain] || fastLink;
};

const replaceDomain = (
  alternateDomainReplaces: AlternateDomainReplacesModel,
  domain: string,
  locale: string
): string => {
  return alternateDomainReplaces[locale] || domain;
};

const getAlternatePath = (
  alternateLinkMeta: AlternateLinkMetaModel,
  alternateLinksReplaces: AlternateLinksReplacesModel,
  coursePagePath: string,
  router: NextRouter
): string => {
  let path = `${!!alternateLinkMeta.path ? `/${alternateLinkMeta.path}` : ''}`;

  if (!!router.query.fastlink) {
    const correctFastLink = replaceFastLink(
      alternateLinksReplaces,
      router.query.fastlink as string,
      alternateLinkMeta.topLevelDomain
    );

    if (alternateLinkMeta.cleanFL) {
      path += router.asPath
        .split('?')[0]
        .replace(coursePagePath, '')
        .replace(router.query.fastlink as string, correctFastLink);
    } else {
      path += router.asPath
        .split('?')[0]
        .replace(router.query.fastlink as string, correctFastLink);
    }
  } else {
    const alternateCatalogPath = !!alternateLinksReplaces?.main?.[
      alternateLinkMeta.topLevelDomain
    ]
      ? `/${alternateLinksReplaces.main[alternateLinkMeta.topLevelDomain]}`
      : '';

    path += `${router.asPath.split('?')[0]}${alternateCatalogPath}`;
  }

  return path;
};

const getAlternateLinkHref = (
  alternateLinkMeta: AlternateLinkMetaModel,
  alternateLinksReplaces: AlternateLinksReplacesModel,
  alternateDomainReplaces: AlternateDomainReplacesModel,
  coursePagePath: string,
  router: NextRouter
): string => {
  const domain = `${replaceDomain(
    alternateDomainReplaces,
    publicConfig.BASE_DOMAIN.split('.')[0],
    alternateLinkMeta.topLevelDomain
  )}.${alternateLinkMeta.topLevelDomain}`;
  const path = getAlternatePath(
    alternateLinkMeta,
    alternateLinksReplaces,
    coursePagePath,
    router
  );

  return `${publicConfig?.BASE_PROTOCOL}//${domain}${path}`;
};

export const getAlternateLinks = (
  alternateLinks: AlternateLinkMetaModel[],
  alternateLinksReplaces: AlternateLinksReplacesModel,
  alternateDomainReplaces: AlternateDomainReplacesModel,
  coursePagePath: string,
  router: NextRouter
): AlternateLinkModel[] => {
  return alternateLinks.map((alternateLinkMeta: AlternateLinkMetaModel) => {
    return {
      href: urlService.normalizeUrl(
        getAlternateLinkHref(
          alternateLinkMeta,
          alternateLinksReplaces,
          alternateDomainReplaces,
          coursePagePath,
          router
        )
      ),
      hreflang: alternateLinkMeta.hreflang,
    };
  });
};
