import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconsCheckComponent = ({
  width = 20,
  height = 20,
  fillColor = '#2E8200',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2264 4.58239C16.0102 4.58884 15.805 4.67925 15.6543 4.83448L7.08922 13.4054L4.34904 10.6633C4.27236 10.5834 4.18052 10.5196 4.07889 10.4757C3.97726 10.4317 3.86789 10.4085 3.75719 10.4073C3.64648 10.4062 3.53666 10.4272 3.43416 10.4691C3.33166 10.511 3.23854 10.5729 3.16025 10.6512C3.08197 10.7296 3.02009 10.8227 2.97824 10.9253C2.9364 11.0279 2.91542 11.1378 2.91655 11.2486C2.91767 11.3593 2.94088 11.4688 2.9848 11.5705C3.02873 11.6722 3.09249 11.7641 3.17235 11.8408L6.50088 15.1716C6.65694 15.3277 6.86856 15.4154 7.08922 15.4154C7.30988 15.4154 7.5215 15.3277 7.67757 15.1716L16.831 6.01196C16.9513 5.89499 17.0334 5.74441 17.0666 5.57994C17.0999 5.41546 17.0827 5.24477 17.0174 5.09024C16.952 4.9357 16.8415 4.80453 16.7004 4.71391C16.5592 4.62329 16.394 4.57745 16.2264 4.58239Z"
      fill={fillColor}
    />
  </svg>
);
