import React, { ReactElement } from 'react';
import { LegendItemModel } from './legendItem.model';
import {
  LegendItemStyled,
  LegendItemColorStyled,
  LegendItemTitleStyled,
} from './legendItem.styled';

export const LegendItemContainer = ({
  title,
  color,
}: LegendItemModel): ReactElement => (
  <LegendItemStyled key={`${title}_${color}`}>
    <LegendItemColorStyled color={color} />
    <LegendItemTitleStyled>{title}</LegendItemTitleStyled>
  </LegendItemStyled>
);
