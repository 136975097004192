import { FaqClient } from './faq.client';
import { Faq } from './faq.model';
import { RequestHeader } from '../../services';

export async function faqClientProvider(
  requestHeaders?: RequestHeader
): Promise<Faq[]> {
  const response = await new FaqClient(requestHeaders).get();

  return response as unknown as Promise<Faq[]>;
}
