import { SubscriptionAvailabilityModel } from './subscriptionAvailability.model';
import { SubscriptionAvailabilityClient } from './subscriptionAvailability.client';

export async function subscriptionAvailabilityProvider(
  courseUuid: string
): Promise<SubscriptionAvailabilityModel> {
  const response = await new SubscriptionAvailabilityClient(courseUuid).get();

  return response as unknown as Promise<SubscriptionAvailabilityModel>;
}
