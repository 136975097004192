import styled from 'styled-components';

import { fontSettings } from '../../../styles';
import { FloatingPlaceholderStyledProps } from './floatingPlaceholder.model';
import { getFloatingPlaceholderColor } from './floatingPlaceholder.mixin';

export const FloatingPlaceholderStyled = styled.label<FloatingPlaceholderStyledProps>`
  display: inline-block;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${({ theme, hasError, disabled, active }): string =>
    getFloatingPlaceholderColor(theme, hasError, disabled, active)};
  top: 27px;
  font-weight: 500;
  position: absolute;
  left: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  border: none;
  border-radius: 4px;
  padding: 0 4px;
  transition: top 0.15s, font-size 0.15s;
  margin: 0;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    right: -4px;
    top: 49%;
    height: 50%;
    z-index: -1;
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
  }

  ${({ active }): string =>
    active
      ? `
        top: -1px;
        font-size: 13px;
    `
      : ''}
`;
