import styled, { css } from 'styled-components';

import { breakpoint, fontSettings } from '../../../styles';

export const NotificationAgreement = styled.div`
  position: fixed;
  bottom: calc(45px + env(safe-area-inset-bottom));
  left: 40px;
  background-color: ${(props): string => props.theme.COLOR.WHITE};
  border-radius: 16px;
  box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.16);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_LAYER};

  ${breakpoint(
    'mediumDown',
    css`
      bottom: calc(40px + env(safe-area-inset-bottom));
      left: 20px;
      right: 20px;
      width: 284px;
      flex-wrap: wrap;
    `
  )}
`;

export const NotificationAgreementText = styled.div`
  padding-right: 16px;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  white-space: pre-wrap;

  ${(props): string => fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)}
  ${breakpoint(
    'mediumDown',
    css`
      padding-right: 0;
      white-space: normal;
      flex: 1 1 100%;
      margin-bottom: 16px;
    `
  )}
    a {
    color: inherit;
  }
`;

export const NotificationAgreementButton = styled.div``;

export const NotificationAgreementLink = styled.span`
  display: block;

  a {
    text-decoration: underline;
  }

  ${breakpoint(
    'mediumDown',
    css`
      display: inline;
    `
  )}
`;
