import * as constants from './customersTransfer.constants';
import {
  ConcatTransferModel,
  CustomersFilters,
  CustomersItemsAfterChange,
  CustomersTableAction,
  CustomersTableRequestParams,
  CustomersTransferState,
} from './customersTransfer.model';
import { getCustomersLoading } from './customersTransfer.service';
import { SearchTerms } from './transferTable';

export const initialTransferCustomersMeta = {
  total: 0,
  count: 0,
  perPage: 50,
  currentPage: 1,
  totalPages: 1,
  links: {},
};

export const initialTransferCustomers = {
  data: [],
  meta: initialTransferCustomersMeta,
  isSelectedAll: false,
  isPaginating: false,
  isPending: false,
  isLoading: false,
  initialTotalCustomers: 0,
  filters: {
    departmentUuids: [],
    positionUuids: [],
    search: '',
    searchTerm: SearchTerms.name,
  },
};

export const initialTransferCustomersState: CustomersTransferState = {
  unallocated: initialTransferCustomers,
  allocated: initialTransferCustomers,
  added: [],
  removed: [],
  totalCustomers: 0,
};

export const customersTransferReducer = (
  state: CustomersTransferState = initialTransferCustomersState,
  action: CustomersTableAction
): CustomersTransferState => {
  let payload = action.payload;
  const { allocated: allocatedCustomers, unallocated: unallocatedCustomers } =
    state;

  switch (action.type) {
    case constants.INIT_TRANSFER_CUSTOMERS:
      const customersTransferState = action.payload as CustomersTransferState;
      return {
        ...state,
        ...customersTransferState,
      };

    case constants.TOGGLE_TRANSFER_CUSTOMERS:
      return {
        ...state,
        ...(payload as CustomersItemsAfterChange),
      };

    case constants.SET_TRANSFER_ALLOCATED_CUSTOMERS_FILTERS: {
      const filters = action.payload as CustomersFilters;

      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          filters: {
            ...allocatedCustomers.filters,
            ...filters,
          },
        },
      };
    }

    case constants.SET_TRANSFER_UNALLOCATED_CUSTOMERS_FILTERS: {
      const filters = action.payload as CustomersFilters;

      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          filters: {
            ...unallocatedCustomers.filters,
            ...filters,
          },
        },
      };
    }

    case constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_START:
      const requestParams = payload as CustomersTableRequestParams;

      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          ...getCustomersLoading(
            unallocatedCustomers.meta.currentPage,
            requestParams.page
          ),
        },
      };

    case constants.REQUEST_ALL_TRANSFER_UNALLOCATED_CUSTOMERS_START:
      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          isLoading: true,
        },
      };

    case constants.SELECT_ALL_TRANSFER_UNALLOCATED_CUSTOMERS:
      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          isSelectedAll: true,
        },
      };

    case constants.SELECT_ALL_TRANSFER_ALLOCATED_CUSTOMERS:
      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          isSelectedAll: true,
        },
      };

    case constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_SUCCESS: {
      const customers = payload as ConcatTransferModel;

      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          data: customers.concat
            ? [...unallocatedCustomers.data, ...customers.data]
            : customers.data,
          meta: customers.meta,
          isLoading: false,
          isPending: false,
          isPaginating: false,
          isSelectedAll: false,
        },
      };
    }

    case constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_ERROR:
      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          isLoading: false,
          isPaginating: false,
          isPending: false,
        },
      };

    case constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_START: {
      const requestParams = payload as CustomersTableRequestParams;

      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          ...getCustomersLoading(
            allocatedCustomers.meta.currentPage,
            requestParams.page
          ),
        },
      };
    }

    case constants.REQUEST_ALL_TRANSFER_ALLOCATED_CUSTOMERS_START:
      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          isLoading: true,
        },
      };

    case constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS:
      const customers = payload as ConcatTransferModel;

      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          data: customers.concat
            ? [...allocatedCustomers.data, ...customers.data]
            : customers.data,
          meta: customers.meta,
          isLoading: false,
          isPending: false,
          isPaginating: false,
          isSelectedAll: false,
        },
      };

    case constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_ERROR:
      return {
        ...state,
        allocated: {
          ...allocatedCustomers,
          isLoading: false,
          isPaginating: false,
          isPending: false,
        },
      };

    case constants.TOGGLE_TRANSFER_CUSTOMERS_PENDING: {
      return {
        ...state,
        unallocated: {
          ...unallocatedCustomers,
          isPending: payload as boolean,
        },
        allocated: {
          ...allocatedCustomers,
          isPending: payload as boolean,
        },
      };
    }

    case constants.SET_TRANSFER_CUSTOMERS_TOTAL_CUSTOMERS: {
      return {
        ...state,
        totalCustomers: payload as number,
      };
    }

    case constants.CLEAR_TRANSFER_CUSTOMERS:
      return initialTransferCustomersState;

    default:
      return state;
  }
};
