import styled from 'styled-components';
import { lineClamping } from '../../../../../styles';
import { TooltipComponent } from '../../../../tooltip';

export const TooltipStyled = styled(TooltipComponent)`
  width: 100%;
`;

export const CellExternalIdStyled = styled.div`
  ${lineClamping(1)}
`;
