import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, positionSticky } from '../../../styles';

export const RegisterForm = styled.div``;

export const RegisterFormTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        white-space: unset;
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
      `,
      theme.IS_MOBILE
    )}
`;

export const RegisterFormFooterStyled = styled.div`
  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        ${positionSticky()}
        z-index: 2;
        bottom: -20px;
        background: ${({ theme }): string => theme.COLOR.WHITE};
        padding: 20px 0 32px;
        margin: -20px auto;
      `,
      theme.IS_MOBILE
    )}
`;
