import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { FooterApiResponseModel, FooterModel } from './footer.model';
import { footerApiAdapter } from './footer.adapter';

export class FooterClient extends SecureRequestClient<
  null,
  FooterApiResponseModel,
  FooterModel
> {
  protected path = '';
  protected requestParameters: null;
  protected adapter = footerApiAdapter;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );

    this.path = 'course-api/v1/footer';
    this.requestParameters = null;
  }
}
