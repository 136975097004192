import { AxiosResponse } from 'axios';
import { RefreshTokenClient } from './refreshToken.client';
import { RequestHeader } from '../../../services';

export async function refreshTokenClientProvider(
  requestHeaders?: RequestHeader
): Promise<AxiosResponse<null>> {
  const response = await new RefreshTokenClient(requestHeaders).get();

  return response as unknown as Promise<AxiosResponse<null>>;
}
