import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftSubscriptionInfoContainer } from './leftSubscriptionInfo';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { useFormArchiveContext } from '../../formArchive.context';

export const ContentLeftContainer = (): ReactElement => {
  const { translations } = useFormArchiveContext();

  return (
    <ContentLeftStyled>
      <TitleStyled>
        {translations?.course_free_access_by_subscription}
      </TitleStyled>
      <LeftAdvantagesContainer />
      <LeftSubscriptionInfoContainer />
    </ContentLeftStyled>
  );
};
