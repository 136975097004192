import React, { ReactElement } from 'react';

interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
  checked,
  onChange,
}: CheckboxProps): ReactElement => (
  <input type="checkbox" checked={checked} onChange={onChange} />
);
