import {
  UserProfileRequestParamsApiModel,
  UserProfileRequestParamsModel,
} from './userProfile.model';

export const mapUserProfileRequestParams = (
  params: UserProfileRequestParamsModel
): UserProfileRequestParamsApiModel => ({
  first_name: params.firstName,
  last_name: params.lastName,
  phone: params.phone,
  position_id: params.positionUuid,
  department_id: params.departmentUuid,
  utm_query: params.utmQuery,
});
