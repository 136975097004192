import { SearchPopupStyleType } from './search.reducer';
import * as constants from './search.constants';

export type HandleSetSearchTerm = {
  type: string;
  term: string;
};

export type HandleToggleSearchForm = {
  type: string;
  isOpened: boolean;
  style: SearchPopupStyleType;
};

export type SearchActions = HandleSetSearchTerm & HandleToggleSearchForm;
/**
 * Redux event for handling search term
 * @param {string} term - search term value
 * @return {HandleSetSearchTerm} - Redux event
 */
export const handleSetSearchTerm = (term: string): HandleSetSearchTerm => {
  return {
    type: constants.SET_SEARCH_TERM,
    term,
  };
};

/**
 * Redux event for toggling search form
 * @param {boolean} isOpened
 * @param {object | null} style popup position style
 * @return {HandleSetSearchTerm} - Redux event
 */
export const handleToggleSearchForm = (
  isOpened: boolean,
  style: SearchPopupStyleType
): HandleToggleSearchForm => {
  return {
    type: constants.TOGGLE_OPEN_SEARCH_TERM,
    isOpened,
    style,
  };
};
