import React, { ReactElement } from 'react';
import { TableSearchButtonProps } from './tableSearchButton.model';
import {
  TableSearchButtonStyled,
  TableSearchIconStyled,
} from './tableSearchButton.styled';
import { IconMagnifierComponent } from '../../icons';
import { useTheme } from '../../../hooks';

export const TableSearchButtonContainer = ({
  title,
  onClick,
}: TableSearchButtonProps): ReactElement => {
  const theme = useTheme();

  return (
    <TableSearchButtonStyled onClick={onClick}>
      {title}
      <TableSearchIconStyled>
        <IconMagnifierComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
          fillColor={theme.COLOR.TEXT_LIGHT_COLOR}
        />
      </TableSearchIconStyled>
    </TableSearchButtonStyled>
  );
};
