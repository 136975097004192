import { ApiCourseOptionModel, CourseOptionModel } from './courseOptions.model';

export const courseOptionsAdapter = (
  options: ApiCourseOptionModel[]
): CourseOptionModel[] =>
  options.map((option) => ({
    logicType: option.logic_type,
    filterSlug: option.filter_slug,
    slug: option.slug,
    value: option.value,
  }));
