import React, { ReactElement, useEffect, useState } from 'react';

import {
  GenericFormControlContainer,
  GenericFormControlContainerProps,
} from '../../form';
import { SelectOption } from '../../select';
import {
  departmentsByCompanyClientProvider,
  departmentsClientProvider,
} from '../departments.clientProvider';

type DepartmentsSelectContainerProps = GenericFormControlContainerProps & {
  companyId?: string;
  requestHost?: string;
};

export const DepartmentsSelectContainer = ({
  errors,
  onChange,
  onBlur,
  defaultOption,
  placeholder,
  label,
  name,
  color,
  disabled,
  bordered,
  companyId,
  requestHost,
}: DepartmentsSelectContainerProps): ReactElement => {
  const [departments, setDepartments] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (companyId) {
      departmentsByCompanyClientProvider(companyId, requestHost).then(
        setDepartments
      );
    } else {
      departmentsClientProvider(requestHost).then(setDepartments);
    }
  }, []);

  return (
    <GenericFormControlContainer
      type="select"
      errors={errors}
      onChange={(value): void => {
        onChange(value);
      }}
      onBlur={(value): void => {
        onBlur(value);
      }}
      initialValue={defaultOption?.value || ''}
      placeholder={placeholder}
      label={label}
      name={name}
      isRequired
      color={color}
      bordered={bordered}
      disabled={disabled}
      clearControl={false}
      options={departments}
      defaultOption={defaultOption}
    />
  );
};
