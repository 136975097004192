import { CSSVariables, Nullable } from '../../models';

export const BODY_CLASS_NAME = 'isPopupOpened';
const MIN_TOP_MARGIN = 250;

export const scrollToElement = (element: Element) => {
  const elementRect = element.getBoundingClientRect();
  const isInViewport =
    elementRect.top >= 0 && elementRect.bottom <= window.innerHeight;
  if (!isInViewport) {
    window.scroll({
      top: elementRect.top - MIN_TOP_MARGIN,
      behavior: 'smooth',
    });
  }
};

export const getVisibleElement = (
  elements: NodeListOf<Element>
): Nullable<Element> => {
  if (!elements.length) return null;

  if (elements.length === 1) {
    return getComputedStyle(elements[0]).display !== 'none'
      ? elements[0]
      : null;
  }

  for (let i = 0; i < elements.length; i++) {
    if (getComputedStyle(elements[i]).display !== 'none') {
      return elements[i];
    }
  }

  return null;
};

export const cleanDOM = (): void => {
  document.documentElement.style.setProperty(
    CSSVariables.scrollbarPlugWidth,
    '0px'
  );
  document.body.classList.remove(BODY_CLASS_NAME);
};
