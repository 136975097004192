import { Nullable, stringService, TranslationModel } from '@lerna-core';

export const getSubscriptionAvailabilityInfo = (
  translations: TranslationModel,
  isClaimAvailable: boolean,
  coursesAvailable: number,
  subscriptionTitle: string,
  claimPeriodEnd: string
): Nullable<string> => {
  if (isClaimAvailable) {
    return stringService.capitalizeFirstLetter(
      `${translations.you_can_add} ${coursesAvailable} ${stringService
        .pluralTranslations(coursesAvailable, [
          translations.course_label_1,
          translations.course_label_2,
          translations.course_label_many,
        ])
        .toLowerCase()} ${
        translations?.from_subscription
      } <span>«${stringService.capitalizeFirstLetter(
        subscriptionTitle
      )}»</span> ${translations.prefix_to} ${claimPeriodEnd}`
    );
  }

  return null;
};
