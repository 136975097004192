import React, { ReactElement } from 'react';
import { IconFireComponent } from '@lerna-core';
import {
  PricesTotalDiscountIconStyled,
  PricesTotalDiscountStyled,
} from './pricesTotalDiscount.styled';
import { PricesTotalDiscountContainerProps } from './pricesTotalDiscount.model';

export const PricesTotalDiscountContainer = ({
  discount,
  isMegaDiscount,
}: PricesTotalDiscountContainerProps): ReactElement => (
  <PricesTotalDiscountStyled>
    {isMegaDiscount && (
      <PricesTotalDiscountIconStyled>
        <IconFireComponent />
      </PricesTotalDiscountIconStyled>
    )}
    -{discount}
  </PricesTotalDiscountStyled>
);
