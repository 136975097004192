import { useEffect, useMemo, useState } from 'react';
import { userPositionsByCompanyProvider } from './userPositions.clientProvider';
import { SelectOption } from '../../select';
import { UserPositionsHookModel } from './userPosition.model';

export function useCompanyPositions(
  companyId?: string
): UserPositionsHookModel {
  const [positions, setPositions] = useState<SelectOption[]>([]);
  const [hasDefaultPosition, setHasDefaultPosition] = useState<boolean>(false);

  useEffect(() => {
    if (companyId) {
      userPositionsByCompanyProvider(companyId).then((positions) => {
        const hasDefaultPosition = positions.some(
          (position) => position.isDefault
        );
        setPositions(positions);
        setHasDefaultPosition(hasDefaultPosition);
      });
    }
  }, [companyId, hasDefaultPosition]);

  return useMemo(() => ({ positions, hasDefaultPosition }), [positions]);
}
