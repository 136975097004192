import { CoursesFilters, CoursesTransferState } from '../coursesTransfer.model';
import { TranslationModel } from '../../../../models';
import { TableEmptyContent } from '../../../table';

export const getAllocatedEmptyContent = (
  translations: TranslationModel,
  coursesAllocatedFilters: CoursesFilters,
  courses: CoursesTransferState
): TableEmptyContent => {
  switch (true) {
    case !!coursesAllocatedFilters.sphere?.length &&
      !courses.allocated.data.length:
      return {
        title: translations.filters_is_empty,
        text: null,
      };
    default:
      return {
        title: translations.list_is_empty,
        text: translations.add_courses_with_arrows,
      };
  }
};
