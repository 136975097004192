import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { PopupContainer } from '../../../features/popups/popup';
import { SearchPopupContainerProps } from './searchPopup.model';
import {
  getSearchIsOpenedSelector,
  getSearchPopupStyleSelector,
} from '../../search';

export const SearchPopupContainer = ({
  children,
  handleClose,
  isMobile,
}: SearchPopupContainerProps): ReactElement | null => {
  const style = useSelector(getSearchPopupStyleSelector);
  const isOpened = useSelector(getSearchIsOpenedSelector);

  const onClose = (): void => {
    handleClose(true);
  };

  if (!isOpened) {
    return null;
  }

  return (
    <PopupContainer
      handleClose={onClose}
      isOpened={isOpened}
      hasClose={false}
      hasHeader={false}
      style={isMobile ? null : style}
      customStyle={!isMobile}
      isMobile={isMobile}
    >
      {children}
    </PopupContainer>
  );
};
