import { breakpoint, fontSettings } from '@lerna-core';
import styled, { css } from 'styled-components';
import {
  SearchButtonContainerStyledProps,
  SearchButtonFiltersCountStyledProps,
} from './searchButton.model';

export const SearchButtonContainerStyled = styled.div<SearchButtonContainerStyledProps>`
  margin-left: 8px;
  position: relative;

  & > button {
    width: ${({ theme }): number => (theme.IS_MOBILE ? 48 : 54)}px;
    height: ${({ theme }): number => (theme.IS_MOBILE ? 48 : 54)}px;
    padding: 0;
    border-radius: 50%;

    svg:first-child {
      margin-right: 0;
    }

    svg:first-child {
      margin-left: 0;
    }
  }

  ${({ isFullButton }): ReturnType<typeof css> | string =>
    isFullButton
      ? css`
          width: 100%;
          display: flex;
          margin-left: 0;

          & > button {
            flex: 1;
            width: 100%;
            border-radius: 100px;
          }
        `
      : ''}

  ${({ isMobile, isSticky }): ReturnType<typeof css> | string =>
    breakpoint(
      'smallDown',
      isSticky
        ? css`
            > button {
              background-color: transparent;
            }
          `
        : css``,
      isMobile
    )}
`;

export const SearchButtonFiltersCountStyled = styled.div<SearchButtonFiltersCountStyledProps>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_12, props.theme.FONT)};
  font-weight: 700;
  line-height: 18px;
  color: ${(props): string => props.theme.COLOR.WHITE};
  background-color: ${(props): string => props.theme.COLOR.RED};
  border-radius: 50%;
  text-align: center;
  width: 18px;
  height: 18px;
  position: absolute;
  right: -5px;
  top: -5px;
  align-items: center;
  justify-content: center;

  ${({ isSticky, isMobile }): ReturnType<typeof css> | string => {
    return breakpoint(
      'mediumDown',
      isSticky
        ? css`
            top: -7px;
            right: -7px;
          `
        : css``,
      isMobile
    );
  }}
`;
