import React, { ReactElement, useCallback, useState } from 'react';

import { useTheme } from '../../../../../hooks';
import { IconArrowComponent } from '../../../../icons';
import {
  IconArrowBlockStyled,
  SideMenuSectionBlockStyled,
  SideMenuSectionItemsStyled,
  SideMenuSectionStyled,
  SideMenuSectionTitleStyled,
} from './sideMenuSection.styled';
import { MenuIconContainer } from '../../../menuIcon';

export interface SideMenuSectionProps {
  title: string;
  link: string;
  icon: string | null;
  children: ReactElement[] | ReactElement | null;
  isOpen?: boolean;
}

export const SideMenuSection = ({
  title,
  icon,
  children,
  isOpen = false,
}: SideMenuSectionProps): ReactElement => {
  const theme = useTheme();
  const [opened, setOpen] = useState(isOpen);

  const handleToggle = useCallback(() => setOpen(!opened), [opened]);

  return (
    <>
      <SideMenuSectionStyled onClick={handleToggle}>
        <SideMenuSectionBlockStyled>
          <MenuIconContainer icon={icon} dark theme={theme} />
          <SideMenuSectionTitleStyled>{title}</SideMenuSectionTitleStyled>
        </SideMenuSectionBlockStyled>

        <SideMenuSectionBlockStyled>
          <IconArrowBlockStyled opened={opened}>
            <IconArrowComponent
              strokeColor={theme.COLOR.TEXT_DARK_COLOR}
              width={24}
              height={24}
            />
          </IconArrowBlockStyled>
        </SideMenuSectionBlockStyled>
      </SideMenuSectionStyled>

      <SideMenuSectionItemsStyled opened={opened}>
        {children}
      </SideMenuSectionItemsStyled>
    </>
  );
};
