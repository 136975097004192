import styled from 'styled-components';
import { customizeScrollBar } from '../../../../styles';

export const MenuDesktopStyled = styled.div`
  max-height: 500px;
  overflow-x: hidden;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  ${customizeScrollBar(20, 20)}
`;
