import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../styles';
import { WithTheme } from '../themes';

export const PageNotFoundStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 108px 0 208px;

  ${breakpoint(
    'smallDown',
    css`
      padding: 48px 0 96px;
    `
  )};
`;

export const PageNotFoundTitleStyled = styled.h1<WithTheme>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_56, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 700;
  line-height: 56px;
  margin: 0 0 28px 0;

  ${breakpoint(
    'smallDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_34, props.theme.FONT)};
      line-height: 40px;
    `
  )}
`;

export const PageNotFoundTextStyled = styled.span<WithTheme>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  font-weight: 500;

  ${breakpoint(
    'smallDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)}
    `
  )}
`;

export const PageNotFoundLinkContainerStyled = styled.div`
  margin: 16px 0 120px;

  ${breakpoint(
    'smallDown',
    css`
      margin-bottom: 80px;
    `
  )}
`;

export const PageNotFoundLinkStyled = styled.span<WithTheme>`
  color: ${({ theme }): string => theme.COLOR.PURPLE};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_20, props.theme.FONT)};

  font-weight: 500;
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${({ theme }): string => theme.COLOR.PURPLE};

    &:visited {
      color: ${({ theme }): string => theme.COLOR.PURPLE};
    }
  }
`;
