import React, { ReactElement, useCallback, useRef, useState } from 'react';

import {
  TableFilterButtonStyled,
  TableFilterIconStyled,
  TableFilterStyled,
} from './tableFilter.styled';
import { TableFilterContainerProps } from './tableFilter.model';
import { FilterOptionsTypes, FilterPopupContainer } from '../../filterPopup';
import { IconFilterComponent } from '../../icons';
import { useTheme } from '../../../hooks';
import { Nullable } from '../../../models';
import { DropdownPosition } from '../../dropdown';

export const TableFilterContainer = ({
  filterOptions,
  filters,
  handleApplyFilter,
  handleRequest,
  buttonTitle,
  searchPlaceholder,
  translations,
  idKey = 'key',
  labelKey = 'value',
  searchType,
  handlePagination,
  handleSearch,
  isPaginating,
  hideSelectAllOption = false,
  optionType = FilterOptionsTypes.checkbox,
  meta,
}: TableFilterContainerProps): ReactElement => {
  const triggerRef = useRef<Nullable<HTMLDivElement>>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(filters || []);

  const theme = useTheme();

  const togglePopup = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const applyFilter = (filters: string[]): void => {
    setSelected(filters);
    handleApplyFilter(filters);
    handleRequest(filters);
  };

  const resetFilter = (filters: string[]): void => {
    setSelected(filters);
    handleApplyFilter(filters);
    handleRequest(filters);
  };

  return (
    <TableFilterStyled ref={triggerRef}>
      <TableFilterButtonStyled onClick={togglePopup}>
        {buttonTitle}
        <TableFilterIconStyled selected={!!selected.length}>
          <IconFilterComponent
            width={16}
            height={16}
            strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
          />
        </TableFilterIconStyled>
      </TableFilterButtonStyled>

      <FilterPopupContainer
        ref={triggerRef}
        open={open}
        options={filterOptions}
        idKey={idKey}
        labelKey={labelKey}
        selected={selected}
        onApply={applyFilter}
        onClose={togglePopup}
        onReset={resetFilter}
        searchType={searchType}
        meta={meta}
        position={DropdownPosition.right}
        handleApiSearch={handleSearch}
        isPaginating={isPaginating}
        hideSelectAllOption={hideSelectAllOption}
        handlePagination={handlePagination}
        selectAllOptionLabel={translations.select_all_option}
        resetButtonTitle={translations.clear_filter_label}
        applyButtonTitle={translations.apply_filter_label}
        searchPlaceholder={searchPlaceholder}
        optionsType={optionType}
      />
    </TableFilterStyled>
  );
};
