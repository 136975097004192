export enum SubscriptionBadgeStatuses {
  warn = 'warn',
  success = 'success',
}

export type SubscriptionBadgeModel = {
  status: SubscriptionBadgeStatuses;
  label: string;
  link?: string;
  linkLabel?: string;
};
