export const TOGGLE_TRANSFER_COURSES = 'TOGGLE_TRANSFER_COURSES';
export const INIT_TRANSFER_COURSES_SUCCESS = 'INIT_TRANSFER_COURSES_SUCCESS';

export const SET_TRANSFER_COURSES_TOTAL_COURSES =
  'SET_TRANSFER_COURSES_TOTAL_COURSES';

export const REQUEST_TRANSFER_UNALLOCATED_COURSES_ERROR =
  'REQUEST_TRANSFER_UNALLOCATED_COURSES_ERROR';
export const REQUEST_TRANSFER_UNALLOCATED_COURSES_START =
  'REQUEST_TRANSFER_UNALLOCATED_COURSES_START';
export const REQUEST_ALL_TRANSFER_UNALLOCATED_COURSES_START =
  'REQUEST_ALL_TRANSFER_UNALLOCATED_COURSES_START';
export const REQUEST_TRANSFER_UNALLOCATED_COURSES_SUCCESS =
  'REQUEST_TRANSFER_UNALLOCATED_COURSES_SUCCESS';
export const SELECT_ALL_TRANSFER_UNALLOCATED_COURSES =
  'SELECT_ALL_TRANSFER_UNALLOCATED_COURSES';

export const REQUEST_TRANSFER_ALLOCATED_COURSES_ERROR =
  'REQUEST_TRANSFER_ALLOCATED_COURSES_ERROR';
export const REQUEST_TRANSFER_ALLOCATED_COURSES_START =
  'REQUEST_TRANSFER_ALLOCATED_COURSES_START';
export const REQUEST_ALL_TRANSFER_ALLOCATED_COURSES_START =
  'REQUEST_ALL_TRANSFER_ALLOCATED_COURSES_START';
export const REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS =
  'REQUEST_TRANSFER_ALLOCATED_COURSES_SUCCESS';
export const SELECT_ALL_TRANSFER_ALLOCATED_COURSES =
  'SELECT_ALL_TRANSFER_ALLOCATED_COURSES';

export const TOGGLE_TRANSFER_COURSES_PENDING =
  'TOGGLE_TRANSFER_COURSES_PENDING';

export const SET_TRANSFER_UNALLOCATED_COURSES_FILTERS =
  'SET_TRANSFER_UNALLOCATED_COURSES_FILTERS';
export const SET_TRANSFER_ALLOCATED_COURSES_FILTERS =
  'SET_TRANSFER_ALLOCATED_COURSES_FILTERS';

export const CLEAR_TRANSFER_COURSES = 'CLEAR_TRANSFER_COURSES';
