import {
  ShowcaseApiModel,
  ShowcaseDefaultModel,
  ShowcaseErrorModel,
} from './showcase.model';
import { CourseModel } from '../../features/course';
import {
  mapCurrencies,
  mapMetaPaginationAdapter,
  mapVendors,
} from '../../adapters';
import { AggregateResponseApiErrorModel, CurrencyModel } from '../../models';
import { VendorModel } from '../../features/vendors';
import { coursesAdapter } from '../../features/courses';
import { mapCompany } from '../../features/company';
import { CatalogPageTypes, PageStatusCodes } from '../../constants';
import { checkError } from '../../services/aggregateRequests';
import { filtersAdapter } from '../../adapters/filter.adapter';
import { footerAdapter } from '../../features/footer';
import { advantagesAdapter } from '../advantages';
import { seoAdapter } from '../../adapters/seo.adapter';
import { promoCodeWithErrorAdapter } from '../promoCode';

export const showcaseDefaultAdapter = (
  response: ShowcaseApiModel,
  requiredKeys: string[]
): ShowcaseDefaultModel | ShowcaseErrorModel => {
  const { data } = response;
  const statusCode = checkError(
    data as unknown as AggregateResponseApiErrorModel,
    requiredKeys
  );
  const translations = data.statics?.data.translations;
  const company = data.companies?.data
    ? mapCompany(data.companies?.data)
    : null;
  const footer = footerAdapter(data.footer);
  const filters = filtersAdapter(data.filters.data, translations);
  const promoCode = promoCodeWithErrorAdapter(
    data?.promo_code?.data,
    data.error_promo_code
  );
  const advantages = data.info_block?.data?.image
    ? advantagesAdapter(data.info_block)
    : null;

  if (statusCode) {
    return {
      statusCode: PageStatusCodes.errorNotFound,
      translations,
      filters,
      footer,
      company,
    };
  }

  const currencies: CurrencyModel[] = mapCurrencies(
    data.currencies?.data || []
  );
  const vendors: VendorModel[] = mapVendors(data.vendors?.data || []);

  const courses: CourseModel[] = coursesAdapter(
    data.courses.data,
    currencies,
    translations,
    vendors
  );

  return {
    currencies,
    seo: data.seo?.data ? seoAdapter(data.seo?.data) : {},
    filters,
    translations,
    courses,
    pagination: mapMetaPaginationAdapter(data.courses.meta.pagination),
    suggest: '',
    footer,
    company,
    advantages,
    pageName: CatalogPageTypes.courses,
    promoCode,
  };
};
