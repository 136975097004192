import styled from 'styled-components';

import {
  IconDirection,
  TransferControlStyledProps,
} from './transferControl.model';

const getIconStylesByDirection = (direction: IconDirection): string => {
  switch (direction) {
    case IconDirection.left:
      return `
        transform: rotate(180deg);
      `;
    case IconDirection.right:
      return '';
  }
};

export const TransferControlStyled = styled.div<TransferControlStyledProps>`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  button {
    padding: 12px 11px;
    border: 0;
  }

  svg {
    ${({ iconDirection }): string => getIconStylesByDirection(iconDirection)}
  }
`;
