import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBoxComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.59998 6.70312H21.3"
      stroke={fillColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.45436 2.80005H17.7246C18.2214 2.80005 18.6931 3.02029 19.0113 3.40167L20.912 5.67993C21.1626 5.98046 21.2999 6.35961 21.2999 6.75103V18.9697C21.2999 20.2014 20.3002 21.2 19.0671 21.2H5.11186C3.87878 21.2 2.87903 20.2014 2.87903 18.9697V6.75103C2.87903 6.35961 3.01635 5.98102 3.26698 5.67993L5.16769 3.40167C5.48586 3.02029 5.95755 2.80005 6.45436 2.80005Z"
      stroke={fillColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.57776 10.6061H14.6016"
      stroke={fillColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0896 3.07886V6.42431"
      stroke={fillColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
