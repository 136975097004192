import styled from 'styled-components';

export const CourseVendorLogoStyled = styled.div`
  display: flex;
  align-items: center;
  max-width: ${({ theme }): number => (theme.IS_MOBILE ? 182 : 208)}px;
  height: ${({ theme }): number => (theme.IS_MOBILE ? 28 : 32)}px;
  justify-content: flex-start;

  img {
    max-width: 100%;
    height: ${({ theme }): string => (theme.IS_MOBILE ? 'unset' : '32px')};
  }
`;
