import { CustomersClient } from './customers.client';
import { Customers, CustomersRequestParams } from './customers.model';

export async function customersClientProvider(
  companyId: string,
  params: CustomersRequestParams = {}
): Promise<Customers> {
  const response = await new CustomersClient(companyId, params).get();

  return response as unknown as Promise<Customers>;
}
