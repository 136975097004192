import React, { ReactElement, useMemo } from 'react';
import { PriceValueContainerProps } from './priceValue.model';
import { PriceValueContainerStyled } from './priceValue.styled';
import { useShowLegalEntitiesPriceHook } from '@hooks';

export const PriceValueContainer = ({
  price,
  isB2B,
}: PriceValueContainerProps): ReactElement => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const value = useMemo(() => {
    if (showLegalEntitiesPrice) {
      return price?.priceCustomerB2B?.amount;
    }

    if (isB2B) {
      return price?.priceCustomer?.amount;
    }

    return price?.price?.amount;
  }, [price, showLegalEntitiesPrice]);

  return <PriceValueContainerStyled>{value}</PriceValueContainerStyled>;
};
