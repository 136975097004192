import React, { ReactElement } from 'react';
import { Nullable } from '../../../models';
import {
  IconAnalyticsComponent,
  IconBadgeComponent,
  IconBoxComponent,
  IconCaseComponent,
  IconCoinComponent,
  IconCVComponent,
  IconDocumentWithArrowCheckComponent,
  IconGridComponent,
  IconHeartComponent,
  IconHelpComponent,
  IconHomeComponent,
  IconLaptopComponent,
  IconListComponent,
  IconLoginLComponent,
  IconLogoutLComponent,
  IconMarketingComponent,
  IconPaperWithPenComponent,
  IconPercentComponent,
  IconPercentWithPaperComponent,
  IconPortfolioComponent,
  IconReferralUsersComponent,
  IconSettingsComponent,
  IconUserComponent,
  IconUsersComponent,
} from '../../icons';
import { IconNames } from './menuIcon.constants';
import { IconProps } from './menuIcon.model';

export const mapIcons = (
  iconName: Nullable<string>,
  { color, height = 24, width = 24 }: IconProps
): Nullable<ReactElement> => {
  const baseProps = { height, width };

  switch (iconName) {
    case IconNames.user:
      return <IconUserComponent {...baseProps} strokeColor={color} />;
    case IconNames.catalog:
      return <IconListComponent {...baseProps} strokeColor={color} />;
    case IconNames.home:
      return <IconHomeComponent {...baseProps} strokeColor={color} />;
    case IconNames.case:
      return (
        <IconCaseComponent
          {...baseProps}
          fillColor={color}
          strokeColor={color}
        />
      );
    case IconNames.analytics:
      return (
        <IconAnalyticsComponent
          {...baseProps}
          fillColor={color}
          strokeColor={color}
        />
      );
    case IconNames.proftests:
    case IconNames.badge:
      return (
        <IconBadgeComponent
          {...baseProps}
          fillColor={color}
          strokeColor={color}
        />
      );
    case IconNames.coin:
      return (
        <IconCoinComponent
          {...baseProps}
          strokeColor={color}
          fillColor={color}
        />
      );
    case IconNames.grid:
      return <IconGridComponent {...baseProps} strokeColor={color} />;
    case IconNames.heart:
      return <IconHeartComponent {...baseProps} strokeColor={color} />;
    case IconNames.list:
      return <IconHeartComponent {...baseProps} strokeColor={color} />;
    case IconNames.cv:
      return <IconCVComponent {...baseProps} strokeColor={color} />;
    case IconNames.cog:
    case IconNames.settings:
      return <IconSettingsComponent {...baseProps} strokeColor={color} />;
    case IconNames.users:
      return <IconUsersComponent {...baseProps} strokeColor={color} />;
    case IconNames.help:
      return (
        <IconHelpComponent
          {...baseProps}
          fillColor={color}
          strokeColor={color}
        />
      );
    case IconNames.percent:
      return <IconPercentComponent {...baseProps} strokeColor={color} />;
    case IconNames.percentWithPen:
      return (
        <IconPercentWithPaperComponent {...baseProps} strokeColor={color} />
      );
    case IconNames.portfolio:
      return (
        <IconPortfolioComponent
          {...baseProps}
          strokeColor={color}
          fillColor={color}
        />
      );
    case IconNames.paper_with_pen:
      return (
        <IconPaperWithPenComponent
          {...baseProps}
          strokeColor={color}
          fillColor={color}
        />
      );
    case IconNames.box:
      return <IconBoxComponent {...baseProps} fillColor={color} />;
    case IconNames.referralUsers:
      return <IconReferralUsersComponent {...baseProps} fillColor={color} />;
    case IconNames.logout:
      return <IconLogoutLComponent {...baseProps} strokeColor={color} />;
    case IconNames.login:
      return <IconLoginLComponent {...baseProps} strokeColor={color} />;
    case IconNames.tests:
      return (
        <IconDocumentWithArrowCheckComponent
          {...baseProps}
          strokeColor={color}
        />
      );
    case IconNames.marketing:
      return <IconMarketingComponent {...baseProps} strokeColor={color} />;
    case IconNames.laptop:
      return <IconLaptopComponent {...baseProps} strokeColor={color} />;
    default:
      return null;
  }
};
