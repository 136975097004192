import styled from 'styled-components';
import { TableSearchInputStyled, TableStyled } from '../../../table';
import { CustomerTableNameStyledProps } from './transferTable.model';

export const CustomersTableBoxStyled = styled.div`
  ${TableStyled} {
    position: relative;
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    height: 655px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }

  ${TableSearchInputStyled} {
    width: 100%;
    max-width: none;
  }
`;

export const CustomerTableNameStyled = styled.a<CustomerTableNameStyledProps>`
  color: ${({ theme, disabled }): string =>
    Boolean(disabled)
      ? theme.COLOR.TEXT_LIGHT_COLOR
      : theme.COLOR.TEXT_DARK_COLOR};
  text-decoration: none;

  &:active,
  &:visited {
    color: ${({ theme, disabled }): string =>
      Boolean(disabled)
        ? theme.COLOR.TEXT_LIGHT_COLOR
        : theme.COLOR.TEXT_DARK_COLOR};
  }

  &:hover {
    text-decoration: ${({ href }): string => (!!href ? 'underline' : 'none')};
  }
`;

export const CustomerTableEmailStyled = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const CustomersTableEmptyCheckAll = styled.div`
  width: 16px;
`;
