import React, { ReactElement } from 'react';
import { FooterFastLinksContainerProps } from './footerFastLinks.model';
import { useRouter } from 'next/router';
import {
  FooterFastLinksItemStyled,
  FooterFastLinksStyled,
  FooterFastLinkTextStyled,
} from './footerFastLinks.styled';
import { urlService } from '../../../services';

export const FooterFastLinksContainer = ({
  fastLinks,
}: FooterFastLinksContainerProps): ReactElement => {
  const { query } = useRouter();
  const fastLinkQuery = (query?.fastlink as string) || '';

  return (
    <FooterFastLinksStyled>
      {fastLinks.map((link) => (
        <FooterFastLinksItemStyled
          href={urlService.normalizeUrl(link.path)}
          key={link.slug}
        >
          <FooterFastLinkTextStyled isActive={link.slug === fastLinkQuery}>
            {link.title}
          </FooterFastLinkTextStyled>
        </FooterFastLinksItemStyled>
      ))}
    </FooterFastLinksStyled>
  );
};
