import { GTMEventSearchModel } from '@lerna-core';

export enum CaseSearchFormEvent {
  constructorSearchMain = 'constructor_search_main',
  constructorSearchFixed = 'constructor_search_fixed',
}

export enum CaseSearchFormEventType {
  searchFilterEvents = 'search_filter_events',
}

export enum CaseSearchFormEventCategoryType {
  search = 'Search',
}

export const getSearchEventData = (
  eventName: string,
  eventQuery: string,
  eventParam: string
): GTMEventSearchModel => {
  const eventProps: GTMEventSearchModel = {
    event: eventName,
    event_type: CaseSearchFormEventType.searchFilterEvents,
    event_category: CaseSearchFormEventCategoryType.search,
    event_query: eventQuery,
  };

  if (eventParam !== eventQuery) {
    eventProps.event_param = eventParam;
  }

  return eventProps;
};
