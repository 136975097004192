import React, { ReactElement } from 'react';

import { BackgroundColorsModel } from './background.model';
import {
  BackgroundLayer0Styled,
  BackgroundLayer1Styled,
  BackgroundLayer2Styled,
} from './background.styled';

export const BackgroundContainer = ({
  backgroundLayer0Styles,
  backgroundLayer1Styles,
  backgroundLayer2Styles,
}: BackgroundColorsModel): ReactElement => (
  <>
    <BackgroundLayer0Styled backgroundLayer0Styles={backgroundLayer0Styles} />
    <BackgroundLayer1Styled backgroundLayer1Styles={backgroundLayer1Styles} />
    <BackgroundLayer2Styled backgroundLayer2Styles={backgroundLayer2Styles} />
  </>
);
