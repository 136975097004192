import {
  CourseBenefitApiModel,
  CourseBenefitModel,
} from './courseBenefit.model';

export const courseBenefitAdapter = ({
  id,
  type,
  is_comment_necessary,
}: CourseBenefitApiModel): CourseBenefitModel => ({
  id,
  type,
  isCommentNecessary: is_comment_necessary,
});
