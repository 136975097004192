import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconBusinessManagementComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.583 3.333a1.25 1.25 0 0 0-1.25 1.25v3.75H6.667a3.343 3.343 0 0 0-3.334 3.334v7.5c0 1.833 1.5 3.333 3.334 3.333h9.583c.917-1 2.25-1.667 3.75-1.667V20H6.667c-.5 0-.834-.333-.834-.833v-7.5c0-.5.334-.834.834-.834H20c.333 0 .5.168.583.251.083.083.25.25.25.583v5.083c.5-.584 1.25-.917 2.084-.917h.416v-4.166c0-1.834-1.5-3.334-3.333-3.334h-1.667v-3.75a1.25 1.25 0 0 0-1.25-1.25h-7.5Zm19.56 1.656a1.25 1.25 0 0 0-.86.377l-2.81 2.81a1.25 1.25 0 0 0 .007 1.988l2.803 2.803A1.25 1.25 0 1 0 30.05 11.2l-.782-.782h5.315a1.248 1.248 0 0 0 1.171-1.733 1.25 1.25 0 0 0-1.17-.767h-5.316l.782-.783a1.25 1.25 0 0 0-.908-2.145Zm-18.31.844h5v2.5h-5v-2.5ZM22.917 17.5a1.25 1.25 0 0 0-1.25 1.25v3.75H20c-1.821 0-3.333 1.512-3.333 3.333v7.5c0 1.822 1.512 3.334 3.333 3.334h13.333c1.822 0 3.334-1.512 3.334-3.334v-7.5c0-.94-.41-1.72-.858-2.28-.676-.845-1.654-1.053-2.476-1.053h-1.666v-3.75a1.25 1.25 0 0 0-1.25-1.25h-7.5Zm1.25 2.5h5v2.5h-5V20ZM20 25h13.333c.346 0 .534.126.524.114.22.274.31.493.31.72v7.5c0 .511-.322.833-.834.833H20c-.512 0-.833-.322-.833-.834v-7.5c0-.512.321-.833.833-.833Zm-9.18 1.654a1.25 1.25 0 0 0-.87 2.146l.782.783H5.417a1.252 1.252 0 0 0-1.171 1.733 1.25 1.25 0 0 0 1.17.767h5.316l-.782.783a1.25 1.25 0 1 0 1.767 1.768l2.81-2.81a1.25 1.25 0 0 0-.007-1.988l-2.803-2.803a1.251 1.251 0 0 0-.897-.38Z"
        fill={fillColor}
      />
    </svg>
  );
};
