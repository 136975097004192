import styled, { css } from 'styled-components';

import { breakpoint, transition } from '../../../styles';
import { fontSettings } from '../../../styles/mixins';

type AccordionSpoilerStyledProps = {
  isOpened: boolean;
  hasContent: boolean;
};

export const accordionSpoilerVerticalPadding = '24px';

export const AccordionSpoilerStyled = styled.div<AccordionSpoilerStyledProps>`
  position: relative;
  cursor: ${({ hasContent }): string => (hasContent ? 'pointer' : 'default')};
  font-weight: 600;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_22, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  padding: ${accordionSpoilerVerticalPadding} 36px
    ${accordionSpoilerVerticalPadding} 0;

  ${breakpoint(
    'mediumDown',
    css`
      font-weight: 500;
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
    `
  )}

  svg {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    ${transition('transform')};
    transform: rotate(${({ isOpened }): string => (isOpened ? '180' : '0')}deg);
  }
`;
