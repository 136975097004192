import React, { ReactElement, useContext, useMemo } from 'react';
import {
  IconUserFillComponent,
  ImageContainer,
  browserService,
  useTheme,
  DeviceInfoContext,
  Nullable,
} from '@lerna-core';
import { ItemPhotoContainerProps } from './itemPhoto.model';
import { ItemPhotoContainerStyled } from './itemPhoto.styled';

export const ItemPhotoContainer = ({
  photo,
  tutorName,
}: ItemPhotoContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const iconProps = useMemo(() => {
    const size = isMobile ? 50 : 100;
    const fillColor = isMobile
      ? theme.COLOR.MEDIUM_LIGHT_PURPLE
      : theme.COLOR.WHITE;

    return {
      fillColor,
      width: size,
      height: size,
    };
  }, []);

  const imagePreset = useMemo(
    () => (isMobile ? 'tutorPhotoSmall' : 'tutorPhoto'),
    []
  );

  return (
    <ItemPhotoContainerStyled isMobile={isMobile}>
      {!!photo?.imageResource ? (
        <ImageContainer
          imageResource={photo.imageResource}
          preset={imagePreset}
          title={tutorName}
          alt={tutorName}
        />
      ) : (
        <IconUserFillComponent {...iconProps} />
      )}
    </ItemPhotoContainerStyled>
  );
};
