import styled, { css } from 'styled-components';
import { fontSettings } from '../../../../../styles';
import { DescriptionItemStyledProps } from './description.model';

export const DescriptionStyled = styled.ul`
  margin: 8px 0 0;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)};
  padding-left: 12px;
`;

export const DescriptionItemStyled = styled.li<DescriptionItemStyledProps>`
  & + & {
    margin-top: 4px;
  }

  color: ${({ theme, success, error }): ReturnType<typeof css> | string =>
    (success && theme.COLOR.INFO_GREEN_COLOR) ||
    (error && theme.COLOR.INFO_RED_COLOR) ||
    'inherit'};
`;
