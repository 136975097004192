import styled, { css } from 'styled-components';
import { SubscriptionBadgeStatuses } from './subscriptionBadge.model';
import { getSubscriptionBadgeColor } from './subscriptionBadge.mixin';
import { breakpoint, fontSettings } from '@lerna-core';

export const SubscriptionBadge = styled.div<{
  status: SubscriptionBadgeStatuses;
}>`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  margin-top: auto;
  ${({ status }) => getSubscriptionBadgeColor(status)}

  ${(): ReturnType<typeof css> | string =>
    breakpoint(
      'smallDown',
      css`
        flex-wrap: wrap;
      `
    )}
`;

export const SubscriptionBadgeLabel = styled.span`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  b {
    font-weight: 600;
  }
`;

export const SubscriptionBadgeLink = styled.a`
  flex: 0 0 auto;
  padding: 8px 12px;
  background: #fff;
  border-radius: 100px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.BLACK};
  line-height: 16px;
  font-weight: 500;
`;
