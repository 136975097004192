import {
  CoursesFiltersState,
  CoursesFiltersActions,
  CoursesFiltersConstants,
  CoursesFilter,
} from './coursesFilters.model';

const initialState: CoursesFiltersState = {
  status: {
    isLoading: false,
    isLoaded: false,
  },
  filters: {},
};

export const coursesFiltersReducer = (
  state: CoursesFiltersState = initialState,
  { type, payload }: CoursesFiltersActions
): CoursesFiltersState => {
  switch (type) {
    case CoursesFiltersConstants.REQUEST_COURSES_FILTERS_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };
    case CoursesFiltersConstants.REQUEST_COURSES_FILTERS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          isLoaded: true,
          isLoading: false,
        },
        filters: {
          ...state.filters,
          ...(payload as CoursesFilter),
        },
      };
    case CoursesFiltersConstants.REQUEST_COURSES_FILTERS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoaded: true,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
