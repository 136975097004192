import React, { ReactElement, useContext } from 'react';

import { PresetsContext } from '../../../contexts';
import { ImageResourceModel } from '../../../models';
import { mediaPreset } from '../../../services';
import { getSrcSetString } from './imageDefault.helper';

type ImageDefaultContainerProps = {
  imageResource: ImageResourceModel;
  mediaDomain?: string;
  proxyOrigin?: string;
  preset: string;
  width?: number;
  height?: number;
  alt?: string;
  title?: string;
  useMaxQuality?: boolean;
};

export const ImageDefaultContainer = ({
  imageResource,
  mediaDomain,
  proxyOrigin,
  preset,
  width,
  height,
  alt,
  title,
  useMaxQuality = false,
}: ImageDefaultContainerProps): ReactElement | null => {
  const context = useContext(PresetsContext);
  const mediaPresets = context?.mediaPresets;
  const currentMediaPreset = mediaPresets ? mediaPresets[preset] : null;

  if (mediaPresets && currentMediaPreset) {
    const imageSrc = mediaPreset.getImageSet(
      currentMediaPreset,
      imageResource,
      mediaDomain,
      proxyOrigin
    );

    if (!imageSrc) {
      return null;
    }

    const srcSet = getSrcSetString(imageSrc);

    if (imageSrc._3x && useMaxQuality) {
      return (
        <img
          src={imageSrc._3x}
          width={width}
          height={height}
          alt={alt}
          title={title}
        />
      );
    }

    if (imageSrc._1x) {
      return (
        <img
          src={imageSrc._1x}
          srcSet={srcSet ? srcSet : undefined}
          width={width}
          height={height}
          alt={alt}
          title={title}
        />
      );
    }

    return null;
  }

  return null;
};
