import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useWindowSize, WindowSize } from '../../hooks';
import { SliderContainerProps } from './slider.model';
import {
  getScrollNodeState,
  getScrollOffset,
  getSlideToScrollIndex,
  scrollToSlide,
} from './slider.service';
import {
  SliderContainerStyled,
  SliderControlIcon,
  SliderControlStyle,
  SliderListStyled,
  SliderListWrapStyled,
} from './slider.styled';
import { SliderControlContainer, SliderControlTypes } from './sliderControl';
import { SliderItemContainer } from './sliderItem';

let isScrollingTimeoutId: ReturnType<typeof setTimeout> | null = null;

export const SliderContainer = ({
  items,
  spaceBetweenItems = 20,
  itemWidth,
  activeIndex = 0,
  handlePrevSlideClick,
  handleNextSlideClick,
  handleSliderScroll,
  itemsToScroll = 1,
  loop = false,
  withoutControls = false,
  controlIcon = SliderControlIcon.arrow,
  controlsStyle = SliderControlStyle.square,
  isMobile,
  controlsInside = false,
  controlsOutsideContainer = false,
  centeredList = false,
}: SliderContainerProps): ReactElement => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(!loop && activeIndex === 0);
  const [end, setEnd] = useState<boolean>(
    !loop && activeIndex === items.length - 1
  );
  const [maxScroll, setMaxScroll] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const listRef = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
  const { width: windowWidth }: WindowSize = useWindowSize();

  useEffect(() => {
    if (listRef.current) {
      const offset = getScrollOffset(listRef.current, 0);
      setOffset(offset);
      listRef.current.addEventListener('scroll', handleScroll);
    }

    return (): void => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      setMaxScroll(
        Math.ceil(
          listRef.current.scrollWidth -
            listRef.current.getBoundingClientRect().width
        )
      );
    }
  }, [listRef]);

  useEffect(() => {
    if (listRef.current) {
      if (!loop) {
        setStart(offset === 0);
        setEnd(!!offset && maxScroll <= offset);
      }
      scrollToSlide(listRef.current, offset);
    }
  }, [offset]);

  useEffect(() => {
    if (listRef.current) {
      const offset = getScrollOffset(listRef.current, activeIndex);
      setMaxScroll(
        listRef.current.scrollWidth -
          listRef.current.getBoundingClientRect().width
      );
      setOffset(offset);
    }
  }, [windowWidth]);

  const handleScroll = (): void => {
    if (isScrollingTimeoutId) {
      clearTimeout(isScrollingTimeoutId);
    }

    setIsScrolling(true);

    isScrollingTimeoutId = setTimeout(function () {
      if (listRef.current) {
        setIsScrolling(false);
        const scrollDirection: SliderControlTypes =
          listRef.current.scrollLeft > offset
            ? SliderControlTypes.next
            : SliderControlTypes.prev;
        const activeIndex = getSlideToScrollIndex(
          listRef.current,
          itemsToScroll,
          scrollDirection,
          loop
        );

        setOffset(listRef.current.scrollLeft);

        if (!loop) {
          const { isEnd, isStart } = getScrollNodeState(listRef.current);
          setEnd(isEnd);
          setStart(isStart);
        }

        if (!!handleSliderScroll) {
          handleSliderScroll(activeIndex);
        }
      }
    }, 75);
  };

  const handlePrevClick = (): void => {
    if (listRef.current && !isScrolling) {
      const prevSlideIndex = getSlideToScrollIndex(
        listRef.current,
        itemsToScroll,
        SliderControlTypes.prev,
        loop
      );
      const offset = getScrollOffset(listRef.current, prevSlideIndex);
      setOffset(offset);

      if (handlePrevSlideClick) {
        handlePrevSlideClick();
      }
    }
  };

  const handleNextClick = (): void => {
    if (listRef.current && !isScrolling) {
      const nextSlideIndex = getSlideToScrollIndex(
        listRef.current,
        itemsToScroll,
        SliderControlTypes.next,
        loop
      );
      const offset = getScrollOffset(listRef.current, nextSlideIndex);
      setOffset(offset);

      if (handleNextSlideClick) {
        handleNextSlideClick();
      }
    }
  };

  const disabledPrevBtn = (!loop && start) || maxScroll === 0;
  const disabledNextBtn = (!loop && end) || maxScroll === 0;
  const isCenteredList = centeredList && maxScroll === 0;

  const sliderControls = !withoutControls && (
    <>
      <SliderControlContainer
        isMobile={isMobile}
        disabled={disabledPrevBtn}
        handleClick={handlePrevClick}
        type={SliderControlTypes.prev}
        controlIcon={controlIcon}
        controlsStyle={controlsStyle}
        controlsInside={controlsInside}
      />
      <SliderControlContainer
        isMobile={isMobile}
        disabled={disabledNextBtn}
        handleClick={handleNextClick}
        type={SliderControlTypes.next}
        controlIcon={controlIcon}
        controlsStyle={controlsStyle}
        controlsInside={controlsInside}
      />
    </>
  );

  return (
    <>
      <SliderContainerStyled
        withoutControls={withoutControls}
        spaceBetweenItems={spaceBetweenItems}
        isMobile={isMobile}
        controlsInside={controlsInside}
        disabledPrevBtn={disabledPrevBtn}
        disabledNextBtn={disabledNextBtn}
      >
        <SliderListStyled>
          <SliderListWrapStyled ref={listRef} centeredList={isCenteredList}>
            {items.map((item, idx) => (
              <SliderItemContainer
                spaceBetweenItems={spaceBetweenItems}
                key={idx}
                width={itemWidth}
                slide={item}
              />
            ))}
          </SliderListWrapStyled>
          {!controlsOutsideContainer && sliderControls}
        </SliderListStyled>
      </SliderContainerStyled>
      {controlsOutsideContainer && sliderControls}
    </>
  );
};
