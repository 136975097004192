import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { FilterTabStyled } from './filterTab.styled';
import { FilterTabModel } from './filterTab.model';
import {
  filterRouterService,
  getFilterOptionValueSelector,
  mainPath,
  urlService,
} from '@lerna-core';

export const FilterTab = ({
  option,
  filter,
  filterColor,
  defaultPathname,
}: FilterTabModel): ReactElement => {
  const router = useRouter();
  const isSelected = useSelector(
    getFilterOptionValueSelector(filter.slug, option.slug)
  );

  const handleClick = (): void => {
    const defaultPath = router.query?.fastlink
      ? mainPath.courses
      : defaultPathname;
    const newQuery = filterRouterService.getNewRouteTab(
      router,
      filter.slug,
      option.slug
    );

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <FilterTabStyled
      activeColor={filterColor}
      onClick={handleClick}
      isActive={isSelected}
    >
      {option.value}
    </FilterTabStyled>
  );
};
