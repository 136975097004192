import React, { ReactElement } from 'react';
import { TopMenuLinkContainer } from './topMenuLink';
import { TopMenuLinksContainerStyled } from './topMenuLinks.styled';
import { getTopMenuElements } from '../../menu.selectors';
import { useSelector } from 'react-redux';
import { TopMenuLinksModel } from './topMenuLinks.model';

export const TopMenuLinksContainer = ({
  handleItemClick,
}: TopMenuLinksModel): ReactElement => {
  const topMenuLinks = useSelector(getTopMenuElements);

  return (
    <TopMenuLinksContainerStyled
      children={topMenuLinks.map((link) => (
        <TopMenuLinkContainer
          {...link}
          key={`${link.link}${link.title}`}
          handleClickCallback={handleItemClick}
        />
      ))}
    />
  );
};
