import { FooterConstants } from './footer.constants';
import { FooterActions, FooterModel, FooterState } from './footer.model';

const initialState: FooterState = {
  images: [],
  status: {
    isLoading: false,
    isLoaded: false,
  },
};

export function footerReducer(
  state: FooterState = initialState,
  action: FooterActions
): FooterState {
  switch (action.type) {
    case FooterConstants.REQUEST_FOOTER_START:
      return { ...state, status: { ...state.status, isLoading: true } };

    case FooterConstants.REQUEST_FOOTER_SUCCESS:
      const { images } = action.payload as FooterModel;

      return {
        ...state,
        images,
        status: { ...state.status, isLoading: false, isLoaded: true },
      };

    case FooterConstants.REQUEST_FOOTER_ERROR:
      return { ...state, status: { ...state.status, isLoading: false } };

    default:
      return state;
  }
}
