import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconChevronComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7496 19.5L8.24963 12L15.7496 4.5"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);
