import { RequestHeader } from '../../services';
import { UserClient } from './user.client';
import { UserAggregateModel } from './user.model';

export async function userClientProvider(
  requestHeaders?: RequestHeader
): Promise<UserAggregateModel> {
  const response = await new UserClient(requestHeaders).get();

  return response as unknown as Promise<UserAggregateModel>;
}
