import {
  FilterOptionsTypes,
  FilterPopupOptions,
  FilterSearchType,
} from './filterPopup.model';
import { MetaPaginationModel } from '../../models';

export const getOptionsToRender = (
  options: FilterPopupOptions,
  searchType: FilterSearchType,
  searchString: string,
  labelKey: string
): FilterPopupOptions => {
  switch (searchType) {
    case FilterSearchType.local:
      return options.filter((option) =>
        (option[labelKey] as string)
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    case FilterSearchType.api:
      return options;
  }
};

export const getShowSelectAllOption = (
  optionType: FilterOptionsTypes,
  searchType: FilterSearchType,
  searchString: string,
  hideSelectAllOption?: boolean
): boolean => {
  const searchLength = searchString.trim().length;

  switch (searchType) {
    case FilterSearchType.local:
      return (
        !searchLength &&
        !hideSelectAllOption &&
        optionType === FilterOptionsTypes.checkbox
      );
    case FilterSearchType.api:
      return !hideSelectAllOption && optionType === FilterOptionsTypes.checkbox;
  }
};

export const getHasNextPage = (meta?: MetaPaginationModel): boolean =>
  !!(meta && meta.currentPage < meta.totalPages);
