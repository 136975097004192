class RequestId {
  private static instance: RequestId;
  private requestId = '';

  public static getInstance(): RequestId {
    if (!RequestId.instance) {
      RequestId.instance = new RequestId();
    }

    return RequestId.instance;
  }

  public set(requestId: string): void {
    this.requestId = requestId;
  }

  public get get(): string {
    return this.requestId;
  }
}

export const requestIdService = RequestId.getInstance();
