import { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { RequestHeader } from '../../services';
import { companyClientProvider } from './company.clientProvider';
import * as constants from './company.constants';
import { Company, CompanyActions } from './company.model';

export const requestCompany = async (
  dispatch: Dispatch<CompanyActions>,
  uuid: string,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await companyClientProvider(uuid, requestHeaders)
    .then((response) => {
      dispatch(requestCompanySuccess(response));
    })
    .catch((error) => {
      dispatch(requestCompanyError(error));
    });
};

export const requestCompanySuccess = (payload: Company): CompanyActions => ({
  type: constants.REQUEST_COMPANY_SUCCESS,
  payload,
});

export const requestCompanyError = (payload: AxiosError): CompanyActions => ({
  type: constants.REQUEST_COMPANY_ERROR,
  payload,
});
