import { createSelector } from 'reselect';

import { RootState } from '../../models';
import { Department, DepartmentsState } from './departments.model';

export const getDepartments = createSelector(
  (state: RootState<DepartmentsState>) => state.departments,
  (reducer: DepartmentsState) => reducer.departments as Department[]
);

export const getIsDepartmentsLoading = createSelector(
  (state: RootState<DepartmentsState>) => state.departments,
  (reducer: DepartmentsState) => reducer.isLoading
);

export const getIsDepartmentsLoaded = createSelector(
  (state: RootState<DepartmentsState>) => state.departments,
  (reducer: DepartmentsState) => reducer.isLoaded
);
