import {
  CustomersItemsAfterChange,
  CustomersLoading,
} from './customersTransfer.model';
import { CustomerTableData } from './transferTable';

export const getDefaultColWidth = (
  columnsCount: number,
  tablesCount: number
): number => {
  const pageMaxWidth = 1440;
  // todo: react-table не умеет принимать % поэтому пока что хардкод из ширины экрана + отступы
  // 80 внутренние отступу страницы
  // 44 ширина колонки с чекбоксом
  // 32 внутренние отступы карточки
  // 78 ширина контролов между таблицами
  // 1 бордер таблицы

  const offset = 80 + 44 * tablesCount + 32 * 2 + 78 + 1 * 2 * tablesCount;

  return (pageMaxWidth - offset) / tablesCount / columnsCount;
};

export const getCustomerItemsAfterRemove = (
  added: CustomerTableData[],
  removed: CustomerTableData[],
  moved: CustomerTableData[]
): CustomersItemsAfterChange => {
  const initialValue = { added: [...added], removed: [...removed] };

  return moved.reduce(
    (
      acc: CustomersItemsAfterChange,
      movedItem: CustomerTableData
    ): CustomersItemsAfterChange => {
      const hasAdded = acc.added.some(
        (addedItem) => addedItem.uuid === movedItem.uuid
      );

      if (hasAdded) {
        acc.added = acc.added.filter(
          (addedItem) => addedItem.uuid !== movedItem.uuid
        );
      } else {
        acc.removed.push(movedItem);
      }

      return acc;
    },
    initialValue
  );
};

export const getCustomerItemsAfterAdd = (
  added: CustomerTableData[],
  removed: CustomerTableData[],
  moved: CustomerTableData[]
): CustomersItemsAfterChange => {
  const initialValue = { added: [...added], removed: [...removed] };

  return moved.reduce(
    (
      acc: CustomersItemsAfterChange,
      movedItem: CustomerTableData
    ): CustomersItemsAfterChange => {
      const hasRemoved = acc.removed.some(
        (removedItem) => removedItem.uuid === movedItem.uuid
      );

      if (hasRemoved) {
        acc.removed = acc.removed.filter(
          (removedItem) => removedItem.uuid !== movedItem.uuid
        );
      } else {
        acc.added.push(movedItem);
      }

      return acc;
    },
    initialValue
  );
};

export const getCustomersLoading = (
  currentPage: number,
  requestPage?: number
): CustomersLoading => {
  switch (true) {
    case requestPage && currentPage < requestPage:
      return {
        isPaginating: true,
        isLoading: false,
      };
    default:
      return {
        isPaginating: false,
        isLoading: true,
      };
  }
};
