import styled, { css } from 'styled-components';
import { fontSettings, lineClamping, transition } from '@lerna-core';
import { rgba } from 'polished';
import {
  ItemHeaderSphereLinkStyledProps,
  ItemHeaderSphereRectangleStyledProps,
  ItemHeaderSphereStyledProps,
} from './itemHeaderSphere.model';

const DEFAULT_BACKGROUND_OPACITY = 0.1;
const HOVERED_BACKGROUND_OPACITY = 0.2;
const TRANSITION = transition('background');

const ItemHeaderSphereRectangleStyled = styled.div<ItemHeaderSphereRectangleStyledProps>`
  &:after,
  &:before {
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:after {
    background: #fff;
    border-top-right-radius: 16px;
  }

  &:before {
    background: ${({ theme, sphereColor }): string =>
      sphereColor
        ? rgba(sphereColor, DEFAULT_BACKGROUND_OPACITY)
        : rgba(theme.COLOR.PURPLE_SUBSCRIPTION, DEFAULT_BACKGROUND_OPACITY)};
    ${TRANSITION}
  }
`;

export const ItemHeaderSphereStyled = styled.div<ItemHeaderSphereStyledProps>`
  display: flex;
  justify-content: flex-end;
  margin: 0 -20px 24px;

  ${({ isDisabled }): string => (isDisabled ? 'pointer-events: none;' : '')}
`;

export const ItemHeaderSphereLinkStyled = styled.div<ItemHeaderSphereLinkStyledProps>`
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_BLOCK + 1};
  position: relative;
  color: ${({ theme, sphereColor }): string =>
    sphereColor ? sphereColor : theme.COLOR.PURPLE_SUBSCRIPTION};
  background: ${({ theme, sphereColor }): string =>
    sphereColor
      ? rgba(sphereColor, DEFAULT_BACKGROUND_OPACITY)
      : rgba(theme.COLOR.PURPLE_SUBSCRIPTION, DEFAULT_BACKGROUND_OPACITY)};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  font-weight: 500;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 6px 24px;
  cursor: pointer;
  ${TRANSITION}

  ${({ theme }): ReturnType<typeof css> =>
    theme.IS_MOBILE
      ? css`
          flex: 1 1 auto;
          max-width: none;
          text-align: center;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 0;

          ${ItemHeaderSphereRectangleStyled} {
            &:before,
            &:after {
              content: none;
            }
          }
        `
      : css`
          max-width: 75%;
        `}
        
  &:hover {
    background: ${({ theme, sphereColor }): string =>
      sphereColor
        ? rgba(sphereColor, HOVERED_BACKGROUND_OPACITY)
        : rgba(theme.COLOR.PURPLE_SUBSCRIPTION, HOVERED_BACKGROUND_OPACITY)};

    ${ItemHeaderSphereRectangleStyled} {
      &:before {
        background: ${({ theme, sphereColor }): string =>
          sphereColor
            ? rgba(sphereColor, HOVERED_BACKGROUND_OPACITY)
            : rgba(
                theme.COLOR.PURPLE_SUBSCRIPTION,
                HOVERED_BACKGROUND_OPACITY
              )};
      }
    }
  }
`;

export const ItemHeaderSphereBottomRectangleStyled = styled(
  ItemHeaderSphereRectangleStyled
)`
  &:after,
  &:before {
    bottom: 0;
    right: 0;
    transform: translateY(100%);
  }
`;

export const ItemHeaderSphereTopRectangleStyled = styled(
  ItemHeaderSphereRectangleStyled
)`
  &:after,
  &:before {
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }
`;

export const ItemHeaderSphereValueStyled = styled.div`
  ${({ theme }): ReturnType<typeof css> =>
    !theme.IS_MOBILE
      ? css`
          ${lineClamping(1)}
        `
      : css``}
`;
