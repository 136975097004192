import React, { ReactElement } from 'react';

import { InputContainer } from './input.container';
import { InputProps } from './input.model';
import { InputStyled } from './input.styled';
import { InputColors } from '../../../../styles';
import { FloatingPlaceholderStyled } from '../../floatingPlaceholder';

export const Input = ({
  name,
  value,
  onReset,
  showResetButton = true,
  color = InputColors.white,
  bordered = true,
  setFieldValue,
  disabled,
  placeholder,
  floatingPlaceholder = true,
  hasError,
  readOnly,
  hintButton = null,
  ...props
}: InputProps): ReactElement => {
  return (
    <InputContainer
      name={name}
      onReset={onReset}
      showIcon={showResetButton && !!value && !disabled}
      setFieldValue={setFieldValue}
      hintButton={hintButton}
    >
      <InputStyled
        name={name}
        value={value}
        color={color}
        bordered={bordered}
        disabled={disabled}
        placeholder={placeholder}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
        readOnly={readOnly}
        {...props}
      />

      <>
        {floatingPlaceholder ? (
          <FloatingPlaceholderStyled
            hasError={hasError}
            htmlFor={name}
            children={placeholder}
            disabled={disabled}
            active={!!value}
          />
        ) : null}
      </>
    </InputContainer>
  );
};
