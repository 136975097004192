import React, { ReactElement } from 'react';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
  IconNextComponent,
  Nullable,
  PopupContainer,
  useTheme,
  useTranslations,
  useUser,
} from '@lerna-core';
import { modalPaddings } from './bonusesPopup.meta';
import { useBonusesPopup } from './bonusesPopup.hook';
import {
  getBonusesPriceConversion,
  getTelegramHref,
} from './bonusesPopup.helper';
import * as S from './bonusesPopup.styled';
import { BonusesPopupModel } from './bonusesPopup.model';
import Image from 'next/image';

export const BonusesPopup = ({
  currencies = [],
}: BonusesPopupModel): Nullable<ReactElement> => {
  const theme = useTheme();
  const { user } = useUser();
  const translations = useTranslations();
  const {
    handleClosePopup,
    isPopupOpened,
    incrementBonusesCounter,
    handleGoToTelegram,
  } = useBonusesPopup();

  const handleClose = () => {
    handleClosePopup();
    incrementBonusesCounter();
  };

  if (!user) {
    return null;
  }

  return (
    <PopupContainer
      styleSettings={modalPaddings}
      closeOutside
      isOpened={isPopupOpened}
      fullHeightMobile={false}
      hasHeader={theme.IS_MOBILE}
      handleClose={handleClose}
    >
      <S.BonusesPopup>
        <S.BonusesPopupTitle>
          {translations.get_gift_bonuses_title}
        </S.BonusesPopupTitle>
        <S.BonusesPopupSticker>
          {getBonusesPriceConversion(user.bonuses, translations, currencies)}
        </S.BonusesPopupSticker>
        <S.BonusesPopupSubtitle
          dangerouslySetInnerHTML={{
            __html: translations.get_gift_bonuses_subtitle,
          }}
        />
        <Image
          width={200}
          height={200}
          src="/img/gift_with_coins.png"
          alt="gift"
        />
        <S.BonusesPopupButton>
          <ButtonContainer
            fullWidth
            target="_blank"
            borderRadius={100}
            color={ButtonColor.purple}
            title={translations.go_to_telegram}
            childrenPosition={ButtonChildrenPosition.right}
            href={getTelegramHref(user.personal.email)}
            onClick={handleGoToTelegram}
          >
            <IconNextComponent strokeColor={theme.COLOR.WHITE} />
          </ButtonContainer>
        </S.BonusesPopupButton>
      </S.BonusesPopup>
    </PopupContainer>
  );
};
