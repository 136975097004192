import React, { ReactElement } from 'react';
import { RightPricesStyled } from './rightPrices.styled';
import { getPricesContent } from './rightPrice.meta';
import { useCustomCompensationFormContext } from '../../../customCompensationForm.context';

export const RightPricesContainer = (): ReactElement => {
  const {
    paymentContent,
    course,
    translations,
    isLernaPrime,
    values: { withCustomCompensation },
  } = useCustomCompensationFormContext();

  return (
    <RightPricesStyled>
      {getPricesContent(
        paymentContent,
        course,
        translations,
        withCustomCompensation,
        isLernaPrime
      )}
    </RightPricesStyled>
  );
};
