import styled from 'styled-components';
import { TableSortIconProps } from './tableSort.model';

export const TableSortButtonStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TableSortIconStyled = styled.div<TableSortIconProps>`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 2px;

  ${({ selected }): string => (selected ? '' : 'opacity: 0.4;')}

  svg {
    transform: rotate(180deg);
  }
`;
