import { ReactElement, useState } from 'react';

import { TransferProps } from './transfer.model';
import { TransferStyled } from './transfer.styled';

export const TransferContainer = ({
  Components,
  leftRows,
  leftColumns,
  rightRows,
  rightColumns,
  handleCheckedLeftCallback,
  handleCheckedRightCallback,
  leftHeaderComponent,
  rightHeaderComponent,
  translations,
  disabledLeft,
  disabledRight,
  componentsProps,
  allocatedTopBlock,
  unallocatedTopBlock,
}: TransferProps): ReactElement => {
  const [leftChecked, setLeftChecked] = useState<Record<string, unknown>[]>([]);
  const [rightChecked, setRightChecked] = useState<Record<string, unknown>[]>(
    []
  );

  const {
    TransferLeftContainer,
    TransferRightContainer,
    TransferControlsContainer,
  } = Components;

  const handleCheckedRight = (): void => {
    handleCheckedRightCallback({ moved: leftChecked });
  };

  const handleCheckedLeft = (): void => {
    handleCheckedLeftCallback({ moved: rightChecked });
  };

  const disabledLeftControl = !leftChecked.length || !!disabledLeft;
  const disabledRightControl = !rightChecked.length || !!disabledRight;

  return (
    <TransferStyled>
      <TransferLeftContainer
        getSelectedRows={setLeftChecked}
        leftRows={leftRows}
        leftColumns={leftColumns}
        headerComponent={leftHeaderComponent}
        translations={translations}
        unallocatedTopBlock={unallocatedTopBlock}
        {...componentsProps}
      />
      <TransferControlsContainer
        disabledLeft={disabledLeftControl}
        disabledRight={disabledRightControl}
        handleCheckedLeft={handleCheckedLeft}
        handleCheckedRight={handleCheckedRight}
      />
      <TransferRightContainer
        getSelectedRows={setRightChecked}
        rightRows={rightRows}
        rightColumns={rightColumns}
        headerComponent={rightHeaderComponent}
        translations={translations}
        allocatedTopBlock={allocatedTopBlock}
        {...componentsProps}
      />
    </TransferStyled>
  );
};
