import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconMonitorWithManComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 2c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h5.5a.5.5 0 0 1 0 1H7a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2h-2.5a.5.5 0 0 1 0-1H20c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4Zm-.397 1.647h16.79V17H3.602V3.647ZM12 5c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-2 5c-1.1 0-2 .9-2 2v1h8v-1c0-1.1-.9-2-2-2h-4Zm-4.7 4a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h3.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3H5.3Zm5 0a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h3.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3h-3.4Zm5 0a.3.3 0 0 0-.3.3v1.4a.3.3 0 0 0 .3.3h3.4a.3.3 0 0 0 .3-.3v-1.4a.3.3 0 0 0-.3-.3h-3.4Z"
      fill={fillColor}
    />
  </svg>
);
