import { logoAddAdapter } from './logoAdd.adapter';
import { publicConfig } from '../../../../services';
import { RequestClient } from '../../../../services/request/request.service';

export type AddLogoApiModel = {
  data: {
    response: AddLogo;
    success: string;
  };
};

export type AddLogo = {
  filename: string;
  url: string;
};

export class LogoAddClient extends RequestClient<
  FormData,
  AddLogoApiModel,
  AddLogo
> {
  protected path = 'ms-uploader-api/v1/image/add';
  protected requestParameters: FormData;
  protected adapter = logoAddAdapter;

  constructor(host = publicConfig.API_GATEWAY_URL, blob: Blob) {
    super(host, '', { 'Content-Type': `multipart/form-data` });
    this.requestParameters = new FormData();
    this.requestParameters.append('image', blob);
  }
}
