import styled from 'styled-components';

export const MessagePopupStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  svg:first-child {
    margin-bottom: 32px;
  }
`;

type MessagePopupStatusProps = {
  status: string;
  width?: number;
  height?: number;
};

export const MessagePopupStatusStyled = styled.div<MessagePopupStatusProps>`
  width: ${({ width }): number => (width ? width : 192)}px;
  height: ${({ height }): number => (height ? height : 192)}px;
  margin: 0 auto 32px;
  background: url('/img/${({ status }): string => status || 'error'}.svg')
    center / cover no-repeat;
`;
