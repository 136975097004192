import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconErrorComponent = ({
  width = 24,
  height = 24,
  fillColor = '#C7141A',
  strokeColor = '#fff',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill={fillColor} />
      <path
        d="m9.083 9.083 5.834 5.834M14.916 9.083l-5.833 5.834"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
