import {
  CourseItemsAfterChange,
  CoursesFilters,
  CoursesSearchTerms,
  SearchStateModel,
} from './coursesTransfer.model';
import { coursesColumnsWidth } from './coursesTransfer.meta';
import { CustomersLoading } from '../../customers';
import { CourseTableData } from './transferTable';

export const getCoursesSearchWidth = (): number => {
  return Object.values(coursesColumnsWidth).reduce(
    (acc, item) => acc + item,
    0
  );
};

export const getDefaultColWidth = (
  columnsCount: number,
  tablesCount: number
): number => {
  const pageMaxWidth = 1440;

  // todo: react-table не умеет принимать % поэтому пока что хардкод из ширины экрана + отступы
  // 80 внутренние отступу страницы
  // 44 ширина колонки с чекбоксом
  // 32 внутренние отступы карточки
  // 78 ширина контролов между таблицами
  // 1 бордер таблицы

  const offset = 80 + 44 * tablesCount + 32 * 2 + 78 + 1 * 2 * tablesCount;

  return (pageMaxWidth - offset) / tablesCount / columnsCount;
};

export const getCourseItemsAfterRemove = (
  added: CourseTableData[],
  removed: CourseTableData[],
  moved: CourseTableData[]
): CourseItemsAfterChange => {
  const initialValue = { added: [...added], removed: [...removed] };

  return moved.reduce(
    (
      acc: CourseItemsAfterChange,
      movedItem: CourseTableData
    ): CourseItemsAfterChange => {
      const hasAdded = acc.added.some(
        (addedItem) => addedItem.uuid === movedItem.uuid
      );

      if (hasAdded) {
        acc.added = acc.added.filter(
          (addedItem) => addedItem.uuid !== movedItem.uuid
        );
      } else {
        acc.removed.push(movedItem);
      }

      return acc;
    },
    initialValue
  );
};

export const getCourseItemsAfterAdd = (
  added: CourseTableData[],
  removed: CourseTableData[],
  moved: CourseTableData[]
): CourseItemsAfterChange => {
  const initialValue = { added: [...added], removed: [...removed] };

  return moved.reduce(
    (
      acc: CourseItemsAfterChange,
      movedItem: CourseTableData
    ): CourseItemsAfterChange => {
      const hasRemoved = acc.removed.some(
        (removedItem) => removedItem.uuid === movedItem.uuid
      );

      if (hasRemoved) {
        acc.removed = acc.removed.filter(
          (removedItem) => removedItem.uuid !== movedItem.uuid
        );
      } else {
        acc.added.push(movedItem);
      }

      return acc;
    },
    initialValue
  );
};

export const getCoursesLoading = (
  currentPage: number,
  requestPage?: number
): CustomersLoading => {
  switch (true) {
    case requestPage && currentPage < requestPage:
      return {
        isPaginating: true,
        isLoading: false,
      };
    default:
      return {
        isPaginating: false,
        isLoading: true,
      };
  }
};

export const getSearchInitialState = (
  coursesFilters: CoursesFilters
): SearchStateModel => {
  return {
    isOpen: !!coursesFilters.search,
    searchTerm: coursesFilters.searchTerm || CoursesSearchTerms.title,
  };
};
