import { useCoursesPageContext } from '@features/courses/coursesPage';
import {
  browserService,
  ButtonColor,
  ButtonContainer,
  DeviceInfoContext,
  getSelectedFiltersCountSelector,
  handleOpenFilters,
  IconFilterComponent,
  useTheme,
} from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchButtonContainerProps } from './searchButton.model';
import {
  SearchButtonContainerStyled,
  SearchButtonFiltersCountStyled,
} from './searchButton.styled';

export const SearchButtonContainer = ({
  isSticky,
  isFullButton,
}: SearchButtonContainerProps): ReactElement => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedFiltersCount = useSelector(getSelectedFiltersCountSelector);
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const coursesContext = useCoursesPageContext();
  const translations = coursesContext?.translations;

  const handleClick = (): void => {
    dispatch(handleOpenFilters(true));
  };

  const buttonTitle =
    isMobile && isFullButton ? translations?.filters_popup_title : '';

  return (
    <SearchButtonContainerStyled
      isSticky={isSticky}
      isMobile={isMobile}
      isFullButton={isMobile && isFullButton}
    >
      <ButtonContainer
        onClick={handleClick}
        color={ButtonColor.white}
        title={buttonTitle}
      >
        <>
          <IconFilterComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
          {selectedFiltersCount > 0 && (
            <SearchButtonFiltersCountStyled
              isSticky={isSticky}
              isMobile={isMobile}
            >
              {selectedFiltersCount}
            </SearchButtonFiltersCountStyled>
          )}
        </>
      </ButtonContainer>
    </SearchButtonContainerStyled>
  );
};
