import * as Yup from 'yup';
import { TranslationModel } from '../../../../models';
import { isPhoneValid } from '../../../inputs';
import { phoneRegex } from '../../../form';
import { FormB2BInitialValues, FormB2BValidationSchema } from './formB2B.model';
import { RegisterPopupFormValuesModel } from '../../registerPopup';
import { Department } from '../../../departments';
import { UserPositionWithOptions } from '../../../user';

export const getFormB2BInitialValues = (
  { firstName, lastName, phone }: RegisterPopupFormValuesModel,
  departments: Department[],
  positions: UserPositionWithOptions[]
): FormB2BInitialValues => {
  return {
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
    positionUuid: positions.find(({ isDefault }) => isDefault)?.key || '',
    departmentUuid: departments.find(({ isDefault }) => isDefault)?.key || '',
  };
};

export const getFormB2BValidationSchema = (
  translations: TranslationModel
): FormB2BValidationSchema => {
  return Yup.object().shape({
    firstName: Yup.string().required(translations.input_required_error_label),
    departmentUuid: Yup.string().required(
      translations.input_required_error_label
    ),
    positionUuid: Yup.string().required(
      translations.input_required_error_label
    ),
    lastName: Yup.string().required(translations.input_required_error_label),
    phone: Yup.string()
      .required(translations.input_required_error_label)
      .matches(phoneRegex, translations.input_phone_format_error_label)
      .test('phone', translations.input_phone_format_error_label, isPhoneValid),
  });
};
