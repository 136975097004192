import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { AccordionContainer } from '../../accordion';
import { AccordionModel } from '../../accordion/accordion.model';
import { getFaqSelector } from '../../faq';
import { useTranslations } from '../../translations';
import { HelpFaqStyled, HelpFaqTitleStyled } from './helpFaq.styled';

export const HelpFaqContainer = (): ReactElement => {
  const faq = useSelector(getFaqSelector);
  const translations = useTranslations();

  const mapFaq = (): AccordionModel[] =>
    faq
      .filter((currentFaq) => currentFaq.shown)
      .map((currentFaq) => ({
        title: currentFaq.question,
        content: currentFaq.answer,
      }));

  return (
    <HelpFaqStyled>
      <HelpFaqTitleStyled>{translations?.help_faq_title}</HelpFaqTitleStyled>
      <AccordionContainer items={mapFaq()} />
    </HelpFaqStyled>
  );
};
