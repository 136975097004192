import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { fontSettings } from '../../../styles';

interface ActionHeaderCellProps {
  selectedLength: number;
  selectedCountLabel?: string;
  children: ReactNode;
}

const Container = styled.div`
  display: flex;
`;

const SelectedContainer = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  margin-right: 32px;
`;

export const ActionHeaderCell = ({
  selectedLength = 0,
  selectedCountLabel = 'Выбрано',
  children,
}: ActionHeaderCellProps): ReactElement => {
  return (
    <Container>
      <SelectedContainer>
        {selectedCountLabel}: {selectedLength}
      </SelectedContainer>

      {children}
    </Container>
  );
};
