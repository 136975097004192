import { Currency, CurrencyApiModel } from '../features/currencies';
import { ApiPriceModel } from '../models';

export const currencyPriceMask = '{{price}}';

export const mapCurrencies = (currencies: CurrencyApiModel[]): Currency[] => {
  return currencies.map((currency) => ({
    code: currency.iso_code,
    mask: currency.mask,
    isBase: currency.is_base,
  }));
};

export const mapPriceValue = (
  price: ApiPriceModel,
  currencies: Currency[]
): string => {
  const currentCurrency = currencies.find(
    (currency) => currency.code === price.currency
  );
  if (currentCurrency) {
    return currentCurrency.mask.replace(currencyPriceMask, `${price.amount}`);
  }

  return '';
};
