import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { FilterModel, getFilterCheckedOptionsSelector } from '@lerna-core';
import { MobileCheckboxTypeContainerProps } from './mobileCheckboxType.model';
import {
  FilterItemTypeTitleStyled,
  FilterItemTypeValueStyled,
} from '../filtersPopupMobile.styled';
import {
  MobileCheckboxTypeDescriptionStyled,
  MobileCheckboxTypeStyled,
  MobileCheckboxTypeWrapperStyled,
} from './mobileCheckboxType.styled';

export const MobileCheckboxTypeContainer = ({
  filter,
  withDescription,
  icon,
}: MobileCheckboxTypeContainerProps): ReactElement => {
  const checkedOptions = useSelector(
    getFilterCheckedOptionsSelector(filter.slug)
  );
  const getCheckedOptionsTitle = (): string => {
    return checkedOptions.map((option) => option.value).join(', ');
  };

  const description = (filter as FilterModel)?.description;
  const hasSelected = checkedOptions.length > 0;

  return (
    <MobileCheckboxTypeStyled>
      <MobileCheckboxTypeWrapperStyled>
        <FilterItemTypeTitleStyled hasSelected={hasSelected}>
          {icon && icon}
          {filter.title}
        </FilterItemTypeTitleStyled>
        {hasSelected && (
          <FilterItemTypeValueStyled>
            {getCheckedOptionsTitle()}
          </FilterItemTypeValueStyled>
        )}
      </MobileCheckboxTypeWrapperStyled>
      {description && withDescription && (
        <MobileCheckboxTypeDescriptionStyled>
          {description}
        </MobileCheckboxTypeDescriptionStyled>
      )}
    </MobileCheckboxTypeStyled>
  );
};
