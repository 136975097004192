import {
  CourseModel,
  CourseOptionLogicTypes,
  CourseOptionModel,
  FiltersSlugEnum,
  getCourseOption,
  IframeInitiator,
  mainPath,
  QueryParams,
  TranslationModel,
  urlService,
} from '@lerna-core';
import { HeaderBreadcrumbModel } from './headerBreadcrumbs.model';
import { pageUrls } from '@constants';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

const DISCOUNT_PROGRAM_PAGE_REPLACER = '{{discount}}';

const getCoursesPath = (query: ParsedUrlQuery): string => {
  const discount = query[QueryParams.discountProgram] as string;
  const iframeInitiator = query[QueryParams.initiator] as IframeInitiator;

  switch (true) {
    case !!discount:
      return `${mainPath.discountProgram.replace(
        DISCOUNT_PROGRAM_PAGE_REPLACER,
        discount
      )}`;
    case !!iframeInitiator:
      return mainPath.iframeCourses.replace(
        `[${QueryParams.initiator}]`,
        iframeInitiator
      );
    default:
      return pageUrls.courses;
  }
};

const getFastlinkLink = (
  router: NextRouter,
  courseSphere: CourseOptionModel,
  coursesPath: string
): string => {
  const discount = router.query[QueryParams.discountProgram] as string;

  switch (true) {
    case !!discount:
      return urlService.getFullUrlToPage(
        `${mainPath.discountProgram.replace(
          DISCOUNT_PROGRAM_PAGE_REPLACER,
          discount
        )}`,
        {
          ...router,
          query: {
            ...router.query,
            [FiltersSlugEnum.sphere]: courseSphere.slug,
          },
        },
        [FiltersSlugEnum.sphere, QueryParams.promoCode]
      );
    default:
      return urlService.getFullUrlToPage(
        `${coursesPath}/${courseSphere.slug}`,
        router,
        [QueryParams.promoCode]
      );
  }
};

export const getHeaderBreadcrumbs = (
  course: CourseModel,
  translations: TranslationModel,
  router: NextRouter
): HeaderBreadcrumbModel[] => {
  const coursesPath = getCoursesPath(router.query);

  const result = [
    {
      title: translations.catalog,
      link: urlService.getFullUrlToPage(coursesPath, router, [
        QueryParams.promoCode,
      ]),
    },
    {
      title: course.title,
    },
  ];
  const courseSphere = getCourseOption(
    course.options,
    CourseOptionLogicTypes.spheres
  );

  if (courseSphere) {
    result.splice(1, 0, {
      title: courseSphere.value,
      link: getFastlinkLink(router, courseSphere, coursesPath),
    });
  }

  return result;
};
