import { DESCRIPTION_HEIGHT } from './leftDescription.constants';
import { Nullable } from '@lerna-core';

export const needToCollapse = (
  descriptionHeight: Nullable<number>,
  isMobile: boolean
): boolean => {
  if (!descriptionHeight) return false;

  if (isMobile) {
    return descriptionHeight >= DESCRIPTION_HEIGHT.MOBILE;
  }

  return descriptionHeight >= DESCRIPTION_HEIGHT.DESKTOP;
};
