import React, { ReactElement } from 'react';
import { Nullable, publicConfig } from '@lerna-core';

export const FbPixelAllPagesContainer = (): Nullable<ReactElement> => {
  if (publicConfig?.USE_FB_PIXEL_ALL_PAGES !== 'true') {
    return null;
  }

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${publicConfig.FB_PIXEL_ALL_PAGES_ID}); 
          fbq('track', 'PageView');`,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          alt=""
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${publicConfig.FB_PIXEL_ALL_PAGES_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  );
};
