export const lineClamping = (lineCount = 2): string => `
  overflow: hidden;
  text-overflow: ellipsis;
  ${
    lineCount < 2
      ? 'white-space: nowrap;'
      : `
          display: -webkit-box;
          -webkit-line-clamp: ${lineCount};
          -webkit-box-orient: vertical;
        `
  }
`;

export const hideScrollbar = (): string => `
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const positionSticky = (): string => `
  position: relative;
  position: -webkit-sticky;
  position: sticky;
`;

export const textOverflowEllipsis = (): string => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
