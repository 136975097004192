import { Dispatch } from 'react';
import * as constants from './customersTransfer.constants';
import {
  CustomersFilters,
  CustomersItemsAfterChange,
  CustomersTableAction,
  CustomersTableAllRequestParams,
  CustomersTableRequestParams,
  CustomersTransferAllProvider,
  CustomersTransferProvider,
  CustomersTransferState,
} from './customersTransfer.model';
import { initialTransferCustomers } from './customersTransfer.reducer';
import { errorAction } from '../../error';
import { ToastActions } from '../../toast';
import { LocationType } from '../../transfer';
import { logger } from '../../../services';
import { customersTableAllAdapter } from '../customers.adapter';

export const toggleTransferCustomers =
  (changedCustomers: CustomersItemsAfterChange) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.TOGGLE_TRANSFER_CUSTOMERS,
      payload: changedCustomers,
    });
  };

export const initTransferCustomers =
  (transferCustomersState: CustomersTransferState) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.INIT_TRANSFER_CUSTOMERS,
      payload: transferCustomersState,
    });
  };

export const setTransferCustomersTotalCustomers =
  (totalCustomers: number) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.SET_TRANSFER_CUSTOMERS_TOTAL_CUSTOMERS,
      payload: totalCustomers,
    });
  };

export const clearTransferCustomers =
  () =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.CLEAR_TRANSFER_CUSTOMERS,
    });
  };

export const setTransferCustomersUnallocatedFilters =
  (customerFilters: CustomersFilters) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.SET_TRANSFER_UNALLOCATED_CUSTOMERS_FILTERS,
      payload: customerFilters,
    });
  };

export const setTransferCustomersAllocatedFilters =
  (customerFilters: CustomersFilters) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.SET_TRANSFER_ALLOCATED_CUSTOMERS_FILTERS,
      payload: customerFilters,
    });
  };

export const requestTransferAllocatedCustomersStart = (
  requestParams: CustomersTableRequestParams
) => ({
  type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_START,
  payload: requestParams,
});

export const requestTransferUnallocatedCustomersStart = (
  requestParams: CustomersTableRequestParams
) => ({
  type: constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_START,
  payload: requestParams,
});

export const requestTransferAllocatedCustomersError = () => ({
  type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_ERROR,
});

export const requestTransferUnallocatedCustomersError = () => ({
  type: constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_ERROR,
});

export const requestTransferCustomersUnallocated =
  (
    provider: CustomersTransferProvider,
    requestParams: CustomersTableRequestParams,
    concat?: boolean
  ) =>
  (dispatch: Dispatch<CustomersTableAction | ToastActions>): void => {
    dispatch(requestTransferUnallocatedCustomersStart(requestParams));

    provider({
      ...requestParams,
      locationType: LocationType.unallocated,
    })
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_SUCCESS,
          payload: { ...response, concat } as CustomersTableAction['payload'],
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get unallocated customers', err);
        dispatch(requestTransferUnallocatedCustomersError());
        dispatch(errorAction());
      });
  };

export const requestTransferCustomersAllocated =
  (
    provider: CustomersTransferProvider,
    requestParams: CustomersTableRequestParams,
    concat?: boolean
  ) =>
  (dispatch: Dispatch<CustomersTableAction | ToastActions>): void => {
    dispatch(requestTransferAllocatedCustomersStart(requestParams));

    if (
      !requestParams.id &&
      requestParams.added &&
      requestParams.added.length === 0
    ) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            dispatch({
              type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS,
              payload: initialTransferCustomers,
            })
          );
        }, 300);
      });

      return;
    }

    provider({
      ...requestParams,
      locationType: LocationType.allocated,
    })
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS,
          payload: { ...response, concat } as CustomersTableAction['payload'],
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get allocated customers', err);
        dispatch(requestTransferAllocatedCustomersError());
        dispatch(errorAction());
      });
  };

export const requestTransferCustomersAllUnallocated =
  (
    provider: CustomersTransferAllProvider,
    requestParams: CustomersTableAllRequestParams,
    concat?: boolean
  ) =>
  (dispatch: Dispatch<CustomersTableAction | ToastActions>): void => {
    dispatch({
      type: constants.REQUEST_ALL_TRANSFER_UNALLOCATED_CUSTOMERS_START,
    });

    provider({
      ...requestParams,
      locationType: LocationType.unallocated,
    })
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_SUCCESS,
          payload: { ...customersTableAllAdapter(response), concat },
        });
        dispatch({
          type: constants.SELECT_ALL_TRANSFER_UNALLOCATED_CUSTOMERS,
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get unallocated customers', err);
        dispatch({
          type: constants.REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_ERROR,
        });
      });
  };

export const requestTransferCustomersAllAllocated =
  (
    provider: CustomersTransferAllProvider,
    requestParams: CustomersTableAllRequestParams,
    concat?: boolean
  ) =>
  (dispatch: Dispatch<CustomersTableAction | ToastActions>): void => {
    dispatch({
      type: constants.REQUEST_ALL_TRANSFER_ALLOCATED_CUSTOMERS_START,
    });

    provider({
      ...requestParams,
      locationType: LocationType.allocated,
    })
      .then((response) => {
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS,
          payload: { ...customersTableAllAdapter(response), concat },
        });
        dispatch({
          type: constants.SELECT_ALL_TRANSFER_ALLOCATED_CUSTOMERS,
        });
      })
      .catch((err) => {
        logger.error('[ERROR]: can not get all allocated customers', err);
        dispatch({
          type: constants.REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_ERROR,
        });
      });
  };

export const toggleTransferCustomersPending =
  (state: boolean) =>
  (dispatch: Dispatch<CustomersTableAction>): void => {
    dispatch({
      type: constants.TOGGLE_TRANSFER_CUSTOMERS_PENDING,
      payload: state,
    });
  };
