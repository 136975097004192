import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, positionSticky } from '@lerna-core';

export const BonusesPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin: 32px 0;
  }

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        margin-top: 24px;
      `,
      theme.IS_MOBILE
    )}
`;

export const BonusesPopupTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin-bottom: 8px;
  max-width: 400px;
`;

export const BonusesPopupSubtitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 400;
  white-space: pre-wrap;
`;

export const BonusesPopupSticker = styled.div`
  display: flex;
  border-radius: 8px;
  background: linear-gradient(90deg, #9647fe 0%, #573df4 100%);
  color: ${({ theme }): string => theme.COLOR.WHITE};
  margin-bottom: 8px;
  padding: 8px 16px;
`;

export const BonusesPopupButton = styled.div`
  width: 100%;

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        ${positionSticky()}
        bottom: -32px;
        padding: 32px 0 32px;
        margin-bottom: -32px;
        background: ${({ theme }): string => theme.COLOR.WHITE};
      `,
      theme.IS_MOBILE
    )}
`;
