import { Dispatch } from 'react';
import { AxiosError } from 'axios';
import {
  REQUEST_EDUCATION_STREAMS_ERROR,
  REQUEST_EDUCATION_STREAMS_START,
  REQUEST_EDUCATION_STREAMS_SUCCESS,
} from './educationStreams.constants';
import {
  EducationStreamsActions,
  EducationStreamsModel,
} from './educationStreams.model';
import { educationStreamsClientProvider } from './educationStreams.clientProvider';
import { logger, RequestHeader } from '../../services';

export const requestEducationStreams = async (
  dispatch: Dispatch<EducationStreamsActions>,
  requestHeaders?: RequestHeader
): Promise<void> => {
  dispatch({
    type: REQUEST_EDUCATION_STREAMS_START,
  });

  await educationStreamsClientProvider(requestHeaders)
    .then((response) => {
      dispatch(requestEducationStreamsSuccess(response));
    })
    .catch((error) => {
      logger.error('[ERROR]: can not get education streams', error);
      dispatch(requestEducationStreamsError(error));
    });
};

export const requestEducationStreamsSuccess = (
  payload: EducationStreamsModel
): EducationStreamsActions => ({
  type: REQUEST_EDUCATION_STREAMS_SUCCESS,
  payload,
});

export const requestEducationStreamsError = (
  payload: AxiosError
): EducationStreamsActions => ({
  type: REQUEST_EDUCATION_STREAMS_ERROR,
  payload,
});
