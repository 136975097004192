import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '@lerna-core';

export const ListItemTitleStyled = styled.div`
  font-weight: 600;
  white-space: pre-wrap;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_22, props.theme.FONT)};

  ${lineClamping(3)}
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${(props): string =>
        fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
    `
  )}
`;
