import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconHelpComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
  strokeColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      width={width}
      height={height}
    >
      <circle
        cx="12"
        cy="16.75"
        r="1.25"
        transform="rotate(-180 12 16.75)"
        fill={fillColor}
      />
      <circle cx="12" cy="12" r="10" stroke={strokeColor} strokeWidth="2" />
      <path
        d="M9 10C9 8.34315 10.3431 7 12 7C12.8284 7 13.5784 7.33579 14.1213 7.87868C15.1452 8.90251 15 10.5 14.1213 11.3771C13.33 12.167 12.5 12.5 12 13.5"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
