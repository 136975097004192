import React, { ReactElement } from 'react';
import {
  InvalidPhoneMessageStyled,
  MessageButtonStyled,
  MessageIconStyled,
  MessageLinkStyled,
  MessageStyled,
  MessageTextStyled,
} from './invalidPhoneMessage.styled';
import {
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  IconAttentionComponent,
  isPhoneValid,
  Nullable,
  personalPath,
  projectSubDomains,
  urlService,
  useTheme,
  useUser,
} from '@lerna-core';
import { InvalidPhoneMessageContainerProps } from './invalidPhoneMessage.model';
import Link from 'next/link';

export const InvalidPhoneMessageContainer = ({
  translations,
}: InvalidPhoneMessageContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const { user } = useUser();

  const showMessage =
    user &&
    !isPhoneValid(user.personal.phone) &&
    !user.career?.withoutIncorrectPhonePopup &&
    !user.career?.isPersonalDataFillOptional;

  const userEditPage = urlService.getFullUrlToSubDomain(
    projectSubDomains.personal,
    personalPath.userEdit
  );

  if (!showMessage) return null;

  return (
    <Link href={userEditPage}>
      <InvalidPhoneMessageStyled>
        <MessageStyled>
          <MessageIconStyled>
            <IconAttentionComponent
              width={24}
              height={24}
              fillColor={theme.COLOR.ERROR[500]}
            />
          </MessageIconStyled>
          <MessageTextStyled>
            {translations?.invalid_phone_message_text}
            <MessageLinkStyled>{translations?.edit}</MessageLinkStyled>
          </MessageTextStyled>
        </MessageStyled>
        <MessageButtonStyled>
          <ButtonContainer
            color={ButtonColor.white}
            size={ButtonSize.small}
            borderRadius={8}
            title={translations?.edit}
          />
        </MessageButtonStyled>
      </InvalidPhoneMessageStyled>
    </Link>
  );
};
