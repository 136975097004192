import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { SearchUnallocatedContainerProps } from './searchUnallocated.model';
import {
  requestTransferCustomersUnallocated,
  setTransferCustomersUnallocatedFilters,
} from '../../customersTransfer.actions';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import {
  getCustomersTransferSelector,
  getCustomersTransferUnallocatedFiltersSelector,
} from '../../customersTransfer.selectors';
import { TableSearchContainer } from '../../../../table';
import { useSearch } from '../../../../../hooks';

export const SearchUnallocatedContainer = ({
  translations,
  handleSearchCancel,
}: SearchUnallocatedContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    benefitId,
    libraryId,
    tariffId,
  } = useCustomersTransferContext();
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCustomersTransferSelector);
  const { search: initialSearch, searchTerm } = useSelector(
    getCustomersTransferUnallocatedFiltersSelector
  );

  const onSearch = (search: string): void => {
    dispatch(setTransferCustomersUnallocatedFilters({ search }));
    dispatch(
      requestTransferCustomersUnallocated(customersProvider, {
        ...filters,
        id,
        added,
        removed,
        searchTerm,
        search,
        notRelatedWithSubscriptionId: subscriptionId,
        notRelatedWithBenefitId: benefitId,
        notRelatedWithLibraryId: libraryId,
        notRelatedWithTariffId: tariffId,
      })
    );
  };

  const { search, setSearch } = useSearch({
    onSearch,
    initialSearch,
  });

  return (
    <TableSearchContainer
      cancelText={translations.cancel}
      onCancelClick={handleSearchCancel}
      placeholder={translations.search}
      search={search || ''}
      onChange={setSearch}
    />
  );
};
