import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { PositionsAllocatedFilterContainerProps } from './positionsAllocatedFilter.model';
import {
  requestTransferCustomersAllocated,
  setTransferCustomersAllocatedFilters,
} from '../../customersTransfer.actions';
import { getCustomersTransferSelector } from '../../customersTransfer.selectors';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import { getUserPositions } from '../../../../user';
import { TableFilterContainer } from '../../../../table';

export const PositionsAllocatedFilterContainer = ({
  translations,
}: PositionsAllocatedFilterContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    libraryId,
    benefitId,
    tariffId,
  } = useCustomersTransferContext();
  const filterOptions = useSelector(getUserPositions);
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCustomersTransferSelector);

  const handleApplyFilter = (positionUuids: string[]): void => {
    dispatch(setTransferCustomersAllocatedFilters({ positionUuids }));
  };

  const handleRequest = (positionUuids: string[]): void => {
    dispatch(
      requestTransferCustomersAllocated(customersProvider, {
        ...filters,
        positionUuids,
        removed,
        added,
        id,
        relatedWithSubscriptionId: subscriptionId,
        relatedWithBenefitId: benefitId,
        relatedWithLibraryId: libraryId,
        relatedWithTariffId: tariffId,
      })
    );
  };

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.positionUuids}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.customer_profile_position_title}
    />
  );
};
