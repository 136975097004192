import styled from 'styled-components';

import { InputLabelStyledProps } from './inputLabel.model';
import { fontSettings } from '../../../styles';

export const InputLabelStyled = styled.div<InputLabelStyledProps>`
  font-weight: 500;
  margin-bottom: 4px;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR}};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
`;
