import styled, { css } from 'styled-components';
import {
  breakpoint,
  fontSettings,
  FormControl,
  InputStyled,
  transition,
} from '@lerna-core';
import { rgba } from 'polished';

export const ContentRightStyled = styled.div`
  padding: 40px;
  background-color: #fafafa;
  flex: 0 1 50%;
  box-sizing: border-box;

  ${FormControl} {
    &:not(:last-child) {
      margin: 0 0 24px;
    }
  }

  ${InputStyled} {
    background: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
    border-radius: 8px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  }

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
      background-color: unset;

      ${FormControl} {
        &:not(:last-child) {
          margin: 0 0 20px;
        }
      }
    `
  )}
`;

export const ButtonStyled = styled.div`
  button {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_18, theme.FONT)}
    padding: 15px 40px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      button {
        padding: 15px;
        width: 100%;
      }
    `
  )}
`;

export const AgreementStyled = styled.div`
  margin-top: 12px;

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}
  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid
      ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.2)};

    ${transition('border-color')}
    &:hover {
      border-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.4)};
    }
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    `
  )}
`;
