import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLogoutLComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3043 12.0005L2 12.0005"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.47825 15.4917L2 12.0008L5.47825 8.50988"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.97559 19.8546C8.52254 20.9473 10.3982 21.6001 12.4347 21.6001C17.7173 21.6001 21.9999 17.3019 21.9999 12.0001C21.9999 6.69828 17.7173 2.4001 12.4347 2.4001C10.3982 2.4001 8.52254 3.0529 6.97559 4.14555"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
