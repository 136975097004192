import { Dispatch } from 'react';

import { logger } from '../../services';
import {
  departmentsByCompanyClientProvider,
  departmentsClientProvider,
} from './departments.clientProvider';
import * as constants from './departments.constants';
import { Department, DepartmentsActions } from './departments.model';

export const requestDepartments = (
  dispatch: Dispatch<DepartmentsActions>,
  companyId?: string
): void => {
  const provider = companyId
    ? departmentsByCompanyClientProvider(companyId)
    : departmentsClientProvider();

  dispatch({
    type: constants.REQUEST_DEPARTMENTS_START,
  });

  provider
    .then((response) => {
      dispatch({
        type: constants.REQUEST_DEPARTMENTS_SUCCESS,
        payload: response as Department[],
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.REQUEST_DEPARTMENTS_ERROR,
        payload: err,
      });
      logger.error('[ERROR]: Can not get departments', err);
    });
};

export const requestDepartmentsSuccess = (
  payload: Department[]
): DepartmentsActions => ({
  type: constants.REQUEST_DEPARTMENTS_SUCCESS,
  payload,
});
