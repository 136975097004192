import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconGridComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <rect
      width="8"
      height="8"
      x="2.5"
      y="2.5"
      rx="2"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <rect
      width="8"
      height="8"
      x="13.5"
      y="2.5"
      rx="2"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <rect
      width="8"
      height="8"
      x="2.5"
      y="13.5"
      rx="2"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <rect
      width="8"
      height="8"
      x="13.5"
      y="13.5"
      rx="2"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
);
