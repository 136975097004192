import {
  RedirectApiModel,
  RedirectApiResponseModel,
  RedirectModel,
} from './redirect.model';

export const redirectAdapter = ({
  from_url,
  to_url,
  code,
}: RedirectApiModel): RedirectModel => ({
  fromUrl: from_url,
  toUrl: to_url,
  code,
});

export const redirectResponseAdapter = ({
  data,
}: RedirectApiResponseModel): RedirectModel => redirectAdapter(data.redirect);
