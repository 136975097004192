import React, { ReactElement } from 'react';
import { SearchSuggestContainerProps } from './searchSuggest.model';
import {
  SearchSuggestContainerStyled,
  SearchSuggestWrapperStyled,
} from './searchSuggest.styled';
import { SuggestItemContainer } from './suggestItem';

export const SearchSuggestContainer = ({
  suggestItems,
  term,
  handleClickSuggest,
  arrowIcon,
}: SearchSuggestContainerProps): ReactElement | null => {
  if (!suggestItems || suggestItems.length === 0) {
    return null;
  }

  return (
    <SearchSuggestContainerStyled>
      <SearchSuggestWrapperStyled>
        {suggestItems.map(
          (item): ReactElement => (
            <SuggestItemContainer
              item={item}
              key={item.title}
              term={term}
              handleClick={handleClickSuggest}
              arrowIcon={arrowIcon}
            />
          )
        )}
      </SearchSuggestWrapperStyled>
    </SearchSuggestContainerStyled>
  );
};
