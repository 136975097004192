import React, { memo, ReactElement } from 'react';
import {
  BannerSizeTypes,
  ImageContainer,
  Nullable,
  urlService,
} from '@lerna-core';
import { useHeaderBanner } from './headerBanner.hook';
import { HeaderBannerContainerProps } from './headerBanner.model';
import {
  HeaderBannerStyled,
  HeaderBannerLinkStyled,
} from './headerBanner.styled';

const BannerContainer = ({
  banners,
}: HeaderBannerContainerProps): Nullable<ReactElement> => {
  const { showBanners, bannerImageResources, banner } =
    useHeaderBanner(banners);

  const bannerImages = !!bannerImageResources && (
    <>
      {bannerImageResources[BannerSizeTypes.desktop] && (
        <ImageContainer
          imageResource={bannerImageResources[BannerSizeTypes.desktop]}
          preset="headerBannerDesktop"
          alt={BannerSizeTypes.desktop}
          useMaxQuality
        />
      )}
      {bannerImageResources[BannerSizeTypes.tablet] && (
        <ImageContainer
          imageResource={bannerImageResources[BannerSizeTypes.tablet]}
          preset="headerBannerTablet"
          alt={BannerSizeTypes.tablet}
          useMaxQuality
        />
      )}
      {bannerImageResources[BannerSizeTypes.mobile] && (
        <ImageContainer
          imageResource={bannerImageResources[BannerSizeTypes.mobile]}
          preset="headerBannerMobile"
          alt={BannerSizeTypes.mobile}
          useMaxQuality
        />
      )}
      {bannerImageResources[BannerSizeTypes.smallMobile] && (
        <ImageContainer
          imageResource={bannerImageResources[BannerSizeTypes.smallMobile]}
          preset="headerBannerSmallMobile"
          alt={BannerSizeTypes.smallMobile}
          useMaxQuality
        />
      )}
    </>
  );

  if (!showBanners) return null;

  return (
    <HeaderBannerStyled>
      {!!banner?.destinationUrl ? (
        <HeaderBannerLinkStyled
          href={banner.destinationUrl}
          {...(banner.isOpenNewWindow && {
            ...urlService.setTargetBlankOptions(),
          })}
        >
          {bannerImages}
        </HeaderBannerLinkStyled>
      ) : (
        bannerImages
      )}
    </HeaderBannerStyled>
  );
};

export const HeaderBannerContainer = memo(BannerContainer);
