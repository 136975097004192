import { css } from 'styled-components';
import { fontSettings } from '@lerna-core';
import { rgba } from 'polished';

export const FiltersInputStyles = css`
  input {
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid ${({ theme }): string => theme.COLOR.BLACK_12};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

    &:hover {
      border-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.24)};
    }

    &:focus,
    &:focus-within {
      border-color: ${({ theme }): string => theme.COLOR.PRIMARY[500]};
    }
  }
`;
