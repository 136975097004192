import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const FiltersContainerStyled = styled.div`
  position: relative;
  margin-right: 20px;
  margin-bottom: 56px;
  flex: 0 0 280px;
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_BLOCK};

  ${breakpoint(
    'xlargeDown',
    css`
      display: none;
    `
  )}
`;

export const FiltersStyled = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
`;
