import { ReactElement } from 'react';
import { BreadcrumbsItemContainer } from './breadcrumbsItem';
import * as S from './headerBreadcrumbs.styled';
import { useCourseContext } from '@features/course';
import { getHeaderBreadcrumbs } from './headerBreadcrumbs.helper';
import { useTranslations } from '@lerna-core';
import { useRouter } from 'next/router';
import { HeaderBreadcrumbsModel } from './headerBreadcrumbs.model';

export const HeaderBreadcrumbsContainer = ({
  hasBorder = true,
}: HeaderBreadcrumbsModel): ReactElement => {
  const course = useCourseContext();
  const router = useRouter();
  const translations = useTranslations();
  const breadcrumbs = getHeaderBreadcrumbs(course, translations, router);

  return (
    <S.HeaderBreadcrumbsWrapper hasBorder={hasBorder}>
      <S.HeaderBreadcrumbs>
        {breadcrumbs.map((item, index) => (
          <BreadcrumbsItemContainer
            hasDivider={index !== 0}
            key={`link_${index}`}
            {...item}
          />
        ))}
      </S.HeaderBreadcrumbs>
    </S.HeaderBreadcrumbsWrapper>
  );
};
