import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { FilterClearFilterContainerProps } from './filterClearFilter.model';
import { FilterClearContainerStyled } from './filterClearFilter.styled';
import {
  filterRouterService,
  handleClearFilter,
  handleSetSearchTerm,
  urlService,
} from '@lerna-core';

export const FilterClearFilterContainer = ({
  children,
  handleClear,
  filter,
  translations,
  defaultPathname,
}: FilterClearFilterContainerProps): ReactElement => {
  const router = useRouter();
  const dispatch = useDispatch();

  const handleClick = (): void => {
    if (filter) {
      if (handleClear) {
        handleClear();
      }

      dispatch(handleSetSearchTerm(''));
      dispatch(handleClearFilter(filter.slug, filter.type, translations));

      const newQuery = filterRouterService.clearFilter(filter, router);
      delete newQuery.title;

      router.push(
        urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
        undefined,
        { scroll: false, shallow: false }
      );
    }
  };

  return (
    <FilterClearContainerStyled onClick={handleClick}>
      {children}
    </FilterClearContainerStyled>
  );
};
