import { PageStatusCodes } from '../../constants';
import { AggregateResponseApiErrorModel, Nullable } from '../../models';

export const checkError = (
  data: AggregateResponseApiErrorModel,
  requiredKeys?: string[]
): Nullable<PageStatusCodes> => {
  const errorPrefix = 'error_';
  const requiredKeysWithPrefix = (requiredKeys || []).map(
    (requiredKey): string => `${errorPrefix}${requiredKey}`
  );
  let statusCode: PageStatusCodes | null = null;

  Object.keys(data).forEach((key) => {
    if (key.includes(errorPrefix) && requiredKeysWithPrefix.includes(key)) {
      if (statusCode !== PageStatusCodes.errorNotFound) {
        statusCode =
          data[key as keyof AggregateResponseApiErrorModel]?.http_status_code ||
          null;
      }
    }
  });

  return statusCode;
};
