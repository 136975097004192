import React, { ReactElement } from 'react';

import { LanguageItemContainer } from '../languageItem';
import { LanguageItemsStyled } from './languageItems.styled';

type LanguageItemsContainerProps = {
  supportedLocales: string[];
  handleClickItem?: () => void;
};

export const LanguageItemsContainer = ({
  supportedLocales,
  handleClickItem,
}: LanguageItemsContainerProps): ReactElement => {
  return (
    <LanguageItemsStyled>
      {supportedLocales.map((locale) => {
        return (
          <LanguageItemContainer
            key={locale}
            locale={locale}
            handleClickItem={handleClickItem}
          />
        );
      })}
    </LanguageItemsStyled>
  );
};
