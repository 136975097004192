import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const PriceValueContainerStyled = styled.div`
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
