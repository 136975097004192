import styled from 'styled-components';
import { fontSettings } from '../../../styles/mixins';

export const UserDataStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const UserDataNameStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  margin-left: 20px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
