import {
  CustomersApiModel,
  CustomersApiResponseModel,
  CustomersModel,
  CustomersRequestApiParams,
  CustomersRequestParams,
} from './customers.model';
import { mapCustomer } from '../customer';
import { mapMetaPaginationAdapter } from '../../adapters';
import { CustomersTransferModel } from './customersTransfer';

export const customersApiAdapter = (
  response: CustomersApiResponseModel
): CustomersModel => customersAdapter(response.data);

export const customersAdapter = ({
  data,
  meta,
}: CustomersApiModel): CustomersModel => ({
  data: data.map(mapCustomer),
  meta: mapMetaPaginationAdapter(meta.pagination),
});

export const customersRequestsParametersAdapter = ({
  limit,
  search,
  page,
  companyIds,
  tariffTypeUuids,
}: CustomersRequestParams): CustomersRequestApiParams => {
  const params: CustomersRequestApiParams = {};

  if (companyIds && companyIds.length) {
    params.company_ids = companyIds;
  }

  if (tariffTypeUuids && tariffTypeUuids.length) {
    params.tariff_type_ids = tariffTypeUuids;
  }

  if (search) {
    params.search_term = search;
  }

  if (limit) {
    params.limit = limit;
  }

  if (page) {
    params.page = page;
  }

  return params;
};

export const customersTableAllAdapter = (
  response: CustomersTransferModel[]
): CustomersTransferModel =>
  response.reduce(
    (
      acc: CustomersTransferModel,
      item: CustomersTransferModel
    ): CustomersTransferModel => {
      if (!acc.data) {
        acc.data = item.data;
      } else {
        acc.data = [...acc.data, ...item.data];
      }
      acc.meta = item.meta;

      return acc;
    },
    {} as CustomersTransferModel
  );
