import React, { ReactElement, useMemo } from 'react';
import { FilterPopupFooterContainerProps } from './filterPopupFooter.model';
import {
  FilterPopupFooterButtonStyled,
  FilterPopupFooterClearStyled,
  FilterPopupFooterContainerStyled,
} from './filterPopupFooter.styled';
import { ButtonColor, ButtonContainer, stringService } from '@lerna-core';
import { FilterClearFilterContainer } from '../../filtersClearFilter';
import { FiltersClearAllContainer } from '../../filtersClearAll';

export const FilterPopupFooterContainer = ({
  coursesCount,
  handleClose,
  activeFilter,
  translations,
  isMobile,
  filters,
  buttonSize,
  defaultPathname,
}: FilterPopupFooterContainerProps): ReactElement => {
  const btnStr = useMemo((): string => {
    if (activeFilter) {
      return translations?.apply_filter_label || '';
    }

    return `${
      translations?.show_filters_label
    } ${coursesCount} ${stringService.pluralTranslations(coursesCount, [
      translations?.course_label_1 || '',
      translations?.course_label_2 || '',
      translations?.course_label_many || '',
    ])}`;
  }, [filters]);

  const clearFilterStr = translations?.clear_filter_label;
  const clearFiltersStr = isMobile
    ? clearFilterStr
    : translations?.clear_filters_label;

  return (
    <FilterPopupFooterContainerStyled>
      <FilterPopupFooterClearStyled>
        {activeFilter ? (
          <FilterClearFilterContainer
            filter={activeFilter}
            translations={translations}
            defaultPathname={defaultPathname}
          >
            <>{clearFilterStr}</>
          </FilterClearFilterContainer>
        ) : (
          <FiltersClearAllContainer
            filters={filters}
            defaultPathname={defaultPathname}
          >
            <>{clearFiltersStr}</>
          </FiltersClearAllContainer>
        )}
      </FilterPopupFooterClearStyled>
      <FilterPopupFooterButtonStyled>
        <ButtonContainer
          title={btnStr}
          color={ButtonColor.purple}
          borderRadius={100}
          size={buttonSize}
          onClick={handleClose}
        />
      </FilterPopupFooterButtonStyled>
    </FilterPopupFooterContainerStyled>
  );
};
