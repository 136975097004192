import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, positionSticky } from '../../styles';
import { popupStyleSettings } from '../../features/popups/popup';
import { SearchContainerStyledProps } from './search.model';

export const SearchContainerStyled = styled.div<SearchContainerStyledProps>`
  padding: 0;
  position: relative;
  width: 100%;
  
  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        margin-top: -${popupStyleSettings.paddingContent.top};
        margin-bottom: -${popupStyleSettings.paddingContent.top};
      `,
      theme.IS_MOBILE
    )}
  
  form {
    display: flex;
    align-items: center;
    
    input {
      border-radius: ${({ hasSuggests, theme }): string =>
        hasSuggests && !theme.IS_MOBILE ? '16px 16px 0 0' : '100px'};
      color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
      background-color: ${({ theme }): string =>
        theme.IS_MOBILE ? theme.COLOR.BLACK_08 : theme.COLOR.WHITE};

      ${({ theme }): ReturnType<typeof css> | string =>
        theme.IS_MOBILE
          ? 'padding: 10px 37px 10px 14px;'
          : css`
              padding-left: 52px;
              padding-right: 44px;
            `}  
    }
    
    ${({ theme }): ReturnType<typeof css> | string =>
      breakpoint(
        'mediumDown',
        css`
          ${positionSticky};
          background-color: ${({ theme }): string => theme.COLOR.WHITE};
          z-index: ${(props): string => props.theme.ZINDEX.TOP_BLOCK};
          top: 0;
          margin-left: -20px;
          margin-right: -20px;
          padding: 16px 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        `,
        theme.IS_MOBILE
      )}`;

export const SearchFormStyled = styled.form`
  position: relative;
`;

export const SearchMagnifierStyled = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
`;

export const SearchClearStyled = styled.div`
  margin-left: 12px;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  font-weight: 500;
  cursor: pointer;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
`;
