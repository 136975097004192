import { ImageModel } from '@lerna-core';

export type CourseLogoContainerProps = {
  logo: ImageModel;
  logoSize?: CourseLogoSizeEnum;
};

export type CourseLogoPropsModel = {
  width: number;
  height: number;
  preset: string;
};

export type CourseLogoContainerStyledProps = {
  width: number;
  height: number;
  'data-element'?: string;
};

export enum CourseLogoSizeEnum {
  xbig = 'xbig',
  big = 'big',
  medium = 'medium',
  small = 'small',
}
