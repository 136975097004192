import { RequestClient, RequestHeader } from './request.service';
import { AxiosRequestConfig } from 'axios';

export abstract class SecureRequestClient<
  Params,
  ServerRequestResponse,
  RequestResponse
> extends RequestClient<Params, ServerRequestResponse, RequestResponse> {
  protected constructor(
    apiUrl: string,
    internalApiUrl?: string,
    additionalHeaders?: RequestHeader,
    requestConfigs?: AxiosRequestConfig
  ) {
    const secureHeaders: RequestHeader = {
      'Cache-Control': 'no-cache',
      ...additionalHeaders,
    };

    super(apiUrl, internalApiUrl, secureHeaders, requestConfigs);
  }

  protected setMiddlewares(): void {
    this.requestMiddlewares.forEach((middleware) => {
      // TODO: разобраться что должно быть внутри интерсептора
      // @ts-ignore
      this.axios.interceptors.request.use(middleware);
    });
    this.responseMiddlewares.forEach((middleware) => {
      this.axios.interceptors.response.use(middleware, this.errorMiddleware);
    });
  }
}
