import { createSelector } from 'reselect';
import { RootState } from '../../models';
import { EducationStreamsState } from './educationStreams.model';

export const getEducationStreams = createSelector(
  (state: RootState<EducationStreamsState>) => state.educationStreams,
  (reducer: EducationStreamsState) => reducer.educationStreams?.data
);

export const getEducationStreamsStatus = createSelector(
  (state: RootState<EducationStreamsState>) => state.educationStreams,
  (reducer: EducationStreamsState) => reducer.status
);
