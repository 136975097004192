import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCloseComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
    >
      <path
        d="M5 5l14 14M19 5L5 19"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
