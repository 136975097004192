import React, { ReactElement } from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { CenteredPopupContainer } from '@features/common/popups';
import {
  GranulePayFormContextProvider,
  useGranulePayFormContext,
} from './granulePayForm.context';
import { GranulePayFormContainerProps } from './granulePayForm.model';
import { FormContentContainer } from './formContent';

export const GranulePayFormContent = (): ReactElement => {
  const theme = useTheme();
  const { currentPopup, handlePopupClose } = useGranulePayFormContext();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer />
    </CenteredPopupContainer>
  );
};

export const GranulePayFormContainer = (
  props: GranulePayFormContainerProps
): Nullable<ReactElement> => (
  <GranulePayFormContextProvider props={props}>
    <GranulePayFormContent />
  </GranulePayFormContextProvider>
);
