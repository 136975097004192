import cookie from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import {
  cookieService,
  logger,
  UTM_COOKIE_NAME,
  utmService,
} from '../../services';

export const utmMiddleware = (
  context: NextPageContext | GetServerSidePropsContext
): NextPageContext | GetServerSidePropsContext => {
  const utmFromCookie = utmService.getUTMFromCookie(
    context.req?.headers?.cookie as string
  );
  const utmFromRouter = utmService.getUTMFromQuery(context?.query);
  const mappedUtmFromRouter = utmService.mapUTM(
    utmFromRouter,
    context?.req?.headers?.referer
  );

  const needSetCookie =
    !utmFromCookie ||
    (!!utmFromCookie &&
      !!utmFromRouter &&
      !utmService.compareUTM(utmFromCookie, mappedUtmFromRouter));

  if (needSetCookie) {
    try {
      context?.res?.setHeader(
        'Set-Cookie',
        cookie.serialize(
          cookieService.getCookieKey(UTM_COOKIE_NAME),
          JSON.stringify(mappedUtmFromRouter),
          {
            ...cookieService.cookiesDefaultProps,
            // 90 days
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
          }
        )
      );
    } catch (e) {
      logger.error('[ERROR]: invalid utm cookie value');
    }
  }

  return context;
};
