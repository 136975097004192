import { UserAvatarSize } from '../userAvatar.model';
import { UserAvatarPresetType } from './avatarImage.model';

export const getAvatarImageResource = (
  size: UserAvatarSize
): UserAvatarPresetType => {
  switch (size) {
    case UserAvatarSize.large:
      return UserAvatarPresetType.userAvatarLarge;
    case UserAvatarSize.big:
      return UserAvatarPresetType.userAvatarBig;
    case UserAvatarSize.xbig:
      return UserAvatarPresetType.userAvatarXBig;
    case UserAvatarSize.medium:
      return UserAvatarPresetType.userAvatarMedium;
    case UserAvatarSize.xsmall:
      return UserAvatarPresetType.userAvatarXSmall;
    case UserAvatarSize.small:
    default:
      return UserAvatarPresetType.userAvatarSmall;
  }
};
