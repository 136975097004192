import { rgba } from 'polished';
import styled from 'styled-components';
import { customizeScrollBar, fontSettings } from '../../../styles';
import { ITheme } from '../../themes';
import { TableBodyRowProps, TableBodyStyledProps } from './tableBody.model';

const getTableBodyScrollStyles = (scroll?: boolean): string =>
  scroll ? 'overflow: hidden;' : '';

export const TableBodyStyled = styled.tbody<TableBodyStyledProps>`
  position: relative;
  min-height: 500px;
  ${customizeScrollBar(20, 20, 4, false)}
  ${({ scroll }): string => getTableBodyScrollStyles(scroll)}
  height: ${({ height }): string => (height ? `${height}px` : 'auto')};
  border-radius: 0 0 16px 16px;
  overflow-x: hidden;
  overflow-y: auto;

  tr:last-child td:after {
    content: none;
  }
`;

const getHoverStyles = (theme: ITheme, hasHover: boolean): string => {
  if (hasHover) {
    return `
      cursor: pointer;
      &:hover {
        background-color: ${rgba(theme.COLOR.BLACK, 0.04)};
      }
    `;
  }

  return '';
};

export const TableBodyDataStyled = styled.td<TableBodyDataStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 10px;
  text-align: left;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme, disabled }): string =>
    Boolean(disabled)
      ? theme.COLOR.TEXT_LIGHT_COLOR
      : theme.COLOR.TEXT_DARK_COLOR};
  font-weight: normal;

  &:first-child {
    padding-left: 20px;

    &:after {
      left: 20px;
    }
  }

  &:last-child {
    padding-right: 20px;

    &:after {
      right: 20px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.COLOR.BLACK_08};
  }
`;

export const TableBodyRowStyled = styled.tr<TableBodyRowProps>`
  display: flex;
  align-items: stretch;
  flex: 1;
  position: relative;

  ${({ theme, hasHover }): string => getHoverStyles(theme, hasHover)}
  &:last-child:not(:first-child) {
    ${TableBodyDataStyled} {
      &:after {
        content: none;
      }
    }
  }
`;

export type TableBodyDataStyledProps = {
  disabled?: boolean;
};
