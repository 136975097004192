import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconsCrossComponent = ({
  width = 20,
  height = 20,
  fillColor = '#C7141A',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16876 3.33239C4.00328 3.33244 3.84158 3.38184 3.70432 3.47429C3.56706 3.56675 3.46048 3.69804 3.39822 3.8514C3.33595 4.00476 3.32082 4.1732 3.35476 4.33521C3.3887 4.49721 3.47017 4.64541 3.58876 4.76085L8.82342 9.99691L3.58876 15.233C3.50893 15.3096 3.44519 15.4015 3.40128 15.5031C3.35737 15.6047 3.33418 15.7141 3.33305 15.8248C3.33192 15.9355 3.35289 16.0453 3.39472 16.1478C3.43656 16.2502 3.49841 16.3434 3.57667 16.4216C3.65493 16.4999 3.74801 16.5618 3.85047 16.6036C3.95294 16.6455 4.06271 16.6664 4.17338 16.6653C4.28405 16.6642 4.39338 16.641 4.49497 16.5971C4.59656 16.5531 4.68836 16.4894 4.76501 16.4095L9.99967 11.1735L15.2343 16.4095C15.311 16.4894 15.4028 16.5532 15.5044 16.5971C15.606 16.641 15.7153 16.6642 15.826 16.6653C15.9366 16.6664 16.0464 16.6455 16.1489 16.6036C16.2513 16.5618 16.3444 16.4999 16.4227 16.4216C16.5009 16.3434 16.5628 16.2502 16.6046 16.1478C16.6465 16.0453 16.6674 15.9355 16.6663 15.8248C16.6652 15.7141 16.642 15.6047 16.5981 15.5031C16.5542 15.4015 16.4904 15.3096 16.4106 15.233L11.1759 9.99691L16.4106 4.76085C16.5308 4.64398 16.6129 4.49351 16.6461 4.32917C16.6794 4.16482 16.6622 3.99426 16.5969 3.83985C16.5315 3.68543 16.4211 3.55436 16.28 3.46381C16.1389 3.37326 15.9738 3.32745 15.8062 3.33239C15.5901 3.33883 15.385 3.42918 15.2343 3.58428L9.99967 8.82034L4.76501 3.58428C4.6875 3.50458 4.59479 3.44122 4.49238 3.39796C4.38997 3.35469 4.27993 3.3324 4.16876 3.33239Z"
      fill={fillColor}
    />
  </svg>
);
