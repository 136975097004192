import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPresentComponent = ({
  width = 32,
  height = 32,
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.35"
      d="M8 29.333H24C26.2093 29.333 28 27.5423 28 25.333V15.9997C28 14.5263 26.8067 13.333 25.3333 13.333H4V25.333C4 27.5423 5.79067 29.333 8 29.333Z"
      fill="url(#paint0_linear_14652_393234)"
    />
    <path
      d="M27.8894 8.52966L27.1694 5.96166C26.9707 5.25233 26.2347 4.83899 25.5254 5.03766L19.612 6.69632C19.8534 6.29899 20 5.83499 20 5.33366C20 3.86033 18.8067 2.66699 17.3334 2.66699C15.86 2.66699 14.6667 3.86033 14.6667 5.33366C14.6667 5.55766 14.7027 5.77099 14.7547 5.97899V6.02299C14.464 4.86299 13.2494 4.00033 12 4.00033C10.5267 4.00033 9.33335 5.19366 9.33335 6.66699C9.33335 7.77099 10.004 8.71899 10.9614 9.12433L3.70135 11.1603C2.99202 11.359 2.57868 12.095 2.77735 12.8043L3.49735 15.3723C3.69602 16.0817 4.43202 16.495 5.14135 16.2963L26.9654 10.1737C27.6747 9.97499 28.088 9.23899 27.8894 8.52966Z"
      fill="url(#paint1_linear_14652_393234)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14652_393234"
        x1="4"
        y1="13.333"
        x2="30.3832"
        y2="20.2725"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF95C2" />
        <stop offset="0.234306" stopColor="#E48ACB" />
        <stop offset="0.526042" stopColor="#C092F5" />
        <stop offset="0.802083" stopColor="#9B9FFF" />
        <stop offset="1" stopColor="#8DA7FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14652_393234"
        x1="2.72754"
        y1="2.66699"
        x2="29.5577"
        y2="11.3381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF95C2" />
        <stop offset="0.234306" stopColor="#E48ACB" />
        <stop offset="0.526042" stopColor="#C092F5" />
        <stop offset="0.802083" stopColor="#9B9FFF" />
        <stop offset="1" stopColor="#8DA7FF" />
      </linearGradient>
    </defs>
  </svg>
);
