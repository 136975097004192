import { IconProps } from './icon.model';
import React, { ReactElement } from 'react';

export const IconCheckedCustomerComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C9.24746 2 7 4.24746 7 7C7 9.75254 9.24746 12 12 12C14.7525 12 17 9.75254 17 7C17 4.24746 14.7525 2 12 2ZM12 3.5C13.9419 3.5 15.5 5.05812 15.5 7C15.5 8.94188 13.9419 10.5 12 10.5C10.0581 10.5 8.5 8.94188 8.5 7C8.5 5.05812 10.0581 3.5 12 3.5ZM17.5 12C14.4625 12 12 14.4625 12 17.5C12 20.5375 14.4625 23 17.5 23C20.5375 23 23 20.5375 23 17.5C23 14.4625 20.5375 12 17.5 12ZM6.25 14C5.0095 14 4 15.0095 4 16.25V16.6309C4 17.8079 4.48975 18.9394 5.34375 19.7344C6.45325 20.7679 8.5085 22 12 22C12.2745 22 12.5369 21.9901 12.7939 21.9756C12.3754 21.5351 12.0174 21.0376 11.7344 20.4941C8.89687 20.4356 7.25071 19.4607 6.36621 18.6367C5.81571 18.1242 5.5 17.3934 5.5 16.6309V16.25C5.5 15.8365 5.8365 15.5 6.25 15.5H11.3174C11.4899 14.966 11.7318 14.4635 12.0293 14H6.25ZM20.5 15C20.6279 15 20.7558 15.0487 20.8535 15.1465C21.049 15.342 21.049 15.658 20.8535 15.8535L16.8535 19.8535C16.756 19.951 16.628 20 16.5 20C16.372 20 16.244 19.951 16.1465 19.8535L14.1465 17.8535C13.951 17.658 13.951 17.342 14.1465 17.1465C14.342 16.951 14.658 16.951 14.8535 17.1465L16.5 18.793L20.1465 15.1465C20.2442 15.0487 20.3721 15 20.5 15Z"
      fill={fillColor}
    />
  </svg>
);
