import { publicConfig } from '../../../services';
import { RequestClient } from '../../../services/request/request.service';
import { mapUserProfileRequestParams } from './userProfile.helper';
import {
  UserProfileRequestParamsApiModel,
  UserProfileRequestParamsModel,
} from './userProfile.model';

export class UserProfileClient extends RequestClient<
  UserProfileRequestParamsApiModel,
  unknown,
  unknown
> {
  protected path = 'user-api/v1/me/profile';
  protected requestParameters: UserProfileRequestParamsApiModel;

  constructor(params: UserProfileRequestParamsModel) {
    super(publicConfig.API_GATEWAY_URL);

    this.requestParameters = mapUserProfileRequestParams(params);
  }
}
