import { VendorModel, VendorsActions, VendorsState } from './vendors.model';
import {
  REQUEST_VENDORS_ERROR,
  REQUEST_VENDORS_META_SUCCESS,
  REQUEST_VENDORS_PAGINATE_SUCCESS,
  REQUEST_VENDORS_START,
  REQUEST_VENDORS_SUCCESS,
  START_REQUEST_VENDORS_PAGINATE_SUCCESS,
} from './vendors.constants';
import { MetaPaginationModel } from '../../models';

const initialMeta: MetaPaginationModel = {
  total: 0,
  count: 0,
  perPage: 30,
  currentPage: 1,
  totalPages: 1,
  links: {},
};

const initialState: VendorsState = {
  vendors: [],
  meta: initialMeta,
  status: {
    isPaginating: false,
    isLoading: false,
    isLoaded: false,
  },
};

export const vendorsReducer = (
  state: VendorsState = initialState,
  action: VendorsActions
): VendorsState => {
  switch (action.type) {
    case REQUEST_VENDORS_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case REQUEST_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload as VendorModel[],
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case REQUEST_VENDORS_PAGINATE_SUCCESS:
      return {
        ...state,
        vendors: [...state.vendors, ...(action.payload as VendorModel[])],
        status: {
          ...state.status,
          isPaginating: false,
          isLoading: false,
          isLoaded: true,
        },
      };

    case START_REQUEST_VENDORS_PAGINATE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          isPaginating: true,
        },
      };

    case REQUEST_VENDORS_META_SUCCESS:
      return {
        ...state,
        meta: action.payload as MetaPaginationModel,
      };

    case REQUEST_VENDORS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isPaginating: false,
          isLoading: false,
          isLoaded: true,
        },
      };

    default:
      return state;
  }
};
