import { Nullable, BannerImageModel, BannerImageResources } from '@lerna-core';

export const getBannerImageResources = (
  images?: BannerImageModel[]
): Nullable<BannerImageResources> => {
  if (!images) return null;

  return images.reduce((urlsObj, image) => {
    return Object.assign(urlsObj, {
      [image.sizeType]: image.image.imageResource,
    });
  }, {});
};
