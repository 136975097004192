import React, {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

import { useTheme } from '../../../../hooks';
import { IconCheckComponent } from '../../../icons';
import {
  FormCheckboxContainer,
  IconContainerStyled,
  IndeterminateMark,
} from './checkbox.styled';

export interface CheckboxProps {
  checked?: boolean;
  value?: string;
  children?: ReactNode;
  name?: string;
  indeterminate?: boolean;
  readOnly?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasError?: boolean;
  checkboxValue?: string;
  checkedColor?: string;
}

export const FormCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function RenderCheckbox(
    {
      value,
      children,
      checked,
      indeterminate,
      readOnly,
      disabled,
      hasError,
      checkboxValue,
      checkedColor,
      ...props
    },
    ref
  ): ReactElement {
    const theme = useTheme();
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef: MutableRefObject<HTMLInputElement> =
      (ref as MutableRefObject<HTMLInputElement>) || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = !!indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <FormCheckboxContainer readOnly={readOnly} disabled={disabled}>
        <input
          hidden
          type="checkbox"
          ref={resolvedRef}
          checked={checked}
          value={checkboxValue}
          readOnly={readOnly}
          {...props}
        />
        <IconContainerStyled
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          tabIndex={0}
          hasError={hasError}
          checkedColor={checkedColor}
        >
          {checked && <IconCheckComponent strokeColor={theme.COLOR.WHITE} />}
          {indeterminate && <IndeterminateMark color={theme.COLOR.WHITE} />}
        </IconContainerStyled>

        {value}
        {children}
      </FormCheckboxContainer>
    );
  }
);
