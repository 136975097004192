import React, { ReactElement } from 'react';

import { PopupTitleStyled } from './popupTitle.styled';
import { PopupTitleContainerProps } from './popupTitle.model';
import { Nullable } from '../../../../models';

export const PopupTitleContainer = ({
  text,
}: PopupTitleContainerProps): Nullable<ReactElement> => {
  if (!text) {
    return null;
  }

  return <PopupTitleStyled>{text}</PopupTitleStyled>;
};
