import {
  BackgroundColorsModel,
  gradientBackground0Styles,
  gradientBackground1Styles,
  gradientBackground2Styles,
  IframeInitiator,
  Nullable,
  UserAggregateModel,
} from '@lerna-core';

// TODO: переделать кастомизацию бэкграунда на ответ из бэка
const GREY_COLOR = '#F0F3F6';

export const getBackgroundColors = (
  user: Nullable<UserAggregateModel>,
  isDiscountProgramPage: boolean,
  isCoursePage = false,
  initiator?: Nullable<IframeInitiator>
): BackgroundColorsModel => {
  switch (true) {
    case initiator === IframeInitiator.edstein:
    case initiator === IframeInitiator.finincubator:
      return {
        backgroundLayer0Styles: `background: #FFF;`,
        backgroundLayer1Styles: `background: #FFF;`,
        backgroundLayer2Styles: `background: #FFF;`,
      };
    case Boolean(user?.career?.companyId):
    case isDiscountProgramPage:
    case isCoursePage:
      return {
        backgroundLayer0Styles: `background: ${GREY_COLOR};`,
        backgroundLayer1Styles: `background: ${GREY_COLOR};`,
        backgroundLayer2Styles: `background: ${GREY_COLOR};`,
      };
    default:
      return {
        backgroundLayer0Styles: `${gradientBackground0Styles}`,
        backgroundLayer1Styles: `${gradientBackground1Styles}`,
        backgroundLayer2Styles: `${gradientBackground2Styles}`,
      };
  }
};
