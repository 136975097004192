import { Dispatch } from 'react';
import { FooterConstants } from './footer.constants';
import { logger, RequestHeader } from '../../services';
import { FooterActions, FooterModel } from './footer.model';
import { footerClientProvider } from './footer.clientProvider';
import { AxiosError } from 'axios';

export const requestFooter = async (
  dispatch: Dispatch<FooterActions>,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await footerClientProvider(requestHeaders)
    .then((response) => {
      dispatch(requestFooterSuccess(response));
    })
    .catch((error) => {
      logger.error('[ERROR]: can not get footer data', error);
      dispatch(requestFooterError(error));
    });
};

export const requestFooterSuccess = (payload: FooterModel): FooterActions => ({
  type: FooterConstants.REQUEST_FOOTER_SUCCESS,
  payload,
});

export const requestFooterError = (payload: AxiosError): FooterActions => ({
  type: FooterConstants.REQUEST_FOOTER_ERROR,
  payload,
});
