import styled from 'styled-components';
import { accordionSpoilerVerticalPadding } from '../accordionSpoiler';

export const AccordionItemStyled = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  &:last-child {
    margin-bottom: -${accordionSpoilerVerticalPadding};
  }
`;
