import { useCallback } from 'react';
import { useShowLegalEntitiesPriceHook, useUserTariff } from '@hooks';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  CoursePricesModel,
  pushDataLayer,
  TranslationModel,
} from '@lerna-core';
import { UseCompanyBonusPointsCompensationTagModel } from './companyBonusPointsCompensationTag.model';
import { getCourseEventData } from '@features/course';
import { ListItemTagsEvents } from '../listItemTags.analytics';

export const useCompanyBonusPointsCompensationTag = (
  price: CoursePricesModel,
  translations: TranslationModel,
  course: CourseModel,
  courseIndex?: number
): UseCompanyBonusPointsCompensationTagModel => {
  const { isLernaPrime } = useUserTariff();
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const companyCompensation =
    price?.priceCustomer?.compensation?.discount?.amount;
  const hasCompanyCompensation =
    !!companyCompensation && !!parseInt(companyCompensation);
  const bonusPointsCompensation = price?.priceCustomer?.discountBonuses?.amount;
  const hasBonusPointsCompensation =
    !!bonusPointsCompensation && !!parseInt(bonusPointsCompensation);

  const showTag =
    isLernaPrime &&
    !showLegalEntitiesPrice &&
    hasBonusPointsCompensation &&
    !hasCompanyCompensation;
  const tagLabel = isLernaPrime
    ? `${translations.discount_bonuses_label}: ${bonusPointsCompensation}`
    : `-${bonusPointsCompensation} ${translations?.from_company_label}`;
  const tooltipContent =
    translations?.company_bonus_points_compensation_tag_label;

  const handleTooltipOpen = useCallback((): void => {
    pushDataLayer(
      getCourseEventData(
        ListItemTagsEvents.clickIconInfoCompanyPointsDiscount,
        CommonEventTypes.coursesEvents,
        CommonEventPositions.coursesPageList,
        course,
        courseIndex
      )
    );
  }, []);

  return {
    showTag,
    tagLabel,
    tooltipContent,
    handleTooltipOpen,
  };
};
