import { ToastActions, ToastState } from './toast.model';
import { ToastConstants } from './toast.constants';

const initialState: ToastState = {
  message: '',
  type: null,
  visible: false,
};

export function toastReducer(
  state: ToastState = initialState,
  action: ToastActions
): ToastState {
  switch (action.type) {
    case ToastConstants.SET_TOAST:
      return { ...state, ...action.payload };

    case ToastConstants.CLEAR_TOAST:
      return initialState;

    default:
      return state;
  }
}
