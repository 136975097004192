import { customerActivityClientProvider } from './customerActivity.clientProvider';
import { logger } from '../../../services';

export class CustomerActivityService {
  private readonly REFRESH_TIME = 60 * 60 * 12 * 1000; // 12 часов
  private refreshTimerId: NodeJS.Timeout | null = null;
  public static instance: CustomerActivityService;

  public static getInstance = (): CustomerActivityService => {
    if (!CustomerActivityService.instance) {
      CustomerActivityService.instance = new CustomerActivityService();
    }

    return CustomerActivityService.instance;
  };

  public init(): void {
    this.updateCustomerActivity();
  }

  private setRefreshProcedure(): void {
    const nextRefreshTime = this.REFRESH_TIME;

    if (this.refreshTimerId !== null) {
      clearTimeout(this.refreshTimerId);
    }

    this.refreshTimerId = setTimeout(() => {
      this.updateCustomerActivity();
    }, nextRefreshTime);
  }

  protected updateCustomerActivity(): void {
    customerActivityClientProvider()
      .catch((err) => {
        logger.error("[ERROR]: can't update customer last activity", err);
      })
      .finally(async () => {
        this.setRefreshProcedure();
      });
  }
}

export const customerActivityService = CustomerActivityService.getInstance();
