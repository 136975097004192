import {
  publicConfig,
  RequestClient,
  RequestHeader,
  serverConfig,
  ShowcaseApiModel,
  ShowcaseErrorModel,
  mapShowcaseDefaultRequestProps,
  UserAggregateModel,
  ShowcaseAggregatePathEnum,
  ShowcaseRequestProps,
} from '@lerna-core';
import { CoursesPageModel } from './courses.model';
import { coursesAdapter } from './courses.adapter';

export class CoursesClient extends RequestClient<
  ShowcaseRequestProps,
  ShowcaseApiModel,
  CoursesPageModel | ShowcaseErrorModel
> {
  protected path = `${ShowcaseAggregatePathEnum.aggregate}`;
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesAdapter;

  constructor(
    params: ShowcaseRequestProps,
    headers?: RequestHeader,
    user?: UserAggregateModel
  ) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );
    const token = user?.career?.companyId;
    this.requestParameters = mapShowcaseDefaultRequestProps(params);
    if (!!user) {
      if (!!token) {
        this.path = `${ShowcaseAggregatePathEnum.aggregateAuthWithToken}/${token}`;
      } else {
        this.path = ShowcaseAggregatePathEnum.aggregateAuth;
      }
    }
  }
}

export class CoursesPageLazyClient extends CoursesClient {
  protected path = '';
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesAdapter;

  constructor(path: string, headers?: RequestHeader) {
    super({}, headers);
    this.apiUrl = '';
    this.path = path;
    this.requestParameters = {};
  }
}
