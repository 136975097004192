import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconMarketingComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.98 2.5a1.25 1.25 0 0 0-1.229 1.266v1.249H3.767a1.25 1.25 0 1 0 0 2.497h.416v17.894c0 2.513 2.065 4.577 4.578 4.577h5.057l-2.844 5.685a1.247 1.247 0 0 0 1.024 1.83 1.251 1.251 0 0 0 1.21-.715l3.4-6.8h6.784l3.4 6.8a1.249 1.249 0 1 0 2.234-1.115l-2.844-5.685h5.057c2.513 0 4.578-2.064 4.578-4.577V7.512h.416a1.25 1.25 0 1 0 0-2.497H21.25V3.766A1.248 1.248 0 0 0 19.98 2.5ZM6.68 7.512h13.115c.134.022.27.022.405 0h13.12v17.894c0 1.163-.917 2.08-2.081 2.08h-6.895a1.237 1.237 0 0 0-.164-.016 1.25 1.25 0 0 0-.224.016h-7.91a1.25 1.25 0 0 0-.397 0H8.761a2.063 2.063 0 0 1-2.08-2.08V7.512Zm16.214 4.143a1.249 1.249 0 0 0-1.229 1.267v9.155a1.248 1.248 0 1 0 2.497 0V12.92a1.246 1.246 0 0 0-1.268-1.266Zm5.828 2.497a1.25 1.25 0 0 0-1.23 1.266v6.659a1.248 1.248 0 1 0 2.498 0v-6.659a1.247 1.247 0 0 0-1.268-1.266Zm-11.655 2.497a1.249 1.249 0 0 0-1.23 1.266v4.162a1.248 1.248 0 1 0 2.498 0v-4.162a1.248 1.248 0 0 0-1.268-1.266Zm-5.828 2.497a1.249 1.249 0 0 0-1.229 1.266v1.665a1.248 1.248 0 1 0 2.498 0v-1.665a1.248 1.248 0 0 0-1.269-1.266Z"
        fill={fillColor}
      />
    </svg>
  );
};
