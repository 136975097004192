import styled from 'styled-components';
import { TagLabelContainerStyled } from '@lerna-core';
import { ListItemTagLabelHover } from '../listItemTags.mixin';

export const SubscriptionTagContainerStyled = styled.div`
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_BLOCK};

  ${TagLabelContainerStyled} {
    display: inline-block;
    vertical-align: top;

    ${ListItemTagLabelHover}
  }
`;
