import styled from 'styled-components';
import { FilterPopupOptionsStyledProps } from './popupBody.model';

export const FilterPopupOptionsStyled = styled.div<FilterPopupOptionsStyledProps>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-height: 300px;

  ${({ hasSearch }): string =>
    !hasSearch ? 'padding: 20px 0 12px;' : 'padding: 0 0 12px'}
`;

export const FilterPopupSpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
