import styled from 'styled-components';
import { rgba } from 'polished';
import { transition } from '@lerna-core';
import { ToggleStyledProps } from './toggle.model';

export const ToggleStyled = styled.div<ToggleStyledProps>`
  position: relative;
  display: inline-block;
  padding: 2px;
  z-index: 1;
  vertical-align: top;
  height: 24px;
  width: 44px;
  border-radius: 100px;
  background: #eceffc;
  cursor: pointer;
  transition: background 0.4s ease;
  box-sizing: border-box;

  background-color: ${({ isActive, theme, activeColor }): string =>
    isActive ? activeColor || theme.COLOR.PRIMARY[900] : theme.COLOR.BLACK_12};

  ${({ disabled }): string =>
    disabled ? 'pointer-events: none; opacity:0.4;' : ''};

  ${transition('background-color')}

  ${({ isActive, theme }): string =>
    !isActive
      ? `
    &:hover {
      background-color: ${rgba(theme.COLOR.BLACK, 0.24)};
    }
  `
      : ''};
`;

export const ToggleDotStyled = styled.div<{ isActive: boolean }>`
  background: ${(props): string => props.theme.COLOR.WHITE};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: auto;
  transition: margin 0.4s;

  ${({ isActive }): string =>
    isActive ? 'margin-left: calc(100% - 20px);' : ''}
`;
