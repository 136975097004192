import styled from 'styled-components';
import { fontSettings } from '../../../../styles/mixins';

export const SocialItemContainerStyled = styled.a`
  display: block;
  margin-right: 12px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_9, theme.FONT)};
  text-decoration: none;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
`;
