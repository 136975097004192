import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { advantagesBreakpoint } from '../coursesAdvantages.styled';

export const AdvantagesCustomerBannersContainerStyled = styled.div`
  display: flex;

  ${advantagesBreakpoint} {
    width: 100%;
    justify-content: center;
  }

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    `
  )}
`;

export const CustomerBannerContainerStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.WHITE};
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
`;

export const CustomerBannerLabelStyled = styled.div`
  opacity: 0.64;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
