import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../styles';
import { rgba } from 'polished';

type FooterSberInfoStyledProps = {
  hasJivoChat: boolean;
};

export const FooterSberInfoStyled = styled.div<FooterSberInfoStyledProps>`
  ${breakpoint(
    'mediumDown',
    css`
      align-items: center;
      text-align: center;
    `
  )}

  ${({ hasJivoChat }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumUp',
      css`
        padding-bottom: ${hasJivoChat ? '24' : '0'}px;
      `
    )};
`;

export const FooterSberInfoTextStyled = styled.p`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)};
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.7)};
  white-space: pre-line;
  margin: 8px 0 0;
`;
