import React, { ReactElement, useRef } from 'react';
import { Nullable } from '../../models';
import { useHorizontalScroll } from './table.hooks';

import { TableProps } from './table.model';
import { TableStyled, TableWrapperStyled } from './table.styled';
import { TableBodyContainer } from './tableBody';
import { TableHeaderContainer } from './tableHeader';

export const TableContainer = ({
  table,
  isStickyHeader,
  headerComponent,
  handlePagination,
  hasEndlessPagination,
  handleRowClick,
  hasNextPage,
  isLoading,
  emptyContent,
  isPaginating,
  height,
  paginationLoader,
  loader,
  hasHorizontalScroll = false,
}: TableProps): ReactElement => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table;

  const tableBodyRef = useRef<Nullable<HTMLTableSectionElement>>(null);
  const tableRef = useRef<Nullable<HTMLTableElement>>(null);

  const [showLeftBlur, showRightBlur] = useHorizontalScroll(
    tableRef,
    tableBodyRef
  );

  return (
    <TableWrapperStyled hasHorizontalScroll={hasHorizontalScroll}>
      <TableStyled
        {...getTableProps()}
        ref={tableRef}
        hasHorizontalScroll={hasHorizontalScroll}
        showLeftBlur={showLeftBlur && hasHorizontalScroll}
        showRightBlur={showRightBlur && hasHorizontalScroll}
      >
        <TableHeaderContainer
          isSticky={isStickyHeader}
          headerGroups={headerGroups}
          headerComponent={headerComponent}
        />
        <TableBodyContainer
          ref={tableBodyRef}
          rows={rows}
          prepareRow={prepareRow}
          handlePagination={handlePagination}
          hasEndlessPagination={hasEndlessPagination}
          handleRowClick={handleRowClick}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          emptyContent={emptyContent}
          isPaginating={isPaginating}
          height={height}
          paginationLoader={paginationLoader}
          loader={loader}
          {...getTableBodyProps()}
        />
      </TableStyled>
    </TableWrapperStyled>
  );
};
