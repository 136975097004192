import React, {
  ChangeEvent,
  cloneElement,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { FileInputStyled } from './fileUpload.styled';
import { FileUploadAcceptEnum } from './fileUpload.enum';
import { FileUploadContainerProps } from './fileUpload.model';

export const FileUploadContainer = ({
  children,
  onUpload,
  name = 'file',
  accept = FileUploadAcceptEnum.all,
}: FileUploadContainerProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback((): void => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      onUpload(e);
      (e.target as HTMLInputElement).value = '';
    },
    [onUpload]
  );

  const renderChildren = useMemo(
    (): ReactElement => cloneElement(children, { onClick: handleClick }),
    []
  );

  const inputAccept = useMemo(() => {
    if (Array.isArray(accept)) {
      return accept.join(', ');
    }

    return accept;
  }, [accept]);

  return (
    <>
      <FileInputStyled
        ref={inputRef}
        name={name}
        type="file"
        accept={inputAccept}
        onChange={handleUpload}
      />
      {renderChildren}
    </>
  );
};
