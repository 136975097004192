import React, { ReactElement } from 'react';
import { getDurationsString } from '@lerna-core';
import {
  CourseLogoStyled,
  DurationStyled,
  RightHeadStyled,
  TitleStyled,
  VendorLogoStyled,
  WrapperStyled,
} from './rightHead.styled';
import { RightHeadContainerProps } from './rightHead.model';
import { CourseVendorLogoContainer } from '@features/course';
import {
  CourseLogoContainer,
  CourseLogoSizeEnum,
} from '@features/course/courseLogo';

export const RightHeadContainer = ({
  course,
  isMobile,
  translations,
}: RightHeadContainerProps): ReactElement => {
  const vendorLogo = course.vendor?.logo || course.vendor?.coursePlug;
  const courseLogoSize = isMobile
    ? CourseLogoSizeEnum.big
    : CourseLogoSizeEnum.xbig;

  return (
    <RightHeadStyled>
      <WrapperStyled>
        {vendorLogo && (
          <VendorLogoStyled>
            <CourseVendorLogoContainer logo={vendorLogo} />
          </VendorLogoStyled>
        )}
        <TitleStyled>{course.title}</TitleStyled>
        {course.durations && (
          <DurationStyled>
            {translations?.item_duration_label}:{' '}
            {getDurationsString(course.durations)}
          </DurationStyled>
        )}
      </WrapperStyled>
      {course.logo && (
        <CourseLogoStyled>
          <CourseLogoContainer logo={course.logo} logoSize={courseLogoSize} />
        </CourseLogoStyled>
      )}
    </RightHeadStyled>
  );
};
