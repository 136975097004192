import { pageUrls, projectDomains } from '@constants';
import {
  BackgroundContainer,
  browserService,
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
  ContentWrapperStyled,
  DeviceInfoContext,
  IconLernaLogoComponent,
  IconMyskillsLogoComponent,
  IconReloadComponent,
  publicConfig,
  urlService,
  useTheme,
} from '@lerna-core';
import { getBackgroundColors } from '@styles';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext } from 'react';
import {
  ErrorPageContainerStyled,
  ErrorPageHeaderStyled,
  ErrorPageContentStyled,
  TextStyled,
  TitleStyled,
} from './errorPage.styled';

export const ErrorPageContainer = (): ReactElement => {
  const router = useRouter();
  const deviceInfoContext = useContext(DeviceInfoContext);
  const theme = useTheme();
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const logoWidth = isMobile ? 105 : 137;
  const logoHeight = isMobile ? 28 : 37;

  const handleReloadClick = (): void => {
    router.reload();
  };

  return (
    <>
      <BackgroundContainer {...getBackgroundColors(null, false)} />

      <ContentWrapperStyled>
        <ErrorPageContainerStyled>
          <ErrorPageHeaderStyled>
            <a href={urlService.getFullUrlToPage(pageUrls.courses, router)}>
              {publicConfig.BASE_DOMAIN === projectDomains.myskillsTeam ? (
                <IconMyskillsLogoComponent
                  width={logoWidth}
                  height={logoHeight}
                />
              ) : (
                <IconLernaLogoComponent width={logoWidth} height={logoHeight} />
              )}
            </a>
          </ErrorPageHeaderStyled>

          <ErrorPageContentStyled>
            <TitleStyled>Ошибка сервера</TitleStyled>

            <TextStyled>Код ошибки: 500</TextStyled>

            <TextStyled>
              Попробуйте перезагрузить страницу или зайдите позже
            </TextStyled>

            <ButtonContainer
              color={ButtonColor.white}
              onClick={handleReloadClick}
              childrenPosition={ButtonChildrenPosition.left}
              title={'Перезагрузить страницу'}
            >
              <IconReloadComponent
                height={24}
                width={24}
                strokeColor={theme.COLOR.TEXT_DARK_COLOR}
              />
            </ButtonContainer>
          </ErrorPageContentStyled>
        </ErrorPageContainerStyled>
      </ContentWrapperStyled>
    </>
  );
};
