import { AdvantagesApiModel, AdvantagesModel } from './advantages.model';
import { mapImageResource } from '../../features';

export const advantagesAdapter = ({
  data,
}: AdvantagesApiModel): AdvantagesModel => ({
  image: {
    backgroundColor: data.image.data.image.data.background_color,
    size: data.image.data.image.data.size,
    imageResource: mapImageResource(data.image.data.image.data.image_resource),
  },
  background: data.background,
  color: data.color,
  title: data.title,
  subtitle: data.subtitle,
  privileges: data.privileges,
});
