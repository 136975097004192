import { TransferTableModel } from './transferTable.model';

export const coursesTableAllAdapter = (
  response: TransferTableModel[]
): TransferTableModel =>
  response.reduce(
    (acc: TransferTableModel, item: TransferTableModel): TransferTableModel => {
      if (!acc.data) {
        acc.data = item.data;
      } else {
        acc.data = [...acc.data, ...item.data];
      }
      acc.meta = item.meta;

      return acc;
    },
    {} as TransferTableModel
  );
