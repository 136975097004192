import React, { ReactElement, useMemo } from 'react';
import { urlService } from '../../services';
import { getCanonicalPath } from './canonical.helpers';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { CanonicalContainerProps } from './canonical.model';

export const CanonicalContainer = ({
  hrefWithWWW = false,
}: CanonicalContainerProps): ReactElement => {
  const router = useRouter();
  const path = useMemo(() => getCanonicalPath(router.asPath), [router.asPath]);
  const href = useMemo(
    () =>
      urlService.getFullUrlToPage(
        path,
        router,
        undefined,
        undefined,
        hrefWithWWW
      ),
    [path]
  );

  return (
    <Head>
      <link rel="canonical" href={href} />
    </Head>
  );
};
