import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FilterOptionModel, filterOptionsByTerm } from '../../../../catalog';

import { useTheme } from '../../../../hooks';
import { Nullable } from '../../../../models';
import { InputColors } from '../../../../styles';
import { IconArrowComponent } from '../../../icons';
import { useTranslations } from '../../../translations';
import { FloatingPlaceholderStyled } from '../../floatingPlaceholder';
import { useIsDropdownOpenBottom } from './select.hook';
import {
  SelectedValue,
  SelectLabelModel,
  SelectOptionModel,
  SelectOptionType,
  SelectProps,
} from './select.model';
import {
  SelectEmptySearchStyled,
  SelectStyled,
  SelectStyledArrow,
  SelectStyledButton,
  SelectStyledText,
} from './select.styled';
import { SelectDropdown } from './selectDropdown';
import { SelectOption } from './selectOptions/selectOption.container';
import { SelectSearchContainer } from './selectSearch';

const DEFAULT_OPTION_KEY = 'default';

const defaultOptionState = {
  key: DEFAULT_OPTION_KEY,
  value: '',
} as SelectOptionType;

export const Select = ({
  name = '',
  options,
  placeholder,
  defaultOption = defaultOptionState,
  onChange,
  disabled = false,
  bordered = true,
  activeHovered = true,
  color = InputColors.white,
  optionsComponent,
  selectLabelComponent,
  floatingPlaceholder,
  hasError,
  directionOpenTop = false,
  dropdownHeight = 270,
  className,
  withSearch = false,
}: SelectProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const [isOpened, setIsOpened] = useState(false);
  const [activeOption, setActiveOption] = useState(defaultOption);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<SelectOptionType[]>(options);
  const filterDropdownRef = useRef<Nullable<HTMLDivElement>>(null);

  const isOpenTop = useIsDropdownOpenBottom(
    filterDropdownRef,
    directionOpenTop,
    dropdownHeight
  );

  const isDefaultValue = useMemo(
    () => activeOption.key === DEFAULT_OPTION_KEY,
    [activeOption.key]
  );

  const handleToggleDropdown = useCallback((): void => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleSelect = useCallback(
    (option: SelectOptionType): void => {
      setActiveOption(option);
      onChange({ name, value: option } as SelectedValue);
      setIsOpened(false);
    },
    [name, onChange]
  );

  const SelectedLabelComponent = ({
    activeOption,
    isDefaultValue,
  }: SelectLabelModel): ReactElement => {
    const activePlaceholder = floatingPlaceholder ? '' : placeholder;
    const text = isDefaultValue ? activePlaceholder : activeOption.value;

    if (selectLabelComponent) {
      return selectLabelComponent({ isDefaultValue, activeOption });
    }

    return <SelectStyledText isDefaultValue={isDefaultValue} children={text} />;
  };

  const OptionsComponent = (props: SelectOptionModel): ReactElement => {
    const { options, activeOption, activeHovered, onClick } = props;

    if (optionsComponent) {
      return optionsComponent(props);
    }

    return (
      <>
        {options.map((option) => (
          <SelectOption
            activeHovered={activeHovered}
            onClick={onClick}
            key={option.key}
            option={option}
            active={activeOption.key === option.key}
          />
        ))}
      </>
    );
  };

  const selectSearch: Nullable<ReactElement> = withSearch ? (
    <SelectSearchContainer
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  ) : null;

  useEffect(() => {
    if (defaultOption && defaultOption.key !== activeOption.key) {
      setActiveOption(defaultOption);
    }
  }, [options]);

  useEffect(() => {
    const filtered = filterOptionsByTerm(
      searchTerm,
      options as unknown as FilterOptionModel[]
    );
    setFilteredOptions(filtered as unknown as SelectOptionType[]);
  }, [searchTerm, options]);

  const isActiveFloatingPlaceholder = !isDefaultValue;

  return (
    <SelectStyled
      ref={filterDropdownRef}
      disabled={disabled}
      bordered={bordered}
      floatingPlaceholder={floatingPlaceholder}
      hasError={hasError}
      color={color}
      className={className}
    >
      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          htmlFor={name}
          children={placeholder}
          active={isActiveFloatingPlaceholder}
          disabled={disabled}
        />
      ) : null}

      <SelectStyledButton onClick={handleToggleDropdown} />
      <SelectedLabelComponent
        activeOption={activeOption}
        isDefaultValue={isDefaultValue}
      />
      <SelectStyledArrow isOpened={isOpened}>
        <IconArrowComponent
          strokeColor={
            isOpened
              ? theme.COLOR.TEXT_DARK_COLOR
              : theme.COLOR.TEXT_LIGHT_COLOR
          }
        />
      </SelectStyledArrow>
      {isOpened && !!options.length && (
        <SelectDropdown
          ref={filterDropdownRef}
          handleOutsideClick={handleToggleDropdown}
          bordered={bordered}
          directionOpenTop={isOpenTop}
          dropdownHeight={dropdownHeight}
          search={selectSearch}
        >
          <OptionsComponent
            options={filteredOptions}
            activeOption={activeOption}
            activeHovered={activeHovered}
            onClick={handleSelect}
          />
          {withSearch && !filteredOptions.length && (
            <SelectEmptySearchStyled>
              {translations.request_not_found}
            </SelectEmptySearchStyled>
          )}
        </SelectDropdown>
      )}
    </SelectStyled>
  );
};
