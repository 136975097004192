import React, { ReactElement } from 'react';
import { ButtonColor, ButtonContainer } from '../button';
import { LoaderContainer } from '../loader';
import { PopupContainer } from '../popups';
import { modalPaddings } from './warningPopup.meta';
import { WarningPopupProps } from './warningPopup.model';
import {
  ButtonsWrapperStyled,
  PopupContainerStyled,
  PopupHeaderStyled,
  PopupWrapperStyled,
  TextStyled,
  TitleStyled,
} from './warningPopup.styled';

export const WarningPopupContainer = ({
  isOpened,
  handleClose,
  handleRightButton,
  leftButtonTitle,
  rightButtonTitle,
  popupTitle,
  popupText,
  // popupWarning DEPRECATED
  popupWarning,
  isChanging = false,
}: WarningPopupProps): ReactElement => {
  return (
    <PopupContainer
      handleClose={handleClose}
      isOpened={isOpened}
      hasHeader={false}
      width={'600px'}
      styleSettings={modalPaddings}
    >
      {!isChanging ? (
        <PopupContainerStyled>
          <PopupHeaderStyled>
            <TitleStyled>{popupTitle}</TitleStyled>

            <TextStyled>
              {popupText} {popupWarning}
            </TextStyled>
          </PopupHeaderStyled>

          <ButtonsWrapperStyled>
            <ButtonContainer
              title={leftButtonTitle}
              color={ButtonColor.transparent}
              borderRadius={100}
              fullWidth
              onClick={handleClose}
            />

            <ButtonContainer
              title={rightButtonTitle}
              color={ButtonColor.red}
              borderRadius={100}
              fullWidth
              onClick={handleRightButton}
            />
          </ButtonsWrapperStyled>
        </PopupContainerStyled>
      ) : (
        <PopupWrapperStyled>
          <LoaderContainer />
        </PopupWrapperStyled>
      )}
    </PopupContainer>
  );
};
