import { NotificationManagementEvent } from '../notificationManagement.analytics';
import { CookiePermissionsModel } from '../notificationManagement.model';
import { Nullable } from '../../../../models';

export const getAnalyticsEventByPermissions = ({
  analytics,
  advertising,
  technical,
}: CookiePermissionsModel): Nullable<NotificationManagementEvent> => {
  switch (true) {
    case analytics && advertising && technical:
      return NotificationManagementEvent.clickSaveAllSettings;
    case !analytics && !advertising && technical:
      return NotificationManagementEvent.clickSaveTechnicalSettings;
    case !analytics && advertising && technical:
      return NotificationManagementEvent.clickSaveTechnicalPromoSettings;
    case analytics && !advertising && technical:
      return NotificationManagementEvent.clickSaveTechnicalAnalyticSettings;
    default:
      return null;
  }
};
