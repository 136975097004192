import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconStarComponent = ({
  width = 16,
  height = 16,
  fillColor = '#ffffff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00317 1.50001C7.91206 1.49944 7.82261 1.52441 7.74497 1.5721C7.66733 1.61978 7.6046 1.68827 7.56391 1.76979L5.88936 5.11888L1.9131 5.73081C1.8241 5.74456 1.74065 5.7827 1.672 5.84099C1.60334 5.89928 1.55218 5.97544 1.52417 6.06104C1.49617 6.14663 1.49242 6.23831 1.51334 6.32591C1.53426 6.4135 1.57903 6.49359 1.64269 6.55729L4.39319 9.30779L3.78062 13.2885C3.76692 13.3776 3.77816 13.4687 3.81311 13.5517C3.84805 13.6348 3.90533 13.7065 3.97859 13.759C4.05184 13.8115 4.1382 13.8426 4.22808 13.849C4.31797 13.8553 4.40785 13.8367 4.48777 13.795L8 11.9624L11.5122 13.795C11.5921 13.8367 11.682 13.8553 11.7719 13.849C11.8618 13.8426 11.9482 13.8115 12.0214 13.759C12.0947 13.7065 12.1519 13.6348 12.1869 13.5517C12.2218 13.4687 12.2331 13.3776 12.2194 13.2885L11.6068 9.30779L14.3573 6.55729C14.421 6.49359 14.4657 6.4135 14.4867 6.32591C14.5076 6.23831 14.5038 6.14663 14.4758 6.06104C14.4478 5.97544 14.3967 5.89928 14.328 5.84099C14.2594 5.7827 14.1759 5.74456 14.0869 5.73081L10.1106 5.11888L8.43609 1.76979C8.39588 1.68923 8.33414 1.62139 8.25773 1.57377C8.18132 1.52615 8.09321 1.50062 8.00317 1.50001Z"
      fill={fillColor}
    />
  </svg>
);
