import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPercentWithPaperComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4119 21.2H5.58768C4.04822 21.2 2.7998 19.9516 2.7998 18.4122V5.58793C2.7998 4.04846 4.04822 2.80005 5.58768 2.80005H18.4119C19.9514 2.80005 21.1998 4.04846 21.1998 5.58793V18.4122C21.1998 19.9516 19.9514 21.2 18.4119 21.2Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M2.7998 7.2605H21.1998"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M14.5094 10.6062L9.49121 17.8547"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.933 13.3941C9.70285 13.3941 10.3269 12.77 10.3269 12.0001C10.3269 11.2303 9.70285 10.6062 8.933 10.6062C8.16315 10.6062 7.53906 11.2303 7.53906 12.0001C7.53906 12.77 8.16315 13.3941 8.933 13.3941Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.0668 17.8545C15.8366 17.8545 16.4607 17.2304 16.4607 16.4606C16.4607 15.6907 15.8366 15.0667 15.0668 15.0667C14.2969 15.0667 13.6729 15.6907 13.6729 16.4606C13.6729 17.2304 14.2969 17.8545 15.0668 17.8545Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
