import {
  TagLabelColor,
  TagLabelIconPosition,
  TagLabelSize,
} from './tagLabel.model';
import styled, { css } from 'styled-components';
import { ITheme } from '../themes';
import { defaultGradient, fontSettings, textGradient } from '../../styles';

//todo: refactor to any padding
export const tagLabelPadding = {
  big: {
    vertical: 12,
    horizontal: 4,
  },
  normal: {
    vertical: 10,
    horizontal: 4,
  },
  small: {
    vertical: 10,
    horizontal: 4,
  },
  xsmall: {
    vertical: 8,
    horizontal: 2,
  },
};

const iconPadding = 22;

const setColor = (
  color: TagLabelColor,
  theme: ITheme,
  borderRadius: number
): ReturnType<typeof css> => {
  switch (color) {
    case TagLabelColor.white: {
      return css`
        color: ${theme.COLOR.TEXT_DARK_COLOR};
        background-color: rgba(255, 255, 255, 0.3);
      `;
    }
    case TagLabelColor.blue: {
      return css`
        color: ${theme.COLOR.INFO_BLUE_COLOR};
        background-color: ${theme.COLOR.INFO_BLUE_LIGHT_COLOR};
      `;
    }
    case TagLabelColor.green: {
      return css`
        color: ${theme.COLOR.INFO_GREEN_COLOR};
        background-color: ${theme.COLOR.INFO_GREEN_LIGHT_COLOR};
      `;
    }
    case TagLabelColor.rose: {
      return css`
        color: ${({ theme }): string => theme.COLOR.RED};
        background-color: ${({ theme }): string => theme.COLOR.LIGHT_ROSE};
      `;
    }
    case TagLabelColor.gradient: {
      return css`
        ${defaultGradient(theme.COLOR)};
        color: ${theme.COLOR.WHITE};
      `;
    }
    case TagLabelColor.gradientBorder: {
      return css`
        ${defaultGradient(theme.COLOR)};
        position: relative;

        :after {
          content: '';
          position: absolute;
          top: 1px;
          right: 1px;
          bottom: 1px;
          left: 1px;
          background-color: ${theme.COLOR.WHITE};
          border-radius: ${borderRadius - 1}px;
          z-index: ${theme.ZINDEX.TOP_BLOCK};
        }

        ${TagLabelTextStyled} {
          position: relative;
          ${textGradient(
            theme.COLOR.DEFAULT_GRADIENT_START,
            theme.COLOR.DEFAULT_GRADIENT_END
          )};
          z-index: ${theme.ZINDEX.TOP_BLOCK + 1};
        }
      `;
    }
    case TagLabelColor.subscription: {
      return css`
        color: ${theme.COLOR.SUBSCRIPTION};
        background-color: ${theme.COLOR.SUBSCRIPTION_BG};
      `;
    }
    case TagLabelColor.orange: {
      return css`
        color: ${theme.COLOR.WHITE};
        background-color: #ec6337;
      `;
    }
    case TagLabelColor.grey: {
      return css`
        color: ${theme.COLOR.TEXT_LIGHT_COLOR};
        background-color: ${theme.COLOR.BLACK_4};
      `;
    }
  }
};

const setIconPadding = (
  iconPosition: TagLabelIconPosition,
  currentPosition: TagLabelIconPosition,
  hasIcon?: boolean
): number => {
  return hasIcon && iconPosition === currentPosition ? iconPadding : 0;
};

const setIconPosition = (size?: TagLabelSize): string => {
  if (size) {
    switch (size) {
      case TagLabelSize.big: {
        return `${tagLabelPadding.big.vertical}px`;
      }
      case TagLabelSize.normal: {
        return `${tagLabelPadding.normal.vertical}px`;
      }
      case TagLabelSize.small: {
        return `${tagLabelPadding.small.vertical}px`;
      }
      case TagLabelSize.xsmall: {
        return `${tagLabelPadding.xsmall.vertical}px`;
      }
    }
  }

  return '0';
};

const setSize = (
  theme: ITheme,
  iconPosition: TagLabelIconPosition,
  size?: TagLabelSize,
  hasIcon?: boolean
): ReturnType<typeof css> => {
  if (size) {
    switch (size) {
      case TagLabelSize.big: {
        return css`
          padding: ${tagLabelPadding.big.horizontal}px
            ${setIconPadding(
              iconPosition,
              TagLabelIconPosition.right,
              hasIcon
            ) + tagLabelPadding.big.vertical}px
            ${tagLabelPadding.big.horizontal}px
            ${setIconPadding(iconPosition, TagLabelIconPosition.left, hasIcon) +
            tagLabelPadding.big.vertical}px;
          ${fontSettings(theme.FONT.SIZE_15, theme.FONT)}
        `;
      }
      case TagLabelSize.normal: {
        return css`
          padding: ${tagLabelPadding.normal.horizontal}px
            ${setIconPadding(
              iconPosition,
              TagLabelIconPosition.right,
              hasIcon
            ) + tagLabelPadding.normal.vertical}px
            ${tagLabelPadding.normal.horizontal}px
            ${setIconPadding(iconPosition, TagLabelIconPosition.left, hasIcon) +
            tagLabelPadding.normal.vertical}px;
          ${fontSettings(theme.FONT.SIZE_15, theme.FONT)}
        `;
      }
      case TagLabelSize.small: {
        return css`
          padding: ${tagLabelPadding.small.horizontal}px
            ${setIconPadding(
              iconPosition,
              TagLabelIconPosition.right,
              hasIcon
            ) + tagLabelPadding.small.vertical}px
            ${tagLabelPadding.small.horizontal}px
            ${setIconPadding(iconPosition, TagLabelIconPosition.left, hasIcon) +
            tagLabelPadding.small.vertical}px;

          ${fontSettings(theme.FONT.SIZE_12, theme.FONT)}
        `;
      }
      case TagLabelSize.xsmall: {
        return css`
          padding: ${tagLabelPadding.xsmall.horizontal}px
            ${setIconPadding(
              iconPosition,
              TagLabelIconPosition.right,
              hasIcon
            ) + tagLabelPadding.xsmall.vertical}px
            ${tagLabelPadding.xsmall.horizontal}px
            ${setIconPadding(iconPosition, TagLabelIconPosition.left, hasIcon) +
            tagLabelPadding.xsmall.vertical}px;

          ${fontSettings(theme.FONT.SIZE_10, theme.FONT)}
        `;
      }
    }
  }

  return css``;
};

type TagLabelContainerStyledProps = {
  color: TagLabelColor;
  borderRadius: number;
  iconPosition: TagLabelIconPosition;
  noWrap: boolean;
  size?: TagLabelSize;
  hasIcon?: boolean;
};
export const TagLabelContainerStyled = styled.div<TagLabelContainerStyledProps>`
  font-weight: 500;
  border-radius: ${({ borderRadius }): number => borderRadius}px;
  white-space: ${({ noWrap }): string => (noWrap ? 'nowrap' : 'pre-wrap')};
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  ${({ color, theme, borderRadius }): ReturnType<typeof css> =>
    setColor(color, theme, borderRadius)};
  ${({ size, theme, iconPosition, hasIcon }): ReturnType<typeof css> =>
    setSize(theme, iconPosition, size, hasIcon)};

  b {
    font-weight: 700;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    ${({ iconPosition, size }): string =>
      `${iconPosition}: ${setIconPosition(size)}`};
  }
`;

export const TagLabelTextStyled = styled.div``;
