import { useSelector } from 'react-redux';
import { getCurrencies } from './currencies.selectors';
import { CurrencyModel } from '../../models';

const FALLBACK_CODE = 'BYN';

export const useCurrentCurrency = (): CurrencyModel => {
  const currencies = useSelector(getCurrencies);

  const currentCurrency = currencies.find((currency) => currency.isBase);
  const fallbackCurrency =
    currencies.find((currency) => currency.code === FALLBACK_CODE) ||
    currencies[0];

  return currentCurrency || fallbackCurrency;
};
