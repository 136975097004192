import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import {
  promoCodeClientProvider,
  PromoCodeModel,
  QueryParams,
  usePromoCodeContext,
} from '@lerna-core';
import { useDiscountProgram } from '@features/common/discountProgram';
import { UseFormPromoCodeModel } from '../formPromoCode.model';

const PROMO_FIELD_VALUE = 'promoCodeDraft';

export const usePromoCodeControlledInput = (
  setFieldValue: (field: string, value: string) => void,
  setIsApplyingPromoCode: Dispatch<SetStateAction<boolean>>
): UseFormPromoCodeModel => {
  const { discountProgramQuery } = useDiscountProgram();
  const { promoCode, setPromoCode } = usePromoCodeContext();
  const [promoCodeIsValid, setPromoCodeIsValid] = useState<boolean | undefined>(
    !!(promoCode as PromoCodeModel)?.name
  );
  const router = useRouter();

  const handleApply = async (promoCode: string): Promise<void> => {
    setIsApplyingPromoCode(true);

    await promoCodeClientProvider({
      promoCode,
      discountProgramSlug: discountProgramQuery,
    })
      .then((promoCode) => {
        router
          .replace(
            {
              query: {
                ...router.query,
                [QueryParams.promoCode]: promoCode.name,
              },
            },
            undefined,
            { scroll: false }
          )
          .finally(() => {
            setPromoCodeIsValid(true);
            setFieldValue(PROMO_FIELD_VALUE, promoCode.name);
            setPromoCode(promoCode);
            setIsApplyingPromoCode(false);
          });
      })
      .catch((error) => {
        setIsApplyingPromoCode(false);

        const errorCallback = () => {
          setPromoCodeIsValid(false);
          if (error?.response?.data?.status) {
            const { status } = error.response.data;
            setPromoCode({ status });
          }
        };

        if (router.query[QueryParams.promoCode]) {
          const nextQuery = { ...router.query };
          delete nextQuery[QueryParams.promoCode];

          router
            .replace({ query: nextQuery }, undefined, {
              scroll: false,
              shallow: true,
            })
            .then(errorCallback);
        } else {
          errorCallback();
        }
      });
  };

  const handleCancel = () => {
    setIsApplyingPromoCode(true);

    const nextQuery = { ...router.query };
    delete nextQuery[QueryParams.promoCode];
    router
      .replace({ query: nextQuery }, undefined, {
        scroll: false,
      })
      .finally(() => {
        setPromoCode(null);
        setPromoCodeIsValid(false);
        setFieldValue(PROMO_FIELD_VALUE, '');
        setIsApplyingPromoCode(false);
      });
  };

  return {
    promoCodeIsValid,
    handleApply,
    handleCancel,
  };
};
