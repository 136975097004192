import styled from 'styled-components';
import { fontSettings } from '../../../../../styles';

type CourseItemProgressItemStyledProps = {
  isMobile: boolean;
};

export const CourseItemProgressItemStyled = styled.div<CourseItemProgressItemStyledProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ theme, isMobile }): string =>
    isMobile
      ? `
        ${fontSettings(theme.FONT.SIZE_11, theme.FONT)};
    `
      : `
        ${fontSettings(theme.FONT.SIZE_13, theme.FONT)};
   `};
`;

export const CourseItemProgressItemTextStyled = styled.p`
  margin: 0;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const CourseItemProgressItemPercentStyled = styled.p`
  margin: 0;
  font-weight: 700;
`;
