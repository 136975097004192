import React, { ReactElement, useContext } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import {
  browserService,
  ButtonColor,
  ButtonContainer,
  DeviceInfoContext,
} from '@lerna-core';
import { RightHeadContainer } from './rightHead';
import { ContentRightContainerProps } from './contentRight.model';

export const ContentRightContainer = ({
  course,
  translations,
  disabled,
  handleSubmit,
}: ContentRightContainerProps): ReactElement => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  return (
    <ContentRightStyled>
      <RightHeadContainer
        course={course}
        isMobile={isMobile}
        translations={translations}
      />
      <ButtonStyled>
        <ButtonContainer
          onClick={handleSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.sign_up_course_button_title}
          disabled={disabled}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
