import React, { ReactElement } from 'react';
import {
  TableLoaderStyled,
  TableLoaderWrapperStyled,
} from './tableLoader.styled';
import { TableLoaderProps } from './tableLoader.model';
import { TableLoaderPositionsEnum } from './tableLoader.enum';
import { SpinnerContainer } from '../../spinner';

export const TableLoaderContainer = ({
  position = TableLoaderPositionsEnum.sticky,
}: TableLoaderProps): ReactElement => {
  return (
    <TableLoaderStyled position={position}>
      <TableLoaderWrapperStyled>
        <SpinnerContainer width={32} height={32} strokeWidth="2" />
      </TableLoaderWrapperStyled>
    </TableLoaderStyled>
  );
};
