import styled from 'styled-components';
import { fontSettings, transition } from '@lerna-core';
import { FiltersShowAllProps } from './filtersShowAll.model';

export const FiltersShowAllStyled = styled.div<FiltersShowAllProps>`
  text-align: center;
  cursor: pointer;
  color: ${({ theme, customColor }): string =>
    customColor ? customColor : theme.COLOR.PRIMARY[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};

  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  ${transition('opacity')}
    &:hover {
    opacity: 0.8;
  }
`;

export const ArrowStyled = styled.div`
  display: flex;
  margin-top: 2px;
  transform: rotate(180deg);
  margin-left: 8px;
`;
