import React, { ReactElement } from 'react';
import {
  FormStatusStyled,
  IconStyled,
  SubtitleStyled,
  TitleStyled,
  ButtonStyled,
} from './formStatus.styled';
import { getStatusIcon } from './formStatus.meta';
import { getStatusMessage } from './formStatus.helper';
import {
  ButtonColor,
  ButtonContainer,
  CommonEventPositions,
  CommonEventTypes,
  getSimpleEvent,
  IframeInitiator,
  personalPath,
  projectSubDomains,
  pushDataLayer,
  QueryParams,
  urlService,
  VendorEnum,
} from '@lerna-core';
import { useRouter } from 'next/router';
import { OrderFormEvents } from '@features/common/forms';
import { useFormSubscriptionContext } from '../formSubscription.context';

export const FormStatusContainer = (): ReactElement => {
  const router = useRouter();
  const {
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    translations,
    isFreeCourse,
    course,
    isSubscriptionFormContentSelected,
  } = useFormSubscriptionContext();

  const isSkillboxCourse = course?.vendor?.slug === VendorEnum.skillbox;
  const showStatusButton =
    isSubscriptionFormContentSelected ||
    ((isSuccessDisplayed || isExistOrder) && isFreeCourse);

  const statusIcon = getStatusIcon(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder
  );
  const statusMessage = getStatusMessage(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    translations,
    isFreeCourse,
    isSkillboxCourse,
    isSubscriptionFormContentSelected
  );

  const handleStatusButtonClick = (): void => {
    let path = personalPath.courses;
    const iframeInitiator = router.query[
      QueryParams.initiator
    ] as IframeInitiator;

    if (iframeInitiator) {
      path = personalPath.iframeCourses.replace(
        `[${QueryParams.initiator}]`,
        iframeInitiator
      ) as personalPath;
    }

    pushDataLayer(
      getSimpleEvent(
        OrderFormEvents.clickButtonMyCourses,
        CommonEventTypes.overallEvents,
        CommonEventPositions.formOrderStatus
      )
    );

    router.push(
      urlService.getFullUrlToSubDomain(projectSubDomains.personal, path)
    );
  };

  return (
    <FormStatusStyled>
      <IconStyled>{statusIcon}</IconStyled>
      <TitleStyled>{statusMessage.title}</TitleStyled>
      {statusMessage.subtitle && (
        <SubtitleStyled>{statusMessage.subtitle}</SubtitleStyled>
      )}
      {showStatusButton && (
        <ButtonStyled>
          <ButtonContainer
            onClick={handleStatusButtonClick}
            color={ButtonColor.transparent}
            borderRadius={8}
            title={translations?.my_education}
          />
        </ButtonStyled>
      )}
    </FormStatusStyled>
  );
};
