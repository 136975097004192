import styled from 'styled-components';
import { CustomersTransferStyledProps } from './customersTransfer.model';

export const CustomersTransferStyled = styled.div<CustomersTransferStyledProps>`
  width: 100%;

  ${({ isPending }): string => getIsLoadingStyles(isPending)}
`;

const getIsLoadingStyles = (isPending: boolean): string => {
  if (isPending) {
    return `
      &:after {
        content: '';
        position: absolute;
        top: -24px;
        left: -16px;
        right: -16px;
        bottom: -16px;
        background: rgba(0,0,0, .24);
        border-radius: 16px;
        z-index: 2;
      }
    `;
  }

  return '';
};

export const CustomersTransferPendingStyled = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: inherit;
  display: flex;
  justify-content: center;
  z-index: 3;
`;
