import React, { ReactElement } from 'react';

import { Column, HeaderGroup, useRowSelect, useTable } from 'react-table';
import { ActionHeaderCell } from './actionHeaderCell';
import { selectionColumn } from './selectionColumn';
import {
  Table,
  TableBody,
  TableBox,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableActionsContainer,
} from './table.styled';
import { useTranslations } from '../translations';

export interface TableContainerProps {
  data: Record<string, unknown>[];
  columns: Column<Record<string, unknown>>[];
  rowSelection?: boolean;
  renderActions?: (selected: unknown) => ReactElement;
  selectedCountLabel?: string;
}

export const DEPRECATED_TableContainer = ({
  data,
  columns = [],
  rowSelection = false,
  renderActions,
  selectedCountLabel,
}: TableContainerProps): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable<Record<string, unknown>>(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      if (rowSelection) {
        hooks.visibleColumns.push((columns) => [selectionColumn, ...columns]);
      }
    }
  );

  const { request_not_found } = useTranslations();

  const renderHeaderCells = (headers: HeaderGroup[]): ReactElement[] => {
    return headers.map((column) => {
      const { key, style, role, className } = column.getHeaderProps();

      return (
        <TableHeadCell
          style={
            style
              ? style
              : { minWidth: column.minWidth, maxWidth: column.maxWidth }
          }
          key={key}
          role={role}
          className={className}
        >
          {column.render('Header')}
        </TableHeadCell>
      );
    });
  };

  const renderActionHeaderCell = (headers: HeaderGroup[]): ReactElement => {
    const filteredCols = headers.filter(
      (col) => col?.id === selectionColumn.id
    );

    return (
      <>
        {renderHeaderCells(filteredCols)}
        <TableHeadCell colSpan={columns.length}>
          <ActionHeaderCell
            selectedLength={selectedFlatRows.length}
            selectedCountLabel={selectedCountLabel}
          >
            {renderActions && (
              <TableActionsContainer>
                {renderActions(selectedFlatRows)}
              </TableActionsContainer>
            )}
          </ActionHeaderCell>
        </TableHeadCell>
      </>
    );
  };

  return (
    <TableBox>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup: HeaderGroup) => {
            const { key, style, role, className } =
              headerGroup.getHeaderGroupProps();

            return (
              <TableRow
                key={key}
                role={role}
                style={style}
                className={className}
              >
                {(selectedFlatRows.length
                  ? renderActionHeaderCell
                  : renderHeaderCells)(headerGroup.headers)}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, style, role, className } = row.getRowProps();

            return (
              <TableRow
                key={key}
                role={role}
                style={style}
                className={className}
              >
                {row.cells.map((cell) => {
                  const { key, style, role, className } = cell.getCellProps();

                  return (
                    <TableCell
                      key={key}
                      role={role}
                      style={style}
                      className={className}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={100} style={{ textAlign: 'center' }}>
                {request_not_found}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableBox>
  );
};
