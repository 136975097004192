import { useEffect, useState } from 'react';
import {
  CommonEventPositions,
  CommonEventTypes,
  cookieService,
  dateService,
  getSimpleEvent,
  pushDataLayer,
  useUser,
} from '@lerna-core';
import { BONUSES_COOKIE_NAME } from './bonusesPopup.constants';
import {
  BonusesCookieModel,
  BonusesPopupHookModel,
} from './bonusesPopup.model';
import { canShowBonusesPopup } from './bonusesPopup.helper';
import { BonusesPopupAnalytics } from './bonusesPopup.analytics';

export const useBonusesPopup = (): BonusesPopupHookModel => {
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const { user } = useUser();

  const sendEvent = (eventName: BonusesPopupAnalytics): void => {
    pushDataLayer(
      getSimpleEvent(
        eventName,
        CommonEventTypes.overallEvents,
        CommonEventPositions.coursesPageList
      )
    );
  };

  const handleClosePopup = () => {
    setIsPopupOpened(false);
    sendEvent(BonusesPopupAnalytics.close);
  };

  const handleOpenPopup = () => {
    setIsPopupOpened(true);
    sendEvent(BonusesPopupAnalytics.show);
  };

  const handleGoToTelegram = () => {
    setIsPopupOpened(false);
    incrementBonusesCounter();
    sendEvent(BonusesPopupAnalytics.goToTelegram);
  };

  const getBonusesCookie = () => {
    const cookiePermissions = cookieService.getCookie(BONUSES_COOKIE_NAME);

    if (cookiePermissions) {
      return JSON.parse(cookiePermissions) as BonusesCookieModel;
    }

    return null;
  };

  const setBonusesCookie = (val: BonusesCookieModel): void => {
    cookieService.setCookie({
      name: BONUSES_COOKIE_NAME,
      val: JSON.stringify(val),
      days: dateService.yearInDays,
    });
  };

  const incrementBonusesCounter = (increment: number = 1) => {
    const bonusesCookie = getBonusesCookie();
    const showCounter = (bonusesCookie?.showCounter || 0) + increment;
    setBonusesCookie({ showCounter, lastUpdate: Date.now() });
  };

  useEffect(() => {
    const bonusesCookie = getBonusesCookie();
    const useBonusesPopup = canShowBonusesPopup(user);
    const updatedDayAgo =
      bonusesCookie?.lastUpdate &&
      Date.now() - bonusesCookie.lastUpdate > dateService.dayInMilliseconds;
    if (
      useBonusesPopup &&
      (!bonusesCookie || (bonusesCookie.showCounter < 2 && updatedDayAgo))
    ) {
      handleOpenPopup();
    }
  }, []);

  return {
    isPopupOpened,
    handleClosePopup,
    handleGoToTelegram,
    incrementBonusesCounter,
  };
};
