import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconSortComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  fillColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.958 17.292V3.958M6.042 2.708v13.334"
        stroke={fillColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="m9.375 13.958-3.333 3.334-3.334-3.334M10.625 6.042l3.333-3.334 3.334 3.334"
        stroke={fillColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
