import { createSelector } from 'reselect';

import { RootState } from '../../../index';
import { UserState } from './user.model';

export const getUser = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.user
);

export const getUserStatus = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.status
);

export const getUserCareer = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.user?.career
);
