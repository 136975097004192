import React, { ReactElement } from 'react';
import { ResetItemContainerProps } from './resetItem.model';
import { getNewQuery } from './resetItem.helper';
import { IconStyled, ResetItemStyled } from './resetItem.styled';
import {
  IconCloseComponent,
  pushDataLayer,
  urlService,
  useTheme,
} from '@lerna-core';
import { useRouter } from 'next/router';
import { pageUrls } from '@constants';
import { FilterEvent, getMainFilterEventData } from '../../filters.analytics';

export const ResetItemContainer = ({
  filter,
  itemValue,
  itemSlug,
  currentOption,
  isMobileType,
  customFilterColor,
}: ResetItemContainerProps): ReactElement => {
  const theme = useTheme();
  const router = useRouter();

  const iconCloseColor = isMobileType
    ? theme.COLOR.WHITE
    : theme.COLOR.TEXT[100];

  const resetFilter = (): void => {
    const defaultPath = router.query?.fastlink
      ? pageUrls.courses
      : router.pathname;
    const newQuery = getNewQuery(router, filter, itemSlug);

    if (!isMobileType) {
      pushDataLayer(
        getMainFilterEventData(
          FilterEvent.constructorFiltersMain,
          filter,
          currentOption,
          true
        )
      );
    }

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <>
      <ResetItemStyled
        customFilterColor={customFilterColor}
        isMobileType={isMobileType}
        onClick={resetFilter}
      >
        {itemValue}
        <IconStyled>
          <IconCloseComponent
            width={14}
            height={14}
            strokeColor={iconCloseColor}
            strokeWidth="3"
          />
        </IconStyled>
      </ResetItemStyled>
    </>
  );
};
