import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';

export const FormContentContainer = (): ReactElement => (
  <FormContentStyled>
    <ContentLeftContainer />
    <ContentRightContainer />
  </FormContentStyled>
);
