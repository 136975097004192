import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { logger } from '../../logger/logger.service';
import { serverConfig } from '../../config/config.model';

/**
 * Request middleware will log data about sending request to the console. Triggering from env config
 * @param config{InternalAxiosRequestConfig}
 * @return config{InternalAxiosRequestConfig}
 */
export const requestLoggerMiddleware = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  logger.info('Sending request', {
    url: config.url,
    req_id: config.headers?.req_id,
    headers:
      serverConfig?.DEBUG_REQUEST_HEADERS === 'true'
        ? config.headers
        : undefined,
  });

  return config;
};

/**
 * Response middleware will log data about getting response to the console. Triggering from env config
 * @param response{AxiosResponse}
 * @return response{AxiosResponse}
 */
export const responseLoggerMiddleware = (
  response: AxiosResponse
): AxiosResponse => {
  const isDebug =
    response.config &&
    response.config.params &&
    response.config.params.hasOwnProperty('debug');
  const loggerInfo = {
    url: response.config.url,
    urlParams: response.config.params,
    req_id: response.config.headers['req_id'],
    responseData: isDebug ? response.data : 'DISABLED',
  };
  if (isDebug) {
    if (response.status === 200 || response.status === 201) {
      logger.info('Receiving response', loggerInfo);
    } else {
      logger.error('Receiving response with error', loggerInfo);
    }
  }

  return response;
};
