import { FastLinkModel, FastLinkWithActiveModel } from './fastLinks.model';
import { TranslationModel } from '../../models';
import { urlService } from '../../services';

export const getLinksWithAllCategories = (
  links: FastLinkModel[],
  fastLinkQuery: string,
  translations: TranslationModel
): FastLinkWithActiveModel[] => {
  const allCategoryLink = {
    ...allCategoryFastLink(translations?.all_categories),
    isActive: !fastLinkQuery,
  };
  const linksWithActive = links.map((link) => ({
    isActive: link.slug === fastLinkQuery,
    ...link,
  }));

  return [allCategoryLink, ...linksWithActive];
};

export const getFastLinkHref = (fastLink: string, path: string): string => {
  return urlService.normalizeUrl(`${path}/${fastLink}`);
};

export const allCategoryFastLink = (title: string): FastLinkModel => ({
  title,
  slug: '',
  isVisible: true,
});

export const getActiveLinkTitle = (
  links: FastLinkWithActiveModel[],
  fastLinkQuery: string
): string | null => {
  const activeLink = links.find((link) => link.slug === fastLinkQuery);

  return activeLink ? activeLink.title : null;
};
