import * as constants from './user.constants';
import { UserActions, UserAggregateModel, UserState } from './user.model';

const initialState: UserState = {
  user: null,
  status: {
    isLoading: false,
    isLoaded: false,
    isReloadNeeded: false,
  },
};

export function userReducer(
  state: UserState = initialState,
  action: UserActions
): UserState {
  switch (action.type) {
    case constants.REQUEST_USER_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case constants.RELOAD_USER_START:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true,
        },
      };

    case constants.REQUEST_USER_SUCCESS:
      return {
        ...state,
        user: action.payload as UserAggregateModel,
        status: {
          ...state.status,
          isReloadNeeded: false,
          isLoading: false,
          isLoaded: true,
        },
      };

    case constants.REQUEST_USER_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          isLoaded: true,
        },
      };

    case constants.REQUEST_USER_RELOAD:
      return {
        ...state,
        status: {
          ...state.status,
          isReloadNeeded: true,
        },
      };

    default:
      return state;
  }
}
