import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconWarningComponent = ({
  width = 48,
  height = 48,
  fillColor = '#000',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill={fillColor} />
    <path
      d="M24 13.5L24 28.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="24" cy="33.5" r="1.5" fill="white" />
  </svg>
);
