import React, { ReactElement, useContext } from 'react';
import { browserService, DeviceInfoContext, ImageContainer } from '@lerna-core';
import { CourseLogoContainerStyled } from './courseLogo.styled';
import {
  CourseLogoContainerProps,
  CourseLogoSizeEnum,
} from './courseLogo.model';
import { getCourseLogoProps } from './courseLogo.helpers';

export const CourseLogoContainer = ({
  logo,
  logoSize,
}: CourseLogoContainerProps): ReactElement => {
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const defaultLogoSize = isMobile
    ? CourseLogoSizeEnum.medium
    : CourseLogoSizeEnum.big;

  const logoProps = getCourseLogoProps(logoSize || defaultLogoSize);

  return (
    <CourseLogoContainerStyled
      width={logoProps.width}
      height={logoProps.height}
    >
      <ImageContainer
        imageResource={logo.imageResource}
        preset={logoProps.preset}
      />
    </CourseLogoContainerStyled>
  );
};
