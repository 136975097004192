import React, { ReactElement, useRef, useState } from 'react';

import { useTheme } from '../../../hooks';
import { ButtonColor, ButtonContainer } from '../../button';
import { DropdownContainer } from '../../dropdown/dropdown.container';
import { IconLanguageComponent } from '../../icons/iconLanguage.component';
import { LanguageItemsContainer } from '../languageItems';
import { LanguageDesktopContainerStyled } from './languageDesktop.styled';
import { DropdownPosition } from '../../dropdown/dropdown.model';

type LanguageDesktopContainerProps = {
  supportedLocales: string[];
};

export const LanguageDesktopContainer = ({
  supportedLocales,
}: LanguageDesktopContainerProps): ReactElement => {
  const theme = useTheme();
  const [isOpened, setIsOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const handleOutsideClick = (): void => {
    setIsOpened(false);
  };

  const handleClickBtn = (): void => {
    setIsOpened(!isOpened);
  };

  const handleClickItem = (): void => {
    setIsOpened(false);
  };

  return (
    <>
      <LanguageDesktopContainerStyled ref={dropdownRef} isOpened={isOpened}>
        <ButtonContainer
          onClick={handleClickBtn}
          borderRadius={16}
          color={isOpened ? ButtonColor.default : ButtonColor.transparentFull}
        >
          <IconLanguageComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
        </ButtonContainer>
        {isOpened && (
          <DropdownContainer
            ref={dropdownRef}
            handleOutsideClick={handleOutsideClick}
            position={DropdownPosition.right}
          >
            <LanguageItemsContainer
              supportedLocales={supportedLocales}
              handleClickItem={handleClickItem}
            />
          </DropdownContainer>
        )}
      </LanguageDesktopContainerStyled>
    </>
  );
};
