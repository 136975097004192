import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import React, { ReactElement } from 'react';
import { SideMenuLinkContainer } from './sideMenuLink';
import { urlService } from '../../../../services';
import { getSideMenuElements } from '../../menu.selectors';
import { SideMenuLinksContainerProps } from './sideMenuLinks.model';

export const SideMenuLinksContainer = ({
  useAnalytics = false,
}: SideMenuLinksContainerProps): ReactElement => {
  const { pathname } = useRouter();
  const sideMenuLinks = useSelector(getSideMenuElements);
  const path = urlService.normalizeSlash(`/${pathname.split('/')[1]}`);

  return (
    <>
      {sideMenuLinks.map((link) => (
        <SideMenuLinkContainer
          {...link}
          key={link.title}
          isActive={path === urlService.normalizeSlash(link.link)}
          useAnalytics={useAnalytics}
        />
      ))}
    </>
  );
};
