import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLoaderLComponent = ({
  width = 18,
  height = 26,
  fillColor = '#29004D',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.61964 0H0V25.2692H7.61964V0Z" fill={fillColor} />
    <path
      d="M13.0628 20.3048C11.3561 22.2486 9.52898 24.2896 8.68927 25.2303H18V14.7728C17.343 15.492 14.9638 18.1589 13.0628 20.3048Z"
      fill={fillColor}
    />
  </svg>
);
