import styled from 'styled-components';

import { fontSettings } from '../../../styles';

export const FilterPopupSearchSectionStyled = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 36px;
  }

  input {
    ${(props): string =>
      fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
    width: 100%;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.04);
    border-color: transparent;
    padding-left: 48px;
  }
`;
