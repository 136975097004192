import React, { ReactElement } from 'react';
import {
  IconErrorComponent,
  IconSuccessComponent,
  IconWarningComponent,
} from '@features/common/forms/components/icons';

export const getStatusIcon = (
  isSuccessDisplayed: boolean,
  isErrorDisplayed: boolean,
  isExistOrder: boolean,
  isPhoneErrorDisplayed: boolean
): ReactElement => {
  switch (true) {
    case isSuccessDisplayed:
      return <IconSuccessComponent />;
    case isExistOrder:
    case isPhoneErrorDisplayed:
      return <IconWarningComponent />;
    case isErrorDisplayed:
    default:
      return <IconErrorComponent />;
  }
};
