import React, { ReactElement, useMemo } from 'react';
import { PricesInfoContainerProps } from './B2B2CPriceInfo.model';
import { B2B2CPriceInfoStyled } from './B2B2CPriceInfo.styled';
import { PricesInstallmentContainer } from '../../pricesInstallment';
import { PricesTotalDiscountContainer } from '../../pricesTotalDiscount';
import { PricesTotalContainer } from '../../pricesTotal';
import { getCustomerB2B2CPrices } from '@features/common/course';

export const B2B2CPriceInfoContainer = ({
  course,
  translations,
  withInstallment,
}: PricesInfoContainerProps): ReactElement => {
  const {
    hasTotalDiscount,
    totalDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = useMemo(() => getCustomerB2B2CPrices(course), []);

  const showInstallment =
    withInstallment &&
    hasInstallment &&
    !course.isFree &&
    !course.isFreeByDiscount;
  const showTotalPrice =
    !withInstallment ||
    !hasInstallment ||
    course.isFree ||
    course.isFreeByDiscount;
  const showTotalDiscount = hasTotalDiscount && !course.isFree;
  const totalPriceTitle =
    hasOneOfDiscounts && !course.isFree
      ? translations?.total_price_label
      : translations?.item_price_label;

  return (
    <B2B2CPriceInfoStyled>
      {showInstallment && (
        <PricesInstallmentContainer
          installment={course.price?.priceCustomerB2B2C?.installment}
          hasDiscounts={hasOneOfDiscounts}
          translations={translations}
        />
      )}
      {showTotalPrice && (
        <PricesTotalContainer title={totalPriceTitle} value={totalPrice} />
      )}
      {showTotalDiscount && (
        <PricesTotalDiscountContainer
          discount={totalDiscount}
          isMegaDiscount={course.megaDiscount}
        />
      )}
    </B2B2CPriceInfoStyled>
  );
};
