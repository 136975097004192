import React, { ReactElement } from 'react';
import { DividerContainerStyled } from './divider.styled';
import { DividerContainerProps } from './divider.model';

export const DividerContainer = ({
  title,
}: DividerContainerProps): ReactElement => {
  return (
    <DividerContainerStyled containTitle={title}>
      {title ? title : ''}
    </DividerContainerStyled>
  );
};
