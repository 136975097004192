import {
  LibraryCourseApiModel,
  LibraryCourseModel,
} from './libraryCourse.model';
import { mapImageResource } from '../../image';

export const libraryCourseAdapter = (
  libraryCourseApi: LibraryCourseApiModel
): LibraryCourseModel => ({
  id: libraryCourseApi.id,
  courseId: libraryCourseApi.course_id,
  title: libraryCourseApi.title,
  claims: libraryCourseApi.claims,
  claimedCourseCount: libraryCourseApi.claimed_courses_count,
  vendorName: libraryCourseApi.vendor_name,
  type: {
    withHomeworks: libraryCourseApi.type.with_homeworks,
    type: libraryCourseApi.type.type,
    school: libraryCourseApi.type.school,
    isVisible: libraryCourseApi.type.is_visible,
    courseStatus: libraryCourseApi.type.course_status,
  },
  logo: libraryCourseApi.logo
    ? {
        imageResource: mapImageResource(
          libraryCourseApi.logo.data.image_resource
        ),
        size: libraryCourseApi?.logo?.data?.size || null,
        backgroundColor: libraryCourseApi?.logo?.data?.background_color || null,
      }
    : null,
});
