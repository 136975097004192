import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from './useDebounce.hook';

export type UseSearchProps = {
  onSearch: (search: string) => void;
  onEmptySearch?: (search: string) => void;
  maxSearchLength?: number;
  minSearchLength?: number;
  initialSearch?: string;
};

export type UseSearchReturnType = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const useSearch = ({
  onSearch,
  onEmptySearch = onSearch,
  maxSearchLength = 256,
  minSearchLength = 1,
  initialSearch = '',
}: UseSearchProps): UseSearchReturnType => {
  const [search, setSearch] = useState<string>(initialSearch);
  const debouncedSearch = useDebounce(search, 300);
  const [prevSearch, setPrevSearch] = useState<string>(initialSearch);

  useEffect(() => {
    if (prevSearch === debouncedSearch) return;

    setPrevSearch(debouncedSearch);

    const trimmedSearch = debouncedSearch.trim();
    const trimmedDebouncedSearchLength = trimmedSearch.length;
    const isValidSearch =
      trimmedDebouncedSearchLength > minSearchLength &&
      trimmedDebouncedSearchLength < maxSearchLength;
    const isSearchEmpty = debouncedSearch.length === 0;
    if (isValidSearch) {
      onSearch(trimmedSearch);
    } else if (isSearchEmpty) {
      onEmptySearch(trimmedSearch);
    }
  }, [debouncedSearch]);

  return {
    search,
    setSearch,
  };
};
