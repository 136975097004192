import {
  EducationStreamsApiResponse,
  EducationStreamsModel,
} from './educationStreams.model';
import { educationStreamsAdapter } from './educationStreams.adapter';
import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';

export class EducationStreamsClient extends RequestClient<
  null,
  EducationStreamsApiResponse,
  EducationStreamsModel
> {
  protected requestParameters: null;
  protected adapter = educationStreamsAdapter;
  protected path = 'personal-api/v1/education/streams';

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
  }
}
