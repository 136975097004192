import React, { ReactElement } from 'react';

import { AccordionModel } from '../accordion.model';
import { AccordionContentContainer } from '../accordionContent/accordionContent.container';
import { AccordionSpoilerContainer } from '../accordionSpoiler/accordionSpoiler.container';
import { AccordionItemStyled } from './accordionItem.styled';

type AccordionItemContainerProps = {
  item: AccordionModel;
  isOpened: boolean;
  handleClickItem: () => void;
};

export const AccordionItemContainer = ({
  item,
  isOpened,
  handleClickItem,
}: AccordionItemContainerProps): ReactElement => {
  const hasContent = !!item.content;

  const handleClick = (): void => {
    handleClickItem();
  };

  return (
    <AccordionItemStyled>
      <AccordionSpoilerContainer
        isOpened={isOpened}
        handleClickItem={handleClick}
        title={item.title}
        hasContent={hasContent}
      />
      {!!hasContent && (
        <AccordionContentContainer content={item.content} isOpened={isOpened} />
      )}
    </AccordionItemStyled>
  );
};
