import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  CoursesPageContextModel,
  CoursesPageContextPropsModel,
} from '@features/courses';
import {
  clearAcademikaCoursesVendor,
  CourseModel,
  logger,
  Nullable,
} from '@lerna-core';
import { getAdditionalColors } from './coursesPage.helper';

export const CoursesPageContext =
  React.createContext<Nullable<CoursesPageContextModel>>(null);

export const CoursesPageContextProvider = ({
  props,
  children,
}: CoursesPageContextPropsModel): ReactElement => {
  const [courses, setCourses] = useState<CourseModel[]>(
    clearAcademikaCoursesVendor(props.courses || [])
  );
  const handleSetCourses = (courses: CourseModel[]) => {
    setCourses(clearAcademikaCoursesVendor(courses));
  };
  const additionalColors = getAdditionalColors(
    props.discountProgram,
    props.company?.uuid
  );

  useEffect(() => {
    handleSetCourses(props.courses || []);
  }, [props.courses]);

  const context = {
    ...props,
    additionalColors,
    handleSetCourses,
    courses,
  };

  return (
    <CoursesPageContext.Provider value={context}>
      {children}
    </CoursesPageContext.Provider>
  );
};

export const useCoursesPageContext = (): CoursesPageContextModel => {
  const value = useContext(CoursesPageContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {} as CoursesPageContextModel;
  }

  return value;
};
