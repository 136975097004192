import { useEffect, useState } from 'react';

import { useTranslations } from '../features';

export type UseControlHook = [
  string,
  string[],
  boolean,
  (value: string) => void,
  (value: string) => void,
  (value: string[]) => void
];

export type ControlValidationRule = {
  rule: (value: string) => boolean;
  messageTranslationKey: string;
  message?: string;
};

export const useControl = (
  initialValue: string | undefined = '',
  rules?: ControlValidationRule[],
  immediatelyValidate = false
): UseControlHook => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);

  const translations = useTranslations();

  const handleChange = (newValue: string): void => {
    setValue(newValue);
    setErrors([]);
    setIsValid(true);
  };

  const handleBlur = (newVal: string): void => {
    rules &&
      rules.some((rule) => {
        if (rule.rule(newVal)) {
          if (rule.message) {
            setErrors([rule.message]);
          } else {
            setErrors([translations[rule.messageTranslationKey]]);
          }
          setIsValid(false);

          return true;
        }
      });
  };

  const handleSetErrors = (newErrors: string[]): void => {
    setErrors(newErrors);
    setIsValid(false);
  };

  useEffect(() => {
    if (initialValue && immediatelyValidate) {
      handleBlur(initialValue);
    }
  }, []);

  return [value, errors, isValid, handleChange, handleBlur, handleSetErrors];
};
