import React, { ReactElement, useMemo } from 'react';
import { CompanyLogoStyled, LogoPlug } from './companyLogo.styled';
import { HeaderLogoContainerProps } from './companyLogo.model';
import { PRESETS } from '../../../services';
import { ImageContainer } from '../../image';

const { h: height, w: width } = PRESETS.logoCompanyBig.opts;

export const CompanyLogoContainer = ({
  logo,
  mediaDomain,
}: HeaderLogoContainerProps): ReactElement => {
  const companyLogo = useMemo(
    () =>
      logo ? (
        <ImageContainer
          height={height}
          width={width}
          mediaDomain={mediaDomain}
          imageResource={logo}
          preset="logoCompanyBig"
        />
      ) : (
        <LogoPlug width={width} height={height} />
      ),
    [logo]
  );

  return <CompanyLogoStyled>{companyLogo}</CompanyLogoStyled>;
};
