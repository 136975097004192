import React, { ReactElement } from 'react';
import { LinksParentContainerProps } from './linksParent.model';
import { MobileLinkContainer } from '../../mobileLink';
import { projectTypes } from '../../../../../../constants';
import { Nullable } from '../../../../../../models';

export const LinksParentContainer = ({
  parent,
  handleClose,
  projectPageName,
}: LinksParentContainerProps): Nullable<ReactElement> => {
  if (parent.type === projectTypes.hr) return null;

  return (
    <>
      {parent.children.map((children) => (
        <MobileLinkContainer
          {...children}
          parentType={parent.type}
          key={children.title}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </>
  );
};
