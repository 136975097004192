import React, { ReactElement } from 'react';
import { DesktopStaticLinksStyled } from './desktopStaticLinks.styled';
import { DesktopStaticLinksContainerProps } from './desktopStaticLinks.model';
import { DesktopLinkContainer } from '../desktopLink';

export const DesktopStaticLinksContainer = ({
  links,
  bordered,
  handleClose,
  projectPageName,
}: DesktopStaticLinksContainerProps): ReactElement => {
  return (
    <DesktopStaticLinksStyled bordered={bordered}>
      {links.map((link) => (
        <DesktopLinkContainer
          key={link.title}
          {...link}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </DesktopStaticLinksStyled>
  );
};
