import React, { ReactElement } from 'react';
import { MobileLinkContainer } from '../mobileLink';
import { MobileStaticLinksContainerProps } from './mobileStaticLinks.model';

export const MobileStaticLinksContainer = ({
  links,
  handleClose,
  projectPageName,
}: MobileStaticLinksContainerProps): ReactElement => {
  return (
    <>
      {links.map((link) => (
        <MobileLinkContainer
          key={link.title}
          {...link}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      ))}
    </>
  );
};
