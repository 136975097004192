import { RequestHeader } from '../../services';
import { OnboardingClient } from './onboarding.client';
import { OnboardingSlugsEnum } from './onboarding.model';

export async function onboardingClientProvider(
  slugs: OnboardingSlugsEnum[],
  requestHeaders?: RequestHeader
): Promise<null> {
  const response = new OnboardingClient(slugs, requestHeaders).post();

  return response as unknown as Promise<null>;
}
