import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCVComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18 6.5V4c0-1.1046-.8954-2-2-2H5c-1.1046 0-2 .8954-2 2v13c0 1.1046.8954 2 2 2h6"
    />
    <circle
      cx="18"
      cy="12.5"
      r="3"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18 18c-2.3584 0-3.3262 1.3905-3.7235 2.5318C14.0042 21.3142 14.6716 22 15.5 22h5c.8284 0 1.4958-.6858 1.2235-1.4682C21.3262 19.3905 20.3584 18 18 18zM7 6h7M7 9.5h4"
    />
  </svg>
);
