import React, { memo, ReactElement } from 'react';

import { useTheme } from '../../../hooks';
import { Input } from '../../form';
import { IconMagnifierComponent } from '../../icons';
import { FilterPopupSearchProps } from './filterPopupSearch.model';
import { FilterPopupSearchSectionStyled } from './filterPopupSearch.styled';

function RenderFilterPopupSearchContainer({
  placeholder,
  value,
  onSearch,
}: FilterPopupSearchProps): ReactElement {
  const theme = useTheme();

  return (
    <FilterPopupSearchSectionStyled>
      <IconMagnifierComponent
        fillColor={theme.COLOR.TEXT_LIGHT_COLOR}
        strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
      />

      <Input
        id="search"
        name="search"
        placeholder={placeholder}
        autoComplete={'off'}
        value={value}
        onChange={onSearch}
        floatingPlaceholder={false}
        showResetButton={false}
      />
    </FilterPopupSearchSectionStyled>
  );
}

export const FilterPopupSearchContainer = memo(
  RenderFilterPopupSearchContainer
);
