import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { useTheme } from '../../../hooks';
import { LanguageLabel } from '../../../models';
import {
  IconRadiobuttonCheckedComponent,
  IconRadiobuttonComponent,
} from '../../icons';
import { USER_LOCALE_COOKIE_NAME } from '../switchLanguage.constants';
import { LanguageItemStyled } from './languageItem.styled';
import { cookieService, dateService, urlService } from '../../../services';

type LanguageItemContainerProps = {
  locale: string;
  handleClickItem?: () => void;
};

export const LanguageItemContainer = ({
  locale,
  handleClickItem,
}: LanguageItemContainerProps): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const isActive = router.locale === locale;
  const color = isActive
    ? theme.COLOR.TEXT_DARK_COLOR
    : theme.COLOR.TEXT_LIGHT_COLOR;
  const Icon = isActive ? (
    <IconRadiobuttonCheckedComponent fillColor={color} strokeColor={color} />
  ) : (
    <IconRadiobuttonComponent fillColor={color} strokeColor={color} />
  );

  const handleClick = (): void => {
    cookieService.setCookie({
      name: USER_LOCALE_COOKIE_NAME,
      val: locale,
      days: dateService.yearInDays,
    });
    if (handleClickItem) {
      handleClickItem();
    }
    router.push(
      urlService.createUrlFromPathAndQuery(
        router.pathname,
        router,
        router.query
      ),
      undefined,
      { locale }
    );
  };

  const getTitle = (): string => {
    return LanguageLabel[locale] || locale;
  };

  return (
    <LanguageItemStyled isActive={isActive} onClick={handleClick}>
      {Icon}
      {getTitle()}
    </LanguageItemStyled>
  );
};
