import { UserOrderFormSubmitStatusTypes } from '../userOrderForm.model';
import { CommonEventTypes, CourseModel } from '@lerna-core';
import {
  EVENT_TYPE,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';

export const pushFormWithoutPricesSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string
): void => {
  const eventValueOptions = [
    OrderFormEventsValues.addOnCompensation,
    OrderFormEventsValues.noTabs,
    OrderFormEventsValues.withCompensation,
  ];
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  orderFormEvent(event, eventValueOptions, eventType, eventPosition, course);
};
