import {
  CourseModel,
  getBonusPointsLabel,
  TranslationModel,
} from '@lerna-core';

export const getAdvantages = (
  { price }: CourseModel,
  translations: TranslationModel
): string[] => {
  const advantages = [];

  if (price?.priceCustomer?.cashBack?.percent) {
    const cashbackText = `${translations.cashback_label} ${
      price.priceCustomer.cashBack.percent
    }%: ${getBonusPointsLabel(Number(price.priceCustomer.cashBack.points), [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])} ${translations?.on_lerna}`;
    advantages.push(cashbackText);
  }

  return advantages;
};
