import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { TableFilterContainer } from '../../../../table';
import {
  requestTransferCustomersAllocated,
  setTransferCustomersAllocatedFilters,
} from '../../customersTransfer.actions';
import { useCustomersTransferContext } from '../../customersTransfer.context';
import { getCustomersTransferSelector } from '../../customersTransfer.selectors';
import { getTariffsFilterOptions } from '../transferTariffs.helper';
import { TariffsAllocatedFilterContainerProps } from './tariffsAllocatedFilter.model';

export const TariffsAllocatedFilterContainer = ({
  translations,
}: TariffsAllocatedFilterContainerProps): ReactElement => {
  const {
    id,
    customersProvider,
    dispatch,
    subscriptionId,
    libraryId,
    benefitId,
    tariffId,
    tariffs = [],
  } = useCustomersTransferContext();
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCustomersTransferSelector);

  const handleApplyFilter = (tariffUuids: string[]): void => {
    dispatch(setTransferCustomersAllocatedFilters({ tariffUuids }));
  };

  const handleRequest = (tariffUuids: string[]): void => {
    dispatch(
      requestTransferCustomersAllocated(customersProvider, {
        ...filters,
        tariffUuids,
        removed,
        added,
        id,
        relatedWithSubscriptionId: subscriptionId,
        relatedWithBenefitId: benefitId,
        relatedWithLibraryId: libraryId,
        relatedWithTariffId: tariffId,
      })
    );
  };

  const filterOptions = getTariffsFilterOptions(tariffs);

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.tariffUuids}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.tariff}
    />
  );
};
