import styled, { css } from 'styled-components';
import { fontSettings } from '../../../styles';
import { breakpoint } from '../../../styles';
import { FooterHover } from '../footer.mixin';

export const FooterLinkContainerStyled = styled.a`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.WHITE};
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${FooterHover}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
