import { staticsAdapter } from './statics.adapter';
import {
  StaticsApiModel,
  StaticsErrorModel,
  StaticsModel,
  StaticsRequestParamsModel,
} from './statics.model';
import { RequestHeader } from '../../services/request';
import { RequestClient } from '../../services/request/request.service';
import { publicConfig, serverConfig } from '../../services';

export class StaticsClient extends RequestClient<
  StaticsRequestParamsModel,
  StaticsApiModel,
  StaticsModel | StaticsErrorModel
> {
  protected path = 'aggregate/v1/statics';
  protected requestParameters: StaticsRequestParamsModel;
  protected adapter = staticsAdapter;

  constructor(params: StaticsRequestParamsModel, headers?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );
    this.requestParameters = params;
  }
}
