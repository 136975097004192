import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpoint } from '@lerna-core';
import { SubtitleStyled, TitleStyled } from './itemContent';
import { advantagesBreakpoint } from '../../coursesAdvantages.styled';

export const ListItemStyled = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.5)};
  border-radius: 8px;
  box-sizing: border-box;
  max-width: max-content;
  margin: 8px;

  ${breakpoint(
    'xxlargeDown',
    css`
      &:nth-child(1) {
        ${SubtitleStyled} {
          max-width: 255px;
        }
      }
      &:nth-child(2) {
        ${SubtitleStyled} {
          max-width: 210px;
        }
      }
      &:nth-child(3) {
        ${SubtitleStyled} {
          max-width: 165px;
        }
      }
    `
  )}
  ${advantagesBreakpoint} {
    border: none;
    padding: 0;

    &:nth-child(1) {
      ${TitleStyled} {
        max-width: unset;
      }
      ${SubtitleStyled} {
        max-width: unset;
      }
    }
    &:nth-child(2) {
      ${TitleStyled} {
        max-width: unset;
      }
      ${SubtitleStyled} {
        max-width: unset;
      }
    }
    &:nth-child(3) {
      ${TitleStyled} {
        max-width: unset;
      }
      ${SubtitleStyled} {
        max-width: unset;
      }
    }
  }
  ${breakpoint(
    'largeDown',
    css`
      &:nth-child(1) {
        ${TitleStyled} {
          max-width: 130px;
        }
        ${SubtitleStyled} {
          max-width: 150px;
        }
      }
      &:nth-child(2) {
        ${TitleStyled} {
          max-width: 120px;
        }
        ${SubtitleStyled} {
          max-width: 130px;
        }
      }
      &:nth-child(3) {
        ${TitleStyled} {
          max-width: 90px;
        }
        ${SubtitleStyled} {
          max-width: 130px;
        }
      }
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      max-width: unset;
      margin: 0;

      &:nth-child(1) {
        ${TitleStyled} {
          max-width: unset;
        }
        ${SubtitleStyled} {
          max-width: unset;
        }
      }
      &:nth-child(2) {
        ${TitleStyled} {
          max-width: unset;
        }
        ${SubtitleStyled} {
          max-width: unset;
        }
      }
      &:nth-child(3) {
        ${TitleStyled} {
          max-width: unset;
        }
        ${SubtitleStyled} {
          max-width: unset;
        }
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    `
  )}
`;
