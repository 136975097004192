import styled from 'styled-components';
import { fontSettings } from '../../../../styles';
import { ChipStyledProps } from './chip.model';

export const ChipStyled = styled.div<ChipStyledProps>`
  display: flex;
  align-items: center;
  max-width: max-content;
  overflow: hidden;
  align-self: flex-start;
  gap: 6px;

  padding: 4px 8px;
  margin: 6px;

  border-radius: 100px;
  max-height: 28px;
  font-weight: 500;
  background: ${({ isValid }): string => (isValid ? '#F4F6FA' : '#FFE5E6')};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme, isValid }): string =>
    isValid ? theme.COLOR.INFO_BLUE_COLOR : theme.COLOR.INFO_RED_COLOR};
`;

export const ChipInputStyled = styled.input`
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  font-weight: 500;
  padding: 4px 0 4px 10px;
  margin: 6px;
  height: 28px;
  box-sizing: border-box;
`;

type ChipCloseStyledProps = {
  isValid?: boolean;
};

export const ChipCloseStyled = styled.div<ChipCloseStyledProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isValid }): string =>
    isValid ? theme.COLOR.INFO_BLUE_COLOR : theme.COLOR.INFO_RED_COLOR};
  border-radius: 50%;
`;

export const ChipTextStyled = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
