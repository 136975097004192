import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconEmptyComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill={strokeColor} />
  </svg>
);
