import {
  FiltersSlugEnum,
  IconBookMinimalisticComponent,
  IconOnlyMiniCourses,
  IconProps,
  IconStarComponent,
  Nullable,
} from '@lerna-core';
import { FC } from 'react';

export const getFilterIcon = (
  slug: FiltersSlugEnum
): Nullable<FC<IconProps>> => {
  switch (slug) {
    case FiltersSlugEnum.subscription:
      return IconBookMinimalisticComponent;
    case FiltersSlugEnum.wb:
      return IconStarComponent;
    case FiltersSlugEnum.onlyMiniCourses:
      return IconOnlyMiniCourses;
    default:
      return null;
  }
};
