import { useB2BLandingLink } from '@hooks';
import { Nullable } from '@lerna-core';
import React, { ReactElement, useContext, useMemo } from 'react';
import { PageNameContext } from '../../../../../contexts';
import { getHeaderDesktopTopLinks } from '../headerDesktop.meta';
import { DesktopLinksStyled } from './deskktopLinks.styled';
import { getDesktopLinks } from './desktopLinks.meta';
import { DesktopLinksContainerProps } from './desktopLinks.model';
import { useRouter } from 'next/router';

export const DesktopLinksContainer = ({
  user,
  headerLinkType,
  translations,
  customerCoursesCount = 0,
}: DesktopLinksContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const pageName = useContext(PageNameContext);
  const readyToShowB2bLandingLink = useB2BLandingLink();

  const menuSwitcherLinks = useMemo(
    () =>
      getHeaderDesktopTopLinks(
        user,
        translations,
        customerCoursesCount,
        pageName
      ),
    [user, customerCoursesCount]
  );
  const desktopLinks = useMemo(
    () =>
      getDesktopLinks(
        headerLinkType,
        translations,
        readyToShowB2bLandingLink,
        menuSwitcherLinks,
        router
      ),
    [readyToShowB2bLandingLink, menuSwitcherLinks]
  );

  return <DesktopLinksStyled>{desktopLinks}</DesktopLinksStyled>;
};
