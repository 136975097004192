import Script from 'next/script';
import React, { ReactElement } from 'react';
import { Nullable } from '../../models';
import { useFasttrackWidget } from './fasttrackWidget.hook';
import { FasttrackWidgetPropsModel } from './fasttrackWidget.model';
import FasttrackWidgetGlobalStyles from './fasttrackWidget.styled';

export const FasttrackWidgetContainer = ({
  fasttrackId = 'b064bb25-c3ad-423f-a234-32dce559bd59',
  show = false,
}: FasttrackWidgetPropsModel): Nullable<ReactElement> => {
  const { showFasttrackWidget, handleLoad } = useFasttrackWidget(show);

  if (!showFasttrackWidget) {
    return null;
  }

  return (
    <>
      <Script
        strategy="lazyOnload"
        onLoad={handleLoad}
        src={`https://dashboard.fstrk.io/widget3/${fasttrackId}.js`}
      />
      <FasttrackWidgetGlobalStyles />
    </>
  );
};
