import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBuildingGradientComponent = ({
  width = 40,
  height = 40,
}: IconProps): ReactElement => (
  <svg height={height} width={width} viewBox="0 0 40 40" fill="none">
    <path
      fill="url(#paint0_linear_3042_49704)"
      fillRule="evenodd"
      d="M8 1.95c-.69 0-1.25.56-1.25 1.25v3.585A2.85 2.85 0 004.35 9.6v28.45h31.3V9.6a2.85 2.85 0 00-2.4-2.815V3.2c0-.69-.56-1.25-1.25-1.25H8zm22.75 4.8v-2.3H9.25v2.3h21.5zM7.2 9.25a.35.35 0 00-.35.35v25.95h14.3v-8h9.7v8h2.3V9.6a.35.35 0 00-.35-.35H7.2zm1.95 2.3h9.7v6.5h-9.7v-6.5zm2.5 2.5v1.5h4.7v-1.5h-4.7zm9.5-2.5h9.7v6.5h-9.7v-6.5zm2.5 2.5v1.5h4.7v-1.5h-4.7zm-14.5 5.5h9.7v6.5h-9.7v-6.5zm2.5 2.5v1.5h4.7v-1.5h-4.7zm9.5-2.5h9.7v6.5h-9.7v-6.5zm2.5 2.5v1.5h4.7v-1.5h-4.7zm-14.5 5.5h9.7v6.5h-9.7v-6.5zm2.5 2.5v1.5h4.7v-1.5h-4.7zm12 5.5h4.7v-5.5h-4.7v5.5z"
      clipRule="evenodd"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_3042_49704"
        x1="4.35"
        x2="36.305"
        y1="25.866"
        y2="17.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE2C72"></stop>
        <stop offset="1" stopColor="#4C07DF"></stop>
      </linearGradient>
    </defs>
  </svg>
);
