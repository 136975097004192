import { CoursePageHrefProps, CourseUrlParams } from './course.model';
import { urlService } from '../../services';
import { mainPath } from '../../constants';
import { CourseLinkModel, CourseLinkTypes } from './courseUrl';
import { CourseOptionLogicTypes, CourseOptionModel } from './courseOptions';
import { Nullable } from '../../models';

export const getCourseSubdomainPageHref = (
  url: CourseLinkModel,
  urlParams: CourseUrlParams,
  baseDomain?: string
): string => {
  let coursePath = `${url.params.path}/${url.params.courseSlug}`;

  if (!!Object.keys(urlParams).length) {
    coursePath += `?${new URLSearchParams(urlParams).toString()}`;
  }

  return urlService.getFullUrlToSubDomain(
    url.params.vendorSlug || '',
    coursePath,
    baseDomain
  );
};

export const getCoursePageHref = ({
  course,
  router,
  urlParams,
  baseDomain,
  basePath = mainPath.course,
}: CoursePageHrefProps): Nullable<string> => {
  if (!course.url) return null;

  switch (course.url.type) {
    case CourseLinkTypes.lernaVendorCourse: {
      return getCourseSubdomainPageHref(course.url, urlParams, baseDomain);
    }

    default: {
      let path = `${basePath}/${course.slug}`;

      if (Object.keys(urlParams).length > 0) {
        path += `?${new URLSearchParams(urlParams).toString()}`;
      }

      return urlService.getFullUrlToPage(path, router, [], baseDomain);
    }
  }
};

export const getCourseSphere = (options: CourseOptionModel[]): string => {
  const sphere = options.find((option: CourseOptionModel) => {
    return option.logicType === CourseOptionLogicTypes.spheres;
  });

  if (sphere) {
    return sphere.value;
  }

  return '';
};
