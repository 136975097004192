import React, { ReactElement } from 'react';
import {
  getBonusPointsLabel,
  IconWalletComponent,
  useTheme,
} from '@lerna-core';
import * as S from './pricesCashBack.styled';
import { PricesCashBackContainerProps } from './pricesCashBack.model';

export const PricesCashBackContainer = ({
  cashBack,
  translations,
}: PricesCashBackContainerProps): ReactElement => {
  const { COLOR } = useTheme();
  const cashbackText = `${translations.cashback_label} ${cashBack.percent}%: `;
  const cashbackValue = `${getBonusPointsLabel(Number(cashBack.points), [
    translations.points_label_1,
    translations.points_label_2,
    translations.points_label_many,
  ])}`;

  return (
    <S.PricesCashBack>
      <S.PricesCashBackIcon>
        <IconWalletComponent
          strokeColor={COLOR.PRIMARY['500']}
          height={24}
          width={24}
        />
      </S.PricesCashBackIcon>
      <S.PricesCashBackText>
        {cashbackText}
        <b>&nbsp;{cashbackValue}</b>
      </S.PricesCashBackText>
    </S.PricesCashBack>
  );
};
