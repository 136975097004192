import styled from 'styled-components';
import { ResetItemStyled } from '../resetItem';

export const ResetAllItemStyled = styled(ResetItemStyled)`
  color: ${({ theme }): string => theme.COLOR.WHITE};
  background-color: #333333;

  &:hover {
    background-color: ${({ theme }): string => theme.COLOR.BLACK};
  }
`;
