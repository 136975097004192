import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconHighlightComponent = ({
  width = 20,
  height = 20,
  fillColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.99742C5.46656 9.87551 9.87551 5.46656 9.99742 0H10.0026C10.1245 5.46656 14.5334 9.87551 20 9.99742V10.0026C14.5334 10.1245 10.1245 14.5334 10.0026 20H9.99742C9.87551 14.5334 5.46656 10.1245 0 10.0026V9.99742Z"
      fill={fillColor}
    />
  </svg>
);
