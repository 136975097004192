import {
  LibraryApiModel,
  LibraryModel,
  LibraryTypeApiModel,
  LibraryTypeModel,
} from './library.model';
import { VendorModel } from '../vendors';
import { dateService } from '../../services';

export const libraryTypeAdapter = (
  libraryTypeApi: LibraryTypeApiModel
): LibraryTypeModel => ({
  school: libraryTypeApi.school,
  type: libraryTypeApi.type,
  isVisible: libraryTypeApi.is_visible,
  courseStatus: libraryTypeApi.course_status,
});

export const libraryAdapter = (
  libraryApi: LibraryApiModel,
  vendors: VendorModel[]
): LibraryModel => ({
  id: libraryApi.id,
  title: libraryApi.title,
  claims: libraryApi.claims,
  claimedCourseCount: libraryApi.claimed_courses_count,
  expiredAt: dateService.formatDate(libraryApi.expired_at),
  vendor: vendors.find(
    (vendor) => vendor.uuid === libraryApi.vendor_id
  ) as VendorModel,
  type: libraryTypeAdapter(libraryApi.type),
});
