import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconReloadComponent = ({
  width = 66,
  height = 60,
  strokeColor = '#29004D',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2500_2542)">
        <path
          d="M20.4879 9C20.0547 7.77164 19.3468 6.61852 18.3643 5.63604C14.8496 2.12132 9.15114 2.12132 5.63642 5.63604L2.27246 9M3.51289 15C3.94609 16.2284 4.65394 17.3815 5.63642 18.364C9.15114 21.8787 14.8496 21.8787 18.3643 18.364L21.7283 15M2.27246 9L2.27246 3M2.27246 9L8.27037 9M21.7283 15L21.7283 21M21.7283 15L15.7304 15"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2500_2542">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
