import styled, { css } from 'styled-components';

import { fontSettings } from '../../styles';
import { TooltipContainerStyled } from '../tooltip';

const align = (props: { align?: string }): ReturnType<typeof css> => css`
  text-align: ${props.align || 'left'};
`;

export const TableBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  border-radius: 16px;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
`;

export const Table = styled.table`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  border-collapse: collapse;
  width: 100%;
`;

export const TableHead = styled.thead`
  padding: 0 20px;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const TableBody = styled.tbody`
  padding: 0 20px;
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &:last-child:not(:first-child) {
    border-bottom: none;
  }
`;

export const TableHeadCell = styled.th`
  ${align};
  padding: 20px 0;
  font-weight: normal;
`;

export const TableCell = styled.td`
  ${align};
  padding-top: 25px;
  padding-bottom: 25px;

  &:not(:last-child) {
    padding-right: 24px;
  }
`;

export const RightAlign = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const TableActionsContainer = styled.div`
  display: flex;
`;

export const TableActionButton = styled.button`
  color: ${({ color }): string | undefined => color};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 30px;
  }

  & > span {
    margin-left: 8px;
  }

  ${TooltipContainerStyled} {
    cursor: pointer;
  }
`;
