import styled from 'styled-components';
import { ITheme } from '../../../themes';
import { fontSettings } from '../../../../styles';

export const HistogramMarkersStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin: calc(${({ theme }): string => `-${getMarkerFontSize(theme)}px`} / 2) 0;
`;

export const getMarkerFontSize = (theme: ITheme): number => {
  return theme.FONT.SIZE_13;
};

export const MarkerLabelStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  ${({ theme }): string => fontSettings(getMarkerFontSize(theme), theme.FONT)};
`;
