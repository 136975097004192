export enum BenefitType {
  PERCENTAGE = 'PERCENTAGE',
  ABSOLUTE = 'ABSOLUTE',
}

export type CourseBenefitModel = {
  id: string;
  type: keyof typeof BenefitType;
  isCommentNecessary: boolean;
};

export type CourseBenefitApiModel = {
  id: string;
  type: keyof typeof BenefitType;
  is_comment_necessary: boolean;
};
