import React, { ReactElement } from 'react';
import {
  PricesItemStyled,
  TitleStyled,
  ValueStyled,
} from './pricesItem.styled';
import { PricesItemContainerProps } from './pricesItem.model';
import { Nullable } from '@lerna-core';

export const PricesItemContainer = ({
  title,
  value,
  isNegative,
  itemStyles,
}: PricesItemContainerProps): Nullable<ReactElement> => {
  if (!value) return null;

  return (
    <PricesItemStyled styles={itemStyles?.item}>
      <TitleStyled styles={itemStyles?.title}>{title}</TitleStyled>
      <ValueStyled styles={itemStyles?.value}>
        {isNegative && '-'} {value}
      </ValueStyled>
    </PricesItemStyled>
  );
};
