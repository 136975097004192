import { CSSVariables } from '@lerna-core';
import { css } from 'styled-components';
import { HeaderColorTypes } from './header.model';

export const stickyHeaderBg = (): ReturnType<typeof css> => css`
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: rgba(255, 255, 255, 0.8);
`;

export const getHeaderStyle = (
  isSticky: boolean,
  colorType?: HeaderColorTypes
): ReturnType<typeof css> => {
  switch (true) {
    case colorType === HeaderColorTypes.white:
      return css`
        background: rgb(255, 255, 255);

        &:after {
          content: '';
          background: rgb(255, 255, 255);
          width: var(${CSSVariables.scrollbarPlugWidth});
          right: calc(0px - var(${CSSVariables.scrollbarPlugWidth}));
          position: absolute;
          height: 100%;
          top: 0;
        }
      `;
    case colorType === HeaderColorTypes.default && isSticky:
      return css`
        ${stickyHeaderBg()};

        &:after {
          content: '';
          ${stickyHeaderBg()};
          width: var(${CSSVariables.scrollbarPlugWidth});
          right: calc(0px - var(${CSSVariables.scrollbarPlugWidth}));
          position: absolute;
          height: 100%;
          top: 0;
        }
      `;
  }

  return css``;
};
