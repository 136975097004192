export const excludeCompaniesFooterSberInfo = [
  'd6043e2a-9208-48bd-b2b0-0837291b8fd9',
  '582f3ea3-6f6f-4c29-a65f-bb4c20c83b8d',
];

export const isNotContainsExcludedCompanies = (companyId?: string) => {
  if (companyId) {
    return !excludeCompaniesFooterSberInfo.includes(companyId);
  }

  return true;
};
