import { TypeAllocatedFilterProps } from './typeAllocatedFilter.model';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrencies } from '../../../../currencies';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import { getVendors } from '../../../../vendors';
import { getCoursesTransferSelector } from '../../coursesTransfer.selectors';
import { getCoursesFilters } from '../../../coursesFilters';
import {
  requestTransferCoursesAllocated,
  setTransferCoursesAllocatedFilters,
} from '../../coursesTransfer.actions';
import { CourseTypes } from '../../../../course';
import { TableFilterContainer } from '../../../../table';
import { FilterOptionsTypes } from '../../../../filterPopup';

export const TypeAllocatedFilterContainer = ({
  translations,
}: TypeAllocatedFilterProps): ReactElement => {
  const currencies = useSelector(getCurrencies);
  const { id, coursesProvider, dispatch } = useCoursesTransferContext();
  const vendors = useSelector(getVendors);
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const { types: filterOptions } = useSelector(getCoursesFilters);

  const handleApplyFilter = (types: string[]): void => {
    dispatch(
      setTransferCoursesAllocatedFilters({ types: types as CourseTypes[] })
    );
  };

  const handleRequest = (types: string[]): void => {
    dispatch(
      requestTransferCoursesAllocated(
        coursesProvider,
        {
          ...filters,
          removed,
          added,
          id,
          types: types as CourseTypes[],
        },
        currencies,
        vendors
      )
    );
  };

  return (
    <TableFilterContainer
      optionType={FilterOptionsTypes.radio}
      filterOptions={filterOptions}
      filters={filters.types}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      labelKey={'title'}
      idKey={'slug'}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.type}
    />
  );
};
