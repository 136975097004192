import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconUsersComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <circle
      cx="9"
      cy="7"
      r="4.5"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      d="M15 11.5c2.4853 0 4.5-2.0147 4.5-4.5S17.4853 2.5 15 2.5M9 14.5c-4 0-7 2-7 4.5 0 1.5 3 3 7 3s7-1.5 7-3c0-2.5-3-4.5-7-4.5zM17.5 14.8884c2.6.6992 4.5 2.2697 4.5 4.1117 0 1.1051-1.9 2.2103-4.5 2.7156"
    />
  </svg>
);
