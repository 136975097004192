import React, { ReactElement, useContext } from 'react';
import { CourseVendorLogoStyled } from './courseVendorLogo.styled';
import { browserService, DeviceInfoContext, ImageContainer } from '@lerna-core';
import { CourseVendorLogoContainerProps } from './courseVendorLogo.model';

export const CourseVendorLogoContainer = ({
  logo,
}: CourseVendorLogoContainerProps): ReactElement => {
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const preset = isMobile ? 'logoVendorMobile' : 'logoVendor';

  return (
    <CourseVendorLogoStyled data-element={'vendorLogo'}>
      <ImageContainer imageResource={logo.imageResource} preset={preset} />
    </CourseVendorLogoStyled>
  );
};
