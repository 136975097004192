import React, { forwardRef, ReactElement, ReactNode, RefObject } from 'react';

import { useClickOutsideHook } from '../../../../../hooks';
import {
  SelectDropdownInnerStyled,
  SelectDropdownStyled,
} from './selectDropdown.styled';
import { Nullable } from '../../../../../models';

export type SelectOptionsContainerTypes = {
  children: ReactNode;
  handleOutsideClick: () => void;
  bordered?: boolean;
  directionOpenTop?: boolean;
  search?: ReactElement | null;
  dropdownHeight?: number;
};

export const SelectDropdown = forwardRef<
  HTMLElement,
  SelectOptionsContainerTypes
>(
  (
    {
      children,
      handleOutsideClick,
      bordered = true,
      directionOpenTop = false,
      search = null,
      dropdownHeight,
    },
    ref
  ): Nullable<ReactElement> => {
    useClickOutsideHook(ref as RefObject<HTMLDivElement>, handleOutsideClick);

    return (
      <SelectDropdownStyled
        bordered={bordered}
        directionOpenTop={directionOpenTop}
        search={!!search}
        dropdownHeight={dropdownHeight}
      >
        {search ? search : null}
        <SelectDropdownInnerStyled>{children}</SelectDropdownInnerStyled>
      </SelectDropdownStyled>
    );
  }
);

SelectDropdown.displayName = 'SelectDropdown';
