import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ChipsInputStyled,
  ChipsWrapperStyled,
  InnerStyled,
  TextAreaStyled,
  WrapperStyled,
} from './chipsInput.styled';
import { ChipsInputProps, KEYCODES } from './chipsInput.model';
import { isChipInList } from './chipsInput.service';
import { ChipContainer } from './chip';
import { ChipModel } from './chip/chip.model';
import { FloatingPlaceholderStyled, FormControlError } from '../../form';

export const ChipsInputContainer = ({
  label,
  chips = [],
  onChange,
  isValidRule,
  errorMessage,
  duplicateErrorMessage,
  maxLength = 255,
  isAllowSpace = false,
}: ChipsInputProps): ReactElement => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [values, setValues] = useState<ChipModel[]>(chips);

  const isChipsExist = values.length > 0;

  const handleChangeChips = (chips: ChipModel[]): void => {
    setValues(chips);
    onChange(chips);
  };

  const addChip = (): void => {
    const trimmedValue = inputValue.trim();

    if (!trimmedValue.length) return;
    if (isChipInList(trimmedValue, values)) {
      setError(duplicateErrorMessage);

      return;
    }

    const chip: ChipModel = {
      value: trimmedValue,
      isValid: isValidRule ? isValidRule.test(trimmedValue) : true,
    };

    const chips = [...values, chip];

    handleChangeChips(chips);
    setInputValue('');
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    const shouldAddKeys = [
      ...(isAllowSpace ? [] : [KEYCODES.SPACE]),
      KEYCODES.ENTER,
      KEYCODES.TAB,
    ];
    const shouldAdd = shouldAddKeys.includes(e.keyCode);

    if (shouldAdd) {
      e.preventDefault();
      addChip();
    }

    const shouldDeleteLast =
      e.keyCode === KEYCODES.BACKSPACE && !inputValue && isChipsExist;
    if (!shouldDeleteLast) return;

    const chipsWithoutLast = values.slice(0, -1);
    handleChangeChips(chipsWithoutLast);
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setInputValue(e.target.value);
  };

  const handleDelete = (deletedEmail: string): void => {
    const filteredChips = values.filter(
      (email) => email.value !== deletedEmail
    );
    handleChangeChips(filteredChips);
  };

  const handlePaste = (e: ClipboardEvent): void => {
    e.preventDefault();
    const separator = isAllowSpace ? /[\r\n]/ : /\s|\r|\n/;

    const paste = e.clipboardData?.getData('text')?.split(separator);
    const pasteWithoutDuplicates = Array.from(new Set(paste));
    const pastedChips = pasteWithoutDuplicates.reduce(
      (arr: ChipModel[], value) => {
        const trimmedValue = value.trim();
        const trimmedToMaxLengthValue = trimmedValue.substring(0, maxLength);

        return trimmedToMaxLengthValue
          ? [
              ...arr,
              {
                value: trimmedToMaxLengthValue,
                isValid: isValidRule
                  ? isValidRule.test(trimmedToMaxLengthValue)
                  : true,
              },
            ]
          : arr;
      },
      []
    );

    const isPastedChipsExist = pastedChips?.length > 0;

    if (!isPastedChipsExist) return;

    const toBeAdded = pastedChips.filter(
      (chip) => !isChipInList(chip.value, values)
    );
    const chips = [...values, ...toBeAdded];

    handleChangeChips(chips);
  };

  const handleUpdateChip = (
    newText: string,
    prevText: string,
    toggleEditing: () => void
  ): void => {
    const updatedChips = values.map((existingChip) =>
      existingChip.value === prevText && newText.length > 0
        ? {
            value: newText,
            isValid: isValidRule ? isValidRule.test(newText) : true,
          }
        : existingChip
    );
    const duplicatedChips = updatedChips.filter(
      (email) => email.value === newText
    );

    if (duplicatedChips.length <= 1) {
      handleChangeChips(updatedChips);
      toggleEditing();
    } else {
      setError(duplicateErrorMessage);
    }
  };

  const handleTextAreaFocus = (): void => {
    textAreaRef.current?.focus();
    addChip();
  };

  const inputError = error || errorMessage;

  useEffect(() => {
    setError('');
  }, [inputValue]);

  const chipsList = isChipsExist && (
    <ChipsWrapperStyled>
      {values.map((email) => (
        <ChipContainer
          key={email.value}
          value={email.value}
          maxLength={maxLength}
          isValid={email.isValid}
          handleDelete={handleDelete}
          handleUpdate={handleUpdateChip}
        />
      ))}
    </ChipsWrapperStyled>
  );

  return (
    <ChipsInputStyled>
      <WrapperStyled error={Boolean(inputError)}>
        <FloatingPlaceholderStyled
          hasError={Boolean(inputError)}
          children={label}
          active={isChipsExist}
        />

        <InnerStyled>
          {chipsList}
          <TextAreaStyled
            value={inputValue}
            onClick={handleTextAreaFocus}
            onBlur={addChip}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            onPaste={handlePaste}
            maxLength={maxLength}
            rows={1}
            ref={textAreaRef}
          />
        </InnerStyled>
      </WrapperStyled>
      {inputError && <FormControlError>{inputError}</FormControlError>}
    </ChipsInputStyled>
  );
};
