import {
  CourseDurationApiModel,
  CourseDurationModel,
} from './courseDurations.model';

export const courseDurationsAdapter = (
  durations: CourseDurationApiModel[]
): CourseDurationModel[] =>
  durations.map((duration) => ({
    amount: duration.amount,
    unit: duration.unit,
  }));
