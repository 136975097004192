import { publicConfig, RequestHeader, serverConfig } from '../../../services';
import { RequestClient } from '../../../services/request/request.service';
import { AuthorizationPaths } from '../authorizationsPaths.enum';

export class RefreshTokenClient extends RequestClient<null, null, null> {
  protected path = AuthorizationPaths.refreshToken;

  protected requestParameters: null;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.AUTH_URL,
      serverConfig?.PRIVATE_AUTH_URL,
      requestHeaders
    );

    this.requestParameters = null;
  }
}
