import { AdvantagesPrivilegeTypes, hasBonusPoints, useUser } from '@lerna-core';
import React, { ReactElement, useState } from 'react';
import { ItemPopupContainer as PrivilegePopupContainer } from '../../courses/coursesAdvantages';
import { useCoursesPageContext } from '../../courses/coursesPage';
import { WildberriesBonusesContainer } from '../wildberriesBonuses';
import { wildberriesAdvantagesMeta } from './wildberriesAdvantages.meta';
import { WildberriesAdvantagesModel } from './wildberriesAdvantages.model';
import * as S from './wildberriesAdvantages.styled';

export const WildberriesAdvantagesContainer = ({
  handleScrollToCourses,
}: WildberriesAdvantagesModel): ReactElement => {
  const { user } = useUser();
  const { advantages, translations, currencies } = useCoursesPageContext();
  const cashbackPrivilege = advantages?.privileges.find(
    (privilege) => privilege.type === AdvantagesPrivilegeTypes.cashback
  );
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);

  const handleOpenPopup = (): void => {
    setIsOpenedPopup(true);
  };
  const handleClose = (): void => {
    setIsOpenedPopup(false);
  };
  const { title } = wildberriesAdvantagesMeta;
  const showBonuses = hasBonusPoints(user?.career);
  const handleClickActionButton = (): void => {
    handleScrollToCourses();
  };

  return (
    <>
      <S.WildberriesAdvantages>
        <S.WildberriesAdvantagesTitle>{title}</S.WildberriesAdvantagesTitle>
        {showBonuses && (
          <WildberriesBonusesContainer
            currencies={currencies}
            bonuses={user?.bonuses}
            translations={translations}
            handleClickActionButton={handleClickActionButton}
            handleClickMoreButton={handleOpenPopup}
          />
        )}
      </S.WildberriesAdvantages>
      {cashbackPrivilege?.popup && (
        <PrivilegePopupContainer
          handleClose={handleClose}
          isOpened={isOpenedPopup}
          translations={translations}
          popup={cashbackPrivilege.popup}
        />
      )}
    </>
  );
};
