import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ColumnInstance,
  useFlexLayout,
  useRowSelect,
  UseRowSelectRowProps,
  useTable,
} from 'react-table';
import {
  getSelectionCell,
  getSelectionColumnOptions,
  getSelectionHeader,
  TableContainer,
  TableEndlessLoaderContainer,
  TableLoaderContainer,
} from '../../../table';
import { LocationType } from '../../../transfer';
import { getCoursesTransferSelector } from '../coursesTransfer.selectors';
import { CoursesTableProps } from './transferTable.model';
import { getOriginalSelectedRows } from './transferTable.service';
import {
  CoursesTableBoxStyled,
  CoursesTableEmptyCheckAll,
} from './transferTable.styled';

export const CoursesTransferTableContainer = ({
  selectionColWidth,
  selectionColMinWidth,
  defaultColMinWidth = 100,
  defaultColWidth = 200,
  tableData,
  handleCheckAll,
  isRadioButton,
  handleCheck,
  getSelectedRows,
  handlePagination,
  isLoading,
  isPaginating,
  meta,
  headerComponent,
  emptyContent,
  isSelectedAll,
  locationType,
}: CoursesTableProps): ReactElement => {
  const {
    added,
    removed,
    unallocated: { filters: unallocatedFilters },
    allocated: { filters: allocatedFilters },
  } = useSelector(getCoursesTransferSelector);

  const hasNextPage = meta.currentPage < meta.totalPages;
  const defaultColumn = {
    minWidth: defaultColMinWidth,
    width: defaultColWidth,
  };
  const autoResetSelectedRows = React.useRef<boolean>(false);
  const columns = useMemo(() => tableData.columns || [], []);
  const data = useMemo(() => {
    autoResetSelectedRows.current = false;

    return tableData.rows;
  }, [tableData.rows]);

  React.useEffect(() => {
    autoResetSelectedRows.current = true;
  });

  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSelectedRows: autoResetSelectedRows.current,
    },
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const selectionColumn = {
          id: 'selection',
          ...getSelectionCell(handleCheck, isRadioButton),
          ...getSelectionColumnOptions({
            selectionColWidth,
            selectionColMinWidth,
          }),
          // TODO: разобраться с типами
        } as unknown as ColumnInstance<Record<string, unknown>>;

        const cols = [selectionColumn, ...columns];

        if (typeof handleCheckAll === 'function' && !isRadioButton) {
          cols[0] = {
            ...cols[0],
            ...getSelectionHeader(handleCheckAll, isPaginating, isLoading),
          };
        } else {
          cols[0] = {
            ...cols[0],
            Header: <CoursesTableEmptyCheckAll></CoursesTableEmptyCheckAll>,
          };
        }

        return cols;
      });

      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  useEffect(() => {
    if (isSelectedAll) {
      table.toggleAllRowsSelected(true);
    }
  }, [isSelectedAll]);

  useEffect(() => {
    if (getSelectedRows) {
      getSelectedRows(getOriginalSelectedRows(table.selectedFlatRows));
    }
  }, [table.selectedFlatRows]);

  useEffect(() => {
    if (locationType === LocationType.unallocated) {
      table.toggleAllRowsSelected(false);
    }
  }, [added, removed, unallocatedFilters]);

  useEffect(() => {
    if (locationType === LocationType.allocated) {
      table.toggleAllRowsSelected(false);
    }
  }, [added, removed, allocatedFilters]);

  const handleRowClick = (
    row: UseRowSelectRowProps<Record<string, unknown>>
  ): void => {
    if (isRadioButton) {
      table.toggleAllRowsSelected(false);
    }
    row.toggleRowSelected();
  };

  return (
    <CoursesTableBoxStyled>
      <TableContainer
        isLoading={isLoading}
        isPaginating={isPaginating}
        hasNextPage={hasNextPage}
        handleRowClick={handleRowClick}
        headerComponent={headerComponent}
        handlePagination={handlePagination}
        hasEndlessPagination
        isStickyHeader
        table={table}
        height={594}
        paginationLoader={<TableEndlessLoaderContainer />}
        loader={<TableLoaderContainer />}
        emptyContent={emptyContent}
      />
    </CoursesTableBoxStyled>
  );
};
