import React, { ReactElement } from 'react';
import {
  CounterLoaderDotsItemStyled,
  CounterLoaderStyled,
} from './counterLoader.styled';
import { CounterLoaderModel } from './counterLoader.model';

export const CounterLoaderContainer = ({
  customColor,
}: CounterLoaderModel): ReactElement => (
  <CounterLoaderStyled>
    {[1, 2, 3].map((key) => (
      <CounterLoaderDotsItemStyled
        customColor={customColor}
        key={`dots-item-${key}`}
      />
    ))}
  </CounterLoaderStyled>
);
