import React, { ReactElement } from 'react';

import { FormCheckbox } from '../form';
import { UseRowSelectInstanceProps, UseRowSelectRowProps } from 'react-table';

export const selectionColumn = {
  id: 'selection',
  Header({
    getToggleAllRowsSelectedProps,
  }: UseRowSelectInstanceProps<Record<string, unknown>>): ReactElement {
    return <FormCheckbox {...getToggleAllRowsSelectedProps()} />;
  },
  Cell({
    row,
  }: {
    row: UseRowSelectRowProps<Record<string, unknown>>;
  }): ReactElement {
    return <FormCheckbox {...row.getToggleRowSelectedProps()} />;
  },
};
