import styled from 'styled-components';
import { BannerSizeTypes } from '@lerna-core';

export const HeaderBannerStyled = styled.div`
  min-width: 100%;
  overflow: hidden;

  img {
    display: none;
    min-width: 100%;
    max-width: 100%;
  }

  img[alt='${BannerSizeTypes.desktop}'] {
    @media (min-width: 1281px) {
      display: block;
    }
  }

  img[alt='${BannerSizeTypes.tablet}'] {
    @media (max-width: 1280px) and (min-width: 768px) {
      display: block;
    }
  }

  img[alt='${BannerSizeTypes.mobile}'] {
    @media (max-width: 767px) and (min-width: 480px) {
      display: block;
    }
  }

  img[alt='${BannerSizeTypes.smallMobile}'] {
    @media (max-width: 479px) {
      display: block;
    }
  }
`;

export const HeaderBannerLinkStyled = styled.a`
  display: block;
`;
