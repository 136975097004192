import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPortfolioComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      height={height}
      width={width}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.499985C8.067 0.499985 6.5 2.06699 6.5 3.99998C6.5 4.55227 6.05229 5 5.5 5H3.76556C2.25259 5 0.976386 6.12661 0.788726 7.62789L0.222867 12.1548C0.0376889 13.6362 0.97 15.0283 2.41035 15.4212L10.6844 17.6777C11.5457 17.9126 12.4543 17.9126 13.3156 17.6777L21.5896 15.4212C23.03 15.0283 23.9623 13.6362 23.7771 12.1548L23.2113 7.62789C23.0236 6.12661 21.7474 5 20.2344 5H18.5C17.9477 5 17.5 4.55227 17.5 3.99998C17.5 2.06699 15.933 0.499985 14 0.499985H10ZM14.5 5C15.0523 5 15.5 4.55227 15.5 3.99998C15.5 3.17156 14.8284 2.49998 14 2.49998H10C9.17157 2.49998 8.5 3.17156 8.5 3.99998C8.5 4.55227 8.94772 5 9.5 5H14.5ZM2.77328 7.87596C2.83583 7.37553 3.26124 7 3.76556 7H20.2344C20.7388 7 21.1642 7.37553 21.2267 7.87596L21.7926 12.4028C21.8543 12.8966 21.5435 13.3607 21.0634 13.4916L12.7893 15.7482C12.2725 15.8891 11.7274 15.8891 11.2106 15.7482L2.93658 13.4916C2.45647 13.3607 2.1457 12.8966 2.20742 12.4028L2.77328 7.87596Z"
        fill={fillColor}
      />
      <path
        d="M2 14.5V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V14.5"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
