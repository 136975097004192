import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, transition } from '../../../styles';

export const RegisterWarning = styled.div`
  position: absolute;
  right: 40px;
  top: calc(100% + 12px);
  display: flex;
  justify-content: space-between;
  background: #f7d653;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  max-width: 375px;
  cursor: pointer;

  ${transition('background-color')}
  &:hover {
    background: #ffca1f;
  }

  svg {
    flex: 0 0 auto;
  }

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        align-items: center;
        max-width: unset;
        left: 0;
        right: 0;
        top: 64px;
      `,
      theme.IS_MOBILE
    )}

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'smallDown',
      css`
        align-items: unset;
      `,
      theme.IS_MOBILE
    )}
`;

export const RegisterWarningText = styled.div`
  margin: 0 16px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
`;

export const RegisterWarningIcon = styled.div`
  background: ${({ theme }) => theme.COLOR.WHITE};
  padding: 8px;
  align-self: center;
  flex: 0 0 auto;
  display: flex;
  border-radius: 50%;
  box-shadow: 0 5px 25px -5px #0c0b0d29;
`;
