import { RequestHeader } from '../../services';
import { MenuApiSlugs } from '../menu';
import { NavigationsClient } from './navigations.client';
import { NavigationsResponseModel } from './navigations.model';

export async function navigationsClientProvider(
  type: MenuApiSlugs,
  requestHeaders?: RequestHeader
): Promise<NavigationsResponseModel> {
  const response = new NavigationsClient(type, requestHeaders).get();

  return response as unknown as Promise<NavigationsResponseModel>;
}
