import styled from 'styled-components';
import { SwitcherOptionStyledProps } from './switcherOption.model';
import { fontSettings } from '../../../../../../styles';

export const SwitcherOptionStyled = styled.label<SwitcherOptionStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
  min-width: 56px;
  flex: 1;

  cursor: pointer;

  background: ${({ isChecked, theme }) =>
    isChecked ? theme.COLOR.WHITE : 'transparent'};

  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const OptionValueStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
`;
