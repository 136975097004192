import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconWalletComponent = ({
  width = 32,
  height = 32,
  strokeColor = '#FBF7FF',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.492 5.645v-1.5l-8.46 2.227h10.116a2.45 2.45 0 0 1 2.451 2.45V19.15a2.45 2.45 0 0 1-2.45 2.451H4.85a2.45 2.45 0 0 1-2.45-2.45V8.222a2.45 2.45 0 0 1 1.827-2.37l12.91-3.398a1.656 1.656 0 0 1 2.08 1.602v1.588h-1.725ZM4.124 8.823v10.326c0 .402.325.726.726.726h14.298a.726.726 0 0 0 .727-.726V8.823a.726.726 0 0 0-.727-.727H4.85a.726.726 0 0 0-.726.727Z"
      fill={strokeColor}
    />
    <path
      d="M17.163 12.794a1.191 1.191 0 1 0 0 2.383 1.191 1.191 0 0 0 0-2.383Z"
      fill={strokeColor}
    />
  </svg>
);
