import { VendorsClient } from './vendors.client';
import { VendorModel } from './vendors.model';

export async function vendorsClientProvider(
  host?: string,
  privateHost?: string
): Promise<VendorModel[]> {
  const response = await new VendorsClient(host, privateHost).get();

  return response as unknown as Promise<VendorModel[]>;
}
