import styled from 'styled-components';

export const TableSearchButtonStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: max-content;
`;

export const TableSearchIconStyled = styled.div`
  margin-left: 8px;

  svg {
    margin-bottom: -3px;
  }
`;
