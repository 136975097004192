import { fontSettings, transition } from '@lerna-core';
import styled, { css } from 'styled-components';
import { ListEmptyStyledProps } from './listEmpty.model';

export const ListEmptyStyled = styled.div<ListEmptyStyledProps>`
  width: 100%;
  margin: ${({ theme }): string =>
    theme.IS_MOBILE ? '60px 0 80px' : '140px 0 80px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * {
    ${transition('opacity')}
    ${({ isRouting }): ReturnType<typeof css> | string =>
      isRouting
        ? css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
          `
        : ''}
  }
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin-bottom: 16px;
`;

export const SubtitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 32px;
  font-weight: 400;
`;
