import styled from 'styled-components';

import { fontSettings } from '../../styles';
import { ToastStyledProps } from './toast.model';

export const ToastStyled = styled.div<ToastStyledProps>`
  background: rgba(32, 25, 38, 0.8);
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  border-radius: 16px;
  padding: 15px 22px;
  box-sizing: border-box;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.WHITE};
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_HINTS};
  display: flex;
  justify-content: space-between;

  position: fixed;
  top: ${({ top }): string => `${top}px`};
  right: ${({ visible, right, width }): string =>
    visible ? `${right}px` : `-${width + 40}px`};
  width: ${({ width }): string => `${width}px`};
  max-width: max-content;
  transition: right 0.7s;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    flex: 0 0 20px;
  }
`;

export const ToastMessageStyled = styled.span`
  margin-left: 8px;
  white-space: pre-line;
`;

export const ToastIconButton = styled.div`
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 5px;
`;
