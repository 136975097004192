import {
  ExpiredDateValuesModel,
  SubscriptionApiModel,
  SubscriptionModel,
  SubscriptionsApiModel,
  SubscriptionsApiResponseModel,
  SubscriptionsModel,
  SubscriptionsRequestApiParams,
  SubscriptionsRequestParams,
} from './subscriptions.model';
import { mapMetaPaginationAdapter } from '../../adapters';
import { DateFormatEnum, dateService } from '../../services';
import { Nullable } from '../../models';

export const subscriptionExpiredAtAdapter = (
  expiredAt: Nullable<Date> | string
): ExpiredDateValuesModel => ({
  [DateFormatEnum.DDMMYYYY]: dateService.formatDate(
    expiredAt,
    DateFormatEnum.DDMMYYYY
  ),
  [DateFormatEnum.YYYYMMDD]: dateService.formatDate(
    expiredAt,
    DateFormatEnum.YYYYMMDD,
    '-'
  ),
});

const employeeReplacementsLimitAdapter = (
  customerRemovalLimit: Nullable<number>,
  availableRemovalCount: Nullable<number>
): Nullable<number> =>
  customerRemovalLimit === 0 ||
  customerRemovalLimit === null ||
  customerRemovalLimit
    ? customerRemovalLimit
    : availableRemovalCount;

export const subscriptionAdapter = (
  subscriptionApi: SubscriptionApiModel
): SubscriptionModel => ({
  id: subscriptionApi.id,
  title: subscriptionApi.title,
  expiredAt: subscriptionExpiredAtAdapter(subscriptionApi.expired_at),
  coursesCount: subscriptionApi.courses_count,
  customersCount: subscriptionApi.customers_count,
  customersCountLimit: subscriptionApi.customers_count_limit,
  withHomeworks: subscriptionApi.with_homeworks,
  claimPeriod: subscriptionApi.claim_period,
  coursesPerPeriod: subscriptionApi.courses_per_period,
  isUnlimitedCustomers: subscriptionApi.is_unlimited_customers,
  isAddingJoiningCustomers: subscriptionApi.is_adding_joining_customers,
  //TODO вынести в разные адапреты т.к. отличается hr от cms
  employeeReplacementsLimit: employeeReplacementsLimitAdapter(
    subscriptionApi.customer_removal_limit,
    subscriptionApi.available_removal_count
  ),
});

export const subscriptionsAdapter = ({
  data,
  meta,
}: SubscriptionsApiModel): SubscriptionsModel => ({
  data: data.map(subscriptionAdapter),
  meta: mapMetaPaginationAdapter(meta.pagination),
});

export const subscriptionsApiResponseAdapter = ({
  data,
}: SubscriptionsApiResponseModel): SubscriptionsModel =>
  subscriptionsAdapter(data);

export const subscriptionsRequestsParametersAdapter = ({
  limit,
  search,
  page,
}: SubscriptionsRequestParams): SubscriptionsRequestApiParams => {
  const params: SubscriptionsRequestApiParams = {};

  if (search) {
    params.title = search;
  }

  if (limit) {
    params.limit = limit;
  }

  if (page) {
    params.page = page;
  }

  return params;
};
