import { ApplicationActions } from './application.actions';
import * as constants from './application.constants';

export type ApplicationState = {
  isLoaded: boolean;
};

const initialState: ApplicationState = {
  isLoaded: false,
};

export const applicationReducer = (
  state: ApplicationState = initialState,
  action: ApplicationActions
): ApplicationState => {
  switch (action.type) {
    case constants.APPLICATION_LOADED:
      return { ...state, isLoaded: true };

    default:
      return state;
  }
};
