import React, { ReactElement } from 'react';

import { useTranslations } from '../../../translations';
import { getUserRoleTitle } from '../../user.service';
import { EditUserSmallButtonComponent } from '../../userEdit';
import { InfoPropContainer } from '../infoProp';
import {
  UserInfoParamsContainerStyled,
  UserInfoParamsTitleStyled,
} from '../userInfo.styled';
import { InfoCompanyContainerPropsType } from './infoCompany.model';

export const InfoCompanyContainer = ({
  career,
  allowEdit,
  handleClick,
  showRole,
  invitedAt,
  allowSeeCompanyLink,
}: InfoCompanyContainerPropsType): ReactElement => {
  const translations = useTranslations();

  const userRole = getUserRoleTitle(
    career.roles,
    translations,
    career.companyTitle,
    invitedAt
  );

  return (
    <>
      <UserInfoParamsTitleStyled margin="20px 0 14px">
        {translations.customer_profile_career}
        {allowEdit && (
          <EditUserSmallButtonComponent handleClick={handleClick} />
        )}
      </UserInfoParamsTitleStyled>
      <UserInfoParamsContainerStyled>
        <InfoPropContainer
          title={translations.customer_profile_workplace_title}
          value={career.companyTitle}
          companyUuid={career.companyId}
          allowSeeCompanyLink={allowSeeCompanyLink}
        />
        <InfoPropContainer
          title={translations.customer_profile_position_title}
          value={career?.position?.title || null}
        />
        {career.department && (
          <InfoPropContainer
            title={translations.customer_profile_department_label}
            value={career.department.title}
          />
        )}
        {showRole && userRole && (
          <InfoPropContainer title={translations.role} value={userRole} />
        )}
      </UserInfoParamsContainerStyled>
    </>
  );
};
