import React, { ReactElement, useState } from 'react';
import {
  GenericInputContainer,
  IconMagnifierComponent,
  InputSizes,
  useTheme,
} from '@lerna-core';
import {
  FilterOptionsSearchMagnifierStyled,
  FilterOptionsSearchStyled,
} from './filterOptionsSearch.styled';
import { FilterOptionsSearchContainerTypes } from './filterOptionsSearch.model';

export const FilterOptionsSearchContainer = ({
  onChange,
  placeholder,
  term,
  searchColors,
}: FilterOptionsSearchContainerTypes): ReactElement => {
  const theme = useTheme();
  const [color, setColor] = useState(theme.COLOR.TEXT_LIGHT_COLOR);

  const handleFocus = (): void => {
    if (!!searchColors) {
      setColor(searchColors.active);
    }
  };

  const handleBlur = (): void => {
    if (!!searchColors) {
      setColor(term ? searchColors.active : searchColors.default);
    }
  };

  return (
    <FilterOptionsSearchStyled color={color} isMobile={theme.IS_MOBILE}>
      <FilterOptionsSearchMagnifierStyled>
        <IconMagnifierComponent
          fillColor={color}
          strokeColor={color}
          width={20}
          height={20}
        />
      </FilterOptionsSearchMagnifierStyled>
      <GenericInputContainer
        name={'filterOptions'}
        type={'text'}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        size={InputSizes.small}
        initialValue={term}
        clearControl={true}
        autoComplete={'off'}
      />
    </FilterOptionsSearchStyled>
  );
};
