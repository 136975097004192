import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const PopupListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 32px;
`;

export const PopupListItemStyled = styled.li`
  padding-left: 26px;
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 13px;
    transform: translate(-50%, -50%);
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
