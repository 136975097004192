import styled, { css } from 'styled-components';

import { breakpoint, fontSettings } from '../../../styles';

export const NotificationManagement = styled.div`
  position: fixed;
  bottom: calc(45px + env(safe-area-inset-bottom));
  left: 40px;
  right: 40px;
  background-color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  border-radius: 16px;
  box-shadow: 0 5px 25px -5px #0c0b0d29;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_LAYER};
  max-width: 725px;

  ${breakpoint(
    'mediumDown',
    css`
      bottom: calc(20px + env(safe-area-inset-bottom));
      left: 20px;
      max-width: 335px;
      flex-wrap: wrap;
    `
  )}

  ${breakpoint(
    'smallDown',
    css`
      left: 20px;
      right: 20px;
      max-width: unset;
    `
  )}
`;

export const NotificationManagementText = styled.div`
  padding-right: 16px;
  color: ${(props): string => props.theme.COLOR.WHITE};
  white-space: pre-wrap;

  ${(props): string => fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)}
  ${breakpoint(
    'mediumDown',
    css`
      padding-right: 0;
      white-space: normal;
      flex: 1 1 100%;
      margin-bottom: 16px;
    `
  )}
    a {
    color: inherit;
  }
`;

export const NotificationManagementButton = styled.div`
  button {
    width: 120px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      flex: 0 0 calc(50% - 4px);

      button {
        width: 100%;
      }
    `
  )}
`;

export const NotificationManagementLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  display: inline;
`;
