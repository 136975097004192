import {
  AdditionalCoursesStateEnum,
  AdditionalCoursesStateModel,
} from './courseSimilar.model';

/**
 * The pagination state of similar courses. Which pages are loaded and which are not yet
 * @params (number) totalPages
 * @params (number) initialPage
 * @return (object)
 * */
export const getAdditionalCoursesState = (
  totalPages = 1,
  initialPage = 1
): AdditionalCoursesStateModel => {
  const state: AdditionalCoursesStateModel = {};

  for (let i = 1; i <= totalPages; i++) {
    state[i] =
      i === initialPage
        ? AdditionalCoursesStateEnum.success
        : AdditionalCoursesStateEnum.init;
  }

  return state;
};
