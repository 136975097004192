import React, { ReactElement, useMemo } from 'react';
import { CoursePriceContainerProps } from './itemPrice.model';
import { PriceTitleContainer } from './priceTitle';
import { PriceValueContainer } from './priceValue';
import { ItemPriceContainerStyled } from './itemPrice.styled';
import {
  hasCustomerDiscount,
  hasB2CDiscount,
  hasProgramDiscount,
  hasLegalEntitiesDiscount,
} from '@features/common/course';
import { useShowLegalEntitiesPriceHook } from '@hooks';

export const ItemPriceContainer = ({
  price,
  translations,
  isFreeCourse,
  isFreeCourseByDiscount,
}: CoursePriceContainerProps): ReactElement => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const isB2B = useMemo(() => !!price.priceCustomer, []);
  const hasDiscount = useMemo(() => {
    const B2BDiscount = showLegalEntitiesPrice
      ? hasLegalEntitiesDiscount(price)
      : hasCustomerDiscount(price);

    return (
      (isB2B ? B2BDiscount : hasB2CDiscount(price)) || hasProgramDiscount(price)
    );
  }, [showLegalEntitiesPrice]);

  return (
    <ItemPriceContainerStyled>
      <PriceTitleContainer
        price={price}
        hasDiscount={hasDiscount}
        translations={translations}
        isFreeCourse={isFreeCourse}
        isFreeCourseByDiscount={isFreeCourseByDiscount}
      />
      <PriceValueContainer price={price} isB2B={isB2B} />
    </ItemPriceContainerStyled>
  );
};
