import styled, { css } from 'styled-components';

import { breakpoint, positionSticky } from '../../../../styles';
import { PopupFooterContainerStyledProps } from './popupFooter.model';
import { getStylesByMode } from '../popup.service';

export const PopupFooterContainerStyled = styled.div<PopupFooterContainerStyledProps>`
  box-sizing: border-box;
  bottom: 0;
  padding: ${({ styleProps }): string =>
    `${styleProps.paddingSticky.top} ${styleProps.paddingSticky.right}`};
  border-top: ${({ border }): string =>
    border ? border : '1px solid rgba(0, 0, 0, 0.08)'};
  ${({ theme, darkMode }): string => getStylesByMode(theme, darkMode)}
  z-index: ${(props): string => props.theme.ZINDEX.BOTTOM};
  ${positionSticky()};
  z-index: ${(props): string => props.theme.ZINDEX.TOP_BLOCK};
  border-radius: 0 0 24px 24px;

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 16px;
      `,
      isMobile
    )}
`;
