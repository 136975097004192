import { useSelector } from 'react-redux';
import {
  getUserMenuSelector,
  getUserMenuStatusSelector,
} from './userMenu.selectors';
import { UserMenuHookModel } from './userMenu.model';

export const useUserMenu = (): UserMenuHookModel => {
  const menu = useSelector(getUserMenuSelector);
  const status = useSelector(getUserMenuStatusSelector);

  return {
    menu,
    status,
  };
};
