import { PopupStyleProps } from '@lerna-core';

export const modalPaddings: PopupStyleProps = {
  paddingSticky: {
    top: '32px',
    right: '40px',
    bottom: '32px',
    left: '40px',
  },
  paddingContent: {
    top: '32px',
    right: '40px',
    bottom: '32px',
    left: '40px',
  },
};

export const EMAIL = '{{EMAIL}}';

export const telegramHref = `https://refer.id/?bot=LernaAssistBot&platform=telegram&verbose_name=Lerna&n=73950&c=3087&email=${EMAIL}&utm_campaign=scoring-bot123`;
