import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  SyntheticEvent,
  useState,
} from 'react';
import { ChipContainerProps } from './chip.model';
import {
  ChipCloseStyled,
  ChipInputStyled,
  ChipStyled,
  ChipTextStyled,
} from './chip.styled';
import { useTheme, useToggle } from '../../../../hooks';
import { KEYCODES } from '../chipsInput.model';
import { IconCloseComponent } from '../../../icons';

export const ChipContainer = ({
  value,
  isValid = true,
  handleDelete,
  handleUpdate,
  maxLength,
}: ChipContainerProps): ReactElement => {
  const theme = useTheme();
  const [isEditing, toggleEditing] = useToggle();
  const [inputValue, setInputValue] = useState<string>(value);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);
  };

  const editChip = (e: SyntheticEvent): void => {
    e.stopPropagation();

    const trimmedValue = inputValue.trim();

    if (!trimmedValue.length) {
      handleDelete(value);
    } else {
      handleUpdate(trimmedValue, value, toggleEditing);
    }
  };

  const onClickDelete = (e: SyntheticEvent): void => {
    e.stopPropagation();
    handleDelete(value);
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if ([KEYCODES.SPACE, KEYCODES.ENTER, KEYCODES.TAB].includes(e.keyCode)) {
      e.preventDefault();

      editChip(e);
    }
  };
  const handleBlur = (e: SyntheticEvent): void => {
    editChip(e);
  };

  if (isEditing) {
    return (
      <ChipInputStyled
        value={inputValue}
        onChange={handleChangeInput}
        onKeyDown={handleKeyDown}
        size={inputValue.length || value.length}
        onBlur={handleBlur}
        maxLength={maxLength}
        autoFocus
      />
    );
  }

  return (
    <ChipStyled isValid={isValid} onClick={toggleEditing}>
      <ChipTextStyled>{value}</ChipTextStyled>
      <ChipCloseStyled onClick={onClickDelete} isValid={isValid}>
        <IconCloseComponent
          width={8}
          height={8}
          strokeWidth={'4'}
          strokeColor={theme.COLOR.WHITE}
        />
      </ChipCloseStyled>
    </ChipStyled>
  );
};
