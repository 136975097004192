import React, { memo, ReactElement } from 'react';

import { AvatarDefaultProps } from './avatarDefault.model';
import { getUserInitials } from './avatarDefault.service';
import { AvatarDefaultStyled } from './avatarDefault.styled';

function avatarDefaultContainer({
  size,
  firstName = '',
  lastName = '',
  isRound,
}: AvatarDefaultProps): ReactElement {
  return (
    <AvatarDefaultStyled size={size} isRound={isRound}>
      {getUserInitials(firstName, lastName)}
    </AvatarDefaultStyled>
  );
}

export const AvatarDefaultContainer = memo(avatarDefaultContainer);
