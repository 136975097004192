import styled, { css } from 'styled-components';

import { breakpoint } from '../../../styles';
import { ITheme } from '../../themes';
import { SliderControlTypes } from './sliderControl.model';
import { SliderControlStyle } from '../slider.styled';

type SliderControlContainerStyledProps = {
  disabled: boolean;
  type: SliderControlTypes;
  isMobile: boolean;
  isChevron: boolean;
  controlsInside: boolean;
  controlsStyle: SliderControlStyle;
};

const slideControlStyled = (
  theme: ITheme,
  disabled: boolean,
  isMobile: boolean,
  controlsInside: boolean,
  controlsStyle: SliderControlStyle
): ReturnType<typeof css> => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: ${disabled && controlsInside ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  background: ${theme.COLOR.WHITE};
  cursor: pointer;
  opacity: ${disabled ? 0.4 : 1};
  padding: 15px;
  pointer-events: ${disabled ? 'none' : 'auto'};
  z-index: 2;

  ${controlsStyle === SliderControlStyle.square
    ? `
        box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.04);
        border-radius: 16px;
      `
    : ''}

  ${controlsStyle === SliderControlStyle.circle
    ? `
        box-shadow: 0px 5px 25px -5px rgba(12, 11, 13, 0.16);
        border-radius: 50%;
      `
    : ''}

  ${breakpoint(
    'mediumDown',
    css`
      display: none;
    `,
    isMobile
  )}
`;

export const SliderControlPrevStyled = styled.div<SliderControlContainerStyledProps>`
  ${(props): ReturnType<typeof css> =>
    slideControlStyled(
      props.theme,
      props.disabled,
      props.isMobile,
      props.controlsInside,
      props.controlsStyle
    )}
  left: ${({ controlsInside }): string => (controlsInside ? '16px' : '0')};

  > svg {
    ${({ isChevron }): string =>
      isChevron ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);'};
  }
`;

export const SliderControlNextStyled = styled.div<SliderControlContainerStyledProps>`
  ${(props): ReturnType<typeof css> =>
    slideControlStyled(
      props.theme,
      props.disabled,
      props.isMobile,
      props.controlsInside,
      props.controlsStyle
    )}
  right: ${({ controlsInside }): string => (controlsInside ? '16px' : '0')};

  svg {
    ${({ isChevron }): string =>
      isChevron ? 'transform: rotate(-180deg);' : ''};
  }
`;
