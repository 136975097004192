import { ElementPropertiesModel, PlacementEnum } from '../onboarding.model';

const getAvailablePlacement = (
  popupProperties: ElementPropertiesModel,
  targetProperties: ElementPropertiesModel,
  gap: number
): keyof typeof PlacementEnum => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  const availableHeight =
    windowHeight - targetProperties.top - targetProperties.height;
  const availableWidth =
    windowWidth - targetProperties.left - targetProperties.width;

  const popupWidthWithGap = popupProperties.width + gap;
  const popupHeightWithGap = popupProperties.height + gap;

  if (availableHeight >= popupHeightWithGap) {
    return PlacementEnum.bottom;
  } else if (
    availableHeight < popupHeightWithGap &&
    targetProperties.top > popupHeightWithGap
  ) {
    return PlacementEnum.top;
  } else if (availableWidth >= popupWidthWithGap) {
    return PlacementEnum.right;
  } else if (
    availableWidth < popupWidthWithGap &&
    targetProperties.left > popupWidthWithGap
  ) {
    return PlacementEnum.left;
  }

  return PlacementEnum.bottom;
};

export const getPlacement = (
  popupProperties: ElementPropertiesModel,
  targetProperties: ElementPropertiesModel,
  gap: number,
  placement?: keyof typeof PlacementEnum
): string => {
  const actualPlacement =
    placement || getAvailablePlacement(popupProperties, targetProperties, gap);

  let top, bottom, right, left;

  switch (actualPlacement) {
    case PlacementEnum.top:
      bottom = window.innerHeight - targetProperties.top + gap;
      left = targetProperties.left + targetProperties.width / 2;

      // если попап не влезет справа
      if (left + popupProperties.width / 2 > window.innerWidth) {
        left = window.innerWidth - popupProperties.width / 2 - gap;
      }

      // если попап не влезет слева
      if (left - popupProperties.width / 2 < 0) {
        left = popupProperties.width / 2 + gap;
      }

      return `
        bottom: ${bottom}px;
        left: ${left}px;
        transform: translateX(-50%);
        &:after{
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
      `;
    case PlacementEnum.right:
      left = targetProperties.left + targetProperties.width + gap;
      top = targetProperties.top + targetProperties.height / 2;
      return `
        left: ${left}px;
        top: ${top}px;
        transform: translateY(-50%);
        &:after{
          left: -8px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      `;
    case PlacementEnum.left:
      right = window.innerWidth - targetProperties.left + gap;
      top = targetProperties.top + targetProperties.height / 2;
      return `
        right: ${right}px;
        top: ${top}px;
        transform: translateY(-50%);
        &:after{
          right: -8px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      `;
    default:
    case PlacementEnum.bottom:
      left = targetProperties.left + targetProperties.width / 2;
      top = targetProperties.top + targetProperties.height + gap;

      // если попап не влезет справа
      if (left + popupProperties.width / 2 > window.innerWidth) {
        left = window.innerWidth - popupProperties.width / 2 - gap;
      }

      // если попап не влезет слева
      if (left - popupProperties.width / 2 < 0) {
        left = popupProperties.width / 2 + gap;
      }

      return `
        left: ${left}px;
        top: ${top}px;
        transform: translateX(-50%);
        &:after{
          top: -8px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
      `;
  }
};
