import React, { ReactElement } from 'react';
import {
  TransferCountStyled,
  TransferTextStyled,
  TransferTitleStyled,
} from './transferTitle.styled';
import { TransferTitleContainerProps } from './transferTitle.model';

export const TransferTitleContainer = ({
  title,
  amount,
  selected,
  translations,
  showUnlimitedCount = false,
}: TransferTitleContainerProps): ReactElement => (
  <TransferTitleStyled>
    <TransferTextStyled>{title}</TransferTextStyled>
    {showUnlimitedCount ? (
      <TransferCountStyled>{selected || 0}</TransferCountStyled>
    ) : (
      amount && (
        <TransferCountStyled>
          {selected || 0} {translations.from} {amount}
        </TransferCountStyled>
      )
    )}
  </TransferTitleStyled>
);
