import {
  CompensationRequestsModel,
  CompensationRequestsResponse,
  CompensationsRequestsApiParams,
  CompensationsRequestsParams,
} from './compensationRequests.model';
import { publicConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { mapCompensationRequestsResponse } from './compensationRequests.adapter';
import { CurrencyModel } from '../../models';

export class CompensationRequestsClient extends SecureRequestClient<
  CompensationsRequestsApiParams,
  CompensationRequestsResponse,
  CompensationRequestsModel
> {
  protected path = 'user-api/manager/v1/compensation-requests';
  protected requestParameters: CompensationsRequestsApiParams;

  constructor(
    requestParameters: CompensationsRequestsParams,
    currencies: CurrencyModel[]
  ) {
    super(publicConfig.API_GATEWAY_URL);
    this.adapter = mapCompensationRequestsResponse.bind(null, currencies);
    this.requestParameters = requestParameters;
  }
}
