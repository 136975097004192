import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const SimilarItemContainerStyled = styled.div`
  padding: 4px;
  box-sizing: border-box;
  height: 100%;

  ${breakpoint(
    'mediumDown',
    css`
      flex: 1 1 100%;
      max-width: 100%;
    `
  )}
`;
