import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCropComponent = ({
  width = 25,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#ffffff',
  fillColor = '#ffffff',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      clipPath="url(#path)"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6.63 1 6.5 16a2 2 0 0 0 2 2h15" />
      <path d="m1.5 6.13 15-.13a2 2 0 0 1 2 2v15" />
    </g>
    <defs>
      <clipPath id="path">
        <path fill={fillColor} transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
