import styled, { css } from 'styled-components';

import { customizeScrollBar, getInputColor } from '../../../../../styles';
import { setBaseInputStyles } from '../input.styled';
import { TextareaProps } from './textarea.component';

type TextareaStyledProps = Pick<
  TextareaProps,
  | 'autoHeight'
  | 'color'
  | 'disabled'
  | 'floatingPlaceholder'
  | 'hasError'
  | 'bordered'
>;

export const TextareaStyled = styled.textarea<TextareaStyledProps>`
  resize: vertical;
  font-family: inherit;
  font-size: inherit;
  ${({ floatingPlaceholder }): ReturnType<typeof css> =>
    setBaseInputStyles(floatingPlaceholder)}
  ${({ bordered, disabled, color, theme, hasError }): string =>
    getInputColor({ bordered, disabled, color, theme, hasError })}
  
  ${({ autoHeight }): ReturnType<typeof css> =>
    autoHeight
      ? css`
          overflow: hidden;
        `
      : css`
          overflow: auto;
          ${customizeScrollBar(15, 15)}
        `}

  ::-webkit-resizer {
    display: none;
  }
`;
