import styled from 'styled-components';
import {
  SliderContainerStyled,
  SliderControlNextStyled,
  SliderControlPrevStyled,
  SliderListStyled,
} from '@lerna-core';
import { rgba } from 'polished';
import { ROW_ITEM_BORDER_RADIUS } from './rowItem';

export const TabsRowStyled = styled.div`
  position: relative;

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? `
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `
      : 'margin-bottom: 32px;'}

  ${SliderContainerStyled} {
    border-radius: ${ROW_ITEM_BORDER_RADIUS}px;
    ${({ theme }): string =>
      theme.IS_MOBILE
        ? `
      margin: 0 -20px;
      border-radius: unset;
    `
        : ''}
  }
  ${SliderListStyled} {
    ${({ theme }): string =>
      theme.IS_MOBILE
        ? `
      padding: 0 20px;
    `
        : ''}
  }
  ${SliderControlNextStyled}, ${SliderControlPrevStyled} {
    padding: 12px;
    background: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.8)};

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: ${({ theme }): string => theme.COLOR.WHITE};
      }
    }
  }
  ${SliderControlNextStyled} {
    right: -12px;
  }
  ${SliderControlPrevStyled} {
    left: -12px;
  }
`;
