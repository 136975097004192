import { ReactChild } from 'react';
import styled, { css } from 'styled-components';
import { getInputColor, InputColor } from '../../../styles';
import { setBaseInputStyles } from '../../form';

export enum InputSizes {
  small = 'small',
  normal = 'normal',
}

type GenericInputStyledProps = {
  size: string;
  clearControl?: boolean;
};

const sizeProps = {
  small: css`
    padding: 11px 15px;
    border-radius: 8px;
  `,
  normal: css`
    padding: 15px 19px;
    border-radius: 16px;
    max-height: 54px;
  `,
};

const clearControlProps = {
  small: css`
    padding-right: 37px;
  `,
  normal: css`
    padding-right: 45px;
  `,
};

const getSizeProps = (size: string): ReturnType<typeof css> => {
  switch (size) {
    case InputSizes.small: {
      return sizeProps.small;
    }
    default: {
      return sizeProps.normal;
    }
  }
};

const getClearControlProps = (
  clearControl = true,
  size: string
): ReturnType<typeof css> => {
  if (clearControl) {
    switch (size) {
      case InputSizes.small: {
        return clearControlProps.small;
      }
      default: {
        return clearControlProps.normal;
      }
    }
  }

  return css``;
};

export const GenericInputWrapStyled = styled.div<GenericInputStyledProps>`
  position: relative;
  width: 100%;

  input,
  textArea {
    ${({ size }): ReturnType<typeof css> => getSizeProps(size)}
    ${({ clearControl, size }): ReturnType<typeof css> =>
      getClearControlProps(clearControl, size)}
  }
`;

type GenericInputProps = {
  bordered?: boolean;
  disabled?: boolean;
  color?: InputColor;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
};

export const GenericInputStyled = styled.input<GenericInputProps>`
  ${({ floatingPlaceholder }): ReturnType<typeof css> =>
    setBaseInputStyles(floatingPlaceholder)}
  ${({ bordered, disabled, color, theme, hasError }): string =>
    getInputColor({ bordered, disabled, color, theme, hasError })}
`;

type GenericInputClearStyledType = {
  visibleClearControl?: boolean;
  children?: ReactChild;
};

export const GenericInputClearStyled = styled.div<GenericInputClearStyledType>`
  display: ${({ visibleClearControl }): string =>
    visibleClearControl ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  border: none;
  background: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  padding: 0;
  opacity: 0.64;
  transition: opacity 0.1s ease-in-out;

  :hover {
    opacity: 1;
  }
`;
