import { publicConfig, RequestHeader, serverConfig } from '../../../services';
import { SecureRequestClient } from '../../../services/request/secureRequest.service';
import { OnboardingArea } from '../onboarding.model';
import { onboardingAllAdapter } from './onboardingAll.adapter';
import {
  OnboardingAllResponseApiModel,
  OnboardingAllResponseModel,
} from './onboardingAll.model';

const AREA = '{{AREA}}';

export class OnboardingAllClient extends SecureRequestClient<
  null,
  OnboardingAllResponseApiModel,
  OnboardingAllResponseModel
> {
  protected path = `user-api/v1/suggests/all/${AREA}`;
  protected requestParameters: null;
  protected adapter = onboardingAllAdapter;

  constructor(area: OnboardingArea, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
    this.path = this.path.replace(AREA, area);
  }
}
