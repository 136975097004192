import { css } from 'styled-components';

export const breakpointType = {
  smallDown: 'only screen and (min-width: 0) and (max-width: 479px)',
  // mobile-horizontal
  small: 'only screen and (min-width: 480px) and (max-width: 767px)',
  smallUp: 'only screen and (min-width: 480px)',

  mediumDown: 'only screen and (min-width: 0) and (max-width: 767px)',
  // tablet-vertical
  medium: 'only screen and (min-width: 768px) and (max-width: 959px)',
  mediumUp: 'only screen and (min-width: 768px)',

  largeDown: 'only screen and (min-width: 0) and (max-width: 959px)',
  // tablet-horizontal
  large: 'only screen and (min-width: 960px) and (max-width: 1279px)',
  largeUp: 'only screen and (min-width: 960px)',

  xlargeDown: 'only screen and (min-width: 0) and (max-width: 1279px)',

  // desktop
  xlarge: 'only screen and (min-width: 1280px)',

  //  xlarge with banner (25vw)
  xxlarge: 'only screen and (min-width: 1680px)',
  xxlargeDown: 'only screen and (min-width: 0) and (max-width: 1679px)',

  mobile: 'only screen and (max-width: 767px)',
  tablet: 'only screen and (min-width: 768px) and (max-width: 1279px)',
  desktop: 'only screen and (min-width: 1280px)',
};

type Breakpoint = (
  type: keyof typeof breakpointType,
  style: ReturnType<typeof css>,
  isDefault?: boolean
) => ReturnType<typeof css> | '';

export const breakpoint: Breakpoint = (type, style, isDefault = false) => {
  if (isDefault) {
    return css`
      ${style}
    `;
  }

  if (breakpointType[type] === undefined) return '';

  return css`
    @media ${breakpointType[type]} {
      ${style}
    }
  `;
};
