import { breakpoint } from '@lerna-core';
import styled, { css } from 'styled-components';

type CoursePropsContainerStyledProps = {
  count: number;
};
export const CoursePropsContainerStyled = styled.div<CoursePropsContainerStyledProps>`
  display: flex;
  justify-content: ${({ count }): string =>
    count > 3 ? 'space-between' : 'normal'};
  margin-top: 24px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 16px;
    `
  )}

  ${breakpoint(
    'smallDown',
    css`
      flex-direction: column;
    `
  )}
`;
