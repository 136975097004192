import React, { ReactElement, useEffect } from 'react';
import { CenteredPopupContainerProps } from './centeredPopup.model';
import { PopupPortalContainer } from '../popupPortal';
import { PopupCloseContainer } from './popupClose';
import { browserService, Nullable } from '@lerna-core';
import {
  CenteredPopupStyled,
  ContentStyled,
  OverlayStyled,
} from './centeredPopup.styled';

export const CenteredPopupContainer = ({
  isOpen,
  handleClose,
  children,
  styles,
  withScrollbarHide = true,
}: CenteredPopupContainerProps): Nullable<ReactElement> => {
  useEffect(() => {
    if (withScrollbarHide) {
      if (isOpen) {
        browserService.removePageScroll();
      } else {
        browserService.addPageScroll();
      }
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <PopupPortalContainer>
      <CenteredPopupStyled>
        <OverlayStyled onClick={handleClose} />
        <PopupCloseContainer
          handleClose={handleClose}
          contentWidth={styles?.width}
        />
        <ContentStyled styles={styles}>{children}</ContentStyled>
      </CenteredPopupStyled>
    </PopupPortalContainer>
  );
};
