import styled from 'styled-components';
import {
  FilterIconStyled,
  FilterOptionsItemsStyled,
  FilterOptionsShowAllContainerStyled,
  FilterOptionStyled,
  FilterTitleContainerStyled,
  FilterToggleContainerStyled,
} from '@features/common/filters';
import { CheckboxContainerStyled, fontSettings } from '@lerna-core';
import { rgba } from 'polished';
import {
  DesktopFilterStyledProps,
  DesktopToggleFilterStyledProps,
} from './desktopFilter.model';

export const FiltersPopupDesktopFilterStyled = styled.div<DesktopFilterStyledProps>`
  ${({ isCustomFilter, background }): string =>
    isCustomFilter
      ? `
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 32px;
    background: ${background ? `${background}` : 'transparent'};
  `
      : ''}
  &:not(:first-child) {
    margin-top: 32px;
    padding-top: 32px;
    margin-bottom: 0;
    border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  }

  input {
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid ${({ theme }): string => theme.COLOR.BLACK_12};

    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    &:hover {
      border-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.24)};
    }

    &:focus,
    &:focus-within {
      border-color: ${({ theme }): string => theme.COLOR.PRIMARY[500]};
    }
  }

  ${FilterOptionsItemsStyled} {
    display: flex;
    flex-wrap: wrap;
    max-height: unset;
    border: none;
    padding: 0;
  }

  ${FilterOptionStyled} {
    min-width: 50%;
    display: flex;

    &:nth-child(odd) {
      padding-right: 16px;
    }

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  ${FilterOptionStyled} + ${FilterOptionStyled} {
    flex: 0 0 50%;
  }

  ${CheckboxContainerStyled} {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    line-height: 24px;
  }

  ${({ isCustomFilter, theme }): string => `
  ${FilterTitleContainerStyled} {
    ${
      isCustomFilter
        ? `font-weight: 500; ${fontSettings(theme.FONT.SIZE_20, theme.FONT)}`
        : `font-weight: 600;${fontSettings(theme.FONT.SIZE_24, theme.FONT)}`
    }
    margin-bottom: 24px;
  }
`}
  ${FilterOptionsShowAllContainerStyled} {
    margin-top: 24px;
    font-weight: 400;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  }

  ${FilterToggleContainerStyled} {
    margin-bottom: 32px;
  }
`;

export const FiltersPopupDesktopToggleFilterStyled = styled.div<DesktopToggleFilterStyledProps>`
  ${({ isCustomFilter, theme }): string => `
  ${FilterToggleContainerStyled} {
    ${fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    ${
      !isCustomFilter
        ? 'margin-top: 32px; padding-top: 32px;'
        : `font-weight: 500; padding: 16px; ${fontSettings(
            theme.FONT.SIZE_20,
            theme.FONT
          )}`
    }
    
    ${FilterIconStyled} {
      margin-right: 12px;
    }
    }
    
    ${FilterTitleContainerStyled} {
    ${
      isCustomFilter
        ? `font-weight: 500; ${fontSettings(theme.FONT.SIZE_20, theme.FONT)};`
        : ''
    }
  }
`}
`;
