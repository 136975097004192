import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconMirComponent = ({
  width = 37,
  height = 20,
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M10.02 5v.004c-.005 0-1.38-.004-1.747 1.31-.434 1.542-.87 3.084-1.31 4.625H6.7S5.73 7.537 5.39 6.31C5.024 4.996 3.644 5 3.644 5H.5v10h3.144V9.061h.262L5.74 15h2.184l1.834-5.934h.262V15h3.144V5H10.02Zm11.484 0s-.921.083-1.353 1.048l-2.227 4.89h-.262V5h-3.144v10h2.969s.965-.087 1.397-1.048l2.184-4.89h.262V15h3.144V5h-2.97Zm4.367 4.543v5.459h3.144v-3.188h3.407a3.402 3.402 0 0 0 3.21-2.271h-9.761Z"
        fill="#4DB45E"
      />
      <path
        d="M32.422 5H25.43a5.023 5.023 0 0 0 4.94 4.105h5.388c.048-.227.07-.459.07-.699A3.406 3.406 0 0 0 32.42 5h.002Z"
        fill="url(#b)"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1="25.431"
        y1="7.052"
        x2="35.828"
        y2="7.052"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stopColor="#00B4E6" />
        <stop offset="1" stopColor="#088CCB" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h36v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
