import * as constants from './filters.constants';
import {
  FilterModel,
  FilterOptionModel,
  FilterRangeModel,
  FiltersModel,
} from './filters.model';
import {
  getFilterSelectedTitle,
  getFilterRangeSelectedTitle,
  getFiltersSelectedCount,
} from './filters.selectors';
import { TranslationModel } from '../../models';
import { FilterActions } from './filters.actions';
import { FilterTypeEnum } from './filters.enum';
import { ParsedUrlQuery } from 'querystring';

export type FiltersState = {
  filters: FiltersModel[];
  selectedCount: number;
  isOpened: boolean;
};

const defaultState: FiltersState = {
  filters: [],
  selectedCount: 0,
  isOpened: false,
};

const updateFilterOptionValue = (
  state: FiltersState,
  filterSlug: string,
  optionSlug: string,
  value: boolean
): FiltersState => {
  const filters = ([] as FiltersModel[]).concat(state.filters);
  filters.forEach((filter) => {
    if (filter.slug === filterSlug) {
      const options = filter.options as FilterOptionModel[];
      options.forEach((option) => {
        if (option.slug === optionSlug) {
          option.checked = value;
        }
      });
      filter.options = options;
      filter.showTitle = getFilterSelectedTitle(filter.title, options);
    }
  });
  const selectedCount = getFiltersSelectedCount(filters);

  return { ...state, filters, selectedCount };
};

const updateFilterRangeValue = (
  state: FiltersState,
  filterSlug: string,
  fromValue: string,
  toValue: string,
  transitions?: TranslationModel
): FiltersState => {
  const { filters } = state;
  filters.forEach((filter) => {
    if (filter.slug === filterSlug) {
      const currentFilter = filter as FilterRangeModel;
      filter.options = {
        from: fromValue,
        to: toValue,
      };

      filter.showTitle = getFilterRangeSelectedTitle(
        currentFilter.title,
        currentFilter.options,
        currentFilter.units,
        transitions
      );
    }
  });
  const selectedCount = getFiltersSelectedCount(filters);

  return { ...state, filters, selectedCount };
};

const updateSelectAllFilterOptionValue = (
  state: FiltersState,
  filterSlug: string,
  value: boolean
): FiltersState => {
  const { filters } = state;
  filters.forEach((filter) => {
    if (filter.slug === filterSlug) {
      const currentFilter = { ...filter } as FilterModel;
      currentFilter.options = currentFilter.options.map((option) => ({
        ...option,
        checked: value,
      }));
      currentFilter.showTitle = getFilterSelectedTitle(
        currentFilter.title,
        currentFilter.options
      );
      filter = currentFilter;
    }
  });
  const selectedCount = getFiltersSelectedCount(filters);

  return { ...state, filters, selectedCount };
};

const clearFilter = (
  state: FiltersState,
  filterSlug: string,
  filterType: string,
  transitions?: TranslationModel
): FiltersState => {
  switch (filterType) {
    case FilterTypeEnum.range: {
      return updateFilterRangeValue(state, filterSlug, '', '', transitions);
    }

    default: {
      return updateSelectAllFilterOptionValue(state, filterSlug, false);
    }
  }
};

const checkActiveSingleOptionFilter = (
  filter: FilterModel,
  query: ParsedUrlQuery
): boolean => {
  return query[filter.slug] === filter.options[0].slug;
};

const setFilterOptionsState = (
  filter: FiltersModel,
  query: ParsedUrlQuery
): FiltersModel => {
  if (filter.type === FilterTypeEnum.checkbox) {
    const checkboxFilter = filter as FilterModel;

    if (checkboxFilter.singleOption) {
      return {
        ...checkboxFilter,
        options: [
          {
            ...checkboxFilter.options[0],
            checked: checkActiveSingleOptionFilter(checkboxFilter, query),
          },
        ],
      };
    }
  }

  return filter;
};

export function filterReducer(
  state: FiltersState = defaultState,
  action: FilterActions
): FiltersState {
  switch (action.type) {
    case constants.SET_FILTERS_OPTIONS_STATE:
      const filters = action.filters.map((filter) =>
        setFilterOptionsState(filter, action.query)
      );

      return {
        ...state,
        filters,
        selectedCount: getFiltersSelectedCount(filters),
      };
    case constants.TRIGGER_FILTER_OPTION:
      return updateFilterOptionValue(
        { ...state },
        action.filterSlug,
        action.optionSlug,
        action.newValue
      );
    case constants.TRIGGER_FILTER_RANGE:
      return updateFilterRangeValue(
        { ...state },
        action.filterSlug,
        action.fromValue,
        action.toValue,
        action.translations
      );
    case constants.TRIGGER_ALL_FILTER_OPTION:
      return updateSelectAllFilterOptionValue(
        { ...state },
        action.filterSlug,
        action.newValue
      );
    case constants.TRIGGER_CLEAR_FILTERS: {
      return {
        ...state,
        selectedCount: 0,
      };
    }
    case constants.TRIGGER_CLEAR_FILTER: {
      return clearFilter(
        { ...state },
        action.filterSlug,
        action.filterType,
        action.translations
      );
    }
    case constants.TRIGGER_OPEN_FILTERS: {
      return {
        ...state,
        isOpened: action.isOpened,
      };
    }
    default:
      return state;
  }
}
