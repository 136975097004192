import { ReactElement } from 'react';
import { Nullable } from '../../../models';

export enum MessagePopupStatus {
  success = 'success',
  error = 'error',
}

export type MessagePopupComponentProps = {
  title?: string;
  subtitle?: Nullable<string | ReactElement>;
  buttonTitle?: Nullable<string>;
  buttonCallback?: () => void;
  status?: string;
  statusWidth?: number;
  statusHeight?: number;
};
