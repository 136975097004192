import React, { ReactElement } from 'react';
import { TextControlComponent } from '@lerna-core';
import { PromoCodeInputModel } from './promoCodeInput.model';

export const PromoCodeInputContainer = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  setFieldValue,
  translations,
  disabled,
}: PromoCodeInputModel): ReactElement => (
  <TextControlComponent
    type="text"
    name="promoCode"
    value={values.promoCode}
    onChange={onChange}
    onBlur={onBlur}
    placeholder={translations?.promo_code}
    floatingPlaceholder={false}
    disabled={disabled}
    touched={touched.promoCode}
    errorMessage={errors.promoCode}
    showResetButton
    setFieldValue={setFieldValue}
    margin="0 0 20px 0"
  />
);
