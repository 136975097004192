import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconClockWithArrowComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.875 24.375H31.125V29.625"
        stroke={strokeColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2002 24.6135C27.8505 28.9388 23.268 31.875 18 31.875C10.3372 31.875 4.125 25.6628 4.125 18C4.125 10.3372 10.3372 4.125 18 4.125C25.6628 4.125 31.875 10.3372 31.875 18V18.375"
        stroke={strokeColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.625 10.125V19.125H23.625"
        stroke={strokeColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
