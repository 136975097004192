import { createSelector } from 'reselect';
import { RootState } from '../../models';
import { CustomerState } from './customer.model';

export const getCustomer = createSelector(
  (state: RootState<CustomerState>) => state.customer,
  (reducer: CustomerState) => reducer.customer
);

export const getCustomerStatus = createSelector(
  (state: RootState<CustomerState>) => state.customer,
  (reducer: CustomerState) => reducer.status
);
