import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconLikeComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 22H5a2 2 0 0 1-2-2v-8.5a2 2 0 0 1 2-2h3.5V20a2 2 0 0 1-2 2Zm13.831-11.216A2.534 2.534 0 0 0 18.474 10H14.75s.545-2.188.732-2.922c.284-1.111.34-2.045.179-2.941-.085-.466-.234-1.068-.646-1.602a1.96 1.96 0 0 0-1.088-.733c-.64-.16-1.024.045-1.255.686-.019.052-.822 2.883-.822 2.883-.063.224-.124.402-.216.545-.595.926-1.313 1.827-2.135 2.745v12.078c2.23.637 5.583 1.22 5.812 1.242a3.981 3.981 0 0 0 4.242-2.99c.419-1.619 1.282-5.491 1.282-5.491.07-.255.148-.545.165-.861.038-.7-.2-1.358-.669-1.854Z"
        fill={fillColor}
      />
    </svg>
  );
};
