import { ToastActions, ToastConstants, ToastType } from '../toast';

export const errorAction = (): ToastActions => ({
  type: ToastConstants.SET_TOAST,
  payload: {
    message: 'oops... something went wrong, try again later!',
    type: ToastType.error,
    visible: true,
  },
});
