import React, { ReactElement } from 'react';
import { TooltipIconStyled } from './tooltipArrow.styled';
import { TooltipArrowIconProps } from './tooltipArrow.model';

export const TooltipArrowComponent = (
  props: TooltipArrowIconProps
): ReactElement => (
  <TooltipIconStyled {...props}>
    <svg
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8H0C4 8 7 -4.37114e-08 9 0C11 4.37114e-08 14 8 18 8Z"
        fill="white"
      />
    </svg>
  </TooltipIconStyled>
);
