import {
  SelectionColumnOptions,
  SelectionColumnProps,
} from './selectionColumn.model';

export const getSelectionColumnOptions = ({
  selectionColWidth,
  selectionColMinWidth,
  disableGroupBy = true,
  disableResizing = true,
}: SelectionColumnProps): SelectionColumnOptions => ({
  width: selectionColWidth,
  minWidth: selectionColMinWidth,
  disableGroupBy: disableGroupBy,
  disableResizing: disableResizing,
});
