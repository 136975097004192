import React, { ReactElement } from 'react';
import { SeoTextShowMoreContainerProps } from './seoTextShowMore.model';
import { SeoTextShowMoreContainerStyled } from './seoTextShowMore.styled';

export const SeoTextShowMoreContainer = ({
  label,
  handleClick,
}: SeoTextShowMoreContainerProps): ReactElement => {
  return (
    <SeoTextShowMoreContainerStyled>
      <span onClick={handleClick}>{label}</span>
    </SeoTextShowMoreContainerStyled>
  );
};
