import { fontSettings, lineClamping } from '@lerna-core';
import styled from 'styled-components';

export const LabelDesktopContainerStyled = styled.div`
  ${(props): string => fontSettings(props.theme.FONT.SIZE_13, props.theme.FONT)}
  font-weight: 400;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-top: 8px;
  ${lineClamping(2)}
`;
