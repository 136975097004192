import React, { ReactElement } from 'react';

import { InputColor } from '../../../../styles';
import {
  GenericFormControlStyled,
  InputErrorStyled,
  InputLabelStyled,
  PhoneInputContainer,
} from '../../../index';

type GenericFormControlContainerProps = {
  errors: string[];
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  initialValue: string;
  label?: string | ReactElement;
  bordered?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  color?: InputColor;
  name: string;
  height?: number;
  borderRadius?: number;
  floatingPlaceholder: boolean;
};

const getErrorsComponents = (errors: string[]): ReactElement[] => {
  return errors.map((error) => (
    <InputErrorStyled key={error}>{error}</InputErrorStyled>
  ));
};

export const PhoneFormControlContainer = ({
  initialValue,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  disabled,
  bordered = true,
  color,
  isRequired,
  errors = [],
  height,
  borderRadius,
  floatingPlaceholder,
}: GenericFormControlContainerProps): ReactElement => (
  <GenericFormControlStyled>
    {label && !floatingPlaceholder && (
      <InputLabelStyled>{label}</InputLabelStyled>
    )}
    <PhoneInputContainer
      name={name}
      value={initialValue}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      required={isRequired}
      disabled={disabled}
      bordered={bordered}
      color={color}
      height={height}
      borderRadius={borderRadius}
      hasError={Boolean(errors.length)}
    />
    {getErrorsComponents(errors)}
  </GenericFormControlStyled>
);
