import { FormikProps, useFormik } from 'formik';

import { useSelector } from 'react-redux';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormInitialValues,
  UseUserPhotoReturnType,
} from './formUserEdit.model';
import {
  getFormInitialValues,
  getFormValidationSchema,
} from './formUserEdit.service';
import { getUser } from '../../user.selectors';
import { useTranslations } from '../../../translations';
import {
  addUserPhotoClientProvider,
  deleteUserPhotoClientProvider,
  getUserPhotoClientProvider,
} from './formUserPhoto';
import { ImageResourceModel, Nullable } from '../../../../models';

export const useUserEditForm = (
  onSubmit: (formData: FormInitialValues) => void
): FormikProps<FormInitialValues> => {
  const user = useSelector(getUser);
  const translations = useTranslations();

  const initialValues = useMemo(
    () => getFormInitialValues(user?.personal),
    [user?.personal]
  );

  const validationSchema = useMemo(
    () => getFormValidationSchema(translations),
    []
  );

  return useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });
};

const USER_CUSTOMER_ENTITY = 'users_customer_avatar';

export const useUserPhoto = (): UseUserPhotoReturnType => {
  const user = useSelector(getUser);

  const [photoError, setPhotoError] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<
    Nullable<ImageResourceModel>
  >(user?.personal.avatar || null);
  const [imageToDelete, setImageToDelete] =
    useState<Nullable<ImageResourceModel>>(null);

  const fetchUploadedPhoto = async (filename: string): Promise<void> => {
    await getUserPhotoClientProvider({
      entity: USER_CUSTOMER_ENTITY,
      filename,
    }).then((response) => {
      setCurrentImage({
        ...response.image,
        source_server: response.source.server,
        entity: USER_CUSTOMER_ENTITY,
      });
    });
  };

  const deleteUploadedPhoto = async (): Promise<void> => {
    if (!imageToDelete) return;
    const { filename, path, server, source_server } = imageToDelete;

    await deleteUserPhotoClientProvider({
      image: {
        entity: USER_CUSTOMER_ENTITY,
        filename,
        path,
        server,
      },
      source: {
        entity: USER_CUSTOMER_ENTITY,
        filename,
        path,
        server: source_server,
      },
    }).then(() => {
      setImageToDelete(null);
    });
  };

  const uploadPhoto = useCallback(
    async (files: FileList): Promise<void> => {
      await addUserPhotoClientProvider(files)
        .then((response) => {
          if (currentImage) {
            setImageToDelete(currentImage);
          }
          fetchUploadedPhoto(response.filename);
        })
        .catch(() => {
          setPhotoError(true);
        });
    },
    [currentImage]
  );

  const handleUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { files } = e.target as HTMLInputElement;
      if (files?.length) {
        uploadPhoto(files);
      }
    },
    [currentImage]
  );

  const closePhotoError = useCallback((): void => {
    setPhotoError(false);
  }, []);

  const handleDelete = useCallback((): void => {
    setImageToDelete(currentImage);
    setCurrentImage(null);
  }, [currentImage]);

  useEffect(() => {
    setCurrentImage(user?.personal.avatar || null);
  }, [user?.personal.avatar]);

  return {
    handleDelete,
    handleUpload,
    deleteUploadedPhoto,
    photoError,
    closePhotoError,
    currentImage,
  };
};
