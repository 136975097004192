import styled from 'styled-components';

export const DiscountTotalContainerStyled = styled.div``;

export const DiscountTotalWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscountTotalIconStyled = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;

  svg {
    ${({ theme }): string => (theme.IS_MOBILE ? 'height: 16px;' : '')}
    position: static;
    top: unset;
    left: unset;
    transform: unset;
  }
`;
