import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconTabWithCrossComponent = ({
  width = 48,
  height = 48,
  strokeWidth = '2',
  strokeColor = '#000',
  fillColor = '#000',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M3 12h40"
      />
      <path
        fill={fillColor}
        d="M6 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      />
      <path
        fill={fillColor}
        d="M20.913 39H5c-.552 0-1-.449-1-1V8c0-.551.448-1 1-1h36c.552 0 1 .449 1 1v14.564c.704.335 1.368.725 2 1.152V8c0-1.654-1.346-3-3-3H5C3.346 5 2 6.346 2 8v30c0 1.654 1.346 3 3 3h16.931a12.848 12.848 0 0 1-1.018-2Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M26 36c0-5.502 4.498-10 10-10s10 4.498 10 10-4.498 10-10 10-10-4.498-10-10Zm10-8.182c-4.498 0-8.182 3.684-8.182 8.182 0 1.923.674 3.698 1.797 5.1L41.1 29.614a8.134 8.134 0 0 0-5.1-1.797Zm6.385 3.082L30.901 42.386A8.134 8.134 0 0 0 36 44.182c4.498 0 8.182-3.684 8.182-8.182a8.134 8.134 0 0 0-1.797-5.1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
