import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const DiscountTotalContainerStyled = styled.div`
  border-radius: 100px;
  padding: 4px 12px;
  background: linear-gradient(90deg, #f60065 0%, #ff4793 100%);
  color: ${({ theme }): string => theme.COLOR.WHITE};

  ${({ theme }): string =>
    theme.IS_MOBILE
      ? fontSettings(theme.FONT.SIZE_12, theme.FONT)
      : fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  b {
    font-weight: 500;
  }
`;
