import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const RightHeadStyled = styled.div`
  margin-bottom: 24px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;

export const TitleStyled = styled.div`
  font-weight: 600;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
`;
