import React, { ReactElement } from 'react';

import { TransferControlsProps } from '../transfer.model';
import { IconDirection, TransferControlContainer } from '../transferControl';
import { TransferControlsStyled } from './transferControls.styled';

export const TransferControlsContainer = ({
  disabledLeft,
  disabledRight,
  handleCheckedLeft,
  handleCheckedRight,
}: TransferControlsProps): ReactElement => (
  <TransferControlsStyled>
    <TransferControlContainer
      handleClick={handleCheckedRight}
      disabled={disabledLeft}
      iconDirection={IconDirection.right}
    />
    <TransferControlContainer
      handleClick={handleCheckedLeft}
      disabled={disabledRight}
      iconDirection={IconDirection.left}
    />
  </TransferControlsStyled>
);
