import {
  ApiInstallmentModel,
  CurrencyModel,
  InstallmentModel,
  TranslationModel,
} from '../models';
import { setPriceValue } from './price.adapter';

const getMonthlyPaymentUnits = (
  price: string,
  translations: TranslationModel
): string => `${price}/${translations.installment_duration_label}`;

export const installmentAdapter = (
  installment: ApiInstallmentModel,
  currencies: CurrencyModel[],
  translations: TranslationModel
): InstallmentModel => ({
  amount: installment.price.amount,
  duration: `${installment.duration} ${translations.installment_duration_label}`,
  price: getMonthlyPaymentUnits(
    setPriceValue(installment.price, currencies),
    translations
  ),
  priceWithTotalDiscount: getMonthlyPaymentUnits(
    setPriceValue(installment.price_with_total_discount, currencies),
    translations
  ),
});
