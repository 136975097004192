import React, { memo, ReactElement } from 'react';
import { FooterSocialContainerProps } from './footerSocial.model';
import { FooterSocialContainerStyled } from './footerSocial.styled';
import { SocialItemContainer } from './socialItem';
import { Nullable } from '../../../models';

function footerSocialContainer({
  socialLinks,
}: FooterSocialContainerProps): Nullable<ReactElement> {
  if (!socialLinks?.length) return null;

  return (
    <FooterSocialContainerStyled>
      {socialLinks.map((social) => (
        <SocialItemContainer social={social} key={social.slug} />
      ))}
    </FooterSocialContainerStyled>
  );
}

export const FooterSocialContainer = memo(footerSocialContainer);
