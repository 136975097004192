import React, { ReactElement } from 'react';
import { PopupVendorsStyled } from './popupVendors.styled';
import {
  GeekbrainsComponent,
  SkillboxComponent,
  SkillfactoryComponent,
} from './vendorsIcons';
import { PopupVendorsContainerProps } from './popupVendors.model';
import { publicConfig, RegionsEnum } from '@lerna-core';

const regionsWithoutSkillfactory = [
  RegionsEnum.am,
  RegionsEnum.kg,
  RegionsEnum.md,
];

export const PopupVendorsContainer = ({
  isMobile,
}: PopupVendorsContainerProps): ReactElement => {
  const showSkillfactoryLogo = !regionsWithoutSkillfactory.includes(
    publicConfig?.REGION as RegionsEnum
  );

  return (
    <PopupVendorsStyled>
      <SkillboxComponent
        width={isMobile ? 60 : 76}
        height={isMobile ? 16 : 20}
      />
      <GeekbrainsComponent
        width={isMobile ? 100 : 126}
        height={isMobile ? 16 : 20}
      />
      {showSkillfactoryLogo && (
        <SkillfactoryComponent
          width={isMobile ? 68 : 86}
          height={isMobile ? 16 : 20}
        />
      )}
    </PopupVendorsStyled>
  );
};
