import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCubeComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#000000',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12 1.5 9 5v11l-9 5-9-5v-11l9-5Z"
        stroke={strokeColor}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12 11.5 9-5m-9 5-9-5m9 5v11"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
