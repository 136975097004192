import { LogoModel } from '../../logo';
import { VendorModel } from '../../vendors';

export enum OrderApiStatuses {
  new = 'new',
  awaiting = 'awaiting',
  paid = 'paid',
  available = 'available',
  archived = 'archived',
  cancelled = 'cancelled',
}

export type OrderStatus = {
  id: number;
  title: string;
  slug: OrderApiStatuses;
};

export type CustomerOrder = {
  uuid: string;
  createdAt: string;
  status: OrderStatus;
  email: string;
  phone: string;
  username: string;
  course: CustomerOrderCourse;
  startDate: string;
  customerUuid: string;
  companyId?: string;
  price: string;
};

export type CustomerOrderCourse = {
  uuid: string;
  title: string;
  slug: string;
  link: string;
  duration: string | null;
  vendor: VendorModel | null;
  logo: LogoModel;
  props: CustomerOrderCourseProps[];
} | null;

export type CustomerOrderCourseProps = {
  slugTitle: string;
  value: string;
  lineThrough: boolean;
};

export type CustomerOrderByStatus = {
  new: CustomerOrder[];
  declined: CustomerOrder[];
  accepted: CustomerOrder[];
};

export type CompanyOrdersTab = {
  title: string;
  href: string;
  count: number;
};

// TODO: нужно было сделать enum не на id, а на слагах
export enum OrderStatusEnum {
  newId = 1,
  avaitingId = 2,
  paidId = 3,
  availableId = 4,
  archivedId = 5,
  canselledId = 6,
}
