import { AppContext } from 'next/app';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { publicConfig } from '../config/config.model';
import {
  ExpandedReqModel,
  LinkTargetEnum,
  QueryParams,
  TargetBlankOptionsProps,
} from '../../models';
import { objectService } from '../objectService';
import { cookieService } from '../cookie';
import { USER_LOCALE_COOKIE_NAME } from '../../features';
import { AppService } from '../appService/app.service';

class UrlService {
  private static instance: UrlService;
  private static readonly pathnameWithoutLocale = ['/'];
  public static readonly noReferrer = 'noreferrer';
  public static readonly noOpener = 'noopener';
  public readonly requiredParameters: string[] = [];

  public static getInstance(): UrlService {
    if (!UrlService.instance) {
      UrlService.instance = new UrlService();
    }

    return UrlService.instance;
  }

  /**
   * Generate full url from request and config
   * @params {NextRouter} router - NextRouter
   * @params {string} locale - ru, en etc.
   * @return {string} full url
   * */
  public getFullUrlFromReq(router: NextRouter, locale?: string | null): string {
    return this.normalizeUrl(
      `${publicConfig?.BASE_PROTOCOL}//${this.setWWW()}${
        publicConfig?.BASE_DOMAIN
      }${locale && router.defaultLocale !== locale ? `/${locale}` : ''}${
        router.asPath
      }`
    );
  }

  /**
   * Generate full url from request and config
   * @params {string} path - path of url.
   * @return {string} full url
   * */
  public getFullUrlFromToPage(
    path: string,
    withWWW?: boolean,
    trailingSlash?: boolean
  ): string {
    return this.normalizeUrl(
      `${publicConfig?.BASE_PROTOCOL}//${this.setWWW(withWWW)}${
        publicConfig?.BASE_DOMAIN
      }${path}`,
      trailingSlash
    );
  }

  /**
   * Get full url from request and config with query params
   * @params {string} path - url path
   * @return {string} - url to file
   * */
  public getFullUrlToPageWithQuery(
    path: string,
    subDomain?: string,
    withWWW?: boolean
  ): string {
    if (!!subDomain) {
      return this.getFullUrlToSubDomain(subDomain, path);
    }

    return `${publicConfig?.BASE_PROTOCOL}//${this.setWWW(withWWW)}${
      publicConfig.BASE_DOMAIN
    }${path}`;
  }

  /**
   * Get locale from url
   * @params {string} url
   * @params {object} router - NextRouter
   * @return {string | null} - locale or null *
   * */
  public getLocaleFromUrl(
    req: ExpandedReqModel,
    router: NextRouter
  ): string | null {
    let locale = null;
    const pathSplit = (req?.originalUrl || '').split('/');
    if ((router.locales || []).some((locale) => locale === pathSplit[1])) {
      locale = pathSplit[1];
    }

    return locale;
  }

  /**
   * Check for a redirect to local
   * @params {object} req
   * @params {string} locale - ru, en etc.
   * @return {boolean} - true if you need to redirect. false if you don't need to redirect
   * */
  public checkNeedRedirectToLocale(appContext: AppContext): boolean {
    const { router } = appContext;
    const { locale, locales } = router;

    const userLocale = cookieService.getCookie(
      USER_LOCALE_COOKIE_NAME,
      appContext?.ctx?.req?.headers?.cookie || ''
    );
    const localeFromUrl = this.getLocaleFromUrl(
      appContext?.ctx?.req as ExpandedReqModel,
      router
    );

    if (localeFromUrl && localeFromUrl === router.defaultLocale) {
      return true;
    }

    return (
      !UrlService.pathnameWithoutLocale.includes(router.pathname) &&
      !!userLocale &&
      locale !== userLocale &&
      (locales || []).includes(userLocale)
    );
  }

  /**
   * Set required params to url
   * @params {url} url
   * @params {object} query.
   * @return {array} requiredParameters
   * */
  public setRequiredParametersFromQuery(
    url: URL,
    query: ParsedUrlQuery,
    requiredParameters: string[]
  ): URL {
    requiredParameters.forEach((param) => {
      if (!!query[param]) {
        const paramValue = query[param] as string;
        if (paramValue) {
          url.searchParams.append(param, paramValue);
        }
      }
    });

    return url;
  }

  public setQueryParamsToLink(link: string, query: ParsedUrlQuery): string {
    const url = new URL(link);

    Object.keys(query).forEach((param) => {
      if (query[param]) {
        url.searchParams.append(param, query[param] as string);
      }
    });

    return url.href;
  }

  /**
   * Get home page url with parameters
   * @params {string} path - url path
   * @params {object} router - NextRouter
   * @params {array} requiredParameters.
   * @return {string} - url to home page
   * */
  public getFullUrlToPage(
    path: string,
    { query, locale, defaultLocale }: NextRouter,
    requiredParameters?: string[],
    baseDomain: string = publicConfig.BASE_DOMAIN,
    withWWW?: boolean
  ): string {
    const urlWithParams = new URL(
      `${publicConfig?.BASE_PROTOCOL}//${this.setWWW(
        withWWW
      )}${baseDomain}${path}`
    );

    if (requiredParameters) {
      this.setRequiredParametersFromQuery(
        urlWithParams,
        query,
        requiredParameters
      );
    }

    const localePath = locale && defaultLocale !== locale ? `/${locale}` : '';

    return this.normalizeUrl(
      `${urlWithParams.origin}${localePath}${urlWithParams.pathname}${urlWithParams.search}`
    );
  }

  /**
   * Get file url
   * @params {string} path - url path
   * @return {string} - url to file
   * */
  public getUrlToFile(
    path: string,
    withWWW?: boolean,
    domain?: string
  ): string {
    return `${publicConfig?.BASE_PROTOCOL}//${this.setWWW(withWWW)}${
      domain ? domain : publicConfig.BASE_DOMAIN
    }${path}`;
  }

  /**
   * Inserting a www if necessary
   * @return {string} www. or ''
   * */
  public setWWW(withWWW?: boolean): string {
    return !!withWWW || publicConfig?.BASE_WWW === 'true' ? 'www.' : '';
  }

  /**
   * Generate full url to admin
   * @params {string} sub domain
   * @params {string} path
   * @return {string} full url
   * */
  public getFullUrlToSubDomain(
    subDomain: string,
    path?: string | null,
    baseDomain: string = publicConfig?.BASE_DOMAIN
  ): string {
    return this.normalizeUrl(
      `${publicConfig?.BASE_PROTOCOL}//${subDomain}.${baseDomain}${
        path ? path : ''
      }`
    );
  }

  /**
   * Provides props for links opens in new tab
   * @return {TargetBlankOptionsProps} - links props for new tab
   */
  public setTargetBlankOptions = (): TargetBlankOptionsProps => ({
    rel: `${UrlService.noOpener} ${UrlService.noReferrer}`,
    target: LinkTargetEnum.blank,
  });

  /**
   * Adding a slash to the end of path
   * @params {string} ts - should use trailing slash
   * @return {string}
   * */
  public normalizeSlash = (str: string, ts = false): string => {
    const trailingSlash = ts || publicConfig?.TRAILING_SLASH === 'true';
    const lastSymbol = str[str.length - 1];

    if (trailingSlash) {
      return lastSymbol === '/' ? str : `${str}/`;
    }

    return lastSymbol === '/' ? str.slice(0, str.length - 1) : str;
  };

  /**
   * Normalizing the slash in the search string from url
   * @return {string}
   */
  public normalizeSearch = (str: string, withoutSlash = true): string => {
    const needToClean = str[str.length - 1] === '/' && withoutSlash;
    const cleanSearch = needToClean ? str.substring(0, str.length - 1) : str;
    const cleanSearchObj = objectService.getObjectFromQueryString(cleanSearch);

    return objectService.getQueryStringFromObject(cleanSearchObj);
  };

  /**
   * Normalizing the slash in the url
   * @return {string}
   */
  public normalizeUrl = (url: string, trailingSlash = false): string => {
    const splitBySearch = url.split('?');
    const search = splitBySearch[1] || '';
    const path = splitBySearch[0];

    return `${this.normalizeSlash(path, trailingSlash)}${
      !!search ? `?${this.normalizeSearch(search)}` : ''
    }`;
  };

  /**
   * Create url from path string and query object
   * @return {string}
   * @param {string}
   * @param {object}
   */
  public createUrlFromPathAndQuery = (
    path: string,
    router: NextRouter,
    query?: ParsedUrlQuery
  ): string => {
    const newQuery = !!query ? query : {};

    this.requiredParameters.forEach((param) => {
      if (!!router.query[param]) {
        newQuery[param] = router.query[param];
      }
    });

    const search =
      !!newQuery && !objectService.isEmptyObject(newQuery)
        ? objectService.getQueryStringFromObject(newQuery)
        : '';

    return this.normalizeUrl(`${path}${!!search ? `?${search}` : ''}`);
  };

  public getSubdomain = (host?: string): string => {
    return AppService.isClientSide
      ? (window?.location?.host?.split('.')[0] as string)
      : (host?.split('.')[0] as string);
  };

  public getUrlHostnameFromLink = (link: string): string => {
    const url = new URL(link);

    return url.hostname;
  };

  public getDecodedQueryValue = (
    param: QueryParams,
    query: ParsedUrlQuery
  ): string => {
    const value = query[param];

    return value ? decodeURIComponent(value as string) : '';
  };

  public updatePathQuery = (path: string, query: ParsedUrlQuery): string => {
    const hasPathQuery = Boolean(path.split('?')[1]);
    const newSearch = objectService.getQueryStringFromObject(query);
    const searchMark = hasPathQuery ? '&' : '?';

    return this.normalizeUrl(`${path}${searchMark}${newSearch}`);
  };
}

export const urlService = UrlService.getInstance();
