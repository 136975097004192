import {
  ApiCoursesSubscriptionModel,
  CoursesSubscriptionModel,
} from '../models';

export const coursesSubscriptionAdapter = (
  subscription: ApiCoursesSubscriptionModel
): CoursesSubscriptionModel => ({
  id: subscription.id,
  createdAt: subscription.created_at,
  title: subscription.title,
});
