import { EducationStreamsModel } from './educationStreams.model';
import { EducationStreamsClient } from './educationStreams.client';
import { RequestHeader } from '../../services';

export async function educationStreamsClientProvider(
  requestHeaders?: RequestHeader
): Promise<EducationStreamsModel> {
  const response = await new EducationStreamsClient(requestHeaders).get();

  return response as unknown as Promise<EducationStreamsModel>;
}
