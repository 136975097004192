import { LogoResponseModel } from '../companyEditLogo.model';
import { logoGetAdapter } from './logoGet.adapter';
import { publicConfig } from '../../../../services';
import { RequestClient } from '../../../../services/request/request.service';

export type GetLogoParams = {
  entity: string;
  filename: string;
};

export type GetLogoApiModel = {
  data: {
    response: LogoResponseModel;
  };
};

export class LogoGetClient extends RequestClient<
  GetLogoParams,
  GetLogoApiModel,
  LogoResponseModel
> {
  protected path = 'ms-uploader-api/v1/image/store';
  protected requestParameters: GetLogoParams;
  protected adapter = logoGetAdapter;

  constructor(host = publicConfig.API_GATEWAY_URL, params: GetLogoParams) {
    super(host);
    this.requestParameters = params;
  }
}
