import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const LeftPrices = styled.div`
  position: relative;
  z-index: 10;
  padding: 32px 20px 16px;
  background-color: ${({ theme }): string => theme.COLOR.BLACK_4};
  border-radius: 12px;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 20px 20px 4px;
    `
  )}
`;

export const LeftPricesWrapper = styled.div`
  position: relative;
  margin-bottom: 48px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;
