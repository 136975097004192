import { RequestHeader, ShowcaseRequestProps } from '@lerna-core';
import {
  DiscountProgramClient,
  DiscountProgramCourseClient,
} from './discountProgram.client';
import { CoursePageModel, CourseRequestParams } from '@features/course';
import { CoursesPageWithErrorModel } from '@features/courses';

export async function discountProgramClientProvider(
  params: ShowcaseRequestProps,
  discountProgram: string,
  headers: RequestHeader
): Promise<CoursesPageWithErrorModel> {
  const response = await new DiscountProgramClient(
    params,
    discountProgram,
    headers
  ).get();

  return response as unknown as Promise<CoursesPageWithErrorModel>;
}

export async function discountProgramCourseClientProvider(
  params: CourseRequestParams,
  headers: RequestHeader
): Promise<CoursePageModel> {
  const response = await new DiscountProgramCourseClient(params, headers).get();

  return { ...response, params: params } as unknown as Promise<CoursePageModel>;
}
