import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { advantagesBreakpoint } from '../../../coursesAdvantages.styled';

export const ItemContentStyled = styled.div`
  display: flex;
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const TitleStyled = styled.div`
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${breakpoint(
    'xxlargeDown',
    css`
      font-weight: 600;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    `
  )}
  ${breakpoint(
    'xlargeDown',
    css`
      margin-bottom: 4px;
      font-weight: 500;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    `
  )}
`;

export const IconHighlightStyled = styled.div`
  flex: 0 0 20px;

  svg {
    max-width: 100%;
  }

  ${advantagesBreakpoint} {
    flex: 0 0 16px;
  }
`;

export const IconInfoStyled = styled.div`
  flex: 0 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const SubtitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  opacity: 0.7;

  ${advantagesBreakpoint} {
    max-width: unset;
  }
  ${breakpoint(
    'xlargeDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
    `
  )}
  ${breakpoint(
    'mediumDown',
    css`
      max-width: unset;
    `
  )}
`;
