import React, { ReactElement } from 'react';
import {
  TableEndlessLoaderStyled,
  TableEndlessLoaderWrapperStyled,
} from './tableEndlessLoader.styled';
import { SpinnerContainer } from '../../spinner';

export const TableEndlessLoaderContainer = (): ReactElement => {
  return (
    <TableEndlessLoaderStyled>
      <TableEndlessLoaderWrapperStyled>
        <SpinnerContainer width={32} height={32} strokeWidth="2" />
      </TableEndlessLoaderWrapperStyled>
    </TableEndlessLoaderStyled>
  );
};
