import React, { ReactElement } from 'react';
import { EducationStreamStyled } from './educationStream.styled';
import { EducationStreamComponentProps } from './educationStream.model';
import { IconCloseComponent } from '../../icons';

export const EducationStreamComponent = ({
  educationStream,
  isSelected,
  handleSelect,
  showClose = false,
}: EducationStreamComponentProps): ReactElement => {
  return (
    <EducationStreamStyled
      onClick={(): void => handleSelect(educationStream)}
      isSelected={isSelected}
    >
      {educationStream.title}
      {showClose && <IconCloseComponent width={16} height={16} />}
    </EducationStreamStyled>
  );
};
