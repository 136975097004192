import React, { ReactElement } from 'react';
import { AdvantagesItem, LeftAdvantagesStyled } from './leftAdvantages.styled';
import { getAdvantages } from './leftAdvantages.helper';
import { useCustomCompensationFormContext } from '../../../customCompensationForm.context';
import { Nullable } from '@lerna-core';
import { IconsCheckComponent } from '@features/common/forms/components/icons';

export const LeftAdvantagesContainer = (): Nullable<ReactElement> => {
  const { course, translations } = useCustomCompensationFormContext();

  const advantages = getAdvantages(course, translations);

  if (!advantages.length) return null;

  return (
    <LeftAdvantagesStyled>
      {advantages.map(
        (advantage) =>
          !!advantage && (
            <AdvantagesItem key={advantage}>
              <IconsCheckComponent width={24} height={24} />
              {advantage}
            </AdvantagesItem>
          )
      )}
    </LeftAdvantagesStyled>
  );
};
