import { RequestHeader } from '../../../services';
import { UserMenuClient } from './userMenu.client';
import { UserMenuModel } from './userMenu.model';

export async function userMenuClientProvider(
  requestHeaders?: RequestHeader
): Promise<UserMenuModel[]> {
  const response = await new UserMenuClient(requestHeaders).get();

  return response as unknown as Promise<UserMenuModel[]>;
}
