import { useCoursesPageContext } from '@features/courses/coursesPage';
import { CoursesSearchContainer as CoursesPageSearchContainer } from '@features/courses/coursesSearch';
import { browserService, DeviceInfoContext, Nullable } from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import { SearchContainerProps } from './search.model';
import { SearchStyled } from './search.styled';
import { SearchButtonContainer } from './searchFilterButton';

export const SearchContainer = ({
  showSearch,
}: SearchContainerProps): Nullable<ReactElement> => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const coursesContext = useCoursesPageContext();

  const showFilters =
    !coursesContext.user?.career?.isFiltersHiddenOnListing && isMobile;

  if (!showSearch && !showFilters) return null;

  return (
    <SearchStyled data-instance="search" isMobile={isMobile}>
      {showSearch && <CoursesPageSearchContainer />}
      {showFilters && (
        <SearchButtonContainer isSticky={false} isFullButton={!showSearch} />
      )}
    </SearchStyled>
  );
};
