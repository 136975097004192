export const PREFERRED_PHONE_COUNTRIES = [
  'by',
  'kz',
  'ru',
  'uz',
  'kg',
  'am',
  'md',
  'ge',
  'pl',
];

export const PHONE_MASKS = {
  by: '(..) ...-..-..',
  kz: '(...) ...-..-..',
  ru: '(...) ...-..-..',
  pl: '(..) ...-..-..',
  uz: '(..) ...-..-..',
  kg: '(...) ...-...',
  ar: '(.) .. ....-....',
  am: '(..) ...-...',
  md: '(..) ...-...',
  ge: '(...) ...-...',
};

export const INPUT_PHONE_DEFAULT_HEIGHT = 54;
export const INPUT_PHONE__DEFAULT_BORDER_RADIUS = 8;
export const FIRST_TWO_CHARS = 2;
export const PHONE_LOCAL_STORAGE_KEY = 'phone_country_code';
