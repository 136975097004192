import styled from 'styled-components';
import { fontSettings } from '../../../../../styles';

export const UserInfoStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const UserInfoAvatarStyled = styled.div`
  margin-right: 16px;
`;

export const UserInfoNameStyled = styled.div`
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
`;

export const UserInfoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
