import { AxiosError } from 'axios';
import { Dispatch } from 'react';

import { faqClientProvider } from './faq.clientProvider';
import * as constants from './faq.constants';
import { Faq } from './faq.model';
import { logger, RequestHeader } from '../../services';

export type FaqActions = {
  type: string;
  payload?: Faq[] | AxiosError;
};

export const requestFaq = async (
  dispatch: Dispatch<FaqActions>,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await faqClientProvider(requestHeaders)
    .then((response) => {
      dispatch(requestFaqSuccess(response));
    })
    .catch((error) => {
      logger.error(`[ERROR]: can not get faq. ${error}`);
      dispatch(requestFaqError(error));
    });
};

export const requestFaqSuccess = (payload: Faq[]): FaqActions => ({
  type: constants.REQUEST_FAQ_SUCCESS,
  payload,
});

export const requestFaqError = (payload: AxiosError): FaqActions => ({
  type: constants.REQUEST_FAQ_ERROR,
  payload,
});
