import styled from 'styled-components';

export const TopMenuLinksContainerStyled = styled.div`
  display: flex;
  margin-left: auto;

  > div:not(:first-child) {
    margin-left: 20px;
  }
`;
