import React, { ReactElement } from 'react';
import { MobileTypeContainerProps } from './mobileType.model';
import { MobileRangeTypeContainer } from '../mobileRangeType';
import { MobileCheckboxTypeContainer } from '../mobileCheckboxType';
import { FilterTypeEnum, Nullable } from '@lerna-core';

export const MobileTypeContainer = ({
  filter,
  withDescription,
  icon,
}: MobileTypeContainerProps): Nullable<ReactElement> => {
  switch (filter.type) {
    case FilterTypeEnum.range: {
      return <MobileRangeTypeContainer filter={filter} key={filter.slug} />;
    }
    case FilterTypeEnum.tab:
    case FilterTypeEnum.toggle:
    case FilterTypeEnum.checkbox: {
      return (
        <MobileCheckboxTypeContainer
          key={filter.slug}
          filter={filter}
          withDescription={withDescription}
          icon={icon}
        />
      );
    }

    default:
      return null;
  }
};
