import React, { MutableRefObject, ReactElement, useRef } from 'react';
import { TooltipDesktopContainerProps } from './tooltipDesktop.model';
import {
  TooltipDesktopContentStyled,
  TooltipDesktopStyled,
  TooltipDesktopTriggerStyled,
} from './tooltipDesktop.styled';
import { IconInfoCircledComponent, Nullable, useTheme } from '@lerna-core';
import { useTooltipDesktop } from './tooltipDesktop.hook';

export const TooltipDesktopContainer = ({
  content,
  defaultPosition,
}: TooltipDesktopContainerProps): ReactElement => {
  const theme = useTheme();
  const tooltipRef = useRef<Nullable<HTMLDivElement>>(null);
  const { isOpen, position, handleTooltipToggle } =
    useTooltipDesktop(tooltipRef);

  return (
    <TooltipDesktopStyled>
      <TooltipDesktopTriggerStyled
        onMouseEnter={handleTooltipToggle}
        onMouseLeave={handleTooltipToggle}
      >
        <IconInfoCircledComponent
          width={16}
          height={16}
          strokeColor={theme.COLOR.TEXT[900]}
          fillColor={theme.COLOR.TEXT[900]}
        />
      </TooltipDesktopTriggerStyled>
      <TooltipDesktopContentStyled
        ref={tooltipRef as MutableRefObject<HTMLDivElement>}
        dangerouslySetInnerHTML={{ __html: content }}
        isOpen={isOpen}
        position={defaultPosition || position}
      />
    </TooltipDesktopStyled>
  );
};
