import React, { ReactElement, useContext } from 'react';
import { browserService, DeviceInfoContext } from '@lerna-core';
import { TutorsItemContainerProps } from './tutorsItem.model';
import {
  TutorsItemContainerStyled,
  TutorsItemInfoStyled,
} from './tutorsItem.styled';
import { LabelMobileContainer, LabelDesktopContainer } from './itemLabel';
import { ItemTitleContainer } from './itemTitle';
import { ItemPhotoContainer } from './itemPhoto';

export const TutorsItemContainer = ({
  tutor,
}: TutorsItemContainerProps): ReactElement => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  return (
    <TutorsItemContainerStyled isMobile={isMobile}>
      <ItemPhotoContainer tutorName={tutor.name} photo={tutor.photo} />
      <TutorsItemInfoStyled isMobile={isMobile}>
        <ItemTitleContainer title={tutor.name} />
        {!!tutor.workInfo &&
          (isMobile ? (
            <LabelMobileContainer label={tutor.workInfo} />
          ) : (
            <LabelDesktopContainer label={tutor.workInfo} />
          ))}
      </TutorsItemInfoStyled>
    </TutorsItemContainerStyled>
  );
};
