import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconDigitalDesignComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.914 3.333a1.25 1.25 0 0 0-.866.367L25.07 7.677l-4.23 1.14a5.094 5.094 0 0 0-3.626 3.727l-2.133 8.883c-.194.81.06 1.554.479 2.185a1.25 1.25 0 0 0 .827.827c.63.42 1.376.674 2.186.48l8.883-2.133a5.096 5.096 0 0 0 3.727-3.626l1.14-4.23 3.977-3.978a1.25 1.25 0 0 0 0-1.767L30.816 3.7a1.249 1.249 0 0 0-.901-.367Zm.018 3.018 3.717 3.717-2.227 2.225-3.715-3.717 2.225-2.225ZM7.083 9.167c-1.145 0-2.163.474-2.809 1.2-.646.727-.94 1.647-.94 2.55 0 .902.294 1.822.94 2.549.413.464 1.008.763 1.662.963.519 9.513 8.09 17.174 17.594 17.705.153.478.391.934.744 1.331.646.727 1.663 1.202 2.81 1.202 1.145 0 2.163-.475 2.809-1.202.645-.726.94-1.646.94-2.548 0-.903-.295-1.823-.94-2.55-.646-.726-1.664-1.2-2.81-1.2-1.145 0-2.163.474-2.809 1.2-.333.375-.568.801-.723 1.25-8.17-.515-14.63-7.046-15.12-15.229.573-.208 1.09-.504 1.462-.923.645-.726.94-1.646.94-2.548 0-.903-.295-1.823-.94-2.55-.646-.726-1.664-1.2-2.81-1.2Zm18.61.93 4.208 4.21-1.131 4.204a2.584 2.584 0 0 1-1.897 1.844L19.69 22.08l3.79-3.792a2.074 2.074 0 0 0 1.769-.59 2.083 2.083 0 1 0-3.534-1.18l-3.796 3.796 1.727-7.187a2.584 2.584 0 0 1 1.844-1.897l4.204-1.132Zm-18.61 1.57c.521 0 .754.15.941.361.188.211.31.541.31.889 0 .347-.122.677-.31.888-.18.202-.425.333-.9.345h-.083c-.473-.012-.72-.143-.898-.345-.188-.21-.31-.541-.31-.888 0-.348.122-.678.31-.889.187-.211.42-.361.94-.361Zm20 20c.521 0 .754.15.941.361.188.211.31.541.31.889 0 .347-.122.677-.31.888-.188.211-.42.362-.94.362-.521 0-.754-.15-.941-.362-.165-.185-.274-.463-.3-.763.01-.084.01-.17.002-.254.026-.298.134-.576.298-.76.187-.211.42-.361.94-.361Z"
        fill={fillColor}
      />
    </svg>
  );
};
