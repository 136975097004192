import React, { ReactElement } from 'react';

import { InfoLabelContainer } from './infoLabel';
import { UserAvatarContainer, UserAvatarSize } from '../../../../user';
import { UserInfoContainerProps } from './userInfo.model';
import {
  UserInfoStyled,
  UserInfoAvatarStyled,
  UserInfoWrapperStyled,
  UserInfoNameStyled,
} from './userInfo.styled';
import { Nullable } from '../../../../../models';
import { getUserName } from './userInfo.helpers';

export const UserInfoContainer = ({
  user,
  translations,
}: UserInfoContainerProps): Nullable<ReactElement> => {
  if (!user) return null;

  const userName = getUserName(user.personal) || translations.user_profile;

  return (
    <UserInfoStyled>
      <UserInfoAvatarStyled>
        <UserAvatarContainer
          size={UserAvatarSize.big}
          personal={user.personal}
          isRound={true}
        />
      </UserInfoAvatarStyled>
      <UserInfoWrapperStyled>
        <UserInfoNameStyled>{userName}</UserInfoNameStyled>
        <InfoLabelContainer
          career={user.career}
          bonuses={user.bonuses}
          translations={translations}
        />
      </UserInfoWrapperStyled>
    </UserInfoStyled>
  );
};
