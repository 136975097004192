import {
  CourseItemApiModel,
  CourseItemApiVendorModel,
  CourseItemModel,
  CourseItemProgressApiModel,
  CourseItemProgressModel,
} from './courseItem.model';
import { mapImageResource } from '../../image';
import { VendorModel } from '../../vendors';

export const mapCourseItem = (course: CourseItemApiModel): CourseItemModel => ({
  uuid: course.id,
  access: {
    accessType: course.access.access_type,
    isHomeworkAvailable: course.access.is_homework_available,
  },
  title: course.lms_course.course?.title || course.lms_course.title,
  studyType: course.lms_course.course?.study_type.title || '',
  type: course.lms_course.course?.type || '',
  courseUrl: course.lms_course.course?.url || '',
  courseId: course.lms_course.course?.id || '',
  vendor: vendorAdapter(course.lms_course.vendor),
  logo: course.lms_course.course?.logo
    ? mapImageResource(course.lms_course.course?.logo)
    : null,
  progress: course?.progress ? mapCourseItemProgress(course.progress) : null,
});

const vendorAdapter = (apiVendor: CourseItemApiVendorModel): VendorModel => ({
  uuid: apiVendor.id,
  slug: apiVendor.slug,
  title: apiVendor.title,
  logo: apiVendor.logo
    ? {
        imageResource: mapImageResource(apiVendor.logo),
        backgroundColor: null,
        size: null,
      }
    : null,
  coursePlug: apiVendor.course_plug
    ? {
        imageResource: mapImageResource(apiVendor.course_plug),
        backgroundColor: null,
        size: null,
      }
    : null,
});

const mapCourseItemProgress = (
  progress: CourseItemProgressApiModel
): CourseItemProgressModel => ({
  progressPercent: progress.progress_percent,
  totalCount: progress.total.total,
  totalDoneCount: progress.total.done,
  topicsCount: progress.topics.total,
  topicsDoneCount: progress.topics.done,
  homeworksCount: progress.homeworks.total,
  homeworksDoneCount: progress.homeworks.done,
  videosCount: progress.lessons.total + progress.longreads.total,
  videosDoneCount: progress.lessons.done + progress.longreads.done,
  testsCount: progress.tests.total,
  testsDoneCount: progress.tests.done,
  diplomasCount: progress.diplomas.total,
  diplomasDoneCount: progress.diplomas.done,
});
