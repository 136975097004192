import { ChangeEvent, FocusEvent } from 'react';
import { InputColor } from '../../../../styles';
import { TranslationModel } from '../../../../models';
import * as Yup from 'yup';
import { NonOptionalKeys } from '@reduxjs/toolkit/dist/query/tsHelpers';

export type PasswordControlProps = {
  label?: string;
  name: string;
  disabled: boolean;
  touched?: boolean;
  errorMessage?: PasswordValidationError[];
  statusMessage?: string;
  translations?: TranslationModel;
  value: string;
  onChange: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  margin?: string;
  color?: InputColor;
  bordered?: boolean;
  floatingPlaceholder?: boolean;
};

export enum PasswordValidationTypes {
  minLength = 'minLength',
  upperCase = 'upperCase',
  lowerCase = 'lowerCase',
  number = 'number',
  specialCharacter = 'specialCharacter',
}

export type PasswordValidation = {
  regex: RegExp;
  regexExclude?: RegExp;
  type: PasswordValidationTypes;
};

export enum PasswordValidationErrorTypes {
  include = 'include',
  exclude = 'exclude',
}

export type PasswordValidationError = {
  type: PasswordValidationErrorTypes;
  validationType: PasswordValidationTypes;
};

export type PasswordValidationErrorsTitles = {
  [key in PasswordValidationTypes]?: string;
};

export type PasswordInitialValues = {
  password: string;
};

export type PasswordValidationObject = Record<
  NonOptionalKeys<PasswordInitialValues>,
  Yup.AnySchema
>;
