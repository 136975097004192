import React, { ReactElement } from 'react';
import { CompensationTagContainerProps } from './compensationTag.model';
import { CompensationTagStyled } from './compensationTag.styled';
import {
  IconInfoCircledComponent,
  Nullable,
  TagLabelColor,
  TagLabelContainer,
  TagLabelIconPosition,
  useTheme,
} from '@lerna-core';
import { TooltipContainer } from '@features/common/tooltip';
import { useCompensationTag } from './compensationTag.hook';

export const CompensationTagContainer = ({
  price,
  size,
  translations,
  course,
  courseIndex,
  borderRadius = 100,
}: CompensationTagContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const { showTag, tagLabel, tooltipContent, handleTooltipOpen } =
    useCompensationTag(price, translations, course, courseIndex);

  if (!showTag) {
    return null;
  }

  return (
    <TooltipContainer content={tooltipContent} onOpen={handleTooltipOpen}>
      <CompensationTagStyled>
        <TagLabelContainer
          noWrap={false}
          label={tagLabel}
          color={TagLabelColor.green}
          size={size}
          iconPosition={TagLabelIconPosition.right}
          icon={
            <IconInfoCircledComponent
              fillColor={theme.COLOR.INFO_GREEN_COLOR}
              strokeColor={theme.COLOR.INFO_GREEN_COLOR}
            />
          }
          borderRadius={borderRadius}
        />
      </CompensationTagStyled>
    </TooltipContainer>
  );
};
