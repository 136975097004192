import React, { ReactElement, ReactNode, useCallback } from 'react';

import { SelectItemStyled } from './selectItem.styled';
import { SelectOptionType } from '../../../form';

export interface DropdownItemProps {
  children: ReactNode;
  value: SelectOptionType;
  onSelect: (value: SelectOptionType) => void;
  active: boolean;
}

export const SelectItemContainer = ({
  value,
  children,
  onSelect,
  active = false,
}: DropdownItemProps): ReactElement => {
  const handleSelect = useCallback(() => {
    onSelect(value);
  }, [value]);

  return (
    <SelectItemStyled active={active} onClick={handleSelect}>
      {children}
    </SelectItemStyled>
  );
};
