import React, { ReactElement } from 'react';

import { SelectionCellContainer } from './selectionCell.container';
import { SelectionCell, SelectionCellProps } from './selectionCell.model';

export const getSelectionCell = (
  handleCheck?: (rows: Record<string, unknown>[]) => void,
  isRadioButton = false
): SelectionCell => {
  return {
    Cell({
      row,
      getToggleAllRowsSelectedProps,
      toggleAllRowsSelected,
    }: SelectionCellProps): ReactElement {
      return (
        <SelectionCellContainer
          toggleAllRowsSelected={toggleAllRowsSelected}
          getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
          handleCheck={handleCheck}
          row={row}
          isRadioButton={isRadioButton}
        />
      );
    },
  };
};
