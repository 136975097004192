import React, { KeyboardEventHandler, ReactElement } from 'react';
import { FilterRangeItemContainerProps } from './filterRangeItem.model';
import { FilterRangeItemStyled } from './filterRangeItem.styled';
import {
  GenericInputContainer,
  InputLabelStyled,
  InputSizes,
  stringService,
} from '@lerna-core';

export const FilterRangeItemContainer = ({
  value,
  prefix,
  filterSlug,
  handleChange,
  handleBlur,
  handleClear,
  placeholder,
  prefixLabel,
  units,
  size = InputSizes.small,
}: FilterRangeItemContainerProps): ReactElement => {
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const isEnter = e.key === 'Enter';
    const target = e.target as HTMLInputElement;
    if (isEnter && target) {
      target.blur();
    }
  };

  return (
    <FilterRangeItemStyled>
      <InputLabelStyled>
        {prefixLabel}, {units}
      </InputLabelStyled>
      <GenericInputContainer
        size={size}
        onChange={handleChange}
        onBlur={handleBlur}
        onClear={handleClear}
        name={`${filterSlug}${prefix}`}
        initialValue={value}
        placeholder={placeholder}
        formatValue={stringService.formatIntNumberValue}
        deformatValue={stringService.formatIntNumberValue}
        autoComplete={'off'}
        onKeyDown={handleKeyDown}
      />
    </FilterRangeItemStyled>
  );
};
