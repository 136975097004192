import { CreateCustomer, CreateCustomerParams } from './createCustomer.model';
import { CreateCustomerClient } from './createCustomer.client';

export async function createCustomerProvider(
  createCustomerParams: CreateCustomerParams
): Promise<CreateCustomer> {
  const response = await new CreateCustomerClient(createCustomerParams).post();

  return response as unknown as Promise<CreateCustomer>;
}
