import React, { ReactElement, useContext, useMemo } from 'react';
import { DiscountProgramLogoContainerProps } from './discountProgramLogo.model';
import { DiscountProgramLogoStyled } from './discountProgramLogo.styled';
import { browserService, DeviceInfoContext, ImageContainer } from '@lerna-core';
import { getDiscountLogoProps } from './dicountProgramLogo.helpers';
import Link from 'next/link';

export const DiscountProgramLogoContainer = ({
  logo,
  href,
}: DiscountProgramLogoContainerProps): ReactElement => {
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const logoProps = useMemo(() => getDiscountLogoProps(isMobile), []);

  if (href) {
    return (
      <DiscountProgramLogoStyled
        width={logoProps.width}
        height={logoProps.height}
      >
        <Link href={href}>
          <ImageContainer
            imageResource={logo.imageResource}
            preset={logoProps.preset}
          />
        </Link>
      </DiscountProgramLogoStyled>
    );
  }

  return (
    <DiscountProgramLogoStyled
      width={logoProps.width}
      height={logoProps.height}
    >
      <ImageContainer
        imageResource={logo.imageResource}
        preset={logoProps.preset}
      />
    </DiscountProgramLogoStyled>
  );
};
