import styled from 'styled-components';
import { ButtonStyled } from '../../../../button';

export const FormUserEducationStreamsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${ButtonStyled} {
    width: auto;
  }
`;
