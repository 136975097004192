import { PageNameContext } from '@contexts';
import { useDiscountProgram } from '@features/common/discountProgram';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  withPhoneError,
  withPromoCodeError,
} from '@features/common/forms';
import {
  CommonEventTypes,
  getCurrentPopupFromOpen,
  logger,
  Nullable,
  PopupsEnum,
  requestClosePopup,
  usePromoCodeContext,
} from '@lerna-core';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { sendUserOrderRequest } from '../userOrderForm.helper';
import {
  getFormB2CInitialValues,
  getFormB2CValidationSchema,
  pushFormSubmitStatusEvent,
} from './formB2C.helper';
import {
  FormB2CContextModel,
  FormB2CContextProviderProps,
} from './formB2C.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const FormB2CContext = React.createContext<Nullable<FormB2CContextModel>>(null);

export const FormB2CContextProvider = ({
  props,
  children,
}: FormB2CContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pageName = useContext(PageNameContext);
  const { removeNumericId } = useCourseActiveContext();
  const { promoCodeQuery, discountProgramQuery } = useDiscountProgram();
  const { promoCode, setPromoCode } = usePromoCodeContext();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [isApplyingPromoCode, setIsApplyingPromoCode] =
    useState<boolean>(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);
  const [isPhoneErrorDisplayed, setIsPhoneErrorDisplayed] =
    useState<boolean>(false);

  const validationSchema = useMemo(
    () =>
      getFormB2CValidationSchema(
        props.translations,
        promoCode,
        discountProgramQuery
      ),
    [promoCode]
  );
  const initialValues = useMemo(() => getFormB2CInitialValues(), []);

  const submitButtonTitle =
    paymentContent === UserOrderFormPaymentContentTypes.consultation
      ? props.translations?.send_request
      : props.translations?.go_to_payment;
  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;
  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder ||
    isPhoneErrorDisplayed;
  const isFreeCourse =
    props.course.isFree ||
    props.course.isFreeByBonuses ||
    props.course.isFreeByDiscount;

  const showPaymentTabs =
    formOrderInfo.hasOrder && !isFreeCourse && !!props.withOnlinePay;

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      actions.setStatus({});

      const promoCodeValue =
        !isOnlinePaySelected && values.withPromoCode
          ? values.promoCode.trim()
          : undefined;

      sendUserOrderRequest(
        props.user,
        props.course,
        router,
        true,
        false,
        pageName,
        promoCodeValue,
        undefined,
        discountProgramQuery,
        isOnlinePaySelected
      )
        .then((response) => onSuccess(!isOnlinePaySelected, response))
        .catch((error): void => {
          const conflictError = isConflictError(error);
          const promoCodeError = withPromoCodeError(error);
          const phoneError = withPhoneError(error);

          if (phoneError) {
            setIsPhoneErrorDisplayed(true);

            return;
          }
          if (promoCodeError) {
            setInputError(
              discountProgramQuery ? 'promoCodeDraft' : 'promoCode',
              `${props.translations?.promocode_does_not_match}`
            );
            orderFormPushDataLayer(
              OrderFormEvents.formEnrollPromoCodeError,
              CommonEventTypes.coursesEvents,
              props.eventPosition,
              props.course
            );

            return;
          }
          if (conflictError) {
            onExistError();

            return;
          }

          onError();
          logger.error(
            `[ERROR]: error sending request. ${error.response?.data}`
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    !promoCodeQuery && setFieldValue('withPromoCode', false);
    !promoCodeQuery && setFieldValue('promoCode', '');
    !promoCodeQuery && setFieldValue('promoCodeDraft', '');
    !promoCodeQuery && setPromoCode(null);
    setFieldValue('withCompany', false);
    setPaymentContent(initialPaymentContent);
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
    isPhoneErrorDisplayed && setIsPhoneErrorDisplayed(false);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        paymentContent,
        showPaymentTabs,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        props.user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
    }
    if (values.withPromoCode) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollPromoCode,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }

    setIsSuccessDisplayed(true);

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.exist,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };
  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };
  const handleFormSubmit = (): void => {
    handleSubmit();
  };

  useEffect(() => {
    if (promoCodeQuery && (!isFreeCourse || discountProgramQuery)) {
      setFieldValue('withPromoCode', true);
      setFieldValue('promoCode', promoCodeQuery);
      setFieldValue('promoCodeDraft', promoCodeQuery);
    }
    if (isFreeCourse && !discountProgramQuery) {
      setFieldValue('withPromoCode', false);
      setFieldValue('promoCode', '');
      setFieldValue('promoCodeDraft', '');
    }
  }, [promoCodeQuery, isFreeCourse, currentPopup]);

  useEffect(() => {
    if (promoCode) {
      setTouched({ promoCodeDraft: true });
    }
  }, [promoCode, currentPopup]);

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    paymentContent,
    handleChangePaymentContent,
    submitButtonTitle,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    showPaymentTabs,
    isFreeCourse,
    isPhoneErrorDisplayed,
    discountProgramQuery,
    promoCodeQuery,
    formOrderInfo,
    isApplyingPromoCode,
    setIsApplyingPromoCode,
  };

  return (
    <FormB2CContext.Provider value={context}>
      {children}
    </FormB2CContext.Provider>
  );
};

export const useFormB2CContext = (): FormB2CContextModel => {
  const value = useContext(FormB2CContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as FormB2CContextModel;
  }

  return value;
};
