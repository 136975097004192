import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPercentGradientComponent = ({
  width = 40,
  height = 40,
}: IconProps): ReactElement => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 40 40">
      <path
        fill="url(#paint0_linear_1759_48521)"
        fillRule="evenodd"
        d="M20 5.833C12.176 5.833 5.833 12.177 5.833 20a1.25 1.25 0 01-2.5 0c0-9.204 7.463-16.666 16.667-16.666 5.453 0 10.294 2.62 13.333 6.665V7.083a1.25 1.25 0 012.5 0v5.834c0 .69-.56 1.25-1.25 1.25H28.75a1.25 1.25 0 110-2.5h2.708A14.147 14.147 0 0020 5.834zM35.417 18.75c.69 0 1.25.56 1.25 1.25 0 9.204-7.462 16.667-16.667 16.667-5.453 0-10.294-2.62-13.333-6.666v2.916a1.25 1.25 0 11-2.5 0v-5.834c0-.69.56-1.25 1.25-1.25h5.833a1.25 1.25 0 010 2.5H8.543A14.147 14.147 0 0020 34.168c7.824 0 14.167-6.343 14.167-14.167 0-.69.56-1.25 1.25-1.25zm-9.95-2.45a1.25 1.25 0 00-1.768-1.767l-9.166 9.166a1.25 1.25 0 001.768 1.768l9.166-9.167zm-9.634 1.2a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zm10 6.667a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1759_48521"
          x1="3.333"
          x2="36.667"
          y1="25.417"
          y2="15.417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE2C72"></stop>
          <stop offset="1" stopColor="#4C07DF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
