import { ApiCourseModel, courseAdapter, CourseModel } from '../course';
import { CurrencyModel, TranslationModel } from '../../models';
import { VendorModel } from '../vendors';

export const coursesAdapter = (
  apiCourses: ApiCourseModel[],
  currencies: CurrencyModel[],
  translations: TranslationModel,
  vendors: VendorModel[]
): CourseModel[] =>
  apiCourses.map((course) =>
    courseAdapter(course, currencies, translations, vendors)
  );
