import styled from 'styled-components';

import { fontSettings } from '../../../styles/mixins';

export const UserSmallInfoStyled = styled.div`
  display: flex;
`;

export const UserSmallInfoTextBlockStyled = styled.div`
  margin-left: 20px;
`;

export const UserSmallInfoNameStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 600;
`;

export const UserSmallInfoProfessionStyled = styled.div`
  margin-top: 8px;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
`;
