import {
  ApiCourseModel,
  publicConfig,
  RequestClient,
  RequestHeader,
} from '@lerna-core';
import { courseSimilarAdapter } from './courseSimilar.adapter';
import { ApiSimilarCoursesModel } from './courseSimilar.model';
import { CourseSimilarPathEnum } from './courseSimilar.enum';

export class CourseSimilarClient extends RequestClient<
  null,
  ApiSimilarCoursesModel,
  ApiCourseModel[]
> {
  protected path = '';
  protected requestParameters = null;
  protected adapter = courseSimilarAdapter;

  constructor(courseSlug: string, page: number, headers?: RequestHeader) {
    super(publicConfig.API_GATEWAY_URL, '', headers);

    this.path = `${CourseSimilarPathEnum.default}${courseSlug}/similar?page=${page}`;
  }
}
