import styled, { css } from 'styled-components';
import { FooterImagesContainerStyledProps } from './footerImages.model';
import { breakpoint } from '../../../styles';

const imageSize = {
  w: 40,
  h: 40,
};
const imageOffset = 4;

export const FooterImagesContainerStyled = styled.div<FooterImagesContainerStyledProps>`
  margin: -${imageOffset}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${({ maxColumns }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        width: calc((${imageSize.w}px + ${imageOffset}px * 2) * ${maxColumns});
      `
    )}
`;

export const FooterImageStyled = styled.div`
  overflow: hidden;
  font-size: 8px;
  display: flex;
  width: ${imageSize.w}px;
  height: ${imageSize.h}px;
  margin: ${imageOffset}px;
  color: ${({ theme }): string => theme.COLOR.WHITE};

  img {
    width: ${imageSize.w}px;
    height: ${imageSize.h}px;
    display: block;
  }
`;
