import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconFashionPhotographyComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.06 4.167c-1.331 0-2.568.71-3.238 1.86l-1.831 3.14H8.75c-2.977 0-5.417 2.44-5.417 5.416v15.834c0 2.976 2.44 5.416 5.417 5.416h22.5c2.977 0 5.417-2.44 5.417-5.416V14.583c0-2.976-2.44-5.416-5.417-5.416h-3.24l-1.832-3.14a3.754 3.754 0 0 0-3.239-1.86h-5.878Zm0 2.5h5.88c.446 0 .854.235 1.079.62l2.194 3.76a1.25 1.25 0 0 0 1.079.62h3.958a2.899 2.899 0 0 1 2.917 2.916v15.834a2.899 2.899 0 0 1-2.917 2.916H8.75a2.899 2.899 0 0 1-2.917-2.916V14.583a2.899 2.899 0 0 1 2.917-2.916h3.958a1.25 1.25 0 0 0 1.08-.62l2.193-3.76c.225-.385.633-.62 1.08-.62Zm2.94 7.5c-4.127 0-7.5 3.372-7.5 7.5 0 4.127 3.373 7.5 7.5 7.5s7.5-3.373 7.5-7.5c0-4.128-3.373-7.5-7.5-7.5Zm0 2.5c2.776 0 5 2.223 5 5 0 2.776-2.224 5-5 5s-5-2.224-5-5c0-2.777 2.224-5 5-5Z"
        fill={fillColor}
      />
    </svg>
  );
};
