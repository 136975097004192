import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const RightHeadStyled = styled.div`
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;

  ${breakpoint(
    'mediumDown',
    css`
      margin-bottom: 20px;
    `
  )}
`;

export const VendorLogoStyled = styled.div`
  margin-bottom: 8px;
`;

export const CourseLogoStyled = styled.div``;

export const WrapperStyled = styled.div`
  margin-right: 16px;
  ${breakpoint(
    'mediumDown',
    css`
      margin-right: 8px;
    `
  )}
`;

export const TitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
  font-weight: 600;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    `
  )}
`;

export const DurationStyled = styled.div`
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    `
  )}
`;

export const StartDateStyled = styled.div`
  margin-top: 4px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    `
  )}
`;
