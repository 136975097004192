import React, { ReactElement } from 'react';
import { useUser } from '../../../user';
import { FooterAnalyticsEvents } from '../../footer.analytics';
import { FooterSocialContainer } from '../../footerSocial';
import { ContactsEmailContainer } from './contactsEmail';
import { ContactsPhoneContainer } from './contactsPhone';
import { ContactsTextContainer } from './contactsText';
import { getMainContactPhone } from './topContacts.helper';
import { TopContactsContainerProps } from './topContacts.model';
import { FooterTopContactsStyled } from './topContacts.styled';
import { ContactsLinkContainer } from './contactsLink';
import { FasttrackControlButtonContainer } from '../../../fasttrackWidget';

export const TopContactsContainer = ({
  translations,
  socialLinks,
  hasFastLinks,
}: TopContactsContainerProps): ReactElement => {
  const { user } = useUser();
  const mainPhone = getMainContactPhone(user, translations);

  return (
    <FooterTopContactsStyled hasFastLinks={hasFastLinks}>
      <FasttrackControlButtonContainer translations={translations} />
      <ContactsPhoneContainer
        value={mainPhone}
        analyticsEvent={FooterAnalyticsEvents.clickInfoPhone}
      />
      <ContactsPhoneContainer
        value={translations?.company_second_phone_label}
        analyticsEvent={FooterAnalyticsEvents.clickInfoPhone}
      />
      <ContactsEmailContainer
        title={translations?.common_questions}
        value={translations?.company_email_label}
        analyticsEvent={FooterAnalyticsEvents.clickInfoEmail}
      />
      <ContactsEmailContainer
        title={translations?.for_advertising}
        value={translations?.company_ad_email_label}
      />
      <ContactsTextContainer
        title={translations?.office_hours}
        value={translations?.office_hours_value_1}
        secondValue={translations?.office_hours_value_2}
      />
      <ContactsLinkContainer
        title={translations?.placement_of_courses}
        link={{
          link: translations?.placement_of_courses_link_1,
          label: translations?.placement_of_courses_label_1,
        }}
        secondLink={{
          link: translations?.placement_of_courses_link_2,
          label: translations?.placement_of_courses_label_2,
        }}
      />
      <ContactsEmailContainer
        title={translations?.collaboration_and_advertising}
        value={translations?.collaboration_email_label}
      />
      <FooterSocialContainer socialLinks={socialLinks} />
    </FooterTopContactsStyled>
  );
};
