import React, { forwardRef, ReactElement, useMemo } from 'react';
import { MenuDesktopStyled } from './menuDesktop.styled';
import { MenuDesktopProps } from './menuDesktop.model';
import { Nullable } from '../../../../models';
import { DropdownContainer } from '../../../dropdown';
import { DesktopStaticLinksContainer } from './desktopStaticLinks';
import { DesktopDynamicLinksContainer } from './desktopDynamicLinks';
import {
  getUserMenuBottomStaticLinks,
  getUserMenuTopStaticLinks,
} from '../userMenu.helpers';
import { useRouter } from 'next/router';
import { DropdownPosition } from '../../../dropdown/dropdown.model';

export const MenuDesktop = forwardRef<HTMLDivElement, MenuDesktopProps>(
  (
    { menu, user, isOpened, handleClose, translations, projectPageName },
    forwardedRef
  ): Nullable<ReactElement> => {
    const router = useRouter();

    const staticLinksTop = useMemo(
      () => getUserMenuTopStaticLinks(translations, router, user),
      []
    );

    const staticLinksBottom = useMemo(
      () => getUserMenuBottomStaticLinks(translations, router, user),
      [router.query]
    );

    if (!isOpened) return null;

    return (
      <DropdownContainer
        ref={forwardedRef}
        handleOutsideClick={handleClose}
        position={DropdownPosition.right}
        top={16}
        extraStyles={['padding: 0; min-width: 320px;']}
      >
        <MenuDesktopStyled>
          <DesktopStaticLinksContainer
            links={staticLinksTop}
            handleClose={handleClose}
            projectPageName={projectPageName}
          />
          <DesktopDynamicLinksContainer
            menu={menu}
            handleClose={handleClose}
            projectPageName={projectPageName}
          />
          <DesktopStaticLinksContainer
            links={staticLinksBottom}
            bordered
            handleClose={handleClose}
            projectPageName={projectPageName}
          />
        </MenuDesktopStyled>
      </DropdownContainer>
    );
  }
);

MenuDesktop.displayName = 'MenuDesktop';
