import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { MenuApiSlugs } from '../menu';
import { navigationsAdapter } from './navigations.adapter';
import {
  NavigationsResponseApiModel,
  NavigationsResponseModel,
} from './navigations.model';

const TYPE = '{{TYPE}}';

export class NavigationsClient extends SecureRequestClient<
  null,
  NavigationsResponseApiModel,
  NavigationsResponseModel
> {
  protected path = `/aggregate/v1/me/navigations/${TYPE}`;
  protected requestParameters: null;
  protected adapter = navigationsAdapter;

  constructor(type: MenuApiSlugs, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
    this.path = this.path.replace(TYPE, type);
  }
}
