import { useEffect, useState } from 'react';
import { POPUP_ANIMATION_DURATION } from './sidePopup.constants';
import { browserService } from '@lerna-core';
import { UseSidePopupModel, UseSidePopupPropsModel } from './sidePopup.model';

export const useSidePopup = ({
  isOpen,
  footerRef,
  withAnimation,
}: UseSidePopupPropsModel): UseSidePopupModel => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isOpen) {
      !!footerRef?.current && setFooterHeight(footerRef.current.clientHeight);
    }

    if (isOpen && !mounted) {
      setMounted(true);
      browserService.removePageScroll();
    } else if (!isOpen && mounted && withAnimation) {
      timeout = setTimeout(() => {
        setMounted(false);
        browserService.addPageScroll();
      }, POPUP_ANIMATION_DURATION);
    } else if (!isOpen && mounted && !withAnimation) {
      setMounted(false);
      browserService.addPageScroll();
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen, footerRef?.current]);

  return { mounted, footerHeight };
};
