import styled, { css } from 'styled-components';

import { fontSettings } from '../../../../styles/mixins';
import { ITheme } from '../../../themes';
import { UserAvatarSize } from '../userAvatar.model';

type UserAvatarIconStyledProps = {
  size: UserAvatarSize;
  isRound?: boolean;
};

const getAvatarDefaultScale = (
  size: UserAvatarSize,
  theme: ITheme
): ReturnType<typeof css> => {
  switch (size) {
    case UserAvatarSize.large:
      return css`
        width: 100px;
        height: 100px;
        ${fontSettings(theme.FONT.SIZE_34, theme.FONT)}
      `;
    case UserAvatarSize.xbig:
      return css`
        width: 64px;
        height: 64px;
        ${fontSettings(theme.FONT.SIZE_20, theme.FONT)}
      `;
    case UserAvatarSize.big:
      return css`
        width: 52px;
        height: 52px;
        ${fontSettings(theme.FONT.SIZE_17, theme.FONT)}
      `;
    case UserAvatarSize.medium:
      return css`
        width: 50px;
        height: 50px;
        ${fontSettings(theme.FONT.SIZE_17, theme.FONT)}
      `;
    case UserAvatarSize.small:
      return css`
        width: 32px;
        height: 32px;
        ${fontSettings(theme.FONT.SIZE_13, theme.FONT)}
      `;
    case UserAvatarSize.xsmall:
      return css`
        width: 24px;
        height: 24px;
        ${fontSettings(theme.FONT.SIZE_9, theme.FONT)}
      `;
  }
};

export const AvatarDefaultStyled = styled.div<UserAvatarIconStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ size, theme }): ReturnType<typeof css> =>
    getAvatarDefaultScale(size, theme)}
  border-radius: ${({ isRound }): string => `${isRound ? '50%' : '8px'}`};
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  background-color: ${({ theme }): string => theme.COLOR.MEDIUM_PURPLE};
`;
