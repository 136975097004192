import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const TableDoubleSortStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SortTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.BLACK};
  font-weight: 500;
`;

export const SortIconsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    cursor: pointer;
  }

  div:first-child {
    transform: rotate(180deg);
    margin-bottom: -8px;
  }
`;
