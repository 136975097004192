import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPaperWithPenGradientComponent = ({
  width = 44,
  height = 40,
}: IconProps): ReactElement => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 44 40">
      <path
        stroke="url(#paint0_linear_1748_60009)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M32.745 12.157V7.255a3.922 3.922 0 00-3.921-3.921H7.253a3.922 3.922 0 00-3.92 3.921v25.49a3.922 3.922 0 003.92 3.922H19.02"
      ></path>
      <path
        stroke="url(#paint1_linear_1748_60009)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M11.176 11.177h13.726"
      ></path>
      <path
        stroke="url(#paint2_linear_1748_60009)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M11.176 18.04h7.843"
      ></path>
      <path
        fill="url(#paint3_linear_1748_60009)"
        d="M42.43 17.64c-11.694-1.277-19.138 15.246-20.542 20.583-.072.273.247.397.413.168 2.11-2.906 3.982-4.97 4.841-5.874.313-.33.758-.49 1.213-.495 4.584-.044 6.47-2.868 6.47-2.868-.636.213-3.065-.211-3.065-.211-1.69-.212.413-.738.726-.805 5.27-1.136 5.614-3.42 5.614-3.42-1.268.423-4.12-.317-4.12-.317 5.176-.421 6.127-3.486 6.127-3.486-1.585.528-3.54.29-3.54.29 4.2-.87 5.864-3.566 5.864-3.566z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1748_60009"
          x1="3.333"
          x2="33.293"
          y1="25.417"
          y2="17.486"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE2C72"></stop>
          <stop offset="1" stopColor="#4C07DF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1748_60009"
          x1="11.176"
          x2="12.009"
          y1="11.839"
          y2="8.408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE2C72"></stop>
          <stop offset="1" stopColor="#4C07DF"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1748_60009"
          x1="11.176"
          x2="12.484"
          y1="18.702"
          y2="15.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE2C72"></stop>
          <stop offset="1" stopColor="#4C07DF"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1748_60009"
          x1="21.667"
          x2="42.578"
          y1="31.953"
          y2="25.954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE2C72"></stop>
          <stop offset="1" stopColor="#4C07DF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
