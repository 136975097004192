import { RequestHeader } from '@lerna-core';
import {
  B2BOrderRequestParams,
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderRequestParams,
  B2COrderResponse,
  FreeOrderRequestParams,
} from './form.model';
import {
  B2BOrderClient,
  B2COrderClient,
  DiscountProgramOrderClient,
  FreeOrderClient,
} from './form.client';

export async function b2cOrderClientProvider(
  params: B2COrderRequestParams,
  headers: RequestHeader
): Promise<B2COrderResponse> {
  const response = await new B2COrderClient(params, headers).post();

  return response as unknown as Promise<B2COrderResponse>;
}

export async function discountProgramOrderClientProvider(
  params: B2COrderRequestParams,
  headers: RequestHeader
): Promise<B2COrderResponse> {
  const response = await new DiscountProgramOrderClient(params, headers).post();

  return response as unknown as Promise<B2COrderResponse>;
}

export async function b2bOrderClientProvider(
  params: B2BOrderRequestParams,
  headers: RequestHeader
): Promise<B2BOrderResponse> {
  const response = await new B2BOrderClient(params, headers).post();

  return response as unknown as Promise<B2BOrderResponse>;
}

export async function freeOrderClientProvider(
  params: FreeOrderRequestParams,
  headers: RequestHeader
): Promise<B2CFreeOrderResponse> {
  const response = await new FreeOrderClient(params, headers).post();

  return response as unknown as Promise<B2CFreeOrderResponse>;
}
