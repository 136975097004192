import styled, { css } from 'styled-components';
import { CheckboxProps } from './checkbox.container';
import { getDisabledStyles } from './checkbox.helper';
import { getCheckboxBorderColor } from './checkbox.mixin.styled';
import { fontSettings } from '../../../../styles';

type CheckboxContainerProps = {
  readOnly?: boolean;
  disabled?: boolean;
};

export const FormCheckboxContainer = styled.label<CheckboxContainerProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  gap: 12px;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_16, props.theme.FONT)};
  ${({ readOnly, disabled }): ReturnType<typeof css> =>
    getDisabledStyles(!!(readOnly || disabled))}
`;

export const IconContainerStyled = styled.div<CheckboxProps>`
  height: 16px;
  min-width: 16px;
  background-color: ${({
    theme,
    checked,
    indeterminate,
    checkedColor,
  }): string =>
    indeterminate || checked
      ? checkedColor || theme.COLOR.TEXT_DARK_COLOR
      : 'transparent'};
  border: 2px solid
    ${({ theme, checked, indeterminate, disabled, checkedColor }): string =>
      getCheckboxBorderColor(
        theme,
        checked,
        indeterminate,
        disabled,
        checkedColor
      )};
  ${({ hasError, theme }): string =>
    hasError ? `border-color: ${theme.COLOR.INFO_RED_COLOR};` : ''}
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 2px;

  svg {
    margin-top: ${({ indeterminate }) => (indeterminate ? '0' : '1px')};
  }
`;

export const IndeterminateMark = styled.span<{ color: string }>`
  width: 10px;
  height: 2.5px;
  border-radius: 20px;
  background-color: ${({ color }): string => color};
`;
