import { UserProfileRequestParamsModel } from './userProfile.model';
import { UserProfileClient } from './userProfile.client';

export const updateUserProfileClientProvider = async (
  params: UserProfileRequestParamsModel
): Promise<unknown> => {
  const response = new UserProfileClient(params).put();

  return response as unknown as Promise<unknown>;
};
