import { Dispatch } from 'react';
import { RequestHeader } from '../../../services';
import { userMenuClientProvider } from './userMenu.clientProvider';
import { UserMenuConstants } from './userMenu.constants';
import { UserMenuActions, UserMenuModel } from './userMenu.model';

export const requestUserMenu = async (
  dispatch: Dispatch<UserMenuActions>,
  requestHeaders?: RequestHeader
): Promise<void> => {
  await userMenuClientProvider(requestHeaders)
    .then((response) => {
      dispatch(requestUserMenuSuccess(response));
    })
    .catch(() => {
      dispatch(requestUserMenuError());
    });
};

export const requestUserMenuSuccess = (
  payload: UserMenuModel[]
): UserMenuActions => ({
  type: UserMenuConstants.REQUEST_USER_MENU_SUCCESS,
  payload,
});

export const requestUserMenuError = (): UserMenuActions => ({
  type: UserMenuConstants.REQUEST_USER_MENU_ERROR,
});
