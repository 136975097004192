import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CourseItemsAfterChange,
  CoursesSearchTerms,
  CoursesTransferProvider,
  UseCoursesTransferReturnType,
  UseCoursesTransferSearchReturnType,
} from './coursesTransfer.model';
import {
  requestTransferCoursesAllocated,
  requestTransferCoursesUnallocated,
  setTransferCoursesAllocatedFilters,
  setTransferCoursesUnallocatedFilters,
  toggleTransferCourses,
  toggleTransferCoursesPending,
} from './coursesTransfer.actions';
import { handleCheckedProps } from '../../transfer';
import {
  getCourseItemsAfterAdd,
  getCourseItemsAfterRemove,
} from './coursesTransfer.service';
import { CourseTableData } from './transferTable';
import {
  getCoursesTransferAllocatedFiltersSelector,
  getCoursesTransferSelector,
  getCoursesTransferUnallocatedFiltersSelector,
} from './coursesTransfer.selectors';
import { getCurrencies } from '../../currencies';
import { getVendors } from '../../vendors';

export const useCoursesTransfer = (
  dispatch: <T>(action: T) => T,
  coursesProvider: CoursesTransferProvider,
  id: string,
  subscriptionId: string,
  benefitId: string,
  courseExclusionCompanyId: string
): UseCoursesTransferReturnType => {
  const {
    unallocated,
    allocated,
    added: addedCourses,
    removed: removedCourses,
  } = useSelector(getCoursesTransferSelector);
  const currencies = useSelector(getCurrencies);
  const vendors = useSelector(getVendors);

  const reloadCourses = (changedCourses: CourseItemsAfterChange): void => {
    const requestParams = {
      ...changedCourses,
      page: 1,
      id,
    };
    dispatch(toggleTransferCoursesPending(true));
    dispatch(
      requestTransferCoursesUnallocated(
        coursesProvider,
        {
          ...unallocated.filters,
          ...requestParams,
          notRelatedWithSubscriptionId: subscriptionId,
          notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          notRelatedWithBenefitId: benefitId,
        },
        currencies,
        vendors
      )
    );
    dispatch(
      requestTransferCoursesAllocated(
        coursesProvider,
        {
          ...allocated.filters,
          ...requestParams,
          relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          relatedWithBenefitId: benefitId,
        },
        currencies,
        vendors
      )
    );
  };

  const handleCheckedLeftCallback = (props: handleCheckedProps): void => {
    const changedCourses = getCourseItemsAfterRemove(
      addedCourses,
      removedCourses,
      props.moved as CourseTableData[]
    );
    dispatch(toggleTransferCourses(changedCourses));
    reloadCourses(changedCourses);
  };

  const handleCheckedRightCallback = (props: handleCheckedProps): void => {
    const changedCourses = getCourseItemsAfterAdd(
      addedCourses,
      removedCourses,
      props.moved as CourseTableData[]
    );
    dispatch(toggleTransferCourses(changedCourses));
    reloadCourses(changedCourses);
  };

  return {
    handleCheckedLeftCallback,
    handleCheckedRightCallback,
  };
};

export const useCoursesTransferSearch = (
  dispatch: <T>(action: T) => T,
  coursesProvider: CoursesTransferProvider,
  id: string,
  subscriptionId: string,
  benefitId: string,
  courseExclusionCompanyId: string
): UseCoursesTransferSearchReturnType => {
  const {
    unallocated,
    allocated,
    added: addedCourses,
    removed: removedCourses,
  } = useSelector(getCoursesTransferSelector);
  const currencies = useSelector(getCurrencies);
  const vendors = useSelector(getVendors);
  const coursesAllocatedFilters = useSelector(
    getCoursesTransferAllocatedFiltersSelector
  );
  const coursesUnallocatedFilters = useSelector(
    getCoursesTransferUnallocatedFiltersSelector
  );

  const [isUnallocatedSearchOpen, setIsUnallocatedSearchOpen] =
    useState<boolean>(!!coursesUnallocatedFilters.search);
  const [isAllocatedSearchOpen, setIsAllocatedSearchOpen] = useState<boolean>(
    !!coursesAllocatedFilters.search
  );

  const handleUnallocatedSearchCancel = (): void => {
    setIsUnallocatedSearchOpen(false);
    dispatch(setTransferCoursesUnallocatedFilters({ search: '' }));

    if (unallocated.filters.search) {
      dispatch(
        requestTransferCoursesUnallocated(
          coursesProvider,
          {
            ...unallocated.filters,
            id,
            added: addedCourses,
            removed: removedCourses,
            search: '',
            notRelatedWithSubscriptionId: subscriptionId,
            notRelatedWithBenefitId: benefitId,
            notRelatedWithCourseExclusionsForCompanyId:
              courseExclusionCompanyId,
          },
          currencies,
          vendors
        )
      );
    }
  };

  const handleAllocatedSearchCancel = (): void => {
    setIsAllocatedSearchOpen(false);
    dispatch(setTransferCoursesAllocatedFilters({ search: '' }));

    if (allocated.filters.search) {
      dispatch(
        requestTransferCoursesAllocated(
          coursesProvider,
          {
            ...allocated.filters,
            id,
            added: addedCourses,
            removed: removedCourses,
            search: '',
            relatedWithSubscriptionId: subscriptionId,
            relatedWithBenefitId: benefitId,
            relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          },
          currencies,
          vendors
        )
      );
    }
  };

  const changeAllocatedSearch = (searchTerm: CoursesSearchTerms): void => {
    setIsAllocatedSearchOpen(true);
    dispatch(setTransferCoursesAllocatedFilters({ searchTerm }));
  };

  const changeUnallocatedSearch = (searchTerm: CoursesSearchTerms): void => {
    setIsUnallocatedSearchOpen(true);
    dispatch(setTransferCoursesUnallocatedFilters({ searchTerm }));
  };

  return {
    isAllocatedSearchOpen,
    handleAllocatedSearchCancel,
    handleUnallocatedSearchCancel,
    isUnallocatedSearchOpen,
    changeUnallocatedSearch,
    changeAllocatedSearch,
  };
};
