import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSoftSkillsComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.841.817a1.25 1.25 0 0 0-1.084 1.857A18.957 18.957 0 0 1 35 11.667c0 3.251-.805 6.312-2.243 8.992a1.25 1.25 0 1 0 2.202 1.182A21.475 21.475 0 0 0 37.5 11.667c0-3.666-.912-7.138-2.54-10.175A1.25 1.25 0 0 0 33.84.817ZM15.833 3.333c-4.587 0-8.333 3.746-8.333 8.334C7.5 16.254 11.246 20 15.833 20c4.588 0 8.334-3.746 8.334-8.333 0-4.588-3.746-8.334-8.334-8.334Zm12.243.817a1.25 1.25 0 0 0-1.118 1.836 12.406 12.406 0 0 1 1.375 5.68c0 2.05-.5 3.971-1.375 5.681a1.25 1.25 0 1 0 2.225 1.14 14.931 14.931 0 0 0 1.65-6.82c0-2.458-.604-4.776-1.65-6.82a1.25 1.25 0 0 0-1.107-.697ZM15.833 5.833a5.815 5.815 0 0 1 5.834 5.834 5.815 5.815 0 0 1-5.834 5.833A5.815 5.815 0 0 1 10 11.667a5.815 5.815 0 0 1 5.833-5.834Zm-9.583 17.5c-2.056 0-3.75 1.694-3.75 3.75v1.25c0 2.532 1.73 4.665 4.157 6.088 2.426 1.422 5.642 2.246 9.176 2.246 3.535 0 6.75-.824 9.177-2.246 2.426-1.423 4.157-3.556 4.157-6.088v-1.25c0-2.056-1.694-3.75-3.75-3.75H6.25Zm0 2.5h19.167c.705 0 1.25.545 1.25 1.25v1.25c0 1.38-.975 2.79-2.922 3.931-1.947 1.142-4.773 1.903-7.912 1.903-3.138 0-5.964-.761-7.911-1.903C5.974 31.122 5 29.713 5 28.334v-1.25c0-.706.545-1.25 1.25-1.25Z"
        fill={fillColor}
      />
    </svg>
  );
};
