import styled from 'styled-components';
import { rgba } from 'polished';
import { fontSettings } from '@lerna-core';

export const TooltipMobileContentStyled = styled.div`
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_HINTS};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: unset;
  transform: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
`;

export const TooltipMobileOverlayStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }): string => theme.ZINDEX.BOTTOM};
  background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.48)};
`;

export const TooltipMobileContentWrapperStyled = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  padding: 40px 24px;
  border-radius: 24px 24px 0 0;
  white-space: normal;
`;

export const TooltipMobileContentHTMLStyled = styled.div`
  h4 {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  p {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
    margin: 0;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

export const TooltipMobileButtonStyled = styled.div`
  margin-top: 32px;

  button {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
  }
`;
