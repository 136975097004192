import React, { ReactElement } from 'react';
import { IconProps } from './icon.model';

export const IconEducationComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.044 21c-3.8 0-6.705-1.639-6.705-3.715v-6.009L.535 9.31c-.447-.219-.67-.656-.447-1.093a.863.863 0 0 1 .447-.437l11.174-4.698a.727.727 0 0 1 .67 0L23.553 7.78s.112 0 .112.11l.223.218c0 .109.112.218.112.218v5.682a.871.871 0 0 1-.894.874c-.559 0-.894-.328-.894-.874V9.747l-3.464 1.53v6.008c0 2.076-2.905 3.715-6.704 3.715Zm-5.028-3.715c0 .874 1.9 2.076 5.028 2.076 3.129 0 5.028-1.202 5.028-2.076v-5.353l-4.693 1.966a.727.727 0 0 1-.67 0l-4.693-1.966v5.353Zm-4.023-8.74 9.05 3.824 9.052-3.824-9.051-3.824-9.051 3.824Z"
      fill={fillColor}
    />
  </svg>
);
