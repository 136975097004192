import React, { ReactElement } from 'react';
import { ToggleButtonGroupStyled } from './toggleButtonGroup.styled';
import { ToggleButtonGroupModel } from './toggleButtonGroup.model';
import { ToggleButtonContainer } from './toggleButton';
import { Nullable } from '../../models';

export const ToggleButtonGroupContainer = ({
  active,
  items,
  handleChange,
}: ToggleButtonGroupModel): Nullable<ReactElement> => {
  if (items.length < 2) {
    return null;
  }

  return (
    <ToggleButtonGroupStyled>
      {items.map((item, index) => (
        <ToggleButtonContainer
          handleClick={handleChange}
          isActive={item.key === active?.key}
          key={`toggleButton_${index}`}
          item={item}
        />
      ))}
    </ToggleButtonGroupStyled>
  );
};
