import React, { ReactElement, useCallback } from 'react';
import {
  FilterPopupOptionsStyled,
  FilterPopupSpinnerStyled,
} from './popupBody.styled';
import { FilterPopupOptionContainer } from '../filterPopupOption';
import { SpinnerContainer } from '../../spinner';
import { PopupBodyModel } from './popupBody.model';
import { handleSelectOption } from './popupBody.service';
import { getOption } from './popupBody.meta';

export const PopupBodyContainer = ({
  options,
  selected,
  selectAllOptionLabel,
  optionsToRender,
  idKey,
  labelKey,
  setSelected,
  selectAllOption,
  hasNextPage,
  optionsType,
  hasSearch,
}: PopupBodyModel): ReactElement => {
  const allSelected = selected.length === options.length;

  const handleSelectAll = useCallback(() => {
    setSelected(allSelected ? [] : options.map((opt) => opt[idKey] as string));
  }, [options, allSelected]);

  const handleSelect = useCallback(
    (optionId: string) => {
      handleSelectOption({
        optionsType,
        setSelected,
        selected,
        optionId,
      });
    },
    [selected]
  );

  return (
    <FilterPopupOptionsStyled hasSearch={hasSearch}>
      {selectAllOption && (
        <FilterPopupOptionContainer
          active={allSelected}
          id="selectAllOption"
          label={selectAllOptionLabel}
          onSelect={handleSelectAll}
        />
      )}

      {optionsToRender.map((option) => {
        const isActive = !!selected.find((opt) => opt === option[idKey]);

        return getOption({
          optionsType,
          option,
          idKey,
          labelKey,
          isActive,
          handleSelect,
        });
      })}
      {hasNextPage && (
        <>
          <FilterPopupSpinnerStyled>
            <SpinnerContainer height={24} width={24} />
          </FilterPopupSpinnerStyled>
          <div id="footerAnchor" style={{ marginTop: '-250px' }} />
        </>
      )}
    </FilterPopupOptionsStyled>
  );
};
