import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconArrowComponent = ({
  width = 16,
  height = 16,
  strokeColor = '#000000',
  strokeWidth = '1.5',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 16 16'}
    >
      <path
        d="M3 6l5 5 5-5"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
