import { CareerModel, checkUserRole, getUserRole } from '../user';

/**
 * Check for points on the user
 * @return {boolean}
 * @params - user
 * */
export const hasBonusPoints = (career?: CareerModel): boolean => {
  const userRole = getUserRole(career);

  return (
    !career?.isBonusPointsHiddenOnListing && checkUserRole(userRole, career)
  );
};
