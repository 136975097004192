import styled, { css } from 'styled-components';

import { breakpoint, transition } from '../../../styles';

type AccordionContentStyledProps = {
  isOpened: boolean;
};

export const AccordionContentStyled = styled.div<AccordionContentStyledProps>`
  overflow: hidden;
  max-height: ${({ isOpened }): string => (isOpened ? 'unset' : '0')};
  padding-bottom: ${({ isOpened }): string => (isOpened ? '32' : '0')}px;
  opacity: ${({ isOpened }): string => (isOpened ? '1' : '0')};
  ${transition('max-height, opacity')};
  position: relative;

  ${({ isOpened }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        margin-top: ${isOpened ? '-16' : '0'}px;
      `
    )};
`;
