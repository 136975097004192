import { SliderControlTypes } from './sliderControl';
import { ScrollOptionsModel } from '../../models';

type ScrollNodeStateModel = {
  isStart: boolean;
  isEnd: boolean;
};

const getPrevIndex = (
  activeSlide: number,
  itemsToScroll: number,
  loop: boolean,
  count: number
): number => {
  const prevIndex = activeSlide - itemsToScroll;

  if (prevIndex < 0) {
    if (loop) {
      return count - 1;
    }

    return 0;
  }

  return prevIndex;
};

const getNextIndex = (
  activeSlide: number,
  itemsToScroll: number,
  loop: boolean,
  count: number
): number => {
  const nextIndex = activeSlide + itemsToScroll;
  if (nextIndex > count - 1) {
    if (loop) {
      return 0;
    }

    return count - 1;
  }

  return nextIndex;
};

/**
 * Getting state of the element that is scrolling
 * @return (object) - isStart - if there was no horizontal scrolling. isEnd - if the horizontal scroll is all scrolled
 * @param node
 * */
export const getScrollNodeState = (
  node: HTMLDivElement
): ScrollNodeStateModel => {
  const { scrollLeft, scrollWidth } = node;
  const { width: clientWidth } = node.getBoundingClientRect();

  return {
    isStart: node.scrollLeft <= 0,
    isEnd: clientWidth + scrollLeft >= scrollWidth,
  };
};

const getFirstVisibleSlideIndex = (slider: HTMLDivElement): number => {
  const sliderScrollLeft = Math.ceil(slider.scrollLeft);
  let index = 0;

  for (let i = 0; i < slider.childElementCount; i++) {
    const currentChild = slider.children[i] as HTMLDivElement;

    const currentChildOffsetLeft = Math.floor(currentChild.offsetLeft);
    const currentChildWidth = Math.floor(
      currentChild.getBoundingClientRect().width
    );

    const isVisible =
      currentChildOffsetLeft <= sliderScrollLeft &&
      currentChildWidth + currentChildOffsetLeft > sliderScrollLeft;

    if (isVisible) {
      index = i;

      break;
    }
  }

  return index;
};

/**
 * Getting index of the slider you want to scroll to
 * @return (number) - scroll value
 * @param slider
 * @param itemsToScroll
 * @param type
 * @param loop
 * */
export const getSlideToScrollIndex = (
  slider: HTMLDivElement,
  itemsToScroll: number,
  type: SliderControlTypes,
  loop: boolean
): number => {
  switch (type) {
    case SliderControlTypes.next: {
      const { isEnd } = getScrollNodeState(slider);

      if (isEnd && loop) {
        return 0;
      }

      return isEnd
        ? 0
        : getNextIndex(
            getFirstVisibleSlideIndex(slider),
            itemsToScroll,
            loop,
            slider.childElementCount
          );
    }
    case SliderControlTypes.prev: {
      const { isStart } = getScrollNodeState(slider);

      return isStart
        ? slider.childElementCount - 1
        : getPrevIndex(
            getFirstVisibleSlideIndex(slider),
            itemsToScroll,
            loop,
            slider.childElementCount
          );
    }
  }
};

/**
 * Getting the scroll value
 * @return (number) - scroll value
 * @param slider
 * @param index
 * */
export const getScrollOffset = (
  slider: HTMLDivElement | null,
  index: number
): number => {
  if (slider) {
    const selectedElement = slider.children[index] as HTMLDivElement;
    if (selectedElement) {
      const offsetLeft =
        (index === 0 ? 0 : selectedElement.offsetLeft) - slider.scrollLeft;
      const width = slider.getBoundingClientRect().width;

      return Math.min(
        Math.max(0, slider.scrollLeft + offsetLeft),
        slider.scrollWidth - width
      );
    }
  }

  return 0;
};

/**
 * Scroll to the desired slide
 * @param slider
 * @param offset
 * */
export const scrollToSlide = (slider: HTMLDivElement, offset: number): void => {
  const scrollOptions: ScrollOptionsModel = {
    top: 0,
    left: offset,
  };

  if ('scrollBehavior' in document.body.style) {
    scrollOptions.behavior = 'smooth';
  }

  slider.scrollTo(scrollOptions);
};
