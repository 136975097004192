import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconShareComponent = ({
  width = 16,
  height = 16,
  fillColor = '#49454D',
}: IconProps): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor}
      d="M5.64333 9.41667L11.7267 3.33333H11H10C9.63181 3.33333 9.33333 3.03486 9.33333 2.66667C9.33333 2.29848 9.63181 2 10 2H12.6667H14V6C14 6.36819 13.7015 6.66667 13.3333 6.66667C12.9651 6.66667 12.6667 6.36819 12.6667 6V4.27333L6.58333 10.3567C6.32376 10.6162 5.90291 10.6162 5.64333 10.3567C5.38376 10.0971 5.38376 9.67624 5.64333 9.41667ZM3.33333 12.6667H12.6667V8.66667C12.6667 8.29848 12.9651 8 13.3333 8C13.7015 8 14 8.29848 14 8.66667V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H7.33333C7.70152 2 8 2.29848 8 2.66667C8 3.03486 7.70152 3.33333 7.33333 3.33333H3.33333V12.6667Z"
    />
  </svg>
);
