import { ReactElement } from 'react';
import { Column } from 'react-table';
import { Nullable, TranslationModel } from '../../models';

export type Row = Record<string, unknown>;
export type Columns = Column<Row>;

export type TransferLeftProps = {
  getSelectedRows: (rows: Row[]) => void;
  leftRows: Row[];
  leftColumns: Columns[];
  handleCheck?: (rows: Row[]) => void;
  headerComponent?: ReactElement | boolean;
  translations: TranslationModel;
  libraryId?: string;
  unallocatedTopBlock?: Nullable<ReactElement>;
};

export type TransferRightProps = {
  getSelectedRows: (rows: Row[]) => void;
  rightRows: Row[];
  rightColumns: Columns[];
  handleCheck?: (rows: Row[]) => void;
  headerComponent?: ReactElement | boolean;
  translations: TranslationModel;
  libraryId?: string;
  allocatedTopBlock?: Nullable<ReactElement>;
};

export type TransferControlsProps = {
  handleCheckedLeft: () => void;
  handleCheckedRight: () => void;
  disabledLeft: boolean;
  disabledRight: boolean;
};

export type handleCheckedProps = {
  moved: Row[];
};

export type TransferProps = {
  Components: {
    TransferLeftContainer: (props: TransferLeftProps) => ReactElement;
    TransferRightContainer: (props: TransferRightProps) => ReactElement;
    TransferControlsContainer: (props: TransferControlsProps) => ReactElement;
  };
  leftRows: Row[];
  rightRows: Row[];
  leftColumns: Columns[];
  rightColumns: Columns[];
  handleCheckedLeftCallback: ({ moved }: handleCheckedProps) => void;
  handleCheckedRightCallback: ({ moved }: handleCheckedProps) => void;
  handleSelectLeftCallback?: (count: number) => void;
  handleSelectRightCallback?: (count: number) => void;
  leftHeaderComponent?: ReactElement | boolean;
  rightHeaderComponent?: ReactElement | boolean;
  translations: TranslationModel;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  componentsProps?: {
    libraryId?: string;
  };
  allocatedTopBlock?: Nullable<ReactElement>;
  unallocatedTopBlock?: Nullable<ReactElement>;
};

export enum LocationType {
  allocated = 'allocated',
  unallocated = 'unallocated',
}
