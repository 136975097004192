import { NextRouter } from 'next/router';
import { mainPath, QueryParams, urlService } from '@lerna-core';
import { getDiscountProgramPageUrl } from '@features/common/discountProgram';

export const getCourseBackHref = (
  router: NextRouter,
  backHrefPath = mainPath.courses
): string => {
  const ignoredQueryParams = [QueryParams.discountProgram, 'slug'];
  const queryParams = Object.keys(router.query).filter(
    (param) => !ignoredQueryParams.includes(param)
  );
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  if (discountProgramQuery) {
    return getDiscountProgramPageUrl(router, discountProgramQuery, queryParams);
  }

  return urlService.getFullUrlToPage(backHrefPath, router, queryParams);
};
