import styled from 'styled-components';
import { customizeScrollBar, fontSettings } from '@lerna-core';
import { FilterOptionsItemsStyledProps } from './filterOptions.model';

export const FilterOptionsStyled = styled.div`
  width: 100%;
`;

export const FilterOptionsItemsStyled = styled.div<FilterOptionsItemsStyledProps>`
  position: relative;
  max-height: 255px;

  ${({ bordered, theme, isEmpty }): string =>
    bordered
      ? `
    padding: 12px 0;
    margin-top: 12px;
    border-top: 1px solid ${theme.COLOR.BLACK_08};
    ${
      !isEmpty
        ? `border-bottom: 1px solid ${theme.COLOR.BLACK_08};`
        : 'padding: 12px 0 0;'
    }
  `
      : ''}

  ${customizeScrollBar()}
  
  ${({ showAllFilterOptions }): string =>
    !showAllFilterOptions ? 'overflow-y: unset;' : ''}
`;

export const FilterOptionsEmptyTitleStyled = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 4px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)}
`;

export const FilterOptionsEmptySubTitleStyled = styled.div`
  text-align: center;
  color: ${({ theme }): string => theme.COLOR.TEXT[100]};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
`;

export const FilterOptionsBorderStyled = styled.div``;
