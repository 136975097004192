import styled, { css } from 'styled-components';
import {
  breakpoint,
  SliderContainerStyled,
  SliderControlNextStyled,
  SliderControlPrevStyled,
  SliderListWrapStyled,
} from '@lerna-core';

export const CourseGalleryContainerStyled = styled.div`
  margin: 0;

  ${breakpoint(
    'xlargeDown',
    css`
    margin: 0;
  
    ${SliderControlPrevStyled},
    ${SliderControlNextStyled} {
      top: unset;
      bottom: 0;
      transform: translateY(0);
    }
    
    ${SliderContainerStyled} {
        padding: 0 0 78px;
      }
    )}
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      ${SliderListWrapStyled} {
        &:before,
        &:after {
          content: '';
          flex: 0 0 20px;
        }
      }
    `
  )}
`;
