import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';
import { useFormB2BWithoutCompensationContext } from '../formB2BWithoutCompensation.context';
import { ContentStatusContainer } from './contentStatus';

export const FormContentContainer = (): ReactElement => {
  const { showFormStatus } = useFormB2BWithoutCompensationContext();

  return (
    <FormContentStyled>
      <ContentLeftContainer />
      <ContentRightContainer />
      {showFormStatus && <ContentStatusContainer />}
    </FormContentStyled>
  );
};
