import getConfig from 'next/config';

import devConfig from './devConfig.json';

export type Config = {
  [key: string]: string;
};

export type ServerConfig = {
  [key: string]: string;
};

export const publicConfig: Config =
  !getConfig() && process.env.NODE_ENV === 'development'
    ? devConfig
    : getConfig()?.publicRuntimeConfig.config;

export const serverConfig: ServerConfig =
  getConfig()?.serverRuntimeConfig.config;
