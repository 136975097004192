import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const FilterPopupFooterStyled = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;

  padding: 12px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.8);

  > button {
    width: inherit;
    ${(props): string =>
      fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
    padding: 12px 16px;
  }
`;
