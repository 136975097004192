import styled from 'styled-components';

type LanguageDesktopContainerStyledProps = {
  isOpened: boolean;
};

export const LanguageDesktopContainerStyled = styled.div<LanguageDesktopContainerStyledProps>`
  position: relative;

  > button {
    padding: 14px 13px;
    width: 54px;
  }
`;
