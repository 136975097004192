import React, { createContext, ReactElement, useContext, useMemo } from 'react';
import {
  CoursesTransferContextProviderProps,
  CoursesTransferContextReturnType,
} from './coursesTransfer.model';
import { logger } from '../../../services';

const CoursesTransferContext = createContext<CoursesTransferContextReturnType>(
  {} as CoursesTransferContextReturnType
);

export const CoursesTransferContextProvider = ({
  children,
  coursesProvider,
  coursesAllProvider,
  accessors,
  baseDomain,
  isRadioButton,
  dispatch,
  courseExclusionCompanyId,
  id,
}: CoursesTransferContextProviderProps): ReactElement => {
  const context = useMemo(
    () =>
      ({
        coursesProvider,
        coursesAllProvider,
        accessors,
        isRadioButton,
        baseDomain,
        id,
        courseExclusionCompanyId,
        dispatch,
      } as CoursesTransferContextReturnType),
    [
      coursesProvider,
      id,
      courseExclusionCompanyId,
      baseDomain,
      isRadioButton,
      coursesAllProvider,
      accessors,
    ]
  );

  return (
    <CoursesTransferContext.Provider value={context}>
      {children}
    </CoursesTransferContext.Provider>
  );
};

export const useCoursesTransferContext =
  (): CoursesTransferContextReturnType => {
    const value = useContext(CoursesTransferContext);

    if (!value) {
      logger.error('[ERROR]: you cannot use context without a provider');

      // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
      // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
      return {} as CoursesTransferContextReturnType;
    }

    return value;
  };
