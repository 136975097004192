import { UpdateCareer, UpdateCareerParams } from './updateCareer.model';
import { UpdateCareerClient } from './updateCareer.client';

export async function updateCareerProvider(
  params: UpdateCareerParams
): Promise<UpdateCareer> {
  const response = await new UpdateCareerClient(params).patch();

  return response as unknown as Promise<UpdateCareer>;
}
