export const TOGGLE_TRANSFER_CUSTOMERS = 'TOGGLE_TRANSFER_CUSTOMERS';
export const INIT_TRANSFER_CUSTOMERS = 'INIT_TRANSFER_CUSTOMERS';

export const SET_TRANSFER_CUSTOMERS_TOTAL_CUSTOMERS =
  'SET_TRANSFER_CUSTOMERS_TOTAL_CUSTOMERS';

export const REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_ERROR =
  'REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_ERROR';
export const REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_START =
  'REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_START';
export const REQUEST_ALL_TRANSFER_UNALLOCATED_CUSTOMERS_START =
  'REQUEST_ALL_TRANSFER_UNALLOCATED_CUSTOMERS_START';
export const REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_SUCCESS =
  'REQUEST_TRANSFER_UNALLOCATED_CUSTOMERS_SUCCESS';
export const SELECT_ALL_TRANSFER_UNALLOCATED_CUSTOMERS =
  'SELECT_ALL_TRANSFER_UNALLOCATED_CUSTOMERS';

export const REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_ERROR =
  'REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_ERROR';
export const REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_START =
  'REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_START';
export const REQUEST_ALL_TRANSFER_ALLOCATED_CUSTOMERS_START =
  'REQUEST_ALL_TRANSFER_ALLOCATED_CUSTOMERS_START';
export const REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS =
  'REQUEST_TRANSFER_ALLOCATED_CUSTOMERS_SUCCESS';
export const SELECT_ALL_TRANSFER_ALLOCATED_CUSTOMERS =
  'SELECT_ALL_TRANSFER_ALLOCATED_CUSTOMERS';

export const TOGGLE_TRANSFER_CUSTOMERS_PENDING =
  'TOGGLE_TRANSFER_CUSTOMERS_PENDING';

export const SET_TRANSFER_UNALLOCATED_CUSTOMERS_FILTERS =
  'SET_TRANSFER_UNALLOCATED_CUSTOMERS_FILTERS';
export const SET_TRANSFER_ALLOCATED_CUSTOMERS_FILTERS =
  'SET_TRANSFER_ALLOCATED_CUSTOMERS_FILTERS';

export const CLEAR_TRANSFER_CUSTOMERS = 'CLEAR_TRANSFER_CUSTOMERS';
