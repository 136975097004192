import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../styles';

export const RegisterWelcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RegisterWelcomeTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  white-space: pre-wrap;

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
      `,
      theme.IS_MOBILE
    )}
`;

export const RegisterWelcomeBonusesSubtitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 32px;
  text-align: center;
  white-space: pre-wrap;
`;

export const RegisterWelcomeBonusesCurrency = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.WHITE};
  background: linear-gradient(90deg, #9647fe 0%, #573df4 100%);
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

export const RegisterWelcomeVideo = styled.video`
  margin-bottom: 32px;
  border-radius: 50%;
  height: 168px;
  width: 168px;
  object-position: top;
  background: #325ba2;
`;
