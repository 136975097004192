import styled, { css } from 'styled-components';
import { breakpoint } from '../../../styles';

export const FooterSocialContainerStyled = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint(
    'mediumDown',
    css`
      justify-content: center;
    `
  )}
`;
