import { fontSettings } from '@lerna-core';
import styled from 'styled-components';
import { CourseVendorLabelStyledProps } from './courseVendorLabel.model';

export const CourseVendorLabelStyled = styled.div<CourseVendorLabelStyledProps>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)};
  text-transform: uppercase;
  padding: 6px 12px;
  font-weight: 700;
  border-radius: 100px;
  margin-top: 2px;
`;
