import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';

export const FilterPopupFooterContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterPopupFooterClearStyled = styled.div`
  cursor: pointer;
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_15, props.theme.FONT)};
  font-weight: 500;
  margin-right: 25px;
`;

export const FilterPopupFooterButtonStyled = styled.div`
  cursor: pointer;
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'smallDown',
      css`
        flex: 1 1 auto;
      `,
      theme.IS_MOBILE
    )}

  button {
    ${({ theme }): ReturnType<typeof css> | string =>
      breakpoint(
        'smallDown',
        css`
          width: 100%;
        `,
        theme.IS_MOBILE
      )}
  }
`;
