import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { TooltipContainerProps } from './tooltip.model';
import { TooltipStyled, TooltipTriggerStyled } from './tooltip.styled';
import { TooltipContentContainer } from './tooltipContent';
import {
  browserService,
  getCurrentPopups,
  useClickOutsideHook,
} from '@lerna-core';
import { useSelector } from 'react-redux';

export const TooltipContainer = ({
  children,
  content,
  contentPosition = 'right',
  triggerMargin,
  onOpen,
  contentMargin,
  triggerHoverOpacity,
}: TooltipContainerProps): ReactElement => {
  const tooltipRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const currentPopups = useSelector(getCurrentPopups());

  const handleTooltipToggle = (): void => {
    setIsOpen(!isOpen);
  };
  const handleOutsideClick = (): void => {
    setIsOpen(false);
  };

  useClickOutsideHook(tooltipRef, handleOutsideClick);

  useEffect(() => {
    if (isOpen) {
      !currentPopups?.length && browserService.removePageScroll();
      onOpen && onOpen();
    } else {
      !currentPopups?.length && browserService.addPageScroll();
    }
  }, [isOpen, currentPopups?.length]);

  return (
    <TooltipStyled ref={tooltipRef}>
      <TooltipTriggerStyled
        onClick={handleTooltipToggle}
        margin={triggerMargin}
        triggerHoverOpacity={triggerHoverOpacity}
      >
        {children}
      </TooltipTriggerStyled>
      {isOpen && (
        <TooltipContentContainer
          content={content}
          handleClose={handleTooltipToggle}
          position={contentPosition}
          margin={contentMargin}
        />
      )}
    </TooltipStyled>
  );
};
