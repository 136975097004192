import { useEffect, useMemo, useState } from 'react';
import { EducationStreamModel } from './educationStreams.model';
import { useSelector } from 'react-redux';
import { getUser } from '../user';
import { getEducationStreams } from './educationStreams.selectors';
import { MAX_EDUCATION_STREAMS } from './educationStreams.constants';

export const useSelectedEducationStreams = (): [
  EducationStreamModel[],
  (stream: EducationStreamModel) => void
] => {
  const [selectedEducationStreams, setSelectedEducationStreams] = useState<
    EducationStreamModel[]
  >([]);

  const user = useSelector(getUser);
  const educationStreams = useSelector(getEducationStreams);

  useEffect(() => {
    const userEducationStreams = user?.personal?.educationStreams;

    if (userEducationStreams?.length && educationStreams?.length) {
      const interEducationStreams: EducationStreamModel[] = [];

      userEducationStreams.forEach((userStream) => {
        if (educationStreams.some((stream) => stream.id === userStream.id)) {
          return interEducationStreams.push(userStream);
        }
      });

      setSelectedEducationStreams(interEducationStreams);
    }
  }, [user, educationStreams]);

  const handleSelect = (stream: EducationStreamModel): void => {
    let educationStreams: EducationStreamModel[];

    const isExistStream = selectedEducationStreams?.some(
      (item) => item.id === stream.id
    );

    if (isExistStream) {
      educationStreams = selectedEducationStreams.filter(
        (item) => item.id !== stream.id
      );
    } else if (selectedEducationStreams.length < MAX_EDUCATION_STREAMS) {
      educationStreams = [...selectedEducationStreams, stream];
    } else {
      educationStreams = selectedEducationStreams;
    }

    setSelectedEducationStreams(educationStreams);
  };

  return useMemo(
    () => [selectedEducationStreams, handleSelect],
    [selectedEducationStreams]
  );
};
